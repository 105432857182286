<script lang="ts" setup>
import { setLocaleFromLocalStoragePublic } from "@/i18n";

await setLocaleFromLocalStoragePublic(true);

window.location.replace("https://www.memom.solutions/not-found");
</script>

<template>
  <v-main v-bind="$attrs">
    <v-container>
      <!--      <v-card>-->
      <!--        <v-card-text class="text-center">-->
      <!--          <div class="text-h3 text-uppercase pb-6">{{ $t("tenantNotFound-title", { $: "Not found" }) }}</div>-->
      <!--          <div class="text-h4 pb-4">{{ $t("tenantNotFound-label", { $: "Unfortunately requested tenant was not found." }) }}</div>-->
      <!--          <div class="text-h4 pb-4">{{ $t("tenantNotFound-emoji", { $: "¯\_(ツ)_/¯" }) }}</div>-->
      <!--        </v-card-text>-->
      <!--      </v-card>-->
    </v-container>
  </v-main>
</template>

<style lang="scss" scoped></style>
