import { ApiClient } from "@/services/api/common.api";
import {
  CreateCommunicationItemCommand,
  CreateCommunicationItemResponse, CreateRandomCommunicationItemsCommand,
  GetCommunicationItemsQuery, GetCommunicationItemsResponse,
  MarkCommunicationItemAsFailedCommand, MarkCommunicationItemAsPlannedCommand, MarkCommunicationItemAsSentCommand, UpdateCommunicationItemCommand
} from "@masta/generated-model";

export default {
  async create(request: CreateCommunicationItemCommand) {
    return ApiClient.post<CreateCommunicationItemResponse>(`/communications`, request);
  },
  async getAll(request: GetCommunicationItemsQuery) {
    return ApiClient.get<GetCommunicationItemsResponse>(`/communications/${request.scenarioId}`);
  },
  async update(request: UpdateCommunicationItemCommand) {
    return ApiClient.put<undefined>(`/communications/${request.id}`, request);
  },
  async remove(id: string) {
    return ApiClient.delete<undefined>(`/communications/${id}`);
  },
  async markAsPlanned(request: MarkCommunicationItemAsPlannedCommand) {
    return ApiClient.put(`/communications/${request.id}/markAsPlanned`, request);
  },
  async markAsFailed(request: MarkCommunicationItemAsFailedCommand) {
    return ApiClient.put(`/communications/${request.id}/markAsFailed`, request);
  },
  async markAsSent(request: MarkCommunicationItemAsSentCommand) {
    return ApiClient.put(`/communications/${request.id}/markAsSent`, request);
  },
  async generateRandomCommunicationItems(request: CreateRandomCommunicationItemsCommand) {
    return ApiClient.post(`/communications/generateRandomCommunicationItems`, request);
  }
};
