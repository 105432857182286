import { Layout } from "../Layout";

export class GanttLayout extends Layout {
  constructor() {
    super();
  }

  get name(): string {
    return "GanttLayout";
  }
}
