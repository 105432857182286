﻿import ApiService from "@/services/api";
import { CalendarDto, UpdateResourceModelInstanceAssignmentCommand } from "@masta/generated-model";
import { defineStore } from "pinia";
import { useScenariosStore } from "@/store/ScenariosStore";
import { useErrorsStore } from "@/store/ErrorsStore";

interface State {
  calendars: CalendarDto[];
}

export const useCalendarsStore = defineStore("calendars", {
  state: (): State => ({
    calendars: []
  }),
  actions: {
    async fetch() {
      try {
        const scenariosStore = useScenariosStore();
        if (!scenariosStore.selectedScenario) return;
        const response = await ApiService.calendars.getAll(scenariosStore.selectedScenario.id);
        this.calendars = response.data.calendars ?? [];
      } catch (e) {
        const errorsStore = useErrorsStore();
        errorsStore.handleError(e);
        throw e;
      }
    },
    async updateModelInstanceAssignment(request: UpdateResourceModelInstanceAssignmentCommand) {
      try {
        await ApiService.resources.updateModelInstanceAssignment(request);
        await this.fetch();
      } catch (e) {
        const errorsStore = useErrorsStore();
        errorsStore.handleError(e);
        throw e;
      }
    }
  }
});
