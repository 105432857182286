import { FilterChangedEvent, GridApi, GridReadyEvent } from "ag-grid-community";
import { Ref } from "vue";
import { GridWrapperComponent } from "@/components/Grid/GridWrapperComponent";
import FilterGridAction, { FilterGridActionItemValue } from "@/components/Grid/Filters/FilterGridAction.vue"


export interface IFilterGridActionProps {
    /**
     * Identifier for the filter modal, representing the field name within the grid.
     */
    filterKey: string;

    /**
     * This reference is a valuable link to the GridWrapper component.
     */
    gridWrapperRef: Ref<GridWrapperComponent | undefined>;

    /**
     * This reference establishes a connection with the predefined FilterGridAction component. 
     */
    filterGridActionRef: Ref<typeof FilterGridAction | undefined>;   
}

export const useFilterGridAction = (options: IFilterGridActionProps) => {

    /**
     * Default implementation. Handles the change action triggered by the FilterGridAction component.
     *
     * @param {any} filterAction - The selected filter button/buttons model value, which can be a single value or an array
     *                            if multiple buttons are selected.
     */
    function onFilterGridActionChanged(filterAction: FilterGridActionItemValue | FilterGridActionItemValue[]) {
      const { filterKey, gridWrapperRef } = options;
      gridWrapperRef.value?.gridApi.getColumnFilterInstance(filterKey).then((filterComponent) => {
        if (filterComponent) {
          if (filterAction) {
            const modelValues = Array.isArray(filterAction) ? filterAction : [filterAction];
            filterComponent.setModel({ values: modelValues });
          } else {
            filterComponent.setModel(null);
          }
  
          gridWrapperRef.value?.gridApi.onFilterChanged();
        }
      });
    }
      
      /**
       * Default implementation. Handles the filter model change from AgGrid. 
       * Updates the state of the selected filter buttons inside FilterGridAction component.
       */
      function onFilterGridModelChanged(e: FilterChangedEvent) {
        const { filterKey, filterGridActionRef } = options;
        if(e.source !== 'api') {
          const filterModel = e.api.getFilterModel()[filterKey];

          filterGridActionRef.value?.setFilterModel(filterModel ? filterModel.values : undefined);
        }
      }

    return {
        onFilterGridActionChanged,
        onFilterGridModelChanged
    };
};