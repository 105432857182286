﻿import { IocContainer, IocSymbols, onActivationLifecycle, onDeactivationLifecycle } from "@masta/gantt2/core";
import { GanttTimelineUnit } from "@masta/generated-model";
import { ChronoUnit } from "@js-joda/core";
import { IResourcesGanttOptions } from "@/components/Gantt/ResourcesGantt/ResourcesGanttLoader";
import { StatusColorHelper } from "@/components/Gantt/ResourcesGantt/Renderers";
import { CustomNavigationScheme } from "@/components/Gantt/Common/CustomNavigationScheme";

export const name = "CommonFrontModule";

export function getChronoUnitFromTimelineUnit(timelineUnit: GanttTimelineUnit) {
  switch (timelineUnit) {
    case 0:
      return ChronoUnit.MONTHS.toString().toUpperCase();
    case 1:
      return ChronoUnit.DAYS.toString().toUpperCase();
    case 2:
      return ChronoUnit.HOURS.toString().toUpperCase();
    case 3:
      return ChronoUnit.MINUTES.toString().toUpperCase();
    default:
      return ChronoUnit.DAYS.toString().toUpperCase();
  }
}

export async function load(container: IocContainer, options: IResourcesGanttOptions) {
  container.unbind(IocSymbols.RowFactorySymbol);

  container.unbind(IocSymbols.NavigationScheme);
  container.bind<CustomNavigationScheme>(IocSymbols.NavigationScheme).to(CustomNavigationScheme).inSingletonScope().onActivation(onActivationLifecycle);

  // container.bind<ILinesManager<any>>(IocSymbols.LinesManagerClass).toConstructor(QuantityBasedLineManager).whenTargetNamed("QuantityBasedLineManager");

  options.constsToRegisterInIoc.forEach((c) => {
    container.bind(c.key).toConstantValue(c.value);
  });

  container.bind<StatusColorHelper>(StatusColorHelper).to(StatusColorHelper).inSingletonScope();
  container.onActivation(StatusColorHelper, onActivationLifecycle);
  container.onDeactivation(StatusColorHelper, onDeactivationLifecycle);
}
