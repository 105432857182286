export interface LcaEcoefficiencyDto {
  resourceId: string;
  gwp20?: number;
  gwp100?: number;
  gwp500?: number;
}

export default {
  async getLca(resourceId: string) {
    //return ApiClient.get(`/KyklosLca/${resourceId}`);
    return generateMockResponse(resourceId);
  }
};

// Mock API response generator
function generateMockResponse(resourceId: string): LcaEcoefficiencyDto {
  const generateRandomFraction = () => parseFloat((Math.random() * 1).toFixed(7));

  const lcaData: LcaEcoefficiencyDto = {
    resourceId: resourceId,
    gwp20: generateRandomFraction(),
    gwp100: generateRandomFraction(),
    gwp500: generateRandomFraction()
  };

  return { data: lcaData };
}
