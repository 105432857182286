type TranslateEditorEntryFn = (value: any) => string;

export function getEnumTitleValuePairs<T extends Record<string, any>>(enumType: T, translateFn: TranslateEditorEntryFn): { title: string; value: any }[] {
  return Object.keys(enumType)
    .map((x) => enumType[x])
    .filter((x) => typeof x === "number")
    .map((x) => ({
      title: translateFn(x),
      value: x
    }));
}

export function getEnumValuesExceptProvided<T extends Record<string, any>>(enumType: T, ...values: T[keyof T][]): T[keyof T][] {
  return Object.keys(enumType)
    .map((x) => enumType[x])
    .filter((x) => typeof x === "number")
    .filter((x) => !values.includes(x));
}
