import { GridApi, GridReadyEvent, PaginationChangedEvent, RowDataUpdatedEvent, RowDoubleClickedEvent, SelectionChangedEvent } from "ag-grid-community";
import { tryOnBeforeUnmount } from "@vueuse/core";
import { AgGridFilterModel } from "@masta/generated-model";
import { getSelectedRows } from "@/components/Grid/UseGridSelection";

export interface IReferenceGridProps {
  $emits: {
    (event: "rowSelected", data: any): void;
    (event: "rowDoubleClicked", data: any): void;
  };
  isRowSelected: (data: any) => boolean;
  multiselect?: boolean;
  cqlFilter?: (() => string) | null | undefined;
  agGridFilterModel?: (() => AgGridFilterModel) | null | undefined | any;
}

export const useReferenceGrid = (options: IReferenceGridProps) => {
  let gridApi: GridApi = null!;
  const { $emits, isRowSelected, multiselect, cqlFilter, agGridFilterModel } = options;

  function onGridReady(event: GridReadyEvent) {
    gridApi = event.api;
    (event.api as any).gos.gridOptions.context = {
      ...event.context,
      cqlFilter,
      agGridFilterModel
    };

    if (typeof agGridFilterModel === "function") {
      const filterModel = agGridFilterModel();
      if (filterModel) {
        gridApi.setFilterModel(filterModel);
      }
    }

    gridApi.addEventListener("selectionChanged", onSelectionChanged);
    gridApi.addEventListener("rowDoubleClicked", onRowDoubleClicked);
    gridApi.addEventListener("rowDataUpdated", onDataUpdated);
    gridApi.addEventListener("paginationChanged", onDataUpdated);
  }

  tryOnBeforeUnmount(() => {
    if (!gridApi) return;
    gridApi.removeEventListener("selectionChanged", onSelectionChanged);
    gridApi.removeEventListener("rowDoubleClicked", onRowDoubleClicked);
    gridApi.removeEventListener("rowDataUpdated", onDataUpdated);
    gridApi.removeEventListener("paginationChanged", onDataUpdated);
  });

  function onRowDoubleClicked(event: RowDoubleClickedEvent) {
    $emits("rowDoubleClicked", event.data);
  }

  function onSelectionChanged(event: SelectionChangedEvent) {
    const rows = getSelectedRows(event.api);
    if (rows && (rows.length === 1 || (rows.length >= 1 && multiselect))) {
      $emits("rowSelected", multiselect ? rows : rows[0]);
    } else {
      $emits("rowSelected", null);
    }
  }

  function onDataUpdated(event: RowDataUpdatedEvent | PaginationChangedEvent) {
    event.api.forEachNode((n) => {
      if (n.data && isRowSelected(n.data)) {
        n.setSelected(true);
        if (n.rowIndex) {
          event.api.ensureIndexVisible(n.rowIndex, "bottom");
        }
      }
    });
  }

  return {
    onGridReady
  };
};
