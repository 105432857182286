﻿import ApiService from "@/services/api";
import { ServerSideDataSource } from "@/components/Grid/ServerSideDataSource";
import { AgGridRequestBase, AssetDto, IAgGridPaginationResponse } from "@masta/generated-model";
import { IServerSideGetRowsParams } from "ag-grid-enterprise";
import { Store } from "pinia";
import { State as ScenarioState, useScenariosStore } from "@/store/ScenariosStore";

export class AssetServerSideDataSource extends ServerSideDataSource<AssetDto> {
  private _scenarioStore: Store<string, ScenarioState>;

  constructor(id: string) {
    super(id);
    this._scenarioStore = useScenariosStore();
  }

  async create(entity: AssetDto): Promise<string | undefined> {
    const request = this.transformForCreate(entity);
    const scenario = this._scenarioStore.selectedScenario;
    request.scenarioId = scenario?.id ?? "";
    const response = await ApiService.assets.create(request);
    return response.data;
  }

  async getAll(params: IServerSideGetRowsParams): Promise<IAgGridPaginationResponse<AssetDto>> {
    const groupKeys = params.request.groupKeys;
    const parentId = groupKeys[groupKeys.length - 1];

    const serverRequest = this.constructServerRequest(params);
    this.modifiedFilterModel(serverRequest);
    this.modifiedSortModel(serverRequest);

    const { data } = await ApiService.assets.getPaginated({
      ...serverRequest,
      scenarioId: this._scenarioStore.selectedScenario?.id ?? "",
      onlyReleasedModelInstances: false,
      groupId: parentId
    });
    return data;
  }

  async getSingle(id: string): Promise<AssetDto | undefined> {
    const scenario = this._scenarioStore.selectedScenario;
    if (!scenario) return;
    const response = await ApiService.assets.getSingle(id, scenario.id);
    return response.data;
  }

  async remove(entity: AssetDto): Promise<void> {
    await ApiService.assets.remove(entity.id, this._scenarioStore.selectedScenario?.id);
  }

  async update(entity: AssetDto): Promise<string | undefined> {
    const response = await ApiService.assets.update(this.transformForUpdate(entity));
    return response.data;
  }

  protected transformForCreate(listEntity: AssetDto): AssetDto {
    return listEntity;
  }

  protected transformForUpdate(listEntity: AssetDto): AssetDto {
    return listEntity;
  }

  protected modifiedFilterModel(serverRequest: AgGridRequestBase) {
    const filterModel = serverRequest.filterModel;

    if (filterModel && "ag-Grid-AutoColumn" in filterModel) {
      // rewrite ag-Grid-AutoColumn to the businessId to the filterModel
      filterModel["businessId"] = filterModel["ag-Grid-AutoColumn"];
    }
  }

  protected modifiedSortModel(serverRequest: AgGridRequestBase) {
    const sortModel = serverRequest.sortModel;

    if (sortModel) {
      sortModel.forEach((sortModelItem) => {
        if (sortModelItem.colId === "ag-Grid-AutoColumn") {
          // rewrite ag-Grid-AutoColumn to the businessId
          sortModelItem.colId = "businessId";
        }
      });
    }
  }
}
