import { inject, injectable, optional } from "inversify";
import { ActivityTooltipRenderer } from "@masta/gantt2/gantt";
import { GanttSettings } from "@masta/gantt2/core";
import { GanttNoteDto } from "@masta/generated-model";
import { AppIocSymbols } from "@/components/Gantt/ResourcesGantt/AppIocSymbols";
import type { DateFormatter } from "@masta/shared";

@injectable()
export class NoteActivityTooltipRenderer extends ActivityTooltipRenderer {
  constructor(
    @inject(GanttSettings) protected readonly _settings: GanttSettings,
    @inject(AppIocSymbols.$dateTimeFormatter) @optional() protected readonly $dateTimeFormatter?: DateFormatter
  ) {
    super(NoteActivityTooltipRenderer.name, "default-activity-tooltip note-tooltip");
  }

  async doDrawFromBatch(): Promise<void> {
    await super.doDrawFromBatch();
    if (this.visible) {
      const note = this._activity.activity as unknown as GanttNoteDto;
      const author = `${note.author && note.author != "" ? note.author : ""}`;
      const issueDate = `${note.issueDate && note.issueDate != "" ? this.$dateTimeFormatter!(note.issueDate) : ""}`;
      const text = `${note.text && note.text != "" ? note.text : ""}`;
      const name = `${note.name && note.name != "" ? note.name : ""}`;

      this.element.innerHTML = `
<div class="note">
  <div class="title">${name}</div>
  <div class="text"">${text}</div>
  <div class="info">${author} | ${issueDate}</div>
</div>
      `;
      this.element.style.setProperty("--note-tooltip-color", note.color);
    } else {
      this.element.innerHTML = "";
    }
  }
}
