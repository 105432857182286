import { useRouter } from "vue-router";
import { storeToRefs } from "pinia";
import { useAuthStore } from "@/store/AuthStore";
import { useScenariosStore } from "@/store/ScenariosStore";

export const useContextMaterialGanttGridAction = () => {
  const $router = useRouter();
  const scenarioStore = useScenariosStore();

  return async (materialId: string) => {
    await $router.push({
      name: "ContextMaterialGantt",
      query: {
        materialId: materialId
      },
      params: {
        scenarioId: scenarioStore.scenarioBusinessId
      }
    });
  };
};
