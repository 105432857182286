import { GridState, GridStateActions } from "@/store/GridRegistryState";
import { tryOnBeforeUnmount } from "@vueuse/core";
import { GridApi, GridReadyEvent, RowDoubleClickedEvent } from "ag-grid-community";
import { Store, storeToRefs } from "pinia";

export const useGridRowDoubleClicked = (
    gridWrapperProps: {
      editBtn?: boolean;
    },
    crudActions: { 
      onEditAction: () => void 
    }, 
    gridStore: Store<string, GridState, any, GridStateActions>
) => {
  let gridApi: GridApi = null!;

  function onGridReady(event: GridReadyEvent) {
    gridApi = event.api;
    if (gridWrapperProps.editBtn) {
      gridApi.addEventListener("rowDoubleClicked", onRowDoubleClicked);
    }
  }

  tryOnBeforeUnmount(() => {
    if (gridApi) {
      if (gridApi.isDestroyed()) return;
      gridApi.removeEventListener("rowDoubleClicked", onRowDoubleClicked);
    }
  });

  function onRowDoubleClicked(event: RowDoubleClickedEvent) {
    if (!gridStore.isEditing) {
      crudActions.onEditAction();
    }
  }

  return {
    onGridReady
  };
};


