import { AgEvent, Column, ColumnApi, GridApi, GridReadyEvent } from "ag-grid-community";
import { onBeforeUnmount, ref } from "vue";

export interface IInvalidColumnScrollEvent extends AgEvent {
  invalidColumns: Column[];
}

export const useInvalidColumnScroll = () => {
  const gridApi = ref<GridApi | null>(null);
  const columnApi = ref<ColumnApi | null>(null);

  function onGridReady(params: GridReadyEvent) {
    gridApi.value = params.api;
    columnApi.value = params.columnApi;

    gridApi.value.addEventListener("invalidColumnScroll", onScrollToInvalidColumn);
  }

  onBeforeUnmount(() => {
    gridApi.value?.removeEventListener("invalidColumnScroll", onScrollToInvalidColumn);
  });

  function onScrollToInvalidColumn(event: IInvalidColumnScrollEvent) {
    const { invalidColumns } = event;
    if (invalidColumns && invalidColumns.length > 0) {
      const firstInvalidColumn = invalidColumns[0];
      gridApi.value?.ensureColumnVisible(firstInvalidColumn);
    }
  }

  return {
    onGridReady
  };
};

