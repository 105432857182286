<script setup>
// eslint-disable-next-line no-undef
import { computed, onBeforeMount, ref } from "vue";
import SettingsContextService from "@/services/settings-context.service";

const componentProps = defineProps({
  params: {
    type: Object,
    required: true
  }
});
const value = ref({
  name: "Global",
  value: 100
});

const contextIcon = computed(() => {
  switch (value.value) {
    case 100:
      return "mdi-earth";
    case 200:
      return "mdi-domain";
    case 300:
      return "mdi-account";
    default:
      return "mdi-earth";
  }
});

onBeforeMount(() => {
  if (componentProps.params.value && componentProps.params.value.value) {
    value.value = SettingsContextService.getContextByValue(componentProps.params.value.value);
  } else if (!isNaN(+componentProps.params.value)) {
    value.value = SettingsContextService.getContextByValue(+componentProps.params.value);
  }
});
</script>
<template>
  <div class="d-flex" style="height: 100%">
    {{ value.name }}
    <!--    <v-tooltip bottom>-->
    <!--      <template #activator="{ props }">-->
    <!--        <v-icon-->
    <!--          v-bind="props"-->
    <!--          class="pr-2"-->
    <!--        >-->
    <!--          {{ contextIcon }}-->
    <!--        </v-icon>-->
    <!--        {{ value.name }}-->
    <!--      </template>-->
    <!--      <span>Default context: {{ value.name }}</span>-->
    <!--    </v-tooltip>-->
  </div>
</template>

<!--<script>-->
<!--import SettingsContextService from "@/services/settings-context.service";-->
<!--export default {-->
<!--  name: "SettingsContextCellRenderer",-->
<!--  data() {-->
<!--    return {-->
<!--      value: { name: "Global", value: 100 },-->
<!--    };-->
<!--  },-->
<!--  computed: {-->
<!--    contextIcon() {-->
<!--      switch (this.value.value) {-->
<!--        case 100:-->
<!--          return "mdi-earth";-->
<!--        case 200:-->
<!--          return "mdi-domain";-->
<!--        case 300:-->
<!--          return "mdi-account";-->
<!--        default:-->
<!--          return "mdi-earth";-->
<!--      }-->
<!--    },-->
<!--  },-->
<!--  beforeMount() {-->
<!--    this.value = SettingsContextService.getContextByValue(+this.params.value);-->
<!--  },-->
<!--};-->
<!--</script>-->

<style lang="scss" scoped></style>
