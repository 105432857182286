import { inject } from "inversify";
import { fromEvent } from "rxjs";
import { Promise } from "cypress/types/cy-bluebird";
import { LayerCanvas, RowContainer } from "@masta/gantt2/gantt";
import { GanttEvents, GanttSettings, GraphicsMousePositionEvent, IocContainer, provideSingleton, SettingKey, TimelineManager } from "@masta/gantt2/core";
import { GanttTaskConnection } from "@masta/generated-model";
import { Instant } from "@js-joda/core";

export type ActivityConnector = GanttTaskConnection & {
  startTaskStartTime: number;
  startTaskEndTime: number;
  startTaskResourceId?: string;
  startTaskRowContainer: RowContainer<any>;
  endTaskStartTime: number;
  endTaskEndTime: number;
  endTaskResourceId?: string;
  endTaskRowContainer: RowContainer<any>;
}

@provideSingleton(ActivityConnectorsLayer)
export class ActivityConnectorsLayer extends LayerCanvas {

  private _connectors: ActivityConnector[] = [];

  constructor(
    @inject(TimelineManager) timelineManager: TimelineManager,
    @inject(GanttEvents) ganttEvents: GanttEvents,
    @inject(IocContainer) private _iocContainer: IocContainer,
    @inject(GanttSettings) private _ganttSettings: GanttSettings
  ) {
    super(timelineManager, ganttEvents, ActivityConnectorsLayer.name, "help-tooltip-layer");
    this.visible = false;
  }

  async beforeInitialize() {
    return super.beforeInitialize();
  }

  async afterInitialize() {
    await super.afterInitialize();
  }

  set connectors(value: ActivityConnector[]) {
    this._connectors = value;
    this.batchDraw();
  }

  async doDrawFromBatch() {
    this.clear();
    if (!this.visible) return;

    const rectangleSize = 10;
    const rectanglePaddingBottom = 5 + rectangleSize / 2;

    for (const connection of this._connectors) {
      const startDate = Instant.parse(connection.startDate);
      const startDateTime = startDate.toEpochMilli();
      const startDateX = Math.round(this._timelineManager.calculateLocationForTimeMillis(startDateTime));
      const endDate = Instant.parse(connection.endDate);
      const endDateTime = endDate.toEpochMilli();
      const endDateX = Math.round(this._timelineManager.calculateLocationForTimeMillis(endDateTime));

      const padding = rectangleSize + rectanglePaddingBottom;

      const startY = connection.startTaskRowContainer.row.height + connection.startTaskRowContainer.element.offsetTop;
      const endY = connection.endTaskRowContainer.row.height + connection.endTaskRowContainer.element.offsetTop;

      this.context.fillStyle = this._connectors.indexOf(connection) === 0 ? "rgba(0,154,0,0.89)" : "rgba(191,255,0,0.89)";
      this.context.fillRect(startDateX - rectangleSize, startY - padding, rectangleSize, rectangleSize);
      this.context.fillStyle = this._connectors.indexOf(connection) === this._connectors.length - 1 ? "rgba(222,0,65,0.89)" : "rgba(191,255,0,0.89)";
      this.context.fillRect(endDateX, endY - padding, rectangleSize, rectangleSize);

      this.context.lineWidth = 2;
      this.context.strokeStyle = "rgba(161,0,255,0.89)";
      this.context.beginPath();
      this.context.moveTo(startDateX - rectangleSize / 2, startY - rectanglePaddingBottom);
      this.context.lineTo(startDateX - rectangleSize / 2, startY);
      this.context.lineTo(endDateX - rectangleSize, startY);
      this.context.lineTo(endDateX - rectangleSize, endY - rectanglePaddingBottom - rectangleSize / 2);
      this.context.lineTo(endDateX, endY - rectanglePaddingBottom - rectangleSize / 2);
      this.context.stroke();
      this.context.closePath();
    }
  }

  drawCanvasArrow(fromX: number, fromY: number, toX: number, toY: number) {
    const headlen = 10; // length of head in pixels
    const minWidth = 40; // minimum width for the arrow when fromX and toX are equal

    // Ensure a minimum width if fromX and toX are equal
    let dx = toX - fromX;
    if (dx === 0) {
      dx = minWidth;
      // const newX = fromX + (fromX < toX ? minWidth : -minWidth);
    }
    if (dx < 0) {
      dx = -dx;
    }

    const centerX = fromX + dx / 2;

    // Draw the arrow lines
    this.context.beginPath();
    this.context.moveTo(fromX, fromY);

    // Construct the path through the center
    this.context.lineTo(centerX, fromY);
    this.context.lineTo(centerX, toY);
    this.context.lineTo(toX, toY);

    this.context.stroke();

    if (Math.abs(toX - centerX) < headlen) {
      // The arrowhead is too close to the end of the line, so don't draw it
      return;
    }

    // Determine the direction of the arrowhead
    const isReversed = fromX > toX || (fromX === toX && fromY !== toY);

    // Draw the horizontal arrowhead
    this.context.beginPath();
    if (isReversed) {
      this.context.moveTo(toX, toY);
      this.context.lineTo(toX + headlen, toY - headlen / 2);
      this.context.moveTo(toX, toY);
      this.context.lineTo(toX + headlen, toY + headlen / 2);
    } else {
      this.context.moveTo(toX, toY);
      this.context.lineTo(toX - headlen, toY - headlen / 2);
      this.context.moveTo(toX, toY);
      this.context.lineTo(toX - headlen, toY + headlen / 2);
    }
    this.context.stroke();
  }


}
