// SchedulingOperationGhostActivity
import { MutableActivityBase } from "@masta/gantt2/core";
import { Instant } from "@js-joda/core";
import type { DateFormatter } from "@masta/shared";
import { SchedulingResponseStatus } from "@masta/generated-model";
import { IDraggableTaskDto } from "@/components/Gantt/ResourcesGantt/DragAndDrop/IDraggableTaskDto";

export interface SchedulingOperationGhostActivityUserObject {
  draggableTaskDto: IDraggableTaskDto;
  status?: SchedulingResponseStatus;
  startTimeFormatted?: string;
  endTimeFormatted?: string;
}

export class SchedulingOperationGhostActivity extends MutableActivityBase<SchedulingOperationGhostActivityUserObject> {

  constructor(businessKey: string, name: string, dropTime: Instant, endTime: Instant, data: SchedulingOperationGhostActivityUserObject, private _dateFormatter: DateFormatter) {
    super(businessKey, name, dropTime, endTime);
    this._userObject = data;
    this._userObject.startTimeFormatted = this._dateFormatter(this.startTime.toJSON());
    this._userObject.endTimeFormatted = this._dateFormatter(this.endTime);
  }
}
