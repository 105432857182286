import ApiService from "@/services/api";
import { ServerSideDataSource } from "@/components/Grid/ServerSideDataSource";
import { CalendarDto, CreateCalendarResourceCommand, GetAvailabilityRulesPaginatedQuery, IAgGridPaginationResponse, UpdateCalendarResourceCommand } from "@masta/generated-model";
import { IServerSideGetRowsParams } from "ag-grid-community";
import { Store } from "pinia";
import { State as ScenarioState, useScenariosStore } from "@/store/ScenariosStore";
import { GUID_EMPTY } from "@/components/ValueCellEditor/CommonFormatters";

type CreateCalendarCommandType = CreateCalendarResourceCommand;
type UpdateCalendarCommandType = UpdateCalendarResourceCommand;

export class CalendarServerSideDataSource extends ServerSideDataSource<CalendarDto, CalendarDto, CreateCalendarCommandType, UpdateCalendarCommandType, string> {
  private _scenarioStore: Store<string, ScenarioState>;

  constructor(id: string) {
    super(id);
    this._scenarioStore = useScenariosStore();
  }

  async getAll(params: IServerSideGetRowsParams): Promise<IAgGridPaginationResponse<CalendarDto>> {
    const scenarioId = this._scenarioStore.selectedScenario?.id;
    if (!scenarioId) return { results: [], count: 0 } as IAgGridPaginationResponse<CalendarDto>;

    const request = {
      ...this.constructServerRequest(params),
      scenarioId: scenarioId
    } as GetAvailabilityRulesPaginatedQuery;
    const response = await ApiService.calendars.getPaginated(request);
    return response.data as IAgGridPaginationResponse<CalendarDto>;
  }

  async getSingle(id: string): Promise<CalendarDto | undefined> {
    const scenarioId = this._scenarioStore.selectedScenario?.id;
    if (!scenarioId) return;
    const response = await ApiService.calendars.get(id, scenarioId);
    return response.data;
  }

  async create(entity: CalendarDto): Promise<string> {
    const request = this.transformForCreate(entity);
    const scenario = this._scenarioStore.selectedScenario;
    request.scenarioId = scenario?.id ?? "";
    const response = await ApiService.calendars.create(request);
    return response.data.resourceId;
  }

  async update(entity: CalendarDto): Promise<string> {
    const request = this.transformForUpdate(entity);
    await ApiService.calendars.update(request);
    return entity.id;
  }

  async remove(entity: CalendarDto): Promise<void> {
    await ApiService.calendars.remove(entity.id, entity.scenarioId);
    return;
  }

  protected transformForCreate(entity: CalendarDto): CreateCalendarCommandType {
    return {
      scenarioId: entity.scenarioId ?? this._scenarioStore.selectedScenario?.id ?? GUID_EMPTY,
      businessId: entity.businessId,
      name: entity.name,
      color: entity.color,
      tags: entity.tags,
      isBase: entity.isBase
    } as CreateCalendarResourceCommand;
  }

  protected transformForUpdate(entity: CalendarDto): UpdateCalendarCommandType {
    return {
      id: entity.id,
      scenarioId: entity.scenarioId ?? this._scenarioStore.selectedScenario?.id ?? GUID_EMPTY,
      businessId: entity.businessId,
      name: entity.name,
      color: entity.color,
      tags: entity.tags ?? []
    } as UpdateCalendarResourceCommand;
  }
}
