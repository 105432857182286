import FilterService from "@/services/filter.service";
import { FilterDto } from "@masta/generated-model";
import { defineStore } from "pinia";
import { useErrorsStore } from "@/store/ErrorsStore";

interface State {
  resourceFilters: FilterDto[];
}

export const useFiltersStore = defineStore("filters", {
  state: (): State => ({
    resourceFilters: []
  }),
  actions: {
    async fetchResourceFilters(refresh = false) {
      if (!refresh && this.resourceFilters) {
        return;
      }
      try {
        const filters = await FilterService.getResourceFilters();
        this.resourceFilters = filters ?? [];
      } catch (e) {
        const errorsStore = useErrorsStore();
        errorsStore.handleError(e);
        throw e;
      }
    },
    async addResourceFilter(filter: FilterDto) {
      try {
        filter.cql = FilterService.translate2Cql(filter.model);
        this.resourceFilters = this.resourceFilters.concat(filter);
        await FilterService.createResourceFilter(filter);
        await this.fetchResourceFilters();
      } catch (e) {
        const errorsStore = useErrorsStore();
        errorsStore.handleError(e);
        throw e;
      }
    }
  }
});
