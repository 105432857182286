<script setup lang="ts">
import CustomerOrderLineDetailsGrid from "@/components/CustomerOrders/CustomerOrderLineDetailsGrid.vue";
import { CustomerOrderLineDetailsDto, ModelInstanceIdDto } from "@masta/generated-model";
import { onMounted, onUnmounted, ref, watch } from "vue";
import CloseCardButton from "@/components/CloseCardButton.vue";
import { $t } from "../../i18n";

const props = defineProps<{
  opened: boolean;
}>();

const emit = defineEmits<{
  (e: "confirm", ids: CustomerOrderLineDetailsDto[]): void;
  (e: "cancel"): void;
}>();

const selectedOrderLines = ref<CustomerOrderLineDetailsDto[]>([]);

onMounted(() => {
  document.addEventListener("keydown", escapeHandler);
});

onUnmounted(() => {
  document.removeEventListener("keydown", escapeHandler);
});

watch(
  () => props.opened,
  async (newValue) => {
    if (newValue) {
      await loadData();
    }
  },
  {
    immediate: true
  }
);

async function loadData() {}

function confirm() {
  emit("confirm", selectedOrderLines.value);
}

function cancel() {
  emit("cancel");
}

function escapeHandler(e: KeyboardEvent) {
  if (e.key === "Escape") {
    emit("cancel");
  }
}

function onSelectionChanged(selection: CustomerOrderLineDetailsDto[]) {
  selectedOrderLines.value = selection;
}
</script>

<template>
  <v-dialog :model-value="opened" style="z-index: 2000" :persistent="true" class="assignment-editor-dialog" @click:outside="cancel">
    <v-card width="100%" height="90vh" class="assignment-editor-card">
      <close-card-button @click="cancel"></close-card-button>
      <v-card-title class="pa-0">
        <div class="text-h4 pb-5">
          {{ $t("orderLineGroup-assignmentEditor-modelInstanceAssignment-title", { $: "Select order lines for grouping" }) }}
        </div>
      </v-card-title>
      <v-card-text class="d-flex flex-column flex-grow-1 px-0">
        <v-row class="flex-nowrap flex-grow-1">
          <div class="left-col d-flex flex-column flex-grow-1 flex-shrink-1">
            <customer-order-line-details-grid only-ungrouped @selection-changed="onSelectionChanged"></customer-order-line-details-grid>
          </div>
        </v-row>
      </v-card-text>
      <v-card-actions class="pa-0">
        <div class="left-col pl-0">
          <v-btn variant="elevated" color="secondary" class="w-100" @click="cancel">
            {{ $t("orderLineGroup-assignmentEditor-cancel-action", { $: "Cancel" }) }}
          </v-btn>
        </div>
        <div class="middle-col"></div>
        <div class="right-col pr-0">
          <v-btn variant="elevated" color="primary" class="w-100" @click="confirm">
            {{ $t("orderLineGroup-assignmentEditor-confirm-action", { $: "Group Selected Lines" }) }}
          </v-btn>
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style scoped lang="scss"></style>
