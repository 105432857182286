import { injectable } from "inversify";
import { BehaviorSubject, distinctUntilChanged, map, Observable } from "rxjs";
import { SettingKey } from "./SettingKey";

export interface IGanttSetting<TValue = any> {
  key: SettingKey | string;
  value: TValue;
}

@injectable()
export class GanttSettings {
  private _data: IGanttSetting[] = [];
  private _settings$$ = new BehaviorSubject<IGanttSetting[]>(this._data);

  get settings$(): Observable<IGanttSetting[]> {
    return this._settings$$.asObservable();
  }

  get settings(): IGanttSetting[] {
    return [...this._data];
  }

  setSetting<TValue = any>(setting: IGanttSetting<TValue>): void {
    const index = this._data.findIndex((x) => x.key === setting.key);
    if (index >= 0) {
      this._data[index] = setting;
    } else {
      this._data.push(setting);
    }
    this._settings$$.next(this._data);
  }

  setSettings(settings: IGanttSetting[]): void {
    settings.forEach((setting) => {
      const index = this._data.findIndex((x) => x.key === setting.key);
      if (index >= 0) {
        this._data[index] = setting;
      } else {
        this._data.push(setting);
      }
    });
    this._settings$$.next(this._data);
  }

  getSetting$<TValue>(key: SettingKey | string, defaultValue: TValue | undefined = undefined): Observable<TValue | undefined> {
    return this.settings$.pipe(
      map((arr) => {
        const setting = arr.find((s) => s.key === key);
        return setting ? setting.value : defaultValue;
      }),
      distinctUntilChanged<TValue | undefined>()
    );
  }

  getSetting<TValue = any>(key: SettingKey | string): TValue | undefined {
    return this._data.find((x) => x.key === key)?.value;
  }
}
