import { Duration, Instant } from "@js-joda/core";
import { IOverlappingActivity } from "./IOverlappingActivity";

export class Overlap {
  private readonly _owner: OverlappingElement;
  private _isOverlapped: boolean = false;
  private _overlappingPosition: number | null;
  private _overlapping: OverlappingElement[] = [];
  private _overlappingIntervals: OverlapInterval[] = [];

  constructor(_activity: IOverlappingActivity) {
    this._owner = new OverlappingElement(_activity);
    this._overlapping.push(this._owner);
  }

  public issOverlapped() {
    return this._isOverlapped;
  }

  public setOverlappingIntervals(overlappingIntervals: OverlapInterval[]): void {
    this._overlappingIntervals = [...overlappingIntervals];
    this._isOverlapped = overlappingIntervals.some(interval => interval.level.isOverlapped());
  }

  public getOverlappedIntervals(): OverlapInterval[] {
    return [...this._overlappingIntervals];
  }

  public getOverlapPosition(): number {
    if (this._overlappingPosition === null) {
      this._overlappingPosition = this.calcOverlapIndexOfOwner();
    }
    return this._overlappingPosition;
  }

  public updateOverlaps(assignmentsByStart: Map<Instant, IOverlappingActivity>, assignmentsByStop: Map<Instant, IOverlappingActivity>, longestOperation: Duration): void {
    if (assignmentsByStart.size === 0) {
      return;
    }

    this.clearStaleAssignments();
    this.addAllOverlappingAssignments(assignmentsByStart, assignmentsByStop, longestOperation);

    // Sort overlapping assignments by start time
    this._overlapping.sort((a, b) => this.overlappingElementComparator(a, b));

    this.recomputeOverlappingIntervals();
    this._overlappingPosition = null;
  }

  public getMaxOverlapLevel(): number {
    return this._overlappingIntervals.reduce((max, interval) => Math.max(max, interval.level.level), 0);
  }

  protected overlappingElementComparator(a: OverlappingElement, b: OverlappingElement): number {
    const startTimeCompare = a.activity.startTime.compareTo(b.activity.startTime);

    if (startTimeCompare !== 0) {
      return startTimeCompare;
    }

    // If start times are equal, sort by duration
    const durationCompare = Duration.between(a.activity.startTime, a.activity.endTime).compareTo(Duration.between(b.activity.startTime, b.activity.endTime));

    if (durationCompare !== 0) {
      return durationCompare;
    }

    // If durations are equal, sort by activity name
    if (a.activity.name !== b.activity.name) {
      return a.activity.name.localeCompare(b.activity.name);
    }

    // If activity names are equal, sort by activity constructor name
    if (a.activity.constructor.name !== b.activity.constructor.name) {
      return a.activity.constructor.name.localeCompare(b.activity.constructor.name);
    }

    // If activity constructor names are equal, sort by activity id
    return a.activity.id.localeCompare(b.activity.id);
  }

  private calcOverlapIndexOfOwner(): number {
    const overlaps = this.getPrecedingOverlaps();
    if (overlaps.length === 0) {
      return 0;
    }

    const overlapPositions = this.getOverlapPositions(overlaps);

    let index = Math.min(...overlapPositions);
    if (index > 0) {
      return 0;
    }
    const maxIndex = Math.max(...overlapPositions);
    index++;

    const positionsSet = new Set(overlapPositions);
    while (index < maxIndex) {
      if (!positionsSet.has(index)) {
        return index;
      }
      index++;
    }
    return maxIndex + 1;
  }

  private getOverlapPositions(succeedingOverlaps: OverlappingElement[]): number[] {
    return succeedingOverlaps.map(o => o.activity.overlap.getOverlapPosition());
  }

  private getSucceedingOverlaps(): OverlappingElement[] {
    const ownerIndex = this._overlapping.indexOf(this._owner);
    return this._overlapping.slice(ownerIndex + 1);
  }

  private getPrecedingOverlaps(): OverlappingElement[] {
    const ownerIndex = this._overlapping.indexOf(this._owner);
    return this._overlapping.slice(0, ownerIndex);
  }

  private clearStaleAssignments(): void {
    this._overlapping = [];
    this._overlapping.push(this._owner);
  }

  private addAllOverlappingAssignments(assignmentsByStart: Map<Instant, IOverlappingActivity>, assignmentsByStop: Map<Instant, IOverlappingActivity>, longestOperation: Duration): void {
    if (this._owner.activity.startTime.compareTo(this._owner.activity.endTime) === 0) {
      return;
    }

    const from = this._owner.activity.startTime.minus(longestOperation);
    const to = this._owner.activity.endTime.plus(longestOperation);

    const preFilteredAssignmentsByStart = new Map(Array.from(assignmentsByStart.entries()).filter(([key]) => key.compareTo(from) >= 0 && key.compareTo(to) <= 0));
    const preFilteredAssignmentsByStop = new Map(Array.from(assignmentsByStop.entries()).filter(([key]) => key.compareTo(from) >= 0 && key.compareTo(to) <= 0));

    const overlappingAssignments: Map<string, OverlappingElement> = new Map();

    preFilteredAssignmentsByStart.forEach((value) => {
      if (this.isOverlapping(this._owner.activity, value) && value.id !== this._owner.activity.id) {
        overlappingAssignments.set(value.id, new OverlappingElement(value));
      }
    });

    preFilteredAssignmentsByStop.forEach((value) => {
      if (this.isOverlapping(this._owner.activity, value) && value.id !== this._owner.activity.id) {
        overlappingAssignments.set(value.id, new OverlappingElement(value));
      }
    });

    this._overlapping.push(...overlappingAssignments.values());
    this._isOverlapped = this._overlapping.length > 1;
  }

  private isOverlapping(first: IOverlappingActivity, second: IOverlappingActivity): boolean {
    return first.startTime.isBefore(second.endTime) && first.endTime.isAfter(second.startTime);
  }

  private isOverlappingWithOwner(assignment: IOverlappingActivity): boolean {
    return this.isOverlapping(this._owner.activity, assignment);
  }

  private recomputeOverlappingIntervals(): void {
    const sortedInstants: ValuedTimePoint[] = [];

    this._overlapping.forEach(element => {
      sortedInstants.push(new ValuedTimePoint(element.activity.startTime, true, 0));
      sortedInstants.push(new ValuedTimePoint(element.activity.endTime, false, 0));
    });

    sortedInstants.sort((a, b) => a.instant.compareTo(b.instant));

    this._overlappingIntervals = [];
    let level = 1;
    let value = sortedInstants.length > 0 ? sortedInstants[0].value : 0;

    for (let i = 1; i < sortedInstants.length; i++) {
      const currentInstant = sortedInstants[i];

      // check if there is already an overlap interval with the same start and end time and level
      const existingInterval = this._overlappingIntervals.find(interval => interval.start.equals(sortedInstants[i - 1].instant) && interval.end.equals(sortedInstants[i].instant) && interval.level.level === (level === 0 ? 1 : level));
      if (existingInterval) {
        value = existingInterval.value;
        continue;
      }

      this._overlappingIntervals.push(new OverlapInterval(sortedInstants[i - 1].instant, sortedInstants[i].instant, new OverlapLevel(level === 0 ? 1 : level), value));

      if (currentInstant.isStart) {
        level++;
        value += currentInstant.value;
      } else {
        level--;
        value -= currentInstant.value;
      }
    }
  }

  public static computeOverlappingIntervals(overlappingElements: OverlappingElement[]): OverlapInterval[] {
    const sortedInstants: ValuedTimePoint[] = [];

    overlappingElements.forEach(element => {
      sortedInstants.push(new ValuedTimePoint(element.activity.startTime, true, 0));
      sortedInstants.push(new ValuedTimePoint(element.activity.endTime, false, 0));
    });

    sortedInstants.sort((a, b) => a.instant.compareTo(b.instant));

    const overlappingIntervals: OverlapInterval[] = [];
    let level = 1;
    let value = sortedInstants.length > 0 ? sortedInstants[0].value : 0;

    for (let i = 1; i < sortedInstants.length; i++) {
      const currentInstant = sortedInstants[i];

      overlappingIntervals.push(new OverlapInterval(sortedInstants[i - 1].instant, sortedInstants[i].instant, new OverlapLevel(level === 0 ? 1 : level), value));

      if (currentInstant.isStart) {
        level++;
        value += currentInstant.value;
      } else {
        level--;
        value -= currentInstant.value;
      }
    }

    return overlappingIntervals;
  }
}

export class OverlappingElement {
  constructor(public readonly activity: IOverlappingActivity) {
  }
}

export class OverlapLevel {
  constructor(public readonly level: number) {
  }

  public isOverlapped(): boolean {
    return this.level > 1;
  }
}

export class OverlapInterval {
  constructor(public readonly start: Instant, public readonly end: Instant, public readonly level: OverlapLevel, public readonly value: number) {
  }
}

export class ValuedTimePoint {
  constructor(public readonly instant: Instant, public readonly isStart: boolean, public readonly value: number) {
  }
}
