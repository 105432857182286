import { DocumentDto } from "@masta/generated-model";

export enum MediaItemType {
  UploadAction,
  ImageFile,
  ModelFile
}

export interface DocumentTile {
  title: string;
  url: string;
  file?: () => Promise<File>;
  documentDto: DocumentDto | null;
  type: MediaItemType;
}

export type DocumentProvider = (contextName: string) => Promise<DocumentDto[]>;
