import type { IActivity, ILinesManager, IRowChartScaleProvider } from "../../Core";
import {
  ActivityBase,
  ActivityBounds,
  ActivityHelper,
  GanttEvents,
  IocSymbols,
  IRowContainer,
  Layer,
  Lifecycle,
  ObservableDataSet,
  requestAnimation,
  Row,
  RowMouseBtnPressedEvent
} from "../../Core";
import type { interfaces } from "inversify";
import { inject, injectable } from "inversify";
import { ILayerRenderer } from "./Layers";
import { animationFrameScheduler, BehaviorSubject, filter, skip, throttleTime, withLatestFrom } from "rxjs";
import { Instant } from "@js-joda/core";
import { CanvasRegistryEntry } from "../CanvasManager";

@injectable()
export class WebWorkerRowContainer<TResource extends Row<any, any, any>> extends Lifecycle implements IRowContainer {
  private _activityBounds$$ = new ObservableDataSet<ActivityBounds>();
  private _layerCanvasMap: Map<string, CanvasRegistryEntry & { rendererInstance: ILayerRenderer }> = new Map();
  private _rowCanvasMap: Map<string, CanvasRegistryEntry & { rendererInstance: ILayerRenderer }> = new Map();

  public readonly hoveredActivities$$ = new BehaviorSubject<string[]>([]);
  public readonly pressedActivities$$ = new BehaviorSubject<string[]>([]);
  public readonly selectedActivities$$ = new BehaviorSubject<string[]>([]);

  constructor(
    @inject(IocSymbols.RowContainerIocContainerSymbol) private _scopeIocContainer: interfaces.Container,
    @inject(Row<any, any, any>) private _row: Row<any, any, ActivityBase<IActivity>>,
    @inject(IocSymbols.LinesManagerSymbol) private _lineManager: ILinesManager<any>,
    @inject(IocSymbols.RowChartScaleProvider) private _rowChartScaleProvider: IRowChartScaleProvider,
    @inject(GanttEvents) private _ganttEvents: GanttEvents,
    @inject(IocSymbols.LayersSymbol) private _layers$$: BehaviorSubject<Layer[]>
  ) {
    super();
  }

  get activityBounds$$(): ObservableDataSet<ActivityBounds> {
    return this._activityBounds$$;
  }

  get activityBounds(): ActivityBounds[] {
    return Array.from(this._activityBounds$$.data.values());
  }

  set activityBounds(value: ActivityBounds[]) {
    this._activityBounds$$.clear();
    this._activityBounds$$.add(value);
  }

  get row() {
    return this._row;
  }

  async afterInitialize(): Promise<void> {
    console.debug("[Gantt] [WW] WebWorkerRowContainer initialized!", this._row.name);
    this._row.linesManager = this._lineManager;

    this.subscribe(
      this._row.repository.changeEvent$.subscribe(async (_) => {
        this._row.linesManager.clearCache();
        this._row.linesManager.layout();
      })
    );
    this.subscribe(
      this._row.height$.pipe(throttleTime(0, animationFrameScheduler)).subscribe(async () => {
        this._row.linesManager.clearCache();
        this._row.linesManager.layout();
      })
    );

    //
    //  Hovered activities
    //
    function arraysAreEqual(array1: string[], array2: string[]) {
      if (array1.length !== array2.length) {
        return false;
      }
      return array1.every((value, index) => value === array2[index]);
    }

    this.subscribe(
      this._ganttEvents.mousePositionEvent$
        .pipe(
          withLatestFrom(this._ganttEvents.rowHoverEvent$),
          filter(([_, rowHoverEvent]) => !rowHoverEvent || this._row.id === rowHoverEvent.rowId)
        )
        .subscribe(async ([mousePosEvent, rowHoverEvent]) => {
          if (!rowHoverEvent) {
            if (this.hoveredActivities$$.value.length > 0) {
              this.hoveredActivities$$.next([]);
              this._ganttEvents.onTooltipActivityEvent(null);
              this.drawRows({ offsetTop: 0, rowId: this._row.id });
            }
            return;
          }
          const relativeMousePosition = { x: mousePosEvent.x, y: mousePosEvent.y - rowHoverEvent.offsetTop };
          const bounds = this.getActivityBoundsForMousePosition(this._row.id, relativeMousePosition);
          const boundIds = bounds.map(x => x.id);
          if (!arraysAreEqual(boundIds, this.hoveredActivities$$.value)) {
            this.hoveredActivities$$.next(boundIds);
            if (boundIds.length === 0) {
              this._ganttEvents.onTooltipActivityEvent(null);
            }
            bounds.forEach(b => {
              const layer = this._layers$$.value.find(x => x.id === b.layerId)!;
              const activity = this._row.repository.getActivity(layer, b.activityId);
              if (activity) {
                // this._ganttEvents.onTooltipActivityEvent(createTooltipActivity(b, activity.toJSON(), activity.constructor.name));
              }
            });
            this.drawRows({ offsetTop: 0, rowId: this._row.id, boundIds });
          }
        })
    );
    // Dateline hover
    this.subscribe(
      this._ganttEvents.datelineHoverEvent$
        .subscribe((e) => {
          if (!e) {
            this.hoveredActivities$$.next([]);
            this.drawRows({ offsetTop: 0, rowId: this._row.id, boundIds: [] });
            return;
          }
          const start = Instant.ofEpochMilli(e.start);
          const end = Instant.ofEpochMilli(e.end);
          const boundIds = this.getActivityBoundsForTimeInterval(this._row.id, start, end).map(x => x.id);
          this.hoveredActivities$$.next(boundIds);
          this.drawRows({ offsetTop: 0, rowId: this._row.id, boundIds });
        })
    );

    //
    //  Selected activities
    //
    this.subscribe(
      this.pressedActivities$$.pipe(skip(1)).subscribe((pressedActivities) => {
        this.drawRows({ offsetTop: 0, rowId: this._row.id, boundIds: pressedActivities });
      })
    );
    this.subscribe(
      this.selectedActivities$$.pipe(skip(1)).subscribe((selectedActivities) => {
        this.drawRows({ offsetTop: 0, rowId: this._row.id, boundIds: selectedActivities });
      })
    );
    this.subscribe(
      this._ganttEvents.rowMousePressedEvent$
        .pipe(
          filter((e: RowMouseBtnPressedEvent | null) => !!e && e.rowId === this._row.id)
        )
        .subscribe((e) => {
          if (!e) return;
          const relativeMousePosition = { x: e.relX, y: e.relY };
          const bounds = this.getActivityBoundsForMousePosition(this._row.id, relativeMousePosition);
          // pressed
          if (bounds.length === 1 && e.osCtrlKey && !e.altKey && e.type === "pointerup") {
            const selectedActIds = this.selectedActivities$$.value;
            const toExclude = bounds.filter((b) => selectedActIds.includes(b.id)).map((b) => b.id);
            const selectedActivities = [...this.selectedActivities$$.value, ...bounds.map(x => x.id)].filter((x) => !toExclude.includes(x));
            this.selectedActivities$$.next(selectedActivities);
          }
          if (bounds.length === 1 && !e.altKey && e.type === "pointerdown") {
            this.pressedActivities$$.next([bounds[0].id]);
          } else {
            this.pressedActivities$$.next([]);
          }
        })
    );

    // redraw on scale change
    this.subscribe(
      this._rowChartScaleProvider.scaleValues$
        .subscribe(() => {
            this.drawLayers({ offsetTop: 0, rowId: this._row.id });
          }
        ));
  }

  async afterDestroy(): Promise<void> {
    await this._scopeIocContainer.unbindAllAsync();
    console.debug("[Gantt] [WW] WebWorkerRowContainer destroyed!", this._row.name);
  }

  async bindLayerCanvas(registryEntry: CanvasRegistryEntry) {
    this._layerCanvasMap.set(registryEntry.id, { ...registryEntry, rendererInstance: await this._scopeIocContainer.getAsync<ILayerRenderer>(registryEntry.renderer) });
  }

  async bindRowCanvas(registryEntry: CanvasRegistryEntry) {
    this._rowCanvasMap.set(registryEntry.id, { ...registryEntry, rendererInstance: await this._scopeIocContainer.getAsync<ILayerRenderer>(registryEntry.renderer) });
  }

  unbindLayerCanvas(canvasId: string): void {
    this._layerCanvasMap.delete(canvasId);
  }

  unbindRowCanvas(canvasId: string): void {
    this._rowCanvasMap.delete(canvasId);
  }

  drawLayer<TParams = any>(canvasId: string, ...params: TParams[]): void {
    const canvasEntry = this._layerCanvasMap.get(canvasId);
    if (!canvasEntry) {
      return;
    }
    requestAnimation({
      id: this._row.id,
      callback: () => {
        canvasEntry.rendererInstance.render(canvasEntry.canvas, canvasEntry.context, ...params);
      }
    });
  }

  drawRow<TParams = any>(canvasId: string, ...params: TParams[]): void {
    const canvasEntry = this._rowCanvasMap.get(canvasId);
    if (!canvasEntry) {
      return;
    }
    requestAnimation({
      id: this._row.id,
      callback: () => {
        canvasEntry.rendererInstance.render(canvasEntry.canvas, canvasEntry.context, ...params);
      }
    });
  }

  private drawRows<TParams = any>(...params: TParams[]) {
    for (const value of this._rowCanvasMap.values()) {
      this.drawRow(value.id, ...params);
    }
  }

  private drawLayers<TParams = any>(...params: TParams[]) {
    for (const value of this._layerCanvasMap.values()) {
      this.drawLayer(value.id, ...params);
    }
  }

  // drawRowContainer<TParams = any>(...params: TParams[]): void {
  //   if (!this._isBinded) {
  //     return;
  //   }
  //   console.log("\t\tdrawRowContainer", this._row.id, this._renderer);
  //
  //   requestAnimation({
  //     id: this._row.id,
  //     callback: () => {
  //       this._renderer.render(this._canvas, this._context, ...params);
  //     }
  //   });
  // }
  //
  // drawRowLayer<TParams = any>(id: string, canvas: OffscreenCanvas, context: OffscreenCanvasRenderingContext2D, rendererIdentifier: string, ...params: TParams[]): void {
  //   const _renderer = this._scopeIocContainer.get<ILayerRenderer<TParams>>(rendererIdentifier);
  //   requestAnimation({
  //     id: id,
  //     callback: () => {
  //       _renderer.render(canvas, context, ...params);
  //     }
  //   });
  // }

  onLassoBoundsSelection(bounds: ActivityBounds[]): void {
    this.selectedActivities$$.next([...bounds.filter(x => x.rowId === this._row.id).map(x => x.id)]);
  }

  private getActivityBoundsForMousePosition(rowId: string, { x, y }: { x: number; y: number }): Array<ActivityBounds> {
    const result = [];
    for (const bounds of this.activityBounds) {
      if (bounds.rowId === rowId && x >= bounds.x && x <= bounds.x + bounds.width && y >= bounds.y && y <= bounds.y + bounds.height) {
        result.push(bounds);
      }
    }
    return result;
  }

  private getActivityBoundsForTimeInterval(rowId: string, startTime: Instant, endTime: Instant): Array<ActivityBounds> {
    const result = [];
    for (const bounds of this.activityBounds) {
      if (bounds.rowId === rowId && ActivityHelper.instantIntersects(bounds.startTime, bounds.endTime, startTime, endTime)) {
        result.push(bounds);
      }
    }
    return result;
  }
}
