import ApiService from "@/services/api";
import { ServerSideDataSource } from "@/components/Grid/ServerSideDataSource";
import {
  AgreementDto,
  AgreementSubType,
  CreateAgreementCommand,
  CreateAgreementGroupCommand,
  CreateResourceCommand,
  GetAgreementByIdQuery,
  GetAgreementsByGroupIdQuery,
  IAgGridPaginationResponse,
  ResourceSubType,
  ResourceType,
  UpdateAgreementCommand,
  UpdateAgreementGroupCommand
} from "@masta/generated-model";
import { IServerSideGetRowsParams } from "ag-grid-community";
import { State as ScenarioState, useScenariosStore } from "@/store/ScenariosStore";
import { Store } from "pinia";

function asAgreementSubType(resourceSubType: ResourceSubType): AgreementSubType | undefined {
  return Object.keys(AgreementSubType)
    .map((x: string) => AgreementSubType[x as keyof typeof AgreementSubType])
    .find((x: AgreementSubType) => x.valueOf() === resourceSubType.valueOf());
}

type CreateCommandType = CreateAgreementCommand | CreateAgreementGroupCommand;
type UpdateCommandType = UpdateAgreementCommand | UpdateAgreementGroupCommand;

export class AgreementsServerSideDataSource extends ServerSideDataSource<AgreementDto, AgreementDto, CreateCommandType, UpdateCommandType, string> {
  private _scenarioStore: Store<string, ScenarioState>;

  constructor(id: string) {
    super(id);
    this._scenarioStore = useScenariosStore();
  }

  async create(entity: AgreementDto): Promise<string> {
    const request = this.transformForCreate(entity);
    const scenario = this._scenarioStore.selectedScenario;
    request.scenarioId = scenario?.id ?? "";
    const response = this.isGroup(entity)
      ? await ApiService.agreements.createGroup(request as CreateAgreementGroupCommand)
      : await ApiService.agreements.create(request as CreateAgreementCommand);
    return response.data.resourceId;
  }

  async getAll(params: IServerSideGetRowsParams): Promise<IAgGridPaginationResponse<AgreementDto>> {
    const groupKeys = params.request.groupKeys;
    const parentId = groupKeys[groupKeys.length - 1];
    const agreements = await this.getByGroupIdForSelectedScenario(parentId ?? undefined);
    return { count: agreements.length, results: agreements } as IAgGridPaginationResponse<AgreementDto>;
  }

  async getSingle(id: string): Promise<AgreementDto | undefined> {
    return await this.getByIdForSelectedScenario(id);
  }

  async update(entity: AgreementDto): Promise<string> {
    const request = this.transformForUpdate(entity);
    this.isGroup(entity) ? await ApiService.agreements.updateGroup(request as UpdateAgreementGroupCommand) : await ApiService.agreements.update(request as UpdateAgreementCommand);
    return entity.id;
  }

  async remove(entity: AgreementDto): Promise<void> {
    this.isGroup(entity) ? await ApiService.agreements.removeGroup(entity.id) : await ApiService.agreements.remove(entity.id);
  }

  protected transformForCreateResource(listEntity: AgreementDto): CreateResourceCommand {
    return {
      name: listEntity.name,
      businessId: listEntity.businessId,
      scenarioId: listEntity.scenarioId,
      subTypes: listEntity.subTypes?.filter((x) => !!x) ?? null,
      color: listEntity.color,
      tags: listEntity.tags
    } as CreateResourceCommand;
  }

  protected transformForCreate(listEntity: AgreementDto): CreateCommandType {
    if (this.isGroup(listEntity)) {
      return {
        ...this.transformForCreateResource(listEntity),
        parentId: listEntity.parentId
      } as CreateAgreementGroupCommand;
    } else {
      const subType = listEntity.subTypes ? listEntity.subTypes[0] : null;
      return {
        ...this.transformForCreateResource(listEntity),
        plannable: listEntity.plannable,
        parentId: listEntity.parentId,
        periodStart: listEntity.periodStart,
        periodEnd: listEntity.periodEnd,
        agreementType: subType ? asAgreementSubType(subType) : null,
        subTypes: listEntity.subTypes,
        constraintType: listEntity.constraintType,
        plannedEffort: listEntity.plannedEffort,
        periodAggregationUnit: listEntity.periodAggregationUnit,
        agreedFTE: listEntity.agreedFTE,
        remainingEffort: listEntity.remainingEffort,
        isActive: listEntity.isActive
      } as CreateAgreementCommand;
    }
  }

  protected isGroup(listEntity: AgreementDto) {
    return listEntity.type === ResourceType.AgreementGroup;
  }

  protected transformForUpdate(listEntity: AgreementDto): UpdateCommandType {
    return {
      ...this.transformForCreate(listEntity),
      id: listEntity.id
    } as UpdateAgreementCommand;
  }

  private async getByIdForSelectedScenario(id: string): Promise<AgreementDto | undefined> {
    const scenario = this._scenarioStore.selectedScenario;
    if (!scenario) {
      return;
    }
    const request = { scenarioId: scenario?.id, id } as GetAgreementByIdQuery;
    const response = await ApiService.agreements.getById(request);
    return response.data;
  }

  private async getByGroupIdForSelectedScenario(groupId: string | undefined = undefined): Promise<AgreementDto[]> {
    const scenario = this._scenarioStore.selectedScenario;
    if (!scenario) {
      return [];
    }
    const request = { scenarioId: scenario?.id, groupId } as GetAgreementsByGroupIdQuery;
    const response = await ApiService.agreements.getByGroupId(request);
    return response.data.agreements ?? [];
  }
}
