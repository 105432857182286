import { IocSymbols, Lifecycle } from "../Core";
import { inject, injectable } from "inversify";

@injectable()
export class GanttStyleProvider extends Lifecycle {
  constructor(@inject(IocSymbols.HtmlContainerSymbol) private _container: HTMLDivElement) {
    super();
  }

  get font(): string {
    return window.getComputedStyle(this._container).font;
  }

  get fontSize(): string {
    return window.getComputedStyle(this._container).fontSize;
  }

  get fontFamily(): string {
    return window.getComputedStyle(this._container).fontFamily;
  }
}
