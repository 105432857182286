import { CreateProcessDraftCommand, ProcessDraftDto, UpdateProcessDraftCommand } from "@masta/generated-model";
import ApiService from "@/services/api";
import { defineStore } from "pinia";
import { useErrorsStore } from "@/store/ErrorsStore";
import { useScenariosStore } from "@/store/ScenariosStore";

interface State {
  processDrafts: ProcessDraftDto[];
  loaded: boolean;
}

export const useProcessDraftsStore = defineStore("processDrafts", {
  state: (): State => ({
    processDrafts: [],
    loaded: false
  }),
  actions: {
    async fetch(force = false) {
      try {
        if (this.loaded && !force) return;
        const scenarioStore = useScenariosStore();
        const { data } = await ApiService.processDrafts.getAll(scenarioStore.selectedScenario?.id ?? "");
        this.processDrafts = data.processDrafts ?? [];
        this.loaded = true;
      } catch (e) {
        const errorsStore = useErrorsStore();
        errorsStore.handleError(e);
        throw e;
      }
    },
    async fetchSingle(id: string): Promise<ProcessDraftDto | null> {
      try {
        const scenarioStore = useScenariosStore();
        const { data } = await ApiService.processDrafts.getById(id, scenarioStore.selectedScenario?.id ?? "");
        return data;
      } catch (e) {
        const errorsStore = useErrorsStore();
        errorsStore.handleError(e);
        throw e;
      }
    },
    async create(request: CreateProcessDraftCommand) {
      return ApiService.processDrafts.create(request);
    },
    async update(request: UpdateProcessDraftCommand) {
      return ApiService.processDrafts.update(request);
    },
    async delete(id: string) {
      const scenarioStore = useScenariosStore();
      return ApiService.processDrafts.remove(id, scenarioStore.selectedScenario?.id ?? "");
    }
  }
});
