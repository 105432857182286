import { IOverlappingActivity } from "../Activity";
import { Duration } from "@js-joda/core";

export class OverlapComputer<Activity extends IOverlappingActivity> {

  constructor(private _getAllActivitiesFunction: () => Iterable<Activity>) {
  }

  compute() {
    const activities: Iterable<Activity> = this._getAllActivitiesFunction();

    const sortedActivitiesByStart = Array.from(activities).sort((a, b) => a.startTime.compareTo(b.startTime));
    const sortedActivitiesByStartMap = new Map(sortedActivitiesByStart.map(a => [a.startTime, a]));

    const sortedActivitiesByEnd = Array.from(activities).sort((a, b) => a.endTime.compareTo(b.endTime));
    const sortedActivitiesByEndMap = new Map(sortedActivitiesByEnd.map(a => [a.endTime, a]));

    const longestDuration = this.getLongest(sortedActivitiesByStart);

    for (const activity of sortedActivitiesByStart) {
      activity.overlap.updateOverlaps(sortedActivitiesByStartMap, sortedActivitiesByEndMap, longestDuration);
    }
  }

  private getLongest(activities: Iterable<Activity>): Duration {
    let maxDuration = Duration.ZERO;

    Array.from(activities).forEach((e: IOverlappingActivity) => {
      const duration = Duration.between(e.startTime, e.endTime);
      if (duration.compareTo(maxDuration) > 0) {
        maxDuration = duration;
      }
    });

    return maxDuration;
  }
}
