<script lang="ts">
import { ICellEditorParams } from "ag-grid-community";
import { computed, ref, toRef } from "vue";
import { IControlledCellEditorParams, resolveValueRef, useValueChangeControl } from "@/components/Grid/UseValueChangeControl";
import EmojiPicker from "@/components/Tags/EmojiPicker.vue";
import { VForm } from "vuetify/lib/components/index.mjs";

export interface IEmojiPickerCellEditorParams extends IControlledCellEditorParams, ICellEditorParams {
  rules?: any[];
}

// Warn: Use export default instead of <script setup /> because only this works with AgGrid!
export default {
  components: { EmojiPicker },
  setup(props: { params: IEmojiPickerCellEditorParams }) {
    const emojiPickerRef = ref<typeof EmojiPicker>();
    const formRef = ref<VForm>();
    const valid = ref<boolean>();

    const params = toRef(props, "params");

    const isEditEnabled = toRef(props.params.isEditEnabled ? props.params.isEditEnabled(props.params) : true);

    const initialValue = props.params.value;
    const emojiValue = resolveValueRef(props.params, initialValue);

    const rules = computed(() => {
      return params.value.rules ?? [];
    });

    const errors = computed(() => {
      return formRef.value?.errors.map((e) => e.errorMessages.join(",")).join(",");
    });

    const showTooltip = computed<boolean>(() => !!errors.value && !!emojiPickerRef.value?.hasFocus());

    function getValue() {
      return emojiValue.value;
    }

    function afterGuiAttached() {
      if (props.params.cellStartedEdit) {
        focusIn();
      }
      if (formRef.value && formRef.value.validate) {
        formRef.value.validate();
      }
    }

    function focusIn() {
      emojiPickerRef.value?.focus();
    }

    function focusOut() {
      emojiPickerRef.value?.focusOut();
    }

    function isValid() {
      return isEditEnabled.value ? valid?.value : undefined;
    }

    useValueChangeControl(emojiValue, props.params);

    return {
      getValue,
      afterGuiAttached,
      focusIn,
      focusOut,
      isValid,
      showTooltip,
      rules,
      valid,
      errors,
      isEditEnabled,
      emojiValue,
      emojiPickerRef: emojiPickerRef as any,
      formRef: formRef as any
    };
  }
};
</script>

<template>
  <v-tooltip v-model="showTooltip" location="top" color="error" :offset="30" class="emoji-picker-cell-editor-tooltip">
    <template #activator="act">
      <v-form ref="formRef" v-model="valid" :disabled="!isEditEnabled" class="text-input-cell-editor">
        <div class="text-input-cell-editor-container">
          <EmojiPicker ref="emojiPickerRef" v-model="emojiValue" color="primary" :readonly="!isEditEnabled" :placeholder="params.placeholder" :rules="rules"></EmojiPicker>
          <div v-bind="act.props" />
        </div>
      </v-form>
    </template>
    <span v-if="errors">{{ errors }}</span>
  </v-tooltip>
</template>

<style lang="scss">
.emoji-picker-cell-editor {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .emoji-picker-cell-editor-container {
    padding: 0.31em;

    .v-btn {
      height: 44px;
      padding: 0.31em;
    }

    .v-input {
      --v-input-chips-margin-top: 0;
      --v-input-chips-margin-bottom: 0;
      font-size: 1em;
    }

    .v-autocomplete {
      &--single {
        .v-field--variant-outlined input {
          --v-input-chips-margin-top: 2px;
          --v-input-chips-margin-bottom: 2px;
        }
      }

      .v-field {
        border-radius: 8px;
        font-size: 1em;

        .v-field__overlay {
          background-color: white;
        }

        .v-field__input {
          font-size: 1em;
          min-height: 44px;
        }

        .v-field__outline {
          --v-field-border-width: 2px;
        }

        color: rgba(0, 0, 0, 0.5);

        &:hover {
          color: var(--ag-data-color);
        }

        &.v-field--focused {
          color: var(--ag-data-color);
        }
      }
    }
  }
}

.emoji-picker-cell-editor-tooltip {
  .v-overlay__content {
    background: rgba(var(--v-theme-error), 0.7);
  }
}
</style>
