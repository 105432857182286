<template>
  <master-detail-layout scenario-selector>
    <template #master>
      <v-card elevation="0" class="flexcard fill-height">
        <v-card-title>
          <breadcrumbs>{{ $t("workJournalRecords-view-workJournalRecords-title", { $: "Work Journal Records" }) }}</breadcrumbs>
        </v-card-title>
        <v-card-text class="fill-height">
          <work-journal-records-grid />
        </v-card-text>
      </v-card>
    </template>
  </master-detail-layout>
</template>

<script>
import ServicesGrid from "@/components/Services/ServicesGrid";
import MasterDetailLayout from "@/components/Layout/MasterDetailLayout";
import ResourceLabel from "@/components/ModelInstances/ModelInstanceAssignment/ResourceLabel";
import AvailabilityRulesAssignmentEditor from "@/components/AvailabilityRules/AvailabilityRulesAssignment/AvailabilityRulesAssignmentEditor";
import { mapActions } from "pinia";
import { useServicesStore } from "@/store/ServicesStore";
import Breadcrumbs from "@/components/Layout/Breadcrumbs.vue";
import ResourceModelInstanceAssignment from "@/components/ModelInstances/ModelInstanceAssignment/ResourceModelInstanceAssignment.vue";
import WorkJournalRecordsGrid from "@/components/WorkJournalRecords/WorkJournalRecordsGrid.vue";

export default {
  name: "Work Journal Records",
  components: {
    WorkJournalRecordsGrid,
    Breadcrumbs,
    MasterDetailLayout
  },
  title: "Work Journal Records",
  data() {
    return {
      refreshGridAction: null
    };
  }
};
</script>

<style scoped></style>
