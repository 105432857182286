import { AutoLineManager, IocSymbols, Layer, Row, TimelineManager } from "@masta/gantt2/core";
import { inject, injectable } from "inversify";
import { BehaviorSubject } from "rxjs";
import { ResourceCapacityActivity, ResourceRow } from "@/components/Gantt/Common/Model";

@injectable()
export class EnhancedAutoLineManger extends AutoLineManager<ResourceRow, ResourceCapacityActivity> {

  constructor(
    @inject(TimelineManager) timelineManager: TimelineManager,
    @inject(IocSymbols.LayersSymbol) layers$$: BehaviorSubject<Layer[]>,
    @inject(Row<any, any, any>) row: ResourceRow
  ) {
    super(timelineManager, layers$$, row);


    this.filter = (activity: ResourceCapacityActivity) => {
      return activity.constructor.name !== "AvailabilityRuleResourceCapacityActivity";
    };

    this.layout();
  }
}
