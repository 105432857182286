import { reactive, UnwrapNestedRefs, watch } from "vue";
import { AuthenticationState } from "./keycloak.types";
import equal from "fast-deep-equal";

export class LocalStorageAuthentication {
  public static readonly LOCAL_STORAGE_KEY = "authentication";
  private readonly _storageKey: string;
  private _isUpdatingFromStorageEvent = false;
  private _authenticationState: UnwrapNestedRefs<{ state: AuthenticationState }> = reactive({
    state: {} as AuthenticationState
  });

  constructor(storageKeyPrefix: string) {
    this._storageKey = `${LocalStorageAuthentication.LOCAL_STORAGE_KEY}-${storageKeyPrefix}`;
    this.loadFromLocalStorage();

    window.addEventListener("storage", this.onStorageChange.bind(this));
    this.watchAuthenticationState();
  }

  get authenticationState(): AuthenticationState {
    return this._authenticationState.state;
  }

  set authenticationState(value: AuthenticationState) {
    this._authenticationState.state = value;
  }

  get storageKey(): string {
    return this._storageKey;
  }

  clear() {
    localStorage[this.storageKey] = JSON.stringify({});
    this._authenticationState.state = {} as AuthenticationState;
  }

  private loadFromLocalStorage() {
    const auth: AuthenticationState = JSON.parse(localStorage[this.storageKey] ?? null);
    this._authenticationState.state = auth ?? {};
  }

  private watchAuthenticationState() {
    watch(
      this._authenticationState,
      ({ state }) => {
        if (this._isUpdatingFromStorageEvent) {
          this._isUpdatingFromStorageEvent = false;
          return;
        }
        const newStateSerialized = JSON.stringify(state);
        if (!equal(newStateSerialized, localStorage[this.storageKey])) {
          localStorage[this.storageKey] = newStateSerialized;
        }
      },
      { deep: true }
    );
  }

  protected onStorageChange(e: StorageEvent) {
    if (e.key === this.storageKey && e.newValue) {
      this._isUpdatingFromStorageEvent = true;
      this.authenticationState = JSON.parse(e.newValue) as AuthenticationState;
    }
  }
}
