<script lang="ts" setup>
import { NumberPropertyModel } from "@masta/json-schema";
import { $t } from "@/i18n";

const props = defineProps<{
  modelValue: NumberPropertyModel;
  disabled: boolean;
}>();

const emit = defineEmits(["update:modelValue"]);

function generateRules() {
  const result = [];
  if (props.modelValue.max) {
    const max = props.modelValue.max;
    result.push((v: number) => v <= max || $t("modelInstance-numberProperty-maxMaxCharacters-label", { $: "Max {max} characters", max }));
  }
  if (props.modelValue.min) {
    const min = props.modelValue.min;
    result.push((v: number) => v >= min || $t("modelInstance-numberProperty-minMinCharacters-label", { $: "Min {min} characters", min }));
  }
  if (props.modelValue.exclusiveMax) {
    const exclusiveMax = props.modelValue.exclusiveMax;
    result.push((v: number) => v < exclusiveMax || $t("modelInstance-numberProperty-maxExclusiveMaxCharacters-label", { $: "Max {exclusiveMax} characters", exclusiveMax }));
  }
  if (props.modelValue.exclusiveMin) {
    const exclusiveMin = props.modelValue.exclusiveMin;
    result.push((v: number) => v > exclusiveMin || $t("modelInstance-numberProperty-minExclusiveMinCharacters-label", { $: "Min {exclusiveMin} characters", exclusiveMin }));
  }
  return result;
}

function updateModel(value: any) {
  // eslint-disable-next-line vue/no-mutating-props
  props.modelValue.value = Number(value);
}
</script>

<template>
  <v-col cols="6">
    <div class="d-flex pa-5 rounded-lg" :style="{ backgroundColor: modelValue.color }">
      <v-text-field
        type="number"
        variant="outlined"
        density="compact"
        hide-details
        :model-value="modelValue.value"
        :label="modelValue.name"
        :rules="generateRules()"
        :disabled="disabled"
        :prepend-icon="modelValue.icon"
        @update:model-value="updateModel"
      />
    </div>
  </v-col>
</template>
