import { ApiClient } from "./common.api";
import agreements from "./agreements.api";
import aggregateResourceCapacities from "./aggregate-resource-capacities.api";
import aggregateElectricityConsumptionResourceCapacities from "./aggregate-electricity-consumption-resource-capacities.api";
import assets from "./assets.api";
import businessActions from "./business-actions.api";
import documentEditionForMobile from "./document-edition-for-mobile.api";
import communications from "./communications.api";
import contacts from "./contacts.api";
import dispatch from "./dispatch.api";
import equipment from "./equipment.api";
import filters from "./filters.api";
import imports from "./imports.api";
import exports from "./exports.api";
import events from "./events.api";
import modelInstances from "./model-instances";
import tenants from "./tenants.api";
import personnel from "./personnel.api";
import services from "./services.api";
import planning from "./planning.api";
import tasks from "./tasks.api";
import processDrafts from "./process-drafts.api";
import productionTasks from "./production-tasks.api";
import productTemplates from "./product-templates.api";
import mat from "./mat/mat.api";
import materials from "./material.api";
import orders from "./orders.api";
import orderLineGroups from "@/services/api/order-line-groups.api";
import organizations from "./organizations.api";
import scenarios from "./scenarios.api";
import settings from "./settings.api";
import scheduling from "./scheduling.api";
import locations from "./locations.api";
import system from "./system.api";
import users from "./users.api";
import gantt from "./gantt.api";
import reports from "./reports.api";
import resources from "./resources.api";
import resourceAssignment from "./resource-assignments.api";
import resourceCapacities from "./resource-capacities.api";
import resourceAvailabilityRules from "./resource-availability-rules.api";
import materialStock from "./material-stock.api";
import customerOrders from "./customer-orders.api";
import purchaseOrders from "./purchase-orders.api";
import inboundDeliveries from "./inbound-deliveries.api";
import generateCalendars from "./generate-calendars.api";
import tags from "./tags.api";
import calendars from "./calendars.api";
import documents from "./documents.api";
import documentContexts from "./document-contexts.api";
import availabilityRules from "./availability-rules.api";
import executionIssues from "./execution-issues.api";
import workJournalRecords from "./work-journal-records.api";
import costCatalogue from "./cost-catalogue.api";
import kyklos from "./kyklos.api";
import quotations from "./quotations.api";
import quotationDocuments from "./quotation-documents.api";
import quotationLines from "./quotation-lines.api";
import productCostAccounting from "./product-cost-accounting.api";
import executionEvents from "@/services/api/execution-events.api";
import costControllingDocuments from "@/services/api/cost-controlling-documents.api";
import keyPerformanceIndicators from "@/services/api/key-performance-indicators.api";
import userNotices from "./user-notices.api";

export default {
  ApiClient,
  aggregateResourceCapacities,
  aggregateElectricityConsumptionResourceCapacities,
  agreements,
  assets,
  businessActions,
  documentEditionForMobile,
  communications,
  contacts,
  calendars,
  costCatalogue,
  costControllingDocuments,
  dispatch,
  documents,
  documentContexts,
  equipment,
  executionEvents,
  executionIssues,
  filters,
  gantt,
  generateCalendars,
  imports,
  events,
  exports,
  keyPerformanceIndicators,
  locations,
  mat,
  materials,
  materialStock,
  modelInstances,
  customerOrders,
  purchaseOrders,
  inboundDeliveries,
  orders,
  orderLineGroups,
  organizations,
  personnel,
  processDrafts,
  productCostAccounting,
  productionTasks,
  productTemplates,
  planning,
  quotations,
  quotationDocuments,
  quotationLines,
  reports,
  resources,
  resourceAssignment,
  resourceCapacities,
  resourceAvailabilityRules,
  scenarios,
  scheduling,
  settings,
  system,
  services,
  tags,
  tasks,
  tenants,
  availabilityRules,
  users,
  userNotices,
  workJournalRecords,
  kyklos
};
