import { inject, injectable } from "inversify";
import { GanttDomElement } from "../../../GanttDomElement";
import { Row } from "../../../../Core";

@injectable()
export class RowInfoHeightResizeHandle<TResource extends Row<any, any, any>> extends GanttDomElement<HTMLDivElement> {
  private _initialMousePosition = 0;

  private _initialRowHeight = 0;

  private _mouseMoveListener: OmitThisParameter<(e: MouseEvent) => void>;

  private _mouseDownListener: OmitThisParameter<(e: MouseEvent) => void>;

  private _mouseUpListener: OmitThisParameter<() => void>;

  private _dblClickListener: OmitThisParameter<(e: MouseEvent) => void>;

  constructor(
    @inject(Row<any, any, any>) private _row: TResource
  ) {
    super(`${RowInfoHeightResizeHandle.name}-${_row.id}`, undefined, "info-row-height-resize-handle");
    this.element.dataset.id = this.identifier;
  }


  async beforeInitialize(): Promise<void> {
  }

  async afterInitialize(): Promise<void> {
    this._mouseDownListener = this.onMouseDown.bind(this);
    this._mouseUpListener = this.onMouseUp.bind(this);
    this._mouseMoveListener = this.onResize.bind(this);
    this._dblClickListener = this.onDblClick.bind(this);
    this.element.addEventListener("mousedown", this._mouseDownListener, false);
    document.addEventListener("mouseup", this._mouseUpListener, false);
    this.element.addEventListener("dblclick", this._dblClickListener, false);
  }

  async beforeDestroy(): Promise<void> {
    this.element.removeEventListener("mousedown", this._mouseDownListener);
    document.removeEventListener("mouseup", this._mouseUpListener);
    document.removeEventListener("mousemove", this._mouseMoveListener, false);
    this.element.removeEventListener("dblclick", this._dblClickListener, false);
  }

  private onMouseDown(e: MouseEvent) {
    this._initialMousePosition = e.y;
    this._initialRowHeight = this._row.height;
    document.addEventListener("mousemove", this._mouseMoveListener, false);
  }

  private onMouseUp() {
    this._initialMousePosition = 0;
    this._initialRowHeight = 0;
    document.removeEventListener("mousemove", this._mouseMoveListener, false);
  }

  private onResize(e: MouseEvent) {
    const diff = e.y - this._initialMousePosition;
    const height = this._initialRowHeight + diff;
    if ((height >= this._row.minHeight || height > this._initialRowHeight) && (height <= this._row.maxHeight || height < this._initialRowHeight)) {
      this._row.height = height;
    }
  }

  private onDblClick(e: MouseEvent) {
    this._row.height = this._row.defaultHeight;
    this.element.scrollIntoView({ behavior: "instant", block: "center" });
  }
}
