<template>
  <v-card>
    <v-card-title>
      <v-icon class="pr-2"> mdi-account-multiple</v-icon>
      Suppliers Matching
    </v-card-title>
    <v-card-text>
      <v-card v-if="!project.projectInquiry" class="mb-2">
        <v-card-text>
          <v-file-input accept="application/json" label="Upload project of your part (JSON)" truncate-length="75" @change="readFile"/>
        </v-card-text>
        <v-progress-linear v-if="uploading" indeterminate/>
      </v-card>
      <v-card v-else>
        <v-card-title>{{ project.projectInquiry.name }}</v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="4">
              <v-btn color="accent" block :loading="sendingInquiry" :disabled="sendingInquiry" @click="sendInquiry"> send inquiry to suppliers</v-btn>
            </v-col>
            <v-col cols="4">
              <v-btn block :loading="refreshing" :disabled="refreshing" @click="refresh"> refresh</v-btn>
            </v-col>
            <v-col cols="4">
              <v-btn block @click="project = { projectInquiry: null }"> clear inquiry</v-btn>
            </v-col>
          </v-row>
          <v-col v-if="project.projectInquiry" cols="12">
            <inquiry-suppliers-grid :suppliers="project.projectInquiry.supplierInquires"/>
          </v-col>
        </v-card-text>
      </v-card>
    </v-card-text>
  </v-card>
</template>

<script>
import ApiService from "@/services/api";
import InquirySuppliersGrid from "@/components/Mat/InquirySuppliersGrid.vue";
import {mapActions} from "pinia";
import {useSnackbarsStore} from "@/store/SnackbarsStore";

export default {
  name: "MatSuppliers",
  components: {InquirySuppliersGrid},
  title: "Mat Webapp",
  data() {
    return {
      project: {
        projectInquiry: null
      },
      refreshing: false,
      uploading: false,
      sendingInquiry: false
    };
  },
  async mounted() {
    await this.refresh();
  },
  methods: {
    ...mapActions(useSnackbarsStore, ["createSnackbar"]),
    async refresh() {
      try {
        this.refreshing = true;
        const {data} = await ApiService.mat.get();
        this.project = data;
      } catch (e) {
        console.error(e);
        await this.createSnackbar({
          message: e.message,
          type: "error",
          closeable: true
        });
      } finally {
        this.refreshing = false;
      }
    },
    async readFile(file) {
      this.uploading = true;
      try {
        const formData = new FormData();
        formData.append("file", file, file.name);
        await ApiService.mat.upload(formData);
        this.uploading = false;
        await this.refresh();
      } catch (e) {
        console.error(e);
        await this.createSnackbar({
          message: e.message,
          type: "error",
          closeable: true
        });
      } finally {
        this.uploading = false;
      }
    },
    async sendInquiry() {
      try {
        this.sendingInquiry = true;
        await ApiService.mat.sendInquiry();
        await this.refresh();
        this.sendingInquiry = false;
      } catch (e) {
        console.error(e);
        await this.createSnackbar({
          message: e.message,
          type: "error",
          closeable: true
        });
      } finally {
        this.sendingInquiry = false;
      }
    }
  }
};
</script>

<style lang="scss" scoped></style>
