import { ServerSideDataSource } from "@/components/Grid/ServerSideDataSource";
import { CustomerOrderLineDetailsDto, GetCustomerOrderLinesDetailsPaginatedQuery, IAgGridPaginationResponse } from "@masta/generated-model";
import { Store } from "pinia";
import { State as ScenarioState, useScenariosStore } from "@/store/ScenariosStore";
import { IServerSideGetRowsParams } from "ag-grid-community";
import ApiService from "@/services/api";

export class CustomerOrderLineDetailsServerSideDataSource extends ServerSideDataSource<
  CustomerOrderLineDetailsDto,
  CustomerOrderLineDetailsDto,
  CustomerOrderLineDetailsDto,
  CustomerOrderLineDetailsDto,
  string
> {
  private _scenarioStore: Store<string, ScenarioState>;
  private _onlyUngroupedFilter: boolean = false;

  constructor(id: string) {
    super(id);
    this._scenarioStore = useScenariosStore();
  }

  public useOnlyUngroupedFilter(onlyUngrouped: boolean) {
    this._onlyUngroupedFilter = onlyUngrouped;
  }

  async getAll(params: IServerSideGetRowsParams): Promise<IAgGridPaginationResponse<CustomerOrderLineDetailsDto>> {
    if (!this._scenarioStore.selectedScenario) return { count: 0, results: [] } as IAgGridPaginationResponse<CustomerOrderLineDetailsDto>;

    const request = {
      ...this.constructServerRequest(params),
      scenarioId: this._scenarioStore.selectedScenario.id,
      onlyUngrouped: this._onlyUngroupedFilter
    } as GetCustomerOrderLinesDetailsPaginatedQuery;
    const response = await ApiService.customerOrders.getOrderLineDetailsPaginated(request);
    return response.data as IAgGridPaginationResponse<CustomerOrderLineDetailsDto>;
  }

  async create(entity: CustomerOrderLineDetailsDto): Promise<string> {
    throw new Error("Method not implemented.");
  }

  async getSingle(id: string): Promise<CustomerOrderLineDetailsDto | undefined> {
    throw new Error("Method not implemented.");
  }

  async remove(entity: CustomerOrderLineDetailsDto): Promise<void> {
    throw new Error("Method not implemented.");
  }

  async update(entity: CustomerOrderLineDetailsDto): Promise<string> {
    throw new Error("Method not implemented.");
  }

  protected transformForCreate(listEntity: CustomerOrderLineDetailsDto): CustomerOrderLineDetailsDto {
    throw new Error("Method not implemented.");
  }

  protected transformForUpdate(listEntity: CustomerOrderLineDetailsDto): CustomerOrderLineDetailsDto {
    throw new Error("Method not implemented.");
  }
}
