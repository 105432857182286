import { inject, injectable, multiInject, optional } from "inversify";
import { GanttDomElement } from "../GanttDomElement";
import { IocSymbols } from "../../Core";
import { ActivityTooltipLayer, HelpLayer, RowLayerCanvasWithWebWorker } from "./Row";

@injectable()
export class AboveRowsContentCanvasContainer extends GanttDomElement<HTMLDivElement> {
  constructor(
    @multiInject(IocSymbols.AboveRowContentSystemLayersSymbol) @optional() private readonly _aboveRowContentSystemLayers: RowLayerCanvasWithWebWorker[] = [],
    @inject(ActivityTooltipLayer) private readonly _activityTooltipLayer: ActivityTooltipLayer,
    @inject(HelpLayer) private readonly _helpLayer: HelpLayer
  ) {
    super(AboveRowsContentCanvasContainer.name, undefined, "above-rows-content-canvas-container");
  }

  async beforeInitialize(): Promise<void> {
    await this.addChildren(this._aboveRowContentSystemLayers);
    await this.addChild(this._activityTooltipLayer);
    await this.addChild(this._helpLayer);
  }
}
