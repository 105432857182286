<script setup lang="ts">
import ModelInstanceAssignmentEditor from "@/components/ModelInstances/ModelInstanceAssignment/ModelInstanceAssignmentEditor";
import TaskLabel from "@/components/ModelInstances/ModelInstanceAssignment/TaskLabel";
import SystemEnumService from "@/services/system-enum.service";
import { useProductTemplatesStore } from "@/store/ProductTemplatesStore";
import { useScenariosStore } from "@/store/ScenariosStore";
import ProductTemplateStepResourceSpecGrid from "@/components/ProductTemplates/ProductTemplateStepResourceSpecsGrid.vue";
import { nextTick, ref } from "vue";
import { ProductTemplateDetailsDto, StepTemplateDto, UpdateTaskModelInstanceAssignmentCommand } from "@masta/generated-model";

const props = defineProps<{
  theme: any;
  readonly: boolean;
}>();

const productTemplatesStore = useProductTemplatesStore();
const scenariosStore = useScenariosStore();

const editModelInstanceAssignmentDialog = ref<boolean>(false);
const tab = ref("specifications");

const step = ref<StepTemplateDto | undefined>(undefined);
const task = ref<ProductTemplateDetailsDto | undefined>(undefined);
const spec = ref<any>(undefined);

const renderDetails = ref<boolean>(false);

function manageModelInstances(value: any) {
  spec.value = value;
  editModelInstanceAssignmentDialog.value = true;
}

function saveModelInstanceAssignment(instances: any) {
  if (!scenariosStore.selectedScenario) return;
  const selectedScenarioId = scenariosStore.selectedScenario.id;

  if (step.value && spec.value) {
    const updateTaskModelInstanceAssignmentCmd: UpdateTaskModelInstanceAssignmentCommand = {
      taskId: step.value.taskId,
      stepId: step.value.id,
      stepName: null,
      stepResourceSpecId: spec.value.id,
      resourceBusinessId: null,
      taskBusinessId: null,
      scenarioId: selectedScenarioId,
      modelInstances: instances.map((x: any) => ({
        modelInstanceId: x.id,
        revisionNumber: x.revisionNumber
      })),
      modelInstanceBusinessIds: null
    };

    productTemplatesStore.updateTaskModelInstanceAssignment(updateTaskModelInstanceAssignmentCmd);

    editModelInstanceAssignmentDialog.value = false;
  }
}

function getAssignmentType() {
  return SystemEnumService.stepResourceAssignmentType(spec.value.assignmentType);
}

function getResourceType() {
  return SystemEnumService.resourceType(spec.value.resourceType);
}

async function loadStepDetails(stepTemplate: StepTemplateDto | undefined) {
  renderDetails.value = false;

  // Wait for the change to get flushed to the DOM
  // Needed to force a re-creation of `product-template-step-resource-spec-grid`
  await nextTick();

  if (stepTemplate) {
    step.value = stepTemplate;
    task.value = productTemplatesStore.productTemplateDetail(stepTemplate?.taskId);

    renderDetails.value = true;
  } else {
    step.value = undefined;
    task.value = undefined;
  }
}

defineExpose({ loadStepDetails });
</script>

<template>
  <div v-if="renderDetails" class="fill-height pa-4 d-flex flex-column">
    <div class="tabs">
      <v-tabs
        v-model="tab"
        class="tab-details text-disabled bg-white"
        selected-class="v-slide-group-item--active text-primary bg-white"
        density="compact"
        color="primary"
        show-arrows
      >
        <v-tab :theme="theme.current" value="specifications">
          {{ $t("productTemplate-stepDetailsSpecification-specification-title", { $: "Specification" }) }}
        </v-tab>
      </v-tabs>
    </div>
    <v-sheet class="flex-fill mt-1">
      <v-window v-model="tab" direction="vertical" :theme="theme.current" class="fill-height">
        <v-window-item value="specifications" class="fill-height">
          <product-template-step-resource-spec-grid :readonly="readonly" :step="step" @manage-model-instances="manageModelInstances" />
        </v-window-item>
      </v-window>
      <div v-if="editModelInstanceAssignmentDialog">
        <model-instance-assignment-editor
          task-assignment
          :model-instances="spec?.modelInstances"
          :opened="editModelInstanceAssignmentDialog"
          @cancel="editModelInstanceAssignmentDialog = false"
          @save="saveModelInstanceAssignment"
        >
          <task-label
            v-if="step && task"
            :spec-resource-type="getResourceType()"
            :spec-type="getAssignmentType()"
            :step-name="step.name"
            :step-position="step.position"
            :task-external-id="task.businessId"
            :task-name="task.name"
            type="Step Resource Specification Template"
          />
        </model-instance-assignment-editor>
      </div>
    </v-sheet>
  </div>
  <v-card-text v-else class="fill-height">
    <v-row class="fill-height">
      <v-col cols="12" class="d-flex align-center justify-center">
        <label class="text-secondary text-h6 font-weight-regular">
          {{ $t("productTemplate-stepDetailsSpecification-noTaskSelected-label", { $: "No Task selected" }) }}
        </label>
      </v-col>
    </v-row>
  </v-card-text>
</template>

<style lang="scss">
@import "vuetify/lib/styles/settings/colors";

.task-steps-details {
  display: flex;
  flex-direction: column;

  .v-slide-group__content {
    .v-btn {
      text-align: left;
      justify-content: start;
    }

    .v-btn {
      border-right: unset;
    }
  }

  .v-window {
    flex-grow: 1;
  }
}
</style>
