import { checkValues } from "@/plugins/acl.plugin";
import { RouteLocationNormalized } from "vue-router";
import { useAuthStore } from "@/store/AuthStore";

const check = (to: RouteLocationNormalized, field: string) => {
  if (!to.matched.some((x) => {
    const isArray = Array.isArray(x.meta[field]);
    const isString = typeof x.meta[field] === "string";
    return isArray || isString;
  })) return true;
  const matched = to.matched.find((x) => {
    const isArray = Array.isArray(x.meta[field]);
    const isString = typeof x.meta[field] === "string";
    return isArray || isString;
  });
  if (!matched) return true;
  const meta: any = matched.meta;
  // const values: any[] = meta ? (meta[field] ? meta[field] : []) : [];
  const values: any[] = meta[field] ? (Array.isArray(meta[field]) ? meta[field] : [meta[field]]) : [];
  if (values.length > 0) {
    const user = useAuthStore().getUser;
    if (user === null) {
      return false;
    }
    return checkValues({
      existing: [...new Set(user[field])],
      values: [...new Set(values)],
      anyModifier: true
    });
  }
  return true;
};
export const checkRoles = (to: RouteLocationNormalized) => check(to, "tenant_roles");
// export const checkPermissions = (to: RouteLocationNormalized) => check(to, "permissions");
