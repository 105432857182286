<script lang="ts">
import { BodyScrollEvent, ICellEditorParams } from "ag-grid-community";
import { computed, onMounted, onUnmounted, Ref, ref, toRef, watch, isRef, nextTick } from "vue";
import { IEnumValueSelectCellEditorParams, ISelectEnumValueEntry } from "@/components/Grid/CellEditors/IEnumValueSelectCellEditorParams";
import { VForm, VAutocomplete } from "vuetify/components";
import { getOrEvalBoolean, resolveValueRef, useValueChangeControl } from "@/components/Grid/UseValueChangeControl";

interface IProps extends IEnumValueSelectCellEditorParams, ICellEditorParams {}

// Warn: Use export default instead of <script setup /> because only this works with AgGrid!
export default {
  setup(props: { params: IProps }) {
    const params = toRef(props, "params");

    const valid = ref<boolean>();
    const hasFocus = ref<boolean>(false);
    const form = ref<VForm>();

    let values: Ref<ISelectEnumValueEntry[]>;
    if (typeof props.params.values === "function") {
      const result = props.params.values();
      values = isRef(result) ? result : ref(result);
    } else {
      values = ref(props.params.values);
    }

    const hideOnEditDisabled = ref<boolean>(getOrEvalBoolean(props.params.hideOnEditDisabled, false));

    function isValueValid(x: any) {
      return values.value.map((entry) => entry.value).indexOf(x) !== -1;
    }

    function validateValue(v: any) {
      for (const x of Array.isArray(v) ? v : v ? [v] : []) {
        if (!isValueValid(x)) {
          return false;
        }
      }
      return true;
    }

    const isInitialValueValid = validateValue(props.params.value);
    const initialValue = isInitialValueValid ? props.params.value : null;

    const value = resolveValueRef(props.params, initialValue);

    const input = ref<VAutocomplete>();
    const errors = computed(() => {
      return form.value?.errors.map((e) => e.errorMessages.join(",")).join(",");
    });
    const showTooltip = computed<boolean>(() => !!errors.value && hasFocus.value);

    const isEditEnabled = toRef(props.params.isEditEnabled ? props.params.isEditEnabled(props.params) : true);

    const rules = computed(() => {
      return params.value.rules ?? [];
    });

    onMounted(() => {
      props.params.api.addEventListener("bodyScroll", onGridBodyScroll);
    });

    onUnmounted(() => {
      const { api } = props.params;
      if (api && !api.isDestroyed()) {
        api.removeEventListener("bodyScroll", onGridBodyScroll);
      }
    });

    function onGridBodyScroll(event: BodyScrollEvent) {
      // close menu on scroll (when is open) to avoid menu position issues
      if (input.value && input.value.menu) {
        input.value.menu = false;
      }
    }

    function getValue() {
      return value.value;
    }

    function afterGuiAttached() {
      if (props.params.cellStartedEdit) {
        focusIn();
      }
      if (form.value && form.value.validate) {
        form.value.validate();
      }
    }

    // Gets called once before editing starts, to give editor a chance to
    // cancel the editing before it even starts.
    function isCancelBeforeStart() {
      return false;
    }

    // Gets called once when editing is finished (eg if Enter is pressed).
    // If you return true, then the result of the edit will be ignored.
    function isCancelAfterEnd() {
      return !valid.value;
    }

    function focusIn() {     
      if (input.value?.$el) {
        const inputs = input.value?.$el.getElementsByTagName("input");
        if (inputs.length > 0) {
          inputs[0].focus();
        }
      }
      hasFocus.value = true;
    }

    function focusOut() {
      if (form.value && form.value.validate) {
        form.value.validate();
      }
      hasFocus.value = false;
    }

    function isValid() {
      return isEditEnabled.value ? valid?.value : undefined;
    }

    useValueChangeControl(value, props.params);

    if (values) {
      watch(values, () => {
        const validValues = values.value.map((x) => x.value) ?? [];
        if (validValues.indexOf(value.value) === -1) {
          value.value = null;
        }
      });
    }

    return {
      getValue,
      afterGuiAttached,
      isCancelBeforeStart,
      isCancelAfterEnd,
      focusIn,
      focusOut,
      isValid,
      showTooltip,
      rules,
      valid,
      hasFocus,
      errors,
      value,
      values,
      params,
      form: form as any,
      input: input as any,
      isEditEnabled,
      hideOnEditDisabled
    };
  }
};
</script>

<template>
  <v-tooltip v-model="showTooltip" location="top" color="error" class="enum-type-cell-editor-tooltip">
    <template #activator="act">
      <v-form ref="form" v-model="valid" :disabled="!isEditEnabled" class="text-input-cell-editor">
        <div class="text-input-cell-editor-container enum-type-cell-editor-container">
          <v-autocomplete
            ref="input"
            v-model="value"
            :items="values"
            :readonly="!isEditEnabled"
            :disabled="!isEditEnabled"
            color="primary"
            item-title="key"
            item-value="value"
            density="compact"
            variant="outlined"
            auto-select-first
            hide-details
            :rules="rules"
            :multiple="params?.multiple ?? false"
            :clearable="false"
            :placeholder="params?.placeholder ?? ''"
          >
            <template #prepend-inner>
              <span v-bind="act.props" />
            </template>
          </v-autocomplete>
        </div>
      </v-form>
    </template>
    <span v-if="errors">{{ errors }}</span>
  </v-tooltip>
</template>

<style lang="scss">
.text-input-cell-editor {
  .text-input-cell-editor-container {
    .v-field--disabled {
      opacity: 0.75;
    }

    .v-field {
      .v-field__input {
        flex-wrap: nowrap;
        overflow: hidden;
        min-height: 34px;
      }
    }

    .v-select {
      .v-field__input {
        min-height: 24px;
      }
    }

    .v-select:not(.v-select--selected) {
      .v-field {
        .v-field__input {
          flex-wrap: nowrap;
          overflow: hidden;

          input {
            color: rgba(0, 0, 0);
            position: relative;
            flex: auto;
          }
        }
      }
    }
  }

  .enum-type-cell-editor-container {
    .v-field__input {
      padding-left: 0;
    }
  }
}

.enum-type-cell-editor-tooltip {
  .v-overlay__content {
    background: rgba(var(--v-theme-error), 0.7);
  }
}
</style>
