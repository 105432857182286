﻿import ApiService from "@/services/api";
import { ServerSideDataSource } from "@/components/Grid/ServerSideDataSource";
import {
  IAgGridPaginationResponse,
  WorkJournalRecordDto,
  GetWorkJournalRecordsPaginatedQuery
} from "@masta/generated-model";
import { IServerSideGetRowsParams } from "ag-grid-enterprise";
import { Store } from "pinia";
import { State as ScenarioState, useScenariosStore } from "@/store/ScenariosStore";

export class WorkJournalRecordsServerSideDataSource extends ServerSideDataSource<WorkJournalRecordDto> {
  private _scenarioStore: Store<string, ScenarioState>;

  constructor(id: string) {
    super(id);
    this._scenarioStore = useScenariosStore();
  }

  async create(entity: WorkJournalRecordDto): Promise<string | undefined> {
    return "";
  }

  async getAll(params: IServerSideGetRowsParams): Promise<IAgGridPaginationResponse<WorkJournalRecordDto>> {
    if (!this._scenarioStore.selectedScenario) return { count: 0, results: [] } as IAgGridPaginationResponse<WorkJournalRecordDto>;
    const request = {
      ...this.constructServerRequest(params),
      scenarioId: this._scenarioStore.selectedScenario.id
    } as GetWorkJournalRecordsPaginatedQuery;
    const response = await ApiService.workJournalRecords.getPaginated(request);
    return response.data as IAgGridPaginationResponse<WorkJournalRecordDto>;
  }

  async getSingle(id: string | undefined): Promise<WorkJournalRecordDto | undefined> {
    return undefined;
  }

  async remove(entity: WorkJournalRecordDto): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      resolve();
    });
  }

  async update(entity: WorkJournalRecordDto): Promise<string | undefined> {
    return "";
  }
}
