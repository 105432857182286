﻿import {
  ActivityRendererRegistry,
  GanttSettings,
  IGanttSettingsLoader,
  IocContainer,
  IocSymbols,
  IRowInfoColumnCellRegistry,
  onActivationLifecycle,
  Row,
  SettingKey
} from "@masta/gantt2/core";
import { AppContextDto, GanttSettings as GanttSettingsDto } from "@masta/generated-model";
import Api from "@/services/api";
import { CustomSettingKeys } from "@/components/Gantt/ResourcesGantt/CustomSettingKeys";
import { ChronoUnit, Instant } from "@js-joda/core";
import { IResourcesGanttOptions } from "@/components/Gantt/ResourcesGantt/ResourcesGanttLoader";
import { AppIocSymbols } from "@/components/Gantt/ResourcesGantt/AppIocSymbols";
import { RouteLocationNormalizedLoaded } from "vue-router";
import { TaskExecutionActivity, TaskSchedulingActivity } from "src/components/Gantt/Common/Model";
import { ActivityTooltipRendererRegistry, RowNameRowInfoTemplateCell } from "@masta/gantt2/gantt";
import type { interfaces } from "inversify";
import { NoteHandler } from "@/components/Gantt/ResourcesGantt/NoteHandler";
import { TaskGanttRowInfoColumnProvider } from "@/components/Gantt/TaskGantt/TaskGanttRowInfoColumnProvider";
import { DemandSupplyScaleRowInfoTemplateCellCanvas } from "@/components/Gantt/ResourcesGantt/RowInfo/DemandSupplyScaleRowInfoTemplateCellCanvas";
import { getChronoUnitFromTimelineUnit } from "@/components/Gantt/Common/CommonFrontModule";
import { RowWbsRowInfoTemplateCell } from "@/components/Gantt/TaskGantt/RowWbsRowInfoTemplateCell";
import { RowBusinessIdRowInfoTemplateCell } from "@/components/Gantt/TaskGantt/RowBusinessIdRowInfoTemplateCell";
import { TaskSchedulingActivityRenderer } from "@/components/Gantt/TaskGantt/Renderers/TaskSchedulingActivityRenderer";
import { TaskExecutionActivityRenderer } from "@/components/Gantt/TaskGantt/Renderers/TaskExecutionActivityRenderer";
import { TaskGanttActivityFactory } from "@/components/Gantt/TaskGantt/TaskGanttActivityFactory";
import { CustomTaskActivityTooltipRenderer } from "@/components/Gantt/TaskGantt/Renderers";
import { ProductionTaskChangeNotificationHandler } from "@/components/Gantt/TaskGantt/ProductionTaskChangeNotificationHandler";
import { TaskRowFactory } from "@/components/Gantt/TaskGantt/TaskRowFactory";

export const name = "TasksFrontModule";

function parseQueryFilter(ganttSettings: GanttSettings, route: RouteLocationNormalizedLoaded, filterExpressionProvider?: () => (string | null | undefined)) {
  if (filterExpressionProvider) {
    const filterExpression = filterExpressionProvider();
    if (filterExpression) {
      ganttSettings.setSetting({ key: CustomSettingKeys.QUERY_FILTER, value: filterExpression });
    }
  }
  const query: any = route.query;
  if (query.tasks) {
    const tasks = query.tasks.split(",");
    if (tasks.length > 0) {
      ganttSettings.setSetting({ key: CustomSettingKeys.TASK_ID_FILTER, value: tasks });
    }
  }
}

const settingsLoader = {
  async loadSettings(ganttSettings: GanttSettings, iocContainer: IocContainer) {
    parseQueryFilter(ganttSettings, iocContainer.get<RouteLocationNormalizedLoaded>(AppIocSymbols.$route), iocContainer.get<() => (string | null | undefined)>(AppIocSymbols.filterExpressionProvider));

    const { data }: {
      data: GanttSettingsDto
    } = await Api.gantt.getGanttSettings({ profile: "tasks" });

    const chronoUnit = getChronoUnitFromTimelineUnit(data.timelineUnit);
    ganttSettings.setSettings([
      {
        key: SettingKey.START_TIME,
        value: Instant.now()
          .minusSeconds(ChronoUnit[chronoUnit as unknown as keyof typeof ChronoUnit].duration().seconds())
          .toEpochMilli()
      },
      { key: SettingKey.TIMELINE_UNIT, value: chronoUnit },
      { key: SettingKey.TIMELINE_UNIT_WIDTH, value: data.timelineUnitWidth ?? 400 },
      { key: SettingKey.TIMELINE_ZOOM_FACTOR, value: data.mouseSensitivity ?? 0.05 },
      { key: SettingKey.ROW_FIXED_SCALE_ACTIVITY_DISPLAY_RANGE, value: { from: 0, to: 1 } },
      { key: SettingKey.ROW_FIXED_SCALE, value: { min: data.chart!.fixedScaleFromValue ?? 0, max: data.chart!.fixedScaleToValue ?? 3 } },
      { key: SettingKey.ROW_DEFAULT_HEIGHT, value: data.row.defaultHeight },
      { key: SettingKey.CHART_SHOW, value: false },
      {
        key: SettingKey.ROW_SORTING_FUNCTION, value: (a: Row<any, any, any>, b: Row<any, any, any>) => {
          const aUserObject: any = a.userObject;
          const bUserObject: any = b.userObject;

          if (aUserObject.wbs !== bUserObject.wbs) {
            return aUserObject.wbs?.localeCompare(bUserObject.wbs);
          }
          if (aUserObject.businessId !== bUserObject.businessId) {
            return aUserObject.businessId?.localeCompare(bUserObject.businessId);
          }
          return a.name?.localeCompare(b.name);
        }
      },

      { key: CustomSettingKeys.SCENARIO_ID, value: iocContainer.get<string>(AppIocSymbols.$selectedScenario) },
      { key: CustomSettingKeys.LOCAL_STORAGE_PREFIX, value: "gantt#tasks#" },
      { key: CustomSettingKeys.FILTER, value: data.filter ?? null },
      { key: CustomSettingKeys.ACTIVITY_FILL_COLOR_SCHEDULING, value: data.activity!.schedulingActivityFillColor },
      { key: CustomSettingKeys.ACTIVITY_FILL_COLOR_EXECUTION, value: data.activity!.executionActivityFillColor },
      { key: CustomSettingKeys.ACTIVITY_TEXT_COLOR_SCHEDULING, value: data.activity!.schedulingActivityTextColor },
      { key: CustomSettingKeys.ACTIVITY_TEXT_COLOR_EXECUTION, value: data.activity!.executionActivityTextColor },
      { key: CustomSettingKeys.ACTIVITY_STATUS_SETTINGS, value: iocContainer.get<AppContextDto>(AppIocSymbols.$appContext).statusSettings ?? null },
      { key: CustomSettingKeys.STEP_STATUS_COLOR_SETTINGS, value: iocContainer.get<AppContextDto>(AppIocSymbols.$appContext).stepStatusSettings ?? null },
      { key: CustomSettingKeys.ACTIVITY_TOOLTIP_DISPLAYED_PROPERTIES, value: data.activity!.activityTooltipDisplayProperties },
      { key: CustomSettingKeys.ACTIVITY_DISPLAYED_PROPERTIES, value: data.activity!.schedulingActivityDisplayProperties },
      { key: CustomSettingKeys.LAYERS, value: data.layers ?? [] }
    ]);
    return;
  }
} as IGanttSettingsLoader;

export function rowFactoryIocExtension(container: interfaces.Container) {
  container.bind<DemandSupplyScaleRowInfoTemplateCellCanvas<any>>(DemandSupplyScaleRowInfoTemplateCellCanvas<any>).to(DemandSupplyScaleRowInfoTemplateCellCanvas).inSingletonScope();
}

export async function load(container: IocContainer, options: IResourcesGanttOptions) {
  container.bind<TaskRowFactory>(IocSymbols.RowFactorySymbol).to(TaskRowFactory).inSingletonScope().onActivation(onActivationLifecycle);

  container.bind<TaskGanttActivityFactory>(IocSymbols.ActivityFactory).to(TaskGanttActivityFactory).inSingletonScope().onActivation(onActivationLifecycle);
  container.bind(IocSymbols.GanttSettingsLoader).toConstantValue(settingsLoader);
  container
    .bind<ProductionTaskChangeNotificationHandler>(ProductionTaskChangeNotificationHandler.name)
    .to(ProductionTaskChangeNotificationHandler)
    .inSingletonScope()
    .onActivation(onActivationLifecycle);

  // container
  //   .bind<IRowChartScaleProvider>(IocSymbols.RowChartScaleProviderClass)
  //   .toConstructor(ResourceFixedRowChartScaleProvider)
  //   .whenTargetNamed("ResourceFixedRowChartScaleProvider");

  container.bind<NoteHandler>(NoteHandler).to(NoteHandler).inSingletonScope().onActivation(onActivationLifecycle);

  const activityRendererRegistry = await container.getAsync<ActivityRendererRegistry>(ActivityRendererRegistry);
  activityRendererRegistry.registerActivityRenderer(TaskSchedulingActivity, TaskSchedulingActivityRenderer);
  activityRendererRegistry.registerActivityRenderer(TaskExecutionActivity, TaskExecutionActivityRenderer);

  const activityTooltipRendererRegistry = await container.getAsync<ActivityTooltipRendererRegistry>(ActivityTooltipRendererRegistry);
  activityTooltipRendererRegistry.registerActivityTooltipRenderer(TaskSchedulingActivity, CustomTaskActivityTooltipRenderer);
  activityTooltipRendererRegistry.registerActivityTooltipRenderer(TaskExecutionActivity, CustomTaskActivityTooltipRenderer);
  //activityTooltipRendererRegistry.registerDefaultActivityTooltipRenderer();

  container.unbind(IocSymbols.RowInfoColumnProvider);
  container.bind<TaskGanttRowInfoColumnProvider>(IocSymbols.RowInfoColumnProvider).to(TaskGanttRowInfoColumnProvider).inSingletonScope().onActivation(onActivationLifecycle);

  const infoColumnCellRegistry = await container.getAsync<IRowInfoColumnCellRegistry>(IocSymbols.RowInfoColumnCellRegistry);

  infoColumnCellRegistry.register("external-id", RowBusinessIdRowInfoTemplateCell);
  infoColumnCellRegistry.register("text", RowNameRowInfoTemplateCell);
  infoColumnCellRegistry.register("wbs", RowWbsRowInfoTemplateCell);
}
