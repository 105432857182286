import { onBeforeMount, onBeforeUnmount } from "vue";
import { NotificationHandler } from "@/notifications/notification-handler";
import { useSignalR } from "@/plugins/signalR.plugin";

export const useNotification = (hubMethodName: string, handlerFunction: (...args: any[]) => void) => {
  const signalR = useSignalR();
  let handler: NotificationHandler | null = null;
  onBeforeMount(() => {
    if (!signalR) return;
    handler = new NotificationHandler(signalR, hubMethodName);
    if (handler) {
      handler.subscribe(handlerFunction);
    }
  });
  onBeforeUnmount(() => {
    if (handler) {
      handler.unsubscribe();
    }
  });
};
