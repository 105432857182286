<script lang="ts">
import { ICellRendererParams } from 'ag-grid-community';
import CloseCardButton from "@/components/CloseCardButton.vue";
import CodeEditor from "@/components/CodeEditor.vue";
import { ref } from 'vue';
import { $t } from "@/i18n";

export default {
  components: { CloseCardButton, CodeEditor },
  setup(props: { params: ICellRendererParams }) {
    const value = props.params.value;

    const slicedValue = value && value.length > 50 ? value.slice(0, 50) + "..." : value;

    const showDialog = ref<boolean>(false);
    const formattedValue = ref<string>();

    function formatValue(jsonValue: string) {
      try {
        return JSON.stringify(JSON.parse(jsonValue), null, 2);
      } catch (error) {
        return jsonValue;
      }
    }

    function onShowClicked() {
      formattedValue.value = formatValue(value);

      showDialog.value = true;
    }

    function onCloseDialogClicked() {
      showDialog.value = false;
    }

    return {
      value,
      slicedValue,
      showDialog,
      formattedValue,
      onShowClicked,
      onCloseDialogClicked,
    };
  }
};
</script>

<template>
  <div class="long-text-cell-renderer-cell">
    <span v-if="slicedValue" class="long-text-cell-renderer-cell-value">{{ slicedValue }}</span>
    <v-btn 
      class="long-text-cell-renderer-cell-btn" 
      icon="mdi-magnify-expand" color="primary" variant="plain"
      size="x-small" @click="onShowClicked">
    </v-btn>
  </div>
  <v-dialog class="long-text-cell-renderer-dialog" width="70vw" height="70vh" :model-value="showDialog" @click:outside="onCloseDialogClicked">
    <v-card min-width="300px" height="100%" class="assignment-editor-card" location="center center">
      <close-card-button @click="onCloseDialogClicked"></close-card-button>
      <v-card-title class="pa-0">
        <div class="text-h4 pb-5">
          {{ $t("longText-renderer-Preview-label", { $: "Preview" }) }}
        </div>
      </v-card-title>
      <v-card-text class="d-flex flex-grow-1 pa-0">
        <v-container>
          <code-editor :model-value="formattedValue" language="json" readonly />
        </v-container>
      </v-card-text>
      <v-card-actions class="pa-0">
        <div class="left-col pl-0">
        </div>
        <div class="middle-col"></div>
        <div class="right-col pr-0">
          <v-btn variant="elevated" color="primary" class="w-100" @click="onCloseDialogClicked">{{ $t("longText-renderer-close-action", { $: "Close" }) }}</v-btn>
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>


<style lang="scss">
.long-text-cell-renderer-cell {
  display: grid;
  grid-template-columns: 1fr auto;
  grid-template-rows: 1fr;
  align-items: center;

  .long-text-cell-renderer-cell-value {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .long-text-cell-renderer-cell-btn {
    padding: 0.31em;
    margin-left: 0.31em;
  }
}

.long-text-cell-renderer-dialog {
  .long-text-cell-renderer-pre {
    text-wrap: wrap;
  }
}
</style>