import { Language } from "rrule/dist/esm/nlp/i18n";
import { GetText } from "rrule/dist/esm/nlp/totext";
import { useI18n } from "vue-i18n";

import { $dateFormatterSymbol, $dateSymbol, DateFormatter } from "@masta/shared";
import { inject } from "vue";
import { RRule } from "rrule";
import { Locale } from "@/i18n";

/**
 * This hook, useRecurrenceRuleText, is designed to integrate internationalization (i18n) 
 * support into the RRule.toText() method from the powerful rrule.js library
 * 
 * When RRule.toText() is called by default, this method will return the recurence rule text in English. 
 * But sometimes, we want to display the recurrence rule text in the user language.
 * 
 * In order to do that, we need to invoke RRule.toText() with  3 additional parameters RRule.toText(getText, language, dateFormatter)
 * - getText - function that will be called on each word in the recurrence rule text,
 * - language - object with dayNames and monthNames properties,
 * - dateFormatter - function that will be called on each date in the recurrence rule text.
 * 
 * Otherwords, rrule.js tries to replace each word in the recurrence rule english text with the user language word.
 * Unfortunately, this approach doesn't work well with e.g. German, as words cannot be just replaced with their german equivalent.
 */
export const useRecurrenceRuleText = () => {
  const i18n = useI18n();
  const $t = i18n.t;
  const locale = i18n.locale
  
  const $dateFormatter = inject<DateFormatter>($dateFormatterSymbol)!;

  const currentLocaleStrings: { [key: string]: string } = {
    'every' : $t("recurrence-rule-text-every", { $: "every" }),
    'on' : $t("recurrence-rule-text-on", { $: "on" }),
    'in' : $t("recurrence-rule-text-in", { $: "in" }),
    'at' : $t("recurrence-rule-text-at", { $: "at" }),
    'and' : $t("recurrence-rule-text-and", { $: "and" }),
    'or' : $t("recurrence-rule-text-or", { $: "or" }),
    'on the' : $t("recurrence-rule-text-on-the", { $: "on the" }),
    'nd' : $t("recurrence-rule-text-on-the", { $: "nd" }),
    'rd' : $t("recurrence-rule-text-on-the", { $: "rd" }),
    'th' : $t("recurrence-rule-text-on-the", { $: "th" }),
    'the' : $t("recurrence-rule-text-the", { $: "the" }),
    'first' : $t("recurrence-rule-text-first", { $: "first" }),
    'second' : $t("recurrence-rule-text-second", { $: "second" }),
    'third' : $t("recurrence-rule-text-third", { $: "third" }),
    'last' : $t("recurrence-rule-text-last", { $: "last" }),
    'until' : $t("recurrence-rule-text-until", { $: "until" }),
    'for' : $t("recurrence-rule-text-for", { $: "for" }),
    'time' : $t("recurrence-rule-text-time", { $: "time" }),
    'times' : $t("recurrence-rule-text-times", { $: "times" }),
    'week' : $t("recurrence-rule-text-week", { $: "week" }),
    'weeks' : $t("recurrence-rule-text-weeks", { $: "weeks" }),
    'month' : $t("recurrence-rule-text-month", { $: "month" }),
    'months' : $t("recurrence-rule-text-months", { $: "months" }),
    'weekdays' : $t("recurrence-rule-text-weekdays", { $: "weekdays" }),
    'weekday' : $t("recurrence-rule-text-weekday", { $: "weekday" }),
    'hours' : $t("recurrence-rule-text-hours", { $: "hours" }),
    'hour' : $t("recurrence-rule-text-hour", { $: "hour" }),
    'minutes' : $t("recurrence-rule-text-minutes", { $: "minutes" }),
    'minute' : $t("recurrence-rule-text-minute", { $: "minute" }),
    'day' : $t("recurrence-rule-text-day", { $: "day" }),
    'days' : $t("recurrence-rule-text-days", { $: "days" }),
  };

  const dayNames: string[] = [
    $t("recurrence-rule-dayNames-Sunday", { $: "Sunday" }),
    $t("recurrence-rule-dayNames-Monday", { $: "Monday" }),
    $t("recurrence-rule-dayNames-Tuesday", { $: "Tuesday" }),
    $t("recurrence-rule-dayNames-Wednesday", { $: "Wednesday" }),
    $t("recurrence-rule-dayNames-Thursday", { $: "Thursday" }),
    $t("recurrence-rule-dayNames-Friday", { $: "Friday" }),
    $t("recurrence-rule-dayNames-Saturday", { $: "Saturday" })
  ];

  const monthNames: string[] = [
    $t("recurrence-rule-monthNames-January", { $: "January" }),
    $t("recurrence-rule-monthNames-February", { $: "February" }),
    $t("recurrence-rule-monthNames-March", { $: "March" }),
    $t("recurrence-rule-monthNames-April", { $: "April" }),
    $t("recurrence-rule-monthNames-May", { $: "May" }),
    $t("recurrence-rule-monthNames-June", { $: "June" }),
    $t("recurrence-rule-monthNames-July", { $: "July" }),
    $t("recurrence-rule-monthNames-August", { $: "August" }),
    $t("recurrence-rule-monthNames-September", { $: "September" }),
    $t("recurrence-rule-monthNames-October", { $: "October" }),
    $t("recurrence-rule-monthNames-November", { $: "November" }),
    $t("recurrence-rule-monthNames-December", { $: "December" })
  ];


  const getText: GetText = (id:any): string => {
    if(currentLocaleStrings[id] === undefined) console.debug(`Missing rrule test translation for ${id}`);
    return currentLocaleStrings[id] || id.toString();
  };

  const language: Language = {
    dayNames: dayNames,
    monthNames: monthNames,

    tokens: {
        // `tokens` are only needed for `RRule.fromText` - ignored here
    }
  };

  const dateFormatter = (year: number, month: string, day: number): string => {
    const numericMonth = monthNames.indexOf(month);
    if (numericMonth && numericMonth !== -1) {
        const date = new Date(year, numericMonth, day);
        return $dateFormatter(date);
    } else {
        // month not found in monthNames
        return `${month} ${day}, ${year}`;
    }
  };

  /**
   * Wrapper around RRule.toText() method. If user language is not English, then invoke RRule.toText() with 3 additional parameters.
   */
  const toText = (rrule: RRule): string => {
    if(locale.value === Locale.English) {
        // for English use default toText() method
        return rrule.toText();
      } else {
        return rrule.toText(getText, language, dateFormatter);
      }
  }

  return {
    toText,
    getText,
    language,
    dateFormatter,
  };
};

