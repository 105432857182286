<script lang="ts" setup>
import { useExecutionIssuesStore } from "@/store/ExecutionIssuesStore";
import Breadcrumbs from "@/components/Layout/Breadcrumbs.vue";
import MasterDetailLayout from "@/components/Layout/MasterDetailLayout.vue";
import { ref } from "vue";
import { storeToRefs } from "pinia";
import SystemEnumService from "@/services/system-enum.service";
import { ExecutionIssueSolutionDto, ExecutionIssueSolutionType } from "@masta/generated-model";
import { $t } from "@/i18n";
import { useSnackbarsStore } from "@/store/SnackbarsStore";

const snackbarsStore = useSnackbarsStore();
const issueStore = useExecutionIssuesStore();
const { issues } = storeToRefs(issueStore);

const detail = ref(false);
const selectedItems = ref(new Set<number>());
const selectedSolution = ref(new Map<number, ExecutionIssueSolutionDto>);

async function loadIssues() {
  selectedItems.value.clear();
  selectedSolution.value.clear();
  await issueStore.fetchIssues();
}

function getSelected(index: number) {
  return selectedItems.value.has(index);
}

function changeSelected(index: number, selected: boolean) {
  selected ? selectedItems.value.add(index) : selectedItems.value.delete(index);
}

function getSelectedSolution(index: number) {
  if (!selectedSolution.value.has(index)) {
    const issue = issues.value[index];
    if (issue.solutions) {
      selectedSolution.value.set(index, issue.solutions[0]);
    } else {
      return null;
    }
  }
  return selectedSolution.value.get(index);
}

function changeSelectedSolution(index: number, solution: ExecutionIssueSolutionDto) {
  selectedSolution.value.set(index, solution);
}

function selectAll() {
  for (let i = 0; i < issues.value.length; i++) {
    selectedItems.value.add(i);
  }
}

function unselectAll() {
  selectedItems.value.clear();
}

async function fixSelected() {
  for (const index of selectedItems.value.values()) {
    const solution = selectedSolution.value.get(index);
    if (!solution?.correctingRecords) continue;
    await issueStore.sendRecords(solution.correctingRecords);
  }
  await snackbarsStore.createSnackbar({
    message: $t("inconsistencyDetection-view-onFixSuccess-message", { $: "Issues are being fixed" }),
    type: "success",
    closeable: true
  });
}
</script>

<template>
  <master-detail-layout :detail="detail" detail-dialog-width="75%" @change="detail = false">
    <template #master>
      <v-card elevation="0" class="flexcard fill-height">
        <v-card-title>
          <breadcrumbs>
            {{ $t("inconsistencyDetection-view-inconsistencyDetection-title", { $: "Inconsistency Detection" }) }}
          </breadcrumbs>
        </v-card-title>
        <v-card-text class="fill-height">
          <v-row>
            <v-col class="d-flex flex-row">
              <v-btn class="ma-2" @click="loadIssues">{{ $t("inconsistencyDetection-view-find-button", { $: "Find" }) }} </v-btn>
              <v-btn class="ma-2" @click="selectAll()">
                {{ $t("inconsistencyDetection-view-selectAll-button", { $: "Select All" }) }}
              </v-btn>
              <v-btn class="ma-2" @click="unselectAll()">
                {{ $t("inconsistencyDetection-view-unselectAll-button", { $: "Unselect All" }) }}
              </v-btn>
              <v-btn class="ma-2" :disabled="selectedItems.size == 0" @click="fixSelected()"
                >{{ $t("inconsistencyDetection-view-fixSelected-button", { $: "Fix Selected" }) }}
              </v-btn>
            </v-col>
          </v-row>
          <v-row class="mt-10 mx-auto d-flex flex-column align-stretch">
            <v-card v-for="(issue, index) in issues" :key="index" class="ma-4">
              <v-card-title>
                <v-checkbox
                  density="compact"
                  :label="issue.task.name"
                  :model-value="getSelected(index)"
                  :disabled="!issue.solutions"
                  @update:model-value="changeSelected(index, $event)"
                ></v-checkbox>
              </v-card-title>
              <v-card-subtitle>{{ issue.task.businessId }} ({{ SystemEnumService.totalStatus(issue.task.status) }}) </v-card-subtitle>
              <v-card-text>
                <v-table>
                  <thead>
                    <tr>
                      <th class="text-left">
                        {{ $t("inconsistencyDetection-view-stepPosition-columnHeader", { $: "Position" }) }}
                      </th>
                      <th class="text-left">{{ $t("inconsistencyDetection-view-stepName-columnHeader", { $: "Name" }) }}</th>
                      <th class="text-left">
                        {{ $t("inconsistencyDetection-view-stepExecutionStatus-columnHeader", { $: "Status" }) }}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="step in issue.task.steps" :key="step.id">
                      <td>{{ step.position }}</td>
                      <td>{{ step.name }}</td>
                      <td>{{ SystemEnumService.totalStatus(step?.status) }}</td>
                    </tr>
                  </tbody>
                </v-table>
              </v-card-text>
              <v-card-actions>
                <v-select
                  :items="issue.solutions"
                  return-object
                  :item-title="(item) => SystemEnumService.solutionType(item.solutionType)"
                  :model-value="getSelectedSolution(index)"
                  @update:model-value="changeSelectedSolution(index, $event)"
                />
              </v-card-actions>
            </v-card>
          </v-row>
        </v-card-text>
      </v-card>
    </template>
    <template #detail></template>
  </master-detail-layout>
</template>
