import { ChartDataRecord } from "@/components/Gantt/ResourcesGantt/DragAndDrop/ChartDataRecord";
import { RowContainer } from "@masta/gantt2/gantt";
import { IRowChartDataSet, OverlappingTimeIntervalTreeActivityRepository, Row, TimelineManager } from "@masta/gantt2/core";
import { DemandSchedulingResourceCapacityActivity, ResourceCapacityActivity } from "@/components/Gantt/Common/Model";
import { Instant } from "@js-joda/core";

export interface EarliestViableDate {
  initialViableDate: Instant;
  earliestViableDate: Instant;
}

export class RowDropZone {
  private readonly _chartData: ChartDataRecord[];

  public static readonly SnapAreaWidth = 5;

  public initialViableDatePosX: number | null;
  public earliestViableDatePosX: number | null;

  constructor(public readonly container: RowContainer<any>, dataSets: IRowChartDataSet[], private _timelineManager: TimelineManager, private _earliestViableDate?: EarliestViableDate) {
    this._chartData = dataSets.filter(x => x.resourceId === container.row.id).find(x => x.id.startsWith("supply"))?.data.map(data => ({
      ...data,
      xPos: Math.round(this._timelineManager.calculateLocationForTimeMillis(data.x))
    })) ?? [];
    this.recalculateViableDatePosition();
  }

  get row(): Row<any, any, any> {
    return this.container.row;
  }

  get chartData() {
    return this._chartData;
  }

  public recalculateChartDataPosition() {
    for (const data of this._chartData) {
      data.xPos = Math.round(this._timelineManager.calculateLocationForTimeMillis(data.x));
    }
    this.recalculateViableDatePosition();
  }

  private recalculateViableDatePosition() {
    this.initialViableDatePosX = this._earliestViableDate && this._earliestViableDate.initialViableDate ? Math.round(this._timelineManager.calculateLocationForTimeMillis(this._earliestViableDate.initialViableDate.toEpochMilli())) : null;
    this.earliestViableDatePosX = this._earliestViableDate && this._earliestViableDate.earliestViableDate ? Math.round(this._timelineManager.calculateLocationForTimeMillis(this._earliestViableDate.earliestViableDate.toEpochMilli())) : null;
  }

  isDropAllowedForSupplyArea(mousePosX: number): boolean {
    const nowTimeMillis = this._timelineManager.nowTime.toEpochMilli();
    const atMousePos = this.chartData.filter(x => x.x >= nowTimeMillis).find(x => x.xPos >= mousePosX);
    return atMousePos ? atMousePos.y > 0 : false;
  }

  getSnapXCoordinate(mousePosX: number, snapAreaWidth: number, schedulingLengthMillis: number | null) {
    const nowTime = this._timelineManager.nowTime;
    const nowTimeMillis = nowTime.toEpochMilli();
    const nowTimeX = this._timelineManager.calculateLocationForTime(nowTime);
    const snapNowX = nowTimeX + snapAreaWidth;
    const mousePosTime = this._timelineManager.calculateTimeForLocation(mousePosX);
    const atMousePos = this.chartData.filter(x => x.x >= nowTimeMillis).find(x => x.xPos >= mousePosX);

    const firstLeft = [...this.chartData].reverse().find(x => x.xPos <= mousePosX);
    let snapPosX = mousePosX;
    if (firstLeft && mousePosX < firstLeft.xPos + snapAreaWidth && mousePosX >= firstLeft.xPos && atMousePos && atMousePos.y > 0) {
      snapPosX = firstLeft.xPos;
    }

    if (snapPosX === mousePosX) {
      const leftNearestActivityMousePosTime = this._timelineManager.calculateTimeForLocation(mousePosX - snapAreaWidth < 0 ? mousePosX : mousePosX - snapAreaWidth);
      const endTime = mousePosTime.plusMillis(schedulingLengthMillis ?? 0);
      const activities = [...(this.container.row.repository as OverlappingTimeIntervalTreeActivityRepository<ResourceCapacityActivity>)
        .getActivitiesByStartEndTime(leftNearestActivityMousePosTime, endTime)]
        .filter((activity) => activity.constructor.name === DemandSchedulingResourceCapacityActivity.name)
      ;
      if (activities.length > 0) {
        const activityToSnapTo = activities[activities.length - 1];
        const activityEndX = this._timelineManager.calculateLocationForTime(activityToSnapTo.endTime);
        if (activityEndX < mousePosX && mousePosX < activityEndX + snapAreaWidth) {
          snapPosX = activityEndX;
        }
      }
    }

    return snapPosX < snapNowX ? nowTimeX : snapPosX;
  }
}
