import { useRoute } from "vue-router";

export const useGridContext = (dynamicAttrs: any) => {
  const params = [];

  const route = useRoute();
  if (route.query) {
    for (const [key, value] of Object.entries(route.query)) {
      if (key.toLowerCase().startsWith("ctx_")) {
        let v = null;
        if (Array.isArray(value)) {
          v = [...value];
        } else {
          v = value?.split(",");
        }
        params.push({
          key: key.slice(4),
          value: v
        });
      }
    }
  }

  dynamicAttrs.context = {
    routeParams: params
  };
};
