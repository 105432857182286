<template>
  <split-layout ref="splitLayoutRef" identifier="materials" :gutter="createSplitPanelGutterElement">
    <template #master>
      <v-card elevation="0" class="flexcard fill-height">
        <v-card-title>
          <breadcrumbs>{{ $t("material-view-materials-title", { $: "Materials" }) }}</breadcrumbs>
        </v-card-title>
        <v-card-text class="fill-height">
          <v-row class="fill-height" no-gutters>
            <v-col cols="12">
              <material-grid
                ref="grid"
                :reference-value="$route.query.materialId?.toString()"
                @manage-availability-rules="manageAvailabilityRules"
                @resource2resource="resource2resourceAction"
                @resource2resource-reverse="resource2resourceReverseAction"
                @selection-changed="onMaterialSelected"
                @resource-updated="onResourceUpdated"
              />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-col v-if="editAvailabilityRulesAssignmentDialog">
        <availability-rules-assignment-editor
          :resource="material"
          :opened="editAvailabilityRulesAssignmentDialog"
          @cancel="editAvailabilityRulesAssignmentDialog = false"
          @save="editAvailabilityRulesAssignmentDialog = false"
        />
      </v-col>
      <resource2-resource-dialog
        v-if="resourceType >= 0 && material"
        :opened="resource2resourceDialog"
        :assignment-type="resourceAssignmentType"
        :resource-type="resourceType"
        :resource="material"
        :reverse="isReverseResource2ResourceAssignment"
        @close="resource2resourceDialogClose"
      />
    </template>
    <template #detail>
      <material-details v-if="material" v-model="material" :schema-tabs="schemaTabs" @updated="materialUpdated" @closed="onCloseDetails" />
      <v-card v-if="!material" elevation="7" class="d-flex flex-column fill-height no-materials-card">
        <close-card-button @click="onCloseDetails"></close-card-button>
        <v-card-text class="fill-height">
          <v-row class="fill-height">
            <v-col cols="12" class="d-flex align-center justify-center">
              <label class="text-secondary text-h6 font-weight-regular">
                {{ $t("material-view-noMaterialsSelected-title", { $: "No material selected" }) }}
              </label>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </template>
  </split-layout>
</template>

<script lang="ts" setup>
import CloseCardButton from "@/components/CloseCardButton.vue";
import SplitLayout from "@/components/Layout/SplitLayout.vue";
import { $t } from "@/i18n";

/**
 * Called to create gutter element for split panel.
 */
function createSplitPanelGutterElement(index: number, direction: "horizontal" | "vertical") {
  const gutter = document.createElement("div");
  gutter.classList.add("gutter-materials");
  return gutter;
}
</script>

<script lang="ts">
import AvailabilityRulesAssignmentEditor from "@/components/AvailabilityRules/AvailabilityRulesAssignment/AvailabilityRulesAssignmentEditor.vue";
import { getSelectedRows } from "@/components/Grid/UseGridSelection";
import Breadcrumbs from "@/components/Layout/Breadcrumbs.vue";
import MaterialDetails from "@/components/Material/MaterialDetails.vue";
import MaterialGrid from "@/components/Material/MaterialGrid.vue";
import Resource2ResourceDialog from "@/components/Resources/ResourceAssignment/Resource2ResourceDialog.vue";
import ApiService from "@/services/api";
import { useErrorsStore } from "@/store/ErrorsStore";
import { useModelInstancesStore } from "@/store/ModelInstancesStore";
import { useScenariosStore } from "@/store/ScenariosStore";
import { useSystemStore } from "@/store/SystemStore";
import { MaterialDto, ResourceAssignmentType } from "@masta/generated-model";
import { SelectionChangedEvent } from "ag-grid-community";
import { mapActions, mapState } from "pinia";

export default {
  name: "Material",
  components: {
    MaterialDetails,
    Breadcrumbs,
    Resource2ResourceDialog,
    AvailabilityRulesAssignmentEditor,
    MaterialGrid
  },
  title: "Material",
  data() {
    return {
      editAvailabilityRulesAssignmentDialog: false,
      material: null,
      refreshGridAction: null,
      resource2resourceDialog: false,
      resourceType: null,
      resourceAssignmentType: ResourceAssignmentType.MaterialToScrap,
      isReverseResource2ResourceAssignment: false,
      schemaTabs: []
    };
  },
  computed: {
    ...mapState(useModelInstancesStore, ["schemas"]),
    ...mapState(useSystemStore, ["modelInstanceTabs"])
  },
  created() {
    this.$watch(
      () => this.$route.query.materialId, async (materialId, _) => {
        if (materialId) {
          if (this.material && materialId === this.material.id) {
            return;
          } else {
            const material = await this.fetchMaterial(materialId);
            if (material) {
              this.material = material;
            }
          }
        } else {
          this.material = null;
        }
      },
      { immediate: true }
    );
    this.$watch(
      () => this.material, async (newVal, _) => {
        if (newVal) {
          this.onShowDetails();
        } else {
          this.onCloseDetails();
        }
      }
    );
  },
  mounted() {
    this.loadTabSchemas();
  },
  methods: {
    ...mapActions(useModelInstancesStore, ["fetchSchemas"]),
    async fetchMaterial(materialId: string) {
      try {
        const scenariosStore = useScenariosStore();
        const selectedScenario = scenariosStore.selectedScenario;

        if (!selectedScenario) {
          return null;
        }

        const response = await ApiService.materials.getSingle(materialId, selectedScenario.id);
        return response.data;
      } catch (error) {
        const errorsStore = useErrorsStore();
        errorsStore.handleError(error);
      }
      return null;
    },
    manageAvailabilityRules(material) {
      this.material = material;
      this.editAvailabilityRulesAssignmentDialog = true;
    },
    resource2resourceAction(resourceType, material) {
      this.isReverseResource2ResourceAssignment = false;
      this.resourceType = resourceType;
      this.material = material;
      this.resource2resourceDialog = true;
    },
    resource2resourceReverseAction(resourceType, material) {
      this.isReverseResource2ResourceAssignment = true;
      this.resourceType = resourceType;
      this.material = material;
      this.resource2resourceDialog = true;
    },
    resource2resourceDialogClose() {
      this.isReverseResource2ResourceAssignment = false;
      this.resource2resourceDialog = false;
      this.resourceType = null;
      this.material = null;
    },
    onShowDetails() {
      this.$refs.splitLayoutRef.showDetails();
    },
    onCloseDetails() {
      this.$refs.splitLayoutRef.closeDetails();
    },
    async onMaterialSelected(event: SelectionChangedEvent) {
      const selectedRows = getSelectedRows(event.api);
      if (selectedRows.length === 0) {
        this.material = null;
        return;
      }

      const selectedMaterial = selectedRows[0];

      if (selectedMaterial) {
        this.material = selectedMaterial;

        this.onShowDetails();

        const currentQuery = this.$router.currentRoute.value.query;
        const newQuery = Object.assign({}, currentQuery, { materialId: selectedMaterial.id });
        await this.$router.push({ query: newQuery });
      }
    },
    async loadTabSchemas() {
      await this.fetchSchemas();
      for (const tab of this.modelInstanceTabs?.materials ?? []) {
        const schema = this.schemas.find((x) => x.schemaName === tab.schemaName && x.schemaKey === tab.schemaKey && x.version === tab.schemaVersion);
        if (schema) {
          this.schemaTabs.push({ title: tab.title ?? "", schema, contextName: tab.contextName ?? "", resourceSubTypes: tab.resourceSubTypes });
        }
      }
    },
    async materialUpdated(id: string) {
      (this.$refs.grid as typeof MaterialGrid).refreshSingle(id);
    },
    onResourceUpdated(resource?: MaterialDto) {
      this.material = resource as any;
    }
  }
};
</script>

<style lang="scss" scoped>
.no-materials-card {
  border: 2px solid rgb(var(--v-theme-primary), 0.0);
  border-radius: 0;

  .close-card-button {
    position: absolute;
    top: 0px;
    right: 10px;
  }
}
</style>
