import { Lifecycle } from "../../Lifecycle";
import { IRowChartScaleProvider } from "../../Model";
import { BehaviorSubject, Observable } from "rxjs";

export abstract class RowChartScaleProviderBase extends Lifecycle implements IRowChartScaleProvider {

  protected _scaleUnit$$: BehaviorSubject<string | undefined> = new BehaviorSubject<string | undefined>(undefined);

  scaleValues$$ = new BehaviorSubject<number[]>([]);
  scaleValues$: Observable<number[]> = this.scaleValues$$.asObservable();

  protected constructor() {
    super();
  }

  get scaleUnit$(): Observable<string | undefined> {
    return this._scaleUnit$$.asObservable();
  }

  get scaleUnit(): string | undefined {
    return this._scaleUnit$$.value;
  }

  get scaleValues(): number[] {
    return this.scaleValues$$.getValue();
  }
}
