import { GuiModelItem, GuiObjectType } from "./GuiModelItem";

export class NumberPropertyModel implements GuiModelItem {
  public value: number | undefined = 0;
  public readonly type = GuiObjectType.Number;
  public min: number | undefined;
  public max: number | undefined;
  public exclusiveMin: number | undefined;
  public exclusiveMax: number | undefined;
  public color: string | undefined;
  public icon: string | undefined;

  constructor(public name: string, public isInteger: boolean) {}

  getValue(): any {
    return this.value;
  }

  isValid(): boolean {
    if (this.max && this.value && this.value > this.max) {
      return false;
    }
    return true;
  }

  setValue(value: any): void {
    if (typeof value !== "number") {
      throw new Error("Setting non-number value to number");
    }
    this.value = value;
  }
}
