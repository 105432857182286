import { Duration, Instant } from "@js-joda/core";

import { ActivityBase } from "./ActivityBase";
import { IMutableActivity } from "./IMutableActivity";

export abstract class MutableActivityBase<TActivity> extends ActivityBase<TActivity> implements IMutableActivity {
  constructor(id: string | undefined, name: string, startTime: Instant = Instant.now(), endTime: Instant = Instant.now().plus(ActivityBase.DEFAULT_DURATION)) {
    super(id, name, startTime, endTime);
  }

  get duration(): Duration {
    return Duration.between(this._startTime, this._endTime);
  }

  addDuration(duration: Duration, multipliedBy = 1): void {
    this._endTime = this._endTime.plus(duration.multipliedBy(multipliedBy));
  }
}
