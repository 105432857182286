<script lang="ts" setup>
import { usePersonnelStore } from "@/store/PersonnelStore";
import { storeToRefs } from "pinia";
import { onBeforeMount, ref } from "vue";
import { useEquipmentsStore } from "@/store/EquipmentsStore";
import { useI18n } from "vue-i18n";
import { ResourceType } from "@masta/generated-model";
import { useMaterialsStore } from "@/store/MaterialsStore";
import { useLocationsStore } from "@/store/LocationsStore";

const props = withDefaults(
  defineProps<{
    resourceTypeLabel: string;
    resourceLabel: string;
    disableTypeSelection?: boolean;
    disabled?: boolean;
    multiple?: boolean;
    orientation?: "horizontal" | "vertical";
    resourceType: "Person" | "Agreement" | "Equipment" | "EquipmentGroup" | "EquipmentWithGroups" | "Material" | "Location" | "Asset" | "Service" | "Calendar";
    hideDetails?: boolean;
    rules?: any;
  }>(),
  {
    disabled: false,
    multiple: false,
    resourceTypeLabel: "Type",
    resourceLabel: "Resource",
    orientation: "horizontal",
    rules: []
  }
);

const emit = defineEmits(["resourceSelected"]);

const allResourceTypes = ["Person", "Agreement", "Equipment", "EquipmentGroup", "EquipmentWithGroups", "Material", "Location", "Asset", "Service", "Calendar"];

const { t: $t } = useI18n();
const selectedResource = ref<any>(null);
const selectedType = ref<string>(props.resourceType);
const personnelStore = usePersonnelStore();
const { personnel } = storeToRefs(personnelStore);
const equipmentsStore = useEquipmentsStore();
const { equipments } = storeToRefs(equipmentsStore);
const materialsStore = useMaterialsStore();
const { materials } = storeToRefs(materialsStore);
const locationsStore = useLocationsStore();
const { locations } = storeToRefs(locationsStore);

const hint = ref<string>("");

async function onSelectedTypeChanged(newType: string) {
  selectedType.value = newType;
  await checkResourceType();
}

function onSelectedResourceChanged(newResource: any) {
  selectedResource.value = newResource;
  emit("resourceSelected", newResource);
}

const items = ref<any[]>([]);

onBeforeMount(async () => {
  await checkResourceType();
});

async function checkResourceType() {
  switch (selectedType.value) {
    case "Person":
      await personnelStore.fetch();
      items.value = personnel.value;
      hint.value = $t("resourceSelector-personnel-filter-hint", { $: "Type in supply value" });
      break;
    case "Equipment":
      await equipmentsStore.fetch(`Type = ${ResourceType.Equipment}`);
      items.value = equipments.value;
      hint.value = $t("resourceSelector-equipment-filter-hint", { $: "Type in equipment value" });
      break;
    case "EquipmentGroup":
      await equipmentsStore.fetch(`Type = ${ResourceType.EquipmentGroup}`);
      items.value = equipments.value;
      hint.value = $t("resourceSelector-equipmentGroup-filter-hint", { $: "Type in equipment group value" });
      break;
    case "EquipmentWithGroups":
      await equipmentsStore.fetch();
      items.value = equipments.value;
      hint.value = $t("resourceSelector-equipmentAndGroup-filter-hint", { $: "Type in equipment/group value" });
      break;
    case "Agreement":
    case "Material":
      await materialsStore.fetch();
      items.value = materials.value;
      hint.value = $t("resourceSelector-material-filter-hint", { $: "Type in material value" });
      break;
    case "Location":
      await locationsStore.fetch();
      items.value = locations.value;
      hint.value = $t("resourceSelector-location-filter-hint", { $: "Type in location value" });
      break;
    case "Asset":
    case "Service":
    case "Calendar":
    default:
      throw new Error(`Resource type ${props.resourceType} not implemented!`);
  }
}
</script>

<template>
  <div class="d-flex align-center justify-center" :class="{ 'flex-column': orientation === 'vertical' }">
    <v-autocomplete
      v-if="!disableTypeSelection"
      variant="outlined"
      density="compact"
      :hide-details="hideDetails"
      :model-value="selectedType"
      :items="allResourceTypes"
      :label="resourceTypeLabel"
      :disabled="disabled ?? false"
      class="pr-6"
      @update:model-value="onSelectedTypeChanged"
    />
    <v-autocomplete
      variant="outlined"
      density="compact"
      :multiple="multiple ?? false"
      :disabled="disabled ?? false"
      :model-value="selectedResource"
      :items="items"
      :item-title="(v) => `${v.name} (${v.businessId})`"
      :item-value="(v) => v"
      :label="resourceLabel"
      :placeholder="hint"
      :rules="props.rules"
      :hide-details="hideDetails"
      @update:model-value="onSelectedResourceChanged"
    />
  </div>
</template>
