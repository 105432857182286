import imageCompression from "browser-image-compression";

export async function compressImageFile(imageFile: File | null) {
  if (imageFile == null) return null;

  console.log(`Original image file size ${imageFile.size / 1024 / 1024} MB`);

  const options = {
    maxSizeMB: 1,
    useWebWorker: true
  };
  try {
    const compressedFile = await imageCompression(imageFile, options);
    if (compressedFile != null) {
      console.log(`Compressed image file size ${compressedFile.size / 1024 / 1024} MB`); // smaller than maxSizeMB

      return new File([compressedFile!], imageFile.name, { type: imageFile.type });
    }

    return null;
  } catch (error) {
    console.error("Failed compress image file", error);
  }

  return null;
}

export function isFileValidImageType(file: File | null) {
  if (file == null) return false;

  const validImageTypes = ["image/jpeg", "image/png", "image/gif", "image/bmp", "image/webp", "image/svg+xml"];
  return validImageTypes.includes(file.type);
}
