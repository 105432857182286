<template>
  <v-card>
    <v-card-title>
      <breadcrumbs>{{ $t("tenant-view-tenants-title", { $: "Tenants" }) }}</breadcrumbs>
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col v-for="tenant in tenants" :key="tenant.id" md="4" lg="3" xl="2">
          <v-card width="100%" height="200">
            <v-card-title>{{ tenant.displayName }}</v-card-title>
            <v-card-subtitle>{{ tenant.name }}</v-card-subtitle>
            <v-card-text v-if="currentTenantId === tenant.id"> {{ $t("tenant-manage-onCurrentTenant-label", { $: "You are currently logged into this tenant." }) }}</v-card-text>
            <v-card-actions v-else>
              <v-btn variant="outlined" color="golden" @click="loginToTenant(tenant)">
                {{ $t("tenant-manage-selectTenant-action", { $: "Select" }) }}
                <v-icon right> mdi-login</v-icon>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col md="4" lg="3" xl="2">
          <v-card width="100%" height="200">
            <v-card-title>{{ $t("tenant-manage-create-label", { $: "New Tenant" }) }}</v-card-title>
            <v-card-actions>
              <v-btn variant="outlined" block color="accent" @click="createTenant">
                {{ $t("tenant-manage-create-action", { $: "Create" }) }}
                <v-icon right> mdi-bank-plus</v-icon>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { useAuthStore } from "@/store/AuthStore";
import { mapState } from "pinia";
import Breadcrumbs from "@/components/Layout/Breadcrumbs.vue";

export default {
  components: { Breadcrumbs },
  title: "Tenants",
  computed: {
    ...mapState(useAuthStore, ["tenants", "currentTenantId"])
  },
  methods: {
    async loginToTenant(tenant) {
      window.location.href = `${window.location.origin}/${tenant.id}`;
    },
    async createTenant() {
      await this.$router.push({ path: "/create-tenant" });
    }
  }
};
</script>

<style scoped></style>
