import { nextTick, ref, Ref, watch } from "vue";
import { toRef } from "@vueuse/core";
import { ICellEditorParams, IRowNode } from "ag-grid-community";

export type ControlledValueRefProvider = () => Ref;
export type ControlledValueEditionLockProvider = (params?: ICellEditorParams) => Ref<boolean>;

export interface IControlledCellEditorParams {
  isEditEnabled?: ControlledValueEditionLockProvider;
  clearOnEditDisabled?: boolean | (() => boolean);
  hideOnEditDisabled?: boolean | (() => boolean);
  defaultValue?: any;
  clearValue?: any;
  onValueChange?: (newValue: any, oldValue: any, params?: ICellEditorParams) => boolean | undefined;
  valueRef?: ControlledValueRefProvider | undefined;
  placeholder?: string;
}

export function getOrEvalBoolean(v: undefined | boolean | (() => boolean), defaultValue: boolean): boolean {
  if (typeof v == "boolean") {
    return v;
  } else if (typeof v == "function") {
    return v();
  }
  return defaultValue;
}

export function resolveValueRef(params: IControlledCellEditorParams & ICellEditorParams, initialValue: any): Ref {
  if (params.valueRef) {
    const r = toRef(params.valueRef());
    r.value = initialValue;
    return r;
  } else {
    return toRef(initialValue);
  }
}

export function useValueChangeControl(valueRef: Ref, params: IControlledCellEditorParams & ICellEditorParams) {
  if (isNullOrUndefined(valueRef.value) && !isNullOrUndefined(params.defaultValue)) {
    valueRef.value = params.defaultValue;
    nextTick().then(() => (valueRef.value = params.defaultValue));
  }

  const editEnabled = ref(params.isEditEnabled ? params.isEditEnabled(params) : null);
  if (editEnabled.value) {
    watch(editEnabled, (newValue, _) => {
      if (getOrEvalBoolean(params.clearOnEditDisabled, true) && !newValue) {
        valueRef.value = params.clearValue ?? null;
      }
    });
  }

  function processValueChange(newValue: any, oldValue: any) {
    if (params.onValueChange) {
      const useValue = params.onValueChange(newValue, oldValue, params);
      if (useValue === false) {
        valueRef.value = oldValue;
      }
    }
  }

  watch(valueRef, (newValue, oldValue) => {
    processValueChange(newValue, oldValue);
  });

  processValueChange(valueRef.value, undefined);
}

function isNullOrUndefined(value: any) {
  return typeof value === "undefined" || value == null;
}
