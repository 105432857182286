<template>
    <v-btn class="close-card-button" icon="mdi-window-close" size="x-large" elevation="0" />
</template>
  
<script setup></script>
  
<style>
.close-card-button {
    position: absolute;
    top: 26px;
    right: 24px;
}
</style>
  