<template>
  <master-detail-layout>
    <template #master>
      <v-card elevation="0" class="flexcard fill-height">
        <v-card-title>
          <v-icon class="pr-2"> mdi-notebook</v-icon>
          Capacity board
        </v-card-title>
        <v-card-text class="fill-height">
          <v-row class="fill-height">
            <v-col cols="12">
              <ag-grid-wrapper
                ref="grid"
                refresh-btn
                :column-defs="columnDefinitions"
                :row-data="view.data"
                row-selection="single"
                :get-context-menu-items="getContextMenuItems"
                :enable-range-selection="true"
                :suppress-multi-range-selection="true"
                @grid-ready="onGridReady"
                @row-selected="onRowSelected"
                @refresh-action="onRefreshAction"
              >
                <template #custom-buttons>
                  <v-tooltip location="bottom" open-delay="300">
                    <template #activator="{ props }">
                      <v-btn
                        size="small"
                        density="compact"
                        icon="mdi-send-circle-outline"
                        v-bind="props"
                        :disabled="!isViewScenarioEditable || $acl.hasNotRole($acl.Permission.Write.PlanningView)"
                        @click="updateCapacityByAgreement"
                      />
                    </template>
                    <span>Update agreement capacity</span>
                  </v-tooltip>
                  <v-tooltip location="bottom" open-delay="300">
                    <template #activator="{ props }">
                      <v-btn
                        size="small"
                        density="compact"
                        icon="mdi-content-save"
                        v-bind="props"
                        :disabled="!isUpdated || $acl.hasNotRole($acl.Permission.Write.PlanningView)"
                        @click="savePlanningView"
                      />
                    </template>
                    <span>Save planning view</span>
                  </v-tooltip>
                </template>
                <template #filter>
                  <v-row class="custom-filter">
                    <v-col cols="1">
                      <v-text-field v-model="request.start" density="compact" hide-details label="Start" prepend-icon="mdi-calendar"/>
                      <!--                      <v-menu-->
                      <!--                        v-model="menuStart"-->
                      <!--                        :close-on-content-click="false"-->
                      <!--                        :nudge-right="40"-->
                      <!--                        transition="scale-transition"-->
                      <!--                        offset-y-->
                      <!--                        min-width="auto"-->
                      <!--                      >-->
                      <!--                        <template #activator="{ on, attrs }">-->
                      <!--                          <v-text-field-->
                      <!--                            v-model="request.start"-->
                      <!--                            dense-->
                      <!--                            hide-details-->
                      <!--                            label="Start"-->
                      <!--                            prepend-icon="mdi-calendar"-->
                      <!--                            readonly-->
                      <!--                            v-bind="attrs"-->
                      <!--                            :v-on="on"-->
                      <!--                          />-->
                      <!--                        </template>-->
                      <!--                        <v-date-picker-->
                      <!--                          v-model="request.start"-->
                      <!--                          @input="menuStart = false"-->
                      <!--                        />-->
                      <!--                      </v-menu>-->
                    </v-col>
                    <v-col cols="1">
                      <v-text-field v-model="request.end" density="compact" hide-details label="End" prepend-icon="mdi-calendar"/>
                      <!--                      <v-menu-->
                      <!--                        v-model="menuEnd"-->
                      <!--                        :close-on-content-click="false"-->
                      <!--                        :nudge-right="40"-->
                      <!--                        transition="scale-transition"-->
                      <!--                        offset-y-->
                      <!--                        min-width="auto"-->
                      <!--                      >-->
                      <!--                        <template #activator="{ on, attrs }">-->
                      <!--                          <v-text-field-->
                      <!--                            v-model="request.end"-->
                      <!--                            dense-->
                      <!--                            hide-details-->
                      <!--                            label="End"-->
                      <!--                            prepend-icon="mdi-calendar"-->
                      <!--                            readonly-->
                      <!--                            v-bind="attrs"-->
                      <!--                            :v-on="on"-->
                      <!--                          />-->
                      <!--                        </template>-->
                      <!--                        <v-date-picker-->
                      <!--                          v-model="request.end"-->
                      <!--                          @input="menuEnd = false"-->
                      <!--                        />-->
                      <!--                      </v-menu>-->
                    </v-col>
                    <v-col cols="1">
                      <v-select v-model="request.timeZone" density="compact" hide-details :items="timeZones" item-title="name" item-value="value" label="Time zone"/>
                    </v-col>
                    <v-col cols="1">
                      <v-select v-model="request.aggregate" density="compact" hide-details :items="aggregates" item-title="label" item-value="id" label="Aggregate"/>
                    </v-col>
                    <v-col cols="1">
                      <v-select v-model="request.workloadUnit" density="compact" hide-details :items="workloadUnits" item-title="label" item-value="id" label="Measurement Unit"/>
                    </v-col>
                    <v-col cols="2">
                      <v-select
                        v-if="scenarios"
                        v-model="selectedScenario"
                        density="compact"
                        hide-details
                        :items="scenarios"
                        item-title="name"
                        :item-value="(item) => item"
                        label="Scenario"
                        @change="request.scenarioId = selectedScenario.id"
                      />
                    </v-col>
                    <v-col cols="2">
                      <v-select
                        v-if="resourceFilters && resourceFilters.length"
                        v-model="request.supplyFilter"
                        density="compact"
                        hide-details
                        :items="resourceFilters"
                        item-title="name"
                        item-value="name"
                        label="Supply Filter"
                      />
                    </v-col>
                    <v-col cols="2">
                      <v-select
                        v-if="resourceFilters && resourceFilters.length"
                        v-model="request.demandFilter"
                        density="compact"
                        hide-details
                        :items="resourceFilters"
                        item-title="name"
                        item-value="name"
                        label="Demand Filter"
                      />
                    </v-col>
                  </v-row>
                </template>
              </ag-grid-wrapper>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </template>
  </master-detail-layout>
</template>

<script>
import MasterDetailLayout from "@/components/Layout/MasterDetailLayout.vue";
import AgGridWrapper from "@/components/Grid/AgGridWrapper.vue";
import Grid from "@/components/Grid/Grid.vue";
import {mapActions, mapState} from "pinia";
import {useFiltersStore} from "@/store/FiltersStore";
import {useScenariosStore} from "@/store/ScenariosStore";
import {usePlanningStore} from "@/store/PlanningStore";

export default {
  name: "Planning",
  components: {AgGridWrapper, MasterDetailLayout},
  title: "Planning",
  extends: Grid,
  data: () => ({
    timeZones: [
      {
        name: "Local",
        value: Intl.DateTimeFormat().resolvedOptions().timeZone
      },
      {name: "UTC", value: "Etc/UTC"}
    ],
    menuStart: false,
    menuEnd: false,
    aggregates: [
      {id: 0, label: "Weekly"},
      {id: 1, label: "Monthly"},
      {id: 2, label: "Quarterly"},
      {id: 3, label: "Yearly"}
    ],
    workloadUnits: [
      {id: 0, label: "FTE"},
      {id: 1, label: "Working Time"}
    ]
  }),
  computed: {
    ...mapState(usePlanningStore, ["request", "view", "updates", "viewScenario", "isViewScenarioEditable", "getViewPeriods"]),
    ...mapState(useScenariosStore, ["selectedScenario", "scenarios"]),
    ...mapState(useFiltersStore, ["resourceFilters"]),
    isUpdated() {
      return this.updates.length;
    },
    columnDefinitions() {
      if (!this.view.periods) {
        return null;
      }
      let defs = this.getViewPeriods.map((x) => {
        return {
          field: x.id,
          headerName: x.name,
          editable: this.createIsEditable(),
          valueSetter: this.createValueSetters(),
          cellStyle: this.createCellStyle(),
          valueFormatter: this.createValueFormatter(),
          type: "rightAligned"
        };
      });
      defs.unshift({
        field: "name",
        headerName: "Resource",
        editable: false
      });
      return defs;
    }
  },
  async mounted() {
    await this.fetch();
    await this.fetchResourceFilters(true);
    if (this.scenarios) {
      this.request.scenarioId = this.selectedScenario ? this.selectedScenario.id : this.scenarios[0].id;
      if (this.request.demandFilter && this.request.supplyFilter) {
        await this.fetchPlanningView();
      }
    }
  },
  methods: {
    ...mapActions(useFiltersStore, ["fetchResourceFilters"]),
    ...mapActions(useScenariosStore, ["fetch"]),
    ...mapActions(usePlanningStore, ["fetchView", "saveView", "updateRow", "updateCapacityByAgreement", "updateCapacityByRange"]),
    async onRefreshAction() {
      await this.fetchPlanningView();
      this.gridApi.sizeColumnsToFit();
    },
    async fetchPlanningView() {
      await this.fetchView(this.request);
    },
    ///////
    async savePlanningView() {
      await this.saveView();
      await this.fetchPlanningView();
    },
    createIsEditable() {
      return (params) => params.data.editable && this.isViewScenarioEditable;
    },
    createValueSetters() {
      return (params) => {
        if (params.newValue !== params.oldValue) {
          let update = {
            resourceId: params.data.id,
            value: params.newValue,
            periodId: params.colDef.field
          };
          this.updateRow(update);
        }
        return false;
      };
    },
    createCellStyle() {
      return (params) => {
        if (params.value && params.data.color) {
          return {"background-color": params.data.color};
        }
      };
    },
    createValueFormatter() {
      return (params) => {
        let value = params.value;
        if (!value || isNaN(value)) {
          return "";
        }
        if (typeof value !== "number") {
          value = parseFloat(value);
        }
        return value.toFixed(2);
      };
    },
    async updateCapacityByAgreement() {
      if (this.gridApi.getCellRanges().length === 1) {
        const {startRow, endRow, columns} = this.gridApi.getCellRanges()[0];
        const selectedColumnIds = columns.map((x) => x.colDef.field);
        const agreementIds = [];
        for (let i = startRow.rowIndex; i <= endRow.rowIndex; i++) {
          const row = this.gridApi.getDisplayedRowAtIndex(i);
          if (row) {
            agreementIds.push(row.data.id);
          }
        }
        const periods = this.getViewPeriods.filter((p) => selectedColumnIds.includes(p.id));
        if (periods.length === 0 && agreementIds.length === 1) {
          await this.updateCapacityByAgreement({
            agreementId: this.gridApi.getSelectedRows()[0].id
          });
          await this.fetchPlanningView();
        } else {
          await this.updateCapacityByRange({
            agreementIds,
            periods
          });
          await this.fetchPlanningView();
        }
      }
    }
  }
};
</script>

<style lang="scss">
.custom-filter {
  .v-text-field {
    margin: 0;

    .v-input__prepend-outer {
      padding-top: 2px;
      margin-top: 0;
      margin-bottom: 0;
    }
  }
}
</style>
