<template>
  <v-btn variant="outlined" size="small" :disabled="isDisabled" @click="clickRequestForQuotation()"> Send request for quotation </v-btn>
</template>

<script>
export default {
  name: "ButtonCellRenderer",
  data() {
    return {
      test: "test"
    };
  },
  computed: {
    isDisabled() {
      return false;
    }
  },
  beforeMount() {},
  methods: {
    clickRequestForQuotation() {
      this.params.clicked(this.params.value);
      //alert('clicked');
    }
  }
};
</script>

<style lang="scss" scoped></style>
