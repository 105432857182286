<script lang="ts" setup>
import { BoolPropertyModel } from "@masta/json-schema";
import { toRefs } from "vue";

const props = defineProps<{
  modelValue: BoolPropertyModel;
}>();
const { modelValue: _modelValue } = toRefs(props);
defineEmits(["update:modelValue"]);
</script>

<template>
  <v-col cols="6">
    <div class="d-flex pa-5 rounded-lg" :style="{ backgroundColor: modelValue.color }">
      <v-checkbox 
        v-model="modelValue.value" 
        :label="modelValue.name" 
        variant="outlined" 
        density="compact" 
        hide-details 
        :prepend-icon="modelValue.icon"
      />
    </div>
  </v-col>
</template>
