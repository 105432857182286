import { IActivity } from "./IActivity";
import { Layer } from "./Layer";
import { Row } from "./Row";

export class ActivityRef<Activity extends IActivity> {
  private _id: string;
  private _row: Row<any, any, Activity>;
  private _activity: Activity;
  private _layer: Layer;

  constructor(row: Row<any, any, Activity>, layer: Layer, activity: Activity) {
    this._row = row;
    this._layer = layer;
    this._activity = activity;
    this._id = ActivityRef.generateId(row, layer, activity);
  }

  public static generateId(row: Row<any, any, any>, layer: Layer, activity: IActivity): string {
    return `${row?.id}-${layer?.id}-${activity?.hashCode}`;
  }

  get id(): string {
    return this._id;
  }

  get row(): Row<any, any, Activity> {
    return this._row;
  }

  get activity(): Activity {
    return this._activity;
  }

  get layer(): Layer {
    return this._layer;
  }

  isPathExpanded(row: Row<any, any, any>): boolean {
    const parent = row.parent as Row<any, any, any>;
    return parent === null || (parent.expanded && this.isPathExpanded(parent));
  }

  get lineIndex() {
    return this._row.getLineIndex(this.activity);
  }

  detachFromRow() {
    this._row.removeActivity(this._layer, this._activity);
  }

  attachToRow() {
    this._row.addActivity(this._layer, this._activity);
  }

  toString(): string {
    return `ActivityRef[id=${this._id}]`;
  }
}
