<script lang="ts" setup>
import { getAlertType, ProductTemplateDraft, StepDraft, StepDraftResourceSpec } from "@/components/ProcessDrafts/ProcessDraftModels";
import { InventoryChangeType, MeasurementUnit, ResourceInfoDto, ResourceType, StepResourceAssignmentType, StepResourceUsageType, StepType } from "@masta/generated-model";
import { positiveQuantityRules, quantityRules } from "@/components/ProcessDrafts/validation";
import ResourcesPicker from "@/components/Resources/ResourcesPicker.vue";
import { computed, onMounted, ref, watch } from "vue";
import { $t } from "@/i18n";
import { getEnumTitleValuePairs, getEnumValuesExceptProvided } from "@/composables/enumHelpers";
import { translateInventoryChangeType, translateMeasurementUnit, translateResourceType, translateUsageType } from "@/composables/translateEnum";
import { filterValidationError, ProcessValidationError } from "@/components/ProcessDrafts/ProcessValidationError";
import { isDefined } from "@/components/Common/Types";
import { useScenariosStore } from "@/store/ScenariosStore";

const modelValue = defineModel<StepDraftResourceSpec>({ required: true });
const props = defineProps<{
  productTemplate: ProductTemplateDraft;
  step: StepDraft;
  readonly: boolean;
  validationErrors: ProcessValidationError[];
}>();
const emit = defineEmits<{
  (e: "remove", spec: StepDraftResourceSpec): void;
  (e: "populateOtherSteps", spec: StepDraftResourceSpec): void;
}>();

const filteredValidationErrors = computed(() => {
  return props.validationErrors.filter((x) => filterValidationError(x, { productTemplate: props.productTemplate, step: props.step, spec: modelValue.value }));
});

const resourceTypes = getEnumTitleValuePairs(ResourceType, translateResourceType);
const measurementUnits = getEnumTitleValuePairs(MeasurementUnit, translateMeasurementUnit);
const usageTypes = getEnumTitleValuePairs(StepResourceUsageType, translateUsageType);
const inventoryChangeType = getEnumTitleValuePairs(InventoryChangeType, translateInventoryChangeType);

const isResourceSelected = computed(() => modelValue.value?.resources?.length > 0);
const resourceType = computed(() => {
  if (isResourceSelected.value) {
    return modelValue.value.resourceType;
  }
  const book = getEnumValuesExceptProvided(ResourceType, ResourceType.Material, ResourceType.MaterialGroup);
  const others = [ResourceType.Material, ResourceType.MaterialGroup];
  if (modelValue.value.usageType === StepResourceUsageType.Book) {
    return book;
  }
  return others;
});
const resourceTypeLabel = computed(() => (isResourceSelected.value ? resourceTypes.find((x) => x.value === modelValue.value.resourceType)?.title ?? "" : ""));
const availableUsageTypes = computed(() =>
  modelValue.value.resourceType === ResourceType.Material
    ? usageTypes.filter((x) => x.value !== StepResourceUsageType.Book)
    : usageTypes.filter((x) => x.value === StepResourceUsageType.Book)
);

const measureUnit = computed(() => {
  return getMeasurementUnitFromSpec(modelValue.value);
});

const produceSpecMeasUnit = computed(() => {
  if (modelValue.value.usageType === StepResourceUsageType.Produce) {
    return getMeasurementUnitFromSpec(modelValue.value);
  }
  return null;
});

const consumeSpecFormula = computed(() => {
  if (modelValue.value.usageType !== StepResourceUsageType.Consume) {
    return null;
  }
  if (modelValue.value.quantity > 0 && !modelValue.value.isBase) {
    const [sumQuantities, measUnitForProductionSpec] = getBaseQuantityAndUnit();
    const measUnitForCurrentSpec = getMeasurementUnitFromSpec(modelValue.value);
    if (isDefined(sumQuantities) && sumQuantities > 0 && isDefined(measUnitForCurrentSpec) && isDefined(measUnitForProductionSpec)) {
      const consumePercentage = (modelValue.value.quantity * 100) / sumQuantities;
      return `${measUnitForCurrentSpec} / ${Number(sumQuantities)} ${measUnitForProductionSpec}` + "\n" + `(${consumePercentage.toFixed(0)}%)`;
    }
  }
  return getMeasurementUnitFromSpec(modelValue.value);
});

watch(
  () => modelValue.value.isBase,
  (newValue) => {
    if (newValue) {
      props.step.specs.forEach((spec) => {
        if (spec !== modelValue.value && spec.isBase) {
          spec.isBase = false;
        }
      });
    }
  }
);

const preferredUsageType = ref<StepResourceUsageType | null>(modelValue.value?.usageType);

function remove() {
  emit("remove", modelValue.value);
}

function populateOtherSteps() {
  emit("populateOtherSteps", modelValue.value);
}

watch(
  () => modelValue.value.resources,
  (newValue, oldValue) => {
    if (oldValue.length === 0 && newValue.length > 0) {
      modelValue.value.resourceType = newValue[0].type;
      recomputeAfterResourceChange();
    }
  }
);

watch(
  () => modelValue.value.resourceType,
  (newValue, oldValue) => {
    if ((oldValue === ResourceType.Material || oldValue === ResourceType.MaterialGroup) && newValue !== ResourceType.Material && newValue !== ResourceType.MaterialGroup) {
      modelValue.value.isBase = false;
    }
  }
);

function isValidPreferredUsageType(prefUsageType: StepResourceUsageType | null) {
  if (prefUsageType === null) {
    return false;
  }
  switch (prefUsageType) {
    case StepResourceUsageType.Book:
      return modelValue.value.resourceType !== ResourceType.Material && modelValue.value.resourceType !== ResourceType.MaterialGroup;
    default:
      return true;
  }
}

function recomputeAfterResourceChange() {
  if (isValidPreferredUsageType(preferredUsageType.value)) {
    modelValue.value.usageType = preferredUsageType.value!;
  } else if (modelValue.value.resourceType === ResourceType.Material) {
    if (modelValue.value.usageType !== StepResourceUsageType.Produce && modelValue.value.usageType !== StepResourceUsageType.Consume) {
      modelValue.value.usageType = StepResourceUsageType.Consume;
    }
  } else {
    modelValue.value.usageType = StepResourceUsageType.Book;
  }

  if (modelValue.value.resources.length > 0) {
    modelValue.value.assignmentType = StepResourceAssignmentType.Alternatives;
  }
}

/**
 * Calculate the sum of quantities from all specs in step
 */
function sumProduceQuantities(stepDraft: StepDraft | null): number | undefined {
  if (isDefined(stepDraft)) {
    if (stepDraft.specs.length === 0) {
      return undefined;
    }

    const produceQuantitySum = stepDraft.specs.reduce((sum, spec) => {
      if (spec.usageType === StepResourceUsageType.Produce) {
        return sum + Number(spec.quantity);
      }
      return sum;
    }, 0);

    return produceQuantitySum;
  }

  return undefined;
}

function getBaseQuantityAndUnit(): [number?, string?] {
  const baseSpec = props.step.specs.find((spec) => spec.isBase);

  if (isDefined(baseSpec)) {
    return [baseSpec.quantity, getMeasurementUnitFromSpec(baseSpec)];
  }

  const firstProductionSpec = props.step.specs.find((spec) => spec.usageType === StepResourceUsageType.Produce);
  if (isDefined(firstProductionSpec)) {
    const sumQuantities = sumProduceQuantities(props.step);
    return [sumQuantities, getMeasurementUnitFromSpec(firstProductionSpec)];
  }

  return [undefined, undefined];
}

/**
 * Get measurement unit from the first resource for given spec.
 */
function getMeasurementUnitFromSpec(spec: StepDraftResourceSpec | undefined): string | undefined {
  if (isDefined(spec) && spec.resources.length > 0) {
    const measUnitValue = spec.resources[0].measurementUnit;

    const measUnitValueTitle = measurementUnits.find((x) => x.value === measUnitValue)?.title ?? "";
    return measUnitValueTitle;
  }

  return undefined;
}

onMounted(() => {
  preferredUsageType.value = modelValue.value.usageType;
});

function openResource(resource: ResourceInfoDto) {
  if (resource.type === ResourceType.Material || resource.type === ResourceType.MaterialGroup) {
    return { name: "Product Card", query: { product: resource.id, selected: resource.id }, params: { scenarioId: useScenariosStore().scenarioBusinessId } };
  }
  if (resource.type === ResourceType.Asset) {
    return { name: "Assets", query: { assetId: resource.id }, params: { scenarioId: useScenariosStore().scenarioBusinessId } };
  }
  if (resource.type === ResourceType.Agreement || resource.type === ResourceType.AgreementGroup) {
    return { name: "Agreements", query: { agreementId: resource.id }, params: { scenarioId: useScenariosStore().scenarioBusinessId } };
  }
  if (resource.type === ResourceType.Equipment || resource.type === ResourceType.EquipmentGroup) {
    return { name: "Equipment", query: { equipmentId: resource.id }, params: { scenarioId: useScenariosStore().scenarioBusinessId } };
  }
  if (resource.type === ResourceType.Person || resource.type === ResourceType.PersonGroup) {
    return { name: "Personnel", query: { personId: resource.id }, params: { scenarioId: useScenariosStore().scenarioBusinessId } };
  }
  if (resource.type === ResourceType.Location) {
    return { name: "Location", query: { locationId: resource.id }, params: { scenarioId: useScenariosStore().scenarioBusinessId } };
  }
  if (resource.type === ResourceType.Service) {
    return { name: "Services", query: { serviceId: resource.id }, params: { scenarioId: useScenariosStore().scenarioBusinessId } };
  }
}

// $t("processDraft-view-specInventoryChangeType-Default-tooltip", { $: "All At Start Or End" })
// $t("processDraft-view-specInventoryChangeType-AfterSpecificAmount-tooltip", { $: "All After Specific Quantity" })
// $t("processDraft-view-specInventoryChangeType-Proportional-tooltip", { $: "Proportionally, Every Certain Quantity" })
</script>

<template>
  <v-container fluid>
    <v-row class="resource-spec-editor align-center">
      <v-col cols="11" class="pr-0">
        <v-row no-gutters class="d-flex flex-wrap gap-10">
          <div class="mt-4 d-flex align-start min-width-150" style="min-width: 115px">
            <v-chip class="bg-indigo-lighten-5 text-primary text-body-1 chip-select text-body-1" style="min-width: 5rem">{{ resourceTypeLabel }}</v-chip>
          </div>

          <div class="flex-fill min-width-100 max-width-500 resource-picker">
            <ResourcesPicker
              v-model="modelValue.resources"
              :readonly="props.readonly"
              class="flex-1-1-100"
              :label="$t('processDraft-view-specResources-label', { $: 'Resources' })"
              :resource-type="resourceType"
            />
          </div>

          <div class="flex-fill min-width-50 max-width-100">
            <v-text-field
              v-model="modelValue.quantity"
              :readonly="props.readonly"
              variant="outlined"
              density="compact"
              hide-details="auto"
              type="number"
              :label="$t('processDraft-view-specQuantity-label', { $: 'Quantity' })"
              :rules="modelValue.usageType === StepResourceUsageType.Scrap ? quantityRules : positiveQuantityRules"
              @click.stop=""
            />
          </div>

          <div
            v-if="step.type !== StepType.Production || (modelValue.usageType !== StepResourceUsageType.Produce && modelValue.usageType !== StepResourceUsageType.Consume)"
            class="d-flex align-center"
          >
            <v-label class="flex-shrink-0 flex-grow-0">{{ measureUnit }}</v-label>
          </div>

          <div
            v-if="step.type === StepType.Production && (modelValue.usageType === StepResourceUsageType.Produce || modelValue.usageType === StepResourceUsageType.Consume)"
            class="flex-fill2"
          >
            <v-row no-gutters>
              <v-label v-if="modelValue.usageType === StepResourceUsageType.Produce" class="flex-shrink-0 flex-grow-0">{{ produceSpecMeasUnit }}</v-label>
              <v-label v-if="modelValue.usageType === StepResourceUsageType.Consume" class="flex-shrink-0 flex-grow-0 text-pre-wrap">{{ consumeSpecFormula }}</v-label>
              <v-tooltip :text="$t('processDraft-view-isBase-label', { $: 'Basis for recipe conversion' })" class="flex-shrink-1 flex-grow-0">
                <template #activator="{ props }">
                  <v-checkbox v-model="modelValue.isBase" v-bind="props" :disabled="props.readonly" variant="outlined" density="compact" hide-details="auto"></v-checkbox>
                </template>
              </v-tooltip>
            </v-row>
          </div>

          <div
            v-if="modelValue.usageType === StepResourceUsageType.Produce || modelValue.usageType === StepResourceUsageType.Consume"
            class="flex-fill min-width-100 max-width-300"
          >
            <v-tooltip :text="$t('processDraft-view-specInventoryChangeType-tooltip', { $: 'Inventory Change Type' })" location="top">
              <template #activator="{ props }">
                <v-select
                  v-model="modelValue.inventoryChangeType"
                  :disabled="props.readonly"
                  :items="inventoryChangeType"
                  variant="outlined"
                  density="compact"
                  hide-details="auto"
                  :label="$t('processDraft-view-specStockIncrease-label', { $: 'Inventory Change Type' })"
                  v-bind="props"
                  @click.stop=""
                >
                  <template #item="{ props, item }">
                    <v-tooltip :text="$t(`processDraft-view-specInventoryChangeType-${InventoryChangeType[item.value]}-tooltip`)" location="left">
                      <template #activator="activator">
                        <v-list-item v-bind="props" title>
                          <v-list-item-title v-bind="activator.props">{{ item.title }}</v-list-item-title>
                        </v-list-item>
                      </template>
                    </v-tooltip>
                  </template>
                </v-select>
              </template>
            </v-tooltip>
          </div>

          <div
            v-if="
              (modelValue.usageType === StepResourceUsageType.Produce || modelValue.usageType === StepResourceUsageType.Consume) &&
              modelValue.inventoryChangeType !== InventoryChangeType.Default
            "
            class="flex-fill min-width-100 max-width-200"
          >
            <v-text-field
              v-model="modelValue.inventoryChangeThresholdQuantity"
              :readonly="props.readonly"
              variant="outlined"
              density="compact"
              hide-details="auto"
              type="number"
              :label="$t('processDraft-view-specStockIncreaseThreshold-label', { $: 'Inventory Change Threshold' })"
              :rules="quantityRules"
              @click.stop=""
            />
          </div>
        </v-row>
      </v-col>
      <v-col cols="1" class="text-right d-flex align-center px-0">
        <v-tooltip :text="$t('processDraft-view-specPopulateOtherSteps-action', { $: 'Populate other steps' })" location="top">
          <template #activator="{ props }">
            <v-btn
              v-if="modelValue.usageType === StepResourceUsageType.Book"
              v-bind="props"
              density="compact"
              variant="text"
              color="primary"
              icon="mdi-content-duplicate"
              :disabled="props.readonly"
              @click="populateOtherSteps"
            ></v-btn>
          </template>
        </v-tooltip>
        <v-menu open-on-hover location="start">
          <template #activator="{ props }">
            <v-btn density="compact" variant="text" color="primary" icon="mdi-select-search" :disabled="props.readonly || modelValue.resources.length === 0" v-bind="props"></v-btn>
          </template>
          <v-list>
            <v-list-item v-for="(resource, index) in modelValue.resources" :key="index" :value="index" :to="openResource(resource)">
              <v-list-item-title>
                <v-icon>mdi-select-search</v-icon>
                {{ resource.name ?? resource.businessId }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-btn density="compact" variant="text" color="primary" icon="mdi-delete" :disabled="props.readonly" @click="remove"></v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col v-if="modelValue.message" cols="12">
        <v-alert closable density="compact" :type="getAlertType(modelValue.message.type)" :text="modelValue.message.text" @click:close="modelValue.message = undefined"></v-alert>
      </v-col>
    </v-row>
    <v-row>
      <v-col v-if="filteredValidationErrors?.length > 0" cols="12">
        <v-alert
          v-for="(error, idx) in filteredValidationErrors"
          :key="idx"
          v-model="error.isErrorShown"
          density="compact"
          type="error"
          closable
          :text="error?.message"
          class="mb-1"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<style lang="scss">
.resource-spec-editor {
  .title {
    font-size: 14px;
    font-weight: 500;
  }

  .resource-picker {
    .v-field__input input {
      min-width: unset !important;
    }
  }
}
</style>
