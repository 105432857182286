import { GraphicsMouseEvent, isCtrlKey } from "./GraphicsMouseEvent";

export type MouseBtnPressedEvent = GraphicsMouseEvent & {
  button: number;
  x: number;
  y: number;
  type: "pointerdown" | "pointerup";
  preventDefault: () => void;
};

export function createMouseBtnPressedEvent(event: PointerEvent, element: HTMLElement): MouseBtnPressedEvent {
  const { left, top } = element.getBoundingClientRect();
  return {
    button: event.button,
    x: event.clientX - left,
    y: event.clientY - top,
    ctrlKey: event.ctrlKey,
    shiftKey: event.shiftKey,
    altKey: event.altKey,
    metaKey: event.metaKey,
    osCtrlKey: isCtrlKey(event),
    type: event.type as "pointerdown" | "pointerup",
    preventDefault: () => event.preventDefault()
  };
}
