import { ILayerRenderer } from "./ILayerRenderer";
import { inject, injectable } from "inversify";
import { createPaddingInsets, GanttSettings, Lifecycle, PaddingInsets, Row, SettingKey } from "../../../Core";

@injectable()
export class ChartScaleLayerRenderer extends Lifecycle implements ILayerRenderer {
  public static Identifier = "ChartScaleLayerRenderer";
  private _paddingInsets: PaddingInsets;
  private _show: boolean;
  private _prevWidth = 0;
  private _prevHeight = 0;

  constructor(
    @inject(Row<any, any, any>) private _row: Row<any, any, any>,
    @inject(GanttSettings) private _ganttSettings: GanttSettings
  ) {
    super();
  }

  public async afterInitialize(): Promise<void> {
    await super.afterInitialize();

    this.subscribe(this._ganttSettings.getSetting$<boolean>(SettingKey.CHART_HEADER_SHOW).subscribe((s) => {
      this._show = s ?? false;
    }));
    this.subscribe(this._ganttSettings.getSetting$<PaddingInsets>(SettingKey.ROW_PADDING).subscribe((s) => {
      if (s && s) {
        this._paddingInsets = createPaddingInsets(s.top, s.right, s.bottom, s.left);
      }
    }));
  }

  render(canvas: OffscreenCanvas | HTMLCanvasElement, context: OffscreenCanvasRenderingContext2D | CanvasRenderingContext2D, params: { scaleValues: number[], unit?: string }) {
    // console.log("--> ChartScaleLayerRenderer render", this._row.name, this._show, params.scaleValues);
    if (!this._show) return;

    const width = canvas instanceof HTMLCanvasElement ? canvas.clientWidth : canvas.width;
    const height = canvas instanceof HTMLCanvasElement ? canvas.clientHeight : canvas.height;

    context.clearRect(0, 0, width, height);

    const scaleValues = params.scaleValues;
    if (!scaleValues || scaleValues.length === 0) {
      return;
    }
    context.save();


    const prevFontSize = context.font;
    const fontSize = 10;
    context.font = context.font.replace(/\d+px/, `${fontSize}px`);

    const L = height - this._paddingInsets.top - this._paddingInsets.bottom;
    const N = scaleValues.length;
    const W = fontSize;
    const H = L / (N - 1);

    const top = this._paddingInsets.top;
    const maxScaleLabelWidth = this.getMaxScaleLabelWidth(context, scaleValues, params.unit);

    context.beginPath();
    context.textAlign = "end";
    context.strokeStyle = "rgb(0,0,0)";
    context.lineWidth = 1;

    for (let i = 0; i < scaleValues.length; i++) {
      const scaleValue = scaleValues[i];

      const antiBlur = 0.5;
      const yPos = Math.round(top + H * i) + antiBlur;
      const fontYPos = Math.round(yPos + W / 4);
      const fontXPos = maxScaleLabelWidth + W - Math.round(W / 4);
      context.moveTo(maxScaleLabelWidth + W, yPos);
      context.lineTo(width - this._paddingInsets.right, yPos);

      context.fillText(`${scaleValue}${params.unit ? ` ${params.unit}` : ""}`, fontXPos, fontYPos);
    }
    context.closePath();
    context.stroke();

    context.restore();
    this._prevWidth = width;
    this._prevHeight = height;
  }

  private getMaxScaleLabelWidth(context: OffscreenCanvasRenderingContext2D | CanvasRenderingContext2D, scaleValues: number[], unit: string | undefined) {
    // get max scale value width by charter length
    return Math.max(...scaleValues.map((x) => context.measureText(`${x}${unit}`).width));
  }
}
