﻿<template>
  <v-main>
    <div class="login w-auto h-screen">
      <v-row no-gutters class="h-screen">
        <v-col cols="12" md="5" class="left px-8">
          <div class="text-center d-flex justify-center align-center">
            <masta-logo :dark="$vuetify.theme.global.name === 'dark'" health-indicator />
          </div>
          <div>
            <v-divider />
            <v-row v-if="tenants.length > 0" class="py-12">
              <v-col cols="12" class="text-h4 font-weight-bold text-center">
                {{ $t("home-view-loginHeader-label", { $: "Login to the production panel" }) }}
              </v-col>
              <v-col cols="12" class="text-h4 text-subtitle-1 text-center">
                {{ $t("home-view-loginSubheader-label", { $: "To start select from previously used tenants:" }) }}
              </v-col>
              <v-col v-for="tenant in tenants" :key="tenant" cols="12" class="text-center flex-wrap">
                <v-chip
                  class="chip justify-center text-uppercase"
                  :closable="true"
                  color="primary lighten-1"
                  @click="loginToTenant(tenant)"
                  @click:close.stop="removeTenantFromLocalStorage(tenant)"
                >
                  {{ tenant }}
                </v-chip>
              </v-col>
            </v-row>
          </div>
          <div>
            <v-divider v-if="tenants.length > 0" />
            <v-form @submit.prevent="login">
              <v-row class="py-12">
                <v-col cols="12" class="text-subtitle-1 font-weight-bold text-center">
                  {{ $t("home-view-typeTenantId-label", { $: "You can also type in your Tenant ID:" }) }}
                </v-col>
                <v-col cols="12" class="d-flex justify-center pb-0">
                  <v-text-field
                    v-model="tenantId"
                    class="tenant-input"
                    variant="outlined"
                    persistent-placeholder
                    :placeholder="$t('home-view-tenantId-label', { $: 'Tenant ID' })"
                    required
                    :error-messages="tenantIdErrorMessages"
                    data-cy="login-to-tenant-txt"
                  />
                </v-col>
                <v-col cols="12" class="d-flex justify-center align-center" :class="[ tenantIdErrorMessages.length === 0 ? 'pt-0' : ''  ]">
                  <v-btn size="x-large" :color="!tenantId ? '' : 'primary'" type="submit" :disabled="!tenantId" variant="elevated" data-cy="login-to-tenant-btn">
                    {{ $t("home-view-loginToTenant-action", { $: "Login to tenant" }) }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </div>
          <div class="text-center d-flex justify-center align-center text-caption position-relative">
            {{ $t("home-view-createNewTenantQuestion-label", { $: "Don’t have a Tenant account yet?" }) }}
            <router-link to="create-tenant" class="text-primary text-decoration-none px-2">{{ $t("home-view-createNewTenant-label", { $: "Create new tenant" }) }}</router-link>

            <theme-switch class="theme-switch" />
          </div>
        </v-col>
        <v-col md="7" class="right bg-indigo-lighten-5">
          <div class="d-none d-md-inline">
            <v-img src="/app-screen-1.png"></v-img>
          </div>
        </v-col>
      </v-row>
    </div>
  </v-main>
</template>

<script>
import ThemeSwitch from "@/components/ThemeSwitch.vue";
import MastaLogo from "@/components/MastaLogo.vue";
import ApiService from "@/services/api";

export default {
  name: "TenantApp",
  components: {
    MastaLogo,
    ThemeSwitch
  },
  data: () => ({
    tenantIdInput: null,
    tenantIdErrorMessages: [],
    tenants: []
  }),
  computed: {
    tenantId: {
      get() {
        return this.tenantIdInput;
      },
      set(value) {
        this.tenantIdInput = value?.toLowerCase();
        this.tenantIdErrorMessages = [];
      }
    },
    usedTenants: {
      get() {
        let usedTenants = localStorage.getItem("used-tenants");
        if (!usedTenants) {
          usedTenants = [];
        } else {
          try {
            usedTenants = JSON.parse(usedTenants);
          } catch {
            usedTenants = [];
          }
        }
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.tenants = usedTenants;
        return usedTenants;
      },
      set(value) {
        if (Array.isArray(value)) {
          this.tenants = value;
          localStorage.setItem("used-tenants", JSON.stringify(value));
        }
      }
    }
  },
  mounted() {
    this.tenants = this.usedTenants;
  },
  methods: {
    async login() {
      this.tenantIdErrorMessages = [];
      const tenantExist = await this.checkTenantExists(this.tenantId);
      if (tenantExist) {
        this.addTenantToLocalStorage(this.tenantId);
        await this.$router.push({ path: `/${this.tenantId}` });
      } else {
        this.tenantIdErrorMessages = [this.$t("home-view-tenantNotExists-error", { $: "Invalid tenant name. Please enter a valid tenant." })];
      }
    },
    async loginToTenant(tenantId) {
      this.tenantId = tenantId;
      await this.login();
    },
    async checkTenantExists(tenantId) {
      if (tenantId && tenantId !== "") {
        try {
          const response = await ApiService.tenants.checkTenantExists({
            tenantId: this.tenantId
          });
          return response.data && response.data.exists;
        } catch (e) {
          console.error(e);
        }
      }

      return false;
    },
    addTenantToLocalStorage(tenantId) {
      if (tenantId && tenantId !== "") {
        try {
          const tenants = this.usedTenants;
          if (!tenants.includes(tenantId)) {
            this.usedTenants = [tenantId, ...tenants].slice(0, 5);
          }
        } catch (e) {
          console.error(e);
        }
      }
    },
    removeTenantFromLocalStorage(tenantId) {
      if (tenantId && tenantId !== "") {
        try {
          const tenants = this.tenants;
          if (tenants.includes(tenantId)) {
            this.usedTenants = [...tenants.filter((x) => x !== tenantId)];
          }
        } catch (e) {
          console.error(e);
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.login {
  .left {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 0.3fr auto 1fr 0.1fr;
    align-content: space-between;

    .chip {
      width: 25%;
    }

    .tenant-input {
      max-width: 50%;
    }

    .theme-switch {
      position: absolute;
      left: 0;
    }
  }

  .right {
    display: grid;
    grid-template-rows: auto;
    grid-template-columns: 1fr;
    align-content: center;
    justify-content: center;
  }
}
</style>
