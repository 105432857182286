<script setup lang="ts">
import { BomItemDto, IResourceDto } from "@masta/generated-model";
import GridWrapper from "@/components/Grid/GridWrapper.vue";
import { onMounted, ref, watch } from "vue";
import ApiService from "@/services/api";
import { ColDef, GridApi, ValueFormatterParams } from "ag-grid-community";
import { $t } from "@/i18n";
import { translateMeasurementUnit, translateTrackingType } from "@/composables/translateEnum";

const props = defineProps<{
  resource: IResourceDto;
}>();

const emit = defineEmits<{
  (e: "detail", item: BomItemDto): void;
}>();

const items = ref<BomItemDto[]>([]);
const quantity = ref<number>(0);
const gridWrapperRef = ref<InstanceType<typeof GridWrapper> | null>(null);

async function getBom() {
  const result = await ApiService.resources.getBom(props.resource.id, props.resource.scenarioId);
  items.value = result.data?.items ?? [];
  quantity.value = result.data?.mainResourceQuantity ?? 0;
}

watch(
  () => props.resource,
  (newResource) => {
    getBom();
  },
  {
    immediate: true
  }
);

onMounted(async () => {});

const basicColumnDefs = ref<ColDef[]>([
  {
    field: "businessId",
    editable: false,
    resizable: true,
    headerValueGetter: (_: any) => $t("bom-list-businessId-label", { $: "Business Id" })
  },
  {
    field: "name",
    editable: false,
    resizable: true,
    sortable: true,
    headerValueGetter: (_: any) => $t("bom-list-name-label", { $: "Name" })
  },
  {
    field: "description",
    editable: false,
    resizable: true,
    sortable: true,
    headerValueGetter: (_: any) => $t("bom-list-description-label", { $: "Description" })
  },
  {
    field: "quantity",
    editable: false,
    resizable: true,
    sortable: true,
    headerValueGetter: (_: any) => $t("bom-list-quantity-label", { $: "Quantity" })
  },
  {
    field: "measurementUnit",
    type: "enumTypeColumn",
    editable: false,
    resizable: true,
    sortable: true,
    headerValueGetter: (_: any) => $t("bom-list-measurementUnit-label", { $: "Unit" }),
    valueFormatter: (params: any) => translateMeasurementUnit(params.data.measurementUnit)
  },
  {
    field: "trackingType",
    type: "enumTypeColumn",
    editable: false,
    resizable: true,
    sortable: true,
    headerValueGetter: (_: any) => $t("bom-list-trackingType-label", { $: "Tracking Type" }),
    valueFormatter: (params: ValueFormatterParams) => translateTrackingType(params.data.trackingType)
  }
]);

function onPrepareColumns(columnDefs: any) {
  columnDefs.value = basicColumnDefs.value;
}

function onRowDoubleClicked({ api }: { api: GridApi }) {
  if (gridWrapperRef.value?.isEditing()) return;
  const rows = api.getSelectedRows();
  const selectedBomItem = rows.length === 1 ? rows[0] : null;
  if (selectedBomItem) {
    emit("detail", selectedBomItem);
  }
}
</script>

<template>
  <div class="d-flex flex-column fill-height">
    <div class="flex-grow-0 ma-3 text-subtitle-1">
      {{ $t("bom-list-unitsOfTargetProduct-label", { $: "Units of target product" }) }}: <span class="text-h6">{{ quantity }}</span>
    </div>
    <grid-wrapper
      ref="gridWrapperRef"
      class="flex-grow-1 fill-height"
      row-selection="single"
      identifier="resource-bom"
      :grid-data="items"
      @prepare-columns="onPrepareColumns"
      @row-double-clicked="onRowDoubleClicked"
    >
    </grid-wrapper>
  </div>
</template>
