import {
  GanttEvents,
  GanttException,
  GanttSettings,
  GraphicsMousePositionEvent,
  IRowChartDataSet,
  Lifecycle,
  RowChartRepository,
  RowHoverEvent,
  TimelineManager
} from "@masta/gantt2/core";
import { DropAreasLayer } from "@/components/Gantt/ResourcesGantt/DragAndDrop/DropAreasLayer";
import { GhostActivityLayer } from "@/components/Gantt/ResourcesGantt/DragAndDrop/GhostActivityLayer";
import { LayerCanvas, ViewportPointerEventNoneScrollGlass, ViewportRowsContainer } from "@masta/gantt2/gantt";
import { useScenariosStore } from "@/store/ScenariosStore";
import { GanttDragAndDropContextResult, SchedulingFinishedNotificationEvent, SchedulingResponseStatus } from "@masta/generated-model";
import { RowDropZone } from "@/components/Gantt/ResourcesGantt/DragAndDrop/RowDropZone";
import { Instant } from "@js-joda/core";
import { startWith, withLatestFrom } from "rxjs";
import { useSnackbarsStore } from "@/store/SnackbarsStore";
import { CustomSettingKeys } from "@/components/Gantt/ResourcesGantt/CustomSettingKeys";

export class BaseTaskDragHandler extends Lifecycle {

  protected readonly _snackbarsStore: ReturnType<typeof useSnackbarsStore>;
  protected _dragId: string;
  protected _businessKey: string | undefined;
  protected _context: GanttDragAndDropContextResult;
  protected _dropAreasLayer: DropAreasLayer;
  protected _ghostActivityLayer: GhostActivityLayer;
  protected _rowDropZones: RowDropZone[] = [];
  protected _destroyTimeoutMilis: number = 10000;
  protected _destroyTimeoutId: any;
  protected _isDropped: boolean = false;
  protected _rowDatasets: IRowChartDataSet[];
  protected _rowHoverEvent: RowHoverEvent | null = null;
  protected _mousePosition: GraphicsMousePositionEvent;
  protected _filterRows: boolean;

  constructor(protected readonly _ganttEvents: GanttEvents,
              protected readonly _settings: GanttSettings,
              protected readonly _timelineManager: TimelineManager,
              protected readonly _rowsContainer: ViewportRowsContainer,
              protected readonly _rowChartRepository: RowChartRepository,
              protected readonly _glassLayer: ViewportPointerEventNoneScrollGlass,
              aboveRowLayers: LayerCanvas[] = []) {
    super();
    this._snackbarsStore = useSnackbarsStore();
    this._dropAreasLayer = aboveRowLayers.find(x => x.identifier == DropAreasLayer.name) as DropAreasLayer;
    this._ghostActivityLayer = aboveRowLayers.find(x => x.identifier == GhostActivityLayer.name) as GhostActivityLayer;
  }

  set dragId(value: string) {
    if (this._dragId) {
      throw new GanttException("DragId is already set");
    }
    this._dragId = value;
  }

  get dragId(): string {
    return this._dragId;
  }

  get businessKey(): string | undefined {
    return this._businessKey;
  }

  public async destroyWithTimeout() {
    // destroy immediately if no scheduling operation is in progress (not dropped)
    if (!this._isDropped) {
      await this.destroy();
      return;
    }
    this._destroyTimeoutId = setTimeout(async () => {
      await this.destroy();
    }, this._destroyTimeoutMilis);
  }

  async afterInitialize(): Promise<void> {
    await super.afterInitialize();
    this.subscribe(this._rowChartRepository.dataSets$.subscribe(ds => {
      this._rowDatasets = ds.filter(x => x.visible);
    }));

    this._ganttEvents.mousePositionEvent$.pipe(withLatestFrom(this._ganttEvents.rowHoverEvent$.pipe(startWith(null)))).subscribe(async ([mousePositionEvent, rowHoverEvent]) => {
      if (this._rowHoverEvent !== rowHoverEvent) {
        this._rowHoverEvent = rowHoverEvent as RowHoverEvent;
      }

      this._mousePosition = mousePositionEvent;
    });

    this._filterRows = this._settings.getSetting<boolean>(CustomSettingKeys.DND_FILTER_ROWS) ?? true;
  }

  async beforeDestroy() {
    await super.beforeDestroy();
    if (this._destroyTimeoutId) {
      clearTimeout(this._destroyTimeoutId);
    }
    this._dropAreasLayer.visible = false;
    this._dropAreasLayer.batchDraw();
    this._ghostActivityLayer.removeExternalTaskGhostActivityLayerContext(this._dragId);
    this._ghostActivityLayer.removeSchedulingOperationGhostActivityLayerContext(this._businessKey);
    this._ghostActivityLayer.batchDraw();
    if (this._filterRows) {
      this.showAllRows();
    }
  }

  protected cancelDestroyTimeout() {
    if (this._destroyTimeoutId) {
      clearTimeout(this._destroyTimeoutId);
    }
  }

  protected async setDropZones() {
    const resourceIds = this.getUniqueResourceIds();
    const rowContainers = this._rowsContainer.rowContainers.filter(x => resourceIds.length === 0 || resourceIds.includes(x.row.id));
    const earliestViableDate = !!this._context.earliestViableDate && !!this._context.initialViableDate ? {
      earliestViableDate: Instant.parse(this._context.earliestViableDate),
      initialViableDate: Instant.parse(this._context.initialViableDate)
    } : undefined;
    this._rowDropZones = rowContainers.map(rowContainer => new RowDropZone(rowContainer, this._rowDatasets, this._timelineManager, earliestViableDate));
    this._dropAreasLayer.rowDropZones = this._rowDropZones;
    this._dropAreasLayer.batchDraw();
    this._ghostActivityLayer.rowDropZones = this._rowDropZones;
  }

  public async handleSchedulingOperationResultEvent(event: SchedulingFinishedNotificationEvent) {
    let status: SchedulingResponseStatus;
    const statuses: SchedulingResponseStatus[] = Object.values(event.taskResponses);
    // determine status for event
    if (statuses.includes(SchedulingResponseStatus.Failure)) {
      status = SchedulingResponseStatus.Failure;
    } else if (statuses.includes(SchedulingResponseStatus.Error)) {
      status = SchedulingResponseStatus.Error;
    } else if (statuses.includes(SchedulingResponseStatus.Warning)) {
      status = SchedulingResponseStatus.Warning;
    } else {
      status = SchedulingResponseStatus.Success;
    }
    this._ghostActivityLayer.updateSchedulingOperationStatus(event.businessKey, status);
  }

  protected showAllRows() {
    const containers = this._rowsContainer.rowContainers;
    for (const rowContainer of containers) {
      const hasHiddenClass = rowContainer.element.classList.contains("hidden");
      const hasDroppableClass = rowContainer.element.classList.contains("droppable");
      rowContainer.element.classList.remove("hidden");
      rowContainer.element.classList.remove("droppable");
      if (hasHiddenClass || hasDroppableClass)
        rowContainer.batchDraw(true);
    }
  }

  protected hideRowsOutsideOfDndContext() {
    try {
      // this._settings.setSetting<boolean>({ key: SettingKey.HELP_SHOW_LAYER, value: true });
      // this._settings.setSetting<boolean>({ key: SettingKey.HELP_SHOW_INFO, value: true });
      // this._settings.setSetting<boolean>({ key: SettingKey.HELP_SHOW_NAV_KEYBOARD, value: true });

      const scenariosStore = useScenariosStore();
      if (!scenariosStore.selectedScenario) return;

      const resourceIds = this.getUniqueResourceIds();

      const containers = this._rowsContainer.rowContainers;
      if (resourceIds.length === 0) {
        for (const rowContainer of containers) {
          rowContainer.element.classList.add("droppable");
        }
      } else {
        for (const rowContainer of containers) {
          if (!resourceIds?.includes(rowContainer.row.id)) {
            rowContainer.element.classList.add("hidden");
          } else {
            rowContainer.element.classList.add("droppable");
          }
        }
      }
    } catch (e) {
      console.error(e);
    }
  }

  protected scrollToRowDropZone(rowDropZone: RowDropZone) {
    setTimeout(() => {
      // console.log("%cScrolling to rowDropZone", LOG_STYLE);
      // rowDropZone.container.element.scrollIntoView({ behavior: "instant", block: "center" });
      rowDropZone.container.element.scrollIntoView({ behavior: "smooth", block: "center" });
    }, 100);
  }

  protected isHoveringOverRow() {
    return this._rowsContainer.rowContainers.filter(rowContainer => this._mousePosition?.y >= rowContainer.element.offsetTop && this._mousePosition?.y <= rowContainer.element.offsetTop + rowContainer.element.offsetHeight).length > 0;
  }

  private getUniqueResourceIds() {
    const resourceIdsUnique = new Set();

    for (const resourceId of this._context.resourceIds ?? []) {
      resourceIdsUnique.add(resourceId);
    }

    return Array.from(resourceIdsUnique);
  }
}
