﻿import { BomGraphDto, BomLinkDto, BomNodeDto } from "@masta/generated-model";
import { $t } from "@/i18n";

export function createMermaidGraph(graph: BomGraphDto, selectedResourceId: string) {
  let source: string[] = [];
  source.push("flowchart RL");

  source = source.concat(graph.nodes.map((n) => createNode(n, n.resourceId === selectedResourceId)));
  source = source.concat(graph.nodes.flatMap((n) => n.predecessors).map((l) => createLink(l)));

  return source.join("\n");
}

function createNode(node: BomNodeDto, isSelected: boolean = false) {
  return node.error ? createInvalidNode(node, isSelected) : createValidNode(node, isSelected);
}

function createValidNode(node: BomNodeDto, isSelected: boolean = false) {
  let result = `${node.resourceId}>"\`${node.resourceBusinessId}\`"]`;
  if (isSelected) {
    result += `:::selected`;
  }
  return result;
}

function createInvalidNode(node: BomNodeDto, isSelected: boolean = false) {
  return `${node.resourceId}>"\`${$t(node.error.messageCode)} ${getResourceId(node)}\`"]:::${isSelected ? "error-selected" : "error"}`;
}

function getResourceId(node: BomNodeDto) {
  return node.resourceBusinessId ? node.resourceBusinessId : node.resourceId;
}

function createLink(link: BomLinkDto) {
  return `${link.nextResourceId} -.- ${link.previousResourceId}`;
}
