import { Container, interfaces } from "inversify";
import { fluentProvide } from "inversify-binding-decorators";
import { ILifecycle } from "./Lifecycle";
import ContainerOptions = interfaces.ContainerOptions;

export class IocContainer extends Container {
  constructor(options: ContainerOptions) {
    super(options);
  }
}

export async function onActivationLifecycle<T extends ILifecycle>(_: any, injectable: T): Promise<T> {
  if (injectable.initialize) await injectable.initialize();
  return injectable;
}

export function onDeactivationLifecycle<T extends ILifecycle>(injectable: T): void {
  if (injectable.destroy) injectable.destroy();
}

export const provideSingleton = (identifier: any) => {
  return fluentProvide(identifier).inSingletonScope().done();
};
export const provideTransient = (identifier: any) => {
  return fluentProvide(identifier).inTransientScope().done();
};
