import { inject, injectable } from "inversify";
import { GanttEvents, GanttSettings, IRowInfoColumn, Row, RowChartRepository, SettingKey, TimelineManager } from "@masta/gantt2/core";
import { GanttCanvas, GanttStyleProvider } from "@masta/gantt2/gantt";
import { withLatestFrom } from "rxjs";

@injectable()
export class DemandSupplyScaleRowInfoTemplateCellCanvas<TResource extends Row<any, any, any>> extends GanttCanvas {

  private _column: IRowInfoColumn;
  private _show: boolean;
  private _width: number;
  private _redraw = true;

  private _demandLabel = "D: ";
  private _supplyLabel = "S: ";

  private _demandValue?: number;
  private _supplyValue?: number;

  constructor(@inject(Row<any, any, any>) private _row: TResource,
              @inject(GanttEvents) ganttEvents: GanttEvents,
              @inject(TimelineManager) private _timelineManager: TimelineManager,
              @inject(GanttSettings) private _ganttSettings: GanttSettings,
              @inject(RowChartRepository) private _rowChartRepository: RowChartRepository,
              @inject(GanttStyleProvider) private _styleProvider: GanttStyleProvider) {
    super(ganttEvents, `${DemandSupplyScaleRowInfoTemplateCellCanvas.name}-${_row.id}`, "demand-supply-scale-canvas");
  }

  setColumn(column: IRowInfoColumn): void {
    this._column = column;
  }

  async beforeInitialize(): Promise<void> {
    await super.beforeInitialize();
    this.canvas.width = 0;
    this.canvas.height = 0;
  }

  async afterInitialize(): Promise<void> {
    await super.afterInitialize();
    this.subscribe(
      this._row.height$.subscribe(async (h) => {
        if (!this.isInitialized) return;
        await this.scale();
      })
    );
    this.subscribe(this._ganttSettings.getSetting$<boolean>(SettingKey.CHART_HEADER_SHOW).subscribe((s) => {
      this._show = s ?? false;
      this._redraw = true;
      this.clear();
      this.batchDraw();
    }));
    this.subscribe(this._ganttSettings.getSetting$<number>(SettingKey.CHART_HEADER_WIDTH).subscribe((s) => {
      this._width = s ?? 0;
      this.element.style.width = `${this._width}px`;
      this._redraw = true;
      this.clear();
      this.batchDraw();
    }));

    this.subscribe(
      this._ganttEvents.mousePositionEvent$
        .pipe(
          withLatestFrom(this._ganttEvents.rowHoverEvent$)
        )
        .subscribe(([mousePosEvent, rowHoverEvent]) => {
          // if (rowHoverEvent?.rowId !== this._row.id) {
          //   if (this._demandValue !== undefined || this._supplyValue !== undefined) {
          //     this._demandValue = undefined;
          //     this._supplyValue = undefined;
          //     this._redraw = true;
          //     this.clear();
          //     this.batchDraw();
          //   }
          //   return;
          // }

          const t = this._timelineManager.calculateTimeForLocation(mousePosEvent.x).toEpochMilli();
          let dv, sv;
          this._rowChartRepository.dataSets.filter((d) => d.resourceId === this._row.id && d.id.startsWith("demand")).forEach((d) => {
            dv = d.data.find(x => x.x >= t)?.y;
          });
          this._rowChartRepository.dataSets.filter((d) => d.resourceId === this._row.id && d.id.startsWith("supply")).forEach((d) => {
            sv = d.data.find(x => x.x >= t)?.y;
          });

          if (this._demandValue !== dv || this._supplyValue !== sv) {
            this._demandValue = dv;
            this._supplyValue = sv;

            this._redraw = true;
            this.clear();
            this.batchDraw();
          }
        })
    );
  }

  async scale(): Promise<void> {
    const parentElement = this.element.parentElement!;
    if (!parentElement) return;
    const w = parentElement.offsetWidth;
    const h = this._row.height;
    if (w === this.canvas.width && h === this.canvas.height) return;
    const dpr = window.devicePixelRatio || 1;

    this.canvas.width = w * dpr;
    this.canvas.height = h * dpr;
    this.context.scale(dpr, dpr);

    this._redraw = true;
  }

  async doDrawFromBatch(): Promise<void> {
    if (!this._row.visible || !this._show || !this._redraw) return;

    this.clear();

    this.context.font = this._styleProvider.font;

    if (this._supplyValue !== undefined) {
      this.context.fillStyle = "rgb(76,175,80)";
      const supply = `${this._supplyLabel}${this._supplyValue?.toFixed(0) ?? ""}`;
      const dim = this.context.measureText(supply);
      this.context.fillText(supply, 2, 10 + dim.fontBoundingBoxAscent);
    }
    if (this._demandValue !== undefined) {
      this.context.fillStyle = "rgb(122, 99, 255)";
      const demand = `${this._demandLabel}${this._demandValue?.toFixed(0) ?? ""}`;
      const dim = this.context.measureText(demand);
      this.context.fillText(demand, 2, this.canvas.clientHeight - 10 - dim.fontBoundingBoxDescent);
    }

    this._redraw = false;
  }
}
