<script lang="ts" setup>
import { computed, onBeforeUnmount, ref, toRef, watch } from "vue";
import { GridApi, SelectionChangedEvent } from "ag-grid-community";
import ProductTemplateUsableResourcesGrid from "@/components/ProductTemplates/ProductTemplateUsableResourcesGrid.vue";
import { ProductTemplateDto, ResourceDto, ResourceSubType } from "@masta/generated-model";
import { getSelectedRows } from "@/components/Grid/UseGridSelection";

const $props = defineProps<{ modelValue: boolean; gridApi: GridApi }>();

const emit = defineEmits(["selected", "update:modelValue"]);

const resource = ref<Pick<ResourceDto, "id"> | null>(null);
const selectedResource = ref<ResourceDto | null>(null);

const isResourceSelectionValid = computed(() => {
  return !!selectedResource.value;
});

const watchHandler = watch(toRef($props, "gridApi"), (value) => {
  if (value) {
    $props.gridApi.addEventListener("selectionChanged", onSelectionChanged);
    watchHandler();
  }
});

onBeforeUnmount(() => {
  $props.gridApi?.removeEventListener("selectionChanged", onSelectionChanged);
});

function onSelectionChanged(e: SelectionChangedEvent<ProductTemplateDto>) {
  const selectedRows = getSelectedRows(e.api);
  if (selectedRows.length > 0) {
    resource.value = {
      id: selectedRows[0].resourceId
    };
  } else {
    resource.value = null;
  }
}

function cqlFilter() {
  return `${ResourceSubType.MaterialSemiFinishedOrFinalProduct} IN SubTypes`;
}

function onResourceSelected(resourceDto: ResourceDto) {
  selectedResource.value = resourceDto;
}

function onConfirm() {
  if (isResourceSelectionValid.value) {
    emit("selected", selectedResource.value);
    emit("update:modelValue", false);
  }
}
</script>

<template>
  <v-dialog :model-value="modelValue" close-on-back width="100%" height="80vh" @update:model-value="emit('update:modelValue', $event)">
    <v-card min-width="300px" height="100%">
      <v-card-title>{{ $t("productTemplate-list-copyToNewProduct-title", { $: "Select resource for copy assignment" }) }}</v-card-title>
      <v-card-text>
        <product-template-usable-resources-grid
          :reference-value="resource"
          row-selection="single"
          :grid-api="gridApi"
          :use-grouping="false"
          :cql-filter="cqlFilter"
          style="height: 100%"
          @row-selected="onResourceSelected"
        />
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn class="ma-4" variant="outlined" color="secondary" text @click="emit('update:modelValue', false)"
          >{{ $t("copyToNewProduct-dialog-cancel-action", { $: "Cancel" }) }}
        </v-btn>
        <v-btn class="ma-4" variant="outlined" color="accent" text :disabled="!isResourceSelectionValid" @click="onConfirm">
          {{ $t("copyToNewProduct-dialog-copy-action", { $: "Copy" }) }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style lang="scss" scoped></style>
