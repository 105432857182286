<script setup lang="ts">
import UserNoticesGrid from "@/components/UserNotices/UserNoticesGrid.vue";
import CloseCardButton from "@/components/CloseCardButton.vue";

const openedModelValue = defineModel<boolean>({ required: true });

const props = defineProps<{
  url?: string;
}>();

function close() {
  openedModelValue.value = false;
}

function openInNewTab() {
  window.open(props.url, "_blank");
  close();
}
</script>

<template>
  <v-dialog v-model="openedModelValue" width="100%" height="80vh" @click:outside="close">
    <v-card min-width="300px" height="100%" class="reference-picker-card">
      <close-card-button @click="close"></close-card-button> 
      <v-card-title class="pa-0"> 
        <div class="text-h4 pb-5">{{ $t("userNotice-title-label", { $: "User Notices" }) }}</div>
      </v-card-title>
      <v-card-subtitle>
      </v-card-subtitle>
      <v-card-text class="px-0">
        <user-notices-grid @close-dialog="close" />
      </v-card-text>
      <v-card-actions class="pa-0 px-4">
        <div class="left-col pl-0">
          <v-btn variant="elevated" color="secondary" class="w-100" @click="close">
            {{ $t("resourcePicker-list-cancel-label", { $: "Cancel" }) }}
          </v-btn>
        </div>
        <div class="middle-col"></div>
        <div class="right-col pr-0">
          <v-btn variant="elevated" color="primary" class="w-100" @click="openInNewTab">
            {{ $t("resourcePicker-list-confirm-label", { $: "Confirm" }) }}
          </v-btn>
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style scoped lang="scss">
</style>
