import { inject, injectable } from "inversify";
import { GanttDomElement } from "@masta/gantt2/gantt";
import type { IRowInfoCell, IRowInfoColumn } from "@masta/gantt2/core";
import { Row } from "@masta/gantt2/core";
import { ExtendedTaskDto, TaskRow } from "@/components/Gantt/Common/Model";

@injectable()
export class RowBusinessIdRowInfoTemplateCell extends GanttDomElement<HTMLDivElement> implements IRowInfoCell {

  readonly cellId = "external-id";
  private _column: IRowInfoColumn;

  constructor(@inject(Row<any, any, any>) private _row: TaskRow) {
    super(`${RowBusinessIdRowInfoTemplateCell.name}-${_row.id}`, undefined, "cell external-id");
  }

  async doDrawFromBatch(): Promise<void> {
    this.element.innerText = (this._row.userObject as ExtendedTaskDto).businessId ?? "";
    this.element.style.width = `${(this._column.width)}px`;
  }

  setColumn(column: IRowInfoColumn): void {
    this._column = column;
  }
}
