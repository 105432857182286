import { KeyCreatorParams } from "ag-grid-community";
import { ISelectEnumValueEntry } from "@/components/Grid/CellEditors/IEnumValueSelectCellEditorParams";

/**
 * Creates a row key for a grouping column of type enum.
 *
 * This method takes a KeyCreatorParams object as input and generates a string key based on the provided value, which can be of type:
 * - ISelectEnumValueEntry: when referring to a normal row
 * - enum value (number): when it concerns a grouping row
 *
 * @param params - The KeyCreatorParams object containing the value to create a key for.
 * @returns A string key representing the provided value. It must be a string.
 */
export function groupEnumValueRowKeyCreator(params: KeyCreatorParams<any, ISelectEnumValueEntry | number>): string {
  // for normal row
  if (typeof params.value === "object") {
    return params.value?.value.toString();
  }

  // for grouping row
  if (typeof params.value === "number") {
    return params.value.toString();
    // return params.value?.value.toString();
  }

  return '';  
}
