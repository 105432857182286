import { inject, injectable } from "inversify";
import { createLassoEvent, GanttEvents, GraphicsDragEvent, GraphicsMousePositionEvent, LassoEvent, TimelineManager } from "../../../../Core";
import { LayerCanvas } from "./LayerCanvas";
import { filter, withLatestFrom } from "rxjs";

@injectable()
export class LassoLayer extends LayerCanvas {
  private _initialX: number | null = null;
  private _initialY: number | null = null;
  private _mousePointerEvent: GraphicsMousePositionEvent | null = null;

  constructor(@inject(TimelineManager) timelineManager: TimelineManager, @inject(GanttEvents) ganttEvents: GanttEvents) {
    super(timelineManager, ganttEvents, LassoLayer.name, "lasso-layer");
  }

  async beforeInitialize(): Promise<void> {
    return super.beforeInitialize();
  }

  async afterInitialize(): Promise<void> {
    await super.afterInitialize();

    this.subscribe(this._ganttEvents.panEndEvent$.subscribe(this.onViewportDragEnd.bind(this)));
    this.subscribe(
      this._ganttEvents.panStartEvent$
        .pipe(
          filter((x) => x.shiftKey),
          withLatestFrom(this._ganttEvents.mousePositionEvent$)
        )
        .subscribe(([dragEvent, graphicsMousePositionEvent]) => {
          this.onViewportDragStart(dragEvent, graphicsMousePositionEvent);
        })
    );
    this.subscribe(
      this._ganttEvents.mousePositionEvent$
        .pipe(withLatestFrom(this._ganttEvents.panStartEvent$.pipe(filter((x) => x.shiftKey))))
        .subscribe(([graphicsMousePositionEvent, dragEvent]) => {
          this.onViewportDrag(dragEvent, graphicsMousePositionEvent);
        })
    );
  }

  onViewportDragStart(event: GraphicsDragEvent, { x, y }: { x: number; y: number }): void {
    this._initialX = x;
    this._initialY = y;
  }

  onViewportDragEnd(event: GraphicsDragEvent): void {
    this._initialX = null;
    this._initialY = null;

    this.clear();
  }

  onViewportDrag(event: GraphicsDragEvent, mousePointerEvent: GraphicsMousePositionEvent): void {
    if (!this._initialX || !this._initialY) return;
    this._mousePointerEvent = mousePointerEvent;
    const { x, y, ctrlKey, altKey, shiftKey } = this._mousePointerEvent;

    const initX = this._initialX;
    const initY = this._initialY;

    if (x > initX && y > initY) {
      this.emitLassoEvent(createLassoEvent(initX, x, initY, y, ctrlKey, altKey, shiftKey));
    }
    if (x > initX && y <= initY) {
      this.emitLassoEvent(createLassoEvent(initX, x, y, initY, ctrlKey, altKey, shiftKey));
    }
    if (x <= initX && y > initY) {
      this.emitLassoEvent(createLassoEvent(x, initX, initY, y, ctrlKey, altKey, shiftKey));
    }
    if (x <= initX && y <= initY) {
      this.emitLassoEvent(createLassoEvent(x, initX, y, initY, ctrlKey, altKey, shiftKey));
    }

    this.batchDraw();
  }

  async doDrawFromBatch(): Promise<void> {
    if (!this._initialX || !this._initialY || !this._mousePointerEvent) return;
    const { x, y } = this._mousePointerEvent;

    this.clear();

    const initX = this._initialX;
    const initY = this._initialY;

    this.context.fillStyle = "rgba(94,106,162,0.16)";
    if (x > initX && y > initY) {
      this.context.fillRect(initX, initY, x - initX, y - initY);
    }
    if (x > initX && y <= initY) {
      this.context.fillRect(initX, y, x - initX, initY - y);
    }
    if (x <= initX && y > initY) {
      this.context.fillRect(x, initY, initX - x, y - initY);
    }
    if (x <= initX && y <= initY) {
      this.context.fillRect(x, y, initX - x, initY - y);
    }
  }

  private emitLassoEvent(lassoEvent: LassoEvent): void {
    this._ganttEvents.onLassoEvent(lassoEvent);
  }
}
