import { IRowChartDataSet } from "./IRowChartDataSet";
import { ObservableDataSet } from "../../ObservableDataSet";
import { IRowChartDataEntry } from "./IRowChartDataEntry";
import { Observable } from "rxjs";

export class RowChartDataSet implements IRowChartDataSet {
  private _dataSet = new ObservableDataSet<IRowChartDataEntry>();

  readonly data$: Observable<IRowChartDataEntry[]> = this._dataSet.data$;

  positiveColor = "#000000";
  negativeColor = "#000000";
  lineWidth = 2;

  constructor(public readonly id: string, public readonly resourceId: string) {}

  get data(): IRowChartDataEntry[] {
    return Array.from(this._dataSet.data.values());
  }

  set data(value: IRowChartDataEntry[]) {
    this._dataSet.clear();
    this._dataSet.add(value);
  }
}
