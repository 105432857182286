import { ApiClient } from "@/services/api/common.api";
import { CheckTenantExistsCommand, CheckTenantExistsResponse, GetUserTenantsResponse, RegisterTenantCommand, RegisterTenantResponse, TenantDto } from "@masta/generated-model";

export default {
  async getUserTenants() {
    return ApiClient.get<GetUserTenantsResponse>("/tenants");
  },
  async getUserTenant() {
    return ApiClient.get<TenantDto>("/tenants/current");
  },
  async createTenant(tenantData: RegisterTenantCommand) {
    return ApiClient.post<RegisterTenantResponse>("/tenants", tenantData, {
      withoutAuthorization: true
    });
  },
  async checkTenantExists(tenantData: CheckTenantExistsCommand) {
    const apiKey = prompt("Enter API key");
    return ApiClient.post<CheckTenantExistsResponse>("/tenants/exists", tenantData, {
      withoutAuthorization: true,
      headers: {
        "X-Api-Key": apiKey!
      }
    });
  },
  async deleteAllData() {
    return ApiClient.delete("/tenants");
  },
  async resetAllData(tenantType: number) {
    return ApiClient.post("/tenants/reset", { tenantType });
  }
};

