<script lang="ts" setup>
import Split from "split.js";
import { computed, watch, ref, onMounted, nextTick, onUnmounted, onBeforeUnmount } from "vue";
import { useStorage } from "@vueuse/core";
import { isDefined } from "@/components/Common/Types";

interface Props {

  // Unique identifier of the split panel.
  // Used, among other things, to store and restore the sizes of the panels.
  identifier?: string;

  // Initial sizes of each element in percents or CSS values.
  sizes?: number[];

  // Minimum size of each element.
  minSize?: number | number[];

  direction?: "horizontal" | "vertical";

  // Gutter size in pixels.
  gutterSize?: number;

  // Called to create each gutter element
  gutter?(
    index: number,
    direction: "horizontal" | "vertical"
  ): HTMLElement;
}

const properties = withDefaults(defineProps<Props>(), {
  identifier: () => "default-split-panel",
  sizes: () => [50, 50],
  minSize: () => 0,
  direction: () => "vertical",
  gutterSize: () => 4,
  gutter: undefined
});

// splitjs does not exporting init result interfaace Split.Instance
const splitInstance = ref<Split.Instance>();
const splitContainerRef = ref<HTMLElement>();
const gutterInstance = ref<HTMLElement | undefined>();

const usedSizes = useStorage(properties.identifier + "-sizes", [] as number[]);

const emits = defineEmits<{
  /**
   * Emitted when the split panel is created.
   * @param sizes - Sizes of the panels (percentage values).
   * @param height - Height of the split panel (pixels number).
   * @param width - Width of the split panel (pixels number).
   */
  (e: "created", sizes: number[], height?: number, width?: number): void;

  /**
   * Emitted when the split panel is resized.
   * @param sizes - Sizes of the panels (percentage values).
   * @param height - Height of the split panel (pixels number).
   * @param width - Width of the split panel (pixels number).
   */
  (e: "resized", sizes: number[], height?: number, width?: number): void;
}>();

defineExpose({ splitInstance, restoreSizes });

onMounted(() => {
  init();
});

watch(
  () => properties.sizes,
  (_) => {
    if (splitInstance.value) {
      init();
    }
  }
);

watch(
  () => properties.minSize,
  (_) => {
    if (splitInstance.value) {
      init();
    }
  }
);

watch(
  () => properties.direction,
  (_) => {
    if (splitInstance.value) {
      init();
    }
  }
);

onMounted(() => {
  window.addEventListener("resize", onWindowSizeChanged);
});

onUnmounted(() => {
  window.removeEventListener("resize", onWindowSizeChanged);
});

function restoreSizes() {
  const initialSizes = isDefined(usedSizes.value) && usedSizes.value.length > 0 ? usedSizes.value : properties.sizes;
  splitInstance.value?.setSizes(initialSizes);
}

function onWindowSizeChanged() {
  if (splitInstance.value) {
    const sizes = splitInstance.value.getSizes();
    emits("resized", [...sizes], splitContainerRef.value?.clientHeight, splitContainerRef.value?.clientWidth);
  }
}

const splitContainerClassObject = computed(() => ({
  "flex-column": properties.direction === "vertical",
  "flex-row": properties.direction === "horizontal",
  "dragging": isDragging.value
}));

function getSplitPanelElements(): HTMLElement[] {
  const elements: HTMLElement[] = [];
  if (splitContainerRef.value) {
    const splitContainerChildren = splitContainerRef.value.children;
    if (splitContainerChildren) {
      const panel1 = splitContainerChildren.item(0);
      if (panel1 != null) {
        elements.push(panel1 as HTMLElement);
      } else {
        console.warn("Split panel elemenet #1 not founded");
      }

      const panel2 = splitContainerChildren.item(1);
      if (panel2 != null) {
        elements.push(panel2 as HTMLElement);
      } else {
        console.warn("Split panel elemenet #2 not founded");
      }
    }
  }

  return elements;
}


function toggleChevron() {
  const isLeft = (splitInstance.value?.getSizes() ?? [50, 50])[0] < 50;
  const classList = gutterInstance.value?.getElementsByClassName("split-panel-grab-circle")?.[0].classList;
  if (!classList) return;
  const hasChevronLeft = classList.contains("mdi-chevron-left");
  const hasChevronRight = classList.contains("mdi-chevron-right");
  const hasNone = !hasChevronLeft && !hasChevronRight;
  if (isLeft && (hasChevronLeft || hasNone)) {
    classList.remove("mdi-arrow-split-vertical");
    classList.add("mdi-chevron-right");
    classList.remove("mdi-chevron-left");
  } else if (!isLeft && (hasChevronRight || hasNone)) {
    classList.remove("mdi-arrow-split-vertical");
    classList.add("mdi-chevron-left");
    classList.remove("mdi-chevron-right");
  }
}

const isMouseDrag = ref<boolean>(false);
const isDragging = ref<boolean>(false);

function onMouseDown(e: MouseEvent) {
  isMouseDrag.value = false;
}

function onMouseMove(e: MouseEvent) {
  isMouseDrag.value = true;
}

function onClick() {
  if (isMouseDrag.value) return;
  const initialSizes = isDefined(usedSizes.value) && usedSizes.value.length > 0 ? usedSizes.value : properties.sizes;
  const sizes = splitInstance.value?.getSizes() ?? (initialSizes ?? [50, 50]);
  const reversed = sizes.reverse();
  splitInstance.value?.setSizes(reversed);
  nextTick(toggleChevron);
  usedSizes.value = reversed;
  nextTick(() => {
    emits("resized", [...reversed], splitContainerRef.value?.clientHeight, splitContainerRef.value?.clientWidth);
  });
}

function init() {
  // destroy old instance if exists
  if (splitInstance.value) {
    splitInstance.value.destroy();
  }

  const initialSizes = isDefined(usedSizes.value) && usedSizes.value.length > 0 ? usedSizes.value : properties.sizes;

  const elements = getSplitPanelElements();

  let gutter = properties.gutter;
  if (properties.gutter) {
    const func = properties.gutter;
    gutter = (
      index: number,
      direction: "horizontal" | "vertical"
    ) => {
      gutterInstance.value = func!(index, direction);
      const dir = direction === "vertical" ? "horizontal" : "vertical";
      gutterInstance.value.innerHTML = `<div class='split-panel-grab-circle ${dir} mdi mdi-arrow-split-${dir}'></div>`;
      toggleChevron();
      return gutterInstance.value;
    };
  }

  splitInstance.value = Split(elements, {
    direction: properties.direction,
    sizes: initialSizes,
    minSize: properties.minSize,
    gutterSize: properties.gutterSize,
    gutter,
    gutterAlign: "center",
    snapOffset: 0,
    onDragStart(sizes) {
      isDragging.value = true;
      gutterInstance.value?.getElementsByClassName("split-panel-grab-circle")?.[0].classList.add("active");
    },
    onDrag(sizes: number[]) {
      toggleChevron();
    },
    onDragEnd(sizes: number[]) {
      usedSizes.value = sizes;
      nextTick(() => {
        emits("resized", [...sizes], splitContainerRef.value?.clientHeight, splitContainerRef.value?.clientWidth);
      });
      gutterInstance.value?.getElementsByClassName("split-panel-grab-circle")?.[0].classList.remove("active");
      isDragging.value = false;
    }
  });

  nextTick(() => {
    toggleChevron();
    gutterInstance.value?.addEventListener("mousedown", onMouseDown);
    gutterInstance.value?.addEventListener("mousemove", onMouseMove);
    gutterInstance.value?.addEventListener("click", onClick);
    emits("created", [...initialSizes], splitContainerRef.value?.clientHeight, splitContainerRef.value?.clientWidth);
  });
}

onBeforeUnmount(() => {
  gutterInstance.value?.removeEventListener("mousedown", onMouseDown);
  gutterInstance.value?.removeEventListener("mousemove", onMouseMove);
  gutterInstance.value?.removeEventListener("click", onClick);
});


</script>

<template>
  <div ref="splitContainerRef" class="split-container d-flex fill-height w-100" :class="splitContainerClassObject">
    <div class="split-panel panel-1">
      <slot name="panel-1"></slot>
    </div>
    <div class="split-panel panel-2">
      <slot name="panel-2"></slot>
    </div>
  </div>
</template>

<style lang="scss">
.split-container {
  &:not(.dragging) .split-panel {
    transition: width 0.3s ease, height 0.3s ease;
  }
}

.split-panel-grab-circle {
  position: absolute;
  z-index: 1;
  width: 3rem; /* 1rem radius, so diameter is 2rem */
  height: 3rem;
  background-color: rgb(var(--v-theme-primary));
  transition: background-color 0.3s ease;
  border-radius: 50%;
  transform: translate(calc(-36%), 50%);
  color: rgb(var(--v-theme-white));
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2em;

  &:hover, &.active {
    background-color: rgb(var(--v-theme-secondary));
  }

  &.vertical {
    top: 50%;
    transform: translate(calc(-33%), 50%);
  }


  &.horizontal {
    left: 50%;
    transform: translate(-50%, calc(-50%)) rotate(90deg);
  }
}

</style>
