import { ServerSideDataSource } from "@/components/Grid/ServerSideDataSource";
import ApiService from "@/services/api";
import { State as ScenarioState, useScenariosStore } from "@/store/ScenariosStore";
import {
  AgGridRequestBase,
  GetProductionTasksPaginatedQuery,
  IAgGridPaginationResponse,
  ProductionTaskDto,
  ProductionTaskSchedulingFilter
} from "@masta/generated-model";
import { IServerSideGetRowsParams } from "ag-grid-community";
import { Store } from "pinia";

export class TaskServerSideDataSource extends ServerSideDataSource<ProductionTaskDto, ProductionTaskDto, ProductionTaskDto, ProductionTaskDto, string> {
  private _scenarioStore: Store<string, ScenarioState>;
  private _unscheduledProductionTasks: boolean | null;
  private _unscheduledWorkOrders: boolean | null;

  constructor(id: string, unscheduledProductionTasks: boolean | null = null, unscheduledWorkOrders: boolean | null = null) {
    super(id);
    this._scenarioStore = useScenariosStore();
    this._unscheduledProductionTasks = unscheduledProductionTasks;
    this._unscheduledWorkOrders = unscheduledWorkOrders;
  }

  async getAll(params: IServerSideGetRowsParams): Promise<IAgGridPaginationResponse<ProductionTaskDto>> {
    const groupKeys = params.request.groupKeys;
    const parentId = groupKeys[groupKeys.length - 1];

    const serverRequest = this.constructServerRequest(params);
    this.modifiedFilterModel(serverRequest);
    this.modifiedSortModel(serverRequest);

    const taskDataSchedulingFilter = this.getProductionTaskSchedulingFilter();

    const response = await ApiService.productionTasks.getTasksPaginated({
      ...serverRequest,
      scenarioId: this._scenarioStore.selectedScenario?.id ?? "",
      groupId: parentId,
      schedulingFilter: taskDataSchedulingFilter
    } as GetProductionTasksPaginatedQuery);

    if (response.data.count === 0) {
      params.api.showNoRowsOverlay();
    }

    return response.data as IAgGridPaginationResponse<ProductionTaskDto>;
  }

  private getProductionTaskSchedulingFilter(): ProductionTaskSchedulingFilter | null {
    if (this._unscheduledProductionTasks) return ProductionTaskSchedulingFilter.UnscheduledProductionTasks;
    if (this._unscheduledWorkOrders) return ProductionTaskSchedulingFilter.UnscheduledWorkOrders;
    return null;
  }

  protected modifiedFilterModel(serverRequest: AgGridRequestBase) {
    const filterModel = serverRequest.filterModel;

    if (filterModel && "ag-Grid-AutoColumn" in filterModel) {
      // rewrite ag-Grid-AutoColumn to the businessId to the filterModel
      filterModel["businessId"] = filterModel["ag-Grid-AutoColumn"];
    }
  }

  protected modifiedSortModel(serverRequest: AgGridRequestBase) {
    const sortModel = serverRequest.sortModel;

    if (sortModel) {
      sortModel.forEach((sortModelItem) => {
        if (sortModelItem.colId === "ag-Grid-AutoColumn") {
          // rewrite ag-Grid-AutoColumn to the businessId
          sortModelItem.colId = "businessId";
        }
      });
    }
  }

  getSingle(id: string): Promise<ProductionTaskDto | undefined> {
    throw new Error("Method not implemented.");
  }

  create(entity: ProductionTaskDto): Promise<string> {
    throw new Error("Method not implemented.");
  }

  update(entity: ProductionTaskDto): Promise<string> {
    throw new Error("Method not implemented.");
  }

  remove(entity: ProductionTaskDto): Promise<void> {
    throw new Error("Method not implemented.");
  }

  protected transformForUpdate(listEntity: ProductionTaskDto): ProductionTaskDto {
    throw new Error("Method not implemented.");
  }

  protected transformForCreate(listEntity: ProductionTaskDto): ProductionTaskDto {
    throw new Error("Method not implemented.");
  }

}
