import { GuiModelItem, GuiObjectType } from "./GuiModelItem";

export class BoolPropertyModel implements GuiModelItem {
  public value: boolean | undefined = false;
  public readonly type = GuiObjectType.Bool;
  public color: string | undefined;
  public icon: string | undefined;

  constructor(public name: string) {}

  getValue(): any {
    return this.value;
  }

  isValid(): boolean {
    return true;
  }

  setValue(value: any): void {
    if(typeof value !== "boolean") {
      throw new Error("Setting non-boolean value to boolean");
    }
    this.value = value;
  }
}
