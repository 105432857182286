<template>
  <split-layout ref="splitLayoutRef" identifier="product-templates" :gutter="createSplitPanelGutterElement">
    <template #master>
      <v-card elevation="0" class="d-flex flex-column fill-height">
        <v-card-title v-if="!embedded">
          <breadcrumbs>{{ $t("productTemplate-view-productTemplates-title", { $: "Product Technologies" }) }}</breadcrumbs>
        </v-card-title>
        <v-card-text class="fill-height">
          <v-row class="fill-height" no-gutters>
            <v-col cols="12">
              <product-templates-grid
                ref="gridWrapperRef"
                :filter-by-product-id="filterByProductId"
                :filter-by-process-draft-id="filterByProcessDraftId"
                @row-selected="rowSelected"
                @manage-model-instances="manageTaskModelInstances"
              />
            </v-col>
            <v-col v-if="editTaskModelInstanceAssignmentDialog">
              <model-instance-assignment-editor
                task-assignment
                :read-only="isReadOnly"
                :model-instances="task?.modelInstances"
                :opened="editTaskModelInstanceAssignmentDialog"
                @cancel="editTaskModelInstanceAssignmentDialog = false"
                @save="saveTaskModelInstanceAssignment"
              >
                <task-label type="Task Template" :task-name="task?.name" :task-external-id="task?.businessId" />
              </model-instance-assignment-editor>
            </v-col>
            <v-col v-if="editStepModelInstanceAssignmentDialog">
              <model-instance-assignment-editor
                task-assignment
                :read-only="isReadOnly"
                :model-instances="step?.modelInstances"
                :opened="editStepModelInstanceAssignmentDialog"
                @cancel="editStepModelInstanceAssignmentDialog = false"
                @save="saveStepModelInstanceAssignment"
              >
                <task-label type="Step Template" :task-name="task?.name" :task-external-id="task?.businessId" :step-name="step?.name" :step-position="step?.position" />
              </model-instance-assignment-editor>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </template>
    <template #detail>
      <v-card v-if="task" elevation="7" class="d-flex flex-column fill-height tasks-card">
        <close-card-button @click="closeDetails"></close-card-button>
        <v-card-title class="pt-2">
          <breadcrumbs>{{ task?.name }}</breadcrumbs>
        </v-card-title>
        <v-card-text class="fill-height px-2 py-0">
          <v-row class="fill-height">
            <v-col cols="12 py-0">
              <product-template-details v-if="task" :task="task" @manage-model-instances="manageStepModelInstances" />
              <copy-to-new-product-dialog v-model="copyToNewProductDialog" :grid-api="gridWrapperRef?.gridApi" @selected="onCopyReference" />
              <product-template-historical-performance-dialog v-if="task" v-model="historicalPerformanceDialog" :template="task" />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-card v-if="!task" elevation="7" class="d-flex flex-column fill-height no-tasks-card">
        <close-card-button @click="closeDetails"></close-card-button>
        <v-card-text class="fill-height">
          <v-row class="fill-height">
            <v-col cols="12" class="d-flex align-center justify-center">
              <label class="text-secondary text-h6 font-weight-regular">
                {{ $t("productTemplate-view-noTaskSelected-label", { $: "No Product Template selected" }) }}
              </label>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </template>
  </split-layout>
</template>

<script lang="ts" setup>
import Breadcrumbs from "@/components/Layout/Breadcrumbs.vue";
import SplitLayout from "@/components/Layout/SplitLayout.vue";
import ProductTemplatesGrid from "@/components/ProductTemplates/ProductTemplatesGrid.vue";
import ProductTemplateDetails from "@/components/ProductTemplates/ProductTemplateDetailsGrid.vue";
import ModelInstanceAssignmentEditor from "@/components/ModelInstances/ModelInstanceAssignment/ModelInstanceAssignmentEditor.vue";
import TaskLabel from "@/components/ModelInstances/ModelInstanceAssignment/TaskLabel.vue";
import CopyToNewProductDialog from "@/components/ProductTemplates/CopyToNewProductDialog.vue";
import ProductTemplateHistoricalPerformanceDialog from "@/components/ProductTemplates/ProductTemplateHistoricalPerformanceDialog.vue";
import { useProductTemplatesStore } from "@/store/ProductTemplatesStore";
import { useModelInstancesStore } from "@/store/ModelInstancesStore";
import { useScenariosStore } from "@/store/ScenariosStore";
import { ModelInstanceDto, ProductTemplateDto, ResourceDto, StepTemplateDto, TaskSubType, TemplateStatus } from "@masta/generated-model";
import { computed, nextTick, onMounted, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import CloseCardButton from "@/components/CloseCardButton.vue";

const props = defineProps<{
  componentRoutePath: string;
  filterByProductId: string;
  embedded: boolean;
}>();

const task = ref<ProductTemplateDto | null>(null);
const step = ref<StepTemplateDto | null>(null);
const refreshGridAction = ref<(() => void) | null>(null);
const editTaskModelInstanceAssignmentDialog = ref(false);
const editStepModelInstanceAssignmentDialog = ref(false);
const detail = ref(false);
const copyToNewProductDialog = ref(false);
const historicalPerformanceDialog = ref(false);
const gridWrapperRef = ref<InstanceType<typeof ProductTemplatesGrid> | null>(null);
const filterByProcessDraftId = ref<string | undefined>();

const { fetchSchemas } = useModelInstancesStore();
const { updateTaskModelInstanceAssignment, copyToNewProduct } = useProductTemplatesStore();
const { selectedScenario } = useScenariosStore();

const router = useRouter();
const route = useRoute();
const productTemplatesStore = useProductTemplatesStore();

const splitLayoutRef = ref<typeof SplitLayout | null>(null);

watch(() => route.query.taskId, async (taskId) => {
  if (taskId != null) {
    if (task.value != null && task.value.id === taskId) {
      return;
    } else {
      task.value = await productTemplatesStore.fetchSingle(taskId as string);
      showDetails();
    }
  } else {
    task.value = null;
  }
}, { immediate: true });

watch(
  () => route.query,
  async (query, _) => {
    filterByProcessDraftId.value = query.draft as string;
  },
  { immediate: true }
);

onMounted(() => {
  fetchSchemas();

  if (task.value == null) {
    closeDetails();
  }
});

const rowSelected = async (selectedTask: ProductTemplateDto) => {
  if (selectedTask) {
    task.value = selectedTask;
    showDetails();
    if (!props.embedded) {
      await router.push({ query: { ...(route.query ?? {}), taskId: selectedTask.id } });
    }
  }
};

function manageTaskModelInstances(selectedTask: ProductTemplateDto, action: () => void) {
  refreshGridAction.value = action;
  task.value = selectedTask;
  editTaskModelInstanceAssignmentDialog.value = true;
}

function manageStepModelInstances(selectedStep: StepTemplateDto, action: () => void) {
  refreshGridAction.value = action;
  step.value = selectedStep;
  editStepModelInstanceAssignmentDialog.value = true;
}

async function saveTaskModelInstanceAssignment(instances: ModelInstanceDto[]) {
  await updateTaskModelInstanceAssignment({
    taskId: task.value.id,
    scenarioId: task.value.scenarioId,
    modelInstances: instances.map((x) => ({
      modelInstanceId: x.id,
      revisionNumber: x.revisionNumber
    }))
  });

  if (refreshGridAction.value !== null) {
    refreshGridAction.value();
  }

  editTaskModelInstanceAssignmentDialog.value = false;
}

async function saveStepModelInstanceAssignment(instances: ModelInstanceDto[]) {
  await updateTaskModelInstanceAssignment({
    taskId: step.value.taskId,
    scenarioId: task.value?.scenarioId,
    stepId: step.value.id,
    modelInstances: instances.map((x) => ({
      modelInstanceId: x.id,
      revisionNumber: x.revisionNumber
    }))
  });

  if (refreshGridAction.value !== null) {
    refreshGridAction.value();
  }

  editStepModelInstanceAssignmentDialog.value = false;
}

/**
 * Called to create gutter element for split panel.
 */
function createSplitPanelGutterElement(index: number, direction: "horizontal" | "vertical") {
  const gutter = document.createElement("div");
  gutter.classList.add("gutter-product-templates");
  return gutter;
}

function showDetails() {
  splitLayoutRef.value?.showDetails();
}

function closeDetails() {
  splitLayoutRef.value?.closeDetails();
}

function onCopyReference(resource: ResourceDto) {
  if (task.value !== null) {
    copyToNewProduct(task.value.id, resource.id);
  }
}

const isReadOnly = computed(() => {
  const productTemplate = task.value;
  return productTemplate?.taskSubType !== TaskSubType.ProductionTask || productTemplate?.templateStatus !== TemplateStatus.WorkInProgress;
});
</script>

<style lang="scss" scoped>
.tasks-card {
  border: 2px solid rgb(var(--v-theme-primary));
  border-radius: 0px;

  .close-card-button {
    position: absolute;
    top: 0px;
    right: 10px;
  }
}

.no-tasks-card {
  border: 2px solid rgb(var(--v-theme-background-surface));
  border-radius: 10px;

  .close-card-button {
    position: absolute;
    top: 2px;
    right: 10px;
  }
}

.gutter-product-templates {
  cursor: col-resize;
}
</style>
