<template>
  <master-detail-layout scenario-selector>
    <template #master>
      <v-card elevation="0" class="flexcard fill-height">
        <v-card-title>
          <breadcrumbs>{{ $t("keyPerformanceIndicators-view-keyPerformanceIndicators-title", { $: "Schedule Indicator Analysis" }) }}</breadcrumbs>
        </v-card-title>
        <v-card-text class="fill-height">
          <v-row class="fill-height" no-gutters>
            <v-col cols="12">
              <kpi-results-grid />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </template>
  </master-detail-layout>
</template>

<script>
import MasterDetailLayout from "@/components/Layout/MasterDetailLayout.vue";
import ContactGrid from "@/components/Contacts/ContactGrid.vue";
import Breadcrumbs from "@/components/Layout/Breadcrumbs.vue";
import CommunicationsGrid from "@/components/Communications/CommunicationsGrid.vue";
import KpiResultsGrid from "@/components/KpiResults/KpiResultsGrid.vue";

export default {
  name: "Key Performance Indicators",
  components: { KpiResultsGrid, Breadcrumbs, MasterDetailLayout },
  title: "Key Performance Indicators"
};
</script>

<style lang="scss" scoped></style>
