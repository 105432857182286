import { ApiClient } from "@/services/api/common.api";
import {
  CreatePersonCommand,
  CreatePersonGroupCommand,
  CreateResourceResponse,
  GetPersonnelPaginatedQuery,
  GetPersonnelResponse,
  IAgGridPaginationResponse,
  PersonDto,
  UpdatePersonCommand,
  UpdatePersonGroupCommand
} from "@masta/generated-model";

export default {
  async getAll(scenarioId: string) {
    return ApiClient.get<GetPersonnelResponse>("/personnel?scenarioId=${scenarioId}");
  },

  async getPaginated(request: GetPersonnelPaginatedQuery) {
    return ApiClient.post<IAgGridPaginationResponse<PersonDto>>(`/personnel`, request);
  },
  async getSingle(id: string, scenarioId: string) {
    return ApiClient.get<PersonDto>(`/personnel/${id}?scenarioId=${scenarioId}`);
  },

  async create(request: CreatePersonCommand) {
    return ApiClient.put<CreateResourceResponse>(`/personnel`, request);
  },
  async update(request: UpdatePersonCommand) {
    return ApiClient.put<string>(`/personnel/${request.id}`, request);
  },
  async remove(id: string, scenarioId?: string) {
    return ApiClient.delete(`/personnel/${id}?scenarioId=${scenarioId}`);
  },

  async createGroup(request: CreatePersonGroupCommand) {
    return ApiClient.post<CreateResourceResponse>("/personGroups", request);
  },
  async updateGroup(request: UpdatePersonGroupCommand) {
    return ApiClient.put<undefined>(`/personGroups/${request.id}`, request);
  },
  async removeGroup(id: string, scenarioId?: string) {
    return ApiClient.delete(`/personGroups/${id}?scenarioId=${scenarioId}`);
  }
};
