import { ApiClient } from "@/services/api/common.api";
import {
  ArchiveModelInstanceCommand,
  AttachmentSourceType,
  CreateDocumentForContextCommand,
  CreateNewDocumentRevisionCommand,
  CreateNewDocumentRevisionWithNewAttachmentCommand,
  GetDocumentsDto,
  MakeDocumentCopyCommand,
  MarkConfirmedModelInstanceCommand,
  MarkSentModelInstanceCommand,
  ReleaseModelInstanceCommand,
  UpdateDocumentCommand
} from "@masta/generated-model";
import dayjs from "dayjs";

export default {
  async getAllDocumentsForContext(contextName: string) {
    return ApiClient.get<GetDocumentsDto>(`/documents/${contextName}`);
  },
  getDocumentsAssignedToResourceForContext(contextName: string, resourceId: string, scenarioId: string) {
    return ApiClient.get<GetDocumentsDto>(`/documents/context/${contextName}/resource/${resourceId}?scenarioId=${scenarioId}`);
  },
  getDocumentsAssignedToOrderForContext(contextName: string, orderId: string, scenarioId: string) {
    return ApiClient.get<GetDocumentsDto>(`/documents/context/${contextName}/order/${orderId}?scenarioId=${scenarioId}`);
  },
  getDocumentsAssignedToCostCatalogueItemForContext(contextName: string, costCatalogueItemId: string, scenarioId: string) {
    return ApiClient.get<GetDocumentsDto>(`/documents/context/${contextName}/costCatalogueItem/${costCatalogueItemId}?scenarioId=${scenarioId}`);
  },

  async getDocumentContentStream({
    documentId,
    revisionNumber,
    attachmentContentStorageConfigurationId,
    attachmentSourceType
  }: {
    documentId: string;
    revisionNumber: number;
    attachmentContentStorageConfigurationId?: string;
    attachmentSourceType?: AttachmentSourceType;
  }) {
    return ApiClient.get<Blob>(`documents/${documentId}/${revisionNumber}/document-content-stream`, {
      params: {
        attachmentContentStorageConfigurationId,
        attachmentSourceType
      },
      responseType: "blob"
    });
  },
  async getDocumentContentThumbnailStream({
    documentId,
    revisionNumber,
    attachmentContentStorageConfigurationId,
    attachmentSourceType,
    width,
    height
  }: {
    documentId: string;
    revisionNumber: number;
    attachmentContentStorageConfigurationId?: string;
    attachmentSourceType?: AttachmentSourceType;
    width?: number;
    height?: number;
  }) {
    const queryParams = [];
    if (width && !isNaN(width)) {
      queryParams.push(`width=${width}`);
    }
    if (height && !isNaN(height)) {
      queryParams.push(`height=${height}`);
    }
    return ApiClient.get<Blob>(`documents/${documentId}/${revisionNumber}/document-content-thumbnail-stream${queryParams.length ? `?${queryParams.join("&")}` : ""}`, {
      params: {
        attachmentContentStorageConfigurationId,
        attachmentSourceType
      },
      responseType: "blob"
    });
  },
  async createDocument(createDocument: CreateDocumentForContextCommand) {
    let formData = new FormData();
    formData = this.createFormForDocumentData(createDocument);
    return await ApiClient.post(`documents`, formData, { headers: { "Content-Type": `multipart/form-data;` } });
  },

  createFormForDocumentData(document: any): FormData {
    const obj: any = {
      ...document,
      issueDate: dayjs().set("seconds", 0).set("millisecond", 0).toISOString()
    };
    const formData = new FormData();
    for (const [key, value] of Object.entries(obj)) {
      if (Array.isArray(value)) {
        if (value.length > 0) {
          value.forEach((v) => formData.append(`${key}[]`, v));
        } else {
          formData.append(key, "");
        }
      } else {
        if (value !== null) {
          formData.append(key, value as any);
        }
      }
    }
    if (document.file) formData.append("File", document.file, document.businessId);
    return formData;
  },

  async updateDocument(updateDocument: UpdateDocumentCommand) {
    let formData = new FormData();
    formData = this.createFormForDocumentData(updateDocument);
    return await ApiClient.put(`documents/${updateDocument.id}`, formData, { headers: { "Content-Type": `multipart/form-data;` } });
  },
  async deleteDocument(documentId: string, revisionNumber: number) {
    await ApiClient.delete(`documents/${documentId}/${revisionNumber}`);
  },
  async deassignResourceDocument(documentId: string, resourceId: string, scenarioId: string) {
    await ApiClient.delete(`documents/resource/${resourceId}/document/${documentId}?scenarioId=${scenarioId}`);
  },
  async deleteResourceDocument(documentId: string, revisionNumber: number, resourceId: string, scenarioId: string) {
    //TODO scenarioId is not used in the backend
    await ApiClient.delete(`documents/resource/${resourceId}/document/${documentId}/${revisionNumber}?scenarioId=${scenarioId}`);
  },
  async deassignOrderDocument(documentId: string, orderId: string, scenarioId: string) {
    await ApiClient.delete(`documents/order/${orderId}/document/${documentId}?scenarioId=${scenarioId}`);
  },
  async deleteOrderDocument(documentId: string, revisionNumber: number, orderId: string, scenarioId: string) {
    await ApiClient.delete(`documents/order/${orderId}/document/${documentId}/${revisionNumber}?scenarioId=${scenarioId}`);
  },
  async deassignCostCatalogueItemDocument(documentId: string, costCatalogueItemId: string, scenarioId: string) {
    await ApiClient.delete(`documents/costCatalogueItem/${costCatalogueItemId}/document/${documentId}?scenarioId=${scenarioId}`);
  },
  async deleteCostCatalogueItemDocument(documentId: string, revisionNumber: number, costCatalogueItemId: string, scenarioId: string) {
    await ApiClient.delete(`documents/costCatalogueItem/${costCatalogueItemId}/document/${documentId}/${revisionNumber}?scenarioId=${scenarioId}`);
  },
  async makeCopy(request: MakeDocumentCopyCommand) {
    return ApiClient.post(`/documents/${request.id}/${request.revisionNumber}/makeCopy`, request);
  },
  async createNewRevision(request: CreateNewDocumentRevisionCommand) {
    return ApiClient.post(`/documents/${request.id}/create-new-revision`, request);
  },
  async createNewRevisionWithNewAttachment(request: CreateNewDocumentRevisionWithNewAttachmentCommand) {
    let formData = new FormData();
    formData = this.createFormForDocumentData(request);
    return await ApiClient.post(`documents/${request.id}/create-new-revision-with-new-attachment`, formData, { headers: { "Content-Type": `multipart/form-data;` } });
  },
  async release(request: ReleaseModelInstanceCommand) {
    return ApiClient.post(`/modelinstance/${request.id}/${request.revisionNumber}/release`, request);
  },
  async markSent(request: MarkSentModelInstanceCommand) {
    return ApiClient.post(`/modelinstance/${request.id}/${request.revisionNumber}/markSent`, request);
  },
  async markConfirmed(request: MarkConfirmedModelInstanceCommand) {
    return ApiClient.post(`/modelinstance/${request.id}/${request.revisionNumber}/markConfirmed`, request);
  },
  async archive(request: ArchiveModelInstanceCommand) {
    return ApiClient.post(`/modelinstance/${request.id}/${request.revisionNumber}/archive`, request);
  }
};
