export class AppError extends Error {
  constructor(
    public readonly status: number,
    public readonly type: string,
    public readonly title: string,
    public readonly detail?: string,
    public readonly errors?: any,
    public readonly args?: any
  ) {
    super(title);
    // 👇️ because we are extending a built-in class
    Object.setPrototypeOf(this, AppError.prototype);
  }
}
