import { GanttException, GanttSettings, IActivityDataLoaderOperation, IChartDataLoaderOperation, ILayer, IRowChartData, IRowChartDataEntry, Layer } from "@masta/gantt2/core";
import { inject, injectable } from "inversify";
import { CustomSettingKeys } from "@/components/Gantt/ResourcesGantt/CustomSettingKeys";
import {
  GanttLayerSettings,
  GanttNoteDto,
  GanttResourceDataDto,
  GanttResourceDataResponseDto,
  GanttResourceDto, GanttTimeSectionAggregation,
  GetAllGanttResourcesResponse,
  ResourceCapacityEntrySource,
  ResourceCapacityGroup,
  ResourceType
} from "@masta/generated-model";
import { EnhancedGanttResourceCapacityDto, ExtendedGanttResourceDto, toEnhancedGanttNoteDto, toEnhancedGanttResourceCapacityDto } from "src/components/Gantt/Common/Model";
import SystemEnumService from "@/services/system-enum.service";
import { Instant } from "@js-joda/core";
import { BehaviorSubject } from "rxjs";
import { CommonGanttDataLoader, IGanttDataLoaderOptions } from "@/components/Gantt/Common/CommonGanttDataLoader";

@injectable()
export class ResourcesGanttDataLoader extends CommonGanttDataLoader {
  public static readonly RESOURCE_NOTE_LAYER = "resource-notes";
  protected _rows$$ = new BehaviorSubject<ExtendedGanttResourceDto[]>([]);
  private _initialLoadDone: boolean = false;
  private _resourceFilter: string | undefined;
  private _measurementUnitTranslations: {
    [key: string]: string;
  };
  private _timeSectionAggregation: GanttTimeSectionAggregation;

  constructor(@inject(GanttSettings) _settings: GanttSettings) {
    super(_settings);
  }

  public static sortResources(r1: GanttResourceDto, r2: GanttResourceDto) {
    if (r1.businessId || r2.businessId) {
      return r1.businessId && r2.businessId ? r1.businessId.localeCompare(r2.businessId) : r1.businessId ? -1 : 1;
    }
    return r1.id.localeCompare(r2.id);
  }

  async initialLoad(startDate: number, endDate: number, options?: IGanttDataLoaderOptions): Promise<void> {
    this._options = options!;

    this.setScenarioId();

    this._resourceFilter = this._settings.getSetting<string>(CustomSettingKeys.QUERY_FILTER) ?? this._settings.getSetting<string>(CustomSettingKeys.FILTER);
    this._timeSectionAggregation = this._settings.getSetting<GanttTimeSectionAggregation>(CustomSettingKeys.TIME_SECTION_AGGREGATION) ?? GanttTimeSectionAggregation.None;

    this._measurementUnitTranslations =
      this._settings.getSetting<{
        [key: string]: string;
      }>(CustomSettingKeys.MEASUREMENT_UNIT_TRANSLATIONS) ?? {};

    await this.loadResources(startDate, endDate);
    this._initialLoadDone = true;
  }

  public refreshApiToken(token: string) {
    this._options.token = token;
  }

  async loadData(startDate: number, endDate: number, rowIds?: string[]): Promise<void> {
    await this.checkIfInitialLoadIsDone();
    const startTime = Instant.ofEpochMilli(startDate);
    const endTime = Instant.ofEpochMilli(endDate);

    const providedRowIds = rowIds ?? this._rows$$.value.map((r) => r.id);
    const processedResources = this._rows$$.value.filter((r) => providedRowIds.includes(r.id));

    if (processedResources.length === 0) {
      console.warn("[Gantt] No resources to load data for");
      return;
    }

    const { data } = await this._apiClient.post<GanttResourceDataResponseDto>("/gantt/resource-data", {
      resourceIds: providedRowIds,
      scenarioId: this._scenarioId,
      start: startTime.toJSON(),
      end: endTime.toJSON(),
      aggregation: +this._timeSectionAggregation
    });

    const activityOperations: IActivityDataLoaderOperation[] = [];
    const chartDataActivityOperations: IChartDataLoaderOperation[] = [];

    const activities: Map<string, EnhancedGanttResourceCapacityDto[]> = new Map<string, EnhancedGanttResourceCapacityDto[]>();

    const noteLayer = this.getResourceNoteLayer();

    for (const processedResource of processedResources) {
      const resourceData = data.data.find((x) => x.resourceId === processedResource.id);
      if (!resourceData) {
        continue;
      }

      // Resource activities
      const resourceActivities = await this.getAllResourceCapacities(processedResource, resourceData);
      for (const [key, value] of resourceActivities) {
        if (activities.has(key)) {
          activities.get(key)?.push(...value);
        } else {
          activities.set(key, value);
        }
      }

      for (const layer of this._layers$$.value.map((l) => l.id)) {
        const acts = activities.get(layer)?.filter((a) => a.resourceId === processedResource.id);
        activityOperations.push({ rowId: processedResource.id, startDate, endDate, layerId: layer, activities: acts ?? [] });
      }

      // Chart data
      const chartData: IRowChartData[] = [];
      chartData.push(...(await this.loadChartDataForResource(processedResources, resourceData)));
      if (chartData.length > 0) {
        chartDataActivityOperations.push({
          startDate: startTime.toEpochMilli(),
          endDate: endTime.toEpochMilli(),
          chartData
        });
      }

      // Activity notes
      const activityNotes = resourceData.capacities
        .map((c) => c.notes)
        // reduce to flat array filtering out already existing notes
        .reduce((acc, val) => acc.concat(val.filter((x) => !acc.map((a) => a.id).includes(x.id))), [])
        .map((n) => toEnhancedGanttNoteDto("NoteActivity", n as GanttNoteDto));

      activityOperations.push({
        rowId: processedResource.id,
        startDate: Instant.parse(processedResource.periodStart).toEpochMilli(),
        endDate: Instant.parse(processedResource.periodStart).toEpochMilli(),
        layerId: noteLayer.id,
        activities: activityNotes
      });

      // Resource notes
      const notes =
        resourceData.notes?.map((n) => {
          return toEnhancedGanttNoteDto("NoteActivity", n as GanttNoteDto);
        }) ?? [];
      activityOperations.push({
        rowId: processedResource.id,
        startDate: Instant.parse(processedResource.periodStart).toEpochMilli(),
        endDate: Instant.parse(processedResource.periodStart).toEpochMilli(),
        layerId: noteLayer.id,
        activities: notes
      });
    }

    for (const operation of activityOperations) {
      this._activityOperation$$.next(operation);
    }
    for (const operation of chartDataActivityOperations) {
      this._chartDataOperation$$.next(operation);
    }
  }

  protected async loadLayers(processedResources: ExtendedGanttResourceDto[]) {
    let layers = this.getLayersForResourceTypes(processedResources);
    layers = [...layers, new Layer(ResourcesGanttDataLoader.RESOURCE_NOTE_LAYER, ResourcesGanttDataLoader.RESOURCE_NOTE_LAYER)];
    layers.forEach((layer) => {
      const layerSettings = this.getLayerSettings(layer.id);
      if (layerSettings) {
        layer.visible = layerSettings.visible;
        layer.opacity = layerSettings.opacity;
      }
    });
    this._layers$$.next(layers.map((l) => l.toJSON()));
  }

  protected async loadResources(startDate: number, endDate: number) {

    const excludeResourceGroups = this._settings.getSetting<boolean>(CustomSettingKeys.ROW_EXCLUDE_RESOURCE_GROUPS) ?? false;

    const { data } = await this._apiClient.post<GetAllGanttResourcesResponse>("/gantt/resources", {
      resourceFilter: this._resourceFilter,
      scenarioId: this._scenarioId
    });

    const resourceIdFilter = this._settings.getSetting(CustomSettingKeys.RESOURCE_ID_FILTER);
    let resources = [];
    if (resourceIdFilter) {
      resources = data?.resources?.filter((r) => resourceIdFilter.includes(r.id) || resourceIdFilter.includes(r.parentId)).sort(ResourcesGanttDataLoader.sortResources) ?? [];
    } else {
      resources = data?.resources?.sort(ResourcesGanttDataLoader.sortResources) ?? [];
    }

    if (!resourceIdFilter && excludeResourceGroups) {
      const excludeGroups = [ResourceType.PersonGroup, ResourceType.AgreementGroup, ResourceType.EquipmentGroup, ResourceType.MaterialGroup];
      resources = resources.filter((r) => !excludeGroups.includes(r.type));
    }

    let processedResources: ExtendedGanttResourceDto[] = resources as ExtendedGanttResourceDto[];
    this.processResources(processedResources);
    processedResources = processedResources.sort(this.sortResources);

    await this.loadLayers(processedResources);

    this._rowsAdded$$.next(processedResources);
    this._rows$$.next(processedResources);
  }

  protected sortResources(a: ExtendedGanttResourceDto, b: ExtendedGanttResourceDto) {
    if (a.parentId === null && b.parentId === null) {
      return a.businessId?.localeCompare(b.businessId ?? "") ?? 0; // Sort root rows by name
    } else if (a.parentId === null) {
      return -1; // Place root rows before child rows
    } else if (b.parentId === null) {
      return 1; // Place root rows before child rows
    } else {
      // Sort child rows by name
      return a.businessId?.localeCompare(b.businessId ?? "") ?? 0;
    }
  }

  protected processResources(resources: ExtendedGanttResourceDto[]) {
    resources.forEach((r) => {
      if (!r.childrenIds) {
        r.childrenIds = [];
      }
      if (r.parentId) {
        const parent = resources.find((x) => x.id === r.parentId);
        if (parent) {
          if (!parent.childrenIds) {
            parent.childrenIds = [];
          }
          parent.childrenIds.push(r.id);
        }
      }
    });
  }

  protected getLayersForResourceTypes(resources: ExtendedGanttResourceDto[]) {
    const resourceTypes = [...new Set(resources.map((x: ExtendedGanttResourceDto) => x.type))];

    const schedulingTypeLayers = resourceTypes.map(
      (t: ResourceType) =>
        new Layer(
          `${SystemEnumService.resourceType(t)} ${SystemEnumService.resourceCapacityEntrySource(ResourceCapacityEntrySource.Scheduling)}`,
          `${t.valueOf()}-${ResourceCapacityEntrySource.Scheduling.valueOf()}`
        )
    );

    const executionTypeLayers = resourceTypes.map(
      (t: ResourceType) =>
        new Layer(
          `${SystemEnumService.resourceType(t)} ${SystemEnumService.resourceCapacityEntrySource(ResourceCapacityEntrySource.Execution)}`,
          `${t.valueOf()}-${ResourceCapacityEntrySource.Execution.valueOf()}`
        )
    );

    const calendarTypeLayers = resourceTypes.map(
      (t: ResourceType) =>
        new Layer(
          `${SystemEnumService.resourceType(t)} ${SystemEnumService.resourceCapacityEntrySource(ResourceCapacityEntrySource.Calendar)}`,
          `${t.valueOf()}-${ResourceCapacityEntrySource.Calendar.valueOf()}`
        )
    );

    const availabilityRuleTypeLayers = resourceTypes.map(
      (t: ResourceType) =>
        new Layer(
          `${SystemEnumService.resourceType(t)} ${SystemEnumService.resourceCapacityEntrySource(ResourceCapacityEntrySource.AvailabilityRule)}`,
          `${t.valueOf()}-${ResourceCapacityEntrySource.AvailabilityRule.valueOf()}`
        )
    );

    calendarTypeLayers.forEach((l) => (l.visible = false));
    availabilityRuleTypeLayers.forEach((l) => (l.visible = false));

    return [...availabilityRuleTypeLayers, ...schedulingTypeLayers, ...executionTypeLayers, ...calendarTypeLayers];
  }

  protected async getAllResourceCapacities(resource: ExtendedGanttResourceDto, data: GanttResourceDataDto) {
    const activities: Map<string, EnhancedGanttResourceCapacityDto[]> = new Map<string, EnhancedGanttResourceCapacityDto[]>();

    data.capacities?.forEach((c) => {
      let activity;
      if (c.entrySourcePayload) {
        try {
          c.entrySourcePayload = JSON.parse(c.entrySourcePayload);
        } catch {
          // do nothing
        }
      }

      // warunek dla scheduling i exectuion - pokazywac tylko demand - ResourceCapacityGroup.DEMAND === 1

      if (c.entrySource === ResourceCapacityEntrySource.Scheduling && c.capacityGroup == ResourceCapacityGroup.Demand) {
        if (c.entrySourcePayloadType === "Waiting") {
          activity = toEnhancedGanttResourceCapacityDto("WaitingSchedulingResourceCapacityActivity", c);
        } else {
          activity = toEnhancedGanttResourceCapacityDto("DemandSchedulingResourceCapacityActivity", c);
        }
      } else if (c.entrySource === ResourceCapacityEntrySource.Execution && c.capacityGroup == ResourceCapacityGroup.Demand) {
        if (c.entrySourcePayloadType === "Interrupted") {
          activity = toEnhancedGanttResourceCapacityDto("InterruptionExecutionResourceCapacityActivity", c);
        } else {
          activity = toEnhancedGanttResourceCapacityDto("DemandExecutionResourceCapacityActivity", c);
        }
      } else if (c.entrySource === ResourceCapacityEntrySource.Calendar) {
        activity = toEnhancedGanttResourceCapacityDto("CalendarResourceCapacityActivity", c);
      } else if (c.entrySource === ResourceCapacityEntrySource.AvailabilityRule) {
        activity = toEnhancedGanttResourceCapacityDto("AvailabilityRuleResourceCapacityActivity", c);
      } else {
        return;
      }
      const layer = this.getResourceLayer(resource.type, c.entrySource);
      if (!activities.has(layer.id)) {
        activities.set(layer.id, []);
      }
      activities.get(layer.id)?.push(activity);
    });

    return activities;
  }

  private checkIfInitialLoadIsDone() {
    return new Promise((resolve) => {
      if (this._initialLoadDone) return resolve(true);
      const subscription = this._rows$$.subscribe((rows) => {
        if (rows.length > 0) {
          subscription.unsubscribe();
          resolve(true);
        }
      });
    });
  }

  private getLayerSettings(id: string): GanttLayerSettings | null {
    return (this._settings.getSetting(CustomSettingKeys.LAYERS) ?? []).find((x: GanttLayerSettings) => x.id === id) ?? null;
  }

  private getResourceLayer(resourceType: ResourceType, entrySource: ResourceCapacityEntrySource): ILayer {
    const layer = this._layers$$.value.find((l) => l.id === `${resourceType.valueOf()}-${entrySource.valueOf()}`);
    if (!layer) {
      // console.log(this._layers$$.value, resourceType, entrySource);
      throw new GanttException(`Layer not found: ${SystemEnumService.resourceType(resourceType)} - ${SystemEnumService.resourceCapacityEntrySource(entrySource)}`);
    }
    return layer;
  }

  private async loadChartDataForResource(processedResources: ExtendedGanttResourceDto[], data: GanttResourceDataDto): Promise<IRowChartData[]> {
    if (processedResources.length === 0) {
      return [];
    }
    const chartDataArray: IRowChartData[] = [];
    const includeResGroups = this._settings.getSetting(CustomSettingKeys.CHART_INCLUDE_RESOURCE_GROUPS);
    if (!includeResGroups) {
      const excludeGroups = [ResourceType.EquipmentGroup, ResourceType.PersonGroup, ResourceType.AgreementGroup];
      processedResources
        .filter((r) => excludeGroups.includes(r.type))
        .forEach((r) => {
          chartDataArray.push({
            id: `supply-${r.id}`,
            resourceId: r.id,
            data: [],
            visible: false
          });
        });
    }

    for (const section of data.timeSections ?? []) {
      if (section.supplyTimeSections && section.supplyTimeSections.length > 0) {
        const chartData: IRowChartDataEntry[] = [];
        for (const [index, ts] of section.supplyTimeSections.entries()) {
          chartData.push({
            x: ts.startMillis,
            y: ts.value,
            id: `${section.resourceId}-${index}-${ts.startMillis}`,
            label: `${ts.value} ${this._measurementUnitTranslations[ts.unit] ?? ts.unit}`
          });

          chartData.push({
            x: ts.endMillis,
            y: ts.value,
            id: `${section.resourceId}-${index}-${ts.endMillis}`,
            label: `${ts.value} ${this._measurementUnitTranslations[ts.unit] ?? ts.unit}`
          });
        }

        if (chartData.length > 0) {
          chartDataArray.push({
            id: `supply-${section.resourceId}`,
            resourceId: section.resourceId,
            data: chartData,
            visible: true
          });
        }
      }

      if (section.demandTimeSections && section.demandTimeSections.length > 0) {
        const chartData: IRowChartDataEntry[] = [];
        for (const [index, ts] of section.demandTimeSections.entries()) {
          chartData.push({
            x: ts.startMillis,
            y: ts.value,
            id: `${section.resourceId}-${index}-${ts.startMillis}`,
            label: `${ts.value} ${this._measurementUnitTranslations[ts.unit] ?? ts.unit}`
          });

          chartData.push({
            x: ts.endMillis,
            y: ts.value,
            id: `${section.resourceId}-${index}-${ts.endMillis}`,
            label: `${ts.value} ${this._measurementUnitTranslations[ts.unit] ?? ts.unit}`
          });
        }

        if (chartData.length > 0) {
          chartDataArray.push({
            id: `demand-${section.resourceId}`,
            resourceId: section.resourceId,
            data: chartData,
            visible: true
          });
        }
      }
    }
    return chartDataArray;
  }

  private async loadNotesForResource(processedResource: ExtendedGanttResourceDto, data: GanttResourceDataDto) {
    const layer = this.getResourceNoteLayer();
    const notes =
      data.notes?.map((n) => {
        return toEnhancedGanttNoteDto("NoteActivity", n as GanttNoteDto);
      }) ?? [];
    this._activityOperation$$.next({
      rowId: processedResource.id,
      startDate: Instant.parse(processedResource.periodStart).toEpochMilli(),
      endDate: Instant.parse(processedResource.periodStart).toEpochMilli(),
      layerId: layer.id,
      activities: notes
    });
  }

  private getResourceNoteLayer(): ILayer {
    const layer = this._layers$$.value.find((l) => l.id === ResourcesGanttDataLoader.RESOURCE_NOTE_LAYER);
    if (!layer) {
      throw new GanttException(`Layer not found: ${ResourcesGanttDataLoader.RESOURCE_NOTE_LAYER}`);
    }
    return layer;
  }
}
