import { ActivityRef } from "../ActivityRef";
import { Layer } from "../Layer";

import { IOverlappingActivity } from "../Activity";
import { TimeIntervalTreeActivityRepository } from "./TimeIntervalTreeActivityRepository";
import { OverlapComputer } from "./OverlapComputer";
import { Observable, Subject } from "rxjs";

export class OverlappingTimeIntervalTreeActivityRepository<Activity extends IOverlappingActivity> extends TimeIntervalTreeActivityRepository<Activity> {
  private _overlapComputer: OverlapComputer<Activity>;
  private readonly _filter: () => Activity[];
  private _overlapRecomputed$$: Subject<void> = new Subject<void>();

  constructor(filter: (activity: Activity) => boolean = () => true) {
    super();
    this._filter = () => {
      return this.getAllActivities().filter(filter);
    };
    this._overlapComputer = new OverlapComputer(this._filter);
  }

  get overlapRecomputed$(): Observable<void> {
    return this._overlapRecomputed$$.asObservable();
  }

  addActivity(activityRef: ActivityRef<Activity>): void {
    super.addActivity(activityRef);
    this._overlapComputer.compute();
    this._overlapRecomputed$$.next();
  }

  addActivities(activityRef: ActivityRef<Activity>[]): void {
    for (const r of activityRef) {
      super.addActivity(r);
    }
    this._overlapComputer.compute();
    this._overlapRecomputed$$.next();
  }

  removeActivity(activityRef: ActivityRef<Activity>): void {
    super.removeActivity(activityRef);
    this._overlapComputer.compute();
    this._overlapRecomputed$$.next();
  }

  clearActivities(): void {
    super.clearActivities();
    this._overlapComputer.compute();
    this._overlapRecomputed$$.next();
  }

  clearLayerActivities(layer: Layer): void {
    super.clearLayerActivities(layer);
    this._overlapComputer.compute();
    this._overlapRecomputed$$.next();
  }

  getMaximalOverlapLevel(): number {
    return this._filter().map(x => x.overlap.getOverlapPosition()).reduce((a, b) => Math.max(a, b), 0) + 1;
  }

  recomputeOverlaps(): void {
    this._overlapComputer.compute();
    this._overlapRecomputed$$.next();
  }
}
