<script lang="ts" setup>
import {SchemaTypeModel} from "@/components/ModelInstances/SchemaBuilder/SchemaModel";

const props = defineProps<{
  modelValue: SchemaTypeModel;
}>();

const emit = defineEmits(["update:modelValue"]);

function update(key: string, value: any) {
  const newModel = { ...props.modelValue, [key]: value };
  emit("update:modelValue", newModel);
}
</script>

<template>
  <div class="d-flex justify-start flex-wrap">
    <v-chip class="ma-2" size="x-large" label> {{ $t("schemaType-booleanProperty-type-label", {$: "Boolean"}) }}</v-chip>
    <v-text-field
      variant="outlined"
      class="mx-2"
      :label="$t('schemaType-property-icon-label', { $: 'Icon' })"
      :model-value="modelValue.icon"
      @update:model-value="update('icon', $event)"
    />
    <v-text-field
      variant="outlined"
      class="mx-2"
      :label="$t('schemaType-property-color-label', { $: 'Color' })"
      :model-value="modelValue.color"
      @update:model-value="update('color', $event)"
    />
  </div>
</template>

<style lang="scss" scoped>
.v-text-field {
  width: 200px;
}
</style>
