<script setup lang="ts">
import { $t } from "@/i18n";
import GridWrapper from "@/components/Grid/GridWrapper.vue";
import { inject, reactive, ref } from "vue";
import { OrderLineGroupStatus } from "@masta/generated-model";
import { SelectionChangedEvent } from "ag-grid-community";
import { translateOrderLineGroupStatus } from "@/composables/translateEnum";
import { getSelectedRows } from "@/components/Grid/UseGridSelection";
import { GridWrapperComponent } from "@/components/Grid/GridWrapperComponent";
import { $dateTimeFormatterSymbol, DateFormatter } from "@masta/shared";
import { OrderLineGroupsServerSideDataSource } from "@/components/OrderLineGroups/OrderLineGroupsServerSideDataSource";
import ApiService from "@/services/api";
import { useSnackbarsStore } from "@/store/SnackbarsStore";
import { useScenariosStore } from "@/store/ScenariosStore";

const $emits = defineEmits(["rowSelected", "deleteAction", "rowDoubleClicked", "selectionChanged"]);
const serverSideDataSource = reactive(new OrderLineGroupsServerSideDataSource("order-line-groups"));
const gridWrapperRef = ref<GridWrapperComponent>();
const $dateTimeFormatter = inject<DateFormatter>($dateTimeFormatterSymbol)!;
const isRowSelected = ref(false);
const snackbarsStore = useSnackbarsStore();
const scenariosStore = useScenariosStore();

const DEFAULT_CREATE_VALUE = {
  businessId: null,
  status: OrderLineGroupStatus.InPreparation
};

const defaultColumnDef = ref({
  floatingFilter: true,
  filterParams: {
    applyMiniFilterWhileTyping: true
  }
});

function onPrepareColumns(columnDefs: any) {
  columnDefs.value = [
    {
      field: "businessId",
      type: ["textInputTypeColumn", "textFloatingFilterColumnType"],
      editable: true,
      sortable: true,
      resizable: true,
      filter: "agTextColumnFilter",
      cellEditorParams: {
        placeholder: $t("orderLineGroup-list-businessId-label", { $: "Business ID" })
      },
      headerValueGetter: (_: any) => $t("orderLineGroup-list-businessId-label", { $: "Business ID" }),
      floatingFilterComponentParams: {
        placeholder: $t("orderLineGroup-list-businessId-label", { $: "Business ID" })
      }
    },
    {
      field: "status",
      resizable: true,
      valueGetter: (params: any) => translateOrderLineGroupStatus(params.data.status),
      headerValueGetter: (_: any) => $t("orderLineGroup-list-status-label", { $: "Status" })
    },
    {
      field: "createdBy",
      type: ["textInputTypeColumn", "textFloatingFilterColumnType"],
      filter: "agTextColumnFilter",
      editable: false,
      resizable: true,
      headerValueGetter: (_: any) => $t("orderLineGroup-list-createdBy-label", { $: "Created By" }),
      floatingFilterComponentParams: {
        placeholder: $t("orderLineGroup-list-createdBy-label", { $: "Created By" })
      }
    },
    {
      field: "createdAt",
      type: ["dateTimeTypeColumn"],
      filter: "agDateColumnFilter",
      editable: false,
      resizable: true,
      valueFormatter: (params: any) => {
        return $dateTimeFormatter(params.data.createdAt);
      },
      headerValueGetter: (_: any) => $t("orderLineGroup-list-createdAt-label", { $: "Created At" })
    },
    {
      field: "modifiedBy",
      type: ["textInputTypeColumn", "textFloatingFilterColumnType"],
      filter: "agTextColumnFilter",
      editable: false,
      resizable: true,
      headerValueGetter: (_: any) => $t("orderLineGroup-list-modifiedBy-label", { $: "Modified By" }),
      floatingFilterComponentParams: {
        placeholder: $t("orderLineGroup-list-modifiedBy-label", { $: "Modified By" })
      }
    },
    {
      field: "modifiedAt",
      type: ["dateTimeTypeColumn"],
      filter: "agDateColumnFilter",
      editable: false,
      resizable: true,
      valueFormatter: (params: any) => {
        return $dateTimeFormatter(params.data.modifiedAt);
      },
      headerValueGetter: (_: any) => $t("orderLineGroup-list-modifiedAt-label", { $: "Modified At" })
    }
  ];
}

function onSelectionChanged(event: SelectionChangedEvent) {
  const { api } = event;
  const selectedRows = getSelectedRows(api);
  isRowSelected.value = selectedRows.length > 0;
  if (selectedRows.length === 1) {
    $emits("rowSelected", selectedRows[0]);
  }

  $emits("selectionChanged", event);
}

async function releaseToProduction() {
  const selectedRows = getSelectedRows(gridWrapperRef.value?.gridApi);
  if (selectedRows.length <= 0 || !scenariosStore.selectedScenario) {
    return;
  }
  const groupIds = selectedRows.map((x) => x.id);
  try {
    const request = { orderLineGroupIds: groupIds, scenarioId: scenariosStore.selectedScenario.id };
    await ApiService.orderLineGroups.releaseToProduction(request);
    await snackbarsStore.createSnackbar({
      message: $t("orderLineGroup-list-releaseSuccess-message", { $: "Groups released! Production tasks are being generated" }),
      type: "success",
      closeable: true
    });
  } catch (e) {
    console.error(e);
    await snackbarsStore.createSnackbar({
      message: $t("orderLineGroup-list-releaseError-message", { $: "Could not release groups" }),
      type: "error",
      closeable: true
    });
  }
}
</script>

<template>
  <grid-wrapper
    ref="gridWrapperRef"
    identifier="order-line-groups"
    :default-col-def="defaultColumnDef"
    row-selection="multiple"
    :create-default-value="DEFAULT_CREATE_VALUE"
    server-side
    :server-side-datasource="serverSideDataSource"
    refresh-btn
    edit-btn
    create-btn
    delete-btn
    @prepare-columns="onPrepareColumns"
    @selection-changed="onSelectionChanged"
  >
    <template #custom-buttons>
      <v-tooltip location="bottom" open-delay="300">
        <template #activator="{ props }">
          <div class="d-inline-flex pr-4">
            <v-btn :disabled="!isRowSelected" size="small" variant="text" density="compact" v-bind="props" class="mx-2" @click="releaseToProduction">
              <v-icon icon="mdi-account-hard-hat" class="pr-4" />
              {{ $t("orderLineGroup-list-release-tooltip", { $: "Release" }) }}
            </v-btn>
          </div>
        </template>
        <span>{{ $t("orderLineGroup-list-release-tooltip", { $: "Release" }) }}</span>
      </v-tooltip>
    </template>
  </grid-wrapper>
</template>

<style scoped lang="scss"></style>
