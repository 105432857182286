import type { interfaces } from "inversify";
import { inject, injectable } from "inversify";
import type { IRowContainer } from "../../../Core";
import {
  ActivityBounds,
  ActivityPosition,
  ActivityRef,
  GanttEvents,
  GanttException,
  GanttSettings,
  IActivity,
  IocSymbols,
  Layer,
  Lifecycle,
  PaddingInsets,
  Row,
  SettingKey,
  TimelineManager,
  WeakCache
} from "../../../Core";
import { Duration, Instant, ZoneId } from "@js-joda/core";
import { ILayerRenderer } from "./ILayerRenderer";
import { BehaviorSubject } from "rxjs";
import { ActivityBarRenderer } from "../Activities";

export type RowCanvasLayerRendererRequest = {
  rowId: string;
  offsetTop: number;
  boundIds?: string[];
  layerOpacity: number;
};

@injectable()
export class RowCanvasLayerRenderer extends Lifecycle implements ILayerRenderer<RowCanvasLayerRendererRequest> {
  public static Identifier = "RowCanvasLayerRenderer";
  private _rowPadding: PaddingInsets;
  private _renderers: Record<string, ActivityBarRenderer<any>> = {};
  private _millisPerPixel: number;
  private _refsCache: WeakCache<ActivityRef<any>>;
  private _visibleRefs: Map<string, ActivityRef<any>[]> = new Map<string, ActivityRef<any>[]>();

  constructor(
    @inject(Row<any, any, any>) private _row: Row<any, any, any>,
    @inject(TimelineManager) private _timelineManager: TimelineManager,
    @inject(IocSymbols.LayersSymbol) private _layers$$: BehaviorSubject<Layer[]>,
    @inject(IocSymbols.RowContainerIocContainerSymbol) private _scopeIocContainer: interfaces.Container,
    @inject(GanttSettings) private _settings: GanttSettings,
    @inject(GanttEvents) private _ganttEvents: GanttEvents,
    @inject(IocSymbols.RowContainer) private _rowContainer: IRowContainer
  ) {
    super();
    this._rowPadding = this._settings.getSetting<PaddingInsets>(SettingKey.ROW_PADDING)!;
    this._refsCache = new WeakCache<ActivityRef<any>>((actRefId) => {
      const ab = this._rowContainer.activityBounds$$.data.get(actRefId);
      if (ab) {
        ab.setInactive();
        this._rowContainer.activityBounds$$.remove(actRefId);
        // console.warn("[Gantt] [WW] RowCanvasLayerRenderer._refsCache removed!", this._row.name, actRefId, this._visibleRefs);
      } else {
        // console.error("[Gantt] [WW] RowCanvasLayerRenderer._refsCache removed! not found!", this._row.name, actRefId, this._visibleRefs);
      }
      // console.log("[Gantt] [WW] RowCanvasLayerRenderer._refsCache visible refs", this._visibleRefs);
    });
  }

  render(canvas: OffscreenCanvas, context: OffscreenCanvasRenderingContext2D, params: RowCanvasLayerRendererRequest): void {
    // console.log("[Gantt] [WW] RowCanvasLayerRenderer.render", params);
    const { startTime, endTime, primaryTemporalUnit } = this._timelineManager;

    let zoomChanged = false;
    if (this._millisPerPixel !== this._timelineManager.millisPerPixel) {
      this._millisPerPixel = this._timelineManager.millisPerPixel;
      zoomChanged = true;
    }

    const visibleLayers = this._layers$$.value.filter((layer) => layer.visible);

    const width = canvas instanceof HTMLCanvasElement ? canvas.clientWidth : canvas.width;
    const height = canvas instanceof HTMLCanvasElement ? canvas.clientHeight : canvas.height;
    context.clearRect(0, 0, width, height);

    visibleLayers.forEach((layer) => {
      this.renderLayer(canvas, context, this._row, layer, startTime, endTime, zoomChanged, {
        ...params,
        layerOpacity: layer.opacity ?? 1
      });
    });
  }

  private renderLayer(canvas: OffscreenCanvas | HTMLCanvasElement, context: OffscreenCanvasRenderingContext2D | CanvasRenderingContext2D, row: Row<any, any, any>, layer: Layer, startTime: Instant, endTime: Instant, zoomChanged: boolean, params: RowCanvasLayerRendererRequest) {
    const _activityBounds = new Array<ActivityBounds>();
    const { offsetTop } = params;
    const zone = this._timelineManager.zoneId;
    const start = startTime.atZone(zone).toInstant();
    const end = endTime.atZone(zone).toInstant();
    const temporalUnit = this._timelineManager.primaryTemporalUnit;

    const activities = row.repository.getActivities(layer, start, end, temporalUnit, row.zoneId) as Array<IActivity>;
    if (!activities) throw new GanttException("activities repository returned null");

    activities.sort(this.activitiesComparator);

    // console.log("[Gantt] [WW] RowCanvasLayerRenderer.renderLayer", activities.length, row.name, layer.name, start, end, temporalUnit, row.zoneId, activities);

    if (!this._visibleRefs.has(layer.id)) {
      this._visibleRefs.set(layer.id, []);
    }
    const existingVisibleRefs = this._visibleRefs.get(layer.id)!;
    const visibleRefs = new Array<ActivityRef<any>>();

    for (const activity of activities) {
      const lineIndex = row.getLineIndex(activity);

      const id = ActivityRef.generateId(row, layer, activity);

      // eslint-disable-next-line no-continue
      if (lineIndex >= 0 && lineIndex >= row.lineCount) {
        continue;
      }

      let ref = existingVisibleRefs.find((vref) => vref.id === id);
      if (!ref) {
        ref = new ActivityRef<any>(row, layer, activity);
      }

      // let ref = this._refsCache.get(id);
      // if (!ref) {
      //   ref = new ActivityRef(row, layer, activity);
      //   const weakRef = this._refsCache.set(ref.id, ref);
      //   ref = weakRef.deref();
      // }

      visibleRefs.push(ref!);

      const rowHeight = row.height;
      this.drawActivity(ref!, zone, rowHeight, params, canvas, context);
    }
    for (let i = existingVisibleRefs.length - 1; i >= 0; i--) {
      const ref = existingVisibleRefs[i];
      if (!visibleRefs.find((vref) => vref.id === ref?.id)) {
        existingVisibleRefs.splice(i, 1);
        const ab = this._rowContainer.activityBounds$$.data.get(ref?.id);
        if (ab) {
          ab.setInactive();
          this._rowContainer.activityBounds$$.remove(ref?.id);
        }
      }
    }
    for (const ref of visibleRefs) {
      if (!existingVisibleRefs.find((vref) => vref.id === ref?.id)) {
        existingVisibleRefs.push(ref);
      }
    }
    // console.log("[Gantt] [WW] RowCanvasLayerRenderer.renderLayer", existingVisibleRefs.length, row.name, layer.name, existingVisibleRefs.map(x => x.id));
    return _activityBounds;
  }

  private async drawActivity(ref: ActivityRef<any>, zoneId: ZoneId, rowHeight: number, params: RowCanvasLayerRendererRequest, canvas: OffscreenCanvas | HTMLCanvasElement, context: OffscreenCanvasRenderingContext2D | CanvasRenderingContext2D): Promise<ActivityBounds | undefined> {
    const selected = !!this._ganttEvents.selectedActivities$$.value.find((ev) => ev.activity.id === ref.activity.id && ev.layer.id === ref.layer.id && ev.row.id === ref.row.id);
    const hovered = !!this._ganttEvents.hoveredActivities$$.value.find((ev) => ev.activity.id === ref.activity.id && ev.layer.id === ref.layer.id && ev.row.id === ref.row.id);
    const highlighted = false;
    const pressed = !!this._ganttEvents.pressedActivities$$.value.find((wv) => wv.activity.id === ref.activity.id && wv.layer.id === ref.layer.id && wv.row.id === ref.row.id);

    // console.log("drawActivity", ref.id, hovered, pressed);

    const { row, activity, layer } = ref;
    let { layout } = row;
    const lineIndex = row.linesManager.getLineIndex(activity);
    let yOffset = this._rowPadding.top;
    let availableHeight = rowHeight - (this._rowPadding.top + this._rowPadding.bottom);
    if (lineIndex >= 0) {
      yOffset = row.linesManager.getLineLocation(lineIndex, availableHeight) + this._rowPadding.top;
      availableHeight = row.linesManager.getLineHeight(lineIndex, availableHeight);
      layout = row.linesManager.getLineLayout(lineIndex);
    }
    const x1 = Math.round(this._timelineManager.calculateLocationForTime(activity.startTime));
    const x2 = Math.round(this._timelineManager.calculateLocationForTime(activity.endTime));

    // const selected = !!this._selectedActivities$$.value.find((b) => b.activityRef.activity.id === ref.activity.id);
    // const hovered = !!this._hoveredActivities$$.value.find((b) => b.activityRef.activity.id === ref.activity.id);
    // const highlighted = !!this._highlightedActivities$$.value.find((b) => b.activityRef.activity.id === ref.activity.id);
    // const pressed = !!this._pressedActivities$$.value.find((b) => b.activityRef.activity.id === ref.activity.id);

    yOffset += layout.padding;
    availableHeight -= 2 * layout.padding;

    if (row.lineCount && availableHeight <= 0) {
      console.log("no space left", ref.activity.name);
      return;
    }

    const renderer = await this.getRenderer(activity);
    const lockedOpacityMultiplier = activity.locked ? 0.25 : 1;
    renderer.opacity = params.layerOpacity * lockedOpacityMultiplier;
    const bounds = renderer.draw(ref, ActivityPosition.ONLY, context, x1, yOffset, x2 - x1, availableHeight, params.offsetTop, selected, hovered, highlighted, pressed);

    if (!bounds) {
      return;
    }
    bounds.position = ActivityPosition.ONLY;
    bounds.layout = layout;
    return bounds;
  }

  private async getRenderer(activity: IActivity): Promise<ActivityBarRenderer<any>> {
    let renderer = this._renderers[activity.constructor.name];
    if (!renderer) {
      try {
        renderer = await this._scopeIocContainer.getTaggedAsync<ActivityBarRenderer<any>>(IocSymbols.ActivityRenderer, IocSymbols.ActivityRendererTag, activity.constructor.name);
      } catch (e) {
        console.error(`no renderer found for activity of type ${activity.constructor.name}`);
        throw e;
      }
      if (!renderer) {
        throw new GanttException(`no renderer found for activity of type ${activity.constructor.name}`);
      }
      this._renderers[activity.constructor.name] = renderer;
    }
    return renderer;
  }

  private activitiesComparator(a: IActivity, b: IActivity): number {
    // First compare by duration, Longest duration should be first (* -1)
    const durationCompare = Duration.between(a.startTime, a.endTime).compareTo(Duration.between(b.startTime, b.endTime)) * -1;
    if (durationCompare !== 0) {
      return durationCompare;
    }

    // If duration is this same, compare by start time
    const startTimeCompare = a.startTime.compareTo(b.startTime);
    if (startTimeCompare !== 0) {
      return startTimeCompare;
    }

    // If start times are this same, compare by end time
    const endTimeCompare = a.endTime.compareTo(b.endTime);
    if (endTimeCompare !== 0) {
      return endTimeCompare;
    }

    // If end times are this same, compare by name
    if (a.name !== b.name) {
      return a.name.localeCompare(b.name);
    }

    // If names are this same, compare by constructor name
    if (a.constructor.name !== b.constructor.name) {
      return a.constructor.name.localeCompare(b.constructor.name);
    }

    // If constructor names are this same, compare by id
    return a.id.localeCompare(b.id);
  }
}
