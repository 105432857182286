<script lang="ts" setup>
import { SchemaTypeNumberModel } from "@/components/ModelInstances/SchemaBuilder/SchemaModel";

const props = defineProps<{
  modelValue: SchemaTypeNumberModel;
}>();

const emit = defineEmits(["update:modelValue"]);

function update(key: string, value: any) {
  const newModel = { ...props.modelValue, [key]: value };
  emit("update:modelValue", newModel);
}
</script>

<template>
  <div class="d-flex justify-start flex-wrap">
    <v-chip class="ma-2" size="x-large" label>
      {{ $t("schemaType-numberProperty-type-label", { $: "Number" }) }}
    </v-chip>
    <v-text-field
      variant="outlined"
      class="mx-2"
      :label="$t('schemaType-numberProperty-minimumValue-label', { $: 'Minimum' })"
      type="number"
      :model-value="modelValue.minimum"
      @update:model-value="update('minimum', Number($event))"
    />
    <v-text-field
      variant="outlined"
      class="mx-2"
      :label="$t('schemaType-numberProperty-maximumValue-label', { $: 'Maximum' })"
      type="number"
      :model-value="modelValue.maximum"
      @update:model-value="update('maximum', Number($event))"
    />
    <v-text-field
      variant="outlined"
      class="mx-2"
      :label="$t('schemaType-numberProperty-exclusiveMinimum-label', { $: 'Exclusive Minimum' })"
      type="number"
      :model-value="modelValue.exclusiveMinimum"
      @update:model-value="update('exclusiveMinimum', Number($event))"
    />
    <v-text-field
      variant="outlined"
      class="mx-2"
      :label="$t('schemaType-numberProperty-exclusiveMaximum-label', { $: 'Exclusive Maximum' })"
      type="number"
      :model-value="modelValue.exclusiveMaximum"
      @update:model-value="update('exclusiveMaximum', Number($event))"
    />
    <v-text-field
      variant="outlined"
      class="mx-2"
      :label="$t('schemaType-numberProperty-multipleOfValue-label', { $: 'Multiple Of' })"
      type="number"
      :model-value="modelValue.multipleOf"
      @update:model-value="update('multipleOf', Number($event))"
    />
    <v-text-field
      variant="outlined"
      class="mx-2"
      :label="$t('schemaType-property-icon-label', { $: 'Icon' })"
      :model-value="modelValue.icon"
      @update:model-value="update('icon', $event)"
    />
    <v-text-field
      variant="outlined"
      class="mx-2"
      :label="$t('schemaType-property-color-label', { $: 'Color' })"
      :model-value="modelValue.color"
      @update:model-value="update('color', $event)"
    />
  </div>
</template>

<style lang="scss" scoped>
.v-text-field {
  width: 200px;
}
</style>
