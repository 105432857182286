<script lang="ts">
import { BodyScrollEvent, ICellEditorParams } from "ag-grid-community";
import { computed, onMounted, onUnmounted, ref, toRef } from "vue";
import { IControlledCellEditorParams, resolveValueRef, useValueChangeControl } from "@/components/Grid/UseValueChangeControl";
import TagsPicker from "@/components/Tags/TagsPicker.vue";
import { VForm } from "vuetify/lib/components/index.mjs";
import { AgGridFilterModel, TagDto } from "@masta/generated-model";

export interface ITagsPickerCellEditorParams extends IControlledCellEditorParams, ICellEditorParams<TagDto> {
  rules?: any[],
  single?: boolean;
  returnObject?: boolean;
  inputFilter?: (params: ITagsPickerCellEditorParams, tag: TagDto) => boolean;
  agGridFilterModel?: (() => AgGridFilterModel) | null | undefined | any;
  cqlFilter?: (params: ITagsPickerCellEditorParams) => string;
}

// Warn: Use export default instead of <script setup /> because only this works with AgGrid!
export default {
  components: { TagsPicker },
  setup(props: {
    params: ITagsPickerCellEditorParams;
  }) {
    const tagsPickerRef = ref<typeof TagsPicker>();
    const formRef = ref<VForm>();
    const valid = ref<boolean>();

    const params = toRef(props, "params");

    const isEditEnabled = toRef(props.params.isEditEnabled ? props.params.isEditEnabled(props.params) : true);
    const single = toRef(props.params.single);
    const returnObject = toRef(props.params.returnObject);
    const inputFilter = (tag: TagDto) => props.params.inputFilter?.(props.params, tag) ?? true;

    const agGridFilterModel = props.params.agGridFilterModel;
    const cqlFilter = () => props.params.cqlFilter?.(props.params) ?? "";

    const initialValue = props.params.value;
    const tagsValue = resolveValueRef(props.params, initialValue);

    const rules = computed(() => {
      return params.value.rules ?? [];
    });

    const errors = computed(() => {
      return formRef.value?.errors.map((e) => e.errorMessages.join(",")).join(",");
    });

    const showTooltip = computed<boolean>(() => !!errors.value && !!tagsPickerRef.value?.hasFocus());

    function getValue() {
      if (tagsValue.value === null) {
        return null;
      }
      if (single.value && Array.isArray(tagsValue.value) && tagsValue.value.length > 0) {
        return tagsValue.value[0];
      }
      return tagsValue.value;
    }

    function afterGuiAttached() {
      if (props.params.cellStartedEdit) {
        focusIn();
      }
    }

    function focusIn() {
      tagsPickerRef.value?.focus();
    }

    function focusOut() {
    }

    function isValid() {
      return isEditEnabled.value ? valid?.value : undefined;
    }

    onMounted(() => {
      props.params.api.addEventListener("bodyScroll", onGridBodyScroll);
    });

    onUnmounted(() => {
      const { api } = props.params;
      if (api && !api.isDestroyed()) {
        api.removeEventListener("bodyScroll", onGridBodyScroll);
      }
    });

    function onGridBodyScroll(event: BodyScrollEvent) {
      // close menu on scroll (when is open) to avoid menu position issues
      if (tagsPickerRef.value && tagsPickerRef.value.getMenu()) {
        tagsPickerRef.value.setMenu(false);
      }
    }

    useValueChangeControl(tagsValue, props.params);

    return {
      getValue,
      afterGuiAttached,
      focusIn,
      focusOut,
      isValid,
      showTooltip,
      single,
      returnObject,
      inputFilter,
      agGridFilterModel,
      cqlFilter,
      rules,
      valid,
      errors,
      isEditEnabled,
      tagsValue,
      tagsPickerRef: tagsPickerRef as any,
      formRef: formRef as any
    };
  }
};
</script>


<template>
  <v-tooltip v-model="showTooltip" location="top" color="error" :offset="30" class="tags-picker-cell-editor-tooltip">
    <template #activator="act">
      <v-form ref="formRef" v-model="valid" :disabled="!isEditEnabled" class="tags-picker-cell-editor">
        <div class="tags-picker-cell-editor-container">
          <TagsPicker
            ref="tagsPickerRef"
            v-model="tagsValue"
            color="primary"
            :readonly="!isEditEnabled"
            :placeholder="params.placeholder"
            :rules="rules"
            :single="single"
            :return-object="returnObject"
            :input-filter="inputFilter"
            :ag-grid-filter-model="agGridFilterModel"
            :cql-filter="cqlFilter"
          />
          <div v-bind="act.props" />
        </div>
      </v-form>
    </template>
    <span v-if="errors">{{ errors }}</span>
  </v-tooltip>
</template>


<style lang="scss">
.tags-picker-cell-editor {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .tags-picker-cell-editor-container {
    padding: 0.31em;
    width: 100%;

    .v-input {
      --v-input-chips-margin-top: 0;
      --v-input-chips-margin-bottom: 0;
      font-size: 1em;
    }

    .v-autocomplete {
      &--single {
        .v-field--variant-outlined input {
          --v-input-chips-margin-top: 2px;
          --v-input-chips-margin-bottom: 2px;
        }
      }

      .v-field {
        border-radius: 8px;
        font-size: 1em;

        .v-field__overlay {
          background-color: white;
        }

        .v-field__input {
          padding-top: 0;
          padding-bottom: 0;
          font-size: 1em;
          height: 34px;
          overflow-x: hidden;
          overflow-y: auto;

          input {
            // padding-top: 10px;
            align-self: center;
          }
        }

        .v-field__outline {
          --v-field-border-width: 2px;
        }

        color: rgba(0, 0, 0, 0.5);

        &:hover {
          color: var(--ag-data-color);
        }

        &.v-field--focused {
          color: var(--ag-data-color);
        }
      }
    }
  }
}

.tags-picker-cell-editor-tooltip {
  .v-overlay__content {
    background: rgba(var(--v-theme-error), 0.7);
  }
}
</style>
