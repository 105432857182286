
/**
 * Fix the popup position in TasksGrid when it is out of the page.
 * When the popup is out of the page, it will be displayedabove the Grid header.
 * When the popup is inside the page, it will be displayed at the height of the Grid header
 * 
 * More info: https://www.ag-grid.com/javascript-grid-context-menu/#context-menu-and-popup-position
 * @param ePopup - popup HTML element
 * @param gridElement - grid HTML element
 */

export function tasksGridPostProcessPopup(ePopup: HTMLElement, gridElement: HTMLElement) {
  const gridElementTop = gridElement.getBoundingClientRect().top;
  const popupHeight = ePopup.getBoundingClientRect().height;
  const pageHeight = window.innerHeight;

  if (popupHeight + gridElementTop > pageHeight) {
    ePopup.style.top = `${gridElementTop - popupHeight}px`;
  } else {
    ePopup.style.top = `${gridElementTop}px`;
  }
}
