import { inject, injectable, optional } from "inversify";
import { LayerCanvas } from "@masta/gantt2/gantt";
import {
  ActivityPosition,
  ActivityRef,
  GanttEvents,
  GanttSettings,
  GraphicsMousePositionEvent,
  PaddingInsets,
  RowHoverEvent,
  SettingKey,
  TimelineManager
} from "@masta/gantt2/core";
import { RowDropZone } from "@/components/Gantt/ResourcesGantt/DragAndDrop/RowDropZone";
import { withLatestFrom } from "rxjs";
import { GanttDragAndDropContextResult, SchedulingResponseStatus } from "@masta/generated-model";
import { ExternalTaskGhostActivity } from "@/components/Gantt/ResourcesGantt/DragAndDrop/ExternalTaskGhostActivity";
import { Instant } from "@js-joda/core";
import { AppIocSymbols } from "@/components/Gantt/ResourcesGantt/AppIocSymbols";
import type { DateFormatter } from "@masta/shared";
import { ExternalTaskGhostActivityRenderer } from "@/components/Gantt/ResourcesGantt/DragAndDrop/Renderers/ExternalTaskGhostActivityRenderer";
import { IDraggableTaskDto } from "@/components/Gantt/ResourcesGantt/DragAndDrop/IDraggableTaskDto";
import { SchedulingOperationGhostActivity } from "@/components/Gantt/ResourcesGantt/DragAndDrop/SchedulingOperationGhostActivity";
import { SchedulingOperationGhostActivityRenderer } from "@/components/Gantt/ResourcesGantt/DragAndDrop/Renderers/SchedulingOperationGhostActivityRenderer";

export type ExternalTaskGhostActivityLayerContext = {
  dragId: string;
  context: GanttDragAndDropContextResult;
  draggableTaskDto: IDraggableTaskDto;
}

type ExternalTaskContext = ExternalTaskGhostActivityLayerContext & {
  activity: ExternalTaskGhostActivity;
}

export type SchedulingOperationGhostActivityLayerContext = {
  businessKey: string;
  dropTime: Instant;
  rowDropZone: RowDropZone;
  context: GanttDragAndDropContextResult;
  draggableTaskDto: IDraggableTaskDto;
  status?: SchedulingResponseStatus;
}

type SchedulingOperationContext = SchedulingOperationGhostActivityLayerContext & {
  activity: SchedulingOperationGhostActivity;
}

@injectable()
export class GhostActivityLayer extends LayerCanvas {

  private _animationDuration = 2000;
  private _rowDropZones: RowDropZone[] = [];
  private _mousePosition: GraphicsMousePositionEvent | undefined;
  private _rowHoverEvent: RowHoverEvent | undefined;
  private _externalTaskGhostActivityLayerContexts: ExternalTaskContext[] = [];
  private _schedulingOperationContexts: SchedulingOperationContext[] = [];
  private _rowPadding: PaddingInsets;

  constructor(
    @inject(TimelineManager) timelineManager: TimelineManager,
    @inject(GanttEvents) ganttEvents: GanttEvents,
    @inject(GanttSettings) protected readonly _settings: GanttSettings,
    @inject(AppIocSymbols.$dateTimeFormatter) @optional() private _dateTimeFormatter?: DateFormatter
  ) {
    super(timelineManager, ganttEvents, GhostActivityLayer.name, "ghost-activity-layer");
    this.visible = false;
  }

  public addExternalTaskGhostActivityLayerContext(value: ExternalTaskGhostActivityLayerContext) {
    const context: ExternalTaskContext = {
      ...value,
      activity: new ExternalTaskGhostActivity(
        value.draggableTaskDto.businessId,
        value.draggableTaskDto.name,
        Instant.now(),
        Instant.now().plusMillis(value.context.schedulingLengthMillis),
        {
          draggableTaskDto: value.draggableTaskDto
        },
        this._dateTimeFormatter!
      )
    };
    this._externalTaskGhostActivityLayerContexts.push(context);
    this.visible = true;
    this.batchDraw();
    // console.log("addExternalTaskGhostActivityLayerContext", context.dragId, context);
  }

  public addSchedulingOperationGhostActivityLayerContext(value: SchedulingOperationGhostActivityLayerContext) {
    const context: SchedulingOperationContext = {
      ...value,
      activity: new SchedulingOperationGhostActivity(
        value.draggableTaskDto.businessId,
        value.draggableTaskDto.name,
        value.dropTime,
        value.dropTime.plusMillis(value.context.schedulingLengthMillis),
        {
          draggableTaskDto: value.draggableTaskDto,
          get status() {
            return context.status;
          }
        },
        this._dateTimeFormatter!
      )
    };
    this._schedulingOperationContexts.push(context);
    this.visible = true;
    this.batchDraw();
    this.startAnimation();
    // console.log("addSchedulingOperationGhostActivityLayerContext", context.businessKey, context);
  }

  async afterInitialize(): Promise<void> {
    await super.afterInitialize();

    this._rowPadding = this._settings.getSetting<PaddingInsets>(SettingKey.ROW_PADDING)!;

    this.subscribe(
      this._ganttEvents.mousePositionEvent$.pipe(withLatestFrom(this._ganttEvents.rowHoverEvent$)).subscribe(async ([mousePositionEvent, rowHoverEvent]) => {
        if (!this.visible) {
          return;
        }
        if (this._rowHoverEvent !== rowHoverEvent) {
          this._rowHoverEvent = rowHoverEvent as RowHoverEvent;
        }

        this._mousePosition = mousePositionEvent;
        this.batchDraw();
      })
    );
  }

  public set rowDropZones(value: RowDropZone[]) {
    this._rowDropZones = value;
  }

  public updateSchedulingOperationStatus(businessKey: string, status: SchedulingResponseStatus) {
    const context = this._schedulingOperationContexts.find(x => x.businessKey === businessKey);
    // console.log("updateSchedulingOperationStatus", businessKey, status, context);
    if (context) {
      context.status = status;
      this.batchDraw();
      // context.activity.userObject.status = status;
    }
  }

  public removeExternalTaskGhostActivityLayerContext(dragId: string) {
    // console.log("removeExternalTaskGhostActivityLayerContext", dragId, this._externalTaskGhostActivityLayerContexts);
    this._externalTaskGhostActivityLayerContexts = this._externalTaskGhostActivityLayerContexts.filter(x => x.dragId !== dragId);
    if (this._externalTaskGhostActivityLayerContexts.length === 0) {
      this.visible = false;
    }
    this.batchDraw();
  }

  public removeSchedulingOperationGhostActivityLayerContext(businessKey: string | undefined) {
    // console.log("removeSchedulingOperationGhostActivityLayerContext", businessKey, this._schedulingOperationContexts);
    this._schedulingOperationContexts = this._schedulingOperationContexts.filter(x => x.businessKey !== businessKey);
    if (this._schedulingOperationContexts.length === 0) {
      this.stopAnimation();
      this.context.globalAlpha = 1;
      this.visible = false;
    }
    this.batchDraw();
  }

  public async doDrawFromBatch() {
    if (!this.visible) {
      this.clear();
      return;
    }

    this.clear();

    if (this._rowHoverEvent) {

      const rowDropZone = this._rowDropZones.find(x => x.row.id === this._rowHoverEvent?.rowId);

      if (rowDropZone && this._externalTaskGhostActivityLayerContexts.length > 0) {
        const mouseX = this._mousePosition?.x ?? 0;

        const isDropAllowed = rowDropZone.isDropAllowedForSupplyArea(mouseX);

        if (isDropAllowed) {

          for (const context of this._externalTaskGhostActivityLayerContexts) {
            const snapX = rowDropZone.getSnapXCoordinate(mouseX, RowDropZone.SnapAreaWidth, context.context.schedulingLengthMillis);
            context.activity.startTime = this._timelineManager.calculateTimeForLocation(snapX);
            context.activity.endTime = context.activity.startTime.plusMillis(context.context?.schedulingLengthMillis ?? 0);
            await this.drawExternalTaskGhostActivity(context.activity, rowDropZone, snapX);
          }
        }
      }
    }
    let alpha = this.context.globalAlpha;
    if (this._animating) {
      const now = performance.now();
      const elapsed = (now - this._animationStartTime) % this._animationDuration;
      const t = elapsed / this._animationDuration;
      const easedT = this.easeOutIn(t);

      alpha = 1 - Math.abs(1 - 2 * easedT);
    }
    if (this._schedulingOperationContexts.length > 0) {
      for (const context of this._schedulingOperationContexts) {
        await this.drawSchedulingOperationGhostActivity(context, alpha);
      }
    }
  }

  private async drawExternalTaskGhostActivity(activity: ExternalTaskGhostActivity, rowDropZone: RowDropZone, mouseX: number) {
    const rowContainer = rowDropZone.container;
    const x = this._timelineManager.calculateLocationForTime(activity.startTime);
    const y = rowContainer.element.offsetTop + this._rowPadding.top;

    const renderer = await rowContainer.iocContainer.getAsync<ExternalTaskGhostActivityRenderer>(ExternalTaskGhostActivityRenderer);

    const ref = new ActivityRef(rowContainer.row, null!, activity);

    const height = rowContainer.row.height - (this._rowPadding.top + this._rowPadding.bottom);

    renderer.draw(ref,
      ActivityPosition.ONLY,
      this.context,
      x,
      y,
      this._timelineManager.calculateWidthForDuration(activity.duration),
      height,
      this._rowPadding.top,
      false,
      false,
      false,
      false
    );
  }

  private async drawSchedulingOperationGhostActivity(context: SchedulingOperationContext, alpha: number) {
    const activity = context.activity;
    const rowContainer = context.rowDropZone.container;
    const x = this._timelineManager.calculateLocationForTime(activity.startTime);
    const y = rowContainer.element.offsetTop + this._rowPadding.top;

    const renderer = await rowContainer.iocContainer.getAsync<SchedulingOperationGhostActivityRenderer>(SchedulingOperationGhostActivityRenderer);

    const ref = new ActivityRef(rowContainer.row, null!, activity);

    const height = rowContainer.row.height - (this._rowPadding.top + this._rowPadding.bottom);

    renderer.opacity = alpha;
    renderer.draw(ref,
      ActivityPosition.ONLY,
      this.context,
      x,
      y,
      this._timelineManager.calculateWidthForDuration(activity.duration),
      height,
      this._rowPadding.top,
      false,
      false,
      false,
      false
    );
  }

  private easeOutIn(t: number): number {
    return t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t;
  }
}
