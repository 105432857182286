import { inject, injectable } from "inversify";
import { GanttDomElement } from "./GanttDomElement";
import { InfoHeaderContainer } from "./InfoHeaderContainer";
import { TimelineContainer } from "./Timeline/TimelineContainer";

@injectable()
export class TopBarContainer extends GanttDomElement<HTMLDivElement> {
  constructor(@inject(InfoHeaderContainer) private _infoHeaderContainer: InfoHeaderContainer, @inject(TimelineContainer) private _timelineContainer: TimelineContainer) {
    super(TopBarContainer.name, undefined, "top-bar-container");
  }

  async beforeInitialize(): Promise<void> {
    await this.addChildren([this._infoHeaderContainer, this._timelineContainer]);
  }
}
