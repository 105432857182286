import { Resolver } from "./Resolver";
import { ResolverResult } from "./ResolverResult";
import { inject, injectable } from "inversify";
import { BehaviorSubject } from "rxjs";
import { EqualsLinesManager, IActivity, Layer, Row, TimelineModel } from "../../Model";
import { IocSymbols } from "../../IocSymbols";
import { TimelineManager } from "../../TimelineManager";
import { Duration } from "@js-joda/core";

@injectable()
export class AutoLineManager<TRow extends Row<any, any, TActivity>, TActivity extends IActivity> extends EqualsLinesManager<TRow, TActivity> {
  private _filter: (activity: TActivity) => boolean;

  private _resolveResult: ResolverResult<TActivity>;

  constructor(
    @inject(TimelineManager) protected _timelineManager: TimelineManager,
    @inject(IocSymbols.LayersSymbol) protected readonly _layers$$: BehaviorSubject<Layer[]>,
    @inject(Row<any, any, any>) row: TRow
  ) {
    super(row);

    this.layout();
  }

  set filter(predicate: (activity: TActivity) => boolean) {
    this._filter = predicate;
  }

  public layout(): void {
    const { row } = this;

    const timeOffset = Math.round(Duration.between(this._timelineManager.startTime, this._timelineManager.endTime).toMillis() / 2);

    const startTime = this._timelineManager.startTime.minusMillis(timeOffset);
    const endTime = this._timelineManager.endTime.plusMillis(timeOffset);

    if (!startTime || !endTime) {
      return;
    }

    const allActivities: TActivity[] = [];

    for (const layer of this._layers$$.value) {
      const activities = row.repository.getActivities(layer, startTime, endTime, this._timelineManager.primaryTemporalUnit, row.zoneId);
      if (activities) {
        for (const activity of activities) {
          allActivities.push(activity);
        }
      }
    }

    this._resolveResult = Resolver.resolveWithPredicate(allActivities, this._filter);

    row.lineCount = this._resolveResult.maxColumns;

  }

  getLineIndex(activity: TActivity): number {
    if (this._resolveResult) {
      const { placements } = this._resolveResult;
      const placement = placements.get(activity);
      if (placement) {
        return placement.columnIndex;
      }
    }
    return -1;
  }
}
