import {
  CheckboxSelectionCallbackParams,
  GetMainMenuItemsParams,
  GridApi,
  HeaderCheckboxSelectionCallbackParams,
  IMenuActionParams,
  IRowNode,
  MenuItemDef
} from "ag-grid-community";
import { Ref, UnwrapRef } from "vue";
import { useGridProfile } from "@/components/Grid/Profile/UseGridProfile";
import { SettingsColDef } from "@/components/Grid/Profile/SettingsColDef";
import { $t } from "@/i18n";
import { IGridWrapperProps } from "@/components/Grid/GridWrapperProps";
import { IGridWrapperEmits } from "@/components/Grid/GridWrapperEmits";
import { useCrudActions } from "@/components/Grid/UseCrudActions";
import { IActionsRowEvent } from "@/components/Grid/CellRenderers/IActionsRowEvent";
import { IActionsCellRendererParams } from "@/components/Grid/CellRenderers/IActionsCellRendererParams";

export const useActionsColumn = (
  gridApi: Ref<GridApi>,
  $updatedAttrs: Ref<any>,
  $gridProfile: ReturnType<typeof useGridProfile>,
  $crudActions: ReturnType<typeof useCrudActions>,
  $properties: IGridWrapperProps,
  $emits: IGridWrapperEmits
) => {
  const ACTIONS_COLUMN_ID = "actions";

  const isRowSelectableFunc = $updatedAttrs.value["isRowSelectable"];

  function isRowSelectable(params: IRowNode) {
    const isSelectable = typeof isRowSelectableFunc === "function" ? isRowSelectableFunc(params) : true;
    if ($gridProfile.selectChildrenOnly.value) {
      const isServerSideGroup = gridApi.value.getGridOption("isServerSideGroup");
      return isServerSideGroup ? !isServerSideGroup(params.data) && isSelectable : isSelectable;
    } else {
      return isSelectable;
    }
  }

  $updatedAttrs.value["isRowSelectable"] = isRowSelectable;

  function addActionsColumn(columnDefs: Ref<UnwrapRef<SettingsColDef[]>>) {
    if (columnDefs.value.some((col) => col.colId === ACTIONS_COLUMN_ID)) return;
    if ($properties.disableActionsColumn) return;

    const actionsColumnDev = {
      colId: ACTIONS_COLUMN_ID,
      type: "actionsColumnType",
      headerCheckboxSelection: (params: HeaderCheckboxSelectionCallbackParams) => {
        const checkboxSelection = typeof $properties.checkboxSelection === "function" ? $properties.checkboxSelection(params) : $properties.checkboxSelection;
        return checkboxSelection && params.api.getGridOption("rowSelection") === "multiple";
      },
      checkboxSelection: (params: CheckboxSelectionCallbackParams) => {
        if (typeof $properties.checkboxSelection === "function") {
          return $properties.checkboxSelection(params);
        }
        if (typeof $properties.checkboxSelection === "boolean") {
          if (!$properties.checkboxSelection) {
            return false;
          } else {
            if ($gridProfile.selectChildrenOnly.value) {
              const isServerSideGroup = params.api.getGridOption("isServerSideGroup");
              return isServerSideGroup ? !isServerSideGroup(params.data) : false;
            } else {
              return true;
            }
          }
        }
        return true;
      },
      cellRendererParams: {
        ...($properties.detailsBtn ? { onDetails: (rowEvent: IActionsRowEvent) => $emits("details", rowEvent.data) } : { onDetails: null }),
        ...($properties.editBtn ? { onEdit: (rowEvent: IActionsRowEvent) => $crudActions.onEditAction(rowEvent.event, rowEvent.node) } : { onEdit: null }),
        ...($properties.deleteBtn ? { onDelete: (rowEvent: IActionsRowEvent) => $crudActions.onDeleteAction(rowEvent.event, rowEvent.node) } : { onDelete: null }),
        ...($properties.duplicateBtn ? { onDuplicate: (rowEvent: IActionsRowEvent) => $crudActions.onDuplicateAction(rowEvent.event, rowEvent.node) } : { onDuplicate: null }),
        ...($properties.createChildBtn
          ? { onCreateChild: (rowEvent: IActionsRowEvent) => $crudActions.onCreateChildAction(rowEvent.node, $properties.setParentId) }
          : { onCreateChild: null }),
        ...($properties.createChildBtn ? { createChildDisabled: $properties.createChildBtnDisabled } : { createChildDisabled: null })
      } as IActionsCellRendererParams,
      sortable: false,
      filter: false,
      resizable: true,
      suppressSizeToFit: true,
      editable: false,
      mainMenuItems: (params: GetMainMenuItemsParams) => {
        const items: (MenuItemDef | string)[] = [...params.defaultItems];
        if ($properties.enableChildrenSelectionOnly) {
          items.push("separator");
          items.push({
            name: $gridProfile.selectChildrenOnly.value ? $t("grid-actions-column-menu-disableChildrenSelection-action", { $: "Select children and parents" }) :
              $t("grid-actions-column-menu-enableChildrenSelection-action", { $: "Select children only" }),
            icon: `<i class="mdi mdi-${$gridProfile.selectChildrenOnly.value ? "baby-carriage" : "baby-carriage-off"}" style="font-size: 1.5em"'/>`,
            action: (menuActionParams: IMenuActionParams) => {
              $gridProfile.selectChildrenOnly.value = !$gridProfile.selectChildrenOnly.value;
              menuActionParams.api.deselectAll();
              menuActionParams.api.refreshServerSide();
            }
          });
        }
        return items;
      }
    } as SettingsColDef;

    columnDefs.value = [...columnDefs.value, actionsColumnDev];
  }

  return {
    addActionsColumn
  };
};
