<script lang="ts" setup>
import { computed, onBeforeMount, onMounted, onUnmounted, ref } from "vue";
import { ResourceDto, AvailabilityRuleDto } from "@masta/generated-model";
import AvailabilityRulesAssignmentGrid from "@/components/AvailabilityRules/AvailabilityRulesAssignment/AvailabilityRulesAssignmentGrid.vue";
import CloseCardButton from "@/components/CloseCardButton.vue";
import { useAvailabilityRulesStore } from "@/store/AvailabilityRulesStore";
import { storeToRefs } from "pinia";
import { useSnackbarsStore } from "@/store/SnackbarsStore";
import { $t } from "@/i18n";

interface Props {
  resource: ResourceDto;
  opened: boolean;
}

const snackbarsStore = useSnackbarsStore();
const tcrStore = useAvailabilityRulesStore();
const props = defineProps<Props>();
const emit = defineEmits(["save", "cancel"]);

const { rules: allAvailabilityRules, resourceRules: assignedRules } = storeToRefs(tcrStore);

const unassignedRules = computed(() => {
  const selIds: string[] = assignedRules.value.map((x: AvailabilityRuleDto) => x.id);
  return allAvailabilityRules.value.filter((x: AvailabilityRuleDto) => !selIds.includes(x.id));
});
const selectedUnassigned = ref<AvailabilityRuleDto[]>([]);
const selectedAssigned = ref<AvailabilityRuleDto[]>([]);

onBeforeMount(async () => {
  await tcrStore.fetch();
  await tcrStore.fetchForResource(props.resource.id);
});

onMounted(() => {
  document.addEventListener("keydown", escapeHandler);
});

onUnmounted(() => {
  document.removeEventListener("keydown", escapeHandler);
});

function moveToAssigned() {
  selectedUnassigned.value.forEach((rule) => {
    tcrStore.assignResourceRule(rule);
  });

  selectedUnassigned.value = [];
}

function moveToUnassigned() {
  selectedAssigned.value.forEach((rule) => {
    tcrStore.unassignResourceRule(rule);
  });

  selectedAssigned.value = [];
}

async function save() {
  try {
    await tcrStore.saveForResource({
      id: props.resource.id,
      availabilityRules: assignedRules.value.map((x: AvailabilityRuleDto) => x.id)
    });
    await snackbarsStore.createSnackbar({
      message: $t("availabilityRules-editor-onSaveSuccess-message", { $: "Availability rules saved for {resourceName}", resourceName: props.resource.name }),
      closeable: true
    });
    emit("save");
  } catch (e: any) {
    await snackbarsStore.createSnackbar({
      message: $t("availabilityRules-editor-onSaveError-message", { $: "Availability rules could not be saved for {resourceName}", resourceName: props.resource.name }),
      type: "error"
    });
  }
}

function cancel() {
  emit("cancel");
}

function escapeHandler(e: KeyboardEvent) {
  if (e.key === "Escape") {
    emit("cancel");
  }
}

function onUnassignedSelectionChanged(selection: AvailabilityRuleDto[]) {
  selectedUnassigned.value = selection;
}

function onAssignedSelectionChanged(selection: AvailabilityRuleDto[]) {
  selectedAssigned.value = selection;
}
</script>

<template>
  <v-dialog :model-value="opened" style="z-index: 2000" :persistent="true" class="assignment-editor-dialog" @click:outside="cancel">
    <v-card width="90vw" height="90vh" class="assignment-editor-card">
      <close-card-button @click="cancel"></close-card-button>
      <v-card-title class="pa-0">
        <div class="text-h4 pb-5">
          {{ $t("availabilityRules-editor-availabilityRulesAssignment-title", { $: "Availability rules Assignment" }) }}
        </div>
      </v-card-title>
      <v-card-text class="d-flex flex-grow-1 px-0">
        <v-row class="flex-nowrap flex-grow-1">
          <div class="left-col d-flex flex-column flex-grow-1 flex-shrink-1">
            <h3 class="my-2">{{ $t("availabilityRules-editor-unassigned-label", { $: "Unassigned" }) }}</h3>
            <availability-rules-assignment-grid :availability-rules="unassignedRules" @selection-changed="onUnassignedSelectionChanged" />
          </div>
          <div class="middle-col pt-gap-35 d-flex flex-column align-center">
            <v-btn variant="flat" color="primary" size="small" class="button-arrow my-4" :disabled="selectedUnassigned.length === 0" @click="moveToAssigned">
              <v-icon icon="mdi-chevron-right"></v-icon>
            </v-btn>
            <v-btn variant="flat" color="primary" size="small" class="button-arrow my-4" :disabled="selectedAssigned.length === 0" @click="moveToUnassigned">
              <v-icon icon="mdi-chevron-left"></v-icon>
            </v-btn>
          </div>
          <div class="right-col d-flex flex-column flex-grow-1 flex-shrink-1">
            <h3 class="my-2">{{ $t("availabilityRules-editor-assigned-label", { $: "Assigned" }) }}</h3>
            <availability-rules-assignment-grid :availability-rules="assignedRules" @selection-changed="onAssignedSelectionChanged" />
          </div>
        </v-row>
      </v-card-text>
      <v-card-actions class="pa-0">
        <div class="left-col pl-0">
          <v-btn variant="elevated" color="secondary" class="w-100" @click="cancel">{{ $t("availabilityRules-editor-cancel-action", { $: "Cancel" }) }}</v-btn>
        </div>
        <div class="middle-col"></div>
        <div class="right-col pr-0">
          <v-btn variant="elevated" color="primary" class="w-100" @click="save">{{ $t("availabilityRules-editor-save-action", { $: "Save" }) }}</v-btn>
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
