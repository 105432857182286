import {
  SchemaModel,
  SchemaObjectPropertyModel,
  SchemaTypeArrayModel,
  SchemaTypeEnumModel,
  SchemaTypeModel,
  SchemaTypeNumberModel,
  SchemaTypeStringModel
} from "@/components/ModelInstances/SchemaBuilder/SchemaModel";

function getBasicProp(value: SchemaTypeModel) {
  return `{ "type": "${value.type}", "color": "${value.color}", "icon": "${value.icon}" }`;
}

function getStringProp(value: SchemaTypeStringModel) {
  let result = `{ "type": "string", "color": "${value.color}", "icon": "${value.icon}"`;
  if (value.minLength !== null) {
    result += `, "minLength": ${value.minLength}`;
  }
  if (value.maxLength !== null) {
    result += `, "maxLength": ${value.maxLength}`;
  }
  if (value.pattern) {
    result += `, "pattern": "${value.pattern}"`;
  }
  result += " }";
  return result;
}

function getNumberProp(value: SchemaTypeNumberModel) {
  let result = `{ "type": "number", "color": "${value.color}", "icon": "${value.icon}"`;
  if (value.minimum !== undefined) {
    result += `, "minimum": ${value.minimum}`;
  }
  if (value.maximum !== undefined) {
    result += `, "maximum": ${value.maximum}`;
  }
  if (value.exclusiveMinimum !== undefined) {
    result += `, "exclusiveMinimum": ${value.exclusiveMinimum}`;
  }
  if (value.exclusiveMaximum !== undefined) {
    result += `, "exclusiveMaximum": ${value.exclusiveMaximum}`;
  }
  if (value.multipleOf !== undefined) {
    result += `, "multipleOf": ${value.multipleOf}`;
  }
  result += " }";
  return result;
}

function getEnumProp(value: SchemaTypeEnumModel) {
  let items = "";
  if (value.items) {
    items = value.items.map((x) => `"${x}"`).join(",");
  }
  if (value.multiple) {
    return `{ "type": "array", "items": { "type": "string", "enum": [${items}] }, "color": "${value.color}", "icon": "${value.icon}" }`;
  }

  return `{ "enum": [${items}], "color": "${value.color}", "icon": "${value.icon}" }`;
}

function getArrayProp(value: SchemaTypeArrayModel) {
  const items = getPropValue(value.items);
  return `{ "type": "array", "items"=${items},  "color": "${value.color}", "icon": "${value.icon}" }`;
}

function getPropValue(value: SchemaTypeModel): string {
  switch (value.type) {
    case "string":
      return getStringProp(value as SchemaTypeStringModel);
    case "number":
      return getNumberProp(value as SchemaTypeNumberModel);
    case "boolean":
      return getBasicProp(value);
    case "enum":
      return getEnumProp(value as SchemaTypeEnumModel);
    case "array":
      return getArrayProp(value as SchemaTypeArrayModel);
    default:
      throw new Error(`Cannot generate property for type=${value.type}`);
  }
}

function getProperty(prop: SchemaObjectPropertyModel): string {
  return `"${prop.name}": ${getPropValue(prop.value)}`;
}

export function serializeSchemaModel(model: SchemaModel): string {
  const properties = model.properties.map((x) => getProperty(x)).join(",");

  return `
{
  "$schema": "https://json-schema.org/draft/2020-12/schema",
  "$id": "https://example.com/test.schema.json",
  "title": "${model.title}",
  "description": "${model.description}",
  "type": "${model.type}",
  "properties": {${properties}}
}
    `;
}
