import { ApiClient } from "@/services/api/common.api";
import { ClientFilterValidationResponse, CreateFilterCommand, GetFiltersResponse, ValidateClientFilterQuery } from "@masta/generated-model";

export default {
  async getFilters(key: string) {
    return ApiClient.get<GetFiltersResponse>("/filters", { params: { key } });
  },
  async createFilter(request: CreateFilterCommand) {
    return ApiClient.post("/filters", request);
  },
  async validateCqlExpressions(request: ValidateClientFilterQuery) {
    return ApiClient.post<ClientFilterValidationResponse>("/filters/validate", request);
  }
};
