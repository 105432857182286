import { BehaviorSubject, combineLatest, map, Observable } from "rxjs";
import { GanttSettings, IRowChartScaleProvider, Row, RowChartRepository, SettingKey } from "../../Model";
import { inject, injectable } from "inversify";
import { GanttEvents } from "../../Events";
import { RowChartScaleProviderBase } from "./RowChartScaleProviderBase";

@injectable()
export class FixedRowChartScaleProvider<TRow extends Row<any, any, any>> extends RowChartScaleProviderBase implements IRowChartScaleProvider {
  private _min = 0;
  private _max = 0;
  private _density = 1;

  constructor(
    @inject(Row<any, any, any>) row: TRow,
    @inject(RowChartRepository) private _repository: RowChartRepository,
    @inject(GanttSettings) private _settings: GanttSettings,
    @inject(GanttEvents) private _ganttEvents: GanttEvents
  ) {
    super();
    this.row = row;
  }

  private _row: BehaviorSubject<TRow> = new BehaviorSubject<TRow>(null!);

  get row(): TRow {
    return this._row.value;
  }

  set row(value: TRow) {
    this._row.next(value);
  }

  get row$(): Observable<TRow> {
    return this._row.asObservable();
  }

  public async afterInitialize() {
    await super.afterInitialize();
    this.subscribe(this._settings.getSetting$<{ min: number, max: number, density: number }>(SettingKey.ROW_FIXED_SCALE).subscribe((setting) => {
      if (setting) {
        const { min, max, density } = setting;
        this._min = min;
        this._max = max;
        this._density = density ?? Math.abs(min) + Math.abs(max);
      }
    }));

    const hasDatasets$ = combineLatest([this._repository.dataSets$]).pipe(map(([x]) => x.filter((ds) => ds.resourceId === this.row.id).length > 0));

    this.subscribe(hasDatasets$.subscribe(hasDatasets => {
      if (hasDatasets) {
        const range = this._max - this._min;
        const step = range / this._density;
        const calculatedScale = [...Array(Math.floor((this._max - this._min) / step) + 1).keys()]
          .map((x) => x * step + this._min)
          .map((x) => Math.round(x * 100) / 100)
          .reverse();
        this.scaleValues$$.next(calculatedScale);
        this._ganttEvents.onRowScaleChangeEvent(this.row.id);
      } else if (this.scaleValues$$.value.length > 0) {
        this.scaleValues$$.next([]);
        this._ganttEvents.onRowScaleChangeEvent(this.row.id);
      }
    }));
  }
}
