import type { interfaces } from "inversify";
import { inject, injectable } from "inversify";
import { GanttEvents, IocSymbols, Row, TimelineManager } from "../../../../Core";
import { RowCanvasLayerRenderer } from "../../../../Worker";
import { GanttCanvas } from "../../../GanttCanvas";

@injectable()
export class RowCanvas<TResource extends Row<any, any, any>> extends GanttCanvas {
  private _renderer: RowCanvasLayerRenderer;

  constructor(
    @inject(Row<any, any, any>) private _row: TResource,
    @inject(GanttEvents) ganttEvents: GanttEvents,
    @inject(TimelineManager) private _timelineManager: TimelineManager,
    // @inject(IocSymbols.GanttWorkerSymbol) ganttWorker: IGanttWorker,
    @inject(IocSymbols.RowContainerIocContainerSymbol) private _scopeIocContainer: interfaces.Container
    // @inject(RowCanvasLayerRenderer.Identifier) private _renderer: RowCanvasLayerRenderer
  ) {
    // super(ganttEvents, ganttWorker, `${RowCanvas.name}-${_row.id}`, "row-canvas", _row.id, "row");
    super(ganttEvents, `${RowCanvas.name}-${_row.id}`, "row-canvas");
    this.element.dataset.id = this.identifier;
  }

  async beforeInitialize(): Promise<void> {
    await super.beforeInitialize();
  }

  async afterInitialize(): Promise<void> {
    await super.afterInitialize();
  }

  // protected getRendererIdentifier(): string {
  //   return "RowCanvasLayerRenderer";
  // }

  public get renderer(): RowCanvasLayerRenderer {
    return this._renderer;
  }

  public async doDrawFromBatch(): Promise<void> {
    if (!this._row.visible) return;

    if (this._timelineManager.datelineManager.scaleResolutions.length === 0) return;

    if (!this._renderer) {
      this._renderer = await this._scopeIocContainer.getAsync<RowCanvasLayerRenderer>(RowCanvasLayerRenderer.Identifier);
    }

    this._renderer.render(this.canvas as any, this.context as any, {
      rowId: this._row.id,
      offsetTop: this.parent?.element.offsetTop ?? 0,
      layerOpacity: 1
    });
    // console.log("doDrawFromBatch", this._row.name);
    // this._canvasManager?.draw<RowCanvasLayerRendererRequest>(this._canvasIdentifier, {
    //   rowId: this._row.id,
    //   offsetTop: this.parent?.element.offsetTop ?? 0
    // });
  }
}
