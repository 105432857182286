export type GraphicsDragEvent = {
  ctrlKey: boolean;
  altKey: boolean;
  shiftKey: boolean;
  deltaX: number;
  deltaY: number;
};

export function createGraphicsDragEvent(event: PointerEvent, deltaX: number, deltaY: number): GraphicsDragEvent {
  return {
    ctrlKey: event.ctrlKey,
    altKey: event.altKey,
    shiftKey: event.shiftKey,
    deltaX: deltaX,
    deltaY: deltaY
  };
}
