import { ChronoUnit } from "@js-joda/core";

import { TimelineModel } from "./TimelineModel";

export class ChronoUnitTimelineModel extends TimelineModel<ChronoUnit> {
  constructor() {
    super();
    this.setZoomRange(ChronoUnit.MINUTES, 5, 100, ChronoUnit.MONTHS, 1, 50);
  }
}
