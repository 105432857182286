<template>
  <master-detail-layout scenario-selector detail-dialog-width="100%" @scenario-selection-changed="onScenarioSelectionChanged">
    <template #master>
      <v-card elevation="0" class="flexcard fill-height">
        <v-card-title>
          <breadcrumbs>{{ $t("location-view-locations-title", { $: "Locations" }) }}</breadcrumbs>
        </v-card-title>
        <v-card-text class="fill-height">
          <v-row class="fill-height" no-gutters>
            <v-col cols="12">
              <location-grid />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </template>
  </master-detail-layout>
</template>

<script>
import LocationGrid from "@/components/Location/LocationGrid.vue";
import MasterDetailLayout from "@/components/Layout/MasterDetailLayout.vue";
import Breadcrumbs from "@/components/Layout/Breadcrumbs.vue";

export default {
  name: "Location",
  components: {
    Breadcrumbs,
    LocationGrid,
    MasterDetailLayout
  },
  title: "Location",
  data() {
    return {
      editAvailabilityRulesAssignmentDialog: false,
      locationResource: null,
      refreshGridAction: null
    };
  },
  methods: {
    onScenarioSelectionChanged() {
      this.fetch();
    }
  }
};
</script>

<style lang="scss" scoped></style>
