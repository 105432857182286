<script setup lang="ts">
import CloseCardButton from "@/components/CloseCardButton.vue";

const openedModelValue = defineModel<boolean>({ required: true });

const props = defineProps<{
    url?: string;
}>();

function close() {
    openedModelValue.value = false;
}

function openInNewTab() {
    window.open(props.url, "_blank");
    close();
}

</script>

<template>
    <v-dialog v-model="openedModelValue" width="80vw" height="80vh" @click:outside="close">
        <v-card width="100%" height="100%" location="center center" class="onboarding-help-dialog-card">
            <v-card-title class="pa-0 pb-3">
                {{ $t("onboardingHelp-title-label", { $: "Welcome to Memom! 🚀 Explore. Connect. Achieve." }) }}
            </v-card-title>
            <v-card-text class="pa-0">
                <iframe :src="props.url" width="100%" height="100%" class="" style="border:0;">
                    {{ $t("onboardingHelp-noIFrameSupport-label", { $: "This browser does not support iframes." }) }}
                </iframe>
            </v-card-text>     
            <v-card-actions class="pa-0">
                <div class="left-col pl-0">
                    <v-btn variant="elevated" color="secondary" class="w-100" @click="openInNewTab">
                        {{ $t("onboardingHelp-openInNewTab-label", { $: "Open in New Tab" }) }}
                    </v-btn>
                </div>
                <div class="middle-col"></div>
                <div class="right-col pr-0">
                    <v-btn variant="elevated" color="primary" class="w-100" @click="close">
                        {{ $t("onboardingHelp-close-label", { $: "Close" }) }}
                    </v-btn>
                </div>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>


<style lang="scss">
.onboarding-help-dialog-card {
    padding: 1em;

    .left-col,
    .right-col {
        flex: 1;
        overflow: hidden;
        padding: 6px;
    }

    .middle-col {
        min-width: 78px;
        width: auto;
    }
}
</style>