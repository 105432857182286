import { inject, injectable, multiInject, optional } from "inversify";
import type { interfaces } from "inversify";
import { GanttDomElement } from "../../../GanttDomElement";
import { IocSymbols, Row } from "../../../../Core";
import { RowCanvas } from "./RowCanvas";
import { LayerCanvas, RowLayerCanvasWithWebWorker } from "../Layers";

@injectable()
export class RowCanvasContainer<TResource extends Row<any, any, any>> extends GanttDomElement<HTMLDivElement> {

  constructor(
    @inject(Row<any, any, any>) private readonly _row: TResource,
    @inject(RowCanvas<any>) public readonly _rowCanvas: RowCanvas<TResource>,
    @inject(IocSymbols.RowContainerIocContainerSymbol) private _scopeIocContainer: interfaces.Container
    // @multiInject(IocSymbols.RowBackgroundSystemLayersSymbol) @optional() private readonly _backgroundLayers: RowLayerCanvasWithWebWorker[] = [],
    // @multiInject(IocSymbols.RowForegroundSystemLayersSymbol) @optional() private readonly _foregroundLayers: RowLayerCanvasWithWebWorker[] = []
  ) {
    super(`${RowCanvasContainer.name}-${_row.id}`, undefined, "row-canvas-container");
    this.element.dataset.id = this.identifier;
  }

  async beforeInitialize(): Promise<void> {
    const backgroundLayers = await this._scopeIocContainer.getAllAsync<RowLayerCanvasWithWebWorker | LayerCanvas>(IocSymbols.RowBackgroundSystemLayersSymbol);
    const foregroundLayers = await this._scopeIocContainer.getAllAsync<RowLayerCanvasWithWebWorker | LayerCanvas>(IocSymbols.RowForegroundSystemLayersSymbol);
    await this.addChildren(backgroundLayers);
    await this.addChildren([this._rowCanvas]);
    await this.addChildren(foregroundLayers);
  }

  get rowCanvas() {
    return this._rowCanvas;
  }
}
