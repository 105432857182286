<script lang="ts" setup>
import { asBoringEnumItem, BoringEnumItem, enumTransform } from "@/components/Common/Enums";
import { MaterialSubType, MeasurementUnit, StepResourceUsageType } from "@masta/generated-model";
import { onMounted, ref } from "vue";
import { translateResourceSubType } from "@/composables/translateEnum";
import { $t } from "@/i18n";
import { requiredRule } from "@/components/ValueCellEditor/CommonValidationRules";

export interface MaterialData {
  name: string | undefined;
  businessId: string | undefined;
  materialType: MaterialSubType;
  measurementUnit: MeasurementUnit;
}

interface CreateMaterialEditorData {
  material: MaterialData;
  createTemplate: boolean;
}

interface CreateMaterialProps {
  isProcessing: boolean;
  data: CreateMaterialEditorData | any | undefined;
  createActionText: string | undefined;
  cancelActionText: string | undefined;
}

export type CreateMaterialEvent = CreateMaterialEditorData;

const $props = withDefaults(defineProps<CreateMaterialProps>(), {
  isProcessing: false,
  data: {
    material: {
      name: undefined,
      businessId: undefined,
      materialType: MaterialSubType.SemiFinishedOrFinalProduct,
      measurementUnit: MeasurementUnit.Unknown
    } as MaterialData,
    createTemplate: true
  } as CreateMaterialEditorData,
  createActionText: undefined,
  cancelActionText: undefined,
  materialUsageType: StepResourceUsageType.Consume
});

const $emit = defineEmits<{
  create: [event: CreateMaterialEvent];
  cancel: [];
}>();

// const usageTypeItems: BoringEnumItem[] = enumTransform(StepResourceUsageType, asBoringEnumItem);
const materialTypeItems: BoringEnumItem[] = enumTransform(MaterialSubType, asBoringEnumItem);
const measurementUnitItems: BoringEnumItem[] = enumTransform(MeasurementUnit, asBoringEnumItem);

const name = ref<string>($props.data.material.name ?? "");
const businessId = ref<string>($props.data.material.businessId ?? "");
const materialType = ref<MaterialSubType | null | undefined>($props.data?.material.subTypes?.[0] ?? null);
const measurementUnit = ref<MeasurementUnit>($props.data?.material.measurementUnit ?? MeasurementUnit.Unknown);

const isTemplateCreationRequired = ref<boolean>(false);
// const usageType = ref<number>($props.data.template.usageType);

const createActionLabel = ref<string>($props.createActionText ?? "");
const cancelActionLabel = ref<string>($props.cancelActionText ?? "");

function onCreate() {
  $emit("create", {
    material: {
      name: name.value,
      businessId: businessId.value,
      measurementUnit: measurementUnit.value,
      materialType: materialType.value
    } as MaterialData,
    createTemplate: isTemplateCreationRequired.value
  } as CreateMaterialEvent);
}

function onCancel() {
  $emit("cancel");
}

onMounted(() => {
  if (!createActionLabel.value) {
    createActionLabel.value = $t("material-createEditor-create-action", { $: "Create" });
  }
  if (!cancelActionLabel.value) {
    cancelActionLabel.value = $t("material-createEditor-cancel-action", { $: "Cancel" });
  }
});
</script>

<template>
  <v-card :loading="isProcessing" class="d-flex flex-column w-100 h-100" style="float: left">
    <v-card-text>
      <v-col class="flex flex-column mx-auto" cols="8">
        <v-text-field
          v-model="name"
          :disabled="isProcessing"
          :rules="[requiredRule]"
          class="py-2"
          :label="$t('material-createEditor-name-label', { $: 'Name' })"
          variant="outlined"
        ></v-text-field>
        <v-text-field
          v-model="businessId"
          :disabled="isProcessing"
          :rules="[requiredRule]"
          class="py-2"
          :label="$t('material-createEditor-businessId-label', { $: 'Business Id' })"
          variant="outlined"
        ></v-text-field>
        <v-select
          v-model="materialType"
          :disabled="isProcessing"
          :item-title="(item) => translateResourceSubType(item.value)"
          :items="materialTypeItems"
          class="py-2"
          :label="$t('material-createEditor-materialType-label', { $: 'Material Type' })"
          variant="outlined"
        />
        <v-select
          v-model="measurementUnit"
          :items="measurementUnitItems"
          item-title="key"
          item-value="value"
          class="py-2"
          :disabled="isProcessing"
          :label="$t('material-createEditor-measurementUnit-label', { $: 'Measurement Unit' })"
          variant="outlined"
        />
      </v-col>
    </v-card-text>
    <v-col class="mx-auto" cols="8">
      <div class="d-flex flex-column mx-4">
        <v-btn block class="btn-primary mt-4" color="primary" variant="outlined" @click="onCreate">{{ createActionLabel }}</v-btn>
        <v-btn block class="btn-secondary mt-4" color="secondary" variant="outlined" @click="onCancel">
          {{ cancelActionLabel }}
        </v-btn>
      </div>
    </v-col>
  </v-card>
</template>
