<script lang="ts" setup>
import { ref } from "vue";
import MergeScenariosDialog from "@/components/MergeScenariosDialog.vue";
import MasterDetailLayout from "@/components/Layout/MasterDetailLayout.vue";
import ScenariosGrid from "@/components/Scenarios/ScenariosGrid.vue";
import Breadcrumbs from "@/components/Layout/Breadcrumbs.vue";

const showMergeDialog = ref<boolean>(false);
const selectedScenarios = ref<any[]>([]);

function onMerged() {
  showMergeDialog.value = false;
}

function onMergeAction({ first, second }: any) {
  selectedScenarios.value = [first, second];
  showMergeDialog.value = true;
}
</script>
<template>
  <master-detail-layout>
    <template #master>
      <v-card elevation="0" class="flexcard fill-height">
        <v-card-title class="text-h6">
          <breadcrumbs>
            {{ $t("scenario-manage-scenarioManagement-title", { $: "Scenario Management" }) }}
          </breadcrumbs>
        </v-card-title>
        <v-card-text class="fill-height">
          <merge-scenarios-dialog v-model="showMergeDialog" :source="selectedScenarios[0]" :destination="selectedScenarios[1]" @merged="onMerged" />
          <scenarios-grid @merge="onMergeAction" />
        </v-card-text>
      </v-card>
    </template>
  </master-detail-layout>
</template>
