import { isDefined } from "@/components/Common/Types";
import { AssetDto, IResourceDto, ProductionTaskDto } from "@masta/generated-model";
import { CellClassParams, RowNode } from "ag-grid-community";

/**
 * Converts a hexadecimal color code to an RGBA color code with the specified opacity.
 * @param hexColor The hexadecimal color code to convert.
 * @param opacity The opacity value for the RGBA color code.
 * @returns The RGBA color code.
 */
function hexToRgb(hexColor: string, opacity: number) {
  if (hexColor === undefined || hexColor === null) {
    return null;
  }

  // Remove the # character if present
  const hex = hexColor.replace("#", "");

  // Parse the hexadecimal color components
  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  return "rgba(" + r + ", " + g + ", " + b + ", " + opacity + ")";
}

const backgroundColorOpacity = 0.25;

/**
 * Returns the cell style for group value cells in an RESOURCES ag-grid table.
 * The cell style contains the background color calculated from color property.
 *
 * Color property is taken from row data if the row represents a group
 * or from parent row data if the row is a child of a group.
 *
 * @param params - The cell class parameters.
 * @param isGroup - A function that determines if a row data represents a group.
 * @returns The cell style object or null if no style is applied.
 */
export function groupValueCellStyle(params: CellClassParams<IResourceDto>, isGroup: (data: any) => boolean) {
  const { data, node } = params;

  if (node.isRowPinned()) return null;

  if (data && isGroup(data)) {
    if (!isDefined(data.color)) {
      return null;
    }

    return {
      "background-color": hexToRgb(data.color, backgroundColorOpacity)
    };
  }

  if (node.parent != null) {
    const parentData = node.parent.data;
    if (parentData && isGroup(parentData)) {
      if (parentData.color === undefined || parentData.color === null) {
        return null;
      }

      return {
        "background-color": hexToRgb(parentData.color, backgroundColorOpacity)
      };
    }
  }

  return null;
}

/**
 * Returns the cell style for group value cells in an TASKS ag-grid table.
 * The cell style contains the background color calculated from color property.
 */
export function taskGroupValueCellStyle(params: CellClassParams<ProductionTaskDto>) {
  const { data, node } = params;

  if (node.isRowPinned()) return null;

  const rowNode = node as RowNode<ProductionTaskDto>;

  if (rowNode.hasChildren()) {
    if (data && isDefined(data.resourceColor)) {
      return {
        "background-color": hexToRgb(data.resourceColor, backgroundColorOpacity)
      };
    }
  }

  if (node.parent != null) {
    const parentData = node.parent.data;
    if (parentData && isDefined(parentData.resourceColor)) {
      return {
        "background-color": hexToRgb(parentData.resourceColor, backgroundColorOpacity)
      };
    }
  }

  return null;
}

/**
 * Returns the cell style for group value cells in an ASSETS ag-grid table.
 * The cell style contains the background color calculated from color property.
 */
export function assetGroupValueCellStyle(params: CellClassParams<AssetDto>) {
  const { data, node } = params;

  if (node.isRowPinned()) return null;

  if (data && isDefined(data.color)) {
    return {
      "background-color": hexToRgb(data.color, backgroundColorOpacity)
    };
  }

  if (node.parent != null) {
    const parentData = node.parent.data;
    if (parentData && isDefined(parentData.color)) {
      return {
        "background-color": hexToRgb(parentData.color, backgroundColorOpacity)
      };
    }
  }

  return null;
}