<script lang="ts" setup>
import { onMounted, ref, watch } from "vue";
import { round } from "lodash";
import { Deferred } from "nx/src/adapter/rxjs-for-await";

interface AnimatedCounterProps {
  title: string;
  icon: string;
  value: number;
  leadingZeroes: number;
  decimalPlaces: number;
  duration: number;
  frameDuration: number;
}

export interface AnimatedCounterComponent {
  resetAnimation(): Deferred<void>;

  fetchValueUsingProvider(valueProvider: Promise<number | null>): Promise<void>;
}

const $props = withDefaults(defineProps<AnimatedCounterProps>(), {
  icon: "mdi-leaf",
  value: 0.0,
  leadingZeroes: 1,
  decimalPlaces: 9,
  duration: 1000,
  frameDuration: 50
});
const $emits = defineEmits(["animationFinished"]);

const counterTitle = ref<string>("KPI");
const counterIcon = ref<string>("mdi-leaf");
const counterValue = ref<number>(0.0);
const counterDisplayLeadingZeroesCount = ref<number>(6);
const counterDisplayDecimalPlaces = ref<number>(3);
const counterDisplayValue = ref<string>("");

const animationDurationInMs = ref<number>(1000);
const animationFrameDurationInMs = ref<number>(50);

const animationCounterValue = ref<number>(0.0);

const animationFrameValueChange = ref<number>(0.0);
const animationInterval = ref<any>();
const animationIndicateWaiting = ref<boolean>(false);
const animationFinished = ref<boolean>(false);

let animationDeferred: Deferred<void>;

function updateDisplayValue(value: number) {
  counterDisplayValue.value = value
    .toFixed(counterDisplayDecimalPlaces.value)
    .toString()
    .padStart(counterDisplayLeadingZeroesCount.value + (counterDisplayDecimalPlaces.value > 0 ? counterDisplayDecimalPlaces.value + 1 : 0), "0");
}

function animationFrame() {
  const diff = counterValue.value - animationCounterValue.value;
  if (diff <= 2.0 * animationFrameValueChange.value) {
    animationCounterValue.value = counterValue.value;
    updateDisplayValue(animationCounterValue.value);
    animationIndicateWaiting.value = false;
    animationFinished.value = true;
    animationDeferred.resolve();
    $emits("animationFinished");
  } else {
    const value = animationCounterValue.value + Math.sign(diff) * animationFrameValueChange.value;
    animationCounterValue.value = round(value, counterDisplayDecimalPlaces.value);
    updateDisplayValue(animationCounterValue.value);
  }
}

function resetAnimation(): Deferred<void> {
  animationDeferred = new Deferred<void>();

  animationCounterValue.value = 0.0;
  updateDisplayValue(animationCounterValue.value);

  animationIndicateWaiting.value = false;
  animationFinished.value = false;

  if (animationInterval.value) {
    clearInterval(animationInterval.value);
    animationInterval.value = null;
  }

  return animationDeferred;
}

function startAnimation(value: number) {
  counterValue.value = value;

  const dv = counterValue.value / animationDurationInMs.value; // value change per ms
  animationFrameValueChange.value = dv * animationFrameDurationInMs.value; // value change per frame

  animationCounterValue.value = 0.0;
  animationInterval.value = setInterval(animationFrame, animationFrameDurationInMs.value);
}

function cancelWaitIndication() {
  animationIndicateWaiting.value = false;
  animationDeferred.resolve();
}

function startWaitIndication() {
  animationIndicateWaiting.value = true;
}

async function fetchValueUsingProvider(valueProvider: Promise<number | null>) {
  const deferred = resetAnimation();
  startWaitIndication();

  const value = await valueProvider;

  if (value) {
    startAnimation(value);
  } else {
    cancelWaitIndication();
  }

  return deferred.promise;
}

function applyProps() {
  counterTitle.value = $props.title ?? "KPI";
  counterIcon.value = $props.icon ?? "mdi-leaf";

  animationDurationInMs.value = $props.duration ?? 1000;
  animationFrameDurationInMs.value = $props.frameDuration ?? 50;

  counterDisplayLeadingZeroesCount.value = $props.leadingZeroes;
  counterDisplayDecimalPlaces.value = $props.decimalPlaces;

  updateDisplayValue(0.0);
}

defineExpose<AnimatedCounterComponent>({
  resetAnimation,
  fetchValueUsingProvider
});

watch($props, () => applyProps());

onMounted(() => applyProps());
</script>

<template>
  <div
    :class="{ 'animation-counter-value-requested': animationIndicateWaiting, 'animation-counter-value-reached': animationFinished }"
    class="counter-container flex-column font-mono"
  >
    <div class="w-100">{{ counterTitle }}</div>
    <div class="counter-inner-container text-h2">
      <v-icon>{{ counterIcon }}</v-icon>
      <div>{{ counterDisplayValue }}</div>
    </div>
  </div>
</template>

<style>
.counter-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  border-radius: 10px;
  padding: 20px;
  width: 30%;
  background-color: lightgrey;
  color: white;
}

.counter-inner-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.animation-counter-value-requested {
  animation: keyframes-bg-grey 0.5s linear forwards;
}

.animation-counter-value-reached {
  animation: keyframes-pop 0.3s linear both, keyframes-bg-green 0.5s linear forwards;
}

.animation-rotate {
  animation: keyframes-rotate-360 0.5s linear infinite;
}

@keyframes keyframes-rotate-360 {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes keyframes-pop {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes keyframes-bg-grey {
  0% {
  }
  25% {
    background-color: lightslategrey;
    color: white;
  }
  100% {
    background-color: lightslategrey;
    color: white;
  }
}

@keyframes keyframes-bg-green {
  0% {
    background-color: grey;
    color: white;
  }
  25% {
    background-color: rgb(180, 240, 100);
    color: white;
  }
  100% {
    background-color: rgb(150, 210, 100);
    color: white;
  }
}
</style>
