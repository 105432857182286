import { Instant } from "@js-joda/core";

export type DatelineHoverEvent = {
  start: number;
  end: number;
};

export function createDatelineHoverEvent(start: Instant, end: Instant): DatelineHoverEvent {
  return {
    start: start.toEpochMilli(),
    end: end.toEpochMilli()
  };
}
