﻿export enum CustomSettingKeys {
  DATE_TIME_FORMATTER = "app.gantt.dateTimeFormatter",
  DURATION_FORMATTER = "app.gantt.durationFormatter",
  SCENARIO_ID = "app.gantt.data.scenarioId",
  LOCAL_STORAGE_PREFIX = "gantt#",
  FILTER = "app.gantt.filter",
  START_TIME = "app.gantt.startTime",
  TIMELINE_UNIT = "app.gantt.timelineUnit",
  TIMELINE_UNIT_WIDTH = "app.gantt.timelineUnitWidth",
  QUERY_FILTER = "app.gantt.queryFilter",
  RESOURCE_ID_FILTER = "app.gantt.resourceIdFilter",
  TASK_ID_FILTER = "app.gantt.taskIdFilter",
  ACTIVITY_FILL_COLOR_SCHEDULING = "app.gantt.activity.fillColor.scheduling",
  ACTIVITY_FILL_COLOR_EXECUTION = "app.gantt.activity.fillColor.execution",
  ACTIVITY_FILL_COLOR_INTERRUPTED = "app.gantt.activity.fillColor.interrupted",
  ACTIVITY_FILL_COLOR_CALENDAR = "app.gantt.activity.fillColor.calendar",
  ACTIVITY_FILL_COLOR_CALENDAR_RESOURCE = "app.gantt.activity.fillColor.calendarResource",
  ACTIVITY_FILL_COLOR_AVAILABILITY_RULE = "app.gantt.activity.fillColor.availabilityRule",
  ACTIVITY_TEXT_COLOR_SCHEDULING = "app.gantt.activity.textColor.scheduling",
  ACTIVITY_TEXT_COLOR_EXECUTION = "app.gantt.activity.textColor.execution",
  ACTIVITY_TEXT_COLOR_INTERRUPTED = "app.gantt.activity.textColor.interrupted",
  ACTIVITY_TOOLTIP_DISPLAYED_PROPERTIES = "app.gantt.activity.tooltipDisplayProperties",
  ACTIVITY_DISPLAYED_PROPERTIES = "app.gantt.activity.activity.displayProperties",
  ACTIVITY_TEXT_COLOR_CALENDAR = "app.gantt.activity.textColor.calendar",
  ACTIVITY_TEXT_COLOR_CALENDAR_RESOURCE = "app.gantt.activity.textColor.calendarResource",
  ACTIVITY_TEXT_COLOR_AVAILABILITY_RULE = "app.gantt.activity.textColor.availabilityRule",
  ACTIVITY_STATUS_SETTINGS = "app.gantt.activity.statusSettings",
  ACTIVITY_CALENDAR_HEIGHT = "app.gantt.activity.calendar.height",
  STEP_STATUS_COLOR_SETTINGS = "app.gantt.step.colorSettings",
  CHART_SUPPLY_DEMAND_POSITIVE_COLOR = "app.gantt.chart.supplyDemandPositiveColor",
  CHART_SUPPLY_DEMAND_NEGATIVE_COLOR = "app.gantt.chart.supplyDemandNegativeColor",
  CHART_INCLUDE_RESOURCE_GROUPS = "app.gantt.chart.includeResourceGroups",
  CHART_RESOURCE_TYPE_SCALE_SETTINGS = "app.gantt.chart.resourceTypeScaleSettings",
  CHART_RESOURCE_SCALE_SETTINGS = "app.gantt.chart.resourceScaleSettings",
  CHART_RESOURCE_TYPE_VISIBILITY_SETTINGS = "app.gantt.chart.resourceTypeVisibilitySettings",
  LAYERS = "app.gantt.layers",
  MEASUREMENT_UNIT_TRANSLATIONS = "app.gantt.measurementsUnitTranslations",
  ROW_EXCLUDE_RESOURCE_GROUPS = "app.gantt.row.excludeResourceGroups",
  TAGS = "app.gantt.tags",
  TIME_SECTION_AGGREGATION = "app.gantt.timeSectionAggregation",
  DND_FILTER_ROWS = "app.gantt.dnd.filterRows",
}
