export type PaddingInsets = {
  top: number;
  right: number;
  bottom: number;
  left: number;
};

export function createPaddingInsets(top = 0, right = 0, bottom = 0, left = 0): PaddingInsets {
  return {
    top,
    right,
    bottom,
    left
  };
}
