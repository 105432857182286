<script lang="ts" setup>
import SettingsValueCellRenderer from "@/components/Settings/SettingsValueCellRenderer.vue";
import SettingsMetadataCellRenderer from "@/components/Settings/SettingsMetadataCellRenderer.vue";
import SettingsContextCellRenderer from "@/components/Settings/SettingsContextCellRenderer.vue";
import GridWrapper from "@/components/Grid/GridWrapper.vue";
import FilterGridAction, { FilterGridActionItem } from "@/components/Grid/Filters/FilterGridAction.vue";
import { computed, inject, ref } from "vue";
import { SettingContext } from "@masta/generated-model";
import { GridWrapperComponent } from "@/components/Grid/GridWrapperComponent";
import { GridApi, ValueFormatterParams } from "ag-grid-community";
import { useSettingsStore } from "@/store/SettingsStore";
import { storeToRefs } from "pinia";
import { $t } from "@/i18n";
import { enumToEditorEntries } from "@/components/Grid/ColumnTypes";
import { groupEnumValueRowKeyCreator } from "@/components/Grid/Filters/ColumnFilters";
import { useFilterGridAction } from "../Grid/Filters/UseFilterGridAction";

const settingsStore = useSettingsStore();
const $emits = defineEmits(["details", "rowSelected"]);
const { settings } = storeToRefs(settingsStore);

const gridWrapperRef = ref<GridWrapperComponent>();
const filterGridActionRef = ref<typeof FilterGridAction>();
const defaultColumnDef = ref({
  floatingFilter: true,
  filterParams: {
    applyMiniFilterWhileTyping: true
  }
});
const autoGroupColumnDef = ref({
  headerName: "Context",
  initialWidth: 150,
  suppressSizeToFit: true,
  cellRendererParams: {
    suppressCount: true
  },
  resizable: true,
  headerValueGetter: (_: any) => $t("setting-list-group-label", { $: "Context" })
});
const $acl = inject("$acl")!;
const selectedRow = ref<any>(null);
const selectedRowDisabled = computed(() => {
  return selectedRow.value?.metadata?.value?.removable;
});

const getDataPath = ref((data: any) => {
  return data.hierarchy;
});

async function onFetchData() {
  await settingsStore.fetch();
}

async function onRefreshAction() {
  await onFetchData();
}

function onPrepareColumns(columnDefs: any) {
  columnDefs.value = [
    {
      field: "key",
      type: ["textFloatingFilterColumnType"],
      editable: false,
      sortable: true,
      resizable: true,
      suppressSizeToFit: true,
      filter: "agTextColumnFilter",
      headerValueGetter: (_: any) => $t("setting-list-key-label", { $: "Key" })
    },
    {
      field: "profile",
      type: ["textFloatingFilterColumnType"],
      sortable: true,
      headerName: "Profile",
      resizable: true,
      suppressSizeToFit: true,
      filter: "agTextColumnFilter",
      headerValueGetter: (_: any) => $t("setting-list-profile-label", { $: "Profile" })
    },
    {
      field: "value",
      type: ["textFloatingFilterColumnType"],
      cellRenderer: SettingsValueCellRenderer,
      resizable: true,
      suppressSizeToFit: true,
      filter: "agTextColumnFilter",
      headerValueGetter: (_: any) => $t("setting-list-value-label", { $: "Value" })
    },
    {
      field: "context",
      type: ["setFloatingFilterColumnType"],
      rowGroup: true,
      suppressSizeToFit: true,
      cellRenderer: "agGroupCellRenderer",
      filter: "agSetColumnFilter",
      filterParams: {
        keyCreator: groupEnumValueRowKeyCreator,
        valueFormatter: (params: ValueFormatterParams) => params.value.key,
        values: enumToEditorEntries(SettingContext)
      },
      cellRendererParams: {
        suppressCount: true,
        innerRenderer: SettingsContextCellRenderer
      },
      resizable: true,
      headerValueGetter: (_: any) => $t("setting-list-context-label", { $: "Context" })
    },
    {
      field: "metadata",
      headerName: "Metadata",
      suppressSizeToFit: true,
      valueGetter: (params: any) => (params.data ? params.data.metadata.value : null),
      cellRenderer: SettingsMetadataCellRenderer,
      resizable: true,
      headerValueGetter: (_: any) => $t("setting-list-metadata-label", { $: "Metadata" })
    }
  ];
}

function onReady() {
  // gridWrapperRef.value?.columnApi.applyColumnState({
  //   state: [{ colId: "key", sort: "asc" }],
  //   defaultState: { sort: null }
  // });
}

function onCreateAction() {
  gridWrapperRef.value?.gridApi.deselectAll();
  $emits("details");
}

function onEditAction() {
  $emits("details", selectedRow.value);
}

function onDeleteAction() {
  settingsStore.delete(selectedRow.value.id);
  gridWrapperRef.value?.gridApi.deselectAll();
}

function onSelectionChanged({ api }: { api: GridApi }) {
  const rows = api.getSelectedRows();
  selectedRow.value = rows.length === 1 ? rows[0] : null;
  $emits("rowSelected", selectedRow.value);
}

function onRowDoubleClicked({ api }: { api: GridApi }) {
  if (gridWrapperRef.value?.isEditing()) return;
  const selectedRows = api.getSelectedRows();
  if (!gridWrapperRef.value?.isEditing() && selectedRows.length > 0) {
    $emits("details", selectedRows[0]);
  }
}

const filterGridAction = useFilterGridAction({ filterKey: "context", gridWrapperRef: gridWrapperRef, filterGridActionRef: filterGridActionRef });

const filterGridActionItems: FilterGridActionItem[] = [
  { value: SettingContext.Global.toString(), text: "Global" },
  { value: SettingContext.Tenant.toString(), text: "Tenant" },
  { value: SettingContext.TenantType.toString(), text: "TenantType" },
  { value: SettingContext.User.toString(), text: "User" }
];
</script>

<template>
  <grid-wrapper
    ref="gridWrapperRef"
    identifier="settings"
    refresh-btn
    create-btn
    duplicate-btn
    edit-btn
    delete-btn
    custom-create-action
    custom-edit-action
    :grid-data="settings"
    :default-col-def="defaultColumnDef"
    :auto-group-column-def="autoGroupColumnDef"
    :group-default-expanded="1"
    row-selection="multiple"
    :get-data-path="getDataPath"
    @fetch-data="onFetchData"
    @ready="onReady"
    @refresh-action="onRefreshAction"
    @create-action="onCreateAction"
    @edit-action="onEditAction"
    @delete-action="onDeleteAction"
    @prepare-columns="onPrepareColumns"
    @selection-changed="onSelectionChanged"
    @row-double-clicked="onRowDoubleClicked"
    @filter-changed="filterGridAction.onFilterGridModelChanged"
  >
    <template #filter>
      <filter-grid-action ref="filterGridActionRef" :items="filterGridActionItems" @filter-changed="filterGridAction.onFilterGridActionChanged" />
    </template>
  </grid-wrapper>
</template>

<style lang="scss" scoped></style>
