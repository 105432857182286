import { IocSymbols } from "../Core";
import { inject, injectable } from "inversify";
import { GanttDomElement } from "./GanttDomElement";
import { TopBarContainer } from "./TopBarContainer";
import { GraphicsContainer } from "./Graphics/GraphicsContainer";

@injectable()
export class GanttContainer extends GanttDomElement<HTMLDivElement> {
  constructor(
    @inject(IocSymbols.HtmlContainerSymbol) private _container: HTMLDivElement,
    @inject(TopBarContainer) private _topBarContainer: TopBarContainer,
    @inject(GraphicsContainer) private _graphicsContainer: GraphicsContainer
  ) {
    super(GanttContainer.name, _container, "gantt2");
    this.element.tabIndex = 0;
    // this._container.appendChild(this.element);
  }

  async beforeInitialize(): Promise<void> {
    await this.addChildren([this._topBarContainer, this._graphicsContainer]);
  }

  async afterInitialize(): Promise<void> {
    console.debug(`[Gantt] ${this.identifier} initialized`);
  }

  async afterDestroy(): Promise<void> {
    await super.afterDestroy();
    console.debug(`[Gantt] ${this.identifier} destroyed`);
  }
}
