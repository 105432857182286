<template>
  <split-layout ref="splitLayoutRef" identifier="personnel" :gutter="createSplitPanelGutterElement">
    <template #master>
      <v-card elevation="0" class="flexcard fill-height">
        <v-card-title>
          <breadcrumbs>{{ $t("personnel-view-personnel-title", { $: "Personnel" }) }}</breadcrumbs>
        </v-card-title>
        <v-card-text class="fill-height">
          <personnel-grid
            ref="grid"
            :reference-value="$route.query.personId?.toString()"
            @manage-availability-rules="manageAvailabilityRules"
            @resource2resource="resource2resourceAction"
            @selection-changed="onPersonSelected"
            @resource-updated="onResourceUpdated"
          />
        </v-card-text>
      </v-card>
      <v-col v-if="editAvailabilityRulesAssignmentDialog">
        <availability-rules-assignment-editor
          :resource="person"
          :opened="editAvailabilityRulesAssignmentDialog"
          @cancel="editAvailabilityRulesAssignmentDialog = false"
          @save="editAvailabilityRulesAssignmentDialog = false"
        />
      </v-col>
      <resource2-resource-dialog
        v-if="resourceType >= 0 && person"
        :opened="resource2resourceDialog"
        :assignment-type="resourceAssignmentType"
        :resource-type="resourceType"
        :resource="person"
        :reverse="isReverseResourceAssignment"
        @close="resource2resourceDialogClose"
      />
    </template>
    <template #detail>
      <personnel-details v-if="person && person.id" :person="person" :schema-tabs="schemaTabs" @updated="personUpdated" @closed="onCloseDetails" />
      <v-card v-else elevation="7" class="d-flex flex-column fill-height no-personnel-card">
        <close-card-button @click="onCloseDetails"></close-card-button>
        <v-card-text class="fill-height">
          <v-row class="fill-height">
            <v-col cols="12" class="d-flex align-center justify-center">
              <label class="text-secondary text-h6 font-weight-regular">
                {{ $t("personnel-view-noPersonnelSelected-title", { $: "No personnel selected" }) }}
              </label>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </template>
  </split-layout>
</template>

<script lang="ts" setup>
import CloseCardButton from "@/components/CloseCardButton.vue";
import SplitLayout from "@/components/Layout/SplitLayout.vue";
import { $t } from "@/i18n";

/**
 * Called to create gutter element for split panel.
 */
function createSplitPanelGutterElement(index: number, direction: "horizontal" | "vertical") {
  const gutter = document.createElement("div");
  gutter.classList.add("gutter-personnel");
  return gutter;
}
</script>

<script lang="ts">
import AvailabilityRulesAssignmentEditor from "@/components/AvailabilityRules/AvailabilityRulesAssignment/AvailabilityRulesAssignmentEditor.vue";
import { getSelectedRows } from "@/components/Grid/UseGridSelection";
import Breadcrumbs from "@/components/Layout/Breadcrumbs.vue";
import PersonnelDetails from "@/components/Personnel/PersonnelDetails.vue";
import PersonnelGrid from "@/components/Personnel/PersonnelGrid.vue";
import Resource2ResourceDialog from "@/components/Resources/ResourceAssignment/Resource2ResourceDialog.vue";
import ApiService from "@/services/api";
import { useErrorsStore } from "@/store/ErrorsStore";
import { useModelInstancesStore } from "@/store/ModelInstancesStore";
import { useScenariosStore } from "@/store/ScenariosStore";
import { useSystemStore } from "@/store/SystemStore";
import { PersonDto, ResourceAssignmentType, ResourceType } from "@masta/generated-model";
import { SelectionChangedEvent } from "ag-grid-community";
import { mapActions, mapState } from "pinia";

export default {
  name: "Personnel",
  components: {
    PersonnelDetails,
    Breadcrumbs,
    Resource2ResourceDialog,
    AvailabilityRulesAssignmentEditor,
    PersonnelGrid
  },
  title: "Personnel",
  data() {
    return {
      editAvailabilityRulesAssignmentDialog: false,
      person: null,
      refreshGridAction: null,
      resource2resourceDialog: false,
      resourceType: null,
      resourceAssignmentType: ResourceAssignmentType.AgreementAssignment,
      isReverseResourceAssignment: true,
      schemaTabs: []
    };
  },
  computed: {
    ...mapState(useModelInstancesStore, ["schemas"]),
    ...mapState(useSystemStore, ["modelInstanceTabs"])
  },
  created() {
    this.$watch(
      () => this.$route.query.personId, async (personId, _) => {
        if (personId) {
          if (this.person && personId === this.person.id) {
            return;
          } else {
            const person = await this.fetchPerson(personId);
            if (person) {
              this.person = person;
            }
          }
        } else {
          this.person = null;
        }
      },
      { immediate: true }
    );
    this.$watch(
      () => this.person, async (newVal, _) => {
        if (newVal) {
          this.onShowDetails();
        } else {
          this.onCloseDetails();
        }
      }
    );
  },
  mounted() {
    this.loadTabSchemas();
  },
  methods: {
    ...mapActions(useModelInstancesStore, ["fetchSchemas"]),
    async fetchPerson(personId: string) {
      try {
        const scenariosStore = useScenariosStore();
        const selectedScenario = scenariosStore.selectedScenario;

        if (!selectedScenario) {
          return null;
        }
        const response = await ApiService.personnel.getSingle(personId, selectedScenario.id);
        return response.data;
      } catch (error) {
        const errorsStore = useErrorsStore();
        errorsStore.handleError(error);
      }
      return null;
    },
    manageAvailabilityRules(person) {
      this.person = person;
      this.editAvailabilityRulesAssignmentDialog = true;
    },
    resource2resourceAction(resourceType, person) {
      this.resourceType = resourceType;
      switch (this.resourceType) {
        case ResourceType.Agreement:
          this.resourceAssignmentType = ResourceAssignmentType.AgreementAssignment;
          this.isReverseResourceAssignment = true;
          break;
        case ResourceType.Asset:
          this.resourceAssignmentType = ResourceAssignmentType.Identity;
          this.isReverseResourceAssignment = false;
          break;
      }
      this.person = person;
      this.resource2resourceDialog = true;
    },
    resource2resourceDialogClose() {
      this.resource2resourceDialog = false;
      this.resourceType = null;
      this.person = null;
    },
    onShowDetails() {
      this.$refs.splitLayoutRef.showDetails();
    },
    onCloseDetails() {
      this.$refs.splitLayoutRef.closeDetails();
    },
    async onPersonSelected(event: SelectionChangedEvent) {
      const selectedRows = getSelectedRows(event.api);
      if (selectedRows.length === 0) {
        this.person = null;
        return;
      }

      const selectedPerson = selectedRows[0];

      if (selectedPerson) {
        this.person = selectedPerson;

        this.onShowDetails();

        const currentQuery = this.$router.currentRoute.value.query;
        const newQuery = Object.assign({}, currentQuery, { personId: selectedPerson.id });
        await this.$router.push({ query: newQuery });
      }
    },
    async loadTabSchemas() {
      await this.fetchSchemas();
      for (const tab of this.modelInstanceTabs?.personnel ?? []) {
        const schema = this.schemas.find((x) => x.schemaName === tab.schemaName && x.schemaKey === tab.schemaKey && x.version === tab.schemaVersion);
        if (schema) {
          this.schemaTabs.push({ title: tab.title ?? "", schema, contextName: tab.contextName ?? "", resourceSubTypes: tab.resourceSubTypes });
        }
      }
    },
    async personUpdated(id: string) {
      (this.$refs.grid as typeof PersonnelGrid).refreshSingle(id);
    },
    onResourceUpdated(resource?: PersonDto) {
      this.person = resource as any;
    }
  }
};
</script>

<style scoped>
.no-personnel-card {
  border: 2px solid rgb(var(--v-theme-primary), 0.0);
  border-radius: 0;

  .close-card-button {
    position: absolute;
    top: 0px;
    right: 10px;
  }
}
</style>
