<script lang="ts" setup>
import { LocationAsRelativeRaw, useRouter } from "vue-router";
import { useAuthStore } from "@/store/AuthStore";
import { useSystemStore } from "@/store/SystemStore";
import MasterDetailLayout from "@/components/Layout/MasterDetailLayout.vue";
import { storeToRefs } from "pinia";
import { watch } from "vue";
import { useI18n } from "vue-i18n";
import { useScenariosStore } from "@/store/ScenariosStore";

const { currentTenantId } = useAuthStore();
const { selectedScenario } = useScenariosStore();

const { context } = storeToRefs(useSystemStore());
const router = useRouter();

// initialize i18n
useI18n();

watch(
  context,
  (context) => {
    if (context) {
      router.push({
        name: context.settings?.defaultStartComponentName ?? "Product Card",
        params: {
          tenantId: currentTenantId,
          scenarioId: selectedScenario?.businessId,
          ...(context.settings?.defaultStartComponentRouteProps ?? {})
        }
      } as LocationAsRelativeRaw);
    }
  },
  { immediate: true }
);
</script>

<template>
  <master-detail-layout>
    <template #master>
      <v-card elevation="0" class="flexcard fill-height"></v-card>
    </template>
  </master-detail-layout>
</template>

<script setup></script>
<style lang="scss" scoped></style>
