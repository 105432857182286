<script lang="ts" setup>
import { enumToEditorEntries, enumValueEntryWithLocaleComparator, translateEditorEntries } from "@/components/Grid/ColumnTypes";
import { ensureRowPageVisible } from "@/components/Grid/GridPageVisibility";
import GridWrapper from "@/components/Grid/GridWrapper.vue";
import { GridWrapperComponent } from "@/components/Grid/GridWrapperComponent";
import { useReferenceGrid } from "@/components/Grid/UseReferenceGrid";
import { ProcessDraftsServerSideDataSource } from "@/components/ProcessDrafts/ProcessDraftsServerSideDataSource";
import { requiredRule } from "@/components/ValueCellEditor/CommonValidationRules";
import { translateProcessDraftStatus } from "@/composables/translateEnum";
import { $t } from "@/i18n";
import ApiService from "@/services/api";
import { GetProcessDraftRowNumberQuery, IAgGridRowNumberRequest, ProcessDraftDto, ProcessDraftStatus } from "@masta/generated-model";
import { $dateTimeFormatterSymbol, DateFormatter } from "@masta/shared";
import { ColDef, FirstDataRenderedEvent, KeyCreatorParams, ValueFormatterParams } from "ag-grid-community";
import { inject, reactive, Ref, ref, watch } from "vue";

interface Props {
  referenceValue?: string;
  filterByProductId: string | undefined;
}

const props = defineProps<Props>();

const emit = defineEmits<{
  (e: "create-action"): void;
  (e: "duplicate-action"): void;
  (e: "delete-action"): void;
  (e: "rowSelected", processDraft: ProcessDraftDto): void;
  (e: "rowDoubleClicked", processDraft: ProcessDraftDto): void;
}>();

const $dateTimeFormatter = inject<DateFormatter>($dateTimeFormatterSymbol)!;
const serverSideDataSource = reactive(new ProcessDraftsServerSideDataSource("processDrafts", props.filterByProductId));
const gridWrapperRef = ref<GridWrapperComponent>();
const defaultColumnDef = ref({
  floatingFilter: true,
  filterParams: {
    applyMiniFilterWhileTyping: true
  },
  sortable: true,
  resizable: true
});

const referenceGrid = useReferenceGrid({
  $emits: emit,
  isRowSelected: (data: any) => data.id === props.referenceValue
});

defineExpose({
  clearSelection,
  refreshAll
});

watch(() => props.filterByProductId, (newValue) => {
  serverSideDataSource.useFilteringByProductId(newValue);
  gridWrapperRef.value?.gridApi.refreshServerSide({ purge: true });
});

watch(() => props.referenceValue, (newValue) => {
  if (gridWrapperRef.value) {
    ensureRowPageVisible(newValue, gridWrapperRef.value.gridApi, getProcessDraftRowNumber);
  }
});

function onFirstDataRendered(event: FirstDataRenderedEvent) {
  const { api } = event;

  ensureRowPageVisible(props.referenceValue, api, getProcessDraftRowNumber);
}

function getProcessDraftRowNumber(request: IAgGridRowNumberRequest) {

  const processDraftRequest = {
    ...request,
    targetResourceId: props.filterByProductId
  } as GetProcessDraftRowNumberQuery;

  return ApiService.processDrafts.getRowNumber(processDraftRequest);
}

function onPrepareColumns(columnDefs: Ref<ColDef[]>) {
  columnDefs.value = [
    {
      field: "name",
      filter: "agTextColumnFilter",
      type: ["textInputTypeColumn", "textFloatingFilterColumnType"],
      headerValueGetter: (_: any) => $t("processDrafts-list-name-label", { $: "Revision Name" }),
      editable: true,
      sortable: true,
      cellEditorParams: {
        rules: [requiredRule],
        placeholder: $t("processDrafts-edit-name-label", { $: "Revision Name" })
      },
      floatingFilterComponentParams: {
        placeholder: $t("processDrafts-edit-name-label", { $: "Revision Name" })
      }
    },
    {
      field: "targetResource.businessId",
      filter: "agTextColumnFilter",
      type: ["textInputTypeColumn", "textFloatingFilterColumnType"],
      headerValueGetter: (_: any) => $t("processDrafts-list-targetResourceBusinessID-label", { $: "Target Resource" }),
      editable: false,
      sortable: true,
      floatingFilterComponentParams: {
        placeholder: $t("processDrafts-list-targetResourceBusinessID-label", { $: "Target Resource" })
      }
    },
    {
      field: "targetResource.name",
      filter: "agTextColumnFilter",
      type: ["textInputTypeColumn", "textFloatingFilterColumnType"],
      headerValueGetter: (_: any) => $t("processDrafts-list-targetResourceName-label", { $: "Target Resource Name" }),
      editable: false,
      sortable: true,
      floatingFilterComponentParams: {
        placeholder: $t("processDrafts-list-targetResourceName-label", { $: "Target Resource Name" })
      }
    },
    {
      field: "status",
      type: ["enumTypeColumn", "setFloatingFilterColumnType"],
      headerValueGetter: (_: any) => $t("processDrafts-list-status-label", { $: "Status" }),
      editable: false,
      sortable: true,
      valueFormatter: (params: any) => translateProcessDraftStatus(params.data.status),
      cellStyle: (params: any) => getProcessDraftStatusCellStyle(params.value),
      filter: "agSetColumnFilter",
      filterParams: {
        valueFormatter: (params: ValueFormatterParams) => params.value.key,
        keyCreator: (params: KeyCreatorParams) => params.value.value,
        values: translateEditorEntries(enumToEditorEntries(ProcessDraftStatus), translateProcessDraftStatus),
        comparator: enumValueEntryWithLocaleComparator
      },
      floatingFilterComponentParams: {
        placeholder: $t("processDrafts-list-status-label", { $: "Status" }),
        values: translateEditorEntries(enumToEditorEntries(ProcessDraftStatus), translateProcessDraftStatus)
      }
    },
    {
      field: "createdAt",
      filter: "agDateColumnFilter",
      type: ["datepickerTypeColumn", "dateFloatingFilterColumnType", "dateTimeTypeColumn"],
      headerValueGetter: (_: any) => $t("processDrafts-list-createdAt-label", { $: "Created At" }),
      editable: false,
      sortable: true,
      valueFormatter: (params) => (params.data && params.data.createdAt ? $dateTimeFormatter(params.data.createdAt) : ""),
      suppressFloatingFilterButton: true
    },
    {
      field: "createdBy",
      filter: "agTextColumnFilter",
      type: ["textInputTypeColumn", "textFloatingFilterColumnType"],
      headerValueGetter: (_: any) => $t("processDrafts-list-createdBy-label", { $: "Created By" }),
      editable: false,
      sortable: true,
      floatingFilterComponentParams: {
        placeholder: $t("processDrafts-list-createdBy-label", { $: "Created By" })
      }
    },
    {
      field: "modifiedAt",
      filter: "agDateColumnFilter",
      type: ["datepickerTypeColumn", "dateFloatingFilterColumnType", "dateTimeTypeColumn"],
      headerValueGetter: (_: any) => $t("processDrafts-list-modifiedAt-label", { $: "Modified At" }),
      editable: false,
      sortable: true,
      valueFormatter: (params) => (params.data && params.data.modifiedAt ? $dateTimeFormatter(params.data.modifiedAt) : "")
    },
    {
      field: "modifiedBy",
      filter: "agTextColumnFilter",
      type: ["textInputTypeColumn", "textFloatingFilterColumnType"],
      headerValueGetter: (_: any) => $t("processDrafts-list-modifiedBy-label", { $: "Modified By" }),
      editable: false,
      sortable: true,
      floatingFilterComponentParams: {
        placeholder: $t("processDrafts-list-createdBy-label", { $: "Created By" })
      }
    }
  ];
}

function onCreateAction() {
  emit("create-action");
}

function onDuplicateAction() {
  emit("duplicate-action");
}

function onDeleteAction() {
  emit("delete-action");
}

function refreshAll() {
  gridWrapperRef.value?.crudActions.onRefreshAction();
}

function clearSelection() {
  gridWrapperRef.value?.gridApi.deselectAll();
}

function getProcessDraftStatusCellStyle(status: ProcessDraftStatus) {
  switch (status) {
    case ProcessDraftStatus.WorkInProgress:
      return {
        color: "rgba(30,30,30,0.95)",
        "background-color": "rgba(168,232,255,0.87)"
      };
    case ProcessDraftStatus.Archived:
      return {
        color: "rgba(30,30,30,0.95)",
        "background-color": "rgba(255,214,0,1)"
      };
  }
}
</script>

<template>
  <grid-wrapper
    ref="gridWrapperRef"
    create-btn
    duplicate-btn
    delete-btn
    refresh-btn
    row-selection="single"
    server-side
    custom-create-action
    custom-edit-action
    custom-duplicate-action
    :server-side-datasource="serverSideDataSource"
    :default-col-def="defaultColumnDef"
    identifier="process-drafts"
    @prepare-columns="onPrepareColumns"
    @create-action="onCreateAction"
    @duplicate-action="onDuplicateAction"
    @delete-action="onDeleteAction"
    @ready="referenceGrid.onGridReady"
    @first-data-rendered="onFirstDataRendered"
  >
    <!--     <template #custom-buttons>
          <v-tooltip location="bottom" open-delay="300">
            <template #activator="{ props }">
              <div class="d-inline-flex pr-4">
                <v-btn size="small" variant="text" density="compact" v-bind="props" @click="copy">
                  <v-icon icon="mdi-content-copy" class="pr-4" />
                  {{ $t("processDrafts-list-copy-label", { $: "Copy" }) }}
                </v-btn>
              </div>
            </template>
            <span>{{ $t("processDrafts-list-copy-label", { $: "Copy" }) }}</span>
          </v-tooltip>
        </template> -->
  </grid-wrapper>
</template>
