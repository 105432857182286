<script lang="ts">
import { FilterChangedEvent, IAfterGuiAttachedParams, IFloatingFilterParams } from "ag-grid-community";
import { toRef } from "@vueuse/core";
import { ref, watch } from "vue";

export default {
  setup(props: { params: IFloatingFilterParams }) {
    const params = toRef(props, "params");

    const value = ref();

    const chips = ref<string[]>([]);

    watch(value, (v) => {
      params.value.parentFilterInstance((instance) => {
        const filterModel = instance.getModel();

        if(instance.getModelAsString) {

          /**
           * AG Grid method getModelAsString returns sth like that:
           *  `(2) Częściowo zaplanowane,Nie uruchomiono`
           * 
           * We need to remove the number in brackets and split the string by comma and then update the chips
           */
          let filterModelAsString = instance.getModelAsString(filterModel);

          filterModelAsString = filterModelAsString.replace(/\(\d+\)/, '').trim();

          const cv = [] as string[];
          filterModelAsString.split(",").forEach((item) => {
            if(item) cv.push(item);
          });

          chips.value = cv;
        } else {
          console.warn("Ag Grid: instance.getModelAsString is not defined");
        }
      });
    });

    return {
      value,
      params,
      chips,
      label: "test",
      onParentModelChanged(parentModel: any, filterChangedEvent?: FilterChangedEvent | null) {
        if (!parentModel) {
          value.value = [];
        } else {
          value.value = parentModel.values;
        }
      },
      // eslint-disable-next-line @typescript-eslint/no-shadow
      afterGuiAttached(params?: IAfterGuiAttachedParams): void {
        // intentionally empty
      }
    };
  }
};
</script>

<template>
  <div class="set-floating-filter">
    <div class="set-floating-filter-container">
      <v-slide-group show-arrows>
        <v-slide-group-item v-for="c in chips" :key="c">
          <v-chip density="compact" size="small" color="primary" :text="c" variant="outlined" />
        </v-slide-group-item>
      </v-slide-group>
    </div>
  </div>
</template>

<style lang="scss">
.set-floating-filter {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1 1 auto;
  overflow: hidden;

  .set-floating-filter-container {
    padding: 0.31em;
    max-width: 100%;

    .v-slide-group__next,
    .v-slide-group__prev {
      min-width: unset;
    }

    .v-field--disabled {
      opacity: 0.75;
    }

    .v-input {
      font-size: 1em;

      .v-field {
        border-radius: 8px;
        font-size: 1em;

        .v-field__overlay {
          background-color: white;
        }

        .v-field__input {
          font-size: 1em;
          //min-height: 24px;
          padding: 0;
        }

        .v-field__outline {
          --v-field-border-width: 2px;
        }

        color: rgba(0, 0, 0, 0.5);

        &:hover {
          color: var(--ag-data-color);
        }

        &.v-field--focused {
          color: var(--ag-data-color);
        }
      }
    }
  }
}
</style>
