import ApiService from "@/services/api";
import { ServerSideDataSource } from "@/components/Grid/ServerSideDataSource";
import { DocumentDto, GetQuotationDocumentPaginatedQuery, IAgGridPaginationResponse } from "@masta/generated-model";
import { IServerSideGetRowsParams } from "ag-grid-community";
import { State as ScenarioState, useScenariosStore } from "@/store/ScenariosStore";
import { Store } from "pinia";
import { GridState, GridStateActions, useGridStore } from "@/store/GridRegistryState";

export class QuotationDocumentsServerSideDataSource extends ServerSideDataSource<DocumentDto, DocumentDto, string> {
  private _scenarioStore: Store<string, ScenarioState>;
  private _quotationDocumentsStore: Store<string, GridState, any, GridStateActions>;
  private _filterByOrderId: string | null;

  constructor(id: string, quotationDocumentsStoreId: string) {
    super(id);
    this._scenarioStore = useScenariosStore();
    this._quotationDocumentsStore = useGridStore(quotationDocumentsStoreId);
  }

  public useFilteringByOrderId(orderId: string | null) {
    this._filterByOrderId = orderId;
  }

  async getAll<T>(params: IServerSideGetRowsParams): Promise<IAgGridPaginationResponse<DocumentDto>> {
    if (!this._filterByOrderId) {
      return { count: 0, results: [] } as IAgGridPaginationResponse<DocumentDto>;
    }
    const scenarioId = this._scenarioStore.selectedScenario?.id;

    if (!scenarioId) {
      return { count: 0, results: [] } as IAgGridPaginationResponse<DocumentDto>;
    }

    const request = {
      ...this.constructServerRequest(params),
      scenarioId,
      orderId: this._filterByOrderId
    } as GetQuotationDocumentPaginatedQuery;
    const response = await ApiService.quotationDocuments.getByOrderId(request);

    return response.data as IAgGridPaginationResponse<DocumentDto>;
  }

  create(entity: DocumentDto): Promise<string | undefined> {
    return Promise.resolve(undefined);
  }

  getSingle(id: string | undefined): Promise<DocumentDto | undefined> {
    return Promise.resolve(undefined);
  }

  async remove(entity: DocumentDto): Promise<void> {
    await ApiService.documents.deleteDocument(entity.id, entity.revisionNumber);
  }

  protected transformForCreate(listEntity: DocumentDto): string {
    return "";
  }

  protected transformForUpdate(listEntity: DocumentDto): DocumentDto {
    return undefined;
  }

  update(entity: DocumentDto): Promise<string | undefined> {
    return Promise.resolve(undefined);
  }
}
