export enum GuiObjectType {
  Object,
  Text,
  Select,
  Number,
  Bool,
  MultiSelect
}

export interface GuiModelItem {
  name: string;
  type: GuiObjectType;

  getValue(): any;

  setValue(value: any): void;

  isValid(): boolean;
}
