<template>
  <v-dialog v-model="propModel" max-width="600px">
    <v-card>
      <v-card-title>
        <v-icon class="pr-2"> mdi-call-merge</v-icon>
        {{ $t("scenario-mergeScenario-mergingScenarios-label", {$: "Merging Scenarios"}) }}
      </v-card-title>
      <v-card-text>
        <div v-if="source" class="text-center font-weight-bold">
          <v-icon class="pr-2"> mdi-order-bool-ascending-variant</v-icon>
          {{ source.name }}
        </div>
        <div class="caption text-center">{{ $t("scenario-mergeScenario-source-label", {$: "Source"}) }}</div>
        <v-btn class="ma-2" color="accent" block dark @click="swap()">
          <v-icon dark> mdi-swap-vertical-bold</v-icon>
        </v-btn>
        <div class="caption text-center">{{ $t("scenario-mergeScenario-destination-label", {$: "Destination"}) }}</div>
        <div v-if="destination" class="text-center font-weight-bold">
          <v-icon class="pr-2"> mdi-order-bool-ascending-variant</v-icon>
          {{ destination.name }}
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer/>
        <v-btn color="accent" text @click="mergeScenarios"> {{ $t("scenario-mergeScenario-merge-action", {$: "Merge"}) }}</v-btn>
        <v-btn color="secondary" text @click="propModel = false"> {{ $t("scenario-mergeScenario-cancel-action", {$: "Cancel"}) }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {mapActions} from "pinia";
import {useScenariosStore} from "@/store/ScenariosStore";
import {useSnackbarsStore} from "@/store/SnackbarsStore";
import {$t} from "@/i18n";

export default {
  name: "MergeScenariosDialog",
  props: {
    value: {
      type: Boolean,
      default: false
    },
    source: {
      type: Object,
      default: null
    },
    destination: {
      type: Object,
      default: null
    }
  },
  emits: ["input", "merged"],
  computed: {
    propModel: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      }
    }
  },
  methods: {
    ...mapActions(useScenariosStore, ["merge"]),
    ...mapActions(useSnackbarsStore, ["createSnackbar"]),
    async mergeScenarios() {
      try {
        await this.merge({
          sourceScenarioId: this.source.id,
          destinationScenarioId: this.destination.id
        });
        this.propModel = false;
        this.$emit("merged");
        await this.createSnackbar({
          message: $t("scenario-mergeScenario-scenariosMerged-message", {$: "Scenarios merged"}),
          closeable: true
        });
      } catch (e) {
        console.error(e);
        await this.createSnackbar({
          message: e.message,
          type: "error",
          closeable: true
        });
      }
    },
    swap() {
      const tmp = this.destination;
      // eslint-disable-next-line vue/no-mutating-props
      this.destination = this.source;
      // eslint-disable-next-line vue/no-mutating-props
      this.source = tmp;
    }
  }
};
</script>

<style scoped></style>
