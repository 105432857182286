import { ApiClient } from "@/services/api/common.api";
import { IAgGridPaginationResponse, AssetDto, GetAssetsPaginatedQuery } from "@masta/generated-model";

export default {
  async getPaginated(request: GetAssetsPaginatedQuery) {
    return ApiClient.post<IAgGridPaginationResponse<AssetDto>>(`/assets`, request);
  },
  async getSingle(id: string, scenarioId: string) {
    return ApiClient.get<AssetDto>(`/assets/${id}?scenarioId=${scenarioId}`);
  },
  async getAssets({ scenarioId }: { scenarioId: string }) {
    return ApiClient.get<any>(`/assets?scenarioId=${scenarioId}`);
  },
  async create(request: Partial<AssetDto>) {
    return ApiClient.put<string>(`/assets`, request);
  },
  async update(request: Partial<AssetDto>) {
    return ApiClient.put<string>(`/assets/${request.id}`, request);
  },
  async remove(id: string, scenarioId?: string) {
    return ApiClient.delete(`/assets/${id}?scenarioId=${scenarioId}`);
  }
};
