import { ServerSideDataSource } from "@/components/Grid/ServerSideDataSource";
import ApiService from "@/services/api";
import { State as ScenarioState, useScenariosStore } from "@/store/ScenariosStore";
import {
  AgGridFilterModel,
  ContactDto,
  CreateContactCommand,
  GetContactsPaginatedQuery,
  GetContactsQuery,
  IAgGridPaginationResponse,
  UpdateContactCommand
} from "@masta/generated-model";
import { isDefined } from "@vueuse/core";
import { IServerSideGetRowsParams } from "ag-grid-community";
import { Store } from "pinia";

export class ContactsServerSideDataSource extends ServerSideDataSource<ContactDto, ContactDto, CreateContactCommand, UpdateContactCommand, string> {
  private _scenarioStore: Store<string, ScenarioState>;
  private _filterByOrganizationId: string | null | undefined;

  constructor(id: string, organizationId: string | null | undefined) {
    super(id);
    this._scenarioStore = useScenariosStore();
    this._filterByOrganizationId = organizationId;
  }

  public useFilteringByOrganizationId(organizationId: string | null | undefined) {
    this._filterByOrganizationId = organizationId;
  }

  async create(entity: ContactDto): Promise<string> {
    const request = this.transformForCreate(entity);
    const scenario = this._scenarioStore.selectedScenario;
    request.scenarioId = scenario?.id ?? "";
    const response = await ApiService.contacts.create(request);
    return response.data.contactId;
  }

  async getAll(params: IServerSideGetRowsParams): Promise<IAgGridPaginationResponse<ContactDto>> {
    const scenario = this.currentScenario();
    if (!scenario) {
      return { count: 0, results: [] } as IAgGridPaginationResponse<ContactDto>;
    }

    const request = {
      ...this.constructServerRequest(params),
      scenarioId: scenario.id
    } as GetContactsPaginatedQuery;

    if (isDefined(this._filterByOrganizationId)) {
      request.filterModel["organization"] = {
        filterType: "set",
        values: [this._filterByOrganizationId]
      } as any as AgGridFilterModel;
    }

    const response = await ApiService.contacts.getAllPaginated(request);
    return response.data as IAgGridPaginationResponse<ContactDto>;
  }

  async getSingle(id: string): Promise<ContactDto | undefined> {
    const scenario = this.currentScenario();
    if (!scenario) {
      return undefined;
    }
    const response = await ApiService.contacts.getAll({ scenarioId: scenario.id } as GetContactsQuery);
    return response.data.contacts.find((dto: ContactDto) => dto.id === id);
  }

  async remove(listEntity: ContactDto): Promise<void> {
    await ApiService.contacts.remove(listEntity.id, listEntity.scenarioId);
  }

  protected transformForCreate(listEntity: ContactDto): CreateContactCommand {
    return {
      name: listEntity.name,
      businessId: listEntity.businessId,
      scenarioId: listEntity.scenarioId,
      description: listEntity.description,
      email: listEntity.email,
      organizationBusinessId: listEntity.organization?.businessId,
      organizationId: listEntity.organization?.id,
      phone: listEntity.phone
    } as CreateContactCommand;
  }

  protected transformForUpdate(listEntity: ContactDto): UpdateContactCommand {
    return {
      id: listEntity.id,
      ...this.transformForCreate(listEntity)
    } as UpdateContactCommand;
  }

  async update(entity: ContactDto): Promise<string> {
    await ApiService.contacts.update(this.transformForUpdate(entity));
    return entity.id;
  }

  private currentScenario() {
    return this._scenarioStore.selectedScenario;
  }
}
