import type { IRowContainer } from "@masta/gantt2/core";
import { ActivityBounds, ActivityPosition, ActivityRef, ActivityRenderer, GanttSettings, IActivityRenderingRequest, IocSymbols, TimelineManager } from "@masta/gantt2/core";
import { inject, injectable } from "inversify";
import { CalendarResourceCapacityActivity } from "src/components/Gantt/Common/Model";
import { CustomSettingKeys } from "@/components/Gantt/ResourcesGantt/CustomSettingKeys";
import { GanttActivityColorsSetting } from "@masta/generated-model";

@injectable()
export class CalendarResourceActivityRenderer extends ActivityRenderer<CalendarResourceCapacityActivity> {
  private _startDateColor: string = "rgba(0,0,0,1)";
  private _endDateColor: string = "rgba(0,0,0,1)";

  private _defaultFillColor: string = "rgba(0,0,0,0.2)";

  constructor(
    @inject(TimelineManager) timelineManager: TimelineManager,
    @inject(IocSymbols.RowContainer) rowContainer: IRowContainer,
    @inject(GanttSettings) protected readonly _settings: GanttSettings
  ) {
    super(timelineManager, rowContainer, CalendarResourceActivityRenderer.name);
    this.paddingInsets.left = 4;
    this.paddingInsets.right = 4;
  }

  async afterInitialize(): Promise<void> {
    await super.afterInitialize();
    const fillSetting = this._settings.getSetting<GanttActivityColorsSetting>(CustomSettingKeys.ACTIVITY_FILL_COLOR_CALENDAR_RESOURCE);
    const textColorSetting = this._settings.getSetting<GanttActivityColorsSetting>(CustomSettingKeys.ACTIVITY_TEXT_COLOR_CALENDAR_RESOURCE);
    if (fillSetting) {
      this.fill = fillSetting.default;
      this.fillHighlight = fillSetting.highlight;
      this.fillSelected = fillSetting.selected;
      this.fillHover = fillSetting.hover;
      this.fillPressed = fillSetting.pressed;
    }
    if (textColorSetting) {
      this.textColor = textColorSetting.default;
      this.textColorHighlight = textColorSetting.highlight;
      this.textColorSelected = textColorSetting.selected;
      this.textColorHover = textColorSetting.hover;
      this.textColorPressed = textColorSetting.pressed;
      this._startDateColor = textColorSetting.startDate ?? this._startDateColor;
      this._endDateColor = textColorSetting.endDate ?? this._endDateColor;
    }
  }

  draw(
    activityRef: ActivityRef<CalendarResourceCapacityActivity>,
    position: ActivityPosition,
    rowCanvasContext: CanvasRenderingContext2D | OffscreenCanvasRenderingContext2D,
    x: number,
    y: number,
    w: number,
    h: number,
    offsetTop: number,
    selected: boolean,
    hover: boolean,
    highlighted: boolean,
    pressed: boolean
  ): ActivityBounds | undefined {
    if (w <= this._minWidth) return;

    h = Math.round(h / 8);

    return super.draw(activityRef, position, rowCanvasContext, x, y, w, h, offsetTop, selected, hover, highlighted, pressed);
  }

  drawActivity(request: IActivityRenderingRequest<CalendarResourceCapacityActivity>): void {
    const { activityBounds, activityRef, position, canvas, context, x, y, h, offsetTop, selected, hover, highlighted, pressed } = request;
    const { w } = request;

    context.clearRect(0, 0, w, h);

    // const font = "normal 10px Roboto";
    // context.font = font;
    // (context as any).letterSpacing = "0.3px";
    context.globalAlpha = this.opacity;

    this.fill = activityRef.activity.userObject.availabilityRule?.color ?? this._defaultFillColor;

    this.drawBackground(activityRef, position, context, x, y, w, h, offsetTop, selected, hover, highlighted, pressed);
    context.save();
    context.strokeStyle = "rgba(0,0,0,0.8)";
    context.beginPath();
    context.rect(x, y, w, h);
    context.closePath();
    context.stroke();
    context.restore();
  }
}
