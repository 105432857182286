import { Instant } from "@js-joda/core";
import { Column } from "./Column";
import { Placement } from "./Placement";
import { IActivity } from "../../Model";

export class Cluster<TActivity extends IActivity> {
  private _activities: TActivity[];

  private _startTime: Instant;

  private _endTime: Instant;

  private _columns: Column<TActivity>[];

  get activities(): TActivity[] {
    return this._activities;
  }

  get columns(): Column<TActivity>[] {
    return this._columns;
  }

  public getColumnCount(): number {
    if (!this._columns || this._columns.length === 0) {
      return -1;
    }
    return this._columns.length;
  }

  public add(activity: TActivity): void {
    if (!this._activities) {
      this._activities = [];
    }
    this._activities.push(activity);

    const { startTime, endTime } = activity;

    if (!this._startTime || startTime.isBefore(this._startTime)) {
      this._startTime = startTime;
    }
    if (!this._endTime || endTime.isAfter(this._endTime)) {
      this._endTime = endTime;
    }
  }

  public intersects(activity: TActivity): boolean {
    if (!this._startTime) return true;
    const { startTime, endTime } = activity;

    return startTime.isBefore(this._endTime) && endTime.isAfter(this._startTime);
  }

  public resolve(predicate: (activity: TActivity) => boolean): Map<TActivity, Placement<TActivity>> {
    if (!this._activities || this._activities.length === 0) {
      return new Map<TActivity, Placement<TActivity>>();
    }
    this._columns = [];
    for (const activity of this._activities) {
      let added = false;
      for (const column of this._columns) {
        if (column.hasRoomFor(activity, predicate)) {
          column.add(activity);
          added = true;

          break;
        }
      }

      if (!added) {
        const column = new Column<TActivity>();
        this.columns.push(column);
        column.add(activity);
      }
    }

    const placements = new Map<TActivity, Placement<TActivity>>();
    const colCount = this._columns.length;

    for (let col = 0; col < colCount; col++) {
      const column = this.columns[col];
      for (const activity of column.activities) {
        placements.set(activity, new Placement<TActivity>(activity, col, colCount));
      }
    }

    return placements;
  }
}
