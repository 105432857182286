import { inject, injectable } from "inversify";
import { GanttEvents, IRowInfoCell, IRowInfoColumn, Row } from "../../../../Core";
import { GanttDomElement } from "../../../GanttDomElement";
import { RowScaleRowInfoTemplateCellCanvas } from "./RowScaleRowInfoTemplateCellCanvas";

@injectable()
export class RowScaleRowInfoTemplateCellWrapper<TResource extends Row<any, any, any>> extends GanttDomElement<HTMLDivElement> implements IRowInfoCell {

  readonly cellId = "scale";
  private _column: IRowInfoColumn;

  constructor(@inject(Row<any, any, any>) private _row: TResource,
              @inject(GanttEvents) private _ganttEvents: GanttEvents,
              @inject(RowScaleRowInfoTemplateCellCanvas) private _rowScaleRowInfoTemplateCellCanvas: RowScaleRowInfoTemplateCellCanvas<any>) {
    // super(ganttEvents, `${RowScaleRowInfoTemplateCell.name}-${_row.id}`, "cell scale");
    super(`${RowScaleRowInfoTemplateCellWrapper.name}-${_row.id}`, undefined, "cell canvas");
  }


  setColumn(column: IRowInfoColumn): void {
    this._column = column;
    this._rowScaleRowInfoTemplateCellCanvas.setColumn(this._column);
  }

  async beforeInitialize(): Promise<void> {
    await super.beforeInitialize();
    await this.addChildren([this._rowScaleRowInfoTemplateCellCanvas]);
  }

  async afterInitialize(): Promise<void> {
    await super.afterInitialize();
  }

  async beforeDestroy(): Promise<void> {
    await super.beforeDestroy();
    await this.removeChild(this._rowScaleRowInfoTemplateCellCanvas);
  }

  async doDrawFromBatch(): Promise<void> {
    await super.doDrawFromBatch();
    this.element.style.width = `${this._column.width}px`;
    this._rowScaleRowInfoTemplateCellCanvas.element.style.width = `${this._column.width}px`;
  }
}
