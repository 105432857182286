import { Row } from "./Row";
import { Lifecycle } from "../Lifecycle";

export interface IRow {
  id: string;
  name: string;
  properties: Record<string, any>;
  parentId?: string;
  childrenIds: string[];
}

export interface IRowFactory {
  createRow(row: IRow, currentRows: Row<any, any, any>[]): Row<any, any, any>;
}

export class RowFactory extends Lifecycle implements IRowFactory {
  protected createRowInstance(row: IRow): Row<any, any, any> {
    return new Row(row);
  }

  createRow(row: IRow, currentRows: Row<any, any, any>[]): Row<any, any, any> {
    const resource = this.createRowInstance(row);

    const parentRow = currentRows.find((r) => r.id === row.parentId);
    if (parentRow) {
      resource.levelNumber = parentRow.levelNumber + 1;
      parentRow.addChild(resource);
      parentRow.childrenIds.push(resource.id);
      resource.parent = parentRow;
    }
    const existingChildRows = currentRows.filter((r) => r.parentId === row.id);
    if (existingChildRows.length > 0) {
      existingChildRows.forEach((r) => {
        r.parent = resource;
        resource.addChild(r);
        r.levelNumber = resource.levelNumber + 1;
      });
    }
    return resource;
  }
}
