import { inject, injectable } from "inversify";
import { GanttDomElement } from "../../../GanttDomElement";
import { Row } from "../../../../Core";
import { filter, map } from "rxjs";

@injectable()
export class RowInfoIndex<TResource extends Row<any, any, any>> extends GanttDomElement<HTMLDivElement> {
  private _color: any;

  constructor(
    @inject(Row<any, any, any>) private _row: TResource
  ) {
    super(`${RowInfoIndex.name}-${_row.id}`, undefined, "info-index");
    this.element.dataset.id = this.identifier;
  }


  async beforeInitialize(): Promise<void> {
  }

  async afterInitialize(): Promise<void> {
    this.subscribe(
      this._row.properties$
        .pipe(
          map((m) => m.get("color")),
          filter((color) => {
            return !!color;
          })
        )
        .subscribe((color) => this.onColorChange(color))
    );
  }

  private onColorChange(color: any): void {
    this._color = color;
    this.element.style.backgroundColor = this._color;
  }
}
