export type AnimationFrameCallback = {
  id: string;
  callback: (time: number) => void;
};

let animationQueue = new Array<AnimationFrameCallback>();
export const requestAnimation = (callback: AnimationFrameCallback) => {
  animationQueue.push(callback);
  if (animationQueue.length === 1) {
    requestAnimationFrame(function (time) {
      const queue = animationQueue;
      animationQueue = [];
      queue.forEach(function (cb) {
        // console.debug("[Gantt] Animation frame for: ", cb.id);
        cb.callback(time);
      });
    });
  } else {
    // console.warn(
    //   "Animation frame skipped! Queue: ",
    //   animationQueue.length,
    //   animationQueue.map((cb) => cb.id)
    // );
  }
};
