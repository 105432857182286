import { IocContainer, IocSymbols, Lifecycle, onActivationLifecycle, onDeactivationLifecycle } from "@masta/gantt2/core";
import { IResourcesGanttOptions } from "@/components/Gantt/ResourcesGantt";
import type { interfaces } from "inversify";
import { LayerManager } from "@masta/gantt2/gantt";
import { DragAndDropService } from "@/components/Gantt/ResourcesGantt/DragAndDrop/DragAndDropService";
import { ExternalTaskDragHandler } from "@/components/Gantt/ResourcesGantt/DragAndDrop/ExternalTaskDragHandler";
import { DropAreasLayer } from "@/components/Gantt/ResourcesGantt/DragAndDrop/DropAreasLayer";
import { GanttTaskDragHandler } from "@/components/Gantt/ResourcesGantt/DragAndDrop/GanttTaskDragHandler";
import { GhostActivityLayer } from "@/components/Gantt/ResourcesGantt/DragAndDrop/GhostActivityLayer";
import { ExternalTaskGhostActivityRenderer } from "@/components/Gantt/ResourcesGantt/DragAndDrop/Renderers/ExternalTaskGhostActivityRenderer";
import { SchedulingOperationGhostActivityRenderer } from "@/components/Gantt/ResourcesGantt/DragAndDrop/Renderers/SchedulingOperationGhostActivityRenderer";

export const name = "ResourcesGanttDragAndDropFrontModule";

export function rowFactoryIocExtension(container: interfaces.Container) {

  container.bind<ExternalTaskGhostActivityRenderer>(ExternalTaskGhostActivityRenderer).to(ExternalTaskGhostActivityRenderer).inTransientScope();
  container.onActivation<ExternalTaskGhostActivityRenderer>(ExternalTaskGhostActivityRenderer, onActivationLifecycle);
  container.onDeactivation<ExternalTaskGhostActivityRenderer>(ExternalTaskGhostActivityRenderer, onDeactivationLifecycle);

  container.bind<SchedulingOperationGhostActivityRenderer>(SchedulingOperationGhostActivityRenderer).to(SchedulingOperationGhostActivityRenderer).inTransientScope();
  container.onActivation<SchedulingOperationGhostActivityRenderer>(SchedulingOperationGhostActivityRenderer, onActivationLifecycle);
  container.onDeactivation<SchedulingOperationGhostActivityRenderer>(SchedulingOperationGhostActivityRenderer, onDeactivationLifecycle);
}

export async function load(container: IocContainer, options: IResourcesGanttOptions) {

  initializeLifecycle(container, DragAndDropService, DragAndDropService);

  container.bind<ExternalTaskDragHandler>(ExternalTaskDragHandler).to(ExternalTaskDragHandler).inTransientScope().onActivation(onActivationLifecycle);
  container.bind<GanttTaskDragHandler>(GanttTaskDragHandler).to(GanttTaskDragHandler).inTransientScope().onActivation(onActivationLifecycle);

  const layerManager = container.get<LayerManager>(IocSymbols.LayerManager);
  layerManager.addAboveRowContentSystemLayers(DropAreasLayer);
  layerManager.addAboveRowContentSystemLayers(GhostActivityLayer);
}

function initializeLifecycle<T extends Lifecycle>(container: IocContainer, serviceIdentifier: interfaces.ServiceIdentifier<T>, constructor: new (...args: never[]) => T) {
  const service = container.bind<T>(serviceIdentifier).to(constructor).inSingletonScope();
  service.onActivation(onActivationLifecycle);
  service.onDeactivation(onDeactivationLifecycle);
}
