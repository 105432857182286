import { inject, injectable } from "inversify";
import type { IRowChartScaleProvider } from "../../../../Core";
import { GanttEvents, GanttSettings, IocSymbols, Row, SettingKey, TimelineManager } from "../../../../Core";
import { LayerCanvas } from "./LayerCanvas";
import { ChartLayerRenderer } from "../../../../Worker";

@injectable()
export class ChartLayer extends LayerCanvas {
  private _show: boolean;
  private _scaleValues: number[] = [];

  constructor(
    @inject(Row<any, any, any>) private _row: Row<any, any, any>,
    @inject(TimelineManager) timelineManager: TimelineManager,
    @inject(GanttEvents) ganttEvents: GanttEvents,
    // @inject(IocSymbols.GanttWorkerSymbol) ganttWorker: IGanttWorker,
    @inject(GanttSettings) private _ganttSettings: GanttSettings,
    @inject(IocSymbols.RowChartScaleProvider) private _rowChartScaleProvider: IRowChartScaleProvider,
    @inject(ChartLayerRenderer.Identifier) private _renderer: ChartLayerRenderer
  ) {
    // super(timelineManager, ganttEvents, ganttWorker, ChartLayer.name, "chart-layer", _row.id);
    super(timelineManager, ganttEvents, ChartLayer.name, "chart-layer");
  }

  async beforeInitialize(): Promise<void> {
    return super.beforeInitialize();
  }

  async afterInitialize(): Promise<void> {
    await super.afterInitialize();
    this.subscribe(this._ganttSettings.getSetting$<boolean>(SettingKey.CHART_SHOW).subscribe((s) => {
      const redraw = this._show !== undefined && this._show !== s;
      this._show = s ?? false;
      if (redraw) {
        this.clear();
        this.batchDraw();
      }
    }));
    this.subscribe(
      this._rowChartScaleProvider.scaleValues$.subscribe((value) => {
        this._scaleValues = value;
        this.clear();
        this.batchDraw();
      })
    );
  }

  async doDrawFromBatch(): Promise<void> {
    if (!this._row.visible || !this._show) return;
    this._renderer.render(this.canvas as any, this.context as any, { scaleValues: this._scaleValues });
  }

  // protected getRendererIdentifier(): string {
  //   return "ChartLayerRenderer";
  // }
}
