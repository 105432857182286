import { inject, injectable, multiInject } from "inversify";
import { GanttEvents, GanttException, GanttSettings, IocSymbols, RowChartRepository, TimelineManager } from "@masta/gantt2/core";
import { filter, withLatestFrom } from "rxjs";
import { ProductionTaskDto } from "@masta/generated-model";
import ApiService from "@/services/api";
import { DragAndDropData, LayerCanvas, ViewportPointerEventNoneScrollGlass, ViewportRowsContainer } from "@masta/gantt2/gantt";
import { RowDropZone } from "@/components/Gantt/ResourcesGantt/DragAndDrop/RowDropZone";
import { useScenariosStore } from "@/store/ScenariosStore";
import { $t } from "@/i18n";
import { Instant } from "@js-joda/core";
import { v4 as uuid } from "uuid";
import { useProductionTasksStore } from "@/store/ProductionTasksStore";
import { BaseTaskDragHandler } from "@/components/Gantt/ResourcesGantt/DragAndDrop/BaseTaskDragHandler";

const LOG_STYLE = "color: black; font-weight: bold; background-color: yellow; padding: 2px 25px;";

@injectable()
export class ExternalTaskDragHandler extends BaseTaskDragHandler {

  private _productionTaskDto: ProductionTaskDto;

  constructor(@inject(GanttEvents) ganttEvents: GanttEvents,
              @inject(GanttSettings) settings: GanttSettings,
              @inject(TimelineManager) timelineManager: TimelineManager,
              @inject(ViewportRowsContainer) rowsContainer: ViewportRowsContainer,
              @inject(RowChartRepository) rowChartRepository: RowChartRepository,
              @inject(ViewportPointerEventNoneScrollGlass) glassLayer: ViewportPointerEventNoneScrollGlass,
              @multiInject(IocSymbols.AboveRowContentSystemLayersSymbol) aboveRowLayers: LayerCanvas[] = []) {
    super(ganttEvents, settings, timelineManager, rowsContainer, rowChartRepository, glassLayer, aboveRowLayers);
  }

  set productionTaskDto(value: ProductionTaskDto) {
    if (this._productionTaskDto) {
      throw new GanttException("ProductionTask is already set");
    }
    this._productionTaskDto = value;
  }

  async afterInitialize(): Promise<void> {
    await super.afterInitialize();

    this.subscribe(
      this._ganttEvents.dragOverEvent$$.pipe(withLatestFrom(this._ganttEvents.externalActivityDrag$$, this._ganttEvents.ganttActivityDrag$$))
        .subscribe(([event, isExternal, draggedActivityData]) => this.onDragOver(event, isExternal, draggedActivityData))
    );

    this.subscribe(
      this._ganttEvents.dropEvent$$.pipe(withLatestFrom(this._ganttEvents.externalActivityDrag$$, this._ganttEvents.ganttActivityDrag$$))
        .pipe(filter(([_, isExternal, __]) => isExternal))
        .subscribe(() => this.onDrop())
    );
  }

  async afterDestroy() {
    await super.afterDestroy();
  }

  async setContext() {
    try {
      const scenariosStore = useScenariosStore();
      const { data } = await ApiService.gantt.getDragAndDropContext(this._productionTaskDto.id, scenariosStore.selectedScenario!.id);
      // check if component is destroyed (delay between drag cancel and API response)
      if (this.isDestroyed) {
        return;
      }
      this._context = data;
      if (this._filterRows) {
        this.hideRowsOutsideOfDndContext();
      }
      await this.setDropZones();
      if (!this._filterRows && this._rowDropZones.length > 0) {
        this.scrollToRowDropZone(this._rowDropZones[0]);
      }

      this._ghostActivityLayer.addExternalTaskGhostActivityLayerContext({
        dragId: this._dragId,
        context: this._context,
        draggableTaskDto: this._productionTaskDto
      });
    } catch (e: any) {
      if (e.name !== "AbortError") {
        console.error(e);
      }
    }
  }

  private async onDragOver(event: DragEvent, isExternal: boolean, _: DragAndDropData[] | null) {
    if (isExternal && event.dataTransfer && this._rowHoverEvent && this._context) {
      const rowDropZone = this._rowDropZones.find(x => x.row.id === this._rowHoverEvent?.rowId);

      const isDropAllowed = rowDropZone?.isDropAllowedForSupplyArea(this._mousePosition.x) ?? false;
      if (isDropAllowed) {
        if (this._glassLayer.element.classList.contains("not-allowed")) {
          this._glassLayer.element.classList.remove("not-allowed");
        }
        event.dataTransfer.dropEffect = "move";
        event.preventDefault();
        return;
      } else {
        if (!this._glassLayer.element.classList.contains("not-allowed")) {
          this._glassLayer.element.classList.add("not-allowed");
        }
        event.dataTransfer.dropEffect = "none";
      }
    }
  }

  public async onFinished() {
    await this.destroyWithTimeout();
    const ghosts = document.getElementsByClassName("ag-dnd-ghost");
    for (let i = 0; i < ghosts.length; i++) {
      ghosts[i].classList.remove("drag-not-allowed");
    }
  }

  public async onDrag() {
    if (this._context) {
      const rowDropZone = this._rowDropZones.find(x => x.row.id === this._rowHoverEvent?.rowId);

      if (!rowDropZone) {
        if (!this._glassLayer.element.classList.contains("not-allowed")) {
          this._glassLayer.element.classList.add("not-allowed");
        }
        const ghosts = document.getElementsByClassName("ag-dnd-ghost");
        for (let i = 0; i < ghosts.length; i++) {
          ghosts[i].classList.add("drag-not-allowed");
        }
        return;
      }

      const isDropAllowed = rowDropZone.isDropAllowedForSupplyArea(this._mousePosition.x);
      if (isDropAllowed) {
        if (this._glassLayer.element.classList.contains("not-allowed")) {
          this._glassLayer.element.classList.remove("not-allowed");
        }
        const ghosts = document.getElementsByClassName("ag-dnd-ghost");
        for (let i = 0; i < ghosts.length; i++) {
          ghosts[i].classList.remove("drag-not-allowed");
        }
      } else {
        if (!this._glassLayer.element.classList.contains("not-allowed")) {
          this._glassLayer.element.classList.add("not-allowed");
        }
        const ghosts = document.getElementsByClassName("ag-dnd-ghost");
        for (let i = 0; i < ghosts.length; i++) {
          ghosts[i].classList.add("drag-not-allowed");
        }
      }
    }
  }

  public async onDrop() {
    if (this.isDestroyed) return;

    this._isDropped = true;

    if (this._rowHoverEvent) {

      const rowDropZone = this._rowDropZones.find(x => x.row.id === this._rowHoverEvent?.rowId);

      const isDropAllowed = rowDropZone?.isDropAllowedForSupplyArea(this._mousePosition.x) ?? false;
      if (!rowDropZone || !isDropAllowed) {
        const name = this._productionTaskDto.name;
        const businessId = this._productionTaskDto.businessId;
        await this._snackbarsStore.createSnackbar({
          message:
            $t("gantt-dragAndDrop-dropNotAllowed-message", { name, businessId, $: "Task {name} {businessId} cannot be dropped here!" }),
          type: "warning",
          closeable: true
        });
        console.warn("Task cannot be dropped here");
        await this.destroy();
        return;
      }

      if (this._businessKey) {
        console.warn("Task already scheduled, businessKey: ", this._businessKey);
        await this.destroy();
        return;
      }

      const snapX = rowDropZone.getSnapXCoordinate(this._mousePosition.x, RowDropZone.SnapAreaWidth, this._context.schedulingLengthMillis);
      const dropTime = this._timelineManager.calculateTimeForLocation(snapX);

      this._businessKey = await this.scheduleTask(this._productionTaskDto, dropTime, this._rowHoverEvent.rowId);

      if (this._filterRows) {
        this.showAllRows();
      }

      if (!this._businessKey) return;

      this.cancelDestroyTimeout();

      this._dropAreasLayer.visible = false;
      this._dropAreasLayer.batchDraw();

      this._ghostActivityLayer.removeExternalTaskGhostActivityLayerContext(this._dragId);
      this._ghostActivityLayer.addSchedulingOperationGhostActivityLayerContext({
        businessKey: this._businessKey,
        rowDropZone,
        dropTime,
        context: this._context,
        draggableTaskDto: this._productionTaskDto
      });


      this.scrollToRowDropZone(rowDropZone);
    } else {
      await this.destroy();
    }
  }

  private async scheduleTask(data: ProductionTaskDto, dropTime: Instant, resourceId: string | undefined) {
    try {
      const businessKey = uuid();
      const store = useProductionTasksStore();
      await store.scheduleGanttDroppedTask({
        taskId: data.id,
        resourceId,
        time: dropTime.toJSON(),
        businessKey
      });
      await this._snackbarsStore.createSnackbar({
        message: $t("gantt-dragAndDrop-onScheduleTaskSuccess-message", { name: data.name, businessId: data.businessId, $: "Task {name} {businessId} scheduled!" }),
        type: "info",
        closeable: true
      });
      return businessKey;
    } catch (e) {
      console.error(e);
      await this._snackbarsStore.createSnackbar({
        message: $t("gantt-dragAndDrop-onScheduleTaskError-message", { name: data.name, businessId: data.businessId, $: "Could not schedule task {name} {businessId}" }),
        type: "error",
        closeable: true
      });
    }
  }
}
