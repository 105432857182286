import ApiService from "@/services/api";
import { ServerSideDataSource } from "@/components/Grid/ServerSideDataSource";
import {
  AssignOrderLinesToGroupCommand,
  DeassignOrderLinesFromGroupCommand,
  GetOrderLineGroupAssignmentsPaginatedQuery,
  IAgGridPaginationResponse,
  OrderLineGroupAssignmentDto
} from "@masta/generated-model";
import { IServerSideGetRowsParams } from "ag-grid-community";
import { State as ScenarioState, useScenariosStore } from "@/store/ScenariosStore";
import { Store } from "pinia";

export class OrderLineGroupAssignmentServerSideDataSource extends ServerSideDataSource<
  OrderLineGroupAssignmentDto,
  OrderLineGroupAssignmentDto,
  AssignOrderLinesToGroupCommand,
  AssignOrderLinesToGroupCommand,
  string
> {
  private _scenarioStore: Store<string, ScenarioState>;
  private _filterByOrderLineGroupId: string | null | undefined;

  constructor(id: string) {
    super(id);
    this._scenarioStore = useScenariosStore();
  }

  public useFilteringByOrderLineGroupId(productId: string | null | undefined) {
    this._filterByOrderLineGroupId = productId;
  }

  async create(entity: OrderLineGroupAssignmentDto): Promise<string> {
    const request = this.transformForCreate(entity);
    const scenario = this._scenarioStore.selectedScenario;
    request.scenarioId = scenario?.id ?? "";
    await ApiService.orderLineGroups.assignOrderLinesToGroup(request);
    return "";
  }

  async getAll<T>(params: IServerSideGetRowsParams): Promise<IAgGridPaginationResponse<OrderLineGroupAssignmentDto>> {
    const scenarioId = this._scenarioStore.selectedScenario?.id;

    if (!scenarioId || !this.isOrderLineGroupSelected()) {
      return { count: 0, results: [] } as IAgGridPaginationResponse<OrderLineGroupAssignmentDto>;
    }

    const request = {
      ...this.constructServerRequest(params),
      orderLineGroupId: this.getOrderLineGroupId(),
      scenarioId
    } as GetOrderLineGroupAssignmentsPaginatedQuery;
    const response = await ApiService.orderLineGroups.getOrderLineGroupAssignmentsPaginated(request);

    return response.data as IAgGridPaginationResponse<OrderLineGroupAssignmentDto>;
  }

  async getSingle(id: string): Promise<OrderLineGroupAssignmentDto | undefined> {
    const scenarioId = this._scenarioStore.selectedScenario?.id;

    if (!scenarioId || !this.isOrderLineGroupSelected()) return;

    if (!this.getOrderLineGroupId()) return;

    const response = await ApiService.orderLineGroups.getOrderLineGroupAssignment(this.getOrderLineGroupId(), id, scenarioId);
    return response.data;
  }

  async update(entity: OrderLineGroupAssignmentDto): Promise<string> {
    throw new Error("Method not implemented.");
  }

  protected transformForCreate(entity: OrderLineGroupAssignmentDto): AssignOrderLinesToGroupCommand {
    return {
      orderLineGroupId: entity.orderLineGroupId,
      orderLineIds: [entity.orderLineId],
      orderIds: [entity.orderId],
      scenarioId: this._scenarioStore.selectedScenario?.id ?? ""
    };
  }

  protected transformForUpdate(listEntity: OrderLineGroupAssignmentDto): AssignOrderLinesToGroupCommand {
    throw new Error("Method not implemented.");
  }

  async remove(entity: OrderLineGroupAssignmentDto): Promise<void> {
    if (entity.orderLineId && this._filterByOrderLineGroupId) {
      const request = {
        orderLineGroupId: entity.orderLineGroupId,
        orderLineIds: [entity.orderLineId],
        orderIds: [entity.orderId],
        scenarioId: this._scenarioStore.selectedScenario?.id ?? ""
      } as DeassignOrderLinesFromGroupCommand;
      await ApiService.orderLineGroups.unassignOrderLinesFromGroup(request);
    }
  }

  private isOrderLineGroupSelected() {
    return this._filterByOrderLineGroupId;
  }

  private getOrderLineGroupId(): string {
    return this._filterByOrderLineGroupId ?? "";
  }
}
