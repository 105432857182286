import { Instant } from "@js-joda/core";

export class TreeEntry<Value> {
  low: number;

  high: number;

  value: Value;

  maxHigh: number = Number.MIN_VALUE;

  left: TreeEntry<Value> | null | undefined = null;

  right: TreeEntry<Value> | null | undefined = null;

  parent: TreeEntry<Value> | null | undefined;

  color = true;

  constructor(low: number, high: number, value: Value, parent: TreeEntry<Value> | null) {
    this.low = low;
    this.high = high;
    this.value = value;
    this.parent = parent;
    this.maxHigh = high;
  }

  toString(): string {
    return `[${Instant.ofEpochMilli(this.low)} - ${Instant.ofEpochMilli(this.high)}]`;
  }
}
