import { ApiClient } from "@/services/api/common.api";
import { useAuthStore } from "@/store/AuthStore";
import { $authService } from "@/services/AuthService";
import { DocumentEditionDetailsDto, GetDocumentDetailsForEditionQuery } from "@masta/generated-model";

export default {
  async getDocumentEditionQrcode(scenarioId: string, documentId: string | undefined, revisionNumber: number | undefined, contextName: string): DocumentEditionDetailsDto {
    const authStore = useAuthStore();
    const query = {
      scenarioId: scenarioId,
      clientId: authStore.clientId,
      token: $authService.token,
      attachmentGuid: documentId,
      revisionNumber,
      contextName
    } as GetDocumentDetailsForEditionQuery;
    return ApiClient.post<DocumentEditionDetailsDto>("/documenteditionformobile", query);
  },
  async getDocumentAssignedToResourceEditionQrcode(
    scenarioId: string,
    documentId: string | undefined,
    revisionNumber: number | undefined,
    contextName: string,
    resourceId: string
  ): DocumentEditionDetailsDto {
    const authStore = useAuthStore();
    const query = {
      scenarioId: scenarioId,
      clientId: authStore.clientId,
      token: $authService.token,
      attachmentGuid: documentId,
      revisionNumber,
      contextName
    } as GetDocumentDetailsForEditionQuery;
    return ApiClient.post<DocumentEditionDetailsDto>(`/documenteditionformobile/resource/${resourceId}`, query);
  },
  async getDocumentAssignedToOrderEditionQrcode(
    scenarioId: string,
    documentId: string | undefined,
    revisionNumber: number | undefined,
    contextName: string,
    orderId: string
  ): DocumentEditionDetailsDto {
    const authStore = useAuthStore();
    const query = {
      scenarioId: scenarioId,
      clientId: authStore.clientId,
      token: $authService.token,
      attachmentGuid: documentId,
      revisionNumber,
      contextName
    } as GetDocumentDetailsForEditionQuery;
    return ApiClient.post<DocumentEditionDetailsDto>(`/documenteditionformobile/order/${orderId}`, query);
  },
  async getDocumentAssignedToCostCatalogueItemEditionQrcode(
    scenarioId: string,
    documentId: string | undefined,
    revisionNumber: number | undefined,
    contextName: string,
    costCatalogueItemId: string
  ): DocumentEditionDetailsDto {
    const authStore = useAuthStore();
    const query = {
      scenarioId: scenarioId,
      clientId: authStore.clientId,
      token: $authService.token,
      attachmentGuid: documentId,
      revisionNumber,
      contextName
    } as GetDocumentDetailsForEditionQuery;
    return ApiClient.post<DocumentEditionDetailsDto>(`/documenteditionformobile/costCatalogueItem/${costCatalogueItemId}`, query);
  }
};
