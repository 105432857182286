<script lang="ts" setup>
import { onMounted, ref, watch } from "vue";
import { random } from "lodash";
import { $t } from "@/i18n";
import { MaterialDto } from "@masta/generated-model";
import AnimatedCounter, { AnimatedCounterComponent } from "@/views/ProductOverview/AnimatedCounter.vue";
import { Deferred } from "nx/src/adapter/rxjs-for-await";
import ApiService from "@/services/api";
import { LcaEcoefficiencyDto } from "@/services/api/kyklos.api";

interface EcoEfficiencyProps {
  product: MaterialDto | undefined;
}

const $props = defineProps<EcoEfficiencyProps>();

const recalculateInProgress = ref<boolean>(false);

const counter1Ref = ref<AnimatedCounterComponent>();
const counter2Ref = ref<AnimatedCounterComponent>();
const counter3Ref = ref<AnimatedCounterComponent>();

let counterDeferredValues: Deferred<number | null>[];
let counterValues: (number | null)[];

function generateEcoEfficiencyIfEnabled(): LcaEcoefficiencyDto | undefined {
  if (!$props.product || !("FEATURE_ECO_EFFICIENCY_TEST" in window)) {
    return;
  }

  const m = 0.0001;
  const v = random(10, 1000, true);
  return {
    resourceId: $props.product.id,
    gwp20: v * m,
    gwp100: v * 5.0 * m,
    gwp500: v * 25.0 * m
  } as LcaEcoefficiencyDto;
}

async function fetchEcoEfficiency(): Promise<LcaEcoefficiencyDto | undefined> {
  if (!$props.product) {
    return;
  }

  try {
    const { data } = await ApiService.kyklos.getLca($props.product.id);
    return data;
  } catch (e) {
    console.log(e);
  }
}

async function fetchCounterValues() {
  const data = (await fetchEcoEfficiency()) ?? generateEcoEfficiencyIfEnabled();

  if (data) {
    counterValues = [data.gwp20 ?? null, data.gwp100 ?? null, data.gwp500 ?? null];
  } else {
    counterValues = [null, null, null];
  }
}

function prepareCounterValueProviders() {
  counterDeferredValues = [new Deferred<number | null>(), new Deferred<number | null>(), new Deferred<number | null>()];
}

function getCounterValueProvider(counterId: number) {
  return counterDeferredValues[counterId].promise;
}

function getCounterValue(counterId: number) {
  return counterValues[counterId];
}

function provideCounterValue(counterId: number) {
  counterDeferredValues[counterId].resolve(getCounterValue(counterId));
}

async function onRecalculate() {
  if (!$props.product) {
    return;
  }
  if (!counter1Ref.value || !counter2Ref.value || !counter3Ref.value) {
    console.error("One of the counter refs is not set");
    return;
  }

  recalculateInProgress.value = true;

  prepareCounterValueProviders();

  // the idea here is switch counters to waiting mode in the same time, then run them one by one
  // pass value providers (promises) so all counters switch to waiting mode (visual style), after promise is resolved the animation of given counter will start
  counter1Ref.value.fetchValueUsingProvider(getCounterValueProvider(0)).then(() => provideCounterValue(1));
  counter2Ref.value.fetchValueUsingProvider(getCounterValueProvider(1)).then(() => provideCounterValue(2));
  counter3Ref.value.fetchValueUsingProvider(getCounterValueProvider(2)).then(() => onCounterAnimationsFinished());

  await fetchCounterValues();

  provideCounterValue(0);
}

function onCounterAnimationsFinished() {
  recalculateInProgress.value = false;
}

function onReset() {
  if (counter1Ref.value) {
    counter1Ref.value.resetAnimation();
  }
  if (counter2Ref.value) {
    counter2Ref.value.resetAnimation();
  }
  if (counter3Ref.value) {
    counter3Ref.value.resetAnimation();
  }
}

onMounted(() => {
  onReset();
});

watch($props, () => {
  onReset();
});
</script>

<template>
  <v-card elevation="0">
    <v-card-title>{{ $t("productOverview-ecoEfficiency-ecoEfficiency-title", { $: "Ecoefficiency" }) }}</v-card-title>
    <v-card-item>
      <div class="kpi-container">
        <div class="kpi-inner-container">
          <AnimatedCounter ref="counter1Ref" title="Benefits / GWP 20" />
          <AnimatedCounter ref="counter2Ref" title="Benefits / GWP 100" />
          <AnimatedCounter ref="counter3Ref" title="Benefits / GWP 500" />
        </div>
        <v-btn :disabled="recalculateInProgress" size="x-large" @click="onRecalculate">
          <template #prepend>
            <v-icon :class="{ 'animation-rotate': recalculateInProgress }">mdi-refresh</v-icon>
          </template>
          {{ $t("productOverview-ecoEfficiency-calculate-action", { $: "Calculate using LCA data" }) }}
        </v-btn>
      </div>
      <div class="image-container">
        <v-img class="image" src="/eco-efficiency-definition.png"></v-img>
      </div>
    </v-card-item>
  </v-card>
</template>

<style>
.kpi-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  padding: 20px;
}

.kpi-inner-container {
  display: flex;
  flex-direction: row;
  gap: 20px;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.counter-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  border-radius: 10px;
  padding: 20px;
  width: 30%;
  background-color: lightgrey;
  color: white;
}

.counter-inner-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 20px;
}

.animation-counter-value-requested {
  animation: keyframes-bg-grey 0.5s linear forwards;
}

.animation-counter-value-reached {
  animation: keyframes-pop 0.3s linear both, keyframes-bg-green 0.5s linear forwards;
}

.animation-rotate {
  animation: keyframes-rotate-360 0.5s linear infinite;
}

.image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 15vh;
}

.image {
  max-width: 100%;
  max-height: 100%;
}

@keyframes keyframes-rotate-360 {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes keyframes-pop {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes keyframes-bg-grey {
  0% {
  }
  25% {
    background-color: lightslategrey;
    color: white;
  }
  100% {
    background-color: lightslategrey;
    color: white;
  }
}

@keyframes keyframes-bg-green {
  0% {
    background-color: grey;
    color: white;
  }
  25% {
    background-color: rgb(180, 240, 100);
    color: white;
  }
  100% {
    background-color: rgb(150, 210, 100);
    color: white;
  }
}
</style>
