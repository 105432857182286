<script lang="ts" setup>
import GridWrapper from "@/components/Grid/GridWrapper.vue";
import { GridWrapperComponent } from "@/components/Grid/GridWrapperComponent";
import { ref } from "vue";
import { $t } from "@/i18n";
import { ValueFormatterParams } from "ag-grid-community";
import { translateMeasurementUnit } from "@/composables/translateEnum";
import ApiService from "@/services/api";
import { useErrorsStore } from "@/store/ErrorsStore";
import { GetMaterialStockQuery, MaterialStockDto } from "@masta/generated-model";
import ActionsButton from "@/components/Layout/ActionsButton.vue";
import MaterialStockParametersDialog from "@/components/MaterialStock/MaterialStockParametersDialog.vue";
import CreateMaterialMovementDialog from "@/components/MaterialStock/CreateMaterialMovementDialog.vue";

const gridWrapperRef = ref<GridWrapperComponent>();
const errorsStore = useErrorsStore();
const data = ref<MaterialStockDto[]>([]);
const materialStockDialog = ref(false);
const materialMovementDialog = ref(false);
const query = ref<GetMaterialStockQuery>();

async function onRefreshAction() {
  await onFetchData();
}

async function onFetchData() {
  await fetchData();
}

async function fetchData() {
  if (!query.value) {
    return;
  }
  try {
    const response = await ApiService.materialStock.getMaterialStock(query.value);
    data.value = response.data?.materialStockDtos ?? [];
  } catch (e) {
    errorsStore.handleError(e);
    throw e;
  }
}

function onPrepareColumns(columnDefs: any) {
  columnDefs.value = [
    {
      field: "scenarioId",
      headerName: "Scenario Id",
      editable: false,
      sortable: true,
      hidden: true,
      filter: "agTextColumnFilter",
      type: "textInputTypeColumn",
      headerValueGetter: (_: any) => $t("materialStock-list-scenarioId-label", { $: "ScenarioId" })
    },
    {
      field: "locationId",
      headerName: "Location Id",
      editable: false,
      sortable: true,
      filter: "agTextColumnFilter",
      type: "textInputTypeColumn",
      headerValueGetter: (_: any) => $t("materialStock-list-locationId-label", { $: "LocationId" })
    },
    {
      field: "locationBusinessId",
      headerName: "Location Business Id",
      editable: false,
      sortable: true,
      filter: "agTextColumnFilter",
      type: "textInputTypeColumn",
      headerValueGetter: (_: any) => $t("materialStock-list-locationBusinessId-label", { $: "Location Business Id" })
    },
    {
      field: "materialId",
      headerName: "Material Id",
      editable: false,
      sortable: true,
      filter: "agTextColumnFilter",
      type: "textInputTypeColumn",
      headerValueGetter: (_: any) => $t("materialStock-list-materialId-label", { $: "MaterialId" })
    },
    {
      field: "materialBusinessId",
      headerName: "Material Business Id",
      editable: false,
      sortable: true,
      filter: "agTextColumnFilter",
      type: "textInputTypeColumn",
      headerValueGetter: (_: any) => $t("materialStock-list-materialBusinessId-label", { $: "Material Business Id" })
    },
    {
      field: "trackingUniqueIdentifier",
      headerName: "TrackingUniqueIdentifier",
      editable: false,
      sortable: true,
      filter: "agTextColumnFilter",
      type: "textInputTypeColumn",
      headerValueGetter: (_: any) => $t("materialStock-list-trackingUniqueIdentifier-label", { $: "Lot / Serial" })
    },
    {
      field: "quantity",
      headerName: "Quantity",
      sortable: true,
      editable: false,
      filter: "agNumberColumnFilter",
      resizable: true,
      type: ["textInputTypeColumn", "textFloatingFilterColumnType"],
      valueFormatter: (params: ValueFormatterParams) => {
        return params.value ? params.value.toFixed(1) : null;
      },
      headerValueGetter: (_: any) => $t("materialStock-list-quantity-label", { $: "Quantity" })
    },
    {
      field: "measurementUnit",
      type: "enumTypeColumn",
      editable: false,
      resizable: true,
      sortable: true,
      headerValueGetter: (_: any) => $t("materialStock-list-unit-label", { $: "Unit" }),
      valueFormatter: (params: ValueFormatterParams) => translateMeasurementUnit(params.value)
    }
  ];
}

const defaultColDef = ref({
  // allow every column to be aggregated
  enableValue: true,
  // allow every column to be grouped
  enableRowGroup: true,
  // allow every column to be pivoted
  enablePivot: true,
  floatingFilter: true,
  filterParams: {
    applyMiniFilterWhileTyping: true
  }
});

function triggerMaterialStockAction() {
  materialStockDialog.value = true;
}

function triggerCreateMaterialMovementAction() {
  materialMovementDialog.value = true;
}

async function calculationParamsSet(queryFromDialog: GetMaterialStockQuery) {
  materialStockDialog.value = false;
  query.value = queryFromDialog;
  await fetchData();
}

const detailActions = ref([
  {
    title: $t("materialStock-list-fetch-action", { $: "1. Get Material Stock" }),
    tooltip: $t("materialStock-list-fetch-tooltip", { $: "1. Get Material Stock" }),
    action: triggerMaterialStockAction,
    icon: "mdi-warehouse",
    disabled: () => false,
    order: 1
  },
  {
    title: $t("materialStock-list-createMaterialMovement-action", { $: "2. Create Material Movement" }),
    tooltip: $t("materialStock-list-createMaterialMovement-tooltip", { $: "2. Create Material Movement" }),
    action: triggerCreateMaterialMovementAction,
    icon: "mdi-swap-horizontal",
    disabled: () => data.value.length === 0,
    order: 3
  }
]);

</script>

<template>
  <grid-wrapper
    ref="gridWrapperRef"
    identifier="material-stock"
    :enable-charts="true"
    refresh-btn
    enable-range-selection
    enable-columns-tool-panel
    enable-filters-tool-panel
    row-selection="multiple"
    :grid-data="data"
    group-display-type="multipleColumns"
    :pivot-mode="false"
    :default-col-def="defaultColDef"
    :hide-custom-actions-separator="true"
    :checkbox-selection="false"
    disable-actions-column
    :pivot-default-expanded="-1"
    :group-default-expanded="-1"
    :group-hide-open-parents="true"
    @fetch-data="onFetchData"
    @refresh-action="onRefreshAction"
    @prepare-columns="onPrepareColumns"
  >
    <template #custom-buttons>
      <div class="d-inline-flex pr-4">
        <actions-button :model-value="detailActions" />
      </div>
      <material-stock-parameters-dialog v-model="materialStockDialog" @calculation-params-set="calculationParamsSet" />
      <create-material-movement-dialog v-model="materialMovementDialog" @material-movement-created="fetchData" />
    </template>
  </grid-wrapper>
</template>
