import { inject, injectable, optional, tagged } from "inversify";
import { ActivityPosition, ActivityRef, IActivity, ICalendar, IocSymbols, Layer, Lifecycle, Row, TimelineManager, WeekendCalendarActivity } from "../../../Core";
import { ILayerRenderer } from "./ILayerRenderer";
import { WeekendCalendarActivityRenderer } from "../Activities";

export type WeekendCalendarLayerRendererRequest = {
  offsetTop: number;
};

@injectable()
export class WeekendCalendarLayerRenderer extends Lifecycle implements ILayerRenderer<WeekendCalendarLayerRendererRequest> {
  public static Identifier = "WeekendCalendarLayerRenderer";

  private _calendar: ICalendar<WeekendCalendarActivity> | undefined;
  private _refs: ActivityRef<any>[] = [];

  constructor(
    @inject(Row<any, any, any>) private _row: Row<any, any, any>,
    @inject(TimelineManager) private _timelineManager: TimelineManager,
    @inject(IocSymbols.Calendars) private _calendars: ICalendar<any>[],
    @inject(IocSymbols.ActivityRenderer) @tagged(IocSymbols.ActivityRendererTag, WeekendCalendarActivity.name) private _renderer: WeekendCalendarActivityRenderer
  ) {
    super();
    this._calendar = this._calendars.find((x) => x.id == "weekend-calendar");
  }

  render(canvas: OffscreenCanvas | HTMLCanvasElement, context: OffscreenCanvasRenderingContext2D | CanvasRenderingContext2D, params: WeekendCalendarLayerRendererRequest): void {
    if (!this._calendar) return;

    const width = canvas instanceof HTMLCanvasElement ? canvas.clientWidth : canvas.width;
    const height = canvas instanceof HTMLCanvasElement ? canvas.clientHeight : canvas.height;
    const startTime = this._timelineManager.startTime;
    const endTime = this._timelineManager.endTime;
    const zoneId = this._timelineManager.zoneId;
    const temporalUnit = this._timelineManager.primaryTemporalUnit;

    const activities = this._calendar.getActivities(null!, startTime, endTime, temporalUnit, zoneId) as Array<IActivity>;

    context.clearRect(0, 0, width, height);
    const layer = { id: WeekendCalendarLayerRenderer.Identifier } as Layer;

    for (const activity of activities) {
      const id = ActivityRef.generateId(this._row, layer, activity);

      let ref = this._refs.find(r => r.id === id);
      if (!ref) {
        ref = new ActivityRef(this._row, layer, activity);
        this._refs.push(ref);
      }

      const x1 = this._timelineManager.calculateLocationForTime(activity.startTime);
      const x2 = this._timelineManager.calculateLocationForTime(activity.endTime);
      if (this._renderer && this._renderer.enabled) {
        const offsetTop = params.offsetTop;
        this._renderer.draw(ref, ActivityPosition.ONLY, context, x1, 0, x2 - x1, height, offsetTop, false, false, false, false);
      }
    }
    this._refs.splice(0, this._refs.length, ...this._refs.filter((ref) => activities.includes(ref.activity)));

  }
}
