export enum OperatingSystem {
  Other = "other",
  Mac = "mac",
  Windows = "windows"
}

export function getOS(): OperatingSystem {
  const userAgentData = (navigator as any).userAgentData || {}; // https://developer.mozilla.org/en-US/docs/Web/API/Navigator/userAgentData (experimental)
  const platform = (userAgentData.platform || navigator.userAgent)?.toLowerCase();
  if (platform.includes("mac")) {
    return OperatingSystem.Mac;
  } else if (platform.includes("win")) {
    return OperatingSystem.Windows;
  }
  return OperatingSystem.Other;
}
