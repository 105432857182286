import { inject, injectable } from "inversify";
import { GanttDomElement } from "../GanttDomElement";
import { fromEvent, merge } from "rxjs";
import {
  createGraphicsDragEvent,
  createGraphicsMousePositionEvent,
  createGraphicsMouseWheelEvent,
  createMouseBtnPressedEvent,
  GanttEvents,
  IocSymbols,
  Lifecycle,
  TimelineManager
} from "../../Core";
import { DragState } from "../DragState";

@injectable()
export class ViewportPointerEventNoneScrollGlass extends GanttDomElement<HTMLDivElement> {
  private _dragState: DragState = { start: 0, end: 0, dragging: false, dragDeltaDifference: 0, previousDragDelta: 0 };
  private lastX: number = 0;
  private lastY: number = 0;

  constructor(
    @inject(GanttEvents) private _ganttEvents: GanttEvents,
    @inject(TimelineManager) private _timelineManager: TimelineManager,
    @inject(IocSymbols.NavigationScheme) private _navigationScheme: Lifecycle
  ) {
    super(ViewportPointerEventNoneScrollGlass.name, undefined, "viewport-pointer-event-none-scroll-glass");
  }

  async afterInitialize(): Promise<void> {
    this.subscribe(fromEvent<MouseEvent>(this.element, "click").subscribe(this.onMouseClick.bind(this)));
    this.subscribe(fromEvent<MouseEvent>(this.element, "mousemove").subscribe(this.onMouseMove.bind(this)));
    this.subscribe(fromEvent<MouseEvent>(this.element, "mouseenter").subscribe(this.onMouseEnter.bind(this)));
    this.subscribe(fromEvent<MouseEvent>(this.element, "mouseleave").subscribe(this.onMouseLeave.bind(this)));
    this.subscribe(fromEvent<PointerEvent>(this.element, "contextmenu").subscribe(this.onContextMenu.bind(this)));
    const pointerDown$ = fromEvent<PointerEvent>(this.element, "pointerdown");
    const pointerUp$ = fromEvent<PointerEvent>(this.element, "pointerup");

    this.subscribe(
      merge(pointerDown$, pointerUp$).subscribe(this.onMousePressed.bind(this))
    );
    this.subscribe(
      fromEvent<WheelEvent>(this.element, "wheel", { passive: false }) /* .pipe(filter((x) => x.ctrlKey || x.shiftKey)) */
        .subscribe((e: WheelEvent) => {
          this._ganttEvents.onMouseWheelEvent(createGraphicsMouseWheelEvent(e));
          e.preventDefault();
        })
    );

    this.initializePointerEvents();
  }

  private onMouseClick(e: MouseEvent) {
    this._ganttEvents.onMouseClickEvent(createGraphicsMousePositionEvent(e, this.element));
  }

  private onMouseMove(e: MouseEvent) {
    this._ganttEvents.onMousePositionEvent(createGraphicsMousePositionEvent(e, this.element));
  }

  private onMouseEnter(e: MouseEvent) {
    this._ganttEvents.onMouseEnterEvent(createGraphicsMousePositionEvent(e, this.element));
  }

  private onMouseLeave(e: MouseEvent) {
    this._ganttEvents.onMouseLeaveEvent(createGraphicsMousePositionEvent(e, this.element));
  }

  private onMousePressed(e: PointerEvent) {
    this._ganttEvents.onMousePressedEvent(createMouseBtnPressedEvent(e, this.element));
  }

  private onContextMenu(e: PointerEvent) {
    this._ganttEvents.onContextMenuEvent(createMouseBtnPressedEvent(e, this.element));
  }

  private initializePointerEvents() {
    this.subscribe(fromEvent<PointerEvent>(this.element, "pointerdown").subscribe(this.onPanStart.bind(this)));
    this.subscribe(fromEvent<PointerEvent>(this.element, "pointermove").subscribe(this.onPanMove.bind(this)));
    this.subscribe(fromEvent<PointerEvent>(this.element, "pointerup").subscribe(this.onPanEnd.bind(this)));
    this.subscribe(fromEvent<PointerEvent>(this.element, "pointercancel").subscribe(this.onPanCancel.bind(this)));
  }

  private onPanStart(event: PointerEvent) {
    this.lastX = event.clientX;
    this.lastY = event.clientY;

    this._dragState.dragDeltaDifference = 0;
    this._dragState.previousDragDelta = 0;

    this._dragState.start = this._timelineManager.startTime.toEpochMilli();
    this._dragState.end = this._timelineManager.startTime.toEpochMilli();

    this._dragState.dragging = true;

    this._ganttEvents.onPanStartEvent(createGraphicsDragEvent(event, this.lastX, this.lastY));

    this.element.setPointerCapture(event.pointerId);
  }

  private onPanMove(event: PointerEvent) {
    if (!this._dragState.dragging) return;

    const deltaX = event.clientX - this.lastX;
    const deltaY = event.clientY - this.lastY;

    this._ganttEvents.onPanMoveEvent(createGraphicsDragEvent(event, deltaX, deltaY));
  }

  private onPanEnd(event: PointerEvent) {
    this._dragState.dragging = false;

    const deltaX = event.clientX - this.lastX;
    const deltaY = event.clientY - this.lastY;

    this._ganttEvents.onPanEndEvent(createGraphicsDragEvent(event, deltaX, deltaY));

    this.element.releasePointerCapture(event.pointerId);
  }

  private onPanCancel(event: PointerEvent) {
    this._dragState.dragging = false;

    this.element.releasePointerCapture(event.pointerId);
  }
}
