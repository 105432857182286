import {
  ModelInstanceStatus,
  PlanningStatus,
  ProductionTaskDto,
  ResourceDto,
  ResourceInfoDto,
  ResourceType,
  StepDto,
  StepResourceCapacityDto,
  StepResourceSpecDto,
  TemplateStatus
} from "@masta/generated-model";
import { nameOrBusinessIdOrIdOrNull } from "@/components/ValueCellEditor/CommonFormatters";
import { translateMeasurementUnit } from "@/composables/translateEnum";
import { Router } from "vue-router";
import { useAuthStore } from "@/store/AuthStore";
import { useScenariosStore } from "@/store/ScenariosStore";

export function getStatusBasedCellStyle($appContext: any, status: number) {
  const appContext = $appContext.value;
  if (isNaN(status) || !(appContext && appContext.statusSettings)) return null;
  return {
    color: appContext.statusSettings.textColors.find((x: any) => x.status === status)?.color,
    "background-color": appContext.statusSettings.backgroundColors.find((x: any) => x.status === status)?.color
  };
}

export function getProductionTaskPlanningStatusCellStyle(source: PlanningStatus) {
  switch (source) {
    case PlanningStatus.Planned:
      return {
        color: "#000000",
        "background-color": "#C8E6C9"
      };
    case PlanningStatus.Quotation:
      return {
        color: "#000000",
        "background-color": "#FFF9C4"
      };
    case PlanningStatus.Cancelled:
      return {
        color: "#000000",
        "background-color": "#FFCDD2"
      };
    default:
      return null;
  }
}

export function getResourceCapacitySourceCellStyle(source: string) {
  if (source === "Scheduling") {
    return {
      color: "#434343",
      "background-color": "#DEDAF2"
    };
  }
  if (source === "Execution") {
    return {
      color: "#140853",
      "background-color": "#A8E8FF"
    };
  }
  return null;
}

export enum StatusStyleVariant {
  Default,
  Faded,
  Error
}

export function getProductTemplateStatusCellStyle(status: TemplateStatus, variant: StatusStyleVariant = StatusStyleVariant.Default) {
  if (variant === StatusStyleVariant.Error) {
    return {
      color: "rgba(255,255,255,0.87)",
      "background-color": "rgb(215, 58, 112, 0.7)"
    };
  } else if (variant === StatusStyleVariant.Faded) {
    switch (status) {
      case TemplateStatus.WorkInProgress:
        return {
          color: "rgba(30,30,30,0.5)",
          "background-color": "rgba(168,232,255,0.5)"
        };
      case TemplateStatus.Released:
        return {
          color: "rgba(255,255,255,0.5)",
          "background-color": "rgba(76,175,80,0.5)"
        };
      case TemplateStatus.Archived:
        return {
          color: "rgba(30,30,30,0.5)",
          "background-color": "rgba(255,214,0,1)"
        };
    }
  } else {
    // Default
    switch (status) {
      case TemplateStatus.WorkInProgress:
        return {
          color: "rgba(30,30,30,0.95)",
          "background-color": "rgba(168,232,255,0.7)"
        };
      case TemplateStatus.Released:
        return {
          color: "rgba(255,255,255,0.95)",
          "background-color": "rgba(76,175,80,0.7)"
        };
      case TemplateStatus.Archived:
        return {
          color: "rgba(30,30,30,0.95)",
          "background-color": "rgba(222,218,242,0.7)"
        };
    }
  }
}

export function getModelInstanceStatusCellStyle(status: ModelInstanceStatus, variant: StatusStyleVariant = StatusStyleVariant.Default) {
  if (variant === StatusStyleVariant.Error) {
    return {
      color: "rgba(255,255,255,0.7)",
      "background-color": "rgb(215, 58, 112, 0.7)"
    };
  } else if (variant === StatusStyleVariant.Faded) {
    switch (status) {
      case ModelInstanceStatus.WorkInProgress:
        return {
          color: "rgba(30,30,30,0.5)",
          "background-color": "rgba(168,232,255,0.5)"
        };
      case ModelInstanceStatus.Released:
        return {
          color: "rgba(255,255,255,0.5)",
          "background-color": "rgba(76,175,80,0.5)"
        };
      case ModelInstanceStatus.Archived:
        return {
          color: "rgba(30,30,30,0.5)",
          "background-color": "rgba(0,0,80,0.5)"
        };
    }
  } else {
    // Default
    switch (status) {
      case ModelInstanceStatus.WorkInProgress:
        return {
          color: "rgba(30,30,30,0.95)",
          "background-color": "rgba(168,232,255,0.7)"
        };
      case ModelInstanceStatus.Released:
        return {
          color: "rgba(255,255,255,0.95)",
          "background-color": "rgba(76,175,80,0.7)"
        };
      case ModelInstanceStatus.Archived:
        return {
          color: "rgba(30,30,30,0.95)",
          "background-color": "rgba(222,218,242,0.7)"
        };
    }
  }
}

export function getProductTemplateGraphNodeStyleByStatus(status: TemplateStatus) {
  switch (status) {
    default:
      return {};
  }
}

export function asResourceRepresentation(resource: ResourceDto | null | undefined): string | null {
  return nameOrBusinessIdOrIdOrNull(resource);
}

export function asResourceInfoRepresentation(resourceInfo: ResourceInfoDto | null | undefined): string | null {
  return nameOrBusinessIdOrIdOrNull(resourceInfo);
}

export function asResourcesRepresentation(resources: ResourceDto[] | ResourceDto): string | null {
  if (!resources) {
    return null;
  }
  if (!Array.isArray(resources)) {
    return asResourceRepresentation(resources);
  }
  return resources
    .map(asResourceRepresentation)
    .filter((x) => !!x)
    .join(", ");
}

export function asResourceInfosRepresentation(resourceInfos: ResourceInfoDto[] | ResourceInfoDto): string | null {
  if (!resourceInfos) {
    return null;
  }
  if (!Array.isArray(resourceInfos)) {
    return asResourceInfoRepresentation(resourceInfos);
  }
  return resourceInfos
    .map(asResourceInfoRepresentation)
    .filter((x) => !!x)
    .join(", ");
}

export function asResourcesMeasurementUnits(resources: ResourceDto[] | ResourceDto | null | undefined): string | null {
  if (!resources) {
    return null;
  }
  if (!Array.isArray(resources)) {
    return asResourceMeasurementUnit(resources);
  }
  return resources
    .map(asResourceMeasurementUnit)
    .filter((x) => !!x)
    .join(", ");
}

export function asResourceMeasurementUnit(resource: ResourceDto | null | undefined): string | null {
  if (!resource) {
    return null;
  }
  return translateMeasurementUnit(resource.measurementUnit);
}

export function asResourceType(resources: ResourceDto[] | ResourceDto): ResourceType | undefined {
  if (Array.isArray(resources)) {
    return resources
      ?.filter((x) => !!x)
      .map((x) => x.type)
      .find((_) => true);
  } else if (resources) {
    return (resources as ResourceDto).type;
  }
  return;
}

export function asResourceTypeFromResourceInfos(resourceInfos: ResourceInfoDto[] | ResourceInfoDto): ResourceType | undefined {
  if (Array.isArray(resourceInfos)) {
    return resourceInfos
      ?.filter((x) => !!x)
      .map((x) => x.type)
      .find((_) => true);
  } else if (resourceInfos) {
    return (resourceInfos as ResourceInfoDto).type;
  }
  return;
}

export async function openResourceGanttForStepsSpecification(router: Router, steps: StepDto[]) {
  const resourcesByType = steps.reduce((acc: Map<ResourceType, Set<string>>, step) => {
    step.resources?.forEach((s) => getResourcesFromSpec(s, acc));
    return acc;
  }, new Map<ResourceType, Set<string>>());
  await openResourceGantt(router, resourcesByType);
}

function getResourcesFromSpec(spec: StepResourceSpecDto, map: Map<ResourceType, Set<string>>) {
  spec.resources?.forEach((x) => updateMap(map, spec.resourceType, x.id));
}

function updateMap(map: Map<ResourceType, Set<string>>, resourceType: ResourceType, resourceId: string) {
  if (map.has(resourceType)) {
    map.get(resourceType)?.add(resourceId);
  } else {
    const set = new Set<string>([resourceId]);
    map.set(resourceType, set);
  }
}

export async function openTaskGantt(router: Router, selectedTasks: ProductionTaskDto[] | undefined) {
  const tasks = (selectedTasks ?? []).map((x) => x.id).join(",");
  await router.push({
    name: "TasksGantt",
    params: {
      scenarioId: useScenariosStore().scenarioBusinessId
    },
    query: {
      tasks
      // types,
      // resources
    }
  });
}

export async function openResourceGanttForStepsCapacities(router: Router, steps: StepDto[]) {
  const resourcesByType = steps.reduce((acc: Map<ResourceType, Set<string>>, step) => {
    step.capacities?.forEach((c) => getResourcesFromCapacities(c, acc));
    return acc;
  }, new Map<ResourceType, Set<string>>());
  await openResourceGantt(router, resourcesByType);
}

function getResourcesFromCapacities(cap: StepResourceCapacityDto, map: Map<ResourceType, Set<string>>) {
  updateMap(map, cap.resourceType, cap.resourceId);
}

async function openResourceGantt(router: Router, resourcesByType: Map<ResourceType, Set<string>>) {
  const types = Array.from(resourcesByType.keys());
  const resources = Array.from(resourcesByType.values())
    .flatMap((x) => Array.from(x))
    .join(",");
  await router.push({
    name: "ResourcesGantt",
    params: {
      scenarioId: useScenariosStore().scenarioBusinessId
    },
    query: {
      types,
      resources
    }
  });
}
