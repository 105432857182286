import { Duration, Instant } from "@js-joda/core";
import { v4 as uuid } from "uuid";

import { IActivity } from "../IActivity";
import { IActivityObject } from "../IActivityObject";
import { BehaviorSubject, map, Observable } from "rxjs";

export type ActivityDeserializer<TActivityObject extends IActivityObject, TActivityClass extends IActivity> = (
  json: TActivityObject,
  activityClass: new (...params: any[]) => TActivityClass
) => TActivityClass;

export abstract class ActivityBase<TActivity> implements IActivity {
  public static readonly DEFAULT_DURATION: Duration = Duration.ofDays(1);

  protected _id: string;

  protected _name: string;

  protected _startTime: Instant;

  protected _endTime: Instant;

  protected _userObject: TActivity;

  protected _enabled$$ = new BehaviorSubject(true);

  protected _selectable$$ = new BehaviorSubject(true);

  protected _draggable$$ = new BehaviorSubject(false);

  private _lockSources$$ = new BehaviorSubject<string[]>([]);

  constructor(id: string | undefined, name: string, startTime: Instant = Instant.now(), endTime: Instant = Instant.now().plus(ActivityBase.DEFAULT_DURATION)) {
    this._id = id ?? uuid();
    this._name = name;
    this._startTime = startTime;
    this._endTime = endTime;
  }

  get hashCode(): number {
    return this._id.split("").reduce((a, b) => {
      a = (a << 5) - a + b.charCodeAt(0);
      return a & a;
    }, 0);
  }

  get id(): string {
    return this._id;
  }

  get name(): string {
    return this._name;
  }

  get startTime(): Instant {
    return this._startTime;
  }

  get endTime(): Instant {
    return this._endTime;
  }

  get userObject(): TActivity {
    return this._userObject;
  }

  set userObject(value: TActivity) {
    this._userObject = value;
  }

  get enabled(): boolean {
    return this._enabled$$.value;
  }

  set enabled(value: boolean) {
    this._enabled$$.next(value);
  }

  get enabled$$() {
    return this._enabled$$;
  }

  get selectable(): boolean {
    return this._selectable$$.value;
  }

  set selectable(value: boolean) {
    this._selectable$$.next(value);
  }

  get selectable$$() {
    return this._selectable$$;
  }

  get draggable(): boolean {
    return this._draggable$$.value;
  }

  set draggable(value: boolean) {
    this._draggable$$.next(value);
  }

  get draggable$$() {
    return this._draggable$$;
  }

  lock(source: string): void {
    this._lockSources$$.next([...this._lockSources$$.value.filter(x => x !== source), source]);
  }

  releaseLock(source: string): void {
    this._lockSources$$.next(this._lockSources$$.value.filter(x => x !== source));
  }

  unlock(): void {
    this._lockSources$$.next([]);
  }

  get locked$(): Observable<boolean> {
    return this._lockSources$$.pipe(map(x => !!x.length));
  }

  get locked(): boolean {
    return !!this._lockSources$$.value.length;
  }

  isLockedBy(source: string): boolean {
    return this._lockSources$$.value.includes(source);
  }

  toString(): string {
    return `[${this._id}] ${this._name} from ${this._startTime} to ${this._endTime}`;
  }

  toJSON<TActivityObject extends IActivityObject>(): TActivityObject {
    return {
      id: this._id,
      name: this._name,
      startTime: this._startTime.toEpochMilli(),
      endTime: this._endTime.toEpochMilli()
    } as TActivityObject;
  }

  static fromJSON<TActivityObject extends IActivityObject, TActivityClass extends IActivity>(
    json: any,
    activityClass: new (...params: any[]) => TActivityClass,
    serializer: ActivityDeserializer<TActivityObject, TActivityClass> = defaultDeserializer
  ): TActivityClass {
    return serializer(json, activityClass);
  }
}

function defaultDeserializer<TActivityObject extends IActivityObject, TActivityClass extends IActivity>(
  json: any,
  activityClass: new (...params: any[]) => TActivityClass
): TActivityClass {
  const act = new activityClass(json.name, Instant.ofEpochMilli(json.startTime), Instant.ofEpochMilli(json.endTime), json.id);
  console.log(activityClass.name, json, act);
  return act;
}
