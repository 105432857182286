<script setup lang="ts">
import { $t } from "@/i18n";

const modelValue = defineModel<boolean>({ required: true });
const props = defineProps<{
  title: string;
  description: string;
}>();
const emit = defineEmits<{
  (e: "dialog-result", result: boolean): void;
}>();

function onNo() {
  close();
  emit("dialog-result", false);
}

function onYes() {
  close();
  emit("dialog-result", true);
}

function close() {
  modelValue.value = false;
}
</script>

<template>
  <v-dialog v-model="modelValue" width="auto" :persistent="true">
    <v-card class="pa-8">
      <v-card-title class="d-flex align-center justify-space-between">
        <div class="text-h4">{{ props.title }}</div>
        <v-icon @click="onNo">mdi-close</v-icon>
      </v-card-title>
      <v-card-text>
        <div>{{ props.description }}</div>
      </v-card-text>
      <v-card-actions>
        <v-row>
          <v-col cols="6">
            <v-btn class="ma-4" variant="elevated" color="secondary" block @click="onNo">
              {{ $t("dialogs-yesNoDialog-no-action", { $: "No" }) }}
            </v-btn>
          </v-col>
          <v-col cols="6">
            <v-btn class="ma-4" variant="elevated" color="primary" block @click="onYes">
              {{ $t("dialogs-yesNoDialog-yes-action", { $: "Yes" }) }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
