import { inject, injectable } from "inversify";
import { GanttDomElement } from "../GanttDomElement";
import { Eventline } from "./Eventline";

@injectable()
export class EventlineContainer extends GanttDomElement<HTMLDivElement> {
  constructor(@inject(Eventline) private _eventline: Eventline) {
    super(EventlineContainer.name, undefined, "eventline-container");
  }

  async beforeInitialize(): Promise<void> {
    await this.addChild(this._eventline);
  }
}
