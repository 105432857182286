import { ApiClient } from "@/services/api/common.api";
import { AvailableSettingKeysDto, CreateOrUpdateSettingCommand, GetAllSettingsDto, UpdateSettingCommand } from "@masta/generated-model";

export default {
  async getSettings() {
    return await ApiClient.get<GetAllSettingsDto>("/settings");
  },
  async createSetting(request: CreateOrUpdateSettingCommand) {
    return ApiClient.post("/settings", request);
  },
  async updateSetting(request: UpdateSettingCommand) {
    return ApiClient.put(`/settings/${request.id}`, request);
  },
  async deleteSetting(id: string) {
    return ApiClient.delete(`/settings/${id}`);
  },
  async getSettingsKeys() {
    return await ApiClient.get<AvailableSettingKeysDto>("/settings/keys");
  }
};
