import { defineStore } from "pinia";

interface State {
  documentObjectUrls: { [key: string]: string };
}

/**
 * This store keep a history of links to objects created using `URL.createObjectURL`.
 * The links refer to documents (images) downloaded from the server as a `Blob` and linked to the current HTML documneut.
 *
 * In particular, this is used in the markdown editor. Links there are inserted under the form
 * `dockumentId&revisionNumber`, and the actual link to the image resolved editor reads them from this store.
 */
export const useDocumentObjectUrlsStore = defineStore("documentObjectUrls", {
  state: (): State => ({
    documentObjectUrls: {}
  }),
  actions: {
    has(documentId: string, revisionNumber: number): boolean {
      const result = this.documentObjectUrls[`${documentId}&${revisionNumber}`];
      return result !== undefined;
    },

    add(documentId: string, revisionNumber: number, documentData: Blob) {
      // first delete if exists old one
      if (this.has(documentId, revisionNumber)) {
        this.delete(documentId, revisionNumber);
      }

      const objectUrl = URL.createObjectURL(documentData);
      this.documentObjectUrls[`${documentId}&${revisionNumber}`] = objectUrl;

      return objectUrl;
    },
    delete(documentId: string, revisionNumber: number) {
      const objectUrl = this.documentObjectUrls[`${documentId}&${revisionNumber}`];

      delete this.documentObjectUrls[`${documentId}&${revisionNumber}`];

      if (objectUrl) {
        URL.revokeObjectURL(objectUrl);
      }
    }
  },
  getters: {
    get: (state) => (documentId: string, revisionNumber: number) => {
      return state.documentObjectUrls[`${documentId}&${revisionNumber}`];
    }
  }
});

