<script setup lang="ts">
import { $t } from "@/i18n";
import {
  AgGridFilterModel,
  IResourceDto,
  MeasurementUnit,
  ResourceCapacityGroup,
  ResourceCapacityChangeType,
  ResourceCapacityEntrySource,
  StepType,
  MaterialSubType,
  ResourceSubType
} from "@masta/generated-model";
import { inject, reactive, ref, watch } from "vue";
import { ResourceCapacityServerSideDataSource } from "@/components/ResourceCapacities/ResourceCapacityServerSideDataSource";
import { GridWrapperComponent } from "@/components/Grid/GridWrapperComponent";
import { requiredRule } from "@/components/ValueCellEditor/CommonValidationRules";
import { KeyCreatorParams, ValueFormatterParams } from "ag-grid-community";
import { enumToEditorEntries, enumValueEntryWithLocaleComparator, translateEditorEntries } from "@/components/Grid/ColumnTypes";
import {
  translateMeasurementUnit,
  translateResourceCapacityChangeType,
  translateResourceCapacityEntrySource,
  translateResourceCapacityGroup,
  translateStepType
} from "@/composables/translateEnum";
import { $dateTimeFormatterSymbol, DateFormatter } from "@masta/shared";
import GridWrapper from "@/components/Grid/GridWrapper.vue";
import ResourceCapacityCreatorGridAction from "@/components/Resources/ResourceCapacityCreatorGridAction.vue";
import FilterGridAction, { FilterGridActionItem } from "@/components/Grid/Filters/FilterGridAction.vue";
import { useFilterGridAction } from "@/components/Grid/Filters/UseFilterGridAction";

interface Props {
  referenceValue?: string;
  cqlFilter?: () => string | null | undefined;
  agGridFilterModel?: () => AgGridFilterModel | null | undefined | any;
  resource: IResourceDto;
}

const props = defineProps<Props>();
const $emits = defineEmits(["rowSelected"]);

const serverSideDataSource = reactive(new ResourceCapacityServerSideDataSource("resourceCapacity"));
const gridWrapperRef = ref<GridWrapperComponent>();
const filterGridActionRef = ref<typeof FilterGridAction>();
const $dateTimeFormatter = inject<DateFormatter>($dateTimeFormatterSymbol)!;

const defaultColumnDef = ref({
  floatingFilter: true,
  filterParams: {
    applyMiniFilterWhileTyping: true
  },
  sortable: true,
  resizable: true
});

watch(
  () => props.resource,
  (newResource) => {
    if (newResource) {
      serverSideDataSource.useFilteringByResourceId(newResource.id);
      gridWrapperRef.value?.gridApi?.refreshServerSide();
    }
  },
  {
    immediate: true
  }
);

function onPrepareColumns(columnDefs: any) {
  columnDefs.value = [
    {
      field: "id",
      editable: false,
      sortable: false,
      filter: false,
      hide: true
    },
    {
      field: "resourceId",
      editable: false,
      sortable: false,
      filter: false,
      hide: true
    },
    {
      field: "capacityGroup",
      headerValueGetter: (_: any) => $t("resourceCapacity-list-capacityGroup-label", { $: "Group" }),
      editable: false,
      sortable: true,
      filter: "agSetColumnFilter",
      filterParams: {
        valueFormatter: (params: ValueFormatterParams) => params.value.key,
        keyCreator: (params: KeyCreatorParams) => params.value.value,
        values: translateEditorEntries(enumToEditorEntries(ResourceCapacityGroup), translateResourceCapacityGroup),
        comparator: enumValueEntryWithLocaleComparator
      },
      valueFormatter: (params: any) => translateResourceCapacityGroup(params.value)
    },
    {
      field: "changeType",
      headerValueGetter: (_: any) => $t("resourceCapacity-list-changeType-label", { $: "Change" }),
      editable: false,
      sortable: true,
      filter: "agSetColumnFilter",
      filterParams: {
        valueFormatter: (params: ValueFormatterParams) => params.value.key,
        keyCreator: (params: KeyCreatorParams) => params.value.value,
        values: translateEditorEntries(enumToEditorEntries(ResourceCapacityChangeType), translateResourceCapacityChangeType),
        comparator: enumValueEntryWithLocaleComparator
      },
      valueFormatter: (params: any) => translateResourceCapacityChangeType(params.value)
    },
    {
      field: "entrySource",
      headerValueGetter: (_: any) => $t("resourceCapacity-list-entrySource-label", { $: "Source" }),
      editable: false,
      sortable: true,
      filter: "agSetColumnFilter",
      filterParams: {
        valueFormatter: (params: ValueFormatterParams) => params.value.key,
        keyCreator: (params: KeyCreatorParams) => params.value.value,
        values: translateEditorEntries(enumToEditorEntries(ResourceCapacityEntrySource), translateResourceCapacityEntrySource),
        comparator: enumValueEntryWithLocaleComparator
      },
      valueFormatter: (params: any) => translateResourceCapacityEntrySource(params.value)
    },
    {
      field: "quantity",
      editable: false,
      sortable: true,
      filter: "agNumberColumnFilter",
      resizable: true,
      type: ["textInputTypeColumn", "textFloatingFilterColumnType"],
      cellEditorParams: {
        rules: [requiredRule],
        placeholder: $t("resourceCapacity-list-quantity-label", { $: "Quantity" })
      },
      headerValueGetter: (_: any) => $t("resourceCapacity-list-quantity-label", { $: "Quantity" })
    },
    {
      field: "quantityUnit",
      editable: false,
      sortable: true,
      resizable: true,
      type: ["enumTypeColumn", "setFloatingFilterColumnType"],
      cellEditorParams: {
        values: translateEditorEntries(enumToEditorEntries(MeasurementUnit), translateMeasurementUnit),
        placeholder: $t("resourceCapacity-list-quantityUnit-label", { $: "Quantity Unit" })
      },
      valueFormatter: (params: any) => (params.data ? translateMeasurementUnit(params.data.quantityUnit) : null),
      headerValueGetter: (_: any) => $t("resourceCapacity-list-quantityUnit-label", { $: "Quantity Unit" }),
      filter: "agSetColumnFilter",
      filterParams: {
        valueFormatter: (params: ValueFormatterParams) => params.value.key,
        keyCreator: (params: KeyCreatorParams) => params.value.value,
        values: translateEditorEntries(enumToEditorEntries(MeasurementUnit), translateMeasurementUnit),
        comparator: enumValueEntryWithLocaleComparator
      }
    },
    {
      field: "periodStart",
      type: "datepickerTypeColumn",
      headerValueGetter: (_: any) => $t("resourceCapacity-list-periodStart-label", { $: "Start" }),
      editable: false,
      resizable: true,
      sortable: true,
      filter: "agDateColumnFilter",
      valueFormatter: (params: any) => {
        return $dateTimeFormatter(params.data.periodStart);
      }
    },
    {
      field: "periodEnd",
      type: "datepickerTypeColumn",
      headerValueGetter: (_: any) => $t("resourceCapacity-list-periodEnd-label", { $: "End" }),
      editable: false,
      resizable: true,
      sortable: true,
      filter: "agDateColumnFilter",
      valueFormatter: (params: any) => {
        return $dateTimeFormatter(params.data.periodEnd);
      }
    },
    {
      field: "trackingUniqueIdentifier",
      type: ["textInputTypeColumn", "textFloatingFilterColumnType"],
      editable: false,
      sortable: true,
      filter: "agTextColumnFilter",
      headerValueGetter: (_: any) => $t("resourceCapacity-list-trackingUniqueIdentifier-label", { $: "Tracking Unique Identifier" }),
      floatingFilterComponentParams: {
        placeholder: $t("resourceCapacity-list-trackingUniqueIdentifier-label", { $: "Tracking Unique Identifier" })
      }
    },
    {
      field: "contextResourceBusinessId",
      type: ["textInputTypeColumn", "textFloatingFilterColumnType"],
      editable: false,
      sortable: true,
      filter: "agTextColumnFilter",
      headerValueGetter: (_: any) => $t("resourceCapacity-list-contextResourceBusinessId-label", { $: "Context Resource BusinessId" }),
      floatingFilterComponentParams: {
        placeholder: $t("resourceCapacity-list-contextResourceBusinessId-label", { $: "Context Resource BusinessId" })
      }
    },
    {
      field: "taskName",
      type: ["textInputTypeColumn", "textFloatingFilterColumnType"],
      editable: false,
      sortable: true,
      filter: "agTextColumnFilter",
      headerValueGetter: (_: any) => $t("resourceCapacity-list-taskName-label", { $: "Task Name" }),
      floatingFilterComponentParams: {
        placeholder: $t("resourceCapacity-list-taskName-label", { $: "Task Name" })
      }
    },
    {
      field: "taskBusinessId",
      type: ["textInputTypeColumn", "textFloatingFilterColumnType"],
      editable: false,
      sortable: true,
      filter: "agTextColumnFilter",
      headerValueGetter: (_: any) => $t("resourceCapacity-list-taskBusinessId-label", { $: "Task BusinessId" }),
      floatingFilterComponentParams: {
        placeholder: $t("resourceCapacity-list-taskBusinessId-label", { $: "Task BusinessId" })
      }
    },
    {
      field: "taskName",
      type: ["textInputTypeColumn", "textFloatingFilterColumnType"],
      editable: false,
      sortable: true,
      filter: "agTextColumnFilter",
      headerValueGetter: (_: any) => $t("resourceCapacity-list-taskName-label", { $: "Task Name" }),
      floatingFilterComponentParams: {
        placeholder: $t("resourceCapacity-list-taskName-label", { $: "Task Name" })
      }
    },
    {
      field: "stepPosition",
      resizable: true,
      type: ["textInputTypeColumn", "textFloatingFilterColumnType"],
      editable: false,
      sortable: true,
      filter: "agNumberColumnFilter",
      headerValueGetter: (_: any) => $t("resourceCapacity-list-stepPosition-label", { $: "Step Position" }),
      floatingFilterComponentParams: {
        placeholder: $t("resourceCapacity-list-stepPosition-label", { $: "Step Position" })
      }
    },
    {
      field: "stepName",
      type: ["textInputTypeColumn", "textFloatingFilterColumnType"],
      editable: false,
      sortable: true,
      filter: "agTextColumnFilter",
      headerValueGetter: (_: any) => $t("resourceCapacity-list-stepName-label", { $: "Step Name" }),
      floatingFilterComponentParams: {
        placeholder: $t("resourceCapacity-list-stepName-label", { $: "Step Name" })
      }
    },
    {
      field: "stepType",
      type: ["enumTypeColumn", "setFloatingFilterColumnType"],
      editable: false,
      sortable: true,
      valueFormatter: (params: any) => (params.data ? translateStepType(params.data.stepType) : null),
      headerValueGetter: (_: any) => $t("resourceCapacity-list-stepType-label", { $: "Step Type" }),
      filter: "agSetColumnFilter",
      filterParams: {
        valueFormatter: (params: ValueFormatterParams) => params.value.key,
        keyCreator: (params: KeyCreatorParams) => params.value.value,
        values: translateEditorEntries(enumToEditorEntries(StepType), translateStepType),
        comparator: enumValueEntryWithLocaleComparator
      }
    }
  ];
}

function onResourceCapacityCreated() {
  gridWrapperRef.value?.gridApi?.refreshServerSide();
}

const filterGridAction = useFilterGridAction({ filterKey: "entrySource", gridWrapperRef: gridWrapperRef, filterGridActionRef: filterGridActionRef });

const filterGridActionItems: FilterGridActionItem[] = [
  { value: ResourceCapacityEntrySource.Calendar, text: translateResourceCapacityEntrySource(ResourceCapacityEntrySource.Calendar) },
  { value: ResourceCapacityEntrySource.AvailabilityRule, text: translateResourceCapacityEntrySource(ResourceCapacityEntrySource.AvailabilityRule) },
  { value: ResourceCapacityEntrySource.PlanningBoard, text: translateResourceCapacityEntrySource(ResourceCapacityEntrySource.PlanningBoard) },
  { value: ResourceCapacityEntrySource.Shift, text: translateResourceCapacityEntrySource(ResourceCapacityEntrySource.Shift) },
  { value: ResourceCapacityEntrySource.Scheduling, text: translateResourceCapacityEntrySource(ResourceCapacityEntrySource.Scheduling) },
  { value: ResourceCapacityEntrySource.Reservation, text: translateResourceCapacityEntrySource(ResourceCapacityEntrySource.Reservation) },
  { value: ResourceCapacityEntrySource.ExecutionResource, text: translateResourceCapacityEntrySource(ResourceCapacityEntrySource.ExecutionResource) },
  { value: ResourceCapacityEntrySource.Balancing, text: translateResourceCapacityEntrySource(ResourceCapacityEntrySource.Balancing) },
  { value: ResourceCapacityEntrySource.ElectricityConsumption, text: translateResourceCapacityEntrySource(ResourceCapacityEntrySource.ElectricityConsumption) },
  { value: ResourceCapacityEntrySource.Import, text: translateResourceCapacityEntrySource(ResourceCapacityEntrySource.Import) },
  { value: ResourceCapacityEntrySource.ManualEntry, text: translateResourceCapacityEntrySource(ResourceCapacityEntrySource.ManualEntry) },
  { value: ResourceCapacityEntrySource.DefaultInitialValue, text: translateResourceCapacityEntrySource(ResourceCapacityEntrySource.DefaultInitialValue) }
];
</script>

<template>
  <grid-wrapper
    ref="gridWrapperRef"
    refresh-btn
    identifier="resourceCapacity"
    :default-col-def="defaultColumnDef"
    server-side
    :server-side-datasource="serverSideDataSource"
    @prepare-columns="onPrepareColumns"
  >
    <template #custom-buttons>
      <resource-capacity-creator-grid-action :resource="resource" @created="onResourceCapacityCreated" />
    </template>
    <template #filter>
      <filter-grid-action ref="filterGridActionRef" :items="filterGridActionItems" @filter-changed="filterGridAction.onFilterGridActionChanged" />
    </template>
  </grid-wrapper>
</template>
