<script setup lang="ts">
import Gantt from "@/components/Gantt/Gantt.vue";
import { $t } from "@/i18n";
import ResourceCapacityGrid from "@/components/ResourceCapacities/ResourceCapacityGrid.vue";
import { IResourceDto } from "@masta/generated-model";
import { nextTick, ref, watch } from "vue";
import ResourceAvailabilityRules from "@/components/AvailabilityRules/ResourceAvailabilityRules.vue";
import SplitPanel from "@/components/Layout/SplitPanel.vue";

interface ResourceCapacityDetailsProps {
  resource: IResourceDto;
}

const props = defineProps<ResourceCapacityDetailsProps>();
const defaultTab = "resourceCapacities";
const currentTab = ref(defaultTab);

const renderGanttComponent = ref(true);

const ganttReloadKey = ref(0);
watch(
  () => props.resource,
  () => {
    ganttReloadKey.value++;
  }
);

function ganttResourceFilterExpressionProvider() {
  return props.resource ? [props.resource.id] : null;
}

async function forceRerenderGantt() {
  renderGanttComponent.value = false;

  nextTick(() => {
    renderGanttComponent.value = true;
  });
}
</script>

<template>
  <v-card class="fill-height" elevation="0">
    <split-panel identifier="resource-capacity-details-split-panel-gantt" :sizes="[25, 75]" :min-size="[50, 50]">
      <template #panel-1>
        <v-row class="fill-height">
          <v-col class="fill-height align-center" cols="12">
            <gantt
              v-if="renderGanttComponent" :key="ganttReloadKey" height="100%" hide-tasks
              :filter-expression-provider="ganttResourceFilterExpressionProvider" @reload="forceRerenderGantt" />
          </v-col>
        </v-row>
      </template>
      <template #panel-2>
        <v-row class="fill-height">
          <v-col cols="12">
            <v-card class="fill-height">
              <v-tabs v-model="currentTab">
                <v-tab value="resourceCapacities">Resource Capacities</v-tab>
                <v-tab value="availabilityRules">Availability Rules</v-tab>
              </v-tabs>
              <v-card-text class="fill-height">
                <v-window v-model="currentTab" class="fill-height">
                  <v-window-item value="resourceCapacities" class="fill-height pa-5 overflow-y-auto">
                    <resource-capacity-grid :resource="resource" />
                  </v-window-item>
                  <v-window-item value="availabilityRules" class="fill-height pa-5 overflow-y-auto">
                    <resource-availability-rules :resource="resource" />
                  </v-window-item>
                </v-window>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </template>
    </split-panel>
  </v-card>
</template>
