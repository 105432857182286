import type { IRowContainer } from "../../../Core";
import { ActivityBounds, ActivityPosition, ActivityRef, ActivityRenderer, IActivity, IActivityRenderingRequest, IocSymbols, TimelineManager } from "../../../Core";
import { inject, injectable } from "inversify";

@injectable()
export class ActivityBarRenderer<TActivity extends IActivity> extends ActivityRenderer<TActivity> {
  constructor(@inject(TimelineManager) timelineManager: TimelineManager, @inject(IocSymbols.RowContainer) rowContainer: IRowContainer) {
    super(timelineManager, rowContainer, ActivityBarRenderer.name);
  }

  draw(
    activityRef: ActivityRef<TActivity>,
    position: ActivityPosition,
    rowCanvasContext: CanvasRenderingContext2D | OffscreenCanvasRenderingContext2D,
    x: number,
    y: number,
    w: number,
    h: number,
    offsetTop: number,
    selected: boolean,
    hover: boolean,
    highlighted: boolean,
    pressed: boolean
  ): ActivityBounds | undefined {
    if (w <= this._minWidth) return;

    y = y + 2;
    h = h - 4;

    return super.draw(activityRef, position, rowCanvasContext, x, y, w, h, offsetTop, selected, hover, highlighted, pressed);
  }

  drawActivity(request: IActivityRenderingRequest<TActivity>) {
    const { activityBounds, activityRef, position, canvas, context, x, y, w, h, offsetTop, selected, hover, highlighted, pressed } = request;

    context.clearRect(0, 0, context.canvas.width, context.canvas.height);

    context.globalAlpha = this.opacity;

    this.drawBackground(activityRef, position, context, x, y, w, h, offsetTop, selected, hover, highlighted, pressed);

    context.fillStyle = this.getTextColor(selected, hover, highlighted, pressed) ?? "rgba(0,0,0,1)";

    const textWidth = context.measureText(activityRef.activity.name).width;
    const textX = x < 0 ? this.paddingInsets.left : x + this.paddingInsets.left;
    if (textWidth <= w && textX + textWidth < x + w) {
      let fsize = this.getFontSize(context.font);
      if (h / 2 < fsize) {
        fsize = h / 2;
      }
      const textY = y + h / 2 + fsize / 4;
      context.font = context.font.replace(/\d+px/, `${fsize}px`);
      context.fillText(activityRef.activity.name, textX, textY);
    }
  }
}
