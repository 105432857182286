import { ChronoField, ChronoUnit, DayOfWeek, LocalTime, ZonedDateTime } from "@js-joda/core";

export class ChronoUnitUtils {
  public static truncateZonedTime(time: ZonedDateTime, unit: ChronoUnit, step: number, firstDayOfWeek: DayOfWeek): ZonedDateTime {
    let result: ZonedDateTime;
    let decade: number;
    let century: number;
    let millennium: number;

    switch (unit) {
      case ChronoUnit.DAYS:
        return this.adjustFieldZoned(time, ChronoField.DAY_OF_YEAR, step).truncatedTo(unit);
      case ChronoUnit.HALF_DAYS:
        return time.truncatedTo(unit);
      case ChronoUnit.HOURS:
        return this.adjustFieldZoned(time, ChronoField.HOUR_OF_DAY, step).truncatedTo(unit);
      case ChronoUnit.MINUTES:
        return this.adjustFieldZoned(time, ChronoField.MINUTE_OF_HOUR, step).truncatedTo(unit);
      case ChronoUnit.SECONDS:
        return this.adjustFieldZoned(time, ChronoField.SECOND_OF_MINUTE, step).truncatedTo(unit);
      case ChronoUnit.MILLIS:
        return this.adjustFieldZoned(time, ChronoField.MILLI_OF_SECOND, step).truncatedTo(unit);
      case ChronoUnit.MICROS:
        return this.adjustFieldZoned(time, ChronoField.MICRO_OF_SECOND, step).truncatedTo(unit);
      case ChronoUnit.NANOS:
        return this.adjustFieldZoned(time, ChronoField.NANO_OF_SECOND, step).truncatedTo(unit);
      case ChronoUnit.MONTHS:
        return time
          .with(ChronoField.MONTH_OF_YEAR, Math.max(1, time.get(ChronoField.MONTH_OF_YEAR) - (time.get(ChronoField.MONTH_OF_YEAR) % step)))
          .withDayOfMonth(1)
          .truncatedTo(ChronoUnit.DAYS);
      case ChronoUnit.YEARS:
        return this.adjustFieldZoned(time, ChronoField.YEAR, step).withDayOfYear(1).truncatedTo(ChronoUnit.DAYS);
      case ChronoUnit.WEEKS:
        result = time.with(ChronoField.DAY_OF_WEEK, firstDayOfWeek.value()).truncatedTo(ChronoUnit.DAYS);
        if (result.isAfter(time)) result = result.minusWeeks(1);
        return result;
      case ChronoUnit.DECADES:
        decade = (time.year() / 10) * 10;
        return time.with(ChronoField.YEAR, decade).withDayOfYear(1).truncatedTo(ChronoUnit.DAYS);
      case ChronoUnit.CENTURIES:
        century = (time.year() / 100) * 100;
        return time.with(ChronoField.YEAR, century).withDayOfYear(1).truncatedTo(ChronoUnit.DAYS);
      case ChronoUnit.MILLENNIA:
        millennium = (time.year() / 1000) * 1000;
        return time.with(ChronoField.YEAR, millennium).withDayOfYear(1).truncatedTo(ChronoUnit.DAYS);
    }
    return time;
  }

  public static truncateLocalTime(time: LocalTime, unit: ChronoUnit, step: number): LocalTime {
    // eslint-disable-next-line default-case
    switch (unit) {
      case ChronoUnit.HOURS:
        return this.adjustFieldLocal(time, ChronoField.HOUR_OF_DAY, step).truncatedTo(unit);
      case ChronoUnit.MINUTES:
        return this.adjustFieldLocal(time, ChronoField.MINUTE_OF_HOUR, step).truncatedTo(unit);
      case ChronoUnit.SECONDS:
        return this.adjustFieldLocal(time, ChronoField.SECOND_OF_MINUTE, step).truncatedTo(unit);
      case ChronoUnit.MILLIS:
        return this.adjustFieldLocal(time, ChronoField.MILLI_OF_SECOND, step).truncatedTo(unit);
      case ChronoUnit.MICROS:
        return this.adjustFieldLocal(time, ChronoField.MICRO_OF_SECOND, step).truncatedTo(unit);
      case ChronoUnit.NANOS:
        return this.adjustFieldLocal(time, ChronoField.NANO_OF_SECOND, step).truncatedTo(unit);
    }
    return time;
  }

  private static adjustFieldZoned(time: ZonedDateTime, field: ChronoField, step: number) {
    return time.with(field, time.get(field) - (time.get(field) % step));
  }

  private static adjustFieldLocal(time: LocalTime, field: ChronoField, step: number) {
    return time.with(field, time.get(field) - (time.get(field) % step));
  }
}
