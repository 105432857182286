import { BehaviorSubject, Observable, Subject } from "rxjs";
import { v4 as uuid } from "uuid";

import { GanttException } from "./Exceptions";

/** Valid id type. */
export type Id = number | string;
/** Nullable id type. */
export type OptId = undefined | null | Id;

export type PartItem<IdProp extends string> = Partial<Record<IdProp, OptId>>;

export type FullItem<Item extends PartItem<IdProp>, IdProp extends string> = Item & Record<IdProp, Id>;

export class ObservableDataSet<Item extends PartItem<IdProp>, IdProp extends string = "id"> {
  private readonly idProp: IdProp;

  public data: Map<Id, FullItem<Item, IdProp>> = new Map<Id, FullItem<Item, IdProp>>();

  private data$$: Subject<FullItem<Item, IdProp>[]>;

  private added$$: Subject<{ item: Item; senderId?: Id }>;

  private removed$$: Subject<{ item: FullItem<Item, IdProp>; senderId?: Id }>;

  private updated$$: Subject<{ item: FullItem<Item, IdProp>; senderId?: Id }>;

  private dataSetLength = 0;

  constructor(fieldId: IdProp = "id" as IdProp) {
    this.idProp = fieldId;

    this.data$$ = new BehaviorSubject<FullItem<Item, IdProp>[]>([]);
    this.added$$ = new Subject<{ item: Item; senderId?: Id }>();
    this.removed$$ = new Subject<{ item: FullItem<Item, IdProp>; senderId?: Id }>();
    this.updated$$ = new Subject<{ item: FullItem<Item, IdProp>; senderId?: Id }>();
  }

  get data$(): Observable<FullItem<Item, IdProp>[]> {
    return this.data$$.asObservable();
  }

  get added$(): Observable<{ item: Item; senderId?: Id }> {
    return this.added$$.asObservable();
  }

  get removed$(): Observable<{ item: FullItem<Item, IdProp>; senderId?: Id }> {
    return this.removed$$.asObservable();
  }

  get updated$(): Observable<{ item: FullItem<Item, IdProp>; senderId?: Id }> {
    return this.updated$$.asObservable();
  }

  get length(): number {
    return this.dataSetLength;
  }

  public add(data: Item | Item[], senderId?: Id): Id[] {
    const addedIds: Id[] = [];

    let id: Id;

    if (Array.isArray(data)) {
      const idsToAdd: Id[] = data.map((d) => d[this.idProp] as Id);
      if (idsToAdd.some((idd) => this.data.has(idd))) {
        throw new GanttException("A duplicate id was found in the parameter array.");
      }
      for (let i = 0, len = data.length; i < len; i++) {
        id = this.addItem(data[i]);
        addedIds.push(id);
      }
    } else if (data && typeof data === "object") {
      // Single item
      id = this.addItem(data);
      addedIds.push(id);
    } else {
      throw new GanttException("Unknown dataType");
    }
    if (addedIds.length > 0) {
      this.data$$.next([...this.data.values()]);
    }
    return addedIds;
  }

  private addItem(data: Item, senderId?: Id): string | number {
    const item = this.ensureFullItem(data, this.idProp);
    const id = item[this.idProp];
    // check whether this id is already taken
    if (this.data.has(id)) {
      // item already exists
      throw new GanttException(`Cannot add item: item with id ${id} already exists`);
    }

    this.data.set(id, item);
    ++this.dataSetLength;
    this.added$$.next({
      item,
      senderId
    });
    return id;
  }

  public remove(id: Id | Item | (Id | Item)[], senderId?: Id): Id[] {
    const removedIds: Id[] = [];
    const removedItems: FullItem<Item, IdProp>[] = [];

    // force everything to be an array for simplicity
    const ids = Array.isArray(id) ? id : [id];

    for (let i = 0, len = ids.length; i < len; i++) {
      const item = this.removeItem(ids[i], senderId);
      if (item) {
        const itemId: OptId = item[this.idProp];
        if (itemId != null) {
          removedIds.push(itemId);
          removedItems.push(item);
        }
      }
    }
    if (removedIds.length > 0) {
      this.data$$.next([...this.data.values()]);
    }
    return removedIds;
  }

  private removeItem(id: Id | Item, senderId?: Id): FullItem<Item, IdProp> | null {
    let ident: OptId;

    if (this.isId(id)) {
      ident = id;
    } else if (id && typeof id === "object") {
      ident = id[this.idProp];
    }

    if (ident != null && this.data.has(ident)) {
      const item = this.data.get(ident) || null;
      if (item !== null) {
        this.data.delete(ident);
        --this.dataSetLength;
        this.removed$$.next({
          item,
          senderId
        });
        return item;
      }
    }

    return null;
  }

  public clear(senderId?: Id): Id[] {
    const ids = [...this.data.keys()];
    const items: FullItem<Item, IdProp>[] = [];

    for (let i = 0, len = ids.length; i < len; i++) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      items.push(this.data.get(ids[i])!);
    }

    this.data.clear();
    this.dataSetLength = 0;

    items.forEach((item) =>
      this.removed$$.next({
        item,
        senderId
      })
    );

    return ids;
  }

  public update(data: Item | Item[], senderId?: Id): Id[] {
    const addedIds: Id[] = [];
    const updatedIds: Id[] = [];
    const oldData: FullItem<Item, IdProp>[] = [];
    const updatedData: FullItem<Item, IdProp>[] = [];
    const { idProp } = this;

    const addOrUpdate = (item: Item): void => {
      const origId: OptId = item[idProp];
      if (origId != null && this.data.has(origId)) {
        const fullItem = item as FullItem<Item, IdProp>;
        const oldItem = { ...this.data.get(origId) } as FullItem<Item, IdProp>;
        // update item
        const id = this.updateItem(fullItem, senderId);
        updatedIds.push(id);
        updatedData.push(fullItem);
        oldData.push(oldItem);
      } else {
        // add new item
        const id = this.addItem(item);
        addedIds.push(id);
      }
    };

    if (Array.isArray(data)) {
      // Array
      for (let i = 0, len = data.length; i < len; i++) {
        if (data[i] && typeof data[i] === "object") {
          addOrUpdate(data[i]);
        } else {
          console.warn(`[Gantt] Ignoring input item, which is not an object at index ${i}`);
        }
      }
    } else if (data && typeof data === "object") {
      // Single item
      addOrUpdate(data);
    } else {
      throw new GanttException("Unknown item data type");
    }

    if (addedIds.length > 0 || updatedIds.length > 0) {
      this.data$$.next([...this.data.values()]);
    }

    return addedIds.concat(updatedIds);
  }

  private updateItem(update: FullItem<Item, IdProp>, senderId?: Id): Id {
    const id: OptId = update[this.idProp];
    if (id == null) {
      throw new GanttException(`Cannot update item: item has no id (item: ${JSON.stringify(update)})`);
    }
    const item = this.data.get(id);
    if (!item) {
      // item doesn't exist
      throw new GanttException(`Cannot update item: no item with id ${id} found`);
    }

    const newItem = { ...item, ...update };
    this.data.set(id, newItem);

    this.updated$$.next({
      item: newItem,
      senderId
    });

    return id;
  }

  private ensureFullItem(item: Item, idProp: IdProp): FullItem<Item, IdProp> {
    if (item[idProp] == null) {
      // generate an id
      item[idProp] = uuid() as any;
    }
    return item as FullItem<Item, IdProp>;
  }

  private isId(value: unknown): value is Id {
    return typeof value === "string" || typeof value === "number";
  }
}
