<script lang="ts" setup>
import { ContactsServerSideDataSource } from "@/components/Contacts/ContactsServerSideDataSource";
import GridWrapper from "@/components/Grid/GridWrapper.vue";
import { GridWrapperComponent } from "@/components/Grid/GridWrapperComponent";
import { nameOrBusinessIdOrIdOrNull } from "@/components/ValueCellEditor/CommonFormatters";
import { $t } from "@/i18n";
import ApiService from "@/services/api";
import { useSnackbarsStore } from "@/store/SnackbarsStore";
import { ContactDto, OrganizationDto } from "@masta/generated-model";
import { isDefined } from "@vueuse/core";
import { EditableCallbackParams, GridApi, ValueFormatterParams, ValueGetterParams, ValueSetterParams } from "ag-grid-community";
import { reactive, ref, watch } from "vue";
import { getSelectedRows } from "@/components/Grid/UseGridSelection";

interface Props {
  /**
   * The organization to filter contacts by. Used when contacts grid is displayed as details view in the organizations view.
   */
  organization?: OrganizationDto | null | undefined;
}

const props = defineProps<Props>();

const DEFAULT_CREATE_VALUE = {
  organization: () => isDefined(props.organization) ? props.organization : null
};

const gridWrapperRef = ref<GridWrapperComponent>();

const isRowSelected = ref(false);

const serverSideDataSource = reactive(new ContactsServerSideDataSource("contacts", props.organization?.id));

const defaultColumnDef = ref({
  floatingFilter: true,
  filterParams: {
    applyMiniFilterWhileTyping: true
  },
});

watch(
  () => props.organization,
  async (organization, _) => {
    serverSideDataSource.useFilteringByOrganizationId(organization?.id);

    const { gridApi } = gridWrapperRef.value ?? {};
    if (gridApi) {
      gridApi.refreshServerSide({ purge: true });
    }
  },
  { deep: true }
);

function onPrepareColumns(columnDefs: any) {
  columnDefs.value = [
    {
      field: "name",
      editable: true,
      sortable: true,
      filter: "agTextColumnFilter",
      type: ["textInputTypeColumn", "textFloatingFilterColumnType"],
      cellEditorParams: {
        rules: [(v: any) => (v !== undefined && v !== null && v !== "") || $t("contact-edit-valueRequired-popup", { $: "Required" })],
        placeholder: "Name"
      },
      headerValueGetter: (_: any) => $t("contact-list-name-label", { $: "Name" }),
      floatingFilterComponentParams: {
        placeholder: $t("contact-list-name-label", { $: "Name" })
      }
    },
    {
      field: "email",
      headerName: "Email",
      editable: true,
      sortable: true,
      filter: "agTextColumnFilter",
      type: ["textInputTypeColumn", "textFloatingFilterColumnType"],
      cellEditorParams: {
        rules: [(v: any) => (v !== undefined && v !== null && v !== "") || $t("contact-edit-valueRequired-popup", { $: "Required" })],
        placeholder: $t("contact-edit-email-label", { $: "E-Mail" })
      },
      headerValueGetter: (_: any) => $t("contact-list-email-label", { $: "Email" }),
      floatingFilterComponentParams: {
        placeholder: $t("contact-list-email-label", { $: "Email" })
      }
    },
    {
      field: "organization",
      headerName: "Organization",
      editable: (params: EditableCallbackParams) => !isDefined(props.organization),
      sortable: true,
      filter: false,
      type: ["organizationPickerTypeColumn", "textFloatingFilterColumnType"],
      cellEditorParams: {
        placeholder: $t("contact-list-organizationName-label", { $: "Organization" })
      },
      valueGetter: (params:ValueGetterParams<ContactDto, OrganizationDto>) => {
        const { data } = params;
        if(!data || !data.organization) return null;

        return {
          id: data.organization.id,
          name: data.organization.name
        } as OrganizationDto;
      },
      valueSetter: (params: ValueSetterParams<ContactDto, OrganizationDto>) => {
        const { data, newValue } = params;
        data.organization = newValue!;
        return true;
      },
      valueFormatter: (params: ValueFormatterParams<ContactDto>) => {
        return nameOrBusinessIdOrIdOrNull(params.data?.organization);
      },
      headerValueGetter: (_: any) => $t("contact-list-organizationName-label", { $: "Organization" })
    },
    {
      field: "description",
      headerName: "Description",
      editable: true,
      sortable: true,
      filter: "agTextColumnFilter",
      type: ["textInputTypeColumn", "textFloatingFilterColumnType"],
      cellEditorParams: {
        render: (value: any) => value,
        placeholder: $t("contact-edit-description-label", { $: "Description" })
      },
      headerValueGetter: (_: any) => $t("contact-list-description-label", { $: "Description" }),
      floatingFilterComponentParams: {
        placeholder: $t("contact-list-description-label", { $: "Description" })
      }
    },
    {
      field: "phone",
      headerName: "Phone",
      editable: true,
      sortable: true,
      filter: "agTextColumnFilter",
      type: ["textInputTypeColumn", "textFloatingFilterColumnType"],
      cellEditorParams: {
        placeholder: $t("contact-edit-telephoneNumber-label", { $: "Phone" })
      },
      headerValueGetter: (_: any) => $t("contact-list-phone-label", { $: "Phone" }),
      floatingFilterComponentParams: {
        placeholder: $t("contact-list-phone-label", { $: "Phone" })
      }
    },
    {
      field: "businessId",
      headerName: "Business ID",
      editable: true,
      sortable: true,
      filter: "agTextColumnFilter",
      type: ["textInputTypeColumn", "textFloatingFilterColumnType"],
      cellEditorParams: {
        isEditEnabled: () => !gridWrapperRef.value?.isUpdating(),
        placeholder: $t("contact-edit-businessId-label", { $: "Business ID" })
      },
      headerValueGetter: (_: any) => $t("contact-list-businessId-label", { $: "Business ID" }),
      floatingFilterComponentParams: {
        placeholder: $t("contact-list-businessId-label", { $: "Business ID" })
      }
    }
  ];
}

function onSelectionChanged({ api }: { api: GridApi }) {
  isRowSelected.value = getSelectedRows(api).length > 0;
}

async function inviteCustomer() {
  const selectedRows = getSelectedRows(gridWrapperRef.value?.gridApi) ?? [];
  const snackbarsStore = useSnackbarsStore();
  if (selectedRows.length > 0) {
    const contact = selectedRows[0];
    try {
      await ApiService.contacts.inviteContact({ contactId: contact.id });
      await snackbarsStore.createSnackbar({
        message: $t("contact-invitation-onInvitationSent-message", { contactName: contact?.name, $: "Contact invitation was sent to {contactName}!" }),
        type: "success",
        closeable: true
      });
    } catch (e) {
      console.error(e);
      await snackbarsStore.createSnackbar({
        message: $t("contact-invitation-onInvitationSentError-message", { contactName: contact?.name, $: "Could not send invitation to contact {contactName}" }),
        type: "error",
        closeable: true
      });
    }
  }
}
</script>

<template>
  <grid-wrapper
    ref="gridWrapperRef"
    identifier="contacts"
    :default-col-def="defaultColumnDef"
    create-btn
    duplicate-btn
    edit-btn
    delete-btn
    refresh-btn
    row-selection="multiple"
    server-side
    :create-default-value="DEFAULT_CREATE_VALUE"
    :server-side-datasource="serverSideDataSource"
    @prepare-columns="onPrepareColumns"
    @selection-changed="onSelectionChanged"
  >
    <template #custom-buttons>
      <v-tooltip bottom open-delay="300">
        <template #activator="{ props }">
          <div class="d-inline-flex pr-4">
            <v-btn size="small" variant="text" density="compact" v-bind="props" :disabled="!isRowSelected" @click="inviteCustomer">
              <v-icon icon="mdi-account-plus" class="pr-4"></v-icon>
              {{ $t("contact-invitation-invite-action-tooltip", { $: "Invite contact" }) }}
            </v-btn>
          </div>
        </template>
        <span>{{ $t("contact-invitation-invite-action-tooltip", { $: "Invite contact" }) }}</span>
      </v-tooltip>
    </template>
  </grid-wrapper>
</template>

<style lang="scss" scoped></style>
