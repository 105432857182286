import { ZoneId } from "@js-joda/core";
import { BehaviorSubject, Observable } from "rxjs";

import { Id, ObservableDataSet } from "../ObservableDataSet";

import { ActivityRef } from "./ActivityRef";
import { IActivity } from "./IActivity";
import { IActivityRepository } from "./IActivityRepository";
import { ICalendar } from "./ICalendar";
import { Layer } from "./Layer";
import { GanttLayout } from "./Layouts";
import { IMutableActivityRepository, TimeIntervalTreeActivityRepository } from "./Repository";
import { ILinesManager } from "./ILinesManager";
import { IRow } from "./IRow";

export class Row<Parent extends Row<any, any, any>, Child extends Row<any, any, any>, Activity extends IActivity> implements IRow {
  private _parent: Parent | this;

  private _id: string;

  private _name: string;

  private _defaultHeight = 60;

  private _minHeight = 24;

  private _maxHeight = 900;

  private _leaf$$ = new BehaviorSubject(true);

  private _expanded$$ = new BehaviorSubject(false);

  private _visible$$ = new BehaviorSubject(false);

  private _layout = new GanttLayout();

  private _zoneId = ZoneId.systemDefault();

  private _properties = new Map<any, any>();

  private _children = new ObservableDataSet<Child>();

  private _calendars = new ObservableDataSet<ICalendar<any>>();

  private _properties$$: BehaviorSubject<Map<any, any>>;

  private _linesManager: ILinesManager<Activity>;

  private _repository: IActivityRepository<Activity>;

  private _lineCount$$ = new BehaviorSubject(0);

  private _height$$ = new BehaviorSubject(this._defaultHeight);

  private _levelNumber$$ = new BehaviorSubject(0);

  private _parentId?: string;

  private _childrenIds: string[] = [];

  protected _userObject: IRow;

  constructor(private _rowObject: IRow) {
    this._id = this._rowObject.id;
    this._name = this._rowObject.name;
    this._parentId = this._rowObject.parentId;
    this._childrenIds = this._rowObject.childrenIds ?? [];
    this._properties = new Map<any, any>();
    Object.entries(this._rowObject.properties ?? {}).forEach(([key, value]) => {
      this._properties.set(key, value);
    });
    this._properties$$ = new BehaviorSubject<Map<any, any>>(this._properties);
    this._repository = new TimeIntervalTreeActivityRepository();
    this._userObject = _rowObject;
  }

  get id(): string {
    return this._id;
  }

  set id(value: string) {
    this._id = value;
  }

  get properties$(): Observable<Map<any, any>> {
    return this._properties$$.asObservable();
  }

  get properties(): Map<any, any> {
    return this._properties$$.value;
  }

  set properties(value: Map<any, any>) {
    this._properties = value;
    this._properties$$.next(value);
  }

  get parentId(): string | undefined {
    return this._parentId;
  }

  set parentId(value: string | undefined) {
    this._parentId = value;
  }

  get childrenIds(): string[] {
    return this._childrenIds;
  }

  set childrenIds(value: string[]) {
    this._childrenIds = value;
  }

  get parent(): Parent | this {
    return this._parent ?? undefined;
  }

  set parent(value: Parent | this) {
    this._parent = value;
  }

  get name(): string {
    return this._name ?? this._id;
  }

  set name(value: string) {
    this._name = value;
  }

  get height$(): Observable<number> {
    return this._height$$.asObservable();
  }

  get height(): number {
    return this._height$$.value;
  }

  set height(value: number) {
    this._height$$.next(value);
  }

  get defaultHeight(): number {
    return this._defaultHeight;
  }

  set defaultHeight(value: number) {
    this._defaultHeight = value;
  }

  get minHeight(): number {
    return this._minHeight;
  }

  set minHeight(value: number) {
    this._minHeight = value;
  }

  get maxHeight(): number {
    return this._maxHeight;
  }

  set maxHeight(value: number) {
    this._maxHeight = value;
  }

  get levelNumber$(): Observable<number> {
    return this._levelNumber$$.asObservable();
  }

  get levelNumber(): number {
    return this._levelNumber$$.value;
  }

  set levelNumber(value: number) {
    this._levelNumber$$.next(value);
  }

  get leaf$(): Observable<boolean> {
    return this._leaf$$.asObservable();
  }

  get leaf(): boolean {
    return this._leaf$$.value;
  }

  set leaf(value: boolean) {
    this._leaf$$.next(value);
  }

  get expanded$(): Observable<boolean> {
    return this._expanded$$.asObservable();
  }

  get expanded(): boolean {
    return this._expanded$$.value;
  }

  set expanded(value: boolean) {
    this._expanded$$.next(value);
  }

  get visible$(): Observable<boolean> {
    return this._visible$$.asObservable();
  }

  get visible(): boolean {
    return this._visible$$.value;
  }

  set visible(value: boolean) {
    this._visible$$.next(value);
  }

  get layout(): GanttLayout {
    return this._layout;
  }

  set layout(value: GanttLayout) {
    this._layout = value;
  }

  get lineCount$(): Observable<number> {
    return this._lineCount$$.asObservable();
  }

  get lineCount(): number {
    return this._lineCount$$.value;
  }

  set lineCount(value: number) {
    this._lineCount$$.next(value);
  }

  get zoneId(): ZoneId {
    return this._zoneId;
  }

  set zoneId(value: ZoneId) {
    this._zoneId = value;
  }

  get children(): ObservableDataSet<Child> {
    return this._children;
  }

  set children(value: ObservableDataSet<Child>) {
    this._children = value;
  }

  get calendars(): ObservableDataSet<ICalendar<any>> {
    return this._calendars;
  }

  set calendars(value: ObservableDataSet<ICalendar<any>>) {
    this._calendars = value;
  }

  get linesManager(): ILinesManager<Activity> {
    return this._linesManager;
  }

  set linesManager(value: ILinesManager<Activity>) {
    this._linesManager = value;
  }

  set repository(value: IActivityRepository<Activity>) {
    this._repository = value;
  }

  get repository(): IActivityRepository<Activity> {
    return this._repository;
  }

  get userObject(): IRow {
    return this._userObject;
  }

  private get mutableRepository(): IMutableActivityRepository<Activity> {
    // TODO check for repository immutability
    // if (this._repository instanceof IMutableActivityRepository<any>) {
    return this._repository as IMutableActivityRepository<Activity>;
    // }
    // throw new GanttException('row activity repository is immutable!');
  }

  getLineIndex(activity: Activity): number {
    return this.linesManager.getLineIndex(activity);
  }

  getLineLocation(lineIndex: number): number {
    return this.linesManager.getLineLocation(lineIndex, this.height);
  }

  getLineHeight(lineIndex: number): number {
    return this.linesManager.getLineHeight(lineIndex, this.height);
  }

  get path(): Row<any, any, any>[] {
    const list = [];
    let { parent } = this;
    while (parent != null) {
      list.unshift(parent);
      parent = parent.parent;
    }
    return list;
  }

  addProperty(key: any, value: any): void {
    this.properties.set(key, value);
    this._properties$$.next(this.properties);
  }

  addChild(child: Child): void {
    if (this.children.data.has(child.id)) {
      return;
    }
    this.leaf = false;
    child.parent = this;
    this.children.add(child);
  }

  addChildren(children: Child[]): void {
    let childAdded = false;
    children.forEach((child) => {
      if (this.children.data.has(child.id)) {
        return;
      }
      child.parent = this;
      this.children.add(child);
      childAdded = true;
    });
    if (childAdded) {
      this.leaf = false;
    }
  }

  removeChild(child: Child): Id[] {
    const removedIds = this.children.remove(child);
    this.leaf = this.children.length === 0;
    return removedIds;
  }

  removeChildren(children: Child[]): Id[] {
    const removedIds = this.children.remove(children);
    children.forEach((child) => {
      if (removedIds.includes(child.id)) {
        child.parent = child;
      }
    });
    this.leaf = this.children.length === 0;
    return removedIds;
  }

  toString(): string {
    return this.name;
  }

  removeActivity(layer: Layer, activity: Activity): void {
    this.mutableRepository.removeActivity(new ActivityRef<Activity>(this, layer, activity));
  }

  removeActivities(layer: Layer, activities: Activity[]): void {
    this.mutableRepository.removeActivities(activities.map((activity) => new ActivityRef<Activity>(this, layer, activity)));
  }

  addActivity(layer: Layer, activity: Activity): void {
    this.mutableRepository.addActivity(new ActivityRef<Activity>(this, layer, activity));
  }

  addActivities(layer: Layer, activities: Activity[]): void {
    this.mutableRepository.addActivities(activities.map((activity) => new ActivityRef<Activity>(this, layer, activity)));
  }

  clearActivities(): void {
    this.mutableRepository.clearActivities();
  }

  clearLayerActivities(layer: Layer): void {
    this.mutableRepository.clearLayerActivities(layer);
  }
}
