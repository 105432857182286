import { interfaces } from "inversify";
import {
  ActivityRendererRegistry,
  GanttSettings,
  ILinesManager,
  IocSymbols,
  IRowChartScaleProvider,
  IRowContainer,
  onActivationLifecycle,
  onDeactivationLifecycle,
  Row,
  SettingKey
} from "../../../Core";
import { RowContainer } from "./RowContainer";
import {
  RowInfoCollapseBtnContainer,
  RowInfoColumnCellRegistry,
  RowInfoContainer,
  RowInfoHeightResizeHandle,
  RowInfoIndex,
  RowInfoTemplate,
  RowScaleRowInfoTemplateCellCanvas
} from "./Info";
import { RowCanvas, RowCanvasContainer } from "./Canvas";
import { ChartLayer, GridLinesRowLayer, LayerManager, WeekendCalendarRowLayer } from "./Layers";
import { ChartLayerRenderer, RowCanvasLayerRenderer, WeekendCalendarLayerRenderer } from "../../../Worker";
import { ChartScaleLayerRenderer } from "../../../Worker/View/Layers/ChartScaleLayerRenderer";

export function rowContainerFactory<TResource extends Row<any, any, any>>(context: interfaces.Context) {
  return async (row: TResource) => {

    const iocExtension = context.container.isBound(IocSymbols.RowFactoryIocExtension) ? context.container.get<(c: interfaces.Container) => void>(IocSymbols.RowFactoryIocExtension) : undefined;
    const settings = context.container.get(GanttSettings);
    const linesManagerName = settings.getSetting<string>(SettingKey.LINE_MANAGER) ?? "default";
    const linesManager = context.container.getNamed<interfaces.Newable<ILinesManager<any>>>(IocSymbols.LinesManagerClass, linesManagerName);

    const rowChartScaleProviderName = settings.getSetting<string>(SettingKey.ROW_CHART_SCALE_PROVIDER) ?? "default";
    const rowChartScaleProvider = context.container.getNamed<interfaces.Newable<IRowChartScaleProvider>>(IocSymbols.RowChartScaleProviderClass, rowChartScaleProviderName);

    const childContainer = context.container.createChild();

    childContainer.bind<LayerManager>(IocSymbols.RowLayerManager).to(LayerManager).inSingletonScope();
    childContainer.bind<interfaces.Container>(IocSymbols.RowContainerIocContainerSymbol).toConstantValue(childContainer);
    childContainer.bind<Row<any, any, any>>(Row<any, any, any>).toConstantValue(row);

    childContainer.bind<IRowContainer>(IocSymbols.RowContainer).to(RowContainer<any>).inSingletonScope();

    childContainer.bind<RowInfoContainer<any>>(RowInfoContainer<any>).to(RowInfoContainer<any>).inSingletonScope();
    childContainer.bind<RowInfoIndex<any>>(RowInfoIndex<any>).to(RowInfoIndex<any>).inSingletonScope();
    childContainer.bind<RowInfoTemplate<any>>(RowInfoTemplate<any>).to(RowInfoTemplate<any>).inSingletonScope();
    childContainer.bind<RowInfoCollapseBtnContainer<any>>(RowInfoCollapseBtnContainer<any>).to(RowInfoCollapseBtnContainer<any>).inSingletonScope();
    childContainer.bind<RowInfoHeightResizeHandle<any>>(RowInfoHeightResizeHandle<any>).to(RowInfoHeightResizeHandle<any>).inSingletonScope();
    childContainer.bind<RowScaleRowInfoTemplateCellCanvas<any>>(RowScaleRowInfoTemplateCellCanvas<any>).to(RowScaleRowInfoTemplateCellCanvas<any>).inSingletonScope();

    childContainer.bind<ILinesManager<any>>(IocSymbols.LinesManagerSymbol).to(linesManager).inSingletonScope();
    childContainer.bind<RowCanvasContainer<any>>(RowCanvasContainer<any>).to(RowCanvasContainer<any>).inSingletonScope();
    childContainer.bind<RowCanvas<any>>(RowCanvas<any>).to(RowCanvas<any>).inSingletonScope();

    // chart scale provider
    childContainer.bind<IRowChartScaleProvider>(IocSymbols.RowChartScaleProvider).to(rowChartScaleProvider).inSingletonScope();
    childContainer.onActivation<IRowChartScaleProvider>(IocSymbols.RowChartScaleProvider, onActivationLifecycle);
    childContainer.onDeactivation<IRowChartScaleProvider>(IocSymbols.RowChartScaleProvider, onDeactivationLifecycle);

    const layerManager = childContainer.get<LayerManager>(IocSymbols.RowLayerManager);

    // add row background layers
    layerManager.addRowBackgroundSystemLayers(GridLinesRowLayer);
    layerManager.addRowBackgroundSystemLayers(WeekendCalendarRowLayer);
    // layerManager.addRowBackgroundSystemLayers(ChartLayer);

    // add row foreground layers
    layerManager.addRowForegroundSystemLayers(ChartLayer);
    // layerManager.addRowForegroundSystemLayers(ChartScaleLayer);

    // renderers
    childContainer.bind<RowCanvasLayerRenderer>(RowCanvasLayerRenderer.Identifier).to(RowCanvasLayerRenderer).inSingletonScope();
    childContainer.onActivation<RowCanvasLayerRenderer>(RowCanvasLayerRenderer.Identifier, onActivationLifecycle);
    childContainer.onDeactivation<RowCanvasLayerRenderer>(RowCanvasLayerRenderer.Identifier, onDeactivationLifecycle);
    childContainer.bind<WeekendCalendarLayerRenderer>(WeekendCalendarLayerRenderer.Identifier).to(WeekendCalendarLayerRenderer).inSingletonScope();
    childContainer.onActivation<WeekendCalendarLayerRenderer>(WeekendCalendarLayerRenderer.Identifier, onActivationLifecycle);
    childContainer.onDeactivation<WeekendCalendarLayerRenderer>(WeekendCalendarLayerRenderer.Identifier, onDeactivationLifecycle);
    childContainer.bind<ChartLayerRenderer>(ChartLayerRenderer.Identifier).to(ChartLayerRenderer).inSingletonScope();
    childContainer.onActivation<ChartLayerRenderer>(ChartLayerRenderer.Identifier, onActivationLifecycle);
    childContainer.onDeactivation<ChartLayerRenderer>(ChartLayerRenderer.Identifier, onDeactivationLifecycle);
    childContainer.bind<ChartScaleLayerRenderer>(ChartScaleLayerRenderer.Identifier).to(ChartScaleLayerRenderer).inSingletonScope();
    childContainer.onActivation<ChartScaleLayerRenderer>(ChartScaleLayerRenderer.Identifier, onActivationLifecycle);
    childContainer.onDeactivation<ChartScaleLayerRenderer>(ChartScaleLayerRenderer.Identifier, onDeactivationLifecycle);

    const activityRendererRegistry = context.container.get(ActivityRendererRegistry);

    if (iocExtension)
      iocExtension(childContainer);

    // bind row background layers
    layerManager.bindRowBackgroundSystemLayers(childContainer);

    // bind row foreground layers
    layerManager.bindRowForegroundSystemLayers(childContainer);

    // bind renderers
    activityRendererRegistry.bindActivityRenderers(childContainer);

    // bind row info columns
    (await context.container.getAsync<RowInfoColumnCellRegistry>(IocSymbols.RowInfoColumnCellRegistry)).bindCells(childContainer);

    return childContainer.getAsync(IocSymbols.RowContainer);
  };
}
