<script lang="ts" setup>
import { $t } from "@/i18n";
import { getEnumTitleValuePairs } from "@/composables/enumHelpers";
import { GanttTimeSectionAggregation } from "@masta/generated-model";
import { translateGanttTimeSectionAggregation } from "@/composables/translateEnum";
import { useRouteQuery } from "@vueuse/router";

const ganttTimeSectionAggregations = getEnumTitleValuePairs(GanttTimeSectionAggregation, translateGanttTimeSectionAggregation).sort((a, b) => a.value - b.value);

const selectedAggregation = useRouteQuery("timeSectionAggregation", GanttTimeSectionAggregation.None, {
  transform(val) {
    return +val;
  }
});

</script>

<template>
  <div class="d-inline-flex align-self-stretch text-secondary font-weight-bold pl-4">
    <v-divider vertical />
  </div>
  <div class="d-inline-flex text-secondary font-weight-bold pl-4 pr-2">
    <div style="min-width: 5vw">
      <v-select
        v-model="selectedAggregation"
        :items="ganttTimeSectionAggregations"
        variant="outlined"
        density="compact"
        hide-details="auto"
        :label="$t('gantt-component-timeSectionAggregation-label', { $: 'Time aggregation' })"
      />
    </div>
  </div>
</template>

<style scoped lang="scss">

</style>
