import ApiService from "@/services/api";
import { ServiceDto, UpdateResourceModelInstanceAssignmentCommand } from "@masta/generated-model";
import { defineStore } from "pinia";
import { composeHierarchy } from "@/store/helper";
import { useErrorsStore } from "@/store/ErrorsStore";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface State {}

export const useServicesStore = defineStore("services", {
  state: (): State => ({}),
  getters: {},
  actions: {
    async updateModelInstanceAssignment(request: UpdateResourceModelInstanceAssignmentCommand) {
      try {
        await ApiService.resources.updateModelInstanceAssignment(request);
      } catch (e) {
        const errorsStore = useErrorsStore();
        errorsStore.handleError(e);
        throw e;
      }
    }
  }
});

