import { ApiClient } from "@/services/api/common.api";
import { GetWorkJournalRecordsPaginatedQuery, IAgGridPaginationResponse, QuotationQuotationLineDto } from "@masta/generated-model";

export default {
  async getAll(scenarioId?: string) {
    return ApiClient.get<IAgGridPaginationResponse<QuotationQuotationLineDto>>(`/quotationLines?scenarioId=${scenarioId}`);
  },
  async getPaginated(request: GetWorkJournalRecordsPaginatedQuery) {
    return ApiClient.post<IAgGridPaginationResponse<QuotationQuotationLineDto>>(`/quotationLines`, request);
  }
};
