export type GraphicsMousePositionEvent = {
  x: number;
  y: number;
  ctrlKey: boolean;
  shiftKey: boolean;
  altKey: boolean;
  source?: any;
  preventDefault: () => void;
};

export function createGraphicsMousePositionEvent(event: MouseEvent, element: HTMLElement, source?: any): GraphicsMousePositionEvent {
  const { left, top } = element.getBoundingClientRect();
  return {
    x: event.clientX - left,
    y: event.clientY - top,
    ctrlKey: event.ctrlKey,
    shiftKey: event.shiftKey,
    altKey: event.altKey,
    source,
    preventDefault: () => event.preventDefault()
  };
}
