import { GridApi, GridReadyEvent, IRowNode, RowNode, RowValueChangedEvent, ValueGetterParams } from "ag-grid-community";
import { GridWrapperComponent } from "@/components/Grid/GridWrapperComponent";
import { Ref } from "vue";
import { tryOnBeforeUnmount } from "@vueuse/core";
import { getSelectedNodes } from "@/components/Grid/UseGridSelection";


function getParentGroupName(api: GridApi, getNameForNode: (node: IRowNode) => any, node: IRowNode | null | undefined) {
  const groupKeys = (node as RowNode | undefined)?.getGroupKeys(true) ?? [];
  if (groupKeys.length > 0) {
    const parentNode = api.getRowNode(groupKeys[groupKeys.length - 1]);
    if (parentNode) {
      return getNameForNode(parentNode);
    }
  }
}

function defaultGetNameForNode(node: IRowNode) {
  return node.data.name ?? node.data.businessId ?? node.data.id;
}

export interface UseServerSideGroupParams {
  isGroup(data: any): boolean;

  getNameForNode?: (node: IRowNode) => any;
  setParentId?: (newRowData: { [k: string]: any; }, parentNode: IRowNode) => void;
  grid: Ref<GridWrapperComponent | undefined>;
}

/**
 * 
 * @deprecated - This is the old grouping mechanism. It is no longer used in new solutions. Instead, we display "Add child" in the rowNode. 
 */
export const useServerSideGroup = (parameters: UseServerSideGroupParams) => {
  let gridApi: GridApi = null!;

  function onGridReady(event: GridReadyEvent) {
    gridApi = event.api;
    gridApi.addEventListener("rowValueChanged", onRowValueChanged);
  }

  tryOnBeforeUnmount(() => {
    if (!gridApi) return;
    gridApi.removeEventListener("rowValueChanged", onRowValueChanged);
  });

  const _getNameForNode = parameters.getNameForNode ? parameters.getNameForNode : defaultGetNameForNode;

  function groupValueGetter(params: ValueGetterParams) {
    if (params.node?.rowPinned) {
      // Row pinned and not creating child == new top item (no group to display)
      // but if parentId is set, then it's a child and display the group (e.g. for copy to new row)

      if (!params.data.parentId) {
        return;
      }

      const firstSelectedNode = getSelectedNodes(params.api).filter((x) => x.displayed)[0];
      const data = firstSelectedNode?.data;
      if (data && parameters.isGroup(data)) {
        return _getNameForNode(firstSelectedNode);
      }
      if (data && !parameters.isGroup(data)) {
        return getParentGroupName(params.api, _getNameForNode, firstSelectedNode);
      }
    }
    if (parameters.isGroup(params.data)) {
      return _getNameForNode(params.node!);
    }
    return getParentGroupName(params.api, _getNameForNode, params.node);
  }

  function onRowValueChanged(event: RowValueChangedEvent) {
    const contextRowNode = parameters.grid.value?.contextRowNode;
    if (!event.rowPinned || !contextRowNode) {
      return;
    }
  }

  return {
    onGridReady,
    groupValueGetter,
  };
};
