import { ChronoUnit, Duration, Instant, TemporalUnit, ZoneId } from "@js-joda/core";
import { BehaviorSubject, Observable } from "rxjs";

import { GanttException } from "../../Exceptions";
import { ITimelineSynchronization } from "./TimelineSynchronization";

export abstract class TimelineModel<TUnit extends TemporalUnit> {
  private _horizonStartTime: Instant;

  private _horizonEndTime: Instant;

  private _startTime$$ = new BehaviorSubject(Instant.now());

  private _endTime$$ = new BehaviorSubject(Instant.now());

  private _nowTime$$ = new BehaviorSubject(Instant.now());

  private _offset = 0;

  private _millisPerPixel$$ = new BehaviorSubject(2880000);

  private _minMillisPerPixel = 1;

  private _maxMillisPerPixel = Number.MAX_VALUE;

  private _smallestTemporalUnit: TUnit;

  private _primaryTemporalUnit: TUnit;

  private _zoneId$$ = new BehaviorSubject<ZoneId>(ZoneId.systemDefault());

  constructor() {
  }

  get horizonStartTime(): Instant {
    return this._horizonStartTime;
  }

  set horizonStartTime(value: Instant) {
    this._horizonStartTime = value;
  }

  get horizonEndTime(): Instant {
    return this._horizonEndTime;
  }

  set horizonEndTime(value: Instant) {
    this._horizonEndTime = value;
  }

  get minMillisPerPixel(): number {
    return this._minMillisPerPixel;
  }

  set minMillisPerPixel(value: number) {
    if (value < 0) {
      throw new GanttException("min millis per pixel must be >= 0");
    }
    this._minMillisPerPixel = value;
    if (this._millisPerPixel$$.value < value) {
      this._millisPerPixel$$.next(value);
    }
  }

  get maxMillisPerPixel(): number {
    return this._maxMillisPerPixel;
  }

  set maxMillisPerPixel(value: number) {
    this._maxMillisPerPixel = value;
    if (this._millisPerPixel$$.value > value) {
      this._millisPerPixel$$.next(value);
    }
  }

  get millisPerPixel$(): Observable<number> {
    return this._millisPerPixel$$.asObservable();
  }

  get millisPerPixel(): number {
    return this._millisPerPixel$$.value;
  }

  set millisPerPixel(value: number) {
    const v = Math.max(this._minMillisPerPixel, Math.min(this._maxMillisPerPixel, value));
    this._millisPerPixel$$.next(v);
  }

  get startTime$(): Observable<Instant> {
    return this._startTime$$.asObservable();
  }

  get startTime(): Instant {
    return this._startTime$$.value;
  }

  set startTime(value: Instant) {
    if (this._horizonStartTime && this._horizonStartTime.isAfter(value)) return;
    if (this._horizonEndTime && this._horizonEndTime.isBefore(value)) return;
    this._startTime$$.next(value);
  }

  get endTime$(): Observable<Instant> {
    return this._endTime$$.asObservable();
  }

  get endTime(): Instant {
    return this._endTime$$.value;
  }

  set endTime(value: Instant) {
    this._endTime$$.next(value);
  }

  get nowTime$(): Observable<Instant> {
    return this._nowTime$$;
  }

  set nowTime(value: Instant) {
    this._nowTime$$.next(value);
  }

  get nowTime(): Instant {
    return this._nowTime$$.value;
  }

  get smallestTemporalUnit(): TUnit {
    return this._smallestTemporalUnit;
  }

  set smallestTemporalUnit(value: TUnit) {
    this._smallestTemporalUnit = value;
  }

  get primaryTemporalUnit(): TUnit {
    return this._primaryTemporalUnit;
  }

  set primaryTemporalUnit(value: TUnit) {
    this._primaryTemporalUnit = value;
  }

  get offset(): number {
    return this._offset;
  }

  set offset(value: number) {
    this._offset = value;
  }

  get zoneId$(): Observable<ZoneId> {
    return this._zoneId$$.asObservable();
  }

  get zoneId(): ZoneId {
    return this._zoneId$$.value;
  }

  set zoneId(value: ZoneId) {
    this._zoneId$$.next(value);
  }

  public calculateLocationForTimeMillis(timeMillis?: number): number {
    if (!timeMillis) return -1;
    if (timeMillis === Number.MAX_VALUE) return Number.MAX_VALUE;
    if (timeMillis === Number.MIN_VALUE) return Number.MIN_VALUE;
    const startTimeMillis = this.startTime.toEpochMilli();
    const millisDiff = timeMillis - startTimeMillis;
    // console.log("calculateLocationForTime", /*time.toJSON(), this.startTime.toJSON(), */ timeMillis, startTimeMillis, millisDiff, this.millisPerPixel);
    return millisDiff / this.millisPerPixel + this._offset;
  }

  public calculateLocationForTime(time?: Instant): number {
    if (!time) return -1;
    if (time === Instant.MAX) return Number.MAX_VALUE;
    if (time === Instant.MIN) return Number.MIN_VALUE;
    return this.calculateLocationForTimeMillis(time.toEpochMilli());
  }

  public calculateTimeForLocation(location: number): Instant {
    const millis = (location - this._offset) * this.millisPerPixel;
    return this.startTime.plus(Duration.ofMillis(millis));
  }

  public calculateWidthForDuration(duration: Duration): number {
    return duration.toMillis() / this.millisPerPixel + this._offset;
  }

  // eslint-disable-next-line max-len
  public setZoomRange(smallestUnit: TUnit, smallestUnitCount: number, smallestUnitWidth: number, largestUnit: TUnit, largestUnitCount: number, largestUnitWidth: number): void {
    if (smallestUnit.duration().toMillis() > largestUnit.duration().toMillis()) {
      throw new GanttException(`zoom range start unit can not be larger than end unit, start = ${smallestUnit}, end = ${largestUnit}`);
    }
    if (smallestUnitCount < 1) throw new GanttException(`smallest unit count must be >= 1 but was ${smallestUnitCount}`);
    if (largestUnitCount < 1) throw new GanttException(`largest unit count must be >= 1 but was ${largestUnitCount}`);
    if (smallestUnitWidth < 10) throw new GanttException(`smallest unit width must be >= 10 but was ${smallestUnitWidth}`);
    if (largestUnitWidth < 10) throw new GanttException(`largest unit width must be >= 10 but was ${largestUnitWidth}`);
    const min = (smallestUnit.duration().toMillis() * smallestUnitCount) / smallestUnitWidth;
    const max = (largestUnit.duration().toMillis() * largestUnitCount) / largestUnitWidth;
    if (min > max) throw new GanttException(`minimum MPP value can not be larger than maximum MPP value, min = ${min}, max = ${max}`);
    this.smallestTemporalUnit = smallestUnit;
    this.minMillisPerPixel = min;
    this.maxMillisPerPixel = max;
  }

  public synchronize(timelineSynchronization: ITimelineSynchronization): void {
    this._nowTime$$.next(Instant.ofEpochMilli(timelineSynchronization.nowTime));
    this._startTime$$.next(Instant.ofEpochMilli(timelineSynchronization.startTime));
    this._endTime$$.next(Instant.ofEpochMilli(timelineSynchronization.endTime));
    this._horizonStartTime = Instant.ofEpochMilli(timelineSynchronization.horizonStartTime);
    this._horizonEndTime = Instant.ofEpochMilli(timelineSynchronization.horizonEndTime);
    this._offset = timelineSynchronization.offset;
    this._minMillisPerPixel = timelineSynchronization.minMillisPerPixel;
    this._maxMillisPerPixel = timelineSynchronization.maxMillisPerPixel;
    this._millisPerPixel$$.next(timelineSynchronization.millisPerPixel);
    this._smallestTemporalUnit = ChronoUnit[timelineSynchronization.smallestTemporalUnit as keyof typeof ChronoUnit] as unknown as TUnit;
    this._primaryTemporalUnit = ChronoUnit[timelineSynchronization.primaryTemporalUnit as keyof typeof ChronoUnit] as unknown as TUnit;
    const zoneId = ZoneId.of(timelineSynchronization.zoneId);
    if (!zoneId.equals(this._zoneId$$.value)) this._zoneId$$.next(zoneId);
  }

  public toTimelineSynchronization(): ITimelineSynchronization {
    return {
      horizonStartTime: this.horizonStartTime?.toEpochMilli() ?? 0,
      horizonEndTime: this.horizonEndTime?.toEpochMilli() ?? 0,
      startTime: this.startTime.toEpochMilli(),
      endTime: this.endTime.toEpochMilli(),
      nowTime: this.nowTime.toEpochMilli(),
      offset: this.offset,
      millisPerPixel: this.millisPerPixel,
      minMillisPerPixel: this.minMillisPerPixel,
      maxMillisPerPixel: this.maxMillisPerPixel,
      smallestTemporalUnit: this.smallestTemporalUnit?.toString().toUpperCase(),
      primaryTemporalUnit: this.primaryTemporalUnit?.toString().toUpperCase(),
      zoneId: this.zoneId.toString()
    };
  }
}
