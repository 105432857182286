import { defineStore, Store } from "pinia";
import { v4 as uuid } from "uuid";
import { tryOnBeforeUnmount } from "@vueuse/core";
import { UserInterfaceProfileDto } from "@masta/generated-model";

export interface GridState {
  readonly id: string;
  loading: boolean;
  isRowSelected: boolean;
  isEditing: boolean;
  isUpdating: boolean;
  editedRowIndex: number | undefined | null;
  selectedEntity: any;
  profiles: UserInterfaceProfileDto[];
  expandedRows: string[];
}

export interface GridStateActions {
  toggleLoading(): void;

  startEditing(isUpdating: boolean, editedRowIndex: number | undefined | null): void;

  stopEditing(): void;

  rowSelected(): void;

  rowDeselected(): void;

  rowExpanded(id: string): void;

  rowCollapsed(id: string): void;

  paginationChanged(): void;
}

export interface GridRegistryState {
  gridStores: Store<string, GridState, any, GridStateActions>[];
}

export interface GridRegistryActions {
  addStore(id: string): Store<string, GridState, any, GridStateActions>;

  removeStore(id: string): void;

  getStore(id: string): Store<string, GridState, any, GridStateActions> | undefined;
}

function createGridState(id: string) {
  return defineStore<any, GridState, any, GridStateActions>(`grid-${id}`, {
    state: (): GridState => ({
      id,
      loading: false,
      isRowSelected: false,
      isEditing: false,
      isUpdating: false,
      editedRowIndex: null,
      selectedEntity: null,
      profiles: [],
      expandedRows: [],
    }),
    actions: {
      toggleLoading() {
        this.loading = !this.loading;
      },
      startEditing(isUpdating: boolean, editedRowIndex: number | undefined | null) {
        this.isEditing = true;
        this.isUpdating = isUpdating;
        this.editedRowIndex = editedRowIndex;
      },
      stopEditing() {
        this.isEditing = false;
        this.isUpdating = false;
        this.editedRowIndex = null;
      },
      rowSelected() {
        this.isRowSelected = true;
      },
      rowDeselected() {
        this.isRowSelected = false;
      },
      rowExpanded(rowId: string) {
        const idx = this.expandedRows.findIndex((x) => x === rowId);
        if (idx < 0) {
          this.expandedRows.push(rowId);
        }
      },
      rowCollapsed(rowId: string) {
        const idx = this.expandedRows.findIndex((x) => x === rowId);
        if (idx > -1) {
          this.expandedRows.splice(idx, 1);
        }
      },
      paginationChanged() {
        // no operation
      }
    }
  });
}

export const useGridRegistryStore = defineStore<"grid-registry", GridRegistryState, any, GridRegistryActions>("grid-registry", {
  state: (): GridRegistryState => ({
    gridStores: []
  }),
  actions: {
    addStore(id: string = uuid()): Store<string, GridState, any, GridStateActions> {
      const idx = this.gridStores.findIndex((x: GridState) => x.id === `grid-${id}`);
      if (idx > -1) throw new Error(`Grid with ID=${id} already exists`);
      const storeDefinition = createGridState(id);
      const store = storeDefinition();
      this.gridStores.push(store);
      return store;
    },
    removeStore(id: string) {
      const idx = this.gridStores.findIndex((x: GridState) => x.id === id);
      if (idx < 0) {
        console.warn(`Grid with ID=${id} not found!`);
        return;
      }
      this.gridStores.splice(idx, 1);
    },
    getStore(id: string): Store<string, GridState, any, GridStateActions> | undefined {
      const idx = this.gridStores.findIndex((x: GridState) => x.id === id);
      if (idx > -1) {
        return this.gridStores[idx];
      }
    }
  }
});

export const useGridStore = (id: string = uuid()) => {
  const gridRegistryStore = useGridRegistryStore();
  let gridStore = gridRegistryStore.getStore(id);
  if (!gridStore) {
    gridStore = gridRegistryStore.addStore(id);

    tryOnBeforeUnmount(() => {
      gridStore?.$reset();      
      gridStore?.$dispose();
      gridRegistryStore.removeStore(id);
    });
  }

  return gridStore;
};