import ApiService from "@/services/api";
import { ServerSideDataSource } from "@/components/Grid/ServerSideDataSource";
import { CreateStepTemplateDto, GetProductTemplateStepsPaginatedQuery, IAgGridPaginationResponse, StepTemplateDto } from "@masta/generated-model";
import { IServerSideGetRowsParams } from "ag-grid-community";
import { Store } from "pinia";
import { State as ScenarioState, useScenariosStore } from "@/store/ScenariosStore";
import { asServerSideDurationFormattedString } from "@masta/shared";

type CreateProductTemplateStepsCommandType = CreateStepTemplateDto;
type UpdateProductTemplateStepsCommandType = CreateStepTemplateDto;

export class ProductTemplateDetailsServerSideDataSource extends ServerSideDataSource<
  StepTemplateDto,
  StepTemplateDto,
  CreateProductTemplateStepsCommandType,
  UpdateProductTemplateStepsCommandType,
  string
> {
  private _scenarioStore: Store<string, ScenarioState>;
  private _getSelectedProductTemplateId: () => string;

  constructor(id: string, getSelectedProductTemplateId: () => string) {
    super(id);
    this._scenarioStore = useScenariosStore();
    this._getSelectedProductTemplateId = getSelectedProductTemplateId;
  }

  async create(entity: StepTemplateDto): Promise<string> {
    const request = this.transformForCreate(entity);
    const { data } = await ApiService.productTemplates.createStep(this.selectedScenarioId(), this.selectedProductTemplateId(), request);
    return data;
  }

  async getAll(params: IServerSideGetRowsParams): Promise<IAgGridPaginationResponse<StepTemplateDto>> {
    const request = {
      ...this.constructServerRequest(params),
      scenarioId: this.selectedScenarioId(),
      taskId: this.selectedProductTemplateId()
    } as GetProductTemplateStepsPaginatedQuery;
    const response = await ApiService.productTemplates.getStepsPaginated(request);
    return response.data;
  }

  async getAllFromDetails(_: IServerSideGetRowsParams): Promise<IAgGridPaginationResponse<StepTemplateDto>> {
    const { data } = await ApiService.productTemplates.getDetails(this.selectedScenarioId(), this.selectedProductTemplateId());
    const steps = data.steps ?? [];
    return { results: steps, count: steps.length } as IAgGridPaginationResponse<StepTemplateDto>;
  }

  async getSingle(id: string): Promise<StepTemplateDto | undefined> {
    const request = {
      scenarioId: this.selectedScenarioId(),
      taskId: this.selectedProductTemplateId()
    } as GetProductTemplateStepsPaginatedQuery;
    const { data } = await ApiService.productTemplates.getStepsPaginated(request);
    return data.results?.find((s) => s.id === id);
  }

  async update(entity: StepTemplateDto): Promise<string> {
    const request = this.transformForUpdate(entity);
    const { data } = await ApiService.productTemplates.updateStep(this.selectedProductTemplateId(), entity.id, this.selectedScenarioId(), request);
    return data;
  }

  async remove(entity: StepTemplateDto): Promise<void> {
    await ApiService.productTemplates.deleteStep(this.selectedProductTemplateId(), entity.id, this.selectedScenarioId());
  }

  protected transformForCreate(listEntity: StepTemplateDto): CreateStepTemplateDto {
    return {
      id: listEntity.id,
      name: listEntity.name,
      stepType: listEntity.stepType,
      position: listEntity.position,
      processingTime: listEntity.processingTime ? asServerSideDurationFormattedString(listEntity.processingTime) : null,
      quantityPerTime: listEntity.quantityPerTime ? listEntity.quantityPerTime : null,
      resourceSpecs: null,
      modelInstances: null,
      modelInstanceBusinessIds: null
    } as CreateStepTemplateDto;
  }

  protected transformForUpdate(listEntity: StepTemplateDto): UpdateProductTemplateStepsCommandType {
    return {
      ...this.transformForCreate(listEntity),
      id: listEntity.id
    } as CreateStepTemplateDto;
  }

  private selectedScenarioId() {
    return this._scenarioStore.selectedScenario!.id;
  }

  private selectedProductTemplateId() {
    return this._getSelectedProductTemplateId();
  }
}
