<script lang="ts">
import { ICellRendererParams } from "ag-grid-community";
import { useTagsStore } from "@/store/TagsStore";
import { TagDto } from "@masta/generated-model";

export default {
  setup(props: { params: ICellRendererParams }) {
    const value = props.params.value;
    if (value === undefined || value === null || value === "") {
      return {
        value: ""
      };
    }
    if (Array.isArray(value)) {
      const tagsStore = useTagsStore();
      const tags = tagsStore.tags;
      const tagNames = value.map((tagName: string) => {
        const tag = tags.find((t: TagDto) => t.name === tagName);
        return tag ? `${tag.emoji ? `${tag.emoji} ` : ""}${tag.name}` : "";
      });
      return {
        value: tagNames.join(", ")
      };
    }
    return {
      value
    };
  }
};
</script>

<template>
  <div style="width: 100%; height: 100%; display: flex; align-items: center; justify-content: start">{{ value }}</div>
</template>
