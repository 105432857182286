import { BehaviorSubject, Observable, Subscription, TeardownLogic } from "rxjs";
import { GanttException } from "./Exceptions";

export interface ILifecycle {
  get isInitialized$(): Observable<boolean>;

  get isInitialized(): boolean;

  beforeInitialize(): Promise<void>;

  initialize(): Promise<void>;

  afterInitialize(): Promise<void>;

  beforeDestroy(): Promise<void>;

  destroy(): Promise<void>;

  afterDestroy(): Promise<void>;

  subscribe(subscription: TeardownLogic): void;

  unsubscribe(subscription: Exclude<TeardownLogic, void>): void;

  clearSubscriptions(): void;
}

export class Lifecycle implements ILifecycle {
  protected _initialized$$ = new BehaviorSubject<boolean>(false);
  protected _destroyed$$ = new BehaviorSubject<boolean>(false);
  protected _subscriptions$$: Subscription = new Subscription();

  public subscribe(subscription: TeardownLogic): void {
    this._subscriptions$$.add(subscription);
  }

  public unsubscribe(subscription: Exclude<TeardownLogic, void>): void {
    this._subscriptions$$.remove(subscription);
  }

  public clearSubscriptions(): void {
    this._subscriptions$$.unsubscribe();
    this._subscriptions$$ = new Subscription();
  }

  public get isInitialized$(): Observable<boolean> {
    return this._initialized$$.asObservable();
  }

  public get isInitialized(): boolean {
    return this._initialized$$.value;
  }

  public get isDestroyed$(): Observable<boolean> {
    return this._destroyed$$.asObservable();
  }

  public get isDestroyed(): boolean {
    return this._destroyed$$.value;
  }

  public async beforeInitialize(): Promise<void> {
  }

  public async initialize(): Promise<void> {
    if (this._initialized$$.value) {
      throw new GanttException(`Instance is already initialized!`);
    }
    await this.beforeInitialize();
    this._initialized$$.next(true);
    await this.afterInitialize();
  }

  public async afterInitialize(): Promise<void> {
  }

  public async beforeDestroy(): Promise<void> {
  }

  public async destroy(): Promise<void> {
    await this.beforeDestroy();
    this.clearSubscriptions();
    this._initialized$$.next(false);
    this._destroyed$$.next(true);
    await this.afterDestroy();
  }

  public async afterDestroy(): Promise<void> {
  }
}
