import { ChronoUnit } from "@js-joda/core";

import { ChronoUnitResolution } from "./ChronoUnitResolution";
import { DatelineModel } from "./DatelineModel";
import { Position } from "./Resolution";

export class ChronoUnitDatelineModel extends DatelineModel<ChronoUnit> {
  constructor() {
    super();
    this.addResolution(new ChronoUnitResolution(ChronoUnit.MILLIS, "EEEE, dd. MMMM yyyy, HH:mm:ss:SSS", 1, Position.TOP, Position.ONLY));
    this.addResolution(new ChronoUnitResolution(ChronoUnit.MILLIS, "EEEE, dd.MM.yy, HH:mm:ss:SSS", 1, Position.TOP, Position.ONLY));
    this.addResolution(new ChronoUnitResolution(ChronoUnit.MILLIS, "E, dd.MM.yy, HH:mm:ss:SSS", 1, Position.TOP, Position.ONLY));
    this.addResolution(new ChronoUnitResolution(ChronoUnit.MILLIS, "dd.MM.yy, HH:mm:ss:SSS", 1, Position.TOP, Position.ONLY));
    this.addResolution(new ChronoUnitResolution(ChronoUnit.MILLIS, "dd.MM, HH:mm:ss:SSS", 1, Position.TOP));
    this.addResolution(new ChronoUnitResolution(ChronoUnit.MILLIS, "SSS", 1, Position.BOTTOM));
    this.addResolution(new ChronoUnitResolution(ChronoUnit.MILLIS, "SSS", 5, Position.BOTTOM));
    this.addResolution(new ChronoUnitResolution(ChronoUnit.MILLIS, "SSS", 10, Position.BOTTOM));
    this.addResolution(new ChronoUnitResolution(ChronoUnit.MILLIS, "SSS", 15, Position.BOTTOM));
    this.addResolution(new ChronoUnitResolution(ChronoUnit.SECONDS, "EEEE, dd. MMMM yyyy, HH:mm:ss", 1, Position.TOP, Position.ONLY));
    this.addResolution(new ChronoUnitResolution(ChronoUnit.SECONDS, "EEEE, dd.MM.yy, HH:mm:ss", 1, Position.TOP, Position.ONLY));
    this.addResolution(new ChronoUnitResolution(ChronoUnit.SECONDS, "E, dd.MM.yy, HH:mm:ss", 1, Position.TOP, Position.ONLY));
    this.addResolution(new ChronoUnitResolution(ChronoUnit.SECONDS, "dd.MM.yy, HH:mm:ss", 1, Position.TOP, Position.ONLY));
    this.addResolution(new ChronoUnitResolution(ChronoUnit.SECONDS, "dd.MM, HH:mm:ss", 1, Position.MIDDLE));
    this.addResolution(new ChronoUnitResolution(ChronoUnit.SECONDS, "HH:mm:ss", 1, Position.MIDDLE));
    this.addResolution(new ChronoUnitResolution(ChronoUnit.SECONDS, "ss", 1, Position.BOTTOM));
    this.addResolution(new ChronoUnitResolution(ChronoUnit.SECONDS, "ss", 5, Position.BOTTOM));
    this.addResolution(new ChronoUnitResolution(ChronoUnit.SECONDS, "ss", 10, Position.BOTTOM));
    this.addResolution(new ChronoUnitResolution(ChronoUnit.SECONDS, "ss", 15, Position.BOTTOM));
    this.addResolution(new ChronoUnitResolution(ChronoUnit.MINUTES, "EEEE, dd. MMMM yyyy, HH:mm", 1, Position.TOP, Position.ONLY));
    this.addResolution(new ChronoUnitResolution(ChronoUnit.MINUTES, "EEEE, dd.MM.yy, HH:mm", 1, Position.TOP, Position.ONLY));
    this.addResolution(new ChronoUnitResolution(ChronoUnit.MINUTES, "E, dd.MM.yy, HH:mm", 1, Position.TOP, Position.ONLY));
    this.addResolution(new ChronoUnitResolution(ChronoUnit.MINUTES, "dd.MM.yy, HH:mm", 1, Position.TOP, Position.ONLY));
    this.addResolution(new ChronoUnitResolution(ChronoUnit.MINUTES, "dd.MM, HH:mm", 1, Position.TOP));
    this.addResolution(new ChronoUnitResolution(ChronoUnit.MINUTES, "HH:mm", 1, Position.MIDDLE));
    this.addResolution(new ChronoUnitResolution(ChronoUnit.MINUTES, "mm", 1, Position.BOTTOM));
    this.addResolution(new ChronoUnitResolution(ChronoUnit.MINUTES, "mm", 5, Position.BOTTOM));
    this.addResolution(new ChronoUnitResolution(ChronoUnit.MINUTES, "mm", 10, Position.BOTTOM));
    this.addResolution(new ChronoUnitResolution(ChronoUnit.MINUTES, "mm", 15, Position.BOTTOM));
    this.addResolution(new ChronoUnitResolution(ChronoUnit.HOURS, "EEEE, dd. MMMM yyyy, HH:mm", 1, Position.TOP, Position.ONLY));
    this.addResolution(new ChronoUnitResolution(ChronoUnit.HOURS, "EEEE, dd.MM.yy, HH:mm", 1, Position.TOP, Position.BOTTOM, Position.ONLY));
    this.addResolution(new ChronoUnitResolution(ChronoUnit.HOURS, "E, dd.MM.yy, HH:mm", 1, Position.TOP, Position.ONLY));
    this.addResolution(new ChronoUnitResolution(ChronoUnit.HOURS, "dd.MM.yy, HH:mm", 1, Position.TOP, Position.ONLY));
    this.addResolution(new ChronoUnitResolution(ChronoUnit.HOURS, "dd.MM, HH:mm", 1, Position.TOP, Position.ONLY));
    this.addResolution(new ChronoUnitResolution(ChronoUnit.HOURS, "H:mm", 1, Position.MIDDLE, Position.BOTTOM));
    this.addResolution(new ChronoUnitResolution(ChronoUnit.HOURS, "H:mm", 3, Position.MIDDLE, Position.BOTTOM));
    this.addResolution(new ChronoUnitResolution(ChronoUnit.HOURS, "H:mm", 6, Position.MIDDLE, Position.BOTTOM));
    this.addResolution(new ChronoUnitResolution(ChronoUnit.DAYS, "EEEE d. MMMM yyyy", 1, Position.TOP, Position.ONLY));
    this.addResolution(new ChronoUnitResolution(ChronoUnit.DAYS, "EEEE d. MMMM yy", 1, Position.TOP, Position.ONLY));
    this.addResolution(new ChronoUnitResolution(ChronoUnit.DAYS, "E, d. MMMM yy", 1, Position.TOP, Position.ONLY));
    this.addResolution(new ChronoUnitResolution(ChronoUnit.DAYS, "E, d. MMMM", 1, Position.TOP, Position.ONLY));
    this.addResolution(new ChronoUnitResolution(ChronoUnit.DAYS, "E, dd.MM.yy", 1, Position.TOP, Position.ONLY));
    this.addResolution(new ChronoUnitResolution(ChronoUnit.DAYS, "EEEE dd", 1, Position.MIDDLE, Position.BOTTOM));
    this.addResolution(new ChronoUnitResolution(ChronoUnit.DAYS, "E dd", 1, Position.MIDDLE, Position.BOTTOM));
    this.addResolution(new ChronoUnitResolution(ChronoUnit.DAYS, "dd.MM", 1, Position.MIDDLE, Position.BOTTOM));
    this.addResolution(new ChronoUnitResolution(ChronoUnit.DAYS, "dd", 1, Position.BOTTOM));
    this.addResolution(new ChronoUnitResolution(ChronoUnit.WEEKS, "'W' w, EEEE d. MMMM yy", 1, Position.TOP, Position.ONLY));
    this.addResolution(new ChronoUnitResolution(ChronoUnit.WEEKS, "'W' w, d. MMMM yy", 1, Position.TOP, Position.ONLY));
    this.addResolution(new ChronoUnitResolution(ChronoUnit.WEEKS, "'W' w, d. MMMM", 1, Position.TOP));
    this.addResolution(new ChronoUnitResolution(ChronoUnit.WEEKS, "'W' w, E, dd.MM.yy", 1, Position.TOP, Position.ONLY));
    this.addResolution(new ChronoUnitResolution(ChronoUnit.WEEKS, "'W' w, dd.MM.yy", 1, Position.TOP, Position.ONLY));
    this.addResolution(new ChronoUnitResolution(ChronoUnit.WEEKS, "'W' w, dd.MM", 1, Position.BOTTOM));
    this.addResolution(new ChronoUnitResolution(ChronoUnit.WEEKS, "'W' w", 1, Position.MIDDLE, Position.BOTTOM));
    this.addResolution(new ChronoUnitResolution(ChronoUnit.MONTHS, "MMMM yyyy", 1, Position.TOP, Position.ONLY));
    this.addResolution(new ChronoUnitResolution(ChronoUnit.MONTHS, "MMMM", 1, Position.MIDDLE, Position.BOTTOM));
    this.addResolution(new ChronoUnitResolution(ChronoUnit.MONTHS, "MMM", 1, Position.MIDDLE, Position.BOTTOM));
    this.addResolution(new ChronoUnitResolution(ChronoUnit.MONTHS, "M", 1, Position.MIDDLE, Position.BOTTOM));
    this.addResolution(new ChronoUnitResolution(ChronoUnit.YEARS, "yyyy", 1, Position.TOP));
    this.addResolution(new ChronoUnitResolution(ChronoUnit.DECADES, "yyyy", 1, Position.TOP));
    this.addResolution(new ChronoUnitResolution(ChronoUnit.CENTURIES, "yyyy", 1, Position.TOP));
    this.addResolution(new ChronoUnitResolution(ChronoUnit.MILLENNIA, "yyyy", 1, Position.TOP));
  }

  nextTemporalUnit(temporalUnit: ChronoUnit): ChronoUnit {
    // eslint-disable-next-line default-case
    switch (temporalUnit) {
      case ChronoUnit.NANOS:
        return ChronoUnit.MICROS;
      case ChronoUnit.MICROS:
        return ChronoUnit.MILLIS;
      case ChronoUnit.MILLIS:
        return ChronoUnit.SECONDS;
      case ChronoUnit.SECONDS:
        return ChronoUnit.MINUTES;
      case ChronoUnit.MINUTES:
        return ChronoUnit.HOURS;
      case ChronoUnit.HOURS:
        return ChronoUnit.DAYS;
      case ChronoUnit.DAYS:
        return ChronoUnit.WEEKS;
      case ChronoUnit.WEEKS:
        return ChronoUnit.MONTHS;
      case ChronoUnit.MONTHS:
        return ChronoUnit.YEARS;
      case ChronoUnit.YEARS:
        return ChronoUnit.DECADES;
      case ChronoUnit.DECADES:
        return ChronoUnit.CENTURIES;
      case ChronoUnit.CENTURIES:
        return ChronoUnit.MILLENNIA;
    }
    return null!;
  }
}
