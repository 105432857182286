import type { IGanttWorker } from "../Core";
import {
  GanttEvents,
  GanttException,
  GanttSettings,
  IActivity,
  IActivityDataLoaderOperation,
  IActivityFactory,
  IChartDataLoaderOperation,
  ILayer,
  IocContainer,
  IocSymbols,
  IRow,
  IRowChartData,
  IRowFactory,
  Layer,
  Lifecycle,
  ObservableDataSet,
  Row,
  RowChartRepository,
  SettingKey,
  TimelineManager
} from "../Core";
import { inject, injectable } from "inversify";
import type { IDataLoaderActivityOperation } from "../Worker";
import type { Remote } from "comlink";
import { proxy } from "comlink";
import { BehaviorSubject } from "rxjs";
import { Instant } from "@js-joda/core";

@injectable()
export class RowManager extends Lifecycle {
  constructor(
    @inject(IocSymbols.LayersSymbol) private _layers: BehaviorSubject<Layer[]>,
    @inject(IocSymbols.GanttWorkerSymbol) private _ganttWorkerWrapped: Remote<IGanttWorker>,
    @inject(IocSymbols.RowsSymbol) private _rows: ObservableDataSet<Row<any, any, any>>,
    @inject(IocContainer) private _iocContainer: IocContainer,
    @inject(GanttSettings) private _ganttSettings: GanttSettings,
    @inject(RowChartRepository) private _rowChartRepository: RowChartRepository,
    @inject(GanttEvents) private _ganttEvents: GanttEvents,
    @inject(TimelineManager) private _timelineManager: TimelineManager
  ) {
    super();
  }

  async afterInitialize(): Promise<void> {
    const worker = this._ganttWorkerWrapped;
    await worker.onLayersModified(proxy(this.onLayersChanged.bind(this)));
    await worker.onRowsAdded(proxy(this.onRowsAdded.bind(this)));
    await worker.onRowsUpdated(proxy(this.onRowsUpdated.bind(this)));
    await worker.onRowsDeleted(proxy(this.onRowsDeleted.bind(this)));
    await worker.onActivitiesOperation(proxy(this.onActivitiesOperation.bind(this)));
    await worker.onChartDataOperation(proxy(this.onChartDataOperation.bind(this)));
    // await worker.onActivitiesAdded(proxy(this.onActivitiesAdded.bind(this)));
    // await worker.onActivitiesRemoved(proxy(this.onActivitiesRemoved.bind(this)));
    // await worker.onRowChartDataChanged(proxy(this.onRowChartDataChanged.bind(this)));
  }

  get rows(): ObservableDataSet<Row<any, any, any>> {
    return this._rows;
  }

  private onLayersChanged(layers: ILayer[]): void {
    const currentLayers = this._layers.value;
    const existing = currentLayers.filter((x) => layers.map((l) => l.id).includes(x.id));
    const removed = currentLayers.filter((x) => !layers.map((l) => l.id).includes(x.id));
    const added = layers
      .filter((x) => !currentLayers.map((l) => l.id).includes(x.id))
      .map((x) => {
        const layer = new Layer(x.name, x.id);
        layer.visible = x.visible;
        layer.opacity = x.opacity;
        layer.disablePointerEvents = x.disablePointerEvents;
        return layer;
      });
    for (const layer of existing) {
      const changedLayer = layers.find((x) => x.id === layer.id);
      if (changedLayer) {
        layer.visible = changedLayer.visible;
        layer.opacity = changedLayer.opacity;
        layer.disablePointerEvents = changedLayer.disablePointerEvents;
      }
    }
    this._layers.next([...existing, ...added]);
  }

  private onRowsAdded(rows: IRow[]): void {
    this._iocContainer.getAsync<IRowFactory>(IocSymbols.RowFactorySymbol).then((factory) => {
      for (const row of rows) {
        const rowInstance = factory.createRow(row, [...this._rows.data.values()]);
        if (this._ganttSettings.getSetting<boolean>(SettingKey.ROW_EXPAND_BY_DEFAULT)) {
          rowInstance.expanded = true;
        }
        this._rows.add(rowInstance);
      }
    });
  }

  private onRowsUpdated(rows: IRow[]): void {
    for (const row of rows) {
      this.onRowUpdated(row);
    }
  }

  private onRowUpdated(row: IRow): void {
    const resourceRow = this._rows.data.get(row.id) as Row<any, any, any> | undefined;
    if (resourceRow) {
      if (row.parentId !== resourceRow.parentId) {
        throw new GanttException("Changing parent of a row is not supported");
      }
      // const removedIds = resourceRow.childrenIds.filter((childId) => row.childrenIds.includes(childId));
      // console.log("removedIds", removedIds);
      // for (const removedId of removedIds) {
      //   const child = this._rows.data.get(removedId);
      //   if (child) {
      //     resourceRow.removeChild(child);
      //   }
      // }
      // this._rows.remove(removedIds);
    }
  }

  private onRowsDeleted(rowIds: string[]): void {
    for (const rowId of rowIds) {
      this.onRowDeleted(rowId);
    }
  }

  private onRowDeleted(rowId: string): void {
    const r = this._rows.data.get(rowId);
    const parent: Row<any, any, any> | undefined = r?.parent;
    if (parent) {
      parent.removeChild(rowId);
      parent.childrenIds.splice(parent.childrenIds.indexOf(rowId), 1);
    }
    this._rows.remove(rowId);
  }

  private onActivitiesOperation(operation: IActivityDataLoaderOperation): void {
    const layer = this._layers.value.find((x) => x.id === operation.layerId);
    if (!layer) {
      throw new GanttException(`Layer with id ${operation.layerId} not found`);
    }
    const row = this._rows.data.get(operation.rowId);
    if (!row) {
      throw new GanttException(`Row with id ${operation.layerId} not found`);
    }
    if (row) {
      this._iocContainer.getAsync<IActivityFactory>(IocSymbols.ActivityFactory).then((factory) => {
        const operationStartDate = Instant.ofEpochMilli(operation.startDate);
        const operationEndDate = Instant.ofEpochMilli(operation.endDate);
        if (operation.activities.length === 0) {
          const existingActs = row.repository.getActivities(layer, operationStartDate, operationEndDate, this._timelineManager.primaryTemporalUnit, row.zoneId) as IActivity[];
          if (existingActs.length > 0) {
            row.removeActivities(layer, existingActs);
          }
          return;
        }
        const operationActIds = operation.activities.map((a) => a.id);

        const existingActs = row.repository.getActivities(layer, operationStartDate, operationEndDate, this._timelineManager.primaryTemporalUnit, row.zoneId) as IActivity[];
        const existingActIds = (existingActs as any[]).map((x) => x.id);
        const newActivities = operation.activities.filter((x) => !existingActIds.includes(x.id)).map((x) => factory.createActivity(x));
        const removedActivities = existingActs.filter((x) => !operationActIds.includes(x.id));

        row.addActivities(layer, newActivities);
        row.removeActivities(layer, removedActivities);
      });
    }
  }

  private onActivitiesAdded(operation: IDataLoaderActivityOperation): void {
    const layer = this._layers.value.find((x) => x.id === operation.layerId);
    if (!layer) {
      throw new GanttException(`Layer with id ${operation.layerId} not found`);
    }
    const row = this._rows.data.get(operation.rowId);
    if (!row) {
      throw new GanttException(`Row with id ${operation.layerId} not found`);
    }
    if (row) {
      this._iocContainer.getAsync<IActivityFactory>(IocSymbols.ActivityFactory).then((factory) => {
        if (operation.addedActivities.length === 0) {
          return;
        }
        const sortedStartTime = operation.addedActivities.sort((a, b) => a.startTime - b.endTime);
        const sortedEndTime = operation.addedActivities.sort((a, b) => a.endTime - b.endTime);
        const startTime = Instant.ofEpochMilli(sortedStartTime[0].startTime);
        const endTime = Instant.ofEpochMilli(sortedEndTime[sortedEndTime.length - 1].endTime);
        const existingActivities = row.repository.getActivities(layer, startTime, endTime, this._timelineManager.primaryTemporalUnit, row.zoneId);
        const existingIds = (existingActivities as any[]).map((x) => x.id);
        const newActivities = operation.addedActivities.filter((x) => !existingIds.includes(x.id));
        const activities = newActivities.map((x) => factory.createActivity(x));
        row.addActivities(layer, activities);
      });
    }
  }

  private onActivitiesRemoved(operation: IDataLoaderActivityOperation): void {
    const layer = this._layers.value.find((x) => x.id === operation.layerId);
    if (!layer) {
      throw new GanttException(`Layer with id ${operation.layerId} not found`);
    }
    const row = this._rows.data.get(operation.rowId);
    if (!row) {
      throw new GanttException(`Row with id ${operation.layerId} not found`);
    }
    if (row) {
      const activities = row.repository.getActivitiesById(layer, operation.removedActivityIds);
      row.removeActivities(layer, activities);
    }
  }

  private onChartDataOperation(operation: IChartDataLoaderOperation): void {
    // const startTime = Instant.ofEpochMilli(operation.startDate);
    // const endTime = Instant.ofEpochMilli(operation.endDate);

    for (const cd of operation.chartData) {
      this._rowChartRepository.setData({
        ...cd,
        positiveColor: this._ganttSettings.getSetting(SettingKey.CHART_POSITIVE_COLOR),
        negativeColor: this._ganttSettings.getSetting(SettingKey.CHART_NEGATIVE_COLOR),
        lineWidth: this._ganttSettings.getSetting<number>(SettingKey.CHART_LINE_WIDTH)
      });
    }
  }

  private onRowChartDataChanged(datasets: IRowChartData[]) {
    datasets.forEach((dataset) => {
      this._rowChartRepository.setData({
        ...dataset,
        positiveColor: this._ganttSettings.getSetting(SettingKey.CHART_POSITIVE_COLOR),
        negativeColor: this._ganttSettings.getSetting(SettingKey.CHART_NEGATIVE_COLOR),
        lineWidth: this._ganttSettings.getSetting<number>(SettingKey.CHART_LINE_WIDTH)
      });
    });
  }
}
