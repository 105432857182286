import { ApiClient } from "@/services/api/common.api";
import { AvailabilityRuleDataDto, CreateOrUpdateAvailabilityRuleCommand, GetAvailabilityRulesPaginatedQuery, IAgGridPaginationResponse } from "@masta/generated-model";

export default {
  async getPaginated(request: GetAvailabilityRulesPaginatedQuery) {
    return ApiClient.post<IAgGridPaginationResponse<AvailabilityRuleDataDto>>(`/availabilityRule`, request);
  },
  async get(id: string, scenarioId: string) {
    return ApiClient.get<AvailabilityRuleDataDto>(`/availabilityRule/${scenarioId}/${id}`);
  },
  async create(request: CreateOrUpdateAvailabilityRuleCommand) {
    return ApiClient.put<string>(`/availabilityRule`, request);
  },
  async update(request: CreateOrUpdateAvailabilityRuleCommand) {
    return ApiClient.put(`/availabilityRule/${request.id}`, request);
  },
  async remove(id: string, scenarioId: string) {
    return ApiClient.delete(`/availabilityRule/${id}?scenarioId=${scenarioId}`);
  }
};
