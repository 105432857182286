import { Observable, Subject } from "rxjs";
import { RepositoryActivityAddedEvent } from "./RepositoryActivityAddedEvent";
import { RepositoryActivityRemovedEvent } from "./RepositoryActivityRemovedEvent";
import { ActivityRef } from "../ActivityRef";
import { IActivity } from "../IActivity";
import { Layer } from "../Layer";
import { ActivityRepositoryBase } from "./ActivityRepositoryBase";
import { IMutableActivityRepository } from "./IMutableActivityRepository";

export abstract class MutableActivityRepositoryBase<TActivity extends IActivity> extends ActivityRepositoryBase<TActivity> implements IMutableActivityRepository<TActivity> {
  protected _addEvents$$ = new Subject<RepositoryActivityAddedEvent>();

  protected _removeEvents$$ = new Subject<RepositoryActivityRemovedEvent>();

  abstract addActivity(activityRef: ActivityRef<TActivity>): void;

  abstract clearActivities(): void;

  abstract clearLayerActivities(layer: Layer): void;

  abstract removeActivity(activityRef: ActivityRef<TActivity>): void;

  get addEvent$(): Observable<RepositoryActivityAddedEvent> {
    return this._addEvents$$.asObservable();
  }

  get removeEvent$(): Observable<RepositoryActivityRemovedEvent> {
    return this._removeEvents$$.asObservable();
  }

  abstract addActivities(activityRef: ActivityRef<TActivity>[]): void;

  abstract removeActivities(activityRef: ActivityRef<TActivity>[]): void;
}
