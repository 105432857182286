<script lang="ts" setup>
import { ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";

const dialog = ref(false);

const router = useRouter();
const route = useRoute();

watch(
  route,
  (r) => {
    if (r.hash === "#eula") {
      dialog.value = true;
    }
  },
  { immediate: true }
);

watch(dialog, (d) => {
  if (!d && route.hash === "#eula") {
    router.push({ hash: "" });
  }
});
</script>

<template>
  <v-dialog v-model="dialog" max-width="80vw">
    <v-card>
      <v-card-text>
        <p><span style="font-size:11pt"><span style="font-family:Cambria,serif"><strong><span dir="ltr" lang="EN-GB" style="font-size:18.0pt"><span style="font-family:Roboto"><span style="color:#222222">END USER LICENSE AGREEMENT (EULA) for PIQ-ME-NOW</span></span></span></strong></span></span></p>

        <p><br />
          <span style="font-size:11pt"><span style="font-family:Cambria,serif"><span dir="ltr" lang="EN-GB" style="font-size:12.0pt"><span style="font-family:Roboto"><span style="color:#222222">NOTICE: READ THIS END-USER LICENSE AGREEMENT (HEREINAFTER REFERRED TO AS &quot;<strong>EULA</strong>&quot;) CAREFULLY BEFORE STARTING USING THE SOFTWARE. THIS END-USER LICENSES AGREEMENT IS A LEGAL AGREEMENT, EFFECTIVE AS OF THE DATE YOU ACCEPT THE TERMS HEREOF, IS ENTERED INTO BETWEEN YOURSELF (HEREINAFTER REFERRED TO AS &quot;<strong>THE LICENSEE</strong>&quot;) AND MASTA SOLUTIONS, REGISTERED IN POLAND UNDER THE ADDRESS: UL. POŁUDNIOWA 72C, 62-081 PRZEŹMIEROWO (HEREINAFTER REFERRED TO AS &quot;<strong>THE LICENSOR</strong>&quot; OR &ldquo;<strong>MASTA</strong>&rdquo;).</span></span></span><br />
<br />
<span dir="ltr" lang="EN-GB" style="font-size:12.0pt"><span style="font-family:Roboto"><span style="color:#222222">CLICKING ON THE &quot;I ACCEPT&quot; BUTTON OR IN ANY OTHER WAY ACCESSING, COPYING OR USING THE LICENSED PRODUCT, CREATES A LEGALLY ENFORCEABLE CONTRACT AND CONSTITUTES ACCEPTANCE OF ALL TERMS AND CONDITIONS OF THE AGREEMENT WITHOUT MODIFICATION.</span></span></span></span></span></p>

        <div style="text-align:center">
          <hr /></div>

        <p><span style="font-size:11pt"><span style="font-family:Cambria,serif"><strong><span dir="ltr" lang="EN-GB" style="font-size:15.0pt"><span style="font-family:Roboto"><span style="color:#222222">Definitions</span></span></span></strong><br />
<br />
<strong><span dir="ltr" lang="EN-GB" style="font-size:12.0pt"><span style="font-family:Roboto"><span style="color:#222222">Licensee</span></span></span></strong></span></span></p>

        <p><span style="font-size:11pt"><span style="font-family:Cambria,serif"><span dir="ltr" lang="EN-GB" style="font-size:12.0pt"><span style="font-family:Roboto"><span style="color:#222222">an entrepreneur (legal entity or natural person) which enters into this EULA by acquiring the User Licence. The Licence is not dedicated to natural persons that are not entrepreneurs and they cannot enter this EULA.</span></span></span></span></span><br />
          &nbsp;</p>

        <p><span style="font-size:11pt"><span style="font-family:Cambria,serif"><strong><span dir="ltr" lang="EN-GB" style="font-size:12.0pt"><span style="font-family:Roboto"><span style="color:#222222">Authorized User</span></span></span></strong></span></span></p>

        <p><span style="font-size:11pt"><span style="font-family:Cambria,serif"><span dir="ltr" lang="EN-GB" style="font-size:12.0pt"><span style="font-family:Roboto"><span style="color:#222222">means a person who accesses and uses the Software under the User License obtained by the Licensee. </span></span></span></span></span></p>

        <p>&nbsp;</p>

        <p><span style="font-size:11pt"><span style="font-family:Cambria,serif"><strong><span dir="ltr" lang="EN-GB" style="font-size:12.0pt"><span style="font-family:Roboto"><span style="color:#222222">User License</span></span></span></strong><br />
<span dir="ltr" lang="EN-GB" style="font-size:12.0pt"><span style="font-family:Roboto"><span style="color:#222222">means a license to use the Software obtained or renewed by the Licensee, granted in exchange for the applicable Subscription Fee. A single User Licence grants a right for use and access to the Software of a maximum 10 (ten) Authorized Users. Each Authorised User can use the Software as a single main client and a single terminal user.</span></span></span></span></span></p>

        <p>&nbsp;</p>

        <p><span style="font-size:11pt"><span style="font-family:Cambria,serif"><strong><span dir="ltr" lang="EN-GB" style="font-size:12.0pt"><span style="font-family:Roboto"><span style="color:#222222">Maintenance</span></span></span></strong><br />
<span dir="ltr" lang="EN-GB" style="font-size:12.0pt"><span style="font-family:Roboto"><span style="color:#222222">the provision by the Licensor to the Licensee of the Software updates made through the i4FS.</span></span></span></span></span></p>

        <p>&nbsp;</p>

        <p><span style="font-size:11pt"><span style="font-family:Cambria,serif"><strong><span dir="ltr" lang="EN-GB" style="font-size:12.0pt"><span style="font-family:Roboto"><span style="color:#222222">Support Services</span></span></span></strong></span></span></p>

        <p><span style="font-size:11pt"><span style="font-family:Cambria,serif"><span dir="ltr" lang="EN-GB" style="font-size:12.0pt"><span style="font-family:Roboto"><span style="color:#222222">online and phone technical support served by the Licensor relating to the use of the Software.</span></span></span></span></span></p>

        <p><br />
          <span style="font-size:11pt"><span style="font-family:Cambria,serif"><strong><span dir="ltr" lang="EN-GB" style="font-size:12.0pt"><span style="font-family:Roboto"><span style="color:#222222">Purchase process</span></span></span></strong><br />
<span dir="ltr" lang="EN-GB" style="font-size:12.0pt"><span style="font-family:Roboto"><span style="color:#222222">means the purchase of the right to use Software by means of i4FS, which may result in a License for the Software.</span></span></span></span></span></p>

        <p>&nbsp;</p>

        <p><span style="font-size:11pt"><span style="font-family:Cambria,serif"><strong><span dir="ltr" lang="EN-GB" style="font-size:12.0pt"><span style="font-family:Roboto"><span style="color:#222222">Software/Licensed Product</span></span></span></strong><br />
<span dir="ltr" lang="EN-GB" style="font-size:12.0pt"><span style="font-family:Roboto"><span style="color:#222222">means computer software products, developed by MASTA, in particular the PIQ-ME-NOW application and all related documentation, any modifications and updates, which is acquired by the Licensee.</span></span></span></span></span></p>

        <p>&nbsp;</p>

        <p><span style="font-size:11pt"><span style="font-family:Cambria,serif"><strong><span dir="ltr" lang="EN-GB" style="font-size:12.0pt"><span style="font-family:Roboto"><span style="color:#222222">I4FS</span></span></span></strong></span></span></p>

        <p><span style="font-size:11pt"><span style="font-family:Cambria,serif"><span dir="ltr" lang="EN-GB" style="font-size:12.0pt"><span style="font-family:Roboto"><span style="color:#222222">Marketplace provided by the company Industry 4.0 Factory Solutions established under the laws of England, with Company Registration number 12235902, and with registered office in 37 Crewe Road, Haslington, Crewe CW1 5QR, UK.</span></span></span></span></span></p>

        <p>&nbsp;</p>

        <p><span style="font-size:11pt"><span style="font-family:Cambria,serif"><strong><span dir="ltr" lang="EN-GB" style="font-size:12.0pt"><span style="font-family:Roboto"><span style="color:#222222">Confidential Information</span></span></span></strong></span></span></p>

        <p><span style="font-size:11pt"><span style="font-family:Cambria,serif"><a href="https://www.lawinsider.com/clause/definition-of-confidential-information" style="color:blue; text-decoration:underline"><span dir="ltr" lang="EN-GB" style="font-size:12.0pt"><span style="font-family:Roboto"><span style="color:#222222">All</span></span></span></a><span dir="ltr" lang="EN-GB" style="font-size:12.0pt"><span style="font-family:Roboto"><span style="color:#222222"> information disclosed by a party of this EULA to the other party, whether orally or in writing, that is designated as confidential or that reasonably should be understood to be confidential given the nature of the information and the circumstances of disclosure. In particular, the Confidential Information is data related to the business of the Licensee such as e.g. production recipes, technologies, personnel data and related to the business of the Licensor, such as data models and technologies used for the Software, which were not made public.&nbsp; </span></span></span></span></span></p>

        <p>&nbsp;</p>

        <p><span style="font-size:11pt"><span style="font-family:Cambria,serif"><strong><span dir="ltr" lang="EN-GB" style="font-size:12.0pt"><span style="font-family:Roboto"><span style="color:#222222">GDPR</span></span></span></strong> </span></span></p>

        <p><span style="font-size:11pt"><span style="font-family:Cambria,serif"><span dir="ltr" lang="EN-GB" style="font-size:12.0pt"><span style="font-family:Roboto"><span style="color:#222222">Regulation (EU) 2016/679 of the European Parliament and of the Council of 27 April 2016 on the protection of natural persons with regard to the processing of personal data and on the free movement of such data, and repealing Directive 95/46/EC (General Data Protection Regulation).</span></span></span></span></span></p>

        <p>&nbsp;</p>

        <p><span style="font-size:11pt"><span style="font-family:Cambria,serif"><strong><span dir="ltr" lang="EN-GB" style="font-size:18.0pt"><span style="font-family:Roboto"><span style="color:#222222">License</span></span></span></strong><br />
<br />
<strong><span dir="ltr" lang="EN-GB" style="font-size:12.0pt"><span style="font-family:Roboto"><span style="color:#222222">Grant of the Licence.</span></span></span></strong><span dir="ltr" lang="EN-GB" style="font-size:12.0pt"><span style="font-family:Roboto"><span style="color:#222222">&nbsp;Subject to all terms and conditions in this EULA, the Licensor grants the Licensee a non-exclusive, non-transferable, non-sublicensable, revocable and limited right to use the Software by the Authorised Users in the period</span></span></span>&nbsp;<span dir="ltr" lang="EN-GB" style="font-size:12.0pt"><span style="font-family:Roboto"><span style="color:#222222">of time agreed within the Purchase process. The Licensee obtain the right at the moment of paying the Subscription Fee.</span></span></span></span></span><br />
          &nbsp;</p>

        <p><span style="font-size:11pt"><span style="font-family:Cambria,serif"><strong><span dir="ltr" lang="EN-GB" style="font-size:12.0pt"><span style="font-family:Roboto"><span style="color:#222222">Restrictions. </span></span></span></strong><span dir="ltr" lang="EN-GB" style="font-size:12.0pt"><span style="font-family:Roboto"><span style="color:#222222">A Licensee agrees not to, and will not permit others to:</span></span></span> <span dir="ltr" lang="EN-GB" style="font-size:12.0pt"><span style="font-family:Roboto"><span style="color:#222222">license, sell, rent, lease, assign, distribute, transmit, host, outsource, disclose or make the Software available to any third party.</span></span></span></span></span></p>

        <p style="text-align:justify"><br />
          <span style="font-size:11pt"><span style="font-family:Cambria,serif"><strong><span dir="ltr" lang="EN-GB" style="font-size:12.0pt"><span style="font-family:Roboto"><span style="color:#222222">Limitations.</span></span></span></strong><span dir="ltr" lang="EN-GB" style="font-size:12.0pt"><span style="font-family:Roboto"><span style="color:#222222">&nbsp;Licensed Products may only be used for the internal business purposes of the Licensee. Number of Authorized Users accessing and using the Software should never exceed the number of users for which the User Licence has been acquired and applicable Subscription Fees have been paid.</span></span></span></span></span></p>

        <p style="text-align:justify"><br />
          <span style="font-size:11pt"><span style="font-family:Cambria,serif"><strong><span dir="ltr" lang="EN-GB" style="font-size:12.0pt"><span style="font-family:Roboto"><span style="color:#222222">Payments.</span></span></span></strong><span dir="ltr" lang="EN-GB" style="font-size:12.0pt"><span style="font-family:Roboto"><span style="color:#222222">&nbsp;The Licensee shall pay (or cause to be paid to) the Licensor any and all Subscription Fees for the User Licences, in the amounts and at the times agreed by the Licensor during the Purchase process including all taxes, duties, customs or similar charges that the Licensor or/and i4FS are obliged to collect during the Purchase process. The Licensee is obliged to cover any other due charges according to applicable law.</span></span></span></span></span></p>

        <p style="text-align:justify"><br />
          <span style="font-size:11pt"><span style="font-family:Cambria,serif"><strong><span dir="ltr" lang="EN-GB" style="font-size:12.0pt"><span style="font-family:Roboto"><span style="color:#222222">Intellectual property rights. </span></span></span></strong><span dir="ltr" lang="EN-GB" style="font-size:12.0pt"><span style="font-family:Roboto"><span style="color:#222222">The Software is licensed, not sold and the Licensee does not acquire any rights of ownership in the Software. MASTA retains all intellectual property rights, title and interest in and to the Software, as well as all intellectual property rights in and to the Software not expressly granted in the Agreement. The Software with its trademarks, logos, designs, and service marks appearing while using the Software, is protected by copyright and other intellectual property laws and treaties. The Licensee does not acquire any rights of ownership in the Software here under. </span></span></span></span></span></p>

        <p style="text-align:justify"><br />
          <span style="font-size:11pt"><span style="font-family:Cambria,serif"><strong><span dir="ltr" lang="EN-GB" style="font-size:12.0pt"><span style="font-family:Roboto"><span style="color:#222222">Feedback.</span></span></span></strong><span dir="ltr" lang="EN-GB" style="font-size:12.0pt"><span style="font-family:Roboto"><span style="color:#222222"> From time to time, Licensee may choose to submit comments, information, questions, data, ideas, description of processes, or other information to MASTA (&quot;Feedback&quot;). MASTA may in connection with any of its products or services freely use, copy, disclose, license, distribute and exploit any Feedback in any manner without any obligation, royalty or restriction based on intellectual property rights or otherwise. No Feedback will be considered Licensee&#39;s Confidential Information, and nothing in this EULA limits MASTA&rsquo;s right to independently use, develop, evaluate, or market products, whether incorporating Feedback or otherwise.</span></span></span></span></span></p>

        <p style="text-align:justify"><br />
          <span style="font-size:11pt"><span style="font-family:Cambria,serif"><strong><span dir="ltr" lang="EN-GB" style="font-size:12.0pt"><span style="font-family:Roboto"><span style="color:#222222">Warranties and Disclaimer</span></span></span></strong></span></span></p>

        <p style="text-align:justify"><br />
          <span style="font-size:11pt"><span style="font-family:Cambria,serif"><span dir="ltr" lang="EN-GB" style="font-size:12.0pt"><span style="font-family:Roboto"><span style="color:#222222">The Licensor represents and warrants that:</span></span></span></span></span></p>

        <ul style="margin-left: 24px;">
          <li style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Cambria,serif"><span dir="ltr" lang="EN-GB" style="font-size:12.0pt"><span style="font-family:Roboto"><span style="color:#222222">it has the right to enter into this EULA and provide the Maintenance and Support Services (if any) as contemplated by this EULA;</span></span></span></span></span></li>
          <li style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Cambria,serif"><span dir="ltr" lang="EN-GB" style="font-size:12.0pt"><span style="font-family:Roboto"><span style="color:#222222">the Maintenance and Support Services (if any) shall be performed with reasonable care, skill and diligence;</span></span></span></span></span></li>
          <li style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Cambria,serif"><span dir="ltr" lang="EN-GB" style="font-size:12.0pt"><span style="font-family:Roboto"><span style="color:#222222">the Software, Maintenance and Support Services (if any) shall comply with all applicable laws, regulatory requirements, mandatory standards and codes of practice of any competent authority for the time being in force;</span></span></span></span></span></li>
          <li style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Cambria,serif"><span dir="ltr" lang="EN-GB" style="font-size:12.0pt"><span style="font-family:Roboto"><span style="color:#222222">it shall not knowingly introduce into any the Software any computer software routine intended or designed to disable, damage, erase, disrupt or impair the normal operation of, or provide unauthorized access to or modification or monitoring of, any computer system or any software or information stored on any computer system, including viruses, worms, time bombs, time locks, drop-dead devices, access codes, security keys, back doors or trap door devices;</span></span></span></span></span></li>
          <li style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Cambria,serif"><span dir="ltr" lang="EN-GB" style="font-size:12.0pt"><span style="font-family:Roboto"><span style="color:#222222">the Software does not contain any restrictive software; </span></span></span></span></span></li>
          <li style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Cambria,serif"><span dir="ltr" lang="EN-GB" style="font-size:12.0pt"><span style="font-family:Roboto"><span style="color:#222222">it will protect Confidential Information of the Licensee during the term of the EULA and after its termination and will only use it for the purpose of implementing the provisions of EULA</span></span></span></span></span></li>
          <li style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Cambria,serif"><span dir="ltr" lang="EN-GB" style="font-size:12.0pt"><span style="font-family:Roboto"><span style="color:#222222">it will protect the personal data of the Licensee and their employees that it could have access to during the term of the EULA and after its termination, according to GDPR</span></span></span></span></span></li>
          <li style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Cambria,serif"><span dir="ltr" lang="EN-GB" style="font-size:12.0pt"><span style="font-family:Roboto"><span style="color:#222222">the Software shall perform substantially in accordance with the documentation for a period of 99% of time, provided that this warranty shall not apply to error or failure resulting from: Licensee&rsquo;s machine error; the Licensee&#39;s failure to follow operating instructions; negligence or accident by any person or entity other than the Licensor; or modifications to the Software by any person or entity other than the Licensor. </span></span></span></span></span></li>
        </ul>

        <p style="margin-left:51px">&nbsp;</p>

        <p><span style="font-size:11pt"><span style="font-family:Cambria,serif"><span dir="ltr" lang="EN-GB" style="font-size:12.0pt"><span style="font-family:Roboto"><span style="color:#222222">The Licensee represents and warrants that:</span></span></span></span></span></p>

        <ul style="margin-left: 24px;">
          <li><span style="font-size:11pt"><span style="font-family:Cambria,serif"><span dir="ltr" lang="EN-GB" style="font-size:12.0pt"><span style="font-family:Roboto"><span style="color:#222222">it has the right to enter into this EULA and to perform its obligations as contemplated by this agreement;</span></span></span></span></span></li>
          <li><span style="font-size:11pt"><span style="font-family:Cambria,serif"><span dir="ltr" lang="EN-GB" style="font-size:12.0pt"><span style="font-family:Roboto"><span style="color:#222222">in the performance of its obligations under this EULA, it shall comply with all applicable laws, regulatory requirements, mandatory standards and codes of practice of any competent authority for the time being in force,</span></span></span></span></span></li>
          <li style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Cambria,serif"><span dir="ltr" lang="EN-GB" style="font-size:12.0pt"><span style="font-family:Roboto"><span style="color:#222222">it will protect Confidential Information of the Licensor during the term of the EULA and after its termination and will only use it for the purpose of implementing the provisions of EULA.</span></span></span></span></span></li>
        </ul>

        <p style="margin-left:24px">&nbsp;</p>

        <p><span style="font-size:11pt"><span style="font-family:Cambria,serif"><span dir="ltr" lang="EN-GB" style="font-size:12.0pt"><span style="font-family:Roboto"><span style="color:#222222">The Licensor does not warrant that the operation of the Software will be uninterrupted or error-free. The Licensor provides the Software on an &quot;as is&quot; basis and all warranties not expressly set out in this EULA, including any warranties of title, non-infringement, merchantability and fitness for a particular purpose, are disclaimed to the fullest extent permitted by law.</span></span></span></span></span></p>

        <p><span style="font-size:11pt"><span style="font-family:Cambria,serif"><span dir="ltr" lang="EN-GB" style="font-size:12.0pt"><span style="font-family:Roboto"><span style="color:#222222">The Licensor shall use its best endeavors to remedy any errors reported within the Support Service, that causes a significant hindrance to the use of the Software without undue delay and to provide regular Maintenance in accordance with its own schedule.</span></span></span></span></span></p>

        <p>&nbsp;</p>

        <p><span style="font-size:11pt"><span style="font-family:Cambria,serif"><span dir="ltr" lang="EN-GB" style="font-size:12.0pt"><span style="font-family:Roboto"><span style="color:#222222">Special Maintenance and Support Services, as well as regular data backups may be provided if required on demand of the Licensee on provision that a separate agreement is concluded between the Licensor and Licensee.</span></span></span></span></span></p>

        <p><br />
          <br />
          <span style="font-size:11pt"><span style="font-family:Cambria,serif"><strong><span dir="ltr" lang="EN-GB" style="font-size:12.0pt"><span style="font-family:Roboto"><span style="color:#222222">Limitation of Liability.</span></span></span></strong><span dir="ltr" lang="EN-GB" style="font-size:12.0pt"><span style="font-family:Roboto"><span style="color:#222222">&nbsp;Entire liability of the Licensor under this EULA shall not exceed the price paid for the Software. In no event, shall the Licensor or its suppliers be liable for any damages whatsoever (including, without limitation, incidental, direct, indirect special and consequential damages, damages for loss of business profits, business interruption, loss of business information, or other pecuniary loss) arising out of the use or inability to use the Software, even if the Licensor has been advised of the possibility of such damages, or any claim by a third party.</span></span></span><br />
<br />
<strong><span dir="ltr" lang="EN-GB" style="font-size:12.0pt"><span style="font-family:Roboto"><span style="color:#222222">Marketing</span></span></span></strong><br />
<span dir="ltr" lang="EN-GB" style="font-size:12.0pt"><span style="font-family:Roboto"><span style="color:#222222">Licensee agrees to be identified as a customer of MASTA and that MASTA may refer to Licensee by name, trade name and trademark in marketing materials and on MASTA and Software websites.</span></span></span><br />
<br />
<span dir="ltr" lang="EN-GB" style="font-size:12.0pt"><span style="font-family:Roboto"><span style="color:#222222">The right reserved by MASTA can be waived upon written request from the Licensee made prior to entering by parties into this Agreement, or at a later time, in which case it may take MASTA up to 30 days to remove the related marketing materials.</span></span></span><br />
<br />
<strong><span dir="ltr" lang="EN-GB" style="font-size:13.5pt"><span style="font-family:Roboto"><span style="color:#222222">Termination</span></span></span></strong><br />
<br />
<span dir="ltr" lang="EN-GB" style="font-size:12.0pt"><span style="font-family:Roboto"><span style="color:#222222">This EULA is terminated in the following cases:</span></span></span></span></span><br />
          &nbsp;</p>

        <ul style="margin-left: 24px;">
          <li><span style="font-size:11pt"><span style="font-family:Cambria,serif"><span dir="ltr" lang="EN-GB" style="font-size:12.0pt"><span style="font-family:Roboto"><span style="color:#222222">If case of the License with an expiration date (as indicated by the i4FS at the time that the Licensee purchases the Software), on expiration date.</span></span></span></span></span></li>
          <li><span style="font-size:11pt"><span style="font-family:Cambria,serif"><span dir="ltr" lang="EN-GB" style="font-size:12.0pt"><span style="font-family:Roboto"><span style="color:#222222">MASTA may terminate this EULA if the Licensee fails to comply with the terms and conditions of this EULA.</span></span></span></span></span></li>
        </ul>

        <p>&nbsp;</p>

        <p><span style="font-size:11pt"><span style="font-family:Cambria,serif"><span dir="ltr" lang="EN-GB" style="font-size:12.0pt"><span style="font-family:Roboto"><span style="color:#222222">After termination, the Licensee has a right to receive a copy of its data that were stored or produced by using the Software. MASTA is obliged to deliver the data up to 30 days from receiving a request on this from the Licensee to the general contact email address. </span></span></span></span></span></p>

        <p><span style="font-size:11pt"><span style="font-family:Cambria,serif"><span dir="ltr" lang="EN-GB" style="font-size:12.0pt"><span style="font-family:Roboto"><span style="color:#222222">In case the Software is no longer offered on the i4FS, the EULA stays in force and the Licensor assumes all rights and obligations of the i4FS related to the Software, its Purchase process and Subscription Fee(s) payments and the Licensee is obliged to cooperate on this with the Licensor.</span></span></span></span></span></p>

        <p>&nbsp;</p>

        <h3><span style="font-size:11pt"><span style="background-color:white"><span style="font-family:Calibri,sans-serif"><span style="color:#4f81bd"><strong><span dir="ltr" lang="EN-GB" style="font-size:12.0pt"><span style="font-family:Roboto"><span style="color:#222222">Amendments to this Agreement</span></span></span></strong></span></span></span></span></h3>

        <p><span style="font-size:12pt"><span style="background-color:white"><span style="font-family:&quot;Times New Roman&quot;,serif"><span dir="ltr" lang="EN-GB" style="font-family:&quot;Arial&quot;,sans-serif"><span style="color:black">MASTA reserves the right, at its sole discretion, to modify or replace this Agreement at any time. If a revision is material, MASTA will provide at least 30 days&rsquo; notice prior to any new terms taking effect. What constitutes a material change will be determined at MASTA&rsquo;s sole discretion.</span></span></span></span></span></p>

        <p>&nbsp;</p>

        <p>&nbsp;</p>

        <p><span style="font-size:11pt"><span style="font-family:Cambria,serif"><strong><span dir="ltr" lang="EN-GB" style="font-size:12.0pt"><span style="font-family:Roboto"><span style="color:#222222">Governing Law</span></span></span></strong><br />
<br />
<span dir="ltr" lang="EN-GB" style="font-size:12.0pt"><span style="font-family:Roboto"><span style="color:#222222">The Agreement shall be governed by and construed in accordance with the laws of Poland without regard to its conflicts of law provisions.</span></span></span></span></span></p>

        <p>&nbsp;</p>

        <p><span style="font-size:11pt"><span style="font-family:Cambria,serif"><strong><span dir="ltr" lang="EN-GB" style="font-size:12.0pt"><span style="font-family:Roboto"><span style="color:#222222">Severability</span></span></span></strong></span></span></p>

        <p><span style="font-size:11pt"><span style="font-family:Cambria,serif"><span dir="ltr" lang="EN-GB" style="font-size:12.0pt"><span style="font-family:Roboto"><span style="color:#222222">If any provision of this EULA is held to be illegal, invalid or unenforceable under any applicable laws, it shall to that extent be deemed not to form part of this agreement but the remainder of this agreement will remain valid and enforceable to the greatest extent permitted by applicable law.</span></span></span></span></span></p>

        <p>&nbsp;</p>

        <p><span style="font-size:11pt"><span style="font-family:Cambria,serif"><strong><span style="font-size:12.0pt"><span style="font-family:Roboto"><span style="color:#222222">Contact</span></span></span></strong></span></span></p>

        <p><span style="font-size:11pt"><span style="font-family:Cambria,serif"><span style="font-size:12.0pt"><span style="font-family:Roboto"><span style="color:#222222">MASTA Solutions</span></span></span></span></span></p>

        <p><span style="font-size:11pt"><span style="font-family:Cambria,serif"><span style="font-size:12.0pt"><span style="font-family:Roboto"><span style="color:#222222">Ul. Południowa 72c</span></span></span></span></span></p>

        <p><span style="font-size:11pt"><span style="font-family:Cambria,serif"><span dir="ltr" lang="EN-GB" style="font-size:12.0pt"><span style="font-family:Roboto"><span style="color:#222222">62-081 Przeźmierowo</span></span></span></span></span></p>

        <p><span style="font-size:11pt"><span style="font-family:Cambria,serif"><a href="mailto:info@masta.solutions" style="color:blue; text-decoration:underline"><span dir="ltr" lang="EN-GB" style="font-size:12.0pt"><span style="font-family:Roboto">info@masta.solutions</span></span></a></span></span></p>

        <p><span style="font-size:11pt"><span style="font-family:Cambria,serif"><span dir="ltr" lang="EN-GB" style="font-size:12.0pt"><span style="font-family:Roboto"><span style="color:#222222">+48&nbsp;507&nbsp;969 964</span></span></span></span></span></p>

      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn block variant="elevated" size="x-large" color="primary" text @click="dialog = false">Close</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style lang="scss" scoped></style>
