import { Overlap } from "./Overlap";
import { ActivityBase } from "./ActivityBase";
import { IOverlappingActivity } from "./IOverlappingActivity";
import { Instant } from "@js-joda/core";
import { MutableActivityBase } from "./MutableActivityBase";

export abstract class OverlappingActivity<TActivity> extends MutableActivityBase<TActivity> implements IOverlappingActivity {
  private _overlap: Overlap;

  constructor(id: string | undefined, name: string, startTime: Instant = Instant.now(), endTime: Instant = Instant.now().plus(ActivityBase.DEFAULT_DURATION)) {
    super(id, name, startTime, endTime);
    this._overlap = new Overlap(this);
  }

  get overlap(): Overlap {
    return this._overlap;
  }
}
