import { MouseBtnPressedEvent } from "./MouseBtnPressedEvent";

export type RowMouseBtnPressedEvent = MouseBtnPressedEvent & {
  relX: number;
  relY: number;
  rowId: string;
  offsetTop: number;
};

export function createRowMouseBtnPressedEvent(event: MouseBtnPressedEvent, element: HTMLElement, rowId: string): RowMouseBtnPressedEvent {
  return {
    ...event,
    relX: event.x,
    relY: event.y - element.offsetTop,
    offsetTop: element.offsetTop,
    rowId
  };
}
