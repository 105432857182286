import { inject, multiInject, injectable, optional } from "inversify";
import { GanttDomElement } from "../../../GanttDomElement";
import { IocSymbols, IRowInfoCell, IRowInfoColumn, Row } from "../../../../Core";
import type { IRowInfoColumnProvider } from "../../../../Core";
import { RowInfoCollapseBtnContainer } from "./RowInfoCollapseBtnContainer";

@injectable()
export class RowInfoTemplate<TResource extends Row<any, any, any>> extends GanttDomElement<HTMLDivElement> {
  private _columns: IRowInfoColumn[] = [];
  private _cells: (IRowInfoCell & GanttDomElement<any>)[] = [];

  constructor(
    @inject(Row<any, any, any>) private _row: TResource,
    @inject(RowInfoCollapseBtnContainer<any>) private _rowInfoCollapseBtnContainer: RowInfoCollapseBtnContainer<any>,
    @inject(IocSymbols.RowInfoColumnProvider) private _rowInfoColumnProvider: IRowInfoColumnProvider,
    @multiInject(IocSymbols.RowInfoCell) @optional() private _rowInfoCells: (IRowInfoCell & GanttDomElement<any>)[]
  ) {
    super(`${RowInfoTemplate.name}-${_row.id}`, undefined, "info-template");
    this.element.dataset.id = this.identifier;
  }


  async beforeInitialize(): Promise<void> {
    await this.addChildren([this._rowInfoCollapseBtnContainer]);
    this._columns = this._rowInfoColumnProvider.columns;
    for (const column of this._columns) {
      await this.addRowInfoCell(column);
    }
  }

  async afterInitialize(): Promise<void> {
    this.subscribe(
      this._rowInfoColumnProvider.computedInfoWidth.subscribe((computedWidth) => {
        this.batchDraw();
      })
    );
    for (const column of this._columns) {
      const observer = this._rowInfoColumnProvider.observeColumn(column.id);
      if (!observer) return;

      this.subscribe(
        observer.subscribe(async (c) => {
          if (c.visible) {
            await this.addRowInfoCell(column);
          } else {
            await this.removeRowInfoCell(column.id);
          }
          await this.initializeAllNotInitialized();
          this.batchDraw(true);
        })
      );
    }
  }

  private async removeRowInfoCell(columnId: string) {
    const cellIndex = this._cells.findIndex((c) => c.cellId === columnId);
    if (cellIndex === -1) return;
    const cell = this._cells[cellIndex];
    this._cells.splice(cellIndex, 1);
    await this.removeChild(cell);
  }

  private async addRowInfoCell(column: IRowInfoColumn) {
    if (!column.visible) return;
    if (this._cells.findIndex(c => c.cellId === column.id) > -1) return;
    const columnIndex = this._columns.findIndex((c) => c.id === column.id);
    if (columnIndex === -1) return;
    const cell = this._rowInfoCells.find((c) => c.cellId === column.id);
    if (!cell) return;
    this._cells.splice(columnIndex, 0, cell);
    cell.setColumn(column);
    const child = this._children$$.value[columnIndex];
    await this.addChildren([cell], child?.identifier);
  }

  async doDrawFromBatch(): Promise<void> {
    this._rowInfoCollapseBtnContainer.element.style.marginLeft = `${this._row.levelNumber * this._rowInfoCollapseBtnContainer.element.clientWidth}px`;
    await this._rowInfoCollapseBtnContainer.doDrawFromBatch();
    if (!this.element.title) {
      this.element.title = this._row.id;
    }
  }
}
