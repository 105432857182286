import { translateBoolen } from "@/composables/translateEnum";
import { ISetFilterParams, KeyCreatorParams, SetFilterValuesFuncParams, ValueFormatterParams } from "ag-grid-community";
import { ISelectEnumValueEntry } from "@/components/Grid/CellEditors/IEnumValueSelectCellEditorParams";

export const booleanTypeColumnFilterParams: ISetFilterParams<any, { key: string; value: boolean }> = {
  valueFormatter: (params: ValueFormatterParams) => params.value.key,
  keyCreator: (params: KeyCreatorParams) => params.value.value,
  values: (params: SetFilterValuesFuncParams<any, ISelectEnumValueEntry>) => {
    const entries =  [
      {
        key: translateBoolen(true),
        value: true
      },
      {
        key: translateBoolen(false),
        value: false
      }
    ];

    params.success(entries);
  }
};
