import { ApiClient } from "@/services/api/common.api";
import {
  CreateOrUpdateAvailabilityRuleCommand,
  GetAvailabilityRulesPaginatedQuery,
  IAgGridPaginationResponse,
  AvailabilityRuleDataDto,
  ResourceAvailabilityRuleDto,
  GetResourceAvailabilityRulesPaginatedQuery,
  CreateResourceAvailabilityRuleCommand,
  UpdateResourceAvailabilityRuleCommand
} from "@masta/generated-model";

export default {
  async getPaginated(request: GetResourceAvailabilityRulesPaginatedQuery) {
    return ApiClient.post<IAgGridPaginationResponse<ResourceAvailabilityRuleDto>>(`resources/${request.resourceId}/availabilityRules`, request);
  },
  async get(resourceId: string, ruleId: string, scenarioId: string) {
    return ApiClient.get<ResourceAvailabilityRuleDto>(`resources/${resourceId}/availabilityRules/${ruleId}?scenarioId=${scenarioId}`);
  },
  async create(request: CreateResourceAvailabilityRuleCommand) {
    return ApiClient.post<string>(`resources/${request.resourceId}/availabilityRules/${request.availabilityRuleId}`, request);
  },
  async update(request: UpdateResourceAvailabilityRuleCommand) {
    return ApiClient.put(`resources/${request.resourceId}/availabilityRules/${request.availabilityRuleId}`, request);
  },
  async remove(resourceId: string, ruleId: string, scenarioId: string) {
    return ApiClient.delete(`resources/${resourceId}/availabilityRules/${ruleId}?scenarioId=${scenarioId}`);
  }
};
