import { GanttException, IdentifiableLifecycle, requestAnimation } from "../Core";
import { BehaviorSubject, Observable } from "rxjs";
import { Instant } from "@js-joda/core";

export abstract class GanttDomElement<TElement extends HTMLElement> extends IdentifiableLifecycle {
  protected _parent: GanttDomElement<any> | null = null;
  protected _children$$ = new BehaviorSubject<Array<GanttDomElement<any>>>([]);
  protected _element: TElement;
  protected _tagName: string | undefined;
  protected _className: string | undefined;
  protected _waitingForDraw = false;
  protected _animating = false;
  protected _animationStartTime: number = 0;

  protected constructor(identifier?: string, element?: TElement, className?: string, tagName?: string) {
    super(identifier);
    if (element) {
      this._element = element;
      if (className && !this._element.classList.contains(className)) {
        this._element.classList.add(className);
      }
    } else {
      this._element = element ?? this.createElement(tagName, className);
    }
    this._className = className;
    this._tagName = tagName;
  }

  get children$(): Observable<GanttDomElement<any>[]> {
    return this._children$$.asObservable();
  }

  get children(): GanttDomElement<any>[] {
    return this._children$$.value;
  }

  get parent(): GanttDomElement<any> | null {
    return this._parent;
  }

  get element(): TElement {
    return this._element;
  }

  get appended(): boolean {
    return this.element ? this.element.isConnected : false;
  }

  protected get isAnimating(): boolean {
    return this._animating;
  }

  public elementAppended(): void {
  }

  public elementRemoved(): void {
  }

  async initialize(): Promise<void> {
    if (this._initialized$$.value) {
      throw new GanttException(`Instance is already initialized!`);
    }
    await this.beforeInitialize();

    if (this._element) {
      for (const child of this.children) {
        try {
          await child.initialize();
        } catch (e) {
          console.error(`Failed to initialize ganttDomElement: ${child.identifier}`, e);
        }
      }
      this._children$$.value.forEach((d) => this.appendChild(d));
    } else {
      console.warn(`[Gantt] Element not initialized [${this._tagName}].${this._className} [${this.identifier}]`);
    }

    this._initialized$$.next(true);
    await this.afterInitialize();
  }

  async destroy(): Promise<void> {
    await this.beforeDestroy();
    this.stopAnimation();
    this.clearSubscriptions();

    for (const d of this._children$$.value) {
      await d.destroy();
    }
    try {
      if (this.element && this.element.isConnected && this.element.parentNode) {
        this.element.parentNode.removeChild(this.element);
        this.elementRemoved();
      }
      this.element.remove();
    } catch (e) {
      console.error(`Failed to remove child ${this.identifier} from parent`, e);
    }

    this._initialized$$.next(false);
    this._destroyed$$.next(true);
    await this.afterDestroy();
  }

  protected yieldToMain(callback: (() => Promise<void>) | undefined = undefined): Promise<void> {
    return new Promise<void>((resolve) => {
      setTimeout(resolve, 0);
      if (callback) {
        return callback();
      }
    });
  }

  public async doDrawFromBatch() {
  }

  public batchDraw(childToo = false): void {
    // console.debug("[Gantt] \t\tREQUESTED \t BATCH DRAW", childToo ? "with child for" : "for", this.identifier);
    if (!this._waitingForDraw) {
      this._waitingForDraw = true;
      if (childToo) {
        const drawPromises = this.getAllDrawFromBatch();
        // console.debug("[Gantt] \t\tBATCH DRAW with child for", this.identifier, drawPromises.length);
        requestAnimation({
          id: this.identifier,
          callback: async () => {
            await Promise.all(drawPromises);
            this._waitingForDraw = false;
          }
        });
      } else {
        // console.debug("[Gantt] \t\tBATCH DRAW for", this.identifier);
        requestAnimation({
          id: this.identifier,
          callback: async () => {
            if (!this.isAnimating) {
              await this.doDrawFromBatch();
            }
            this._waitingForDraw = false;
          }
        });
      }
    } else {
      if (this.identifier === "SampleAnimationChartLayer")
        console.warn("[Gantt] \t\tBATCH DRAW already waiting for", this.identifier);
    }
  }

  private animate() {
    if (this._animating) {
      requestAnimation({
        id: this.identifier,
        callback: async () => {
          await this.doDrawFromBatch();
          this.animate();
        }
      });
    }
  }

  protected startAnimation(): void {
    if (this._animating) return;
    this._animating = true;
    this._animationStartTime = performance.now();
    this.animate();
    this.onAnimationStarted();
  }

  protected stopAnimation(): void {
    this._animating = false;
    this.onAnimationStopped();
  }

  protected onAnimationStarted(): void {
  }

  protected onAnimationStopped(): void {
  }

  protected getAllDrawFromBatch() {
    const result: Promise<void>[] = [];
    this._children$$.value
      .filter((d) => d.isInitialized)
      .forEach((d) => {
        result.push(...d.getAllDrawFromBatch());
      });
    if (!this.isAnimating) {
      result.unshift(this.doDrawFromBatch());
    }
    return result;
  }

  protected sortChildren<TGanttDomElement extends GanttDomElement<any>>(sortFn: (a: any, b: any) => number): void {
    const children = [...this._children$$.value];
    children.sort(sortFn);
    this._children$$.next(children);
  }

  protected async addChild<TGanttDomElement extends GanttDomElement<any>>(ganttDomElement: TGanttDomElement, { beforeAll } = { beforeAll: false }): Promise<void> {
    ganttDomElement._parent = this;
    const children = [...this._children$$.value];
    if (beforeAll) {
      children.unshift(ganttDomElement);
    } else {
      children.push(ganttDomElement);
    }
    this._children$$.next(children);
    if (this.isInitialized && !ganttDomElement.isInitialized) {
      await ganttDomElement.initialize();
    }
  }

  protected async addChildren<TGanttDomElement extends GanttDomElement<any>>(ganttDomElements: TGanttDomElement[], afterElementIdentifier?: string): Promise<void> {
    const children: any[] = [];
    const idx = children.findIndex((d) => d.identifier === afterElementIdentifier);
    if (idx > -1) {
      children.splice(idx + 1, 0, ...ganttDomElements);
      for (const ganttDomElement of ganttDomElements) {
        ganttDomElement._parent = this;
        if (this.isInitialized && !ganttDomElement.isInitialized) {
          await ganttDomElement.initialize();
        }
      }
    } else {
      for (const ganttDomElement of ganttDomElements) {
        ganttDomElement._parent = this;
        children.push(ganttDomElement);
        if (this.isInitialized && !ganttDomElement.isInitialized) {
          await ganttDomElement.initialize();
        }
      }
    }
    this._children$$.next([...this._children$$.value, ...children]);
  }

  public async removeChild<TChild extends HTMLElement>(ganttDomElement: GanttDomElement<TChild>): Promise<boolean> {
    const children = [...this._children$$.value];
    const idx = children.findIndex((x) => x.identifier === ganttDomElement.identifier);
    if (idx > -1) {
      const d = children[idx];
      await d.destroy();
      children.splice(idx, 1);
      this._children$$.next(children);
      return true;
    }
    return false;
  }

  public async removeChildren<TChild extends HTMLElement>(ganttDomElements: GanttDomElement<TChild>[]): Promise<void> {
    const children = [...this._children$$.value];

    for (const ganttDomElement of ganttDomElements) {
      const idx = children.findIndex((x) => x._identifier === ganttDomElement._identifier);
      if (idx > -1) {
        const d = children[idx];
        await d.destroy();
        children.splice(idx, 1);
      }
    }

    this._children$$.next(children);
  }

  public async clearChildren(): Promise<void> {
    for (const child of this.children) {
      await child.destroy();
    }
    this._children$$.next([]);
  }

  public appendChild<TGanttDomElement extends GanttDomElement<any>>(ganttDomElement: TGanttDomElement): void {
    try {
      if (ganttDomElement.parent && ganttDomElement.parent.element && ganttDomElement.element && !ganttDomElement.element.isConnected) {
        ganttDomElement.parent.element.appendChild(ganttDomElement._element);
        ganttDomElement.elementAppended();
      }
    } catch (e) {
      console.error(`Failed to append to ganttDomElement parent: ${ganttDomElement.identifier}`, e);
    }
  }

  protected appendChildBefore<TGanttDomElement extends GanttDomElement<any>>(ganttDomElement: TGanttDomElement, element: HTMLElement): void {
    try {
      if (ganttDomElement.parent && ganttDomElement.parent.element && ganttDomElement.element && !ganttDomElement.element.isConnected) {
        ganttDomElement.parent.element.insertBefore(ganttDomElement._element, element.nextSibling);
        ganttDomElement.elementAppended();
      }
    } catch (e) {
      console.error(`Failed to append to ganttDomElement parent: ${ganttDomElement.identifier}`, e);
    }
  }

  protected createElement(tagName = "div", className?: string): TElement {
    const element = document.createElement(tagName);
    if (className) element.className = className;
    return element as TElement;
  }

  protected async initializeAllNotInitialized() {
    const children = this._children$$.value;
    if (children.length === 0) return;
    this._children$$.value.reduce((previousValue, currentValue) => {
      if (!currentValue.isInitialized) {
        currentValue.initialize();
      }
      if (!previousValue) {
        if (!currentValue.appended) {
          this.appendChild(currentValue);
        }
      } else if (!currentValue.appended) {
        this.appendChildBefore(currentValue, previousValue.element);
      }
      return currentValue;
    });
  }
}
