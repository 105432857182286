<template>
  <div ref="container" :style="style" />
</template>

<script setup>
import { computed, onMounted, onUnmounted, ref, toRefs, watch } from "vue";
import { useDebounceFn, useResizeObserver } from "@vueuse/core";

// Import monaco
// https://github.com/vitejs/vite/discussions/1791
import * as monaco from "monaco-editor";
import editorWorker from "monaco-editor/esm/vs/editor/editor.worker?worker";
import jsonWorker from "monaco-editor/esm/vs/language/json/json.worker?worker";
import cssWorker from "monaco-editor/esm/vs/language/css/css.worker?worker";
import htmlWorker from "monaco-editor/esm/vs/language/html/html.worker?worker";
import tsWorker from "monaco-editor/esm/vs/language/typescript/ts.worker?worker";

self.MonacoEnvironment = {
  getWorker(_, label) {
    if (label === "json") {
      return new jsonWorker();
    }
    if (label === "css" || label === "scss" || label === "less") {
      return new cssWorker();
    }
    if (label === "html" || label === "handlebars" || label === "razor") {
      return new htmlWorker();
    }
    if (label === "typescript" || label === "javascript") {
      return new tsWorker();
    }
    return new editorWorker();
  }
};
monaco.languages.typescript.typescriptDefaults.setEagerModelSync(true);

const container = ref(null);
let editor;
const props = defineProps({
  modelValue: {
    type: [Object, Number, String, null],
    default: null
  },
  options: {
    type: Object,
    default() {
      return {};
    }
  },
  width: {
    type: [String, Number],
    default: "100%"
  },
  height: {
    type: [String, Number],
    default: "100%"
  },
  minHeight: {
    type: [String, Number],
    default: "600px"
  },
  theme: {
    type: String,
    default: "vs-dark"
  },
  language: {
    type: String,
    default: "javascript"
  },
  readonly: {
    type: Boolean,
    default: false
  }
});

const emit = defineEmits(["update:modelValue"]);

const style = computed(() => ({
  width: !/^\d+$/.test(props.width.toString()) ? props.width : `${props.width}px`,
  height: !/^\d+$/.test(props.height.toString()) ? props.height : `${props.height}px`,
  "min-height": !/^\d+$/.test(props.minHeight.toString()) ? props.minHeight : `${props.minHeight}px`
}));

onMounted(() => {
  editor = monaco.editor.create(container.value, {
    language: props.language,
    theme: props.theme,
    readOnly: props.readonly,
    ...props.options
  });
  editor.onDidChangeModelContent(
    useDebounceFn(() => {
      const value = editor.getValue();
      emit("update:modelValue", value);
    }, 500)
  );

  // Set values from storage on load
  if (props.modelValue) {
    editor.setValue(props.modelValue);
  }
});

watch(
  () => props.modelValue,
  (newValue) => {
    const editorValue = editor.getValue();
    if (newValue !== editorValue) editor?.setValue(newValue);
  }
);

watch(
  () => props.readonly,
  (newValue) => {
    if (!editor) return;

    editor.updateOptions({
      readOnly: newValue
    });
  }
);

const editorObserver = useResizeObserver(container, () => {
  editor.layout();
});
onUnmounted(() => {
  editor?.dispose();
  editorObserver.stop();
});
</script>
