import ApiService from "@/services/api";
import { CreateTagCommand, TagDto, UpdateTagCommand } from "@masta/generated-model";
import { defineStore } from "pinia";
import { useErrorsStore } from "@/store/ErrorsStore";
import { composeTreeHierarchy } from "@/store/common";

interface State {
  tags: TagDto[];
}

const fetched = false;

export const useTagsStore = defineStore("tags", {
  state: (): State => ({
    tags: []
  }),
  actions: {
    async fetch(force: boolean = false) {
      try {
        if (!force && fetched) return;
        const response = await ApiService.tags.getTags();
        this.tags = composeTreeHierarchy(response.data.tags, "parentName", "name");
      } catch (e) {
        const errorsStore = useErrorsStore();
        errorsStore.handleError(e);
        throw e;
      }
    },
    async create(request: CreateTagCommand) {
      try {
        await ApiService.tags.createTag(request);
        await this.fetch();
      } catch (e) {
        const errorsStore = useErrorsStore();
        errorsStore.handleError(e);
        throw e;
      }
    },
    async delete(name: string) {
      try {
        await ApiService.tags.deleteTag(name);
        await this.fetch();
      } catch (e) {
        const errorsStore = useErrorsStore();
        errorsStore.handleError(e);
        throw e;
      }
    },
    async update(updated: UpdateTagCommand) {
      try {
        await ApiService.tags.updateTag(updated);
        await this.fetch();
      } catch (e) {
        const errorsStore = useErrorsStore();
        errorsStore.handleError(e);
        throw e;
      }
    }
  }
});

export type TagsStoreType = ReturnType<typeof useTagsStore>;
