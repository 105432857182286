
<script lang="ts">

import { ICellEditorParams } from "ag-grid-community";
import { ref, toRef } from "vue";
import CloseCardButton from "@/components/CloseCardButton.vue"
import RecurrenceRuleEditor from "@/components/RecurrenceRule/RecurrenceRuleEditor.vue";

import { $t } from "@/i18n";
import { resolveValueRef, useValueChangeControl } from "@/components/Grid/UseValueChangeControl";
import { RRule } from "rrule";
import { useRecurrenceRuleText } from "@/components/RecurrenceRule/UseRecurrenceRuleText";

interface IProps extends ICellEditorParams { }

export default {
    components: { CloseCardButton, RecurrenceRuleEditor },
    setup(props: { params: IProps }) {

        const { toText } = useRecurrenceRuleText();

        const params = toRef(props, "params");
        const dialog = ref<boolean>();
        const rruleHumanTextValue = ref<string>();

        const rruleEditorRef = ref<typeof RecurrenceRuleEditor>();

        const initialValue = props.params.value;
        const rruleValue = resolveValueRef(props.params, initialValue);

        /*
         * Get 'start' and 'end' from row 'data' object to pass to RecurrenceRuleEditor.
         * We wants to edit 'start' and 'end' values in RecurrenceRuleEditor.
         */
        const startValue = props.params.node.data.start;
        const endValue = props.params.node.data.end;

        rruleHumanTextValue.value = getRRuleHumanText(rruleValue.value);

        function getValue() {
            return rruleValue.value;
        }

        function afterGuiAttached() {
            if (props.params.cellStartedEdit) {
                focusIn();
            }
        }

        function focusIn() {
        }

        function focusOut() {
        }

        function onBtnEdit() {
            dialog.value = true;
        }

        function onBtnCancel() {
            dialog.value = false;
        }

        function onBtnSave() {
            rruleEditorRef.value?.confirmChanges();

            dialog.value = false;
        }

        function getRRuleHumanText(rruleText:string) {
            const rrule = RRule.fromString(rruleText);
            return toText(rrule);
        }

        function updateRRuleValue(rrule: string) {
            rruleValue.value = rrule;
            rruleHumanTextValue.value = getRRuleHumanText(rrule);
        }

        function updateStartValue(start: Date) {
            const rowValueChanged = props.params.node.setDataValue("start", start);
            if (rowValueChanged) {
                props.params.api.dispatchEvent({ type: "rowValueChanged" });
            } else {
                console.error("Failed to update 'start' value in row data.");
            }
        }

        function updateEndValue(end: Date) {
            const rowValueChanged = props.params.node.setDataValue("end", end);
            if (rowValueChanged) {
                props.params.api.dispatchEvent({ type: "rowValueChanged" });
            } else {
                console.error("Failed to update 'end' value in row data.");
            }
        }

        useValueChangeControl(rruleValue, props.params);

        return {
            dialog,
            rruleValue,
            updateRRuleValue,
            updateStartValue,
            updateEndValue,
            startValue,
            endValue,
            rruleHumanTextValue,
            getValue,
            afterGuiAttached,
            focusIn,
            focusOut,
            onBtnEdit,
            onBtnCancel,
            onBtnSave,
            rruleEditorRef: rruleEditorRef as any
        }
    }
};

</script>


<template>
    <div class="rrule-cell-editor">
        <div class="rrule-cell-editor-cell align-center">
            <span class="rrule-cell-editor-cell-value">
                {{ rruleHumanTextValue }}
            </span>
            <v-btn 
                class="rrule-cell-editor-cell-edit-btn" color="primary" variant="outlined" density="compact" 
                size="small" @click="onBtnEdit">
                <span class="font-weight-bold">
                    {{ $t("rrule-cellEditor-edit-label", { $: "Edit" }) }}
                </span>
            </v-btn>
        </div>
        <v-dialog v-model="dialog" width="100%" height="80vh" @click:outside="onBtnCancel">
            <v-card max-width="850px" height="100%" class="reference-picker-card" location="center center">
                <close-card-button @click="onBtnCancel"></close-card-button>
                <v-card-title class="pa-0">
                    <div class="text-h4 pb-5">
                        {{ $t("rrule-cellEditor-title", { $: "Recurrence Rule" }) }}
                    </div>
                </v-card-title>
                <v-card-text class="px-0">
                    <RecurrenceRuleEditor 
                        ref="rruleEditorRef" 
                        v-model:rrule="rruleValue" 
                        v-model:start="startValue" 
                        v-model:end="endValue"
                        @update:model-value:rrule="updateRRuleValue"
                        @update:model-value:start="updateStartValue"
                        @update:model-value:end="updateEndValue" />
                </v-card-text>
                <v-card-actions class="pa-0">
                    <div class="left-col pl-0">
                        <v-btn variant="elevated" color="secondary" class="w-100" @click="onBtnCancel">
                            {{ $t("rrule-cellEditor-cancel-label", { $: "Cancel" }) }}
                        </v-btn>
                    </div>
                    <div class="middle-col"></div>
                    <div class="right-col pr-0">
                        <v-btn variant="elevated" color="primary" class="w-100" @click="onBtnSave">
                            {{ $t("rrule-cellEditor-confirm-label", { $: "Confirm" }) }}
                        </v-btn>
                    </div>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<style lang="scss">
.rrule-cell-editor {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .rrule-cell-editor-cell {
        display: grid;
        grid-template-columns: 1fr auto;
        grid-template-rows: 1fr;
        padding: 0.31em;

        .rrule-cell-editor-cell-value {
            display: flex;
            justify-content: center;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .rrule-cell-editor-cell-edit-btn {
            height: 34px;
            padding: 0.31em;
            margin-left: 0.31em;
        }
    }
}
</style>