import { IRowNode } from "ag-grid-community";
import { IServerSideSelectionState } from "ag-grid-community";
import { GridApi } from "ag-grid-community";

/**
 * Functions to get the selected rows/nodes in the grid.
 *  - If server side (pagination) is enabled, it will only select the rows on the current page.
 * - If client side is enabled, it will select all rows in the grid (invoke default grid getSelectedRows method).
 *
 * We need to handle the server side selection state differently because when we try to get the selected rows using
 * the default getSelectedRows method as selectAll was used, it will return an empty array and that warning will be displayed in the console:
 *
 * AG Grid: getSelectedNodes and getSelectedRows functions cannot be used with select all functionality with the server-side row model.
 Use `api.getServerSideSelectionState()` instead.

 * !Same warning will be displayed when we try to use getSelectedNodes method after selectAll is used. This looks like a AG Grid bug.
 */

export function getSelectedNodes<TData = any>(gridApi: GridApi | null | undefined): IRowNode<TData>[] {
  if (!gridApi) return [];

  const rowModelType = gridApi.getGridOption("rowModelType");

  if (rowModelType === "serverSide") {
    const serverSideSelectionState = gridApi.getServerSideSelectionState() as IServerSideSelectionState;

    if (serverSideSelectionState != null && serverSideSelectionState.selectAll) {
      const selectedRowNodes = [] as IRowNode<TData>[];
      gridApi.forEachNode((node) => {
        if (node.isSelected()) {
          selectedRowNodes.push(node);
        }
      });

      return selectedRowNodes;
    }
  }

  return gridApi.getSelectedNodes();
}

export function getSelectedRows<TData = any>(gridApi?: GridApi): TData[] {
  if (!gridApi) return [];

  const rowModelType = gridApi.getGridOption("rowModelType");

  if (rowModelType === "serverSide") {
    const serverSideSelectionState = gridApi.getServerSideSelectionState() as IServerSideSelectionState;

    if (serverSideSelectionState != null) {
      const selectedRows = [] as TData[];
      gridApi.forEachNode((node) => {
        if (node.isSelected()) {
          selectedRows.push(node.data);
        }
      });
      return selectedRows;
    }
  }

  return gridApi.getSelectedRows();
}

