import { inject, injectable } from "inversify";
import { GanttEvents, GanttSettings, IocContainer, IocSymbols, IRowInfoColumn, Row, SettingKey } from "../../../../Core";
import type { IRowChartScaleProvider } from "../../../../Core";
import { GanttCanvas } from "../../../GanttCanvas";
import { RowContainer } from "../RowContainer";
import { ChartScaleLayerRenderer } from "../../../../Worker";

@injectable()
export class RowScaleRowInfoTemplateCellCanvas<TResource extends Row<any, any, any>> extends GanttCanvas {

  private _column: IRowInfoColumn;
  private _scaleValues: number[] = [];
  private _scaleUnit?: string;
  private _show: boolean;
  private _width: number;
  private _redraw = true;

  constructor(@inject(Row<any, any, any>) private _row: TResource,
              @inject(GanttEvents) ganttEvents: GanttEvents,
              @inject(GanttSettings) private _ganttSettings: GanttSettings,
              @inject(IocSymbols.RowChartScaleProvider) private _rowChartScaleProvider: IRowChartScaleProvider,
              @inject(ChartScaleLayerRenderer.Identifier) private _renderer: ChartScaleLayerRenderer) {
    super(ganttEvents, `${RowScaleRowInfoTemplateCellCanvas.name}-${_row.id}`, "scale-canvas");
  }

  setColumn(column: IRowInfoColumn): void {
    this._column = column;
  }

  async beforeInitialize(): Promise<void> {
    await super.beforeInitialize();
    this.canvas.width = 0;
    this.canvas.height = 0;
  }

  async beforeDestroy(): Promise<void> {
    return super.beforeDestroy();
  }

  async afterInitialize(): Promise<void> {
    await super.afterInitialize();
    this.subscribe(this._ganttEvents.devicePixelRatio$.subscribe(async () => {
      await this.scale();
    }));
    this.subscribe(
      this._row.height$.subscribe(async (h) => {
        if (!this.isInitialized) return;
        await this.scale();
      })
    );
    this.subscribe(this._ganttSettings.getSetting$<boolean>(SettingKey.CHART_HEADER_SHOW).subscribe((s) => {
      this._show = s ?? false;
      this._redraw = true;
      this.clear();
      this.batchDraw();
    }));
    this.subscribe(this._ganttSettings.getSetting$<number>(SettingKey.CHART_HEADER_WIDTH).subscribe((s) => {
      this._width = s ?? 0;
      this.element.style.width = `${this._width}px`;
      this._redraw = true;
      this.clear();
      this.batchDraw();
    }));

    this.subscribe(
      this._rowChartScaleProvider.scaleValues$.subscribe((value) => {
        this._scaleValues = value;
        this._redraw = true;
        this.clear();
        this.batchDraw();
      })
    );

    this.subscribe(
      this._rowChartScaleProvider.scaleUnit$.subscribe((value) => {
        this._scaleUnit = value;
        this._redraw = true;
        this.clear();
        this.batchDraw();
      })
    );
  }

  async scale(): Promise<void> {
    const parentElement = this.element.parentElement!;
    if (!parentElement) return;
    const w = parentElement.offsetWidth;
    const h = this._row.height;
    if (w === this.canvas.width && h === this.canvas.height) return;
    const dpr = window.devicePixelRatio || 1;

    this.canvas.width = w * dpr;
    this.canvas.height = h * dpr;
    this.context.scale(dpr, dpr);
    this._redraw = true;
  }

  async doDrawFromBatch(): Promise<void> {
    if (!this._row.visible || !this._show || !this._redraw) return;
    // this.webWorkerDraw<any>();
    this._renderer.render(this.canvas as any, this.context as any, { scaleValues: this._scaleValues, unit: this._scaleUnit });
    this._redraw = false;
  }
}
