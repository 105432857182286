import { Instant, TemporalUnit, ZoneId } from "@js-joda/core";

import { Observable, Subject } from "rxjs";
import { IActivity } from "../IActivity";
import { IActivityRepository } from "../IActivityRepository";
import { Layer } from "../Layer";
import { RepositoryChangedEvent } from "./RepositoryChangedEvent";

export abstract class ActivityRepositoryBase<Activity extends IActivity> implements IActivityRepository<Activity> {
  protected _changeEvents$$ = new Subject<RepositoryChangedEvent>();

  get changeEvent$(): Observable<RepositoryChangedEvent> {
    return this._changeEvents$$.asObservable();
  }

  getEarliestTimeUsed(): Instant {
    return Instant.MIN;
  }

  getLatestTimeUsed(): Instant {
    return Instant.MAX;
  }

  abstract getActivities(layer: Layer, startTime: Instant, endTime: Instant, temporalUnit: TemporalUnit, zoneId: ZoneId): Iterable<Activity>;

  abstract getActivity(layer: Layer, id: string): Activity | undefined;

  abstract getActivitiesById(layer: Layer, id: string[]): Activity[];
}
