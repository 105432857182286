import { BehaviorSubject, combineLatest, debounceTime, map, mergeAll, mergeMap, Observable } from "rxjs";
import { IRowChartDataSet, IRowChartScaleProvider, Row, RowChartRepository } from "../../Model";
import { inject, injectable } from "inversify";
import { GanttEvents } from "../../Events";
import { RowChartScaleProviderBase } from "./RowChartScaleProviderBase";

@injectable()
export class CalculatedRowChartScaleProvider<TRow extends Row<any, any, any>> extends RowChartScaleProviderBase implements IRowChartScaleProvider {
  private _row: BehaviorSubject<TRow> = new BehaviorSubject<TRow>(null!);

  constructor(@inject(Row<any, any, any>) row: TRow,
              @inject(RowChartRepository) private _repository: RowChartRepository,
              @inject(GanttEvents) private _ganttEvents: GanttEvents) {
    super();
    this.row = row;
  }

  get row(): TRow {
    return this._row.value;
  }

  set row(value: TRow) {
    this._row.next(value);
  }

  get row$(): Observable<TRow> {
    return this._row.asObservable();
  }

  public async afterInitialize() {
    await super.afterInitialize();
    const dataSets$ = this._repository.dataSets$.pipe(map((x) => x.filter((ds) => ds.resourceId === this.row.id)));
    this.subscribe(
      dataSets$.pipe(debounceTime(100)).subscribe(() => {
        this.onRowDataSetsChange();
      })
    );

    this.subscribe(
      combineLatest([
        dataSets$.pipe(
          map((x) => x.map((ds) => ds.data$)),
          mergeMap((x) => x)
        )
      ])
        .pipe(
          mergeAll(),
          mergeMap((x) => x)
        )
        .subscribe(() => {
          this.onRowDataSetsChange();
        })
    );
  }

  public onRowDataSetsChange() {
    this.rowDataSetsChanged(this._repository.dataSets.filter((ds) => ds.resourceId === this.row.id));
  }

  private rowDataSetsChanged(data: IRowChartDataSet[]) {
    let scaleValues: number[] = [];
    for (const dataSet of data) {
      scaleValues = [...scaleValues, ...dataSet.data.map((x) => x.y)];
    }
    scaleValues = scaleValues.map(x => Math.round((x + Number.EPSILON) * 100) / 100).sort((a, b) => a - b);
    this.scaleValues$$.next([...new Set(scaleValues)].reverse());
    this._ganttEvents.onRowScaleChangeEvent(this.row.id);
  }
}
