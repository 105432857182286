import { ApiClient } from "@/services/api/common.api";
import { PublishExecutionEventCommand } from "@masta/generated-model";

export default {
  // async sendTaskExecutionEvent() {
  //   return ApiClient.post<ExecutionIssuesResponse>("/ExecutionEvents");
  // },
  async sendTaskExecutionEvent(event: PublishExecutionEventCommand) {
    return ApiClient.post(`/ExecutionEvents/task/${event.taskId}`, event);
  },
  async sendResourceExecutionEvent(event: PublishExecutionEventCommand) {
    return ApiClient.post(`/ExecutionEvents/resource/${event.resourceId}`, event);
  },
  async sendQualityCheckExecutionEvent(event: PublishExecutionEventCommand) {
    return ApiClient.post(`/ExecutionEvents/qualityChecks`, event);
  }
};
