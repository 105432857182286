import { IAgGridRowNumberRequest } from "@masta/generated-model";
import { AdvancedFilterModel, FilterModel, GridApi, SortModelItem } from "ag-grid-community";
import { AxiosResponse } from "axios";

function findRowIndexById(id: string, gridApi: GridApi): number | null {
    const rowNode = gridApi.getRowNode(id);
    if (rowNode) {
        return rowNode.rowIndex;
    }

    return null;
}

function calculatePageNumber(rowIndex: number, pageSize: number) {
  return Math.floor(rowIndex / pageSize);
}

function goToPage(pageNumber: number, gridApi: GridApi) {
  if (gridApi.paginationGetCurrentPage() === pageNumber) return;

  gridApi.paginationGoToPage(pageNumber);
}

function getSortModel(gridApi: GridApi): SortModelItem[] {
  const sortModel = gridApi
    .getColumnState()
    .filter((column) => column.sort !== null)
    .map((column) => {
      return {
        colId: column.colId,
        sort: column.sort
      } as SortModelItem;
    });

  return sortModel;
}

function getFilterModel(gridApi: GridApi): FilterModel {
  return gridApi.getFilterModel();
}

/**
 * This function ensures that the row with the specified id is visible on the page.
 * 
 * At first, the function will try to find the row by id in the grid in existing rows.
 * 
 * If the row is not found, the function will make a request to the server to get the row number 
 * and then navigate to the page where the row is located.
 */
export function ensureRowPageVisible(
  id: string | null | undefined,
  gridApi: GridApi | null | undefined,
  getServerSideRowNumberCallback: (request: IAgGridRowNumberRequest) => Promise<AxiosResponse<number>>
) {
  if (id == null || gridApi == null) {
    return;
  }

  const rowIndex = findRowIndexById(id, gridApi);
  if (rowIndex != null) {
    const pageNumber = calculatePageNumber(rowIndex, gridApi.paginationGetPageSize());

    goToPage(pageNumber, gridApi);
  } else {
    const request = {
      filterModel: getFilterModel(gridApi),
      sortModel: getSortModel(gridApi),
      id: id
    } as IAgGridRowNumberRequest;

    getServerSideRowNumberCallback(request).then((response) => {
      const { data: rowNumber } = response;

      const pageNumber = calculatePageNumber(rowNumber, gridApi.paginationGetPageSize());
      
      goToPage(pageNumber, gridApi);
    });
  }
}

