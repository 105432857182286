import { Instant } from "@js-joda/core";

import { GanttException } from "../../Exceptions";
import { IActivity } from "../IActivity";

import { TimeInterval } from "./TimeInterval";
import { TreeEntry } from "./TreeEntry";

export class TimeIntervalTree<Activity extends IActivity> {
  private root: TreeEntry<Activity> | null | undefined;

  private treeSize = 0;

  public getEarliestTimeUsed(): Instant | null {
    if (this.root) {
      const millis = this.getEarliestTimeUsedForEntry(this.root);
      return millis != null ? Instant.ofEpochMilli(millis) : millis;
    }
    return null;
  }

  public get size(): number {
    return this.treeSize;
  }

  private getEarliestTimeUsedForEntry(entry: TreeEntry<Activity>): number | null {
    if (entry.left) {
      return this.getEarliestTimeUsedForEntry(entry.left);
    }
    return entry.low;
  }

  public getLatestTimeUsed(): Instant | null {
    if (this.root) {
      const millis = this.getLatestTimeUsedForEntry(this.root);
      return millis != null ? Instant.ofEpochMilli(millis) : millis;
    }
    return null;
  }

  private getLatestTimeUsedForEntry(entry: TreeEntry<Activity>): number | null {
    if (entry.right) {
      return this.getLatestTimeUsedForEntry(entry.right);
    }
    return entry.high;
  }

  public add(activity: Activity): boolean {
    const entry = this.addEntry(activity);
    return entry !== null;
  }

  public remove(activity: Activity): boolean {
    const entry = this.getEntry(activity);
    if (!entry) return false;
    this.deleteEntry(entry);
    return true;
  }

  public removePeriod(interval: TimeInterval) {
    const result = this.getIntersectingObjects(interval);
    result.forEach((activity) => {
      this.deleteEntry(this.getEntry(activity));
    });
    return result;
  }

  public getIntersectingObjects(interval: TimeInterval) {
    return this.getIntersectingObjectsBetweenDates(interval.startTime.toEpochMilli(), interval.endTime.toEpochMilli());
  }

  public getIntersectingObjectsBetweenDates(start: number, end: number) {
    const result = new Array<Activity>();
    if (!this.root) {
      return result;
    }
    this.searchIntersecting(this.root, start, end, result);
    return result;
  }

  public getById(id: string) {
    if (!this.root) {
      return null;
    }
    return this.searchById(this.root, id);
  }

  public getByIds(ids: string[]) {
    if (!this.root) {
      return [];
    }
    return this.searchByIds(this.root, ids);
  }

  public clear() {
    this.treeSize = 0;
    this.root = null;
  }

  private searchIntersecting(entry: TreeEntry<Activity>, start: number, end: number, result: Activity[]) {
    if (!entry) {
      return;
    }
    const pLow = start;
    const pHigh = end;
    if (entry.maxHigh < pLow) {
      return;
    }
    if (entry.left) {
      this.searchIntersecting(entry.left, start, end, result);
    }
    if (this.checkPLow(entry, pLow) || this.checkPHigh(entry, pHigh) || (pLow <= entry.low && entry.high <= pHigh)) {
      result.push(entry.value);
    }
    if (pHigh < entry.low) {
      return;
    }
    if (entry.right) {
      this.searchIntersecting(entry.right, start, end, result);
    }
  }

  private checkPLow(entry: TreeEntry<Activity>, pLow: number) {
    return entry.low <= pLow && entry.high > pLow;
  }

  private checkPHigh(entry: TreeEntry<Activity>, pHigh: number) {
    return entry.low < pHigh && entry.high >= pHigh;
  }

  private getLow(obj: Activity) {
    return obj.startTime === null || obj.startTime === undefined ? Number.MIN_VALUE : obj.startTime.toEpochMilli();
  }

  private getHigh(obj: Activity) {
    return obj.endTime === null || obj.endTime === undefined ? Number.MAX_VALUE : obj.endTime.toEpochMilli();
  }

  private fixUpMaxHigh(entry: TreeEntry<Activity> | null | undefined) {
    while (entry != null) {
      entry.maxHigh = Math.max(entry.high, Math.max(entry.left != null ? entry.left.maxHigh : Number.MIN_VALUE, entry.right != null ? entry.right.maxHigh : Number.MAX_VALUE));
      entry = entry.parent;
    }
  }

  private getEntry(activity: Activity) {
    let t = this.root;
    while (t != null) {
      let cmp = this.compareNumbers(this.getLow(activity), t.low);
      if (cmp === 0) {
        cmp = this.compareNumbers(this.getHigh(activity), t.high);
      }
      if (cmp === 0) {
        cmp = activity.hashCode - t.value.hashCode;
      }
      if (cmp < 0) {
        t = t.left;
        // eslint-disable-next-line no-continue
        continue;
      }
      if (cmp > 0) {
        t = t.right;
        // eslint-disable-next-line no-continue
        continue;
      }
      return t;
    }
    return null;
  }

  private addEntry(activity: Activity) {
    let cmp = 0;
    let parent: TreeEntry<Activity>;
    if (!activity) {
      throw new GanttException("null element is not supported");
    }
    let t = this.root;
    if (t === null || t === undefined) {
      this.root = new TreeEntry<Activity>(this.getLow(activity), this.getHigh(activity), activity, null);
      this.treeSize = 1;
      return this.root;
    }
    do {
      parent = t;
      cmp = this.compareNumbers(this.getLow(activity), t.low);
      if (cmp === 0) {
        cmp = this.compareNumbers(this.getHigh(activity), t.high);
        if (cmp === 0) {
          cmp = activity.hashCode - t.value.hashCode;
        }
      }
      if (cmp < 0) {
        t = t?.left;
      } else if (cmp > 0) {
        t = t?.right;
      } else {
        return null;
      }
    } while (t != null);
    const entry = new TreeEntry(this.getLow(activity), this.getHigh(activity), activity, parent);
    if (cmp < 0) {
      parent.left = entry;
    } else {
      parent.right = entry;
    }
    this.fixAfterInsertion(entry);
    this.treeSize++;
    return entry;
  }

  private compareNumbers(num1: number, num2: number) {
    // eslint-disable-next-line no-nested-ternary
    return num1 < num2 ? -1 : num1 === num2 ? 0 : 1;
  }

  private successor(t: TreeEntry<Activity>) {
    if (!t) {
      return null;
    }
    if (t.right) {
      let entry = t.right;
      while (entry.left != null) {
        entry = entry.left;
      }
      return entry;
    }
    let { parent } = t;
    let child = t;
    while (parent != null && child === parent.right) {
      child = parent;
      parent = parent.parent;
    }
    return parent;
  }

  private colorOf(p: TreeEntry<Activity> | null | undefined) {
    return p === null ? true : p?.color;
  }

  private parentOf(p: TreeEntry<Activity> | null | undefined) {
    return p === null ? null : p?.parent;
  }

  private setColor(p: TreeEntry<Activity> | null | undefined, color: boolean) {
    if (p != null) p.color = color;
  }

  private leftOf(p: TreeEntry<Activity> | null | undefined) {
    return p === null ? null : p?.left;
  }

  private rightOf(p: TreeEntry<Activity> | null | undefined) {
    return p === null ? null : p?.right;
  }

  private rotateLeft(p: TreeEntry<Activity> | null | undefined) {
    if (p != null) {
      const r: TreeEntry<Activity> = p.right as TreeEntry<Activity>;
      p.right = r?.left;
      if (r.left) {
        r.left.parent = p;
      }
      r.parent = p.parent;
      if (!p.parent) {
        this.root = r;
      } else if (p.parent.left === p) {
        p.parent.left = r;
      } else {
        p.parent.right = r;
      }
      r.left = p;
      p.parent = r;

      p.maxHigh = Math.max(p.left != null ? p.left.maxHigh : Number.MIN_VALUE, Math.max(p.right != null ? p.right.maxHigh : Number.MIN_VALUE, p.high));
      r.maxHigh = Math.max(p.maxHigh, Math.max(r.right != null ? r.right.maxHigh : Number.MIN_VALUE, r.high));
    }
  }

  private rotateRight(p: TreeEntry<Activity> | null | undefined) {
    if (p != null) {
      const l: TreeEntry<Activity> = p.left as TreeEntry<Activity>;
      p.left = l?.right;
      if (l.right) {
        l.right.parent = p;
      }
      l.parent = p.parent;
      if (!p.parent) {
        this.root = l;
      } else if (p.parent.right === p) {
        p.parent.right = l;
      } else {
        p.parent.left = l;
      }
      l.right = p;
      p.parent = l;

      p.maxHigh = Math.max(p.left != null ? p.left.maxHigh : Number.MIN_VALUE, Math.max(p.right != null ? p.right.maxHigh : Number.MIN_VALUE, p.high));
      l.maxHigh = Math.max(p.maxHigh, Math.max(l.left != null ? l.left.maxHigh : Number.MIN_VALUE, l.high));
    }
  }

  private fixAfterInsertion(x: TreeEntry<Activity> | null | undefined) {
    this.fixUpMaxHigh(x?.parent);
    if (x) x.color = false;
    while (x != null && x !== this.root && !x.parent?.color) {
      if (this.parentOf(x) === this.leftOf(this.parentOf(this.parentOf(x)))) {
        const entry = this.rightOf(this.parentOf(this.parentOf(x)));
        if (!this.colorOf(entry)) {
          this.setColor(this.parentOf(x), true);
          this.setColor(entry, true);
          this.setColor(this.parentOf(this.parentOf(x)), false);
          x = this.parentOf(this.parentOf(x));
          // eslint-disable-next-line no-continue
          continue;
        }
        if (x === this.rightOf(this.parentOf(x))) {
          x = this.parentOf(x);
          this.rotateLeft(x);
        }
        this.setColor(this.parentOf(x), true);
        this.setColor(this.parentOf(this.parentOf(x)), false);
        this.rotateRight(this.parentOf(this.parentOf(x)));
        // eslint-disable-next-line no-continue
        continue;
      }
      const y = this.leftOf(this.parentOf(this.parentOf(x)));
      if (!this.colorOf(y)) {
        this.setColor(this.parentOf(x), true);
        this.setColor(y, true);
        this.setColor(this.parentOf(this.parentOf(x)), false);
        x = this.parentOf(this.parentOf(x));
        // eslint-disable-next-line no-continue
        continue;
      }
      if (x === this.leftOf(this.parentOf(x))) {
        x = this.parentOf(x);
        this.rotateRight(x);
      }
      this.setColor(this.parentOf(x), true);
      this.setColor(this.parentOf(this.parentOf(x)), false);
      this.rotateLeft(this.parentOf(this.parentOf(x)));
    }
    if (this.root) this.root.color = true;
  }

  private deleteEntry(entry: TreeEntry<Activity> | null | undefined) {
    let p = entry!;
    this.treeSize--;
    if (p.left != null && p.right != null) {
      const s = this.successor(p)!;
      p.low = s.low;
      p.high = s.high;
      p.value = s.value;
      p.maxHigh = s.maxHigh;
      p = s;
    }
    const replacement = p?.left != null ? p.left : p?.right;
    if (replacement != null) {
      replacement.parent = p?.parent;
      if (p?.parent == null) {
        this.root = replacement;
      } else if (p === p?.parent.left) {
        p.parent.left = replacement;
      } else {
        p.parent.right = replacement;
      }
      p.left = null;
      p.right = null;
      p.parent = null;
      this.fixUpMaxHigh(replacement.parent);
      if (p.color) this.fixAfterDeletion(replacement);
    } else if (p.parent == null) {
      this.root = null;
    } else {
      if (p.color) this.fixAfterDeletion(p);
      if (p.parent != null) {
        if (p === p.parent.left) {
          p.parent.left = null;
        } else if (p === p.parent.right) {
          p.parent.right = null;
        }
        this.fixUpMaxHigh(p.parent);
        p.parent = null;
      }
    }
  }

  private fixAfterDeletion(x: TreeEntry<Activity> | null | undefined) {
    while (x !== this.root && this.colorOf(x)) {
      if (x === this.leftOf(this.parentOf(x))) {
        let entry = this.rightOf(this.parentOf(x));
        if (!this.colorOf(entry)) {
          this.setColor(entry, true);
          this.setColor(this.parentOf(x), false);
          this.rotateLeft(this.parentOf(x));
          entry = this.rightOf(this.parentOf(x));
        }
        if (this.colorOf(this.leftOf(entry)) && this.colorOf(this.rightOf(entry))) {
          this.setColor(entry, false);
          x = this.parentOf(x);
          // eslint-disable-next-line no-continue
          continue;
        }
        if (this.colorOf(this.rightOf(entry))) {
          this.setColor(this.leftOf(entry), true);
          this.setColor(entry, false);
          this.rotateRight(entry);
          entry = this.rightOf(this.parentOf(x));
        }
        this.setColor(entry, this.colorOf(this.parentOf(x))!);
        this.setColor(this.parentOf(x), true);
        this.setColor(this.rightOf(entry), true);
        this.rotateLeft(this.parentOf(x));
        x = this.root;
        // eslint-disable-next-line no-continue
        continue;
      }
      let sib = this.leftOf(this.parentOf(x));
      if (!this.colorOf(sib)) {
        this.setColor(sib, true);
        this.setColor(this.parentOf(x), false);
        this.rotateRight(this.parentOf(x));
        sib = this.leftOf(this.parentOf(x));
      }
      if (this.colorOf(this.rightOf(sib)) && this.colorOf(this.leftOf(sib))) {
        this.setColor(sib, false);
        x = this.parentOf(x);
        // eslint-disable-next-line no-continue
        continue;
      }
      if (this.colorOf(this.leftOf(sib)) === true) {
        this.setColor(this.rightOf(sib), true);
        this.setColor(sib, false);
        this.rotateLeft(sib);
        sib = this.leftOf(this.parentOf(x));
      }
      this.setColor(sib, this.colorOf(this.parentOf(x))!);
      this.setColor(this.parentOf(x), true);
      this.setColor(this.leftOf(sib), true);
      this.rotateRight(this.parentOf(x));
      x = this.root;
    }
    this.setColor(x, true);
  }

  private searchById(entry: TreeEntry<Activity>, id: string): Activity | null {
    if (entry.value.id === id) {
      return entry.value;
    }
    if (entry.left) {
      const result = this.searchById(entry.left, id);
      if (result) return result;
    }
    if (entry.right) {
      const result = this.searchById(entry.right, id);
      if (result) return result;
    }
    return null;
  }

  private searchByIds(entry: TreeEntry<Activity>, ids: string[]) {
    const results: Activity[] = [];
    for (const id of ids) {
      const activity = this.searchById(entry, id);
      if (activity) {
        results.push(activity);
      }
    }
    return results;
  }
}
