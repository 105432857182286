import { Subject, Observable, Subscription } from "rxjs";

export interface ObservedObject<T extends object> {
  changes$: Observable<T>;
  proxy: T;
}

// eslint-disable-next-line @typescript-eslint/ban-types
export function observeObject<T extends Object>(obj: T): ObservedObject<T> {
  /// Variables
  const changes$ = new Subject<T>();
  const propertySubscriptions = new Map<PropertyKey, Subscription>();

  /// Helper Functions
  const observeChildObject = <U extends object>(target: T, prop: PropertyKey, childVal: U): U => {
    const observedObject = observeObject(childVal);
    const sub = observedObject.changes$.subscribe((childObj) => changes$.next(target));
    propertySubscriptions.set(prop, sub);
    return observedObject.proxy;
  };
  const stopObservingChildObject = (prop: any) => {
    propertySubscriptions.get(prop)?.unsubscribe();
    propertySubscriptions.delete(prop);
  };

  /// Proxy Creation
  const proxy = new Proxy({} as T, {
    set: (target, prop, value, receiver) => {
      // If property is changed TO an object.
      if (typeof value === "object" && !propertySubscriptions.has(prop)) {
        value = observeChildObject(target, prop, value);
      }
      // If property is changed FROM an object.
      else if (typeof value !== "object" && propertySubscriptions.has(prop)) {
        stopObservingChildObject(prop);
      }
      const returnVal = Reflect.set(target, prop, value, receiver);
      changes$.next(target);
      return returnVal;
    },
    deleteProperty: (target, prop) => {
      const returnVal = Reflect.deleteProperty(target, prop);
      if (propertySubscriptions.has(prop)) {
        stopObservingChildObject(prop);
      }
      changes$.next(proxy);
      return returnVal;
    }
  });

  /// Initialization
  for (const key of Object.keys(obj)) {
    (proxy as any)[key] = (obj as any)[key];
  }

  return {
    changes$,
    proxy
  };
}
