<script setup lang="ts">
import DatepickerField from "@/components/Datepicker/DatepickerField.vue";
import { inject, ref, watch } from "vue";
import { $dateFormatterSymbol, DateFormatter } from "@masta/shared";
import { useI18n } from "vue-i18n";
import { GetMaterialsDemandQuery, PeriodAggregationUnits, ResourceInfoDto } from "@masta/generated-model";
import { useScenariosStore } from "@/store/ScenariosStore";
import { $t } from "@/i18n";
import ResourcesPicker from "@/components/Resources/ResourcesPicker.vue";
import { getEnumTitleValuePairs } from "@/composables/enumHelpers";
import { translatePeriodAggregationUnits } from "@/composables/translateEnum";

const $dateFormatter = inject<DateFormatter>($dateFormatterSymbol)!;

const { locale } = useI18n();

const props = defineProps<{
  modelValue: boolean;
}>();
const emit = defineEmits<{
  (e: "update:modelValue", value: boolean): void;
  (e: "calculationParamsSet", query: GetMaterialsDemandQuery): void;
}>();

const start = ref(new Date());
const numberOfPeriods = ref(10);
const periodAggregationUnit = ref(PeriodAggregationUnits.Daily);
const resources = ref<ResourceInfoDto[]>([]);
const units = getEnumTitleValuePairs(PeriodAggregationUnits, translatePeriodAggregationUnits);

watch(
  () => props.modelValue,
  (newVal) => {
    if (!newVal) {
      resetDialog();
    }
  }
);

function resetDialog() {
}

function cancel() {
  emit("update:modelValue", false);
}

//cut timezone
const formatOutputDate = (date: Date) => date.toISOString().slice(0, 10);

function calculate() {
  const scenariosStore = useScenariosStore();
  const query: GetMaterialsDemandQuery = {
    start: formatOutputDate(start.value),
    numberOfPeriods: numberOfPeriods.value,
    aggregate: periodAggregationUnit.value,
    resourceIds: resources.value.map((x) => x.id),
    scenarioId: scenariosStore.selectedScenario?.id || "",
    includePurchaseOrderPredictions: true,
    includeInternalCustomerOrderPredictions: true
  };
  emit("calculationParamsSet", query);
}

function formatDate(date: Date) {
  return $dateFormatter(date);
}
</script>

<template>
  <v-dialog :model-value="modelValue" width="auto" @update:model-value="(value) => emit('update:modelValue', value)">
    <v-card class="pa-8 calculate-card">
      <v-card-title class="d-flex align-center justify-space-between">
        <div class="text-h4">{{ $t("materialsDemand-calculateDialog-title-label", { $: "Material Demands" }) }}</div>
        <v-icon @click="cancel">mdi-close</v-icon>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="6">
              <datepicker-field
                v-model="start"
                :clearable="false"
                :format="formatDate"
                :locale="locale"
                :enable-time-picker="false"
                :label="$t('materialsDemand-calculateDialog-start-label', { $: 'Start' })"
                :placeholder="$t('materialsDemand-calculateDialog-start-placeholder', { $: 'Start' })"
              />
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="numberOfPeriods"
                variant="outlined"
                density="compact"
                hide-details="auto"
                type="number"
                :label="$t('materialsDemand-calculateDialog-numberOfPeriods-label', { $: 'Number of Periods' })"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-select
                v-model="periodAggregationUnit"
                :items="units"
                :label="$t('materialsDemand-calculateDialog-periodAggregationUnit-label', { $: 'Period Aggregation Unit' })"
                variant="outlined"
                density="compact"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <ResourcesPicker v-model="resources" :label="$t('materialsDemand-calculateDialog-resources-label', { $: 'Resources' })" :readonly="false" />
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-row>
          <v-col cols="6">
            <v-btn class="ma-4" variant="elevated" color="secondary" block @click="cancel">
              {{ $t("materialsDemand-parametersDialog-cancel-label", { $: "Cancel" }) }}
            </v-btn>
          </v-col>
          <v-col cols="6">
            <v-btn class="ma-4" variant="elevated" color="primary" block @click="calculate">
              {{ $t("materialsDemand-parametersDialog-calculate-label", { $: "Calculate" }) }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style scoped lang="scss">
.calculate-card {
  overflow: initial !important;
  z-index: initial !important;
}
</style>
