<script lang="ts" setup>
import { GridApi, SelectionChangedEvent } from "ag-grid-community";
import { nextTick, onBeforeUnmount, onMounted, ref, toRef, watch } from "vue";
import { AvailabilityRulesController } from "@/services/api-service";
import { useProductionTasksStore } from "@/store/ProductionTasksStore";
import { $t } from "@/i18n";
import { useSnackbarsStore } from "@/store/SnackbarsStore";
import { useScenariosStore } from "@/store/ScenariosStore";
import { RegisterRootRescheduleCommand } from "@masta/generated-model";
import RescheduleRootTaskDialog from "@/components/Tasks/RescheduleRootTaskDialog.vue";
import { getSelectedNodes } from "@/components/Grid/UseGridSelection";

interface Props {
  gridApi: GridApi | null;
}

const $props = defineProps<Props>();
const snackbarsStore = useSnackbarsStore();
const scenariosStore = useScenariosStore();
const tasksStore = useProductionTasksStore();
const dialog = ref(false);
const selectedDate = ref<string | undefined>(undefined);

onMounted(() => {
  const watchHandler = watch(toRef($props, "gridApi"), (value) => {
    if (value) {
      $props.gridApi?.addEventListener("selectionChanged", onSelectionChanged);
      new AvailabilityRulesController().getAll(scenariosStore.selectedScenario?.id);
      onSelectionChanged({ api: value } as SelectionChangedEvent);
      nextTick(() => {
        watchHandler();
      });
    }
  }, { immediate: true });
});

onBeforeUnmount(() => {
  $props.gridApi?.removeEventListener("selectionChanged", onSelectionChanged);
});

const isRootTaskSelected = ref(false);

function onSelectionChanged(e: SelectionChangedEvent) {
  const selectedNodes = getSelectedNodes(e.api);
  isRootTaskSelected.value = selectedNodes.length === 1 && selectedNodes.filter(x => x.data.id === x.data.rootTaskId).length === selectedNodes.length;
}

async function dateSelected(date: string) {
  await reschedule(date);
}

async function reschedule(date: string) {
  const request = createRescheduleRootTaskRequest(date);
  if (!request) return;

  try {
    await tasksStore.rescheduleRootTask(request);
    await snackbarsStore.createSnackbar({
      message: $t("task-list-taskRescheduled-message", { $: "Tasks rescheduled" }),
      closeable: true
    });
  } catch (e: any) {
    console.error(e);
    await snackbarsStore.createSnackbar({
      message: e.message,
      type: "error",
      closeable: true
    });
  }
}

function createRescheduleRootTaskRequest(date: string): RegisterRootRescheduleCommand | null {
  const selectedTasks = $props.gridApi?.getSelectedNodes() ?? [];

  if (!selectedTasks || selectedTasks.length !== 1 || !scenariosStore.selectedScenario) return null;

  return {
    rootTaskId: selectedTasks[0].data.rootTaskId,
    scenarioId: scenariosStore.selectedScenario.id,
    start: date
  } as RegisterRootRescheduleCommand;
}

/**
 * Expose triggerRescheduledAction function to parent component.
 * Thanks of that we can trigger scheduled action from code.
 */
function triggerRescheduledAction() {
  dialog.value = true;
}

defineExpose({
  triggerRescheduledAction
});
</script>

<template>
  <v-tooltip location="bottom" open-delay="300">
    <template #activator="{ props }">
      <v-list-item
        style="order: 7"
        v-bind="props" dense class="pt-0 pb-0 action-btn" :title="$t('task-list-rescheduleTask-action', { $: 'Reschedule' })" prepend-icon="mdi-calendar-sync"
        :disabled="!isRootTaskSelected"
        @click="dialog = true" />
    </template>
    <span>{{ $t("task-list-rescheduleTask-action", { $: "Reschedule" }) }}</span>
  </v-tooltip>
  <reschedule-root-task-dialog v-model="dialog" v-model:date="selectedDate" @date-selected="dateSelected" />
</template>
