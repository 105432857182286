import type { ActivityBase, IActivity, IActivityObject, Layer, Row } from "../Model";
import type { ActivityBounds } from "../View";

export type ActivityActionEvent<TActivity extends IActivity = any> = {
  x: number;
  y: number;
  w: number;
  h: number;
  offsetTop: number;
  activity: ActivityBase<TActivity>;
  row: Row<any, any, TActivity>;
  layer: Layer;
};

export function createActivityActionEvent<TActivity extends IActivity = any>(
  bounds: ActivityBounds,
  layers: Layer[],
  row: Row<any, any, any>
): ActivityActionEvent<TActivity> | undefined {
  const layer = layers.find((l) => l.id === bounds.layerId);
  if (!layer) {
    return undefined;
  }
  const activities = row.repository.getActivitiesById(layer, [bounds.activityId]);
  if (activities.length !== 1) {
    return undefined;
  }
  return {
    x: bounds.x,
    y: bounds.y,
    w: bounds.width,
    h: bounds.height,
    offsetTop: bounds.offsetTop,
    activity: activities[0],
    row,
    layer
  };
}
