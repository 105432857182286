<script lang="ts" setup>
import { GridApi } from "ag-grid-community";
import { useRouter } from "vue-router";
import { storeToRefs } from "pinia";
import { useAuthStore } from "@/store/AuthStore";
import { getSelectedRows } from "@/components/Grid/UseGridSelection";
import { $t } from "@/i18n";
import { useScenariosStore } from "@/store/ScenariosStore";

interface Props {
  types: string[];
  gridApi?: GridApi;
}

const properties = defineProps<Props>();
const $router = useRouter();

async function openResourceGantt() {
  let resources = "";
  if (properties.gridApi) {
    resources = getSelectedRows(properties.gridApi)?.map((x) => x.id).join(",") ?? "";
  }
  await $router.push({
    name: "ResourcesGantt",
    params: {
      scenarioId: useScenariosStore().scenarioBusinessId
    },
    query: {
      types: [...properties.types],
      resources
    }
  });
}
</script>

<template>
  <v-tooltip location="bottom" open-delay="300">
    <template #activator="{ props }">
      <v-list-item
        v-bind="props" dense class="pt-0 pb-0" :title="$t('gantt-resource-openChart-action-tooltip', { $: 'Selected Resource' })" prepend-icon="mdi-chart-gantt"
        @click="openResourceGantt" />
    </template>
    <span>{{ $t("gantt-resource-openChart-action-tooltip", { $: "Selected Resource" }) }}</span>
  </v-tooltip>
</template>

<style lang="scss" scoped></style>
