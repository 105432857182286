<template>
  <v-app :theme="$vuetify.theme.global.name">
    <v-main>
      <div class="create-tenant w-auto h-screen">
        <v-row no-gutters class="h-screen">
          <v-col cols="12" md="5" class="left px-8">
            <div class="text-center d-flex justify-center align-center pb-10">
              <masta-logo :dark="$vuetify.theme.global.name === 'dark'" health-indicator />
            </div>
            <div />
            <div>
              <v-divider />
              <v-row no-gutters>
                <v-col>
                  <v-form v-model="valid">
                    <v-row class="pa-12" dense>
                      <v-col cols="12" class="text-h4 font-weight-bold text-center">
                        {{ $t("create-tenant-view-createHeader-label", { $: "Create a new Tenant" }) }}
                      </v-col>
                      <v-col cols="12" class="text-h4 text-subtitle-1 text-center">
                        {{ $t("create-tenant-view-createSubheader-label", { $: "Fill the form below:" }) }}
                      </v-col>
                      <v-col cols="12">
                        <v-row>
                          <v-col cols="12">
                            <v-text-field
                              v-model="tenantName"
                              variant="outlined"
                              :label="$t('create-tenant-view-tenantId-label', { $: 'Tenant ID' })"
                              persistent-placeholder
                              :placeholder="$t('create-tenant-view-tenantId-placeholder', { $: 'eg. Starlink' })"
                              required
                              :rules="rules.tenantName"
                            />
                          </v-col>
                          <v-col cols="12">
                            <v-text-field
                              v-model="tenantDisplayName"
                              variant="outlined"
                              :label="$t('create-tenant-view-tenantDisplayName-label', { $: 'Tenant display name' })"
                              persistent-placeholder
                              :placeholder="$t('create-tenant-view-tenantDisplayName-placeholder', { $: 'eg. Starlink Company' })"
                              required
                              :rules="rules.tenantDisplayName"
                            />
                          </v-col>
                          <v-col cols="12">
                            <v-text-field
                              id="email"
                              v-model="adminEmail"
                              variant="outlined"
                              :label="$t('create-tenant-view-tenantAdminEmailAddress-label', { $: 'Tenant admin email address' })"
                              persistent-placeholder
                              :placeholder="$t('create-tenant-view-tenantAdminEmailAddress-placeholder', { $: 'email' })"
                              name="email"
                              required
                              type="email"
                              autocomplete="off"
                              :rules="rules.adminEmail"
                            />
                          </v-col>
                          <v-col cols="12">
                            <v-text-field
                              id="password"
                              v-model="adminPassword"
                              variant="outlined"
                              :label="$t('create-tenant-view-password-label', { $: 'Password' })"
                              :placeholder="$t('create-tenant-view-password-placeholder', { $: '***** ***' })"
                              persistent-placeholder
                              required
                              name="password"
                              :type="adminPasswordVisible ? 'text' : 'password'"
                              autocomplete="new-password"
                              :rules="rules.adminPassword"
                            />
                          </v-col>
                          <v-col cols="12">
                            <v-text-field
                              id="password_repeat"
                              v-model="adminPasswordRepeat"
                              variant="outlined"
                              :label="$t('create-tenant-view-repeatPassword-label', { $: 'Repeat password' })"
                              :placeholder="$t('create-tenant-view-repeatPassword-placeholder', { $: '***** ***' })"
                              persistent-placeholder
                              required
                              name="password_repeat"
                              :type="adminPasswordRepeatVisible ? 'text' : 'password'"
                              autocomplete="new-password"
                              :rules="rules.adminPasswordRepeat"
                            />
                          </v-col>
                          <v-col cols="12">
                            <v-select
                              v-model="tenantType"
                              variant="outlined"
                              :label="$t('create-tenant-view-tenantType-label', { $: 'Select profile for sample data and settings' })"
                              :items="tenantTypes"
                              item-title="name"
                              item-value="id"
                              :rules="rules.tenantType"
                            />
                          </v-col>
                          <v-col cols="12">
                            <v-checkbox v-model="eulaAccepted" :rules="rules.eulaAccepted" color="accent" variant="outlined" density="compact" hide-details class="d-inline-block">
                              <template #label>
                                <div class="text-black text-body-1 opacity-100">
                                  {{ $t("create-tenant-view-eula-iAccept-label", { $: "I hereby accept the" }) }}
                                  <a href="#eula" class="text-primary text-decoration-none">{{ $t("create-tenant-view-eula-eula-label", { $: "end user agreement (EULA)" }) }}</a
                                  >.
                                </div>
                              </template>
                            </v-checkbox>
                          </v-col>
                          <v-col cols="12">
                            <v-btn
                              size="x-large"
                              :color="!valid || submitting || isCreatingTenant ? '' : 'primary'"
                              block
                              type="submit"
                              :disabled="!valid || submitting || isCreatingTenant"
                              :loading="submitting"
                              variant="elevated"
                              @click.prevent="submitBtnClick"
                            >
                              {{ submitBtnLabel }}
                            </v-btn>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-col>
              </v-row>
            </div>
            <div class="text-center d-flex justify-center align-center text-caption position-relative">
              {{ $t("create-tenant-view-alreadyHaveTenantQuestion-label", { $: "You aready have an account? Go back to" }) }}
              <router-link to="/" class="text-primary text-decoration-none px-2">{{ $t("create-tenant-alreadyHaveTenant-label", { $: "Tenant selection" }) }}</router-link>
              <theme-switch class="theme-switch" />
            </div>
          </v-col>
          <v-col md="7" class="right bg-indigo-lighten-5">
            <div class="d-none d-md-inline">
              <v-img src="/app-screen-1.png"></v-img>
            </div>
          </v-col>
        </v-row>
        <eula-modal />
      </div>
      <!--      <view-layout>
              <v-row justify="space-around">
                <v-col class="col-12 col-md-10 col-xl-8">
                  <v-form v-model="valid">
                    <v-card class="mt-16 px-6">
                      <v-card-title class="text-h3 text-center d-block">
                        <div style="width: 100%">
                          <masta-logo :dark="theme === 'dark'" />
                        </div>
                      </v-card-title>
                      <v-card-actions class="justify-center pa-10">
                        <v-row>
                          <v-col cols="12" class="text-h4 text-center">
                            <v-divider />
                            {{ $t("tenant-createTenant-create-label", { $: "Create Tenant" }) }}
                          </v-col>
                          <v-col cols="12" md="6" offset-md="3">
                            <v-row>
                              <v-col cols="6">
                                <v-text-field
                                  v-model="tenantName"
                                  variant="outlined"
                                  label="Tenant ID"
                                  persistent-placeholder
                                  placeholder="eg. starlink"
                                  required
                                  :rules="rules.tenantName"
                                />
                              </v-col>
                              <v-col cols="6">
                                <v-text-field
                                  v-model="tenantDisplayName"
                                  variant="outlined"
                                  label="Tenant display name"
                                  persistent-placeholder
                                  placeholder="eg. My Starlink Tenant"
                                  required
                                  :rules="rules.tenantDisplayName"
                                />
                              </v-col>
                              <v-col cols="6">
                                <v-text-field
                                  id="email"
                                  v-model="adminEmail"
                                  variant="outlined"
                                  label="Tenant admin email address"
                                  persistent-placeholder
                                  placeholder="eg. elon@starlink.org"
                                  name="email"
                                  required
                                  type="email"
                                  autocomplete="off"
                                  :rules="rules.adminEmail"
                                />
                              </v-col>
                              <v-col cols="6">
                                <v-text-field
                                  id="password"
                                  v-model="adminPassword"
                                  variant="outlined"
                                  label="Password"
                                  persistent-placeholder
                                  placeholder="***** ***"
                                  required
                                  name="password"
                                  :type="adminPasswordVisible ? 'text' : 'password'"
                                  autocomplete="new-password"
                                  :rules="rules.adminPassword"
                                />
                              </v-col>
                              <v-col offset="6" cols="6">
                                <v-text-field
                                  id="password_repeat"
                                  v-model="adminPasswordRepeat"
                                  variant="outlined"
                                  label="Repeat password"
                                  persistent-placeholder
                                  placeholder="***** ***"
                                  required
                                  name="password_repeat"
                                  :type="adminPasswordRepeatVisible ? 'text' : 'password'"
                                  autocomplete="new-password"
                                  :rules="rules.adminPasswordRepeat"
                                />
                              </v-col>
                              <v-col cols="12">
                                <v-select v-model="tenantType" variant="outlined" label="Tenant type" :items="tenantTypes" item-title="name" item-value="id" :rules="rules.tenantType" />
                              </v-col>
                              <v-col cols="12">
                                <v-row>
                                  <v-col cols="1" class="text-center align-self-center">
                                    <v-switch
                                      v-model="eulaAccepted"
                                      :rules="rules.eulaAccepted"
                                      color="accent"
                                      variant="outlined"
                                      density="compact"
                                      hide-details
                                      class="d-inline-block"
                                    />
                                  </v-col>
                                  <v-col cols="11" class="align-self-center">I hereby accept the <a href="#eula">end user agreement (EULA)</a>.</v-col>
                                </v-row>
                              </v-col>
                              <v-col cols="12">
                                <v-btn
                                  size="x-large"
                                  color="accent"
                                  block
                                  type="submit"
                                  :disabled="!valid || submitting || isCreatingTenant"
                                  variant="elevated"
                                  @click.prevent="submitBtnClick"
                                >
                                  {{ submitBtnLabel }}
                                </v-btn>
                              </v-col>
                              <v-col cols="12" class="body-1 text-center">
                                <v-divider />
                                <br />
                                <router-link to="/" class="text-black"> back to login page</router-link>
                              </v-col>
                            </v-row>
                          </v-col>
                        </v-row>
                      </v-card-actions>
                    </v-card>
                  </v-form>
                </v-col>
              </v-row>
              <eula-modal />
            </view-layout>-->
    </v-main>
  </v-app>
</template>

<script>
import ThemeSwitch from "@/components/ThemeSwitch.vue";
import MastaLogo from "@/components/MastaLogo.vue";
import EulaModal from "@/components/EulaModal.vue";
import SystemEnumService from "@/services/system-enum.service";
import { mapActions, mapState } from "pinia";
import { useAuthStore } from "@/store/AuthStore";
import { useSnackbarsStore } from "@/store/SnackbarsStore";

export default {
  name: "CreateTenantApp",
  components: {
    EulaModal,
    ThemeSwitch,
    MastaLogo
  },
  data: (vm) => {
    return {
      valid: false,
      submitting: false,
      tenantName: null,
      tenantDisplayName: null,
      adminEmail: null,
      adminPassword: null,
      adminPasswordRepeat: null,
      adminPasswordVisible: false,
      adminPasswordRepeatVisible: false,
      tenantType: undefined,
      eulaAccepted: false,
      tenantTypes: [
        { id: 1, name: SystemEnumService.tenantType(1) },
        { id: 0, name: SystemEnumService.tenantType(0) }
      ],
      submitBtnLabel: vm.$t("create-tenant-view-submitBtn-create-label", { $: "Create Tenant" })
    };
  },
  title: "CDEMS",
  computed: {
    ...mapState(useAuthStore, {
      isCreatingTenant: "creatingTenant"
    }),
    rules() {
      return {
        tenantName: [
          (v) => (v !== undefined && v !== null && v !== "") || "Required", 
          (v) => /^[a-z0-9]+$/.test(v) || "Can contain only lowercase letters and digits",
          (v) => (v.length > 2) || "3 characters minimum"
        ],
        tenantDisplayName: [(v) => (v !== undefined && v !== null && v !== "") || "Required"],
        tenantType: [(v) => !isNaN(v) || "Required"],
        adminEmail: [
          (v) => (v !== undefined && v !== null && v !== "") || "Required",
          (v) => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,12})+$/.test(v) || "E-mail must be valid"
        ],
        adminPassword: [
          (v) => (v !== undefined && v !== null && v !== "") || "Required",
          (v) =>
            !v ||
            /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z])(?=.*[#$^+=!*()@%&]).{8,}$/.test(v) ||
            "Password must contain at least 8 characters including A-Z, a-z, 0-9 and one of #$^+=!*()@%&"
        ],
        adminPasswordRepeat: [
          (v) => (v !== undefined && v !== null && v !== "") || "Required",
          (v) =>
            !v ||
            /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z])(?=.*[#$^+=!*()@%&]).{8,}$/.test(v) ||
            "Password must contain at least 8 characters including A-Z, a-z, 0-9 and one of #$^+=!*()@%&",
          (v) => {
            return v === this.adminPassword || "Password fields are not equal";
          }
        ],
        eulaAccepted: [(v) => (v !== undefined && v !== null && v === true) || "Required"]
      };
    }
  },
  mounted() {
    this.tenantType = 1;
  },
  methods: {
    ...mapActions(useAuthStore, ["createTenant", "finishCreatingTenant"]),
    ...mapActions(useSnackbarsStore, ["createSnackbar"]),
    async submitBtnClick() {
      if (!this.valid) return;
      this.submitting = true;
      const payload = {
        name: this.tenantName,
        displayName: this.tenantDisplayName,
        tenantType: this.tenantType,
        adminEmail: this.adminEmail,
        adminPassword: this.adminPassword
      };
      try {
        this.submitBtnLabel = this.$t("create-tenant-view-submitBtn-creating-label", { $: "Please wait. Creating tenant..." });
        await this.createSnackbar({
          message: this.$t("create-tenant-view-toast-creationInProgress-label", { $: "Please wait. Tenant creation in progress..." }),
          type: "info",
          closeable: false
        });
        await this.createTenant(payload);
        await this.createSnackbar({
          message: this.$t("create-tenant-view-toast-createdSuccessfully-label", { $: "Tenant created successfully!" }),
          type: "info",
          closeable: false
        });
        this.finishCreatingTenant();
        this.submitBtnLabel = this.$t("create-tenant-view-submitBtn-created-label", { $: "Tenant created!" });
        setTimeout(async () => {
          await this.$router.push({ path: `/${this.tenantName}` });
        }, 2000);
      } catch (err) {
        console.error(err);
        this.submitBtnLabel = this.$t("create-tenant-view-submitBtn-create-label", { $: "Create Tenant" });
        this.submitting = false;
        await this.createSnackbar({
          message: this.$t("create-tenant-view-toast-creationError-label", { $: "Could not create tenant." }) + err.message,
          type: "error"
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.create-tenant {
  // Needed to see see the page content
  overflow: scroll;

  .left {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 0.3fr auto 1fr 0.1fr;
    align-content: space-between;

    .theme-switch {
      position: absolute;
      left: 0;
    }
  }

  .right {
    display: grid;
    grid-template-rows: auto;
    grid-template-columns: 1fr;
    align-content: center;
    justify-content: center;
  }
}
</style>
