import { OrderLineStatus, OrderReleaseStatus } from "@masta/generated-model";

export function getOrderReleaseStatusCellStyle(source: OrderReleaseStatus) {
  if (source === OrderReleaseStatus.PartiallyReleased) {
    return {
      color: "#434343",
      "background-color": "rgb(222,218,242, 0.7)"
    };
  }
  if (source === OrderReleaseStatus.Released) {
    return {
      color: "#140853",
      "background-color": "rgb(168, 232, 255, 0.7)"
    };
  }
  return null;
}

export function getOrderLineStatusCellStyle(source: OrderLineStatus) {
  if (source === OrderLineStatus.PreReleased) {
    return {
      color: "#434343",
      "background-color": "rgb(222,218,242, 0.7)"
    };
  }
  if (source === OrderLineStatus.Released) {
    return {
      color: "#140853",
      "background-color": "rgb(168, 232, 255, 0.7)"
    };
  }
  if (source === OrderLineStatus.InPreparation) {
    return {
      color: "#140853",
      "background-color": "#FFF9C4"
    };
  }
  if (source === OrderLineStatus.ReturnedToInPreparation) {
    return {
      color: "#140853",
      "background-color": "#FFF9C4"
    };
  }
  return null;
}
