<script lang="ts" setup>
import { embedDashboard, EmbedDashboardParams } from "@superset-ui/embedded-sdk";
import { inject, onBeforeMount, onMounted, ref, toRefs, watch } from "vue";
import ApiService from "@/services/api";
import { $appContextSymbol } from "@/plugins/app.plugin";

const props = defineProps<{ id: string }>();
const $props = toRefs(props);

const $appContext = inject<any>($appContextSymbol as any)!;

const loading = ref<boolean>(true);
const dashboard = ref<HTMLElement>();

onMounted(() => {
  embed($props.id.value);
});
watch($props.id, () => {
  loading.value = true;
  embed($props.id.value);
});

function embed(dashbordEmbeddedId: string) {
  if (dashboard.value) {
    embedDashboard({
      id: dashbordEmbeddedId, // given by the Superset embedding UI
      supersetDomain: $appContext.value.supersetDomain,
      mountPoint: dashboard.value,
      fetchGuestToken: async () => {
        try {
          return (await ApiService.reports.getDashboardGuestToken(dashbordEmbeddedId)).data;
        } catch (err) {
          console.error("Could not get guest token from API", err);
          return null!;
        } finally {
          loading.value = false;
        }
      },
      debug: true,
      dashboardUiConfig: {
        // dashboard UI config: hideTitle, hideTab, hideChartControls, filters.visible, filters.expanded (optional)
        hideTitle: true,
        filters: {
          expanded: true
        }
      }
    } as EmbedDashboardParams);
    loading.value = false;
  }
}
</script>

<template>
  <div ref="dashboard" class="dashboard fill-height w-full text-center">
    <v-progress-linear v-if="loading" indeterminate />
    <div v-else class="text-body-1 text-center text-error">{{ $t("dashboard-view-superset-notAvailable-label", { $: "Dashboard unavailable!" }) }}</div>
  </div>
</template>

<style lang="scss">
.dashboard {
  iframe {
    width: 100%;
    height: 100%;
    border: 0;
  }
}
</style>
