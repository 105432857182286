import ApiService from "@/services/api";
import { defineStore } from "pinia";
import { useScenariosStore } from "@/store/ScenariosStore";
import { useErrorsStore } from "@/store/ErrorsStore";
import { CommunicationItemDto } from "@masta/generated-model";

interface State {
  communications: CommunicationItemDto[];
}

export const useCommunicationsStore = defineStore("communications", {
  state: (): State => ({
    communications: []
  }),
  actions: {
    async fetch() {
      try {
        const scenariosStore = useScenariosStore();
        if (!scenariosStore.selectedScenario) return;
        const response = await ApiService.communications.getAll({
          scenarioId: scenariosStore.selectedScenario.id
        });
        this.communications = response.data.communicationItems;
      } catch (e) {
        const errorsStore = useErrorsStore();
        errorsStore.handleError(e);
        throw e;
      }
    }
  }
});
