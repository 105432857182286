﻿<template>
  <div v-if="step" class="fill-height d-flex flex-column task-steps-details">
    <div class="tabs">
      <v-tabs
        v-model="tab"
        class="tab-details text-disabled bg-white"
        selected-class="v-slide-group-item--active text-primary bg-white"
        density="compact"
        color="primary"
        show-arrows
      >
        <v-tab :theme="theme.name" value="specifications">
          {{ $t("task-taskDetails-specification-label", { $: "Specification" }) }}
        </v-tab>
        <v-tab :theme="theme.name" value="capacities">
          {{ $t("task-taskDetails-capacities-label", { $: "Capacities" }) }}
        </v-tab>
      </v-tabs>
    </div>
    <v-sheet class="flex-fill" rounded>
      <v-window v-model="tab" :theme="theme.name" class="fill-height">
        <v-window-item value="specifications" class="fill-height">
          <step-resource-spec-grid :step="step" @manage-model-instances="manageModelInstances" />
        </v-window-item>
        <v-window-item value="capacities" class="fill-height">
          <task-resource-capacities-grid :step="step" />
        </v-window-item>
      </v-window>
      <div v-if="editModelInstanceAssignmentDialog">
        <model-instance-assignment-editor
          task-assignment
          :model-instances="spec.modelInstances"
          :opened="editModelInstanceAssignmentDialog"
          @cancel="editModelInstanceAssignmentDialog = false"
          @save="saveModelInstanceAssignment"
        >
          <task-label
            :spec-resource-type="getResourceType()"
            :spec-type="getAssignmentType()"
            :step-name="step.name"
            :step-position="step.position"
            :task-external-id="task.businessId"
            :task-name="task.name"
            type="Step Resource Specification"
          />
        </model-instance-assignment-editor>
      </div>
    </v-sheet>
  </div>
</template>

<script>
import StepResourceSpecGrid from "@/components/Tasks/StepResourceSpecGrid.vue";
import TaskResourceCapacitiesGrid from "@/components/Tasks/TaskResourceCapacitiesGrid.vue";
import ModelInstanceAssignmentEditor from "@/components/ModelInstances/ModelInstanceAssignment/ModelInstanceAssignmentEditor";
import TaskLabel from "@/components/ModelInstances/ModelInstanceAssignment/TaskLabel";
import SystemEnumService from "@/services/system-enum.service";
import { mapActions, mapState } from "pinia";
import { useProductionTasksStore } from "@/store/ProductionTasksStore";

export default {
  name: "TaskStepDetails",
  components: {
    TaskLabel,
    TaskResourceCapacitiesGrid,
    StepResourceSpecGrid,
    ModelInstanceAssignmentEditor
  },
  props: {
    theme: {
      type: String,
      default: undefined
    }
  },
  data() {
    return {
      step: null,
      task: null,
      spec: null,
      tab: null,
      editModelInstanceAssignmentDialog: false,
      refreshGridAction: null
    };
  },
  methods: {
    ...mapActions(useProductionTasksStore, ["updateModelInstanceAssignment", "fetchDetail"]),
    manageModelInstances(spec, refreshGridAction) {
      this.refreshGridAction = refreshGridAction;
      this.spec = spec;
      this.editModelInstanceAssignmentDialog = true;
    },
    async saveModelInstanceAssignment(instances) {
      await this.updateModelInstanceAssignment({
        taskId: this.step.taskId,
        stepId: this.step.id,
        stepResourceSpecId: this.spec.id,
        modelInstances: instances.map((x) => ({
          modelInstanceId: x.id,
          revisionNumber: x.revisionNumber
        }))
      });
      this.refreshGridAction();
      this.editModelInstanceAssignmentDialog = false;
    },
    getAssignmentType() {
      return SystemEnumService.stepResourceAssignmentType(this.spec.assignmentType);
    },
    getResourceType() {
      return SystemEnumService.resourceType(this.spec.resourceType);
    },
    async loadStepDetails(taskStep, taskDetail) {
      if (taskStep) {
        this.step = taskStep;
        if (taskDetail) {
          this.task = taskDetail;
        } else {
          this.task = await this.fetchDetail(taskStep.taskId);
        }
      } else {
        this.step = undefined;
        this.task = undefined;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "vuetify/lib/styles/settings/colors";
//
.task-steps-details {
  display: flex;
  flex-direction: row;

  .tabs {
    .v-tab {
      color: rgba(var(--v-theme-on-background), var(--v-disabled-opacity));
    }

    .v-tab:not(.v-tab--selected) {
      border-bottom: 1px solid #e0e0e0;
    }
  }
}
</style>
