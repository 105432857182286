<script lang="ts" setup>
import { enumToEditorEntries, enumValueEntryWithLocaleComparator, translateEditorEntries } from "@/components/Grid/ColumnTypes";
import GridWrapper from "@/components/Grid/GridWrapper.vue";
import { GridWrapperComponent } from "@/components/Grid/GridWrapperComponent";
import { useReferenceGrid } from "@/components/Grid/UseReferenceGrid";
import { ScenarioServerSideDataSource } from "@/components/Scenarios/ScenarioServerSideDataSource";
import { translateScenarioStatus } from "@/composables/translateEnum";
import { $t } from "@/i18n";
import { ScenarioDto, ScenarioStatus } from "@masta/generated-model";
import { $dateTimeFormatterSymbol, $dateTimeUrlSafeFormatterSymbol, DateFormatter } from "@masta/shared";
import { ColDef, GridApi, KeyCreatorParams, ValueFormatterParams } from "ag-grid-community";
import { inject, reactive, Ref, ref } from "vue";
import ActionsButton from "@/components/Layout/ActionsButton.vue";
import { useSnackbarsStore } from "@/store/SnackbarsStore";
import ApiService from "@/services/api";
import { getSelectedRows } from "@/components/Grid/UseGridSelection";

const $dateTimeFormatter = inject<DateFormatter>($dateTimeFormatterSymbol)!;
const $dateTimeUrlSafeFormatter = inject<DateFormatter>($dateTimeUrlSafeFormatterSymbol)!;

const serverSideDataSource = reactive(new ScenarioServerSideDataSource("scenarios"));
const snackbarsStore = useSnackbarsStore();

const gridWrapperRef = ref<GridWrapperComponent>();
const defaultColumnDef = ref({
  filter: false,
  floatingFilter: true,
  filterParams: {
    applyMiniFilterWhileTyping: true
  },
  sortable: true,
  resizable: true
});

interface Props {
  referenceValue?: string;
}

const $emits = defineEmits(["rowSelected", "deleteAction", "rowDoubleClicked"]);
const props = defineProps<Props>();
const selectedRow = ref<ScenarioDto | null>(null);
const selectedRows = ref<ScenarioDto[]>([]);

function onPrepareColumns(columnDefs: Ref<ColDef[]>) {
  columnDefs.value = [
    {
      field: "id",
      editable: false,
      sortable: true,
      filter: "agTextColumnFilter",
      type: ["textInputTypeColumn", "textFloatingFilterColumnType"],
      headerValueGetter: (_: any) => $t("scenario-list-id-label", { $: "ID" }),
      floatingFilterComponentParams: {
        placeholder: $t("scenario-edit-id-label", { $: "ID" })
      }
    },
    {
      field: "businessId",
      editable: true,
      sortable: true,
      filter: "agTextColumnFilter",
      type: ["textInputTypeColumn", "textFloatingFilterColumnType"],
      cellEditorParams: {
        placeholder: $t("scenario-edit-businessId-label", { $: "Business ID" })
      },
      headerValueGetter: (_: any) => $t("scenario-list-businessId-label", { $: "Business ID" }),
      floatingFilterComponentParams: {
        placeholder: $t("scenario-edit-businessId-label", { $: "Business ID" })
      }
    },
    {
      field: "name",
      editable: true,
      sortable: true,
      filter: "agTextColumnFilter",
      type: ["textInputTypeColumn", "textFloatingFilterColumnType"],
      cellEditorParams: {
        placeholder: $t("scenario-edit-name-label", { $: "Name" })
      },
      headerValueGetter: (_: any) => $t("scenario-list-name-label", { $: "Name" }),
      floatingFilterComponentParams: {
        placeholder: $t("scenario-edit-name-label", { $: "Name" })
      }
    },
    {
      field: "UserId",
      editable: false,
      sortable: true,
      filter: "agTextColumnFilter",
      type: ["textInputTypeColumn", "textFloatingFilterColumnType"],
      headerValueGetter: (_: any) => $t("scenario-list-userId-label", { $: "User Id" }),
      floatingFilterComponentParams: {
        placeholder: $t("scenario-edit-userId-label", { $: "UserId" })
      }
    },
    {
      field: "status",
      editable: false,
      sortable: true,
      filter: "agSetColumnFilter",
      type: ["enumTypeColumn", "setFloatingFilterColumnType"],
      filterParams: {
        valueFormatter: (params: ValueFormatterParams) => params.value.key,
        keyCreator: (params: KeyCreatorParams) => params.value.value,
        values: translateEditorEntries(enumToEditorEntries(ScenarioStatus), translateScenarioStatus),
        comparator: enumValueEntryWithLocaleComparator
      },
      valueFormatter: (params: any) => {
        return translateScenarioStatus(params.data?.status);
      },
      headerValueGetter: (_: any) => $t("scenario-list-status-label", { $: "Status" })
    },
    {
      field: "createdAt",
      type: ["dateTimeTypeColumn"],
      filter: "agDateColumnFilter",
      editable: false,
      resizable: true,
      sortable: true,
      valueFormatter: (params: any) => {
        return $dateTimeFormatter(params.data.createdAt);
      },
      headerValueGetter: (_: any) => $t("scenario-list-createdAt-label", { $: "Created At" })
    }
  ];
}

function onSelectionChanged({ api }: { api: GridApi }) {
  selectedRows.value = getSelectedRows(api);
  selectedRow.value = selectedRows.value.length === 1 ? selectedRows.value[0] : null;
}

const refGrid = useReferenceGrid({
  $emits,
  isRowSelected: (data: any) => data.id === props.referenceValue
});

async function onCreateAction() {
  if (!selectedRow.value) {
    return;
  }
  let businessId = $t("scenario-list-initialBusinessId-label", { $: "Simulation" });
  businessId += "-" + $dateTimeUrlSafeFormatter(new Date());
  try {
    await ApiService.scenarios.createScenario({
      name: "",
      businessId: businessId,
      sourceScenarioId: selectedRow.value.id,
      status: ScenarioStatus.Simulation
    });
    snackbarsStore.createSnackbar({
      message: $t("scenario-list-scenarioCreated-message", { $: "Scenario created" }),
      closeable: true
    });
    gridWrapperRef.value?.gridApi.refreshServerSide();
  } catch (e: any) {
    console.error(e);
    snackbarsStore.createSnackbar({
      message: e.message,
      type: "error",
      closeable: true
    });
  }
}

// function onMergeAction() {
//   console.log("Merge action");
// }
//
// function onArchiveAction() {
//   console.log("Archive action");
// }

const actions = ref([
  {
    title: $t("scenario-list-createNewFrom-action", { $: "Create New Scenario From Selected" }),
    tooltip: $t("scenario-list-createNewFrom-actionTooltip", { $: "Create New Scenario From Selected" }),
    icon: "mdi-timeline-plus-outline",
    action: onCreateAction,
    disabled: () => !selectedRow.value,
    order: 1
  }
  // {
  //   title: $t("scenario-list-merge-action", { $: "Merge" }),
  //   tooltip: $t("scenario-list-merge-actionTooltip", { $: "Merge Scenario To" }),
  //   icon: "mdi-call-merge",
  //   action: onMergeAction,
  //   order: 2
  // },
  // {
  //   title: $t("scenario-list-archive-action", { $: "Archive" }),
  //   tooltip: $t("scenario-list-archive-actionTooltip", { $: "Archive Scenario" }),
  //   icon: "mdi-archive",
  //   action: onArchiveAction,
  //   order: 2
  // }
]);
</script>

<template>
  <grid-wrapper
    ref="gridWrapperRef"
    row-selection="single"
    :default-col-def="defaultColumnDef"
    identifier="scenarios"
    edit-btn
    delete-btn
    refresh-btn
    server-side
    :server-side-datasource="serverSideDataSource"
    @prepare-columns="onPrepareColumns"
    @ready="refGrid.onGridReady"
    @selection-changed="onSelectionChanged"
  >
    <template #custom-buttons>
      <div class="d-inline-flex pr-4">
        <actions-button :model-value="actions"></actions-button>
      </div>
    </template>
  </grid-wrapper>
</template>

<style lang="scss" scoped></style>
