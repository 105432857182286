import { TemporalUnit } from "@js-joda/core";
import { BehaviorSubject, Observable } from "rxjs";

import { Resolution } from "./Resolution";
import { GanttException } from "../../Exceptions";

export abstract class DatelineModel<TUnit extends TemporalUnit> {
  private _availableZoneIds = new Set<string>();

  private _resolutionMap = new Map<TUnit, Array<Resolution<TUnit>>>();

  private _resolutions = new Array<Resolution<TUnit>>();

  private _temporalUnits = new Array<TUnit>();

  private _scaleCount$$ = new BehaviorSubject<number>(2);

  private _minScaleCount = 1;

  private _maxScaleCount = 5;

  protected constructor() {
    this.addZoneId("Europe/Berlin");
    this.addZoneId("America/New_York");
    this.addZoneId("Australia/Darwin");
    this.addZoneId("Australia/Sydney");
    this.addZoneId("America/Argentina/Buenos_Aires");
    this.addZoneId("Africa/Cairo");
    this.addZoneId("America/Anchorage");
    this.addZoneId("America/Sao_Paulo");
    this.addZoneId("Asia/Dhaka");
    this.addZoneId("Africa/Harare");
    this.addZoneId("America/St_Johns");
    this.addZoneId("America/Chicago");
    this.addZoneId("Asia/Shanghai");
    this.addZoneId("Africa/Addis_Ababa");
    this.addZoneId("Europe/Paris");
    this.addZoneId("America/Indiana/Indianapolis");
    this.addZoneId("Asia/Kolkata");
    this.addZoneId("Asia/Tokyo");
    this.addZoneId("Pacific/Apia");
    this.addZoneId("Asia/Yerevan");
    this.addZoneId("Pacific/Auckland");
    this.addZoneId("Asia/Karachi");
    this.addZoneId("America/Phoenix");
    this.addZoneId("America/Puerto_Rico");
    this.addZoneId("America/Los_Angeles");
    this.addZoneId("Pacific/Guadalcanal");
    this.addZoneId("Asia/Ho_Chi_Minh");
  }

  public addZoneId(zoneId: string) {
    if (!zoneId || zoneId === "") throw new GanttException("zoneId can not be empty");
    this._availableZoneIds.add(zoneId);
  }

  public addResolution(resolution: Resolution<TUnit>) {
    this._resolutions.push(resolution);
    const { temporalUnit } = resolution;
    let list = this._resolutionMap.get(temporalUnit);
    if (!list) {
      list = new Array<Resolution<TUnit>>();
      this._resolutionMap.set(temporalUnit, list);
    }
    list.push(resolution);
    if (!this._temporalUnits.includes(temporalUnit)) {
      this._temporalUnits.push(temporalUnit);
    }
  }

  public removeResolution(resolution: Resolution<TUnit>) {
    let idx = this._resolutions.indexOf(resolution);
    if (idx > -1) {
      this._resolutions.splice(idx, 1);
    }
    const { temporalUnit } = resolution;
    const list = this._resolutionMap.get(temporalUnit);
    if (list) {
      idx = list.indexOf(resolution);
      if (idx > -1) {
        list.splice(idx, 1);
        if (list.length === 0) {
          this._resolutionMap.delete(temporalUnit);
          if (this._temporalUnits.includes(temporalUnit)) {
            idx = this._temporalUnits.indexOf(temporalUnit);
            if (idx > -1) {
              this._temporalUnits.splice(idx, 1);
            }
          }
        }
      }
    }
  }

  public clearResolutions() {
    this._resolutions.splice(0, this._resolutions.length);
    this._temporalUnits.splice(0, this._temporalUnits.length);
    this._resolutionMap.clear();
  }

  get scaleCount$(): Observable<number> {
    return this._scaleCount$$.asObservable();
  }

  get scaleCount(): number {
    return this._scaleCount$$.value;
  }

  set scaleCount(value: number) {
    if (value < this._minScaleCount || value > this._maxScaleCount) {
      throw new GanttException(`scale count must be between ${this._minScaleCount} and ${this._maxScaleCount} but was ${value}`);
    }
    this._scaleCount$$.next(value);
  }

  get minScaleCount(): number {
    return this._minScaleCount;
  }

  set minScaleCount(value: number) {
    if (value < 1 || value > 5) {
      throw new GanttException(`min scale count must be between 1 and 5 but was ${value}`);
    }
    if (value > this._maxScaleCount) {
      throw new GanttException(`min scale count must be greater than max scale count ${this._maxScaleCount} but was ${value}`);
    }
    this._minScaleCount = value;
    this.scaleCount = Math.min(this.scaleCount, value);
  }

  get maxScaleCount(): number {
    return this._maxScaleCount;
  }

  set maxScaleCount(value: number) {
    if (value < 1 || value > 5) {
      throw new GanttException(`max scale count must be between 1 and 5 but was ${value}`);
    }
    if (value < this._minScaleCount) {
      throw new GanttException(`max scale count must be greater than min scale count ${this._minScaleCount} but was ${value}`);
    }
    this._maxScaleCount = value;
    this.scaleCount = Math.max(this.scaleCount, value);
  }

  get availableZoneIds(): Array<string> {
    return [...this._availableZoneIds];
  }

  get resolutionMap(): Map<TUnit, Array<Resolution<TUnit>>> {
    return this._resolutionMap;
  }

  get resolutions(): Array<Resolution<TUnit>> {
    return [...this._resolutions];
  }

  public abstract nextTemporalUnit(temporalUnit: TUnit): TUnit;
}
