import { ApiClient } from "@/services/api/common.api";
import {
  ApprovePurchaseOrderCommand,
  ConfirmPurchaseOrderCommand,
  CreateOrUpdatePurchaseOrderCommand,
  CreateOrUpdatePurchaseOrderLineCommand,
  CreatePurchaseOrderFromPredictionsCommand,
  GetPurchaseOrderLinesPaginatedQuery,
  GetPurchaseOrdersPaginatedQuery,
  IAgGridPaginationResponse,
  PurchaseOrderDto,
  PurchaseOrderLineDto,
  RegisterStartDeliveringPurchaseOrderCommand,
  RejectPurchaseOrderCommand
} from "@masta/generated-model";

export default {
  async getAll(scenarioId?: string) {
    return ApiClient.get<IAgGridPaginationResponse<PurchaseOrderDto>>(`/purchaseOrders?scenarioId=${scenarioId}`);
  },
  async getPaginated(request: GetPurchaseOrdersPaginatedQuery) {
    return ApiClient.post<IAgGridPaginationResponse<PurchaseOrderDto>>(`/purchaseOrders`, request);
  },
  async getSingle(id: string, scenarioId: string) {
    return ApiClient.get<PurchaseOrderDto>(`/purchaseOrders/${id}?scenarioId=${scenarioId}`);
  },
  async createOrUpdate(request: CreateOrUpdatePurchaseOrderCommand) {
    return ApiClient.put<string>(`/purchaseOrders`, request);
  },
  async remove(id: string, scenarioId: string) {
    return ApiClient.delete(`/purchaseOrders/${id}?scenarioId=${scenarioId}`);
  },
  async getPurchaseOrderLinesPaginated(request: GetPurchaseOrderLinesPaginatedQuery) {
    return ApiClient.post<IAgGridPaginationResponse<PurchaseOrderLineDto>>(`/purchaseOrders/${request.orderId}/lines`, request);
  },
  async getPurchaseOrderLine(orderId: string, orderLineId: string, scenarioId: string) {
    return ApiClient.get(`/purchaseOrders/${orderId}/lines/${orderLineId}?scenarioId=${scenarioId}`);
  },
  async confirm(request: ConfirmPurchaseOrderCommand) {
    return ApiClient.post(`/purchaseOrders/${request.orderId}/confirm`, request);
  },
  async approve(request: ApprovePurchaseOrderCommand) {
    return ApiClient.post(`/purchaseOrders/${request.orderId}/approve`, request);
  },
  async reject(request: RejectPurchaseOrderCommand) {
    return ApiClient.post(`/purchaseOrders/${request.orderId}/reject`, request);
  },
  async startDelivering(request: RegisterStartDeliveringPurchaseOrderCommand) {
    return ApiClient.post(`/purchaseOrders/${request.orderId}/startDelivering`, request);
  },
  async createOrUpdatePurchaseOrderLine(request: CreateOrUpdatePurchaseOrderLineCommand) {
    return ApiClient.put<string>(`/purchaseOrders/${request.orderId}/lines`, request);
  },
  async removePurchaseOrderLine(orderId: string, id: string, scenarioId?: string) {
    return ApiClient.delete(`/purchaseOrders/${orderId}/lines/${id}?scenarioId=${scenarioId}`);
  },
  // async correctPurchaseOrderLine(request: RegisterCorrectPurchaseOrderLineCommand) {
  //   return ApiClient.post(`/purchaseOrders/${request.orderId}/lines/${request.id}/correct`, request);
  // },
  // async updateModelInstanceAssignment(request: UpdatePurchaseOrderModelInstanceAssignmentCommand) {
  //   return ApiClient.post(`/purchaseOrders/updateModelAssignment`, request);
  // }
  async createFromPurchaseOrderPredictions(request: CreatePurchaseOrderFromPredictionsCommand) {
    return ApiClient.post(`/purchaseOrders/createFromPredictions`, request);
  }
};
