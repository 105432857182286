<script setup lang="ts">

import CloseCardButton from "@/components/CloseCardButton.vue";
import ScenariosGrid from "@/components/Scenarios/ScenariosGrid.vue";
import { ScenarioDto } from "@masta/generated-model";
import { ref } from "vue";


const dialog = defineModel<boolean>("dialog", { required: true, default: false });
const scenario = defineModel<ScenarioDto | null | undefined>("scenario", { required: true });

const selectedScenario = ref<ScenarioDto | null>(null);

function onBtnCancel() {
    dialog.value = false;
}

function onBtnConfirm() {
    if (selectedScenario.value) {
        scenario.value = selectedScenario.value;
    }
    dialog.value = false;
}

function onRowSelected(rowData:ScenarioDto) {
    selectedScenario.value = rowData;
}

</script>


<template>
    <v-dialog v-model="dialog" width="100%" height="90vh" @click:outside="onBtnCancel">
        <v-card min-width="300px" height="100%" class="reference-picker-card">
            <close-card-button @click="onBtnCancel"></close-card-button>
            <v-card-title class="pa-0">
                <div class="text-h4 pb-5">{{ $t("scenarioSelector-label", { $: "Select scenario" }) }}</div>
            </v-card-title>
            <v-card-text class="px-0">
                <scenarios-grid
                    row-selection="single"
                    :reference-value="scenario?.id"
                    @row-selected="onRowSelected" />                
            </v-card-text>
            <v-card-actions class="pa-0">
                <div class="left-col pl-0">
                    <v-btn variant="elevated" color="secondary" class="w-100" @click="onBtnCancel">
                        {{ $t("scenarioSelector-cancel-label", { $: "Cancel" }) }}
                    </v-btn>
                </div>
                <div class="middle-col"></div>
                <div class="right-col pr-0">
                    <v-btn variant="elevated" color="primary" class="w-100" @click="onBtnConfirm">
                        {{ $t("scenarioSelector-confirm-label", { $: "Confirm" }) }}
                    </v-btn>
                </div>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>


<style lang="scss">

</style>