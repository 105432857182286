<template>
  <!-- eslint-disable vue/no-mutating-props -->
  <v-row no-gutters>
    <v-col cols="12" class="d-flex flex-column">
      <div>
        <v-row>
          <v-col cols="2">
            <v-tooltip v-if="createBtn" location="bottom" open-delay="300">
              <template #activator="{ props }">
                <v-btn size="small" density="compact" icon="mdi-plus" v-bind="props" :disabled="createBtnDisabled" @click="onCreate" />
              </template>
              <span>{{ $t("grid-view-add-tooltip", { $: "Add" }) }}</span>
            </v-tooltip>
            <v-tooltip v-if="deleteBtn" location="bottom" open-delay="300">
              <template #activator="{ props }">
                <v-btn size="small" density="compact" icon="mdi-delete" v-bind="props" :disabled="deleteDisabled || deleteBtnDisabled" @click="onDelete" />
              </template>
              <span>{{ $t("grid-view-delete-tooltip", { $: "Delete" }) }}</span>
            </v-tooltip>
            <v-tooltip v-if="editBtn" location="bottom" open-delay="300">
              <template #activator="{ props }">
                <v-btn size="small" density="compact" icon="mdi-pencil" v-bind="props" :disabled="editDisabled || editBtnDisabled" @click="onEdit" />
              </template>
              <span>{{ $t("grid-view-edit-tooltip", { $: "Edit" }) }}</span>
            </v-tooltip>
            <v-tooltip v-if="refreshBtn" location="bottom" open-delay="300">
              <template #activator="{ props }">
                <v-btn size="small" density="compact" icon="mdi-refresh" v-bind="props" :disabled="refreshBtnDisabled" @click="onRefresh" />
              </template>
              <span>{{ $t("grid-view-refresh-tooltip", { $: "Refresh" }) }}</span>
            </v-tooltip>
            <slot name="custom-buttons" />
          </v-col>
          <v-col cols="9">
            <slot name="filter" />
          </v-col>
          <v-col cols="1" class="text-right">
            <actions-button :model-value="actions" />
          </v-col>
        </v-row>
      </div>
      <div class="flex-grow-1" :class="isDark ? 'ag-theme-alpine-dark' : 'ag-theme-alpine'">
        <ag-grid-vue ref="agGrid" :dom-layout="noAutoHeight ? null : 'autoHeight'" :style="agGridStyle" v-bind="$attrs" />
      </div>
    </v-col>
  </v-row>
</template>

<script>
import { AgGridVue } from "ag-grid-vue3";
import ActionsButton from "@/components/Layout/ActionsButton.vue";

/**
 * ag-grid wrapper component
 * @deprecated Deprecated. Use Vue 3 GridWrapper component instead.
 */
export default {
  name: "AgGridWrapper",
  components: { ActionsButton, AgGridVue },
  props: {
    actions: {
      type: Array,
      default: () => []
    },
    createBtn: {
      type: Boolean,
      default: false
    },
    createBtnDisabled: {
      type: Boolean,
      default: false
    },
    deleteBtn: {
      type: Boolean,
      default: false
    },
    deleteBtnDisabled: {
      type: Boolean,
      default: false
    },
    editBtn: {
      type: Boolean,
      default: false
    },
    editBtnDisabled: {
      type: Boolean,
      default: false
    },
    refreshBtn: {
      type: Boolean,
      default: false
    },
    refreshBtnDisabled: {
      type: Boolean,
      default: false
    },
    noAutoHeight: {
      type: Boolean,
      default: false
    },
    agGridStyle: {
      type: Object,
      default: () => ({ width: "100%" })
    }
  },
  emits: ["create-action", "delete-action", "edit-action", "refresh-action"],
  data: () => ({
    editDisabled: true,
    deleteDisabled: true,
    gridOptions: null,
    gridApi: null,
    isDark: false
  }),
  watch: {
    // eslint-disable-next-line vue/no-arrow-functions-in-watch
    "$vuetify.theme.current": {
      handler(val) {
        this.isDark = val === "dark";
      },
      immediate: true
    }
  },
  mounted() {
    this.gridOptions = this.$refs.agGrid.gridOptions;
    this.gridApi = this.$refs.agGrid.gridOptions.api;
    this.gridApi.addEventListener("rowSelected", this.selectionChanged);
    this.gridApi.addEventListener("rowDataChanged", this.rowDataChanged);
  },
  beforeUnmount() {
    this.gridApi.removeEventListener("rowSelected", this.selectionChanged);
    this.gridApi.removeEventListener("rowDataChanged", this.rowDataChanged);
  },
  methods: {
    onCreate() {
      this.gridApi.getSelectedRows();
      this.$emit("create-action");
    },
    onEdit() {
      this.$emit("edit-action");
    },
    onDelete() {
      this.$emit("delete-action");
    },
    onRefresh() {
      this.$emit("refresh-action");
    },
    selectionChanged() {
      if (this.gridApi) {
        this.editDisabled = this.gridApi.getSelectedRows().length !== 1;
        this.deleteDisabled = this.gridApi.getSelectedRows().length !== 1;
      }
    },
    rowDataChanged() {
      this.selectionChanged();
    }
  }
};
</script>

<style lang="scss" scoped></style>
