import { ApiClient } from "@/services/api/common.api";
import { GetServicesPaginatedQuery, IAgGridPaginationResponse, ServiceDto } from "@masta/generated-model";

export default {
  async getPaginated(request: GetServicesPaginatedQuery) {
    return ApiClient.post<IAgGridPaginationResponse<ServiceDto>>(`/services`, request);
  },
  async getSingle(id: string, scenarioId: string) {
    return ApiClient.get<ServiceDto>(`/services/${id}?scenarioId=${scenarioId}`);
  },
  async getServices({ scenarioId }: { scenarioId: string }) {
    return ApiClient.get<any>(`/services?scenarioId=${scenarioId}`);
  },
  async create(request: Partial<ServiceDto>) {
    return ApiClient.put<string>(`/services`, request);
  },
  async update(request: Partial<ServiceDto>) {
    return ApiClient.put<string>(`/services/${request.id}`, request);
  },
  async remove(id: string, scenarioId?: string) {
    return ApiClient.delete(`/services/${id}?scenarioId=${scenarioId}`);
  }
};
