import { ServerSideDataSource } from "@/components/Grid/ServerSideDataSource";
import { BusinessActionDto, GetUserNoticesPaginatedQuery, IAgGridPaginationResponse, UserNoticeDto } from "@masta/generated-model";
import ApiService from "@/services/api";
import { IServerSideGetRowsParams } from "ag-grid-enterprise";

export class UserNoticesServerSideDataSource extends ServerSideDataSource<UserNoticeDto, UserNoticeDto, UserNoticeDto, UserNoticeDto, string> {
  constructor(id: string) {
    super(id);
  }

  async getAll<T>(params: IServerSideGetRowsParams): Promise<IAgGridPaginationResponse<UserNoticeDto>> {
    const request = {
      ...this.constructServerRequest(params)
    } as GetUserNoticesPaginatedQuery;
    const response = await ApiService.userNotices.getPaginated(request);

    return response.data as IAgGridPaginationResponse<UserNoticeDto>;
  }

  create(entity: UserNoticeDto): Promise<string> {
    throw new Error("Method not implemented.");
  }

  getSingle(id: string): Promise<UserNoticeDto | undefined> {
    throw new Error("Method not implemented.");
  }

  remove(entity: UserNoticeDto): Promise<void> {
    throw new Error("Method not implemented.");
  }

  protected transformForCreate(listEntity: UserNoticeDto): BusinessActionDto {
    return listEntity;
  }

  protected transformForUpdate(listEntity: UserNoticeDto): BusinessActionDto {
    return listEntity;
  }

  update(entity: UserNoticeDto): Promise<string> {
    throw new Error("Method not implemented.");
  }
}
