import { ILayerRenderer } from "./ILayerRenderer";
import { inject, injectable } from "inversify";
import type { IRowChartScaleProvider } from "../../../Core";
import { createPaddingInsets, GanttSettings, IocSymbols, IRowChartDataSet, Lifecycle, PaddingInsets, Row, RowChartRepository, SettingKey, TimelineManager } from "../../../Core";

@injectable()
export class ChartLayerRenderer extends Lifecycle implements ILayerRenderer {
  public static Identifier = "ChartLayerRenderer";
  private _blurOffset = 0.5;
  private _negativeBoundaryValue: number;
  private _paddingInsets: PaddingInsets;
  private _rowDatasets: IRowChartDataSet[] = [];
  private _show: boolean;
  private _defaultLineWidth: number;

  constructor(
    @inject(Row<any, any, any>) private _row: Row<any, any, any>,
    @inject(TimelineManager) private _timelineManager: TimelineManager,
    @inject(GanttSettings) private _ganttSettings: GanttSettings,
    @inject(RowChartRepository) private _rowChartRepository: RowChartRepository,
    @inject(IocSymbols.RowChartScaleProvider) private _rowChartScaleProvider: IRowChartScaleProvider
  ) {
    super();
  }

  public async afterInitialize(): Promise<void> {
    await super.afterInitialize();

    this.subscribe(this._ganttSettings.getSetting$<boolean>(SettingKey.CHART_HEADER_SHOW).subscribe((s) => {
      this._show = s ?? false;
    }));
    this.subscribe(this._ganttSettings.getSetting$<number>(SettingKey.CHART_LINE_WIDTH).subscribe((s) => {
      this._defaultLineWidth = s ?? 2;
    }));
    this.subscribe(this._ganttSettings.getSetting$<number>(SettingKey.CHART_NEGATIVE_BOUNDARY_VALUE).subscribe((s) => {
      this._negativeBoundaryValue = s ?? 0;
    }));
    this.subscribe(this._ganttSettings.getSetting$<PaddingInsets>(SettingKey.ROW_PADDING).subscribe((s) => {
      if (s && s) {
        this._paddingInsets = createPaddingInsets(s.top, s.right, s.bottom, s.left);
      }
    }));

    this.subscribe(this._rowChartRepository.dataSets$.subscribe(ds => {
      this._rowDatasets = ds.filter((x) => x.resourceId === this._row.id);
    }));
  }

  render(canvas: OffscreenCanvas | HTMLCanvasElement, context: OffscreenCanvasRenderingContext2D | CanvasRenderingContext2D, params: { scaleValues: number[] }) {
    if (!this._show) return;

    const width = canvas instanceof HTMLCanvasElement ? canvas.clientWidth : canvas.width;
    const height = canvas instanceof HTMLCanvasElement ? canvas.clientHeight : canvas.height;

    context.clearRect(0, 0, width, height);

    const scaleValues = params.scaleValues;
    if (!scaleValues || scaleValues.length === 0) return;

    context.save();

    // const { startTime, endTime } = this._timelineManager;
    // const starTimeMillis = startTime.toEpochMilli();
    // const endTimeMillis = endTime.toEpochMilli();

    const paddingInsets = this._paddingInsets;
    const rowHeaderWidth = 0;
    const canvasWidth = width;
    const canvasHeight = height;
    const paddingLeft = rowHeaderWidth + paddingInsets.left;
    const max = scaleValues[0];
    const min = scaleValues[scaleValues.length - 1];
    const p1 = canvasHeight - paddingInsets.bottom;
    const p2 = paddingInsets.top;
    const zeroPos = this.getYPos(0, min, max, p1, p2);

    this.drawNegativeBoundaryLine(context, rowHeaderWidth, canvasWidth, this.getYPos(this._negativeBoundaryValue, min, max, p1, p2));

    for (const dataSet of this._rowDatasets.filter((x) => x.visible)) {
      context.lineWidth = dataSet.lineWidth ?? this._defaultLineWidth;

      const data = dataSet.data
        .map((d) => {
          const xPos = Math.round(this._timelineManager.calculateLocationForTimeMillis(d.x));
          return {
            ...d,
            xPos,
            xPosPaddedLeft: xPos + paddingLeft
          };
        });

      const segments: any[] = [];
      data.forEach((x, idx) => {
        if (idx + 1 < data.length) {
          segments.push({ left: x, right: data[idx + 1] });
        } else {
          segments.push({ left: x, right: x });
        }
      });

      const positiveLinesToDraw = [];
      const negativeLinesToDraw = [];

      for (let i = 0; i < segments.length; i++) {
        const { left, right } = segments[i];

        if (left.xPosPaddedLeft < paddingLeft) {
          if (right.xPosPaddedLeft < paddingLeft && i + 1 < segments.length) {
            // do not draw chart from LEFT outside of canvas
            // draw only last segment
            continue;
          }
        }
        if (right.xPos > canvasWidth) {
          if (left.xPos > canvasWidth && i !== 0) {
            // do not draw chart from RIGHT outside of canvas
            // draw only first segment
            continue;
          }
        }
        const lxPos = left.xPos < paddingLeft ? paddingLeft : left.xPos;
        const lyPos = this.getYPos(left.y, min, max, p1, p2);
        const rxPos = right.xPos < paddingLeft ? paddingLeft : right.xPos;
        const ryPos = this.getYPos(right.y, min, max, p1, p2);

        // Vertical Line
        if (left.xPos >= paddingLeft && left.xPos <= canvasWidth) {
          if (left.y < this._negativeBoundaryValue) {
            negativeLinesToDraw.push({
              moveTo: { x: lxPos, y: lyPos },
              lineTo: { x: lxPos, y: zeroPos }
            });

            positiveLinesToDraw.push({
              moveTo: { x: lxPos, y: zeroPos },
              lineTo: { x: lxPos, y: ryPos }
            });
          } else if (right.y < 0) {
            positiveLinesToDraw.push({
              moveTo: { x: lxPos, y: lyPos },
              lineTo: { x: lxPos, y: zeroPos }
            });
            negativeLinesToDraw.push({
              moveTo: { x: lxPos, y: zeroPos },
              lineTo: { x: lxPos, y: ryPos }
            });
          } else {
            positiveLinesToDraw.push({
              moveTo: { x: lxPos, y: lyPos },
              lineTo: { x: lxPos, y: ryPos }
            });
          }
        }

        // Horizontal line
        if (i === 0) {
          // draw line to canvas start
          (left.y >= this._negativeBoundaryValue ? positiveLinesToDraw : negativeLinesToDraw).push({
            moveTo: { x: paddingLeft, y: lyPos },
            lineTo: { x: lxPos, y: lyPos }
          });
          // if first segment is also last segment draw line to canvas end
          if (i + 1 === segments.length) {
            (right.y >= this._negativeBoundaryValue ? positiveLinesToDraw : negativeLinesToDraw).push({
              moveTo: { x: canvasWidth, y: ryPos },
              lineTo: { x: lxPos, y: ryPos }
            });
          } else {
            // draw line to next segment
            (right.y >= this._negativeBoundaryValue ? positiveLinesToDraw : negativeLinesToDraw).push({
              moveTo: { x: rxPos, y: ryPos },
              lineTo: { x: lxPos, y: ryPos }
            });
          }
        } else if (i + 1 === segments.length) {
          // draw line to canvas end
          (right.y >= this._negativeBoundaryValue ? positiveLinesToDraw : negativeLinesToDraw).push({
            moveTo: { x: lxPos, y: ryPos },
            lineTo: { x: canvasWidth, y: ryPos }
          });
        } else {
          // draw line to next segment
          (right.y >= this._negativeBoundaryValue ? positiveLinesToDraw : negativeLinesToDraw).push({
            moveTo: { x: rxPos, y: ryPos },
            lineTo: { x: lxPos, y: ryPos }
          });
        }
      }

      if (negativeLinesToDraw.length > 0) {
        context.strokeStyle = dataSet.negativeColor ?? "red";
        context.beginPath();
        for (const n of negativeLinesToDraw) {
          context.moveTo(n.moveTo.x, n.moveTo.y);
          context.lineTo(n.lineTo.x, n.lineTo.y);
        }
        context.closePath();
        context.stroke();
      }

      if (positiveLinesToDraw.length > 0) {
        context.strokeStyle = dataSet.positiveColor ?? "green";
        context.beginPath();
        for (const n of positiveLinesToDraw) {
          context.moveTo(n.moveTo.x, n.moveTo.y);
          context.lineTo(n.lineTo.x, n.lineTo.y);
        }
        context.closePath();
        context.stroke();
      }
    }
    context.restore();
  }


  private getYPos(v: number, min: number, max: number, p1: number, p2: number) {
    return Math.round(((v - min) / (max - min)) * (p2 - p1) + p1) + this._blurOffset;
  }

  private drawNegativeBoundaryLine(context: OffscreenCanvasRenderingContext2D | CanvasRenderingContext2D, rowHeaderWidth: number, canvasWidth: number, negativeBoundaryValueYPosition: number) {
    const prevLineWidth = context.lineWidth;
    const prevStrokeStyle = context.strokeStyle;
    const prevLineDash = context.getLineDash();

    context.lineWidth = 0.2;
    context.strokeStyle = "rgba(0,0,0,0.9)";
    context.beginPath();
    context.setLineDash([3, 3]);
    context.moveTo(rowHeaderWidth, negativeBoundaryValueYPosition);
    context.lineTo(canvasWidth, negativeBoundaryValueYPosition);
    context.stroke();
    context.closePath();

    context.lineWidth = prevLineWidth;
    context.strokeStyle = prevStrokeStyle;
    context.setLineDash(prevLineDash);
  }
}
