export enum SettingKey {
  START_TIME = "gantt.startTime",
  TIMELINE_UNIT = "gantt.timelineUnit",
  TIMELINE_UNIT_WIDTH = "gantt.timelineUnitWidth",
  TIMELINE_ZOOM_FACTOR = "gantt.timelineZoomFactor",
  TIMELINE_STYLE = "gantt.timelineStyle",
  DATA_LOADER_DELAY = "gantt.dataLoader.delay",
  ROW_DEFAULT_HEIGHT = "gantt.row.defaultHeight",
  ROW_SORTING_FUNCTION = "gantt.row.sortingFunction",
  ROW_PADDING = "gantt.row.padding",
  ROW_FIXED_SCALE = "gantt.row.scale.fixed",
  ROW_FIXED_SCALE_ACTIVITY_DISPLAY_RANGE = "gantt.row.scale.fixed.activityDisplayRange",
  ROW_EXPAND_BY_DEFAULT = "gantt.row.expandByDefault",
  LINE_MANAGER = "gantt.lineManager",
  ROW_CHART_SCALE_PROVIDER = "gantt.chart.row.scaleProvider",
  CHART_SHOW = "gantt.chart.show",
  CHART_NEGATIVE_BOUNDARY_VALUE = "gantt.chart.negativeBoundaryValue",
  CHART_HEADER_SHOW = "gantt.chart.header.show",
  CHART_HEADER_WIDTH = "gantt.chart.header.width",
  CHART_POSITIVE_COLOR = "gantt.chart.positiveColor",
  CHART_NEGATIVE_COLOR = "gantt.chart.negativeColor",
  CHART_LINE_WIDTH = "gantt.chart.lineWidth",
  TOOLTIP_DISABLED = "gantt.tooltip.disabled",
  TOOLTIP_OPEN_DELAY = "gantt.tooltip.openDelay",
  DEBUG_SHOW_INFO = "gantt.debug.showInfo",
  HELP_SHOW_LAYER = "gantt.help.showLayer",
  HELP_SHOW_INFO = "gantt.help.showInfo",
  HELP_SHOW_NAV_KEYBOARD = "gantt.help.showNavKeyboard",
  HELP_I18N_LABELS = "gantt.help.i18nLabels"
}
