import { KpiResultDto } from "@masta/generated-model";
import { CellClassParams } from "ag-grid-community";

/**
 * This function is used to set the background color of the cell based on the value of the cell.
 * - If the value is negative, the background color will be red with an opacity based on the value.
 * - If the value is positive, the background color will be green with an opacity based on the value.
 * - If the value is 0, the background color will be the default color of grid cell.
 * 
 * Opacity is calculated as the value divided by the min or max value of the range of values.
 * 
 * @param params 
 * @param minValue - min value of the range of values
 * @param maxValue - max value of the range of values
 * @returns 
 */
export function normalizedValueCellStyle(params: CellClassParams<KpiResultDto, number>, minValue: number, maxValue: number) {
  const { data, node, value } = params;

  if (value === null || value === undefined || value === 0) return;

  if (value < 0) {
    const opacity = value / minValue;
    return {
      "background-color": "rgba(255, 99, 71, " + opacity + ")"
    };
  }

  if (value > 0) {
    const opacity = value / maxValue;
    return {
      "background-color": "rgba(50, 205, 50, " + opacity + ")"
    };
  }

  return null;
}
