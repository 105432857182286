import type { IRowContainer } from "../../../Core";
import { ActivityBounds, ActivityRef, ActivityRenderer, IActivityRenderingRequest, ICalendarActivity, IocSymbols, TimelineManager } from "../../../Core";
import { inject, injectable } from "inversify";

@injectable()
export class WeekendCalendarActivityRenderer extends ActivityRenderer<ICalendarActivity> {
  protected _bounds: WeakMap<ActivityRef<any>, ActivityBounds<any>> = new WeakMap();

  constructor(@inject(TimelineManager) timelineManager: TimelineManager, @inject(IocSymbols.RowContainer) rowContainer: IRowContainer) {
    super(timelineManager, rowContainer, WeekendCalendarActivityRenderer.name);
    this.fill = "rgba(133,133,133,0.1)";
  }

  protected getActivityBounds(activityRef: ActivityRef<ICalendarActivity>): ActivityBounds<ICalendarActivity> | undefined {
    return this._bounds.get(activityRef);
  }

  protected addActivityBounds(activityRef: ActivityRef<ICalendarActivity>, bounds: ActivityBounds<ICalendarActivity>): void {
    this._bounds.set(activityRef, bounds);
  }

  drawActivity(request: IActivityRenderingRequest<ICalendarActivity>): void {
    const { activityBounds, activityRef, position, canvas, context, x, y, w, h, offsetTop, selected, hover, highlighted, pressed } = request;
    this.drawBackground(activityRef, position, context, x, y, w, h, offsetTop, selected, hover, highlighted, pressed);
  }
}
