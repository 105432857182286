import { RouteLocationNormalized, RouteLocationRaw, Router } from "vue-router";
import { $authService } from "@/services/AuthService";
import { AppMenuController } from "@/services/api-service";
import * as guards from "@/router/guards";
import { RouteNames } from "@/router/route-names";
import { app } from "@/main";
import { setLocaleFromLocalStorageAuthorized, setLocaleFromLocalStoragePublic } from "@/i18n";

export class BeforeEachGuard {
  private readonly _modules: Record<string, () => Promise<any>>;
  private _routesSynchronized = false;
  private _availableGuards = Object.keys(guards);

  constructor(private _router: Router) {
    // @see https://vitejs.dev/guide/features.html#glob-import
    this._modules = import.meta.glob("./../../views/**/*.vue", { import: "default", eager: true });
  }

  async beforeEach(to: RouteLocationNormalized, from: RouteLocationNormalized): Promise<Error | RouteLocationRaw | boolean> {
    if (to && to.meta && !!to.meta.tenantId && (to.meta.tenantId as any).length > 0) {
      if ($authService.authenticated) {
        console.log("%cBeforeEachGuard", "color: black; background-color: orange; padding: .3em;", "keycloak: authenticated, setLocaleFromLocalStorageAuthorized");
        await setLocaleFromLocalStorageAuthorized(
          $authService.tenantId!,
          from.name === RouteNames.HomeApp || from.name === RouteNames.Home || from.name === RouteNames.CreateTenant
        );

        return await this.synchronizeRoutes(to, from);
      }
      const result = await $authService.authenticate(to.meta.tenantId as string, to.fullPath);
      if (!result) {
        console.log("%cBeforeEachGuard", "color: black; background-color: orange; padding: .3em;", "keycloak: not initialized, redirect to login");
        return { name: RouteNames.HomeApp };
      } else if ($authService.authenticated) {
        console.log("%cBeforeEachGuard", "color: black; background-color: orange; padding: .3em;", "keycloak: initialized, authenticated, setLocaleFromLocalStorageAuthorized");
        await setLocaleFromLocalStorageAuthorized(
          $authService.tenantId!,
          from.name === RouteNames.HomeApp || from.name === RouteNames.Home || from.name === RouteNames.CreateTenant
        );
      } else {
        console.log("%cBeforeEachGuard", "color: black; background-color: orange; padding: .3em;", "keycloak: initialized, not authenticated");
      }
      return await this.synchronizeRoutes(to, from);
    }
    if (to && !to.meta.tenantId) {
      await setLocaleFromLocalStoragePublic(from.name === RouteNames.TenantApp || from.name === RouteNames.NotFound);
    }
    return true;
  }

  async synchronizeRoutes(to: RouteLocationNormalized, _: RouteLocationNormalized): Promise<Error | RouteLocationRaw | boolean> {
    if (this._routesSynchronized) return true;

    const api = new AppMenuController();
    try {
      const { data } = await api.getAppMenu();
      if (app.config.globalProperties.$megaMenu) {
        app.config.globalProperties.$megaMenu.setDefaultMainMenuStructure(data.menuStructure as any);
      }
      const parentRoute = this._router.getRoutes().find((x) => x.name === RouteNames.TenantApp)!;

      for (const mc of data.menuRouting ?? []) {
        const moduleKey = Object.keys(this._modules).find((x) => x.endsWith(`${mc.component}.vue`));
        if (!moduleKey) {
          console.warn(`Module for route '${mc.path}' not found!`);
          continue;
        }
        const routeModule = this._modules[moduleKey];
        const guardName = this._availableGuards.find((x) => x === mc.beforeEnter);
        const beforeEnter = guardName && guardName in guards ? (guards as any)[guardName] : undefined;
        const registerDetailPath = (mc.meta && mc.meta.register_detail_path) ? Boolean(mc.meta.register_detail_path) : false;
        const routePath = `${mc.path}`;

        const routeProps = (mc as any).params ?? {};
        routeProps.componentRoutePath = routePath;

        if (mc.name) {
          this._router.addRoute(parentRoute.name!, {
            path: routePath,
            name: mc.name,
            props: routeProps,
            beforeEnter,
            component: routeModule,
            meta: {
              ...mc.meta
            },
            ...(registerDetailPath ? { children: [{ path: "detail", component: routeModule }] } : { children: undefined })
          });
        } else {
          console.warn(`Menu route '${mc.path}' has no name!`);
        }
      }
      if (to.name === RouteNames.NotFound) {
        // try to redirect to requested path because maybe it was added
        return { path: to.path, query: to.query } as RouteLocationRaw;
      }
    } catch (e) {
      console.error("Cannot register menu routing", e);
    } finally {
      this._routesSynchronized = true;
    }

    return true;
  }
}
