import ApiService from "@/services/api";
import { UserDto } from "@masta/generated-model";
import { defineStore } from "pinia";
import { useAuthStore } from "@/store/AuthStore";
import { useErrorsStore } from "@/store/ErrorsStore";

interface State {
  users: UserDto[];
}

export const useUsersStore = defineStore("users", {
  state: (): State => ({
    users: []
  }),
  actions: {
    async fetch() {
      try {
        const response = await ApiService.users.getUsers();
        this.users = response.data.users;
      } catch (e) {
        const errorsStore = useErrorsStore();
        errorsStore.handleError(e);
        throw e;
      }
    },
    async invite(email: string) {
      try {
        const authStore = useAuthStore();
        if (!authStore.getClientId) return;

        await ApiService.users.inviteUser({
          email,
          clientId: authStore.getClientId
        });
      } catch (e) {
        const errorsStore = useErrorsStore();
        errorsStore.handleError(e);
        throw e;
      }
    }
  }
});

