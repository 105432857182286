<script lang="ts" setup>
import GridWrapper from "@/components/Grid/GridWrapper.vue";
import { ref } from "vue";
import { GridWrapperComponent } from "@/components/Grid/GridWrapperComponent";
import { useUsersStore } from "@/store/UsersStore";
import { storeToRefs } from "pinia";
import { $t } from "@/i18n";

const store = useUsersStore();
const emits = defineEmits(["create"]);
const { users } = storeToRefs(store);

const gridWrapperRef = ref<GridWrapperComponent>();

async function onFetchData() {
  await store.fetch();
}

async function onRefreshAction() {
  await onFetchData();
}

async function onCreateAction() {
  emits("create");
}

const defaultColumnDef = ref({
  floatingFilter: true,
  filterParams: {
    applyMiniFilterWhileTyping: true
  }
});

function onPrepareColumns(columnDefs: any) {
  columnDefs.value = [
    {
      field: "name",
      type: ["textInputTypeColumn", "textFloatingFilterColumnType"],
      headerValueGetter: (_: any) => $t("user-list-name-label", { $: "Name" }),
      resizable: true,
      editable: false,
      sortable: true,
      filter: "agTextColumnFilter",
      floatingFilterComponentParams: {
        placeholder: $t("user-list-name-label", { $: "Name" })
      }
    },
    {
      field: "email",
      type: ["textInputTypeColumn", "textFloatingFilterColumnType"],
      headerValueGetter: (_: any) => $t("user-list-email-label", { $: "Email" }),
      resizable: true,
      editable: false,
      sortable: true,
      filter: "agTextColumnFilter",
      floatingFilterComponentParams: {
        placeholder: $t("user-list-email-label", { $: "Email" })
      }
    }
  ];
}
</script>

<template>
  <grid-wrapper
    ref="gridWrapperRef"
    identifier="users"
    refresh-btn
    create-btn
    :default-col-def="defaultColumnDef"
    row-selection="multiple"
    :grid-data="users"
    custom-create-action
    @fetch-data="onFetchData"
    @refresh-action="onRefreshAction"
    @create-action="onCreateAction"
    @prepare-columns="onPrepareColumns"
  />
</template>

<style lang="scss" scoped></style>
