﻿import { ActivityBase, ActivityDeserializer, GanttException, GanttSettings, IActivityFactory, Lifecycle, MutableActivityBase } from "@masta/gantt2/core";
import {
  AvailabilityRuleResourceCapacityActivity,
  CalendarResourceCapacityActivity,
  DemandExecutionResourceCapacityActivity,
  DemandSchedulingResourceCapacityActivity,
  EnhancedGanttNoteDto,
  EnhancedGanttResourceCapacityDto,
  InterruptionExecutionResourceCapacityActivity,
  NoteActivity,
  WaitingSchedulingResourceCapacityActivity
} from "src/components/Gantt/Common/Model";
import { inject, injectable, optional } from "inversify";
import { AppIocSymbols } from "@/components/Gantt/ResourcesGantt/AppIocSymbols";
import type { DateFormatter, DurationFormatter } from "@masta/shared";

const demandSchedulingDeserializer: ActivityDeserializer<EnhancedGanttResourceCapacityDto, DemandSchedulingResourceCapacityActivity> = (data: EnhancedGanttResourceCapacityDto) => {
  return new DemandSchedulingResourceCapacityActivity(data);
};
const demandExecutionDeserializer: ActivityDeserializer<EnhancedGanttResourceCapacityDto, DemandExecutionResourceCapacityActivity> = (data: EnhancedGanttResourceCapacityDto) => {
  return new DemandExecutionResourceCapacityActivity(data);
};
const waitingSchedulingResourceCapacityDeserializer: ActivityDeserializer<EnhancedGanttResourceCapacityDto, WaitingSchedulingResourceCapacityActivity> = (
  data: EnhancedGanttResourceCapacityDto
) => {
  return new WaitingSchedulingResourceCapacityActivity(data);
};
const interruptionExecutionResourceCapacityDeserializer: ActivityDeserializer<EnhancedGanttResourceCapacityDto, InterruptionExecutionResourceCapacityActivity> = (
  data: EnhancedGanttResourceCapacityDto
) => {
  return new InterruptionExecutionResourceCapacityActivity(data);
};
const calendarResourceCapacityDeserializer: ActivityDeserializer<EnhancedGanttResourceCapacityDto, CalendarResourceCapacityActivity> = (data: EnhancedGanttResourceCapacityDto) => {
  return new CalendarResourceCapacityActivity(data);
};
const availabilityResourceCapacityDeserializer: ActivityDeserializer<EnhancedGanttResourceCapacityDto, AvailabilityRuleResourceCapacityActivity> = (
  data: EnhancedGanttResourceCapacityDto
) => {
  return new AvailabilityRuleResourceCapacityActivity(data);
};

const resourceNoteDeserializer: ActivityDeserializer<EnhancedGanttNoteDto, NoteActivity> = (data: EnhancedGanttNoteDto) => {
  return new NoteActivity(data);
};

@injectable()
export class ResourceGanttActivityFactory extends Lifecycle implements IActivityFactory<MutableActivityBase<any>> {
  constructor(
    @inject(AppIocSymbols.$durationFormatter) @optional() protected readonly $durationFormatter?: DurationFormatter,
    @inject(AppIocSymbols.$dateTimeFormatter) @optional() protected readonly $dateTimeFormatter?: DateFormatter
  ) {
    super();
  }

  createActivity(data: EnhancedGanttResourceCapacityDto): MutableActivityBase<any> {
    data = this.enhanceDto(data);
    switch (data.type) {
      case "DemandSchedulingResourceCapacityActivity": {
        return ActivityBase.fromJSON(data, DemandSchedulingResourceCapacityActivity, demandSchedulingDeserializer);
      }
      case "DemandExecutionResourceCapacityActivity": {
        return ActivityBase.fromJSON(data, DemandExecutionResourceCapacityActivity, demandExecutionDeserializer);
      }
      case "WaitingSchedulingResourceCapacityActivity": {
        return ActivityBase.fromJSON(data, WaitingSchedulingResourceCapacityActivity, waitingSchedulingResourceCapacityDeserializer);
      }
      case "InterruptionExecutionResourceCapacityActivity": {
        return ActivityBase.fromJSON(data, InterruptionExecutionResourceCapacityActivity, interruptionExecutionResourceCapacityDeserializer);
      }
      case "CalendarResourceCapacityActivity": {
        return ActivityBase.fromJSON(data, CalendarResourceCapacityActivity, calendarResourceCapacityDeserializer);
      }
      case "AvailabilityRuleResourceCapacityActivity": {
        return ActivityBase.fromJSON(data, AvailabilityRuleResourceCapacityActivity, availabilityResourceCapacityDeserializer);
      }
      case "NoteActivity": {
        return ActivityBase.fromJSON(data, NoteActivity, resourceNoteDeserializer);
      }
      default:
        throw new GanttException(`Unknown activity type: ${data.type}`);
    }
  }

  private enhanceDto(data: EnhancedGanttResourceCapacityDto): EnhancedGanttResourceCapacityDto {
    if (this.$durationFormatter) {
      if (!data.periodDurationFormatted) data.periodDurationFormatted = this.$durationFormatter(data.periodStart, data.periodEnd) as string;
    }
    if (this.$dateTimeFormatter) {
      if (!data.periodStartDateFormatted) data.periodStartDateFormatted = this.$dateTimeFormatter(data.periodStart);
      if (!data.periodEndDateFormatted) data.periodEndDateFormatted = this.$dateTimeFormatter(data.periodEnd);
      if (!data.periodStartDateTimeFormatted) data.periodStartDateTimeFormatted = this.$dateTimeFormatter(data.periodStart);
      if (!data.periodEndDateTimeFormatted) data.periodEndDateTimeFormatted = this.$dateTimeFormatter(data.periodEnd);
    }
    return data;
  }
}
