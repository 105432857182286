import { ApiClient } from "@/services/api/common.api";
import { CalculateCostsForOrderCommand, CalculateQuotationForOrderCommand, LaunchQuotationAutomationCommand, QuotationDto } from "@masta/generated-model";

export default {
  async calculate(request: CalculateQuotationForOrderCommand) {
    return ApiClient.post(`/quotations/calculate`, request);
  },
  async calculateCosts(request: CalculateCostsForOrderCommand) {
    return ApiClient.post(`/quotations/calculateCosts`, request);
  },
  async createQuotationDocumentsInAutomatedWay(command: LaunchQuotationAutomationCommand) {
    return ApiClient.post(`/quotations/quotationAutomation`, command);
  }
};
