import { inject, injectable } from "inversify";
import { AutoLineManager } from "./AutoLineManager";
import { BehaviorSubject } from "rxjs";
import { GanttSettings, IActivity, Layer, Row, SettingKey } from "../../Model";
import { IocSymbols } from "../../IocSymbols";
import { createPaddingInsets, PaddingInsets } from "../PaddingInsets";
import { TimelineManager } from "../../TimelineManager";

@injectable()
export class FixedScaleLineManager<TRow extends Row<any, any, TActivity>, TActivity extends IActivity> extends AutoLineManager<TRow, TActivity> {
  private _from = 0;
  private _to = 1;
  private _scaleValues: number[] = [];
  private _paddingInsets: PaddingInsets = createPaddingInsets();

  constructor(
    @inject(TimelineManager) timelineManager: TimelineManager,
    @inject(IocSymbols.LayersSymbol) protected readonly _layers$$: BehaviorSubject<Layer[]>,
    @inject(GanttSettings) protected readonly _settings: GanttSettings,
    // @inject(rowChartRepositorySymbol) private readonly _rowChartRepository: RowChartRepository,
    // @inject(rowChartScaleProviderSymbol) @optional() protected readonly _rowChartScaleProvider: IRowChartScaleProvider,
    @inject(Row<any, any, any>) row: TRow
  ) {
    super(timelineManager, _layers$$, row);
    const displayRangeSetting = this._settings.getSetting(SettingKey.ROW_FIXED_SCALE_ACTIVITY_DISPLAY_RANGE);
    if (displayRangeSetting) {
      const { from, to } = displayRangeSetting;
      this._from = from;
      this._to = to;
    }
    const rowPaddingSettting = this._settings.getSetting(SettingKey.ROW_PADDING);
    if (rowPaddingSettting) {
      this._paddingInsets = createPaddingInsets(rowPaddingSettting.top, rowPaddingSettting.right, rowPaddingSettting.bottom, rowPaddingSettting.left);
    }
  }

  getLineHeight(lineIndex: number, rowHeight: number): number {
    if (!this.hasScaleValues) return super.getLineHeight(lineIndex, rowHeight);
    return this.getYPos(this._from) - this.getYPos(this._to);
  }

  getLineLocation(lineIndex: number, rowHeight: number): number {
    if (!this.hasScaleValues) return super.getLineLocation(lineIndex, rowHeight);
    return this.getYPos(this._to);
  }

  getLineIndex(activity: TActivity): number {
    if (!this.hasScaleValues) return super.getLineIndex(activity);
    return 0;
  }

  layout(): void {
    // TODO FIX
    // if (this._rowChartScaleProvider)
    //   this._scaleValues = this._rowChartScaleProvider.scaleValues;
    if (!this.hasScaleValues) return super.layout();
    this.row.lineCount = 1;
  }

  clearCache(): void {
    if (!this.hasScaleValues) return super.clearCache();
  }

  private get hasScaleValues() {
    // TODO FIX
    // return this._rowChartScaleProvider?.scaleValues.length > 0 ?? false;
    return false;
  }

  private getYPos(v: number): number {
    return 0;
    // TODO FIX
    // const scaleValues = this._rowChartScaleProvider.scaleValues;
    // const max = scaleValues[0];
    // const min = scaleValues[scaleValues.length - 1];
    // const p1 = this.row.height - this._paddingInsets.top;
    // const p2 = this._paddingInsets.bottom;
    // return Math.round(((v - min) / (max - min)) * (p2 - p1) + p1);
  }
}
