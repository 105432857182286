import { ITextFilterParams } from "ag-grid-community";

export const intervalTypeColumnFilter = "agTextColumnFilter";

export const intervalTypeColumnFilterParams = (placeholder: string | undefined): ITextFilterParams => {
  return {
    filterOptions: ["equals", "notEqual", "lessThan", "lessThanOrEqual", "greaterThan", "greaterThanOrEqual"],
    defaultOption: "equals",
    filterPlaceholder: placeholder,
    maxNumConditions: 1
  };
};

