import ApiService from "@/services/api";
import { useDocumentObjectUrlsStore } from "@/store/DocumentObjectUrlsStore";
import { ref } from "vue";

export const MD_IMAGE_URL_SEPARATOR = "&";

/**
 * This regex pattern checks for:
 * 1. A UUID in format (8-4-4-4-12 characters separated by hyphens)
 * 2. Followed by '&' separator.
 * 3. Followed by one or more digits (revision number).
 */
export const MD_IMAGE_URL_REGEX = /^[a-f0-9]{8}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{12}&\d+$/i;

/**
 *  This regex pattern checks Markdown-style links in a string
 */
export const MD_LINK_REGEX = /!\[(.*?)\]\((.*?)\)/g;

export const useMarkdownImageLoader = () => {
  const loadingMarkdownImages = ref(false);

  const documentObjectUrlsStore = useDocumentObjectUrlsStore();

  /**
   * Resolves the markdown image link to the actual object URL.
   *
   * @param mdLink - The markdown image link.
   * @returns The object URL of the image.
   */
  function resolveMarkdownImageLink(mdLink: string): string | undefined {
    if (mdLink) {
      if (MD_IMAGE_URL_REGEX.test(mdLink)) {
        const link = parseMarkdownLink(mdLink);
        if (link) {
          const { documentId, revisionNumber } = link;
          return documentObjectUrlsStore.get(documentId, revisionNumber);
        }
      }
    }
  }

  /**
   * Loads the image data for the markdown content.
   *
   * @param markdownValue - The markdown content.
   */
  async function loadMarkdownImageData(markdownValue: string): Promise<void> {
    loadingMarkdownImages.value = true;

    try {
      const images = getImageTags(markdownValue);

      for (let index = 0; index < images.length; index++) {
        const image = images[index];

        if (MD_IMAGE_URL_REGEX.test(image.src)) {
          const link = parseMarkdownLink(image.src);
          if (link) {
            const { documentId, revisionNumber } = link;

            const { data } = await ApiService.documents.getDocumentContentThumbnailStream({
              documentId: documentId,
              revisionNumber: Number(revisionNumber)
            });

            documentObjectUrlsStore.add(documentId, revisionNumber, data);
          }
        }
      }
    } catch (e) {
      console.error("Error loading markdown images", e);
    } finally {
      loadingMarkdownImages.value = false;
    }
  }

  /**
   * Extracts image tags from a markdown string and returns an array of objects containing the alt text and source URL.
   * @param markdownValue - The markdown string to extract image tags from.
   * @returns An array of objects containing the alt text and source URL of the image tags found in the markdown string.
   * Source URL is the documentId and revisionNumber separated by `&` character. e.g. `documentId&revisionNumber`.
   */
  function getImageTags(markdownValue: string): { alt: string; src: string }[] {
    if (!markdownValue) return [];

    let match;
    const images = [];

    while ((match = MD_LINK_REGEX.exec(markdownValue)) !== null) {
      const image = {
        alt: match[1],
        src: match[2]
      };
      images.push(image);
    }

    return images;
  }

  function parseMarkdownLink(markdownLink: string): { documentId: string; revisionNumber: number } | undefined {
    if (markdownLink) {
      const parts = markdownLink.split(MD_IMAGE_URL_SEPARATOR);
      if (parts.length === 2) {
        return {
          documentId: parts[0],
          revisionNumber: Number(parts[1])
        };
      }
    }
    return undefined;
  }

  return {
    loadingMarkdownImages,
    loadMarkdownImageData,
    resolveMarkdownImageLink
  };
};

