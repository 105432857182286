<script setup lang="ts">
import { onMounted, ref } from "vue";
import ApiService from "@/services/api";
import { useRoute } from "vue-router";
import { UserNoticeDto } from "@masta/generated-model";
import MaterialProvidersSelector from "@/components/MaterialProvidersSelector/MaterialProvidersSelector.vue";

const query = useRoute().query;

const userNoticeDto = ref<UserNoticeDto | null>(null);

const getUserNotice = async (id: string) => {
  const response = await ApiService.userNotices.getById(id);
  userNoticeDto.value = response.data;
};

onMounted(() => {
  const userNoticeId = query.userNoticeId as string | undefined;
  getUserNotice(userNoticeId);
});
</script>

<template>
  <v-container class="fill-height">
    <material-providers-selector :json-string="userNoticeDto?.userNoticePayload" :user-notice-id="userNoticeDto?.id" />
  </v-container>
</template>

<style scoped>
.error-label {
  color: red;
}

.success-label {
  color: green;
}
</style>
