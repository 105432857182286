import { ChronoUnit, Instant } from "@js-joda/core";
import { fromEvent } from "rxjs";

import { Position, Resolution, TimeInterval } from "../../Core";

import { Dateline } from "./Dateline";
import { GanttDomElement } from "../GanttDomElement";

export abstract class TimelineDatelineCell<TUnit extends ChronoUnit> extends GanttDomElement<HTMLDivElement> {
  protected _resolution: Resolution<TUnit>;

  protected _startTime: Instant;

  protected _endTime: Instant;

  protected _text: string;

  protected _dateline: Dateline;

  protected _scalePosition: Position;

  private _visible = false;

  private _managed = false;

  constructor() {
    super(TimelineDatelineCell.name, undefined, "dateline-cell");
  }

  async afterInitialize(): Promise<void> {
    this.subscribe(fromEvent(this.element, "mouseenter").subscribe(() => this.onHoverIn()));
    this.subscribe(fromEvent(this.element, "mouseleave").subscribe(() => this.onHoverOut()));
  }

  public update(startTime: Instant, endTime: Instant, resolution: Resolution<TUnit>, dateline: Dateline, position: Position): void {
    this._startTime = startTime;
    this._endTime = endTime;
    this._resolution = resolution;
    this._dateline = dateline;
    this._scalePosition = position;
  }

  get resolution(): Resolution<TUnit> {
    return this._resolution;
  }

  get startTime(): Instant {
    return this._startTime;
  }

  get endTime(): Instant {
    return this._endTime;
  }

  get text(): string {
    return this._text;
  }

  get dateline(): Dateline {
    return this._dateline;
  }

  get visible(): boolean {
    return this._visible;
  }

  set visible(value: boolean) {
    this._visible = value;
  }

  get managed(): boolean {
    return this._managed;
  }

  set managed(value: boolean) {
    this._managed = value;
  }

  get scalePosition(): Position {
    return this._scalePosition;
  }

  private onHoverIn() {
    this.dateline.datelineHoverEvent$$.next(new TimeInterval(this.startTime, this.endTime));
  }

  private onHoverOut() {
    this.dateline.datelineHoverEvent$$.next(null);
  }
}
