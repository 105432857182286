<template>
  <master-detail-layout>
    <template #master>
      <v-card elevation="0" class="flexcard fill-height">
        <v-card-title>
          <breadcrumbs>{{ $t("user-view-users-title", { $: "User management" }) }}</breadcrumbs>
        </v-card-title>
        <v-card-text class="fill-height">
          <InviteUserDialog v-model="showInviteUserDialog" />
          <users-grid @create="create" />
        </v-card-text>
      </v-card>
    </template>
  </master-detail-layout>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import MasterDetailLayout from "@/components/Layout/MasterDetailLayout.vue";
import InviteUserDialog from "@/components/InviteUserDialog.vue";
import UsersGrid from "@/components/Users/UsersGrid.vue";
import Breadcrumbs from "@/components/Layout/Breadcrumbs.vue";

const showInviteUserDialog = ref(false);

const create = () => {
  showInviteUserDialog.value = true;
};
</script>

<style scoped></style>
