import { GanttEvents, TimelineManager } from "../../../../Core";
import { Instant } from "@js-joda/core";
import { GanttCanvas } from "../../../GanttCanvas";

export abstract class LayerCanvas extends GanttCanvas {
  private _visible = true;

  private _enabled = true;

  private _alpha = 1.0;

  private _opacity = 1.0;

  private _offset = 0;

  constructor(protected readonly _timelineManager: TimelineManager, ganttEvents: GanttEvents, identifier: string, className?: string) {
    super(ganttEvents, identifier, `system-layer ${className}`);
  }

  get visible(): boolean {
    return this._visible;
  }

  set visible(value: boolean) {
    this._visible = value;
  }

  get enabled(): boolean {
    return this._enabled;
  }

  set enabled(value: boolean) {
    this._enabled = value;
  }

  get alpha(): number {
    return this._alpha;
  }

  set alpha(value: number) {
    this._alpha = value;
  }

  get opacity(): number {
    return this._opacity;
  }

  set opacity(value: number) {
    this._opacity = value;
  }

  get offset(): number {
    return this._offset;
  }

  set offset(value: number) {
    this._offset = value;
  }

  getLocation(time: Instant): number {
    return this._timelineManager.calculateLocationForTime(time);
  }

  getTimeAt(location: number): Instant {
    return this._timelineManager.calculateTimeForLocation(location);
  }
}
