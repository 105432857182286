import { ref } from "vue";
import ProfileToolPanel from "@/components/Grid/Profile/ProfileToolPanel.vue";
import { IGridProfile } from "@/components/Grid/Profile/UseGridProfile";

export const useSideBar = (identifier?: string, enableColumnsToolPanel?: boolean, enableFiltersToolPanel?: boolean, $gridProfile?: IGridProfile) => {
  const sideBar = ref({
    hiddenByDefault: true,
    toolPanels: []
  });
  if (identifier) {
    sideBar.value.toolPanels!.push({
      id: "profileToolPanel",
      labelDefault: "Profile",
      labelKey: "profileToolPanel",
      iconKey: "columns",
      toolPanel: ProfileToolPanel,
      minWidth: 350,
      width: 350,
      toolPanelParams: {
        $gridProfile,
        identifier
      }
    } as never);

    if (enableColumnsToolPanel) {
      sideBar.value.toolPanels!.push({
        id: "columns",
        labelDefault: "Columns",
        labelKey: "columns",
        iconKey: "columns",
        toolPanel: "agColumnsToolPanel",
        minWidth: 350,
        width: 350
      } as never);
    }

    if (enableFiltersToolPanel) {
      sideBar.value.toolPanels!.push({
        id: "filters",
        labelDefault: "Filters",
        labelKey: "filters",
        iconKey: "filter",
        toolPanel: "agFiltersToolPanel",
        minWidth: 350,
        width: 350
      } as never);
    }
  } else {
    sideBar.value.hiddenByDefault = true;
  }
  return sideBar;
};

