<template>
  <v-container class="fill-height">
    <v-row class="fill-height">
      <v-col class="d-flex justify-center align-center">
        <masta-logo />
      </v-col>
    </v-row>
    <v-row class="fill-height">
      <v-col class="d-flex justify-center align-center">
        <v-label
          v-if="!downloadError && !downloadComplete"
          class="text-center"
        >{{ $t("downloadExport-view-pending-text", { $: "Your export is in progress. Please wait." }) }}</v-label>
        <v-label
          v-else-if="downloadComplete && !downloadError"
          class="success-label text-center"
        >{{ $t("downloadExport-view-confirmation-text", { $: "Your export should be finished by now" }) }}</v-label>
        <v-label
          v-else
          class="error-label text-center"
        >{{ $t("downloadExport-view-error-text", { $: "Download failed. Please try again." }) }}</v-label>
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup lang="ts">
import { onMounted, ref } from 'vue';
import ApiService from "@/services/api";
import { useRoute } from "vue-router";
import MastaLogo from "@/components/MastaLogo.vue";
import { $t } from "../i18n";

const query = useRoute().query;
const downloadError = ref(false);
const downloadComplete = ref(false);

const downloadExport = async (uri: string | undefined) => {
  try {
    if (!uri) {
      downloadError.value = true;
      return;
    }

    const response = await ApiService.exports.getExportContentStream(uri);

    const contentDisposition = response.headers['content-disposition'];
    const filenameMatch = contentDisposition && contentDisposition.match(/filename=(.+?)(;|$)/);
    const filename = filenameMatch ? filenameMatch[1] : 'exportedFile.json';

    const blob = new Blob([response.data], { type: response.headers['content-type'] });

    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = filename;
    link.click();

    URL.revokeObjectURL(link.href);
    downloadComplete.value = true;
  } catch (error) {
    console.error('Error getting export content stream:', error);
    downloadError.value = true;
  }
};

onMounted(() => {
  const uri = query.attachmentUri as string | undefined;
  downloadExport(uri);
});
</script>

<style scoped>
.error-label {
  color: red;
}

.success-label {
  color: green;
}
</style>
