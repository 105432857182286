import { createRouter, createWebHistory } from "vue-router";
import { BeforeEachGuard, noTenantGuard, tenantGuard } from "@/router/guards";
import Home from "@/views/Home.vue";
import NotFound from "@/views/NotFound.vue";
import TenantApp from "@/views/TenantApp.vue";
import TenantNotFound from "@/views/TenantNotFound.vue";
import { RouteNames } from "@/router/route-names";
import { useTenantIdSubdomain } from "@/useTenantIdSubdomain";

const { tenantId, isTenantIdValid } = useTenantIdSubdomain();

const routes = [
  {
    path: "/",
    name: RouteNames.TenantApp,
    beforeEnter: isTenantIdValid ? tenantGuard : undefined,
    meta: { tenantId },
    component: isTenantIdValid ? TenantApp : TenantNotFound,
    props: true,
    children: isTenantIdValid ? [
      {
        path: "",
        name: RouteNames.Home,
        component: Home
      },
      {
        path: ":pathMatch(.*)",
        name: RouteNames.NotFound,
        component: NotFound
      }
    ] : []
  }
];

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes
});
const beforeEachGuard = new BeforeEachGuard(router);
router.beforeEach(beforeEachGuard.beforeEach.bind(beforeEachGuard));

export default router;
