import { inject, injectable } from "inversify";
import { GanttDomElement } from "../GanttDomElement";
import { RowsContainer } from "./RowsContainer";

@injectable()
export class GraphicsContainer extends GanttDomElement<HTMLDivElement> {
  constructor(@inject(RowsContainer) private _rowsContainer: RowsContainer) {
    super(GraphicsContainer.name, undefined, "graphics-container");
  }

  async beforeInitialize(): Promise<void> {
    await this.addChild(this._rowsContainer);
  }
}
