export interface ModelInstanceChange {
  businessId: string;
  value: any;
  tags: string[];
}

export type ModelInstanceSaveActionType = "instance" | "document";

export class ModelInstanceSaveAction {

  constructor(public readonly type: ModelInstanceSaveActionType, private readonly _change: ModelInstanceChange, private _callback: (result: boolean) => void) {
  }

  public async save(saveAction: (change: ModelInstanceChange) => Promise<boolean>): Promise<void> {
    this._callback(await saveAction(this._change));
  }
}
