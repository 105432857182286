import ApiService from "@/services/api";
import { EquipmentDto, UpdateResourceModelInstanceAssignmentCommand } from "@masta/generated-model";
import { defineStore } from "pinia";
import { useErrorsStore } from "@/store/ErrorsStore";
import { useScenariosStore } from "@/store/ScenariosStore";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface State {
  equipments: EquipmentDto[];
}

export const useEquipmentsStore = defineStore("equipments", {
  state: (): State => ({
    equipments: []
  }),
  actions: {
    async fetch(cql?: string) {
      try {
        const scenariosStore = useScenariosStore();
        const response = await ApiService.equipment.getPaginated({
          scenarioId: scenariosStore.selectedScenario?.id ?? undefined!,
          ignoreGrouping: true,
          cql
        } as any);
        this.equipments = response.data.results ?? [];
      } catch (e) {
        const errorsStore = useErrorsStore();
        errorsStore.handleError(e);
        throw e;
      }
    },
    async updateModelInstanceAssignment(request: UpdateResourceModelInstanceAssignmentCommand) {
      try {
        await ApiService.resources.updateModelInstanceAssignment(request);
      } catch (e) {
        const errorsStore = useErrorsStore();
        errorsStore.handleError(e);
        throw e;
      }
    }
  }
});

