import { inject } from "inversify";
import { GanttEvents, GanttSettings, GraphicsMousePositionEvent, IocContainer, provideSingleton, SettingKey, TimelineManager } from "../../../../Core";
import { fromEvent } from "rxjs";
import { LayerCanvas } from "./LayerCanvas";
import { Promise } from "cypress/types/cy-bluebird";

@provideSingleton(HelpLayer)
export class HelpLayer extends LayerCanvas {
  private _mousePosition: GraphicsMousePositionEvent | undefined;

  private _up = false;
  private _down = false;
  private _left = false;
  private _right = false;
  private _zoomIn = false;
  private _zoomOut = false;

  private _info = false;
  private _navKeyboardInfo = false;
  private _debugInfo = false;

  constructor(
    @inject(TimelineManager) timelineManager: TimelineManager,
    @inject(GanttEvents) ganttEvents: GanttEvents,
    @inject(IocContainer) private _iocContainer: IocContainer,
    @inject(GanttSettings) private _ganttSettings: GanttSettings
  ) {
    super(timelineManager, ganttEvents, HelpLayer.name, "help-tooltip-layer");
  }

  async beforeInitialize(): Promise<void> {
    return super.beforeInitialize();
  }

  async afterInitialize(): Promise<void> {
    await super.afterInitialize();

    this.subscribe(
      this._ganttSettings.getSetting$<boolean>(SettingKey.HELP_SHOW_LAYER).subscribe((s) => {
        this.visible = s ?? false;
        this.batchDraw();
      })
    );

    this.subscribe(
      this._ganttSettings.getSetting$<boolean>(SettingKey.DEBUG_SHOW_INFO).subscribe((s) => {
        this._debugInfo = s ?? false;
        this.batchDraw();
      })
    );

    this.subscribe(
      this._ganttSettings.getSetting$<boolean>(SettingKey.HELP_SHOW_NAV_KEYBOARD).subscribe((s) => {
        this._navKeyboardInfo = s ?? false;
        this.batchDraw();
      })
    );

    this.subscribe(
      this._ganttSettings.getSetting$<boolean>(SettingKey.HELP_SHOW_INFO).subscribe((s) => {
        this._info = s ?? false;
        this.batchDraw();
      })
    );

    this.subscribe(
      this._ganttEvents.mousePositionEvent$.subscribe((e) => {
        this._mousePosition = e;
        if (this._debugInfo) {
          this.batchDraw();
        }
      })
    );
    this.subscribe(
      this._ganttEvents.mouseLeaveEvent$.subscribe(() => {
        this._mousePosition = undefined;
        if (this._debugInfo) {
          this.batchDraw();
        }
      })
    );
    this.subscribe(
      this._ganttEvents.scrollEvent$.subscribe(() => {
        this.batchDraw();
      })
    );
    this.subscribe(
      fromEvent<KeyboardEvent>(document, "keyup").subscribe(async (e) => {
        const key = e.key.toLowerCase();
        if (key === "w" || key === "arrowup") {
          this._up = false;
          this.batchDraw();
          return;
        }
        if (key === "s" || key === "arrowdown") {
          this._down = false;
          this.batchDraw();
          return;
        }
        if (key === "a" || key === "arrowleft") {
          this._left = false;
          this.batchDraw();
          return;
        }
        if (key === "d" || key === "arrowright") {
          this._right = false;
          this.batchDraw();
          return;
        }
        if (key === "+" || key === "q") {
          this._zoomIn = false;
          this.batchDraw();
          return;
        }
        if (key === "-" || key === "e") {
          this._zoomOut = false;
          this.batchDraw();
          return;
        }
      })
    );
    this.subscribe(
      fromEvent<KeyboardEvent>(document, "keydown").subscribe(async (e) => {
        const key = e.key.toLowerCase();
        if (key === "w" || key === "arrowup") {
          this._up = true;
          this.batchDraw();
          return;
        }
        if (key === "s" || key === "arrowdown") {
          this._down = true;
          this.batchDraw();
          return;
        }

        if (key === "a" || key === "arrowleft") {
          this._left = true;
          this.batchDraw();
          return;
        }
        if (key === "d" || key === "arrowright") {
          this._right = true;
          this.batchDraw();
          return;
        }

        if (key === "+" || key === "q") {
          this._zoomIn = true;
          this.batchDraw();
          return;
        }
        if (key === "-" || key === "e") {
          this._zoomOut = true;
          this.batchDraw();
          return;
        }
      })
    );
  }

  async doDrawFromBatch(): Promise<void> {
    this.clear();
    if (!this.visible) return;

    const layerContainer = this.element.closest(".above-rows-content-canvas-container");
    const rowsContainer = this.element.closest(".rows-container");
    const layerContainerRect = layerContainer?.getBoundingClientRect();
    const rowsContainerRect = rowsContainer?.getBoundingClientRect();

    const parentTopOffset = rowsContainer?.scrollTop ?? 0;

    const width = layerContainerRect?.width ?? 400;
    const height = rowsContainerRect?.height ?? 200;

    this.drawInfo(width - 200, height - 180 + parentTopOffset);
    this.drawNavKeyboardInfo(width - 400, height - 165 + parentTopOffset);
    this.drawDebugInfo(width - 150, 10 + parentTopOffset);
  }

  private drawNavKeyboardInfo(xOffset = 0, yOffset = 0) {
    if (!this._navKeyboardInfo) return;

    this.context.save();

    this.context.textAlign = "center";

    this.context.lineWidth = 0.5;
    this.context.strokeStyle = "rgba(187,187,187,0.75)";

    // Draw the up arrow key
    this.context.beginPath();
    this.context.moveTo(30 + xOffset, 25 + yOffset);
    this.context.lineTo(55 + xOffset, 0 + yOffset);
    this.context.lineTo(80 + xOffset, 25 + yOffset);
    this.context.lineTo(55 + xOffset, 25 + yOffset);
    this.context.fillStyle = "rgba(187,187,187,0.32)";
    if (this._up) {
      this.context.fillStyle = "rgba(187,187,187,0.75)";
    }
    this.context.fill();
    this.context.closePath();
    this.context.stroke();

    this.context.fillStyle = "rgba(0,0,0,0.75)";
    this.context.fillText(`W`, 55 + xOffset, 20 + yOffset);

    // Draw the down arrow key
    this.context.beginPath();
    this.context.moveTo(30 + xOffset, 85 + yOffset);
    this.context.lineTo(55 + xOffset, 110 + yOffset);
    this.context.lineTo(80 + xOffset, 85 + yOffset);
    this.context.lineTo(55 + xOffset, 85 + yOffset);
    this.context.fillStyle = "rgba(187,187,187,0.32)";
    if (this._down) {
      this.context.fillStyle = "rgba(187,187,187,0.75)";
    }
    this.context.fill();
    this.context.closePath();
    this.context.stroke();

    this.context.fillStyle = "rgba(0,0,0,0.75)";
    this.context.fillText(`S`, 55 + xOffset, 97 + yOffset);

    // Draw the left arrow key
    this.context.beginPath();
    this.context.moveTo(25 + xOffset, 25 + yOffset);
    this.context.lineTo(0 + xOffset, 55 + yOffset);
    this.context.lineTo(25 + xOffset, 80 + yOffset);
    this.context.lineTo(25 + xOffset, 55 + yOffset);
    this.context.fillStyle = "rgba(187,187,187,0.32)";
    if (this._left) {
      this.context.fillStyle = "rgba(187,187,187,0.75)";
    }
    this.context.fill();
    this.context.closePath();
    this.context.stroke();

    this.context.fillStyle = "rgba(0,0,0,0.75)";
    this.context.fillText(`A`, 15 + xOffset, 57 + yOffset);

    // Draw the right arrow key
    this.context.beginPath();
    this.context.moveTo(85 + xOffset, 30 + yOffset);
    this.context.lineTo(110 + xOffset, 55 + yOffset);
    this.context.lineTo(85 + xOffset, 80 + yOffset);
    this.context.lineTo(85 + xOffset, 55 + yOffset);
    this.context.fillStyle = "rgba(187,187,187,0.32)";
    if (this._right) {
      this.context.fillStyle = "rgba(187,187,187,0.75)";
    }
    this.context.fill();
    this.context.closePath();
    this.context.stroke();

    this.context.fillStyle = "rgba(0,0,0,0.75)";
    this.context.fillText(`D`, 95 + xOffset, 57 + yOffset);

    // draw rounding circle
    this.context.beginPath();
    this.context.arc(55 + xOffset, 55 + yOffset, 55, 0, 2 * Math.PI);
    this.context.stroke();

    // draw zoom in
    this.context.beginPath();
    this.context.rect(30 + xOffset, 30 + yOffset, 50, 20);
    this.context.fillStyle = "rgba(187,187,187,0.32)";
    if (this._zoomIn) {
      this.context.fillStyle = "rgba(187,187,187,0.75)";
    }
    this.context.fill();
    this.context.stroke();

    this.context.fillStyle = "rgba(0,0,0,0.75)";
    this.context.fillText(`+ Q`, 55 + xOffset, 45 + yOffset);

    // draw zoom out
    this.context.beginPath();
    this.context.rect(30 + xOffset, 60 + yOffset, 50, 20);
    this.context.fillStyle = "rgba(187,187,187,0.32)";
    if (this._zoomOut) {
      this.context.fillStyle = "rgba(187,187,187,0.75)";
    }
    this.context.fill();
    this.context.stroke();

    this.context.fillStyle = "rgba(0,0,0,0.75)";
    this.context.fillText(`- E`, 55 + xOffset, 72 + yOffset);

    this.context.restore();
  }

  private drawDebugInfo(xOffset = 0, yOffset = 0) {
    if (!this._debugInfo) return;
    this.context.save();

    this.context.textAlign = "left";

    this.context.beginPath();
    this.context.fillStyle = "rgba(187,187,187,0.32)";
    this.context.rect(0 + xOffset, 0 + yOffset, 130, 100);
    this.context.fill();
    this.context.stroke();

    this.context.fillStyle = "rgba(252,0,0,0.75)";
    this.context.fillText(`🕵️ DEBUG INFO 🐛`, 10 + xOffset, 15 + yOffset);
    this.context.fillText(`🐭 mouse position`, 10 + xOffset, 30 + yOffset);
    this.context.fillText(`x: ${this._mousePosition?.x ?? "-"}`, 10 + xOffset, 45 + yOffset);
    this.context.fillText(`y: ${this._mousePosition?.y ?? "-"}`, 10 + xOffset, 55 + yOffset);

    this.context.restore();
  }

  private drawInfo(xOffset = 0, yOffset = 0) {
    if (!this._info) return;
    this.context.textAlign = "left";

    this.context.save();

    this.context.beginPath();
    this.context.fillStyle = "rgba(187,187,187,0.32)";
    this.context.rect(0 + xOffset, 0 + yOffset, 170, 150);
    this.context.fill();
    this.context.stroke();

    const i18n = this._ganttSettings.getSetting(SettingKey.HELP_I18N_LABELS);

    this.context.fillStyle = "rgba(0,0,0,0.64)";
    this.context.fillText(i18n["help.layer.keyboardNavigation"], 10 + xOffset, 10 + yOffset);
    this.context.fillText(i18n["help.layer.keyboardUp"], 10 + xOffset, 20 + yOffset);
    this.context.fillText(i18n["help.layer.keyboardDown"], 10 + xOffset, 30 + yOffset);
    this.context.fillText(i18n["help.layer.keyboardLeft"], 10 + xOffset, 40 + yOffset);
    this.context.fillText(i18n["help.layer.keyboardRight"], 10 + xOffset, 50 + yOffset);
    this.context.fillText(i18n["help.layer.keyboardZoomIn"], 10 + xOffset, 60 + yOffset);
    this.context.fillText(i18n["help.layer.keyboardZoomOut"], 10 + xOffset, 70 + yOffset);
    this.context.fillText(i18n["help.layer.selection"], 10 + xOffset, 90 + yOffset);
    this.context.fillText(i18n["help.layer.selectionSingle"], 10 + xOffset, 100 + yOffset);
    this.context.fillText(i18n["help.layer.selectionMultiple"], 10 + xOffset, 110 + yOffset);
    this.context.fillText(i18n["help.layer.showDebugInfo"], 10 + xOffset, 130 + yOffset);
    this.context.fillText(i18n["help.layer.hideThisInfo"], 10 + xOffset, 140 + yOffset);

    this.context.restore();
  }
}
