import { inject, injectable } from "inversify";
import { IocContainer, Lifecycle } from "../Core";
import { WebWorkerRowManager } from "./WebWorkerRowManager";

export type CanvasRegistryEntry = {
  id: string;
  renderer: string;
  canvas: OffscreenCanvas;
  context: OffscreenCanvasRenderingContext2D;
  type: "row" | "layer";
  rowId?: string;
};

@injectable()
export class CanvasManager extends Lifecycle {
  private _canvasMap: Map<string, CanvasRegistryEntry> = new Map();

  constructor(@inject(IocContainer) private _ioc: IocContainer) {
    super();
  }

  async afterInitialize(): Promise<void> {
    // we cannot use constructor injection because of IOC container injection -WebWorkerRowManager is singleton
    // this._rowManager = await this._ioc.getAsync<WebWorkerRowManager>(WebWorkerRowManager);
  }

  async bindCanvas(id: string, renderer: string, canvas: OffscreenCanvas, type: "row" | "layer", rowId?: string) {
    const entry = { id, renderer, canvas, context: canvas.getContext("2d", { willReadFrequently: false })!, type, rowId };
    this._canvasMap.set(id, entry);
    if (rowId) {
      const rowManager = await this._ioc.getAsync<WebWorkerRowManager>(WebWorkerRowManager);
      const container = rowManager.geRowContainer(rowId);
      switch (type) {
        case "layer":
          await container.bindLayerCanvas(entry);
          break;
        case "row":
          await container.bindRowCanvas(entry);
          break;
      }
      console.debug("[Gantt] [WW] CanvasManager.bindCanvas (row)", type, id, renderer, rowId);
    } else {
      console.debug("[Gantt] [WW] CanvasManager.bindCanvas", type, id, renderer);
    }
  }

  unbindCanvas(id: string) {
    if (this._canvasMap.has(id)) {
      const entry = this._canvasMap.get(id)!;
      if (entry.rowId) {
        this._ioc.getAsync<WebWorkerRowManager>(WebWorkerRowManager).then((rowManager) => {
          const container = rowManager.geRowContainer(entry.rowId!);
          switch (entry.type) {
            case "layer":
              container.unbindLayerCanvas(entry.id);
              break;
            case "row":
              container.unbindRowCanvas(entry.id);
              break;
          }
        });
      }
    }
    this._canvasMap.delete(id);
    console.debug("[Gantt] [WW] CanvasManager.unbindCanvas", id);
  }

  scale(id: string, width: number, height: number, dpr: number): void {
    const canvasEntry = this._canvasMap.get(id);
    if (canvasEntry) {
      const { canvas, context, rowId } = canvasEntry;
      canvas.width = width;
      canvas.height = height;
      context?.scale(dpr, dpr);
      if (rowId) console.debug("[Gantt] [WW] CanvasManager.scale (row)", rowId);
    }
  }

  clear(id: string): void {
    const canvasEntry = this._canvasMap.get(id);
    if (canvasEntry) {
      const { canvas, context } = canvasEntry;
      context.clearRect(0, 0, canvas.width, canvas.height);
    }
  }

  // async bindRowCanvas(id: string, rowId: string, renderer: string, canvas: OffscreenCanvas) {
  //   this._canvasMap.set(id, { id, renderer, canvas, context: canvas.getContext("2d", { willReadFrequently: false })! });
  //   const rowManager = await this._ioc.getAsync<WebWorkerRowManager>(WebWorkerRowManager);
  //   const container = rowManager.geRowContainer(rowId);
  //   container.bindCanvas(renderer, canvas);
  // }
  //
  // async unbindRowCanvas(id: string, rowId: string) {
  //   this._canvasMap.delete(id);
  //   const rowManager = await this._ioc.getAsync<WebWorkerRowManager>(WebWorkerRowManager);
  //   const container = rowManager.geRowContainer(rowId);
  //   container.unbindCanvas();
  // }

  draw<TParams = any>(id: string, ...params: TParams[]) {
    const canvasEntry = this._canvasMap.get(id);
    if (canvasEntry) {
      const { canvas, context, renderer, rowId } = canvasEntry;
      if (rowId) {
        this._ioc.getAsync<WebWorkerRowManager>(WebWorkerRowManager).then((rowManager) => {
          const container = rowManager.geRowContainer(rowId);
          switch (canvasEntry.type) {
            case "layer":
              container.drawLayer<TParams>(id, ...params);
              break;
            case "row":
              container.drawRow<TParams>(id, ...params);
              break;
          }
          return;
        });
      }
    }
  }

  // drawRowContainerCanvas<TParams = any>(id: string, ...params: TParams[]) {
  //   const canvasEntry = this._canvasMap.get(id);
  //   if (canvasEntry) {
  //     const { canvas, context, renderer, rowId } = canvasEntry;
  //     if (rowId) {
  //       this._ioc.getAsync<WebWorkerRowManager>(WebWorkerRowManager).then((rowManager) => {
  //         const container = rowManager.geRowContainer(rowId);
  //         console.log("CanvasManager.drawRowContainer", rowId, renderer);
  //         container.drawRowContainer<TParams>(...params);
  //         return;
  //       });
  //     }
  //   }
  // }

  // drawRowLayer<TParams = any>(id: string, ...params: TParams[]) {
  //   const canvasEntry = this._canvasMap.get(id);
  //   if (canvasEntry) {
  //     const { canvas, context, renderer, rowId } = canvasEntry;
  //     if (rowId) {
  //       this._ioc.getAsync<WebWorkerRowManager>(WebWorkerRowManager).then((rowManager) => {
  //         const container = rowManager.geRowContainer(rowId);
  //         container.drawRowLayer<TParams>(id, canvas, context, renderer, ...params);
  //         return;
  //       });
  //     }
  //   }
  // }
  //
  // drawSystemLayer<TParams = any>(id: string, ...params: TParams[]) {
  //   const canvasEntry = this._canvasMap.get(id);
  //   if (canvasEntry) {
  //     const { canvas, context, renderer } = canvasEntry;
  //     this._ioc.getAsync<ILayerRenderer<TParams>>(renderer).then((_renderer) => {
  //       requestAnimation({
  //         id: id,
  //         callback: () => {
  //           _renderer.render(canvas, context, ...params);
  //         }
  //       });
  //     });
  //   }
  // }

  // drawRowCanvas<TParams = any>(id: string, rowId: string, ...params: TParams[]): void {
  //   const canvasEntry = this._canvasMap.get(id);
  //   console.log("drawRowCanvas", id, rowId, params, canvasEntry);
  //   if (canvasEntry) {
  //     this._ioc.getAsync<WebWorkerRowManager>(WebWorkerRowManager).then((rowManager) => {
  //       const container = rowManager.geRowContainer(rowId);
  //       container.drawRowCanvas<TParams>(...params);
  //     });
  //   }
  // }
}
