import { AxiosInstance } from "axios";
import Api from "@/services/api";

export abstract class ApiClient {
  protected _axios: AxiosInstance;

  constructor() {
    this._axios = Api.ApiClient as AxiosInstance;
  }
}
