﻿import { inject, Ref } from "vue";
import { IGanttWorker, IocSymbols, Lifecycle } from "@masta/gantt2/core";
import { Gantt, IGanttOptions } from "@masta/gantt2/gantt";
import type { IGanttDataLoaderOptions } from "./TasksGanttDataLoader";
import { rowFactoryIocExtension } from "./TasksFrontModule";
import { $authService } from "@/services/AuthService";
import { toValue } from "@vueuse/core";
import { AppContextDto, ProductionTasksChangedNotificationEvent } from "@masta/generated-model";
import { $appContextSymbol } from "@/plugins/app.plugin";
import { AppIocSymbols } from "@/components/Gantt/ResourcesGantt/AppIocSymbols";
import { RouteLocationNormalizedLoaded, useRoute } from "vue-router";
import { $dateTimeFormatterSymbol, $durationFormatterSymbol, DateFormatter, DurationFormatter } from "@masta/shared";
import { NoteHandler } from "@/components/Gantt/ResourcesGantt/NoteHandler";
import TasksGanttWorker from "./TasksGanttWorker?worker&url";
import { ProductionTaskChangeNotificationHandler } from "@/components/Gantt/TaskGantt/ProductionTaskChangeNotificationHandler";

export interface IResourcesGanttOptions extends IGanttOptions<any> {
  selectedScenario: string | undefined;
  constsToRegisterInIoc: { key: symbol; value: any }[];
}

export class TasksGanttLoader extends Lifecycle {
  public gantt: Gantt<any>;
  private _$appContext: AppContextDto;
  private _$route: RouteLocationNormalizedLoaded;
  private _$selectedScenario: Ref<{ id: string } | undefined>;
  private _$dateTimeFormatter: DateFormatter;
  private _$durationFormatter: DurationFormatter;
  private _ganttWorker: IGanttWorker;
  private _productionTaskChangeNotificationHandler: ProductionTaskChangeNotificationHandler;
  private _filterExpressionProvider?: () => string | null | undefined;

  constructor(private _container: Ref<HTMLDivElement | undefined>, selectedScenario: Ref<any>, filterExpressionProvider?: () => string | null | undefined) {
    super();
    this._$appContext = inject<Ref<AppContextDto>>($appContextSymbol)!.value;
    this._$route = useRoute();
    this._$dateTimeFormatter = inject<DateFormatter>($dateTimeFormatterSymbol)!;
    this._$durationFormatter = inject<DateFormatter>($durationFormatterSymbol)!;
    this._$selectedScenario = selectedScenario;
    this._filterExpressionProvider = filterExpressionProvider;
  }

  async beforeInitialize(): Promise<void> {
    await super.beforeInitialize();
    const commonModule = import.meta.glob("./../Common/CommonFrontModule.ts")["../Common/CommonFrontModule.ts"];
    const tasksFrontModule = import.meta.glob("./TasksFrontModule.ts")["./TasksFrontModule.ts"];

    // const webWorkerModuleAssetUrl = await this.resolveWebWorkerModuleAssetUrl();
    // console.debug("Resolved WebWorkerModule URL: ", webWorkerModuleAssetUrl);
    this.gantt = new Gantt<any>({
      customGanttWorker: new Worker(new URL(TasksGanttWorker, import.meta.url), { type: "module" }),
      container: toValue(this._container)!,
      modules: [commonModule, tasksFrontModule],
      // webWorkerModules: [webWorkerModuleAssetUrl],
      dataLoaderOptions: {
        token: $authService.token!,
        tenantId: $authService.tokenParsed!.tenant_id
      } as IGanttDataLoaderOptions,
      selectedScenario: toValue(this._$selectedScenario),
      constsToRegisterInIoc: [
        { key: AppIocSymbols.$appContext, value: this._$appContext },
        { key: AppIocSymbols.$route, value: this._$route },
        { key: AppIocSymbols.$selectedScenario, value: toValue(this._$selectedScenario)?.id },
        { key: AppIocSymbols.$durationFormatter, value: this._$durationFormatter },
        { key: AppIocSymbols.$dateTimeFormatter, value: this._$dateTimeFormatter },
        { key: AppIocSymbols.filterExpressionProvider, value: this._filterExpressionProvider }
      ],
      rowFactoryIocExtension: rowFactoryIocExtension
    } as IResourcesGanttOptions);
    await this.gantt.initialize();
  }

  async afterInitialize(): Promise<void> {
    await super.afterInitialize();
    // await this.gantt.setTimelineStart(Instant.now().minus(12, ChronoUnit.HOURS), ChronoUnit.DAYS, 400);
    await this.gantt.initializeDataLoader();
    await this.gantt.refreshData();
    this._ganttWorker = await this.gantt.IocContainer.getAsync<IGanttWorker>(IocSymbols.GanttWorkerSymbol);
    // this._resourceCapacityChangeNotificationHandler = await this._ganttWorker.getServiceInstance<ResourceCapacityChangeNotificationHandler>(
    //   ResourceCapacityChangeNotificationHandler.name
    // );
    this._productionTaskChangeNotificationHandler = await this.gantt.IocContainer.getAsync<ProductionTaskChangeNotificationHandler>(ProductionTaskChangeNotificationHandler.name);
  }

  async beforeDestroy(): Promise<void> {
    await super.beforeDestroy();
    await this.gantt.destroy();
  }

  public async productionTaskChangeNotificationEventHandler(event: ProductionTasksChangedNotificationEvent): Promise<void> {
    await this._productionTaskChangeNotificationHandler.handleNotification(event);
  }

  public async getNoteHandler(): Promise<NoteHandler> {
    return this.gantt.IocContainer.getAsync<NoteHandler>(NoteHandler);
  }
}
