import { inject, injectable, interfaces } from "inversify";
import { IocContainer, IocSymbols } from "../../../../Core";
import { GanttCanvas } from "../../../GanttCanvas";

@injectable()
export class LayerManager {
  private _aboveRowContentSystemLayers: (new (...args: any[]) => GanttCanvas)[] = [];
  private _belowRowContentSystemLayers: (new (...args: any[]) => GanttCanvas)[] = [];
  private _rowBackgroundSystemLayers: (new (...args: any[]) => GanttCanvas)[] = [];
  private _rowForegroundSystemLayers: (new (...args: any[]) => GanttCanvas)[] = [];

  constructor(@inject(IocContainer) private readonly _iocContainer: IocContainer) {}

  public addAboveRowContentSystemLayers(layer: new (...args: any[]) => GanttCanvas) {
    if (!this._aboveRowContentSystemLayers.includes(layer)) {
      this._aboveRowContentSystemLayers.push(layer);
    }
  }

  public removeAboveRowContentSystemLayers(layer: new (...args: any[]) => GanttCanvas) {
    const index = this._aboveRowContentSystemLayers.indexOf(layer);
    if (index > -1) {
      this._aboveRowContentSystemLayers.splice(index, 1);
    }
  }

  public bindAboveRowContentSystemLayers(container: interfaces.Container) {
    this._aboveRowContentSystemLayers.forEach((layer) => container.bind(IocSymbols.AboveRowContentSystemLayersSymbol).to(layer).inSingletonScope());
  }

  public addBelowRowContentSystemLayers(layer: new (...args: any[]) => GanttCanvas) {
    if (!this._belowRowContentSystemLayers.includes(layer)) {
      this._belowRowContentSystemLayers.push(layer);
    }
  }

  public removeBelowRowContentSystemLayers(layer: new (...args: any[]) => GanttCanvas) {
    const index = this._belowRowContentSystemLayers.indexOf(layer);
    if (index > -1) {
      this._belowRowContentSystemLayers.splice(index, 1);
    }
  }

  public bindBelowRowContentSystemLayers(container: interfaces.Container) {
    this._belowRowContentSystemLayers.forEach((layer) => container.bind(IocSymbols.BelowRowContentSystemLayersSymbol).to(layer).inSingletonScope());
  }

  public addRowBackgroundSystemLayers(layer: new (...args: any[]) => GanttCanvas) {
    if (!this._rowBackgroundSystemLayers.includes(layer)) this._rowBackgroundSystemLayers.push(layer);
  }

  public removeRowBackgroundSystemLayers(layer: new (...args: any[]) => GanttCanvas) {
    const index = this._rowBackgroundSystemLayers.indexOf(layer);
    if (index > -1) {
      this._rowBackgroundSystemLayers.splice(index, 1);
    }
  }

  public bindRowBackgroundSystemLayers(container: interfaces.Container) {
    this._rowBackgroundSystemLayers.forEach((layer) => container.bind(IocSymbols.RowBackgroundSystemLayersSymbol).to(layer).inSingletonScope());
  }

  public addRowForegroundSystemLayers(layer: new (...args: any[]) => GanttCanvas) {
    if (!this._rowForegroundSystemLayers.includes(layer)) this._rowForegroundSystemLayers.push(layer);
  }

  public removeRowForegroundSystemLayers(layer: new (...args: any[]) => GanttCanvas) {
    const index = this._rowForegroundSystemLayers.indexOf(layer);
    if (index > -1) {
      this._rowForegroundSystemLayers.splice(index, 1);
    }
  }

  public bindRowForegroundSystemLayers(container: interfaces.Container) {
    this._rowForegroundSystemLayers.forEach((layer) => container.bind(IocSymbols.RowForegroundSystemLayersSymbol).to(layer).inSingletonScope());
  }
}
