import { IActivityObject } from "../Model";
import { ActivityBounds } from "./ActivityBounds";
import { ActivityActionEvent } from "../Events";

export type TooltipActivity = {
  rowId: string;
  activity: IActivityObject;
  x: number;
  y: number;
  w: number;
  h: number;
  activityType: string;
};

export function createTooltipActivity(activityActionEvent: ActivityActionEvent, activity: IActivityObject, activityType: string): TooltipActivity {
  return {
    rowId: activityActionEvent.row.id,
    activity,
    x: activityActionEvent.x,
    y: activityActionEvent.y,
    w: activityActionEvent.w,
    h: activityActionEvent.h,
    activityType
  };
}
