import { ApiClient } from "@/services/api/common.api";
import { GetUsersResponse, InviteUserCommand } from "@masta/generated-model";

export default {
  async getUsers() {
    return ApiClient.get<GetUsersResponse>("/users");
  },
  async inviteUser(data: InviteUserCommand) {
    return ApiClient.post("/users", data);
  }
};
