import ApiService from "@/services/api";
import { ServerSideDataSource } from "@/components/Grid/ServerSideDataSource";
import {
  AgGridRequestBase,
  CreatePersonCommand,
  CreatePersonGroupCommand,
  EquipmentDto,
  IAgGridPaginationResponse,
  PersonDto,
  ResourceTrackingType,
  ResourceType,
  UpdatePersonCommand,
  UpdatePersonGroupCommand
} from "@masta/generated-model";
import { IServerSideGetRowsParams } from "ag-grid-enterprise";
import { State as ScenarioState, useScenariosStore } from "@/store/ScenariosStore";
import { Store } from "pinia";
import { GUID_EMPTY } from "@/components/ValueCellEditor/CommonFormatters";

type CreateCommandType = CreatePersonCommand | CreatePersonGroupCommand;
type UpdateCommandType = UpdatePersonCommand | UpdatePersonGroupCommand;

export class PersonnelServerSideDataSource extends ServerSideDataSource<PersonDto, PersonDto, CreateCommandType, UpdateCommandType, string> {
  private _scenarioStore: Store<string, ScenarioState>;

  constructor(id: string) {
    super(id);
    this._scenarioStore = useScenariosStore();
  }

  async getAll(params: IServerSideGetRowsParams): Promise<IAgGridPaginationResponse<PersonDto>> {
    const groupKeys = params.request.groupKeys;
    const parentId = groupKeys[groupKeys.length - 1];
    const scenarioId = this._scenarioStore.selectedScenario?.id ?? "";

    const serverRequest = this.constructServerRequest(params);
    this.modifiedFilterModel(serverRequest);
    this.modifiedSortModel(serverRequest);

    const { data } = await ApiService.personnel.getPaginated({
      ...serverRequest,
      scenarioId: scenarioId,
      groupId: parentId,
      onlyReleasedModelInstances: false
    });
    return data;
  }

  async getSingle(id: string): Promise<PersonDto | undefined> {
    const scenario = this._scenarioStore.selectedScenario;
    if (!scenario) return;
    const response = await ApiService.personnel.getSingle(id, scenario.id);
    return response.data;
  }

  async create(entity: PersonDto): Promise<string> {
    const request = this.transformForCreate(entity);
    const scenario = this._scenarioStore.selectedScenario;
    request.scenarioId = scenario?.id ?? "";
    const response = this.isGroup(entity)
      ? await ApiService.personnel.createGroup(request as CreatePersonGroupCommand)
      : await ApiService.personnel.create(request as CreatePersonCommand);
    return response.data.resourceId;
  }

  async update(entity: PersonDto): Promise<string> {
    this.isGroup(entity)
      ? await ApiService.personnel.updateGroup(this.transformForUpdate(entity) as UpdatePersonGroupCommand)
      : await ApiService.personnel.update(this.transformForUpdate(entity) as UpdatePersonCommand);
    return entity.id;
  }

  async remove(entity: PersonDto): Promise<void> {
    this.isGroup(entity)
      ? await ApiService.personnel.removeGroup(entity.id, this._scenarioStore.selectedScenario?.id)
      : await ApiService.personnel.remove(entity.id, this._scenarioStore.selectedScenario?.id);
  }

  protected transformForCreate(entity: PersonDto): CreateCommandType {
    return {
      scenarioId: entity.scenarioId ?? this._scenarioStore.selectedScenario?.id ?? GUID_EMPTY,

      businessId: entity.businessId,
      name: entity.name,
      parentId: entity.parentId,
      parentBusinessId: null,

      tags: entity.tags,
      color: entity.color,
      trackingType: ResourceTrackingType.None, // ???
      subTypes: null,

      infiniteCapacity: null,
      infiniteCapacityOffset: null,
      token: null,
      plannable: entity.plannable
    } as CreatePersonCommand & CreatePersonGroupCommand;
  }

  protected transformForUpdate(entity: PersonDto): UpdateCommandType {
    return {
      id: entity.id,
      ...this.transformForCreate(entity)
    } as UpdatePersonCommand & UpdatePersonGroupCommand;
  }

  protected isGroup(entity: EquipmentDto) {
    return entity.type === ResourceType.PersonGroup;
  }

  protected modifiedFilterModel(serverRequest: AgGridRequestBase) {
    const filterModel = serverRequest.filterModel;

    if (filterModel && "ag-Grid-AutoColumn" in filterModel) {
      // rewrite ag-Grid-AutoColumn to the businessId to the filterModel
      filterModel["businessId"] = filterModel["ag-Grid-AutoColumn"];
    }
  }

  protected modifiedSortModel(serverRequest: AgGridRequestBase) {
    const sortModel = serverRequest.sortModel;

    if (sortModel) {
      sortModel.forEach((sortModelItem) => {
        if (sortModelItem.colId === "ag-Grid-AutoColumn") {
          // rewrite ag-Grid-AutoColumn to the businessId
          sortModelItem.colId = "businessId";
        }
      });
    }
  }
}
