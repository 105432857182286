<script lang="ts" setup>
import GridWrapper from "@/components/Grid/GridWrapper.vue";
import { inject, Ref, ref } from "vue";
import SystemEnumService from "@/services/system-enum.service";
import { GridWrapperComponent } from "@/components/Grid/GridWrapperComponent";
import { ColDef, SelectionChangedEvent } from "ag-grid-community";
import { $dateTimeFormatterSymbol, DateFormatter } from "@masta/shared";
import { AvailabilityRuleDto } from "@masta/generated-model";
import { $t } from "@/i18n";

interface Props {
  availabilityRules: AvailabilityRuleDto[];
}

const props = defineProps<Props>();
const emits = defineEmits(["selection-changed"]);

const $dateTimeFormatter = inject<DateFormatter>($dateTimeFormatterSymbol)!;

const gridWrapperRef = ref<GridWrapperComponent>();

async function onSelectionChanged({ api }: SelectionChangedEvent) {
  const selectedRows = api.getSelectedRows();
  emits("selection-changed", selectedRows);
}

function onPrepareColumns(columnDefs: Ref<ColDef[]>) {
  columnDefs.value = [
    {
      field: "name",
      headerValueGetter: (_: any) => $t("availabilityRules-list-name-label", { $: "Name" }),
      editable: false,
      sortable: true
    },
    {
      field: "businessId",
      headerValueGetter: (_: any) => $t("availabilityRules-list-businessId-label", { $: "Business ID" }),
      editable: false,
      sortable: true
    },
    {
      field: "availability",
      headerValueGetter: (_: any) => $t("availabilityRules-list-availability-label", { $: "Availability" }),
      editable: false,
      sortable: true,
      valueFormatter: (params: any) => {
        return SystemEnumService.availability(params.data.availability);
      }
    },
    {
      field: "recurrence",
      headerValueGetter: (_: any) => $t("availabilityRules-list-recurrence-label", { $: "Recurrence" }),
      editable: false,
      sortable: true
    },
    {
      field: "start",
      type: ["dateTimeTypeColumn"],
      headerValueGetter: (_: any) => $t("availabilityRules-list-periodStart-label", { $: "Period start" }),
      editable: false,
      sortable: true,
      valueFormatter: (params) => (params.data && params.data.start ? $dateTimeFormatter(params.data.start) : "")
    },
    {
      field: "end",
      type: ["dateTimeTypeColumn"],
      headerValueGetter: (_: any) => $t("availabilityRules-list-periodEnd-label", { $: "Period end" }),
      editable: false,
      sortable: true,
      valueFormatter: (params) => (params.data && params.data.end ? $dateTimeFormatter(params.data.end) : "")
    }
  ];
}
</script>

<template>
  <grid-wrapper
    ref="gridWrapperRef"
    identifier="availability-rules-assignment"
    row-selection="single"
    :enable-range-selection="false"
    :grid-data="availabilityRules"
    @prepare-columns="onPrepareColumns"
    @selection-changed="onSelectionChanged"
  />
</template>

<style lang="scss" scoped></style>
