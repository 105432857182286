<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "TasksNoRowsOverlay"
});
</script>

<template>
  <div
    role="presentation" class="ag-overlay-loading-center">
    {{ $t("task-list-noRows-message", { $: "No tasks to schedule" }) }}
  </div>
</template>

<style lang="scss">
.ag-overlay-no-rows-wrapper {
  .ag-overlay-loading-center {
    margin-top: 86px;
    background-color: rgb(var(--v-theme-primary), .8);
    color: white;
  }
}
</style>
