<script setup lang="ts">
import { watch } from "vue";
import MaterialMovement from "@/views/MaterialMovement.vue";


const props = defineProps<{
  modelValue: boolean;
}>();
const emit = defineEmits<{
  (e: "update:modelValue", value: boolean): void;
  (e: "materialMovementCreated"): void;
}>();

watch(
  () => props.modelValue,
  (newVal) => {
    if (!newVal) {
      resetDialog();
    }
  }
);

function resetDialog() {
}

function cancel() {
  emit("update:modelValue", false);
}

function onCreated() {
  emit("materialMovementCreated");
}

</script>

<template>
  <v-dialog :model-value="modelValue" max-width="90%" @update:model-value="(value) => emit('update:modelValue', value)">
    <v-card class="pa-8">
      <material-movement :embedded="true" class="align-center" @cancel="cancel" @created="onCreated" />

    </v-card>
  </v-dialog>
</template>

<style scoped>
</style>
