﻿<script lang="ts" setup>
import { onBeforeUnmount, ref } from "vue";
import { useRoute } from "vue-router";
import { useScenariosStore } from "@/store/ScenariosStore";
import { storeToRefs } from "pinia";
import EditGanttNoteActionModal from "@/components/Gantt/EditGanttNoteActionModal.vue";
import { GanttNoteDto, ProductionTasksChangedNotificationEvent } from "@masta/generated-model";
import { NoteClickEvent, NoteHandler } from "@/components/Gantt/ResourcesGantt/NoteHandler";
import { useSubscription } from "@vueuse/rxjs";
import _ from "lodash";
import { tryOnMounted } from "@vueuse/core";
import { ProductionTasksChangedNotification, useNotification } from "@/notifications";
import { TasksGanttLoader } from "@/components/Gantt/TaskGantt";

interface GanttProps {
  filterExpressionProvider?: () => string | null | undefined;
  height?: string | null | undefined;
}

const props = defineProps<GanttProps>();

const store = useScenariosStore();
const route = useRoute();
const { selectedScenario } = storeToRefs(store);

const ganttHeight = ref("100%");
const showNoteActionModal = ref(false);
const selectedNote = ref({} as GanttNoteDto);
const lastNoteClickEvent = ref<NoteClickEvent>();
const container = ref<HTMLDivElement>();
let loader: TasksGanttLoader;
let noteHandler: NoteHandler;

tryOnMounted(async () => {
  loader = new TasksGanttLoader(container, selectedScenario, props.filterExpressionProvider);
  onBeforeUnmount(async () => await loader.destroy());
  await loader.initialize();
  noteHandler = await loader.getNoteHandler();
  useSubscription(
    noteHandler.noteClickEvent$.subscribe((e) => {
      showNoteActionModal.value = true;
      selectedNote.value = _.cloneDeep(e.note!);
      lastNoteClickEvent.value = e;
    })
  );
});

async function onProductionTaskChanged(event: ProductionTasksChangedNotificationEvent) {
  await loader.productionTaskChangeNotificationEventHandler(event);
}

useNotification(ProductionTasksChangedNotification, onProductionTaskChanged);

function onSaveNoteAction(note: GanttNoteDto) {
  noteHandler.addNote(note);
}

function onDeleteNoteAction(note: GanttNoteDto) {
  noteHandler.deleteNote(note);
}
</script>

<template>
  <div class="gantt-container">
    <div class="outer">
      <div class="inner">
        <div ref="container" />
      </div>
    </div>
  </div>
  <edit-gantt-note-action-modal v-model="showNoteActionModal" :note="selectedNote" @save="onSaveNoteAction" @delete="onDeleteNoteAction" />
</template>

<style lang="scss" scoped>
.gantt2 {
  --info-container-width: 400px;
}

.gantt-container {
  width: 100%;
  height: v-bind(height);
}

.outer {
  display: flex;
  flex: 1 1 auto;
  padding: 2px;
  overflow: hidden;
  flex-direction: row;
  width: 100%;
  height: v-bind(ganttHeight);

  .bar {
    width: 100%;
  }

  .inner {
    width: 100%;
    height: 100%;
    position: relative;
  }
}

.charts {
  display: flex;
  flex: 1 1 auto;
  padding: 2px;
  overflow: hidden;
  flex-direction: row;
  width: 100%;
  height: 30%;
}
</style>
