<script setup lang="ts">
import { DocumentTile, MediaItemType } from "@/components/Media/MediaModel";
import ApiService from "@/services/api";
import MediaPicker, { MediaPickerComponent, MediaPickerProps } from "@/components/Media/MediaPicker.vue";
import { onMounted, ref, watch } from "vue";
import { DocumentDto, DocumentUpdatedNotificationEvent } from "@masta/generated-model";
import { useMedia } from "@/components/Media/useMedia";
import Online3dViewer from "@/views/ProductOverview/Online3dViewer.vue";
import QrCodeGetter from "@/components/ModelInstances/QrCodeGetter/QrCodeGetter.vue";
import { DocumentUpdatedNotification, useNotification } from "@/notifications";
import { useDebounceFn } from "@vueuse/core/index";
import { useI18n } from "vue-i18n";

export interface MediaGalleryProps extends MediaPickerProps {
}

export interface MediaGalleryComponent {
  refreshItems: () => Promise<void>;
}

const $props = withDefaults(defineProps<MediaGalleryProps>(), {
  multiple: false,
  qrCodeProps: () => ({
    resourceId: undefined,
    orderId: undefined,
    costCatalogueItemId: undefined
  })
});

const emit = defineEmits<{
  (
    e: "uploaded",
    context: {
      modelInstanceId: string;
      businessId: string;
    }
  ): void;
  (e: "selected", item: DocumentDto[]): void;
}>();

defineExpose<MediaGalleryComponent>({
  refreshItems
});

const mediaPickerRef = ref<MediaPickerComponent | null>(null);

const { transformDtoToTile } = useMedia();
const $t = useI18n().t;

const selectedItemIndex = ref<number>(0);
const selectedThumbnailIndex = ref<number>(0);

const items = ref<DocumentTile[]>([]);

function createOpenMediaPickerActionItem() {
  return {
    title: $t("mediaGallery-generalInformation-uploadItem-action", { $: "Click to upload" }),
    url: "",
    documentDto: null,
    type: MediaItemType.UploadAction
  } as DocumentTile;
}

watch(
  () => $props.qrCodeProps,
  async () => {
    await refreshItems();
  }
);

watch(selectedItemIndex, (index) => {
  selectedThumbnailIndex.value = index;
});

async function onDownload(item: DocumentTile) {
  if (!item.documentDto) return;

  const { data } = await ApiService.documents.getDocumentContentStream({
    documentId: item.documentDto.id,
    revisionNumber: item.documentDto.revisionNumber
  });

  const url = window.URL.createObjectURL(data);
  const newTab = window.open("", "_blank")!;

  if (item.documentDto?.value?.attachment?.mimeType == "application/pdf") {
    const iframeElement = document.createElement("iframe");
    iframeElement.src = url;
    iframeElement.style.width = "100%";
    iframeElement.style.height = "100%";
    newTab.document.body.appendChild(iframeElement);
  } else {
    const imgElement = document.createElement("img");
    imgElement.src = url;
    newTab.document.body.appendChild(imgElement);
  }
}

async function refreshItems() {
  if (!$props.contextName) {
    console.warn("No context name provided");
    return;
  }
  if (!$props.documentsProvider) {
    console.warn("No documents provider provided");
    return;
  }
  const documents: DocumentDto[] = [];
  if ($props.documentsProvider) {
    documents.push(...(await $props.documentsProvider($props.contextName)));
  } else {
    const { data } = await ApiService.documents.getAllDocumentsForContext($props.contextName);
    documents.push(...data.documentDtos);
  }

  items.value = await transformDtoToTile(documents);
  items.value.push(createOpenMediaPickerActionItem());
  selectedItemIndex.value = 0;
}

async function onItemDelete(item: DocumentTile) {
  if (item.documentDto && $props.onDelete) {
    await $props.onDelete(item.documentDto);
  }
}

function onSelected(data: any) {
  emit("selected", data);
}

function onUploaded(data: any) {
  refreshItems();
  emit("uploaded", data);
}

function selectSlideGroupItem(toggle: () => void, itemIndex: number) {
  toggle();
  selectedItemIndex.value = itemIndex;
}

onMounted(refreshItems);

useNotification(DocumentUpdatedNotification, (e: DocumentUpdatedNotificationEvent) => {
  onDocumentChanged(e);
});

const onDocumentChanged: (e: DocumentUpdatedNotificationEvent) => void = useDebounceFn(async (e: DocumentUpdatedNotificationEvent) => {
  await refreshItems();
  await mediaPickerRef.value?.refreshItems();
}, 500);

function isDtoAssigned(dto: DocumentDto) {
  return items.value.some((i) => i.documentDto?.id === dto?.id);
}

function getIconForDocument(item: DocumentTile) {
  if (item.type === MediaItemType.ImageFile) {
    if (item.title.endsWith(".pdf")) {
      return "mdi-file-pdf-box";
    }

    return "mdi-image";
  }

  if (item.type === MediaItemType.ModelFile) {
    return "mdi-file";
  }

  return "mdi-file";
}
</script>

<template>
  <media-picker ref="mediaPickerRef" :context-name="contextName" :multiple="multiple" :is-item-assigned="isDtoAssigned" @selected="onSelected" @uploaded="onUploaded">
    <template #activator="{ props }">
      <v-row no-gutters class="fill-height">
        <v-col cols="5" offset="3" class="fill-height d-flex">
          <v-carousel v-model="selectedItemIndex" class="flex-1-1 file-carousel" progress="primary" height="auto" hide-delimiter-background show-arrows="hover">
            <v-carousel-item v-for="item in items" :key="item.title" :src="item.url" class="tyrtyr">
              <v-card
                v-if="item.type === MediaItemType.UploadAction"
                class="d-flex flex-column align-center justify-center fill-height"
                v-bind="props"
              >
                <div class="align-center text-center">
                  <v-icon size="100">mdi-folder-multiple-image</v-icon>
                  <v-card-text>
                    {{ $t("mediaGallery-generalInformation-uploadAttachment-action", { $: "Upload Media" }) }}
                  </v-card-text>
                </div>
              </v-card>
              <v-card
                v-if="item.type === MediaItemType.ModelFile && item.file"
                class="d-flex flex-column align-center justify-center fill-height"
              >
                <Online3dViewer :file="item.file" />
              </v-card>
            </v-carousel-item>
          </v-carousel>
        </v-col>
        <v-col class="d-flex flex-column overflow-hidden fill-height justify-center pa-5" cols="3">
          <v-label class="py-4 text-subtitle-1 font-weight-medium">
            {{ $t("mediaGallery-generalInformation-uploadedAttachments-label", { $: "Uploaded Media:" }) }}
          </v-label>
          <v-slide-group
            ref="thumbnailSlideGroupRef"
            v-model="selectedThumbnailIndex"
            :show-arrows="true"
            center-active
            mandatory
            class="d-flex"
            direction="vertical"
            next-icon="mdi-chevron-double-down"
            prev-icon="mdi-chevron-double-up"
            selected-class="thumbnail-active"
            style="max-height: 60vh;"
          >
            <v-slide-group-item v-for="(item, index) in items" :key="item.title" v-slot="{ toggle, selectedClass }">
              <v-card
                v-if="item.type !== MediaItemType.UploadAction"
                elevation="0" rounded="2" :class="[selectedClass]" class="bg-material-design-grey-lighten-5 my-2"
                @click="selectSlideGroupItem(toggle, index)"
              >
                <div class="d-flex justify-space-between fill-height align-center pa-5" :class="[selectedClass]">
                  <div class="d-flex fill-height align-center thumbnail-label">
                    <v-icon class="text-subtitle-2" :icon="getIconForDocument(item)"></v-icon>
                    <v-label class="ml-2 text-subtitle-2">
                      {{ item.title }}
                    </v-label>
                  </div>
                  <div class="d-flex fill-height align-center">
                    <v-btn
                      v-if="item.type === MediaItemType.ImageFile"
                      class="ma-2" icon="mdi-open-in-new" density="compact" variant="elevated" size="x-small" @click.prevent="onDownload(item)" />
                    <v-btn
                      class="ma-2" icon="mdi-delete-outline" density="compact" variant="elevated" size="x-small" @click.prevent="onItemDelete(item)" />
                  </div>
                </div>
              </v-card>

              <v-card
                v-if="item.type === MediaItemType.UploadAction"
                elevation="0" rounded="2" :class="[selectedClass]" class="bg-material-design-grey-lighten-5 my-2" v-bind="props">
                <div class="d-flex justify-space-between fill-height align-center pa-5" :class="[selectedClass]">
                  <div class="d-flex fill-height align-center thumbnail-label">
                    <v-icon class="text-subtitle-2" icon="mdi-upload"></v-icon>
                    <v-label class="ml-2 text-subtitle-2">
                      {{ $t("mediaGallery-generalInformation-uploadAttachment-action", { $: "Upload Media" }) }}
                    </v-label>
                  </div>
                  <div class="d-flex fill-height align-center">
                    <qr-code-getter v-if="item.type === MediaItemType.UploadAction" :context-name="contextName" v-bind="qrCodeProps" is-new>
                      <template #default="{ getDocumentQrCode }">
                        <v-btn icon="mdi-qrcode" density="compact" variant="elevated" size="x-small" @click.stop="getDocumentQrCode()" />
                      </template>
                    </qr-code-getter>
                  </div>
                </div>
              </v-card>
            </v-slide-group-item>
          </v-slide-group>
        </v-col>
      </v-row>
    </template>
  </media-picker>
</template>

<style scoped lang="scss">
.file-carousel {
  :deep(.v-window__container) {
    height: 100%;
  }
}

.thumbnail-active {
  background-color: rgb(var(--v-theme-primary), 0.1);

  .thumbnail-label {
    color: rgb(var(--v-theme-primary)) !important;
  }
}

</style>
