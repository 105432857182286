<script lang="ts" setup>
import GridWrapper from "@/components/Grid/GridWrapper.vue";
import { inject, reactive, ref, watch } from "vue";
import {
  AgGridFilterModel,
  CostCatalogueItemDto,
  CurrencyCodes,
  GenerateCostCatalogueCommand,
  IResourceDto,
  MeasurementUnit,
  OrganizationDto,
  ResourceInfoDto,
  ResourceType,
  SettingDto
} from "@masta/generated-model";
import { GridWrapperComponent } from "@/components/Grid/GridWrapperComponent";
import { $t } from "@/i18n";
import { enumToEditorEntries, enumToEditorEntriesOnlyIncluding, enumValueEntryWithLocaleComparator, translateEditorEntries } from "@/components/Grid/ColumnTypes";
import { translateCurrencyCode, translateMeasurementUnit, translateResourceType } from "@/composables/translateEnum";
import { GridOptions, KeyCreatorParams, ProcessCellForExportParams, RowSelectedEvent, ValueFormatterParams, ValueGetterParams, ValueSetterParams } from "ag-grid-community";
import { requiredRule } from "@/components/ValueCellEditor/CommonValidationRules";
import { CostCatalogueServerSideDataSource } from "@/components/CostCatalogue/CostCatalogueServerSideDataSource";
import ApiService from "@/services/api";
import { useScenariosStore } from "@/store/ScenariosStore";
import { IControlledCellEditorParams } from "@/components/Grid/UseValueChangeControl";
import { $dateTimeFormatterSymbol, DateFormatter } from "@masta/shared";
import dayjs from "dayjs";
import { nameOrBusinessIdOrIdOrNull, nonEmptyGuidOrNull } from "@/components/ValueCellEditor/CommonFormatters";
import { IEnumValueSelectCellEditorParams } from "@/components/Grid/CellEditors/IEnumValueSelectCellEditorParams";
import FilterGridAction, { FilterGridActionItem } from "@/components/Grid/Filters/FilterGridAction.vue";
import { useFilterGridAction } from "@/components/Grid/Filters/UseFilterGridAction";
import { getNowInISOFormat } from "@/components/Datepicker/DatepickerUtil";
import { useSettingsStore } from "@/store/SettingsStore";
import { getSelectedRows } from "@/components/Grid/UseGridSelection";
import { useReferenceGrid } from "@/components/Grid/UseReferenceGrid";

interface Props {
  referenceValue?: string;
  cqlFilter?: () => string | null | undefined;
  agGridFilterModel?: (() => AgGridFilterModel) | null | undefined;
  resource?: IResourceDto;
  rowSelection?: "single" | "multiple";
  readonly?: boolean;
}

const settingsStore = useSettingsStore();
const props = defineProps<Props>();
const $emits = defineEmits(["rowSelected", "rowDoubleClicked"]);
const DEFAULT_COST_CATALOGUE_CURRENCY = "factory.costCatalogue.defaultCurrency";

const DEFAULT_CREATE_VALUE = ref({
  validFrom: getNowInISOFormat(),
  pricePerQuantity: 1,
  quantityPerPrice: 1,
  currencyCode: CurrencyCodes.EUR
});

async function initializeDefaultCurrency(): Promise<number> {
  await settingsStore.fetchIfEmpty();
  const settingEntry = settingsStore.settings?.find((entry: SettingDto) => entry.key === DEFAULT_COST_CATALOGUE_CURRENCY);
  const value = settingEntry?.value as number;
  return value ?? CurrencyCodes.EUR;

}

initializeDefaultCurrency().then((currency) => {
  DEFAULT_CREATE_VALUE.value.currencyCode = currency;
});

const serverSideDataSource = reactive(new CostCatalogueServerSideDataSource("costCatalogue"));
const $dateTimeFormatter = inject<DateFormatter>($dateTimeFormatterSymbol)!;
const periodStartEditorRef = ref<string | null>(null);
const periodEndEditorRef = ref<string | null>(null);

const gridWrapperRef = ref<GridWrapperComponent>();
const filterGridActionRef = ref<typeof FilterGridAction>();
const isEditBtnDisabled = ref(false);
const isDeleteBtnDisabled = ref(false);

const defaultColumnDef = ref({
  floatingFilter: true,
  filterParams: {
    applyMiniFilterWhileTyping: true
  },
  sortable: true,
  resizable: true
});

watch(
  () => props.resource,
  (newResource) => {
    if (newResource) {
      serverSideDataSource.useFilteringByResourceId(newResource.id);
      gridWrapperRef.value?.gridApi?.refreshServerSide();
    }
  },
  {
    immediate: true
  }
);

const scenariosStore = useScenariosStore();

function periodStartAndEndBothRequiredRule(_: any): boolean | string {
  const isStartPresent = !!periodStartEditorRef.value;
  const isEndPresent = !!periodEndEditorRef.value;
  return isStartPresent === isEndPresent || $t("agreement-edit-periodStartAndEndRequired-tooltip", { $: "Both start and end of period must be set or empty" });
}

function periodStartBeforeEndRequiredRule(_: any): boolean | string {
  const start = periodStartEditorRef.value;
  const end = periodEndEditorRef.value;
  return (!start && !end) || dayjs(end).diff(dayjs(start)) >= 0 || $t("agreement-edit-periodStartBeforeEndRequired-tooltip", { $: "Start must be before end of period" });
}

function mapToOrganizationDto(organizationBusinessId: string | undefined): OrganizationDto | null {
  if (organizationBusinessId) {
    const organizationDto: OrganizationDto = {
      id: "",
      scenarioId: "",
      name: organizationBusinessId,
      businessId: organizationBusinessId,
      types: []
    };
    return organizationDto;
  } else {
    return null;
  }
}

function showDetails(data: CostCatalogueItemDto) {
  $emits("rowSelected", data);
}

function onRowSelected(event: RowSelectedEvent<CostCatalogueItemDto>) {
  const rows = getSelectedRows(event.api);
  if (rows.length !== 1) {
    isEditBtnDisabled.value = true;
    return;
  }
}

const gridOptions: GridOptions = {
  processCellForClipboard: (params: ProcessCellForExportParams) => {
    const { value, column } = params;

    if (column.getColDef().type === "resourcePickerTypeColumn") {
      return params.formatValue(value);
    }

    return value;
  }
};

function onPrepareColumns(columnDefs: any) {
  columnDefs.value = [
    {
      field: "id",
      headerValueGetter: (_: any) => $t("costCatalogue-list-id-label", { $: "Id" }),
      editable: false,
      sortable: false,
      filter: false,
      hide: true
    },
    {
      field: "resourceBusinessId",
      type: ["resourcePickerTypeColumn", "textFloatingFilterColumnType"],
      headerValueGetter: (_: any) => $t("costCatalogue-list-resourceBusinessId-label", { $: "Resource Business Id" }),
      editable: true,
      sortable: true,
      filter: "agTextColumnFilter",
      cellEditorParams: {
        placeholder: $t("costCatalogue-edit-resourceBusinessId-label", { $: "Resource Business Id" }),
        rules: [requiredRule],
        isEditEnabled: () => true
      },
      valueFormatter: (params: ValueFormatterParams) => {
        const resourceInfo: ResourceInfoDto = params.value;
        if (resourceInfo) {
          const result = nameOrBusinessIdOrIdOrNull(resourceInfo);
          return result ?? "";
        }
        const data = params.data;
        return data?.resourceBusinessId ?? nonEmptyGuidOrNull(data?.resourceId) ?? null;
      },
      valueSetter: (params: ValueSetterParams) => {
        const resouceInfo: ResourceInfoDto = params.newValue;
        params.data.resourceBusinessId = resouceInfo?.businessId;
        return true;
      },
      valueGetter: (params: ValueGetterParams) => {
        const resourceInfo = mapToOrganizationDto(params.data.resourceBusinessId);
        return resourceInfo;
      }
    },
    {
      // This column is hidden, because it's used for filtering only (filterGridActionRef)
      field: "resourceType",
      hide: true,
      headerValueGetter: (_: any) => $t("costCatalogue-list-resourceType-label", { $: "Resource Type" }),
      editable: false,
      sortable: true,
      filter: "agSetColumnFilter",
      filterParams: {
        valueFormatter: (params: ValueFormatterParams) => params.value.key,
        keyCreator: (params: KeyCreatorParams) => params.value.value,
        values: translateEditorEntries(enumToEditorEntries(ResourceType), translateResourceType),
        comparator: enumValueEntryWithLocaleComparator
      },
      valueFormatter: (params: any) => translateResourceType(params.value)
    },
    {
      field: "manufacturer",
      type: ["organizationPickerTypeColumn", "textFloatingFilterColumnType"],
      headerValueGetter: (_: any) => $t("costCatalogue-list-manufacturer-label", { $: "Manufacturer" }),
      editable: true,
      sortable: true,
      filter: "agTextColumnFilter",
      cellEditorParams: {
        placeholder: $t("costCatalogue-edit-manufacturer-label", { $: "Manufacturer" })
      },
      valueFormatter: (params: ValueFormatterParams<CostCatalogueItemDto, CostCatalogueItemDto>) => {
        return params.data?.manufacturer;
      },
      valueSetter: (params: ValueSetterParams<CostCatalogueItemDto, OrganizationDto>) => {
        params.data.manufacturer = params.newValue?.businessId ?? "";
        return true;
      },
      valueGetter: (params: ValueGetterParams<CostCatalogueItemDto, OrganizationDto>) => {
        const { data } = params;
        if (!data || !data.manufacturer) return null;

        return {
          name: data?.manufacturer,
          businessId: data?.manufacturer
        } as OrganizationDto;
      }
    },
    {
      field: "name",
      type: ["textInputTypeColumn", "textFloatingFilterColumnType"],
      editable: true,
      sortable: true,
      filter: "agTextColumnFilter",
      headerValueGetter: (_: any) => $t("costCatalogue-list-name-label", { $: "Name" }),
      cellEditorParams: {
        rules: [],
        placeholder: $t("costCatalogue-list-name-label", { $: "Name" }),
        isEditEnabled: () => true
      },
      floatingFilterComponentParams: {
        placeholder: $t("costCatalogue-list-name-label", { $: "Name" })
      }
    },
    {
      field: "pricePerQuantity",
      sortable: true,
      editable: true,
      filter: "agNumberColumnFilter",
      resizable: true,
      type: ["textInputTypeColumn", "textFloatingFilterColumnType"],
      cellEditorParams: {
        rules: [requiredRule],
        placeholder: $t("costCatalogue-list-pricePerQuantity-label", { $: "Price-Per-Quantity" })
      },
      headerValueGetter: (_: any) => $t("costCatalogue-list-pricePerQuantity-label", { $: "Price-Per-Quantity" })
    },
    {
      field: "currencyCode",
      type: ["enumTypeColumn", "setFloatingFilterColumnType"],
      headerValueGetter: (_: any) => $t("costCatalogue-list-currencyCode-label", { $: "Currency" }),
      valueFormatter: (params: ValueFormatterParams) => translateCurrencyCode(params.data.currencyCode),
      editable: true,
      sortable: true,
      cellEditorParams: {
        placeholder: $t("costCatalogue-list-currencyCode-label", { $: "Currency" }),
        values: translateEditorEntries(enumToEditorEntries(CurrencyCodes), translateCurrencyCode)
      } as unknown as IEnumValueSelectCellEditorParams,
      filter: "agSetColumnFilter",
      filterParams: {
        valueFormatter: (params: ValueFormatterParams) => params.value.key,
        keyCreator: (params: KeyCreatorParams) => params.value.value,
        values: translateEditorEntries(enumToEditorEntries(CurrencyCodes), translateCurrencyCode),
        comparator: enumValueEntryWithLocaleComparator
      }
    },
    {
      field: "validFrom",
      type: ["datepickerTypeColumn", "dateTimeTypeColumn"],
      headerValueGetter: (_: any) => $t("costCatalogue-list-validFrom-label", { $: "Valid From" }),
      editable: true,
      resizable: true,
      sortable: true,
      filter: "agDateColumnFilter",
      valueFormatter: (params: any) => {
        return $dateTimeFormatter(params.data.validFrom);
      },
      cellEditorParams: {
        valueRef: () => periodStartEditorRef,
        rules: [periodStartBeforeEndRequiredRule],
        clearOnEditDisabled: true
      } as IControlledCellEditorParams
    },
    {
      field: "validTo",
      type: ["datepickerTypeColumn", "dateTimeTypeColumn"],
      headerValueGetter: (_: any) => $t("costCatalogue-list-validTo-label", { $: "Valid To" }),
      editable: true,
      resizable: true,
      sortable: true,
      filter: "agDateColumnFilter",
      valueFormatter: (params: any) => {
        return $dateTimeFormatter(params.data.validTo);
      },
      cellEditorParams: {
        valueRef: () => periodEndEditorRef,
        rules: [periodStartBeforeEndRequiredRule],
        clearOnEditDisabled: true
      } as IControlledCellEditorParams
    },
    {
      field: "businessId",
      editable: true,
      sortable: true,
      filter: "agTextColumnFilter",
      headerValueGetter: (_: any) => $t("costCatalogue-list-businessId-label", { $: "Business ID" }),
      type: ["textInputTypeColumn", "textFloatingFilterColumnType"],
      cellEditorParams: {
        rules: [],
        placeholder: $t("costCatalogue-list-businessId-label", { $: "Business ID" }),
        isEditEnabled: () => true
      },
      floatingFilterComponentParams: {
        placeholder: $t("costCatalogue-list-businessId-label", { $: "Business ID" })
      }
    },
    {
      field: "providerBusinessId",
      editable: (params: any) => true,
      sortable: true,
      resizable: true,
      filter: "agTextColumnFilter",
      type: ["organizationPickerTypeColumn", "textFloatingFilterColumnType"],
      cellEditorParams: {
        placeholder: $t("costCatalogue-list-providerName-label", { $: "Provider" })
      },
      valueGetter: (params: ValueGetterParams<CostCatalogueItemDto, OrganizationDto>) => {
        const { data } = params;
        if (!data || !data.providerBusinessId) return null;

        return {
          name: data?.providerBusinessId,
          businessId: data?.providerBusinessId
        } as OrganizationDto;
      },
      valueSetter: (params: ValueSetterParams<CostCatalogueItemDto, OrganizationDto>) => {
        params.data.providerBusinessId = params.newValue?.businessId ?? "";
        return true;
      },
      valueFormatter: (params: ValueFormatterParams<CostCatalogueItemDto, CostCatalogueItemDto>) => {
        return params.data?.providerBusinessId;
      },
      headerValueGetter: (_: any) => $t("costCatalogue-list-providerName-label", { $: "Provider" })
    },
    {
      field: "quantityPerPrice",
      sortable: true,
      editable: true,
      filter: "agNumberColumnFilter",
      resizable: true,
      type: ["textInputTypeColumn", "textFloatingFilterColumnType"],
      cellEditorParams: {
        rules: [requiredRule],
        placeholder: $t("costCatalogue-list-quantityPerPrice-label", { $: "Quantity Per Price" })
      },
      headerValueGetter: (_: any) => $t("costCatalogue-list-quantityPerPrice-label", { $: "Quantity Per Price" })
    },
    {
      field: "quantityPerPriceUnit",
      type: ["enumTypeColumn", "setFloatingFilterColumnType"],
      editable: true,
      sortable: true,
      cellEditorParams: (params: any) => {
        const isResourceBookable = isBookable(params.data);
        const options = isResourceBookable
          ? enumToEditorEntriesOnlyIncluding(MeasurementUnit, [MeasurementUnit.WorkHours])
          : translateEditorEntries(enumToEditorEntries(MeasurementUnit), translateMeasurementUnit);

        return {
          values: options,
          placeholder: $t("costCatalogue-list-quantityPerPriceUnit-label", { $: "Quantity-Per-Price Unit" })
        };
      },
      valueFormatter: (params: any) => translateMeasurementUnit(params.data.quantityPerPriceUnit),
      headerValueGetter: (_: any) => $t("costCatalogue-list-quantityPerPriceUnit-label", { $: "Quantity-Per-Price Unit" }),
      filter: "agSetColumnFilter",
      filterParams: {
        valueFormatter: (params: ValueFormatterParams) => params.value.key,
        keyCreator: (params: KeyCreatorParams) => params.value.value,
        values: translateEditorEntries(enumToEditorEntries(MeasurementUnit), translateMeasurementUnit),
        comparator: enumValueEntryWithLocaleComparator
      }
    },
    {
      field: "measurementUnit",
      type: ["enumTypeColumn", "setFloatingFilterColumnType"],
      editable: false,
      sortable: true,
      cellEditorParams: (params: any) => {
        const isResourceBookable = isBookable(params.data);
        const options = isResourceBookable
          ? enumToEditorEntriesOnlyIncluding(MeasurementUnit, [MeasurementUnit.WorkHours])
          : translateEditorEntries(enumToEditorEntries(MeasurementUnit), translateMeasurementUnit);

        return {
          values: options,
          placeholder: $t("costCatalogue-list-measurementUnit-label", { $: "Measurement Unit" })
        };
      },
      valueFormatter: (params: any) => translateMeasurementUnit(params.data.measurementUnit),
      headerValueGetter: (_: any) => $t("costCatalogue-list-measurementUnit-label", { $: "Measurement Unit" }),
      filter: "agSetColumnFilter",
      filterParams: {
        valueFormatter: (params: ValueFormatterParams) => params.value.key,
        keyCreator: (params: KeyCreatorParams) => params.value.value,
        values: translateEditorEntries(enumToEditorEntries(MeasurementUnit), translateMeasurementUnit),
        comparator: enumValueEntryWithLocaleComparator
      }
    },
    {
      field: "orderableUnit",
      type: ["enumTypeColumn", "setFloatingFilterColumnType"],
      headerValueGetter: (_: any) => $t("costCatalogue-list-orderableUnit-label", { $: "Orderable Unit" }),
      editable: true,
      sortable: true,
      valueFormatter: (params: any) => translateMeasurementUnit(params.data.orderableUnit),
      cellEditorParams: {
        values: translateEditorEntries(enumToEditorEntries(MeasurementUnit), translateMeasurementUnit),
        placeholder: $t("costCatalogue-list-orderableUnit-label", { $: "Orderable Unit" })
      },
      filter: "agSetColumnFilter",
      filterParams: {
        valueFormatter: (params: ValueFormatterParams) => params.value.key,
        keyCreator: (params: KeyCreatorParams) => params.value.value,
        values: translateEditorEntries(enumToEditorEntries(MeasurementUnit), translateMeasurementUnit),
        comparator: enumValueEntryWithLocaleComparator
      }
    },
    {
      field: "packSize",
      sortable: true,
      editable: true,
      filter: "agNumberColumnFilter",
      resizable: true,
      type: ["textInputTypeColumn", "textFloatingFilterColumnType"],
      cellEditorParams: {
        placeholder: $t("costCatalogue-list-packSize-label", { $: "Pack size" })
      },
      headerValueGetter: (_: any) => $t("costCatalogue-list-packSize-label", { $: "Pack size" })
    },
    {
      field: "packaging",
      type: ["textInputTypeColumn", "textFloatingFilterColumnType"],
      editable: true,
      sortable: true,
      filter: "agTextColumnFilter",
      headerValueGetter: (_: any) => $t("costCatalogue-list-packaging-label", { $: "Packaging" }),
      cellEditorParams: {
        rules: [],
        placeholder: $t("costCatalogue-list-packaging-label", { $: "Packaging" }),
        isEditEnabled: () => true
      },
      floatingFilterComponentParams: {
        placeholder: $t("costCatalogue-list-packaging-label", { $: "Packaging" })
      }
    },
    {
      field: "minimumNumberOfPackagesToOrder",
      sortable: true,
      editable: true,
      filter: "agNumberColumnFilter",
      resizable: true,
      type: ["textInputTypeColumn", "textFloatingFilterColumnType"],
      cellEditorParams: {
        placeholder: $t("costCatalogue-list-minimumNumberOfPackagesToOrder-label", { $: "Min. Number Of Packages To Order" })
      },
      headerValueGetter: (_: any) => $t("costCatalogue-list-minimumNumberOfPackagesToOrder-label", { $: "Min. Number Of Packages To Order" })
    },
    {
      field: "minimumOrderableQuantity",
      sortable: true,
      editable: true,
      filter: "agNumberColumnFilter",
      resizable: true,
      type: ["textInputTypeColumn", "textFloatingFilterColumnType"],
      cellEditorParams: {
        placeholder: $t("costCatalogue-list-minimumOrderableQuantity-label", { $: "Min. Orderable Qty" })
      },
      headerValueGetter: (_: any) => $t("costCatalogue-list-minimumOrderableQuantity-label", { $: "Min. Orderable Qty" })
    }
  ];
}

function getContextMenuItems(param: any) {
  return [];
}

async function generateCostCatalogue() {
  await ApiService.costCatalogue.generate({ scenarioId: scenariosStore.selectedScenario?.id } as GenerateCostCatalogueCommand);
  gridWrapperRef.value?.crudActions?.onRefreshAction();
}

const filterGridAction = useFilterGridAction({ filterKey: "resourceType", gridWrapperRef: gridWrapperRef, filterGridActionRef: filterGridActionRef });

const filterGridActionItems: FilterGridActionItem[] = [
  { value: [ResourceType.Person, ResourceType.PersonGroup], text: translateResourceType(ResourceType.Person) },
  { value: [ResourceType.Agreement, ResourceType.AgreementGroup], text: translateResourceType(ResourceType.Agreement) },
  { value: ResourceType.Calendar, text: translateResourceType(ResourceType.Calendar) },
  { value: [ResourceType.Equipment, ResourceType.EquipmentGroup], text: translateResourceType(ResourceType.Equipment) },
  { value: ResourceType.Material, text: translateResourceType(ResourceType.Material) },
  { value: ResourceType.Location, text: translateResourceType(ResourceType.Location) },
  { value: ResourceType.Asset, text: translateResourceType(ResourceType.Asset) },
  { value: ResourceType.Service, text: translateResourceType(ResourceType.Service) }
];

const refGrid = useReferenceGrid({
  $emits,
  isRowSelected: (data: any) => data.id === props.referenceValue,
  agGridFilterModel: props.agGridFilterModel
});

function isBookable(costCatalogueItemDto: CostCatalogueItemDto): boolean {
  return costCatalogueItemDto.resourceType !== ResourceType.Material;
}
</script>

<template>
  <grid-wrapper
    ref="gridWrapperRef"
    :create-btn="!readonly"
    :duplicate-btn="!readonly"
    :edit-btn="!readonly"
    :delete-btn="!readonly"
    :refresh-btn="!readonly"
    :details-btn="!readonly"
    :row-selection="rowSelection ?? 'multiple'"
    identifier="costCatalogue"
    :grid-options="gridOptions"
    :default-col-def="defaultColumnDef"
    :context-menu-items="getContextMenuItems"
    server-side
    :server-side-datasource="serverSideDataSource"
    :create-default-value="DEFAULT_CREATE_VALUE"
    @prepare-columns="onPrepareColumns"
    @ready="refGrid.onGridReady"
    @row-selected="onRowSelected"
    @filter-changed="filterGridAction.onFilterGridModelChanged"
    @details="showDetails"
  >
    <template v-if="!readonly" #custom-buttons>
      <div class="d-inline-flex pr-4">
        <v-btn size="small" variant="text" density="compact" @click="generateCostCatalogue">
          {{ $t("costCatalogue-button-generateCostCatalogue-action", { $: "Generate cost catalogue" }) }}
        </v-btn>
      </div>
    </template>
    <template v-if="!readonly" #filter>
      <filter-grid-action ref="filterGridActionRef" :items="filterGridActionItems" @filter-changed="filterGridAction.onFilterGridActionChanged" />
    </template>
  </grid-wrapper>
</template>

<style lang="scss" scoped></style>
