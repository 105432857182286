<script setup lang="ts">
import { onMounted, ref } from "vue";
import ApiService from "@/services/api";
import { useNotification, UserNoticeCreatedOrChangedNotification } from "@/notifications";
import { UserNoticeCreatedOrChangedNotificationEvent } from "@masta/generated-model";
import { useDebounceFn } from "@vueuse/core/index";
import { useAuthStore } from "@/store/AuthStore";
import UserNoticesDialog from "@/components/UserNotices/UserNoticesDialog.vue";
import { $t } from "@/i18n";
import { useSnackbarsStore } from "@/store/SnackbarsStore";

const noticeCount = ref<number>(0);
const authStore = useAuthStore();
const snackbarsStore = useSnackbarsStore();

const showUserNoticesDialog = ref(false);


const emit = defineEmits<{
  (e: "openNotices"): void;
}>();

const fetchNoticeCount = async () => {
  try {
    const count = await ApiService.userNotices.getUnactionedCount();
    const oldNoticeCount = noticeCount.value;
    noticeCount.value = count.data;
    if (count.data > oldNoticeCount) {
      await snackbarsStore.createSnackbar({
        message: $t("userNotice-button-onNoticeFetched-message", {$: "Check user notifications"}),
        type: "success",
        closeable: true
      });
    }
  } catch (e) {
    console.error(e);
  }
};

onMounted(() => {
  fetchNoticeCount();
});

const openNotices = () => {
  showUserNoticesDialog.value = true;
  emit("openNotices");
};

useNotification(UserNoticeCreatedOrChangedNotification, (e: UserNoticeCreatedOrChangedNotificationEvent) => {
  onUserNoticeCreatedOrChanged(e);
});

const onUserNoticeCreatedOrChanged: (e: UserNoticeCreatedOrChangedNotificationEvent) => void = useDebounceFn(async (e: UserNoticeCreatedOrChangedNotificationEvent) => {
  if (e.userId === authStore.user.sub) {
    await fetchNoticeCount();
  }
}, 500);

</script>

<template>
  <v-btn
    color="primary"
    fab
    icon
    variant="plain"
    @click="openNotices"
  >
    <v-icon>mdi-bell</v-icon>
    <v-badge
      v-if="noticeCount > 0"
      :content="noticeCount"
      color="red"
      overlap
      offset-x="0"
      offset-y="-12"
    ></v-badge>
  </v-btn>

  <user-notices-dialog v-model="showUserNoticesDialog" />
</template>

<style>
</style>
