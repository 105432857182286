export default {
    data: () => ({
      theme: "light"
    }),
    mounted() {
      let theme = localStorage.getItem("theme");
      if (!theme) {
        theme = this.$vuetify.theme.name;
        localStorage.setItem("theme", theme ? theme : "light");
      }
      this.theme = theme;
      this.$vuetify.theme.global.name = this.theme;
    },
    computed: {
      isDarkTheme() { 
        return this.theme === 'dark'
      },
      isLightTheme() {
        return this.theme === 'light'
      }
    },
    methods: {
      switchMode() {
        const newTheme = this.theme === "light" ? "dark" : "light";
        this.doThemeSwitch(newTheme);
      },
      doThemeSwitch(theme) {
        this.theme = theme
        localStorage.setItem("theme", this.theme);
        this.$vuetify.theme.global.name = this.theme;
        this.$emit("switch");
      }
    }
  };