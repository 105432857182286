export const IocSymbols = {
  Context: Symbol("Context"),
  GanttWorkerInstanceSymbol: Symbol("GanttWorkerInstance"),
  GanttWorkerSymbol: Symbol("GanttWorker"),
  DataLoader: Symbol("DataLoaderSymbol"),
  GanttDataLoaderSymbol: Symbol("GanttDataLoader"),
  HtmlContainerSymbol: Symbol("HtmlContainer"),
  GanttOptionsSymbol: Symbol("GanttOptions"),
  RowFactoryIocExtension: Symbol("RowFactoryIocExtension"),
  /**
   * @deprecated Use TimelineManager instead
   */
  TimelineSymbol: Symbol("Timeline"),
  LayersSymbol: Symbol("Layers"),
  RowsSymbol: Symbol("Rows"),
  RowFactorySymbol: Symbol("RowFactory"),
  GanttSettingsLoader: Symbol("GanttSettingsLoaderSymbol"),
  ActivityFactory: Symbol("ActivityFactorySymbol"),
  LayerManager: Symbol("LayerManagerSymbol"),
  RowLayerManager: Symbol("RowLayerManagerSymbol"),
  RowContainer: Symbol("RowContainerFactory"),
  RowContainerFactorySymbol: Symbol("RowContainerFactory"),
  RowContainerIocContainerSymbol: Symbol("RowContainerIocContainer"),
  LinesManagerSymbol: Symbol("LinesManager"),
  LinesManagerClass: Symbol("LinesManagerClass"),
  LinesManagerFactory: Symbol("LinesManagerFactorySymbol"),
  AboveRowContentSystemLayersSymbol: Symbol("AboveRowContentSystemLayers"),
  BelowRowContentSystemLayersSymbol: Symbol("BelowRowContentSystemLayers"),
  RowBackgroundSystemLayersSymbol: Symbol("RowBackgroundSystemLayers"),
  RowForegroundSystemLayersSymbol: Symbol("RowForegroundSystemLayersSymbol"),
  Calendars: Symbol("CalendarsSymbol"),
  ActivityRenderer: Symbol("ActivityRendererSymbol"),
  ActivityRendererTag: Symbol("ActivityRendererTagSymbol"),
  ActivityTooltipRenderer: Symbol("ActivityTooltipRendererSymbol"),
  ActivityTooltipRendererTag: Symbol("ActivityTooltipRendererTagSymbol"),
  SystemLayerTag: Symbol("SystemLayerTag"),
  RowChartScaleProviderClass: Symbol("RowChartScaleProviderClassSymbol"),
  RowChartScaleProvider: Symbol("RowChartScaleProviderSymbol"),
  RowInfoColumnProvider: Symbol("RowInfoColumnProviderSymbol"),
  RowInfoColumnCellRegistry: Symbol("RowInfoColumnCellRegistrySymbol"),
  RowInfoCell: Symbol("RowInfoCellSymbol"),
  RowInfoColumnRenderer: Symbol("RowInfoColumnRendererSymbol"),
  DragAndDropDataStore: Symbol("DragAndDropDataStoreSymbol"),
  ContextMenuProvider: Symbol("ContextMenuProviderSymbol"),
  NavigationScheme: Symbol("NavigationSchemeSymbol"),
  GanttActionManager: Symbol("GanttActionManagerSymbol")
};
