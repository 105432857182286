<script lang="ts" setup>
import { QualitativeFeatureDefinition } from "@masta/generated-model";
import { $t } from "@/i18n";
import { requiredNotEmptyArrayRule, requiredRule } from "@/components/ValueCellEditor/CommonValidationRules";

const props = defineProps<{
  modelValue: QualitativeFeatureDefinition;
  disabled: boolean;
}>();

const emit = defineEmits(["update:modelValue", "remove"]);

function update(propertyName: string, value: any) {
  const newFeature = { ...props.modelValue, [propertyName]: value };
  emit("update:modelValue", newFeature);
}

function isMatchingValueRule(value: any) {
  if (!value || value.length === 0) {
    return true;
  }
  if (!props.modelValue.values || !props.modelValue.values.includes(value)) {
    return $t("featureDefinition-qualitativeEditor-onMatchingValueNotInEnum-message", { $: "Matching value must be from defined values" });
  }
  return true;
}
</script>

<template>
  <v-card outlined width="100%">
    <v-card-text>
      <v-col cols="12">
        <!-- First Row: Close Button and Chip -->
        <v-row>
          <v-col cols="4" class="text-center d-flex align-center justify-center">
            <v-chip size="x-large" label class="w-100" color="primary" variant="tonal" :disabled="disabled">
              {{ $t("featureDefinition-qualitativeEditor-qualitative-label", { $: "Qualitative" }) }}
            </v-chip>
          </v-col>
          <v-col cols="8" class="text-right align-center justify-center">
            <v-btn
              variant="text"
              icon="mdi-close"
              size="large"
              hide-details
              :disabled="disabled"
              @click="emit('remove')"
            ></v-btn>
          </v-col>
        </v-row>
        <!-- Second Row: Name, Label, and Description -->
        <v-row>
          <v-col cols="4">
            <v-text-field
              variant="outlined"
              density="compact"
              hide-details
              :rules="[requiredRule]"
              :label="$t('featureDefinition-qualitativeEditor-name-label', { $: 'Name' })"
              :model-value="modelValue.name"
              :disabled="disabled"
              @update:model-value="update('name', $event)"
            />
          </v-col>
          <v-col cols="4">
            <v-text-field
              variant="outlined"
              density="compact"
              hide-details
              :label="$t('featureDefinition-qualitativeEditor-label-label', { $: 'Label' })"
              :model-value="modelValue.label"
              :disabled="disabled"
              @update:model-value="update('label', $event)"
            />
          </v-col>
          <v-col cols="4">
            <v-text-field
              variant="outlined"
              density="compact"
              hide-details
              :label="$t('featureDefinition-qualitativeEditor-description-label', { $: 'Description' })"
              :model-value="modelValue.description"
              :disabled="disabled"
              @update:model-value="update('description', $event)"
            />
          </v-col>
        </v-row>
        <!-- Third Row: Required Switch, Enum Values, and Matching Value -->
        <v-row>
          <v-col cols="2">
            <v-switch
              variant="outlined"
              density="compact"
              hide-details
              color="primary"
              :label="$t('featureDefinition-qualitativeEditor-required-label', { $: 'Required' })"
              :model-value="modelValue.isRequired"
              :disabled="disabled"
              @update:model-value="update('isRequired', $event)"
            />
          </v-col>
          <v-col cols="5">
            <div class="d-flex justify-start">
              <v-combobox
                variant="outlined"
                density="compact"
                hide-details
                class="mx-2"
                :rules="[requiredNotEmptyArrayRule]"
                :model-value="modelValue.values"
                :disabled="disabled"
                :items="modelValue.values"
                chips
                clearable
                :label="$t('featureDefinition-qualitativeEditor-enumValues-label', { $: 'Enum Values' })"
                multiple
                solo
                @update:model-value="update('values', $event)"
              >
                <template #selection="{ attrs, item, selected }">
                  <v-chip v-bind="attrs" :input-value="selected" close color="primary">
                    <strong>{{ item }}</strong>
                    &nbsp;
                    <span>{{ $t("featureDefinition-qualitativeEditor-interest-label", { $: "(interest)" }) }}</span>
                  </v-chip>
                </template>
              </v-combobox>
            </div>
          </v-col>
          <v-col cols="5">
            <v-text-field
              variant="outlined"
              density="compact"
              hide-details
              :rules="[isMatchingValueRule]"
              :label="$t('featureDefinition-qualitativeEditor-matchingValue-label', { $: 'Matching Value' })"
              :model-value="modelValue.matchingValue"
              :disabled="disabled"
              @update:model-value="update('matchingValue', $event)"
            />
          </v-col>
        </v-row>
      </v-col>
    </v-card-text>
  </v-card>
</template>

