import { injectable, multiInject, optional } from "inversify";
import { GanttDomElement } from "../GanttDomElement";
import { IocSymbols } from "../../Core";
import { RowLayerCanvasWithWebWorker } from "./Row";

@injectable()
export class BelowRowsContentCanvasContainer extends GanttDomElement<HTMLDivElement> {
  constructor(@multiInject(IocSymbols.BelowRowContentSystemLayersSymbol) @optional() private readonly _belowRowContentSystemLayers: RowLayerCanvasWithWebWorker[] = []) {
    super(BelowRowsContentCanvasContainer.name, undefined, "below-rows-content-canvas-container");
  }

  async beforeInitialize(): Promise<void> {
    await this.addChildren(this._belowRowContentSystemLayers);
  }
}
