import ApiService from "@/services/api";

class CqlTransformer {
  constructor(filterModel) {
    this.filterModel = filterModel;
  }

  transform() {
    let parts = [];
    for (const [key, value] of Object.entries(this.filterModel)) {
      const cql = this.getCql(this.upperCaseFirstLetter(key), value);
      parts.push(cql);
    }
    return parts.join(" and ").trim();
  }

  upperCaseFirstLetter(name) {
    return name.charAt(0).toUpperCase() + name.slice(1);
  }

  getCql(key, model) {
    const operator = this.getOperator(model);
    if (this.isLogicalOperation(model)) {
      const leftPart = this.getCql(key, model.condition1);
      const rightPart = this.getCql(key, model.condition2);
      return `(${leftPart} ${operator} ${rightPart})`;
    } else {
      let value = null;
      switch (model.filterType) {
        case "text":
          value = `'${model.filter}'`;
          break;
        case "number":
          value = +model.filter;
          break;
      }
      return `${key} ${operator} ${value}`;
    }
  }

  isLogicalOperation(model) {
    return model && model.operator && model.condition1 && model.condition2;
  }

  getOperator(model) {
    if (model.operator) {
      switch (model.operator) {
        case "OR":
          return "or";
        case "AND":
          return "and";
      }
      throw new Error(`Unknown operator: ${model.operator}`);
    }
    switch (model.type) {
      case "contains":
        return "contains";
      case "notContains":
        return "notContains";
      case "equals":
        return "=";
      case "notEqual":
        return "!=";
      case "startsWith":
        return "startsWith";
      case "endsWith":
        return "endsWith";
      case "lessThan":
        return "<";
      case "lessThanOrEqual":
        return "<=";
      case "greaterThan":
        return ">";
      case "greaterThanOrEqual":
        return ">=";
      case "inRange":
        return "between";
    }
    throw new Error(`Unknown operator: ${model.type}`);
  }
}

const RESOURCE_FILTER_KEY = "resources.list.filters";

export default {
  async getResourceFilters() {
    const result = await ApiService.filters.getFilters(RESOURCE_FILTER_KEY);
    // console.log(result);
    if (!result) {
      return [];
    }
    return result.data.list;
  },
  createResourceFilter(filter) {
    const request = {
      key: RESOURCE_FILTER_KEY,
      name: filter.name,
      cql: filter.cql
    };
    return ApiService.filters.createFilter(request);
  },
  async validateResourceFilter(cql) {
    const request = {
      cql: cql
    };
    const { data } = await ApiService.filters.validateCqlExpressions(request);
    return data;
  },
  translate2Cql(filterModel) {
    return new CqlTransformer(filterModel).transform();
  }
};
