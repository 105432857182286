import { JSONSchema7, JSONSchema7Definition } from "json-schema";
import {
  createEmptySchemaModel,
  SchemaModel,
  SchemaObjectPropertyModel,
  SchemaTypeEnumModel,
  SchemaTypeModel,
  SchemaTypeNumberModel,
  SchemaTypeStringModel
} from "@/components/ModelInstances/SchemaBuilder/SchemaModel";

function getPropValueModel(property: JSONSchema7): SchemaTypeModel {
  if (property.enum) {
    return getEnumModel(property);
  }
  switch (property.type) {
    case "string":
      return getStringModel(property);
    case "number":
      return getNumberModel(property);
    case "boolean":
      return getBooleanModel(property);
    case "array":
      return getMultiEnumModel(property);
  }
  throw new Error(`Property not supported: ${property}`);
}

function getStringModel(property: JSONSchema7): SchemaTypeStringModel {
  return {
    type: "string",
    minLength: property.minLength ? property.minLength : null,
    maxLength: property.maxLength ? property.maxLength : null,
    pattern: property.pattern ? property.pattern : "",
    icon: property.icon ? property.icon : "",
    color: property.color ? property.color : "",
  };
}

function getNumberModel(property: JSONSchema7): SchemaTypeNumberModel {
  return {
    type: "number",
    minimum: property.minimum,
    maximum: property.maximum,
    exclusiveMinimum: property.exclusiveMinimum,
    exclusiveMaximum: property.exclusiveMaximum,
    multipleOf: property.multipleOf,
    icon: property.icon ? property.icon : "",
    color: property.color ? property.color : "",
  };
}

function getBooleanModel(property: JSONSchema7): SchemaTypeModel {
  return { 
    type: "boolean",
    icon: property.icon ? property.icon : "",
    color: property.color ? property.color : "",
  };
}

function getEnumModel(property: JSONSchema7): SchemaTypeEnumModel {
  if (!property.enum) {
    throw new Error();
  }
  const items = [];

  for (const item of property.enum) {
    if (typeof item === "string") {
      items.push(item);
    } else {
      throw new Error("Enum does not support ${item}");
    }
  }
  return { 
    type: "enum", 
    items: items,
    multiple: false,
    icon: property.icon ? property.icon : "",
    color: property.color ? property.color : "",
  };
}

function getMultiEnumModel(property: JSONSchema7): SchemaTypeEnumModel {
  if (property.items && !property?.items?.enum && property.items.type !== "string") {
    throw new Error();
  }
  const items = [];

  for (const item of property.items.enum) {
    if (typeof item === "string") {
      items.push(item);
    } else {
      throw new Error("Enum does not support ${item}");
    }
  }
  return { 
    type: "enum", 
    items: items, 
    multiple: true,
    icon: property.icon ? property.icon : "",
    color: property.color ? property.color : "",
  };
}

function getPropertiesModel(properties: { [key: string]: JSONSchema7Definition }): SchemaObjectPropertyModel[] {
  const props = [];
  for (const name in properties) {
    const propValue = properties[name];
    if (propValue === true || propValue === false) {
      throw new Error("'properties: true' not supported");
    }
    const propModel = {
      name: name,
      value: getPropValueModel(propValue)
    };
    props.push(propModel);
  }

  return props;
}

export function deserializeSchemaModel(schema: string): SchemaModel {
  if (!schema) {
    return createEmptySchemaModel();
  }
  const schemaObj = JSON.parse(schema) as JSONSchema7;
  if (
    schemaObj.$schema !== "https://json-schema.org/draft/2020-12/schema" ||
    schemaObj.$id !== "https://example.com/test.schema.json" ||
    schemaObj.type !== "object" ||
    (schemaObj.title === undefined && schemaObj.title === null) ||
    !schemaObj.properties
  ) {
    throw new Error("Not supported schema");
  }
  const model = {
    title: schemaObj.title,
    description: schemaObj.description,
    type: schemaObj.type,
    properties: getPropertiesModel(schemaObj.properties)
  };
  return model;
}
