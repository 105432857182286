import { inject, injectable } from "inversify";
import { GanttDomElement } from "../../../GanttDomElement";
import { IRowInfoCell, IRowInfoColumn, Row } from "../../../../Core";
import { Promise } from "cypress/types/cy-bluebird";

@injectable()
export class RowNameRowInfoTemplateCell<TResource extends Row<any, any, any>> extends GanttDomElement<HTMLDivElement> implements IRowInfoCell {

  readonly cellId = "text";
  protected _column: IRowInfoColumn;
  protected _spanElement: HTMLSpanElement;

  constructor(@inject(Row<any, any, any>) protected _row: TResource) {
    super(`${RowNameRowInfoTemplateCell.name}-${_row.id}`, undefined, "cell name");

    this._spanElement = document.createElement("span");
  }

  async afterInitialize() {
    await super.afterInitialize();

    this.element.appendChild(this._spanElement);

    this._spanElement.addEventListener("click", this.onClick.bind(this));
  }

  async destroy() {
    this._spanElement.removeEventListener("click", this.onClick.bind(this));
    await super.destroy();
  }

  async doDrawFromBatch() {
    const collapseBtnOffset = this._row.childrenIds.length > 0 ? (this._row.levelNumber + 1) * 26 : 0;
    this.element.style.width = `${(this._column.width! - collapseBtnOffset)}px`;
    const paddingLeft = this._row.childrenIds.length === 0 ? (this._row.levelNumber + 1) * 26 : 0;
    this.element.style.paddingLeft = `${paddingLeft}px`;
    this._spanElement.innerText = this._row.name;
  }

  setColumn(column: IRowInfoColumn): void {
    this._column = column;
  }

  private onClick(_: MouseEvent) {
    const e = new CustomEvent("rowNameClick", { detail: { row: this._row }, bubbles: true, cancelable: true, composed: false });
    this.element.dispatchEvent(e);
  }
}
