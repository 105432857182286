﻿import type { IRowContainer } from "@masta/gantt2/core";
import { ActivityBounds, ActivityPosition, ActivityRef, ActivityRenderer, IActivity, IActivityRenderingRequest, IocSymbols, TimelineManager } from "@masta/gantt2/core";
import { inject, injectable } from "inversify";

@injectable()
export class ShiftCalendarActivityRenderer<TActivity extends IActivity> extends ActivityRenderer<TActivity> {
  private _activityHeight = 4;

  constructor(@inject(TimelineManager) timelineManager: TimelineManager, @inject(IocSymbols.RowContainer) rowContainer: IRowContainer) {
    super(timelineManager, rowContainer, ShiftCalendarActivityRenderer.name);
  }

  draw(
    activityRef: ActivityRef<TActivity>,
    position: ActivityPosition,
    rowCanvasContext: CanvasRenderingContext2D | OffscreenCanvasRenderingContext2D,
    x: number,
    y: number,
    w: number,
    h: number,
    offsetTop: number,
    selected: boolean,
    hover: boolean,
    highlighted: boolean,
    pressed: boolean
  ): ActivityBounds | undefined {
    if (w <= this._minWidth) return;

    y = y + h - this._activityHeight;
    h = this._activityHeight;

    return super.draw(activityRef, position, rowCanvasContext, x, y, w, h, offsetTop, selected, hover, highlighted, pressed);
  }

  drawActivity(request: IActivityRenderingRequest<TActivity>): void {
    const { activityBounds, activityRef, position, canvas, context, x, y, w, h, offsetTop, selected, hover, highlighted, pressed } = request;

    context.clearRect(0, 0, w, h);

    context.globalAlpha = activityRef.layer.opacity < 1 ? activityRef.layer.opacity : this.opacity;

    this.drawBackground(activityRef, position, context, x, y, w, h, offsetTop, selected, hover, highlighted, pressed);
  }
}
