import { AgEvent, IRowNode } from "ag-grid-community";

export class CustomEvents {
  public static readonly GRID_ROWS_LOADED = "gridRowsLoaded";

  /**
   * @deprecated No longer needed.
   */
  public static readonly CREATE_CHILD_FOR_GROUP_ACTION = "createChildForGroupAction";
}

/**
 * @deprecated No longer needed.
 */
export interface CreateChildForGroupActionEvent<TData = any> extends AgEvent {
  /**
   * The parent node (group node) to which the new row will be added
   */
  parentNode: IRowNode<TData>;

  /**
   * It is used to set the parent id of the new row data when creating a child for the group.
   */
  setParentId?: (newRowData: { [k: string]: any; }, parentNode: IRowNode) => void;
}

export interface GridRowsLoadedEvent<TData = any> extends AgEvent {
  parentNode: IRowNode<TData>;
} 