// currently supports only enums with numeric values
function isUsableAsTransformedEnumValue(value: any): boolean { // name verbosity level: almost-maximum
  return typeof value === "number";
}

export type EnumTransformFn<T> = (key: string, value: any) => T;

export function enumKeyOfValue<E extends Record<string, unknown>>(enumType: E, value: number): string {
  return enumType[value] as string;
}

// idea: transforms any enum to any kind of object with key (name) and value
export function enumTransform<E extends Record<string, unknown>, T>(enumType: E, transformFn: EnumTransformFn<T>, exclude: any[] = []): T[] {
  const result: T[] = [];
  const keys: string[] = Object.keys(enumType);
  for (const k of keys) {
    const v = enumType[k];
    if (isUsableAsTransformedEnumValue(v) && exclude.indexOf(v) === -1) {
      result.push(transformFn(k, v));
    }
  }
  return result;
}

// contradiction of silly boring names: they are strike people's interest (but I lost confidence after writing this)
export interface BoringEnumItem {
  key: string,
  value: number;
}

export function asBoringEnumItem(key: string, value: any): BoringEnumItem {
  return { key, value } as BoringEnumItem;
}
