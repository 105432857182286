<script lang="ts" setup></script>

<template>
  <v-container>
    <v-card>
      <v-card-text class="text-center">
        <div class="text-h3 text-uppercase pb-6">{{ $t("notFound-resourceNotFound-notFound-title", {$: "Not found"}) }}</div>
        <div class="text-h4 pb-4">{{ $t("notFound-resourceNotFound-notFoundDescription-label", {$: "Unfortunately requested resource was not found."}) }}</div>
        <div class="text-h4 pb-4">{{ $t("notFound-resourceNotFound-sadFace-emoji", {$: "ಠ╭╮ಠ"}) }}</div>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<style lang="scss" scoped></style>
