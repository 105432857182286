﻿import type { IRowContainer } from "@masta/gantt2/core";
import { ActivityRenderer, GanttSettings, IActivityRenderingRequest, IocSymbols, TimelineManager } from "@masta/gantt2/core";
import { inject, injectable } from "inversify";
import { ResourceCapacityActivity } from "src/components/Gantt/Common/Model";
import { GanttActivityColorsSetting } from "@masta/generated-model";
import { CustomSettingKeys } from "@/components/Gantt/ResourcesGantt/CustomSettingKeys";
import { StatusColorHelper } from "@/components/Gantt/ResourcesGantt/Renderers/StatusColorHelper";

@injectable()
export class InterruptionActivityRenderer extends ActivityRenderer<ResourceCapacityActivity> {
  constructor(
    @inject(TimelineManager) timelineManager: TimelineManager,
    @inject(IocSymbols.RowContainer) rowContainer: IRowContainer,
    @inject(StatusColorHelper) private _statusColorHelper: StatusColorHelper,
    @inject(GanttSettings) protected readonly _settings: GanttSettings
  ) {
    super(timelineManager, rowContainer, InterruptionActivityRenderer.name);
    this.paddingInsets.left = 4;
    this.paddingInsets.right = 4;
  }

  async afterInitialize(): Promise<void> {
    await super.afterInitialize();
    const fillSetting = this._settings.getSetting<GanttActivityColorsSetting>(CustomSettingKeys.ACTIVITY_FILL_COLOR_INTERRUPTED);
    const textColorSetting = this._settings.getSetting<GanttActivityColorsSetting>(CustomSettingKeys.ACTIVITY_TEXT_COLOR_INTERRUPTED);
    if (fillSetting) {
      this.fill = fillSetting.default;
      this.fillHighlight = fillSetting.highlight;
      this.fillSelected = fillSetting.selected;
      this.fillHover = fillSetting.hover;
      this.fillPressed = fillSetting.pressed;
    }
    if (textColorSetting) {
      this.textColor = textColorSetting.default;
      this.textColorHighlight = textColorSetting.highlight;
      this.textColorSelected = textColorSetting.selected;
      this.textColorHover = textColorSetting.hover;
      this.textColorPressed = textColorSetting.pressed;
    }
  }

  drawActivity(request: IActivityRenderingRequest<ResourceCapacityActivity>): void {
    const { activityBounds, activityRef, position, canvas, context, x, y, w, h, offsetTop, selected, hover, highlighted, pressed } = request;

    const activityHeight = h / 4;

    context.clearRect(0, 0, w, h);

    context.globalAlpha = this.opacity;

    this.drawBackground(activityRef, position, context, x, y, w, h, offsetTop, selected, hover, highlighted, pressed);
    context.save();
    context.strokeStyle = "rgba(0,0,0,0.8)";
    context.beginPath();
    context.rect(x, y, w, h);
    context.closePath();
    context.stroke();
    context.restore();

    context.fillStyle = this.getTextColor(selected, hover, highlighted, pressed) ?? "rgba(0,0,0,1)";

    let fsize = this.getFontSize(context.font);
    if (h / 2 < fsize) {
      fsize = h / 2;
    }

    context.font = context.font.replace(/\d+px/, `${fsize}px`);
    const payload: any = activityRef.activity.userObject.entrySourcePayload;

    const { step } = activityRef.activity.userObject;
    const statusBarHeight = Math.round(h / 8);
    if (step && step.statistics) {
      const sX = x;
      const sY = y + h - statusBarHeight;
      const sW = w;
      const sH = statusBarHeight;
      context.fillStyle = this._statusColorHelper.getStepStatusBackgroundColor(step.statistics.totalStatus);
      context.fillRect(sX, sY, sW, sH);
    }

    if (payload && payload.reason) {
      const textWidth = context.measureText(payload.reason).width;
      if (textWidth < w && y + h - activityHeight + fsize < y + h) {
        context.fillText(payload.reason, x + w / 2 - textWidth / 2 + this.paddingInsets.left, y + h - activityHeight + fsize);
      }
    }
  }
}
