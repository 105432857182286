import { inject, injectable } from "inversify";
import { GanttEvents, GraphicsMousePositionEvent, TimelineManager } from "../../../../Core";
import { LayerCanvas } from "./LayerCanvas";

@injectable()
export class EventLineLayer extends LayerCanvas {
  private _mousePosition: GraphicsMousePositionEvent | undefined;

  constructor(@inject(TimelineManager) timelineManager: TimelineManager, @inject(GanttEvents) ganttEvents: GanttEvents) {
    super(timelineManager, ganttEvents, EventLineLayer.name, "event-line-layer");
  }

  async beforeInitialize(): Promise<void> {
    return super.beforeInitialize();
  }

  async afterInitialize(): Promise<void> {
    await super.afterInitialize();
    this.subscribe(
      this._ganttEvents.mousePositionEvent$.subscribe((e) => {
        this._mousePosition = e;
        this.batchDraw();
      })
    );
    this.subscribe(
      this._ganttEvents.mouseLeaveEvent$.subscribe(() => {
        this._mousePosition = undefined;
        this.batchDraw();
      })
    );
  }

  async doDrawFromBatch(): Promise<void> {
    if (this._mousePosition) {
      this.clear();
      const xPos = this._mousePosition.x;
      this.context.lineWidth = 1;
      this.context.strokeStyle = "rgba(33,150,243,1)";

      this.context.beginPath();
      this.context.setLineDash([]);
      this.context.moveTo(xPos, 0);
      this.context.lineTo(xPos, this.canvas.clientHeight);
      this.context.stroke();
      this.context.closePath();
    } else {
      this.clear();
    }
  }
}
