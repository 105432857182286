import { inject, injectable } from "inversify";
import { IocSymbols, Lifecycle, ObservableDataSet, Row, TimelineDataLoader } from "@masta/gantt2/core";
import { ProductionTasksChangedNotificationEvent, ResourcesCapacitiesChangeNotificationEvent } from "@masta/generated-model";
import { Instant } from "@js-joda/core";

@injectable()
export class ProductionTaskChangeNotificationHandler extends Lifecycle {
  constructor(@inject(TimelineDataLoader) private _timelineDataLoader: TimelineDataLoader, @inject(IocSymbols.RowsSymbol) private _rows: ObservableDataSet<Row<any, any, any>>) {
    super();
  }

  async handleNotification(event: ProductionTasksChangedNotificationEvent) {
    const ganttRows = [...this._rows.data.values()];
    const ganttRowIds = ganttRows.map((row) => row.id);
    const changes =
      event.taskIds
        ?.filter((c) => ganttRowIds.includes(c))
        .map((taskId) => {
          return {
            taskId: taskId
          };
        }) ?? [];
    const loadedStartTime = this._timelineDataLoader.loadedStartTime;
    const loadedEndTime = this._timelineDataLoader.loadedEndTime;

    setTimeout(async () => {
      await this._timelineDataLoader.refreshData(
        loadedStartTime.toEpochMilli(),
        loadedEndTime.toEpochMilli(),
        changes.map((change) => change.taskId)
      );
    }, 250);
  }
}
