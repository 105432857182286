import { ApiClient } from "@/services/api/common.api";
import {
  CreateEquipmentCommand,
  CreateEquipmentGroupCommand,
  CreateResourceResponse,
  EquipmentDetailsDto,
  EquipmentDto,
  GetEquipmentPaginatedQuery,
  IAgGridPaginationResponse,
  UpdateEquipmentCommand,
  UpdateEquipmentGroupCommand
} from "@masta/generated-model";

export default {
  async getPaginated(request: GetEquipmentPaginatedQuery) {
    return ApiClient.post<IAgGridPaginationResponse<EquipmentDto>>(`/equipment`, request);
  },
  async getAll(scenarioId?: string) {
    return ApiClient.get<IAgGridPaginationResponse<EquipmentDto>>(`/equipment`, {
      params: {
        scenarioId
      }
    });
  },
  async getSingle(id: string, scenarioId: string) {
    return ApiClient.get<EquipmentDto>(`/equipment/${id}?scenarioId=${scenarioId}`);
  },
  async create(request: CreateEquipmentCommand) {
    return ApiClient.put<CreateResourceResponse>(`/equipment`, request);
  },
  async update(request: UpdateEquipmentCommand) {
    return ApiClient.put<string>(`/equipment/${request.id}`, request);
  },
  async remove(id: string, scenarioId?: string) {
    return ApiClient.delete(`/equipment/${id}?scenarioId=${scenarioId}`);
  },
  async createGroup(request: CreateEquipmentGroupCommand) {
    return ApiClient.post<CreateResourceResponse>("/equipmentGroups", request);
  },
  async updateGroup(request: UpdateEquipmentGroupCommand) {
    return ApiClient.put<undefined>(`/equipmentGroups/${request.id}`, request);
  },
  async removeGroup(id: string, scenarioId?: string) {
    return ApiClient.delete(`/equipmentGroups/${id}?scenarioId=${scenarioId}`);
  },
  async getDetails({ scenarioId, id }: { scenarioId: string; id: string }) {
    return ApiClient.get<EquipmentDetailsDto>(`/equipment/${scenarioId}/${id}/details`);
  }
};
