import { inject, injectable } from "inversify";
import { Lifecycle, TimelineManager } from "../../../Core";
import { ILayerRenderer } from "./ILayerRenderer";

export type NowLineLayerRendererRequest = {
  offset: number;
};

/**
 * @deprecated Use NowLineLayer
 */
@injectable()
export class NowLineLayerRenderer extends Lifecycle implements ILayerRenderer<NowLineLayerRendererRequest> {
  public static Identifier = "NowLineLayerRenderer";

  constructor(@inject(TimelineManager) private _timelineManager: TimelineManager) {
    super();
  }

  render(canvas: OffscreenCanvas | HTMLCanvasElement, context: OffscreenCanvasRenderingContext2D | CanvasRenderingContext2D, params: NowLineLayerRendererRequest): void {
    // console.log("NowLineLayerRenderer.render", canvas, params);

    context.clearRect(0, 0, canvas.width, canvas.height);

    const zoneId = this._timelineManager.zoneId;
    const now = this._timelineManager.nowTime.atZone(zoneId).toInstant();
    const x = this._timelineManager.calculateLocationForTime(now);
    const offset = params.offset;

    context.lineWidth = 2.0;
    context.strokeStyle = "#ff8800";

    const height = canvas instanceof HTMLCanvasElement ? canvas.clientHeight : canvas.height;

    context.beginPath();
    context.moveTo(x + offset, 0);
    context.lineTo(x + offset, height);
    context.closePath();
    context.stroke();
  }
}
