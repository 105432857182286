<script setup lang="ts">
import { inject } from "vue";
import { DispatchTaskSpec } from "@/components/Dispatch/DispatchTaskSpec";
import { $dateTimeFormatterSymbol, DateFormatter } from "@masta/shared";
import { $t } from "@/i18n";

const props = defineProps<{
  specs: DispatchTaskSpec[];
  selectedSpecIds: Set<string>;
  selectedTaskIds: Set<string>;
}>();

const dateTimeFormatter = inject<DateFormatter>($dateTimeFormatterSymbol)!;

function toggleSelection(spec: DispatchTaskSpec) {
  spec.selected = !spec.selected;
  if (spec.selected) {
    props.selectedSpecIds.add(spec.id);
  } else {
    props.selectedSpecIds.delete(spec.id);
  }
  recomputeSelectedTasks();
}

function recomputeSelectedTasks() {
  props.selectedTaskIds.clear();
  for (const spec of props.specs) {
    if (spec.selected) {
      props.selectedTaskIds.add(spec.taskId);
    }
  }
}
</script>

<template>
  <div class="bg-grey-lighten-3 rounded-lg pa-2 fill-height">
    <v-card v-for="spec in specs" :key="spec.id" class="ma-4 cursor-pointer" :class="{ 'bg-primary': spec.selected }" @click="toggleSelection(spec)">
      <v-card-text>
        <v-row>
          <v-col cols="1" class="d-flex items-center justify-center align-center">
            <v-icon :icon="spec.iconName" size="40"></v-icon>
          </v-col>
          <v-col cols="10">
            <div class="text-caption self-center">{{ spec.businessId }}</div>
            <v-chip-group v-if="spec.tags">
              <v-chip v-for="tag in spec.tags" :key="tag" size="small">{{ tag }}</v-chip>
            </v-chip-group>

            <div class="text-h5 text-uppercase">{{ spec.taskName }}</div>
            <div>
              {{ spec.stepName }} - <span class="font-weight-bold">{{ spec.specResource }}</span>
            </div>
            <div class="mb-4">
              <span>{{ dateTimeFormatter(spec.start) }}</span> - <span>{{ dateTimeFormatter(spec.end) }}</span>
            </div>
            <div class="font-weight-bold">
              <span>{{ $t("dispatch-personnelDispatching-taskAssignments-label", { $: "Assignments" }) }}</span>
              ({{ spec.assignedQuantity }} / {{ spec.totalQuantity }})
            </div>
            <ul class="mx-6 my-2">
              <li v-for="assignment in spec.assignments">
                <div>
                  {{ assignment.resourceName }} <span class="text-caption">({{ assignment.quantity * 100 }}%)</span>
                </div>
              </li>
            </ul>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>
