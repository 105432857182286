import { ApiClient } from "@/services/api/common.api";
import { GetShiftInstancePersonnelResult, TaskAssignmentDispatchRemovalCommand, TaskAssignmentManualDispatchCommand, TerminalTaskDetailsDto } from "@masta/generated-model";

export default {
  async getShiftInstancePersonnel(availabilityRuleId: string, date: string, scenarioId: string) {
    return ApiClient.get<GetShiftInstancePersonnelResult>(
      `/dispatch/${availabilityRuleId}/${date}/personnel?showSatisfaction=false&showConstraints=false&scenarioId=${scenarioId}`
    );
  },
  async startAutoDispatch(availabilityRuleId: string, startDate: string, scenarioId: string) {
    return ApiClient.post(`/dispatch/auto`, { availabilityRuleId, start: startDate, scenarioId });
  },
  async performTaskAssignmentManualDispatch(availabilityRuleId: string, date: string, personIds: string[], taskIds: string[], specIds: string[], scenarioId: string) {
    return ApiClient.post(`/dispatch/task-assignment`, { availabilityRuleId, date, personIds, taskIds, specIds, scenarioId });
  },
  async performEquipmentAssignmentManualDispatch(availabilityRuleId: string, date: string, personIds: string[], equipmentIds: string[], scenarioId: string) {
    return ApiClient.post(`/dispatch/equipment-assignment`, { availabilityRuleId, date, personIds, equipmentIds, scenarioId });
  },
  async removeAllAssignments(availabilityRuleId: string, date: string, scenarioId: string) {
    return ApiClient.post(`/dispatch/remove-all-assignments`, { availabilityRuleId, date, removeAll: true, scenarioId });
  },
  async removeTaskAssignments(availabilityRuleId: string, date: string, taskIds: string[], scenarioId: string) {
    return ApiClient.post(`/dispatch/remove-task-assignments`, { availabilityRuleId, date, taskIds, scenarioId });
  },
  async removeEquipmentAssignments(availabilityRuleId: string, date: string, equipmentIds: string[], scenarioId: string) {
    return ApiClient.post(`/dispatch/remove-equipment-assignments`, { availabilityRuleId, date, equipmentIds, scenarioId });
  },
  async getResourceProductionTasks(resourceId: string, scenarioId: string) {
    return ApiClient.get<{ tasks: TerminalTaskDetailsDto[] }>(`/TerminalTasks/details?relatedResourceId=${resourceId}&scenarioId=${scenarioId}`);
  }
};
