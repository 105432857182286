import { ApiClient } from "@/services/api/common.api";
import { BusinessActionDto, GetBusinessActionsPaginatedQuery, IAgGridPaginationResponse } from "@masta/generated-model";

export default {
  async getAll() {
    return ApiClient.get<IAgGridPaginationResponse<BusinessActionDto>>(`/businessActions`);
  },
  async getPaginated(request: GetBusinessActionsPaginatedQuery) {
    return ApiClient.post<IAgGridPaginationResponse<BusinessActionDto>>(`/businessActions`, request);
  }
};
