import { ApiClient } from "@/services/api-service/ApiClient";
import { AxiosResponse } from "axios";
import { Locale } from "@/i18n";

export class I18nController extends ApiClient {
  async getPublicClientLabels(locale: Locale): Promise<AxiosResponse<Record<string, any>>> {
    return await this._axios.get<Record<string, any>>(`/i18n/public/client/${locale}`, { withoutAuthorization: true } as any);
  }

  async getClientLabels(locale: Locale): Promise<AxiosResponse<Record<string, any>>> {
    return await this._axios.get<Record<string, any>>(`/i18n/client/${locale}`);
  }
}
