import { ServerSideDataSource } from "@/components/Grid/ServerSideDataSource";
import { CreateOrUpdateOrderLineGroupCommand, OrderLineGroupDto, GetOrderLineGroupsPaginatedQuery, IAgGridPaginationResponse } from "@masta/generated-model";
import { Store } from "pinia";
import { State as ScenarioState, useScenariosStore } from "@/store/ScenariosStore";
import { IServerSideGetRowsParams } from "ag-grid-community";
import ApiService from "@/services/api";
import { GUID_EMPTY } from "@/components/ValueCellEditor/CommonFormatters";

export class OrderLineGroupsServerSideDataSource extends ServerSideDataSource<
  OrderLineGroupDto,
  OrderLineGroupDto,
  CreateOrUpdateOrderLineGroupCommand,
  CreateOrUpdateOrderLineGroupCommand,
  string
> {
  private _scenarioStore: Store<string, ScenarioState>;
  private _filterByProductId: string | null | undefined;

  constructor(id: string) {
    super(id);
    this._scenarioStore = useScenariosStore();
    this._filterByProductId = null;
  }

  public useFilteringByProductId(productId: string | null | undefined) {
    this._filterByProductId = productId;
  }

  async getAll<T>(params: IServerSideGetRowsParams): Promise<IAgGridPaginationResponse<OrderLineGroupDto>> {
    if (!this._scenarioStore.selectedScenario) return { count: 0, results: [] } as IAgGridPaginationResponse<OrderLineGroupDto>;

    const request = {
      ...this.constructServerRequest(params),
      scenarioId: this._scenarioStore.selectedScenario.id,
      resourceId: this._filterByProductId
    } as GetOrderLineGroupsPaginatedQuery;
    const response = await ApiService.orderLineGroups.getPaginated(request);
    return response.data as IAgGridPaginationResponse<OrderLineGroupDto>;
  }

  async create(entity: OrderLineGroupDto): Promise<string> {
    const request = this.transformForCreate(entity);
    const scenario = this._scenarioStore.selectedScenario;
    request.scenarioId = scenario?.id ?? "";
    const response = await ApiService.orderLineGroups.createOrUpdate(request);
    return response.data;
  }

  async getSingle(id: string): Promise<OrderLineGroupDto | undefined> {
    const response = await ApiService.orderLineGroups.getSingle(id, this._scenarioStore.selectedScenario?.id ?? "");
    return response.data;
  }

  async remove(entity: OrderLineGroupDto): Promise<void> {
    if (!entity.id) return;
    await ApiService.orderLineGroups.remove(entity.id, this._scenarioStore.selectedScenario?.id ?? "");
  }

  async update(entity: OrderLineGroupDto): Promise<string> {
    const response = await ApiService.orderLineGroups.createOrUpdate(this.transformForUpdate(entity));
    return response.data;
  }

  public transformOrderLineGroupDtoToCreateOrUpdateOrderLineGroupCommand(listEntity: OrderLineGroupDto): CreateOrUpdateOrderLineGroupCommand {
    return {
      id: listEntity.id,
      scenarioId: listEntity.scenarioId ?? this._scenarioStore.selectedScenario?.id ?? GUID_EMPTY,
      businessId: listEntity.businessId
    };
  }

  protected transformForCreate(listEntity: OrderLineGroupDto): CreateOrUpdateOrderLineGroupCommand {
    return this.transformOrderLineGroupDtoToCreateOrUpdateOrderLineGroupCommand(listEntity);
  }

  protected transformForUpdate(listEntity: OrderLineGroupDto): CreateOrUpdateOrderLineGroupCommand {
    return this.transformOrderLineGroupDtoToCreateOrUpdateOrderLineGroupCommand(listEntity);
  }
}
