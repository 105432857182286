<script setup lang="ts">
import { ref } from "vue";
import { useI18n } from "vue-i18n";

const props = defineProps<{ uploading: boolean; multiple: boolean }>();

const emits = defineEmits(["upload"]);

const { t: $t } = useI18n();

const isDraggingOver = ref(false);

const handleDragOver = (event: DragEvent) => {
  isDraggingOver.value = true;
  setDragDropEffect(event);
};

const handleDragEnter = (_: DragEvent) => {
};

const handleDragLeave = () => {
  isDraggingOver.value = false;
};

const handleDrop = (event: DragEvent) => {
  isDraggingOver.value = false;

  // Handle dropped files
  const files = event.dataTransfer?.files;
  handleDroppedFiles(files);
};

const setDragDropEffect = (event: DragEvent) => {
  // we can filter on the type of file here
  if (event.dataTransfer) event.dataTransfer.dropEffect = "copy";
};

const handleDroppedFiles = (files: FileList | undefined) => {
  // If multiple files are not allowed, take only the first file
  if (!props.multiple && files && files.length > 0) {
    const singleFileList = new DataTransfer();
    singleFileList.items.add(files[0]);
    emits("upload", singleFileList.files);
  } else {
    // If multiple files are allowed, emit all files
    emits("upload", files);
  }
};
</script>

<template>
  <div class="drag-drop-area" @dragover.prevent="handleDragOver" @dragenter.prevent="handleDragEnter" @dragleave.prevent="handleDragLeave" @drop.prevent="handleDrop">
    <v-fade-transition>
      <v-icon v-if="!uploading" class="align-center fill-height middle" size="75px">mdi-file-upload</v-icon>
      <v-progress-circular v-else indeterminate color="primary" size="75"></v-progress-circular>
    </v-fade-transition>
    <div class="name-label text-caption">
      <v-card>
        {{
          isDraggingOver
            ? $t("mediaPickerDialog-drop-label", { $: "Drop files to upload" })
            : $t("mediaPickerDialog-dragAndDropClick-label", { $: "Upload from Computer or drop files here" })
        }}
      </v-card>
    </div>
  </div>
</template>

<style scoped lang="scss">
.drag-drop-area {
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.drag-message {
  text-align: center;
  opacity: 0.5;
  pointer-events: none;

  &.dragging {
    opacity: 1;
  }
}


.name-label {
  position: absolute;
  bottom: 0;
  margin: 0 auto;
  text-align: center;
  width: 100%;
}
</style>
