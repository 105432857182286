import axios, { AxiosRequestConfig } from "axios";
import { $authService } from "@/services/AuthService";
import { AppError } from "@/services/api/errors";
import { authorizedLocalStorageKey, i18n, publicLocalStorageKey } from "@/i18n";
import router from "@/router";

export interface AppAxiosRequestConfig<D = any> extends AxiosRequestConfig<D> {
  withoutAuthorization?: boolean;
}

async function getToken() {
  return $authService.token;
}

async function getTenantId() {
  return $authService.tokenParsed!.tenant_id;
}

async function isAuthenticated(): Promise<boolean> {
  return $authService.authenticated;
}

async function doAuthenticate() {
  const tenantId = router.currentRoute.value.meta.tenantId;
  const fullPath = router.currentRoute.value.fullPath;

  return await $authService.authenticate(tenantId as string, fullPath);
}

async function getLocale() {
  const tenantId = await getTenantId();
  let locale: any = "";
  if (!tenantId) {
    locale = localStorage.getItem(publicLocalStorageKey);
  } else {
    locale = localStorage.getItem(`${authorizedLocalStorageKey}${tenantId}`);
  }
  if (!locale) {
    locale = i18n.global.fallbackLocale.value;
  }
  return locale;
}

async function getHeaders() {
  const token = await getToken();
  return {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
    "X-Tenant": await getTenantId(),
    "Accept-Language": await getLocale()
  };
}

export const ApiClient = axios.create({
  baseURL: `${import.meta.env.VITE_API_BASE_URL}/api`,
  withCredentials: false, // This is the default
  timeout: 60000
} as AppAxiosRequestConfig);

ApiClient.interceptors.request.use(
  async function(config: AppAxiosRequestConfig) {
    if (config.withoutAuthorization) {
      return config;
    }

    const authenticated = await isAuthenticated();
    if (!authenticated) {
      doAuthenticate();
      return Promise.reject(new Error("User is not authenticated"));
    }

    config.headers!.common = {
      ...(config.headers!.common as any),
      ...(await getHeaders())
    };
    return config;
  },
  async (error) => {
    if (error.response && error.response.data) {
      const data = error.response.data;
      if (data.status > 0 && data.type && data.type && data.title) {
        return Promise.reject(new AppError(data.status, data.type, data.title, data.detail, data.errors, data.args));
      }
    }
    return Promise.reject(error);
  }
);

ApiClient.interceptors.response.use(
  async function(response) {
    return response;
  },
  (error: any) => {
    if (error.response && error.response.data) {
      const data = error.response.data;
      if (data.status > 0 && data.type && data.type && data.title) {
        return Promise.reject(new AppError(data.status, data.type, data.title, data.detail, data.errors, data.args));
      }
    }
    return Promise.reject(error);
  }
);

