<script lang="ts" setup>
import { $t } from "@/i18n";
import { ref, watch } from "vue";
import { PublishExecutionEventCommand, WorkJournalRecordDto, WorkJournalRecordEventAction, WorkJournalRecordEventType } from "@masta/generated-model";
import { v4 as uuidv4 } from "uuid";
import { getEnumTitleValuePairs } from "@/composables/enumHelpers";
import { translateWorkJournalRecordEventAction, translateWorkJournalRecordEventType } from "@/composables/translateEnum";
import CodeEditor from "@/components/CodeEditor.vue";


// Define interface for editing the WorkJournalRecordDto
interface EditableWorkJournalRecordDto {
  id: string;
  affectedWorkJournalRecordId: string;
  resourceId: string;
  taskId: string;
  taskBusinessId: string;
  taskName: string;
  stepId: string;
  stepPosition: number;
  stepName: string;
  personId: string;
  personBusinessId: string;
  personName: string;
  agreementId: string;
  agreementBusinessId: string;
  agreementName: string;
  equipmentId: string;
  equipmentBusinessId: string;
  equipmentName: string;
  assetIds: string[];
  eventType: WorkJournalRecordEventType;
  eventAction: WorkJournalRecordEventAction;
  eventStart: string;
  timestamp: string;
  eventPayload: any;
  cancelled: boolean;
  isAutomation: boolean;
  businessId: string;
  tags: string[];
}

const props = defineProps<{
  modelValue: boolean;
  template?: WorkJournalRecordDto; // Make template prop optional
}>();
const emit = defineEmits<{
  (e: "update:modelValue", value: boolean): void;
  (e: "dataFilled", workJournal: PublishExecutionEventCommand): void;
}>();

const editedWorkJournal = ref<EditableWorkJournalRecordDto>();
const payloadString = ref<string>("");
const assetIdsString = ref<string>("");
const tagsString = ref<string>("");
const workJournal = ref<PublishExecutionEventCommand>();

const eventTypes = getEnumTitleValuePairs(WorkJournalRecordEventType, translateWorkJournalRecordEventType).sort((a, b) => a.title.localeCompare(b.title));
const eventActions = getEnumTitleValuePairs(WorkJournalRecordEventAction, translateWorkJournalRecordEventAction).sort((a, b) => a.title.localeCompare(b.title));

watch(
  () => props.template,
  (newVal) => {
    if (newVal) {
      editedWorkJournal.value = { ...newVal };
      payloadString.value = JSON.stringify(newVal.eventPayload, null, 2);
      tagsString.value = newVal.tags.join(", ");
      assetIdsString.value = newVal.assetIds.join(", ");
      editedWorkJournal.value.affectedWorkJournalRecordId = newVal.id;
      editedWorkJournal.value.eventAction = WorkJournalRecordEventAction.Correction;
    } else {
      editedWorkJournal.value = {
        id: uuidv4(),
        affectedWorkJournalRecordId: "",
        resourceId: "",
        taskId: "",
        taskBusinessId: "",
        taskName: "",
        stepId: "",
        stepPosition: 0,
        stepName: "",
        personId: "",
        personBusinessId: "",
        personName: "",
        agreementId: "",
        agreementBusinessId: "",
        agreementName: "",
        equipmentId: "",
        equipmentBusinessId: "",
        equipmentName: "",
        assetIds: [],
        eventType: WorkJournalRecordEventType.Unknown,
        eventAction: WorkJournalRecordEventAction.Unknown,
        eventStart: "",
        timestamp: "",
        eventPayload: {},
        cancelled: false,
        isAutomation: false,
        businessId: "",
        tags: []
      };
      payloadString.value = "";
    }
  }
);

function cancel() {
  setModal(false);
}

function sendWorkJournal() {
  workJournal.value = {
    ...workJournal.value,
    ...editedWorkJournal.value
  };
  editedWorkJournal.value.eventPayload = JSON.parse(payloadString);
  editedWorkJournal.value.assetIds = assetIdsString.value.split(",").map((x) => x.trim());
  editedWorkJournal.value.tags = tagsString.value.split(",").map((x) => x.trim());
  setModal(false);
  emit("dataFilled", workJournal.value);
  workJournal.value = undefined;
}

function setModal(visible: boolean) {
  emit("update:modelValue", visible);
}
</script>

<template>
  <v-dialog :model-value="modelValue" width="80%" @update:model-value="setModal($event)">
    <v-card class="pa-8">
      <v-card-title class="d-flex align-center justify-space-between">
        <div class="text-h4">{{ $t("workJournalRecord-createDialog-title-label", { $: "Enter data to manually create a Work Journal Record" }) }}</div>
        <v-icon @click="cancel">mdi-close</v-icon>
      </v-card-title>
      <v-card-text>
  <v-row>
     <v-col cols="6">
      <v-text-field
        v-model="editedWorkJournal.businessId"
        variant="outlined"
        density="compact"
        :label="$t('workJournalRecord-createDialog-businessId-label', { $: 'Business Id' })"
      />
    </v-col>
    <v-col cols="6">
      <v-text-field
        v-model="editedWorkJournal.tags"
        variant="outlined"
        density="compact"
        :label="$t('workJournalRecord-createDialog-tags-label', { $: 'Tags' })"
      />
    </v-col>
  </v-row>
  <v-row>
    <v-col cols="6">
      <v-select
        v-model="editedWorkJournal.eventType"
        variant="outlined"
        density="compact"
        :label="$t('workJournalRecord-createDialog-eventType-label', { $: 'Event type:' })"
        :items="eventTypes"
      />
    </v-col>
    <v-col cols="6">
      <v-select
        v-model="editedWorkJournal.eventAction"
        variant="outlined"
        density="compact"
        :label="$t('workJournalRecord-createDialog-eventAction-label', { $: 'Event action:' })"
        :items="eventActions"
      />
    </v-col>
  </v-row>

  <v-row>
    <v-col cols="6">
      <v-text-field
        v-model="editedWorkJournal.affectedWorkJournalRecordId"
        variant="outlined"
        density="compact"
        :label="$t('workJournalRecord-createDialog-affectedWorkJournalRecordId-label', { $: 'Affected Work Journal Record Id' })"
      />
    </v-col>
  </v-row>

  <v-row>
    <v-col cols="6">
      <v-text-field
        v-model="editedWorkJournal.taskId"
        variant="outlined"
        density="compact"
        :label="$t('workJournalRecord-createDialog-taskId-label', { $: 'Task Id' })"
      />
    </v-col>
    <v-col cols="6">
      <v-text-field
        v-model="editedWorkJournal.stepId"
        variant="outlined"
        density="compact"
        :label="$t('workJournalRecord-createDialog-stepId-label', { $: 'Step Id' })"
      />
    </v-col>
  </v-row>

  <v-row>
    <v-col cols="6">
      <v-text-field
        v-model="editedWorkJournal.personId"
        variant="outlined"
        density="compact"
        :label="$t('workJournalRecord-createDialog-personId-label', { $: 'Person Id' })"
      />
    </v-col>
    <v-col cols="6">
      <v-text-field
        v-model="editedWorkJournal.equipmentId"
        variant="outlined"
        density="compact"
        :label="$t('workJournalRecord-createDialog-equipmentId-label', { $: 'Equipment Id' })"
      />
    </v-col>
  </v-row>

  <v-row>
    <v-col cols="6">
      <v-text-field
        v-model="editedWorkJournal.agreementId"
        variant="outlined"
        density="compact"
        :label="$t('workJournalRecord-createDialog-agreementId-label', { $: 'Agreement Id' })"
      />
    </v-col>
    <v-col cols="6">
      <v-text-field
        v-model="editedWorkJournal.assetIds"
        variant="outlined"
        density="compact"
        :label="$t('workJournalRecord-createDialog-assetIds-label', { $: 'Asset Ids' })"
      />
    </v-col>
  </v-row>
  <v-row>
    <v-col cols="6">
      <v-text-field
        v-model="editedWorkJournal.resourceId"
        variant="outlined"
        density="compact"
        :label="$t('workJournalRecord-createDialog-resourceId-label', { $: 'Resource Id' })"
      />
    </v-col>
  </v-row>

  <v-row>
    <v-col cols="6">
      <v-text-field
        v-model="editedWorkJournal.eventStart"
        variant="outlined"
        density="compact"
        :label="$t('workJournalRecord-createDialog-eventStart-label', { $: 'Event Start' })"
      />
    </v-col>
    <v-col cols="6">
      <v-text-field
        v-model="editedWorkJournal.timestamp"
        variant="outlined"
        density="compact"
        :label="$t('workJournalRecord-createDialog-timestamp-label', { $: 'Created At' })"
      />
    </v-col>
  </v-row>

  <v-row>
    <v-col cols="12">
    <CodeEditor
      v-model="payloadString"
      :language="'json'"
      :theme="'vs-dark'"
      :minHeight="'300px'"
      :readonly="false"
    />
  </v-col>
  </v-row>

  <v-row>
  <v-col cols="6">
    <v-row>
      <v-col cols="4">
        <v-checkbox
          v-model="editedWorkJournal.cancelled"
          variant="outlined"
          density="compact"
          :label="$t('workJournalRecord-createDialog-cancelled-label', { $: 'Is Cancelled?' })"
        />
      </v-col>
      <v-col cols="4">
        <v-checkbox
          v-model="editedWorkJournal.isAutomation"
          variant="outlined"
          density="compact"
          :label="$t('workJournalRecord-createDialog-isAutomation-label', { $: 'Is Created Automatically?' })"
        />
      </v-col>
    </v-row>
  </v-col>
</v-row>
</v-card-text>
      <v-card-actions>
        <v-row>
          <v-col cols="6">
            <v-btn class="ma-4" variant="elevated" color="secondary" block @click="cancel">
              {{ $t("workJournalRecord-createDialog-cancel-action", { $: "Cancel" }) }}
            </v-btn>
          </v-col>
          <v-col cols="6">
            <v-btn class="ma-4" variant="elevated" color="primary" block @click="sendWorkJournal">
              {{ $t("workJournalRecord-createDialog-send-action", { $: "Create" }) }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
