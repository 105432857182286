<script lang="ts" setup>
import YesNoDialog from "@/components/Dialogs/YesNoDialog.vue";
import Breadcrumbs from "@/components/Layout/Breadcrumbs.vue";
import SplitLayout from "@/components/Layout/SplitLayout.vue";
import AddProcessDraftDialog from "@/components/ProcessDrafts/AddProcessDraftDialog.vue";
import ProcessDraftEditor from "@/components/ProcessDrafts/ProcessDraftEditor.vue";
import { ProcessDraft } from "@/components/ProcessDrafts/ProcessDraftModels";
import ProcessDraftsGrid from "@/components/ProcessDrafts/ProcessDraftsGrid.vue";
import { mapCreateProductTemplateDraftsFromModel, mapProcessDraftToModel } from "@/components/ProcessDrafts/mapping";
import { createProcessDraftDuplicate } from "@/components/ProcessDrafts/model-from-product-template";
import { $t } from "@/i18n";
import { useErrorsStore } from "@/store/ErrorsStore";
import { useProcessDraftsStore } from "@/store/ProcessDraftsStore";
import { CreateSnackbarCommand, useSnackbarsStore } from "@/store/SnackbarsStore";
import { ProcessDraftDto, ProcessDraftStatus } from "@masta/generated-model";
import { isDefined } from "@vueuse/core";
import { onMounted, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useScenariosStore } from "@/store/ScenariosStore";

const props = defineProps<{
  filterByProductId?: string;
  embedded: boolean;
}>();

const router = useRouter();
const route = useRoute();

const snackbarStore = useSnackbarsStore();

const selectedProcessDraft = ref<ProcessDraft | null>(null);

const readonly = ref<boolean>(false);
const grid = ref<InstanceType<typeof ProcessDraftsGrid> | null>(null);
const editor = ref<InstanceType<typeof ProcessDraftEditor> | null>(null);
const confirmationDialog = ref(false);
const confirmationDialogCallbackQueued = ref<(() => void) | null>(null);
const addDialog = ref(false);

const errorsStore = useErrorsStore();

const splitLayoutRef = ref<typeof SplitLayout | null>(null);

watch(
  () => route.query.processDraftId,
  async (processDraftId, _) => {
    if (isDefined(processDraftId)) {
      if (isDefined(selectedProcessDraft.value) && selectedProcessDraft.value?.id === processDraftId) {
        return;
      } else {
        const processDraft = await fetchProcessDraft(processDraftId as string);
        if (processDraft != null) {
          selectedProcessDraft.value = mapProcessDraftToModel(processDraft);
          readonly.value = selectedProcessDraft.value?.status !== ProcessDraftStatus.WorkInProgress;

          showProcessDraftEditor();
        } else {
          selectedProcessDraft.value = null;
        }
      }
    } else {
      selectedProcessDraft.value = null;
    }
  },
  { immediate: true }
);

onMounted(() => {
  if (selectedProcessDraft.value == null) {
    closeProcessDraftEditor();
  }
});

async function onProcessDraftSelected(processDraft: ProcessDraftDto) {
  if (processDraft == null) return;

  if (editor.value?.hasChanges()) {
    confirmationDialogCallbackQueued.value = () => {
      changeSelectedProcessDraft(processDraft);
    };
    confirmationDialog.value = true;
  } else {
    changeSelectedProcessDraft(processDraft);
  }
}

async function changeSelectedProcessDraft(processDraft: ProcessDraftDto) {
  if (processDraft != null) {
    selectedProcessDraft.value = mapProcessDraftToModel(processDraft);

    showProcessDraftEditor();

    readonly.value = selectedProcessDraft.value.status !== ProcessDraftStatus.WorkInProgress;

    if (!props.embedded) {
      const currentQuery = router.currentRoute.value.query;
      const newQuery = Object.assign({}, currentQuery, { processDraftId: processDraft.id });
      await router.push({ query: newQuery });
    }
  }
}

async function fetchProcessDraft(processDraftId: string): Promise<ProcessDraftDto | null | undefined> {
  try {
    const processDraftStore = useProcessDraftsStore();
    return await processDraftStore.fetchSingle(processDraftId);
  } catch (error) {
    console.error("Fetch process draft error", error);
  }

  return null;
}

function onCreateAction() {
  addDialog.value = true;
}

function onDuplicateAction() {
  if (!selectedProcessDraft.value) return;

  const duplicate = createProcessDraftDuplicate(selectedProcessDraft.value);
  createNewProcessDraft(duplicate);
}

async function onAddDialogResult(result: ProcessDraft | null) {
  if (result) {
    if (result.status === ProcessDraftStatus.WaitingForAi) {
      const processDraftAiId = await saveFromAi(result);

      if (!props.embedded) {
        const currentQuery = router.currentRoute.value.query;
        const newQuery = Object.assign({}, currentQuery, { processDraftId: processDraftAiId });
        await router.push({ query: newQuery });
      }
    } else {
      await createNewProcessDraft(result);
    }
  }
}

async function createNewProcessDraft(draft: ProcessDraft) {
  const processDraftsStore = useProcessDraftsStore();

  try {
    const scenarioStore = useScenariosStore();
    const { data: id } = await processDraftsStore.create({
      scenarioId: scenarioStore.selectedScenario?.id ?? "",
      name: draft.name ?? "",
      productTemplates: mapCreateProductTemplateDraftsFromModel(draft.productTemplates),
      createProductTemplates: false,
      createFromAi: null,
      targetResourceId: draft.targetResource != null ? draft.targetResource.id : null,
      targetResourceBusinessId: null
    });

    processDraftSaved(id);
  } catch (error) {
    errorsStore.handleError(error);
    return;
  }
}

async function saveFromAi(template: ProcessDraft) {
  const processDraftsStore = useProcessDraftsStore();
  const scenarioStore = useScenariosStore();
  const { data: id } = await processDraftsStore.create({
    scenarioId: scenarioStore.selectedScenario?.id ?? "",
    name: template.name,
    targetResourceId: template.targetResource?.id ?? null,
    targetResourceBusinessId: null,
    createFromAi: true,
    createProductTemplates: false,
    productTemplates: []
  });
  processDraftSaved(id);
  snackbarStore.createSnackbar({
    message: $t("processDraft-view-createdFromAi-notification", { $: "Project sent to AI " }),
    type: "success"
  } as CreateSnackbarCommand);

  return id;
}

async function onConfirmationDialogResult(result: boolean) {
  if (result) {
    const successfullySaved = await editor.value?.save();

    if (!successfullySaved) {
      // Clear selection; no restore of previous selection to avoid firing grid row selection event
      grid.value?.clearSelection();

      return;
    }
  }

  if (confirmationDialogCallbackQueued.value) {
    await confirmationDialogCallbackQueued.value();
  }
}

async function onDeleteAction() {
  selectedProcessDraft.value = null;

  if (!props.embedded) {
    const currentQuery = router.currentRoute.value.query;
    const newQuery = Object.assign({}, currentQuery);
    delete newQuery.processDraftId;
    await router.push({ query: newQuery });
  }
}

async function processDraftSaved(id: string) {
  grid.value?.refreshAll();

  if (!props.embedded) {
    // need to update the url if new process draft was created / saved
    const currentQuery = router.currentRoute.value.query;
    const newQuery = Object.assign({}, currentQuery, { processDraftId: id });
    await router.push({ query: newQuery });
  }
}

function processDraftFinished() {
  // NOP
}

function closeProcessDraftEditor() {
  splitLayoutRef.value?.closeDetails();
}

function showProcessDraftEditor() {
  splitLayoutRef.value?.showDetails();
}

function createSplitPanelGutterElement(index: number, direction: "horizontal" | "vertical") {
  const gutter = document.createElement("div");
  gutter.classList.add("gutter-process-drafts");
  return gutter;
}
</script>

<template>
  <split-layout ref="splitLayoutRef" identifier="process-drafts" :gutter="createSplitPanelGutterElement">
    <template #master>
      <v-card elevation="0" class="flexcard fill-height">
        <v-card-title v-if="!embedded">
          <breadcrumbs>{{ $t("processDraft-view-processDrafts-title", { $: "Technology Builder" }) }}</breadcrumbs>
        </v-card-title>
        <v-card-text class="fill-height" :class="embedded ? 'pa-0' : ''">
          <v-row class="fill-height" no-gutters>
            <v-col cols="12">
              <process-drafts-grid
                ref="grid"
                :reference-value="route.query.processDraftId?.toString()"
                :filter-by-product-id="filterByProductId"
                @create-action="onCreateAction"
                @duplicate-action="onDuplicateAction"
                @delete-action="onDeleteAction"
                @row-selected="onProcessDraftSelected"
              />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </template>
    <template #detail>
      <div :class="embedded ? 'fill-height' : 'process-draft-editor-container'">
        <v-card v-if="selectedProcessDraft" elevation="7" class="d-flex flex-column fill-height process-draft-editor-card">
          <v-card-text class="fill-height">
            <v-row class="fill-height">
              <v-col cols="12">
                <process-draft-editor
                  ref="editor"
                  :process-draft="selectedProcessDraft"
                  :readonly="readonly"
                  @saved="processDraftSaved"
                  @finished="processDraftFinished"
                  @close="closeProcessDraftEditor"
                />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <v-card v-if="!selectedProcessDraft" elevation="7" class="d-flex flex-column fill-height no-process-draft-editor-card">
          <v-card-title>
            <v-btn class="float-right" icon="mdi-window-close" size="x-large" elevation="0" @click="closeProcessDraftEditor" />
          </v-card-title>
          <v-card-text class="fill-height">
            <v-row class="fill-height">
              <v-col cols="12" class="d-flex align-center justify-center">
                <label class="text-secondary text-h6 font-weight-regular">
                  {{ $t("processDraft-view-noProcessDraftSelected-label", { $: "No process draft selected" }) }}
                </label>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </div>
    </template>
  </split-layout>
  <add-process-draft-dialog v-model="addDialog" @dialog-result="onAddDialogResult" />
  <yes-no-dialog
    v-model="confirmationDialog"
    :title="$t('processDraft-view-closeDetail-title', { $: 'Warning' })"
    :description="$t('processDraft-view-closeDetail-description', { $: 'Do you want to save before closing?' })"
    @dialog-result="onConfirmationDialogResult"
  />
</template>

<style lang="scss">
$app-bar-height: 81px;

.process-draft-editor-container {
  height: calc(100vh - 81px);
}

.process-draft-editor-card {
  border: 2px solid rgb(var(--v-theme-primary));
  border-radius: 0;
  overflow-y: scroll;
}

.no-process-draft-editor-card {
  border-radius: 0;
}

.gutter-process-drafts {
  cursor: col-resize;
}
</style>
