<script lang="ts" setup>
import { GridWrapperComponent } from "@/components/Grid/GridWrapperComponent";
import GridWrapper from "@/components/Grid/GridWrapper.vue";
import { inject, reactive, ref, watch } from "vue";
import { $t } from "@/i18n";
import MaterialMovementDialog from "@/views/ProductOverview/MaterialMovementDialog.vue";
import Gantt from "@/components/Gantt/Gantt.vue";
import { useFilterGridAction } from "@/components/Grid/Filters/UseFilterGridAction";
import { ResourceCapacityEntrySource } from "@masta/generated-model";
import { translateMeasurementUnit, translateResourceCapacityEntrySource } from "@/composables/translateEnum";
import FilterGridAction, { FilterGridActionItem } from "@/components/Grid/Filters/FilterGridAction.vue";
import { ResourceCapacityServerSideDataSource } from "@/components/ResourceCapacities/ResourceCapacityServerSideDataSource";
import { $dateTimeFormatterSymbol, DateFormatter } from "@masta/shared";
import SplitPanel from "@/components/Layout/SplitPanel.vue";
import SystemEnumService from "@/services/system-enum.service";

interface StorageOverviewProps {
  filterByProductId: string | null | undefined;
}

const props = defineProps<StorageOverviewProps>();

const defaultColDef = {};

const serverSideDataSource = reactive(new ResourceCapacityServerSideDataSource("resourceCapacity"));
const gridWrapperRef = ref<GridWrapperComponent>();
const filterGridActionRef = ref<typeof FilterGridAction>();

const isTransactionCreationDialogVisible = ref<boolean>(false);

const onTransactionCreateRequest = () => (isTransactionCreationDialogVisible.value = true);
const onTransactionCreated = () => {
  gridWrapperRef.value?.gridApi?.refreshServerSide();
  isTransactionCreationDialogVisible.value = false;
};
const onTransactionCreateCanceled = () => (isTransactionCreationDialogVisible.value = false);
const $dateTimeFormatter = inject<DateFormatter>($dateTimeFormatterSymbol)!;

function onPrepareColumns(columnDefs: any) {
  columnDefs.value = [
    {
      field: "periodStart",
      editable: false,
      sortable: true,
      filter: "agDateColumnFilter",
      valueFormatter: (params: any) => {
        return params.data ? $dateTimeFormatter(params.data.periodStart) : null;
      },
      headerValueGetter: (_: any) => $t("storageOverview-resourceCapacityList-periodStart-label", { $: "Start" })
    },
    {
      field: "periodEnd",
      editable: false,
      sortable: true,
      filter: "agDateColumnFilter",
      valueFormatter: (params: any) => {
        return params.data ? $dateTimeFormatter(params.data.periodEnd) : null;
      },
      headerValueGetter: (_: any) => $t("storageOverview-resourceCapacityList-periodEnd-label", { $: "End" })
    },
    {
      field: "quantity",
      editable: false,
      sortable: true,
      filter: true,
      headerValueGetter: (_: any) => $t("storageOverview-resourceCapacityList-quantity-label", { $: "Quantity" })
    },
    {
      field: "quantityUnit",
      editable: false,
      sortable: true,
      filter: true,
      valueFormatter: (params: any) => (params.data ? translateMeasurementUnit(params.data.quantityUnit) : null),
      headerValueGetter: (_: any) => $t("storageOverview-resourceCapacityList-quantityUnit-label", { $: "Measurement Unit" })
    },
    {
      field: "capacityGroup",
      editable: false,
      sortable: true,
      filter: true,
      valueFormatter: (params: any) => (params.data ? SystemEnumService.resourceCapacityGroup(params.data.capacityGroup) : null),
      headerValueGetter: (_: any) => $t("storageOverview-resourceCapacityList-capacityGroup-label", { $: "Capacity Group" })
    },
    {
      field: "changeType",
      editable: false,
      sortable: true,
      filter: true,
      valueFormatter: (params: any) => (params.data ? SystemEnumService.resourceCapacityChangeType(params.data.changeType) : null),
      headerValueGetter: (_: any) => $t("storageOverview-resourceCapacityList-changeType-label", { $: "Change Type" })
    },
    {
      field: "taskBusinessId",
      editable: false,
      sortable: true,
      filter: true,
      headerValueGetter: (_: any) => $t("storageOverview-resourceCapacityList-taskBusinessId-label", { $: "Task Business ID" })
    },
    {
      field: "taskName",
      editable: false,
      sortable: true,
      filter: "agTextColumnFilter",
      headerValueGetter: (_: any) => $t("storageOverview-resourceCapacityList-taskName-label", { $: "Task name" })
    },
    {
      field: "stepPosition",
      headerName: "Step position",
      editable: false,
      sortable: true,
      filter: true,
      headerValueGetter: (_: any) => $t("storageOverview-resourceCapacityList-stepPosition-label", { $: "Step position" })
    },
    {
      field: "stepName",
      editable: false,
      sortable: true,
      filter: "agTextColumnFilter",
      headerValueGetter: (_: any) => $t("storageOverview-resourceCapacityList-stepName-label", { $: "Step name" })
    },
    {
      field: "entrySource",
      editable: false,
      sortable: true,
      filter: true,
      valueFormatter: (params: any) => (params.data ? SystemEnumService.resourceCapacityEntrySource(params.data.entrySource) : null),
      headerValueGetter: (_: any) => $t("storageOverview-resourceCapacityList-entrySource-label", { $: "Entry source" })
    },
    {
      field: "contextResourceName",
      editable: false,
      sortable: true,
      filter: "agTextColumnFilter",
      headerValueGetter: (_: any) => $t("storageOverview-resourceCapacityList-contextResourceName-label", { $: "Context resource" })
    }
  ];
}

const ganttReloadKey = ref(0);
watch(
  () => props.filterByProductId,
  () => {
    ganttReloadKey.value++;
  }
);

watch(
  () => props.filterByProductId,
  (newResource) => {
    if (newResource) {
      serverSideDataSource.useFilteringByResourceId(newResource);
      gridWrapperRef.value?.gridApi?.refreshServerSide();
    }
  },
  {
    immediate: true
  }
);

function ganttResourceFilterExpressionProvider() {
  return props.filterByProductId ? [props.filterByProductId] : null;
}

const filterGridAction = useFilterGridAction({ filterKey: "entrySource", gridWrapperRef: gridWrapperRef, filterGridActionRef: filterGridActionRef });

const filterGridActionItems: FilterGridActionItem[] = [
  { value: ResourceCapacityEntrySource.Scheduling, text: translateResourceCapacityEntrySource(ResourceCapacityEntrySource.Scheduling) },
  { value: ResourceCapacityEntrySource.Reservation, text: translateResourceCapacityEntrySource(ResourceCapacityEntrySource.Reservation) },
  { value: ResourceCapacityEntrySource.ExecutionResource, text: translateResourceCapacityEntrySource(ResourceCapacityEntrySource.ExecutionResource) },
  { value: ResourceCapacityEntrySource.Balancing, text: translateResourceCapacityEntrySource(ResourceCapacityEntrySource.Balancing) },
  { value: ResourceCapacityEntrySource.ManualEntry, text: translateResourceCapacityEntrySource(ResourceCapacityEntrySource.ManualEntry) }
];
</script>

<template>
  <v-card class="fill-height" elevation="0">
    <material-movement-dialog
      :visible="isTransactionCreationDialogVisible"
      :selected-material-id="filterByProductId"
      @cancel="onTransactionCreateCanceled"
      @create="onTransactionCreated"
    />
    <split-panel identifier="storage-split-panel-gantt" :sizes="[25,75]" :min-size="[50,50]">
      <template #panel-1>
        <v-row class="fill-height">
          <v-col class="fill-height align-center" cols="12">
            <gantt v-if="filterByProductId" :key="ganttReloadKey" height="100%" hide-tasks :filter-expression-provider="ganttResourceFilterExpressionProvider" />
          </v-col>
        </v-row>
      </template>
      <template #panel-2>
        <v-row class="fill-height">
          <v-col cols="12" class="pt-8">
            <grid-wrapper
              ref="gridWrapperRef"
              identifier="material-transaction"
              server-side
              :server-side-datasource="serverSideDataSource"
              :default-col-def="defaultColDef"
              :expand-after-create="true"
              :master-detail="true"
              refresh-btn
              row-selection="multiple"
              @prepare-columns="onPrepareColumns"
            >
              <template #custom-buttons>
                <v-tooltip location="bottom" open-delay="300">
                  <template #activator="{ props }">
                    <v-btn :disabled="false" size="small" v-bind="props" variant="text" density="compact" @click="onTransactionCreateRequest">
                      <v-icon class="pr-4" icon="mdi-plus" />
                      {{ $t("productOverview-storage-createTransaction-action", { $: "Create Transaction" }) }}
                    </v-btn>
                  </template>
                  <span>{{ $t("productOverview-storage-createTransaction-action-tooltip", { $: "Create Transaction" }) }}</span>
                </v-tooltip>
              </template>
              <template #filter>
                <filter-grid-action ref="filterGridActionRef" :items="filterGridActionItems" @filter-changed="filterGridAction.onFilterGridActionChanged" />
              </template>
            </grid-wrapper>
          </v-col>
        </v-row>
      </template>
    </split-panel>
  </v-card>
</template>

<style></style>
