import { ServerSideDataSource } from "@/components/Grid/ServerSideDataSource";
import ApiService from "@/services/api";
import { State as ScenarioState, useScenariosStore } from "@/store/ScenariosStore";
import {
  CreateOrganizationCommand,
  GetOrganizationsPaginatedQuery,
  GetOrganizationsQuery,
  IAgGridPaginationResponse,
  OrganizationDto,
  UpdateOrganizationCommand
} from "@masta/generated-model";
import { IServerSideGetRowsParams } from "ag-grid-community";
import { Store } from "pinia";

export class OrganizationServerSideDataSource extends ServerSideDataSource<OrganizationDto, OrganizationDto, CreateOrganizationCommand, UpdateOrganizationCommand, string> {
  private _scenarioStore: Store<string, ScenarioState>;

  constructor(id: string) {
    super(id);
    this._scenarioStore = useScenariosStore();
  }

  async create(entity: OrganizationDto): Promise<string> {
    const request = this.transformForCreate(entity);
    const scenario = this._scenarioStore.selectedScenario;
    request.scenarioId = scenario?.id ?? "";
    const response = await ApiService.organizations.create(request);
    return response.data.organizationId;
  }

  async getAll(params: IServerSideGetRowsParams): Promise<IAgGridPaginationResponse<OrganizationDto>> {
    const scenario = this.currentScenario();
    if (!scenario) {
      return { count: 0, results: [] } as IAgGridPaginationResponse<OrganizationDto>;
    }

    const request = {
      ...this.constructServerRequest(params),
      scenarioId: scenario.id
    } as GetOrganizationsPaginatedQuery;

    const response = await ApiService.organizations.getAllPaginated(request);
    return response.data as IAgGridPaginationResponse<OrganizationDto>;
  }

  async getSingle(id: string): Promise<OrganizationDto | undefined> {
    const scenario = this.currentScenario();
    if (!scenario) {
      return undefined;
    }

    const response = await ApiService.organizations.getAll({ scenarioId: scenario.id } as GetOrganizationsQuery);
    return response.data.organizations.find((dto: OrganizationDto) => dto.id === id);
  }

  async update(entity: OrganizationDto): Promise<string> {
    await ApiService.organizations.update(this.transformForUpdate(entity));
    return entity.id;
  }

  async remove(entity: OrganizationDto): Promise<void> {
    await ApiService.organizations.remove(entity.id);
  }

  protected transformForCreate(listEntity: OrganizationDto): CreateOrganizationCommand {
    return {
      name: listEntity.name,
      businessId: listEntity.businessId,
      types: listEntity.types,
      scenarioId: listEntity.scenarioId
    } as CreateOrganizationCommand;
  }

  protected transformForUpdate(listEntity: OrganizationDto): UpdateOrganizationCommand {
    return {
      id: listEntity.id,
      name: listEntity.name,
      businessId: listEntity.businessId,
      types: listEntity.types,
      scenarioId: listEntity.scenarioId,
      tags: listEntity.tags
    } as UpdateOrganizationCommand;
  }

  private currentScenario() {
    return this._scenarioStore.selectedScenario;
  }
}
