import { ApiClient } from "@/services/api/common.api";
import {
  CreateTagCommand,
  FindTagsResponse,
  GetTagsByGroupResponse,
  GetTagsPaginatedQuery,
  GetTagsResponse,
  IAgGridPaginationResponse, IAgGridRequest,
  TagDto,
  UpdateTagCommand
} from "@masta/generated-model";

export default {
  async getTagsPaginated(request: GetTagsPaginatedQuery & IAgGridRequest) {
    return ApiClient.post<IAgGridPaginationResponse<TagDto>>(`/tags/paginated`, request);
  },
  async getTags() {
    return ApiClient.get<GetTagsResponse>(`/tags`);
  },
  async findTags(q: string) {
    return ApiClient.get<FindTagsResponse>(`/tags/query?q=${encodeURIComponent(q)}`);
  },
  async getTagByNameOrId(nameOrId: string) {
    const nameOrIdArg = encodeURIComponent(nameOrId);
    return ApiClient.get<TagDto>(`tags/${nameOrIdArg}`);
  },
  async getTagsByGroup(groupName = "") {
    const groupNameArg = encodeURIComponent(groupName ?? "");
    return ApiClient.get<GetTagsByGroupResponse>(`/tags/group/${groupNameArg}`);
  },
  async createTag(request: CreateTagCommand) {
    return ApiClient.post<string>("/tags", request);
  },
  async updateTag(request: UpdateTagCommand) {
    const nameArg = encodeURIComponent(request.name!);
    return ApiClient.put<string>(`/tags/${nameArg}`, request);
  },
  async deleteTag(name: string) {
    const nameArg = encodeURIComponent(name);
    return ApiClient.delete(`/tags/${nameArg}`);
  }
};
