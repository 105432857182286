<script setup lang="ts">
import { $t } from "@/i18n";
import { AssignModelInstanceToCustomerOrderCommand, DocumentDto } from "@masta/generated-model";
import { ref } from "vue";
import { useDocumentsStore } from "@/store/DocumentsStore";
import ApiService from "@/services/api";
import MediaGallery, { MediaGalleryComponent } from "@/components/Media/MediaGallery.vue";
import { useSnackbarsStore } from "@/store/SnackbarsStore";
import { useScenariosStore } from "@/store/ScenariosStore";

const $props = defineProps<{
  orderId: string;
  contextName: string;
  scenarioId: string;
}>();

const mediaGalleryRef = ref<MediaGalleryComponent | null>(null);

const snackbarStore = useSnackbarsStore();
const documentsStore = useDocumentsStore();
const scenarionStore = useScenariosStore();

async function documentsProvider(contextName: string): Promise<DocumentDto[]> {
  if (!$props.orderId) {
    throw new Error("Order id is required");
  }
  const { data } = await ApiService.documents.getDocumentsAssignedToOrderForContext(contextName, $props.orderId, scenarionStore.selectedScenario?.id ?? "");
  return data.documentDtos;
}

async function onDelete(dto: DocumentDto) {
  if (!$props.orderId) {
    throw new Error("Order id is required");
  }
  try {
    await documentsStore.deassignOrderDocument(dto.id, $props.orderId);
    await mediaGalleryRef.value?.refreshItems();
  } catch (e: any) {
    snackbarStore.createSnackbar({
      message: e && e.detail ? e.detail : $t("resourceMediaGallery-deleteError-message", { $: "Could not delete" }),
      type: "error",
      closeable: true
    });
  }
}

async function onSelected(items: DocumentDto[]) {
  if (items.length > 0 && $props.orderId) {
    for (const item of items) {
      await ApiService.orders.assignModelInstance({
        orderId: $props.orderId,
        modelInstanceId: item.id,
        modelInstanceBusinessId: item.businessId,
        scenarioId: useScenariosStore().selectedScenario?.id
      } as AssignModelInstanceToCustomerOrderCommand);
    }
  }
  await mediaGalleryRef.value?.refreshItems();
}
</script>

<template>
  <media-gallery
    ref="mediaGalleryRef"
    multiple
    :context-name="contextName"
    :documents-provider="documentsProvider"
    :on-delete="onDelete"
    :qr-code-props="{ orderId }"
    @selected="onSelected"
  ></media-gallery>
</template>

<style scoped lang="scss"></style>
