import { Locale } from "@js-joda/locale_en";
import { ChronoUnit, DateTimeFormatter, DayOfWeek, Instant, ZonedDateTime, ZoneId } from "@js-joda/core";

import { ChronoUnitUtils } from "../Util";

import { Position, Resolution } from "./Resolution";

export class ChronoUnitResolution extends Resolution<ChronoUnit> {
  private _textCache: { [key: string]: string } = {};
  private dstStart = false;
  private dstEnd = false;
  private _dtFormat: DateTimeFormatter;

  constructor(temporalUnit: ChronoUnit, format: string, step: number, ...supportedPositions: Position[]) {
    super(temporalUnit, format, step, ...supportedPositions);
    this._dtFormat = DateTimeFormatter.ofPattern(this.format).withLocale(Locale.ENGLISH);
  }

  isDSTStartIncrement() {
    return this.dstStart;
  }

  isDSTEndIncrement() {
    return this.dstEnd;
  }

  formatInstant(instant: Instant, zoneId: ZoneId): string {
    // if (this.format === "M") {
    // TODO handle month?
    // }
    const cacheKey = `${instant.toEpochMilli()}-${zoneId.id()}-${this.format}`;
    let txt: string = this._textCache[cacheKey];
    if (!txt) {
      const zdt = ZonedDateTime.ofInstant(instant, zoneId);
      txt = zdt.format(this._dtFormat);
      this._textCache[cacheKey] = txt;
    }
    return txt;
  }

  increment(instant: Instant, zoneId: ZoneId): Instant {
    let hourBefore;
    let dayBefore;
    let hourAfter;
    let dayAfter;
    let deltaHours;
    this.dstStart = false;
    this.dstEnd = false;
    const unit = this.temporalUnit;
    let time = ZonedDateTime.ofInstant(instant, zoneId);
    const stepRate = this.step;
    // eslint-disable-next-line default-case
    switch (unit) {
      case ChronoUnit.NANOS:
        time = time.plusNanos(stepRate);
        return Instant.from(time);
      case ChronoUnit.SECONDS:
        time = time.plusSeconds(stepRate);
        return Instant.from(time);
      case ChronoUnit.MINUTES:
        time = time.plusMinutes(stepRate);
        return Instant.from(time);
      case ChronoUnit.HOURS:
        hourBefore = Math.max(0, time.hour());
        dayBefore = time.dayOfYear();
        time = time.plusHours(stepRate);
        hourAfter = Math.min(23, time.hour());
        dayAfter = time.dayOfYear();
        deltaHours = hourAfter - hourBefore;
        if (dayBefore === dayAfter) {
          if (deltaHours < stepRate) {
            this.dstEnd = true;
          } else if (deltaHours > stepRate) {
            this.dstStart = true;
          }
        }
        return Instant.from(time);
      case ChronoUnit.DAYS:
        time = time.plusDays(stepRate);
        return Instant.from(time);
      case ChronoUnit.WEEKS:
        time = time.plusWeeks(stepRate);
        return Instant.from(time);
      case ChronoUnit.MONTHS:
        time = time.plusMonths(stepRate);
        return Instant.from(time);
      case ChronoUnit.YEARS:
        time = time.plusYears(stepRate);
        return Instant.from(time);
      case ChronoUnit.DECADES:
        time = time.plusYears(stepRate * 10);
        return Instant.from(time);
      case ChronoUnit.CENTURIES:
        time = time.plusYears(stepRate * 100);
        return Instant.from(time);
      case ChronoUnit.MILLENNIA:
        time = time.plusYears(stepRate * 1000);
        return Instant.from(time);
    }
    return instant.plus(this.temporalUnit.duration().multipliedBy(stepRate));
  }

  decrement(instant: Instant, zoneId: ZoneId): Instant {
    let hourBefore;
    let dayBefore;
    let hourAfter;
    let dayAfter;
    let deltaHours;
    this.dstStart = false;
    this.dstEnd = false;
    const unit = this.temporalUnit;
    let time = ZonedDateTime.ofInstant(instant, zoneId);
    const stepRate = this.step;
    // eslint-disable-next-line default-case
    switch (unit) {
      case ChronoUnit.NANOS:
        time = time.minusNanos(stepRate);
        return Instant.from(time);
      case ChronoUnit.SECONDS:
        time = time.minusSeconds(stepRate);
        return Instant.from(time);
      case ChronoUnit.MINUTES:
        time = time.minusMinutes(stepRate);
        return Instant.from(time);
      case ChronoUnit.HOURS:
        hourBefore = Math.max(0, time.hour());
        dayBefore = time.dayOfYear();
        time = time.minusHours(stepRate);
        hourAfter = Math.min(23, time.hour());
        dayAfter = time.dayOfYear();
        deltaHours = hourAfter - hourBefore;
        if (dayBefore === dayAfter) {
          if (deltaHours < stepRate) {
            this.dstEnd = true;
          } else if (deltaHours > stepRate) {
            this.dstStart = true;
          }
        }
        return Instant.from(time);
      case ChronoUnit.DAYS:
        time = time.minusDays(stepRate);
        return Instant.from(time);
      case ChronoUnit.WEEKS:
        time = time.minusWeeks(stepRate);
        return Instant.from(time);
      case ChronoUnit.MONTHS:
        time = time.minusMonths(stepRate);
        return Instant.from(time);
      case ChronoUnit.YEARS:
        time = time.minusYears(stepRate);
        return Instant.from(time);
      case ChronoUnit.DECADES:
        time = time.minusYears(stepRate * 10);
        return Instant.from(time);
      case ChronoUnit.CENTURIES:
        time = time.minusYears(stepRate * 100);
        return Instant.from(time);
      case ChronoUnit.MILLENNIA:
        time = time.minusYears(stepRate * 1000);
        return Instant.from(time);
    }
    return instant.minus(this.temporalUnit.duration().multipliedBy(stepRate));
  }

  truncateInstant(instant: Instant, zoneId: ZoneId, dayOfWeek: DayOfWeek): Instant {
    const truncated = ChronoUnitUtils.truncateZonedTime(instant.atZone(zoneId), this.temporalUnit, this.step, dayOfWeek);
    return Instant.from(truncated);
  }
}
