<template>
  <master-detail-layout scenario-selector>
    <template #master>
      <v-card elevation="0" class="flexcard fill-height d-flex">
        <v-card-title class="pb-0">
          <breadcrumbs>
            <span>{{ $t("gantt-view-ganttName-title", { ganttName, $: "{ganttName} Gantt" }) }}</span>
            <div class="d-flex align-end pl-4 align-center" style="margin-top: -4px;">
              <gantt-time-section-aggregation-select />
              <filter-grid-action ref="filterGridActionRef" separator size="x-small" :items="filterGridActionItems" @filter-changed="onResourceTypeFilterChanged" />
            </div>
          </breadcrumbs>
        </v-card-title>
        <v-card-text class="pb-0">
          <gantt v-if="renderGanttComponent" height="calc(100vh - 124px - 25px)" />
          <!--          <resource-gantt />-->
        </v-card-text>
      </v-card>
    </template>
  </master-detail-layout>
</template>

<script lang="ts" setup>
import Gantt from "@/components/Gantt/Gantt.vue";
import FilterGridAction, { FilterGridActionItem, FilterGridActionItemValue } from "@/components/Grid/Filters/FilterGridAction.vue";
import Breadcrumbs from "@/components/Layout/Breadcrumbs.vue";
import MasterDetailLayout from "@/components/Layout/MasterDetailLayout.vue";
import { translateResourceType } from "@/composables/translateEnum";
import { $t } from "@/i18n";
import SystemEnumService from "@/services/system-enum.service";
import { ResourceType } from "@masta/generated-model";
import { computed, nextTick, onBeforeMount, onMounted, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import GanttTimeSectionAggregationSelect from "@/components/Gantt/GanttTimeSectionAggregationSelect.vue";

const title = ref("Resources gantt");
const types = ref<string[]>([]);

const filterGridActionRef = ref<typeof FilterGridAction>();

const renderGanttComponent = ref(true);

const filterGridActionItems: FilterGridActionItem[] = [
  { value: [ResourceType.Person, ResourceType.PersonGroup], text: translateResourceType(ResourceType.Person) },
  { value: [ResourceType.Agreement, ResourceType.AgreementGroup], text: translateResourceType(ResourceType.Agreement) },
  { value: ResourceType.Calendar, text: translateResourceType(ResourceType.Calendar) },
  { value: [ResourceType.Equipment, ResourceType.EquipmentGroup], text: translateResourceType(ResourceType.Equipment) },
  { value: [ResourceType.Material, ResourceType.MaterialGroup], text: translateResourceType(ResourceType.Material) },
  { value: ResourceType.Location, text: translateResourceType(ResourceType.Location) },
  { value: ResourceType.Asset, text: translateResourceType(ResourceType.Asset) },
  { value: ResourceType.Service, text: translateResourceType(ResourceType.Service) }
];

const router = useRouter();
const route = useRoute();

const ganttName = computed(() => {
  const { query } = route;
  if (query && query.types) {
    let queryTypes = [];
    if (!Array.isArray(query.types)) {
      queryTypes = [query.types];
    } else {
      queryTypes = query.types;
    }
    queryTypes = queryTypes.map((x) => SystemEnumService.resourceType(+x)).filter((x) => !x.toLowerCase().includes("group"));
    if (queryTypes.length >= 1) {
      return queryTypes[0];
    }
  }
  return $t("gantt-view-ganttName-resources", { $: "Resources" });
});

onBeforeMount(() => {
  const { query } = route;
  if (query && query.types) {
    if (!Array.isArray(query.types)) {
      types.value = [query.types];
    } else {
      types.value = query.types;
    }
  }
});

onMounted(() => {
  const queryTypes = types.value.map((x) => Number(x));
  filterGridActionRef.value?.setFilterModel(queryTypes);
});

watch(
  () => route.query.timeSectionAggregation,
  () => {
    forceRerenderGantt();
  }
);

function onResourceTypeFilterChanged(filterAction: FilterGridActionItemValue[] | undefined) {
  if (filterAction) {
    const queryTypes = filterAction.map((x) => String(x));

    // rewrite query - change types parameters
    router.push({ query: { ...route.query, types: queryTypes } });
  } else {
    // remove types parameter from query
    router.push({ query: { ...route.query, types: undefined } });
  }

  // force rerender gantt
  forceRerenderGantt();
}

async function forceRerenderGantt() {
  renderGanttComponent.value = false;

  nextTick(() => {
    renderGanttComponent.value = true;
  });
}
</script>

<style lang="scss"></style>
