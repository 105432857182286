<script setup lang="ts">
import { JoinParameters } from "@masta/generated-model";
import { $t } from "@/i18n";

const model = defineModel<JoinParameters>({ required: true });

const props = defineProps<{
  disabled: boolean;
}>();
</script>

<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        {{ $t("joinParameters-editor-title-label", { $: "Join Parameters:" }) }}
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-text-field
          v-model="model.matchCriterion"
          variant="outlined"
          density="compact"
          hide-details
          :label="$t('joinParameters-matchCriterion-label', { $: 'Match Criterion' })"
          :disabled="disabled"
        />
      </v-col>
    </v-row>
  </v-container>
</template>
