import { ApiClient } from "@/services/api/common.api";
import {
  CreateOrUpdateProductTemplateCommand,
  CreateStepResourceSpecTemplateDto,
  CreateStepTemplateDto,
  FindProductTemplateNameResult,
  GetProductTemplateForResourceResult,
  GetProductTemplatesPaginatedQuery,
  GetProductTemplatesResponse as GetProductTemplatesResponse,
  GetProductTemplateStepResourceSpecsPaginatedQuery,
  GetProductTemplateStepsPaginatedQuery,
  GetProductTemplateUsableResourcesPaginatedQuery,
  IAgGridPaginationResponse,
  ProductTemplateDetailsDto,
  ProductTemplateDto,
  ProductTemplateHistoricalPerformanceResult,
  ResourceDto,
  StepResourceSpecDto,
  StepTemplateDto
} from "@masta/generated-model";

export default {
  async getAll(scenarioId: string) {
    return await ApiClient.get<GetProductTemplatesResponse>(`/ProductTemplates?scenarioId=${scenarioId}`);
  },
  async getById(id: string, scenarioId: string) {
    return await ApiClient.get<ProductTemplateDto>(`/ProductTemplates/${id}?scenarioId=${scenarioId}`);
  },
  async getEfficiencyById(id: string, scenarioId: string) {
    return await ApiClient.get<ProductTemplateHistoricalPerformanceResult>(`/ProductTemplates/${id}/historical-performance?scenarioId=${scenarioId}`);
  },
  async getCurrentForResource(id: string, scenarioId: string) {
    return await ApiClient.get<GetProductTemplateForResourceResult>(`/ProductTemplates/resource/${id}/current?scenarioId=${scenarioId}`);
  },
  async getForResource(id: string, scenarioId: string) {
    return await ApiClient.get<GetProductTemplateForResourceResult>(`/ProductTemplates/resource/${id}?scenarioId=${scenarioId}`);
  },
  async getPaginated(request: GetProductTemplatesPaginatedQuery) {
    return await ApiClient.post<IAgGridPaginationResponse<ProductTemplateDto>>(`/ProductTemplates/query`, request);
  },
  async create(request: CreateOrUpdateProductTemplateCommand) {
    return await ApiClient.post<string>(`/ProductTemplates/`, request);
  },
  async update(request: CreateOrUpdateProductTemplateCommand) {
    const id = request.id;
    return await ApiClient.put(`/ProductTemplates/${id}`, request);
  },
  async remove(id: string, scenarioId: string) {
    return await ApiClient.delete(`/ProductTemplates/${id}?scenarioId=${scenarioId}`);
  },
  async getDetails(scenarioId: string, taskId: string) {
    return await ApiClient.get<ProductTemplateDetailsDto>(`/ProductTemplates/${taskId}/details?scenarioId=${scenarioId}`);
  },
  async getStepsPaginated(request: GetProductTemplateStepsPaginatedQuery) {
    return await ApiClient.post<IAgGridPaginationResponse<StepTemplateDto>>(`/ProductTemplates/query/steps`, request);
  },
  async createStep(scenarioId: string, taskId: string, step: CreateStepTemplateDto) {
    return await ApiClient.post<string>(`/ProductTemplates/${taskId}/steps?scenarioId=${scenarioId}`, step);
  },
  async updateStep(taskId: string, stepId: string, scenarioId: string, step: CreateStepTemplateDto) {
    return await ApiClient.put<string>(`/ProductTemplates/${taskId}/steps/${stepId}?scenarioId=${scenarioId}`, step);
  },
  async deleteStep(taskId: string, stepId: string, scenarioId: string) {
    return await ApiClient.delete<boolean>(`/ProductTemplates/${taskId}/steps/${stepId}?scenarioId=${scenarioId}`);
  },
  async getStepResourceSpecsPaginated(request: GetProductTemplateStepResourceSpecsPaginatedQuery) {
    return await ApiClient.post<IAgGridPaginationResponse<StepResourceSpecDto>>(`/ProductTemplates/query/stepResourceSpecs`, request);
  },
  async createResourceSpec(scenarioId: string, taskId: string, stepId: string, spec: CreateStepResourceSpecTemplateDto) {
    return await ApiClient.post<string>(`/ProductTemplates/${taskId}/steps/${stepId}/specs?scenarioId=${scenarioId}`, spec);
  },
  async updateResourceSpec(scenarioId: string, taskId: string, stepId: string, resourceSpecId: string, spec: CreateStepResourceSpecTemplateDto) {
    return await ApiClient.put<string>(`/ProductTemplates/${taskId}/steps/${stepId}/specs/${resourceSpecId}?scenarioId=${scenarioId}`, spec);
  },
  async deleteResourceSpec(scenarioId: string, taskId: string, stepId: string, resourceSpecId: string) {
    return await ApiClient.delete<boolean>(`/ProductTemplates/${taskId}/steps/${stepId}/specs/${resourceSpecId}?scenarioId=${scenarioId}`);
  },
  async getUsableResourcesPaginated(request: GetProductTemplateUsableResourcesPaginatedQuery) {
    return await ApiClient.post<IAgGridPaginationResponse<ResourceDto>>(`/ProductTemplates/query/usableSpecResources`, request);
  },
  async release(scenarioId: string, taskId: string) {
    return await ApiClient.post(`/ProductTemplates/${taskId}/release`, { taskId, scenarioId });
  },
  async bulkRelease(scenarioId: string, taskIds: string[]) {
    return await ApiClient.post(`/ProductTemplates/release`, { taskIds, scenarioId });
  },
  async unrelease(scenarioId: string, taskId: string) {
    return await ApiClient.post(`/ProductTemplates/${taskId}/unrelease`, { taskId, scenarioId });
  },
  async archive(scenarioId: string, taskId: string) {
    return await ApiClient.post(`/ProductTemplates/${taskId}/archive`, { taskId, scenarioId });
  },
  async createNewVersion(scenarioId: string, taskId: string) {
    return await ApiClient.post<string>(`/ProductTemplates/${taskId}/createNewVersion`, { taskId, scenarioId });
  },
  async copyToNewProduct(scenarioId: string, taskId: string, resourceId: string) {
    return await ApiClient.post<string>(`/ProductTemplates/${taskId}/copyToNewProduct`, { taskId, scenarioId, resourceId });
  },
  async findName(scenarioId: string, q: string) {
    return ApiClient.get<FindProductTemplateNameResult>(`/ProductTemplates/query/name?scenarioId=${scenarioId}&q=${encodeURIComponent(q)}`);
  },
  async findStepName(scenarioId: string, q: string) {
    return ApiClient.get<FindProductTemplateNameResult>(`/ProductTemplates/query/stepName?scenarioId=${scenarioId}&q=${encodeURIComponent(q)}`);
  }
};
