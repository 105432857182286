<script lang="ts" setup>
import GridWrapper from "@/components/Grid/GridWrapper.vue";
import { GridWrapperComponent } from "@/components/Grid/GridWrapperComponent";
import { ref } from "vue";
import { $t } from "@/i18n";
import type { CellClassParams } from "ag-grid-community";
import { RowClassParams, ValueFormatterParams } from "ag-grid-community";
import { translateMaterialDemandQuantityType, translateMeasurementUnit } from "@/composables/translateEnum";
import ApiService from "@/services/api";
import { useErrorsStore } from "@/store/ErrorsStore";
import { GetMaterialsDemandQuery, InternalCustomerOrderPredictionDto, MaterialDemandItemDto, MaterialDemandQuantityType, PurchaseOrderPredictionDto } from "@masta/generated-model";
import MaterialsDemandCalculateDialog from "@/components/MaterialsDemand/MaterialsDemandCalculateDialog.vue";
import ActionsButton from "@/components/Layout/ActionsButton.vue";
import PurchaseOrdersPredictionDialog from "@/components/MaterialsDemand/PurchaseOrdersPredictionDialog.vue";
import InternalCustomerOrdersPredictionDialog from "@/components/MaterialsDemand/InternalCustomerOrdersPredictionDialog.vue";

const gridWrapperRef = ref<GridWrapperComponent>();
const errorsStore = useErrorsStore();
const data = ref<MaterialDemandItemDto[]>([]);
const purchasePredictions = ref<PurchaseOrderPredictionDto[]>([]);
const internalCustomerOrderPredictions = ref<InternalCustomerOrderPredictionDto[]>([]);
const calculateDialog = ref(false);
const createPurchaseOrdersDialog = ref(false);
const createInternalCustomerOrdersDialog = ref(false);
const query = ref<GetMaterialsDemandQuery>();

async function onRefreshAction() {
  await onFetchData();
}

async function onFetchData() {
  await fetchData();
}

async function fetchData() {
  if (!query.value) {
    return;
  }
  try {
    const response = await ApiService.planning.getMaterialsDemand(query.value);
    data.value = response.data?.items ?? [];
    purchasePredictions.value = response.data?.purchaseOrderPredictions ?? [];
    internalCustomerOrderPredictions.value = response.data?.internalCustomerOrderPredictions ?? [];
  } catch (e) {
    errorsStore.handleError(e);
    throw e;
  }
}

function onPrepareColumns(columnDefs: any) {
  columnDefs.value = [
    {
      field: "resourceId",
      headerName: "Resource Id",
      editable: false,
      sortable: true,
      filter: "agTextColumnFilter",
      type: "textInputTypeColumn",
      headerValueGetter: (_: any) => $t("materialsDemand-list-resourceId-label", { $: "ResourceId" })
    },
    {
      field: "resourceBusinessId",
      headerName: "Resource Business Id",
      editable: false,
      sortable: true,
      filter: "agTextColumnFilter",
      type: "textInputTypeColumn",
      headerValueGetter: (_: any) => $t("materialsDemand-list-resourceBusinessId-label", { $: "Resource Business Id" })
    },
    {
      field: "resourceColor",
      headerName: "Resource Color",
      editable: false,
      sortable: true,
      filter: "agTextColumnFilter",
      type: ["colorTypeColumn", "textFloatingFilterColumnType"],
      headerValueGetter: (_: any) => $t("materialsDemand-list-resourceColor-label", { $: "Resource Color" }),
      floatingFilterComponentParams: {
        placeholder: $t("materialsDemand-list-color-label", { $: "Color" })
      }
    },
    {
      field: "periodName",
      headerName: "PeriodName",
      editable: false,
      sortable: true,
      filter: "agTextColumnFilter",
      type: "textInputTypeColumn",
      headerValueGetter: (_: any) => $t("materialsDemand-list-period-label", { $: "Period" })
    },
    {
      field: "quantity",
      headerName: "Quantity",
      sortable: true,
      editable: false,
      filter: "agNumberColumnFilter",
      resizable: true,
      type: ["textInputTypeColumn", "textFloatingFilterColumnType"],
      valueFormatter: (params: ValueFormatterParams) => {
        return params.value ? params.value.toFixed(1) : null;
      },
      headerValueGetter: (_: any) => $t("materialsDemand-list-quantity-label", { $: "Quantity" }),
      cellStyle: (params: CellClassParams) => {
        if (params.node.groupData) {
          const key = Object.keys(params.node.groupData).filter(k => k.includes("quantityType"));
          if (key.length > 0) {
            const value = params.node.groupData[key[0]];
            if (value === MaterialDemandQuantityType.Balance) {
              if (params.value < 0) {
                return { backgroundColor: "#bb333380", fontWeight: 'bold'};
              } else if (params.value > 0) {
                return { backgroundColor: "#4caf5073", fontWeight: 'bold'};
              }
            }
          }
        }
        return null;
      }
    },
    {
      field: "quantityType",
      type: "enumTypeColumn",
      editable: false,
      resizable: true,
      sortable: true,
      headerValueGetter: (_: any) => $t("materialsDemand-list-quantityType-label", { $: "Quantity Type" }),
      valueFormatter: (params: ValueFormatterParams) => translateMaterialDemandQuantityType(params.value)
    },
    {
      field: "unit",
      type: "enumTypeColumn",
      editable: false,
      resizable: true,
      sortable: true,
      headerValueGetter: (_: any) => $t("materialsDemand-list-unit-label", { $: "Unit" }),
      valueFormatter: (params: ValueFormatterParams) => translateMeasurementUnit(params.value)
    }
  ];
}

const defaultColDef = ref({
  // allow every column to be aggregated
  enableValue: true,
  // allow every column to be grouped
  enableRowGroup: true,
  // allow every column to be pivoted
  enablePivot: true,
  floatingFilter: true,
  filterParams: {
    applyMiniFilterWhileTyping: true
  }
});

function triggerCalculateAction() {
  calculateDialog.value = true;
}

function triggerCreatePurchaseOrdersAction() {
  createPurchaseOrdersDialog.value = true;
}

function triggerCreateInternalCustomerOrdersAction() {
  createInternalCustomerOrdersDialog.value = true;
}

async function calculationParamsSet(queryFromDialog: GetMaterialsDemandQuery) {
  calculateDialog.value = false;
  query.value = queryFromDialog;
  await fetchData();
}

const detailActions = ref([
  {
    title: $t("materialsDemand-list-calculate-action", { $: "1. Calculate Material Demands" }),
    tooltip: $t("materialsDemand-list-calculate-action", { $: "1. Calculate Material Demands" }),
    action: triggerCalculateAction,
    icon: "mdi-calculator",
    disabled: () => false,
    order: 1
  },
  {
    title: $t("materialsDemand-list-createPurchaseOrders-action", { $: "2. Create Purchase Orders" }),
    tooltip: $t("materialsDemand-list-createPurchaseOrders-action", { $: "2. Create Purchase Orders" }),
    action: triggerCreatePurchaseOrdersAction,
    icon: "mdi-cart-arrow-down",
    disabled: () => data.value.length === 0,
    order: 2
  },
  {
    title: $t("materialsDemand-list-createInternalCustomerOrders-action", { $: "3. Create Internal Customer Orders" }),
    tooltip: $t("materialsDemand-list-createInternalCustomerOrders-action", { $: "3. Create Internal Customer Orders" }),
    action: triggerCreateInternalCustomerOrdersAction,
    icon: "mdi-cogs",
    disabled: () => data.value.length === 0,
    order: 3
  }
]);

function getRowStyle(params: RowClassParams<MaterialDemandItemDto>) {
  if (params.node.groupData) {
    const key = Object.keys(params.node.groupData).filter(k => k.includes("quantityType"));
    if (key.length > 0) {
      const value = params.node.groupData[key[0]];
      if (value === MaterialDemandQuantityType.Demand) {
        return { background: "#FFEB3B33" };
      } else if (value === MaterialDemandQuantityType.Supply) {
        return { background: "#9a89fb33" };
      } else if (value === MaterialDemandQuantityType.Initial) {
        return { background: "#82B1FF33" };
      }
    }
  }
}
</script>

<template>
  <grid-wrapper
    ref="gridWrapperRef"
    identifier="materials-demand"
    :enable-charts="true"
    refresh-btn
    enable-range-selection
    enable-columns-tool-panel
    enable-filters-tool-panel
    row-selection="multiple"
    :grid-data="data"
    group-display-type="multipleColumns"
    :pivot-mode="false"
    :default-col-def="defaultColDef"
    :hide-custom-actions-separator="true"
    :checkbox-selection="false"
    disable-actions-column
    :pivot-default-expanded="-1"
    :group-default-expanded="-1"
    :group-hide-open-parents="true"
    :get-row-style="getRowStyle"
    @fetch-data="onFetchData"
    @refresh-action="onRefreshAction"
    @prepare-columns="onPrepareColumns"
  >
    <template #custom-buttons>
      <div class="d-inline-flex pr-4">
        <actions-button :model-value="detailActions" />
      </div>
      <materials-demand-calculate-dialog v-model="calculateDialog" @calculation-params-set="calculationParamsSet" />
      <purchase-orders-prediction-dialog v-model="createPurchaseOrdersDialog" :purchase-order-prediction-dtos="purchasePredictions" @purchase-orders-created="fetchData" />
      <internal-customer-orders-prediction-dialog
        v-model="createInternalCustomerOrdersDialog" :internal-customer-order-prediction-dtos="internalCustomerOrderPredictions"
        @internal-customer-orders-created="fetchData" />
    </template>
  </grid-wrapper>
</template>
