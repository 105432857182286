import { GanttEvents, GanttSettings, SettingKey, TimelineManager, TimeLineStyleSetting } from "../../Core";
import { GanttDomElement } from "../GanttDomElement";
import { inject, injectable } from "inversify";
import { DatelineScaleBlock } from "./DatelineScaleBlock";

@injectable()
export class DatelineBlock extends GanttDomElement<HTMLDivElement> {
  private rowMap = new Map<number, DatelineScaleBlock>();

  private _width = 0;

  constructor(@inject(TimelineManager) private _timelineManager: TimelineManager, @inject(GanttEvents) private _ganttEvents: GanttEvents, @inject(GanttSettings) private _ganttSettings: GanttSettings) {
    super(DatelineBlock.name, undefined, "dateline");
  }

  async beforeInitialize(): Promise<void> {
    this._width = this.element.clientWidth;
  }

  async afterInitialize(): Promise<void> {
    await super.afterInitialize();

    const timelineStyleSetting = this._ganttSettings.getSetting<TimeLineStyleSetting>(SettingKey.TIMELINE_STYLE);

    this.subscribe(
      this._timelineManager.datelineManager.rowMapChange$ /*.pipe(skip(1))*/
        .subscribe(async ({ rowIndex, scale }) => {
          // console.log("DatelineBlock rowMap$ changed");
          if (this.rowMap.has(rowIndex)) {
            const block = this.rowMap.get(rowIndex);
            block!.datelineScaleManager = scale;
          } else {
            const block = new DatelineScaleBlock(this._ganttEvents, scale, timelineStyleSetting);
            this.rowMap.set(rowIndex, block);
            await this.addChildren([block]);
            await this.appendChild(block);
          }
          this.batchDraw(true);
        })
    );
  }
}
