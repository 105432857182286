<script setup lang="ts">
import { $t } from "@/i18n";
import { GanttNoteDefinition } from "@masta/generated-model";

const model = defineModel<GanttNoteDefinition>({ required: true });

defineProps<{
  disabled: boolean;
}>();
</script>

<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        {{ $t("ganttNote-editor-title-label", { $: "Note:" }) }}
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-text-field v-model="model.name" variant="outlined" density="compact" hide-details :label="$t('ganttNote-name-label', { $: 'Name' })" :disabled="disabled" />
      </v-col>
      <v-col cols="12">
        <v-menu :close-on-content-click="false">
          <template #activator="{ props }">
            <v-text-field
              :model-value="model.color"
              v-bind="props"
              :label="$t('ganttNote-color-label', { $: 'Color' })"
              :disabled="disabled"
              readonly
              variant="outlined"
              density="compact"
              hide-details="auto"
            >
              <template #prepend-inner>
                <v-sheet :style="{ backgroundColor: model.color }" width="1em" height="1em" rounded></v-sheet>
              </template>
            </v-text-field>
          </template>
          <v-color-picker v-model="model.color" mode="hex" show-swatches hide-canvas hide-sliders hide-inputs :disabled="disabled" />
        </v-menu>
      </v-col>
      <v-col cols="12">
        <v-textarea v-model="model.text" variant="outlined" density="compact" hide-details :label="$t('ganttNote-text-label', { $: 'Text' })" :disabled="disabled" />
      </v-col>
    </v-row>
  </v-container>
</template>
