﻿import ApiService from "@/services/api";
import { ServerSideDataSource } from "@/components/Grid/ServerSideDataSource";
import { ServiceDto, IAgGridPaginationResponse } from "@masta/generated-model";
import { IServerSideGetRowsParams } from "ag-grid-enterprise";
import { Store } from "pinia";
import { State as ScenarioState, useScenariosStore } from "@/store/ScenariosStore";

export class ServiceServerSideDataSource extends ServerSideDataSource<ServiceDto> {
  private _scenarioStore: Store<string, ScenarioState>;

  constructor(id: string) {
    super(id);
    this._scenarioStore = useScenariosStore();
  }

  async create(entity: ServiceDto): Promise<string | undefined> {
    const request = this.transformForCreate(entity);
    const scenario = this._scenarioStore.selectedScenario;
    request.scenarioId = scenario?.id ?? "";
    const response = await ApiService.services.create(request);
    return response.data;
  }

  async getAll(params: IServerSideGetRowsParams): Promise<IAgGridPaginationResponse<ServiceDto>> {
    const { data } = await ApiService.services.getPaginated({ ...this.constructServerRequest(params), scenarioId: this._scenarioStore.selectedScenario?.id ?? "" });
    return data;
  }

  async getSingle(id: string): Promise<ServiceDto | undefined> {
    const scenario = this._scenarioStore.selectedScenario;
    if (!scenario) return;
    const response = await ApiService.services.getSingle(id, scenario.id);
    return response.data;
  }

  async remove(entity: ServiceDto): Promise<void> {
    await ApiService.services.remove(entity.id, this._scenarioStore.selectedScenario?.id);
  }

  async update(entity: ServiceDto): Promise<string | undefined> {
    const response = await ApiService.services.update(this.transformForUpdate(entity));
    return response.data;
  }

  protected transformForCreate(listEntity: ServiceDto): ServiceDto {
    return listEntity;
  }

  protected transformForUpdate(listEntity: ServiceDto): ServiceDto {
    return listEntity;
  }
}
