import { injectable } from "inversify";
import { IdentifiableLifecycle } from "../Identifiable";
import { GraphicsMousePositionEvent } from "./GraphicsMousePositionEvent";
import { animationFrameScheduler, BehaviorSubject, distinctUntilChanged, fromEvent, map, Observable, of, share, startWith, Subject, throttleTime } from "rxjs";
import { GraphicsMouseWheelEvent } from "./GraphicsMouseWheelEvent";
import { GraphicsDragEvent } from "./GraphicsDragEvent";
import { TimelineRefreshEvent } from "./TimelineRefreshEvent";
import { RowHoverEvent } from "./RowHoverEvent";
import { RowMouseBtnPressedEvent } from "./RowMouseBtnPressedEvent";
import { MouseBtnPressedEvent } from "./MouseBtnPressedEvent";
import { LassoEvent, LassoRowSelectionEvent } from "./LassoEvent";
import { DatelineHoverEvent } from "./DatelineHoverEvent";
import { TooltipActivity } from "../View";
import { ActivityActionEvent } from "./ActivityActionEvent";
import { DragAndDropData } from "../../View";

@injectable()
export class GanttEvents extends IdentifiableLifecycle {
  public readonly devicePixelRatio$ =
    typeof window === "object"
      ? fromEvent(window, "resize").pipe(
        map(() => window.devicePixelRatio),
        distinctUntilChanged()
      )
      : of(1);
  private readonly _mousePositionEvent$$ = new Subject<GraphicsMousePositionEvent>();
  public readonly mousePositionEvent$ = this._mousePositionEvent$$.asObservable().pipe(throttleTime(0, animationFrameScheduler), share());
  private readonly _mouseLeaveEvent$$ = new Subject<GraphicsMousePositionEvent>();
  public readonly mouseLeaveEvent$ = this._mouseLeaveEvent$$.asObservable().pipe(throttleTime(0, animationFrameScheduler), share());
  private readonly _mouseClickEvent$$ = new Subject<GraphicsMousePositionEvent>();
  public readonly mouseClickEvent$ = this._mouseClickEvent$$.asObservable().pipe(throttleTime(0, animationFrameScheduler), share());
  private readonly _mouseEnterEvent$$ = new Subject<GraphicsMousePositionEvent>();
  public readonly mouseEnterEvent$ = this._mouseEnterEvent$$.asObservable().pipe(throttleTime(0, animationFrameScheduler), share());
  private readonly _mousePressedEvent$$ = new Subject<MouseBtnPressedEvent>();
  public readonly mousePressedEvent$ = this._mousePressedEvent$$.asObservable().pipe(throttleTime(0, animationFrameScheduler), share());
  private readonly _mouseWheelEvent$$ = new Subject<GraphicsMouseWheelEvent>();
  public readonly mouseWheelEvent$ = this._mouseWheelEvent$$.asObservable().pipe(throttleTime(0, animationFrameScheduler), share());
  private readonly _contextMenuEvent$$ = new Subject<GraphicsMousePositionEvent>();
  public readonly contextMenuEvent$ = this._contextMenuEvent$$.asObservable().pipe(throttleTime(0, animationFrameScheduler), share());

  private readonly _panStartEvent$$ = new Subject<GraphicsDragEvent>();
  public readonly panStartEvent$ = this._panStartEvent$$.asObservable().pipe(throttleTime(0, animationFrameScheduler), share());
  private readonly _panMoveEvent$$ = new Subject<GraphicsDragEvent>();
  public readonly panMoveEvent$ = this._panMoveEvent$$.asObservable().pipe(throttleTime(0, animationFrameScheduler), share());
  private readonly _panEndEvent$$ = new Subject<GraphicsDragEvent>();
  public readonly panEndEvent$ = this._panEndEvent$$.asObservable().pipe(throttleTime(0, animationFrameScheduler), share());

  public readonly ganttActivityDrag$$ = new BehaviorSubject<DragAndDropData[] | null>(null);
  public readonly externalActivityDrag$$ = new BehaviorSubject<boolean>(false);
  public readonly dragAndDropEnabled$$ = new BehaviorSubject<boolean>(false);

  private readonly _dragStartEvent$$ = new Subject<DragEvent>();
  public readonly dragStartEvent$$ = this._dragStartEvent$$.asObservable().pipe(throttleTime(0, animationFrameScheduler), share());
  private readonly _dragOverEvent$$ = new Subject<DragEvent>();
  public readonly dragOverEvent$$ = this._dragOverEvent$$.asObservable().pipe(throttleTime(0, animationFrameScheduler), share());
  private readonly _dragEvent$$ = new Subject<DragEvent>();
  public readonly dragEvent$$ = this._dragEvent$$.asObservable().pipe(throttleTime(0, animationFrameScheduler), share());
  private readonly _dragEnterEvent$$ = new Subject<DragEvent>();
  public readonly dragEnterEvent$$ = this._dragEnterEvent$$.asObservable().pipe(throttleTime(0, animationFrameScheduler), share());
  private readonly _dragLeaveEvent$$ = new Subject<DragEvent>();
  public readonly dragLeaveEvent$$ = this._dragLeaveEvent$$.asObservable().pipe(throttleTime(0, animationFrameScheduler), share());
  private readonly _dragEndEvent$$ = new Subject<DragEvent>();
  public readonly dragEndEvent$$ = this._dragEndEvent$$.asObservable().pipe(throttleTime(0, animationFrameScheduler), share());
  private readonly _dropEvent$$ = new Subject<DragEvent>();
  public readonly dropEvent$$ = this._dropEvent$$.asObservable().pipe(throttleTime(0, animationFrameScheduler), share());

  private readonly _timelineRefreshEvent$$ = new Subject<TimelineRefreshEvent>();
  public readonly timelineRefreshEvent$ = this._timelineRefreshEvent$$.asObservable().pipe(share()); //.pipe(share(), throttleTime(0, animationFrameScheduler));
  private readonly _scrollEvent$$ = new Subject<any>();
  public readonly scrollEvent$ = this._scrollEvent$$.asObservable().pipe(throttleTime(0, animationFrameScheduler), share());
  private readonly _rowHoverEvent$$ = new Subject<RowHoverEvent | null>();
  public readonly rowHoverEvent$ = this._rowHoverEvent$$.asObservable().pipe(startWith(null), throttleTime(0, animationFrameScheduler), share());
  private readonly _rowMousePressedEvent$$ = new Subject<RowMouseBtnPressedEvent>();
  public readonly rowMousePressedEvent$ = this._rowMousePressedEvent$$.asObservable().pipe(startWith(null), throttleTime(0, animationFrameScheduler), share());
  private readonly _datelineHoverEvent$$ = new BehaviorSubject<DatelineHoverEvent | null>(null);
  public readonly datelineHoverEvent$ = this._datelineHoverEvent$$.asObservable().pipe(share());
  private readonly _lassoEvent$$ = new Subject<LassoEvent>();
  public readonly lassoEvent$ = this._lassoEvent$$.asObservable().pipe(throttleTime(0, animationFrameScheduler), share());
  private readonly _lassoRowSelectionEvent$$ = new Subject<LassoRowSelectionEvent>();
  public readonly lassoRowSelectionEvent$ = this._lassoRowSelectionEvent$$.asObservable().pipe(throttleTime(0, animationFrameScheduler), share());
  private readonly _rowScaleChangeEvent$$ = new Subject<string>();
  public readonly rowScaleChangeEvent$ = this._rowScaleChangeEvent$$.asObservable().pipe(throttleTime(0, animationFrameScheduler), share());
  private readonly _tooltipActivityEvent$$ = new Subject<TooltipActivity | null>();
  public readonly tooltipActivityEvent$ = this._tooltipActivityEvent$$.asObservable().pipe(startWith(null), share());
  public readonly hoveredActivities$$ = new BehaviorSubject<ActivityActionEvent[]>([]);
  public readonly selectedActivities$$ = new BehaviorSubject<ActivityActionEvent[]>([]);
  public readonly pressedActivities$$ = new BehaviorSubject<ActivityActionEvent[]>([]);

  constructor() {
    super(GanttEvents.name);
  }

  public observeResize(elem: HTMLElement) {
    return new Observable((subscriber) => {
      const ro = new ResizeObserver((entries) => {
        subscriber.next(entries);
      });

      // Observe one or multiple elements
      ro.observe(elem);
      return function unsubscribe() {
        ro.unobserve(elem);
      };
    }).pipe(throttleTime(0, animationFrameScheduler));
  }

  public onMousePositionEvent(event: GraphicsMousePositionEvent) {
    this._mousePositionEvent$$.next(event);
  }

  public onMouseLeaveEvent(event: GraphicsMousePositionEvent) {
    this._mouseLeaveEvent$$.next(event);
  }

  public onMouseClickEvent(event: GraphicsMousePositionEvent) {
    this._mouseClickEvent$$.next(event);
  }

  public onMouseEnterEvent(event: GraphicsMousePositionEvent) {
    this._mouseEnterEvent$$.next(event);
  }

  public onMousePressedEvent(event: MouseBtnPressedEvent) {
    this._mousePressedEvent$$.next(event);
  }

  public onMouseWheelEvent(event: GraphicsMouseWheelEvent) {
    this._mouseWheelEvent$$.next(event);
  }

  public onContextMenuEvent(event: GraphicsMousePositionEvent) {
    this._contextMenuEvent$$.next(event);
  }

  public onPanStartEvent(event: GraphicsDragEvent) {
    this._panStartEvent$$.next(event);
  }

  public onPanMoveEvent(event: GraphicsDragEvent) {
    this._panMoveEvent$$.next(event);
  }

  public onPanEndEvent(event: GraphicsDragEvent) {
    this._panEndEvent$$.next(event);
  }

  public onDragStartEvent(event: DragEvent) {
    this._dragStartEvent$$.next(event);
  }

  public onDragOverEvent(event: DragEvent) {
    this._dragOverEvent$$.next(event);
  }

  public onDragEnterEvent(event: DragEvent) {
    this._dragEnterEvent$$.next(event);
  }

  public onDragLeaveEvent(event: DragEvent) {
    this._dragLeaveEvent$$.next(event);
  }

  public onDragEndEvent(event: DragEvent) {
    this._dragEndEvent$$.next(event);
  }

  public onDropEvent(event: DragEvent) {
    this._dropEvent$$.next(event);
  }

  public onDragEvent(event: DragEvent) {
    this._dragEvent$$.next(event);
  }

  public onTimelineRefreshEvent(event: TimelineRefreshEvent) {
    this._timelineRefreshEvent$$.next(event);
  }

  public onScrollEvent(event: any) {
    this._scrollEvent$$.next(event);
  }

  public onRowHoverEvent(event: RowHoverEvent | null) {
    this._rowHoverEvent$$.next(event);
  }

  public onRowMousePressedEvent(event: RowMouseBtnPressedEvent) {
    this._rowMousePressedEvent$$.next(event);
  }

  public onDatelineHoverEvent(event: DatelineHoverEvent | null) {
    this._datelineHoverEvent$$.next(event);
  }

  public onLassoEvent(event: LassoEvent) {
    this._lassoEvent$$.next(event);
  }

  public onLassoRowSelectionEvent(event: LassoRowSelectionEvent) {
    this._lassoRowSelectionEvent$$.next(event);
  }

  public onRowScaleChangeEvent(rowId: string) {
    this._rowScaleChangeEvent$$.next(rowId);
  }

  public onTooltipActivityEvent(activity: TooltipActivity | null) {
    this._tooltipActivityEvent$$.next(activity);
  }

  public addHoveredActivities(events: ActivityActionEvent[]) {
    const currentEvents = this.hoveredActivities$$.getValue();

    const updatedEvents = events.filter((event) => {
      return !currentEvents.some((existingEvent) => this.areActivityEventsEqual(existingEvent, event));
    });

    if (updatedEvents.length > 0) {
      const mergedEvents = [...currentEvents, ...updatedEvents];
      this.hoveredActivities$$.next(mergedEvents);
    }
  }

  public deleteHoveredActivities(events: ActivityActionEvent[]) {
    const currentEvents = this.hoveredActivities$$.getValue();

    const updatedEvents = currentEvents.filter((existingEvent) => {
      return !events.some((event) => this.areActivityEventsEqual(existingEvent, event));
    });

    if (updatedEvents.length !== currentEvents.length) {
      this.hoveredActivities$$.next(updatedEvents);
    }
  }

  public addSelectedActivities(events: ActivityActionEvent[]) {
    const currentEvents = this.selectedActivities$$.getValue();

    const updatedEvents = events.filter((event) => {
      return !currentEvents.some((existingEvent) => this.areActivityEventsEqual(existingEvent, event));
    });

    if (updatedEvents.length > 0) {
      const mergedEvents = [...currentEvents, ...updatedEvents];
      this.selectedActivities$$.next(mergedEvents);
    }
    return updatedEvents.length;
  }

  public deleteSelectedActivities(events: ActivityActionEvent[]) {
    const currentEvents = this.selectedActivities$$.getValue();

    const updatedEvents = currentEvents.filter((existingEvent) => {
      return !events.some((event) => this.areActivityEventsEqual(existingEvent, event));
    });

    if (updatedEvents.length !== currentEvents.length) {
      this.selectedActivities$$.next(updatedEvents);
    }
  }

  public deselectAllActivities() {
    this.selectedActivities$$.next([]);
  }

  public addPressedActivities(events: ActivityActionEvent[]) {
    const currentEvents = this.pressedActivities$$.getValue();

    const updatedEvents = events.filter((event) => {
      return !currentEvents.some((existingEvent) => this.areActivityEventsEqual(existingEvent, event));
    });

    if (updatedEvents.length > 0) {
      const mergedEvents = [...currentEvents, ...updatedEvents];
      this.pressedActivities$$.next(mergedEvents);
    }
  }

  public deletePressedActivities(events: ActivityActionEvent[]) {
    const currentEvents = this.pressedActivities$$.getValue();

    const updatedEvents = currentEvents.filter((existingEvent) => {
      return !events.some((event) => this.areActivityEventsEqual(existingEvent, event));
    });

    if (updatedEvents.length !== currentEvents.length) {
      this.pressedActivities$$.next(updatedEvents);
    }
  }

  // public removeHoveredActivities(rowId: string, activities: string[] = []) {
  //   if (activities.length === 0) this.hoveredActivities$$.next(this.hoveredActivities$$.value.filter((a) => a.rowId !== rowId));
  //   else this.hoveredActivities$$.next(this.hoveredActivities$$.value.filter((a) => !activities.includes(a.activity.id)));
  // }
  //
  // public addSelectedActivities(activities: ActivityActionEvent[]) {
  //   this.selectedActivities$$.next(this.selectedActivities$$.value.concat(activities));
  // }
  //
  // public removeSelectedActivities(rowId: string, activities: string[] = []) {
  //   if (activities.length === 0) this.selectedActivities$$.next(this.selectedActivities$$.value.filter((a) => a.rowId !== rowId));
  //   else this.selectedActivities$$.next(this.selectedActivities$$.value.filter((a) => !activities.includes(a.activity.id)));
  // }
  //
  // public addPressedActivities(activities: ActivityActionEvent[]) {
  //   this.pressedActivities$$.next(this.pressedActivities$$.value.concat(activities));
  // }
  //
  // public removePressedActivities(rowId: string, activities: string[] = []) {
  //   if (activities.length === 0) this.pressedActivities$$.next(this.pressedActivities$$.value.filter((a) => a.rowId !== rowId));
  //   else this.pressedActivities$$.next(this.pressedActivities$$.value.filter((a) => !activities.includes(a.activity.id)));
  // }

  public areActivityEventsEqual(event1?: ActivityActionEvent, event2?: ActivityActionEvent): boolean {
    // Compare the activity, row, and layer properties of the events
    return event1?.activity.id === event2?.activity.id && event1?.row.id === event2?.row.id && event1?.layer.id === event2?.layer.id;
  }
}
