import { GanttDomElement } from "../GanttDomElement";
import { inject, injectable } from "inversify";
import { Instant } from "@js-joda/core";
import { BehaviorSubject, distinctUntilChanged, filter, map, Observable, skip, withLatestFrom } from "rxjs";
import { GanttEvents, GraphicsDragEvent, TimelineManager } from "../../Core";
import { DragState } from "../DragState";
import { DatelineBlock } from "./DatelineBlock";
import { GanttStyleProvider } from "../GanttStyleProvider";

@injectable()
export class TimelineBlock extends GanttDomElement<HTMLDivElement> {
  private _width$$ = new BehaviorSubject<number>(0);
  private _nowInterval: number;
  private _dragState: DragState = { start: 0, end: 0, dragging: false, dragDeltaDifference: 0, previousDragDelta: 0 };
  private _initialDragStartTime: Instant | null = null;

  constructor(
    @inject(GanttEvents) private _ganttEvents: GanttEvents,
    @inject(DatelineBlock) private _datelineBlock: DatelineBlock,
    @inject(TimelineManager) private _timelineManager: TimelineManager,
    @inject(GanttStyleProvider) private _styleProvider: GanttStyleProvider
  ) {
    super(TimelineBlock.name, undefined, "timeline-block");
  }

  get width$(): Observable<number> {
    return this._width$$.asObservable();
  }

  get width(): number {
    return this._width$$.value;
  }

  get timelineManager(): TimelineManager {
    return this._timelineManager;
  }

  async beforeInitialize(): Promise<void> {
    await this.addChild(this._datelineBlock);
  }

  async beforeDestroy(): Promise<void> {
    clearInterval(this._nowInterval);
  }

  async afterInitialize(): Promise<void> {
    this._timelineManager.font = `${this._styleProvider.font}`;
    this._ganttEvents
      .observeResize(this.element)
      // .pipe(debounceTime(100))
      .subscribe(async () => {
        this._width$$.next(this._element.clientWidth);
      });
    this.subscribe(
      this._width$$
        .asObservable()
        .pipe(skip(1))
        .subscribe(async (width) => {
          // console.log("setTimelineWidth", width);
          await this._timelineManager.setTimelineWidth(width);
        })
    );
    this.subscribe(
      this._ganttEvents.timelineRefreshEvent$.subscribe(() => {
        this.batchDraw(true);
      })
    );

    this.subscribe(this._ganttEvents.panStartEvent$.pipe(
      withLatestFrom(this._ganttEvents.dragAndDropEnabled$$),
      filter(([_, isDragAndDropEnabled]) => !isDragAndDropEnabled),
      map(([e, _]) => e)
    ).subscribe(this.onViewportDragStart.bind(this)));
    this.subscribe(this._ganttEvents.panMoveEvent$.pipe(
      withLatestFrom(this._ganttEvents.dragAndDropEnabled$$),
      filter(([_, isDragAndDropEnabled]) => !isDragAndDropEnabled),
      map(([e, _]) => e),
      map(this.mapDragMillis.bind(this)),
      distinctUntilChanged()
    ).subscribe(this.onViewportDrag.bind(this)));
    this.subscribe(this._ganttEvents.panEndEvent$.pipe(
      withLatestFrom(this._ganttEvents.dragAndDropEnabled$$),
      filter(([_, isDragAndDropEnabled]) => !isDragAndDropEnabled),
      map(([e, _]) => e)
    ).subscribe(this.onViewportDragEnd.bind(this)));
  }

  async doDrawFromBatch(): Promise<void> {
    await this._datelineBlock.doDrawFromBatch();
  }

  private onViewportDragStart(e: GraphicsDragEvent): void {
    this._dragState.dragging = true;
    this._dragState.dragDeltaDifference = 0;
    this._initialDragStartTime = this._timelineManager.startTime;
  }

  private mapDragMillis(e: GraphicsDragEvent): number {
    const { deltaX, shiftKey, altKey, ctrlKey } = e;

    // move _timeline
    if (shiftKey || ctrlKey || altKey) return 0;

    return this._timelineManager.millisPerPixel * deltaX;
  }

  private async onViewportDrag(diff: number): Promise<void> {
    if (this._initialDragStartTime) {
      await this._timelineManager.setStartTime(this._initialDragStartTime.plusMillis(-diff));
    }
  }

  private onViewportDragEnd(e: GraphicsDragEvent): void {
    this._dragState.dragging = true;
    this._dragState.dragDeltaDifference = 0;
    this._initialDragStartTime = null;
  }
}
