import { inject, injectable } from "inversify";
import {
  createPaddingInsets,
  GanttSettings,
  IocSymbols,
  IRowChartDataSet,
  type IRowChartScaleProvider,
  Lifecycle,
  PaddingInsets,
  Row,
  RowChartRepository,
  SettingKey,
  TimelineManager
} from "@masta/gantt2/core";
import { ILayerRenderer } from "@masta/gantt2/worker";
import { CustomSettingKeys } from "@/components/Gantt/ResourcesGantt/CustomSettingKeys";

@injectable()
export class DemandChartLayerRenderer extends Lifecycle implements ILayerRenderer {
  public static Identifier = "DemandChartLayerRenderer";
  private _blurOffset = 0.5;
  private _paddingInsets: PaddingInsets;
  private _supplyDataset: IRowChartDataSet | undefined | null;
  private _demandDataset: IRowChartDataSet | undefined | null;
  private _positiveColor: string;
  private _negativeColor: string;

  constructor(
    @inject(Row<any, any, any>) private _row: Row<any, any, any>,
    @inject(TimelineManager) private _timelineManager: TimelineManager,
    @inject(GanttSettings) private _ganttSettings: GanttSettings,
    @inject(RowChartRepository) private _rowChartRepository: RowChartRepository,
    @inject(IocSymbols.RowChartScaleProvider) private _rowChartScaleProvider: IRowChartScaleProvider
  ) {
    super();
  }

  public async afterInitialize(): Promise<void> {
    await super.afterInitialize();

    this.subscribe(this._ganttSettings.getSetting$<PaddingInsets>(SettingKey.ROW_PADDING).subscribe((s) => {
      if (s && s) {
        this._paddingInsets = createPaddingInsets(s.top, s.right, s.bottom, s.left);
      }
    }));
    this.subscribe(this._ganttSettings.getSetting$<string>(CustomSettingKeys.CHART_SUPPLY_DEMAND_POSITIVE_COLOR).subscribe((s) => {
      this._positiveColor = s ?? "green";
    }));
    this.subscribe(this._ganttSettings.getSetting$<string>(CustomSettingKeys.CHART_SUPPLY_DEMAND_NEGATIVE_COLOR).subscribe((s) => {
      this._negativeColor = s ?? "red";
    }));

    this.subscribe(this._rowChartRepository.dataSets$.subscribe(ds => {
      this._supplyDataset = ds.find((x) => x.resourceId === this._row.id && x.id === `supply-${this._row.id}`);
      this._demandDataset = ds.find((x) => x.resourceId === this._row.id && x.id === `demand-${this._row.id}`);
    }));
  }

  render(canvas: OffscreenCanvas | HTMLCanvasElement, context: OffscreenCanvasRenderingContext2D | CanvasRenderingContext2D, params: { scaleValues: number[] }) {
    const width = canvas instanceof HTMLCanvasElement ? canvas.clientWidth : canvas.width;
    const height = canvas instanceof HTMLCanvasElement ? canvas.clientHeight : canvas.height;

    if (!this._demandDataset || !this._demandDataset.visible) {
      return;
    }
    if (!this._supplyDataset || !this._supplyDataset.visible) {
      return;
    }

    const scaleValues = params.scaleValues;
    if (!scaleValues || scaleValues.length === 0) return;

    context.save();

    const paddingInsets = this._paddingInsets;
    const rowHeaderWidth = 0;
    const canvasWidth = width;
    const canvasHeight = height;
    const paddingLeft = rowHeaderWidth + paddingInsets.left;
    const max = scaleValues[0];
    const min = scaleValues[scaleValues.length - 1];
    const bottom = canvasHeight - paddingInsets.bottom;
    const top = paddingInsets.top;
    const zeroPos = this.getYPos(0, min, max, bottom, top);

    const supply = this._supplyDataset.data
      .map((d) => {
        const xPos = Math.round(this._timelineManager.calculateLocationForTimeMillis(d.x));
        return {
          ...d,
          xPos,
          xPosPaddedLeft: xPos + paddingLeft,
          yPos: this.getYPos(d.y, min, max, bottom, top)
        };
      });

    const demand = this._demandDataset.data
      .map((d) => {
        const xPos = Math.round(this._timelineManager.calculateLocationForTimeMillis(d.x));
        return {
          ...d,
          xPos,
          xPosPaddedLeft: xPos + paddingLeft,
          yPos: this.getYPos(d.y, min, max, bottom, top)
        };
      });

    function getDataPoint(dataPoint: any) {
      return {
        x: dataPoint.xPosPaddedLeft < paddingLeft ? paddingLeft : dataPoint.xPosPaddedLeft > canvasWidth ? canvasWidth : dataPoint.xPosPaddedLeft,
        y: dataPoint.yPos,
        p: dataPoint
      };
    }

    const supplyDataPoints = supply.map((dp) => ({
      x: dp.xPosPaddedLeft,
      y: dp.yPos,
      p: dp
    }));

    const supplySegments = [];

    for (let i = 0; i < supplyDataPoints.length; i++) {
      const startPoint = supplyDataPoints[i];
      const endPoint = supplyDataPoints[i + 1];

      if (!endPoint) continue;

      if (startPoint.x == endPoint.x) continue;

      if (startPoint.y !== endPoint.y) continue;

      if (endPoint.x - startPoint.x === 0) continue;

      supplySegments.push({ startX: startPoint.x, endX: endPoint.x, y: startPoint.y, supply: startPoint.p.y, p: startPoint.p });
    }

    const demandDataPoints = demand.map(getDataPoint);


    const demandSegments = [];

    function filterIntersecting(start: number, end: number, segment: { startX: number, endX: number }) {
      return (start < segment.startX && end > segment.startX) ||
        (start >= segment.startX && end < segment.endX) ||
        (start < segment.endX && end > segment.startX);
    }

    function isSegmentYIntersecting(demandValue: number, y: number, supplySegment: { y: number, supply: number }) {
      return (demandValue > supplySegment.supply && y < supplySegment.y);
    }

    for (let i = 0; i < demandDataPoints.length; i++) {
      const startPoint = demandDataPoints[i];
      const endPoint = demandDataPoints[i + 1];

      if (!endPoint) continue;

      if (startPoint.x == endPoint.x) continue;

      if (startPoint.y !== endPoint.y) continue;

      const w = endPoint.x - startPoint.x;
      const h = Math.abs(startPoint.y - zeroPos);

      if (w === 0 || h === 0) continue;

      const ss = supplySegments.filter(s => filterIntersecting(startPoint.x, endPoint.x, s));

      const demandSegmentsSplit = [];

      if (ss.length > 0) {
        // divide demand segment into multiple segments based on intersecting supply segments
        const intersectingSegments = ss.filter(s => isSegmentYIntersecting(startPoint.p.y, startPoint.y, s));
        if (intersectingSegments.length > 0) {

          for (const segment of intersectingSegments) {
            const segmentLength = segment.endX - segment.startX;
            const demandLength = endPoint.x - startPoint.x;

            if (segmentLength > demandLength) {
              const startX = segment.startX < startPoint.x ? startPoint.x : segment.startX;
              const endX = segment.endX > endPoint.x ? endPoint.x : segment.endX;
              const segW = endX - startX;
              demandSegmentsSplit.push({
                x: startX,
                y: startPoint.y,
                w: segW,
                h,
                isOverSupply: startPoint.p.y > segment.supply
              });
            } else {
              const startX = segment.startX < startPoint.x ? startPoint.x : segment.startX;
              const endX = segment.endX > endPoint.x ? endPoint.x : segment.endX;
              const segW = endX - startX;
              demandSegmentsSplit.push({
                x: startX,
                y: startPoint.y,
                w: segW,
                h,
                isOverSupply: startPoint.p.y > segment.supply
              });
            }
          }
        }
      }

      if (demandSegmentsSplit.length > 0) {
        demandSegments.push(...demandSegmentsSplit);
      } else {
        demandSegments.push({ x: startPoint.x, y: startPoint.y, w, h, isOverSupply: false });
      }
    }

    for (let i = 0; i < demandSegments.length; i++) {
      const segment = demandSegments[i];
      // randomize segment color
      // const r = Math.floor(Math.random() * 256);
      // const g = Math.floor(Math.random() * 256);
      // const b = Math.floor(Math.random() * 256);
      // context.fillStyle = `rgba(${r},${g},${b},0.4)`;
      if (segment.isOverSupply) {
        context.fillStyle = this._negativeColor ?? "red";
      } else {
        context.fillStyle = this._positiveColor ?? "green";
      }
      context.fillRect(segment.x, segment.y, segment.w, segment.h);
    }

    context.restore();
  }

  private getYPos(v: number, min: number, max: number, p1: number, p2: number) {
    return Math.round(((v - min) / (max - min)) * (p2 - p1) + p1) + this._blurOffset;
  }
}
