import { HubConnection, HubConnectionBuilder, HubConnectionState, LogLevel } from "@microsoft/signalr";
import { $authService } from "@/services/AuthService";
import { ref, Ref } from "vue";

export class SignalRHub {
  public hubName: string;
  public connection: Ref<HubConnection | null> = ref(null);

  constructor({ hubName }: any) {
    this.hubName = hubName;
  }

  getAuthToken(): Promise<string> {
    return new Promise((resolve) => {
      const checkHandler = async () => {
        if ($authService.authenticated) {
          const token = $authService.token;
          if (token) {
            return resolve(token);
          }
        }
        setTimeout(checkHandler, 500);
      };
      setTimeout(checkHandler, 500);
    });
  }

  async start({ tenantId }: any) {
    if (!this.connection.value) {
      const url = `${import.meta.env.VITE_API_BASE_URL}/hubs/${this.hubName}?tenant=${tenantId}`;
      this.connection.value = new HubConnectionBuilder()
        .withUrl(url, {
          accessTokenFactory: this.getAuthToken.bind(this)
        })
        .configureLogging(LogLevel.Information)
        .withAutomaticReconnect()
        .build();

      this.connection.value.onreconnecting((error) => {
        console.assert(this.connection.value?.state === HubConnectionState.Reconnecting);
        // TODO disable UI here
        console.error("onreconnecting", error);
      });
      this.connection.value.onreconnected((connectionId) => {
        console.assert(this.connection.value?.state === HubConnectionState.Connected);
        // TODO enable UI here
        console.info("onreconnected", connectionId);
      });
      this.connection.value.onclose((error) => {
        console.assert(this.connection.value?.state === HubConnectionState.Disconnected);
        // TODO disable UI here
        console.error("onclose", error);
      });

      this.connection.value.onclose(async () => {
        await this.start({ tenantId });
      });
    }

    if (this.connection.value.state === HubConnectionState.Connected) {
      console.warn("SignalR already connected. Ignoring start() request.");
      return;
    }
    try {
      await this.connection.value.start();
      console.assert(this.connection.value?.state === HubConnectionState.Connected);
      console.log("SignalR Connected.");
    } catch (err) {
      console.assert(this.connection.value.state === HubConnectionState.Disconnected);
      console.log(err);
      setTimeout(async () => await this.start({ tenantId }), 5000);
    }
  }
}

let instance: SignalRHub | null = null;

export const useSignalR = () => instance;

export const SignalRPlugin = {
  install(app: any) {
    if (instance) return;
    instance = new SignalRHub({ hubName: "app" });
    app.provide("signalR", instance);
  }
};
