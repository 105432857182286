<script lang="ts">
import { ICellRendererParams } from 'ag-grid-community';

export default {
  setup(props: { params: ICellRendererParams }) {
    const color = props.params.value;
    return {
      color
    };
  }
};
</script>

<template>
  <div style="width: 100%; height: 100%; display: flex; align-items: center; justify-content: center;" :title="color">
    <div :style="{ backgroundColor: color }"
      style="width: 80%; height: 80%; border-radius: 0.5em; text-align: center; display: flex; align-items: center; justify-content: center;">
    </div>
  </div>
</template>
