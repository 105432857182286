import ApiService from "@/services/api";
import { PersonDto, UpdateResourceModelInstanceAssignmentCommand } from "@masta/generated-model";
import { defineStore } from "pinia";
import { composeHierarchy } from "@/store/helper";
import { useErrorsStore } from "@/store/ErrorsStore";
import { useScenariosStore } from "@/store/ScenariosStore";

interface State {
  personnel: PersonDto[];
}

export const usePersonnelStore = defineStore("personnel", {
  state: (): State => ({
    personnel: []
  }),
  getters: {
    personnelVms: (state) => {
      if (!state.personnel) {
        return [];
      }
      return state.personnel.map((p) => {
        const vm: any = { ...p };
        const hierarchy: any[] = [];
        composeHierarchy(p, state.personnel, hierarchy);
        vm.hierarchy = hierarchy;
        return vm;
      });
    }
  },
  actions: {
    async fetch() {
      try {
        const scenariosStore = useScenariosStore();
        const response = await ApiService.personnel.getAll(scenariosStore.selectedScenario?.id ?? "");
        this.personnel = response.data.personnel ?? [];
      } catch (e) {
        const errorsStore = useErrorsStore();
        errorsStore.handleError(e);
        throw e;
      }
    },
    async updateModelInstanceAssignment(request: UpdateResourceModelInstanceAssignmentCommand) {
      try {
        await ApiService.resources.updateModelInstanceAssignment(request);
        await this.fetch();
      } catch (e) {
        const errorsStore = useErrorsStore();
        errorsStore.handleError(e);
        throw e;
      }
    }
  }
});
