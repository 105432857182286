import { ApiClient } from "@/services/api/common.api";
import {
  ArchiveModelInstanceCommand,
  CreateModelInstanceCommand,
  CreateNewModelInstanceRevisionCommand,
  GetAllModelInstancesDto,
  GetAllModelSchemasDto,
  GetModelInstanceIdsQueryResult,
  GetModelInstancesPaginatedQuery,
  IAgGridPaginationResponse,
  MakeModelInstanceCopyCommand,
  ModelInstanceAssignmentsDto,
  ModelInstanceDto,
  ReleaseModelInstanceCommand,
  UpdateModelInstanceCommand
} from "@masta/generated-model";

export default {
  async getSchemas() {
    return await ApiClient.get<GetAllModelSchemasDto>("/modelinstance/schemas");
  },
  async getSchemaInstances(schemaId: string, schemaVersion: string) {
    return await ApiClient.get<GetAllModelInstancesDto>(`/modelinstance?schemaId=${encodeURIComponent(schemaId)}&schemaVersion=${encodeURIComponent(schemaVersion)}`);
  },
  async getModelInstancesPaginated(request: GetModelInstancesPaginatedQuery) {
    return await ApiClient.post<IAgGridPaginationResponse<ModelInstanceDto>>(`/modelinstance/query`, request);
  },
  async getModelInstanceIds(schemaId: string, schemaVersion: string) {
    return await ApiClient.get<GetModelInstanceIdsQueryResult>(`/modelinstance/ids?schemaId=${encodeURIComponent(schemaId)}&schemaVersion=${encodeURIComponent(schemaVersion)}`);
  },
  async createInstance(request: CreateModelInstanceCommand) {
    return ApiClient.post("/modelinstance", request);
  },
  async updateInstance(request: UpdateModelInstanceCommand) {
    return ApiClient.put(`/modelinstance/${request.id}/${request.revisionNumber}`, request);
  },
  async deleteInstance(id: string, revision: number) {
    return ApiClient.delete(`/modelinstance/${id}/${revision}`);
  },
  async makeCopy(request: MakeModelInstanceCopyCommand) {
    return ApiClient.post(`/modelinstance/${request.id}/${request.revisionNumber}/makeCopy`, request);
  },
  async release(request: ReleaseModelInstanceCommand) {
    return ApiClient.post(`/modelinstance/${request.id}/${request.revisionNumber}/release`, request);
  },
  async archive(request: ArchiveModelInstanceCommand) {
    return ApiClient.post(`/modelinstance/${request.id}/${request.revisionNumber}/archive`, request);
  },
  async createNewRevision(request: CreateNewModelInstanceRevisionCommand) {
    return ApiClient.post(`/modelinstance/${request.id}/create-new-revision`, request);
  },
  async getAssignments(modelInstanceId: string, scenarioId: string) {
    return ApiClient.get<ModelInstanceAssignmentsDto>(`/modelinstance/assignments/${modelInstanceId}?scenarioId=${scenarioId}`);
  }
};
