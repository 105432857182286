<script setup>
import { ref, computed } from "vue";
import MasterDetailLayout from "@/components/Layout/MasterDetailLayout.vue";
import Breadcrumbs from "@/components/Layout/Breadcrumbs.vue";
import SystemEnumService from "@/services/system-enum.service";
import { useRoute } from "vue-router";
import TasksGantt from "@/components/Gantt/TasksGantt.vue";
import { useI18n } from "vue-i18n";

const route = useRoute();
const i18n = useI18n();
const ganttName = computed(() => {
  const { query } = route;
  if (query && query.types) {
    let types = [];
    if (!Array.isArray(query.types)) {
      types = [query.types];
    } else {
      types = query.types;
    }
    types = types.map((x) => SystemEnumService.resourceType(+x)).filter((x) => !x.toLowerCase().includes("group"));
    if (types.length >= 1) {
      return types[0];
    }
  }
  // $t('gantt-view-ganttName-tasks', { $: 'Tasks' })
  return i18n.t("gantt-view-ganttName-tasks", { $: "Tasks" });
});

const types = ref([]);
if (route.query && route.query.types) {
  if (!Array.isArray(route.query.types)) {
    types.value = [route.query.types];
  } else {
    types.value = route.query.types;
  }
}
</script>

<template>
  <master-detail-layout scenario-selector>
    <template #master>
      <v-card elevation="0" class="flexcard fill-height d-flex">
        <v-card-title>
          <breadcrumbs>{{ $t("gantt-view-ganttName-title", { ganttName, $: "{ganttName} Gantt" }) }}</breadcrumbs>
        </v-card-title>
        <v-card-text class="pb-0">
          <tasks-gantt height="100%" />
        </v-card-text>
      </v-card>
    </template>
  </master-detail-layout>
</template>

<style lang="scss"></style>
