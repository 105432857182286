<script lang="ts" setup>
import Breadcrumbs from "@/components/Layout/Breadcrumbs.vue";
import { computed, inject, onMounted, ref, watch } from "vue";
import { VForm } from "vuetify/components";
import {
  CreateResourceCapacityCommand,
  LocationDto,
  MaterialDto,
  MaterialStockDto,
  MeasurementUnit,
  ResourceCapacityChangeType,
  ResourceCapacityEntrySource,
  ResourceCapacityGroup,
  ResourceInfoDto,
  ResourceTrackingType,
  ResourceType
} from "@masta/generated-model";
import Api from "@/services/api";
import { $t } from "@/i18n";
import { useSnackbarsStore } from "@/store/SnackbarsStore";
import { $dateTimeFormatterSymbol, DateFormatter } from "@masta/shared";
import { useI18n } from "vue-i18n";
import ResourcePicker from "@/components/Resources/ResourcePicker.vue";
import { useMaterialsStore } from "@/store/MaterialsStore";
import { useLocationsStore } from "@/store/LocationsStore";
import DatepickerField from "@/components/Datepicker/DatepickerField.vue";
import { getEnumTitleValuePairs } from "@/composables/enumHelpers";
import { translateMeasurementUnit } from "@/composables/translateEnum";
import { useScenariosStore } from "@/store/ScenariosStore";

interface MaterialMovementProps {
  embedded: boolean;
  selectedMaterialId?: string | undefined | null;
}

const props = defineProps<MaterialMovementProps>();

const emit = defineEmits(["created", "cancel"]);

const { locale } = useI18n();
const $dateTimeFormatter = inject<DateFormatter>($dateTimeFormatterSymbol)!;
const snackbarsStore = useSnackbarsStore();

const material = ref<MaterialDto | undefined>(undefined);
const materialResouceInfo = ref<ResourceInfoDto | null>(null);
const location = ref<LocationDto | undefined>(undefined);
const locationResouceInfo = ref<ResourceInfoDto | null>(null);
const movementType = ref<number>(0);
const quantity = ref<number | undefined>(1);
const serialNumber = ref<string | undefined>("");
const lotNumber = ref<string | undefined>("");
const expiryDate = ref<Date | null>(null);
const date = ref(new Date());
const materialStocks = ref<MaterialStockDto[]>([]);

const materialsStore = useMaterialsStore();
const locationsStore = useLocationsStore();

const valid = ref(true);
const form = ref<VForm>();

const scenariosStore = useScenariosStore();

const measurementUnits = getEnumTitleValuePairs(MeasurementUnit, translateMeasurementUnit);

onMounted(() => {
  if (props.selectedMaterialId) {
    materialsStore.getMaterial(props.selectedMaterialId).then((materialDto) => {
      if (materialDto) {
        materialResouceInfo.value = materialDto;
        materialSelected(materialDto);
      }
    });
  }
});

watch(valid, async (v) => {
  if (v === null) valid.value = (await form?.value?.validate())?.valid ?? true; // TODO: https://github.com/vuetifyjs/vuetify/issues/15568
});

watch(materialResouceInfo, async (newValue: ResourceInfoDto | null) => {
  if (newValue) {
    materialsStore.getMaterial(newValue.id).then((materialDto) => {
      if (materialDto) {
        materialSelected(materialDto);
      }
    });
  }
});

watch(locationResouceInfo, async (newValue: ResourceInfoDto | null) => {
  if (newValue) {
    locationsStore.getLocation(newValue.id).then((locationDto) => {
      if (locationDto) {
        locationSelected(locationDto);
      }
    });
  }
});

const materialMeasurementUnit = computed(() => {
  return material.value ? measurementUnits.find((mu) => mu.value == material.value?.measurementUnit)?.title : "";
});

const movementTypeRules = [(v: any) => !!v || $t("productionPreparation-materialMovement-materialMovementType-required", { $: "Material movement type field is required" })];
const materialRules = [
  (v: any) => {
    if (!v) {
      return $t("productionPreparation-materialMovement-material-required", { $: "Material field is required" });
    }
    return true;
  }
];
const locationRules = [
  (v: any) => {
    if (!v) {
      return $t("productionPreparation-materialMovement-location-required", { $: "Location field is required" });
    }
    return true;
  }
];
const lotRules = [
  (v: any) => {
    if (material.value && material.value.trackingType == ResourceTrackingType.Lot && !v) {
      return $t("productionPreparation-materialMovement-lot-required", { $: "Lot number field is required" });
    } else {
      return true;
    }
  }
];
const serialRules = [
  (v: any) => {
    if (material.value && material.value.trackingType == ResourceTrackingType.Serial && !v) {
      return $t("productionPreparation-materialMovement-serial-required", { $: "Serial number field is required" });
    } else {
      return true;
    }
  }
];
const quantityRules = [
  (v: any) => {
    if (!v) {
      return $t("productionPreparation-materialMovement-quantity-required", { $: "Quantity is required" });
    }
    if (material.value && material.value.trackingType == ResourceTrackingType.Serial && v.quantity > 1) {
      return $t("productionPreparation-materialMovement-quantityEquals1-required", { $: "Quantity equals 1 is required" });
    }
    return true;
  }
];

async function materialSelected(resource: MaterialDto) {
  if (!resource) {
    material.value = undefined;
    materialStocks.value = [];
  } else {
    material.value = resource;
    materialStocks.value = (await Api.materialStock.getMaterialStock(resource.id)).data.materialStockDtos;
  }
}

function locationSelected(resource: LocationDto) {
  if (!resource) {
    location.value = undefined;
  } else {
    location.value = resource;
  }
}

async function cleanup() {
  quantity.value = undefined;
  movementType.value = undefined;
  serialNumber.value = undefined;
  lotNumber.value = undefined;
  materialStocks.value = (await Api.materialStock.getMaterialStock(material.value.id)).data.materialStockDtos;
}

async function save() {
  const validationResult = await form.value?.validate();
  if (validationResult?.valid) {
    const createResourceCapacity: CreateResourceCapacityCommand = {};
    createResourceCapacity.periodStart = date.value;
    createResourceCapacity.capacityGroup = ResourceCapacityGroup.Supply;
    createResourceCapacity.contextResourceId = location.value.id;
    createResourceCapacity.resourceBusinessId = material.value.businessId;
    createResourceCapacity.changeType = ResourceCapacityChangeType.Relative;
    createResourceCapacity.entrySource = ResourceCapacityEntrySource.Execution;
    createResourceCapacity.contextResourceBusinessId = location.value.businessId;
    createResourceCapacity.quantity = movementType.value == 1 || movementType.value == 3 ? -quantity.value : quantity.value;
    createResourceCapacity.expiryDate = expiryDate.value;
    createResourceCapacity.trackingUniqueIdentifier = serialNumber.value ? serialNumber.value : lotNumber.value;
    createResourceCapacity.scenarioId = scenariosStore.selectedScenario?.id;
    try {
      await Api.resourceCapacities.createResourceCapacity(createResourceCapacity);
      await snackbarsStore.createSnackbar({
        message: "Created",
        closeable: true
      });
      cleanup();
      emit("created");
    } catch (e: any) {
      await snackbarsStore.createSnackbar({
        message: e.response.status === 400 ? "Material movement creation failed" : e.message,
        type: "error"
      });
    }
  }
}

function cancel() {
  emit("cancel");
}

function formatDate(date: Date) {
  return $dateTimeFormatter(date);
}

function movementTypes() {
  return [
    {
      text: $t("productionPreparation-materialMovement-receivingMovementType-label", { $: "Receiving" }),
      value: "0"
    },
    {
      text: $t("productionPreparation-materialMovement-shippingMovementType-label", { $: "Shipping" }),
      value: "1"
    }
    /*     {
      text: $t("productionPreparation-materialMovement-plannedReceivingMovementType-label", { $: "Planned receiving" }),
      value: "2"
    },
    {
      text: $t("productionPreparation-materialMovement-plannedShippingMovementType-label", { $: "Planned shipping" }),
      value: "3"
    } */
  ];
}
</script>

<template>
  <v-card elevation="0" class="pa-2">
    <v-card-title>
      <breadcrumbs>{{ $t("productionPreparation-materialMovement-create-title", { $: "Material Movement" }) }}</breadcrumbs>
    </v-card-title>
    <v-card-text>
      <v-form ref="form" v-model="valid" validate-on="input" @submit.prevent="save()">
        <v-row class="pt-6">
          <v-col cols="6">
            <v-row>
              <v-col cols="10">
                <v-row>
                  <v-col cols="12" class="text-h6">
                    {{ $t("productionPreparation-materialMovement-movementType-label", { $: "Movement Type" }) }}
                  </v-col>
                  <v-col cols="12">
                    <v-chip-group v-model="movementType" mandatory>
                      <v-chip v-for="mt in movementTypes()" :class="mt.value == movementType ? 'bg-primary' : 'bg-indigo-lighten-5 text-primary'" size="large" :value="mt.value">
                        {{ mt.text }}
                      </v-chip>
                    </v-chip-group>
                  </v-col>
                </v-row>
                <v-row class="pt-12">
                  <v-col cols="12" class="text-h6">
                    {{ $t("productionPreparation-materialMovement-materialAndLocation-label", { $: "Material and Location" }) }}
                  </v-col>
                  <v-col cols="12">
                    <ResourcePicker
                      v-model="materialResouceInfo"
                      class="flex-1-1-100"
                      :readonly="false"
                      :rules="materialRules"
                      :hide-details="'auto'"
                      :label="$t('productionPreparation-materialMovement-material-label', { $: 'Material *' })"
                      :placeholder="$t('productionPreparation-materialMovement-material-label', { $: 'Material *' })"
                      :resource-types="[ResourceType.Material]"
                    />
                  </v-col>
                  <v-col cols="12">
                    <ResourcePicker
                      v-model="locationResouceInfo"
                      class="flex-1-1-100"
                      :readonly="false"
                      :rules="locationRules"
                      :hide-details="'auto'"
                      :label="$t('productionPreparation-materialMovement-Location-label', { $: 'Location *' })"
                      :placeholder="$t('productionPreparation-materialMovement-Location-label', { $: 'Location *' })"
                      :resource-types="[ResourceType.Location]"
                    />
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
          <v-divider vertical />
          <v-col cols="6">
            <v-row>
              <v-col cols="10" offset="2">
                <v-row>
                  <v-col cols="6">
                    <datepicker-field
                      v-model="date"
                      :clearable="false"
                      :format="formatDate"
                      :locale="locale"
                      teleport="body"
                      :enable-time-picker="true"
                      :label="$t('productionPreparation-materialMovement-date-label', { $: 'Movement date and time' })"
                      :placeholder="$t('productionPreparation-materialMovement-date-label', { $: 'Movement date and time' })"
                    >
                    </datepicker-field>
                  </v-col>
                  <v-col cols="5">
                    <v-text-field
                      v-model="quantity"
                      :label="$t('productionPreparation-materialMovement-quantity-label', { $: 'Quantity' })"
                      :rules="quantityRules"
                      density="compact"
                      min="0"
                      type="number"
                      variant="outlined"
                    />
                  </v-col>
                  <v-col cols="1">
                    <v-label class="mt-8">{{ materialMeasurementUnit }}</v-label>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      v-model="serialNumber"
                      :label="$t('productionPreparation-materialMovement-serialNumber-label', { $: 'Serial number' })"
                      :rules="serialRules"
                      :disabled="material?.trackingType !== ResourceTrackingType.Serial"
                      density="compact"
                      variant="outlined"
                    />
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      v-model="lotNumber"
                      :label="$t('productionPreparation-materialMovement-lotNumber-label', { $: 'Lot number' })"
                      :rules="lotRules"
                      :disabled="material?.trackingType !== ResourceTrackingType.Lot"
                      density="compact"
                      variant="outlined"
                    />
                  </v-col>
                  <v-col cols="12">
                    <datepicker-field
                      v-model="expiryDate"
                      :clearable="false"
                      :format="formatDate"
                      :locale="locale"
                      teleport="body"
                      :enable-time-picker="false"
                      :label="$t('productionPreparation-materialMovement-expiryDate-label', { $: 'Expiry date' })"
                      :placeholder="$t('productionPreparation-materialMovement-expiryDate-label', { $: 'Expiry date' })"
                    />
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row class="mt-10">
          <v-col v-if="embedded" cols="6">
            <v-btn block color="indigo-lighten-5" density="default" @click="cancel">
              {{ $t("productionPreparation-materialMovement-cancel-action", { $: "Cancel" }) }}
            </v-btn>
          </v-col>
          <v-col cols="6" :offset="embedded ? 0 : 6">
            <v-btn type="submit" density="default" color="primary" block>
              {{ $t("productionPreparation-materialMovement-create-action", { $: "Create Material Transaction" }) }}
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>
