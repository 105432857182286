<script setup lang="ts">
import { NormalToolbar } from "md-editor-v3";
import { ref } from "vue";
import { MediaGalleryComponent } from "@/components/Media/MediaGallery.vue";
import { DocumentDto } from "@masta/generated-model";
import ApiService from "@/services/api";
import { useSnackbarsStore } from "@/store/SnackbarsStore";
import MediaPicker from "@/components/Media/MediaPicker.vue";
import { useMedia } from "@/components/Media/useMedia";
import { DocumentTile } from "@/components/Media/MediaModel";
import { useI18n } from "vue-i18n";
import { MD_IMAGE_URL_SEPARATOR } from "@/components/ModelInstances/Manual/useMarkdownImageLoader";

const props = defineProps<{ editor: any; contextName: string }>();

const $t = useI18n().t;

const handler = (documentTile: DocumentTile | undefined) => {
  props.editor?.insert((selectedText: string) => {
    /**
     * @return targetValue    Content to be inserted
     * @return select         Automatically select content, default: true
     * @return deviationStart Start position of the selected content, default: 0
     * @return deviationEnd   End position of the selected content, default: 0
     */
    return {
      targetValue: documentTile ? `![${documentTile.title}](${documentTile.documentDto?.id}${MD_IMAGE_URL_SEPARATOR}${documentTile.documentDto?.revisionNumber})` : "",
      select: true,
      deviationStart: 0,
      deviationEnd: 0
    };
  });
};

const mediaPickerRef = ref<MediaGalleryComponent | null>(null);
const snackbarStore = useSnackbarsStore();

async function onDelete(dto: DocumentDto) {
  try {
    await ApiService.documents.deleteDocument(dto.id, dto.revisionNumber);
    await mediaPickerRef.value?.refreshItems();
  } catch (e: any) {
    snackbarStore.createSnackbar({
      message: e && e.detail ? e.detail : $t("resourceMediaGallery-deleteError-message", { $: "Could not delete" }),
      type: "error",
      closeable: true
    });
  }
}

const { transformDtoForManualEditor } = useMedia();

async function onSelected(items: DocumentDto[]) {
  if (items.length === 1) {
    handler(await transformDtoForManualEditor(items[0]));
  }
}
</script>

<template>
  <NormalToolbar :title="$t('manualEditor-insertImageToolbar-label', { $: 'Insert image' }, 'Insert image')" @on-click="handler">
    <template #trigger>
      <media-picker ref="mediaPickerRef" :context-name="contextName" :on-delete="onDelete" @selected="onSelected">
        <template #activator="{ props }">
          <v-icon v-bind="props">mdi-image</v-icon>
        </template>
      </media-picker>
    </template>
  </NormalToolbar>
</template>

<style scoped lang="scss"></style>
