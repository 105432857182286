import { GuiModelItem, GuiObjectType } from "./GuiModelItem";

export class TextPropertyModel implements GuiModelItem {
  public value = "";
  public readonly type = GuiObjectType.Text;
  public minLength: number | undefined;
  public maxLength: number | undefined;
  public pattern: string | undefined;
  public color: string | undefined;
  public icon: string | undefined;

  constructor(public name: string) {}

  getValue(): any {
    return this.value;
  }

  isValid(): boolean {
    if (this.maxLength && this.value && this.value.length > this.maxLength) {
      return false;
    }
    return true;
  }

  setValue(value: any): void {
    if(typeof value !== "string") {
      throw new Error("Setting non-string value to string");
    }
    this.value = value;
  }
}
