<script lang="ts" setup>
import { SchemaTypeEnumModel } from "@/components/ModelInstances/SchemaBuilder/SchemaModel";

const props = defineProps<{
  modelValue: SchemaTypeEnumModel;
}>();

const emit = defineEmits(["update:modelValue"]);

function update(key: string, value: any) {
  const newModel = { ...props.modelValue, [key]: value };
  emit("update:modelValue", newModel);
}

function remove(item: string) {}
</script>

<template>
  <div class="d-flex justify-start flex-wrap">
    <v-chip class="ma-2" size="x-large" label> {{ $t("schemaType-enumProperty-type-label", { $: "Enum Values" }) }}</v-chip>
    <v-combobox
      variant="outlined"
      class="mx-2"
      :model-value="modelValue.items"
      :items="modelValue.items"
      chips
      clearable
      :label="$t('schemaType-enumProperty-valueSelect-label', { $: 'Enum values' })"
      multiple
      solo
      @update:model-value="update('items', $event)"
    >
      <template #selection="{ attrs, item, selected }">
        <v-chip v-bind="attrs" :input-value="selected" close @click:close="remove(item)">
          <strong>{{ item }}</strong
          >&nbsp;
          <span>{{ $t("schemaType-enumProperty-interest-label", { $: "(interest)" }) }}</span>
        </v-chip>
      </template>
    </v-combobox>
    <v-checkbox
      variant="outlined"
      :model-value="modelValue.multiple"
      :label="$t('schemaType-enumProperty-multipleSelection-label', { $: 'Multiple Selection' })"
      @update:model-value="update('multiple', $event)"
    />
    <v-text-field
      variant="outlined"
      class="mx-2"
      :label="$t('schemaType-property-icon-label', { $: 'Icon' })"
      :model-value="modelValue.icon"
      @update:model-value="update('icon', $event)"
    />
    <v-text-field
      variant="outlined"
      class="mx-2"
      :label="$t('schemaType-property-color-label', { $: 'Color' })"
      :model-value="modelValue.color"
      @update:model-value="update('color', $event)"
    />
  </div>
</template>

<style lang="scss" scoped>
.v-text-field {
  width: 200px;
}
</style>
