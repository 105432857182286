import { ref } from "vue";
import DatepickerCellEditor from "@/components/Grid/CellEditors/DatepickerCellEditor.vue";
import TextInputCellEditor from "@/components/Grid/CellEditors/TextInputCellEditor.vue";
import EnumTypeCellEditor from "@/components/Grid/CellEditors/EnumTypeCellEditor.vue";
import ReferencePickerCellEditor from "@/components/Grid/CellEditors/ReferencePickerCellEditor.vue";
import ResourcePickerCellEditor from "@/components/Grid/CellEditors/ResourcePickerCellEditor.vue";
import ResourcesPickerCellEditor from "@/components/Grid/CellEditors/ResourcesPickerCellEditor.vue";
import TagsPickerCellEditor from "@/components/Grid/CellEditors/TagsPickerCellEditor.vue";
import OrganizationPickerCellEditor from "@/components/Grid/CellEditors/OrganizationPickerCellEditor.vue";
import EmojiPickerCellEditor from "@/components/Grid/CellEditors/EmojiPickerCellEditor.vue";
import CostCatalogueItemPickerCellEditor from "@/components/Grid/CellEditors/CostCatalogueItemPickerCellEditor.vue";
import { IEnumValueSelectCellEditorParams, ISelectEnumValueEntry } from "@/components/Grid/CellEditors/IEnumValueSelectCellEditorParams";
import { $t } from "@/i18n";
import NumberInputCellEditor from "@/components/Grid/CellEditors/NumberInputCellEditor.vue";
import colorInputCellEditor from "@/components/Grid/CellEditors/ColorInputCellEditor.vue";
import colorCellRenderer from "@/components/Grid/CellRenderers/ColorCellRenderer.vue";
import rruleCellRenderer from "@/components/Grid/CellRenderers/RRuleCellRenderer.vue";
import rruleCellEditor from "@/components/Grid/CellEditors/RRuleCellEditor.vue";
import longTextCellRenderer from "@/components/Grid/CellRenderers/LongTextCellRenderer.vue";
import TextFloatingFilter from "@/components/Grid/Filters/TextFloatingFilter.vue";
import SetFloatingFilter from "@/components/Grid/Filters/SetFloatingFilter.vue";
import TagsCellRenderer from "@/components/Grid/CellRenderers/TagsCellRenderer.vue";
import DateTimeCellRenderer from "@/components/Grid/CellRenderers/DateTimeCellRenderer.vue";
import ActionsCellRenderer from "@/components/Grid/CellRenderers/ActionsCellRenderer.vue";

type TranslateEditorEntryFn = (value: any) => string;

export function enumKeysExcluding<T extends Record<string, unknown>>(enumType: T, exclude: number[] = [], valueFilter = isNumber): (keyof T)[] {
  const result: (keyof T)[] = [];
  const keys: string[] = Object.keys(enumType);
  for (const k of keys) {
    const v: any = enumType[k];
    if (valueFilter(v) && exclude.indexOf(v) === -1) {
      result.push(k as keyof typeof enumType);
    }
  }
  return result;
}

export function enumValuesExcluding<T extends Record<string, unknown>>(enumType: T, exclude: number[] = [], valueFilter = isNumber): any[] {
  return enumKeysExcluding(enumType, exclude, valueFilter).map((x) => enumType[x]);
}

export function translateEditorEntries(entries: ISelectEnumValueEntry[], translateFn: TranslateEditorEntryFn, sort: boolean = true) {
  const result = entries.map((entry) => {
    entry.key = translateFn(entry.value);
    return entry;
  });

  if (sort) {
    result.sort(enumValueEntryWithLocaleComparator);
  }

  return result;
}

export function enumToEditorEntries<T extends Record<string, unknown>>(enumType: T): ISelectEnumValueEntry[] {
  return enumToEditorEntriesExcluding(enumType, []);
}

export function enumToEditorEntriesExcluding<T extends Record<string, unknown>>(enumType: T, exclude: number[] = []): ISelectEnumValueEntry[] {
  const result = [] as ISelectEnumValueEntry[];
  const keys: string[] = Object.keys(enumType);
  for (const k of keys) {
    const v: any = enumType[k];
    if (isNumber(v) && exclude.indexOf(v) === -1) {
      result.push({ key: k, value: v });
    }
  }
  return result;
}

export function enumToEditorEntriesOnlyIncluding<T extends Record<string, unknown>>(enumType: T, onlyInclude: number[] = []): ISelectEnumValueEntry[] {
  const result = [] as ISelectEnumValueEntry[];
  const keys: string[] = Object.keys(enumType);
  for (const k of keys) {
    const v: any = enumType[k];
    if (isNumber(v) && onlyInclude.indexOf(v) !== -1) {
      result.push({ key: k, value: v });
    }
  }
  return result;
}

export function enumValueEntryWithLocaleComparator(a: ISelectEnumValueEntry, b: ISelectEnumValueEntry) {
  // Move items with empty string (e.g for 'Unknown' key) to the top
  if (a.key === "") return -1;
  if (b.key === "") return 1;

  // Sort the rest alphabetically in natural order
  return a.key.localeCompare(b.key, undefined, { numeric: true, sensitivity: "base" });
}

export const columnTypes = ref({
  orderTypeColumn: {
    cellEditor: EnumTypeCellEditor,
    cellEditorParams: {
      rules: [(v: any) => (!Array.isArray(v) && !isNaN(v)) || (() => $t("order-edit-valueRequired-tooltip", { $: "Required" }))],
      multiple: false,
      values: [
        {
          key: "Customer Order",
          value: 1
        },
        {
          key: "Unknown",
          value: -1
        }
      ] as ISelectEnumValueEntry[]
    } as IEnumValueSelectCellEditorParams
  },
  enumTypeColumn: {
    cellEditor: EnumTypeCellEditor,
    cellEditorParams: {
      multiple: false,
      values: [] as ISelectEnumValueEntry[]
    } as IEnumValueSelectCellEditorParams
  },
  materialTypeColumn: {
    cellEditor: EnumTypeCellEditor,
    cellEditorParams: {
      multiple: true,
      values: [
        {
          key: "Produce",
          value: 7000
        },
        {
          key: "Receive",
          value: 7001
        },
        {
          key: "Scrap",
          value: 7002
        }
      ] as ISelectEnumValueEntry[]
    } as IEnumValueSelectCellEditorParams
  },
  dateTimeTypeColumn: {
    cellRenderer: DateTimeCellRenderer
  },
  datepickerTypeColumn: {
    cellEditor: DatepickerCellEditor
  },
  textInputTypeColumn: {
    cellEditor: TextInputCellEditor
  },
  numberInputTypeColumn: {
    cellEditor: NumberInputCellEditor
  },
  organizationPickerTypeColumn: {
    cellEditor: OrganizationPickerCellEditor
  },
  referencePickerTypeColumn: {
    cellEditor: ReferencePickerCellEditor
  },
  resourcePickerTypeColumn: {
    cellEditor: ResourcePickerCellEditor
  },
  resourcesPickerTypeColumn: {
    cellEditor: ResourcesPickerCellEditor
  },
  tagsTypeColumn: {
    cellRenderer: TagsCellRenderer
  },
  tagsPickerTypeColumn: {
    cellRenderer: TagsCellRenderer,
    cellEditor: TagsPickerCellEditor
  },
  emojiPickerTypeColumn: {
    cellRenderer: TagsCellRenderer,
    cellEditor: EmojiPickerCellEditor
  },
  costCatalogueItemPickerTypeColumn: {
    cellEditor: CostCatalogueItemPickerCellEditor
  },
  colorTypeColumn: {
    cellRenderer: colorCellRenderer,
    cellEditor: colorInputCellEditor
  },
  rruleTypeColumn: {
    cellRenderer: rruleCellRenderer,
    cellEditor: rruleCellEditor
  },
  longTextTypeColumn: {
    cellRenderer: longTextCellRenderer
  },
  textFloatingFilterColumnType: {
    floatingFilterComponent: TextFloatingFilter,
    suppressFloatingFilterButton: false
  },
  numberFloatingFilterColumnType: {
    floatingFilterComponent: TextFloatingFilter,
    suppressFloatingFilterButton: true,
    floatingFilterComponentParams: {
      inputType: "number"
    }
  },
  setFloatingFilterColumnType: {
    floatingFilterComponent: SetFloatingFilter,
    floatingFilterComponentParams: {}
  },
  dateFloatingFilterColumnType: {
    suppressFloatingFilterButton: true
  },
  actionsColumnType: {
    cellRenderer: ActionsCellRenderer
  }
});

function isNumber(value: any): boolean {
  return typeof value === "number";
}
