import { v4 as uuid } from "uuid";
import { Lifecycle } from "./Lifecycle";

export interface IIdentifiable {
  get identifier(): string;
}

export class Identifiable implements IIdentifiable {
  protected _identifier: string;

  constructor(identifier?: string) {
    this._identifier = identifier ?? uuid();
  }

  get identifier(): string {
    return this._identifier;
  }
}

export class IdentifiableLifecycle extends Lifecycle implements IIdentifiable {
  protected _identifier: string;

  constructor(identifier?: string) {
    super();
    this._identifier = identifier ?? uuid();
  }

  get identifier(): string {
    return this._identifier;
  }
}
