import { inject, injectable } from "inversify";
import { GanttCanvas } from "../GanttCanvas";
import { GanttEvents, GraphicsMousePositionEvent, IocSymbols, TimelineManager, TimelineModel } from "../../Core";
import { DateTimeFormatter, ZonedDateTime, ZoneId } from "@js-joda/core";
import { Locale } from "@js-joda/locale_en";

@injectable()
export class Eventline extends GanttCanvas {
  private _mousePosition: GraphicsMousePositionEvent | undefined;
  private _dateTimeFormat: DateTimeFormatter;

  constructor(@inject(GanttEvents) ganttEvents: GanttEvents, @inject(TimelineManager) private _timelineManager: TimelineManager) {
    super(ganttEvents, Eventline.name, "eventline");
    this._dateTimeFormat = DateTimeFormatter.ofPattern("d/M/yy HH:mm:ss").withLocale(Locale.ENGLISH);
  }

  async afterInitialize(): Promise<void> {
    await super.afterInitialize();
    this.subscribe(
      this._ganttEvents.mousePositionEvent$.subscribe((e) => {
        this._mousePosition = e;
        this.batchDraw();
      })
    );
    this.subscribe(
      this._ganttEvents.mouseLeaveEvent$.subscribe(() => {
        this._mousePosition = undefined;
        this.batchDraw();
      })
    );
  }

  elementAppended() {
    super.elementAppended();
  }

  public async doDrawFromBatch(): Promise<void> {
    if (this._mousePosition) {
      this.clear();
      const currentTime = this._timelineManager.calculateTimeForLocation(this._mousePosition.x);

      const zdt = ZonedDateTime.ofInstant(currentTime, ZoneId.systemDefault());
      const formattedDate = zdt.format(this._dateTimeFormat);

      const fontSize = 10;
      this.context.font = this._timelineManager.font.replace(/\d+px/, `${fontSize}px`);

      const xPos = this._mousePosition.x;
      const textWidth = this.context.measureText(formattedDate);
      this.context.strokeStyle = "rgba(33,150,243,1)";
      this.context.fillStyle = "rgba(33, 150, 243, 0.12)";
      this.context.beginPath();
      this.context.rect(xPos, 0, textWidth.width + 10, this.canvas.clientHeight);
      this.context.closePath();
      this.context.fill();
      this.context.stroke();
      this.context.fillStyle = "rgba(0, 0, 0, 0.87)";
      this.context.fillText(`${formattedDate}`, xPos + 5, this.canvas.clientHeight / 2 + fontSize / 4);
    } else {
      this.clear();
    }
  }

  public async scale() {
    if (!this.element || !this.element.parentElement) return;

    const parentElement = this.element.parentElement!;
    const viewportOffset = parentElement.getBoundingClientRect();
    const w = this.getScaledWidth(parentElement, viewportOffset);
    const h = this.getScaledHeight(parentElement, viewportOffset);
    if (w === this.canvas.width && h === this.canvas.height) return;
    const dpr = window.devicePixelRatio || 1;
    const rect = this.canvas.getBoundingClientRect();

    this.canvas.width = rect.width * dpr;
    this.canvas.height = rect.height * dpr;
    this.context.scale(dpr, dpr);
  }

  // protected clear() {
  //   const rect = this.canvas.getBoundingClientRect();
  //   this.context.clearRect(0, 0, rect.width, rect.height);
  // }
}
