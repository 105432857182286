import { inject, injectable } from "inversify";
import { GanttEvents, GanttSettings, Row, SettingKey, TimelineManager, TimeLineStyleSetting } from "../../../../Core";
import { LayerCanvas } from "./LayerCanvas";

@injectable()
export class GridLinesRowLayer extends LayerCanvas {
  private _timelineStyleSetting: TimeLineStyleSetting | undefined;

  constructor(
    @inject(Row<any, any, any>) private _row: Row<any, any, any>,
    @inject(TimelineManager) timelineManager: TimelineManager,
    @inject(GanttEvents) ganttEvents: GanttEvents,
    @inject(GanttSettings) private _ganttSettings: GanttSettings
  ) {
    super(timelineManager, ganttEvents, GridLinesRowLayer.name, "grid-lines-layer");
  }

  async beforeInitialize(): Promise<void> {
    return super.beforeInitialize();
  }

  async afterInitialize(): Promise<void> {
    await super.afterInitialize();
    this._timelineStyleSetting = this._ganttSettings.getSetting<TimeLineStyleSetting>(SettingKey.TIMELINE_STYLE);
  }

  async doDrawFromBatch(): Promise<void> {
    if (!this._row.visible) return;
    this.clear();

    const blurOffset = 0.5;
    const { clientHeight } = this.canvas;

    const datelineGridMap = this._timelineManager.datelineManager.datelineGridMap;

    for (const scalePosition of datelineGridMap.keys()) {
      const gridPositions = datelineGridMap.get(scalePosition);
      if (!gridPositions) continue;
      switch (scalePosition) {
        case 0:
          this.context.lineWidth = this._timelineStyleSetting?.bottom.other.width ?? 0.5;
          this.context.strokeStyle = this._timelineStyleSetting?.bottom.other.color ?? "rgba(187,187,187,0.75)";
          break;
        case 1:
          this.context.lineWidth = this._timelineStyleSetting?.top.width ?? 1.5;
          this.context.strokeStyle = this._timelineStyleSetting?.top.color ?? "rgba(0, 0, 0, 0.38)";
          break;
        default:
          this.context.lineWidth = 1.0;
          this.context.strokeStyle = "#dc2525";
          break;
      }
      for (const gridPosition of gridPositions) {
        const x = gridPosition;
        this.context.beginPath();
        this.context.moveTo(x + blurOffset, 0);
        this.context.lineTo(x + blurOffset, clientHeight);
        this.context.closePath();
        this.context.stroke();
      }
    }
  }
}
