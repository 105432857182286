import { ApiClient } from "@/services/api/common.api";
import { DocumentDto, GetCostControllingDocumentPaginatedQuery, IAgGridPaginationResponse } from "@masta/generated-model";

export default {
  async getByOrderId(request: GetCostControllingDocumentPaginatedQuery) {
    return ApiClient.post<IAgGridPaginationResponse<DocumentDto>>(`/costControllingDocuments`, request);
  },
  async getSingle(documentId: string, revisionNumber: number) {
    return ApiClient.get<DocumentDto>(`/costControllingDocuments/${documentId}/${revisionNumber}`);
  }
};
