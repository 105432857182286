﻿import { ActivityBase, ActivityDeserializer, GanttException, IActivityFactory, Lifecycle, MutableActivityBase } from "@masta/gantt2/core";
import { ActivityTaskDto, TaskExecutionActivity, TaskSchedulingActivity } from "src/components/Gantt/Common/Model";
import { inject, injectable, optional } from "inversify";
import { AppIocSymbols } from "@/components/Gantt/ResourcesGantt/AppIocSymbols";
import type { DateFormatter, DurationFormatter } from "@masta/shared";

const schedulingDeserializer: ActivityDeserializer<ActivityTaskDto, TaskSchedulingActivity> = (data: ActivityTaskDto) => {
  return new TaskSchedulingActivity(data);
};
const executionDeserializer: ActivityDeserializer<ActivityTaskDto, TaskExecutionActivity> = (data: ActivityTaskDto) => {
  return new TaskExecutionActivity(data);
};

@injectable()
export class TaskGanttActivityFactory extends Lifecycle implements IActivityFactory<MutableActivityBase<any>> {
  constructor(
    @inject(AppIocSymbols.$durationFormatter) @optional() protected readonly $durationFormatter?: DurationFormatter,
    @inject(AppIocSymbols.$dateTimeFormatter) @optional() protected readonly $dateTimeFormatter?: DateFormatter
  ) {
    super();
  }

  createActivity(data: ActivityTaskDto): MutableActivityBase<any> {
    data = this.enhanceDto(data);
    switch (data.type) {
      case "TaskSchedulingActivity": {
        return ActivityBase.fromJSON(data, TaskSchedulingActivity, schedulingDeserializer);
      }
      case "TaskExecutionActivity": {
        return ActivityBase.fromJSON(data, TaskExecutionActivity, executionDeserializer);
      }
      default:
        throw new GanttException(`Unknown activity type: ${data.type}`);
    }
  }

  private enhanceDto(data: ActivityTaskDto): ActivityTaskDto {
    if (this.$durationFormatter) {
      if (!data.schedulingDurationFormatted)
        data.schedulingDurationFormatted = this.$durationFormatter(data.statistics!.schedulingStart!, data.statistics!.schedulingEnd!) as string;
      if (!data.executionDurationFormatted) data.executionDurationFormatted = this.$durationFormatter(data.statistics!.executionStart!, data.statistics!.executionEnd!) as string;
    }
    if (this.$dateTimeFormatter) {
      if (!data.schedulingStartDateFormatted) data.schedulingStartDateFormatted = this.$dateTimeFormatter(data.statistics!.schedulingStart);
      if (!data.schedulingEndDateFormatted) data.schedulingEndDateFormatted = this.$dateTimeFormatter(data.statistics!.schedulingEnd);
      if (!data.executionStartDateFormatted) data.executionStartDateFormatted = this.$dateTimeFormatter(data.statistics!.executionStart);
      if (!data.executionEndDateFormatted) data.executionEndDateFormatted = this.$dateTimeFormatter(data.statistics!.executionEnd);
      if (!data.schedulingStartDateTimeFormatted) data.schedulingStartDateTimeFormatted = this.$dateTimeFormatter(data.statistics!.schedulingStart);
      if (!data.schedulingEndDateTimeFormatted) data.schedulingEndDateTimeFormatted = this.$dateTimeFormatter(data.statistics!.schedulingEnd);
      if (!data.executionStartDateTimeFormatted) data.executionStartDateTimeFormatted = this.$dateTimeFormatter(data.statistics!.executionStart);
      if (!data.executionEndDateTimeFormatted) data.executionEndDateTimeFormatted = this.$dateTimeFormatter(data.statistics!.executionEnd);
    }
    return data;
  }
}
