import { ActivityBase, GanttLayout, Layout, WeekendCalendarActivity } from "../Model";
import { ActivityRenderer } from "./ActivityRenderer";
import { inject, injectable, interfaces } from "inversify";
import { Lifecycle } from "../Lifecycle";
import { IocSymbols } from "../IocSymbols";
import { IocContainer, onActivationLifecycle } from "../IocContainer";
import { Renderer } from "./Renderer";

@injectable()
export class ActivityRendererRegistry extends Lifecycle {
  private _renderers = new Map<typeof Layout, Map<new (...args: any[]) => ActivityBase<any>, new (...args: any[]) => ActivityRenderer<any>>>();

  constructor(@inject(IocContainer) private _container: IocContainer) {
    super();
  }

  registerActivityRenderer<
    TRenderer extends ActivityRenderer<any>,
    TRendererClass extends new (...args: any[]) => TRenderer,
    TActivityBase extends new (...args: any[]) => ActivityBase<any>
  >(activityClass: TActivityBase, rendererClass: TRendererClass, layoutClass: typeof Layout = GanttLayout): void {
    let renderers = this._renderers.get(layoutClass);
    if (!renderers) {
      this._renderers.set(layoutClass, new Map<new (...args: any[]) => ActivityBase<any>, new (...args: any[]) => ActivityRenderer<any>>());
      renderers = this._renderers.get(layoutClass);
    }
    if (renderers) {
      renderers.set(activityClass, rendererClass);
    }
  }

  bindActivityRenderers(container: interfaces.Container) {
    for (const [layout, renderers] of this._renderers) {
      for (const [activity, renderer] of renderers) {
        container
          .bind<Renderer>(IocSymbols.ActivityRenderer)
          .to(renderer)
          .inTransientScope()
          .whenTargetTagged(IocSymbols.ActivityRendererTag, activity.name)
          .onActivation(onActivationLifecycle);
        // console.debug(`[Gantt] Registered activity renderer ${renderer.name} for activity ${activity.name} and layout ${layout.name}`);
      }
    }
  }
}
