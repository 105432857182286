<script setup lang="ts">
import { $t } from "@/i18n";
import { DocumentContextNameConsts, PersonDto } from "@masta/generated-model";
import Tasks from "@/views/Tasks.vue";
import ResourceModelInstances from "@/components/ModelInstances/ResourceModelInstances.vue";
import { ref } from "vue";
import { ModelInstanceTab } from "@/components/ModelInstances/model-instance-tab";
import { resolveComponentRoutePath } from "@/components/Layout/UseMasterDetailLayoutRoute";
import ResourceMediaGallery from "@/components/Media/ResourceMediaGallery.vue";
import ResourceCapacityDetails from "@/components/Resources/ResourceCapacityDetails.vue";
import CloseCardButton from "@/components/CloseCardButton.vue";
import Breadcrumbs from "@/components/Layout/Breadcrumbs.vue";

const tasksRoutePath = resolveComponentRoutePath("Tasks");

const props = defineProps<{
  person: PersonDto;
  schemaTabs: ModelInstanceTab[];
}>();

const emit = defineEmits<{
  (e: "updated", id: string): void;
  (e: "closed"): void;
}>();

const defaultTab = "overview";
const currentTab = ref(defaultTab);

async function modelInstanceUpdated(resourceId: string) {
  emit("updated", resourceId);
}

function closeDetails() {
  emit("closed");
}
</script>


<template>
  <v-card elevation="7" class="d-flex flex-column fill-height personnel-card">
    <close-card-button @click="closeDetails"></close-card-button>
    <v-card-title class="tabs">
      <breadcrumbs>{{ person?.name || person?.businessId }}</breadcrumbs>
      <v-tabs
        v-model="currentTab"
        class="task-details text-disabled"
        selected-class="v-slide-group-item--active text-primary bg-white"
        density="compact"
        color="primary"
        show-arrows
      >
        <v-tab value="overview">
          <v-icon class="pr-2">mdi-folder-information</v-icon>
          {{ $t("personDetails-view-overview-label", { $: "Gallery" }) }}
        </v-tab>
        <v-tab v-for="tab in schemaTabs" :key="tab.title" :value="tab.title">
          <v-icon class="pr-2">mdi-tag-multiple</v-icon>
          {{ tab.title }}
        </v-tab>
        <v-tab value="productionTasks">
          <v-icon class="pr-2">mdi-list-box-outline</v-icon>
          {{ $t("personDetails-view-productionTasks-label", { $: "Production Tasks" }) }}
        </v-tab>
        <v-tab value="resourceCapacities">
          <v-icon class="pr-2">mdi-treasure-chest</v-icon>
          {{ $t("personDetails-view-resourceCapacities-label", { $: "Resource Capacities" }) }}
        </v-tab>
      </v-tabs>
    </v-card-title>
    <v-card-text class="d-flex flex-column pa-0">
      <div class="flex-1-1 d-flex overflow-hidden">
        <v-card class="flex-1-1 d-flex flex-column rounded-0" elevation="6">
          <v-card-text class="flex-1-1 d-flex flex-column pa-0">
            <v-window v-model="currentTab" class="bg-white flex-fill d-block" style="position: absolute; width: 100%; height: 100%">
              <v-window-item class="fill-height pa-5 overflow-y-auto" value="overview">
                <resource-media-gallery :resource="person" :context-name="DocumentContextNameConsts.avatar" />
              </v-window-item>
              <v-window-item v-for="tab in schemaTabs" :key="tab.title" :value="tab.title" class="fill-height pl-5 overflow-y-auto">
                <ResourceModelInstances :context-name="tab.contextName" :resource="person" :schema="tab.schema" @updated="modelInstanceUpdated" />
              </v-window-item>
              <v-window-item class="fill-height pa-5 overflow-y-auto" value="productionTasks">
                <tasks :filter-by-product-id="person?.id" embedded :component-route-path="tasksRoutePath" />
              </v-window-item>
              <v-window-item eager class="fill-height pa-5 overflow-y-auto" value="resourceCapacities">
                <resource-capacity-details :resource="person" />
              </v-window-item>
            </v-window>
          </v-card-text>
        </v-card>
      </div>
    </v-card-text>
  </v-card>
</template>

<style lang="scss" scoped>
.personnel-card {
  border: 2px solid rgb(var(--v-theme-primary));
  border-radius: 0;

  .close-card-button {
    position: absolute;
    top: 0px;
    right: 10px;
  }
}
</style>
