import { DayOfWeek, Instant } from "@js-joda/core";

import { ActivityBase } from "../Activity";

import { CalendarActivityBase } from "./CalendarActivityBase";

export class WeekendCalendarActivity extends CalendarActivityBase<any> {
  private readonly _dayOfWeek: DayOfWeek;

  constructor(day: DayOfWeek, startTime: Instant = Instant.now(), endTime: Instant = Instant.now().plus(ActivityBase.DEFAULT_DURATION)) {
    super(`${day.name()}-${startTime.toEpochMilli()}`, day.name(), startTime, endTime);
    this._dayOfWeek = day;
  }

  get dayOfWeek(): DayOfWeek {
    return this._dayOfWeek;
  }
}
