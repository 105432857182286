<template>
  <v-dialog v-model="propModel" persistent>
    <v-card min-width="300px">
      <v-card-title>
        <v-icon class="pr-2"> mdi-account</v-icon>
        {{ $t("user-inviteUser-inviteUser-label", { $: "Invite User" }) }}
      </v-card-title>
      <v-card-text>
        <v-form v-model="valid">
          <v-text-field v-model="email" :rules="rules.email" :label="$t('user-inviteUser-email-label', { $: 'Email' })" />
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn variant="outlined" color="accent" text :disabled="!valid" @click="invite"> {{ $t("user-inviteUser-invite-action", { $: "Invite" }) }}</v-btn>
        <v-btn variant="outlined" color="secondary" text @click="propModel = false"> {{ $t("user-inviteUser-cancel-action", { $: "Cancel" }) }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import { defineComponent, ref, computed, watch } from 'vue';
import { useUsersStore } from '@/store/UsersStore';
import { useSnackbarsStore } from '@/store/SnackbarsStore';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'InviteUserDialog',
  props: {
    modelValue: {
      default: false,
      type: Boolean
    }
  },
  emits: ["update:modelValue", "inviteSent"],
  setup(props, { emit }) {
    const usersStore = useUsersStore();
    const snackbarsStore = useSnackbarsStore();
    const { t } = useI18n();

    const valid = ref(false);
    const email = ref<string | null>(null);

    const propModel = computed({
      get() {
        return props.modelValue;
      },
      set(value) {
        emit("update:modelValue", value);
      }
    });

    const rules = computed(() => {
      return {
        email: [
          (v: string) => !!v || t("user-inviteUser-onEMailIsRequired-message", { $: "E-mail is required" }),
          (v: string) => /.+@.+\..+/.test(v) || t("user-inviteUser-onEMailMustBeValid-message", { $: "E-mail must be valid" })
        ]
      };
    });

    const invite = async () => {
      try {
        await usersStore.invite(email.value);
        propModel.value = false;
        emit("inviteSent");
        await snackbarsStore.createSnackbar({
          message: t("user-inviteUser-onInvitationSent-message", { $: "Invitation sent" }),
          closeable: true
        });
        email.value = null;
      } catch (e) {
        console.error(e);
        await snackbarsStore.createSnackbar({
          message: e.message,
          type: "error",
          closeable: true
        });
      }
    };

    watch(email, () => {
      valid.value = rules.value.email.every(rule => rule(email.value));
    });

    return {
      valid,
      email,
      propModel,
      rules,
      invite
    };
  }
});
</script>

<style scoped></style>
