<script lang="ts" setup>
import SchemaItem from "@/components/ModelInstances/SchemaBuilder/SchemaItem.vue";
import { SchemaObjectPropertyModel } from "@/components/ModelInstances/SchemaBuilder/SchemaModel";
import { $t } from "@/i18n";

const props = defineProps<{
  modelValue: SchemaObjectPropertyModel;
}>();

const emit = defineEmits(["update:modelValue", "remove"]);

function update(key: string, value: any) {
  const newModel = { ...props.modelValue, [key]: value };
  emit("update:modelValue", newModel);
}

function remove() {
  emit("remove", props.modelValue);
}

const nameRules = [
  (v: string) => !!v || $t("schema-objectPropertyEditor-onNameRequired-message", { $: "Name is required" }),
  (v: string) => (v && v.length > 0) || $t("schema-objectPropertyEditor-onNameNotEmptyRequired-message", { $: "Name must not be empty" })
];
</script>

<template>
  <v-row dense>
    <v-col cols="12" sm="2">
      <v-text-field
        variant="outlined"
        :label="$t('schema-objectPropertyEditor-name-label', { $: 'Name' })"
        :rules="nameRules"
        :model-value="modelValue.name"
        required
        @update:model-value="update('name', $event)"
      />
    </v-col>
    <v-col cols="12" sm="9">
      <schema-item :model-value="modelValue.value" @update:model-value="update('value', $event)" />
    </v-col>
    <v-col cols="12" sm="1" class="text-right">
      <v-btn class="my-6" icon="mdi-delete" size="x-small" color="primary" @click="remove()"> x </v-btn>
    </v-col>
  </v-row>
</template>
