export type LassoEvent = {
  xStart: number;
  xEnd: number;
  yStart: number;
  yEnd: number;
  ctrlKey: boolean;
  altKey: boolean;
  shiftKey: boolean;
};

export type LassoRowSelectionEvent = LassoEvent & {
  selectedRows: { id: string; offsetTop: number }[];
};

export function createLassoEvent(xStart: number, xEnd: number, yStart: number, yEnd: number, ctrlKey: boolean, altKey: boolean, shiftKey: boolean): LassoEvent {
  return {
    xStart,
    xEnd,
    yStart,
    yEnd,
    ctrlKey,
    altKey,
    shiftKey
  };
}

export function createLassoRowSelectionEvent(event: LassoEvent, selectedRows: { id: string; offsetTop: number }[]): LassoRowSelectionEvent {
  return {
    ...event,
    selectedRows
  };
}
