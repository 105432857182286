import { inject, injectable, optional } from "inversify";
import { GanttDomElement } from "./GanttDomElement";
import { IocSymbols, IRowInfoColumn, IRowInfoColumnHeader } from "../Core";
import type { IRowInfoColumnProvider } from "../Core";

@injectable()
export class InfoHeaderContainer extends GanttDomElement<HTMLDivElement> {
  public static readonly MIN_COL_WIDTH = 40;

  public static readonly MAX_COL_WIDTH = 400;

  private _initialMousePosition = 0;

  private _initialColWidth = 0;

  private _colId = "";

  private _mouseMoveListener: OmitThisParameter<(e: MouseEvent) => void>;

  private _mouseDownListener: OmitThisParameter<(e: MouseEvent) => void>;

  private _mouseUpListener: OmitThisParameter<() => void>;

  constructor(
    @inject(IocSymbols.HtmlContainerSymbol) private _container: HTMLDivElement,
    @inject(IocSymbols.RowInfoColumnProvider) @optional() private _rowInfoColumnProvider: IRowInfoColumnProvider
  ) {
    super(InfoHeaderContainer.name, undefined, "info-header-container");
  }

  async afterInitialize(): Promise<void> {
    await super.afterInitialize();

    this.element.innerHTML = `
  <div class="info-header">
    <div class="info-header-index"></div>
    <div class="info-header-template"></div>
  </div>
    `;

    this.subscribe(
      this._rowInfoColumnProvider.computedInfoWidth.subscribe((computedWidth) => {
        this.batchDraw();
      })
    );

    this._mouseDownListener = this.onMouseDown.bind(this);
    this._mouseUpListener = this.onMouseUp.bind(this);
    this._mouseMoveListener = this.onResize.bind(this);
    this.element.addEventListener("mousedown", this._mouseDownListener, false);
    document.addEventListener("mouseup", this._mouseUpListener, false);

    this.batchDraw();
  }

  async beforeDestroy(): Promise<void> {
    this.element.removeEventListener("mousedown", this._mouseDownListener);
    document.removeEventListener("mouseup", this._mouseUpListener);
    document.removeEventListener("mousemove", this._mouseMoveListener, false);
  }

  async doDrawFromBatch(): Promise<void> {
    const headers = this.getColumnHeaders().map((h) => {
      const c = this._rowInfoColumnProvider.columns.find((col) => col.id === h.id);
      h.width = c?.width ?? 10;
      return h;
    });
    const template = headers
      .filter((h) => h.visible !== false)
      .map((h) => `<div class="col-header ${h.className}" id="${h.id}" style="width: ${h.width}px">${h.text}<div class="resize-handle"></div></div>`)
      .join("");
    if (template !== this.element.querySelector(".info-header-template")?.innerHTML) {
      const headerTemplate = this.element.querySelector(".info-header-template");
      headerTemplate!.innerHTML = template;
    }
  }

  private getColumnHeaders() {
    return this._rowInfoColumnProvider?.headers ?? [{ id: "text", className: "text", text: "Name" } as IRowInfoColumnHeader];
  }

  private onMouseDown(e: MouseEvent) {
    const parent = (e.target as HTMLDivElement).parentElement!;
    this._initialMousePosition = e.x;
    this._initialColWidth = parent.clientWidth;
    this._colId = parent.id;
    document.addEventListener("mousemove", this._mouseMoveListener, false);
  }

  private onMouseUp() {
    this._initialMousePosition = 0;
    this._initialColWidth = 0;
    document.removeEventListener("mousemove", this._mouseMoveListener, false);
  }

  private onResize(e: MouseEvent) {
    const diff = e.x - this._initialMousePosition;
    const width = this._initialColWidth + diff;
    if (width >= InfoHeaderContainer.MIN_COL_WIDTH && width <= InfoHeaderContainer.MAX_COL_WIDTH) {
      this._rowInfoColumnProvider?.setColumnWidth(this._colId, width);
    }
  }
}
