import { ApiClient } from "@/services/api/common.api";
import {
  CreateResourceCapacityCommand,
  DeleteResourceCapacityCommand,
  GetResourceCapacitiesPaginatedQuery,
  IAgGridPaginationResponse,
  ResourceCapacityDetailsDto
} from "@masta/generated-model";

export default {
  async createResourceCapacity(request: CreateResourceCapacityCommand) {
    return ApiClient.put(`/resourceCapacities`, request);
  },
  async deleteResourceCapacity(request: DeleteResourceCapacityCommand) {
    return ApiClient.delete(`/resourceCapacities`, request);
  },
  getPaginated(request: GetResourceCapacitiesPaginatedQuery) {
    return ApiClient.post<IAgGridPaginationResponse<ResourceCapacityDetailsDto>>(`/resourceCapacities`, request);
  }
};
