import { ChronoUnit, Instant, TemporalUnit } from "@js-joda/core";

import { ChronoUnitResolution, Position, Resolution } from "../../Core";

import { ChronoUnitTimelineDatelineCell } from "./ChronoUnitTimelineDatelineCell";
import { Dateline } from "./Dateline";
import { TimelineDatelineCell } from "./TimelineDatelineCell";
import { GanttDomElement } from "../GanttDomElement";

export class TimelineDatelineScale extends GanttDomElement<HTMLDivElement> {
  private static _textMeasureCanvas = document.createElement("canvas");

  private _textPadding = 10;

  private _font: string;

  private _fontSize: string;

  private _resolution: Resolution<ChronoUnit> | null;

  private _datelineCellArr = new Array<TimelineDatelineCell<any>>();

  private _cellXPositions = new Array<number>();

  constructor(private dateline: Dateline, private position: Position) {
    super(TimelineDatelineScale.name, undefined, `dateline-scale position-${position}`);
  }

  get resolution(): Resolution<ChronoUnit> | null {
    return this._resolution;
  }

  set resolution(value: Resolution<ChronoUnit> | null) {
    this._resolution = value;
  }

  get cellXPositions(): number[] {
    return this._cellXPositions;
  }

  async afterInitialize(): Promise<void> {
    const computedStyle = window.getComputedStyle(document.querySelector(".gantt2")!);
    this._font = computedStyle.font;
    this._fontSize = computedStyle.fontSize;
  }

  async doDrawFromBatch(): Promise<void> {
    await Promise.all(this._datelineCellArr.map((x) => x.doDrawFromBatch()));
  }

  buildScale(temporalUnit: ChronoUnit): ChronoUnit | null {
    // const sp = Instant.now();
    // console.debug('[Gantt] buildScale before', temporalUnit, this.position, !!this._resolution);
    let success = false;
    // this.clearDrawables();
    do {
      if (this.resolution) {
        // console.debug(`[Gantt] \t-> resolution check F: ${this._resolution} @ ${this.position}`);
        success = this.buildCells(this.resolution, true);
      } else {
        const resolutions = this.dateline.dateLineModel.resolutionMap.get(temporalUnit);
        for (const resolution of resolutions!) {
          // console.log(`res: ${resolution} @ ${this.position}`);
          if (resolution.isSupportingPosition(this.position)) {
            // console.log(`\t-> resolution check ?: ${resolution} @ ${this.position}`);
            success = this.buildCells(resolution, false);
          }
          if (success) {
            break;
          }
        }
        if (!success) {
          const nextUnit = this.dateline.dateLineModel.nextTemporalUnit(temporalUnit);
          if (!nextUnit) return null;
          temporalUnit = nextUnit;
        }
      }
    } while (!success);
    // console.debug('[Gantt] buildScale after', temporalUnit, this.position, `took: ${Duration.between(sp, Instant.now()).toMillis()}`);
    return this.dateline.dateLineModel.nextTemporalUnit(temporalUnit);
  }

  private buildCells(resolution: Resolution<any>, cached: boolean): boolean {
    this._datelineCellArr.forEach((cell) => {
      if (!cached) cell.visible = false;
      cell.managed = false;
    });
    this._cellXPositions.splice(0, this._cellXPositions.length);
    const timelineModel = this.dateline.timeline;
    const { temporalUnit } = resolution;
    // TODO selected intervals from dateline
    const { zoneId, firstDayOfWeek } = this.dateline;

    let success = true;
    let start = resolution.decrement(timelineModel.calculateTimeForLocation(/* TODO dateline buffer */ 0), zoneId);
    start = resolution.decrement(start, zoneId);

    let startTime = resolution.truncateInstant(start, zoneId, firstDayOfWeek);
    let x1 = timelineModel.calculateLocationForTime(startTime) + this.dateline.datelineBuffer; /* TODO dateline buffer */

    const minimumWidth = Math.round(this.getMinimumWidth(startTime, resolution)) + this._textPadding;
    let index = 0;
    while (x1 < this.dateline.width) {
      let endTime = resolution.increment(startTime, zoneId);
      let dstCorrectionInHours = 0;
      if (resolution.temporalUnit === ChronoUnit.HOURS && resolution instanceof ChronoUnitResolution) {
        if (resolution.isDSTEndIncrement()) {
          dstCorrectionInHours = 1;
        } else if (resolution.isDSTStartIncrement()) {
          dstCorrectionInHours = -1;
          endTime = resolution.increment(endTime, zoneId);
        }
      }
      if (dstCorrectionInHours > 0) {
        endTime = endTime.plus(1, ChronoUnit.HOURS);
      } else if (dstCorrectionInHours < 0) {
        endTime.minus(1, ChronoUnit.HOURS);
      }

      x1 = timelineModel.calculateLocationForTime(startTime) + this.dateline.datelineBuffer; /* TODO dateline buffer */
      if (x1 < this.dateline.width) {
        const x2 = timelineModel.calculateLocationForTime(endTime) + this.dateline.datelineBuffer; /* TODO dateline buffer */

        // TODO cache and set visibility DatelineScale#355
        if (cached || x1 + minimumWidth <= x2) {
          const timeDiff = Math.round(x2 - x1);
          const cellWidth = `${timeDiff > minimumWidth ? timeDiff : minimumWidth}px`;
          const cellXPosition = Math.round(x1);
          const translate = `translate(${cellXPosition}px, ${0}px)`;
          this._cellXPositions.push(cellXPosition);
          // eslint-disable-next-line no-loop-func
          const cell = this.getOrCreateDatelineCell(temporalUnit, index++) as ChronoUnitTimelineDatelineCell;
          cell.updateCell(startTime, endTime, resolution, this.dateline, this.position, translate, cellWidth);
          if (!cell.visible) cell.visible = true;
          cell.managed = true;
        } else {
          success = false;
          break;
        }

        startTime = resolution.increment(startTime, zoneId);
        if (dstCorrectionInHours > 0) {
          startTime = startTime.plus(dstCorrectionInHours, ChronoUnit.HOURS);
          // eslint-disable-next-line no-continue
          continue;
        }
        if (dstCorrectionInHours < 0) {
          startTime = endTime;
        }
      }
    }
    if (success) {
      this._datelineCellArr
        .filter((x) => !x.managed)
        .forEach((x) => {
          x.visible = false;
        });
      // this._datelineCellArr.forEach((x) => {
      //   x.batchDraw(false);
      // });
      this.resolution = resolution;
    }
    return success;
  }

  private getOrCreateDatelineCell(temporalUnit: TemporalUnit, index: number) {
    let cell = this._datelineCellArr[index];
    if (!cell) {
      cell = new ChronoUnitTimelineDatelineCell();
      cell.managed = false;
      this.addChild(cell);
      this.appendChild(cell);
      this._datelineCellArr.push(cell);
    }
    return cell;
  }

  private getMinimumWidth(startTime: Instant, resolution: Resolution<ChronoUnit>) {
    const text = resolution.formatInstant(startTime, this.dateline.zoneId);
    return this.getCanvasTextWidth(text, `${this._font} ${this._fontSize}`);
  }

  protected getCanvasTextWidth(text: string, font: string): number {
    const canvas = TimelineDatelineScale._textMeasureCanvas;
    const context = canvas.getContext("2d");
    context!.font = font;
    const metrics = context!.measureText(text);
    return metrics.width;
  }
}
