import { GraphicsMouseEvent, isCtrlKey } from "./GraphicsMouseEvent";

export type GraphicsMouseWheelEvent = GraphicsMouseEvent & {
  deltaX: number;
  deltaY: number;
};

export function createGraphicsMouseWheelEvent(event: WheelEvent): GraphicsMouseWheelEvent {
  return {
    ctrlKey: event.ctrlKey,
    altKey: event.altKey,
    shiftKey: event.shiftKey,
    metaKey: event.metaKey,
    osCtrlKey: isCtrlKey(event),
    deltaX: event.deltaX,
    deltaY: event.deltaY
  };
}
