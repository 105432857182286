<script lang="ts" setup>
import { FeatureDefinition, TextFeatureDefinition } from "@masta/generated-model";
import { $t } from "@/i18n";
import { isValidRegExpRule, requiredRule } from "@/components/ValueCellEditor/CommonValidationRules";

const props = defineProps<{
  modelValue: TextFeatureDefinition;
  disabled: boolean;
}>();

const emit = defineEmits(["update:modelValue", "remove"]);

function update(propertyName: string, value: any) {
  const newFeature = { ...props.modelValue, [propertyName]: value };
  emit("update:modelValue", newFeature);
}

const nameRules = [
  (v: string) => !!v || $t("featureDefinition-textEditor-onNameRequired-message", { $: "Name is required" }),
  (v: string) => (v && v.length > 0) || $t("featureDefinition-textEditor-onNameNotEmptyRequired-message", { $: "Name must not be empty" })
];
</script>

<template>
  <v-card outlined width="100%">
    <v-card-text>
      <v-col cols="12">
        <v-row>
          <v-col cols="4" class="text-center d-flex align-center justify-center">
            <v-chip size="x-large" label class="w-100" color="purple" variant="tonal" :disabled="disabled">
              {{ $t("featureDefinition-textEditor-text-label", { $: "Text" }) }}
            </v-chip>
          </v-col>
          <v-col cols="8" class="text-right align-center justify-center">
            <v-btn variant="text" icon="mdi-close" size="large" hide-details :disabled="disabled" @click="emit('remove')"></v-btn>
          </v-col>
        </v-row>
        <v-row>
          <!-- First Row: Name, Label, and Description -->
          <v-col cols="4">
            <v-text-field
              variant="outlined"
              density="compact"
              hide-details
              :rules="[requiredRule]"
              :label="$t('featureDefinition-textEditor-name-label', { $: 'Name' })"
              :model-value="modelValue.name"
              :disabled="disabled"
              @update:model-value="update('name', $event)"
            />
          </v-col>
          <v-col cols="4">
            <v-text-field
              variant="outlined"
              density="compact"
              hide-details
              :label="$t('featureDefinition-textEditor-label-label', { $: 'Label' })"
              :model-value="modelValue.label"
              :disabled="disabled"
              @update:model-value="update('label', $event)"
            />
          </v-col>
          <v-col cols="4">
            <v-text-field
              variant="outlined"
              density="compact"
              hide-details
              :label="$t('featureDefinition-textEditor-description-label', { $: 'Description' })"
              :model-value="modelValue.description"
              :disabled="disabled"
              @update:model-value="update('description', $event)"
            />
          </v-col>
        </v-row>
        <v-row>
          <!-- Second Row: Required Switch, Chip, and Regular Expression -->
          <v-col cols="2">
            <v-switch
              variant="outlined"
              density="compact"
              hide-details
              color="primary"
              :label="$t('featureDefinition-textEditor-required-label', { $: 'Required' })"
              :model-value="modelValue.isRequired"
              :disabled="disabled"
              @update:model-value="update('isRequired', $event)"
            />
          </v-col>
          <v-col cols="6">
            <v-text-field
              variant="outlined"
              density="compact"
              hide-details
              :rules="[isValidRegExpRule]"
              :label="$t('featureDefinition-textEditor-regularExpression-label', { $: 'Regular Expression' })"
              :model-value="modelValue.regex"
              :disabled="disabled"
              @update:model-value="update('regex', $event)"
            />
          </v-col>
        </v-row>
      </v-col>
    </v-card-text>
  </v-card>
</template>

