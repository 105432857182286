import { ApiClient } from "@/services/api/common.api";
import { GetWorkJournalRecordsPaginatedQuery, IAgGridPaginationResponse, WorkJournalRecordDto } from "@masta/generated-model";

export default {
  async createWorkJournalRecord() {
    throw new Error("Not implemented - it is a leftover from some old feature. if it pops up we need to fix it :D");
    //return ApiClient.post("/workJournalRecords", request);
  },
  async getAll(scenarioId: string) {
    return ApiClient.get<IAgGridPaginationResponse<WorkJournalRecordDto>>(`/workJournalRecords?scenarioId=${scenarioId}`);
  },
  async getPaginated(request: GetWorkJournalRecordsPaginatedQuery) {
    return ApiClient.post<IAgGridPaginationResponse<WorkJournalRecordDto>>(`/workJournalRecords`, request);
  }
};
