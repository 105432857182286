import { inject, injectable } from "inversify";
import { GanttDomElement } from "../GanttDomElement";
import { ViewportRowsContainer } from "./ViewportRowsContainer";
import { GanttEvents, GraphicsDragEvent } from "../../Core";
import { debounceTime, fromEvent } from "rxjs";

@injectable()
export class RowsContainer extends GanttDomElement<HTMLDivElement> {
  private _initialScrollPosition = 0;

  constructor(@inject(ViewportRowsContainer) private _viewportRowsContainer: ViewportRowsContainer, @inject(GanttEvents) private _ganttEvents: GanttEvents) {
    super(RowsContainer.name, undefined, "rows-container");
  }

  async beforeInitialize(): Promise<void> {
    await this.addChild(this._viewportRowsContainer);
  }

  async afterInitialize(): Promise<void> {
    this.subscribe(fromEvent<Event>(this.element, "scroll").subscribe(this.onScroll.bind(this)));
    this.subscribe(
      this._ganttEvents
        .observeResize(this.element)
        .pipe(debounceTime(100))
        .subscribe(() => {
          this._viewportRowsContainer.rowContainers.forEach(async (c) => await c.checkRowVisibilityInViewport());
        })
    );
    this.subscribe(this._ganttEvents.panStartEvent$.subscribe(this.onViewportDragStart.bind(this)));
    this.subscribe(this._ganttEvents.panMoveEvent$.subscribe(this.onViewportDrag.bind(this)));
  }

  private onScroll(e: Event): void {
    this._ganttEvents.onScrollEvent(e.type);
  }

  private onViewportDragStart(_: GraphicsDragEvent): void {
    this._initialScrollPosition = this.element.scrollTop;
  }

  private onViewportDrag(e: GraphicsDragEvent): void {
    const { deltaY, shiftKey, altKey, ctrlKey } = e;

    // move _timeline
    if (shiftKey || ctrlKey || altKey) return;

    const maxScroll = this.element.scrollHeight - this.element.offsetHeight;
    if (deltaY <= maxScroll) {
      // scroll
      this.element.scrollTop = this._initialScrollPosition - deltaY;
    }
  }

  batchDraw(childToo = false) {
    super.batchDraw(childToo);
  }
}
