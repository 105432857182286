import { ChronoUnit, DayOfWeek, ZoneId } from "@js-joda/core";
import { BehaviorSubject, Observable, Subject } from "rxjs";

import { ChronoUnitDatelineModel, IocSymbols, Position, Resolution, TimeInterval, TimelineModel } from "../../Core";

import { TimelineDatelineScale } from "./TimelineDatelineScale";
import { GanttDomElement } from "../GanttDomElement";
import { inject, injectable } from "inversify";

@injectable()
export class Dateline extends GanttDomElement<HTMLDivElement> {
  private _timeline: TimelineModel<any>;

  private _dateLineModel: ChronoUnitDatelineModel;

  private rowMap = new Map<number, TimelineDatelineScale>();

  private _firstDayOfWeek$$ = new BehaviorSubject<DayOfWeek>(DayOfWeek.MONDAY);

  private _width = 0;

  private _datelineBuffer = 0;

  private _scaleResolutions = new Array<Resolution<ChronoUnit>>();

  private _datelineHoverEvent$$ = new BehaviorSubject<TimeInterval | null>(null);

  private _datelineGridMap = new Map<number, Array<number>>();

  constructor(@inject(IocSymbols.TimelineSymbol) timeline: TimelineModel<any>) {
    super(Dateline.name, undefined, "dateline");
    this._timeline = timeline;
    this._dateLineModel = new ChronoUnitDatelineModel();
  }

  get timeline(): TimelineModel<any> {
    return this._timeline;
  }

  get dateLineModel(): ChronoUnitDatelineModel {
    return this._dateLineModel;
  }

  get zoneId$(): Observable<ZoneId> {
    return this.timeline.zoneId$;
  }

  get zoneId(): ZoneId {
    return this.timeline.zoneId;
  }

  set zoneId(value: ZoneId) {
    this._timeline.zoneId = value;
  }

  get firstDayOfWeek$(): Observable<DayOfWeek> {
    return this._firstDayOfWeek$$.asObservable();
  }

  get firstDayOfWeek(): DayOfWeek {
    return this._firstDayOfWeek$$.value;
  }

  set firstDayOfWeek(value: DayOfWeek) {
    this._firstDayOfWeek$$.next(value);
  }

  get width(): number {
    return this._width;
  }

  set width(value: number) {
    this._width = value;
  }

  get datelineBuffer(): number {
    return this._datelineBuffer;
  }

  set datelineBuffer(value: number) {
    this._datelineBuffer = value;
  }

  get scaleResolutions(): Resolution<ChronoUnit>[] {
    return this._scaleResolutions;
  }

  set scaleResolutions(value: Resolution<ChronoUnit>[]) {
    this._scaleResolutions = value;
  }

  get datelineHoverEvent$$(): Subject<TimeInterval | null> {
    return this._datelineHoverEvent$$;
  }

  get datelineHoverTimeInterval(): TimeInterval | null {
    return this._datelineHoverEvent$$.value;
  }

  get datelineGridMap(): Map<number, Array<number>> {
    return this._datelineGridMap;
  }

  async beforeInitialize(): Promise<void> {
    this._width = this.element.clientWidth;

    this.subscribe(
      this.timeline.millisPerPixel$.subscribe(() => {
        this._scaleResolutions.forEach((x) => {
          [...this.rowMap.values()].forEach((s) => {
            s.resolution = null;
          });
        });
      })
    );
  }

  async doDrawFromBatch(): Promise<void> {
    for (const value of this.rowMap.values()) {
      await value.doDrawFromBatch();
    }
  }

  public async buildRows(): Promise<void> {
    // this.clearDrawables();
    this.rowMap.clear();
    const { scaleCount } = this._dateLineModel;
    for (let i = 0; i < scaleCount; i++) {
      const scalePosition = this.getScalePosition(i, scaleCount);
      const datelineScale = new TimelineDatelineScale(this, scalePosition);
      this.rowMap.set(i, datelineScale);
      this._datelineGridMap.set(i, new Array<number>());
      await this.addChild(datelineScale, { beforeAll: true });
    }
    for (const children$$Element of this._children$$.value) {
      this.appendChild(children$$Element);
    }
  }

  public buildDateline(): void {
    const { scaleCount } = this._dateLineModel;
    let temporalUnit = this.timeline.smallestTemporalUnit;
    this._scaleResolutions.splice(0, this._scaleResolutions.length);
    for (let i = 0; i < scaleCount; i++) {
      const datelineScale = this.rowMap.get(i);
      // eslint-disable-next-line no-continue
      if (!datelineScale) continue;
      const nextUnit = datelineScale.buildScale(temporalUnit);
      if (!nextUnit) {
        break;
      }
      temporalUnit = nextUnit;
      if (datelineScale.resolution) {
        this._scaleResolutions.push(datelineScale.resolution);
      }
    }
    // save x positions for gridlines to skip consuming calculation operations
    for (let i = 0; i < scaleCount; i++) {
      const datelineScale = this.rowMap.get(i);
      const grids = this._datelineGridMap.get(i);
      // eslint-disable-next-line no-continue
      if (!datelineScale || !grids) continue;
      grids.splice(0, grids.length);
      datelineScale.cellXPositions.forEach((x) => {
        const posX = x;
        if (posX >= 0) {
          grids.push(posX);
        }
      });
    }
    this._timeline.primaryTemporalUnit = this._scaleResolutions.find((r) => r.isSupportingPosition(Position.TOP))?.temporalUnit ?? temporalUnit;
  }

  private getScalePosition(row: number, scaleCount: number): Position {
    if (scaleCount === 1) return Position.ONLY;
    if (row === 0) return Position.BOTTOM;
    if (row === scaleCount - 1) return Position.TOP;
    return Position.MIDDLE;
  }
}
