import { IocContainer, IocSymbols, IRowInfoCell, IRowInfoColumn, IRowInfoColumnCellRegistry, Lifecycle, onActivationLifecycle } from "../../../../Core";
import { inject, injectable, interfaces } from "inversify";
import { GanttDomElement } from "../../../GanttDomElement";

@injectable()
export class RowInfoColumnCellRegistry extends Lifecycle implements IRowInfoColumnCellRegistry {
  private _cellClasses: Map<string, new (...args: any[]) => any> = new Map<string, new (...args: any[]) => any>();

  constructor() {
    super();
  }

  register(columnId: string, cellClass: new (...args: any[]) => IRowInfoCell): void {
    this._cellClasses.set(columnId, cellClass);
  }

  async createCell(column: IRowInfoColumn): Promise<any> {
    // return await this._iocContainer.getNamedAsync<GanttDomElement<HTMLDivElement>>(IocSymbols.RowInfoCell, column.id);
  }

  bindCells(container: interfaces.Container): void {
    this._cellClasses.forEach((cellClass, id) => {
      container.bind<GanttDomElement<HTMLDivElement>>(IocSymbols.RowInfoCell).to(cellClass);
    });
  }
}
