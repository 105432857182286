import { ResourceInfoDto } from "@masta/generated-model";
import { ProductTemplateDraft, StepDraft, StepDraftResourceSpec } from "@/components/ProcessDrafts/ProcessDraftModels";

type ClosableValidationError = {
  isErrorShown?: boolean;
};

type MessageValidationError = {
  message: string;
};

type ValidationErrorWithProductTemplate = {
  productTemplate: ProductTemplateDraft;
} & MessageValidationError &
  ClosableValidationError;

type ValidationErrorWithStep = {
  productTemplate: ProductTemplateDraft;
  step: StepDraft; // make step required
} & MessageValidationError &
  ClosableValidationError;

type ValidationErrorWithSpec = {
  productTemplate: ProductTemplateDraft;
  step: StepDraft; // make step required
  spec: StepDraftResourceSpec; // make spec required
  specPos: number;
} & MessageValidationError &
  ClosableValidationError;

export type ProcessValidationError = (MessageValidationError & ClosableValidationError) | ValidationErrorWithSpec | ValidationErrorWithStep | ValidationErrorWithProductTemplate;

export function filterValidationError(
  validationError: ProcessValidationError,
  context?: {
    productTemplate?: ProductTemplateDraft;
    step?: StepDraft;
    spec?: StepDraftResourceSpec;
    specPos?: number;
    resource?: ResourceInfoDto;
  }
): ProcessValidationError | undefined {
  // console.log(
  //   "filterValidationError",
  //   "PT: ",
  //   context?.productTemplate?.name,
  //   "Step:",
  //   context?.step?.name,
  //   "Spec: ",
  //   context?.spec,
  //   "SpecPos: ",
  //   context?.specPos,
  //   "Error: ",
  //   validationError
  // );
  const error: ValidationErrorWithSpec = validationError as ValidationErrorWithSpec;
  if (!context && !error.productTemplate) {
    return validationError;
  }
  if (!context) {
    return;
  }
  if (error.productTemplate === context.productTemplate && context.resource && error.productTemplate.resource === context.resource) {
    return validationError;
  }
  if (error.productTemplate === context.productTemplate && !context.step && !error.step) {
    return validationError;
  }
  if (error.productTemplate === context.productTemplate && context.step && error.step === context.step && !context.spec) {
    return validationError;
  }
  if (error.productTemplate === context.productTemplate && context.step && error.step === context.step && error.spec === context.spec && !context.specPos) {
    return validationError;
  }
  if (error.productTemplate === context.productTemplate && context.step && error.step === context.step && error.spec === context.spec && error.specPos === context.specPos) {
    return validationError;
  }
  return;
}
