import { inject, injectable } from "inversify";
import { GanttDomElement } from "../GanttDomElement";
import { BelowRowsContentCanvasContainer } from "./BelowRowsContentCanvasContainer";
import { AboveRowsContentCanvasContainer } from "./AboveRowsContentCanvasContainer";
import { ViewportPointerEventNoneScrollGlass } from "./ViewportPointerEventNoneScrollGlass";
import { IGanttWorker, RowMouseBtnPressedEvent } from "../../Core";
import {
  ActivityActionEvent,
  ActivityBounds,
  createActivityActionEvent,
  createLassoRowSelectionEvent,
  createRowMouseBtnPressedEvent,
  createTooltipActivity,
  GanttEvents,
  GanttSettings,
  GraphicsMousePositionEvent,
  IocSymbols,
  LassoRowSelectionEvent,
  Layer,
  ObservableDataSet,
  Row,
  RowHoverEvent,
  SettingKey
} from "../../Core";
import { BehaviorSubject, concatMap, debounceTime, distinctUntilChanged, filter, from, map, merge, startWith, Subscription, withLatestFrom } from "rxjs";
import { RowContainer } from "./Row";
import { Instant } from "@js-joda/core";

@injectable()
export class ViewportRowsContainer extends GanttDomElement<HTMLDivElement> {

  public static readonly DragAndDropContext = Symbol("dnd");

  private _rowContainers = new Array<RowContainer<Row<any, any, any>>>();
  private _rowSubscriptions = new Map<string, Subscription>();
  private _rowSortingFunction: (a: Row<any, any, any>, b: Row<any, any, any>) => number | undefined;

  constructor(
    @inject(AboveRowsContentCanvasContainer) private _aboveRowsContentCanvasContainer: AboveRowsContentCanvasContainer,
    @inject(BelowRowsContentCanvasContainer) private _belowRowsContentCanvasContainer: BelowRowsContentCanvasContainer,
    @inject(ViewportPointerEventNoneScrollGlass) private _viewportPointerEventNoneScrollGlass: ViewportPointerEventNoneScrollGlass,
    @inject(IocSymbols.RowsSymbol) private _rows: ObservableDataSet<Row<any, any, any>>,
    @inject(IocSymbols.RowContainerFactorySymbol) private _rowContainerFactory: (row: Row<any, any, any>) => Promise<RowContainer<Row<any, any, any>>>,
    @inject(GanttEvents) private _ganttEvents: GanttEvents,
    @inject(IocSymbols.GanttWorkerSymbol) private _ganttWorker: IGanttWorker,
    @inject(IocSymbols.LayersSymbol) private _layers$$: BehaviorSubject<Layer[]>,
    @inject(GanttSettings) private _settings: GanttSettings
  ) {
    super(ViewportRowsContainer.name, undefined, "viewport-rows-container");
  }

  get rowContainers(): RowContainer<Row<any, any, any>>[] {
    return this._rowContainers;
  }

  async beforeInitialize(): Promise<void> {
    await this.addChildren([this._belowRowsContentCanvasContainer, this._aboveRowsContentCanvasContainer, this._viewportPointerEventNoneScrollGlass]);
  }

  async afterInitialize(): Promise<void> {
    this.subscribe(this._ganttEvents.timelineRefreshEvent$.subscribe(this.onTimelineRefresh.bind(this)));
    this.subscribe(
      // add row in queue not concurrently
      this._rows.added$
        .pipe(
          map((x) => x.item),
          concatMap((item) => from(this.onRowAdded(item)))
        )
        .subscribe()
    );
    this.subscribe(this._rows.removed$.pipe(map((x) => x.item)).subscribe(this.onRowRemoved.bind(this)));

    // get row sorting function
    this.subscribe(
      this._settings
        .getSetting$(SettingKey.ROW_SORTING_FUNCTION, (a: Row<any, any, any>, b: Row<any, any, any>) => {
          return a.name.localeCompare(b.name);
        })
        .subscribe((func) => {
          if (func) {
            this._rowSortingFunction = func;
          }
        })
    );

    // row hover
    this.subscribe(
      merge(
        this._ganttEvents.mousePositionEvent$,
        this._ganttEvents.mouseLeaveEvent$.pipe(
          startWith(null),
          map(() => null)
        ),
        this._ganttEvents.mouseEnterEvent$.pipe(
          startWith(null),
          map(() => null)
        )
      )
        .pipe(
          map((mousePositionEvent: GraphicsMousePositionEvent | null) => {
            if (!mousePositionEvent) {
              return null;
            }
            for (const rowContainer of this._rowContainers) {
              if (mousePositionEvent.y >= rowContainer.element.offsetTop && mousePositionEvent.y <= rowContainer.element.offsetTop + rowContainer.element.offsetHeight) {
                return rowContainer;
              }
            }
            return null;
          }),
          distinctUntilChanged((previous, current) => previous?.identifier === current?.identifier)
        )
        .subscribe((rowContainer) => {
          const event = rowContainer
            ? ({
              rowId: rowContainer.row.id,
              offsetTop: rowContainer.element.offsetTop
            } as RowHoverEvent)
            : null;
          this._ganttEvents.onRowHoverEvent(event);
          // workerGanttEvents.onRowHoverEvent(event);
          // this._ganttWorkerWorker.postMessage({ type: "APPLY", path: ["ganttEvents", "onRowHoverEvent"], argumentList: [{ type: "RAW", value: e }] });
        })
    );

    // row mouse pressed
    this.subscribe(
      this._ganttEvents.mousePressedEvent$.subscribe((mousePositionEvent) => {
        for (const rowContainer of this._rowContainers) {
          if (mousePositionEvent.y >= rowContainer.element.offsetTop && mousePositionEvent.y <= rowContainer.element.offsetTop + rowContainer.element.offsetHeight) {
            const event = createRowMouseBtnPressedEvent(mousePositionEvent, rowContainer.element, rowContainer.row.id);
            this._ganttEvents.onRowMousePressedEvent(event);
            // workerGanttEvents.onRowMousePressedEvent(event);
          }
        }
      })
    );

    // activity hover
    this.subscribe(
      this._ganttEvents.mousePositionEvent$.pipe(withLatestFrom(this._ganttEvents.rowHoverEvent$)).subscribe(async ([mousePosEvent, rowHoverEvent]) => {
        // if mouse is in drag and drop context, do not hover
        if (mousePosEvent?.source === ViewportRowsContainer.DragAndDropContext) {
          if (this._ganttEvents.hoveredActivities$$.value.length > 0) {
            this._ganttEvents.hoveredActivities$$.next([]);
          }
          return;
        }
        if (!rowHoverEvent) {
          if (this._ganttEvents.hoveredActivities$$.value.length > 0) {
            const rowIds = this._ganttEvents.hoveredActivities$$.value.map((x) => x.row.id);
            this._ganttEvents.hoveredActivities$$.next([]);
            this.rowContainers.filter((x) => rowIds.includes(x.row.id)).forEach((x) => x.batchDraw(true));
          }
          return;
        }
        const eventsForDeletion = this._ganttEvents.hoveredActivities$$.value.filter((x) => x.row.id !== rowHoverEvent.rowId);
        if (eventsForDeletion.length > 0) {
          this._ganttEvents.deleteHoveredActivities(eventsForDeletion);
        }
        const relativeMousePosition = { x: mousePosEvent.x, y: mousePosEvent.y - rowHoverEvent.offsetTop };
        const rowContainer = this._rowContainers.find((x) => x.row.id === rowHoverEvent.rowId);
        if (!rowContainer) {
          return;
        }
        const bounds = rowContainer.getActivityBoundsForMousePosition(relativeMousePosition);
        const hovered = bounds
          .map((x) => createActivityActionEvent(x, this._layers$$.value, rowContainer.row))
          .filter((x) => x !== undefined && !x?.layer.disablePointerEvents) as ActivityActionEvent[];

        const alreadyHovered = this._ganttEvents.hoveredActivities$$.value.filter((x) => hovered.filter((h) => this._ganttEvents.areActivityEventsEqual(x, h)).length > 0);
        if (hovered.length > 0 && alreadyHovered.length === 0) {
          this._ganttEvents.hoveredActivities$$.next(hovered);
          rowContainer.batchDraw(true);
        } else if (hovered.length === 0 && alreadyHovered.length > 0) {
          this._ganttEvents.hoveredActivities$$.next([]);
          rowContainer.batchDraw(true);
        } else if (hovered.length === 0 && alreadyHovered.length === 0 && this._ganttEvents.hoveredActivities$$.value.length > 0) {
          this._ganttEvents.hoveredActivities$$.next([]);
          rowContainer.batchDraw(true);
        }

        if (eventsForDeletion.length > 0) {
          const rowIds = eventsForDeletion.map((x) => x.row.id);
          this.rowContainers.filter((x) => rowIds.includes(x.row.id)).forEach((x) => x.batchDraw(true));
        }
      })
    );

    // dateline hover
    this.subscribe(
      this._ganttEvents.datelineHoverEvent$.pipe(debounceTime(10)).subscribe((e) => {
        const alreadyHoveredActivities = this._ganttEvents.hoveredActivities$$.value;
        const alreadyHoveredRowIds = alreadyHoveredActivities.map((x) => x.row.id);
        const hovered: ActivityActionEvent[] = [];
        for (const rowContainer of this._rowContainers) {
          if (e) {
            const start = Instant.ofEpochMilli(e.start);
            const end = Instant.ofEpochMilli(e.end);
            const bounds = rowContainer.getActivityBoundsForTimeInterval(start, end);
            const events = bounds
              .map((x) => createActivityActionEvent(x, this._layers$$.value, rowContainer.row))
              .filter((x) => x !== undefined && !x?.layer.disablePointerEvents) as ActivityActionEvent[];
            hovered.push(...events);
          }
        }
        const hoveredRowIds = [...new Set(hovered.map((x) => x.row.id))];
        this._ganttEvents.hoveredActivities$$.next(hovered);
        this.rowContainers
          .filter((rc) => alreadyHoveredRowIds.includes(rc.row.id) || hoveredRowIds.includes(rc.row.id))
          .forEach((rc) => {
            rc.batchDraw(true);
          });
      })
    );

    // selection / press
    this.subscribe(
      this._ganttEvents.rowMousePressedEvent$.subscribe((e) => {
        if (!e) return;

        const relativeMousePosition = { x: e.relX, y: e.relY };
        const rowContainer = this._rowContainers.find((x) => x.row.id === e.rowId);
        if (!rowContainer) return;

        const bounds = rowContainer.getActivityBoundsForMousePosition(relativeMousePosition);
        const events = bounds
          .map((x) => createActivityActionEvent(x, this._layers$$.value, rowContainer.row))
          .filter((x) => x !== undefined && !x?.layer.disablePointerEvents && x.activity.selectable && !x.activity.locked) as ActivityActionEvent[];

        let draw = false;

        if (e.type === "pointerdown") {
          // pressed
          if (bounds.length >= 1 && !e.osCtrlKey && !e.altKey && !e.shiftKey) {
            const pressed = events[events.length - 1];
            if (pressed !== undefined) {
              this._ganttEvents.pressedActivities$$.next([pressed]);
              draw = true;
            }
          }
          // selected
          if (bounds.length === 1 && e.osCtrlKey && !e.altKey && !e.shiftKey) {
            const selectedEvents = this._ganttEvents.selectedActivities$$.value;
            const toExclude = events.filter((ev) => selectedEvents.filter((se) => this._ganttEvents.areActivityEventsEqual(ev, se)).length > 0);
            const selected = [...this._ganttEvents.selectedActivities$$.value, ...events].filter(
              (x) => toExclude.filter((y) => this._ganttEvents.areActivityEventsEqual(x, y)).length === 0
            );
            this._ganttEvents.selectedActivities$$.next(selected);
            draw = true;
            // draw rows from deselected activities
            const rowIds = toExclude.map((x) => x.row.id).filter(x => x !== rowContainer.row.id);
            this.rowContainers.filter((x) => rowIds.includes(x.row.id)).forEach((x) => x.batchDraw(true));
          }
          // clear selected for lasso
          if (!e.osCtrlKey && !e.altKey && e.shiftKey) {
            if (this._ganttEvents.selectedActivities$$.value.length > 0) {
              const rowIds = this._ganttEvents.selectedActivities$$.value.map((x) => x.row.id);
              this._ganttEvents.selectedActivities$$.next([]);
              this.rowContainers.filter((x) => rowIds.includes(x.row.id)).forEach((x) => x.batchDraw(true));
            }
          }
        }

        if (e.type === "pointerup") {
          // unpressed
          if (!e.altKey && !e.shiftKey) {
            if (this._ganttEvents.pressedActivities$$.value.length > 0) {
              const rowIds = this._ganttEvents.pressedActivities$$.value.map((x) => x.row.id);
              this._ganttEvents.pressedActivities$$.next([]);
              this.rowContainers.filter((x) => rowIds.includes(x.row.id)).forEach((x) => x.batchDraw(true));
            }
          }
          // deselected
          if (bounds.length === 0 && e.osCtrlKey && !e.altKey && !e.shiftKey) {
            if (this._ganttEvents.selectedActivities$$.value.length > 0) {
              const rowIds = this._ganttEvents.selectedActivities$$.value.map((x) => x.row.id);
              this._ganttEvents.selectedActivities$$.next([]);
              this.rowContainers.filter((x) => rowIds.includes(x.row.id)).forEach((x) => x.batchDraw(true));
            }
          }
        }

        if (draw) {
          rowContainer.batchDraw(true);
        }
      })
    );

    // lasso selection
    this.subscribe(
      this._ganttEvents.lassoEvent$
        .pipe(
          map((e) => {
            const { yStart, yEnd } = e;
            const rowIds = new Array<{ id: string; offsetTop: number }>();
            for (const rc of this._rowContainers) {
              const containerStartY = rc.element.offsetTop;
              const containerEndY = rc.element.offsetTop + rc.element.offsetHeight;
              if (
                (yStart >= containerStartY && yStart <= containerEndY) ||
                (yEnd >= containerStartY && yEnd <= containerEndY) ||
                (yStart <= containerStartY && yEnd >= containerEndY)
              ) {
                rowIds.push({ id: rc.row.id, offsetTop: rc.element.offsetTop });
              }
            }
            return createLassoRowSelectionEvent(e, rowIds);
          }),
          map((e) => {
            const bounds = this.getActivitiesForLasso(e);
            const events = [];
            for (const rowContainer of this._rowContainers) {
              if (e.selectedRows.find((x) => x.id === rowContainer.row.id)) {
                const _events = bounds
                  .map((x) => createActivityActionEvent(x, this._layers$$.value, rowContainer.row))
                  .filter((x) => x !== undefined && !x?.layer.disablePointerEvents && x.activity.selectable) as ActivityActionEvent[];
                events.push(..._events);
              }
            }
            return events;
          }),
          distinctUntilChanged((x, y) => x.length === y.length && x.every((v, i) => this._ganttEvents.areActivityEventsEqual(v, y[i])))
        )
        .subscribe((events) => {
          let rowIds = this._ganttEvents.selectedActivities$$.value.map((x) => x.row.id);
          rowIds.push(...events.map((x) => x.row.id));
          rowIds = rowIds.filter((v, i, a) => a.indexOf(v) === i);
          this._ganttEvents.selectedActivities$$.next(events);
          this.rowContainers.filter((x) => rowIds.includes(x.row.id)).forEach((x) => x.batchDraw(true));
        })
    );

    // Activity tooltip
    this.subscribe(
      this._ganttEvents.hoveredActivities$$
        .pipe(distinctUntilChanged((x, y) => x.length === y.length && x.every((v, i) => this._ganttEvents.areActivityEventsEqual(v, y[i]))))
        .subscribe((hooveredActEvents) => {
          if (hooveredActEvents.length === 0) {
            this._ganttEvents.onTooltipActivityEvent(null);
            return;
          } else {
            const hooveredActEvent = hooveredActEvents[0];
            const activity = hooveredActEvent.activity;
            const event = createTooltipActivity(hooveredActEvent, activity.toJSON(), activity.constructor.name);
            this._ganttEvents.onTooltipActivityEvent(event);
          }
        })
    );

    // // Dateline hover
    // this.subscribe(
    //   this._ganttEvents.datelineHoverEvent$.subscribe((e) => {
    //     workerGanttEvents.onDatelineHoverEvent(e);
    //   })
    // );
    //
    // // Activity hover
    // await this._ganttWorker.onTooltipActivityChanged(proxy(this.onTooltipActivityChanged.bind(this)));
  }

  private async onTimelineRefresh() {
    this.batchDraw(true);
    await this.yieldToMain();
  }

  private async onRowAdded(row: Row<any, any, any>) {
    const parentRowContainer = this._rowContainers.find((x) => x.identifier === row.parentId);

    // if (parentRowContainer) {
    //   console.debug(`%c[Gantt] Parent row container found for row [${row.name}] (${row.id}).`, "color: green", parentRowContainer);
    // } else {
    //   console.debug(`%c[Gantt] Parent row container not found for row [${row.name}] (${row.id}).`, "color: yellow", this._rowContainers);
    // }

    // prevent adding row if it's parent is not expanded
    if (parentRowContainer && !parentRowContainer.row.expanded) {
      // console.debug(`%c[Gantt] Parent row container not expanded for row [${row.name}] (${row.id}). Skipping.`, "color: yellow");
      return;
    }

    const container = await this.addRowContainer(row);
    // console.debug(
    //   `[Gantt] Added row container: [${row.name}] (${row.id})`,
    //   this._children$$.value.map((x) => x.identifier)
    // );
    container.batchDraw(true);
  }

  private async onRowRemoved(row: Row<any, any, any>) {
    // console.debug(`[Gantt] Removing row container: [${row.name}] (${row.id})`);
    await this.removeChildRows(row);
    await this.removeRowContainer(row);
  }

  private async subscribeToRowEvents<TResource extends Row<any, any, any>>(row: TResource, _: RowContainer<TResource>) {
    // console.debug(`[Gantt] Subscribing to row events: [${row.name}] (${row.id})`);
    const subscription = new Subscription();
    this._rowSubscriptions.set(row.id, subscription);

    subscription.add(
      row.children.added$.subscribe(async ({ item }) => {
        // console.debug(`[Gantt] child Row added: [${row.name}] (${row.id})`, item);
        // set level number
        item.levelNumber = row.levelNumber + 1;
        this._rowContainers.find((d) => d.identifier === row.id)?.batchDraw(true);
        // await Promise.all(container.draw());
      })
    );
    subscription.add(
      row.children.removed$.subscribe(async ({ item }) => {
        this._rowContainers.find((d) => d.identifier === row.id)?.batchDraw(true);
        // this._rowContainers.find((d) => d.identifier === row.id)?.rowInfoContainer.batchDraw();
        // await Promise.all(container.draw());
      })
    );
    subscription.add(
      row.expanded$.pipe(filter(() => this._rowContainers.some((x) => x.identifier === row.id))).subscribe(async (expanded) => {
        if (row.children.length === 0) return;
        if (expanded) {
          // console.debug(
          //   `[Gantt] Row expanded: [${row.name}] (${row.id})`,
          //   [...row.children.data.values()].map((x) => x.name),
          //   this._rowContainers.map((x) => x.row.name)
          // );
          for (const childRow of row.children.data.values()) {
            let rowContainer = this._rowContainers.find((x) => x.identifier === childRow.id);
            if (!rowContainer) {
              // console.debug(
              //   `[Gantt] Child row container not found for expanded parent "${row.name}". Adding row container: ${childRow.name}. Exisiting row containers are: `,
              //   this._rowContainers
              // );
              rowContainer = await this.addRowContainer(childRow);
            }
            await rowContainer.checkRowVisibilityInViewport();
          }
          this._rowContainers.find((d) => d.identifier === row.id)?.batchDraw(true);
        } else {
          // console.debug(`[Gantt] Row folded: [${row.name}] (${row.id})`);
          await this.removeChildRows(row);
          this._rowContainers.find((d) => d.identifier === row.id)?.batchDraw(true);
        }
      })
    );
  }

  private unsubscribeRowEvents<TResource extends Row<any, any, any>>(rowId: string): void {
    const sub = this._rowSubscriptions.get(rowId);
    if (sub) {
      sub.unsubscribe();
      this._rowSubscriptions.delete(rowId);
    }
  }

  private async removeChildRows(row: Row<any, any, any>) {
    const rowContainer = this._rowContainers.find((d) => d.identifier === row.id);
    if (rowContainer) {
      for (const childRow of row.children.data.values()) {
        await this.removeChildRows(childRow);
        await this.removeRowContainer(childRow);
        // console.debug("Removed child row: ", childRow.name, childRow.id);
      }
    }
  }

  private async addRowContainer(row: Row<any, any, any>): Promise<RowContainer<Row<any, any, any>>> {
    // console.debug(`[Gantt] Adding row container: [${row.name}] (${row.id})`);

    if (this._rowContainers.find((x) => x.identifier === row.id)) {
      throw new Error(`[Gantt] Row container already exists: [${row.name}] (${row.id})`);
    }

    const rowContainer = await this._rowContainerFactory(row);
    await this.subscribeToRowEvents(row, rowContainer);

    this._rowContainers.push(rowContainer);

    // await this.removeChildRows(row);

    this._rowContainers = this.sortRowContainers<RowContainer<any>>(this._rowContainers);

    await this.addChildren([rowContainer], this._aboveRowsContentCanvasContainer.identifier);

    // Sort children
    const childrenRows = this.children.filter((x) => this._rowContainers.map((rc) => rc.row.id).includes(x.identifier));
    const sortedChildrenRows = this.sortRowContainers<any>(childrenRows);
    this._children$$.next([this._belowRowsContentCanvasContainer, ...sortedChildrenRows, this._aboveRowsContentCanvasContainer, this._viewportPointerEventNoneScrollGlass]);

    let lastElement = this._aboveRowsContentCanvasContainer.element;
    for (const rc of this._rowContainers) {
      if (lastElement.isConnected) {
        // console.debug("[Gantt] Inserting row container: ", rc.row.name, rc.row.id, rc.row.parentId, rc.row.levelNumber, "before: ", lastElement);
        if (!rc.element.isConnected) {
          this.element.insertBefore(rc.element, lastElement === this._aboveRowsContentCanvasContainer.element ? lastElement : lastElement.nextSibling);
          rc.elementAppended();
        } else {
          this.element.insertBefore(rc.element, lastElement === this._aboveRowsContentCanvasContainer.element ? lastElement : lastElement.nextSibling);
          rc.elementAppended();
        }
        lastElement = rc.element;
      } else {
        console.warn("Element not connected: ", lastElement);
      }
    }

    // this.element.insertBefore(rowContainer.element, anchorContainer.element);
    // rowContainer.elementAppended();

    // console.debug("--------------\nSorted row containers: ");
    // this._rowContainers.forEach((x) => {
    //   console.debug(x.row.name, x.row.id, x.row.parentId, x.row.levelNumber);
    // });
    await rowContainer.checkRowVisibilityInViewport();
    return rowContainer;
  }

  private sortRowContainers<T extends { row: Row<any, any, any> }>(containers: T[], parentId: string | null = null, levelNumber = 0): T[] {
    let filteredContainers: T[] = [];
    if (levelNumber === 0 && !parentId) {
      filteredContainers = containers.filter((x) => x.row.levelNumber === levelNumber && (x.row.parentId === parentId || !x.row.parent));
    } else {
      filteredContainers = containers.filter((x) => x.row.parentId === parentId);
    }

    const sortFunction = (a: Row<any, any, any>, b: Row<any, any, any>) => {
      const sortingResult = this._rowSortingFunction(a, b);
      if (sortingResult !== undefined && sortingResult !== null && !isNaN(sortingResult)) {
        return sortingResult;
      }
      return a.name.localeCompare(b.name);
    };

    filteredContainers.sort((a, b) => sortFunction(a.row, b.row));

    const sortedContainers: T[] = [];

    for (const container of filteredContainers) {
      sortedContainers.push(container);
      const children = this.sortRowContainers(containers, container.row.id, levelNumber + 1);
      sortedContainers.push(...children);
    }

    return sortedContainers;
  }

  private async removeRowContainer(row: Row<any, any, any>) {
    const idx = this._rowContainers.findIndex((x) => x.identifier === row.id);
    if (idx > -1) {
      const rowContainer = this._rowContainers[idx];

      // console.debug(`%c[Gantt] Removing row container: [${row.name}] (${row.id})`, "color: red");

      this._rowContainers.splice(idx, 1);
      await this.removeChildren([rowContainer]);
    }
    // console.debug(`[Gantt] Unsubscribing row events: [${row.name}] (${row.id})`);
    this.unsubscribeRowEvents(row.id);
  }

  private getActivitiesForLasso(lasso: LassoRowSelectionEvent): Array<ActivityBounds> {
    const bounds = [];
    for (const rc of this._rowContainers) {
      for (const selectedRow of lasso.selectedRows) {
        if (selectedRow.id === rc.row.id) {
          bounds.push(...rc.activityBounds.filter((b) => this.isActivityInLasso(lasso, selectedRow.offsetTop, b)));
          break;
        }
      }
    }
    return bounds;
  }

  private isActivityInLasso(lasso: LassoRowSelectionEvent, offsetTop: number, bounds: ActivityBounds): boolean {
    const { xStart, xEnd, yStart, yEnd } = lasso;
    const { x, y, width, height } = bounds;
    const eYStart = yStart - offsetTop;
    const eYEnd = yEnd - offsetTop;
    const intersectsX = (xStart >= x && xStart <= x + width) || (xEnd >= x && xEnd <= x + width) || (xStart <= x && xEnd >= x + width);
    const intersectsY = (eYStart >= y && eYStart <= y + height) || (eYEnd >= y && eYEnd <= y + height) || (eYStart <= y && eYEnd >= y + height);
    return intersectsX && intersectsY;
  }
}
