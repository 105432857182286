﻿import type { IRowContainer } from "@masta/gantt2/core";
import { ActivityBounds, ActivityPosition, ActivityRef, ActivityRenderer, IActivityRenderingRequest, IocSymbols, TimelineManager } from "@masta/gantt2/core";
import { inject, injectable } from "inversify";
import { WaitingSchedulingResourceCapacityActivity } from "src/components/Gantt/Common/Model";
import { StatusColorHelper } from "@/components/Gantt/ResourcesGantt/Renderers/StatusColorHelper";

@injectable()
export class WaitingActivityRenderer extends ActivityRenderer<WaitingSchedulingResourceCapacityActivity> {
  private _minWidthForLineIndicator = 6;

  constructor(
    @inject(TimelineManager) timelineManager: TimelineManager,
    @inject(IocSymbols.RowContainer) rowContainer: IRowContainer,
    @inject(StatusColorHelper) private _statusColorHelper: StatusColorHelper
  ) {
    super(timelineManager, rowContainer, WaitingActivityRenderer.name);
    this.paddingInsets.left = 4;
    this.paddingInsets.right = 4;
    this._minWidth = 0;
  }

  draw(
    activityRef: ActivityRef<WaitingSchedulingResourceCapacityActivity>,
    position: ActivityPosition,
    rowCanvasContext: CanvasRenderingContext2D | OffscreenCanvasRenderingContext2D,
    x: number,
    y: number,
    w: number,
    h: number,
    offsetTop: number,
    selected: boolean,
    hover: boolean,
    highlighted: boolean,
    pressed: boolean
  ): ActivityBounds | undefined {
    if (w <= this._minWidth) return;

    const statusBarHeight = Math.round(h / 8);

    const sX = x;
    const sY = y + h - statusBarHeight * 2;
    const sW = w;
    const sH = statusBarHeight;

    x = sX;
    y = sY;
    w = sW;
    h = sH * 2;
    // y = y + (h - 2) - statusBarHeight * 2;
    // h = statusBarHeight * 2 + 2;

    return super.draw(activityRef, position, rowCanvasContext, x, y, w, h, offsetTop, selected, hover, highlighted, pressed);
  }

  drawActivity(request: IActivityRenderingRequest<WaitingSchedulingResourceCapacityActivity>): void {
    const { activityBounds, activityRef, position, canvas, context, x, y, w, h, offsetTop, selected, hover, highlighted, pressed } = request;

    if (w < this._minWidthForLineIndicator) {
      // draw vertical dashed line for smaller activities
      context.save();
      context.lineWidth = 6;
      context.strokeStyle = this.fill$.value!;
      context.beginPath();
      context.setLineDash([4, 4]);
      context.moveTo(x, y);
      context.lineTo(x, y + h);
      context.stroke();
      context.closePath();
      context.restore();
      return;
    }

    context.clearRect(0, 0, w, h);

    context.globalAlpha = this.opacity;

    const { step } = activityRef.activity.userObject;

    const statusBarHeight = Math.round(h / 2);
    if (step && step.statistics) {
      const sX = x;
      const sY = y + statusBarHeight;
      const sW = w;
      const sH = statusBarHeight;
      context.fillStyle = this._statusColorHelper.getStepStatusBackgroundColor(step.statistics.totalStatus);
      context.fillRect(sX, sY, sW, sH);
    }
  }
}
