export interface SchemaModel {
  title: string;
  description?: string;
  type: string;
  properties: SchemaObjectPropertyModel[];
}

export interface SchemaObjectPropertyModel {
  name: string;
  value: SchemaTypeModel;
}

export interface SchemaTypeModel {
  type: string;
  icon: string;
  color: string;
}

export interface SchemaTypeStringModel extends SchemaTypeModel {
  minLength: number | null;
  maxLength: number | null;
  pattern: string;
}

export interface SchemaTypeNumberModel extends SchemaTypeModel {
  multipleOf?: number;
  minimum?: number;
  exclusiveMinimum?: number;
  maximum?: number;
  exclusiveMaximum?: number;
}

export interface SchemaTypeEnumModel extends SchemaTypeModel {
  items: string[];
  multiple: boolean;
}

export interface SchemaTypeArrayModel extends SchemaTypeModel {
  items: SchemaTypeModel;
  minContains?: number;
  maxContains?: number;
  uniqueItems?: boolean;
}

export function createEmptySchemaModel(): SchemaModel {
  return {
    title: "",
    description: "",
    type: "object",
    properties: []
  };
}
