import { inject, injectable } from "inversify";
import { GanttSettings, type IContext, IocSymbols, Row } from "@masta/gantt2/core";
import { ExtendedGanttResourceDto, ResourceRow } from "@/components/Gantt/Common/Model";
import { RowFactoryBase } from "@/components/Gantt/Common/RowFactoryBase";

@injectable()
export class ResourceRowFactory extends RowFactoryBase {

  constructor(@inject(IocSymbols.Context) _context: IContext, @inject(GanttSettings) _settings: GanttSettings) {
    super(_context, _settings);
  }

  getRowInstance(row: ExtendedGanttResourceDto): Row<any, any, any> {
    return new ResourceRow(row, this._defaultRowHeight);
  }
}
