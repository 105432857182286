<script lang="ts" setup>
/**
 * @deprecated no longer used. Resource Picker use ordinary grids instead of this component.
 */
import GridWrapper from "@/components/Grid/GridWrapper.vue";
import { computed, ref } from "vue";
import { GridWrapperComponent } from "@/components/Grid/GridWrapperComponent";
import FilterGridAction, { FilterGridActionItem } from "@/components/Grid/Filters/FilterGridAction.vue";
import { useFilterGridAction } from "@/components/Grid/Filters/UseFilterGridAction";
import { FilterChangedEvent, GridReadyEvent, KeyCreatorParams, RowNode, ValueFormatterParams } from "ag-grid-community";
import { $t } from "@/i18n";
import {
  AgGridFilterModel,
  CreateMaterialCommand,
  CreateOrUpdateProductTemplateCommand,
  CreateStepResourceSpecTemplateDto,
  CreateStepTemplateDto,
  MeasurementUnit,
  ResourceDto,
  ResourceInfoDto,
  ResourceSubType,
  ResourceType,
  StepResourceUsageType,
  StepType,
  TaskSubType
} from "@masta/generated-model";
import { useServerSideGroup, UseServerSideGroupParams } from "@/components/Grid/UseServerSideGroup";
import { translateResourceType } from "@/composables/translateEnum";
import { ProductTemplateUsableResourcesServerSideDataSource } from "@/components/ProductTemplates/ProductTemplateUsableResourcesServerSideDataSource";
import { useReferenceGrid } from "@/components/Grid/UseReferenceGrid";
import { enumToEditorEntries, enumToEditorEntriesOnlyIncluding, enumValueEntryWithLocaleComparator, translateEditorEntries } from "../Grid/ColumnTypes";
import CreateMaterialEditor, { CreateMaterialEvent } from "@/components/Material/CreateMaterialEditor.vue";
import { useErrorsStore } from "@/store/ErrorsStore";
import ApiService from "@/services/api";
import { asServerSideDurationFormattedString } from "@masta/shared";
import { useScenariosStore } from "@/store/ScenariosStore";

interface Props {
  referenceValue?: ResourceDto[] | ResourceDto | null | ResourceInfoDto;
  cqlFilter?: () => string | null | undefined;
  /**
   * Used to specify a predefined filters list and filter options
   */
  resourceTypes?: ResourceType[];
  useGrouping?: boolean; // 'false' flattens results (ignores results hierarchy), 'true' by default,
  rowSelection?: "single" | "multiple";
}

interface CreateMaterialResult {
  materialId: string;
  templateId: string | undefined;
}

const $emits = defineEmits(["rowSelected", "rowDoubleClicked"]);
const props = withDefaults(defineProps<Props>(), {
  useGrouping: true,
  rowSelection: "multiple",
  resourceTypes: undefined
});

const serverSideDataSource = new ProductTemplateUsableResourcesServerSideDataSource("product-template-usable-resources", props.useGrouping);

const gridWrapperRef = ref<GridWrapperComponent>();
const filterGridActionRef = ref<typeof FilterGridAction>();
const isProcessingMaterialCreationRequest = ref<boolean>(false);
const leftPanelMode = ref<string>("availableItems");
const scenarioStore = useScenariosStore();

const isCreateMaterialActionAvailable = computed(() => {
  const { resourceTypes } = props;
  return typeof resourceTypes === "undefined" || resourceTypes.some((x) => x === ResourceType.Material);
});

const serverSideGroup = useServerSideGroup({
  isGroup: function (resource: ResourceDto): boolean {
    return resource.hasChildren;
  },
  getNameForNode: (node: RowNode) => {
    return node.data.name ?? node.data.businessId ?? node.data.id;
  },
  setParentId: (node: RowNode, parentNode: RowNode) => {
    node.data.parentId = parentNode.data.id;
  },
  grid: gridWrapperRef
} as UseServerSideGroupParams);

function isServerSideGroup(dataItem: any) {
  return dataItem.hasChildren;
}

function getServerSideGroupKey(dataItem: any) {
  return dataItem.id;
}

const autoGroupColumnDef = ref({
  headerValueGetter: (_: any) => $t("productTemplate-list-group-label", { $: "Group" }),
  dndSource: false,
  cellRendererParams: {
    suppressCount: true
  },
  valueGetter: serverSideGroup.groupValueGetter
});

const defaultColumnDef = ref({
  sortable: true,
  resizable: true,
  floatingFilter: true,
  filterParams: {
    applyMiniFilterWhileTyping: true
  }
});

/**
 * Prepares a list of values available in the filter for the 'type' column. Restricted (fitler out) option based on property 'resourceTypes'.
 */
function getTypeColumnFilterValues() {
  const { resourceTypes } = props;
  if (resourceTypes) {
    return translateEditorEntries(enumToEditorEntriesOnlyIncluding(ResourceType, resourceTypes), translateResourceType);
  } else {
    return translateEditorEntries(enumToEditorEntries(ResourceType), translateResourceType);
  }
}

function onPrepareColumns(columnDefs: any) {
  columnDefs.value = [
    {
      field: "id",
      editable: false,
      filter: "agTextColumnFilter",
      hide: true
    },
    {
      field: "businessId",
      sortable: true,
      filter: "agTextColumnFilter",
      headerValueGetter: (_: any) => $t("productTemplate-list-businessId-label", { $: "Business ID" }),
      editable: false
    },
    {
      field: "name",
      sortable: true,
      filter: "agTextColumnFilter",
      headerValueGetter: (_: any) => $t("productTemplate-list-name-label", { $: "Name" }),
      editable: false
    },
    {
      field: "type",
      headerValueGetter: (_: any) => $t("productTemplate-list-resourceType-label", { $: "Resource Type" }),
      editable: false,
      sortable: true,
      filter: "agSetColumnFilter",
      filterParams: {
        valueFormatter: (params: ValueFormatterParams) => params.value.key,
        keyCreator: (params: KeyCreatorParams) => params.value.value,
        values: getTypeColumnFilterValues(),
        comparator: enumValueEntryWithLocaleComparator
      },
      valueFormatter: (params: any) => translateResourceType(params.value)
    },
    {
      field: "tags",
      type: ["tagsTypeColumn"],
      headerValueGetter: (_: any) => $t("productTemplate-list-tags-label", { $: "Tags" }),
      editable: false,
      sortable: true,
      valueFormatter: (params: any) => params.value?.join(",")
    }
  ];
}

function isRowSelected(data: any): boolean {
  const id = data.id;
  if (Array.isArray(props.referenceValue)) {
    return !!props.referenceValue.filter((x) => !!x).find((resource) => resource.id === id);
  }
  return props.referenceValue?.id === id;
}

const referenceGrid = useReferenceGrid({
  $emits,
  isRowSelected: isRowSelected,
  multiselect: Array.isArray(props.referenceValue),
  cqlFilter: props.cqlFilter
});

function onGridReady(event: GridReadyEvent) {
  // serverSideGroup.onGridReady(event);
  referenceGrid.onGridReady(event);
}

const filterGridAction = useFilterGridAction({ filterKey: "type", gridWrapperRef: gridWrapperRef, filterGridActionRef: filterGridActionRef });

const filterGridActionItems = computed(() => {
  const { resourceTypes } = props;

  if (resourceTypes) {
    // map resource types list to FilterGridActionItem[]
    return resourceTypes.map((resourceType) => {
      return { value: resourceType, text: translateResourceType(resourceType) } as FilterGridActionItem;
    });
  } else {
    //empty list - all possible types of resources
    const items: FilterGridActionItem[] = [
      { value: [ResourceType.Person, ResourceType.PersonGroup], text: translateResourceType(ResourceType.Person) },
      { value: [ResourceType.Agreement, ResourceType.AgreementGroup], text: translateResourceType(ResourceType.Agreement) },
      { value: ResourceType.Calendar, text: translateResourceType(ResourceType.Calendar) },
      { value: [ResourceType.Equipment, ResourceType.EquipmentGroup], text: translateResourceType(ResourceType.Equipment) },
      { value: ResourceType.Material, text: translateResourceType(ResourceType.Material) },
      { value: ResourceType.Location, text: translateResourceType(ResourceType.Location) },
      { value: ResourceType.Asset, text: translateResourceType(ResourceType.Asset) },
      { value: ResourceType.Service, text: translateResourceType(ResourceType.Service) }
    ];

    return items;
  }
});

function onShowCreateMaterialEditor() {
  leftPanelMode.value = "createMaterialEditor";
}

function onShowAvailableItems() {
  leftPanelMode.value = "availableItems";
}

async function onCreateMaterial(ev: CreateMaterialEvent) {
  isProcessingMaterialCreationRequest.value = true;
  try {
    const result = await requestMaterialCreation(ev);
    if (result.materialId) {
      onShowAvailableItems();

      // After a completed grid refresh, it searches for the added material resource and moves it to the selected items
      const refreshServerSideCallback = () => {
        gridWrapperRef.value?.gridApi.removeEventListener("storeRefreshed", refreshServerSideCallback);
      };
      gridWrapperRef.value?.gridApi.addEventListener("storeRefreshed", refreshServerSideCallback);

      gridWrapperRef.value?.gridApi.refreshServerSide();
    }
  } catch (e) {
    const errorsStore = useErrorsStore();
    errorsStore.handleError(e);
    throw e;
  } finally {
    isProcessingMaterialCreationRequest.value = false;
  }
}

async function requestMaterialCreation(ev: CreateMaterialEvent): Promise<CreateMaterialResult> {
  const createMaterialResponse = await ApiService.materials.create({
    ...ev.material,
    scenarioId: scenarioStore.selectedScenario?.id
  } as CreateMaterialCommand);
  const { data } = createMaterialResponse;
  const materialId = data.resourceId;

  if (!ev.createTemplate) {
    return { materialId } as CreateMaterialResult;
  }

  const createTemplateResponse = await ApiService.productTemplates.create({
    name: asTemplateName(ev, materialId),
    businessId: asTemplateBusinessId(ev, materialId),
    scenarioId: scenarioStore.selectedScenario?.id,
    resourceId: materialId,
    taskSubType: TaskSubType.ProductionTask,
    steps: [
      {
        position: 1,
        name: asStepName(ev, materialId),
        processingTime: asServerSideDurationFormattedString("1h"),
        stepType: StepType.Production,
        resourceSpecs: [
          {
            quantity: 1,
            quantityUnit: MeasurementUnit.Unknown,
            resourceIds: [materialId],
            usageType: StepResourceUsageType.Produce
          } as CreateStepResourceSpecTemplateDto
        ]
      } as CreateStepTemplateDto
    ]
  } as CreateOrUpdateProductTemplateCommand);
  const { data: templateId } = createTemplateResponse;

  return { materialId, templateId };
}

function asTemplateName(ev: CreateMaterialEvent, defaultValue = "?"): string {
  return ev.material.name ?? ev.material.businessId ?? defaultValue;
}

function asTemplateBusinessId(ev: CreateMaterialEvent, defaultValue = "?"): string {
  return ev.material?.businessId ?? defaultValue;
}

function asStepName(ev: CreateMaterialEvent, defaultValue = "?"): string {
  const materialRepresentation = ev.material.name ?? ev.material.businessId ?? defaultValue;
  return $t("productTemplate-list-templateStepName-label", { $: "Produce: {materialRepresentation}", materialRepresentation });
}
</script>

<template>
  <v-window v-model="leftPanelMode" :show-arrows="false" class="fill-height d-flex flex-column">
    <v-window-item class="d-flex-no-important flex-column flex-grow-1 flex-shrink-1" value="availableItems">
      <grid-wrapper
        ref="gridWrapperRef"
        identifier="product-template-usable-resources"
        :get-server-side-group-key="getServerSideGroupKey"
        :is-server-side-group="isServerSideGroup"
        refresh-btn
        :default-col-def="defaultColumnDef"
        :auto-group-column-def="props.useGrouping ? autoGroupColumnDef : undefined"
        :row-selection="rowSelection"
        :server-side="true"
        :server-side-datasource="serverSideDataSource"
        :tree-data="props.useGrouping"
        hide-custom-actions-separator
        @prepare-columns="onPrepareColumns"
        @filter-changed="filterGridAction.onFilterGridModelChanged"
        @ready="onGridReady"
      >
        <template #custom-buttons>
          <v-tooltip v-if="isCreateMaterialActionAvailable" location="bottom" open-delay="300">
            <template #activator="{ props }">
              <div class="d-inline-flex pr-4">
                <v-btn :disabled="!isRowSelected" size="small" v-bind="props" variant="text" density="compact" @click="onShowCreateMaterialEditor">
                  <v-icon class="pr-4" icon="mdi-debug-step-over" />
                  {{ $t("productTemplate-list-createMaterial-action", { $: "Create Material" }) }}
                </v-btn>
              </div>
            </template>
            <span>{{ $t("productTemplate-list-createMaterial-action-tooltip", { $: "Create Material" }) }}</span>
          </v-tooltip>
        </template>
        <template v-if="filterGridActionItems.length > 1" #filter>
          <filter-grid-action ref="filterGridActionRef" :items="filterGridActionItems" @filter-changed="filterGridAction.onFilterGridActionChanged" />
        </template>
      </grid-wrapper>
    </v-window-item>
    <v-window-item class="fill-height" value="createMaterialEditor">
      <h3 class="my-2">{{ $t("productTemplate-list-createMaterial-label", { $: "Create Material" }) }}</h3>
      <create-material-editor
        :cancel-action-text="$t('productTemplate-list-closeCreateMaterialEditor-label', { $: 'Back' })"
        :is-processing="isProcessingMaterialCreationRequest"
        @cancel="onShowAvailableItems"
        @create="onCreateMaterial"
      />
    </v-window-item>
  </v-window>
</template>

<style lang="scss" scoped></style>
