import { GuiObjectModel } from "./GuiObjectModel";
import { JSONSchema7 } from "json-schema";
import { GuiModelItem } from "./GuiModelItem";
import { TextPropertyModel } from "./TextPropertyModel";
import { SelectPropertyModel } from "./SelectPropertyModel";
import { NumberPropertyModel } from "./NumberPropertyModel";
import { BoolPropertyModel } from "./BoolPropertyModel";
import { MultiSelectPropertyModel } from "./MultiSelectPropertyModel";

function getSchemaObj(schemaString: string) {
  if (!schemaString) {
    throw new Error("Invalid json schema");
  }
  return JSON.parse(schemaString) as JSONSchema7;
}

function getGuiItem(schema: JSONSchema7, name: string): GuiModelItem {
  const propName = name ? name : schema.title ?? "";
  if (Array.isArray(schema)) {
    const selectModel = new SelectPropertyModel(propName, schema);
    return selectModel;
  } else if (schema.enum) {
    const selectModel = new SelectPropertyModel(propName, schema.enum as (string | number)[]);
    selectModel.color = (schema as any).color;
    selectModel.icon = (schema as any).icon;
    return selectModel;
  } else if (schema.type === "object") {
    const objectModel = new GuiObjectModel(propName);
    if (schema.properties) {
      for (const _name in schema.properties) {
        const prop = schema.properties[_name];
        const propObj = getGuiItem(prop as JSONSchema7, _name);
        objectModel.add(propObj);
      }
    }
    return objectModel;
  } else if (schema.type === "string") {
    const textPropertyModel = new TextPropertyModel(propName);
    textPropertyModel.maxLength = schema.maxLength;
    textPropertyModel.minLength = schema.minLength;
    textPropertyModel.pattern = schema.pattern;
    textPropertyModel.color = (schema as any).color;
    textPropertyModel.icon = (schema as any).icon;
    return textPropertyModel;
  } else if (schema.type === "number" || schema.type === "integer") {
    const numberPropertyModel = new NumberPropertyModel(propName, schema.type === "integer");
    numberPropertyModel.max = schema.maximum;
    numberPropertyModel.min = schema.minimum;
    numberPropertyModel.exclusiveMax = schema.exclusiveMaximum;
    numberPropertyModel.exclusiveMin = schema.exclusiveMinimum;
    numberPropertyModel.color = (schema as any).color;
    numberPropertyModel.icon = (schema as any).icon;
    return numberPropertyModel;
  } else if (schema.type === "boolean") {
    const boolPropertyModel = new BoolPropertyModel(propName);
    boolPropertyModel.color = (schema as any).color;
    boolPropertyModel.icon = (schema as any).icon;
    return boolPropertyModel;
  } else if (schema.type === "array") {
    if (schema.items) {
      const multiSelectModel = new MultiSelectPropertyModel(propName, (schema.items as any).enum);
      multiSelectModel.color = (schema as any).color;
      multiSelectModel.icon = (schema as any).icon;
      return multiSelectModel;
    }
  }

  throw new Error(`Schema type '${schema.type}' not supported`);
}

export function generateGuiModel(schemaString: string): GuiModelItem {
  const schema = getSchemaObj(schemaString);

  return generateGuiModelFromObject(schema);
}

export function generateGuiModelFromObject(schema: JSONSchema7) {
  return getGuiItem(schema, "");
}
