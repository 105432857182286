<script lang="ts">
import { ICellEditorParams } from "ag-grid-community";
import { computed, onMounted, ref, toRef } from "vue";
import { VForm } from "vuetify/components";
import { ITextInputCellEditorParams } from "@/components/Grid/CellEditors/ITextInputCellEditorParams";
import { resolveValueRef, useValueChangeControl } from "@/components/Grid/UseValueChangeControl";
import { $t } from "@/i18n";
import { useStorage } from "@vueuse/core";
import { onUnmounted } from "vue";
import { BodyScrollEvent } from "ag-grid-community";

interface IProps extends ITextInputCellEditorParams, ICellEditorParams {}

export default {
  setup(props: { params: IProps }) {
    const params = toRef(props, "params");
    const valueInitializer = params.value.valueInitializer;
    const initialValue = valueInitializer ? valueInitializer(props.params.value) : props.params.value;
    const value = resolveValueRef(props.params, initialValue);
    const valid = ref<boolean>();
    const hasFocus = ref<boolean>(false);
    const form = ref<VForm>();
    const input = ref<any>();
    const showMenu = ref<boolean>(false);
    const errors = computed(() => {
      return form.value?.errors.map((e) => e.errorMessages.join(",")).join(",");
    });
    const showTooltip = computed<boolean>(() => !!errors.value && hasFocus.value);

    const isEditEnabled = toRef(props.params.isEditEnabled ? props.params.isEditEnabled(props.params) : true);

    const usedColors = useStorage("used-colors", []);

    const rules = computed(() => {
      return params.value.rules ?? [];
    });

    const colorPickerValue = computed({
      get() {
        return value.value;
      },
      set(v) {
        value.value = v;
      }
    });

    onMounted(() => {
      props.params.api.addEventListener("bodyScroll", onGridBodyScroll);
    });

    onUnmounted(() => {
      const { api } = props.params;
      if (api && !api.isDestroyed()) {
        api.removeEventListener("bodyScroll", onGridBodyScroll);
      }
    });

    function onGridBodyScroll(event: BodyScrollEvent) {
      // close menu on scroll (when is open) to avoid menu position issues
      if (showMenu.value) {
        showMenu.value = false;
      }
    }

    function getValue() {
      // must use useStorage once again because this methos is called after the component is destroyed
      const colors = useStorage("used-colors", []);
      if (value.value) {
        if (!colors.value.includes(value.value as never)) {
          colors.value.unshift(value.value as never);
          colors.value = colors.value.slice(0, 6);
        }
      }
      return value.value;
    }

    function focusIn() {
      if (input.value && input.value.$el) {
        input.value.$el.focus();
        const inputs = input.value.$el.getElementsByTagName("input");
        if (inputs.length >= 1) {
          inputs[0].focus();
        }
      }
      hasFocus.value = true;
    }

    function focusOut() {
      if (form.value && form.value.validate) {
        form.value.validate();
      }
      hasFocus.value = false;
    }

    function afterGuiAttached() {
      if (props.params.cellStartedEdit) {
        focusIn();
      }
      if (form.value && form.value.validate) {
        form.value.validate();
      }
    }

    function isCancelAfterEnd() {
      return !valid.value;
    }

    function onBlur() {
      if (form.value && form.value.validate) {
        form.value.validate();
      }
      hasFocus.value = false;
    }

    function onFocus() {
      hasFocus.value = true;
    }

    function isValid() {
      return isEditEnabled.value ? valid?.value : undefined;
    }

    function pickUsedColor(color: string) {
      value.value = color;
      showMenu.value = false;
    }

    useValueChangeControl(value, props.params);

    return {
      getValue,
      focusIn,
      focusOut,
      isCancelAfterEnd,
      afterGuiAttached,
      isValid,
      showTooltip,
      rules,
      valid,
      errors,
      value,
      colorPickerValue,
      input,
      showMenu,
      hasFocus,
      params,
      onBlur,
      onFocus,
      form: form as any,
      isEditEnabled,
      usedColors,
      pickUsedColor
    };
  },
  methods: { $t }
};
</script>

<template>
  <v-tooltip v-model="showTooltip" location="bottom" :offset="30" class="ag-color-field-cell-editor-tooltip">
    <template #activator="act">
      <v-form ref="form" v-model="valid" class="color-input-cell-editor">
        <div class="color-input-cell-editor-container">
          <v-menu v-bind="act.props" v-model="showMenu" :close-on-content-click="false" location-strategy="connected">
            <template #activator="{ props }">
              <v-text-field
                v-bind="props"
                ref="input"
                v-model="value"
                color="primary"
                :rules="rules"
                :readonly="params.readonly"
                variant="outlined"
                density="compact"
                hide-details
                :disabled="!isEditEnabled"
                :placeholder="params.placeholder"
                @focus="onFocus"
                @blur="onBlur"
              >
                <template #prepend-inner>
                  <v-sheet :style="{ backgroundColor: value }" width="1em" height="1em" rounded></v-sheet>
                </template>
              </v-text-field>
            </template>
            <v-card v-if="!params.readonly && isEditEnabled">
              <v-card-title class="d-flex justify-space-between align-center px-4 py-2">
                <v-sheet :style="{ backgroundColor: value }" width="1em" height="1em" rounded></v-sheet>
                <input v-model="colorPickerValue" class="rounded text-center" style="border: 1px solid rgb(221, 226, 235)" />
                <v-btn class="float-right" density="compact" variant="flat" size="x-large" icon="mdi-close" @click="showMenu = false" />
              </v-card-title>
              <v-card-text class="pa-4">
                <v-row>
                  <v-col v-for="color in usedColors" :key="color" cols="4" class="d-flex justify-space-between align-center cursor-pointer">
                    <v-sheet :style="{ backgroundColor: color }" width="100%" height="2em" rounded @click="pickUsedColor(color)"></v-sheet>
                  </v-col>
                </v-row>
                <v-color-picker v-model="colorPickerValue" width="400" elevation="0" hide-inputs :class="{ 'mt-2': usedColors.length > 0 }" />
              </v-card-text>
            </v-card>
          </v-menu>
        </div>
      </v-form>
    </template>
    <span v-if="errors">{{ errors }}</span>
  </v-tooltip>
</template>

<style lang="scss">
.color-input-cell-editor {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .color-input-cell-editor-container {
    padding: 0.31em;
    flex: 0 1 auto;
    width: 100%;

    .v-field--disabled {
      opacity: 0.75;
    }

    .v-input {
      font-size: 1em;

      .v-field {
        border-radius: 8px;
        font-size: 1em;

        .v-field__overlay {
          background-color: white;
        }

        .v-field__prepend-inner {
          position: relative;
        }

        .v-field__input {
          font-size: 1em;
          height: 34px;
        }

        .v-field__outline {
          --v-field-border-width: 2px;
        }

        color: rgba(0, 0, 0, 0.5);

        &:hover {
          color: var(--ag-data-color);
        }

        &.v-field--focused {
          color: var(--ag-data-color);
        }
      }
    }
  }
}

.ag-color-field-cell-editor-tooltip {
  .v-overlay__content {
    background: rgba(var(--v-theme-error), 0.7);
  }
}
</style>
