<script setup lang="ts">
import { inject, ref, watch } from "vue";
import { $dateFormatterSymbol, DateFormatter } from "@masta/shared";
import { useI18n } from "vue-i18n";
import { CreatePurchaseOrderFromPredictionsCommand, PurchaseOrderPredictionDto } from "@masta/generated-model";
import { useScenariosStore } from "@/store/ScenariosStore";
import PurchaseOrdersPredictionGrid from "@/components/MaterialsDemand/PurchaseOrdersPredictionGrid.vue";
import ApiService from "@/services/api";
import { CreateSnackbarCommand, useSnackbarsStore } from "@/store/SnackbarsStore";
import { $t } from "@/i18n";

const $dateFormatter = inject<DateFormatter>($dateFormatterSymbol)!;

const { locale } = useI18n();
const scenariosStore = useScenariosStore();
const snackbarStore = useSnackbarsStore();

const purchaseOrdersGrid = ref<InstanceType<typeof PurchaseOrdersPredictionGrid> | null>(null);

const props = defineProps<{
  modelValue: boolean;
  purchaseOrderPredictionDtos: PurchaseOrderPredictionDto[]
}>();
const emit = defineEmits<{
  (e: "update:modelValue", value: boolean): void;
  (e: "purchaseOrdersCreated"): void;
}>();

watch(
  () => props.modelValue,
  (newVal) => {
    if (!newVal) {
      resetDialog();
    }
  }
);

function resetDialog() {
}

function cancel() {
  emit("update:modelValue", false);
}

async function createPurchaseOrders() {
  try {
    await ApiService.purchaseOrders.createFromPurchaseOrderPredictions({
      scenarioId: scenariosStore.selectedScenario?.id,
      predictions: purchaseOrdersGrid.value?.gridWrapperRef?.gridApi.getSelectedRows()
    } as CreatePurchaseOrderFromPredictionsCommand);
    emit("purchaseOrdersCreated");
    emit("update:modelValue", false);
    snackbarStore.createSnackbar({
      message: $t("purchaseOrdersPredictions-dialog-createdPurchaseOrders-popup", { $: "Purchase orders based on predictions created" }),
      type: "info"
    } as CreateSnackbarCommand);
  } catch (e: any) {
    snackbarStore.createSnackbar({
      message: e && e.detail ? e.detail : $t("purchaseOrdersPredictions-dialog-createdPurchaseOrders-error", { $: "Could not create purchase orders" }),
      type: "error",
      closeable: true
    });
  }
}

</script>

<template>
  <v-dialog :model-value="modelValue" max-width="90%" @update:model-value="(value) => emit('update:modelValue', value)">
    <v-card class="pa-8">
      <v-card-title class="d-flex align-center justify-space-between">
        <div class="text-h4">{{ $t("purchaseOrdersPredictions-dialog-title", { $: "Predicted Purchase Orders" }) }}</div>
        <v-btn icon="mdi-close" variant="flat" @click="cancel"></v-btn>
      </v-card-title>
      <v-card-text>

        <v-row>
          <v-col>
            <div style="height: 40vh; overflow-y: auto;">
              <purchase-orders-prediction-grid ref="purchaseOrdersGrid" :purchase-order-prediction-dtos="props.purchaseOrderPredictionDtos" />
            </div>
          </v-col>
        </v-row>

      </v-card-text>
      <v-card-actions>
        <v-row>
          <v-col cols="6">
            <v-btn class="ma-4" variant="elevated" color="secondary" block @click="cancel">
              {{ $t("purchaseOrdersPredictions-dialog-cancel-label", { $: "Cancel" }) }}
            </v-btn>
          </v-col>
          <v-col cols="6">
            <v-btn :disabled="props.purchaseOrderPredictionDtos.length == 0" class="ma-4" color="primary" variant="elevated" block @click="createPurchaseOrders">
              {{ $t("purchaseOrdersPredictions-dialog-createPurchaseOrders-label", { $: "Create Purchase Orders" }) }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style scoped>
</style>
