<script lang="ts" setup>
import {
  getAlertType,
  moveDownChild,
  moveDownStep,
  moveUpChild,
  moveUpStep,
  ProductTemplateDraft,
  ProductTemplateDraftType,
  recalculateStepPositions,
  recalculateWbs,
  StepDraft,
  StepDraftResourceSpec,
  unlockTemplate
} from "@/components/ProcessDrafts/ProcessDraftModels";
import { computed, ref } from "vue";
import StepDraftEditor from "@/components/ProcessDrafts/StepDraftEditor.vue";
import { getDirectiveSpecs, specHaveDifferentParams } from "@/components/ProcessDrafts/validation";
import { ResourceSubType, ResourceType, StepType } from "@masta/generated-model";
import ResourcePicker from "@/components/Resources/ResourcePicker.vue";
import TreeViewNode from "@/components/ProcessDrafts/TreeView/TreeViewNode.vue";
import { $t } from "@/i18n";
import ApiService from "@/services/api";
import { requiredRule } from "@/components/ValueCellEditor/CommonValidationRules";
import { filterValidationError, ProcessValidationError } from "@/components/ProcessDrafts/ProcessValidationError";
import { useScenariosStore } from "@/store/ScenariosStore";

const modelValue = defineModel<ProductTemplateDraft>({ required: true });
const props = defineProps<{
  validationErrors: ProcessValidationError[];
  readonly: boolean;
  targetProduct?: boolean;
}>();
const emit = defineEmits<{
  (e: "remove", productTemplateDraft: ProductTemplateDraft): void;
  (e: "moveup", productTemplateDraft: ProductTemplateDraft): void;
  (e: "movedown", productTemplateDraft: ProductTemplateDraft): void;
  (e: "copy", productTemplateDraft: ProductTemplateDraft): void;
}>();
defineExpose({
  openPanel,
  closePanel
});
const isExpanded = ref<boolean>(true);
const isRequireScrollOnTransition = ref<boolean>(false);
const panel = ref<number[]>([0]);
const names = ref<string[]>([]);
const scenarioStore = useScenariosStore();

const filteredValidationErrors = computed(() => {
  return props.validationErrors
    .filter((x) => filterValidationError(x, { productTemplate: modelValue.value }))
    .map((x) => {
      x.closed = true;
      return x;
    });
});

const computedProductTemplateDraftType = computed(() => {
  if (canHaveChildTemplates.value && canHaveSteps.value) {
    return null;
  }
  if (canHaveChildTemplates.value) {
    return taskTypes.find((x) => x.value === ProductTemplateDraftType.WorkOrder);
  }
  return taskTypes.find((x) => x.value === ProductTemplateDraftType.ProductionTask);
});

const taskTypes = [
  { title: $t("processDraft-taskUsageType-WorkOrder-label", { $: "Work Order" }), value: ProductTemplateDraftType.WorkOrder },
  { title: $t("processDraft-taskUsageType-ProductionTask-label", { $: "Production Task" }), value: ProductTemplateDraftType.ProductionTask }
];

const canHaveChildTemplates = computed(() => modelValue.value.steps.length === 0);
const canHaveSteps = computed(() => modelValue.value.childProductTemplates.length === 0);
const statusIcon = computed(() => (modelValue.value.useExistingTemplate ? "mdi-lock" : "mdi-lock-open-variant"));

function addChildTemplate() {
  modelValue.value.childProductTemplates.push({
    position: 0,
    name: "",
    businessId: "",
    wbs: "",
    taskType: ProductTemplateDraftType.ProductionTask,
    steps: [],
    childProductTemplates: [],
    isTopLevel: false,
    resource: null,
    tags: []
  });
  recalculateWbs(modelValue.value);
  recalculateProductTemplateDraftType();
  openPanel();
}

function addStep() {
  isRequireScrollOnTransition.value = true;
  modelValue.value.steps.push({ name: "", specs: getNewSpecs(), position: 0, processingTime: "", quantityPerTime: 1, type: StepType.Undefined });
  recalculateStepPositions(modelValue.value);
  recalculateProductTemplateDraftType();
  openPanel();
}

function getNewSpecs(): StepDraftResourceSpec[] {
  const directiveSpecs = modelValue.value.steps.flatMap((x) => getDirectiveSpecs(x, ResourceType.Equipment));
  if (directiveSpecs.length <= 0) {
    return [];
  }
  const spec = directiveSpecs[0];
  if (directiveSpecs.length > 1 && directiveSpecs.some((x) => specHaveDifferentParams(spec, x))) {
    return [];
  }
  return [{ ...spec }];
}

function onStepListTransitionAfterEnter(element: HTMLElement) {
  if (isRequireScrollOnTransition.value) {
    element.scrollIntoView({ behavior: "smooth", block: "center" });
    element.classList.add("highlight-step");

    isRequireScrollOnTransition.value = false;
  }
}

function remove() {
  emit("remove", modelValue.value);
}

function removeTemplate(productTemplateDraft: ProductTemplateDraft) {
  modelValue.value.childProductTemplates = modelValue.value.childProductTemplates.filter((x) => x !== productTemplateDraft);
}

function removeStep(step: StepDraft) {
  modelValue.value.steps = modelValue.value.steps.filter((x) => x !== step);
}

function recalculateProductTemplateDraftType() {
  if (modelValue.value.steps.length > 0) {
    modelValue.value.taskType = ProductTemplateDraftType.ProductionTask;
  } else if (modelValue.value.childProductTemplates.length > 0) {
    modelValue.value.taskType = ProductTemplateDraftType.WorkOrder;
  }
}

function openPanel() {
  panel.value = [0];
  isExpanded.value = true;
}

function closePanel() {
  panel.value = [];
  isExpanded.value = false;
}

function onMoveUpStep(step: StepDraft) {
  moveUpStep(modelValue.value, step);
}

function onMoveDownStep(step: StepDraft) {
  moveDownStep(modelValue.value, step);
}

function moveUp() {
  emit("moveup", modelValue.value);
}

function moveDown() {
  emit("movedown", modelValue.value);
}

function onMoveUpChildTemplate(productTemplateDraft: ProductTemplateDraft) {
  moveUpChild(modelValue.value, productTemplateDraft);
}

function onMoveDownChildTemplate(productTemplateDraft: ProductTemplateDraft) {
  moveDownChild(modelValue.value, productTemplateDraft);
}

async function queryName(query: string) {
  if (!query || !scenarioStore.selectedScenario) {
    names.value = [];
    return;
  }
  const response = await ApiService.productTemplates.findName(scenarioStore.selectedScenario.id, query);
  names.value = response.data?.names ?? [];
}

async function handleInput(inputValue: any) {
  await queryName(inputValue.data);
}

function unlock() {
  if (!props.readonly) {
    unlockTemplate(modelValue.value);
  }
}

function populateOtherSteps(arg: { step: StepDraft; spec: StepDraftResourceSpec }) {
  modelValue.value.steps.forEach((step) => {
    if (step !== arg.step) {
      step.specs.push({ ...arg.spec });
    }
  });
}

function copy() {
  emit("copy", modelValue.value);
}
</script>

<template>
  <tree-view-node
    v-model="isExpanded"
    :expandable="modelValue.childProductTemplates.length > 0 || modelValue.steps.length > 0"
    :readonly="props.readonly"
    :deletable="!props.targetProduct"
    class="mb-4 task-editor"
    @delete="remove"
    @move-up="moveUp"
    @move-down="moveDown"
  >
    <template #header>
      <v-container fluid>
        <v-row>
          <v-col cols="12">
            <v-row no-gutters class="d-flex flex-wrap gap-10">
              <div class="d-flex align-center title">
                <v-icon v-if="modelValue.isTopLevel && !readonly" :icon="statusIcon" class="ml-4 cursor-pointer" @click="unlock" />
                <div class="ml-8">{{ $t("processDraft-view-task-label", { $: "Task" }) }}:</div>
              </div>
              <div class="flex-fill min-width-50 max-width-100">
                <v-text-field v-model="modelValue.wbs" readonly variant="outlined" density="compact" :label="$t('processDraft-view-wbs-label', { $: 'WBS' })" hide-details />
              </div>
              <div v-if="modelValue.isTopLevel" class="flex-fill min-width-100 max-width-500">
                <ResourcePicker
                  v-model="modelValue.resource"
                  class="flex-1-1-100"
                  :label="$t('processDraft-view-taskProduct-label', { $: 'Product' })"
                  :readonly="props.readonly || !!modelValue.useExistingTemplate"
                  :resource-types="[ResourceType.Material]"
                  :resource-sub-types="[ResourceSubType.MaterialSemiFinishedOrFinalProduct]"
                  :rules="[requiredRule]"
                  :hide-details="'auto'"
                />
              </div>

              <div class="flex-fill d-flex justify-end">
                <v-btn v-if="canHaveSteps" class="mx-2" :disabled="props.readonly" color="primary" prepend-icon="mdi-plus" @click.stop="addStep">
                  {{ $t("processDraft-view-taskAddStep-action", { $: "Add Step" }) }}
                </v-btn>
                <v-tooltip :text="$t('processDraft-view-taskCopy-action', { $: 'Duplicate task' })" location="top">
                  <template #activator="{ props }">
                    <v-btn v-bind="props" density="compact" variant="text" color="primary" icon="mdi-content-copy" :disabled="props.readonly" @click="copy"></v-btn>
                  </template>
                </v-tooltip>
              </div>
              <!-- <v-col cols="4">
                <v-combobox
                  v-model="modelValue.name"
                  :readonly="props.readonly || !!modelValue.useExistingTemplate"
                  variant="outlined"
                  density="compact"
                  :label="$t('processDraft-view-taskName-label', { $: 'Name' })"
                  required
                  :rules="nameRules"
                  :items="names"
                  hide-details="auto"
                  @input="handleInput"
                />
              </v-col> -->
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col v-if="modelValue.message" cols="12">
            <v-alert
              closable
              density="compact"
              :type="getAlertType(modelValue.message.type)"
              :text="modelValue.message.text"
              class="mb-1"
              @click:close="modelValue.message = undefined"
            >
            </v-alert>
          </v-col>
        </v-row>
        <v-row>
          <v-col v-if="filteredValidationErrors?.length > 0" cols="12">
            <v-alert
              v-for="(error, idx) in filteredValidationErrors"
              :key="idx"
              v-model="error.isErrorShown"
              density="compact"
              type="error"
              closable
              :text="error?.message"
              class="mb-1"
            />
          </v-col>
        </v-row>
      </v-container>
    </template>
    <v-slide-y-transition group>
      <template v-for="(subtask, index) in canHaveChildTemplates ? modelValue.childProductTemplates : []" :key="index">
        <product-template-draft-editor
          v-model="modelValue.childProductTemplates[index]"
          :readonly="props.readonly || !!modelValue.useExistingTemplate"
          :validation-errors="validationErrors"
          @remove="removeTemplate"
          @moveup="onMoveUpChildTemplate"
          @movedown="onMoveDownChildTemplate"
        />
      </template>
    </v-slide-y-transition>
    <v-slide-y-transition group @after-enter="onStepListTransitionAfterEnter">
      <template v-for="(step, index) in canHaveSteps ? modelValue.steps : []" :key="index">
        <step-draft-editor
          v-model="modelValue.steps[index]"
          :product-template="modelValue"
          :readonly="props.readonly || !!modelValue.useExistingTemplate"
          :validation-errors="validationErrors"
          @remove="removeStep"
          @populate-other-steps="populateOtherSteps"
          @moveup="onMoveUpStep"
          @movedown="onMoveDownStep"
        ></step-draft-editor>
      </template>
    </v-slide-y-transition>
  </tree-view-node>
</template>

<style lang="scss">
.task-editor {
  --v-high-emphasis-opacity: 1;
  color: rgba(var(--v-theme-on-surface), var(--v-high-emphasis-opacity));

  .title {
    font-size: 14px;
    font-weight: 500;
  }

  // moved here from `TemplateStepEditor` to be ranked higher and override scoped CSS from `TreeViewNode`
  .step-editor .node-header {
    background-color: #f5f5ff;
  }

  @keyframes highlight-fade {
    0% {
      background-color: #d7cffc;
    }
    100% {
      background-color: #f5f5ff;
    }
  }

  .highlight-step,
  .highlight-step > :first-child {
    animation: highlight-fade 1s ease-in 750ms;
  }
}
</style>
