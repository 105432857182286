<script lang="ts" setup>
import { InformativeDocumentAttachmentFeatureDefinition } from "@masta/generated-model";
import { $t } from "@/i18n";
import { requiredRule } from "@/components/ValueCellEditor/CommonValidationRules";

const props = defineProps<{
  modelValue: InformativeDocumentAttachmentFeatureDefinition;
  disabled: boolean;
}>();

const emit = defineEmits(["update:modelValue", "remove"]);

function update(propertyName: string, value: any) {
  const newFeature = { ...props.modelValue, [propertyName]: value };
  emit("update:modelValue", newFeature);
}
</script>

<template>
  <v-card outlined width="100%">
    <v-card-text>
      <v-col cols="12">
        <!-- First Row: Chip and Close Button -->
        <v-row>
          <v-col cols="4" class="text-center d-flex align-center justify-center">
            <v-chip size="x-large" label class="w-100" color="deep-orange" variant="tonal" :disabled="disabled">
              {{ $t("featureDefinition-documentAttachment-documentAttachment-label", { $: "Document Attachment" }) }}
            </v-chip>
          </v-col>
          <v-col cols="8" class="text-right align-center justify-center">
            <v-btn
              variant="text"
              icon="mdi-close"
              size="large"
              hide-details
              :disabled="disabled"
              @click="emit('remove')"
            ></v-btn>
          </v-col>
        </v-row>
        <!-- Second Row: Name, Label, and Description -->
        <v-row>
          <v-col cols="3">
            <v-text-field
              variant="outlined"
              density="compact"
              hide-details
              :rules="[requiredRule]"
              :label="$t('featureDefinition-documentAttachment-name-label', { $: 'Name' })"
              :model-value="modelValue.name"
              :disabled="disabled"
              @update:model-value="update('name', $event)"
            />
          </v-col>
          <v-col cols="3">
            <v-text-field
              variant="outlined"
              density="compact"
              hide-details
              :label="$t('featureDefinition-documentAttachment-label-label', { $: 'Label' })"
              :model-value="modelValue.label"
              :disabled="disabled"
              @update:model-value="update('label', $event)"
            />
          </v-col>
          <v-col cols="6">
            <v-text-field
              variant="outlined"
              density="compact"
              hide-details
              :label="$t('featureDefinition-documentAttachment-description-label', { $: 'Description' })"
              :model-value="modelValue.description"
              :disabled="disabled"
              @update:model-value="update('description', $event)"
            />
          </v-col>
        </v-row>
        <!-- Third Row: Required Switch -->
        <v-row>
          <v-col cols="2">
            <v-switch
              variant="outlined"
              density="compact"
              hide-details
              color="primary"
              :label="$t('featureDefinition-documentAttachment-required-label', { $: 'Required' })"
              :model-value="modelValue.isRequired"
              :disabled="disabled"
              @update:model-value="update('isRequired', $event)"
            />
          </v-col>
          <v-col cols="6"></v-col>
        </v-row>
      </v-col>
    </v-card-text>
  </v-card>
</template>

