import { IActivityObject, ILayer, IRow, IRowChartData } from "./Model";
import { Lifecycle } from "./Lifecycle";
import { BehaviorSubject, skip, Subject } from "rxjs";

export interface IActivityDataLoaderOperation {
  readonly rowId: string;
  readonly layerId: string;
  readonly startDate: number;
  readonly endDate: number;
  readonly activities: IActivityObject[];
}

export interface IChartDataLoaderOperation {
  readonly startDate: number;
  readonly endDate: number;
  readonly chartData: IRowChartData[];
}

export interface IGanttDataLoader {
  initialLoad(startDate: number, endDate: number, options?: unknown): Promise<void>;

  loadData(startDate: number, endDate: number, rowIds?: string[]): Promise<void>;

  onLayersModified(callback: (layers: ILayer[]) => void): void;

  onRowsAdded(callback: (rows: IRow[]) => void): void;

  onRowsUpdated(callback: (rows: IRow[]) => void): void;

  onRowsDeleted(callback: (rowIds: string[]) => void): void;

  onActivitiesOperation(callback: (operation: IActivityDataLoaderOperation) => void): void;

  onChartDataOperation(callback: (operation: IChartDataLoaderOperation) => void): void;
}

export abstract class GanttDataLoaderBase extends Lifecycle implements IGanttDataLoader {
  protected _layers$$ = new BehaviorSubject<ILayer[]>([]);
  protected _rowsAdded$$ = new Subject<IRow[]>();
  protected _rowsUpdated$$ = new Subject<IRow[]>();
  protected _rowsDeleted$$ = new Subject<string[]>();
  protected _activityOperation$$ = new Subject<IActivityDataLoaderOperation>();
  protected _chartDataOperation$$ = new Subject<IChartDataLoaderOperation>();

  constructor() {
    super();
  }

  abstract initialLoad(startDate: number, endDate: number, options?: unknown): Promise<void>;

  abstract loadData(startDate: number, endDate: number, rowIds?: string[]): Promise<void>;

  onLayersModified(callback: (layers: ILayer[]) => void) {
    this.subscribe(this._layers$$.pipe(skip(1)).subscribe(callback));
  }

  onRowsAdded(callback: (rows: IRow[]) => void) {
    this.subscribe(this._rowsAdded$$.subscribe(callback));
  }

  onRowsUpdated(callback: (rows: IRow[]) => void) {
    this.subscribe(this._rowsUpdated$$.subscribe(callback));
  }

  onRowsDeleted(callback: (rowIds: string[]) => void) {
    this.subscribe(this._rowsDeleted$$.subscribe(callback));
  }

  onActivitiesOperation(callback: (operation: IActivityDataLoaderOperation) => void): void {
    this.subscribe(this._activityOperation$$.subscribe(callback));
  }

  onChartDataOperation(callback: (operation: IChartDataLoaderOperation) => void): void {
    this.subscribe(this._chartDataOperation$$.subscribe(callback));
  }
}
