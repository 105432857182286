<script lang="ts" setup>
import GridWrapper from "@/components/Grid/GridWrapper.vue";
import { inject, reactive, ref } from "vue";
import { GridWrapperComponent } from "@/components/Grid/GridWrapperComponent";
import ApiService from "@/services/api";
import { GridApi } from "ag-grid-community";
import { useContactsStore } from "@/store/ContactsStore";
import { storeToRefs } from "pinia";
import { useSnackbarsStore } from "@/store/SnackbarsStore";
import { $t } from "@/i18n";
import OrganizationGrid from "@/components/Organizations/OrganizationGrid.vue";
import { CommunicationType, CreateRandomCommunicationItemsCommand, CurrencyCodes, OrganizationDto } from "@masta/generated-model";
import { ContactsServerSideDataSource } from "@/components/Contacts/ContactsServerSideDataSource";
import { useCommunicationsStore } from "@/store/CommunicationsStore";
import { CommunicationsServerSideDataSource } from "@/components/Communications/CommunicationsServerSideDataSource";
import { $dateTimeFormatterSymbol, DateFormatter } from "@masta/shared";
import SystemEnumService from "@/services/system-enum.service";
import { translateCommunicationItemCommunicationType, translateCommunicationItemStatus, translateCurrencyCode, translateMeasurementUnit } from "@/composables/translateEnum";
import { enumToEditorEntries, translateEditorEntries } from "@/components/Grid/ColumnTypes";
import { IEnumValueSelectCellEditorParams } from "@/components/Grid/CellEditors/IEnumValueSelectCellEditorParams";
import { getNowInISOFormat } from "@/components/Datepicker/DatepickerUtil";
import { getSelectedRows } from "@/components/Grid/UseGridSelection";
import { useScenariosStore } from "@/store/ScenariosStore";

const communicationsStore = useCommunicationsStore();
const { communications } = storeToRefs(communicationsStore);
const scenarioStore = useScenariosStore();
const { selectedScenario } = storeToRefs(scenarioStore);

const gridWrapperRef = ref<GridWrapperComponent>();

const isRowSelected = ref(false);

const serverSideDataSource = reactive(new CommunicationsServerSideDataSource("communications"));

const $dateTimeFormatter = inject<DateFormatter>($dateTimeFormatterSymbol)!;

async function onFetchData() {
  await communicationsStore.fetch();
}

async function onRefreshAction() {
  await onFetchData();
}

function onPrepareColumns(columnDefs: any) {
  columnDefs.value = [
    {
      field: "communicationType",
      type: "enumTypeColumn",
      editable: true,
      resizable: true,
      sortable: true,
      cellEditorParams: {
        placeholder: $t("communicationItem-list-communicationType-label", { $: "Communication Type" }),
        values: translateEditorEntries(enumToEditorEntries(CommunicationType), translateCommunicationItemCommunicationType)
      } as unknown as IEnumValueSelectCellEditorParams,
      headerValueGetter: (_: any) => $t("communicationItem-list-communicationType-label", { $: "Communication Type" }),
      valueFormatter: (params: any) => translateCommunicationItemCommunicationType(params.data.communicationType)
    },
    {
      field: "to",
      headerName: "To",
      editable: true,
      sortable: true,
      filter: "agTextColumnFilter",
      type: "textInputTypeColumn",
      cellEditorParams: {
        rules: [(v: any) => (v !== undefined && v !== null && v !== "") || $t("communicationItem-edit-valueRequired-popup", { $: "Required" })],
        placeholder: "To"
      },
      headerValueGetter: (_: any) => $t("communicationItem-list-to-label", { $: "To" })
    },
    {
      field: "subject",
      headerName: "Subject",
      editable: true,
      sortable: true,
      filter: "agTextColumnFilter",
      type: "textInputTypeColumn",
      cellEditorParams: {
        placeholder: $t("communicationItem-edit-subject-label", { $: "Subject" })
      },
      headerValueGetter: (_: any) => $t("communicationItem-list-subject-label", { $: "Subject" })
    },
    {
      field: "content",
      headerName: "Content",
      editable: true,
      sortable: true,
      filter: "agTextColumnFilter",
      type: "textInputTypeColumn",
      cellEditorParams: {
        render: (value: any) => value,
        placeholder: $t("communicationItem-edit-content-label", { $: "Content" })
      },
      headerValueGetter: (_: any) => $t("communicationItem-list-content-label", { $: "Content" })
    },
    {
      field: "cc",
      headerName: "Cc",
      editable: true,
      sortable: true,
      filter: "agTextColumnFilter",
      type: "textInputTypeColumn",
      cellEditorParams: {
        placeholder: "Cc"
      },
      headerValueGetter: (_: any) => $t("communicationItem-list-cc-label", { $: "Cc" })
    },
    {
      field: "businessId",
      headerCheckboxSelection: true,
      checkboxSelection: true,
      showDisabledCheckboxes: true,
      headerName: "Business ID",
      editable: true,
      sortable: true,
      filter: "agTextColumnFilter",
      type: "textInputTypeColumn",
      cellEditorParams: {
        isEditEnabled: () => !gridWrapperRef.value?.isUpdating(),
        placeholder: $t("communicationItem-edit-businessId-label", { $: "Business ID" })
      },
      headerValueGetter: (_: any) => $t("communicationItem-list-businessId-label", { $: "Business ID" })
    },
    {
      field: "plannedSendTime",
      headerValueGetter: (_: any) => $t("communicationItem-list-plannedSendTime-label", { $: "Planned Send Time" }),
      editable: false,
      sortable: true,
      filter: true,
      valueFormatter: (params: any) => {
        return $dateTimeFormatter(params.data.plannedSendTime);
      }
    },
    {
      field: "sentTime",
      headerValueGetter: (_: any) => $t("communicationItem-list-sentTime-label", { $: "Sent Time" }),
      editable: false,
      sortable: true,
      filter: true,
      valueFormatter: (params: any) => {
        return $dateTimeFormatter(params.data.sentTime);
      }
    },
    {
      field: "deliveredTime",
      headerValueGetter: (_: any) => $t("communicationItem-list-deliveredTime-label", { $: "Delivered Time" }),
      editable: false,
      sortable: true,
      filter: true,
      valueFormatter: (params: any) => {
        return $dateTimeFormatter(params.data.deliveredTime);
      }
    },
    {
      field: "readTime",
      headerValueGetter: (_: any) => $t("communicationItem-list-readTime-label", { $: "Read Time" }),
      editable: false,
      sortable: true,
      filter: true,
      valueFormatter: (params: any) => {
        return $dateTimeFormatter(params.data.readTime);
      }
    },
    {
      field: "responseTime",
      headerValueGetter: (_: any) => $t("communicationItem-list-responseTime-label", { $: "Response Time" }),
      editable: false,
      sortable: true,
      filter: true,
      valueFormatter: (params: any) => {
        return $dateTimeFormatter(params.data.responseTime);
      }
    },
    {
      field: "acknowledgedTime",
      headerValueGetter: (_: any) => $t("communicationItem-list-acknowledged-label", { $: "Acknowledged Time" }),
      editable: false,
      sortable: true,
      filter: true,
      valueFormatter: (params: any) => {
        return $dateTimeFormatter(params.data.acknowledgedTime);
      }
    },
    {
      field: "responseContent",
      headerName: "Response Content",
      editable: true,
      sortable: true,
      filter: "agTextColumnFilter",
      type: "textInputTypeColumn",
      cellEditorParams: {
        render: (value: any) => value,
        placeholder: $t("communicationItem-edit-responseContent-label", { $: "Response Content" })
      },
      headerValueGetter: (_: any) => $t("communicationItem-list-responseContent-label", { $: "Response Content" })
    },
    {
      field: "status",
      type: "enumTypeColumn",
      editable: false,
      resizable: true,
      sortable: true,
      headerValueGetter: (_: any) => $t("communicationItem-list-status-label", { $: "Status" }),
      valueFormatter: (params: any) => translateCommunicationItemStatus(params.data.status)
    },
    {
      field: "createdBy",
      type: ["textInputTypeColumn", "textFloatingFilterColumnType"],
      filter: "agTextColumnFilter",
      editable: false,
      resizable: true,
      sortable: true,
      headerValueGetter: (_: any) => $t("location-list-createdBy-label", { $: "Created By" }),
      floatingFilterComponentParams: {
        placeholder: $t("location-list-createdBy-label", { $: "Created By" })
      }
    },
    {
      field: "createdAt",
      type: ["dateTimeTypeColumn"],
      filter: "agDateColumnFilter",
      editable: false,
      resizable: true,
      sortable: true,
      valueFormatter: (params: any) => {
        return $dateTimeFormatter(params.data.createdAt);
      },
      headerValueGetter: (_: any) => $t("location-list-createdAt-label", { $: "Created At" })
    },
    {
      field: "modifiedBy",
      type: ["textInputTypeColumn", "textFloatingFilterColumnType"],
      filter: "agTextColumnFilter",
      editable: false,
      resizable: true,
      sortable: true,
      headerValueGetter: (_: any) => $t("location-list-modifiedBy-label", { $: "Modified By" }),
      floatingFilterComponentParams: {
        placeholder: $t("location-list-modifiedBy-label", { $: "Modified By" })
      }
    },
    {
      field: "modifiedAt",
      type: ["dateTimeTypeColumn"],
      filter: "agDateColumnFilter",
      editable: false,
      resizable: true,
      sortable: true,
      valueFormatter: (params: any) => {
        return $dateTimeFormatter(params.data.modifiedAt);
      },
      headerValueGetter: (_: any) => $t("location-list-modifiedAt-label", { $: "Modified At" })
    }
  ];
}

function onSelectionChanged({ api }: { api: GridApi }) {
  isRowSelected.value = getSelectedRows(api).length > 0;
}

async function markAsPlanned() {
  const selectedRows = getSelectedRows(gridWrapperRef.value?.gridApi) ?? [];
  const snackbarsStore = useSnackbarsStore();
  if (selectedRows.length > 0) {
    const communicationItem = selectedRows[0];
    try {
      await ApiService.communications.markAsPlanned({ id: communicationItem.id, scenarioId: communicationItem.scenarioId, plannedSendTime: getNowInISOFormat() });
      await snackbarsStore.createSnackbar({
        message: $t("communications-action-markAsPlanned-message", "Message will be send within a minute!"),
        type: "success",
        closeable: true
      });
      gridWrapperRef.value?.gridApi?.refreshServerSide();
    } catch (e) {
      console.error(e);
      await snackbarsStore.createSnackbar({
        message: $t("communications-action-markAsPlanned-errorMessage", "Could not mark message as planned for sending"),
        type: "error",
        closeable: true
      });
    }
  }
}

async function createRandomCommunicationItems() {
  const snackbarsStore = useSnackbarsStore();
  try {
    await ApiService.communications.generateRandomCommunicationItems({ scenarioId: selectedScenario.id } as CreateRandomCommunicationItemsCommand);
    await snackbarsStore.createSnackbar({
      message: $t("communications-action-generateRandom-message", "Done!"),
      type: "success",
      closeable: true
    });
    gridWrapperRef.value?.gridApi?.refreshServerSide();
  } catch (e) {
    console.error(e);
    await snackbarsStore.createSnackbar({
      message: $t("communications-action-generateRandom-errorMessage", "Could not generate random communciation items"),
      type: "error",
      closeable: true
    });
  }
}
</script>

<template>
  <grid-wrapper
    ref="gridWrapperRef"
    identifier="contacts"
    create-btn
    duplicate-btn
    edit-btn
    delete-btn
    refresh-btn
    row-selection="multiple"
    server-side
    :server-side-datasource="serverSideDataSource"
    @fetch-data="onFetchData"
    @refresh-action="onRefreshAction"
    @prepare-columns="onPrepareColumns"
    @selection-changed="onSelectionChanged"
  >
    <template #custom-buttons>
      <v-tooltip bottom open-delay="300">
        <template #activator="{ props }">
          <div class="d-inline-flex pr-4">
            <v-btn size="small" variant="text" density="compact" v-bind="props" :disabled="!isRowSelected" @click="markAsPlanned">
              <v-icon icon="mdi-send" class="pr-4"></v-icon>
              {{ $t("communications-action-markAsPlanned-label", { $: "Confirm messages to be sent" }) }}
            </v-btn>
          </div>
        </template>
        <span>{{ $t("communications-action-markAsPlanned-tooltip", { $: "Once confirmed, the messages will be transferred to the sending queue" }) }}</span>
      </v-tooltip>
      <v-tooltip bottom open-delay="300">
        <template #activator="{ props }">
          <div class="d-inline-flex pr-4">
            <v-btn size="small" variant="text" density="compact" v-bind="props" @click="createRandomCommunicationItems">
              <v-icon icon="mdi-send" class="pr-4"></v-icon>
              {{ $t("communications-action-generateRandom-label", { $: "Generate messages" }) }}
            </v-btn>
          </div>
        </template>
        <span>{{ $t("communications-action-generateRandom-tooltip", { $: "Generate messages according to the set algorithm" }) }}</span>
      </v-tooltip>
    </template>
  </grid-wrapper>
</template>

<style lang="scss" scoped></style>
