import ApiService from "@/services/api";
import { GetProductTemplateUsableResourcesPaginatedQuery, IAgGridPaginationResponse, ResourceDto } from "@masta/generated-model";
import { ServerSideDataSource } from "@/components/Grid/ServerSideDataSource";
import { IServerSideGetRowsParams } from "ag-grid-community";
import { Store } from "pinia";
import { State as ScenarioState, useScenariosStore } from "@/store/ScenariosStore";

export class ProductTemplateUsableResourcesServerSideDataSource extends ServerSideDataSource<ResourceDto, ResourceDto, undefined, undefined, string> {
  private _scenarioStore: Store<string, ScenarioState>;
  private _filterByGroup = true;

  constructor(id: string, filterByGroup = true) {
    super(id);
    this._scenarioStore = useScenariosStore();
    this._filterByGroup = filterByGroup;
  }

  async create(entity: ResourceDto): Promise<string> {
    return "";
  }

  async getAll(params: IServerSideGetRowsParams): Promise<IAgGridPaginationResponse<ResourceDto>> {
    const scenarioId = this.selectedScenarioId();
    const groupId = this.asGroupId(params);
    const request = {
      ...this.constructServerRequest(params),
      scenarioId,
      groupId,
      filterByGroup: this._filterByGroup
    } as GetProductTemplateUsableResourcesPaginatedQuery;
    const response = await ApiService.productTemplates.getUsableResourcesPaginated(request);
    return response.data;
  }

  async getSingle(id: string): Promise<ResourceDto | undefined> {
    return (await this.getAll({} as IServerSideGetRowsParams)).results?.find((rs) => rs.id === id);
  }

  async remove(entity: ResourceDto): Promise<void> {
    return undefined;
  }

  async update(entity: ResourceDto): Promise<string> {
    return "";
  }

  protected transformForCreate(listEntity: ResourceDto): undefined {
    return undefined;
  }

  protected transformForUpdate(listEntity: ResourceDto): undefined {
    return undefined;
  }

  private asGroupId(params: IServerSideGetRowsParams) {
    const groupKeys = params.request.groupKeys;
    return groupKeys[groupKeys.length - 1];
  }

  private selectedScenarioId() {
    return this._scenarioStore.selectedScenario?.id;
  }
}
