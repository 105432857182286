import { SignalRHub } from "@/plugins/signalR.plugin";
import { nextTick, watch } from "vue";
import { HubConnectionState } from "@microsoft/signalr";

export class NotificationHandler {
  private _handler: (...args: any[]) => void;
  private _initialized = false;

  /**
   *
   * @param {import("@/plugins/signalR.plugin").SignalRHub} signalR SignalR instance
   * @param {string} methodName Hub method name to handle
   */
  constructor(private _signalR: SignalRHub, private _methodName: string) {
  }

  subscribe(handler: (...args: any[]) => void) {
    this._handler = handler;
    const stopWatch = watch(
      this._signalR.connection,
      (connection) => {
        if (connection?.state === HubConnectionState.Connected && !this._initialized) {
          this._signalR.connection.value?.on(this._methodName, this._handler);
          this._initialized = true;
          nextTick(() => stopWatch());
        }
      },
      { immediate: true, deep: true }
    );
  }

  unsubscribe() {
    this._signalR.connection.value?.off(this._methodName, this._handler);
  }
}
