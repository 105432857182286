import ApiService from "@/services/api";
import { $authService } from "@/services/AuthService";
import { defineStore } from "pinia";
import { useSystemStore } from "@/store/SystemStore";
import { RegisterTenantCommand, TenantDto } from "@masta/generated-model";

interface State {
  user: any | null;
  clientId: string | null;
  isAuthenticated: boolean;
  loading: boolean;
  creatingTenant: boolean;
  tenant: TenantDto | null;
}

export const useAuthStore = defineStore("auth", {
  state: (): State => ({
    user: null,
    clientId: null,
    isAuthenticated: false,
    loading: true,
    creatingTenant: false,
    tenant: null
  }),
  getters: {
    getUser: (state) => state.user,
    currentTenantId: (state) => state.user?.tenant_id,
    getTenant: (state) => state.tenant,
    isLoggedIntoTenant: (state) => state.isAuthenticated && state.user && state.user.tenant_id,
    getClientId: (state) => state.clientId
  },
  actions: {
    setClientId(clientId: string) {
      this.clientId = clientId;
    },
    async logout() {
      this.loading = true;
      await $authService.logout();
    },
    async finishedLoading() {
      if ($authService.authenticated) {
        await this.logIntoTenant();
        const systemStore = useSystemStore();
        await systemStore.fetchContext();
        await this.fetchTenant();
      } else {
        this.isAuthenticated = false;
        this.user = null;
      }
      this.loading = false;
    },
    async logIntoTenant() {
      this.user = $authService.tokenParsed;
      this.isAuthenticated = true;
    },
    async fetchTenant() {
      const { data } = await ApiService.tenants.getUserTenant();
      this.tenant = data;
    },
    async fetchUserTenants() {
      const { data } = await ApiService.tenants.getUserTenants();
      data.tenants?.sort((a: TenantDto, b: TenantDto) => a.displayName.localeCompare(b.displayName));
      return data.tenants;
    },
    async createTenant(tenantData: RegisterTenantCommand) {
      try {
        this.creatingTenant = true;
        await ApiService.tenants.createTenant(tenantData);
      } catch (e) {
        this.creatingTenant = false;
        throw e;
      }
    },
    finishCreatingTenant() {
      this.creatingTenant = false;
    }
  }
});
