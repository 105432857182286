import { GuiModelItem, GuiObjectType } from "./GuiModelItem";

export class MultiSelectPropertyModel implements GuiModelItem {
  public value: (string | number)[] = [];
  public readonly type = GuiObjectType.MultiSelect;
  public color: string | undefined;
  public icon: string | undefined;

  constructor(public name: string, public options: (string | number)[]) {}

  getValue(): any {
    return this.value;
  }

  isValid(): boolean {
    return false;
  }

  setValue(value: (string | number)[]): void {
    this.value = value;
  }

  addValue(value: string | number) {
    this.value.push(value);
  }

  removeValue(value: string | number) {
    this.value = this.value.filter((x) => x !== value);
  }
}
