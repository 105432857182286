export class WeakCache<T extends object> {
  private cache: Map<string, WeakRef<T>>;
  private finalizationRegistry: FinalizationRegistry<string>;

  constructor(onDelete?: (key: string) => void) {
    this.cache = new Map();
    this.finalizationRegistry = new FinalizationRegistry<string>((key: string) => {
      this.cache.delete(key);
      if (onDelete) {
        onDelete(key);
      }
    });
  }

  get length(): number {
    return this.cache.size;
  }

  public set(key: string, value: T): WeakRef<T> {
    const ref = new WeakRef<T>(value);
    this.cache.set(key, ref);
    this.finalizationRegistry.register(ref, key);
    return ref;
  }

  public get(key: string): T | undefined {
    const entry = this.cache.get(key);
    if (entry) {
      return entry.deref();
    }
    return undefined;
  }
}
