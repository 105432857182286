<script lang="ts" setup>
import { ModelInstanceDto, ModelSchemaDto } from "@masta/generated-model";
import { computed, onMounted, onUnmounted, ref } from "vue";
import ModelInstanceGrid from "@/components/ModelInstances/ModelInstanceAssignment/ModelInstanceGrid.vue";
import CloseCardButton from "@/components/CloseCardButton.vue";
import { useModelInstancesStore } from "@/store/ModelInstancesStore";
import { storeToRefs } from "pinia";
import { useScenariosStore } from "@/store/ScenariosStore";

const props = defineProps<{
  modelInstances: ModelInstanceDto[];
  opened: boolean;
  readOnly?: boolean;
  resourceAssignment?: boolean;
  taskAssignment?: boolean;
}>();

const emit = defineEmits(["save", "cancel"]);

onMounted(() => {
  document.addEventListener("keydown", escapeHandler);
});

onUnmounted(() => {
  document.removeEventListener("keydown", escapeHandler);
});

const miStore = useModelInstancesStore();
const scenariosStore = useScenariosStore();
const { instances: allInstances, schemas, selectedSchema } = storeToRefs(miStore);
//TODO enable editing, grouping

const unassignedInstances = computed<ModelInstanceDto[]>(() => {
  if (!scenariosStore.selectedScenario) {
    return [];
  }
  return allInstances.value.filter((x: ModelInstanceDto) => {
    return !assignedDic.value.has(x.id);
  });
});
const selectedUnassignedInstances = ref<ModelInstanceDto[]>([]);

const assignedDic = ref(new Set(props.modelInstances.map((x) => x.id)));
const assignedInstances = ref<ModelInstanceDto[]>(props.modelInstances);
const selectedAssignedInstances = ref<ModelInstanceDto[]>([]);
const assignmentType = ref(0);

function save() {
  emit("save", assignedInstances.value);
}

function cancel() {
  emit("cancel");
}

function escapeHandler(e: KeyboardEvent) {
  if (e.key === "Escape") {
    emit("cancel");
  }
}

async function schemaSelected(newSchema: ModelSchemaDto) {
  await miStore.fetchSchemaInstancesBySchema(newSchema);
}

function moveToAssigned() {
  const unassigned = selectedUnassignedInstances.value.map((x) => ({
    ...x
  }));
  assignedInstances.value = [...assignedInstances.value, ...unassigned];
  selectedUnassignedInstances.value.forEach((x) => assignedDic.value.add(x.id));

  selectedUnassignedInstances.value = [];
}

function moveToUnassigned() {
  selectedAssignedInstances.value.forEach((x) => {
    assignedInstances.value = assignedInstances.value.filter((y) => y.id !== x.id);
    assignedDic.value.delete(x.id);
  });

  selectedAssignedInstances.value = [];
}

function onUnassignedSelectionChanged(selection: ModelInstanceDto[]) {
  selectedUnassignedInstances.value = selection;
}

function onAssignedSelectionChanged(selection: ModelInstanceDto[]) {
  selectedAssignedInstances.value = selection;
}
</script>

<template>
  <v-dialog :model-value="opened" style="z-index: 2000" :persistent="true" class="assignment-editor-dialog" @click:outside="cancel">
    <v-card width="100%" height="90vh" class="assignment-editor-card">
      <close-card-button @click="cancel"></close-card-button>
      <v-card-title class="pa-0">
        <div class="text-h4 pb-5">
          {{ $t("modelInstance-assignmentEditor-modelInstanceAssignment-title", { $: "Model Instance Assignment" }) }}
        </div>
      </v-card-title>
      <v-card-text class="d-flex flex-column flex-grow-1 px-0">
        <v-row class="flex-nowrap flex-grow-0">
          <div class="left-col d-flex flex-row flex-grow-1 flex-shrink-1">
            <v-autocomplete
              :model-value="selectedSchema"
              :items="schemas"
              :item-title="(v) => `${v.label} (v. ${v.version})`"
              :item-value="(v) => v"
              :label="$t('modelInstance-assignmentEditor-selectSchema-label', { $: 'Select Schema' })"
              :persistent-hint="selectedSchema === null"
              :hint="selectedSchema === null ? $t('modelInstance-assignmentEditor-selectSchema-label', { $: 'Select schema' }) : null"
              variant="outlined"
              density="compact"
              color="primary"
              class="align-self-start"
              @update:model-value="schemaSelected"
            />
          </div>
          <div class="middle-col d-flex flex-column justify-center flex-shrink-1"></div>
          <div class="right-col d-flex flex-column flex-grow-1 flex-shrink-1">
            <slot></slot>
          </div>
        </v-row>
        <v-row class="flex-nowrap flex-grow-1">
          <div class="left-col d-flex flex-column flex-grow-1 flex-shrink-1">
            <h3 class="my-2">{{ $t("modelInstance-assignmentEditor-unassignedStatus-label", { $: "Unassigned" }) }}</h3>
            <model-instance-grid :model-instances="unassignedInstances" @selection-changed="onUnassignedSelectionChanged"></model-instance-grid>
          </div>
          <div class="middle-col pt-gap-35 d-flex flex-column align-center">
            <v-btn variant="flat" color="primary" size="small" class="button-arrow my-4" :disabled="readOnly || selectedUnassignedInstances.length === 0" @click="moveToAssigned">
              <v-icon icon="mdi-chevron-right"></v-icon>
            </v-btn>
            <v-btn variant="flat" color="primary" size="small" class="button-arrow my-4" :disabled="readOnly || selectedAssignedInstances.length === 0" @click="moveToUnassigned">
              <v-icon icon="mdi-chevron-left"></v-icon>
            </v-btn>
          </div>
          <div class="right-col d-flex flex-column flex-grow-1 flex-shrink-1">
            <h3 class="my-2">{{ $t("modelInstance-assignmentEditor-assignedStatus-label", { $: "Assigned" }) }}</h3>
            <model-instance-grid
              :model-instances="assignedInstances"
              :resource-assignment="resourceAssignment"
              :task-assignment="taskAssignment"
              @selection-changed="onAssignedSelectionChanged"
            ></model-instance-grid>
          </div>
        </v-row>
      </v-card-text>
      <v-card-actions class="pa-0">
        <div class="left-col pl-0">
          <v-btn variant="elevated" color="secondary" class="w-100" @click="cancel">{{ $t("modelInstance-assignmentEditor-cancel-action", { $: "Cancel" }) }}</v-btn>
        </div>
        <div class="middle-col"></div>
        <div class="right-col pr-0">
          <v-btn variant="elevated" color="primary" class="w-100" :disabled="readOnly" @click="save">{{ $t("modelInstance-assignmentEditor-save-action", { $: "Save" }) }}</v-btn>
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
