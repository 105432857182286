import { createApp } from "vue";
import App from "@/App.vue";
import router from "@/router/index";
import { createPinia } from "pinia";
import "ag-grid-enterprise";
import "@/aggrid-license";

import { DayjsPlugin } from "@masta/shared";
import { AclPlugin } from "@/plugins/acl.plugin";
import { SignalRPlugin } from "@/plugins/signalR.plugin";
import vuetify from "@/plugins/vuetify";
import { AppPlugin } from "@/plugins/app.plugin";
import Toast, { POSITION } from "vue-toastification";
import { MegaMenuPlugin } from "@/plugins/mega-menu.plugin";
import Datepicker from "@vuepic/vue-datepicker";
import { ApmVuePlugin } from "@elastic/apm-rum-vue";
import { i18n } from "./i18n";
import { useTenantIdSubdomain } from "@/useTenantIdSubdomain";

const { tenantId, isTenantIdValid } = useTenantIdSubdomain();

App.name = "MeMOM";

export const app = createApp(App);

if (isTenantIdValid) {
  app
    .use(router)
    .use(i18n)
    .use(ApmVuePlugin, {
      router,
      config: {
        serviceName: import.meta.env.VITE_APM_SERVICE_NAME,
        serverUrl: import.meta.env.VITE_APM_URL,
        distributedTracingOrigins: [import.meta.env.VITE_API_BASE_URL],
        serviceVersion: "",
        environment: import.meta.env.VITE_APM_ENVIRONMENT
      }
    })
    .use(createPinia())
    .use(vuetify)
    .use(DayjsPlugin)
    .use(AclPlugin)
    .use(SignalRPlugin)
    .use(AppPlugin)
    .use(MegaMenuPlugin)
    .use(Toast, { position: POSITION.BOTTOM_RIGHT, pauseOnHover: false })
    .component("Datepicker", Datepicker)
    .mount("#app");
} else {
  console.log("Tenant ID is not valid");
  window.location.href = "/tenantNotFound.html";
}
