import { DispatchTaskAssignmentDto, ResourceType, ShiftInstanceDto, TerminalStepDto, TerminalStepResourceSpecDto, TerminalTaskDetailsDto } from "@masta/generated-model";
import { parseISO } from "date-fns/fp";

export class DispatchTaskSpec {
  taskId: string;
  taskName: string;
  businessId: string;
  stepId: string;
  stepName: string;
  id: string;
  specResource: string;
  selected: boolean;
  assignments: DispatchTaskAssignmentDto[];
  start: string;
  end: string;
  totalQuantity: number;
  assignedQuantity: number;
  iconName: string;
  tags: string[] | null;

  constructor(task: TerminalTaskDetailsDto, step: TerminalStepDto, spec: TerminalStepResourceSpecDto, start: string, end: string, assignments: DispatchTaskAssignmentDto[]) {
    this.selected = false;
    this.taskId = task.id;
    this.taskName = task.name ?? "";
    this.businessId = task.businessId ?? task.parentTask?.businessId ?? "";
    this.stepId = step.id;
    this.stepName = step.name ?? "";
    this.id = spec.id;
    this.specResource = spec.resource?.name ?? "";
    this.start = start ?? "";
    this.end = end ?? "";
    this.assignments = assignments;
    this.totalQuantity = spec.quantity;
    this.assignedQuantity = assignments.length;
    this.iconName = this.getIconName();
    this.tags = task.tags;
  }

  getIconName() {
    if (this.totalQuantity <= 0) {
      return this.assignedQuantity > 0 ? "mdi-battery-alert-variant-outline" : "mdi-battery-outline";
    }

    const percent = (this.assignedQuantity / this.totalQuantity) * 100;

    if (percent > 100) {
      return "mdi-battery-alert";
    } else if (percent === 100) {
      return "mdi-battery";
    } else if (percent === 0) {
      return "mdi-battery-outline";
    } else if (percent >= 90) {
      return "mdi-battery-90";
    } else if (percent >= 80) {
      return "mdi-battery-80";
    } else if (percent >= 70) {
      return "mdi-battery-70";
    } else if (percent >= 60) {
      return "mdi-battery-60";
    } else if (percent >= 50) {
      return "mdi-battery-50";
    } else if (percent >= 40) {
      return "mdi-battery-40";
    } else if (percent >= 30) {
      return "mdi-battery-30";
    } else if (percent >= 20) {
      return "mdi-battery-20";
    } else {
      return "mdi-battery-10";
    }
  }
}

export function createDispatchTaskSpec(
  task: TerminalTaskDetailsDto,
  step: TerminalStepDto,
  spec: TerminalStepResourceSpecDto,
  periodStart: Date,
  periodEnd: Date,
  shiftInstance: ShiftInstanceDto | null
) {
  if ((spec.resourceType !== ResourceType.PersonGroup && spec.resourceType !== ResourceType.Person) || !step.statistics) {
    return null;
  }
  const assignments = shiftInstance && spec.dispatchAssignments ? spec.dispatchAssignments.filter((x) => x.shiftInstanceId === shiftInstance.id) : [];

  const start = parseISO(step.statistics.schedulingStart);
  const end = parseISO(step.statistics.schedulingEnd);

  if ((start >= periodEnd || periodStart >= end) && start !== periodStart && assignments.length === 0) {
    return null;
  }

  return new DispatchTaskSpec(task, step, spec, step.statistics.schedulingStart, step.statistics.schedulingEnd, assignments);
}
