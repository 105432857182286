import ApiService from "@/services/api";
import { ServerSideDataSource } from "@/components/Grid/ServerSideDataSource";
import { CreateOrUpdatePurchaseOrderCommand, GetPurchaseOrdersPaginatedQuery, IAgGridPaginationResponse, PurchaseOrderDto, OrderType } from "@masta/generated-model";
import { IServerSideGetRowsParams } from "ag-grid-community";
import { State as ScenarioState, useScenariosStore } from "@/store/ScenariosStore";
import { Store } from "pinia";
import { GUID_EMPTY } from "@/components/ValueCellEditor/CommonFormatters";

export class PurchaseOrdersServerSideDataSource extends ServerSideDataSource<
  PurchaseOrderDto,
  PurchaseOrderDto,
  CreateOrUpdatePurchaseOrderCommand,
  CreateOrUpdatePurchaseOrderCommand,
  string
> {
  private _scenarioStore: Store<string, ScenarioState>;
  private _filterByProductId: string | null | undefined;

  constructor(id: string) {
    super(id);
    this._scenarioStore = useScenariosStore();
    this._filterByProductId = null;
  }

  public useFilteringByProductId(productId: string | null | undefined) {
    this._filterByProductId = productId;
  }

  async getAll<T>(params: IServerSideGetRowsParams): Promise<IAgGridPaginationResponse<PurchaseOrderDto>> {
    if (!this._scenarioStore.selectedScenario) return { count: 0, results: [] } as IAgGridPaginationResponse<PurchaseOrderDto>;

    const request = {
      ...this.constructServerRequest(params),
      scenarioId: this._scenarioStore.selectedScenario.id,
      resourceId: this._filterByProductId
    } as GetPurchaseOrdersPaginatedQuery;
    const response = await ApiService.purchaseOrders.getPaginated(request);
    return response.data as IAgGridPaginationResponse<PurchaseOrderDto>;
  }

  async create(entity: PurchaseOrderDto): Promise<string> {
    const request = this.transformForCreate(entity);
    const scenario = this._scenarioStore.selectedScenario;
    request.scenarioId = scenario?.id ?? "";
    const response = await ApiService.purchaseOrders.createOrUpdate(request);
    return response.data;
  }

  async getSingle(id: string): Promise<PurchaseOrderDto | undefined> {
    if (!this._scenarioStore.selectedScenario) return undefined;
    const response = await ApiService.purchaseOrders.getSingle(id, this._scenarioStore.selectedScenario.id);
    return response.data;
  }

  async remove(entity: PurchaseOrderDto): Promise<void> {
    if (!entity.id || !entity.scenarioId) return;
    await ApiService.purchaseOrders.remove(entity.id, entity.scenarioId);
  }

  async update(entity: PurchaseOrderDto): Promise<string> {
    const response = await ApiService.purchaseOrders.createOrUpdate(this.transformForUpdate(entity));
    return response.data;
  }

  public transformPurchaseOrderDtoToCreateOrUpdatePurchaseOrderCommand(listEntity: PurchaseOrderDto): CreateOrUpdatePurchaseOrderCommand {
    return {
      id: listEntity.id,
      scenarioId: listEntity.scenarioId ?? this._scenarioStore.selectedScenario?.id ?? GUID_EMPTY,
      organizationId: listEntity.organizationId ?? GUID_EMPTY,
      issueDate: listEntity.issueDate,
      lines:
        listEntity.lines?.map((x) => ({
          itemBusinessId: x.itemBusinessId,
          itemId: x.itemId,
          description: x.description,
          orderLineStatus: x.status,
          position: x.position,
          deliveryDate: x.deliveryDate,
          quantity: x.quantity,
          quantityUnit: x.measurementUnit,
          modelInstances: [],
          modelInstanceBusinessIds: []
        })) ?? [],
      businessId: listEntity.businessId,
      description: listEntity.description,
      organizationBusinessId: listEntity.customerName,
      modelInstances: [],
      modelInstanceBusinessIds: []
    };
  }

  protected transformForCreate(listEntity: PurchaseOrderDto): CreateOrUpdatePurchaseOrderCommand {
    return this.transformPurchaseOrderDtoToCreateOrUpdatePurchaseOrderCommand(listEntity);
  }

  protected transformForUpdate(listEntity: PurchaseOrderDto): CreateOrUpdatePurchaseOrderCommand {
    return this.transformPurchaseOrderDtoToCreateOrUpdatePurchaseOrderCommand(listEntity);
  }
}
