import {
  BaseRowInfoColumnProvider,
  GanttSettings,
  IocSymbols,
  IRowInfoColumn,
  IRowInfoColumnHeader,
  IRowInfoColumnProvider,
  Lifecycle,
  ObservedObject,
  observeObject,
  SettingKey
} from "@masta/gantt2/core";
import { inject, injectable } from "inversify";
import { BehaviorSubject, Observable } from "rxjs";

@injectable()
export class TaskGanttRowInfoColumnProvider extends BaseRowInfoColumnProvider {
  protected _columns: ObservedObject<IRowInfoColumn>[] = [
    observeObject({ id: "text", className: "text", width: 225, visible: true }),
    observeObject({ id: "external-id", className: "external-id", width: 125, visible: true }),
    observeObject({ id: "wbs", className: "wbs", width: 125, visible: true })
  ];
  protected _headers: IRowInfoColumnHeader[] = [
    { id: "text", className: "text", text: "Name" } as IRowInfoColumnHeader,
    { id: "external-id", className: "external-id", text: "External<br>ID" } as IRowInfoColumnHeader,
    { id: "wbs", className: "wbs", text: "WBS" } as IRowInfoColumnHeader
  ];

  constructor(@inject(IocSymbols.HtmlContainerSymbol) container: HTMLDivElement, @inject(GanttSettings) private _ganttSettings: GanttSettings) {
    super(container);
  }

  async beforeInitialize(): Promise<void> {
    await super.beforeInitialize();
  }
}
