export const SETTING_CONTEXT = Object.freeze([
  { name: "Global", value: 100 },
  { name: "Tenant", value: 200 },
  { name: "User", value: 300 }
]);
export default {
  getContextByName(name) {
    const context = SETTING_CONTEXT.find((x) => x.name.toLowerCase() === name.trim().toLowerCase());
    if (!context) throw new Error(`Setting Context with name ${name} does not exist`);
    return context;
  },
  getContextByValue(value) {
    const context = SETTING_CONTEXT.find((x) => x.value === value);
    if (!context) throw new Error(`Setting Context with value ${value} does not exist`);
    return context;
  }
};
