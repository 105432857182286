import { inject, injectable } from "inversify";
import { GanttDomElement } from "../../../GanttDomElement";
import { Row } from "../../../../Core";
import { fromEvent } from "rxjs";

@injectable()
export class RowInfoCollapseBtnContainer<TResource extends Row<any, any, any>> extends GanttDomElement<HTMLDivElement> {
  constructor(
    @inject(Row<any, any, any>) private _row: TResource
  ) {
    super(`${RowInfoCollapseBtnContainer.name}-${_row.id}`, undefined, "info-collapse-btn-container");
    this.element.dataset.id = this.identifier;
    this.element.className = "info-collapse-btn-container chevron right";
  }

  async afterInitialize(): Promise<void> {
    this.subscribe(fromEvent(this.element, "click").subscribe(this.onClick.bind(this)));
  }

  async doDrawFromBatch(): Promise<void> {
    if (!this._row.leaf) {
      this.element.classList.remove("hidden");
      this.element.innerHTML = this._row.expanded ? "<span>[ - ]</span>" : "<span>[ + ]</span>";
    } else if (this.element.innerHTML !== "<span />") {
      this.element.innerHTML = "<span />";
      this.element.classList.add("hidden");
    }
  }

  protected async doDraw(): Promise<void> {
    if (!this._row.leaf) {
      this.element.classList.remove("hidden");
      this.element.innerHTML = this._row.expanded ? "<span>[ - ]</span>" : "<span>[ + ]</span>";
    } else if (this.element.innerHTML !== "<span />") {
      this.element.innerHTML = "<span />";
      this.element.classList.add("hidden");
    }
  }

  private onClick() {
    if (this._row.children.length > 0) {
      this._row.expanded = !this._row.expanded;
    }
  }
}
