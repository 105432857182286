import { GuiModelItem, GuiObjectType } from "./GuiModelItem";

export class SelectPropertyModel implements GuiModelItem {
  public value: string | number = "";
  public readonly type = GuiObjectType.Select;
  public color: string | undefined;
  public icon: string | undefined;

  constructor(public name: string, public options: (string | number)[]) {
    if (options.length > 0) {
      this.value = options[0];
    }
  }

  getValue(): any {
    return this.value;
  }

  isValid(): boolean {
    return false;
  }

  setValue(value: any): void {
    if(typeof value !== "string" && typeof value !== "number") {
      throw new Error("Setting non-string value to string");
    }
    this.value = value;
  }
}
