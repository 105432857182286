<script lang="ts" setup>
import { SchemaTypeStringModel } from "@/components/ModelInstances/SchemaBuilder/SchemaModel";
import { $t } from "@/i18n";

const props = defineProps<{
  modelValue: SchemaTypeStringModel;
}>();

const emit = defineEmits(["update:modelValue"]);

function update(key: string, value: any) {
  const newModel = { ...props.modelValue, [key]: value };
  emit("update:modelValue", newModel);
}

const optionalNumberRules = [
  (v: any) => !v || +v >= 0 || $t("schemaType-stringProperty-onEmptyOrNotNegativeIfNumberRequired-message", { $: "Must be empty or not negative number" })
];
</script>

<template>
  <div class="d-flex justify-start flex-wrap">
    <v-chip class="ma-2" size="x-large" label>
      {{ $t("schemaType-stringProperty-type-label", { $: "String" }) }}
    </v-chip>
    <v-text-field
      variant="outlined"
      class="mx-2"
      type="number"
      :label="$t('schemaType-numberProperty-minimumLengthValue-label', { $: 'Min. Length' })"
      :model-value="modelValue.minLength"
      :rules="optionalNumberRules"
      @update:model-value="update('minLength', $event)"
    />
    <v-text-field
      variant="outlined"
      class="mx-2"
      :label="$t('schemaType-numberProperty-maximumLengthValue-label', { $: 'Max. Length' })"
      type="number"
      :model-value="modelValue.maxLength"
      :rules="optionalNumberRules"
      @update:model-value="update('maxLength', $event)"
    />
    <v-text-field
      variant="outlined"
      class="mx-2"
      :label="$t('schemaType-numberProperty-regularExpressionValue-label', { $: 'Regular Expression' })"
      :model-value="modelValue.pattern"
      @update:model-value="update('pattern', $event)"
    />
    <v-text-field
      variant="outlined"
      class="mx-2"
      :label="$t('schemaType-property-icon-label', { $: 'Icon' })"
      :model-value="modelValue.icon"
      @update:model-value="update('icon', $event)"
    />
    <v-text-field
      variant="outlined"
      class="mx-2"
      :label="$t('schemaType-property-color-label', { $: 'Color' })"
      :model-value="modelValue.color"
      @update:model-value="update('color', $event)"
    />
  </div>
</template>

<style lang="scss" scoped>
.v-text-field {
  width: 200px;
}
</style>