export const composeTreeHierarchy = (dataset: any[], parentIdPropertyName: string, idPropertyName = "id") => {
  return dataset.map((data) => {
    const hierarchy: string[] = [];
    composeHierarchy(data, dataset, hierarchy, parentIdPropertyName, idPropertyName);
    return {
      ...data,
      hierarchy
    };
  });
};

function composeHierarchy(current: any, all: any[], hierarchy: string[], parentIdPropertyName: string, idPropertyName: string) {
  if (current && current[parentIdPropertyName]) {
    const parent = all.find((p) => p[idPropertyName] === current[parentIdPropertyName]);
    composeHierarchy(parent, all, hierarchy, parentIdPropertyName, idPropertyName);
  }
  if (current) hierarchy.push(current[idPropertyName]);
}


