<template>
  <span v-if="value">
    {{ context }} <strong>({{ value.description }})</strong>
  </span>
  <!--  <div-->
  <!--    v-if="value"-->
  <!--    class="d-flex align-center"-->
  <!--    style="height: 100%"-->
  <!--  >-->
  <!--    <v-tooltip bottom>-->
  <!--      <template #activator="{ props }">-->
  <!--        <v-icon-->
  <!--          v-bind="props"-->
  <!--        >-->
  <!--          {{ value.removable ? "mdi-lock-open-variant" : "mdi-lock" }}-->
  <!--        </v-icon>-->
  <!--      </template>-->
  <!--      <span>Removable: {{ value.removable }}</span>-->
  <!--    </v-tooltip>-->
  <!--    <v-tooltip bottom>-->
  <!--      <template #activator="{ props }">-->
  <!--        <v-icon v-bind="props">-->
  <!--          {{ typeIcon }}-->
  <!--        </v-icon>-->
  <!--      </template>-->
  <!--      <span>Type: {{ value.type }}</span>-->
  <!--    </v-tooltip>-->
  <!--    <v-tooltip bottom>-->
  <!--      <template #activator="{ props }">-->
  <!--        <v-icon v-bind="props">-->
  <!--          {{ contextIcon }}-->
  <!--        </v-icon>-->
  <!--      </template>-->
  <!--      <span>Default context: {{ context }}</span>-->
  <!--    </v-tooltip>-->
  <!--    <v-tooltip bottom>-->
  <!--      <template #activator="{ props }">-->
  <!--        <v-chip-->
  <!--          v-show="value.description"-->
  <!--          v-bind="props"-->
  <!--        >-->
  <!--          {{ value.description }}-->
  <!--        </v-chip>-->
  <!--      </template>-->
  <!--      <span>{{ value.description }}</span>-->
  <!--    </v-tooltip>-->
  <!--  </div>-->
</template>

<script>
import SettingsContextService from "@/services/settings-context.service";
export default {
  name: "SettingsMetadataCellRenderer",
  data() {
    return {
      value: ""
    };
  },
  computed: {
    context() {
      return SettingsContextService.getContextByValue(this.value.defaultContext).name;
    },
    typeIcon() {
      switch (this.value.type) {
        case "System.String":
          return "mdi-code-string";
        case "System.Int32":
          return "mdi-numeric-0-circle";
        case "System.Boolean":
          return "mdi-check-circle";
        default:
          return "mdi-code-string";
      }
    },
    contextIcon() {
      switch (this.value.defaultContext) {
        case 100:
          return "mdi-earth";
        case 200:
          return "mdi-domain";
        case 300:
          return "mdi-account";
        default:
          return "mdi-earth";
      }
    }
  },
  beforeMount() {
    this.value = this.params.value;
  }
};
</script>

<style lang="scss" scoped></style>
