import { ServerSideDataSource } from "@/components/Grid/ServerSideDataSource";
import { GetResourceCapacitiesPaginatedQuery, IAgGridPaginationResponse, ResourceCapacityDto } from "@masta/generated-model";
import { Promise } from "cypress/types/cy-bluebird";
import { State as ScenarioState, useScenariosStore } from "@/store/ScenariosStore";
import { Store } from "pinia";
import ApiService from "@/services/api";
import { IServerSideGetRowsParams } from "ag-grid-community";

export class ResourceCapacityServerSideDataSource extends ServerSideDataSource<ResourceCapacityDto, ResourceCapacityDto, ResourceCapacityDto, ResourceCapacityDto, string> {
  private _scenarioStore: Store<string, ScenarioState>;
  private _filterByResourceId: string | null;

  constructor(id: string) {
    super(id);
    this._scenarioStore = useScenariosStore();
    this._filterByResourceId = null;
  }

  public useFilteringByResourceId(resourceId: string | null) {
    this._filterByResourceId = resourceId;
  }

  async getAll(params: IServerSideGetRowsParams): Promise<IAgGridPaginationResponse<ResourceCapacityDto>> {
    if (!this._scenarioStore.selectedScenario) return { count: 0, results: [] } as IAgGridPaginationResponse<ResourceCapacityDto>;

    const request = {
      ...this.constructServerRequest(params),
      scenarioId: this._scenarioStore.selectedScenario.id,
      resourceId: this._filterByResourceId
    } as GetResourceCapacitiesPaginatedQuery;
    const response = await ApiService.resourceCapacities.getPaginated(request);
    return response.data as IAgGridPaginationResponse<ResourceCapacityDto>;
  }

  async create(entity: ResourceCapacityDto): Promise<string> {
    return "";
  }

  async getSingle(id: string): Promise<ResourceCapacityDto | undefined> {
    return undefined;
  }

  async remove(entity: ResourceCapacityDto): Promise<void> {
    return;
  }

  async update(entity: ResourceCapacityDto): Promise<string> {
    return "";
  }

  protected transformForCreate(dto: ResourceCapacityDto): ResourceCapacityDto {
    return dto;
  }

  protected transformForUpdate(dto: ResourceCapacityDto): ResourceCapacityDto {
    return dto;
  }
}
