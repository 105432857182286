import { inject, injectable } from "inversify";
import { GanttDomElement } from "../../../GanttDomElement";
import { Row } from "../../../../Core";
import { RowInfoIndex } from "./RowInfoIndex";
import { RowInfoTemplate } from "./RowInfoTemplate";
import { RowInfoCollapseBtnContainer } from "./RowInfoCollapseBtnContainer";
import { RowInfoHeightResizeHandle } from "./RowInfoHeightResizeHandle";

@injectable()
export class RowInfoContainer<TResource extends Row<any, any, any>> extends GanttDomElement<HTMLDivElement> {
  constructor(
    @inject(Row<any, any, any>) private _row: TResource,
    @inject(RowInfoIndex<any>) private _rowInfoIndex: RowInfoIndex<any>,
    @inject(RowInfoTemplate<any>) private _rowInfoTemplate: RowInfoTemplate<any>,
    @inject(RowInfoHeightResizeHandle<any>) private _rowInfoHeightResizeHandle: RowInfoHeightResizeHandle<any>
  ) {
    super(`${RowInfoContainer.name}-${_row.id}`, undefined, "info-container");
    this.element.dataset.id = this.identifier;
  }


  async beforeInitialize(): Promise<void> {
    await this.addChildren([this._rowInfoIndex, this._rowInfoTemplate, this._rowInfoHeightResizeHandle]);
  }

  async afterInitialize(): Promise<void> {
  }

  async doDrawFromBatch(): Promise<void> {
    if (!this._row.visible) return;

    await this._rowInfoTemplate.doDrawFromBatch();
  }
}
