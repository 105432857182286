import { inject, injectable } from "inversify";
import { OverlappingTimeIntervalTreeActivityRepository, Row } from "@masta/gantt2/core";
import { RowNameRowInfoTemplateCell } from "@masta/gantt2/gantt";
import { ExtendedGanttResourceDto, ResourceCapacityActivity, ResourceRow } from "@/components/Gantt/Common/Model";
import { useTagsStore } from "@/store/TagsStore";
import { ResourceType, TagDto } from "@masta/generated-model";
import { translateResourceType } from "@/composables/translateEnum";
import { fromEvent } from "rxjs";

@injectable()
export class ResourceRowNameRowInfoTemplateCell extends RowNameRowInfoTemplateCell<ResourceRow> {
  private _tagsStore = useTagsStore();
  private _overlapExpandElement: HTMLDivElement;

  constructor(@inject(Row<any, any, any>) _row: ResourceRow) {
    super(_row);

    this._overlapExpandElement = document.createElement("div");
    this._overlapExpandElement.className = "overlap-expand";
  }

  async afterInitialize() {
    await super.afterInitialize();

    this.element.appendChild(this._overlapExpandElement);

    this.subscribe(fromEvent(this._overlapExpandElement, "click").subscribe(this.onOverlapExpandClick.bind(this)));

    const repository = (this._row.repository as OverlappingTimeIntervalTreeActivityRepository<ResourceCapacityActivity>);
    this.subscribe(repository.overlapRecomputed$
      .subscribe(() => {
        const overlapLevel = repository.getMaximalOverlapLevel();
        if (overlapLevel > 1) {
          this._overlapExpandElement.classList.add("overlap-expand-visible");
        } else {
          this._overlapExpandElement.classList.remove("overlap-expand-visible");
        }
      }));
  }

  async doDrawFromBatch() {
    const collapseBtnOffset = this._row.childrenIds.length > 0 ? (this._row.levelNumber + 1) * 26 : 0;
    this.element.style.width = `${(this._column.width! - collapseBtnOffset)}px`;
    const paddingLeft = this._row.childrenIds.length === 0 ? (this._row.levelNumber + 1) * 26 : 0;
    this.element.style.paddingLeft = `${paddingLeft}px`;
    const rowData = this._row.userObject as ExtendedGanttResourceDto;
    const tags = this.getTags(rowData.tags);
    const type = this.getType(rowData.type);
    this._spanElement.innerHTML = `${this._row.name}${`<br><br>[${type}]`}${tags ? `<br>${tags}` : ""}`;
    this._overlapExpandElement.innerHTML = this._row.overlapExpanded ? "<span class='mdi mdi-chevron-double-down'></span>" : "<span class='mdi mdi-chevron-double-right'></span>";
  }

  private getType(rowType: ResourceType) {
    return translateResourceType(rowType);
  }

  private getTags(rowTags: string[]) {
    if (Array.isArray(rowTags)) {
      const tags = this._tagsStore.tags;
      const tagNames = rowTags.map((tagName: string) => {
        const tag = tags.find((t: TagDto) => t.name === tagName);
        return tag ? `${tag.emoji ? `${tag.emoji} ` : ""}${tag.name}` : "";
      });
      return tagNames.join(", ");
    }
    return "";
  }

  private onOverlapExpandClick() {
    this._row.overlapExpanded = !this._row.overlapExpanded;
  }
}
