<script lang="ts" setup>
import { CreateResourceAssignmentCommand, ResourceAssignmentType, ResourceType, ResourceWithAssignmentDto } from "@masta/generated-model";
import ResourceAssignmentGrid from "@/components/Resources/ResourceAssignment/ResourceAssignmentGrid.vue";
import { ref } from "vue";
import ApiService from "@/services/api";
import { GridApi, RowNode } from "ag-grid-community";
import { useScenariosStore } from "@/store/ScenariosStore";
import { useSnackbarsStore } from "@/store/SnackbarsStore";
import { $t } from "@/i18n";
import { ErrorTypesConstants } from "@masta/shared";
import SystemEnumService from "@/services/system-enum.service";

const props = defineProps<{
  opened: boolean;
  assignmentType: ResourceAssignmentType;
  resourceType: ResourceType;
  resource: any;
  reverse?: boolean;
}>();
const $emit = defineEmits(["close"]);

const gridWrapperRef = ref<any>(null);
const scenarioStore = useScenariosStore();
const snackbarsStore = useSnackbarsStore();

const isSaving = ref(false);

async function saveResourceAssignment() {
  if (!gridWrapperRef.value?.gridApi) {
    console.error("GirdApi is not accessible!");
    await snackbarsStore.createSnackbar({
      message: $t("resource-assignment-problem-message", { $: "Could not save assignments. Please contact the support!" }),
      closeable: true,
      type: "error"
    });
    $emit("close");
    return;
  }
  isSaving.value = true;
  const selectedNodes: RowNode<ResourceWithAssignmentDto>[] = [];
  const deselectedNodes: RowNode<ResourceWithAssignmentDto>[] = [];
  const gridApi: GridApi = gridWrapperRef.value?.gridApi;
  gridApi.forEachNode((n) => {
    if (n.isSelected()) {
      selectedNodes.push(n);
    } else {
      deselectedNodes.push(n);
    }
  });
  const owningResourceId = props.resource.id;
  for (const n of selectedNodes) {
    if (n.data) {
      const resourceId = n.data.id;
      try {
        await ApiService.resourceAssignment.createResourceAssignment({
          resource1Id: props.reverse ? resourceId : owningResourceId,
          resource2Id: props.reverse ? owningResourceId : resourceId,
          assignmentType: props.assignmentType,
          scenarioId: scenarioStore.selectedScenario?.id ?? ""
        } as CreateResourceAssignmentCommand);
      } catch (e: any) {
        if (e.status === 400 && e.type === ErrorTypesConstants.BadRequest400) {
          console.warn(e.detail);
        } else {
          console.error(e);
          await snackbarsStore.createSnackbar({
            message: $t("resource-assignment-save-error-message", { $: "Could not save assignment for {businessId}", businessId: n.data.businessId }),
            closeable: true,
            error: e as Error
          });
        }
      }
    }
  }
  for (const n of deselectedNodes) {
    if (n.data) {
      const resourceId = n.data.id;
      try {
        await ApiService.resourceAssignment.deleteResourceAssignment({
          resource1: props.reverse ? resourceId : owningResourceId,
          resource2: props.reverse ? owningResourceId : resourceId,
          assignmentType: props.assignmentType,
          scenarioId: scenarioStore.selectedScenario?.id ?? ""
        });
      } catch (e: any) {
        if (e.status === 404 && e.type === ErrorTypesConstants.NotFound404) {
          console.warn(e.detail);
        } else {
          console.error(e);
          await snackbarsStore.createSnackbar({
            message: $t("resource-assignment-remove-error-message", { $: "Could not remove assignment for {businessId}", businessId: n.data.businessId }),
            closeable: true,
            error: e as Error
          });
        }
      }
    }
  }
  isSaving.value = false;
  $emit("close");
}
</script>

<template>
  <v-dialog :model-value="opened" height="80vh" @update:model-value="$emit('close')">
    <v-card height="100%">
      <v-card-title>
        {{ $t("resource-assignment-dialog-title", { $: "Resource assignment" }) }}:
        <v-chip v-if="resource" label class="mx-4">{{ resource.name ? resource.name : resource.businessId }}</v-chip>
        <v-chip color="accent" class="mx-4">
          {{ $t("resource-assignment-dialog-resourceType-label", { $: "Resource type" }) }}
          {{ SystemEnumService.resourceType(resourceType) }}
        </v-chip>
        <v-chip color="accent" class="mx-4">
          {{ $t("resource-assignment-dialog-assignmentType-label", { $: "Assignment type" }) }}
          {{ reverse ? SystemEnumService.resourceAssignmentType(assignmentType).split(" ").reverse().join(" ") : SystemEnumService.resourceAssignmentType(assignmentType) }}
        </v-chip>
      </v-card-title>
      <v-card-text class="pt-0">
        <resource-assignment-grid ref="gridWrapperRef" :resource-type="resourceType" :resource="resource" :reverse="reverse" :disabled="isSaving" />
      </v-card-text>
      <v-card-actions class="px-12">
        <v-spacer />
        <v-btn color="secondary" variant="elevated" @click="$emit('close')">
          {{ $t("resource-assignment-cancel-action", { $: "Cancel" }) }}
        </v-btn>
        <v-btn color="primary" variant="elevated" :loading="isSaving" @click="saveResourceAssignment">
          {{ $t("resource-assignment-save-action", { $: "Save" }) }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style lang="scss" scoped></style>
