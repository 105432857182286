<script setup lang="ts">
import ShiftPersonnel from "@/components/Dispatch/ShiftPersonnel.vue";
import ShiftTasks from "@/components/Dispatch/ShiftTasks.vue";
import { $t } from "@/i18n";
import { computed, inject, onMounted, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { $dateFormatterSymbol, DateFormatter } from "@masta/shared";
import ApiService from "@/services/api";
import { AvailabilityRuleDataDto, PersonDto, ResourceInfoDto, ResourceType, ShiftInstanceDto, TerminalTaskDetailsDto } from "@masta/generated-model";
import { format } from "date-fns";
import ResourcePicker from "@/components/Resources/ResourcePicker.vue";
import { createDispatchTaskSpec, DispatchTaskSpec } from "@/components/Dispatch/DispatchTaskSpec";
import { parseISO } from "date-fns/fp";
import Breadcrumbs from "@/components/Layout/Breadcrumbs.vue";
import { useScenariosStore } from "@/store/ScenariosStore";
import { useErrorsStore } from "@/store/ErrorsStore";
import { storeToRefs } from "pinia";
import DatepickerField from "@/components/Datepicker/DatepickerField.vue";

const shiftDate = ref<Date>(new Date());
const periodStart = ref<Date | null>(null);
const periodEnd = ref<Date | null>(null);
const { locale } = useI18n();
const $dateFormatter = inject<DateFormatter>($dateFormatterSymbol)!;
const availabilityRules = ref<AvailabilityRuleDataDto[]>([]);
const selectedAvailabilityRule = ref<AvailabilityRuleDataDto | null>(null);
const selectedResource = ref<ResourceInfoDto | null | undefined>();
const resourceTypes = [ResourceType.Equipment, ResourceType.EquipmentGroup];
const shiftInstance = ref<ShiftInstanceDto | null>(null);
//personnel
const personnel = ref<PersonDto[]>([]);
const availablePersonnel = ref<Set<string>>(new Set<string>());
const selectedPersonnel = ref<Set<string>>(new Set<string>());
//tasks
const productionTasks = ref<TerminalTaskDetailsDto[]>([]);
const specs = ref<DispatchTaskSpec[]>([]);
const selectedSpecIds = ref<Set<string>>(new Set<string>());
const selectedTaskIds = ref<Set<string>>(new Set<string>());
const scenarioStore = useScenariosStore();
const { selectedScenario } = storeToRefs(scenarioStore);

watch(selectedAvailabilityRule, () => loadShiftPersonnel());
watch(shiftDate, () => loadShiftPersonnel());
watch(selectedResource, () => loadResourceTasks());
const shiftDateStr = computed(() => format(shiftDate.value, "yyyy-MM-dd"));
const autoDisabled = computed(() => !(shiftDate.value && selectedAvailabilityRule.value && periodStart.value && periodEnd.value));
const manualDisabled = computed(
  () => !(shiftDate.value && selectedAvailabilityRule.value && periodStart.value && periodEnd.value && selectedTaskIds.value.size > 0 && selectedPersonnel.value.size > 0)
);
const removeDisabled = computed(() => !shiftInstance.value || selectedTaskIds.value.size === 0);

onMounted(() => {
  shiftDate.value.setHours(0, 0, 0, 0);
  loadAvailabilityRules();
});

async function loadAvailabilityRules() {
  console.error("loadAvailabilityRules - scenario id", selectedScenario.value?.id);

  try {
    if (!selectedScenario.value) return;

    const result = await ApiService.availabilityRules.getPaginated({ scenarioId: selectedScenario.value.id });
    availabilityRules.value = result.data.results;
    selectedAvailabilityRule.value = availabilityRules.value.length > 0 ? availabilityRules.value[0] : null;
  } catch (e) {
    const errorsStore = useErrorsStore();
    errorsStore.handleError(e);
  }
}

async function loadShiftPersonnel() {
  if (shiftDate.value && selectedAvailabilityRule.value) {
    try {
      if (!selectedScenario.value) return;

      const result = await ApiService.dispatch.getShiftInstancePersonnel(selectedAvailabilityRule.value.id, shiftDateStr.value, selectedScenario.value.id);
      personnel.value = result.data.personnel ?? [];
      availablePersonnel.value = new Set(result.data.availablePersonnel);
      selectedPersonnel.value.clear();
      shiftInstance.value = result.data.shiftInstance;
      periodStart.value = parseISO(result.data.start);
      periodEnd.value = parseISO(result.data.end);
      computeSpecs();
    } catch (e) {
      const errorsStore = useErrorsStore();
      errorsStore.handleError(e);
    }
  }
}

async function loadResourceTasks() {
  if (selectedResource.value) {
    if (!selectedScenario.value) return;

    const result = await ApiService.dispatch.getResourceProductionTasks(selectedResource.value.id, selectedScenario.value.id);
    productionTasks.value = result.data.tasks ?? [];
  } else {
    productionTasks.value = [];
  }
  computeSpecs();
}

function computeSpecs() {
  specs.value = [];
  selectedTaskIds.value.clear();
  selectedSpecIds.value.clear();

  if (!periodStart.value || !periodEnd.value) {
    return;
  }
  for (const task of productionTasks.value) {
    if (!task.steps) continue;
    for (const step of task.steps) {
      if (!step.resources) continue;
      for (const spec of step.resources) {
        const dispatchSpec = createDispatchTaskSpec(task, step, spec, periodStart.value, periodEnd.value, shiftInstance.value);
        if (dispatchSpec) {
          specs.value.push(dispatchSpec);
        }
      }
    }
  }
}

function formatDate(date: Date) {
  return $dateFormatter(date);
}

async function invokeAutoDispatch() {
  if (selectedAvailabilityRule.value && shiftDate.value) {
    if (!selectedScenario.value) return;

    await ApiService.dispatch.startAutoDispatch(selectedAvailabilityRule.value.id, shiftDateStr.value, selectedScenario.value.id);
    await refreshView();
  }
}

async function invokeManualDispatch() {
  if (selectedAvailabilityRule.value && shiftDate.value) {
    if (!selectedScenario.value) return;

    await ApiService.dispatch.performTaskAssignmentManualDispatch(
      selectedAvailabilityRule.value.id,
      shiftDateStr.value,
      Array.from(selectedPersonnel.value),
      Array.from(selectedTaskIds.value),
      Array.from(selectedSpecIds.value),
      selectedScenario.value.id
    );
    await refreshView();
  }
}

async function invokeDispatchRemoval() {
  if (selectedAvailabilityRule.value && shiftDate.value) {
    if (!selectedScenario.value) return;

    await ApiService.dispatch.removeAllAssignments(selectedAvailabilityRule.value.id, shiftDateStr.value, selectedScenario.value.id);
    await refreshView();
  }
}

async function invokeSelectedTasksDispatchRemoval() {
  if (selectedAvailabilityRule.value && shiftDate.value && selectedTaskIds.value.size > 0) {
    if (!selectedScenario.value) return;
    await ApiService.dispatch.removeTaskAssignments(selectedAvailabilityRule.value.id, shiftDateStr.value, Array.from(selectedTaskIds.value), selectedScenario.value.id);
    await refreshView();
  }
}

async function refreshView() {
  await loadResourceTasks();
  await loadShiftPersonnel();
}
</script>

<template>
  <v-card elevation="0" class="flexcard fill-height">
    <v-card-title>
      <breadcrumbs>{{ $t("personnelDispatch-view-personnelDispatch-title", { $: "Personnel Dispatch" }) }}</breadcrumbs>
    </v-card-title>
    <v-card-text class="fill-height">
      <v-row class="fill-height">
        <v-col cols="12" class="d-flex flex-column">
          <div class="position-relative pb-4">
            <div class="d-flex flex-row">
              <v-row>
                <v-col cols="4">
                  <resource-picker v-model="selectedResource" :resource-types="resourceTypes" />
                </v-col>

                <v-col cols="4">
                  <v-combobox
                    v-model="selectedAvailabilityRule"
                    label="Shift"
                    variant="outlined"
                    density="compact"
                    hide-details="auto"
                    :items="availabilityRules"
                    item-title="businessId"
                  ></v-combobox>
                </v-col>
                <v-col cols="4">
                  <datepicker-field
                    v-model="shiftDate"
                    :clearable="false"
                    :format="formatDate"
                    :locale="locale"
                    teleport="body"
                    :enable-time-picker="false"
                    :placeholder="$t('dispatch-PersonnelDispatch-shiftDate-label', { $: 'Shift Date' })"
                    :label="$t('dispatch-PersonnelDispatch-shiftDate-label', { $: 'Shift Date' })"
                  >
                    <template #input-icon>
                      <v-icon class="pt-2">mdi-calendar</v-icon>
                    </template>
                  </datepicker-field>
                </v-col>
              </v-row>
            </div>
            <div class="d-flex flex-row mt-4">
              <v-tooltip location="bottom" open-delay="300">
                <template #activator="{ props }">
                  <div class="d-inline-flex pr-4">
                    <v-btn size="small" variant="text" density="compact" v-bind="props" :disabled="manualDisabled" @click="invokeManualDispatch">
                      <v-icon icon="mdi-arrow-right-bold" class="pr-4" />
                      {{ $t("dispatch-personnelDispatching-assignPersonnel-action", { $: "Assign Personnel" }) }}
                    </v-btn>
                  </div>
                </template>
                <span>{{ $t("dispatch-personnelDispatching-assignPersonnel-tooltip", { $: "Assign Personnel" }) }}</span>
              </v-tooltip>
              <v-tooltip location="bottom" open-delay="300">
                <template #activator="{ props }">
                  <div class="d-inline-flex pr-4">
                    <v-btn size="small" variant="text" density="compact" v-bind="props" :disabled="autoDisabled" @click="invokeAutoDispatch">
                      <v-icon icon="mdi-arrow-decision" class="pr-4" />
                      {{ $t("dispatch-personnelDispatching-automatedDispatching-action", { $: "Automated Dispatching" }) }}
                    </v-btn>
                  </div>
                </template>
                <span>{{ $t("dispatch-personnelDispatching-automatedDispatching-tooltip", { $: "Automated Dispatching" }) }}</span>
              </v-tooltip>
              <v-tooltip location="bottom" open-delay="300">
                <template #activator="{ props }">
                  <div class="d-inline-flex pr-4">
                    <v-btn size="small" variant="text" density="compact" v-bind="props" :disabled="removeDisabled" @click="invokeSelectedTasksDispatchRemoval">
                      <v-icon icon="mdi-arrow-left-bold" class="pr-4" />
                      {{ $t("dispatch-personnelDispatching-deassignPersonnel-action", { $: "De-assign Personnel" }) }}
                    </v-btn>
                  </div>
                </template>
                <span>{{ $t("dispatch-personnelDispatching-deassignPersonnel-action", { $: "De-assign Personnel" }) }}</span>
              </v-tooltip>
            </div>
          </div>
          <v-row class="flex-grow-1">
            <v-col cols="6">
              <ShiftPersonnel :personnel="personnel" :available-personnel="availablePersonnel" :selected-ids="selectedPersonnel" />
            </v-col>
            <v-col cols="6">
              <ShiftTasks :specs="specs" :selected-spec-ids="selectedSpecIds" :selected-task-ids="selectedTaskIds" />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
