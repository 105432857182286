<script lang="ts" setup>
import GridWrapper from "@/components/Grid/GridWrapper.vue";
import { computed, inject, onMounted, reactive, ref, watch } from "vue";
import { GridWrapperComponent } from "@/components/Grid/GridWrapperComponent";
import { GetRowIdParams, KeyCreatorParams, ValueFormatterParams } from "ag-grid-community";
import { storeToRefs } from "pinia";
import { useSnackbarsStore } from "@/store/SnackbarsStore";
import { useScenariosStore } from "@/store/ScenariosStore";
import { $t } from "@/i18n";
import { translateTemplateStatus } from "@/composables/translateEnum";
import { getProductTemplateStatusCellStyle } from "@/components/Tasks/TaskUtils";
import { $dateTimeFormatterSymbol, DateFormatter } from "@masta/shared";
import { useTagsStore } from "@/store/TagsStore";
import { DocumentUpdatedNotificationEvent, ModelInstanceDto, ModelInstancesChangedNotificationEvent, ModelSchemaDto, TemplateStatus } from "@masta/generated-model";
import { DocumentUpdatedNotification, ModelInstancesChangedNotification, useNotification } from "@/notifications";
import { enumToEditorEntries, enumValueEntryWithLocaleComparator, translateEditorEntries } from "@/components/Grid/ColumnTypes";
import { joinArrayOfStrings } from "@/components/ValueCellEditor/CommonFormatters";
import { tagsTypeColumnFilterParams } from "@/components/Grid/Filters/TagsTypeColumnFilters";
import ActionsButton from "@/components/Layout/ActionsButton.vue";
import { ModelInstancesServerSideDataSource } from "@/components/ModelInstances/ModelInstancesServerSideDataSource";
import { useModelInstancesStore } from "@/store/ModelInstancesStore";
import { getSelectedRows } from "@/components/Grid/UseGridSelection";

const props = defineProps<{
  schema: ModelSchemaDto;
}>();
const emit = defineEmits<{
  (e: "instanceSelected", instance: ModelInstanceDto | null): void;
  (e: "detail", instance: ModelInstanceDto): void;
  (e: "create"): void;
  (e: "release", instance: ModelInstanceDto): void;
  (e: "archive", instance: ModelInstanceDto): void;
  (e: "newRevision", instance: ModelInstanceDto): void;
  (e: "copy", instance: ModelInstanceDto): void;
  (e: "delete", instance: ModelInstanceDto): void;
}>();

const schemaId = computed(() => (props.schema ? `${props.schema.schemaKey}.${props.schema.schemaName}` : undefined));
const schemaVersion = computed(() => props.schema?.version);
const serverSideDataSource = reactive<ModelInstancesServerSideDataSource>(new ModelInstancesServerSideDataSource("model-instances", schemaId.value, schemaVersion.value));
const $dateTimeFormatter = inject<DateFormatter>($dateTimeFormatterSymbol)!;
const selectedModelInstance = ref<ModelInstanceDto | null>(null);
const tagStore = useTagsStore();
const snackbarsStore = useSnackbarsStore();
const scenariosStore = useScenariosStore();
const miStore = useModelInstancesStore();
const gridWrapperRef = ref<GridWrapperComponent>();
const { selectedScenario } = storeToRefs(scenariosStore);
defineExpose({ gridWrapperRef });

watch(props, () => {
  serverSideDataSource.useFilteringBySchema(schemaId.value, schemaVersion.value);
  gridWrapperRef.value.gridApi.refreshServerSide({ purge: true });
});

onMounted(async () => {
  await tagStore.fetch();
});

const copySupported = computed(() => {
  if (props.schema?.schemaKey === "model.schema.json.documentDefinition" && props.schema?.schemaName === "attachment") return false;
  return true;
});

const defaultColumnDef = ref({
  floatingFilter: true,
  filterParams: {
    applyMiniFilterWhileTyping: true
  }
});

const basicColumnDefs = ref([
  {
    field: "businessId",
    type: ["textFloatingFilterColumnType"],
    editable: false,
    resizable: true,
    sortable: true,
    filter: "agTextColumnFilter",
    headerValueGetter: (_: any) => $t("schemaInstance-list-businessId-label", { $: "BusinessId" }),
    floatingFilterComponentParams: {
      placeholder: $t("schemaInstance-list-businessId-label", { $: "BusinessId" })
    }
  },
  {
    field: "revisionNumber",
    type: ["textFloatingFilterColumnType"],
    editable: false,
    resizable: true,
    sortable: true,
    filter: "agTextColumnFilter",
    headerValueGetter: (_: any) => $t("schemaInstance-list-revisionNumber-label", { $: "Revision" }),
    floatingFilterComponentParams: {
      placeholder: $t("schemaInstance-list-revisionNumber-label", { $: "Revision" })
    }
  },
  {
    field: "status",
    type: ["setFloatingFilterColumnType"],
    editable: false,
    resizable: true,
    sortable: true,
    valueFormatter: (params: any) => translateTemplateStatus(params.value),
    cellStyle: (params: any) => getProductTemplateStatusCellStyle(params.value),
    headerValueGetter: (_: any) => $t("modelInstance-list-status-label", { $: "Status" }),
    filter: "agSetColumnFilter",
    filterParams: {
      valueFormatter: (params: ValueFormatterParams) => params.value.key,
      keyCreator: (params: KeyCreatorParams) => {
        //this is for object from filterParams.values
        if (typeof params.value === "object") {
          return params.value?.value.toString();
        }

        //this is for field from row data
        if (typeof params.value === "number") {
          return params.value.toString();
        }

        return params.value;
      },
      values: translateEditorEntries(enumToEditorEntries(TemplateStatus), translateTemplateStatus),
      comparator: enumValueEntryWithLocaleComparator
    }
  },
  {
    field: "tags",
    type: ["tagsPickerTypeColumn", "setFloatingFilterColumnType"],
    headerValueGetter: (_: any) => $t("modelInstance-list-tags-label", { $: "Tags" }),
    filter: "agSetColumnFilter",
    filterParams: tagsTypeColumnFilterParams(tagStore),
    editable: false,
    resizable: true,
    valueFormatter: (params: ValueFormatterParams) => joinArrayOfStrings(params.data.tags),
    cellEditorParams: {
      placeholder: $t("modelInstance-edit-tags-label", { $: "Tags" })
    }
  },
  {
    field: "createdBy",
    type: ["textFloatingFilterColumnType"],
    editable: false,
    resizable: true,
    sortable: true,
    filter: "agTextColumnFilter",
    headerValueGetter: (_: any) => $t("modelInstance-list-createdBy-label", { $: "Created By" }),
    floatingFilterComponentParams: {
      placeholder: $t("modelInstance-list-createdBy-label", { $: "Created By" })
    }
  },
  {
    field: "createdAt",
    type: ["dateTimeTypeColumn"],
    editable: false,
    resizable: true,
    sortable: true,
    filter: "agDateColumnFilter",
    valueFormatter: (params: any) => {
      return $dateTimeFormatter(params.data.createdAt);
    },
    headerValueGetter: (_: any) => $t("modelInstance-list-createdAt-label", { $: "Created At" })
  },
  {
    field: "modifiedBy",
    type: ["textFloatingFilterColumnType"],
    editable: false,
    resizable: true,
    sortable: true,
    filter: "agTextColumnFilter",
    headerValueGetter: (_: any) => $t("modelInstance-list-modifiedBy-label", { $: "Modified By" }),
    floatingFilterComponentParams: {
      placeholder: $t("modelInstance-list-modifiedBy-label", { $: "Modified By" })
    }
  },
  {
    field: "modifiedAt",
    type: ["dateTimeTypeColumn"],
    editable: false,
    resizable: true,
    sortable: true,
    filter: "agDateColumnFilter",
    valueFormatter: (params: any) => {
      return $dateTimeFormatter(params.data.modifiedAt);
    },
    headerValueGetter: (_: any) => $t("modelInstance-list-modifiedAt-label", { $: "Modified At" })
  },
  {
    type: ["setFloatingFilterColumnType"],
    editable: false,
    resizable: true,
    sortable: true,
    filter: true,
    valueGetter: () => (selectedScenario.value ? selectedScenario.value.name : ""),
    headerValueGetter: (_: any) => $t("schemaInstance-list-scenario-label", { $: "Scenario" })
  }
]);

function onCreateAction() {
  emit("create");
}

function onRowDoubleClicked() {
  if (gridWrapperRef.value?.isEditing()) return;
  if (selectedModelInstance.value) {
    emit("detail", selectedModelInstance.value);
  }
}

function onReleaseModelInstance() {
  if (selectedModelInstance.value) {
    emit("release", selectedModelInstance.value);
  }
}

function onArchiveModelInstance() {
  if (selectedModelInstance.value) {
    emit("archive", selectedModelInstance.value);
  }
}

function onCreateNewRevisionOfModelInstance() {
  if (selectedModelInstance.value) {
    emit("newRevision", selectedModelInstance.value);
  }
}

function onCopyModelInstance() {
  if (selectedModelInstance.value) {
    emit("copy", selectedModelInstance.value);
  }
}

async function onDeleteAction() {
  const rows = getSelectedRows(gridWrapperRef.value?.gridApi) ?? [];
  for (const row of rows) {
    try {
      const mi = selectedModelInstance.value;
      await miStore.deleteInstance(row);
      await snackbarsStore.createSnackbar({
        message: $t("schemaInstance-list-deleted-message", { $: "Deleted" }),
        closeable: true
      });
      emit("delete", mi as ModelInstanceDto);
    } catch (e: any) {
      await snackbarsStore.createSnackbar({
        message: e.message,
        type: "error"
      });
    }
  }
  gridWrapperRef.value?.gridApi?.deselectAll();
}

function onPrepareColumns(_columnDefs: any) {
  _columnDefs.value = basicColumnDefs.value;
}

function onSelectionChanged() {
  const rows = getSelectedRows(gridWrapperRef.value?.gridApi) ?? [];
  selectedModelInstance.value = rows.length === 1 ? rows[0] : null;
  if (selectedModelInstance.value) {
    emit("instanceSelected", selectedModelInstance.value);
  }
}

useNotification(DocumentUpdatedNotification, (e: DocumentUpdatedNotificationEvent) => {
  //onDocumentChanged(e);
});

useNotification(ModelInstancesChangedNotification, (e: ModelInstancesChangedNotificationEvent) => {
  if (e.changes.some((x) => x.schemaId === schemaId.value && x.schemaVersion === schemaVersion.value)) {
    gridWrapperRef.value?.gridApi.refreshServerSide({ purge: true });
  }
});

const detailActions = ref([
  {
    title: $t("modelInstance-list-createNewVersion-action", { $: "1. Create New Version" }),
    tooltip: $t("modelInstance-list-createNewVersion-action-tooltip", { $: "Create New Version" }),
    action: onCreateNewRevisionOfModelInstance,
    icon: "mdi-ab-testing",
    disabled: () => !selectedModelInstance.value,
    order: 1
  },
  {
    title: $t("modelInstance-list-release-action", { $: "2. Release" }),
    tooltip: $t("modelInstance-list-release-action-tooltip", { $: "Release" }),
    action: onReleaseModelInstance,
    icon: "mdi-rocket-launch",
    disabled: () => !selectedModelInstance.value,
    order: 2
  },
  {
    title: $t("modelInstance-list-archive-action", { $: "3. Archive" }),
    tooltip: $t("modelInstance-list-archive-action-tooltip", { $: "Archive" }),
    action: onArchiveModelInstance,
    icon: "mdi-archive-arrow-down-outline",
    disabled: () => !selectedModelInstance.value,
    order: 3
  },

  {
    title: $t("modelInstance-list-copy-action", { $: "4. Copy" }),
    tooltip: $t("modelInstance-list-copy-action-tooltip", { $: "Copy" }),
    action: onCopyModelInstance,
    icon: "mdi-content-copy",
    disabled: () => !selectedModelInstance.value,
    order: 5
  }
]);

function getRowId(params: GetRowIdParams<ModelInstanceDto>) {
  return params.data ? `${params.data.id}#${params.data.revisionNumber}` : "";
}
</script>

<template>
  <grid-wrapper
    ref="gridWrapperRef"
    refresh-btn
    create-btn
    duplicate-btn
    delete-btn
    identifier="model-instances"
    :create-btn-disabled="!schema"
    row-selection="single"
    :default-col-def="defaultColumnDef"
    :get-row-id="getRowId"
    :server-side-datasource="serverSideDataSource"
    :server-side="true"
    custom-create-action
    custom-duplicate-action
    @create-action="onCreateAction"
    @duplicate-action="onCopyModelInstance"
    @row-double-clicked="onRowDoubleClicked"
    @delete-action="onDeleteAction"
    @prepare-columns="onPrepareColumns"
    @selection-changed="onSelectionChanged"
  >
    <template #custom-buttons>
      <div class="d-inline-flex pr-4">
        <actions-button :model-value="detailActions"></actions-button>
      </div>
    </template>
  </grid-wrapper>
</template>

<style lang="scss" scoped></style>
