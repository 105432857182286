import { GuiModelItem, GuiObjectType } from "./GuiModelItem";

export class GuiObjectModel implements GuiModelItem {
  public items: GuiModelItem[];
  public readonly type = GuiObjectType.Object;

  constructor(public name: string) {
    this.items = [];
  }

  public add(item: GuiModelItem) {
    this.items.push(item);
  }

  getValue(): any {
    const result: Record<string, any> = {};
    for (const item of this.items) {
      result[item.name] = item.getValue();
    }
    return result;
  }

  isValid(): boolean {
    return this.items.every((x) => x.isValid());
  }

  setValue(value: any): void {
    if (typeof value !== "object" || Array.isArray(value) || value === null) {
      console.error("Setting non-object value to object", value);
      throw new Error("Setting non-object value to object");
    }
    for (const valueKey in value) {
      const item = this.items.find((x) => x.name === valueKey);
      if (!item) {
        throw new Error(`Cannot find property ${valueKey}`);
      }
      item.setValue(value[valueKey]);
    }
  }
}
