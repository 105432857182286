<script lang="ts" setup>
import { $t } from "@/i18n";
import { ref } from "vue";
import { useSystemStore } from "@/store/SystemStore";

interface SchedulingSetting {
  name: string;
  label: string;
}

const props = defineProps<{
  modelValue: boolean;
}>();
const emit = defineEmits<{
  (e: "update:modelValue", value: boolean): void;
  (e: "settingSelected", setting: string): void;
}>();
const systemStore = useSystemStore();

// $t("planning-schedulingSettings-EarliestDate-label", { $: "Earliest Date" })
// $t("planning-schedulingSettings-OrderConsolidatedScheduling-label", { $: "Order-Consolidated Scheduling" })
// $t("planning-schedulingSettings-ExactDate-label", { $: "Exact Date" })
// $t("planning-schedulingSettings-TaskPriority-label", { $: "Task Priority" })
// $t("planning-schedulingSettings-ResourcePriority-label", { $: "Resource Priority" })
const settings = ref<SchedulingSetting[]>([]);
for (const settingName of systemStore.schedulingSettingNames) {
  const label = $t(`planning-schedulingSettings-${settingName}-label`);
  settings.value.push({ name: settingName, label });
}

const selectedSetting = ref<SchedulingSetting>();

function cancel() {
  selectedSetting.value = undefined;
  setModal(false);
}

function schedule() {
  setModal(false);
  emit("settingSelected", selectedSetting.value.name);
  selectedSetting.value = undefined;
}

function setModal(visible: boolean) {
  emit("update:modelValue", visible);
}
</script>

<template>
  <v-dialog :model-value="modelValue" width="auto" @update:model-value="setModal($event)">
    <v-card class="pa-8">
      <v-card-title class="d-flex align-center justify-space-between">
        <div class="text-h4">{{ $t("scheduling-scheduleTaskDialog-title-label", { $: "Schedule" }) }}</div>
        <v-icon @click="cancel">mdi-close</v-icon>
      </v-card-title>
      <v-card-text>
        <div class="text-subtitle-1">{{ $t("scheduling-scheduleTaskDialog-setting-label", { $: "Setting:" }) }}</div>
        <v-chip-group v-model="selectedSetting" mandatory class="d-flex justify-center">
          <v-chip
            v-for="setting in settings"
            :key="setting.name"
            :value="setting"
            :class="setting.name == selectedSetting?.name ? 'bg-primary' : 'bg-indigo-lighten-5 text-primary'"
            size="large"
          >
            {{ setting.label }}
          </v-chip>
        </v-chip-group>
      </v-card-text>
      <v-card-actions>
        <v-row>
          <v-col cols="6">
            <v-btn class="ma-4" variant="elevated" color="secondary" block @click="cancel">
              {{ $t("scheduling-scheduleTaskDialog-cancel-action", { $: "Cancel" }) }}
            </v-btn>
          </v-col>
          <v-col cols="6">
            <v-btn class="ma-4" variant="elevated" :color="selectedSetting ? 'primary' : 'grey'" block :disabled="!selectedSetting" @click="schedule">
              {{ $t("scheduling-scheduleTaskDialog-schedule-action", { $: "Schedule" }) }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
