import { IActivityObject, IRow, MutableActivityBase, Row } from "@masta/gantt2/core";
import { ExtendedGanttResourceDto, ResourceCapacityActivity } from "@/components/Gantt/Common/Model/ResourceRow";
import { ProductionTaskDto, StepDto } from "@masta/generated-model";
import { Instant } from "@js-joda/core";
import { v4 as uuid } from "uuid";

export interface ExtendedTaskDto extends Omit<ProductionTaskDto, "name">, IRow {
  type: string;
}

export interface ActivityTaskDto extends Omit<ProductionTaskDto, "name">, IActivityObject {
  type: string;
  taskId: string;
  step: StepDto;
  schedulingStartDateFormatted?: string;
  schedulingEndDateFormatted?: string;
  schedulingStartDateTimeFormatted?: string;
  schedulingEndDateTimeFormatted?: string;
  schedulingDurationFormatted?: string;
  executionStartDateFormatted?: string;
  executionEndDateFormatted?: string;
  executionStartDateTimeFormatted?: string;
  executionEndDateTimeFormatted?: string;
  executionDurationFormatted?: string;
}

export function toSchedulingExtendedTaskDtos(type: string, data: ProductionTaskDto): ActivityTaskDto[] {
  const name = data.name!;
  return [
    {
      ...data,
      id: uuid(),
      taskId: data.id,
      type,
      name: `${name}`,
      step: null!,
      startTime: data.statistics!.schedulingStartMillis!,
      endTime: data.statistics!.schedulingEndMillis!
    }
  ];
}

export function toExecutionExtendedTaskDtos(type: string, data: ProductionTaskDto): ActivityTaskDto[] {
  const name = data.name!;
  return [
    {
      ...data,
      id: uuid(),
      taskId: data.id,
      type,
      name: `${name}`,
      step: null!,
      startTime: data.statistics!.executionStartMillis!,
      endTime: data.statistics!.executionEndMillis!
    }
  ];
}

export class TaskSchedulingActivity extends MutableActivityBase<ActivityTaskDto> {
  constructor(data: ActivityTaskDto) {
    super(data.id, data.name, Instant.ofEpochMilli(data.statistics!.schedulingStartMillis!), Instant.ofEpochMilli(data.statistics!.schedulingEndMillis!));
    this._id = data.id;
    this._userObject = data;
  }

  toJSON<TActivityObject extends IActivityObject>(): TActivityObject {
    return {
      ...this._userObject,
      name: this._name,
      startTime: this._startTime.toEpochMilli(),
      endTime: this._endTime.toEpochMilli()
    } as unknown as TActivityObject;
  }
}

export class TaskExecutionActivity extends MutableActivityBase<ActivityTaskDto> {
  constructor(data: ActivityTaskDto) {
    super(data.id, data.name, Instant.ofEpochMilli(data.statistics!.executionStartMillis!), Instant.ofEpochMilli(data.statistics!.executionEndMillis!));
    this._id = data.id;
    this._userObject = data;
  }

  toJSON<TActivityObject extends IActivityObject>(): TActivityObject {
    return {
      ...this._userObject,
      name: this._name,
      startTime: this._startTime.toEpochMilli(),
      endTime: this._endTime.toEpochMilli()
    } as unknown as TActivityObject;
  }
}

export class TaskRow extends Row<TaskRow, TaskRow, ResourceCapacityActivity> {
  constructor(resource: ExtendedGanttResourceDto, defaultHeight: number = 80) {
    super(resource);
    this.id = resource.id;
    if (!resource.name) {
      this.name = resource.businessId ?? resource.id;
    }
    this.addProperty("color", resource.color);
    this.addProperty("type", resource.type);
    this.defaultHeight = defaultHeight;
    this.height = defaultHeight;
  }
}
