﻿import { ApplicationStatusSettings, StepStatusSettings } from "@masta/generated-model";
import { inject, injectable } from "inversify";
import { GanttSettings, Lifecycle } from "@masta/gantt2/core";
import { CustomSettingKeys } from "@/components/Gantt/ResourcesGantt/CustomSettingKeys";

@injectable()
export class StatusColorHelper extends Lifecycle {
  private _statusSettings: ApplicationStatusSettings | undefined;
  private _stepStatusSettings: StepStatusSettings | undefined;

  constructor(@inject(GanttSettings) private _gantSettings: GanttSettings) {
    super();
  }

  async afterInitialize(): Promise<void> {
    await super.afterInitialize();

    this.subscribe(
      this._gantSettings.getSetting$<ApplicationStatusSettings>(CustomSettingKeys.ACTIVITY_STATUS_SETTINGS).subscribe((settings) => {
        this._statusSettings = settings;
      })
    );

    this.subscribe(
      this._gantSettings.getSetting$<StepStatusSettings>(CustomSettingKeys.STEP_STATUS_COLOR_SETTINGS).subscribe((settings) => {
        this._stepStatusSettings = settings;
      })
    );
  }

  public getStatusBackgroundColor(status: number): string {
    return this._statusSettings?.backgroundColors?.find((x) => x.status == status)?.color ?? "";
  }

  public getStatusTextColor(status: number): string {
    return this._statusSettings?.textColors?.find((x) => x.status == status)?.color ?? "";
  }

  public getStepStatusBackgroundColor(status: number): string {
    return this._stepStatusSettings?.backgroundColors?.find((x) => x.status == status)?.color ?? "";
  }

  public getStepStatusTextColor(status: number): string {
    return this._stepStatusSettings?.textColors?.find((x) => x.status == status)?.color ?? "";
  }
}
