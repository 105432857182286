import { inject, injectable } from "inversify";
import { LayerCanvas, ViewportPointerEventNoneScrollGlass } from "@masta/gantt2/gantt";
import { GanttEvents, GanttSettings, GraphicsMousePositionEvent, PaddingInsets, SettingKey, TimelineManager } from "@masta/gantt2/core";
import { RowDropZone } from "@/components/Gantt/ResourcesGantt/DragAndDrop/RowDropZone";
import { withLatestFrom } from "rxjs";


@injectable()
export class DropAreasLayer extends LayerCanvas {

  private _rowDropZones: RowDropZone[] = [];
  private _mousePosition: GraphicsMousePositionEvent | undefined;
  private _rowPadding: PaddingInsets;

  constructor(
    @inject(TimelineManager) timelineManager: TimelineManager,
    @inject(ViewportPointerEventNoneScrollGlass) private _glassLayer: ViewportPointerEventNoneScrollGlass,
    @inject(GanttSettings) protected readonly _settings: GanttSettings,
    @inject(GanttEvents) ganttEvents: GanttEvents
  ) {
    super(timelineManager, ganttEvents, DropAreasLayer.name, "drop-areas-layer");
    this.visible = false;
  }

  async afterInitialize(): Promise<void> {
    await super.afterInitialize();

    this._rowPadding = this._settings.getSetting<PaddingInsets>(SettingKey.ROW_PADDING)!;

    const movePixels = 10;

    this.subscribe(
      this._ganttEvents.mousePositionEvent$.pipe(withLatestFrom(this._ganttEvents.rowHoverEvent$)).subscribe(async ([mousePositionEvent, rowHoverEvent]) => {
        if (!this.visible) {
          return;
        }

        const width = this._glassLayer.element.offsetWidth;

        // check if x position of mouse is in first 10% of the 'width'
        if (mousePositionEvent.x < width * 0.05) {
          const millisPerClick = this._timelineManager.millisPerPixel * movePixels;
          await this._timelineManager.setStartTime(this._timelineManager.startTime.minusMillis(millisPerClick));
          this._rowDropZones.forEach(x => x.recalculateChartDataPosition());
        }

        // check if x position of mouse is in last 10% of the 'width'
        if (mousePositionEvent.x > width * 0.95) {
          const millisPerClick = this._timelineManager.millisPerPixel * movePixels;
          await this._timelineManager.setStartTime(this._timelineManager.startTime.plusMillis(millisPerClick));
          this._rowDropZones.forEach(x => x.recalculateChartDataPosition());
        }

        this._mousePosition = mousePositionEvent;
        this.batchDraw();
      })
    );
  }


  set rowDropZones(value: RowDropZone[]) {
    this._rowDropZones = value;
    this.visible = value.length > 0;
  }

  public async doDrawFromBatch() {
    if (!this.visible) {
      this.clear();
      return;
    }

    this.clear();

    for (const rowDropZone of this._rowDropZones) {
      this.drawDropAreasForRow(rowDropZone);
    }
  }

  private drawDropAreasForRow(rowDropZone: RowDropZone) {
    const { chartData, container } = rowDropZone;
    const nowTime = this._timelineManager.nowTime.toEpochMilli();
    const startX = Math.round(this._timelineManager.calculateLocationForTimeMillis(this._timelineManager.startTime.toEpochMilli()));
    const endX = Math.round(this._timelineManager.calculateLocationForTimeMillis(this._timelineManager.endTime.toEpochMilli()));

    const lineWidth = 1;

    this.context.save();
    const green = "#4CAF50";
    const orange = "rgb(255,165,0)";
    const red = "#bb333380";
    this.context.strokeStyle = rowDropZone.earliestViableDatePosX ? red : green;
    this.context.lineWidth = lineWidth;
    this.context.setLineDash([5, 2]);

    const height = container.row.height - (this._rowPadding.top + this._rowPadding.bottom);
    const y = container.element.offsetTop + this._rowPadding.top;

    let _visibleChartData = chartData
      .filter((x, i) => {
        const left = chartData[i - 1];
        const right = chartData[i + 1];
        return (x.xPos < startX && right?.xPos >= startX) || (x.xPos > startX && x.xPos <= endX) || (x.xPos > endX && left?.xPos <= endX);
      });
    _visibleChartData = _visibleChartData
      .filter((x, i) => {
        const right = _visibleChartData[i + 1];
        return x.x >= nowTime || (x.x < nowTime && right?.x >= nowTime);
      });

    for (const [index, current] of _visibleChartData.entries()) {

      const right = _visibleChartData[index + 1];

      if (!right) break;

      if (current.y > 0 && current.xPos !== right.xPos) {
        const x = current.x < nowTime ? this._timelineManager.calculateLocationForTimeMillis(nowTime) : current.xPos;
        const width = right.xPos > endX + lineWidth ? endX - x : right.xPos - x;
        if (rowDropZone.initialViableDatePosX && rowDropZone.earliestViableDatePosX) {

          const viableStartX = rowDropZone.initialViableDatePosX < rowDropZone.earliestViableDatePosX ? rowDropZone.initialViableDatePosX : rowDropZone.earliestViableDatePosX;
          const viableEndX = rowDropZone.initialViableDatePosX < rowDropZone.earliestViableDatePosX ? rowDropZone.earliestViableDatePosX : rowDropZone.initialViableDatePosX;

          if (viableStartX > x + width) {
            this.context.strokeStyle = red;
            this.context.strokeRect(x, y, width, height);
          } else if (viableEndX < x) {
            this.context.strokeStyle = green;
            this.context.strokeRect(x, y, width, height);
          } else {
            // if the viable start and end are this same
            if (viableStartX === viableEndX) {
              this.context.strokeStyle = red;
              this.context.strokeRect(x, y, viableStartX - x, height);

              this.context.strokeStyle = green;
              this.context.strokeRect(viableStartX, y, x + width - viableStartX, height);
            }
            // if the viable start and end are within the current x and width
            else if (x <= viableStartX && x + width >= viableEndX) {
              this.context.strokeStyle = red;
              this.context.strokeRect(x, y, viableStartX - x, height);

              this.context.strokeStyle = orange;
              this.context.strokeRect(viableStartX, y, viableEndX - viableStartX, height);

              this.context.strokeStyle = green;
              this.context.strokeRect(viableEndX, y, x + width - viableEndX, height);
            }
            // if the viable start is within the current x and width
            else if (x <= viableStartX && x + width < viableEndX) {
              this.context.strokeStyle = red;
              this.context.strokeRect(x, y, viableStartX - x, height);

              this.context.strokeStyle = orange;
              this.context.strokeRect(viableStartX, y, x + width - viableStartX, height);
            }
            // if the viable end is within the current x and width
            else if (x > viableStartX && x + width >= viableEndX) {
              this.context.strokeStyle = orange;
              this.context.strokeRect(x, y, viableEndX - x, height);

              this.context.strokeStyle = green;
              this.context.strokeRect(viableEndX, y, x + width - viableEndX, height);
            }
          }
        } else {
          this.context.strokeRect(x, y, width, height);
        }
      }
    }
    this.context.restore();
  }
}
