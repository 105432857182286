import { ActivityTooltipRenderer } from "./ActivityTooltipRenderer";
import { Duration, Instant } from "@js-joda/core";
import { injectable } from "inversify";

@injectable()
export class DefaultActivityTooltipRenderer extends ActivityTooltipRenderer {
  constructor() {
    super(DefaultActivityTooltipRenderer.name, "default-activity-tooltip");
  }

  async doDrawFromBatch(): Promise<void> {
    await super.doDrawFromBatch();
    if (this.visible) {
      this.element.innerHTML = `
<table>
  <tbody>
    <tr>
      <td>Name:</td>
      <td>${this._activity.activity.name}</td>
    </tr>
    <tr>
      <td>Start:</td>
      <td>${this._activity.activity.startTime}</td>
    </tr>
    <tr>
      <td>End:</td>
      <td>${this._activity.activity.endTime}</td>
    </tr>
    <tr>
      <td>Duration:</td>
      <td>${Duration.between(Instant.ofEpochMilli(this._activity.activity.startTime), Instant.ofEpochMilli(this._activity.activity.endTime))}</td>
    </tr>
  </tbody>
</table>
      `;
    } else {
      this.element.innerHTML = "";
    }
  }
}
