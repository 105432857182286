import { ApiClient } from "@/services/api/common.api";
import { AppContextDto } from "@masta/generated-model";

export default {
  async getAppContext() {
    return await ApiClient.get<AppContextDto>("/AppContext");
  },
  async getApiVersion() {
    return await ApiClient.get("/version", {
      withoutAuthorization: true
    });
  },
  async getHealthStatus() {
    return ApiClient.get("/health", {
      withoutAuthorization: true
    });
  }
};
