import { GanttEvents, IocSymbols, TimelineManager } from "../Core";
import type { IGanttWorker } from "../Core";
import { inject, injectable } from "inversify";

@injectable()
export class WorkerSynchronizationTimelineManager extends TimelineManager {
  constructor(@inject(GanttEvents) ganttEvents: GanttEvents, @inject(IocSymbols.GanttWorkerSymbol) private _ganttWorker: IGanttWorker) {
    super(ganttEvents);
  }

  protected async emitTimelineRefreshEvent() {
    await this._ganttWorker.synchronizeTimeline(this.getSynchronizationModel());
    await super.emitTimelineRefreshEvent();
  }
}
