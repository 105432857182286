<template>
  <master-detail-layout :detail="detail" detail-dialog-width="75%" @change="detail = false">
    <template #master>
      <v-card elevation="0" class="flexcard fill-height">
        <v-card-title>
          <breadcrumbs>{{ $t("setting-view-settings-title", { $: "Settings" }) }}</breadcrumbs>
        </v-card-title>
        <v-card-text class="fill-height">
          <settings-grid ref="settingsGridRef" @details="detail = true" @row-selected="onRowSelected" />
        </v-card-text>
      </v-card>
    </template>
    <template #detail>
      <settings-dialog :value="selectedRow" :edit-mode="selectedRow !== null" @save="saveSetting" @update="updateSetting" @cancel="detail = false" />
    </template>
  </master-detail-layout>
</template>

<script>
import MasterDetailLayout from "@/components/Layout/MasterDetailLayout.vue";
import SettingsDialog from "@/components/Settings/SettingsDialog.vue";
import SettingsGrid from "@/components/Settings/SettingsGrid";
import { mapActions, mapState } from "pinia";
import { useSettingsStore } from "@/store/SettingsStore";
import Breadcrumbs from "@/components/Layout/Breadcrumbs.vue";

export default {
  components: {
    Breadcrumbs,
    SettingsGrid,
    SettingsDialog,
    MasterDetailLayout
  },
  title: "Settings",
  data: () => ({
    detail: false,
    selectedRow: null
  }),
  computed: {
    ...mapState(useSettingsStore, ["settings"])
  },
  methods: {
    ...mapActions(useSettingsStore, ["create", "update"]),
    onRowSelected(row) {
      this.selectedRow = row;
    },
    async saveSetting({ setting, onSaved }) {
      await this.create(setting);
      if (onSaved) {
        onSaved();
      }
      this.detail = false;
    },
    async updateSetting({ setting, onUpdated }) {
      await this.update({
        ...this.selectedRow,
        value: setting.value
      });

      if (onUpdated) {
        onUpdated();
      }

      this.detail = false;
      this.selectedRow = this.settings.find((x) => x.id === this.selectedRow.id);
    }
  }
};
</script>

<style lang="scss" scoped></style>
