import { inject, injectable } from "inversify";
import { ActivityRenderer, GanttSettings, IActivityRenderingRequest, IocSymbols, type IRowContainer, TimelineManager } from "@masta/gantt2/core";
import { GanttActivityColorsSetting, GanttActivityDisplayPropertySetting, SchedulingResponseStatus } from "@masta/generated-model";
import { CustomSettingKeys } from "@/components/Gantt/ResourcesGantt/CustomSettingKeys";
import { isDefined } from "@vueuse/core";
import { SchedulingOperationGhostActivity } from "@/components/Gantt/ResourcesGantt/DragAndDrop/SchedulingOperationGhostActivity";
import { IDraggableTaskDto } from "@/components/Gantt/ResourcesGantt/DragAndDrop/IDraggableTaskDto";

const FIRST_LINE_GROUP_INDEX = 0;
const SECOND_LINE_GROUP_INDEX = 1;
const THIRD_LINE_GROUP_INDEX = 2;

@injectable()
export class SchedulingOperationGhostActivityRenderer extends ActivityRenderer<SchedulingOperationGhostActivity> {

  private _startDateColor: string = "rgba(0,0,0,1)";
  private _endDateColor: string = "rgba(0,0,0,1)";
  private _groupedProperties: { [group: number]: GanttActivityDisplayPropertySetting[] } = {};

  constructor(@inject(TimelineManager) timelineManager: TimelineManager,
              @inject(IocSymbols.RowContainer) rowContainer: IRowContainer,
              @inject(GanttSettings) protected readonly _settings: GanttSettings) {
    super(timelineManager, rowContainer, SchedulingOperationGhostActivityRenderer.name);
    this.paddingInsets.left = 4;
    this.paddingInsets.right = 4;
    this._minWidth = 0;
  }

  async afterInitialize(): Promise<void> {
    await super.afterInitialize();

    // IMPORTANT: ignore cache to handle proper status change during animation
    this._ignoreCache = true;

    const fillSetting = this._settings.getSetting<GanttActivityColorsSetting>(CustomSettingKeys.ACTIVITY_FILL_COLOR_SCHEDULING);
    const textColorSetting = this._settings.getSetting<GanttActivityColorsSetting>(CustomSettingKeys.ACTIVITY_TEXT_COLOR_SCHEDULING);
    const displayedPropertiesSetting = this._settings.getSetting<GanttActivityDisplayPropertySetting[]>(CustomSettingKeys.ACTIVITY_DISPLAYED_PROPERTIES);

    if (fillSetting) {
      this.fill = fillSetting.default;
      this.fillHighlight = fillSetting.highlight;
      this.fillSelected = fillSetting.selected;
      this.fillHover = fillSetting.hover;
      this.fillPressed = fillSetting.pressed;
    }
    if (textColorSetting) {
      this.textColor = textColorSetting.default;
      this.textColorHighlight = textColorSetting.highlight;
      this.textColorSelected = textColorSetting.selected;
      this.textColorHover = textColorSetting.hover;
      this.textColorPressed = textColorSetting.pressed;
      this._startDateColor = textColorSetting.startDate ?? this._startDateColor;
      this._endDateColor = textColorSetting.endDate ?? this._endDateColor;
    }
    if (displayedPropertiesSetting) {
      displayedPropertiesSetting
        .filter((property) => property.displayed)
        .forEach((property) => {
          if (isDefined(property.displayGroup)) {
            if (!this._groupedProperties[property.displayGroup]) {
              this._groupedProperties[property.displayGroup] = [];
            }
            this._groupedProperties[property.displayGroup].push(property);
            this._groupedProperties[property.displayGroup].sort((propertyA, propertyB) => (propertyA.displayOrder ?? 0) - (propertyB.displayOrder ?? 0));
          }
        });
    }
  }

  public drawActivity(request: IActivityRenderingRequest<SchedulingOperationGhostActivity>): void {
    const { activityBounds, activityRef, position, canvas, context, x, y, h, offsetTop, selected, hover, highlighted, pressed } = request;
    let { w } = request;

    context.clearRect(0, 0, context.canvas.width, context.canvas.height);

    let font = "normal 10px Roboto";
    context.font = font;
    (context as any).letterSpacing = "0.3px";

    const status = activityRef.activity.userObject.status;
    if (status === SchedulingResponseStatus.Error || status === SchedulingResponseStatus.Failure) {
      this.fill = `rgba(255,0,0,${this.opacity})`;
    } else if (status === SchedulingResponseStatus.Warning) {
      this.fill = `rgba(255,255,0,${this.opacity})`;
    } else if (status === SchedulingResponseStatus.Success) {
      this.fill = `rgba(0,225,0,${this.opacity})`;
    } else {
      this.fill = `rgba(255,255,0,${this.opacity})`;
    }

    this.drawBackground(activityRef, position, context, x, y, w, h, offsetTop, selected, hover, highlighted, pressed);

    if (w < this._minWidth) w = this._minWidth;
    context.fillStyle = this.getTextColor(selected, hover, highlighted, pressed) ?? "rgba(0,0,0,1)";

    const startDate = activityRef.activity.userObject.startTimeFormatted ?? activityRef.activity.startTime.toJSON();
    const endDate = activityRef.activity.userObject.endTimeFormatted ?? activityRef.activity.endTime.toJSON();

    let fsize = this.getFontSize(context.font);
    if (h / 2 < fsize) {
      fsize = h / 2;
    }

    const startDateTextWidth = context.measureText(startDate).width;
    const endDateTextWidth = context.measureText(endDate).width;

    const showStartDateEndDateLine = startDateTextWidth + endDateTextWidth + this.paddingInsets.left + this.paddingInsets.right < w;
    const startEndDateLineTextY = y + h / 1.3 - fsize;

    if (showStartDateEndDateLine) {
      context.save();
      context.textAlign = "left";
      font = font.replace(/\d+px/, `${fsize}px`);
      context.font = font;
      const textY = startEndDateLineTextY;
      let textX = x + this.paddingInsets.left;
      context.fillStyle = this._startDateColor ?? context.fillStyle;
      context.fillText(startDate, textX, textY);
      textX = x + w - endDateTextWidth - this.paddingInsets.right;
      context.fillStyle = this._endDateColor ?? context.fillStyle;
      context.fillText(endDate, textX, textY);
      context.restore();
    }

    const task = activityRef.activity.userObject.draggableTaskDto;
    const statusBarHeight = Math.round(h / 8);

    if (task) {
      const firstLineText = this.getLineText(this._groupedProperties[FIRST_LINE_GROUP_INDEX], activityRef.activity.userObject.draggableTaskDto);
      const firstLineTextLines = this.getTextLines(context, firstLineText, w);

      if (w > 50) {
        // draw first line
        const firstLineX = x + w / 2;

        context.font = font.replace("normal", "bold");
        context.textAlign = "center";

        for (let i = 0; i < firstLineTextLines.length; i++) {
          const line = firstLineTextLines[i];
          const lineY = y + fsize + i * fsize;
          context.fillText(line, firstLineX, lineY);
        }

        context.font = font;

        // draw second line
        const secondLineText = this.getLineText(this._groupedProperties[SECOND_LINE_GROUP_INDEX], activityRef.activity.userObject.draggableTaskDto);
        const secondLineTextLines = this.getTextLines(context, secondLineText, w);

        const secondLineTextX = x + w / 2;

        for (let i = 0; i < secondLineTextLines.length; i++) {
          const line = secondLineTextLines[i];
          const lineY = y + fsize * (2 + firstLineTextLines.length) + i * fsize;

          if (!this.canDrawLine(lineY, w, h, fsize, statusBarHeight, showStartDateEndDateLine, startEndDateLineTextY)) {
            break;
          }

          context.fillText(line, secondLineTextX, lineY);
        }

        // draw third line
        const thirdLineText = this.getLineText(this._groupedProperties[THIRD_LINE_GROUP_INDEX], activityRef.activity.userObject.draggableTaskDto);
        const thirdLineTextLines = this.getTextLines(context, thirdLineText, w);

        const thirdLineTextX = x + w / 2;

        for (let i = 0; i < thirdLineTextLines.length; i++) {
          const line = thirdLineTextLines[i];
          const lineY = y + fsize * (2.5 + firstLineTextLines.length + secondLineTextLines.length) + i * fsize;

          if (!this.canDrawLine(lineY, w, h, fsize, statusBarHeight, showStartDateEndDateLine, startEndDateLineTextY)) {
            break;
          }

          context.fillText(line, thirdLineTextX, lineY);
        }
      }
    }
  }


  private canDrawLine(lineY: number, w: number, h: number, fsize: number, statusBarHeight: number, showStartDateEndDateLine: boolean, startEndDateLineTextY: number): boolean {
    // the drawing point is out of bounds
    if (lineY > h - statusBarHeight * 2) return false;

    // the drawing point coincides with the drawn start/end dates line
    if (showStartDateEndDateLine) {
      if (lineY > startEndDateLineTextY - fsize) {
        return false;
      }
    }

    return true;
  }

  private getLineText(groupProperties: GanttActivityDisplayPropertySetting[], dto: IDraggableTaskDto) {
    if (!groupProperties) {
      return "";
    } else {
      return groupProperties.map((property) => this.mapPropertyToValue(property.propertyName, dto)).filter(x => x !== "").join(" ‧ ");
    }
  }

  private mapPropertyToValue(propertyName: string, task: IDraggableTaskDto): string {

    if (propertyName === "taskName") return task?.name ?? "";
    if (propertyName === "taskBusinessId") return task?.businessId ?? "";

    return "";
  }
}
