<script setup lang="ts">
import { DropdownToolbar } from "md-editor-v3";
import { ref } from "vue";

const props = defineProps<{ editor: any; background?: boolean }>();

const dropdownVisible = ref(false);
const color = ref("#000000");

function colorChanged() {
  handler(color.value);
}

const handler = (pickedColor: string) => {
  props.editor?.insert((selectedText: string) => {
    if (!selectedText || selectedText === "") {
      return {
        targetValue: selectedText,
        select: true,
        deviationStart: 0,
        deviationEnd: 0
      };
    }
    /**
     * @return targetValue    Content to be inserted
     * @return select         Automatically select content, default: true
     * @return deviationStart Start position of the selected content, default: 0
     * @return deviationEnd   End position of the selected content, default: 0
     */
    return {
      targetValue: `<span style="${props.background ? "background-color" : "color"}: ${pickedColor}">${selectedText}</span>`,
      select: true,
      deviationStart: 0,
      deviationEnd: 0
    };
  });
};
</script>

<template>
  <DropdownToolbar :title="$t('manualEditor-textColor-label', { $: 'Text color' }, 'Text color')" :visible="dropdownVisible" @on-change="(v) => (dropdownVisible = v)">
    <template #trigger>
      <div class="md-editor-icon">
        <v-icon size="large">{{ background ? "mdi-format-color-fill" : "mdi-format-color-text" }}</v-icon>
        <div class="rounded" :style="{ 'background-color': color }" style="width: 100%; height: 5px">&nbsp;</div>
      </div>
    </template>
    <template #overlay>
      <div class="md-editor-dropdown-overlay">
        <div class="md-editor-menu">
          <v-color-picker v-model="color" />
          <v-btn density="compact" color="primary" block @click="colorChanged">{{ $t("manualEditor-selectColor-label", { $: "select color" }, "select color") }}</v-btn>
        </div>
      </div>
    </template>
  </DropdownToolbar>
</template>

<style scoped lang="scss"></style>
