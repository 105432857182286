<script lang="ts">
import { ICellRendererParams } from "ag-grid-community";
import { inject } from "vue";
import { $dateTimeFormatterSymbol, DateFormatter } from "@masta/shared";
import { useI18n } from "vue-i18n";

export default {
  setup(props: { params: ICellRendererParams }) {
    const $dateTimeFormatter = inject<DateFormatter>($dateTimeFormatterSymbol)!;
    let value = $dateTimeFormatter(props.params.value);

    if (value?.endsWith("infinity")) {
      const i18n = useI18n();
      const $t = i18n.t;
      value = value.replace("infinity", " " + $t("common-infinity", { $: "Infinity" }));
    }
    return {
      value
    };
  }
};
</script>

<template>
  <div style="width: 100%; height: 100%; display: flex; align-items: center; justify-content: center">
    {{ value }}
  </div>
</template>

<style lang="scss"></style>
