<script lang="ts" setup>
import { useSystemStore } from "@/store/SystemStore";
import { storeToRefs } from "pinia";
import { useRoute } from "vue-router";

const systemStore = useSystemStore();
const route = useRoute();
const { context } = storeToRefs(systemStore);

function getBaseUrl() {
  return context.value?.helpBaseUrl ?? "https://docs.memom.solutions/doc/";
}

function getMappedUrl(path: string) {
  if (context.value?.helpContentMap) {
    console.log("context.value.helpContentMap", context.value.helpContentMap);
    return context.value.helpContentMap[path];
  }
  return "";
}

function getDocUrl() {
  const pathParts = route.path.split("/");
  if (pathParts.length > 2) {
    pathParts.shift();
    pathParts.shift();
    const path = pathParts.join("/");
    const url = getMappedUrl(path);
    if (url) {
      return url;
    }
  }
  return context.value?.helpDefaultPage ?? "introduction-t1rVMQgu5N";
}

function getUrl() {
  return getBaseUrl() + getDocUrl();
}

function openHelp() {
  window.open(getUrl(), "help.memom.solutions");
}
</script>

<template>
  <v-btn color="primary" fab icon="mdi-help-circle-outline" variant="plain" @click="openHelp" />
</template>
