<script lang="ts" setup>
import SchemaTypeString from "@/components/ModelInstances/SchemaBuilder/SchemaTypeString.vue";
import SchemaTypeNumber from "@/components/ModelInstances/SchemaBuilder/SchemaTypeNumber.vue";
import SchemaTypeEnum from "@/components/ModelInstances/SchemaBuilder/SchemaTypeEnum.vue";
import SchemaTypeBoolean from "@/components/ModelInstances/SchemaBuilder/SchemaTypeBoolean.vue";
import SchemaTypeArray from "@/components/ModelInstances/SchemaBuilder/SchemaTypeArray.vue";
import {computed} from "vue";
import {SchemaTypeModel} from "@/components/ModelInstances/SchemaBuilder/SchemaModel";

const props = defineProps<{ modelValue: SchemaTypeModel }>();
const emit = defineEmits(["update:modelValue"]);

const localValue = computed<SchemaTypeModel>({
  get: () => props.modelValue,
  set: (value) => emit("update:modelValue", value)
});

function getComponent(item: SchemaTypeModel) {
  if (item.type === "string") return SchemaTypeString;
  if (item.type === "number") return SchemaTypeNumber;
  if (item.type === "enum") return SchemaTypeEnum;
  if (item.type === "boolean") return SchemaTypeBoolean;
  if (item.type === "array") return SchemaTypeArray;

  throw new Error("Gui component not found");
}
</script>

<template>
  <component :is="getComponent(modelValue)" v-model="localValue"/>
</template>
