import { Placement } from "./Placement";
import { IActivity } from "../../Model";
import { Cluster } from "./Cluster";
import { ResolverResult } from "./ResolverResult";

export class Resolver {
  public static resolve<TActivity extends IActivity>(activities: TActivity[]): ResolverResult<TActivity> {
    return Resolver.resolveWithPredicate<TActivity>(activities, (activity) => true);
  }

  // eslint-disable-next-line max-len
  public static resolveWithPredicate<TActivity extends IActivity>(activities: TActivity[], predicate: (activity: TActivity) => boolean): ResolverResult<TActivity> {
    let maxColumns = 0;
    const placements = new Map<TActivity, Placement<TActivity>>();

    if (activities.length === 1) {
      const activity = activities[0];
      placements.set(activity, new Placement<TActivity>(activity, 0, 1));
      maxColumns = 1;
    } else {
      const clusters: Cluster<TActivity>[] = [];

      let cluster: Cluster<TActivity> | null = null;

      for (const activity of activities) {
        if (!cluster || !cluster.intersects(activity)) {
          cluster = new Cluster<TActivity>();
          clusters.push(cluster);
        }

        cluster.add(activity);
      }

      for (const c of [...clusters]) {
        if (c.activities.length === 1) {
          const activity = c.activities[0];
          placements.set(activity, new Placement<TActivity>(activity, 0, 1));
          maxColumns = Math.max(maxColumns, 1);
          continue;
        }
        const resolveResultPlacements: Map<TActivity, Placement<TActivity>> = c.resolve(predicate);
        resolveResultPlacements.forEach((value, key) => placements.set(key, value));
        maxColumns = Math.max(maxColumns, c.getColumnCount());
      }
    }

    return new ResolverResult<TActivity>(placements, maxColumns);
  }
}
