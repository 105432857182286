<script lang="ts" setup>
import { computed, PropType, ref, toRef } from "vue";
import { GridApi, IToolPanelParams } from "ag-grid-community";
import { SettingContext, UserInterfaceProfileDto } from "@masta/generated-model";
import { GridProfileService, IGridProfile } from "@/components/Grid/Profile/UseGridProfile";
import _ from "lodash";
import { useSnackbarsStore } from "@/store/SnackbarsStore";
import { $t } from "@/i18n";

interface ProfileToolPanelParams extends IToolPanelParams {
  identifier: string;
  $gridProfile: IGridProfile;
  api: GridApi;
}

const props = defineProps({
  params: {
    type: Object as PropType<ProfileToolPanelParams>,
    required: true
  }
});
const snackbarsStore = useSnackbarsStore();
const $params = toRef(props, "params");

const name = ref<string>("");

const $gridProfile = computed(() => $params.value?.$gridProfile);
const service = computed<GridProfileService>(() => $gridProfile?.value?.service);
const gridApi = computed<GridApi>(() => $params.value?.api);
const profiles = computed<UserInterfaceProfileDto[]>(() => $gridProfile?.value?.service.profiles ?? []);
const isUnsaved = toRef($gridProfile.value, "isUnsaved");
const profile = computed<UserInterfaceProfileDto | undefined>(() => $gridProfile?.value?.profile as unknown as UserInterfaceProfileDto | undefined);

async function addProfileBtn() {
  const defaultProfile: UserInterfaceProfileDto = profile.value
    ? _.cloneDeep(profile.value)
    : $gridProfile?.value?.service.createDefaultProfile($params.value?.identifier, SettingContext.Tenant);
  defaultProfile.id = null;
  defaultProfile.isDefault = false;
  defaultProfile.name = name.value.toLowerCase().trim();
  defaultProfile.context = SettingContext.Tenant;
  await $gridProfile?.value?.service.saveProfile(defaultProfile);
  await snackbarsStore.createSnackbar({
    message: $t("profile-manage-onProfileCreated-message", { $: "Profile {profileName} created", profileName: name.value }),
    closeable: true
  });
  name.value = "";
  gridApi.value?.openToolPanel("profileToolPanel");
}

async function deleteProfileBtn(profileId: string) {
  await service.value.deleteProfile(profileId);
  await snackbarsStore.createSnackbar({
    message: $t("profile-manage-onProfileDeleted-message", { $: "Profile deleted" }),
    closeable: true
  });
  gridApi.value?.openToolPanel("profileToolPanel");
}

async function setProfileAsDefault(dto: UserInterfaceProfileDto) {
  dto.isDefault = true;
  await service.value.saveProfile(dto);
  await snackbarsStore.createSnackbar({
    message: $t("profile-manage-onProfileSetAsDefault-message", { $: "Profile {profileName} set as default", profileName: dto.name }),
    closeable: true
  });
  gridApi.value?.openToolPanel("profileToolPanel");
}

async function selectProfile(dto: UserInterfaceProfileDto) {
  $gridProfile.value?.selectProfile(dto);
  await snackbarsStore.createSnackbar({
    message: $t("profile-manage-onProfileSelected-message", { $: "Profile {profileName} selected", profileName: dto.name }),
    closeable: true
  });
  gridApi.value?.openToolPanel("profileToolPanel");
}

async function saveProfileChanges() {
  if (isUnsaved.value && profile.value) {
    try {
      const savedProfile = await service.value.saveProfile(profile.value);
      if (profiles.value?.filter((x) => x.id === profile.value?.id).length === 1 && savedProfile) {
        $gridProfile.value?.selectProfile(savedProfile);
      }
      await snackbarsStore.createSnackbar({
        message: $t("profile-manage-onProfileChangesSaved-message", { $: "Profile changes saved" }),
        closeable: true
      });
    } finally {
      if (isUnsaved.value) {
        isUnsaved.value = false;
      }
    }
    gridApi.value?.openToolPanel("profileToolPanel");
  }
}
</script>

<template>
  <v-container>
    <v-row v-if="profile">
      <v-col cols="6" class="flex align-self-center">
        Profile: <b class="text-uppercase">{{ profile?.name }}</b>
      </v-col>
      <v-col cols="6" class="flex align-self-center text-right">
        <v-btn :disabled="!isUnsaved" density="compact" icon="mdi-content-save" @click="saveProfileChanges()" />
      </v-col>
    </v-row>
    <v-row v-if="profile">
      <v-col v-for="(p, idx) in profiles.filter((x) => x.profile === profile?.profile)" :key="idx" cols="12">
        <v-row>
          <v-col cols="10">
            <v-card :color="p.id === profile?.id ? 'accent' : ''">
              <v-card-text class="text-uppercase">
                <v-row>
                  <v-col cols="6" class="flex align-self-center cursor-pointer" @click="selectProfile(p)"> {{ p.name }}</v-col>
                  <v-col cols="6" class="text-right">
                    <v-checkbox
                      density="compact"
                      hide-details
                      :model-value="p.isDefault"
                      :label="$t('profile-manage-default-label', { $: 'default' })"
                      @update:model-value="setProfileAsDefault(p)"
                    />
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="2" class="flex align-self-center">
            <v-btn density="compact" icon="mdi-delete-outline" @click="deleteProfileBtn(p.id)" />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-divider />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="10">
        <v-text-field v-model="name" density="compact" hide-details :label="$t('profile-manage-newProfileName-label', { $: 'New profile name:' })" />
      </v-col>
      <v-col cols="2" class="flex align-self-center">
        <v-btn density="compact" icon="mdi-plus" @click="addProfileBtn" />
      </v-col>
    </v-row>
  </v-container>
</template>

<style lang="scss" scoped></style>
