import { GanttDomElement } from "./GanttDomElement";
import { GanttEvents } from "../Core";

export abstract class GanttCanvas extends GanttDomElement<HTMLCanvasElement> {
  private _context: CanvasRenderingContext2D | undefined;
  private _cleared = false;

  protected constructor(protected readonly _ganttEvents: GanttEvents, identifier?: string, className?: string) {
    super(identifier, undefined, className, "canvas");
  }

  get cleared(): boolean {
    return this._cleared;
  }

  get canvas(): HTMLCanvasElement {
    return this.element;
  }

  get context(): CanvasRenderingContext2D {
    if (this._context) return this._context;
    this._context = this.canvas.getContext("2d", { willReadFrequently: false }) as CanvasRenderingContext2D;
    return this._context;
  }

  batchDraw(childToo = false) {
    this._cleared = false;
    super.batchDraw(childToo);
  }

  public abstract doDrawFromBatch(): Promise<void>;

  async afterInitialize(): Promise<void> {
    await super.afterInitialize();
    this.subscribe(
      this._ganttEvents
        .observeResize(this.element)
        // .pipe(debounceTime(100))
        .subscribe(async () => {
          // console.log("resize", this.identifier);
          await this.scale();
          await this.batchDraw();
        })
    );
  }

  public async scale() {
    if (!this.element || !this.element.parentElement) return;

    const parentElement = this.element.parentElement!;
    const viewportOffset = parentElement.getBoundingClientRect();
    const w = this.getScaledWidth(parentElement, viewportOffset);
    const h = this.getScaledHeight(parentElement, viewportOffset);
    if (w === this.canvas.width && h === this.canvas.height) return;
    const dpr = window.devicePixelRatio || 1;
    const rect = this.canvas.getBoundingClientRect();

    this.canvas.width = Math.floor(rect.width * dpr);
    this.canvas.height = Math.floor(rect.height * dpr);
    this.context.scale(dpr, dpr);
    this._cleared = false;
    // console.log(`%cScale->${this.identifier}`, "color: magenta; font-weight: bold;", { w: this.canvas.width, h: this.canvas.height, dpr });
  }

  public static scaleCanvas(canvas: HTMLCanvasElement) {
    const dpr = window.devicePixelRatio || 1;
    const rect = canvas.getBoundingClientRect();
    canvas.width = Math.floor(rect.width * dpr);
    canvas.height = Math.floor(rect.height * dpr);
    const context = canvas.getContext("2d")!;
    context.scale(dpr, dpr);
    return context;
  }

  protected getScaledWidth(parentElement: HTMLElement, viewportOffset: DOMRect): number {
    return Math.floor(parentElement.offsetWidth - viewportOffset.left);
  }

  protected getScaledHeight(parentElement: HTMLElement, viewportOffset: DOMRect): number {
    return Math.floor(parentElement.offsetHeight - viewportOffset.top);
  }

  protected clear() {
    // this.context.clearRect(0, 0, this.canvas.width, this.canvas.height);
    const rect = this.canvas.getBoundingClientRect();
    this.context.clearRect(0, 0, rect.width, rect.height);
    this._cleared = true;
  }
}
