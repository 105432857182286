import { ActivityRef, IActivity, Layout } from "../Model";
import { ActivityPosition } from "./ActivityPosition";
import { Instant } from "@js-joda/core";

export class ActivityBounds<Activity extends IActivity = IActivity> {
  private _isActive = true;
  private _id: string;
  private _activityId: string;
  private _rowId: string;
  private _layerId: string;
  private _startTime: Instant;

  private _endTime: Instant;
  private _canvas: OffscreenCanvas | HTMLCanvasElement;

  private _context: CanvasRenderingContext2D | OffscreenCanvasRenderingContext2D | null = null;
  private _layout: Layout;
  private _position: ActivityPosition;
  private _selected = false;
  private _hovered = false;
  private _highlighted = false;
  private _pressed = false;
  private _locked = false;

  constructor(activityRef: ActivityRef<Activity>, public x: number, public y: number, public width: number, public height: number, public offsetTop: number) {
    this._id = activityRef.id;
    this._activityId = activityRef.activity.id;
    this._rowId = activityRef.row.id;
    this._layerId = activityRef.layer?.id;
    this._startTime = Instant.ofEpochMilli(activityRef.activity.startTime.toEpochMilli());
    this._endTime = Instant.ofEpochMilli(activityRef.activity.endTime.toEpochMilli());
    if (typeof window === "object") {
      this._canvas = document.createElement("canvas") as HTMLCanvasElement;
      const dpr = window.devicePixelRatio || 1;
      (this._canvas as HTMLCanvasElement).width = width * dpr;
      (this._canvas as HTMLCanvasElement).height = height * dpr;
      this._canvas.style.imageRendering = "pixelated";
      this._context = this._canvas.getContext("2d", { willReadFrequently: false });
      if (this._context) {
        this._context.scale(dpr, dpr);
        this._context.imageSmoothingEnabled = false;
      }
    } else {
      this._canvas = new OffscreenCanvas(width, height);
      this._context = this._canvas.getContext("2d", { willReadFrequently: false });
    }
  }

  get isActive(): boolean {
    return this._isActive;
  }

  get id(): string {
    return this._id;
  }

  get activityId(): string {
    return this._activityId;
  }

  get rowId(): string {
    return this._rowId;
  }

  get layerId(): string {
    return this._layerId;
  }

  get startTime(): Instant {
    return this._startTime;
  }

  get endTime(): Instant {
    return this._endTime;
  }

  get position(): ActivityPosition {
    return this._position;
  }

  set position(value: ActivityPosition) {
    this._position = value;
  }

  get layout(): Layout {
    return this._layout;
  }

  set layout(value: Layout) {
    this._layout = value;
  }

  get canvas(): OffscreenCanvas | HTMLCanvasElement {
    return this._canvas;
  }

  get context(): CanvasRenderingContext2D | OffscreenCanvasRenderingContext2D | null {
    return this._context;
  }

  get selected(): boolean {
    return this._selected;
  }

  set selected(value: boolean) {
    this._selected = value;
  }

  get hovered(): boolean {
    return this._hovered;
  }

  set hovered(value: boolean) {
    this._hovered = value;
  }

  get highlighted(): boolean {
    return this._highlighted;
  }

  set highlighted(value: boolean) {
    this._highlighted = value;
  }

  get pressed(): boolean {
    return this._pressed;
  }

  set pressed(value: boolean) {
    this._pressed = value;
  }

  get locked(): boolean {
    return this._locked;
  }

  set locked(value: boolean) {
    this._locked = value;
  }

  isInBounds(x: number, y: number): boolean {
    return x >= this.x && x <= this.x + this.width && y >= this.y && y <= this.y + this.height + this.offsetTop;
  }

  toString(): string {
    return `ActivityBounds [actId=${this.id}, layout=${this.layout}, position=${this.position}]`;
  }

  setInactive() {
    this._isActive = false;
    this._canvas = null!;
    this._context = null;
  }
}
