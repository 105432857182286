import ApiService from "@/services/api";

import { composeTreeHierarchy } from "@/store/common";
import { ProductTemplateDetailsDto, ProductTemplateDto, UpdateTaskModelInstanceAssignmentCommand } from "@masta/generated-model";
import { defineStore } from "pinia";
import { useScenariosStore } from "@/store/ScenariosStore";
import { useErrorsStore } from "@/store/ErrorsStore";

interface State {
  productTemplates: (ProductTemplateDto & { hierarchy: string[] })[];
  productTemplateDetails: { [key: string]: ProductTemplateDetailsDto };
}

export const useProductTemplatesStore = defineStore("productTemplates", {
  state: (): State => ({
    productTemplates: [],
    productTemplateDetails: {}
  }),
  getters: {
    productTemplateDetail: (state) => {
      return (taskId: string) => state.productTemplateDetails[taskId];
    }
  },
  actions: {
    async fetch() {
      try {
        const scenariosStore = useScenariosStore();
        if (!scenariosStore.selectedScenario) return;
        const id = scenariosStore.selectedScenario.id;
        const {
          data: { tasks }
        } = await ApiService.productTemplates.getAll(id);
        this.clearDetails();
        this.productTemplates = composeTreeHierarchy(tasks, "parentTaskId");
      } catch (e) {
        const errorsStore = useErrorsStore();
        errorsStore.handleError(e);
        throw e;
      }
    },
    async fetchSingle(taskId: string): Promise<ProductTemplateDto | undefined> {
      try {
        const scenariosStore = useScenariosStore();
        if (!scenariosStore.selectedScenario) return;
        const id = scenariosStore.selectedScenario.id;
        const response = await ApiService.productTemplates.getById(taskId, id);
        return response.data;
      } catch (e) {
        const errorsStore = useErrorsStore();
        errorsStore.handleError(e);
        throw e;
      }
    },
    async fetchDetails(taskId: string) {
      try {
        const scenariosStore = useScenariosStore();
        if (!scenariosStore.selectedScenario) return;
        const id = scenariosStore.selectedScenario.id;
        const response = await ApiService.productTemplates.getDetails(id, taskId);

        const newDetails = { ...this.productTemplateDetails };
        newDetails[taskId] = response.data;
        this.productTemplateDetails = newDetails;
      } catch (e) {
        const errorsStore = useErrorsStore();
        errorsStore.handleError(e);
        throw e;
      }
    },
    async updateTaskModelInstanceAssignment(request: UpdateTaskModelInstanceAssignmentCommand) {
      try {
        await ApiService.tasks.updateTaskModelInstanceAssignment(request);
        await this.fetch();
      } catch (e) {
        const errorsStore = useErrorsStore();
        errorsStore.handleError(e);
        throw e;
      }
    },
    clearDetails() {
      this.productTemplateDetails = {};
    },
    async release(productTemplateId: string) {
      try {
        const scenariosStore = useScenariosStore();
        if (!scenariosStore.selectedScenario) return;

        await ApiService.productTemplates.release(scenariosStore.selectedScenario.id, productTemplateId);
      } catch (e) {
        const errorsStore = useErrorsStore();
        errorsStore.handleError(e);
        throw e;
      }
    },
    async bulkRelease(productTemplateIds: string[]) {
      try {
        const scenariosStore = useScenariosStore();
        if (!scenariosStore.selectedScenario) return;

        await ApiService.productTemplates.bulkRelease(scenariosStore.selectedScenario.id, productTemplateIds);
      } catch (e) {
        const errorsStore = useErrorsStore();
        errorsStore.handleError(e);
        throw e;
      }
    },
    async unrelease(productTemplateId: string) {
      try {
        const scenariosStore = useScenariosStore();
        if (!scenariosStore.selectedScenario) return;

        await ApiService.productTemplates.unrelease(scenariosStore.selectedScenario.id, productTemplateId);
      } catch (e) {
        const errorsStore = useErrorsStore();
        errorsStore.handleError(e);
        throw e;
      }
    },
    async archive(productTemplateId: string) {
      try {
        const scenariosStore = useScenariosStore();
        if (!scenariosStore.selectedScenario) return;

        await ApiService.productTemplates.archive(scenariosStore.selectedScenario.id, productTemplateId);
      } catch (e) {
        const errorsStore = useErrorsStore();
        errorsStore.handleError(e);
        throw e;
      }
    },
    async createNewVersion(productTemplateId: string) {
      try {
        const scenariosStore = useScenariosStore();
        if (!scenariosStore.selectedScenario) return;

        await ApiService.productTemplates.createNewVersion(scenariosStore.selectedScenario.id, productTemplateId);
      } catch (e) {
        const errorsStore = useErrorsStore();
        errorsStore.handleError(e);
        throw e;
      }
    },
    async copyToNewProduct(productTemplateId: string, resourceId: string) {
      try {
        const scenariosStore = useScenariosStore();
        if (!scenariosStore.selectedScenario) return;

        await ApiService.productTemplates.copyToNewProduct(scenariosStore.selectedScenario.id, productTemplateId, resourceId);
      } catch (e) {
        const errorsStore = useErrorsStore();
        errorsStore.handleError(e);
        throw e;
      }
    }
  }
});
