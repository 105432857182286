<script lang="ts" setup>
import GridWrapper from "@/components/Grid/GridWrapper.vue";
import { inject, reactive, ref } from "vue";
import OpenResourceGanttGridAction from "@/components/Gantt/OpenResourceGanttGridAction.vue";
import { ResourceType } from "@masta/generated-model";
import { GridWrapperComponent } from "@/components/Grid/GridWrapperComponent";
import { useModelInstancesStore } from "@/store/ModelInstancesStore";
import { $t } from "@/i18n";
import { useLocationsStore } from "@/store/LocationsStore";
import { LocationServerSideDataSource } from "@/components/Location/LocationServerSideDataSource";
import { GridReadyEvent, ValueFormatterParams } from "ag-grid-community";
import { translateResourceType } from "@/composables/translateEnum";
import { joinArrayOfStrings } from "@/components/ValueCellEditor/CommonFormatters";
import { $dateTimeFormatterSymbol, DateFormatter } from "@masta/shared";
import { generateRandomColorHex } from "@/composables/colorHelpers";
import { useTagsStore } from "@/store/TagsStore";
import { booleanTypeColumnFilterParams } from "@/components/Grid/Filters/BooleanTypeColumnFilters";
import { tagsTypeColumnFilterParams } from "@/components/Grid/Filters/TagsTypeColumnFilters";
import ActionsButton from "@/components/Layout/ActionsButton.vue";

const props = defineProps<{
  readonly?: boolean;
}>();

const emit = defineEmits(["manageAvailabilityRules"]);
const miStore = useModelInstancesStore();
const locationsStore = useLocationsStore();
const tagsStore = useTagsStore();

const serverSideDataSource = reactive(new LocationServerSideDataSource("locations"));
const DEFAULT_CREATE_VALUE = {
  type: ResourceType.Location,
  tags: [],
  color: () => generateRandomColorHex(),
  plannable: true
};
const ganttTypes = reactive([ResourceType.Location]);
const gridWrapperRef = ref<GridWrapperComponent>();
const autoGroupColumnDef = ref({
  minWidth: 330,
  cellRendererParams: {
    suppressCount: true
  },
  field: "name",
  headerValueGetter: (_: any) => $t("location-list-name-label", { $: "Name" })
});
const defaultColumnDef = ref({
  floatingFilter: true,
  filterParams: {
    applyMiniFilterWhileTyping: true
  }
});

const $dateTimeFormatter = inject<DateFormatter>($dateTimeFormatterSymbol)!;

const getDataPath = ref((data: any) => {
  return data.hierarchy;
});

async function loadModelInstances() {
  await miStore.fetchSchemas();
}

async function onReady(event: GridReadyEvent) {
  await loadModelInstances();
}

function onPrepareColumns(columnDefs: any) {
  columnDefs.value = [
    {
      field: "type",
      editable: false,
      sortable: false,
      filter: false,
      valueFormatter: (params: any) => translateResourceType(params.data.type),
      headerValueGetter: (_: any) => $t("location-list-type-label", { $: "Type" })
    },
    {
      field: "name",
      editable: true,
      sortable: true,
      filter: "agTextColumnFilter",
      headerValueGetter: (_: any) => $t("location-list-name-label", { $: "Name" }),
      type: ["textInputTypeColumn", "textFloatingFilterColumnType"],
      cellEditorParams: {
        placeholder: $t("location-list-name-label", { $: "Name" })
      },
      floatingFilterComponentParams: {
        placeholder: $t("location-list-name-label", { $: "Name" })
      }
    },
    {
      field: "description",
      headerValueGetter: (_: any) => $t("location-list-description-label", { $: "Description" }),
      editable: true,
      type: ["textInputTypeColumn", "textFloatingFilterColumnType"],
      filter: "agTextColumnFilter",
      cellEditorParams: {
        placeholder: $t("location-edit-description-label", { $: "Description" })
      },
      floatingFilterComponentParams: {
        placeholder: $t("location-edit-description-label", { $: "Description" })
      }
    },
    {
      field: "businessId",
      editable: true,
      sortable: true,
      filter: "agTextColumnFilter",
      headerValueGetter: (_: any) => $t("location-list-businessId-label", { $: "Business ID" }),
      type: ["textInputTypeColumn", "textFloatingFilterColumnType"],
      cellEditorParams: {
        rules: [(v: any) => (v !== undefined && v !== null && v !== "") || "Required"],
        placeholder: $t("location-list-businessId-label", { $: "Business ID" })
      },
      floatingFilterComponentParams: {
        placeholder: $t("location-list-businessId-label", { $: "Business ID" })
      }
    },
    {
      field: "color",
      type: ["colorTypeColumn", "textFloatingFilterColumnType"],
      editable: true,
      sortable: true,
      filter: "agTextColumnFilter",
      headerValueGetter: (_: any) => $t("location-list-color-label", { $: "Color" }),
      cellEditorParams: {
        placeholder: $t("location-list-color-label", { $: "Color" })
      },
      floatingFilterComponentParams: {
        placeholder: $t("location-list-color-label", { $: "Color" })
      }
    },
    {
      field: "tags",
      type: ["tagsPickerTypeColumn", "setFloatingFilterColumnType"],
      headerValueGetter: (_: any) => $t("location-list-tags-label", { $: "Tags" }),
      editable: true,
      resizable: true,
      filter: "agSetColumnFilter",
      filterParams: tagsTypeColumnFilterParams(tagsStore),
      valueFormatter: (params: ValueFormatterParams) => joinArrayOfStrings(params.data.tags),
      cellEditorParams: {
        placeholder: $t("location-edit-tags-label", { $: "Tags" })
      }
    },
    {
      field: "plannable",
      type: ["setFloatingFilterColumnType"],
      filter: "agSetColumnFilter",
      filterParams: booleanTypeColumnFilterParams,
      headerValueGetter: (_: any) => $t("location-list-plannable-label", { $: "Plannable" }),
      editable: true,
      resizable: true,
      sortable: true,
      cellDataType: "boolean",
      cellRendererParams: {
        disabled: () => !gridWrapperRef.value?.isEditing()
      }
    },
    {
      field: "createdBy",
      type: ["textInputTypeColumn", "textFloatingFilterColumnType"],
      filter: "agTextColumnFilter",
      editable: false,
      resizable: true,
      sortable: true,
      headerValueGetter: (_: any) => $t("location-list-createdBy-label", { $: "Created By" }),
      floatingFilterComponentParams: {
        placeholder: $t("location-list-createdBy-label", { $: "Created By" })
      }
    },
    {
      field: "createdAt",
      type: ["dateTimeTypeColumn"],
      filter: "agDateColumnFilter",
      editable: false,
      resizable: true,
      sortable: true,
      valueFormatter: (params: any) => {
        return $dateTimeFormatter(params.data.createdAt);
      },
      headerValueGetter: (_: any) => $t("location-list-createdAt-label", { $: "Created At" })
    },
    {
      field: "modifiedBy",
      type: ["textInputTypeColumn", "textFloatingFilterColumnType"],
      filter: "agTextColumnFilter",
      editable: false,
      resizable: true,
      sortable: true,
      headerValueGetter: (_: any) => $t("location-list-modifiedBy-label", { $: "Modified By" }),
      floatingFilterComponentParams: {
        placeholder: $t("location-list-modifiedBy-label", { $: "Modified By" })
      }
    },
    {
      field: "modifiedAt",
      type: ["dateTimeTypeColumn"],
      filter: "agDateColumnFilter",
      editable: false,
      resizable: true,
      sortable: true,
      valueFormatter: (params: any) => {
        return $dateTimeFormatter(params.data.modifiedAt);
      },
      headerValueGetter: (_: any) => $t("location-list-modifiedAt-label", { $: "Modified At" })
    }
  ];
}
</script>

<template>
  <grid-wrapper
    ref="gridWrapperRef"
    :create-btn="!readonly"
    :duplicate-btn="!readonly"
    :edit-btn="!readonly"
    :delete-btn="!readonly"
    :refresh-btn="!readonly"
    :hide-custom-actions-separator="readonly"
    row-selection="multiple"
    identifier="locations"
    :default-col-def="defaultColumnDef"
    :auto-group-column-def="autoGroupColumnDef"
    :get-data-path="getDataPath"
    server-side
    :server-side-datasource="serverSideDataSource"
    :create-default-value="DEFAULT_CREATE_VALUE"
    @refresh-action="loadModelInstances"
    @prepare-columns="onPrepareColumns"
    @ready="onReady"
  >
    <template #custom-buttons>
      <actions-button :model-value="[]" :label="$t('actions-button-gantt-label', { $: 'Gantt' })">
        <template #prepend>
          <open-resource-gantt-grid-action :types="ganttTypes" :grid-api="gridWrapperRef?.gridApi" />
        </template>
      </actions-button>
    </template>
  </grid-wrapper>
</template>

<style lang="scss" scoped></style>
