export const ResourceCapacityChangedNotification = "ResourceCapacityChanged";
export const OrderStatusChangedNotification = "OrderStatusChanged";
export const ProductionTasksChangedNotification = "ProductionTasksChanged";
export const ProductTemplateChangedNotification = "ProductTemplateChanged";
export const DocumentUpdatedNotification = "DocumentUpdated";
export const NotifyUserNotification = "NotifyUser";
export const SchedulingFinishedNotification = "SchedulingFinished";
export const QuotationDocumentGeneratedNotification = "QuotationDocumentGenerated";
export const UserNoticeCreatedOrChangedNotification = "UserNoticeCreatedOrChanged";
export const ModelInstancesChangedNotification = "ModelInstancesChanged";
