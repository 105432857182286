import { GanttDomElement } from "../../../GanttDomElement";
import { TooltipActivity } from "../../../../Core";
import { BehaviorSubject } from "rxjs";

export abstract class ActivityTooltipRenderer extends GanttDomElement<HTMLDivElement> {
  protected _visible$ = new BehaviorSubject<boolean>(false);
  protected _activity: TooltipActivity;
  protected _xPos: number | undefined;
  protected _yPos: number | undefined;

  protected constructor(identifier: string, className?: string) {
    super(identifier, undefined, className);
  }

  get visible(): boolean {
    return this._visible$.value;
  }

  set visible(value: boolean) {
    this._visible$.next(value);
  }

  get activity(): TooltipActivity {
    return this._activity;
  }

  async doDrawFromBatch(): Promise<void> {
    if (this._visible$.value) {
      this.element.style.transform = `translate(${this._xPos}px, ${this._yPos}px)`;
      this.element.style.display = "block";
    } else {
      this.element.style.display = "none";
    }
  }

  public show(activity: TooltipActivity, x: number, y: number): void {
    this._activity = activity;
    const rect = this.parent!.element.getBoundingClientRect();
    this._xPos = x + rect.left;
    this._yPos = y + rect.top;
    this.visible = true;
    this.batchDraw();
  }

  public move(x: number, y: number): void {
    const rect = this.parent!.element.getBoundingClientRect();
    this._xPos = x + rect.left;
    this._yPos = y + rect.top;
    this.batchDraw();
  }

  public hide(): void {
    this.visible = false;
  }
}
