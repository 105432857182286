<script setup lang="ts">
import { CreateDocumentForContextCommand, DocumentDto, ModelSchemaDto, UpdateDocumentCommand } from "@masta/generated-model";
import { useDocumentsStore } from "@/store/DocumentsStore";
import { useScenariosStore } from "@/store/ScenariosStore";
import { useSnackbarsStore } from "@/store/SnackbarsStore";
import { computed, Ref, ref } from "vue";
import ApiService from "@/services/api";
import { v4 as uuidv4 } from "uuid";
import { $t } from "@/i18n";
import { useErrorsStore } from "@/store/ErrorsStore";
import { useTagsStore } from "@/store/TagsStore";
import { ModelInstanceChange } from "@/components/ModelInstances/ModelInstanceChange";
import EditModelInstance from "@/components/ModelInstances/EditModelInstance.vue";
import MediaDragAndDropArea from "@/components/Media/MediaDragAndDropArea.vue";
import QrCodeGetter from "@/components/ModelInstances/QrCodeGetter/QrCodeGetter.vue";
import { DocumentProvider, DocumentTile, MediaItemType } from "@/components/Media/MediaModel";
import { useMedia } from "@/components/Media/useMedia";
import { compressImageFile, isFileValidImageType } from "@/components/Media/ImageFileCompression";

export interface QrCodeProps {
  resourceId?: string;
  orderId?: string;
  costCatalogueItemId?: string;
}

export interface MediaPickerProps {
  contextName: string;
  multiple?: boolean;
  documentsProvider?: DocumentProvider;
  qrCodeProps?: QrCodeProps;
  onDelete?: (item: DocumentDto) => Promise<void>;
  isItemAssigned?: (item: DocumentDto) => boolean;
}

export interface MediaPickerComponent {
  refreshItems: () => Promise<void>;
}

const props = withDefaults(defineProps<MediaPickerProps>(), {
  multiple: false,
  qrCodeProps: () => ({
    resourceId: undefined,
    orderId: undefined,
    costCatalogueItemId: undefined
  }),
  isItemAssigned: () => false
});

const emit = defineEmits<{
  (
    e: "uploaded",
    context: {
      modelInstanceId: string;
      businessId: string;
    }
  ): void;
  (e: "selected", item: DocumentDto[]): void;
}>();

defineExpose<MediaPickerComponent>({
  refreshItems
});

const documentsStore = useDocumentsStore();
const scenarioStore = useScenariosStore();
const snackbarStore = useSnackbarsStore();
const { transformDtoToTile, isImage, isPdf, isModel } = useMedia();
const items = ref<DocumentTile[]>([]);
const itemEditorSchema = ref<ModelSchemaDto>();
const fileInputRef = ref<HTMLInputElement>();
const showItemEditDialog = ref<boolean>(false);
const selectedDocumentModelInstance = ref<DocumentDto | null>(null);
const selected = ref<DocumentTile[]>([]);

const canSelect = computed(() => props.multiple || selected.value.length === 0);

async function resolveItemModelSchema() {
  const { data } = await ApiService.documentContexts.getDocumentContext(props.contextName);
  itemEditorSchema.value = {
    schemaName: data.profile,
    version: data.schemaVersion,
    schemaKey: data.schemaKey,
    modelSchemaTypes: data.modelSchemaTypes
  } as ModelSchemaDto;
}

async function refreshItems() {
  if (!props.contextName) {
    console.warn("No context name provided");
    return;
  }
  const documents: DocumentDto[] = [];
  if (props.documentsProvider) {
    documents.push(...(await props.documentsProvider(props.contextName)));
  } else {
    const { data } = await ApiService.documents.getAllDocumentsForContext(props.contextName);
    documents.push(...data.documentDtos);
  }

  items.value = await transformDtoToTile(documents);

  await resolveItemModelSchema();
}

async function uploadItem(file: File): Promise<string | undefined> {
  const selectedScenario = scenarioStore.selectedScenario;

  if (!selectedScenario) return;

  try {
    const businessId = uuidv4();
    const modelInstanceId = await documentsStore.createDocumentWithAttachment({
      businessId,
      contextName: props.contextName,
      tags: [],
      file: file
    } as unknown as CreateDocumentForContextCommand);

    snackbarStore.createSnackbar({
      message: $t("mediaPicker-uploader-itemUploaded-message", { $: "Uploaded" }),
      closeable: true
    });
    emit("uploaded", {
      modelInstanceId,
      businessId
    });
    return modelInstanceId;
  } catch (e: any) {
    const errorsStore = useErrorsStore();
    errorsStore.handleError(e);
  }
}

const dialogOpen = ref<boolean>(false);
const isUploading = ref<boolean>(false);

async function uploadMultipleFiles(files: FileList) {
  try {
    isUploading.value = true;
    const uploadedModelInstanceIds: string[] = [];
    for (let i = 0; i < files.length; i++) {
      let file = files.item(i);

      if (isFileValidImageType(file)) {
        file = await compressImageFile(file);
      }

      if (!file) continue;

      const modelInstanceId = await uploadItem(file);
      if (modelInstanceId) {
        uploadedModelInstanceIds.push(modelInstanceId);
      }
    }
    await refreshItems();
    for (const item of items.value) {
      if (uploadedModelInstanceIds.includes(item.documentDto?.id ?? "")) {
        selected.value.push(item);
      }
    }

    onBtnConfirmSelection(dialogOpen);

  } finally {
    isUploading.value = false;
  }
}


async function handleFileSelection(event: Event) {
  const input = event.target as HTMLInputElement;
  if (input.files) {
    await uploadMultipleFiles(input.files);
  }
}

async function deleteItem(item: DocumentTile) {
  const document = item.documentDto!;
  if (props.onDelete) {
    await props.onDelete(document);
  } else {
    try {
      await ApiService.documents.deleteDocument(document.id, document.revisionNumber);
    } catch (e: any) {
      snackbarStore.createSnackbar({
        message: e && e.detail ? e.detail : $t("mediaPicker-uploader-deleteError-message", { $: "Could not delete" }),
        type: "error",
        closeable: true
      });
    }
  }
  await refreshItems();
}

async function openInNewTab(item: DocumentTile) {
  if (!item.documentDto) {
    console.warn("No documentDto found");
    return;
  }
  const { data } = await ApiService.documents.getDocumentContentStream({
    documentId: item.documentDto.id,
    revisionNumber: item.documentDto?.revisionNumber
  });

  let url = "";
  const contentType = data.type;
  if (contentType === "text/plain") {
    url = await data.text();
  } else {
    url = window.URL.createObjectURL(data);
  }

  if (isModel(item.documentDto?.value.attachment)) {
    try {
      const a = document.createElement("a");
      a.href = url;
      a.type = contentType;
      a.download = item.documentDto?.value?.attachment?.fileName ?? "file";
      a.target = "_blank";
      a.click();
    } catch (e) {
      console.error(e);
    }
  } else {
    window.open(url, "_blank");
  }
  window.URL.revokeObjectURL(url);
}

function isSelected(item: DocumentTile) {
  return selected.value.some((s) => s.documentDto?.businessId === item.documentDto?.businessId);
}

function onItemClick(item: DocumentTile) {
  if (isSelected(item)) {
    selected.value.splice(
      selected.value.findIndex((s) => s.documentDto?.businessId === item.documentDto?.businessId),
      1
    );
  } else if (canSelect.value) {
    selected.value.push(item);
  }
}

function onBtnConfirmSelection(isActive: Ref<boolean>) {
  emit(
    "selected",
    selected.value.filter((x) => !!x.documentDto).map((x) => x.documentDto as DocumentDto)
  );
  isActive.value = false;
}

async function editItem(item: DocumentTile) {
  const { fetch } = useTagsStore();
  await fetch();
  selectedDocumentModelInstance.value = item.documentDto;
  showItemEditDialog.value = true;
}

async function saveDocument(change: ModelInstanceChange) {
  if (selectedDocumentModelInstance.value) {
    await documentsStore.updateDocumentWithAttachment({
      businessId: selectedDocumentModelInstance.value.businessId,
      revisionNumber: selectedDocumentModelInstance.value.revisionNumber,
      id: selectedDocumentModelInstance.value.id,
      tags: change.tags,
      ...change.value.attachment
    } as UpdateDocumentCommand);
    snackbarStore.createSnackbar({
      message: $t("mediaPicker-uploader-itemUpdated-message", { $: "Updated" }),
      closeable: true
    });
    showItemEditDialog.value = false;
    await refreshItems();
  }
}

function onDialogChange(visible: boolean) {
  selected.value = [];
  if (visible) {
    refreshItems();
  }
}
</script>

<template>
  <v-dialog v-model="dialogOpen" width="100%" height="80vh" @update:model-value="onDialogChange">
    <template #activator="{ props }">
      <slot name="activator" :props="props">
        <v-btn icon v-bind="props">
          <v-icon>mdi-folder-image</v-icon>
        </v-btn>
      </slot>
    </template>
    <template #default="{ isActive }">
      <input ref="fileInputRef" type="file" style="display: none" :multiple="multiple" @change="handleFileSelection" />
      <v-card v-if="isActive" height="100%" class="d-flex flex-column justify-space-between">
        <v-card-title class="d-flex justify-space-between">
          <span>{{ $t("mediaPicker-view-title", { $: "Media Library" }) }}</span>
          <v-btn icon @click="isActive.value = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text style="overflow: hidden; overflow-y: auto">
          <div class="media-container">
            <v-fade-transition group tag="div" class="media-gallery">
              <v-card key="qrcode" class="media d-flex cursor-pointer" color="grey-lighten-5" link>
                <slot name="qrCode" :context-name="contextName" :qr-code-props="qrCodeProps">
                  <qr-code-getter :context-name="contextName" v-bind="qrCodeProps" is-new>
                    <template #default="{ getDocumentQrCode }">
                      <div class="w-100 text-center" @click="getDocumentQrCode">
                        <v-icon class="align-center fill-height middle" size="75px">mdi-qrcode</v-icon>
                        <div class="name-label text-caption">
                          <v-card>{{ $t("mediaPicker-view-getQrCode-action", { $: "Upload from Mobile" }) }}</v-card>
                        </div>
                      </div>
                    </template>
                  </qr-code-getter>
                </slot>
              </v-card>
              <v-card key="upload" class="media d-flex cursor-pointer" color="grey-lighten-5" link>
                <media-drag-and-drop-area :uploading="isUploading" :multiple="multiple" @upload="uploadMultipleFiles" @click="fileInputRef?.click()"></media-drag-and-drop-area>
              </v-card>
              <template v-for="item in items" :key="item.title">
                <div class="media">
                  <v-hover v-slot="{ isHovering, props }">
                    <v-card
                      v-if="item.type === MediaItemType.ImageFile"
                      class="d-flex cursor-pointer"
                      color=""
                      :class="{ selected: isSelected(item), assigned: isItemAssigned(item.documentDto!) }"
                      :elevation="isSelected(item) ? 6 : 2"
                      height="100%"
                      v-bind="props"
                      :link="canSelect"
                      :disabled="(!canSelect && !isSelected(item)) || isItemAssigned(item.documentDto!)"
                      @click="onItemClick(item)"
                    >
                      <v-img :src="item.url" :title="item.title">
                        <div v-if="isItemAssigned(item.documentDto!)" class="assigned-label text-caption pt-1">
                          <v-chip color="primary" variant="elevated" density="compact">{{ $t("mediaPicker-view-assigned-label", { $: "Assigned" }) }}</v-chip>
                        </div>
                        <div v-if="isSelected(item)" class="assigned-label text-caption pt-1">
                          <v-chip color="accent" variant="elevated" density="compact">{{ $t("mediaPicker-view-selected-label", { $: "Selected" }) }}</v-chip>
                        </div>
                        <div class="name-label text-caption">
                          <v-card>{{ item.title }}</v-card>
                        </div>
                        <v-fade-transition>
                          <v-btn v-if="isHovering" class="float-right bg-red ma-5" icon="mdi-pencil" position="absolute" location="top left" @click.stop="editItem(item)" />
                        </v-fade-transition>
                        <v-fade-transition>
                          <v-btn v-if="isHovering" class="float-right bg-red ma-5" icon="mdi-trash-can" position="absolute" location="top right" @click.stop="deleteItem(item)" />
                        </v-fade-transition>
                        <v-fade-transition>
                          <v-btn
                            v-if="isHovering"
                            class="float-right bg-red ma-5"
                            icon="mdi-open-in-new"
                            position="absolute"
                            location="bottom right"
                            @click.stop="openInNewTab(item)"
                          />
                        </v-fade-transition>
                      </v-img>
                    </v-card>
                    <v-card
                      v-if="item.type === MediaItemType.ModelFile"
                      class="d-flex cursor-pointer"
                      color="grey-lighten-1"
                      :class="{ selected: isSelected(item), assigned: isItemAssigned(item.documentDto!) }"
                      height="100%"
                      v-bind="props"
                      :link="canSelect"
                      :disabled="!canSelect && !isSelected(item)"
                      @click="onItemClick(item)"
                    >
                      <div v-if="isItemAssigned(item.documentDto!)" class="assigned-label text-caption">
                        <v-card>{{ $t("mediaPicker-view-assigned-label", { $: "Assigned" }) }}</v-card>
                      </div>
                      <div v-if="isSelected(item)" class="assigned-label text-caption pt-1">
                        <v-chip color="accent" variant="elevated" density="compact">{{ $t("mediaPicker-view-selected-label", { $: "Selected" }) }}</v-chip>
                      </div>
                      <div class="name-label text-caption">
                        <v-card>{{ item.title }}</v-card>
                      </div>
                      <v-card-text class="d-flex justify-center align-center">
                        <v-icon size="50">mdi-file</v-icon>
                      </v-card-text>
                      <v-fade-transition>
                        <v-btn v-if="isHovering" class="float-right bg-red ma-5" icon="mdi-pencil" position="absolute" location="top left" @click.stop="editItem(item)" />
                      </v-fade-transition>
                      <v-fade-transition>
                        <v-btn v-if="isHovering" class="float-right bg-red ma-5" icon="mdi-trash-can" position="absolute" location="top right" @click.stop="deleteItem(item)" />
                      </v-fade-transition>
                      <v-fade-transition>
                        <v-btn
                          v-if="isHovering"
                          class="float-right bg-red ma-5"
                          icon="mdi-open-in-new"
                          position="absolute"
                          location="bottom right"
                          @click.stop="openInNewTab(item)"
                        />
                      </v-fade-transition>
                    </v-card>
                  </v-hover>
                </div>
              </template>
            </v-fade-transition>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-row>
            <v-col cols="6">
              <v-btn variant="elevated" color="secondary" block @click="isActive.value = false">
                {{ $t("mediaPicker-view-close-action", { $: "Close" }) }}
              </v-btn>
            </v-col>
            <v-col cols="6">
              <v-btn variant="elevated" color="primary" block :disabled="selected.length === 0" @click="onBtnConfirmSelection(isActive)">
                {{ $t("mediaPicker-view-select-action", { $: "Select" }) }}
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
      <v-dialog v-model="showItemEditDialog" attach>
        <v-card v-if="showItemEditDialog && itemEditorSchema">
          <v-card-text>
            <edit-model-instance
              ref="itemUploadEditor"
              :schema="itemEditorSchema"
              :title="$t('mediaPicker-generalInformation-uploadAttachment-label', { $: 'Upload' })"
              :model-instance="selectedDocumentModelInstance"
              :context-name="contextName"
              @save-document="saveDocument"
            >
              <template #subtitle>
                <div class="text-right">
                  <v-btn icon @click="showItemEditDialog = false">
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </div>
              </template>
            </edit-model-instance>
          </v-card-text>
          <v-card-actions>
            <v-btn variant="elevated" color="secondary" block @click="showItemEditDialog = false">
              {{ $t("mediaPicker-view-close-action", { $: "Close" }) }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>
  </v-dialog>
</template>

<style scoped lang="scss">
.media-gallery {
  flex: 1 0 auto;
  display: grid;
  grid-auto-flow: row;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  grid-gap: 10px;
  padding: 10px;
  height: 100%;
  overflow-y: auto;

  > .media {
    height: calc(100vh / 6);

    .v-card {
      border: 0.3em solid transparent;

      &.assigned {
        border: 0.3em solid rgb(var(--v-theme-secondary));
      }

      &.selected {
        border: 0.3em solid rgb(var(--v-theme-primary));
      }
    }
  }

  transition: border-color 0.2s ease-in-out;
}

.assigned-label {
  position: absolute;
  top: 0;
  margin: 0 auto;
  text-align: center;
  width: 100%;
}

.name-label {
  position: absolute;
  bottom: 0;
  margin: 0 auto;
  text-align: center;
  width: 100%;
}
</style>
