import { useI18n } from "vue-i18n";
import { ref } from "vue";

export const useGridI18n = () => {
  const i18n = useI18n();
  const $t = i18n.t;
  return ref({
    // Set Filter
    selectAll: $t("aggrid-locale-selectAll", { $: "(Select All)" }),
    selectAllSearchResults: $t("aggrid-locale-selectAllSearchResults", { $: "(Select All Search Results)" }),
    addCurrentSelectionToFilter: $t("aggrid-locale-addCurrentSelectionToFilter", { $: "Add current selection to filter" }),
    searchOoo: $t("aggrid-locale-searchOoo", { $: "Search..." }),
    blanks: $t("aggrid-locale-blanks", { $: "(Blanks)" }),
    noMatches: $t("aggrid-locale-noMatches", { $: "No matches" }),

    // Number Filter & Text Filter
    filterOoo: $t("aggrid-locale-filterOoo", { $: "Filter..." }),
    equals: $t("aggrid-locale-equals", { $: "Equals" }),
    notEqual: $t("aggrid-locale-notEqual", { $: "Not equal" }),
    blank: $t("aggrid-locale-blank", { $: "Blank" }),
    notBlank: $t("aggrid-locale-notBlank", { $: "Not blank" }),
    empty: $t("aggrid-locale-empty", { $: "Choose One" }),

    // Number Filter
    lessThan: $t("aggrid-locale-lessThan", { $: "Less than" }),
    greaterThan: $t("aggrid-locale-greaterThan", { $: "Greater than" }),
    lessThanOrEqual: $t("aggrid-locale-lessThanOrEqual", { $: "Less than or equal" }),
    greaterThanOrEqual: $t("aggrid-locale-greaterThanOrEqual", { $: "Greater than or equal" }),
    inRange: $t("aggrid-locale-inRange", { $: "In range" }),
    inRangeStart: $t("aggrid-locale-inRangeStart", { $: "from" }),
    inRangeEnd: $t("aggrid-locale-inRangeEnd", { $: "to" }),

    // Text Filter
    contains: $t("aggrid-locale-contains", { $: "Contains" }),
    notContains: $t("aggrid-locale-notContains", { $: "Not contains" }),
    startsWith: $t("aggrid-locale-startsWith", { $: "Starts with" }),
    endsWith: $t("aggrid-locale-endsWith", { $: "Ends with" }),

    // Date Filter
    dateFormatOoo: $t("aggrid-locale-dateFormatOoo", { $: "yyyy-mm-dd" }),

    // Filter Conditions
    andCondition: $t("aggrid-locale-andCondition", { $: "AND" }),
    orCondition: $t("aggrid-locale-orCondition", { $: "OR" }),

    // Filter Buttons
    applyFilter: $t("aggrid-locale-applyFilter", { $: "Apply" }),
    resetFilter: $t("aggrid-locale-resetFilter", { $: "Reset" }),
    clearFilter: $t("aggrid-locale-clearFilter", { $: "Clear" }),
    cancelFilter: $t("aggrid-locale-cancelFilter", { $: "Cancel" }),

    // Filter Titles
    textFilter: $t("aggrid-locale-textFilter", { $: "Text Filter" }),
    numberFilter: $t("aggrid-locale-numberFilter", { $: "Number Filter" }),
    dateFilter: $t("aggrid-locale-dateFilter", { $: "Date Filter" }),
    setFilter: $t("aggrid-locale-setFilter", { $: "Set Filter" }),

    // Group Column Filter
    groupFilterSelect: $t("aggrid-locale-groupFilterSelect", { $: "Select field:" }),

    // Advanced Filter
    advancedFilterContains: $t("aggrid-locale-advancedFilterContains", { $: "contains" }),
    advancedFilterNotContains: $t("aggrid-locale-advancedFilterNotContains", { $: "does not contain" }),
    advancedFilterTextEquals: $t("aggrid-locale-advancedFilterTextEquals", { $: "equals" }),
    advancedFilterTextNotEqual: $t("aggrid-locale-advancedFilterTextNotEqual", { $: "does not equal" }),
    advancedFilterStartsWith: $t("aggrid-locale-advancedFilterStartsWith", { $: "starts with" }),
    advancedFilterEndsWith: $t("aggrid-locale-advancedFilterEndsWith", { $: "ends with" }),
    advancedFilterBlank: $t("aggrid-locale-advancedFilterBlank", { $: "is blank" }),
    advancedFilterNotBlank: $t("aggrid-locale-advancedFilterNotBlank", { $: "is not blank" }),
    advancedFilterEquals: $t("aggrid-locale-advancedFilterEquals", { $: "=" }),
    advancedFilterNotEqual: $t("aggrid-locale-advancedFilterNotEqual", { $: "!=" }),
    advancedFilterGreaterThan: $t("aggrid-locale-advancedFilterGreaterThan", { $: ">" }),
    advancedFilterGreaterThanOrEqual: $t("aggrid-locale-advancedFilterGreaterThanOrEqual", { $: ">=" }),
    advancedFilterLessThan: $t("aggrid-locale-advancedFilterLessThan", { $: "<" }),
    advancedFilterLessThanOrEqual: $t("aggrid-locale-advancedFilterLessThanOrEqual", { $: "<=" }),
    advancedFilterTrue: $t("aggrid-locale-advancedFilterTrue", { $: "is true" }),
    advancedFilterFalse: $t("aggrid-locale-advancedFilterFalse", { $: "is false" }),
    advancedFilterAnd: $t("aggrid-locale-advancedFilterAnd", { $: "AND" }),
    advancedFilterOr: $t("aggrid-locale-advancedFilterOr", { $: "OR" }),
    advancedFilterApply: $t("aggrid-locale-advancedFilterApply", { $: "Apply" }),
    advancedFilterBuilder: $t("aggrid-locale-advancedFilterBuilder", { $: "Builder" }),
    advancedFilterValidationMissingColumn: $t("aggrid-locale-advancedFilterValidationMissingColumn", { $: "Column is missing" }),
    advancedFilterValidationMissingOption: $t("aggrid-locale-advancedFilterValidationMissingOption", { $: "Option is missing" }),
    advancedFilterValidationMissingValue: $t("aggrid-locale-advancedFilterValidationMissingValue", { $: "Value is missing" }),
    advancedFilterValidationInvalidColumn: $t("aggrid-locale-advancedFilterValidationInvalidColumn", { $: "Column not found" }),
    advancedFilterValidationInvalidOption: $t("aggrid-locale-advancedFilterValidationInvalidOption", { $: "Option not found" }),
    advancedFilterValidationMissingQuote: $t("aggrid-locale-advancedFilterValidationMissingQuote", { $: "Value is missing an end quote" }),
    advancedFilterValidationNotANumber: $t("aggrid-locale-advancedFilterValidationNotANumber", { $: "Value is not a number" }),
    advancedFilterValidationInvalidDate: $t("aggrid-locale-advancedFilterValidationInvalidDate", { $: "Value is not a valid date" }),
    advancedFilterValidationMissingCondition: $t("aggrid-locale-advancedFilterValidationMissingCondition", { $: "Condition is missing" }),
    advancedFilterValidationJoinOperatorMismatch: $t("aggrid-locale-advancedFilterValidationJoinOperatorMismatch", { $: "Join operators within a condition must be the same" }),
    advancedFilterValidationInvalidJoinOperator: $t("aggrid-locale-advancedFilterValidationInvalidJoinOperator", { $: "Join operator not found" }),
    advancedFilterValidationMissingEndBracket: $t("aggrid-locale-advancedFilterValidationMissingEndBracket", { $: "Missing end bracket" }),
    advancedFilterValidationExtraEndBracket: $t("aggrid-locale-advancedFilterValidationExtraEndBracket", { $: "Too many end brackets" }),
    advancedFilterValidationMessage: $t("aggrid-locale-advancedFilterValidationMessage", { $: "Expression has an error. ${variable1} - ${variable2}." }),
    advancedFilterValidationMessageAtEnd: $t("aggrid-locale-advancedFilterValidationMessageAtEnd", { $: "Expression has an error. ${variable} at end of expression." }),
    advancedFilterBuilderTitle: $t("aggrid-locale-advancedFilterBuilderTitle", { $: "Advanced Filter" }),
    advancedFilterBuilderApply: $t("aggrid-locale-advancedFilterBuilderApply", { $: "Apply" }),
    advancedFilterBuilderCancel: $t("aggrid-locale-advancedFilterBuilderCancel", { $: "Cancel" }),
    advancedFilterBuilderAddButtonTooltip: $t("aggrid-locale-advancedFilterBuilderAddButtonTooltip", { $: "Add Filter or Group" }),
    advancedFilterBuilderRemoveButtonTooltip: $t("aggrid-locale-advancedFilterBuilderRemoveButtonTooltip", { $: "Remove" }),
    advancedFilterBuilderMoveUpButtonTooltip: $t("aggrid-locale-advancedFilterBuilderMoveUpButtonTooltip", { $: "Move Up" }),
    advancedFilterBuilderMoveDownButtonTooltip: $t("aggrid-locale-advancedFilterBuilderMoveDownButtonTooltip", { $: "Move Down" }),
    advancedFilterBuilderAddJoin: $t("aggrid-locale-advancedFilterBuilderAddJoin", { $: "Add Group" }),
    advancedFilterBuilderAddCondition: $t("aggrid-locale-advancedFilterBuilderAddCondition", { $: "Add Filter" }),
    advancedFilterBuilderSelectColumn: $t("aggrid-locale-advancedFilterBuilderSelectColumn", { $: "Select a column" }),
    advancedFilterBuilderSelectOption: $t("aggrid-locale-advancedFilterBuilderSelectOption", { $: "Select an option" }),
    advancedFilterBuilderEnterValue: $t("aggrid-locale-advancedFilterBuilderEnterValue", { $: "Enter a value..." }),
    advancedFilterBuilderValidationAlreadyApplied: $t("aggrid-locale-advancedFilterBuilderValidationAlreadyApplied", { $: "Current filter already applied." }),
    advancedFilterBuilderValidationIncomplete: $t("aggrid-locale-advancedFilterBuilderValidationIncomplete", { $: "Not all conditions are complete." }),
    advancedFilterBuilderValidationSelectColumn: $t("aggrid-locale-advancedFilterBuilderValidationSelectColumn", { $: "Must select a column." }),
    advancedFilterBuilderValidationSelectOption: $t("aggrid-locale-advancedFilterBuilderValidationSelectOption", { $: "Must select an option." }),
    advancedFilterBuilderValidationEnterValue: $t("aggrid-locale-advancedFilterBuilderValidationEnterValue", { $: "Must enter a value." }),

    // Side Bar
    columns: $t("aggrid-locale-columns", { $: "Columns" }),
    filters: $t("aggrid-locale-filters", { $: "Filters" }),

    // columns tool panel
    pivotMode: $t("aggrid-locale-pivotMode", { $: "Pivot Mode" }),
    groups: $t("aggrid-locale-groups", { $: "Row Groups" }),
    rowGroupColumnsEmptyMessage: $t("aggrid-locale-rowGroupColumnsEmptyMessage", { $: "Drag here to set row groups" }),
    values: $t("aggrid-locale-values", { $: "Values" }),
    valueColumnsEmptyMessage: $t("aggrid-locale-valueColumnsEmptyMessage", { $: "Drag here to aggregate" }),
    pivots: $t("aggrid-locale-pivots", { $: "Column Labels" }),
    pivotColumnsEmptyMessage: $t("aggrid-locale-pivotColumnsEmptyMessage", { $: "Drag here to set column labels" }),

    // Header of the Default Group Column
    group: $t("aggrid-locale-group", { $: "Group" }),

    // Row Drag
    rowDragRow: $t("aggrid-locale-rowDragRow", { $: "row" }),
    rowDragRows: $t("aggrid-locale-rowDragRows", { $: "rows" }),

    // Other
    loadingOoo: $t("aggrid-locale-loadingOoo", { $: "Loading..." }),
    loadingError: $t("aggrid-locale-loadingError", { $: "ERR" }),
    noRowsToShow: $t("aggrid-locale-noRowsToShow", { $: "No Rows To Show" }),
    enabled: $t("aggrid-locale-enabled", { $: "Enabled" }),

    // Menu
    pinColumn: $t("aggrid-locale-pinColumn", { $: "Pin Column" }),
    pinLeft: $t("aggrid-locale-pinLeft", { $: "Pin Left" }),
    pinRight: $t("aggrid-locale-pinRight", { $: "Pin Right" }),
    noPin: $t("aggrid-locale-noPin", { $: "No Pin" }),
    valueAggregation: $t("aggrid-locale-valueAggregation", { $: "Value Aggregation" }),
    noAggregation: $t("aggrid-locale-noAggregation", { $: "None" }),
    autosizeThiscolumn: $t("aggrid-locale-autosizeThiscolumn", { $: "Autosize This Column" }),
    autosizeAllColumns: $t("aggrid-locale-autosizeAllColumns", { $: "Autosize All Columns" }),
    groupBy: $t("aggrid-locale-groupBy", { $: "Group by" }),
    ungroupBy: $t("aggrid-locale-ungroupBy", { $: "Un-Group by" }),
    ungroupAll: $t("aggrid-locale-ungroupAll", { $: "Un-Group All" }),
    addToValues: $t("aggrid-locale-addToValues", { $: "Add ${variable} to values" }),
    removeFromValues: $t("aggrid-locale-removeFromValues", { $: "Remove ${variable} from values" }),
    addToLabels: $t("aggrid-locale-addToLabels", { $: "Add ${variable} to labels" }),
    removeFromLabels: $t("aggrid-locale-removeFromLabels", { $: "Remove ${variable} from labels" }),
    resetColumns: $t("aggrid-locale-resetColumns", { $: "Reset Columns" }),
    expandAll: $t("aggrid-locale-expandAll", { $: "Expand All Row Groups" }),
    collapseAll: $t("aggrid-locale-collapseAll", { $: "Close All Row Groups" }),
    copy: $t("aggrid-locale-copy", { $: "Copy" }),
    ctrlC: $t("aggrid-locale-ctrlC", { $: "Ctrl+C" }),
    ctrlX: $t("aggrid-locale-ctrlX", { $: "Ctrl+X" }),
    copyWithHeaders: $t("aggrid-locale-copyWithHeaders", { $: "Copy With Headers" }),
    copyWithGroupHeaders: $t("aggrid-locale-copyWithGroupHeaders", { $: "Copy with Group Headers" }),
    cut: $t("aggrid-locale-cut", { $: "Cut" }),
    paste: $t("aggrid-locale-paste", { $: "Paste" }),
    ctrlV: $t("aggrid-locale-ctrlV", { $: "Ctrl+V" }),
    export: $t("aggrid-locale-export", { $: "Export" }),
    csvExport: $t("aggrid-locale-csvExport", { $: "CSV Export" }),
    excelExport: $t("aggrid-locale-excelExport", { $: "Excel Export" }),

    // Enterprise Menu Aggregation and Status Bar
    sum: $t("aggrid-locale-sum", { $: "Sum" }),
    first: $t("aggrid-locale-first", { $: "First" }),
    last: $t("aggrid-locale-last", { $: "Last" }),
    min: $t("aggrid-locale-min", { $: "Min" }),
    max: $t("aggrid-locale-max", { $: "Max" }),
    none: $t("aggrid-locale-none", { $: "None" }),
    count: $t("aggrid-locale-count", { $: "Count" }),
    avg: $t("aggrid-locale-avg", { $: "Average" }),
    filteredRows: $t("aggrid-locale-filteredRows", { $: "Filtered" }),
    selectedRows: $t("aggrid-locale-selectedRows", { $: "Selected" }),
    totalRows: $t("aggrid-locale-totalRows", { $: "Total Rows" }),
    totalAndFilteredRows: $t("aggrid-locale-totalAndFilteredRows", { $: "Rows" }),
    more: $t("aggrid-locale-more", { $: "More" }),
    to: $t("aggrid-locale-to", { $: "to" }),
    of: $t("aggrid-locale-of", { $: "of" }),
    page: $t("aggrid-locale-page", { $: "Page" }),
    pageLastRowUnknown: $t("aggrid-locale-pageLastRowUnknown", { $: "?" }),
    nextPage: $t("aggrid-locale-nextPage", { $: "Next Page" }),
    lastPage: $t("aggrid-locale-lastPage", { $: "Last Page" }),
    firstPage: $t("aggrid-locale-firstPage", { $: "First Page" }),
    previousPage: $t("aggrid-locale-previousPage", { $: "Previous Page" }),
    pageSizeSelectorLabel: $t("aggrid-locale-pageSizeSelectorLabel", { $: "Page size" }),

    // Pivoting
    pivotColumnGroupTotals: $t("aggrid-locale-pivotColumnGroupTotals", { $: "Total" }),

    // Enterprise Menu (Charts)
    pivotChartAndPivotMode: $t("aggrid-locale-pivotChartAndPivotMode", { $: "Pivot Chart & Pivot Mode" }),
    pivotChart: $t("aggrid-locale-pivotChart", { $: "Pivot Chart" }),
    chartRange: $t("aggrid-locale-chartRange", { $: "Chart Range" }),

    columnChart: $t("aggrid-locale-columnChart", { $: "Column" }),
    groupedColumn: $t("aggrid-locale-groupedColumn", { $: "Grouped" }),
    stackedColumn: $t("aggrid-locale-stackedColumn", { $: "Stacked" }),
    normalizedColumn: $t("aggrid-locale-normalizedColumn", { $: "100% Stacked" }),

    barChart: $t("aggrid-locale-barChart", { $: "Bar" }),
    groupedBar: $t("aggrid-locale-groupedBar", { $: "Grouped" }),
    stackedBar: $t("aggrid-locale-stackedBar", { $: "Stacked" }),
    normalizedBar: $t("aggrid-locale-normalizedBar", { $: "100% Stacked" }),

    pieChart: $t("aggrid-locale-pieChart", { $: "Pie" }),
    pie: $t("aggrid-locale-pie", { $: "Pie" }),
    doughnut: $t("aggrid-locale-doughnut", { $: "Doughnut" }),

    line: $t("aggrid-locale-line", { $: "Line" }),

    xyChart: $t("aggrid-locale-xyChart", { $: "X Y (Scatter)" }),
    scatter: $t("aggrid-locale-scatter", { $: "Scatter" }),
    bubble: $t("aggrid-locale-bubble", { $: "Bubble" }),

    areaChart: $t("aggrid-locale-areaChart", { $: "Area" }),
    area: $t("aggrid-locale-area", { $: "Area" }),
    stackedArea: $t("aggrid-locale-stackedArea", { $: "Stacked" }),
    normalizedArea: $t("aggrid-locale-normalizedArea", { $: "100% Stacked" }),

    histogramChart: $t("aggrid-locale-histogramChart", { $: "Histogram" }),
    histogramFrequency: $t("aggrid-locale-histogramFrequency", { $: "Frequency" }),

    combinationChart: $t("aggrid-locale-combinationChart", { $: "Combination" }),
    columnLineCombo: $t("aggrid-locale-columnLineCombo", { $: "Column & Line" }),
    AreaColumnCombo: $t("aggrid-locale-AreaColumnCombo", { $: "Area & Column" }),

    // Charts
    pivotChartTitle: $t("aggrid-locale-pivotChartTitle", { $: "Pivot Chart" }),
    rangeChartTitle: $t("aggrid-locale-rangeChartTitle", { $: "Range Chart" }),
    settings: $t("aggrid-locale-settings", { $: "Settings" }),
    data: $t("aggrid-locale-data", { $: "Data" }),
    format: $t("aggrid-locale-format", { $: "Format" }),
    categories: $t("aggrid-locale-categories", { $: "Categories" }),
    defaultCategory: $t("aggrid-locale-defaultCategory", { $: "(None)" }),
    series: $t("aggrid-locale-series", { $: "Series" }),
    xyValues: $t("aggrid-locale-xyValues", { $: "X Y Values" }),
    paired: $t("aggrid-locale-paired", { $: "Paired Mode" }),
    axis: $t("aggrid-locale-axis", { $: "Axis" }),
    navigator: $t("aggrid-locale-navigator", { $: "Navigator" }),
    color: $t("aggrid-locale-color", { $: "Color" }),
    thickness: $t("aggrid-locale-thickness", { $: "Thickness" }),
    xType: $t("aggrid-locale-xType", { $: "X Type" }),
    automatic: $t("aggrid-locale-automatic", { $: "Automatic" }),
    category: $t("aggrid-locale-category", { $: "Category" }),
    number: $t("aggrid-locale-number", { $: "Number" }),
    time: $t("aggrid-locale-time", { $: "Time" }),
    autoRotate: $t("aggrid-locale-autoRotate", { $: "Auto Rotate" }),
    xRotation: $t("aggrid-locale-xRotation", { $: "X Rotation" }),
    yRotation: $t("aggrid-locale-yRotation", { $: "Y Rotation" }),
    ticks: $t("aggrid-locale-ticks", { $: "Ticks" }),
    width: $t("aggrid-locale-width", { $: "Width" }),
    height: $t("aggrid-locale-height", { $: "Height" }),
    length: $t("aggrid-locale-length", { $: "Length" }),
    padding: $t("aggrid-locale-padding", { $: "Padding" }),
    spacing: $t("aggrid-locale-spacing", { $: "Spacing" }),
    chart: $t("aggrid-locale-chart", { $: "Chart" }),
    title: $t("aggrid-locale-title", { $: "Title" }),
    titlePlaceholder: $t("aggrid-locale-titlePlaceholder", { $: "Chart title - double click to edit" }),
    background: $t("aggrid-locale-background", { $: "Background" }),
    font: $t("aggrid-locale-font", { $: "Font" }),
    top: $t("aggrid-locale-top", { $: "Top" }),
    right: $t("aggrid-locale-right", { $: "Right" }),
    bottom: $t("aggrid-locale-bottom", { $: "Bottom" }),
    left: $t("aggrid-locale-left", { $: "Left" }),
    labels: $t("aggrid-locale-labels", { $: "Labels" }),
    size: $t("aggrid-locale-size", { $: "Size" }),
    minSize: $t("aggrid-locale-minSize", { $: "Minimum Size" }),
    maxSize: $t("aggrid-locale-maxSize", { $: "Maximum Size" }),
    legend: $t("aggrid-locale-legend", { $: "Legend" }),
    position: $t("aggrid-locale-position", { $: "Position" }),
    markerSize: $t("aggrid-locale-markerSize", { $: "Marker Size" }),
    markerStroke: $t("aggrid-locale-markerStroke", { $: "Marker Stroke" }),
    markerPadding: $t("aggrid-locale-markerPadding", { $: "Marker Padding" }),
    itemSpacing: $t("aggrid-locale-itemSpacing", { $: "Item Spacing" }),
    itemPaddingX: $t("aggrid-locale-itemPaddingX", { $: "Item Padding X" }),
    itemPaddingY: $t("aggrid-locale-itemPaddingY", { $: "Item Padding Y" }),
    layoutHorizontalSpacing: $t("aggrid-locale-layoutHorizontalSpacing", { $: "Horizontal Spacing" }),
    layoutVerticalSpacing: $t("aggrid-locale-layoutVerticalSpacing", { $: "Vertical Spacing" }),
    strokeWidth: $t("aggrid-locale-strokeWidth", { $: "Stroke Width" }),
    lineDash: $t("aggrid-locale-lineDash", { $: "Line Dash" }),
    offset: $t("aggrid-locale-offset", { $: "Offset" }),
    offsets: $t("aggrid-locale-offsets", { $: "Offsets" }),
    tooltips: $t("aggrid-locale-tooltips", { $: "Tooltips" }),
    callout: $t("aggrid-locale-callout", { $: "Callout" }),
    markers: $t("aggrid-locale-markers", { $: "Markers" }),
    shadow: $t("aggrid-locale-shadow", { $: "Shadow" }),
    blur: $t("aggrid-locale-blur", { $: "Blur" }),
    xOffset: $t("aggrid-locale-xOffset", { $: "X Offset" }),
    yOffset: $t("aggrid-locale-yOffset", { $: "Y Offset" }),
    lineWidth: $t("aggrid-locale-lineWidth", { $: "Line Width" }),
    normal: $t("aggrid-locale-normal", { $: "Normal" }),
    bold: $t("aggrid-locale-bold", { $: "Bold" }),
    italic: $t("aggrid-locale-italic", { $: "Italic" }),
    boldItalic: $t("aggrid-locale-boldItalic", { $: "Bold Italic" }),
    predefined: $t("aggrid-locale-predefined", { $: "Predefined" }),
    fillOpacity: $t("aggrid-locale-fillOpacity", { $: "Fill Opacity" }),
    strokeOpacity: $t("aggrid-locale-strokeOpacity", { $: "Line Opacity" }),
    histogramBinCount: $t("aggrid-locale-histogramBinCount", { $: "Bin count" }),
    columnGroup: $t("aggrid-locale-columnGroup", { $: "Column" }),
    barGroup: $t("aggrid-locale-barGroup", { $: "Bar" }),
    pieGroup: $t("aggrid-locale-pieGroup", { $: "Pie" }),
    lineGroup: $t("aggrid-locale-lineGroup", { $: "Line" }),
    scatterGroup: $t("aggrid-locale-scatterGroup", { $: "X Y (Scatter)" }),
    areaGroup: $t("aggrid-locale-areaGroup", { $: "Area" }),
    histogramGroup: $t("aggrid-locale-histogramGroup", { $: "Histogram" }),
    combinationGroup: $t("aggrid-locale-combinationGroup", { $: "Combination" }),
    groupedColumnTooltip: $t("aggrid-locale-groupedColumnTooltip", { $: "Grouped" }),
    stackedColumnTooltip: $t("aggrid-locale-stackedColumnTooltip", { $: "Stacked" }),
    normalizedColumnTooltip: $t("aggrid-locale-normalizedColumnTooltip", { $: "100% Stacked" }),
    groupedBarTooltip: $t("aggrid-locale-groupedBarTooltip", { $: "Grouped" }),
    stackedBarTooltip: $t("aggrid-locale-stackedBarTooltip", { $: "Stacked" }),
    normalizedBarTooltip: $t("aggrid-locale-normalizedBarTooltip", { $: "100% Stacked" }),
    pieTooltip: $t("aggrid-locale-pieTooltip", { $: "Pie" }),
    doughnutTooltip: $t("aggrid-locale-doughnutTooltip", { $: "Doughnut" }),
    lineTooltip: $t("aggrid-locale-lineTooltip", { $: "Line" }),
    groupedAreaTooltip: $t("aggrid-locale-groupedAreaTooltip", { $: "Area" }),
    stackedAreaTooltip: $t("aggrid-locale-stackedAreaTooltip", { $: "Stacked" }),
    normalizedAreaTooltip: $t("aggrid-locale-normalizedAreaTooltip", { $: "100% Stacked" }),
    scatterTooltip: $t("aggrid-locale-scatterTooltip", { $: "Scatter" }),
    bubbleTooltip: $t("aggrid-locale-bubbleTooltip", { $: "Bubble" }),
    histogramTooltip: $t("aggrid-locale-histogramTooltip", { $: "Histogram" }),
    columnLineComboTooltip: $t("aggrid-locale-columnLineComboTooltip", { $: "Column & Line" }),
    areaColumnComboTooltip: $t("aggrid-locale-areaColumnComboTooltip", { $: "Area & Column" }),
    customComboTooltip: $t("aggrid-locale-customComboTooltip", { $: "Custom Combination" }),
    noDataToChart: $t("aggrid-locale-noDataToChart", { $: "No data available to be charted." }),
    pivotChartRequiresPivotMode: $t("aggrid-locale-pivotChartRequiresPivotMode", { $: "Pivot Chart requires Pivot Mode enabled." }),
    chartSettingsToolbarTooltip: $t("aggrid-locale-chartSettingsToolbarTooltip", { $: "Menu" }),
    chartLinkToolbarTooltip: $t("aggrid-locale-chartLinkToolbarTooltip", { $: "Linked to Grid" }),
    chartUnlinkToolbarTooltip: $t("aggrid-locale-chartUnlinkToolbarTooltip", { $: "Unlinked from Grid" }),
    chartDownloadToolbarTooltip: $t("aggrid-locale-chartDownloadToolbarTooltip", { $: "Download Chart" }),
    seriesChartType: $t("aggrid-locale-seriesChartType", { $: "Series Chart Type" }),
    seriesType: $t("aggrid-locale-seriesType", { $: "Series Type" }),
    secondaryAxis: $t("aggrid-locale-secondaryAxis", { $: "Secondary Axis" }),

    // ARIA
    ariaAdvancedFilterBuilderItem: $t("aggrid-locale-ariaAdvancedFilterBuilderItem", { $: "${variable1}. Level ${variable2}. Press ENTER to edit." }),
    ariaAdvancedFilterBuilderItemValidation: $t("aggrid-locale-ariaAdvancedFilterBuilderItemValidation", {
      $: "${variable1}. Level ${variable2}. ${variable3} Press ENTER to edit."
    }),
    ariaAdvancedFilterBuilderList: $t("aggrid-locale-ariaAdvancedFilterBuilderList", { $: "Advanced Filter Builder List" }),
    ariaAdvancedFilterBuilderFilterItem: $t("aggrid-locale-ariaAdvancedFilterBuilderFilterItem", { $: "Filter Condition" }),
    ariaAdvancedFilterBuilderGroupItem: $t("aggrid-locale-ariaAdvancedFilterBuilderGroupItem", { $: "Filter Group" }),
    ariaAdvancedFilterBuilderColumn: $t("aggrid-locale-ariaAdvancedFilterBuilderColumn", { $: "Column" }),
    ariaAdvancedFilterBuilderOption: $t("aggrid-locale-ariaAdvancedFilterBuilderOption", { $: "Option" }),
    ariaAdvancedFilterBuilderValueP: $t("aggrid-locale-ariaAdvancedFilterBuilderValueP", { $: "Value" }),
    ariaAdvancedFilterBuilderJoinOperator: $t("aggrid-locale-ariaAdvancedFilterBuilderJoinOperator", { $: "Join Operator" }),
    ariaAdvancedFilterInput: $t("aggrid-locale-ariaAdvancedFilterInput", { $: "Advanced Filter Input" }),
    ariaChecked: $t("aggrid-locale-ariaChecked", { $: "checked" }),
    ariaColumn: $t("aggrid-locale-ariaColumn", { $: "Column" }),
    ariaColumnGroup: $t("aggrid-locale-ariaColumnGroup", { $: "Column Group" }),
    ariaColumnList: $t("aggrid-locale-ariaColumnList", { $: "Column List" }),
    ariaColumnSelectAll: $t("aggrid-locale-ariaColumnSelectAll", { $: "Toggle Select All Columns" }),
    ariaDateFilterInput: $t("aggrid-locale-ariaDateFilterInput", { $: "Date Filter Input" }),
    ariaDefaultListName: $t("aggrid-locale-ariaDefaultListName", { $: "List" }),
    ariaFilterColumnsInput: $t("aggrid-locale-ariaFilterColumnsInput", { $: "Filter Columns Input" }),
    ariaFilterFromValue: $t("aggrid-locale-ariaFilterFromValue", { $: "Filter from value" }),
    ariaFilterInput: $t("aggrid-locale-ariaFilterInput", { $: "Filter Input" }),
    ariaFilterList: $t("aggrid-locale-ariaFilterList", { $: "Filter List" }),
    ariaFilterToValue: $t("aggrid-locale-ariaFilterToValue", { $: "Filter to value" }),
    ariaFilterValue: $t("aggrid-locale-ariaFilterValue", { $: "Filter Value" }),
    ariaFilterMenuOpen: $t("aggrid-locale-ariaFilterMenuOpen", { $: "Open Filter Menu" }),
    ariaFilteringOperator: $t("aggrid-locale-ariaFilteringOperator", { $: "Filtering Operator" }),
    ariaHidden: $t("aggrid-locale-ariaHidden", { $: "hidden" }),
    ariaIndeterminate: $t("aggrid-locale-ariaIndeterminate", { $: "indeterminate" }),
    ariaInputEditor: $t("aggrid-locale-ariaInputEditor", { $: "Input Editor" }),
    ariaMenuColumn: $t("aggrid-locale-ariaMenuColumn", { $: "Press CTRL ENTER to open column menu." }),
    ariaRowDeselect: $t("aggrid-locale-ariaRowDeselect", { $: "Press SPACE to deselect this row" }),
    ariaRowSelectAll: $t("aggrid-locale-ariaRowSelectAll", { $: "Press Space to toggle all rows selection" }),
    ariaRowToggleSelection: $t("aggrid-locale-ariaRowToggleSelection", { $: "Press Space to toggle row selection" }),
    ariaRowSelect: $t("aggrid-locale-ariaRowSelect", { $: "Press SPACE to select this row" }),
    ariaSearch: $t("aggrid-locale-ariaSearch", { $: "Search" }),
    ariaSortableColumn: $t("aggrid-locale-ariaSortableColumn", { $: "Press ENTER to sort" }),
    ariaToggleVisibility: $t("aggrid-locale-ariaToggleVisibility", { $: "Press SPACE to toggle visibility" }),
    ariaToggleCellValue: $t("aggrid-locale-ariaToggleCellValue", { $: "Press SPACE to toggle cell value" }),
    ariaUnchecked: $t("aggrid-locale-ariaUnchecked", { $: "unchecked" }),
    ariaVisible: $t("aggrid-locale-ariaVisible", { $: "visible" }),
    ariaSearchFilterValues: $t("aggrid-locale-ariaSearchFilterValues", { $: "Search filter values" }),

    // ARIA Labels for Drop Zones

    ariaRowGroupDropZonePanelLabel: $t("aggrid-locale-ariaRowGroupDropZonePanelLabel", { $: "Row Groups" }),
    ariaValuesDropZonePanelLabel: $t("aggrid-locale-ariaValuesDropZonePanelLabel", { $: "Values" }),
    ariaPivotDropZonePanelLabel: $t("aggrid-locale-ariaPivotDropZonePanelLabel", { $: "Column Labels" }),
    ariaDropZoneColumnComponentDescription: $t("aggrid-locale-ariaDropZoneColumnComponentDescription", { $: "Press DELETE to remove" }),
    ariaDropZoneColumnValueItemDescription: $t("aggrid-locale-ariaDropZoneColumnValueItemDescription", { $: "Press ENTER to change the aggregation type" }),
    ariaDropZoneColumnGroupItemDescription: $t("aggrid-locale-ariaDropZoneColumnGroupItemDescription", { $: "Press ENTER to sort" }),
    // used for aggregate drop zone, format: {aggregation}{ariaDropZoneColumnComponentAggFuncSeparator}{column name}
    ariaDropZoneColumnComponentAggFuncSeparator: $t("aggrid-locale-ariaDropZoneColumnComponentAggFuncSeparator", { $: " of " }),
    ariaDropZoneColumnComponentSortAscending: $t("aggrid-locale-ariaDropZoneColumnComponentSortAscending", { $: "ascending" }),
    ariaDropZoneColumnComponentSortDescending: $t("aggrid-locale-ariaDropZoneColumnComponentSortDescending", { $: "descending" }),

    // ARIA Labels for Dialogs
    ariaLabelColumnMenu: $t("aggrid-locale-ariaLabelColumnMenu", { $: "Column Menu" }),
    ariaLabelCellEditor: $t("aggrid-locale-ariaLabelCellEditor", { $: "Cell Editor" }),
    ariaLabelDialog: $t("aggrid-locale-ariaLabelDialog", { $: "Dialog" }),
    ariaLabelSelectField: $t("aggrid-locale-ariaLabelSelectField", { $: "Select Field" }),
    ariaLabelRichSelectField: $t("aggrid-locale-ariaLabelRichSelectField", { $: "Rich Select Field" }),
    ariaLabelTooltip: $t("aggrid-locale-ariaLabelTooltip", { $: "Tooltip" }),
    ariaLabelContextMenu: $t("aggrid-locale-ariaLabelContextMenu", { $: "Context Menu" }),
    ariaLabelSubMenu: $t("aggrid-locale-ariaLabelSubMenu", { $: "SubMenu" }),
    ariaLabelAggregationFunction: $t("aggrid-locale-ariaLabelAggregationFunction", { $: "Aggregation Function" }),
    ariaLabelAdvancedFilterAutocomplete: $t("aggrid-locale-ariaLabelAdvancedFilterAutocomplete", { $: "Advanced Filter Autocomplete" }),
    ariaLabelAdvancedFilterBuilderAddField: $t("aggrid-locale-ariaLabelAdvancedFilterBuilderAddField", { $: "Advanced Filter Builder Add Field" }),
    ariaLabelAdvancedFilterBuilderColumnSelectField: $t("aggrid-locale-ariaLabelAdvancedFilterBuilderColumnSelectField", { $: "Advanced Filter Builder Column Select Field" }),
    ariaLabelAdvancedFilterBuilderOptionSelectField: $t("aggrid-locale-ariaLabelAdvancedFilterBuilderOptionSelectField", { $: "Advanced Filter Builder Option Select Field" }),
    ariaLabelAdvancedFilterBuilderJoinSelectField: $t("aggrid-locale-ariaLabelAdvancedFilterBuilderJoinSelectField", { $: "Advanced Filter Builder Join Operator Select Field" }),

    // Number Format (Status Bar, Pagination Panel)
    thousandSeparator: $t("aggrid-locale-thousandSeparator", { $: "," }),
    decimalSeparator: $t("aggrid-locale-decimalSeparator", { $: "." }),

    // Data types
    true: $t("aggrid-locale-true", { $: "True" }),
    false: $t("aggrid-locale-false", { $: "False" }),
    invalidDate: $t("aggrid-locale-invalidDate", { $: "Invalid Date" }),
    invalidNumber: $t("aggrid-locale-invalidNumber", { $: "Invalid Number" }),
    january: $t("aggrid-locale-january", { $: "January" }),
    february: $t("aggrid-locale-february", { $: "February" }),
    march: $t("aggrid-locale-march", { $: "March" }),
    april: $t("aggrid-locale-april", { $: "April" }),
    may: $t("aggrid-locale-may", { $: "May" }),
    june: $t("aggrid-locale-june", { $: "June" }),
    july: $t("aggrid-locale-july", { $: "July" }),
    august: $t("aggrid-locale-august", { $: "August" }),
    september: $t("aggrid-locale-september", { $: "September" }),
    october: $t("aggrid-locale-october", { $: "October" }),
    november: $t("aggrid-locale-november", { $: "November" }),
    december: $t("aggrid-locale-december", { $: "December" })
  });
};
