import { LocalStorageAuthentication } from "./LocalStorageAuthentication";
import { $keycloack } from "./keycloak.service";
import { AuthenticationState, VueKeycloakInstance, VueKeycloakTokenParsed } from "./keycloak.types";
import { UnwrapNestedRefs, watch } from "vue";
import { KeycloakInstance } from "keycloak-js";

export abstract class BaseAuthService {
  protected _localStorageAuthentication: LocalStorageAuthentication;

  protected constructor(storageKeyPrefix: string) {
    this._localStorageAuthentication = new LocalStorageAuthentication(storageKeyPrefix);

    this.watchAuthenticationState();
  }

  get authenticated() {
    return $keycloack.instance.authenticated;
  }

  get instance(): UnwrapNestedRefs<VueKeycloakInstance> {
    return $keycloack.instance;
  }

  get token(): string | null {
    return $keycloack.instance.token ?? null;
  }

  get tokenParsed(): VueKeycloakTokenParsed | null {
    return $keycloack.instance.tokenParsed ?? null;
  }

  get tenantId(): string | null {
    return this._localStorageAuthentication.authenticationState.tenantId ?? null;
  }

  get clientId(): string | null {
    return this._localStorageAuthentication.authenticationState.clientId ?? null;
  }

  get authenticationState(): UnwrapNestedRefs<AuthenticationState> {
    return this._localStorageAuthentication.authenticationState;
  }

  public async logout() {
    if (this.instance.logoutFn) {
      await this.instance.logoutFn();
    }
    this._localStorageAuthentication.clear();
  }


  private watchAuthenticationState() {
    watch(
      () => this._localStorageAuthentication.authenticationState,
      (authenticationState) => {
        if(authenticationState != null) {
          $keycloack.setTokens(
            authenticationState.token,
            authenticationState.refreshToken,
            authenticationState.idToken
          );
        } else {
          $keycloack.setTokens(null, null, null);
        }
      },
      { deep: true }
    );
  }

  protected updateAuthenticationState(keycloak?: KeycloakInstance) {
    if (keycloak && keycloak.token && this._localStorageAuthentication.authenticationState.token !== keycloak.token) {
      this._localStorageAuthentication.authenticationState.token = keycloak.token;
    }
    if (keycloak && keycloak.refreshToken && this._localStorageAuthentication.authenticationState.refreshToken !== keycloak.refreshToken) {
      this._localStorageAuthentication.authenticationState.refreshToken = keycloak.refreshToken;
    }
    if (keycloak && keycloak.idToken && this._localStorageAuthentication.authenticationState.idToken !== keycloak.idToken) {
      this._localStorageAuthentication.authenticationState.idToken = keycloak.idToken;
    }
  }
}
