import ApiService from "@/services/api";
import { defineStore } from "pinia";
import { AssignModelInstanceToResourceCommand, DeassignModelInstanceFromResourceCommand, MaterialDto, UpdateResourceModelInstanceAssignmentCommand } from "@masta/generated-model";
import { useScenariosStore } from "@/store/ScenariosStore";
import { useErrorsStore } from "@/store/ErrorsStore";

interface State {
  materials: MaterialDto[];
}

export const useMaterialsStore = defineStore("materials", {
  state: (): State => ({
    materials: []
  }),
  actions: {
    async fetch() {
      try {
        const scenariosStore = useScenariosStore();
        if (!scenariosStore.selectedScenario) return;
        const response = await ApiService.materials.getMaterials({
          scenarioId: scenariosStore.selectedScenario.id
        });
        this.materials = response.data.results;
      } catch (e) {
        const errorsStore = useErrorsStore();
        errorsStore.handleError(e);
        throw e;
      }
    },
    async assignModelInstance(request: AssignModelInstanceToResourceCommand) {
      try {
        await ApiService.resources.assignModelInstance(request);
        await this.fetch();
      } catch (e) {
        const errorsStore = useErrorsStore();
        errorsStore.handleError(e);
        throw e;
      }
    },
    async deassignModelInstance(request: DeassignModelInstanceFromResourceCommand) {
      try {
        await ApiService.resources.deassignModelInstance(request);
        await this.fetch();
      } catch (e) {
        const errorsStore = useErrorsStore();
        errorsStore.handleError(e);
        throw e;
      }
    },
    async updateModelInstanceAssignment(request: UpdateResourceModelInstanceAssignmentCommand) {
      try {
        await ApiService.resources.updateModelInstanceAssignment(request);
        await this.fetch();
      } catch (e) {
        const errorsStore = useErrorsStore();
        errorsStore.handleError(e);
        throw e;
      }
    },
    async fetchMaterial(id: string) {
      try {
        const scenariosStore = useScenariosStore();
        if (!scenariosStore.selectedScenario) return;
        const response = await ApiService.materials.getSingle(id, scenariosStore.selectedScenario.id);
        if (response.data) {
          const idx = this.materials.findIndex((x) => x.id === id);
          if (idx !== -1) {
            this.materials[idx] = response.data;
          } else {
            this.materials.push(response.data);
          }
        }
        return response.data;
      } catch (e) {
        const errorsStore = useErrorsStore();
        errorsStore.handleError(e);
        throw e;
      }
    },
    async getMaterial(id: string) {
      const idx = this.materials.findIndex((x) => x.id === id);
      if (idx !== -1) {
        return this.materials[idx];
      }
      return this.fetchMaterial(id);
    }
  }
});
