<!-- 
    This Vue component, DatepickerField.vue, is a custom date picker field that uses the @vuepic/vue-datepicker library.
    This component is used to display a date picker field with a text field that shows the selected date.

    The behavior of the date picker is not changed. Only the text field is customized.
-->

<script lang="ts" setup>
import { $dateTimeFormatterSymbol, DateFormatter } from "@masta/shared";
import { DatePickerInstance, VueDatePickerProps } from '@vuepic/vue-datepicker';
import { computed, inject, ref, useAttrs } from 'vue';
import type { VTextField } from "vuetify/components";

const datapickerRef = ref<DatePickerInstance>();
const attrs = useAttrs() as Partial<VueDatePickerProps> & Partial<VTextField>;


const $dateTimeFormatter = inject<DateFormatter>($dateTimeFormatterSymbol)!;

function onClear() {
    datapickerRef.value?.clearValue();
}

const dateFormatted = computed(() => {
    if (attrs.format && typeof attrs.format === 'function') {
        return attrs.format(attrs.modelValue);
    } else {
        return $dateTimeFormatter(attrs.modelValue);
    }
});

const increaseHours = (hours: number, updateTime: (value: number | number[], isHours:boolean) => void) => {
    const newHours = hours === 23 ? 0 : hours + 1;
    updateTime(newHours, true);
};

const decreaseHours = (hours: number, updateTime: (value: number | number[], isHours:boolean) => void) => {
    const newHours = hours === 0 ? 23 : hours - 1;
    updateTime(newHours, true);
};

const increaseMinutes = (minutes: number, updateTime: (value: number | number[], isHours:boolean) => void) => {
    const newMinutes = minutes === 59 ? 0 : minutes + 1;
    updateTime(newMinutes, false);
};

const decreaseMinutes = (minutes: number, updateTime: (value: number | number[], isHours:boolean) => void) => {
    const newMinutes = minutes === 0 ? 59 : minutes - 1;
    updateTime(newMinutes, false);
};

</script>

<template>
    <datepicker 
        ref="datapickerRef" 
        v-bind="$attrs">
        <template #trigger>
            <v-text-field
                ref="datepickerValueRef" 
                :model-value="dateFormatted" 
                density="compact" 
                readonly
                :error="attrs.error"
                :color="attrs.color"
                variant="outlined" 
                hide-details="auto" 
                :clearable="attrs.clearable" 
                :disabled="attrs.disabled"
                prepend-inner-icon="mdi-calendar"
                :label="attrs.label" 
                :placeholder="attrs.placeholder" 
                @update:focused="attrs['onUpdate:focused']"
                @click:clear="onClear" />
        </template>
        <template #time-picker="{ time, updateTime }">
            <div class="d-flex justify-center align-center ma-2">
                <div class="d-flex flex-column justify-center align-center">
                    <v-btn 
                        size="small" density="compact" color="primary" icon="mdi-chevron-up" variant="outlined" 
                        @click="increaseHours(time.hours, updateTime)" />
                    <v-text-field 
                        :value="time.hours" class="custom-timepicker-input" single-line hide-details readonly
                        density="compact" variant="outlined" @change="updateTime(+$event.target.value)" />
                    <v-btn 
                        size="small" density="compact" color="primary" icon="mdi-chevron-down" variant="outlined" 
                        @click="decreaseHours(time.hours, updateTime)" />
                </div>
                <div class="d-flex flex-column justify-center align-center">
                    <v-btn 
                        size="small" density="compact" color="primary" icon="mdi-chevron-up" variant="outlined" 
                        @click="increaseMinutes(time.minutes, updateTime)" />
                    <v-text-field 
                        :value="time.minutes" class="custom-timepicker-input" single-line hide-details readonly
                        density="compact" variant="outlined" @change="updateTime(+$event.target.value)" />
                    <v-btn 
                        size="small" density="compact" color="primary" icon="mdi-chevron-down" variant="outlined" 
                        @click="decreaseMinutes(time.minutes, updateTime)" />
                </div>
            </div>
        </template>
        <template #action-preview>
        </template>
    </datepicker>
</template>

<style lang="scss">
.custom-timepicker-input {
    margin-top: 5px;
    margin-bottom: 5px;
    padding: 5px;
    width: 60px;    
    input {
        text-align: center;
    }
}
</style>