<script lang="ts">
import { ICellEditorParams, BodyScrollEvent } from "ag-grid-community";
import { computed, onMounted, onUnmounted, ref, toRef } from "vue";
import { IControlledCellEditorParams, resolveValueRef, useValueChangeControl } from "@/components/Grid/UseValueChangeControl";

import { ResourceSubType, ResourceType } from "@masta/generated-model";
import ResourcesPicker from "@/components/Resources/ResourcesPicker.vue";
import { VForm } from 'vuetify/lib/components/index.mjs';

export interface IResourcesPickerCellEditorParams extends IControlledCellEditorParams, ICellEditorParams {
    resourceType?: ResourceType | (() => ResourceType),
    resourceSubTypes?: ResourceSubType[],
    rules?: any[],
}

// Warn: Use export default instead of <script setup /> because only this works with AgGrid!
export default {
    components: { ResourcesPicker },
    setup(props: {
        params: IResourcesPickerCellEditorParams;
    }) {
        const resourcesPickerRef = ref<typeof ResourcesPicker>();
        const formRef = ref<VForm>();
        const valid = ref<boolean>();

        const params = toRef(props, "params");

        const isEditEnabled = toRef(props.params.isEditEnabled ? props.params.isEditEnabled(props.params) : true);

        const initialValue = props.params.value;
        const resourcesValue = resolveValueRef(props.params, initialValue);

        const rules = computed(() => {
            return params.value.rules ?? [];
        });

        const errors = computed(() => {
            return formRef.value?.errors.map((e) => e.errorMessages.join(",")).join(",");
        });

        const showTooltip = computed<boolean>(() => !!errors.value && !!resourcesPickerRef.value?.hasFocus());

        function getValue() {
            return resourcesValue.value;
        }

        function afterGuiAttached() {
            if (props.params.cellStartedEdit) {
                focusIn();
            }
        }

        function focusIn() {
            resourcesPickerRef.value?.focus();
        }

        function focusOut() {   
        }

        function isValid() {
            return isEditEnabled.value ? valid?.value : undefined;
        }
        
        useValueChangeControl(resourcesValue, props.params);

        onMounted(() => {
            props.params.api.addEventListener("bodyScroll", onGridBodyScroll);
        });

        onUnmounted(() => {
            const { api } = props.params;
            if (api && !api.isDestroyed()) {
                api.removeEventListener("bodyScroll", onGridBodyScroll);
            }
        });

        function onGridBodyScroll(event: BodyScrollEvent) {
            // close menu on scroll (when is open) to avoid menu position issues
            if (resourcesPickerRef.value && resourcesPickerRef.value.getMenu()) {
                resourcesPickerRef.value.setMenu(false);
            }
        }

        return {
            getValue,
            afterGuiAttached,
            focusIn,
            focusOut,
            isValid,
            showTooltip,
            rules,
            valid,
            errors,
            isEditEnabled,
            resourcesValue,
            resourcesPickerRef: resourcesPickerRef as any,
            formRef: formRef as any,
        };
    },
}
</script>


<template>
      <v-tooltip v-model="showTooltip" location="top" color="error" :offset="30" class="resources-picker-cell-editor-tooltip">
        <template #activator="act">
        <v-form ref="formRef" v-model="valid" :disabled="!isEditEnabled" class="resources-picker-cell-editor">
            <div class="resources-picker-cell-editor-container">
                <ResourcesPicker
                    ref="resourcesPickerRef"
                    v-model="resourcesValue" 
                    color="primary"
                    :readonly="!isEditEnabled"
                    :placeholder="params.placeholder"
                    :resource-type="params.resourceType" 
                    :resource-sub-types="params.resourceSubTypes"
                    :rules="rules" />
                <div v-bind="act.props" />
            </div>
      </v-form>
    </template>
    <span v-if="errors">{{ errors }}</span>
  </v-tooltip>
</template>


<style lang="scss">
.resources-picker-cell-editor {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .resources-picker-cell-editor-container {
        padding: 0.31em;
        height: 100%;

        .v-input {
            --v-input-chips-margin-top: 0;
            --v-input-chips-margin-bottom: 0;
            font-size: 1em;
        }

        .v-autocomplete {
            &--single {
                .v-field--variant-outlined input {
                    --v-input-chips-margin-top: 2px;
                    --v-input-chips-margin-bottom: 2px;
                }
            }

            .v-field {
                border-radius: 8px;
                font-size: 1em;

                .v-field__overlay {
                    background-color: white;
                }

                .v-field__input {
                    padding-top: 0;
                    padding-bottom: 0;
                    font-size: 1em;
                    height: 34px;
                    overflow-x: hidden;
                    overflow-y: auto;

                    input {
                        padding-top: 12px;
                    }
                }

                .v-field__outline {
                    --v-field-border-width: 2px;
                }

                color: rgba(0, 0, 0, 0.5);

                &:hover {
                    color: var(--ag-data-color);
                }
    
                &.v-field--focused {
                    color: var(--ag-data-color);
                }
            }    
        }
    }
}

.resources-picker-cell-editor-tooltip {
  .v-overlay__content {
    background: rgba(var(--v-theme-error), 0.7);
  }
}
</style>