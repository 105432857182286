import { CustomEvents } from "@/components/Grid/Events/CustomEvents";
import { GridApi, GridReadyEvent } from "ag-grid-community";

/**
 * Function to handle own keyboard shortcuts for the grid.
 */
export const useGridKeyboardShortcuts = () => {

    let gridApi: GridApi = null!;

    function onGridReady(event: GridReadyEvent) {
        gridApi = event.api;
    }    

    function handleKeyDown(event: KeyboardEvent) {
        // console.log(event);
        if((event.ctrlKey || event.metaKey) && (event.key === 'a' || event.key === 'A')) {
            event.preventDefault();

            gridApi.selectAll();
        }
    }

    return {
        onGridReady,
        handleKeyDown
    }
};