import { ApiClient } from "@/services/api/common.api";
import { GetProductionTasksPaginatedQuery, GetProductionTasksResponse, IAgGridPaginationResponse, ProductionTaskDetailsDto, ProductionTaskDto } from "@masta/generated-model";

export default {
  async getTasksPaginated(request: GetProductionTasksPaginatedQuery) {
    return ApiClient.post<IAgGridPaginationResponse<ProductionTaskDto>>(`/productionTasks/query`, request);
  },
  async getTasks(scenarioId: string, resourceId: string | null = null) {
    return await ApiClient.get<GetProductionTasksResponse>(`/productionTasks`, {
      params: { scenarioId: scenarioId, resourceId: resourceId ?? undefined }
    });
  },
  async getTask(scenarioId: string, taskId: string) {
    return await ApiClient.get<ProductionTaskDto>(`/productionTasks/${taskId}?scenarioId=${scenarioId}`);
  },
  async getTaskDetails(scenarioId: string, taskId: string) {
    return await ApiClient.get<ProductionTaskDetailsDto>(`/productionTasks/${taskId}/details?scenarioId=${scenarioId}`);
  }
};
