<script lang="ts" setup>
import { ref } from "vue";

const props = defineProps<{
  type: string;
  taskBusinessId: string;
  taskName: string;
  stepPosition?: number;
  stepName?: string;
  specType?: string;
  specResourceType?: string;
}>();

const cols = ref<number>(12);
if (props.stepName) {
  cols.value = 6;
}
if (props.specType) {
  cols.value = 4;
}
</script>

<template>
  <div>
    <div class="text-h6 mb-2">
      {{ type }}
    </div>
    <v-divider></v-divider>
    <v-row class="mt-2">
      <v-col>
        <div>
          <span class="text-caption mr-4">{{ $t("task-label-businessId-label", { $: "Task Business Id:" }) }}</span>
        </div>
        <div>{{ taskBusinessId }}</div>
      </v-col>
      <v-col>
        <div>
          <span class="text-caption mr-4">{{ $t("task-label-name-label", { $: "Task Name:" }) }}</span>
        </div>
        <div>{{ taskName }}</div>
      </v-col>
    </v-row>
    <v-row v-if="stepName" class="mt-2">
      <v-col>
        <div>
          <span class="text-caption mr-4">{{ $t("task-label-stepPosition-label", { $: "Step Position:" }) }}</span>
        </div>
        <div>{{ stepPosition }}</div>
      </v-col>
      <v-col>
        <div>
          <span class="text-caption mr-4">{{ $t("task-label-stepName-label", { $: "Step Name:" }) }}</span>
        </div>
        <div>{{ stepName }}</div>
      </v-col>
    </v-row>
    <v-row v-if="specType" class="mt-2">
      <v-col>
        <div>
          <span class="text-caption mr-4">{{ $t("task-label-assignmentType-label", { $: "Assignment Type:" }) }}</span>
        </div>
        <div>{{ specType }}</div>
      </v-col>
      <v-col>
        <div>
          <span class="text-caption mr-4">{{ $t("task-label-resourceType-label", { $: "Resource Type:" }) }}</span>
        </div>
        <div>{{ specResourceType }}</div>
      </v-col>
    </v-row>
  </div>
</template>
