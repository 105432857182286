<script lang="ts" setup>
import { onMounted, ref, watch } from "vue";
import { generateGuiModelFromObject, GuiModelItem } from "@masta/json-schema";
import GuiItemComponent from "./GuiItemComponent.vue";
import { JSONSchema7 } from "json-schema";

const props = defineProps<{
  schema: JSONSchema7;
  modelValue: any;
  disabled: boolean;
}>();

const emit = defineEmits<{
  (e: "update:modelValue", value: any): void;
}>();

const error = ref<string | undefined>();
const guiModel = ref<GuiModelItem>();
let mounted = false;

function parseSchema(schema: JSONSchema7) {
  try {
    guiModel.value = generateGuiModelFromObject(schema);
  } catch (e: any) {
    error.value = e.message;
    console.error("Error when generating GUI", e);
  }
}

parseSchema(props.schema);

watch(
  () => props.modelValue,
  () => {
    try {
      if (guiModel.value) {
        if (props.modelValue) {
          guiModel.value.setValue(props.modelValue);
        }
      }
      mounted = true;
      error.value = undefined;
    } catch (e: any) {
      error.value = e.message;
      console.error("Error setting model value", e);
    }
  },
  { immediate: true }
);

watch(
  guiModel,
  (value: GuiModelItem, oldValue: GuiModelItem) => {
    if (!mounted) return;
    const newValue = value.getValue();
    emit("update:modelValue", newValue);
  },
  { immediate: true, deep: true }
);
</script>

<template>
  <v-banner v-if="error" icon="$warning" color="warning">
    <v-banner-text> Schema is not supported by GUI builder. Please use code editor instead.</v-banner-text>
  </v-banner>
  <div v-else>
    <v-form @submit.prevent="">
      <v-row>
        <gui-item-component v-if="guiModel" v-model="guiModel" :disabled="disabled" />
      </v-row>
    </v-form>
  </div>
</template>
