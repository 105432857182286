<template>
  <v-card>
    <v-card-title>
      {{ title }}
    </v-card-title>
    <v-card-text>
      <v-form ref="form" v-model="valid">
        <v-row>
          <v-col cols="12" md="8">
            <v-autocomplete
              v-model="key"
              variant="outlined"
              density="compact"
              :items="availableKeys"
              item-title="key"
              item-value="key"
              label="Key"
              :rules="rules.stringValue"
              :disabled="editMode"
              @change="valueType = null"
            />
          </v-col>
          <v-col cols="12" md="8">
            <v-text-field v-model="profile" variant="outlined" density="compact" :disabled="editMode" :label="$t('setting-manage-profile-label', { $: 'Profile' })" />
          </v-col>
          <v-col cols="12">
            <v-select
              v-model="context"
              variant="outlined"
              density="compact"
              :items="contexts"
              item-title="name"
              item-value="value"
              label="Context"
              :rules="rules.numberValue"
              :disabled="editMode"
            />
          </v-col>
          <v-col cols="12">
            <v-select
              v-model="valueType"
              variant="outlined"
              density="compact"
              :items="valueTypes.filter((x) => valueTypes.map((vt) => vt.type).includes(x.value))"
              item-title="name"
              item-value="value"
              label="Value type"
              :rules="rules.stringValue"
              :disabled="editMode"
              @change="value = null"
            />
          </v-col>
          <v-col cols="12">
            <v-textarea v-if="isStringValue" v-model="settingValue" variant="outlined" density="compact" label="String value" />
            <v-text-field v-else-if="isNumberValue" v-model="settingValue" variant="outlined" density="compact" label="Number value" type="number" />
            <v-switch v-else-if="isBooleanValue" v-model="settingValue" variant="outlined" density="compact" label="Boolean value" color="accent" :rules="rules.booleanValue" />
            <json-schema-setting-editor v-else-if="isSchemaValue" v-model="settingValue" />
            <code-editor v-else-if="valueType" v-model="settingValue" language="json" />
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn variant="elevated" color="accent" dark @click="save">
        {{ saveActionName }}
      </v-btn>
      <v-btn variant="elevated" color="secondary" text @click="cancel">{{ $t("setting-manage-cancel-action", { $: "Cancel" }) }}</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import SettingsContextService, { SETTING_CONTEXT } from "@/services/settings-context.service";
import { mapActions, mapState } from "pinia";
import CodeEditor from "@/components/CodeEditor.vue";
import { useSettingsStore } from "@/store/SettingsStore";
import { useSnackbarsStore } from "@/store/SnackbarsStore";
import { $t } from "@/i18n";
import JsonSchemaSettingEditor from "@/components/Settings/JsonSchemaSettingEditor.vue";

export default {
  name: "SettingsDialog",
  components: { JsonSchemaSettingEditor, CodeEditor },
  props: {
    value: {
      type: [Object, null],
      default: null
    },
    editMode: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    valid: true,
    key: "",
    context: SettingsContextService.getContextByName("tenant").value,
    contexts: SETTING_CONTEXT.filter((x) => x.value > 100),
    profile: null,
    settingValue: null,
    valueType: null,
    editorOptions: {
      value: "one two three"
    },
    items: ["Foo", "Bar", "Fizz", "Buzz"]
  }),
  computed: {
    ...mapState(useSettingsStore, ["availableKeys"]),
    valueTypes() {
      if (this.key === "") return [];
      const foundKey = this.availableKeys.find((x) => x.key === this.key);
      if (!foundKey) return [];
      return [foundKey.type];
    },
    rules() {
      return {
        stringValue: [(v) => (v !== undefined && v !== null && v !== "") || $t("setting-manage-onStringValueRequired-label", { $: "Required" })],
        numberValue: [(v) => (v !== undefined && v !== null && !isNaN(+v)) || $t("setting-manage-onNumberValueRequired-label", { $: "Required" })],
        booleanValue: [(v) => (v !== undefined && v !== null && (v === false || v === true)) || $t("setting-manage-onBooleanValueRequired-label", { $: "Required" })]
      };
    },
    isComplexValue() {
      return !this.isStringValue && !this.isNumberValue && !this.isBooleanValue;
    },
    isStringValue() {
      return this.valueType === "System.String" || this.valueType === "CDEMS.Domain.Core.Settings.Models.JiraSettings";
    },
    isNumberValue() {
      return this.valueType === "System.Int32";
    },
    isBooleanValue() {
      return this.valueType === "System.Boolean";
    },
    isSchemaValue() {
      return this.valueType === "CDEMS.Domain.Core.Settings.Models.JsonSchemaSetting";
    },
    title() {
      if (this.editMode) {
        return $t("setting-manage-update-label", { $: "Update setting" });
      } else {
        return $t("setting-manage-create-label", { $: "Create setting" });
      }
    },
    saveActionName() {
      if (this.editMode) {
        return $t("setting-manage-update-action", { $: "Update" });
      } else {
        return $t("setting-manage-create-action", { $: "Create" });
      }
    }
  },
  watch: {
    value: {
      handler() {
        this.setValues();
      },
      immediate: true
    }
  },
  methods: {
    ...mapActions(useSnackbarsStore, ["createSnackbar"]),
    setValues() {
      if (this.editMode) {
        const { key, value, context, profile, metadata } = this.value;
        this.key = key;
        this.context = context;
        this.profile = profile;
        this.valueType = metadata.value.type;

        if (this.isComplexValue) {
          this.settingValue = JSON.stringify(value, 0, 2);
        } else {
          this.settingValue = value;
        }
      } else {
        this.resetFields();
      }
    },
    async save() {
      await this.validateFields();

      if (this.valid) {
        if (this.editMode) {
          this.$emit("update", {
            setting: {
              id: this.value.id,
              value: this.provideValue()
            }, onUpdated: () => {
              this.resetFields();
            }
          });
        } else {
          this.$emit("save", {
            setting: {
              key: this.key,
              context: this.context,
              profile: this.profile ?? "",
              value: this.provideValue()
            }, onSaved: () => {
              this.resetFields();
            }
          });
        }
      }
    },
    cancel() {
      this.$emit("cancel");
      this.setValues();
    },
    resetFields() {
      this.key = "";
      this.context = SettingsContextService.getContextByName("tenant").value;
      this.settingValue = null;
      this.valueType = null;
      this.profile = null;
    },
    provideValue() {
      if (this.isComplexValue) {
        return JSON.parse(this.settingValue);
      } else if (this.isBooleanValue) {
        return this.settingValue;
      } else if (this.isNumberValue) {
        return +this.settingValue;
      }
      return this.settingValue;
    },
    async validateFields() {
      const result = await this.$refs.form?.validate(true);
      if (this.isComplexValue) {
        try {
          JSON.parse(this.settingValue);
        } catch (e) {
          await this.createSnackbar({
            message: e.message,
            type: "error"
          });
          throw e;
        }
      }
      return result;
    },
    onEditorError(count) {
      if (count > 0 && this.valid) {
        this.valid = false;
      }
      if (count === 0 && !this.valid) {
        this.valid = true;
      }
    }
  }
};
</script>

<style lang="scss" scoped></style>
