import "@js-joda/timezone";
import "@js-joda/locale_en";
import { GanttActionManager, getOS, IDataLoaderOptions, IGanttWorker, OperatingSystem, TimeLineStyleSetting } from "./Core";
import {
  ActivityRendererRegistry,
  AutoLineManager,
  CalculatedRowChartScaleProvider,
  ChronoUnitTimelineModel,
  DefaultNavigationScheme,
  FixedRowChartScaleProvider,
  FixedScaleLineManager,
  GanttEvents,
  GanttException,
  GanttSettings,
  ICalendar,
  IContext,
  IGanttSettingsLoader,
  ILinesManager,
  IocContainer,
  IocSymbols,
  IRowChartScaleProvider,
  Layer,
  Lifecycle,
  ObservableDataSet,
  onActivationLifecycle,
  onDeactivationLifecycle,
  Row,
  RowChartRepository,
  RowFactory,
  SettingKey,
  TimelineDataLoader,
  TimelineManager,
  TimelineModel,
  WeekendCalendar,
  WeekendCalendarActivity
} from "./Core";
import {
  AboveRowsContentCanvasContainer,
  ActivityTooltipRendererRegistry,
  BelowRowsContentCanvasContainer,
  ContextMenuManager,
  DatelineBlock,
  DragAndDropManager,
  Eventline,
  EventlineContainer,
  EventLineLayer,
  GanttContainer,
  GraphicsContainer,
  InfoHeaderContainer,
  KeyboardNavigationManager,
  LassoLayer,
  LayerManager,
  NowLineLayer,
  RowContainer,
  rowContainerFactory,
  RowInfoColumnCellRegistry,
  RowInfoColumnProvider,
  RowManager,
  RowsContainer,
  TimelineBlock,
  TimelineContainer,
  TimelineHoverLayer,
  TopBarContainer,
  ViewportPointerEventNoneScrollGlass,
  ViewportRowsContainer
} from "./View";
import { buildProviderModule } from "inversify-binding-decorators";
import { AsyncContainerModule, interfaces } from "inversify";
import GanttWorkerUrl from "./Worker/GanttWorkerInstance?worker&url";
import type { Remote } from "comlink";
import { wrap } from "comlink";
import { ChronoUnit, Instant } from "@js-joda/core";
import { BehaviorSubject } from "rxjs";

import "./gantt.scss";
import { WeekendCalendarActivityRenderer } from "./Worker";
import { GanttStyleProvider } from "./View/GanttStyleProvider";

export interface IGanttOptions<TResource extends Row<any, any, any>> {
  container: HTMLDivElement;
  dataLoaderOptions?: IDataLoaderOptions;
  modules?: (Function | string)[];
  webWorkerModules?: string[];
  rowFactoryIocExtension?: (ioc: interfaces.Container) => void;
  customGanttWorker?: any;
  dragAndDropDataStore?: IGanttDragAndDropDataStore;
}

export interface IGanttDragAndDropDataStore {
  [key: string]: any;
}

export class Gantt<TResource extends Row<any, any, any>> extends Lifecycle {
  private readonly _options: IGanttOptions<TResource>;
  private readonly _ioc: IocContainer;
  private _iocModule: AsyncContainerModule;
  private _ganttWorker: Worker;
  private _ganttWorkerWrapped: Remote<IGanttWorker>;

  private _layers$$ = new BehaviorSubject<Layer[]>([]);
  private _rows = new ObservableDataSet<Row<any, any, any>>();
  private _settings: GanttSettings;

  constructor(options: IGanttOptions<TResource>) {
    super();
    this._options = options;
    this._ioc = new IocContainer({ skipBaseClassChecks: true });
    if (!this._options) {
      throw new GanttException("Options are required");
    }
    if (!this._options || !this._options.container) {
      throw new GanttException("Container is required");
    }
  }

  get IocContainer(): IocContainer {
    return this._ioc;
  }

  get options(): IGanttOptions<TResource> {
    return this._options;
  }

  get settings(): GanttSettings {
    return this._ioc.get(GanttSettings);
  }

  public async beforeInitialize(): Promise<void> {
    console.debug("[Gantt] Initializing Gantt...");
    this._ganttWorker = this._options.customGanttWorker ?? new Worker(new URL(GanttWorkerUrl, import.meta.url), { type: "module" });
    this._ganttWorkerWrapped = wrap<IGanttWorker>(this._ganttWorker);
    await this._ganttWorkerWrapped.isInitialized;
    for (const webWorkerModule of this._options.webWorkerModules ?? []) {
      await this._ganttWorkerWrapped.loadModule(webWorkerModule);
    }
    await this.initializeIocModule();
    await this.setDefaultSettings();
    for (const module of this._options.modules ?? []) {
      await this.loadModule(module);
    }

    // do binding of system layers
    const layerManager = this._ioc.get<LayerManager>(IocSymbols.LayerManager);
    layerManager.bindBelowRowContentSystemLayers(this._ioc);
    layerManager.bindAboveRowContentSystemLayers(this._ioc);
  }

  public async afterInitialize(): Promise<void> {
    const settingsLoaderExists = this._ioc.isBound(IocSymbols.GanttSettingsLoader);
    if (settingsLoaderExists) {
      const settingLoaders = await this._ioc.getAllAsync<IGanttSettingsLoader>(IocSymbols.GanttSettingsLoader);
      for (const settingsLoader of settingLoaders) {
        await settingsLoader.loadSettings(this._settings, this._ioc);
      }
      await this.synchronizeSettings();
    }
    console.debug("[Gantt] Gantt initialized!");
    // start gantt rendering by requesting first DIV container
    await this._ioc.getAsync(GanttContainer);
    // initialize row manager!
    await this._ioc.getAsync(RowManager);
    // initialize drag and drop manager
    await this._ioc.getAsync(DragAndDropManager);
    // initialize Keyboard navigation manager
    await this._ioc.getAsync(KeyboardNavigationManager);
    // initialize Context menu manager
    await this._ioc.getAsync(ContextMenuManager);

    // set timeline start
    const startTime = this._settings.getSetting<number>(SettingKey.START_TIME) ?? Instant.now().toEpochMilli();
    await this._ioc
      .get(TimelineManager)
      .setTimelineStart(
        Instant.ofEpochMilli(startTime),
        ChronoUnit[(this._settings.getSetting<string>(SettingKey.TIMELINE_UNIT)?.toUpperCase() ?? "DAYS") as keyof typeof ChronoUnit],
        this._settings.getSetting<number>(SettingKey.TIMELINE_UNIT_WIDTH) ?? 400
      );
  }

  async beforeDestroy(): Promise<void> {
    await this._ganttWorkerWrapped.clearSubscriptions();
    await this._ganttWorker.terminate();
    await this._ioc.unloadAsync(this._iocModule);
    await super.beforeDestroy();
  }

  public async destroy(): Promise<void> {
    await super.destroy();
    console.debug("[Gantt] Gantt destroyed!");
  }

  public async synchronizeSettings(): Promise<void> {
    await this._ganttWorkerWrapped.synchronizeSettings(JSON.parse(JSON.stringify(this._settings.settings)));
  }

  public async initializeDataLoader(): Promise<void> {
    // console.log("[Gantt] Initializing data loader...");
    try {
      const tm = await this._ioc.get(TimelineManager);
      await this._ganttWorkerWrapped.synchronizeTimeline(tm.getSynchronizationModel());
      await this._ganttWorkerWrapped.initialLoad(tm.startTime.toEpochMilli(), tm.endTime.toEpochMilli(), this._options.dataLoaderOptions);
    } catch (e) {
      console.error("GanttWorker exception: ", e);
    }
  }

  public async refreshData(): Promise<void> {
    await (await this._ioc.getAsync(TimelineDataLoader)).refreshData();
  }

  private async initializeIocModule(): Promise<void> {
    this._ioc.load(buildProviderModule());
    this._iocModule = new AsyncContainerModule(async (bind, unbind, isBound, rebind, unbindAsync, onActivation, onDeactivation) => {
      bind<IContext>(IocSymbols.Context).toConstantValue({
        isWebWorker: false,
        isDom: true
      } as IContext);

      bind<Remote<IGanttWorker>>(IocSymbols.GanttWorkerSymbol).toConstantValue(this._ganttWorkerWrapped);
      bind<Worker>(IocSymbols.GanttWorkerInstanceSymbol).toConstantValue(this._ganttWorker);

      bind<TimelineModel<any>>(IocSymbols.TimelineSymbol).toConstantValue(new ChronoUnitTimelineModel());
      bind<TimelineManager>(TimelineManager).to(TimelineManager).inSingletonScope();
      onActivation(TimelineManager, onActivationLifecycle);
      onDeactivation(TimelineManager, onDeactivationLifecycle);

      bind<GanttSettings>(GanttSettings).to(GanttSettings).inSingletonScope();

      bind<IGanttOptions<any>>(IocSymbols.GanttOptionsSymbol).toConstantValue(this._options);
      bind<IocContainer>(IocContainer).toConstantValue(this._ioc);
      bind<HTMLDivElement>(IocSymbols.HtmlContainerSymbol).toConstantValue(this._options.container);
      bind<BehaviorSubject<Layer[]>>(IocSymbols.LayersSymbol).toConstantValue(this._layers$$);
      bind<ObservableDataSet<Row<any, any, any>>>(IocSymbols.RowsSymbol).toConstantValue(this._rows);
      bind<RowFactory>(IocSymbols.RowFactorySymbol).to(RowFactory).inSingletonScope().onActivation(onActivationLifecycle);
      bind<ICalendar<any>[]>(IocSymbols.Calendars).toConstantValue([new WeekendCalendar()]);

      if (this._options.dragAndDropDataStore) {
        bind<IGanttDragAndDropDataStore>(IocSymbols.DragAndDropDataStore).toConstantValue(this._options.dragAndDropDataStore);
      }

      bind<GanttStyleProvider>(GanttStyleProvider).to(GanttStyleProvider).inSingletonScope();
      onActivation(GanttStyleProvider, onActivationLifecycle);
      onDeactivation(GanttStyleProvider, onDeactivationLifecycle);

      bind<GanttContainer>(GanttContainer).to(GanttContainer).inSingletonScope();
      onActivation(GanttContainer, onActivationLifecycle);
      onDeactivation(GanttContainer, onDeactivationLifecycle);

      bind<GanttEvents>(GanttEvents).to(GanttEvents).inSingletonScope();
      onActivation(GanttEvents, onActivationLifecycle);
      onDeactivation(GanttEvents, onDeactivationLifecycle);

      bind<RowManager>(RowManager).to(RowManager).inSingletonScope();
      onActivation(RowManager, onActivationLifecycle);
      onDeactivation(RowManager, onDeactivationLifecycle);

      bind<ActivityRendererRegistry>(ActivityRendererRegistry).to(ActivityRendererRegistry).inSingletonScope();
      onActivation(ActivityRendererRegistry, onActivationLifecycle);
      onDeactivation(ActivityRendererRegistry, onDeactivationLifecycle);

      bind<ActivityTooltipRendererRegistry>(ActivityTooltipRendererRegistry).to(ActivityTooltipRendererRegistry).inSingletonScope();
      onActivation(ActivityTooltipRendererRegistry, onActivationLifecycle);
      onDeactivation(ActivityTooltipRendererRegistry, onDeactivationLifecycle);

      bind<DragAndDropManager>(DragAndDropManager).to(DragAndDropManager).inSingletonScope();
      onActivation(DragAndDropManager, onActivationLifecycle);
      onDeactivation(DragAndDropManager, onDeactivationLifecycle);

      bind<KeyboardNavigationManager>(KeyboardNavigationManager).to(KeyboardNavigationManager).inSingletonScope();
      onActivation(KeyboardNavigationManager, onActivationLifecycle);
      onDeactivation(KeyboardNavigationManager, onDeactivationLifecycle);

      bind<ContextMenuManager>(ContextMenuManager).to(ContextMenuManager).inSingletonScope();
      onActivation(ContextMenuManager, onActivationLifecycle);
      onDeactivation(ContextMenuManager, onDeactivationLifecycle);

      bind<TimelineDataLoader>(TimelineDataLoader).to(TimelineDataLoader).inSingletonScope();
      onActivation(TimelineDataLoader, onActivationLifecycle);
      onDeactivation(TimelineDataLoader, onDeactivationLifecycle);

      bind<TopBarContainer>(TopBarContainer).to(TopBarContainer).inSingletonScope();
      bind<GraphicsContainer>(GraphicsContainer).to(GraphicsContainer).inSingletonScope();
      bind<InfoHeaderContainer>(InfoHeaderContainer).to(InfoHeaderContainer).inSingletonScope();
      bind<TimelineContainer>(TimelineContainer).to(TimelineContainer).inSingletonScope();
      bind<TimelineBlock>(TimelineBlock).to(TimelineBlock).inSingletonScope();
      bind<DatelineBlock>(DatelineBlock).to(DatelineBlock).inSingletonScope();
      bind<EventlineContainer>(EventlineContainer).to(EventlineContainer).inSingletonScope();
      bind<Eventline>(Eventline).to(Eventline).inSingletonScope();

      bind<RowsContainer>(RowsContainer).to(RowsContainer).inSingletonScope();
      bind<ViewportRowsContainer>(ViewportRowsContainer).to(ViewportRowsContainer).inSingletonScope();
      bind<ViewportPointerEventNoneScrollGlass>(ViewportPointerEventNoneScrollGlass).to(ViewportPointerEventNoneScrollGlass).inSingletonScope();
      bind<BelowRowsContentCanvasContainer>(BelowRowsContentCanvasContainer).to(BelowRowsContentCanvasContainer).inSingletonScope();
      bind<AboveRowsContentCanvasContainer>(AboveRowsContentCanvasContainer).to(AboveRowsContentCanvasContainer).inSingletonScope();
      bind<LayerManager>(IocSymbols.LayerManager).to(LayerManager).inSingletonScope();

      bind<interfaces.Factory<RowContainer<TResource>>>(IocSymbols.RowContainerFactorySymbol).toProvider(rowContainerFactory);

      bind<ILinesManager<any>>(IocSymbols.LinesManagerClass).toConstructor(AutoLineManager).whenTargetNamed("AutoLineManager");
      bind<ILinesManager<any>>(IocSymbols.LinesManagerClass).toConstructor(FixedScaleLineManager).whenTargetNamed("FixedScaleLineManager");

      bind<RowChartRepository>(RowChartRepository).toConstantValue(new RowChartRepository());
      bind<IRowChartScaleProvider>(IocSymbols.RowChartScaleProviderClass).toConstructor(FixedRowChartScaleProvider).whenTargetNamed("FixedRowChartScaleProvider");
      bind<IRowChartScaleProvider>(IocSymbols.RowChartScaleProviderClass).toConstructor(CalculatedRowChartScaleProvider).whenTargetNamed("CalculatedRowChartScaleProvider");

      bind<RowInfoColumnProvider>(IocSymbols.RowInfoColumnProvider).to(RowInfoColumnProvider).inSingletonScope().onActivation(onActivationLifecycle);
      bind<RowInfoColumnCellRegistry>(IocSymbols.RowInfoColumnCellRegistry).to(RowInfoColumnCellRegistry).inSingletonScope().onActivation(onActivationLifecycle);

      if (this._options.rowFactoryIocExtension) {
        bind(IocSymbols.RowFactoryIocExtension).toFunction(this._options.rowFactoryIocExtension);
      }

      bind<DefaultNavigationScheme>(IocSymbols.NavigationScheme).to(DefaultNavigationScheme).inSingletonScope().onActivation(onActivationLifecycle);

      const am = bind<GanttActionManager>(IocSymbols.GanttActionManager).to(GanttActionManager).inSingletonScope();
      am.onActivation(onActivationLifecycle);
      am.onDeactivation(onDeactivationLifecycle);
    });
    await this._ioc.loadAsync(this._iocModule);
    console.debug("[Gantt] Gantt IOC module initialized!");
  }

  private async setDefaultSettings() {
    this._settings = this._ioc.get(GanttSettings);
    this._settings.setSettings([
      { key: SettingKey.START_TIME, value: Instant.now().toEpochMilli() },
      { key: SettingKey.TIMELINE_UNIT, value: ChronoUnit.DAYS.toString().toUpperCase() },
      { key: SettingKey.TIMELINE_UNIT_WIDTH, value: 400 },
      {
        key: SettingKey.TIMELINE_STYLE, value: {
          top: {
            width: 1.5,
            color: "rgba(0, 0, 0, 0.38)"
          },
          bottom: {
            day: {
              width: 1.5,
              color: "rgba(0, 0, 0, 0.38)"
            },
            other: {
              width: 0.5,
              color: "rgba(187,187,187,0.75)"
            }
          }
        } as TimeLineStyleSetting
      },
      { key: SettingKey.DATA_LOADER_DELAY, value: 500 },
      { key: SettingKey.ROW_FIXED_SCALE, value: { min: 0, max: 1 } },
      { key: SettingKey.TOOLTIP_OPEN_DELAY, value: 700 },
      { key: SettingKey.ROW_PADDING, value: { top: 10, right: 0, bottom: 10, left: 0 } },
      { key: SettingKey.ROW_FIXED_SCALE_ACTIVITY_DISPLAY_RANGE, value: { from: 0, to: 1 } },
      { key: SettingKey.ROW_DEFAULT_HEIGHT, value: 80 },
      { key: SettingKey.LINE_MANAGER, value: "AutoLineManager" },
      { key: SettingKey.ROW_CHART_SCALE_PROVIDER, value: "FixedRowChartScaleProvider" },
      { key: SettingKey.CHART_SHOW, value: true },
      { key: SettingKey.CHART_HEADER_SHOW, value: true },
      { key: SettingKey.CHART_LINE_WIDTH, value: 2 },
      { key: SettingKey.CHART_HEADER_WIDTH, value: 50 },
      { key: SettingKey.CHART_NEGATIVE_BOUNDARY_VALUE, value: 0 },
      { key: SettingKey.CHART_POSITIVE_COLOR, value: "rgb(4,211,0)" },
      { key: SettingKey.CHART_NEGATIVE_COLOR, value: "rgba(255,0,0,0.49)" },
      { key: SettingKey.ROW_EXPAND_BY_DEFAULT, value: false },
      { key: SettingKey.DEBUG_SHOW_INFO, value: false },
      { key: SettingKey.HELP_SHOW_NAV_KEYBOARD, value: false },
      { key: SettingKey.HELP_SHOW_LAYER, value: false },
      { key: SettingKey.HELP_SHOW_INFO, value: true },
      {
        key: SettingKey.HELP_I18N_LABELS,
        value: {
          "help.layer.keyboardNavigation": "Keyboard navigation",
          "help.layer.keyboardUp": "Up: W or Arrow Up",
          "help.layer.keyboardDown": "Down: S or Arrow Down",
          "help.layer.keyboardLeft": "Left: A or Arrow Left",
          "help.layer.keyboardRight": "Right: D or Arrow Right",
          "help.layer.keyboardZoomIn": "Zoom in: Q or +",
          "help.layer.keyboardZoomOut": "Zoom out: E or -",
          "help.layer.selection": "Selection",
          "help.layer.selectionSingle": `${getOS() === OperatingSystem.Mac ? "⌘" : "CTRL"} + Left mouse button click`,
          "help.layer.selectionMultiple": "SHIFT ⇧ + Left mouse button press",
          "help.layer.showKeyboardNav": "Show keyboard navigation arrows: N",
          "help.layer.showDebugInfo": "Show debug info: ?",
          "help.layer.hideThisInfo": "Hide this info: I"
        }
      }
    ]);

    const layerManager = this._ioc.get<LayerManager>(IocSymbols.LayerManager);
    // below row content system layers
    layerManager.addBelowRowContentSystemLayers(TimelineHoverLayer);

    // above row content system layers
    layerManager.addAboveRowContentSystemLayers(LassoLayer);
    layerManager.addAboveRowContentSystemLayers(EventLineLayer);
    layerManager.addAboveRowContentSystemLayers(NowLineLayer);

    const activityRendererRegistry = await this._ioc.getAsync(ActivityRendererRegistry);
    activityRendererRegistry.registerActivityRenderer(WeekendCalendarActivity, WeekendCalendarActivityRenderer);
  }

  private async loadModule(moduleOrPath: Function | string) {
    const module = moduleOrPath instanceof Function ? await moduleOrPath() : await import(/* @vite-ignore */ moduleOrPath);
    if (!module["name"] || typeof module["name"] !== "string") {
      console.warn(`[Gantt] Cannot load Gantt module. Module ${moduleOrPath} does not have a name!`);
      return;
    }
    if (module["load"] && typeof module["load"] === "function") {
      await module.load(this._ioc, this._options);
      console.debug(`[Gantt] Gantt module loaded: [${module["name"]}]`);
    } else {
      console.warn(`[Gantt] Cannot load Gantt module. Module ${moduleOrPath} does not have a load function!`);
    }
  }
}
