import {
  ChronoUnitUtils,
  createDatelineHoverEvent,
  createGraphicsMousePositionEvent,
  DatelineCell,
  DatelineScaleManager,
  GanttEvents,
  Position,
  TimeInterval,
  TimeLineStyleSetting
} from "../../Core";
import { GanttCanvas } from "../GanttCanvas";
import { ChronoField, ChronoUnit, ZonedDateTime } from "@js-joda/core";
import { fromEvent } from "rxjs";

export class DatelineScaleBlock extends GanttCanvas {
  private _width = 0;
  private _hoveredCell: DatelineCell | undefined;

  constructor(ganttEvents: GanttEvents, private _datelineScaleManager: DatelineScaleManager, private _timeLineStyleSetting: TimeLineStyleSetting | undefined) {
    super(ganttEvents, `${DatelineScaleBlock.name}-${_datelineScaleManager.position}`, `dateline-scale position-${_datelineScaleManager.position}`);
  }

  async beforeInitialize(): Promise<void> {
    await super.beforeInitialize();
    this._width = this.element.clientWidth;
  }

  async afterInitialize(): Promise<void> {
    await super.afterInitialize();
    this.subscribe(fromEvent<MouseEvent>(this.element, "mouseenter").subscribe(this.onHoverIn.bind(this)));
    this.subscribe(fromEvent<MouseEvent>(this.element, "mousemove").subscribe(this.onMouseMove.bind(this)));
    this.subscribe(fromEvent<MouseEvent>(this.element, "mouseleave").subscribe(this.onHoverOut.bind(this)));
  }

  get datelineScaleManager(): DatelineScaleManager {
    return this._datelineScaleManager;
  }

  set datelineScaleManager(value: DatelineScaleManager) {
    this._datelineScaleManager = value;
    this._identifier = `${DatelineScaleBlock.name}-${this._datelineScaleManager.position}`;
    const className = `dateline-scale position-${this._datelineScaleManager.position}`;
    if (this.element.className !== className) {
      this.element.className = className;
    }
  }

  async doDrawFromBatch(): Promise<void> {
    const heightOffset = 2;
    // console.log("doDrawFromBatch", this.identifier, 0, 0, this.element.clientWidth, this.element.clientHeight + heightOffset, this._datelineScaleManager.datelineCells);
    const context = this.context;
    context.textAlign = "center";
    context.clearRect(0, 0, this.element.clientWidth, this.element.clientHeight + heightOffset);
    for (const dc of this._datelineScaleManager.datelineCells) {
      const { text, textWidth, cellWidth } = dc;
      const x = dc.x;
      const height = this.element.clientHeight;
      const zonedStartTime = this.getZonedStartTime(this._datelineScaleManager, dc);
      const dayOfWeek = this.getDayOfWeek(zonedStartTime);
      const amPm = this.getAmPm(zonedStartTime);
      const fill = this.getFill(this._datelineScaleManager, dayOfWeek, amPm);
      if (fill) {
        const pfill = context.fillStyle;
        context.fillStyle = fill;
        context.fillRect(x, 0, cellWidth, height + heightOffset);
        context.fillStyle = pfill;
      }
      context.font = this._datelineScaleManager.font;
      context.fillStyle = "rgba(0, 0, 0, 0.87)";
      context.fillText(text, x + Math.round(cellWidth / 2), Math.round(height / 2) + heightOffset * 2);
      const isStartOfDay = zonedStartTime.hour() === 0;

      switch (this._datelineScaleManager.position) {
        case Position.TOP:
          context.lineWidth = this._timeLineStyleSetting?.top.width ?? 1.5;
          context.strokeStyle = this._timeLineStyleSetting?.top.color ?? "rgba(0, 0, 0, 0.38)";
          // context.strokeStyle = "rgba(0, 0, 0, 0.38)";
          break;
        case Position.BOTTOM:
          context.lineWidth = isStartOfDay ? (this._timeLineStyleSetting?.bottom.day.width ?? 1.5) : (this._timeLineStyleSetting?.bottom.other.width ?? 0.5);
          context.strokeStyle = isStartOfDay ? (this._timeLineStyleSetting?.bottom.day.color ?? "rgba(0, 0, 0, 0.38)") : (this._timeLineStyleSetting?.bottom.other.color ?? "rgba(187,187,187,0.75)");
          // context.strokeStyle = "rgba(187,187,187,0.75)";
          break;
        default:
          context.lineWidth = 1;
          context.strokeStyle = "#dc2525";
          break;
      }

      const blurOffset = 0.5;
      context.beginPath();
      context.moveTo(x + blurOffset, 0);
      context.lineTo(x + blurOffset, height + heightOffset);
      context.closePath();
      context.stroke();
    }
    // context.translate(-0.5, -0.5);
  }

  private getZonedStartTime(sm: DatelineScaleManager, dc: DatelineCell) {
    return ChronoUnitUtils.truncateZonedTime(ZonedDateTime.ofInstant(dc.startTime, sm.zoneId), dc.resolution.temporalUnit, 1, sm.firstDayOfWeek);
  }

  private getDayOfWeek(zonedStartTime: ZonedDateTime): string {
    return zonedStartTime.dayOfWeek().toString().toLowerCase();
  }

  private getAmPm(zonedStatTime: ZonedDateTime): number {
    return zonedStatTime.get(ChronoField.AMPM_OF_DAY);
  }

  private getFill(sm: DatelineScaleManager, dayOfWeek: string, ampm: number): string | null {
    // eslint-disable-next-line default-case
    switch (sm.resolution?.temporalUnit) {
      case ChronoUnit.DAYS: {
        if (dayOfWeek === "saturday" || dayOfWeek === "sunday") {
          return "rgb(234,234,234)";
        }
      }
        break;
      case ChronoUnit.HALF_DAYS: {
        if (ampm === 1) {
          // 0 - am, 1 - pm
          return "rgba(255, 255, 255, 0.2)";
        }
      }
        break;
      // case ChronoUnit.MONTHS:
      //   zonedStartTime = ChronoUnitUtils.truncateZonedTime(ZonedDateTime.ofInstant(dc.startTime, sm.zoneId), dc.resolution.temporalUnit, 1, sm.firstDayOfWeek);
      //   break;
      default:
        return null;
    }
    return null;
  }

  private getStrokeColor(sm: DatelineScaleManager, dayOfWeek: string, ampm: number): string | null {
    // eslint-disable-next-line default-case
    switch (sm.resolution?.temporalUnit) {
      case ChronoUnit.DAYS: {
        if (dayOfWeek === "saturday" || dayOfWeek === "sunday") {
          return "rgb(234,234,234)";
        }
      }
        break;
      case ChronoUnit.HALF_DAYS: {
        if (ampm === 1) {
          // 0 - am, 1 - pm
          return "rgba(255, 255, 255, 0.2)";
        }
      }
        break;
      default:
        return null;
    }
    return null;
  }

  private onHoverIn(e: MouseEvent) {
    const { x } = createGraphicsMousePositionEvent(e, this.element);
    const cell = this._datelineScaleManager.datelineCells.find((dc) => x >= dc.x && x <= dc.x + dc.cellWidth);
    if (cell) {
      if (this._hoveredCell !== cell) {
        this._hoveredCell = cell;
        this._ganttEvents.onDatelineHoverEvent(createDatelineHoverEvent(cell.startTime, cell.endTime));
      }
    }
  }

  private onMouseMove(e: MouseEvent) {
    const { x } = createGraphicsMousePositionEvent(e, this.element);
    const cell = this._datelineScaleManager.datelineCells.find((dc) => x >= dc.x && x <= dc.x + dc.cellWidth);
    if (cell) {
      if (this._hoveredCell !== cell) {
        this._hoveredCell = cell;
        this._ganttEvents.onDatelineHoverEvent(createDatelineHoverEvent(cell.startTime, cell.endTime));
      }
    }
  }

  private onHoverOut() {
    this._hoveredCell = undefined;
    this._ganttEvents.onDatelineHoverEvent(null);
  }
}
