import { ApiClient } from "@/services/api/common.api";
import {
  AgreementDto,
  CreateAgreementCommand,
  CreateAgreementGroupCommand,
  CreateResourceResponse,
  GetAgreementByIdQuery,
  GetAgreementsByGroupIdQuery,
  GetAgreementsByGroupIdResponse,
  GetAgreementsQuery,
  GetAgreementsResponse,
  UpdateAgreementCommand,
  UpdateAgreementGroupCommand
} from "@masta/generated-model";

export default {
  async create(request: CreateAgreementCommand) {
    return ApiClient.post<CreateResourceResponse>("/agreements", request);
  },
  async update(request: UpdateAgreementCommand) {
    return ApiClient.put<undefined>(`/agreements/${request.id}`, request);
  },
  async remove(id: string) {
    return ApiClient.delete(`/agreements/${id}`);
  },
  async createGroup(request: CreateAgreementGroupCommand) {
    return ApiClient.post<CreateResourceResponse>("/agreementGroups", request);
  },
  async updateGroup(request: UpdateAgreementGroupCommand) {
    return ApiClient.put<undefined>(`/agreementGroups/${request.id}`, request);
  },
  async removeGroup(id: string) {
    return ApiClient.delete(`/agreementGroups/${id}`);
  },
  async getAll(request: GetAgreementsQuery) {
    return ApiClient.get<GetAgreementsResponse>(`/agreements/${request.scenarioId}`);
  },
  async getById(request: GetAgreementByIdQuery) {
    return ApiClient.get<AgreementDto>(`/agreements/${request.scenarioId}/${request.id}`);
  },
  async getByGroupId(request: GetAgreementsByGroupIdQuery) {
    return ApiClient.get<GetAgreementsByGroupIdResponse>(`/agreements/${request.scenarioId}/group/${request.groupId ?? ""}`);
  }
};
