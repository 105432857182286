import { createVuetify } from "vuetify";
import { aliases, mdi } from "vuetify/iconsets/mdi";
import "vuetify/styles";
import "@/styles/styles.scss";
import "vue-toastification/dist/index.css";
// import * as components from "vuetify/components";
// import * as directives from "vuetify/directives";
import { en } from "vuetify/locale";

import { loadFonts } from "@/plugins/webfontloader";

loadFonts();

export default createVuetify({
  icons: {
    defaultSet: "mdi",
    aliases,
    sets: {
      mdi
    }
  },
  locale: {
    locale: "en",
    fallback: "en",
    messages: { en }
  },
  // components,
  // directives,
  theme: {
    defaultTheme: "light",
    themes: {
      light: {
        dark: false,
        colors: {
          primary: "#7A63FF",
          secondary: "#424242",
          accent: "#82B1FF",
          golden: "#dec32f",
          error: "#FF5252",
          info: "#2196F3",
          success: "#4CAF50",
          amber: "#FFC107",
          black: "#000000",
          white: "#FFFFFF",
          "indigo-lighten-5": "#E8EAF6",
          "material-design-grey-lighten-4": "#F5F5F5",
          "material-design-grey-lighten-5": "#FAFAFA",
          "gantt-grey": "#EAEAEA"
        }
      },
      dark: {
        dark: true,
        colors: {
          primary: "#7A63FF",
          secondary: "#939393",
          accent: "#82B1FF",
          golden: "#dec32f",
          error: "#FF5252",
          info: "#2196F3",
          success: "#4CAF50",
          amber: "#FFC107",
          black: "#000000",
          white: "#FFFFFF",
          "indigo-lighten-5": "#E8EAF6",
          "material-design-grey-lighten-4": "#F5F5F5",
          "material-design-grey-lighten-5": "#FAFAFA",
          "gantt-grey": "#EAEAEA"
        }
      }
    }
  }
});
