<script lang="ts" setup>
import { useTheme } from "vuetify";

document.title = "MeMOM";
const { global } = useTheme();
const themeName = global.name;
</script>

<template>
  <v-app :theme="themeName">
    <suspense>
      <router-view />
    </suspense>
  </v-app>
</template>
