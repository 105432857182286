<script lang="ts" setup>
import { ref } from "vue";

export type FilterGridActionItemValue = string | number;

export interface FilterGridActionItem {
  value: FilterGridActionItemValue | FilterGridActionItemValue[];
  text: string;
}

interface Props {
  /**
   * An array of FilterGridActionItem objects representing filter actions.
   *
   * The 'items' property holds an array of objects, where each object represents a filter action.
   * Each filter action is defined by two properties:
   * - 'text': The name of the filter, which is displayed on the button.
   * - 'value': A unique identifier for the filter action, allowing you to track selected buttons.
   *
   * Use this 'items' property to create a group of buttons that correspond to different filter actions
   * in grid. The 'text' property provides the button label, while the 'value' property
   * helps you identify which buttons have been selected when interacting with the filter grid.
   *
   * Example usage:
   * const items = [
   *   { text: 'Filter A', value: 'filter_a' },
   *   { text: 'Filter B', value: 'filter_b' },
   *   { text: 'Filter C', value: 'filter_c' },
   *   { text: 'Filter D', value: 'filter_d' },
   *   // Add more filter items as needed...
   * ];
   */
  items: FilterGridActionItem[];

  prependText?: string | null;

  /**
   * If set to 'true,' this property displays a filter icon separator on the left side of the component.
   * Default value: `true`
   */
  icon?: boolean;

  /**
   * If set to 'true,' this property displays a vertical separator on the left side of the component.
   */
  separator?: boolean;

  /**
   * If set to 'true,' this conponent will grow to fill the remaining space from left (similar to align to left)
   * Default value: `true`
   */
  grow?: boolean;

  /**
   * This property set a size of action buttons inside the component.
   * Default value: `small`
   */
  size?: string | number | undefined;
}

const properties = withDefaults(defineProps<Props>(), {
  separator: false,
  tooltip: undefined,
  prependText: null,
  icon: true,
  grow: true,
  size: "small"
});

const selectedItems = ref<FilterGridActionItemValue[]>([]);

const emit = defineEmits<{
  /**
   * Notifies the application of filter model changes. This function is called when
   * a user selects a unique filter button from group of buttons.
   *
   * @param {any[] | undefined} value - The unique value that represent a filter action value.
   */
  (e: "filter-changed", value: FilterGridActionItemValue[] | undefined): void;
}>();

function onClearFilterAction() {
  selectedItems.value = [];
  emit("filter-changed", undefined);
}

/**
 * Updates the state of the selected filter buttons when filters have been selected from within the Grid.
 *
 * @param {any} gridFilterModel - An array of values representing the selected filters, specified in 'value' within the 'items' property.
 *
 * Example usage:
 * const items = [
 *   { text: 'Filter A', value: 'filter_a' },
 *   { text: 'Filter B', value: 'filter_b' },
 *   { text: 'Filter C', value: 'filter_c' },
 *   { text: 'Filter D', value: 'filter_d' },
 *   // Add more filter items as needed...
 * ];
 *
 * <filter-grid-action ref="filterGridActionRef" :items="items />
 *
 * filterGridActionRef.setFilterModel(['filter_b', 'filter_c'])
 *
 * After this call, the filters 'filter_b', 'filter_c' will remain selected
 */
function setFilterModel(gridFilterModel: any) {
  if (gridFilterModel) {
    selectedItems.value = Array.isArray(gridFilterModel) ? gridFilterModel : [gridFilterModel];
  } else {
    selectedItems.value = [];
  }
}

function toggleSelection(itemValue: FilterGridActionItemValue | FilterGridActionItemValue[]) {

  const consumeItem = (item: FilterGridActionItemValue) => {
    if (selectedItems.value.includes(item)) {
      selectedItems.value = selectedItems.value.filter((value) => value !== item);
    } else {
      selectedItems.value.push(item);
    }
  };
  const consumeItems = (item: FilterGridActionItemValue[]) => {
    if (item.some((value) => selectedItems.value.includes(value))) {
      selectedItems.value = selectedItems.value.filter((value) => !item.includes(value));
    } else {
      selectedItems.value = [...selectedItems.value, ...item];
    }
  };

  if (itemValue) {
    if (Array.isArray(itemValue)) {
      consumeItems(itemValue);
    } else {
      consumeItem(itemValue);
    }
  }

  const emitValue = selectedItems.value.length ? [...selectedItems.value] : undefined;
  emit("filter-changed", emitValue);
}

function isSelected(itemValue: FilterGridActionItemValue | FilterGridActionItemValue[]) {
  if (Array.isArray(itemValue)) {
    return itemValue.some((item) => selectedItems.value.includes(item));
  } else {
    return selectedItems.value.includes(itemValue);
  }
}

function getBtnColor(btnValue: any): string {
  return isSelected(btnValue) ? "primary" : "";
}

defineExpose({ setFilterModel });
</script>

<template>
  <div v-if="properties.grow" v-bind="$attrs" style="flex-grow: 1"></div>
  <div v-if="properties.separator" v-bind="$attrs" class="d-inline-flex  align-self-stretch text-secondary font-weight-bold pl-4 pr-4 filter-grid-action-separator">
    <v-divider vertical />
  </div>
  <v-tooltip v-for="(item, index) in items" :key="index" location="bottom" open-delay="600" class="filter-grid-action-tooltip" v-bind="$attrs">
    <template #activator="{ props }">
      <div class="d-inline-flex" :class="index != items.length - 1 ? 'pr-2' : ''">

        <!--  Glue the filter icon to the first element - this way they are always moved together to a new line during flex wrap -->
        <div v-if="icon && index == 0" class="d-flex justify-center align-center">
          <v-icon icon="mdi-filter" class="pt-1" />
        </div>

        <!--  Glue the prepend text to the first element - this way they are always moved together to a new line during flex wrap -->
        <div v-if="properties.prependText  && index == 0" class="d-flex justify-center align-center">
          <span>{{ properties.prependText }}</span>
        </div>

        <v-chip
          v-bind="props"
          variant="elevated"
          elevation="0"
          :size="size"
          slim
          density="compact"
          :color="getBtnColor(item.value)"
          :value="item.value"
          class="filter-grid-action-btn"
          :class="index == 0 && icon ? 'ml-2' : ''"
          @click="toggleSelection(item.value)"
        >
          {{ item.text }}
        </v-chip>

        <!--  Glue the close action to the last element - this way they are always moved together to a new line during flex wrap -->
        <div v-if="index == items.length - 1" class="d-flex justify-center align-center">
          <v-tooltip location="bottom" open-delay="300">
            <template #activator="{ props }">
              <v-btn v-bind="props" :size="size" variant="plain" density="compact" color="primary" icon @click.stop="onClearFilterAction">
                <v-icon icon="mdi-close-circle" />
              </v-btn>
            </template>
            <span>{{ $t("filter-grid-action-clear-tooltip", { $: "Reset predefined filters" }) }}</span>
          </v-tooltip>
        </div>
      </div>
    </template>
    <span>{{ $t("filter-grid-action-tooltip", { $: "Predefined filters to quickly narrow results" }) }}</span>
  </v-tooltip>
</template>

<style lang="scss" scoped>
.h-separator {
  height: 26px;
}

.filter-grid-action-btn {
  background-color: rgb(var(--v-theme-primary), 0.3);
  color: rgb(var(--v-theme-primary));
  min-height: 26px;
}
</style>
