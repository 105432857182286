import { ApiClient } from "@/services/api/common.api";
import {
  BomGraphDto,
  CreateMaterialCommand,
  CreateMaterialGroupCommand,
  CreateResourceResponse,
  GetMaterialsPaginatedQuery,
  IAgGridPaginationResponse,
  MaterialDto,
  UpdateMaterialCommand,
  UpdateMaterialGroupCommand
} from "@masta/generated-model";

export default {
  async getPaginated(request: GetMaterialsPaginatedQuery) {
    return ApiClient.post<IAgGridPaginationResponse<MaterialDto>>(`/materials`, request);
  },
  async getSingle(id: string, scenarioId: string) {
    return ApiClient.get<MaterialDto>(`/materials/${id}?scenarioId=${scenarioId}`);
  },
  async getMaterials({ scenarioId }: { scenarioId: string }) {
    return ApiClient.get<any>(`/materials?scenarioId=${scenarioId}`);
  },
  async create(request: Partial<CreateMaterialCommand>) {
    return ApiClient.put<CreateResourceResponse>(`/materials`, request);
  },
  async update(request: Partial<UpdateMaterialCommand>) {
    return ApiClient.put<string>(`/materials/${request.id}`, request);
  },
  async remove(id: string, scenarioId: string) {
    return ApiClient.delete(`/materials/${id}?scenarioId=${scenarioId}`);
  },
  async getBomGraph(id: string, scenarioId: string) {
    return ApiClient.get<BomGraphDto>(`/materials/${id}/bomgraph?scenarioId=${scenarioId}`);
  },
  async createGroup(request: CreateMaterialGroupCommand) {
    return ApiClient.post<CreateResourceResponse>("/materialGroups", request);
  },
  async updateGroup(request: UpdateMaterialGroupCommand) {
    return ApiClient.put<undefined>(`/materialGroups/${request.id}`, request);
  },
  async removeGroup(id: string, scenarioId: string) {
    return ApiClient.delete(`/materialGroups/${id}?scenarioId=${scenarioId}`);
  }
};
