<script setup lang="ts">
import SplitLayout from "@/components/Layout/SplitLayout.vue";
import { $t } from "@/i18n";
import Breadcrumbs from "@/components/Layout/Breadcrumbs.vue";
import { nameOrBusinessIdOrIdOrNull } from "@/components/ValueCellEditor/CommonFormatters";
import CloseCardButton from "@/components/CloseCardButton.vue";
import OrderLineGroupAssignmentGrid from "@/components/OrderLineGroups/OrderLineGroupAssignmentGrid.vue";
import OrderLineGroupGrid from "@/components/OrderLineGroups/OrderLineGroupGrid.vue";
import { onMounted, ref, watch } from "vue";
import { OrderLineGroupDto } from "@masta/generated-model";
import { isDefined } from "@vueuse/core";
import { useRoute, useRouter } from "vue-router";
import { useScenariosStore } from "@/store/ScenariosStore";
import { storeToRefs } from "pinia";
import ApiService from "@/services/api";

const router = useRouter();
const route = useRoute();
const scenariosStore = useScenariosStore();
const { selectedScenario } = storeToRefs(scenariosStore);
const splitLayoutRef = ref<typeof SplitLayout | null>(null);
const groupsGridRef = ref<typeof OrderLineGroupGrid | null>(null);
const assignmentsGridRef = ref<typeof OrderLineGroupAssignmentGrid | null>(null);
const selectedGroup = ref<OrderLineGroupDto | null | undefined>(null);

watch(
  () => route.query.groupId,
  async (groupId, _) => {
    if (isDefined(groupId)) {
      if (isDefined(selectedGroup.value) && selectedGroup.value?.id === groupId) {
        return;
      } else {
        const group = await fetchGroup(groupId as string);
        selectedGroup.value = group;

        showDetails();
      }
    } else {
      selectedGroup.value = null;
    }
  },
  { immediate: true }
);

onMounted(() => {
  if (selectedGroup.value == null) {
    closeDetails();
  }
});

function createSplitPanelGutterElement(index: number, direction: "horizontal" | "vertical") {
  const gutter = document.createElement("div");
  gutter.classList.add("gutter-order-line-groups");
  return gutter;
}

function showDetails() {
  splitLayoutRef.value?.showDetails();
}

function closeDetails() {
  splitLayoutRef.value?.closeDetails();
}

async function fetchGroup(groupId: string): Promise<OrderLineGroupDto | null | undefined> {
  try {
    if (!selectedScenario.value) {
      return null;
    }

    const reponse = await ApiService.orderLineGroups.getSingle(groupId, selectedScenario.value.id);
    return reponse.data;
  } catch (error) {
    console.error("Fetch group error", error);
  }

  return null;
}

async function onGroupSelected(group: OrderLineGroupDto) {
  if (isDefined(group)) {
    selectedGroup.value = group;

    showDetails();

    const currentQuery = router.currentRoute.value.query;
    const newQuery = Object.assign({}, currentQuery, { groupId: group.id });
    await router.push({ query: newQuery });
  }
}
</script>

<template>
  <split-layout ref="splitLayoutRef" identifier="orderLineGroups" :gutter="createSplitPanelGutterElement">
    <template #master>
      <v-card elevation="0" class="d-flex flex-column fill-height bg-material-design-grey-lighten-5">
        <v-card-title>
          <breadcrumbs>{{ $t("orderLineGroups-view-group-title", { $: "Order Line Groups" }) }}</breadcrumbs>
        </v-card-title>
        <v-card-text class="fill-height">
          <v-row class="fill-height" no-gutters>
            <v-col cols="12">
              <order-line-group-grid ref="groupsGridRef" @row-selected="onGroupSelected" />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </template>

    <template #detail>
      <v-card v-if="selectedGroup" elevation="7" class="d-flex flex-column fill-height orderLines-card">
        <close-card-button @click="closeDetails"></close-card-button>
        <v-card-title class="pt-2 pb-0 tabs">
          <breadcrumbs> {{ nameOrBusinessIdOrIdOrNull(selectedGroup) }} {{ $t("orderLineGroups-view-orderLines-title", { $: "Order Lines" }) }}</breadcrumbs>
        </v-card-title>
        <v-card-text class="pt-2">
          <order-line-group-assignment-grid ref="assignmentsGridRef" :selected-group="selectedGroup" />
        </v-card-text>
      </v-card>
      <v-card v-else elevation="7" class="d-flex flex-column fill-height no-orderLines-card">
        <close-card-button @click="closeDetails"></close-card-button>
        <v-card-text class="fill-height">
          <v-row class="fill-height" no-gutters>
            <v-col cols="12" class="d-flex align-center justify-center">
              <label class="text-secondary text-h6 font-weight-regular">
                {{ $t("orderLineGroups-view-noGroupSelected-label", { $: "No group selected" }) }}
              </label>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </template>
  </split-layout>
</template>

<style scoped lang="scss">
.gutter-order-line-groups {
  cursor: col-resize;
}

.orderLines-card {
  border: 2px solid rgb(var(--v-theme-primary));
  border-radius: 0;

  .tabs {
    .v-tab:not(.v-tab--selected) {
      border-bottom: 1px solid #e0e0e0;
    }
  }

  .close-card-button {
    position: absolute;
    top: 0px;
    right: 10px;
  }
}

.no-orderLines-card {
  border: 2px solid rgb(var(--v-theme-primary), 0);
  border-radius: 0;

  .close-card-button {
    position: absolute;
    top: 0px;
    right: 10px;
  }
}

.gutter-orders {
  cursor: col-resize;
}
</style>
