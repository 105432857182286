<script setup lang="ts">
import { $t } from "@/i18n";
import { computed, onMounted, reactive, ref } from "vue";
import { useTheme } from "vuetify";
import { useSnackbarsStore } from "@/store/SnackbarsStore";
import ApiService from "@/services/api";
import { useScenariosStore } from "@/store/ScenariosStore";

interface Props {
  resourceIds: string[];
}

const $props = defineProps<Props>();
const $emits = defineEmits(["calendars-generated"]);
const modelValue = defineModel<boolean>();

const theme = reactive(useTheme());
const snackbarsStore = useSnackbarsStore();
const scenarioStore = useScenariosStore();
const dates = ref();
const isDateRangeSelected = computed(() => {
  return !!(dates.value && dates.value.length === 2 && !!dates.value[0] && !!dates.value[1]);
});

onMounted(() => {
  const now = new Date();
  const startDate = new Date(now.getFullYear(), now.getMonth(), 1);
  startDate.setHours(0, 0, 0, 0);
  const endDate = new Date(now.getFullYear(), now.getMonth() + 1, 0);
  endDate.setHours(0, 0, 0, 0);
  dates.value = [startDate, endDate];
});

async function generateCalendars() {
  if ($props.resourceIds.length > 0 && isDateRangeSelected.value && scenarioStore.selectedScenario) {
    try {
      await ApiService.generateCalendars.generateCalendars({
        resourceIds: $props.resourceIds,
        timePeriod: { start: dates.value[0], end: dates.value[1] },
        scenarioId: scenarioStore.selectedScenario.id
      });
      await snackbarsStore.createSnackbar({
        message: $t("resource-generateCalendars-onGenerateSuccess-message", { $: "Calendars for selected resources are being generated" }),
        type: "success",
        closeable: true
      });
      $emits("calendars-generated");
    } catch (e) {
      console.error(e);
      await snackbarsStore.createSnackbar({
        message: $t("resource-generateCalendars-onGenerateError-message", { $: "Could not create calendars for selected resources" }),
        type: "error",
        closeable: true
      });
    }
  }
  modelValue.value = false;
}
</script>

<template>
  <v-dialog v-model="modelValue" style="z-index: 2000">
    <v-card class="align-center" min-width="20vw" min-height="50vh">
      <v-card-title>{{ $t("resource-generateCalendars-generate-action", { $: "Generate calendars" }) }}</v-card-title>
      <v-card-text>
        <Datepicker v-model="dates" inline range multi-calendars auto-apply teleport="body" :dark="theme.name === 'dark'" />
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn class="ma-4" variant="outlined" color="secondary" text @click="modelValue = false">
          {{ $t("resource-generateCalendars-cancel-action", { $: "Cancel" }) }}
        </v-btn>
        <v-btn class="ma-4" variant="outlined" color="accent" text :disabled="!isDateRangeSelected" @click="generateCalendars">
          {{ $t("resource-generateCalendars-save-action", { $: "Generate calendars" }) }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
