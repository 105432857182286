import { AvailabilityRulesController } from "@/services/api-service";
import ApiService from "@/services/api";
import { AvailabilityRuleDto, UpdateResourceAvailabilityRuleAssignmentCommand } from "@masta/generated-model";
import { defineStore } from "pinia";
import { useErrorsStore } from "@/store/ErrorsStore";
import { useScenariosStore } from "@/store/ScenariosStore";

interface State {
  rules: AvailabilityRuleDto[];
  resourceRules: AvailabilityRuleDto[];
}

export const useAvailabilityRulesStore = defineStore("availabilityRules", {
  state: (): State => ({
    rules: [],
    resourceRules: []
  }),
  actions: {
    async fetch() {
      try {
        const response = await new AvailabilityRulesController().getAll();
        this.rules = response.data.availabilityRules;
      } catch (e) {
        const errorsStore = useErrorsStore();
        errorsStore.handleError(e);
        throw e;
      }
    },
    async fetchForResource(id: string) {
      try {
        const scenariosStore = useScenariosStore();
        const response = await ApiService.resources.getAvailabilityRules(id, scenariosStore.selectedScenario?.id ?? "");
        this.resourceRules = response.data.availabilityRules;
      } catch (e) {
        const errorsStore = useErrorsStore();
        errorsStore.handleError(e);
        throw e;
      }
    },
    async saveForResource(request: UpdateResourceAvailabilityRuleAssignmentCommand) {
      try {
        await ApiService.resources.updateAvailabilityRuleAssignment(request);
      } catch (e) {
        const errorsStore = useErrorsStore();
        errorsStore.handleError(e);
        throw e;
      }
    },
    assignResourceRule(rule: AvailabilityRuleDto) {
      this.resourceRules = [...this.resourceRules, rule];
    },
    unassignResourceRule(rule: AvailabilityRuleDto) {
      this.resourceRules = [...this.resourceRules.filter((x) => x.id !== rule.id)];
    }
  }
});

export const mutations = {};
