import { GanttSettings, type IContext, Row, RowFactory, SettingKey } from "@masta/gantt2/core";
import { CustomSettingKeys } from "@/components/Gantt/ResourcesGantt/CustomSettingKeys";
import { ExtendedGanttResourceDto } from "@/components/Gantt/Common/Model";
import { skip } from "rxjs";

export abstract class RowFactoryBase extends RowFactory {
  protected _localeStoragePrefix = "gantt#";
  protected _defaultRowHeight: number;

  protected constructor(protected readonly _context: IContext, protected readonly _settings: GanttSettings) {
    super();
  }

  abstract getRowInstance(row: ExtendedGanttResourceDto): Row<any, any, any>;

  async afterInitialize(): Promise<void> {
    await super.afterInitialize();

    this._defaultRowHeight = this._settings.getSetting<number>(SettingKey.ROW_DEFAULT_HEIGHT) ?? 80;
  }

  protected createRowInstance(row: ExtendedGanttResourceDto): Row<any, any, any> {
    const r = this.getRowInstance(row);
    if (this._context.isDom) {
      this.restoreRowHeight(r);
      this.subscribe(r.height$.pipe(skip(1)).subscribe((e) => this.handleRowHeightChange(e, r)));
    }
    return r;
  }

  private handleRowHeightChange(height: number, row: Row<any, any, any>) {
    const localStorageKey = `${this._localeStoragePrefix}rowsHeight`;
    let item: any = localStorage.getItem(localStorageKey);
    if (!item) item = "[]";
    item = JSON.parse(item);
    let settingIdx = item.findIndex((x: any) => x.id === row.id);
    if (settingIdx > -1) {
      item[settingIdx] = { id: row.id, height };
    } else {
      settingIdx = item.length;
    }
    item[settingIdx] = { id: row.id, height };
    localStorage.setItem(localStorageKey, JSON.stringify(item));
  }

  private restoreRowHeight(row: Row<any, any, any>) {
    const localStorageKey = `${this._localeStoragePrefix}rowsHeight`;
    let item: any = localStorage.getItem(localStorageKey);
    if (item) {
      item = JSON.parse(item);

      const settingIdx = item?.findIndex((x: any) => x.id === row.id);
      if (settingIdx > -1 && item[settingIdx].height > 0) {
        row.height = item[settingIdx].height;
      }
    }
  }
}
