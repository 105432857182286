import { inject, injectable } from "inversify";
import { ActivityTooltipRenderer } from "@masta/gantt2/gantt";
import { GanttSettings, IActivityObject } from "@masta/gantt2/core";
import { TotalStatus } from "@masta/generated-model";
import { StatusColorHelper } from "@/components/Gantt/ResourcesGantt/Renderers/StatusColorHelper";
import SystemEnumService from "@/services/system-enum.service";
import {
  DemandExecutionResourceCapacityActivity,
  DemandSchedulingResourceCapacityActivity,
  ActivityTaskDto,
  InterruptionExecutionResourceCapacityActivity,
  ResourceCapacityActivity,
  WaitingSchedulingResourceCapacityActivity,
  TaskExecutionActivity,
  TaskSchedulingActivity
} from "@/components/Gantt/ResourcesGantt";

@injectable()
export class CustomTaskActivityTooltipRenderer extends ActivityTooltipRenderer {
  constructor(@inject(GanttSettings) protected readonly _settings: GanttSettings, @inject(StatusColorHelper) private _statusColorHelper: StatusColorHelper) {
    super(CustomTaskActivityTooltipRenderer.name, "default-activity-tooltip custom-activity-tooltip");
  }

  async doDrawFromBatch(): Promise<void> {
    await super.doDrawFromBatch();
    if (this.visible) {
      const dto = this._activity.activity as unknown as ActivityTaskDto;
      const type = dto.type;
      const activity = this._activity.activity as unknown as IActivityObject;
      const name = activity.name;
      const stepName = null;
      const startTime = type === "TaskSchedulingActivity" ? dto.schedulingStartDateTimeFormatted : dto.executionStartDateTimeFormatted;
      const endTime = type === "TaskSchedulingActivity" ? dto.schedulingEndDateTimeFormatted : dto.executionEndDateTimeFormatted;
      const duration = type === "TaskSchedulingActivity" ? dto.schedulingDurationFormatted : dto.executionDurationFormatted;
      const taskStatus = dto?.statistics?.totalStatus ?? TotalStatus.Unknown;
      const taskStatusBackgroundColor = this._statusColorHelper.getStatusBackgroundColor(taskStatus);
      const taskStatusTextColor = this._statusColorHelper.getStatusTextColor(taskStatus);
      const stepStatus = dto.step?.statistics?.totalStatus ?? TotalStatus.Unknown;
      const wbs = dto?.wbs;
      const taskBusinessId = dto?.businessId;
      const stepStatusBackgroundColor = this._statusColorHelper.getStepStatusBackgroundColor(stepStatus);
      const stepStatusTextColor = this._statusColorHelper.getStepStatusTextColor(stepStatus);
      const productTemplate = `
    <tr>
      <td style="background-color: ${taskStatusBackgroundColor}; color: ${taskStatusTextColor}">Task (${SystemEnumService.totalStatus(taskStatus)}):</td>
      <td>${name}</td>
    </tr>
      `;
      this.element.innerHTML = `
<table>
  <tbody>
    <tr>
      <td colspan="2" style="text-align: center">${this.getTitle(dto.type)}</td>
    </tr>
    ${productTemplate}
    <tr>
      <td>Start:</td>
      <td>${startTime}</td>
    </tr>
    <tr>
      <td>End:</td>
      <td>${endTime}</td>
    </tr>
    <tr>
      <td>Duration:</td>
      <td>${duration}</td>
    </tr>
    ${this.getRow("WBS", wbs ?? "")}
    ${this.getRow("Business ID", taskBusinessId ?? "")}
  </tbody>
</table>
      `;
    } else {
      this.element.innerHTML = "";
    }
  }

  private getRow(label: string, value?: string) {
    return value
      ? `
    <tr>
        <td>${label}:</td>
        <td>${value}</td>
    </tr>
    `
      : "";
  }

  private getTitle(type: string) {
    switch (type) {
      case TaskSchedulingActivity.name:
        return "Scheduling";
      case TaskExecutionActivity.name:
        return "Execution";
      default:
        return "Unknown";
    }
  }
}
