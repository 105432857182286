<script lang="ts" setup>
import GridWrapper from "@/components/Grid/GridWrapper.vue";
import { computed, inject, onBeforeUnmount, ref, watch } from "vue";
import { GridWrapperComponent } from "@/components/Grid/GridWrapperComponent";
import {
  GridApi,
  GridOptions,
  GridReadyEvent,
  KeyCreatorParams,
  ProcessCellForExportParams,
  RowNode,
  RowSelectedEvent,
  ValueFormatterParams,
  ValueGetterParams,
  ValueSetterParams
} from "ag-grid-community";
import { $dateTimeFormatterSymbol, DateFormatter } from "@masta/shared";
import { $appContextSymbol } from "@/plugins/app.plugin";
import { ProductTemplateChangedNotification, useNotification } from "@/notifications";
import { useDebounceFn } from "@vueuse/core";
import { useModelInstancesStore } from "@/store/ModelInstancesStore";
import { $t } from "@/i18n";
import { ProductTemplatesServerSideDataSource } from "@/components/ProductTemplates/ProductTemplatesServerSideDataSource";
import {
  ProductTemplateChangedNotificationEvent,
  ProductTemplateDto,
  ResourceDto,
  ResourceInfoDto,
  ResourceType,
  TaskSubType,
  TaskType,
  TemplateStatus
} from "@masta/generated-model";
import { useServerSideGroup, UseServerSideGroupParams } from "@/components/Grid/UseServerSideGroup";
import { requiredRule } from "@/components/ValueCellEditor/CommonValidationRules";
import { translateTaskSubType, translateTemplateStatus } from "@/composables/translateEnum";
import { GUID_EMPTY, nameOrBusinessIdOrIdOrNull, nonEmptyGuidOrNull } from "@/components/ValueCellEditor/CommonFormatters";
import { useProductTemplatesStore } from "@/store/ProductTemplatesStore";
import { IEnumValueSelectCellEditorParams, ISelectEnumValueEntry } from "@/components/Grid/CellEditors/IEnumValueSelectCellEditorParams";
import {
  enumToEditorEntries,
  enumToEditorEntriesOnlyIncluding,
  enumValueEntryWithLocaleComparator,
  enumValuesExcluding,
  translateEditorEntries
} from "@/components/Grid/ColumnTypes";
import CopyToNewProductDialog from "@/components/ProductTemplates/CopyToNewProductDialog.vue";
import { CreateSnackbarCommand, useSnackbarsStore } from "@/store/SnackbarsStore";
import { getProductTemplateStatusCellStyle } from "@/components/Tasks/TaskUtils";
import { useMaterialsStore } from "@/store/MaterialsStore";
import { useTagsStore } from "@/store/TagsStore";
import { tagsTypeColumnFilterParams } from "@/components/Grid/Filters/TagsTypeColumnFilters";
import { getSelectedRows } from "@/components/Grid/UseGridSelection";
import ActionsButton from "@/components/Layout/ActionsButton.vue";

const miStore = useModelInstancesStore();
const productTemplateStore = useProductTemplatesStore();

const props = defineProps<{
  filterByProductId: string | null;
  filterByProcessDraftId: string | undefined;
}>();

const $emits = defineEmits(["rowSelected", "manageModelInstances"]);
const $dateTimeFormatter = inject<DateFormatter>($dateTimeFormatterSymbol)!;
const $appContext = inject<any>($appContextSymbol)!;

const snackbarStore = useSnackbarsStore();

const serverSideDataSource = new ProductTemplatesServerSideDataSource("product-overview-product-templates", props.filterByProductId, props.filterByProcessDraftId);

const materialStore = useMaterialsStore();
const tagsStore = useTagsStore();

function determineProductContext() {
  return materialStore.materials.find((material) => material.id === props.filterByProductId);
}

const DEFAULT_CREATE_VALUE = {
  templateStatus: TemplateStatus.WorkInProgress,
  taskType: TaskType.ProductTemplate,
  revisionNumber: 1,
  priority: 0,
  taskSubType: TaskSubType.ProductionTask,
  wbs: "",
  item: () => determineProductContext(),
  resourceId: () => determineProductContext()?.id,
  itemBusinessId: () => determineProductContext()?.businessId,
  itemName: () => determineProductContext()?.name
} as unknown as ProductTemplateDto;

useNotification(ProductTemplateChangedNotification, (e: ProductTemplateChangedNotificationEvent) => {
  onProductTemplateChanged(e);
});

const onProductTemplateChanged: (e: ProductTemplateChangedNotificationEvent) => void = useDebounceFn(async (e: ProductTemplateChangedNotificationEvent) => {
  gridWrapperRef.value?.gridApi.refreshServerSide();
}, 800);

const gridWrapperRef = ref<GridWrapperComponent>();

watch(props, (newProps, _) => {
  serverSideDataSource.useFilteringByProductId(newProps?.filterByProductId ?? null);
  serverSideDataSource.useFilteringByProcessDraftId(newProps?.filterByProcessDraftId ?? undefined);
  gridWrapperRef.value.gridApi.refreshServerSide({ purge: true });
});

const taskSubTypesEditorValuesRef = ref<ISelectEnumValueEntry[]>([]);
const copyToNewProductDialog = ref(false);
const isItemEditorEnabled = ref(true);

function isServerSideGroup(dataItem: any) {
  return dataItem?.hasChildren;
}

function getServerSideGroupKey(dataItem: any) {
  return dataItem?.id;
}

/**
 * TODO : The old grouping mechanism based on the create-child-btn button, which was removed and replaced by an "Add child" added in the row of the grouping column.
 * New mechanism not yet implemented in this grid.
 */
const { onGridReady, groupValueGetter } = useServerSideGroup({
  isGroup: function(_: ProductTemplateDto): boolean {
    return true;
  },
  getNameForNode: (node: RowNode) => {
    return " ";
  },
  setParentId: (node: RowNode, parentNode: RowNode) => {
    node.data.parentTaskId = parentNode.data.id;
    node.data.rootTaskId = parentNode.data.rootTaskId;
  },
  grid: gridWrapperRef
} as UseServerSideGroupParams);

function onGridReadyFq(event: GridReadyEvent) {
  onGridReady(event);

  event.api.addEventListener("gridRowsLoaded", updateSelection);
  event.api.addEventListener("storeRefreshed", onStoreRefreshed);
}

onBeforeUnmount(() => {
  if (gridWrapperRef.value) {
    if (!gridWrapperRef.value.gridApi.isDestroyed()) {
      gridWrapperRef.value.gridApi.removeEventListener("gridRowsLoaded", updateSelection);
      gridWrapperRef.value.gridApi.removeEventListener("storeRefreshed", onStoreRefreshed);
    }
  }
});

const isRowSelected = ref(false);
const selectedRow = ref<ProductTemplateDto | null>(null);
const selectedRows = ref<ProductTemplateDto[]>([]);
const nameEditorRef = ref<string>("");

const isReleasable = computed(() => selectedRows.value.length > 0 && selectedRows.value.some((template) => template.templateStatus === TemplateStatus.WorkInProgress));
const isUnreleasable = computed(() => selectedRow.value && selectedRow.value.templateStatus === TemplateStatus.Released);
const isArchivable = computed(() => selectedRow.value && selectedRow.value.templateStatus !== TemplateStatus.Archived);
const isCopyToNewProductEnabled = computed(() => selectedRow.value && selectedRow.value.parentTaskId === null);

async function onFetchData() {
  await materialStore.fetch(); // could be improved by fetching only the context material (one having ID equal to filterByProductId)
  await miStore.fetchSchemas();
}

async function onRefreshAction() {
  await onFetchData();
}

const autoGroupColumnDef = ref({
  headerValueGetter: (_: any) => $t("productTemplate-list-group-label", { $: "Group" }),
  suppressSizeToFit: true,
  width: 100,
  cellRendererParams: {
    suppressCount: true
  },
  valueGetter: groupValueGetter
});

const defaultColumnDef = ref({
  sortable: true,
  resizable: true,
  floatingFilter: true,
  filterParams: {
    applyMiniFilterWhileTyping: true
  }
});

function isSelectedTemplateReleased() {
  return selectedRow.value && selectedRow.value.templateStatus !== TemplateStatus.WorkInProgress;
}

function hasParentSelected() {
  return typeof selectedRow.value !== "undefined";
}

function hasParentWithValidStatus() {
  return typeof selectedRow.value !== "undefined" && selectedRow.value?.templateStatus === TemplateStatus.WorkInProgress;
}

function hasParentOfValidSubType() {
  return typeof selectedRow.value !== "undefined" && (selectedRow.value?.taskSubType === TaskSubType.WorkOrder || selectedRow.value?.taskSubType === TaskSubType.WorkOrderAlternative);
}

function onCreate() {
  // if (validateParent()) {
  // attachCreatedTemplateAsChild.value = isCreatingChild;
  // isItemEditorEnabled.value = !isCreatingChild;
  // }
}

function validateParent() {
  if (!hasParentSelected()) {
    snackbarStore.createSnackbar({
      message: $t("productTemplate-edit-attachChildCanceledNoParentSelected-popup", { $: "Cannot create child template without selecting parent template" }),
      type: "warning"
    } as CreateSnackbarCommand);
    gridWrapperRef.value?.gridApi.stopEditing(true);
    return false;
  }

  if (!hasParentWithValidStatus()) {
    snackbarStore.createSnackbar({
      message: $t("productTemplate-edit-attachChildCanceledParentHasNoValidStatus-popup", { $: "Cannot create child - status of parent is preventing it" }),
      type: "warning"
    } as CreateSnackbarCommand);
    gridWrapperRef.value?.gridApi.stopEditing(true);
    return false;
  }

  if (!hasParentOfValidSubType()) {
    snackbarStore.createSnackbar({
      message: $t("productTemplate-edit-attachChildCanceledParentHasNoValidSubType-popup", { $: "Cannot create child - sub-type of parent is preventing it" }),
      type: "warning"
    } as CreateSnackbarCommand);
    gridWrapperRef.value?.gridApi.stopEditing(true);
    return false;
  }

  return true;
}

function updateAllowedTaskSubTypes() {
  const jiraTaskSubTypes: number[] = [TaskSubType.Epic, TaskSubType.Bug, TaskSubType.Support, TaskSubType.DevelopmentTask, TaskSubType.Subtask];
  const excludedEnumKeys = [TaskSubType.Undefined, ...jiraTaskSubTypes];
  const contextAllowedEnumValues = enumValuesExcluding(TaskSubType, excludedEnumKeys);
  taskSubTypesEditorValuesRef.value = translateEditorEntries(enumToEditorEntriesOnlyIncluding(TaskSubType, contextAllowedEnumValues), translateTaskSubType);
}

function isSelectedTemplateRoot() {
  return selectedRow.value?.parentTaskId === null || selectedRow.value?.parentTaskId === GUID_EMPTY;
}

function onEdit() {
  if (isSelectedTemplateReleased()) {
    gridWrapperRef.value?.gridApi.stopEditing(true);
    snackbarStore.createSnackbar({
      message: $t("productTemplate-edit-editCanceledTemplateIsReleased-popup", { $: "Cannot edit template with 'Released' status - please create a new version of it" }),
      type: "warning"
    } as CreateSnackbarCommand);
    return;
  }

  isItemEditorEnabled.value = isSelectedTemplateRoot();
}

function onItemChange(item: ResourceDto, _: ResourceDto) {
  if (!nameEditorRef.value && item?.name) {
    nameEditorRef.value = item.name;
  }
}

function mapToResourceInfoDto(resourceId: string | null, itemBusinessId: string | null, itemName: string | null): ResourceInfoDto | null {
  if (resourceId || itemBusinessId || itemName) {
    const resourceInfo: ResourceInfoDto = {
      id: resourceId as string,
      scenarioId: "",
      name: itemName,
      businessId: itemBusinessId,
      type: ResourceType.Material
    };

    return resourceInfo;
  } else {
    return null;
  }
}

const gridOptions: GridOptions = {
  processCellForClipboard: (params: ProcessCellForExportParams) => {
    const { value, column } = params;

    if (column.getColDef().type === "resourcePickerTypeColumn") {
      return params.formatValue(value);
    }

    return value;
  }
};

function onPrepareColumns(columnDefs: any) {
  updateAllowedTaskSubTypes();

  columnDefs.value = [
    {
      field: "id",
      editable: false,
      hide: true
    },
    {
      field: "item", // important: this field is not a part of underlying DTO
      type: ["resourcePickerTypeColumn", "textFloatingFilterColumnType"],
      sortable: true,
      filter: "agTextColumnFilter",
      headerValueGetter: (_: any) => $t("productTemplate-list-item-label", { $: "Item" }),
      editable: true,
      floatingFilterComponentParams: {
        placeholder: $t("productTemplate-edit-item-label", { $: "Item" })
      },
      cellEditorParams: {
        resourceTypes: [ResourceType.Material, ResourceType.Service],
        placeholder: $t("productTemplate-edit-item-label", { $: "Item" }),
        isEditEnabled: () => isItemEditorEnabled,
        onValueChange: onItemChange
      },
      valueFormatter: (params: ValueFormatterParams) => {
        const resourceInfo: ResourceInfoDto = params.value;
        if (resourceInfo) {
          const result = nameOrBusinessIdOrIdOrNull(resourceInfo);
          return result ?? "";
        }
        const data = params.data;
        return data?.itemName ?? data?.itemBusinessId ?? nonEmptyGuidOrNull(data?.resourceId) ?? null;
      },
      valueGetter: (params: ValueGetterParams) => {
        const { data } = params;
        const resourceInfo = mapToResourceInfoDto(data.resourceId, data.itemBusinessId, data.itemName);
        return resourceInfo;
      },
      valueSetter: (params: ValueSetterParams) => {
        const resourceInfo: ResourceInfoDto = params.newValue;
        params.data.resourceId = resourceInfo?.id;
        params.data.itemBusinessId = resourceInfo?.businessId;
        params.data.itemName = resourceInfo?.name;
        return true;
      }
    },
    {
      field: "itemName",
      type: ["textInputTypeColumn", "textFloatingFilterColumnType"],
      editable: false,
      sortable: true,
      filter: "agTextColumnFilter",
      hide: true,
      headerValueGetter: (_: any) => $t("productTemplate-list-itemName-label", { $: "Item Name" }),
      floatingFilterComponentParams: {
        placeholder: $t("productTemplate-edit-itemName-label", { $: "Item Name" })
      }
    },
    {
      field: "itemBusinessId",
      type: ["textInputTypeColumn", "textFloatingFilterColumnType"],
      editable: false,
      sortable: true,
      filter: "agTextColumnFilter",
      hide: true,
      headerValueGetter: (_: any) => $t("productTemplate-list-itemBusinessId-label", { $: "Item Business ID" }),
      floatingFilterComponentParams: {
        placeholder: $t("productTemplate-edit-itemBusinessId-label", { $: "Item Business ID" })
      }
    },
    {
      field: "templateStatus",
      headerName: "Status",
      editable: false,
      sortable: true,
      filter: "agSetColumnFilter",
      type: ["enumTypeColumn", "setFloatingFilterColumnType"],
      filterParams: {
        valueFormatter: (params: ValueFormatterParams) => params.value.key,
        keyCreator: (params: KeyCreatorParams) => params.value.value,
        values: translateEditorEntries(enumToEditorEntries(TemplateStatus), translateTemplateStatus),
        comparator: enumValueEntryWithLocaleComparator
      },
      cellStyle: (params: any) => getProductTemplateStatusCellStyle(params.value),
      valueFormatter: (params: any) => translateTemplateStatus(params.value),
      headerValueGetter: (_: any) => $t("productTemplate-list-templateStatus-label", { $: "Status" }),
      floatingFilterComponentParams: {
        placeholder: $t("productTemplate-list-templateStatus-label", { $: "Status" }),
        values: translateEditorEntries(enumToEditorEntries(TemplateStatus), translateTemplateStatus)
      }
    },
    {
      field: "taskSubType",
      type: ["enumTypeColumn", "setFloatingFilterColumnType"],
      headerValueGetter: (_: any) => $t("productTemplate-list-taskSubType-label", { $: "Task Sub-Type" }),
      valueFormatter: (params: ValueFormatterParams) => translateTaskSubType(params.data.taskSubType),
      editable: true,
      sortable: true,
      cellEditorParams: {
        values: () => taskSubTypesEditorValuesRef,
        rules: [requiredRule],
        placeholder: $t("productTemplate-edit-taskSubType-label", { $: "Task Sub-Type" }),
        isEditEnabled: () => !gridWrapperRef.value?.isUpdating()
      } as unknown as IEnumValueSelectCellEditorParams,
      filter: "agSetColumnFilter",
      filterParams: {
        valueFormatter: (params: ValueFormatterParams) => params.value.key,
        keyCreator: (params: KeyCreatorParams) => params.value.value,
        values: translateEditorEntries(enumToEditorEntries(TaskSubType), translateTaskSubType),
        comparator: enumValueEntryWithLocaleComparator
      },
      floatingFilterComponentParams: {
        placeholder: $t("productTemplate-edit-taskSubType-label", { $: "Task Sub-Type" }),
        values: translateEditorEntries(enumToEditorEntries(TaskSubType), translateTaskSubType)
      }
    },
    {
      field: "businessId",
      type: ["textInputTypeColumn", "textFloatingFilterColumnType"],
      editable: true,
      sortable: true,
      filter: "agTextColumnFilter",
      headerValueGetter: (_: any) => $t("productTemplate-list-businessId-label", { $: "Business ID" }),
      cellEditorParams: {
        placeholder: $t("productTemplate-edit-businessId-label", { $: "Business ID" })
      },
      floatingFilterComponentParams: {
        placeholder: $t("productTemplate-edit-businessId-label", { $: "Business ID" })
      }
    },
    {
      field: "name",
      type: ["textInputTypeColumn", "textFloatingFilterColumnType"],
      editable: true,
      sortable: true,
      filter: "agTextColumnFilter",
      headerValueGetter: (_: any) => $t("productTemplate-list-name-label", { $: "Name" }),
      cellEditorParams: {
        valueRef: () => nameEditorRef,
        rules: [requiredRule],
        placeholder: $t("productTemplate-edit-name-label", { $: "Name" })
      },
      floatingFilterComponentParams: {
        placeholder: $t("productTemplate-edit-name-label", { $: "Name" })
      }
    },
    {
      field: "priority",
      type: ["textInputTypeColumn", "textFloatingFilterColumnType"],
      filter: "agNumberColumnFilter",
      editable: true,
      sortable: true,
      headerValueGetter: (_: any) => $t("productTemplate-list-priority-label", { $: "Priority" }),
      cellEditorParams: {
        placeholder: $t("productTemplate-edit-priority-label", { $: "Priority" })
      },
      floatingFilterComponentParams: {
        placeholder: $t("productTemplate-edit-priority-label", { $: "Priority" })
      }
    },
    {
      field: "templateValidFrom",
      type: "datepickerTypeColumn",
      filter: "agDateColumnFilter",
      editable: true,
      sortable: true,
      valueFormatter: (params: any) => {
        return $dateTimeFormatter(params.data.templateValidFrom);
      },
      headerValueGetter: (_: any) => $t("productTemplate-list-templateValidFrom-label", { $: "Valid From" })
    },
    {
      field: "templateValidTo",
      type: "datepickerTypeColumn",
      filter: "agDateColumnFilter",
      editable: true,
      sortable: true,
      valueFormatter: (params: any) => {
        return $dateTimeFormatter(params.data.templateValidTo);
      },
      headerValueGetter: (_: any) => $t("productTemplate-list-templateValidTo-label", { $: "Valid To" })
    },
    {
      field: "wbs",
      type: ["textInputTypeColumn", "textFloatingFilterColumnType"],
      editable: true,
      sortable: true,
      filter: "agTextColumnFilter",
      headerValueGetter: (_: any) => $t("productTemplate-list-workBreakdownStructure-label", { $: "Work Breakdown Structure" }),
      cellEditorParams: {
        placeholder: $t("productTemplate-edit-workBreakdownStructure-label", { $: "Work Breakdown Structure" })
      },
      floatingFilterComponentParams: {
        placeholder: $t("productTemplate-edit-workBreakdownStructure-label", { $: "Work Breakdown Structure" })
      }
    },
    {
      field: "tags",
      headerValueGetter: (_: any) => $t("productTemplate-list-tags-label", { $: "Tags" }),
      type: ["tagsPickerTypeColumn", "setFloatingFilterColumnType"],
      editable: true,
      resizable: true,
      filter: "agSetColumnFilter",
      filterParams: tagsTypeColumnFilterParams(tagsStore),
      valueFormatter: (params: ValueFormatterParams) => (params.data.tags ?? []).join(", "),
      cellEditorParams: {
        placeholder: $t("productTemplate-edit-tags-label", { $: "Tags" })
      }
    },
    {
      field: "revisionNumber",
      type: ["numberInputTypeColumn", "numberFloatingFilterColumnType"],
      filter: "agNumberColumnFilter",
      headerValueGetter: (_: any) => $t("productTemplate-list-revisionNumber-label", { $: "Revision" }),
      editable: false,
      sortable: true,
      cellEditorParams: {
        isEditEnabled: () => false
      }
    },
    {
      field: "createdBy",
      filter: "agTextColumnFilter",
      type: ["textInputTypeColumn", "textFloatingFilterColumnType"],
      editable: false,
      resizable: true,
      headerValueGetter: (_: any) => $t("productTemplate-list-createdBy-label", { $: "Created By" }),
      floatingFilterComponentParams: {
        placeholder: $t("productTemplate-list-createdBy-label", { $: "Created By" })
      }
    },
    {
      field: "createdAt",
      type: ["dateTimeTypeColumn"],
      filter: "agDateColumnFilter",
      editable: false,
      resizable: true,
      valueFormatter: (params: any) => {
        return $dateTimeFormatter(params.data.createdAt);
      },
      headerValueGetter: (_: any) => $t("productTemplate-list-createdAt-label", { $: "Created At" })
    },
    {
      field: "modifiedBy",
      filter: "agTextColumnFilter",
      type: ["textInputTypeColumn", "textFloatingFilterColumnType"],
      editable: false,
      resizable: true,
      headerValueGetter: (_: any) => $t("productTemplate-list-modifiedBy-label", { $: "Modified By" }),
      floatingFilterComponentParams: {
        placeholder: $t("productTemplate-list-modifiedBy-label", { $: "Modified By" })
      }
    },
    {
      field: "modifiedAt",
      type: ["dateTimeTypeColumn"],
      filter: "agDateColumnFilter",
      editable: false,
      resizable: true,
      valueFormatter: (params: any) => {
        return $dateTimeFormatter(params.data.modifiedAt);
      },
      headerValueGetter: (_: any) => $t("productTemplate-list-modifiedAt-label", { $: "Modified At" })
    }
  ];
}

function showDetails(data: ProductTemplateDto) {
  $emits("rowSelected", data);
}

function onSelectionChanged({ api }: { api: GridApi }) {
  updateSelection();
  /**
   * TODO : the old grouping mechanism based on the create-child-btn button, which was removed and replaced by an "Add child" added in the row of the grouping column.
   */
  // gridWrapperRef.value?.crudActions.setCreateChildEnabled(hasParentOfValidSubType() && hasParentWithValidStatus());
}

function updateSelection() {
  selectedRows.value = getSelectedRows(gridWrapperRef.value?.gridApi) ?? [];
  isRowSelected.value = selectedRows.value.length == 1;
  selectedRow.value = isRowSelected.value ? selectedRows.value[0] : null;
}

function onStoreRefreshed() {
  updateSelection();
  $emits("rowSelected", selectedRow.value);
}

function onSaveOrCancel() {
  isItemEditorEnabled.value = true;
}

const onResourceCapacityChanged: () => void = useDebounceFn(async () => {
  await onFetchData();
}, 800);

async function release() {
  if (!isReleasable.value) return;

  const ids = selectedRows.value.map((template) => template.id);
  await productTemplateStore.bulkRelease(ids);

}

async function unrelease() {
  if (!isUnreleasable.value) return;

  await productTemplateStore.unrelease(selectedRow.value.id);
}

async function archive() {
  if (!isArchivable.value) return;

  await productTemplateStore.archive(selectedRow.value.id);
}

async function createNewVersion() {
  if (!isRowSelected.value) return;

  await productTemplateStore.createNewVersion(selectedRow.value.id);
}

async function copyToNewProduct(resource: ResourceDto) {
  if (!isCopyToNewProductEnabled.value || !resource) return;
  await productTemplateStore.copyToNewProduct(selectedRow.value.id, resource.id);
}

function getContextMenuItems(param: any) {
  return [
    {
      icon: "<i class=\"mdi mdi-content-duplicate\"/>",
      name: $t("productTemplate-list-copyToNewProduct-action", { $: "Copy to New Product" }),
      action: () => {
        copyToNewProductDialog.value = true;
      }
    },
    {
      name: $t("productTemplateDetails-list-manageModelInstances-action", { $: "Manage Model Instance Assignment" }),
      action: () => {
        $emits("manageModelInstances", param.node.data, () => {
          gridWrapperRef.value?.gridApi.refreshServerSide();
        });
      }
    }
  ];
}

function onRowSelected(event: RowSelectedEvent<ProductTemplateDto>) {
  if (event.node.isSelected()) {
    $emits("rowSelected", event.data);
  }
}

const detailActions = ref([
  {
    title: $t("productTemplate-list-createNewVersionOfTemplate-action", { $: "1. Create New Version" }),
    tooltip: $t("productTemplate-list-createNewVersionOfTemplate-action", { $: "Create New Version" }),
    icon: "mdi-ab-testing",
    action: createNewVersion,
    disabled: () => !isRowSelected.value,
    order: 1
  },
  {
    title: $t("productTemplate-list-releaseTemplate-action", { $: "2. Release" }),
    tooltip: $t("productTemplate-list-releaseTemplate-action", { $: "Release" }),
    action: release,
    icon: "mdi-rocket-launch",
    disabled: () => !isReleasable.value,
    order: 2
  },
  {
    title: $t("productTemplate-list-unreleaseTemplate-action", { $: "3. Unrelease" }),
    tooltip: $t("productTemplate-list-unreleaseTemplate-action", { $: "Unrelease" }),
    icon: "mdi-arrow-u-left-top",
    action: unrelease,
    disabled: () => !isUnreleasable.value,
    order: 3
  },
  {
    title: $t("productTemplate-list-archiveTemplate-action", { $: "Archive" }),
    tooltip: $t("productTemplate-list-archiveTemplate-action", { $: "Archive" }),
    icon: "mdi-archive-arrow-down-outline",
    action: archive,
    disabled: () => !isArchivable.value,
    order: 4
  },
  {
    separator: true,
    order: 5
  },
  {
    title: $t("productTemplate-list-copyToNewProduct-action", { $: "Copy to New Product" }),
    tooltip: $t("productTemplate-list-copyToNewProduct-action", { $: "Copy to New Product" }),
    icon: "mdi-content-duplicate",
    action: () => (copyToNewProductDialog.value = true),
    disabled: () => !isCopyToNewProductEnabled.value,
    order: 6
  }
]);
</script>

<template>
  <grid-wrapper
    ref="gridWrapperRef"
    :grid-options="gridOptions"
    :create-default-value="DEFAULT_CREATE_VALUE"
    identifier="product-templates"
    refresh-btn
    :default-col-def="defaultColumnDef"
    :auto-group-column-def="autoGroupColumnDef"
    :enable-group-edit="true"
    enable-children-selection-only
    :get-server-side-group-key="getServerSideGroupKey"
    :is-server-side-group="isServerSideGroup"
    :row-drag-managed="true"
    row-selection="multiple"
    :server-side="true"
    :server-side-datasource="serverSideDataSource"
    :tree-data="true"
    :context-menu-items="getContextMenuItems"
    create-btn
    duplicate-btn
    delete-btn
    edit-btn
    @create-action="onCreate"
    @edit-action="onEdit"
    @fetch-data="onFetchData"
    @refresh-action="onRefreshAction"
    @prepare-columns="onPrepareColumns"
    @selection-changed="onSelectionChanged"
    @save-action="onSaveOrCancel"
    @cancel-action="onSaveOrCancel"
    @ready="onGridReadyFq"
    @details="showDetails"
    @row-selected="onRowSelected"
  >
    <template #custom-buttons>
      <div class="d-inline-flex pr-4">
        <actions-button :model-value="detailActions" />
      </div>
      <copy-to-new-product-dialog v-model="copyToNewProductDialog" :grid-api="gridWrapperRef?.gridApi" @selected="copyToNewProduct" />
    </template>
  </grid-wrapper>
</template>

<style lang="scss" scoped></style>
