import { ApiClient } from "@/services/api/common.api";
import { CalculateKpiValuesCommand, DeleteKpiResultByCalculationIdCommand, GetKpiResultsResponse } from "@masta/generated-model";

export default {
  async delete(id: string, scenarioId: string) {
    return ApiClient.delete<undefined>(`/keyPerformanceIndicators/${id}?scenarioId=${scenarioId}`);
  },
  async deleteByScenarioId(scenarioId: string) {
    return ApiClient.delete<undefined>(`/keyPerformanceIndicators/scenario/${scenarioId}`);
  },
  async deleteByCalculationId(command: DeleteKpiResultByCalculationIdCommand) {
    return ApiClient.delete<undefined>(`/keyPerformanceIndicators/calculationId/${encodeURIComponent(command.calculationId)}?scenarioId=${command.scenarioId}`);
  },
  async getAll(scenarioId: string) {
    return ApiClient.get<GetKpiResultsResponse>(`/keyPerformanceIndicators/${scenarioId}`);
  },
  async calculate(request: CalculateKpiValuesCommand) {
    return ApiClient.post(`/keyPerformanceIndicators/${request.kpiProfileName}/calculate`, request);
  },
  async getProfiles() {
    return ApiClient.get(`/keyPerformanceIndicators/profiles`);
  }
};
