import { IllegalLineIndexException } from "../../Exceptions";
import { IActivity } from "../IActivity";
import { ILinesManager } from "../ILinesManager";
import { Layout } from "../Layout";
import { Row } from "../Row";

import { GanttLayout } from "./GanttLayout";

export abstract class EqualsLinesManager<R extends Row<any, any, Activity>, Activity extends IActivity> implements ILinesManager<Activity> {
  private _row: R;

  private _lineLocations: number[];

  private _lineHeights: number[];

  private _lineLayout: GanttLayout;

  constructor(row: R) {
    this._row = row;
  }

  get row(): R {
    return this._row;
  }

  getLineHeight(lineIndex: number, rowHeight: number): number {
    this.assertLineIndex(lineIndex);
    if (!this._lineHeights) {
      const count = this.row.lineCount;
      const h = rowHeight / count;
      this._lineHeights = new Array(count);
      for (let i = 0; i < count; i++) {
        this._lineHeights[i] = h;
      }
    }
    return this._lineHeights[lineIndex];
  }

  getLineIndex(activity: Activity): number {
    return -1;
  }

  getLineLayout(lineIndex: number): Layout {
    this.assertLineIndex(lineIndex);
    if (!this._lineLayout) {
      this._lineLayout = new GanttLayout();
    }
    return this._lineLayout;
  }

  getLineLocation(lineIndex: number, rowHeight: number): number {
    this.assertLineIndex(lineIndex);
    if (!this._lineLocations) {
      const count = this.row.lineCount;
      const h = rowHeight / count;
      this._lineLocations = new Array(count);
      for (let i = 0; i < count; i++) {
        this._lineLocations[i] = i * h;
      }
    }
    return this._lineLocations[lineIndex];
  }

  assertLineIndex(lineIndex: number): void {
    if (lineIndex < 0 || lineIndex >= this.row.lineCount) {
      throw new IllegalLineIndexException(this.row, lineIndex, this.row.lineCount);
    }
  }

  public clearCache(): void {
    this._lineLocations = null!;
    this._lineHeights = null!;
  }

  abstract layout(): void;
}
