﻿import { GanttEvents } from "../Events";
import { inject, injectable } from "inversify";
import { Lifecycle } from "../Lifecycle";
import { TimelineManager } from "../TimelineManager";
import { filter, withLatestFrom } from "rxjs";

@injectable()
export class DefaultNavigationScheme extends Lifecycle {
  constructor(@inject(GanttEvents) private _ganttEvents: GanttEvents, @inject(TimelineManager) private _timelineManager: TimelineManager) {
    super();
  }

  async afterInitialize() {
    await super.afterInitialize();

    // zoom ctrl
    this.subscribe(
      this._ganttEvents.mouseWheelEvent$.pipe(filter((x) => x.osCtrlKey)).subscribe(async (e) => {
        if (e.deltaY < 0) {
          await this._timelineManager.zoomIn();
        } else if (e.deltaY > 0) {
          await this._timelineManager.zoomOut();
        }
      })
    );

    // zoom
    this.subscribe(
      this._ganttEvents.mouseWheelEvent$
        .pipe(
          filter((x) => !x.osCtrlKey),
          withLatestFrom(this._ganttEvents.mousePositionEvent$)
        )
        .subscribe(async ([wheelEvent, graphicsMousePositionEvent]) => {
          const zoomTime = this._timelineManager.calculateTimeForLocation(graphicsMousePositionEvent.x);
          await this._timelineManager.zoom(wheelEvent.deltaY < 0, zoomTime);
        })
    );
  }
}
