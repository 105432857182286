import { BehaviorSubject, Observable } from "rxjs";
import { ILayer } from "./ILayer";

export class Layer implements ILayer {
  private _opacity$$ = new BehaviorSubject<number>(1.0);
  private _visible$$ = new BehaviorSubject<boolean>(true);
  private _disablePointerEvents$$ = new BehaviorSubject<boolean>(false);

  constructor(name?: string, id?: string) {
    this._name = name ?? "untitled";
    this._id = id ?? this._name;
  }

  private _id: string;

  get id(): string {
    return this._id;
  }

  set id(value: string) {
    this._id = value;
  }

  private _name: string;

  get name(): string {
    return this._name;
  }

  set name(value: string) {
    this._name = value;
  }

  get opacity$(): Observable<number> {
    return this._opacity$$.asObservable();
  }

  get opacity(): number {
    return this._opacity$$.value;
  }

  set opacity(value: number) {
    this._opacity$$.next(value);
  }

  get visible$(): Observable<boolean> {
    return this._visible$$.asObservable();
  }

  get visible(): boolean {
    return this._visible$$.value;
  }

  set visible(value: boolean) {
    this._visible$$.next(value);
  }

  get disablePointerEvents$(): Observable<boolean> {
    return this._disablePointerEvents$$.asObservable();
  }

  get disablePointerEvents(): boolean {
    return this._disablePointerEvents$$.value;
  }

  set disablePointerEvents(value: boolean) {
    this._disablePointerEvents$$.next(value);
  }

  toString(): string {
    return this.name;
  }

  toJSON(): ILayer {
    return {
      id: this.id,
      name: this.name,
      opacity: this.opacity,
      visible: this.visible,
      disablePointerEvents: this.disablePointerEvents
    } as ILayer;
  }
}
