export abstract class Layout {
  protected _padding = 0;

  abstract get name(): string;

  get padding(): number {
    return this._padding;
  }

  set padding(value: number) {
    this._padding = value;
  }
}
