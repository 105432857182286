import { inject, injectable } from "inversify";
import { ActivityBase, GanttLayout, IocContainer, IocSymbols, Layout, Lifecycle } from "../../../../Core";
import { ActivityTooltipRenderer } from "./ActivityTooltipRenderer";
import { DefaultActivityTooltipRenderer } from "./DefaultActivityTooltipRenderer";

@injectable()
export class ActivityTooltipRendererRegistry extends Lifecycle {
  private _renderers = new Map<typeof Layout, Map<new (...args: any[]) => ActivityBase<any>, new (...args: any[]) => ActivityTooltipRenderer>>();

  constructor(@inject(IocContainer) private _container: IocContainer) {
    super();
  }

  registerDefaultActivityTooltipRenderer(): void {
    this._container.bind(IocSymbols.ActivityTooltipRenderer).to(DefaultActivityTooltipRenderer).whenTargetTagged(IocSymbols.ActivityTooltipRendererTag, "default");
  }

  registerActivityTooltipRenderer<
    TRenderer extends ActivityTooltipRenderer,
    TRendererClass extends new (...args: any[]) => TRenderer,
    TActivityBase extends new (...args: any[]) => ActivityBase<any>
  >(activityClass: TActivityBase, rendererClass: TRendererClass, layoutClass: typeof Layout = GanttLayout): void {
    let renderers = this._renderers.get(layoutClass);
    if (!renderers) {
      this._renderers.set(layoutClass, new Map<new (...args: any[]) => ActivityBase<any>, new (...args: any[]) => ActivityTooltipRenderer>());
      renderers = this._renderers.get(layoutClass);
    }
    if (renderers) {
      renderers.set(activityClass, rendererClass);
    }
    this._container.bind(IocSymbols.ActivityTooltipRenderer).to(rendererClass).whenTargetTagged(IocSymbols.ActivityTooltipRendererTag, activityClass.name);
    // console.debug(`[Gantt] Registered activity tooltip renderer ${rendererClass.name} for activity ${activityClass.name} and layout ${layoutClass.name}`);
  }
}
