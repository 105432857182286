import ApiService from "@/services/api";
import { CreateScenarioIfDoesNotExistCommand, MergeScenariosCommand, ScenarioDto, ScenarioStatus, UpdateScenarioCommand } from "@masta/generated-model";
import { defineStore } from "pinia";
import { useErrorsStore } from "@/store/ErrorsStore";

export interface State {
  scenarios: ScenarioDto[];
  selectedScenario: ScenarioDto | null;
}

const SELECTED_SCENARIO_KEY = "selectedScenarioId";

export const useScenariosStore = defineStore("scenarios", {
  state: (): State => ({
    scenarios: [],
    selectedScenario: null
  }),
  getters: {
    scenarioId: (state) => state.selectedScenario?.id,
    scenarioBusinessId: (state) => state.selectedScenario?.businessId,
    getScenarioByIdOrBusinessId: (state) => (scenarioIdOrBusinessId: string | null | undefined): ScenarioDto | undefined => {
      return state.scenarios?.find((s) => s.id === scenarioIdOrBusinessId || s.businessId === scenarioIdOrBusinessId);
    },
    isProductionScenarioSelected: (state) => state.selectedScenario !== null && state.selectedScenario.status === 0,
    isScenarioSelected: (state) => state.selectedScenario !== null
  },
  actions: {
    async fetch() {
      try {
        const response = await ApiService.scenarios.getScenarios();
        const scenarios =
          response.data.scenarios?.map((s: ScenarioDto) => ({
            ...s,
            isProduction: s.status === 0
          })) ?? [];
        this.scenarios = scenarios;
        await this.loadSelectedScenario(); // Load the selected scenario from local storage or default
      } catch (e) {
        const errorsStore = useErrorsStore();
        errorsStore.handleError(e);
        throw e;
      }
    },
    async create(request: CreateScenarioIfDoesNotExistCommand) {
      try {
        const response = await ApiService.scenarios.createScenario(request);
        await this.fetch();
      } catch (e) {
        const errorsStore = useErrorsStore();
        errorsStore.handleError(e);
        throw e;
      }
    },
    async delete(id: string) {
      try {
        await ApiService.scenarios.deleteScenario(id);
        const index = this.scenarios.findIndex((s) => s.id === id);
        this.scenarios.splice(index, 1);
        if (this.selectedScenario?.id === id) {
          this.clearSelectedScenario();
        }
      } catch (e) {
        const errorsStore = useErrorsStore();
        errorsStore.handleError(e);
        throw e;
      }
    },
    async update(updated: UpdateScenarioCommand) {
      try {
        await ApiService.scenarios.updateScenario(updated);
        this.scenarios = this.scenarios.map((s: ScenarioDto) => {
          if (s.id === updated.id) {
            const newScenario = { ...s };
            newScenario.name = updated.name;
            return newScenario;
          }
          return s;
        });
      } catch (e) {
        const errorsStore = useErrorsStore();
        errorsStore.handleError(e);
        throw e;
      }
    },
    async release(id: string) {
      try {
        await ApiService.scenarios.releaseScenario(id);
        this.scenarios = this.scenarios.map((s: ScenarioDto) => {
          if (s.id === id) {
            const newScenario = { ...s };
            newScenario.status = 2;
            return newScenario;
          }
          return s;
        });
      } catch (e) {
        const errorsStore = useErrorsStore();
        errorsStore.handleError(e);
        throw e;
      }
    },
    async merge(request: MergeScenariosCommand) {
      try {
        await ApiService.scenarios.mergeScenarios(request);
      } catch (e) {
        const errorsStore = useErrorsStore();
        errorsStore.handleError(e);
        throw e;
      }
    },
    selectScenario(scenario: ScenarioDto) {
      this.selectedScenario = scenario;
      // localStorage.setItem(SELECTED_SCENARIO_KEY, scenario.id); // Save the selected scenario ID to local storage
    },
    clearSelectedScenario() {
      this.selectedScenario = null;
      // localStorage.removeItem(SELECTED_SCENARIO_KEY); // Remove the selected scenario ID from local storage
    },
    async loadSelectedScenario() {
      // const savedId = localStorage.getItem(SELECTED_SCENARIO_KEY);
      // if (savedId) {
      //   const scenario = this.getScenarioByIdOrBusinessId(savedId);
      //   if (scenario) {
      //     this.selectedScenario = scenario;
      //     return;
      //   }
      // }
      if (this.scenarios.length > 0) {
        this.selectedScenario = this.scenarios.find(s => s.status == ScenarioStatus.Production) || this.scenarios[0];
        // localStorage.setItem(SELECTED_SCENARIO_KEY, this.selectedScenario.id);
      }
    },
    isMatchingScenario(scenario: ScenarioDto, scenarioIdOrBusinessId: string) {
      return scenario.id === scenarioIdOrBusinessId || scenario.businessId === scenarioIdOrBusinessId;
    },
    isSelectedScenarioMatching(scenarioIdOrBusinessId: string | null | undefined) {
      return !!scenarioIdOrBusinessId && this.isMatchingScenario(this.selectedScenario!, scenarioIdOrBusinessId);
    },
    checkIfScenarioExists(scenarioIdOrBusinessId: string | null | undefined) {
      return !!scenarioIdOrBusinessId && this.scenarios.some((s) => this.isMatchingScenario(s, scenarioIdOrBusinessId));
    }
  }
});
