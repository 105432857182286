import {
  GridApi,
  GridReadyEvent,
  IsServerSideGroupOpenByDefaultParams,
  ModelUpdatedEvent,
  PaginationChangedEvent,
  RowEditingStartedEvent,
  RowEditingStoppedEvent,
  RowGroupOpenedEvent,
  RowSelectedEvent
} from "ag-grid-community";
import { GridState, GridStateActions } from "@/store/GridRegistryState";
import { Store, storeToRefs } from "pinia";
import { tryOnBeforeUnmount } from "@vueuse/core";
import { getSelectedRows } from "@/components/Grid/UseGridSelection";

export const useGridStoreBinding = (store: Store<string, GridState, any, GridStateActions>) => {
  const { rowSelected, rowDeselected, startEditing, stopEditing, rowExpanded, rowCollapsed, paginationChanged } = store;

  const { selectedEntity, expandedRows } = storeToRefs(store);

  let gridApi: GridApi = null!;

  function onGridReady(event: GridReadyEvent) {
    gridApi = event.api;
    gridApi.addEventListener("rowSelected", onRowSelected);
    gridApi.addEventListener("rowEditingStarted", onRowEditingStarted);
    gridApi.addEventListener("rowEditingStopped", onRowEditingStopped);
    gridApi.addEventListener("rowGroupOpened", onModelUpdated);
    gridApi.addEventListener("paginationChanged", onPaginationChanged);
  }

  tryOnBeforeUnmount(() => {
    if (!gridApi) return;
    gridApi.removeEventListener("rowSelected", onRowSelected);
    gridApi.removeEventListener("rowEditingStarted", onRowEditingStarted);
    gridApi.removeEventListener("rowEditingStopped", onRowEditingStopped);
    gridApi.removeEventListener("rowGroupOpened", onModelUpdated);
    gridApi.removeEventListener("paginationChanged", onPaginationChanged);
  });

  function onRowSelected(event: RowSelectedEvent) {
    const rows = getSelectedRows(event.api);
    if (rows.length > 0) {
      selectedEntity.value = rows[0];
      rowSelected();
    } else {
      selectedEntity.value = null;
      rowDeselected();
    }
  }

  function onRowEditingStarted(event: RowEditingStartedEvent) {
    const isUpdating = event.rowPinned !== "top" && event.rowPinned !== "bottom";
    startEditing(isUpdating, event.rowIndex);
  }

  function onRowEditingStopped(_: RowEditingStoppedEvent) {
    stopEditing();
  }

  function onModelUpdated(event: RowGroupOpenedEvent) {
    if (event.node.id) {
      if (event.expanded) rowExpanded(event.node.id);
      else rowCollapsed(event.node.id);
    }
  }

  function onPaginationChanged(event: PaginationChangedEvent) {
    paginationChanged();  
  }

  function isServerSideGroupOpenByDefault(params: IsServerSideGroupOpenByDefaultParams) {
    return expandedRows.value.includes(params.rowNode.id!);
  }

  return {
    onGridReady,
    isServerSideGroupOpenByDefault
  };
};
