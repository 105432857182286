import { DocumentTile, MediaItemType } from "@/components/Media/MediaModel";
import ApiService from "@/services/api";
import { AttachmentDto, DocumentDto } from "@masta/generated-model";
import { useDocumentObjectUrlsStore } from "@/store/DocumentObjectUrlsStore";

export const useMedia = (options: { thumbnailWidth: number } = { thumbnailWidth: 480 }) => {

  const documentObjectUrlsStore = useDocumentObjectUrlsStore();

  function isImage(attachment: AttachmentDto) {
    return attachment.mimeType?.startsWith("image");
  }

  function isPdf(attachment: AttachmentDto) {
    return attachment.mimeType?.startsWith("application/pdf");
  }

  function isModel(attachment: AttachmentDto) {
    const fileExtensions = [".obj", ".stp", ".step"];
    return fileExtensions.some((ext) => attachment.fileName?.toLowerCase().endsWith(ext));
  }
  async function transformDtoForManualEditor(documentDto: DocumentDto): Promise<DocumentTile | undefined> {
    const documentData = documentDto.value;
    const attachment = documentData.attachment;
    if (isImage(attachment)) {
      const { data } = await ApiService.documents.getDocumentContentThumbnailStream({
        documentId: documentDto.id,
        revisionNumber: documentDto.revisionNumber,
        attachmentContentStorageConfigurationId: attachment.attachmentContentStorageConfigurationId ?? undefined,
        attachmentSourceType: attachment.sourceType,
        width: options.thumbnailWidth
      });
      return {
        title: attachment.fileName ?? attachment.uri,
        url: documentObjectUrlsStore.add(documentDto.id, documentDto.revisionNumber, data),
        documentDto: documentDto,
        type: MediaItemType.ImageFile
      } as DocumentTile;
    }
    return undefined;
  }

  async function transformDtoToTile(documents: DocumentDto[] = []) {
    const result: DocumentTile[] = [];

    await Promise.all(
      documents.map(async (documentDto) => {
        const documentData = documentDto.value;
        const attachment = documentData.attachment;

        if (!attachment || !attachment.uri) {
          return;
        }

        if (isImage(attachment)) {
          const { data } = await ApiService.documents.getDocumentContentThumbnailStream({
            documentId: documentDto.id,
            revisionNumber: documentDto.revisionNumber,
            attachmentContentStorageConfigurationId: attachment.attachmentContentStorageConfigurationId ?? undefined,
            attachmentSourceType: attachment.sourceType,
            width: options.thumbnailWidth
          });
          result.push({
            title: attachment.fileName ?? attachment.uri,
            url: window.URL.createObjectURL(data),
            documentDto: documentDto,
            type: MediaItemType.ImageFile
          } as DocumentTile);
        } else if (isPdf(attachment)) {
          const { data } = await ApiService.documents.getDocumentContentThumbnailStream({
            documentId: documentDto.id,
            revisionNumber: documentDto.revisionNumber,
            attachmentContentStorageConfigurationId: attachment.attachmentContentStorageConfigurationId ?? undefined,
            attachmentSourceType: attachment.sourceType
          });
          result.push({
            title: attachment.fileName ?? attachment.uri,
            url: window.URL.createObjectURL(data),
            documentDto: documentDto,
            type: MediaItemType.ImageFile
          } as DocumentTile);
        } else if (isModel(attachment)) {
          result.push({
            title: attachment.fileName ?? attachment.uri,
            file: async () => {
              const { data } = await ApiService.documents.getDocumentContentStream({
                documentId: documentDto.id,
                revisionNumber: documentDto.revisionNumber,
                attachmentContentStorageConfigurationId: attachment.attachmentContentStorageConfigurationId ?? undefined,
                attachmentSourceType: attachment.sourceType
              });
              const blob = new Blob([data], { type: "application/octet-stream" });
              return new File([blob], attachment.fileName);
            },
            documentDto: documentDto,
            type: MediaItemType.ModelFile
          } as DocumentTile);
        }
      })
    );

    // sort by name and type
    result.sort((a, b) => {
      if (a.type === b.type) {
        return a.title.localeCompare(b.title);
      }
      return b.type === MediaItemType.ImageFile ? 1 : -1;
    });

    return result;
  }

  return {
    transformDtoToTile,
    transformDtoForManualEditor,
    isImage,
    isPdf,
    isModel
  };
};
