<script lang="ts" setup>
import { GuiModelItem, GuiObjectType } from "@masta/json-schema";
import TextPropertyComponent from "./TextPropertyComponent.vue";
import NumberPropertyComponent from "./NumberPropertyComponent.vue";
import BoolPropertyComponent from "./BoolPropertyComponent.vue";
import SelectPropertyComponent from "./SelectPropertyComponent.vue";
import MultiSelectPropertyComponent from "./MultiSelectPropertyComponent.vue";
import ObjectComponent from "./ObjectComponent.vue";

const props = defineProps<{
  modelValue: GuiModelItem;
  disabled: boolean;
}>();
const $emit = defineEmits(["update:modelValue"]);

function getComponentName(item: GuiModelItem): ComponentDefinition {
  if (item.type === GuiObjectType.Text) return TextPropertyComponent;
  if (item.type === GuiObjectType.Select) return SelectPropertyComponent;
  if (item.type === GuiObjectType.Object) return ObjectComponent;
  if (item.type === GuiObjectType.Number) return NumberPropertyComponent;
  if (item.type === GuiObjectType.Bool) return BoolPropertyComponent;
  if (item.type === GuiObjectType.MultiSelect) return MultiSelectPropertyComponent;
  throw new Error("Gui component not found");
}
</script>

<template>
  <keep-alive>
    <component :is="getComponentName(modelValue)" :model-value="modelValue" :disabled="disabled" @update:model-value="$emit('update:modelValue', $event.target.value)" />
  </keep-alive>
</template>
