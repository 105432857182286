import ApiService from "@/services/api";
import { ServerSideDataSource } from "@/components/Grid/ServerSideDataSource";
import { CreateOrUpdateCustomerOrderLineCommand, GetCustomerOrderLinesPaginatedQuery, IAgGridPaginationResponse, CustomerOrderLineDto } from "@masta/generated-model";
import { IServerSideGetRowsParams } from "ag-grid-community";
import { State as ScenarioState, useScenariosStore } from "@/store/ScenariosStore";
import { Store } from "pinia";
import { GUID_EMPTY } from "@/components/ValueCellEditor/CommonFormatters";

export class CustomerOrderLinesServerSideDataSource extends ServerSideDataSource<
  CustomerOrderLineDto,
  CustomerOrderLineDto,
  CreateOrUpdateCustomerOrderLineCommand,
  CreateOrUpdateCustomerOrderLineCommand,
  string
> {
  private _scenarioStore: Store<string, ScenarioState>;
  private _filterByProductId: string | null | undefined;
  private _filterByOrderId: string | null | undefined;

  constructor(id: string) {
    super(id);
    this._scenarioStore = useScenariosStore();
  }

  public useFilteringByProductId(productId: string | null | undefined) {
    this._filterByProductId = productId;
  }

  public useFilteringByOrderId(productId: string | null | undefined) {
    this._filterByOrderId = productId;
  }

  public static transformCustomerOrderLineDtoToCreateOrUpdateCustomerOrderLineCommand(orderId: string, listEntity: CustomerOrderLineDto): CreateOrUpdateCustomerOrderLineCommand {
    return {
      id: listEntity.id,
      scenarioId: listEntity.scenarioId ?? GUID_EMPTY,
      orderId: orderId,
      itemBusinessId: listEntity.itemBusinessId,
      itemId: listEntity.itemId,
      description: listEntity.description,
      orderLineStatus: listEntity.status,
      position: listEntity.position,
      deliveryDate: listEntity.deliveryDate,
      quantity: listEntity.quantity
    };
  }

  async create(entity: CustomerOrderLineDto): Promise<string> {
    const request = this.transformForCreate(entity);
    const scenario = this._scenarioStore.selectedScenario;
    request.scenarioId = scenario?.id ?? "";
    const response = await ApiService.customerOrders.createOrUpdateCustomerOrderLine(request);
    return response.data;
  }

  async getAll<T>(params: IServerSideGetRowsParams): Promise<IAgGridPaginationResponse<CustomerOrderLineDto>> {
    const scenarioId = this._scenarioStore.selectedScenario?.id;

    if (!scenarioId || !this.isOrderSelected()) {
      return { count: 0, results: [] } as IAgGridPaginationResponse<CustomerOrderLineDto>;
    }

    const request = {
      ...this.constructServerRequest(params),
      orderId: this.getOrderId(),
      scenarioId,
      resourceId: this._filterByProductId
    } as GetCustomerOrderLinesPaginatedQuery;
    const response = await ApiService.customerOrders.getCustomerOrderLinesPaginated(request);

    return response.data as IAgGridPaginationResponse<CustomerOrderLineDto>;
  }

  async getSingle(id: string): Promise<CustomerOrderLineDto | undefined> {
    const scenarioId = this._scenarioStore.selectedScenario?.id;

    if (!scenarioId || !this.isOrderSelected()) return;

    if (!this.getOrderId()) return;

    const response = await ApiService.customerOrders.getCustomerOrderLine(this.getOrderId(), id, scenarioId);
    return response.data;
  }

  async update(entity: CustomerOrderLineDto): Promise<string> {
    const response = await ApiService.customerOrders.createOrUpdateCustomerOrderLine(this.transformForUpdate(entity));
    return response.data;
  }

  protected transformForCreate(listEntity: CustomerOrderLineDto): CreateOrUpdateCustomerOrderLineCommand {
    return CustomerOrderLinesServerSideDataSource.transformCustomerOrderLineDtoToCreateOrUpdateCustomerOrderLineCommand(this.getOrderId(), listEntity);
  }

  protected transformForUpdate(listEntity: CustomerOrderLineDto): CreateOrUpdateCustomerOrderLineCommand {
    return CustomerOrderLinesServerSideDataSource.transformCustomerOrderLineDtoToCreateOrUpdateCustomerOrderLineCommand(this.getOrderId(), listEntity);
  }

  async remove(entity: CustomerOrderLineDto): Promise<void> {
    if (entity.id && this._filterByOrderId) {
      await ApiService.customerOrders.removeCustomerOrderLine(this._filterByOrderId, entity.id, this._scenarioStore.selectedScenario?.id);
    }
  }

  private isOrderSelected() {
    return this._filterByOrderId;
  }

  private getOrderId(): string {
    return this._filterByOrderId ?? "";
  }
}
