import { Ref } from "vue";
import { useI18n } from "vue-i18n";
import { ResourcesGanttLoader } from "@/components/Gantt/ResourcesGantt";
import ContextMaterialGanttWorker from "./ContextMaterialGanttWorker?worker&url";

export class ContextMaterialGanttLoader extends ResourcesGanttLoader {
  constructor(
    container: Ref<HTMLDivElement | undefined>,
    selectedScenario: Ref<any>,
    i18n: ReturnType<typeof useI18n>,
    filterExpressionProvider?: () => string | null | undefined
  ) {
    super(container, selectedScenario, i18n, filterExpressionProvider);
  }

  // eslint-disable-next-line @typescript-eslint/ban-types
  protected provideModules(): (string | Function)[] {
    const commonModule = import.meta.glob("./../Common/CommonFrontModule.ts")["../Common/CommonFrontModule.ts"];
    const resourcesFrontModule = import.meta.glob("./../ResourcesGantt/ResourcesFrontModule.ts")["../ResourcesGantt/ResourcesFrontModule.ts"];
    const dndFrontModule = import.meta.glob("./../ResourcesGantt/DragAndDrop/DragAndDropFrontModule.ts")["../ResourcesGantt/DragAndDrop/DragAndDropFrontModule.ts"];
    const contextMaterialFrontModule = import.meta.glob("./ContextMaterialGanttFrontModule.ts")["./ContextMaterialGanttFrontModule.ts"];
    return [commonModule, resourcesFrontModule, dndFrontModule, contextMaterialFrontModule];
  }

  protected provideCustomGanttWorker(): Worker {
    return new Worker(new URL(ContextMaterialGanttWorker, import.meta.url), { type: "module" });
  }
}
