<script>
/**
 * ag-grid wrapper helper component
 * @deprecated Deprecated. Use Vue 3 GridWrapper component instead.
 */
export default {
  name: "Grid",
  data: () => ({
    actions: [],
    agGridRef: null,
    gridApi: null,
    columnApi: null,
    columnDefs: [],
    getDataPath: null,
    autoGroupColumnDef: null,
    defaultColDef: {
      flex: 1
    },
    isRowSelected: false
  }),
  created() {
  },
  beforeMount() {
    if (this.prepareColumns) {
      this.prepareColumns();
    }
  },
  async mounted() {
    if (!this.$refs.grid) {
      console.warn("please set ref=\"grid\" on <grid /> component", this);
    } else {
      this.agGridRef = this.$refs.grid.$refs.agGrid;
    }
    if (this.fetchData) {
      await this.fetchData();
    }
  },
  methods: {
    onGridReady(params) {
      this.gridApi = params.api;
      this.columnApi = params.columnApi;
      //this.gridApi.sizeColumnsToFit();
      this.gridApi.setPopupParent(this.$el);
      if (this.gridReady) {
        this.gridReady();
      }
    },
    onSelectionChanged() {
      if (!this.gridApi) return;
      let rows = this.gridApi.getSelectedRows();
      this.isRowSelected = rows.length > 0;
      if (rows.length >= 1 && this.rowsSelected) {
        this.rowsSelected(rows);
      }
      if (rows.length === 0 && this.rowsDeselected) {
        this.rowsDeselected();
      }
    },
    onRowSelected({ node, data }) {
      if (node.isSelected() && this.rowSelected) {
        this.rowSelected(data);
      }
      if (!node.isSelected() && this.rowDeselected) {
        this.rowDeselected(data);
      }
    },
    onRowDataChanged() {
      this.onSelectionChanged();
    },
    getContextMenuItems() {
      return this.getContextMenuItemsForActions(this.actions);
    },
    getContextMenuItemsForActions(actions) {
      return actions.map((action) => ({
        name: action.title,
        icon: `<span class="mdi ${action.icon}"></span>`,
        action: action.action
      }));
    },
    getStatusBasedCellStyle(status) {
      let appContext = this.$appContext.value;
      if (isNaN(status) || !(appContext && appContext.statusSettings)) return null;
      return {
        color: appContext.statusSettings.textColors.find((x) => x.status === status)?.color,
        "background-color": appContext.statusSettings.backgroundColors.find((x) => x.status === status)?.color
      };
    },
    getResourceCapacitySourceCellStyle(source) {
      if (source === "Scheduling") {
        return {
          color: "#434343",
          "background-color": "#DEDAF2"
        };
      }
      if (source === "Execution") {
        return {
          color: "#140853",
          "background-color": "#A8E8FF"
        };
      }
      return null;
    },
    onFirstDataRendered() {
      this.autoSizeAll(true);
    },
    autoSizeAll(skipHeader) {
      const allColumnIds = [];
      this.columnApi.getColumns().forEach((column) => {
        allColumnIds.push(column.getId());
      });
      this.columnApi.autoSizeColumns(allColumnIds, skipHeader);
    }
  }
};
</script>
