import ApiService from "@/services/api";
import { LocationDto, UpdateResourceModelInstanceAssignmentCommand } from "@masta/generated-model";
import { defineStore } from "pinia";
import { useScenariosStore } from "@/store/ScenariosStore";
import { useErrorsStore } from "@/store/ErrorsStore";

interface State {
  locations: LocationDto[];
}

export const useLocationsStore = defineStore("locations", {
  state: (): State => ({
    locations: []
  }),
  actions: {
    async fetch() {
      try {
        const scenariosStore = useScenariosStore();
        if (!scenariosStore.selectedScenario) return;
        const response = await ApiService.locations.getLocations({
          scenarioId: scenariosStore.selectedScenario.id
        });
        this.locations = response.data.results;
      } catch (e) {
        const errorsStore = useErrorsStore();
        errorsStore.handleError(e);
        throw e;
      }
    },
    async fetchLocation(id: string) {
      try {
        const scenariosStore = useScenariosStore();
        if (!scenariosStore.selectedScenario) return;
        const response = await ApiService.locations.getSingle(id, scenariosStore.selectedScenario.id);
        if (response.data) {
          const idx = this.locations.findIndex((x) => x.id === id);
          if (idx !== -1) {
            this.locations[idx] = response.data;
          } else {
            this.locations.push(response.data);
          }
        }
        return response.data;
      } catch (e) {
        const errorsStore = useErrorsStore();
        errorsStore.handleError(e);
        throw e;
      }
    },
    async updateModelInstanceAssignment(request: UpdateResourceModelInstanceAssignmentCommand) {
      try {
        await ApiService.resources.updateModelInstanceAssignment(request);
      } catch (e) {
        const errorsStore = useErrorsStore();
        errorsStore.handleError(e);
        throw e;
      }
    },
    async getLocation(id: string) {
      const idx = this.locations.findIndex((x) => x.id === id);
      if (idx !== -1) {
        return this.locations[idx];
      } else {
        return this.fetchLocation(id);
      }
    }
  }
});
