import { VueKeycloakConfig, VueKeycloakInstance, VueKeycloakOptions, VueKeycloakTokenParsed } from "./keycloak.types";
import { assertOptions, getConfig } from "./utils";
import Keycloak from "keycloak-js";
import { reactive, UnwrapNestedRefs } from "vue";

class KeycloakService {
  private _keycloak: Keycloak.KeycloakInstance;
  private _instance: UnwrapNestedRefs<VueKeycloakInstance> = reactive({
    ready: false,
    authenticated: false
  } as VueKeycloakInstance);

  get instance(): UnwrapNestedRefs<VueKeycloakInstance> {
    return this._instance;
  }

  public async initialize(options: VueKeycloakOptions): Promise<boolean> {
    if (assertOptions(options).hasError) throw new Error(`Invalid options given: ${assertOptions(options).error}`);
    const resolvedConfig: VueKeycloakConfig = (options.config ?? {}) as VueKeycloakConfig;
    return getConfig(resolvedConfig).then((config) => {
      this._keycloak = new Keycloak(config);
      return this.initializeKeycloakAdapter(this._keycloak, this._instance, options);
    });
  }

  public setTokens(token, refreshToken, idToken) {
    if (!this._keycloak) {
      console.error("Keycloak instance not initialized");
      return;
    }

    const timeLocal = new Date().getTime();
    const keycloakAny = this._keycloak as any;
    if (keycloakAny && keycloakAny.tokenTimeoutHandle) {
      clearTimeout(keycloakAny.tokenTimeoutHandle);
      keycloakAny.tokenTimeoutHandle = null;
    }

    if (refreshToken) {
      this._keycloak.refreshToken = refreshToken;
      this._keycloak.refreshTokenParsed = this.decodeToken(refreshToken);
    } else {
      delete this._keycloak.refreshToken;
      delete this._keycloak.refreshTokenParsed;
    }

    if (idToken) {
      this._keycloak.idToken = idToken;
      this._keycloak.idTokenParsed = this.decodeToken(idToken);
    } else {
      delete this._keycloak.idToken;
      delete this._keycloak.idTokenParsed;
    }

    if (token) {
      this._keycloak.token = token;
      this._keycloak.tokenParsed = this.decodeToken(token);
      this._keycloak.sessionId = this._keycloak.tokenParsed?.session_state;
      this._keycloak.authenticated = true;
      this._keycloak.subject = this._keycloak.tokenParsed?.sub;
      this._keycloak.realmAccess = this._keycloak.tokenParsed?.realm_access;
      this._keycloak.resourceAccess = this._keycloak.tokenParsed?.resource_access;

      if (timeLocal && this._keycloak.tokenParsed?.iat) {
        this._keycloak.timeSkew = Math.floor(timeLocal / 1000) - this._keycloak.tokenParsed?.iat;
      }

      if (this._keycloak.timeSkew != null) {
        if (this._keycloak.onTokenExpired) {
          const expiresIn = (keycloakAny.tokenParsed["exp"] - new Date().getTime() / 1000 + this._keycloak.timeSkew) * 1000;
          if (expiresIn <= 0) {
            this._keycloak.onTokenExpired();
          } else {
            keycloakAny.tokenTimeoutHandle = setTimeout(this._keycloak.onTokenExpired, expiresIn);
          }
        }
      }
    } else {
      delete this._keycloak.token;
      delete this._keycloak.tokenParsed;
      delete this._keycloak.subject;
      delete this._keycloak.realmAccess;
      delete this._keycloak.resourceAccess;

      this._keycloak.authenticated = false;
    }
    this.updateState(this._keycloak.authenticated, this._keycloak, this._instance);
  }

  // based on: https://github.com/dsb-norge/vue-keycloak-js/blob/main/src/index.ts
  private async initializeKeycloakAdapter(keycloakInstance: Keycloak.KeycloakInstance, adapter: VueKeycloakInstance, options: VueKeycloakOptions): Promise<boolean> {
    keycloakInstance.onReady = (authenticated) => {
      this.updateState(authenticated, keycloakInstance, adapter);
      adapter.ready = true;
      typeof options.onReady === "function" && options.onReady(keycloakInstance, adapter);
    };
    keycloakInstance.onAuthSuccess = function() {
      if (!options.config) throw new Error("Invalid configuration. RefreshToken interval and min validity required!");
      // Check token validity every 10 seconds (10 000 ms) and, if necessary, update the token.
      // Refresh token if it's valid for less than 60 seconds
      const updateTokenInterval = setInterval(() => {
        if (!options.config) return;
        keycloakInstance
          .updateToken(options.config.refreshTokenMinValidity)
          .catch(() => {
            console.error("Failed to refresh token");
            // keycloakInstance.clearToken();
          });
      }, options.config.refreshTokenInterval * 1000);
      adapter.logoutFn = () => {
        clearInterval(updateTokenInterval);
        keycloakInstance.logout(options.logout);
      };
    };
    keycloakInstance.onAuthRefreshSuccess = () => {
      this.updateState(true, keycloakInstance, adapter);
      typeof options.onAuthRefreshSuccess === "function" && options.onAuthRefreshSuccess(keycloakInstance, adapter);
    };
    keycloakInstance.onAuthRefreshError = () => {
      this.updateState(false, keycloakInstance, adapter);
      typeof options.onAuthRefreshError === "function" && options.onAuthRefreshError(keycloakInstance, adapter);
    };

    if (!options.init) return false;

    try {
      const authenticated = await keycloakInstance.init(options.init);
      this.updateState(authenticated, keycloakInstance, adapter);
      typeof options.onInitSuccess === "function" && options.onInitSuccess(authenticated, keycloakInstance, adapter);
      return authenticated;
    } catch (err: any) {
      const error = new Error(
        err && err.error && err.error.startsWith("Timeout") ? "Could not login to this tenant. Check if tenant name is correct." : "Failure during auth initialization"
      );
      typeof options.onInitError === "function" && options.onInitError(error, err);
      return false;
    }
  }

  private updateState(isAuthenticated = false, keycloakInstance: Keycloak.KeycloakInstance, adapter: VueKeycloakInstance) {
    adapter.authenticated = isAuthenticated;
    adapter.loginFn = keycloakInstance.login;
    adapter.login = keycloakInstance.login;
    adapter.createLoginUrl = keycloakInstance.createLoginUrl;
    adapter.createLogoutUrl = keycloakInstance.createLogoutUrl;
    adapter.createRegisterUrl = keycloakInstance.createRegisterUrl;
    adapter.register = keycloakInstance.register;
    adapter.keycloak = keycloakInstance;
    adapter.accountManagement = keycloakInstance.accountManagement;
    adapter.createAccountUrl = keycloakInstance.createAccountUrl;
    adapter.hasRealmRole = keycloakInstance.hasRealmRole;
    adapter.hasResourceRole = keycloakInstance.hasResourceRole;
    adapter.loadUserProfile = keycloakInstance.loadUserProfile;
    adapter.token = keycloakInstance.token;
    adapter.subject = keycloakInstance.subject;
    adapter.idToken = keycloakInstance.idToken;
    adapter.idTokenParsed = keycloakInstance.idTokenParsed as VueKeycloakTokenParsed;
    adapter.realmAccess = keycloakInstance.realmAccess;
    adapter.resourceAccess = keycloakInstance.resourceAccess;
    adapter.refreshToken = keycloakInstance.refreshToken;
    adapter.refreshTokenParsed = keycloakInstance.refreshTokenParsed as VueKeycloakTokenParsed;
    adapter.timeSkew = keycloakInstance.timeSkew;
    adapter.responseMode = keycloakInstance.responseMode;
    adapter.responseType = keycloakInstance.responseType;
    adapter.tokenParsed = keycloakInstance.tokenParsed as VueKeycloakTokenParsed;
    adapter.userName = (keycloakInstance.tokenParsed as VueKeycloakTokenParsed)?.preferred_username;
    adapter.fullName = (keycloakInstance.tokenParsed as VueKeycloakTokenParsed)?.name;

  }

  private decodeToken(str) {
    str = str.split(".")[1];

    str = str.replace(/-/g, "+");
    str = str.replace(/_/g, "/");
    switch (str.length % 4) {
      case 0:
        break;
      case 2:
        str += "==";
        break;
      case 3:
        str += "=";
        break;
      default:
        throw "Invalid token";
    }

    str = decodeURIComponent(escape(atob(str)));

    str = JSON.parse(str);
    return str;
  }
}

export const $keycloack = new KeycloakService();

