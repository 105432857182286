<template>
  <div class="master-detail-layout">
    <div v-if="scenarioSelector" class="scenario-selector">
      <v-row no-gutters>
        <v-col cols="12">
          <!-- <scenario-selector v-if="isAuthenticated && !isAuthLoading" @scenario-selection-changed="onScenarioSelectionChanged" /> -->
        </v-col>
      </v-row>
    </div>
    <slot v-if="!scenarioSelector || isScenarioSelected" name="master" />
    <div v-else class="d-flex justify-center pa-4">
      <v-progress-circular indeterminate />
    </div>
    <v-navigation-drawer
      ref="drawer"
      :model-value="drawer"
      class="detail-drawer"
      temporary
      location="right"
      :style="{ width: drawerWidth === null ? detailDialogWidth : drawerWidth }"
      @update:model-value="onClickOutside"
    >
      <div class="d-flex flex-column fill-height pa-4">
        <div>
          <v-row no-gutters>
            <v-col cols="6" class="text-left" align-self="center">
              <slot name="detail-title" />
              <actions-button :model-value="detailActions" />
            </v-col>
            <v-col cols="6" class="text-right" align-self="center">
              <v-btn color="accent" density="compact" variant="flat" size="large" icon="mdi-close" @click="close" />
            </v-col>
          </v-row>
        </div>
        <div v-if="opened" id="scrolling" class="flex-1-0">
          <slot name="detail" />
        </div>
      </div>
    </v-navigation-drawer>
  </div>
</template>

<script>
import * as Hammer from "hammerjs";
import ActionsButton from "@/components/Layout/ActionsButton.vue";
import ScenarioSelector from "@/components/ScenarioSelector.vue";
import { useAuthStore } from "@/store/AuthStore";
import { mapState } from "pinia";
import { useScenariosStore } from "@/store/ScenariosStore";

export default {
  name: "MasterDetailLayout",
  components: { ScenarioSelector, ActionsButton },
  props: {
    detail: {
      type: Boolean,
      default: false
    },
    detailActions: {
      type: Array,
      default: () => []
    },
    detailDialogWidth: {
      type: String,
      default: "50%"
    },
    scenarioSelector: {
      type: Boolean,
      default: false
    },
    preventEscape: {
      type: Boolean,
      default: false
    }
  },
  emits: ["change", "scenarioSelectionChanged", "resize", "beforeClose"],
  data() {
    return {
      absolute: true,
      opacity: 0.5,
      opened: false,
      drawerWidth: null,
      drawerBorderEl: null,
      hammer: null,
      dragging: false,
      drawerInitialWidth: 0,
      isMouseOver: false,
      isBorderHoover: false
    };
  },
  computed: {
    ...mapState(useAuthStore, {
      isAuthLoading: "loading",
      isAuthenticated: "isAuthenticated"
    }),
    ...mapState(useScenariosStore, ["selectedScenario"]),
    drawer: {
      get() {
        return this.opened;
      },
      set(value) {
        if (!value) this.$emit("change", value);
        this.opened = value;
      }
    },
    isScenarioSelected() {
      return this.selectedScenario !== null;
    }
  },
  watch: {
    detail(value) {
      this.opened = value;
    }
  },
  mounted() {
    window.addEventListener("keydown", this.keyDownHandler);
    // this.$refs.drawer.$el.addEventListener("mouseenter", this.drawerMouseEnter);
    // this.$refs.drawer.$el.addEventListener("mouseleave", this.drawerMouseLeave);
    // this.$refs.drawer.$el.addEventListener("mousemove", this.drawerMouseMove);
    // if (this.$refs.drawer) {
    //   const elems = this.$refs.drawer.$el.getElementsByClassName(
    //     "v-navigation-drawer__border"
    //   );
    //   if (elems.length === 1) {
    //     this.setupBorder(elems[0]);
    //   }
    // }
  },
  beforeUnmount() {
    // this.$refs.drawer.$el.removeEventListener(
    //   "mouseenter",
    //   this.drawerMouseEnter
    // );
    // this.$refs.drawer.$el.removeEventListener(
    //   "mouseleave",
    //   this.drawerMouseLeave
    // );
    // this.$refs.drawer.$el.removeEventListener(
    //   "mousemove",
    //   this.drawerMouseMove
    // );
  },
  unmounted() {
    window.removeEventListener("keydown", this.keyDownHandler);
    // this.hammer.off("panstart");
    // this.hammer.off("panmove");
    // this.hammer.off("panend");
  },
  methods: {
    keyDownHandler(event) {
      if (event.code === "Escape" && this.drawer && this.preventEscape) {
        this.close();
      }
    },
    onClickOutside() {
      if (this.drawer) {
        this.close();
      }
    },
    setupBorder(elem) {
      this.drawerBorderEl = elem;
      this.hammer = new Hammer(this.drawerBorderEl, {});
      this.hammer.get("pan").set({ threshold: 0, direction: Hammer.DIRECTION_ALL });
      this.hammer.on("panstart", this.panStart.bind(this));
      this.hammer.on("panmove", this.panMove.bind(this));
      this.hammer.on("panend", this.panEnd.bind(this));
    },
    drawerMouseEnter() {
      this.isMouseOver = true;
    },
    drawerMouseLeave() {
      this.isMouseOver = false;
    },
    drawerMouseMove(e) {
      const rect = this.$refs.drawer.$el.getBoundingClientRect();
      const x = e.clientX - rect.left;
      this.isBorderHoover = x <= 6;
    },
    panStart() {
      this.drawerInitialWidth = this.$refs.drawer.$el.clientWidth;
      this.dragging = true;
    },
    panEnd() {
      this.drawerInitialWidth = this.$refs.drawer.$el.clientWidth;
      this.dragging = false;
    },
    panMove(e) {
      const delta = -e.deltaX;
      const overallWidth = this.$el.clientWidth;

      const result = this.drawerInitialWidth + delta;

      const drawerPercentWidth = ((isNaN(this.drawerWidth) || this.drawerWidth === null ? this.drawerInitialWidth : result) * 100) / overallWidth;

      if (Math.round(drawerPercentWidth) >= 25 && result <= overallWidth) {
        this.drawerWidth = result;
        this.$emit("resize");
      }
    },
    onScenarioSelectionChanged(selectedScenario) {
      this.$emit("scenarioSelectionChanged", selectedScenario);
    },
    close() {
      const e = this.sendBeforeCloseEvent();
      if (!e.preventClose) {
        this.drawer = false;
      }
    },
    sendBeforeCloseEvent() {
      const e = { preventClose: false };
      this.$emit("beforeClose", e);
      return e;
    }
  }
};
</script>

<style lang="scss">
.master-detail-layout {
  height: 100%;

  .scenario-selector {
    position: absolute;
    z-index: 1;
    top: 99px;
    right: 12px;
    min-width: 20vw;
    text-align: right;
  }

  .detail-drawer {
    will-change: initial;

    .v-navigation-drawer__border {
      width: 0;
    }
  }

  .detail-drawer.hoovered {
    .v-navigation-drawer__border {
      width: 5px;
      cursor: col-resize;
    }
  }
}
</style>
