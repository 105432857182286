import { IResourceDto } from "@masta/generated-model";
import { CellClassParams } from "ag-grid-community";

export function groupTypeCellStyle(params: CellClassParams<IResourceDto>, isGroup: (data: any) => boolean) {
    const { data, node } = params;

    if (data && isGroup(data)) {
        return {
            "font-weight": "bold",
            "color": "#7A63FF"
        };
    }

    return null;
}