<script setup lang="ts">
import { $t } from "@/i18n";
import { OrderLineDto } from "@masta/generated-model";
import { ref, watch, inject, computed } from "vue";
import ApiService from "@/services/api";
import { useSnackbarsStore } from "@/store/SnackbarsStore";
import { $dateTimeFormatterSymbol, DateFormatter } from "@masta/shared";
import DatepickerField from "@/components/Datepicker/DatepickerField.vue";
import { useI18n } from "vue-i18n";

const modelValue = defineModel<boolean>({ required: true });
const props = defineProps<{
  originalLine: OrderLineDto;
  nextPosition: number;
}>();

const { locale } = useI18n();
const snackbarsStore = useSnackbarsStore();
const position = ref<number>(props.nextPosition);
const quantity = ref<number>(props.originalLine.quantity);
const deliveryDate = ref<Date | null>(
  props.originalLine.deliveryDate ? new Date(props.originalLine.deliveryDate) : null
);

const $dateTimeFormatter = inject<DateFormatter>($dateTimeFormatterSymbol)!;

function formatDate(date: Date) {
  return $dateTimeFormatter(date);
}

const originalDeliveryDateFormatted = computed(() => {
  if (props.originalLine.deliveryDate) {
    const date = new Date(props.originalLine.deliveryDate);
    return formatDate(date);
  }
  return "";
});

async function onCreate() {
  try {
    const request = {
      id: props.originalLine.id,
      orderId: props.originalLine.orderId,
      scenarioId: props.originalLine.scenarioId,
      position: position.value,
      quantity: quantity.value,
      deliveryDate: deliveryDate.value ? deliveryDate.value.toISOString() : null
    };
    await ApiService.customerOrders.correctCustomerOrderLine(request);
    snackbarsStore.createSnackbar({
      message: $t("dialogs-orderLineCorrectionDialog-onCorrectionSuccess-message", { $: "Order line has been sent to correction" }),
      type: "success",
      closeable: true
    });
  } catch (e) {
    console.error(e);
    snackbarsStore.createSnackbar({
      message: $t("dialogs-orderLineCorrectionDialog-onCorrectionError-message", { $: "Could not correct order line" }),
      type: "error",
      closeable: true
    });
  }
  close();
}

function onCancel() {
  close();
}

function close() {
  modelValue.value = false;
}

watch(
  () => props.nextPosition,
  (value) => {
    position.value = value;
  }
);

watch(
  () => props.originalLine,
  (value) => {
    quantity.value = value.quantity;
    deliveryDate.value = value.deliveryDate ? new Date(value.deliveryDate) : null;
  }
);
</script>

<template>
  <v-dialog v-model="modelValue" max-width="50vw">
    <v-card class="pa-8">
      <v-card-title class="d-flex align-center justify-space-between">
        <div class="text-h4">
          {{ $t("dialogs-orderLineCorrectionDialog-product-label", { $: "Order line product {orderLine}", orderLine: originalLine.itemBusinessId }) }}
        </div>
      </v-card-title>
      <v-card-text>
        <v-row class="mb-6 original-values-row rounded">
          <v-col cols="12" class="d-flex align-center justify-center">
            <div class="text-subtitle-1 font-weight-bold">
              {{ $t("dialogs-orderLineCorrectionDialog-originalValues-label", { $: "Original Values" }) }}
            </div>
          </v-col>
          <v-col cols="4">
            <v-text-field
              :model-value="props.originalLine.position"
              readonly
              variant="outlined"
              density="compact"
              hide-details
              :label="$t('dialogs-orderLineCorrectionDialog-position-label', { $: 'Position' })"
            />
          </v-col>
          <v-col cols="4">
            <v-text-field
              :model-value="props.originalLine.quantity"
              readonly
              variant="outlined"
              density="compact"
              hide-details
              :label="$t('dialogs-orderLineCorrectionDialog-quantity-label', { $: 'Quantity' })"
            />
          </v-col>
          <v-col cols="4">
            <v-text-field
              :model-value="originalDeliveryDateFormatted"
              readonly
              variant="outlined"
              density="compact"
              hide-details
              :label="$t('dialogs-orderLineCorrectionDialog-deliveryDate-label', { $: 'Delivery Date' })"
            />
          </v-col>
        </v-row>
        <v-row class="corrected-values-row rounded">
          <v-col cols="12" class="d-flex align-center justify-center">
            <div class="text-subtitle-1 font-weight-bold">
              {{ $t("dialogs-orderLineCorrectionDialog-correctedValues-label", { $: "Corrected Values" }) }}
            </div>
          </v-col>
          <v-col cols="4">
            <v-text-field
              v-model="position"
              variant="outlined"
              density="compact"
              hide-details
              :label="$t('dialogs-orderLineCorrectionDialog-position-label', { $: 'Position' })"
            />
          </v-col>
          <v-col cols="4">
            <v-text-field
              v-model="quantity"
              variant="outlined"
              density="compact"
              hide-details
              :label="$t('dialogs-orderLineCorrectionDialog-quantity-label', { $: 'Quantity' })"
            />
          </v-col>
          <v-col cols="4">
            <datepicker-field
              v-model="deliveryDate"
              :clearable="true"
              :format="formatDate"
              :locale="locale"
              :enable-time-picker="true"
              :label="$t('dialogs-orderLineCorrectionDialog-deliveryDate-label', { $: 'Delivery Date' })"
              teleport="body"
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-row>
          <v-col cols="6">
            <v-btn variant="elevated" color="secondary" block @click="onCancel">
              {{ $t("dialogs-orderLineCorrectionDialog-cancel-action", { $: "Cancel" }) }}
            </v-btn>
          </v-col>
          <v-col cols="6">
            <v-btn variant="elevated" color="primary" block @click="onCreate">
              {{ $t("dialogs-orderLineCorrectionDialog-create-action", { $: "Create" }) }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style lang="scss" scoped>
.original-values-row {
  background-color: #FFF9C4;
}

.corrected-values-row {
  background-color: #C8E6C9;
}
</style>
