import { inject, injectable } from "inversify";
import { GanttEvents, GanttSettings, IocSymbols, Layer, OverlappingTimeIntervalTreeActivityRepository, PaddingInsets, Row, SettingKey, TimelineManager } from "@masta/gantt2/core";
import { LayerCanvas, RowContainer } from "@masta/gantt2/gantt";
import { ResourceCapacityActivity, ResourceRow, WaitingSchedulingResourceCapacityActivity } from "@/components/Gantt/Common/Model";
import { ResourceLineManger } from "@/components/Gantt/ResourcesGantt/ResourceLineManger";
import { BehaviorSubject, filter } from "rxjs";
import { GanttLayerSettings } from "@masta/generated-model";
import { CustomSettingKeys } from "@/components/Gantt/ResourcesGantt/CustomSettingKeys";

@injectable()
export class OverlappingActivityLayer extends LayerCanvas {
  private _repository: OverlappingTimeIntervalTreeActivityRepository<ResourceCapacityActivity>;
  private _rowPadding: PaddingInsets;
  private _newRowHeight: number;
  private _prevRowHeight: number;

  constructor(
    @inject(Row<any, any, any>) private _row: ResourceRow,
    @inject(TimelineManager) timelineManager: TimelineManager,
    @inject(GanttEvents) ganttEvents: GanttEvents,
    @inject(IocSymbols.LayersSymbol) private _layers$$: BehaviorSubject<Layer[]>,
    @inject(IocSymbols.RowContainer) private _rowContainer: RowContainer<any>,
    @inject(GanttSettings) private _settings: GanttSettings
  ) {
    super(timelineManager, ganttEvents, OverlappingActivityLayer.name, "overlapping-activity-layer");

    this._rowPadding = this._settings.getSetting<PaddingInsets>(SettingKey.ROW_PADDING)!;
  }

  async beforeInitialize(): Promise<void> {
    await super.beforeInitialize();
    this._repository = (this._row.repository as OverlappingTimeIntervalTreeActivityRepository<ResourceCapacityActivity>);
    this.subscribe(this._row.height$.pipe(filter((h) => h !== this._newRowHeight && h !== this._prevRowHeight)).subscribe((h) => {
      this._newRowHeight = h;
      const ph = Math.round(h / this._repository.getMaximalOverlapLevel());
      this._prevRowHeight = ph < this._row.minHeight ? this._row.minHeight : ph;
    }));
    this.subscribe(this._row.overlapExpanded$.pipe(filter(() => !!this._row.linesManager)).subscribe(() => {
      const lm = (this._row.linesManager as ResourceLineManger);
      lm.isOverlapExtended = !lm.isOverlapExtended;
      if (lm.isOverlapExtended) {
        this._prevRowHeight = this._row.height;
        this._newRowHeight = this._row.height * this._repository.getMaximalOverlapLevel();
        this._row.height = this._newRowHeight;
      } else {
        this._row.height = this._prevRowHeight;
      }
      this._repository.recomputeOverlaps();
      lm.clearCache();
      lm.layout();

      this.clear();

      this._rowContainer.batchDraw(true);
    }));
    this.subscribe(this._settings.getSetting$<GanttLayerSettings[]>(CustomSettingKeys.LAYERS, []).subscribe((layerSettings) => {
      const settings = layerSettings?.find(x => x.id === OverlappingActivityLayer.name);
      if (settings) {
        this.opacity = settings.opacity;
        this.visible = settings.visible;
      }
      this.clear();
      this.batchDraw();
    }));
  }

  async afterInitialize(): Promise<void> {
    await super.afterInitialize();
  }

  async scale(): Promise<void> {
    await super.scale();
  }

  async doDrawFromBatch(): Promise<void> {
    if (!this._row.visible) return;
    if (!this.enabled) return;
    if (!this._row.overlapExpanded) {
      this.clear();
      const layers = this._layers$$.value;

      const maxOverlapLevel = this._repository.getMaximalOverlapLevel();
      const spacing = 2;
      const hasSpace = this._rowPadding.top > maxOverlapLevel * spacing;

      for (let i = 0; i < layers.length; i++) {

        const layer = layers[i];
        const activities = this._repository.getActivities(layer, this._timelineManager.startTime, this._timelineManager.endTime, this._timelineManager.primaryTemporalUnit, this._row.zoneId);
        for (const activity of activities) {
          if (!activity.overlap.issOverlapped() || activity.constructor.name === WaitingSchedulingResourceCapacityActivity.name) continue;

          const intervals = activity.overlap.getOverlappedIntervals().filter(x => x.level.level > 1);

          this.context.strokeStyle = "#212121";
          this.context.lineWidth = hasSpace ? 1 : 2;
          for (let j = 0; j < intervals.length; j++) {
            const interval = intervals[j];

            const startX = this._timelineManager.calculateLocationForTime(interval.start);

            const endX = this._timelineManager.calculateLocationForTime(interval.end);

            const y = (this._rowPadding.top > 4 ? this._rowPadding.top - 4 : this._rowPadding.top);

            this.context.beginPath();
            this.context.moveTo(startX, y);
            this.context.lineTo(endX, y);
            this.context.stroke();
          }
        }
      }
    }
  }
}
