import { ServerSideDataSource } from "@/components/Grid/ServerSideDataSource";
import {
  IAgGridPaginationResponse,
  CreateResourceAvailabilityRuleCommand,
  UpdateResourceAvailabilityRuleCommand,
  ResourceAvailabilityRuleDto,
  GetResourceAvailabilityRulesPaginatedQuery
} from "@masta/generated-model";
import { Store } from "pinia";
import { State as ScenarioState, useScenariosStore } from "@/store/ScenariosStore";
import { IServerSideGetRowsParams } from "ag-grid-enterprise";
import ApiService from "@/services/api";
import { GUID_EMPTY } from "@/components/ValueCellEditor/CommonFormatters";

export class AvailabilityServerSideDataSource extends ServerSideDataSource<
  ResourceAvailabilityRuleDto,
  ResourceAvailabilityRuleDto,
  CreateResourceAvailabilityRuleCommand,
  UpdateResourceAvailabilityRuleCommand,
  string
> {
  private _scenarioStore: Store<string, ScenarioState>;
  private _filterByResourceId: string | null | undefined;

  constructor(id: string) {
    super(id);
    this._scenarioStore = useScenariosStore();
  }

  public useFilteringByResourceId(resourceId: string | null | undefined) {
    this._filterByResourceId = resourceId;
  }

  async getAll(params: IServerSideGetRowsParams): Promise<IAgGridPaginationResponse<ResourceAvailabilityRuleDto>> {
    const scenarioId = this._scenarioStore.selectedScenario?.id;
    if (!scenarioId) return { results: [], count: 0 } as IAgGridPaginationResponse<ResourceAvailabilityRuleDto>;

    const request = {
      ...this.constructServerRequest(params),
      resourceId: this._filterByResourceId,
      scenarioId: scenarioId
    } as GetResourceAvailabilityRulesPaginatedQuery;
    const response = await ApiService.resourceAvailabilityRules.getPaginated(request);
    return response.data as IAgGridPaginationResponse<ResourceAvailabilityRuleDto>;
  }

  async getSingle(id: string): Promise<ResourceAvailabilityRuleDto | undefined> {
    const scenarioId = this._scenarioStore.selectedScenario?.id;
    if (!scenarioId || !this._filterByResourceId) return;
    const response = await ApiService.resourceAvailabilityRules.get(this._filterByResourceId, id, scenarioId);
    return response.data;
  }

  async create(entity: ResourceAvailabilityRuleDto): Promise<string> {
    const request = this.transformForCreate(entity);
    const scenario = this._scenarioStore.selectedScenario;
    request.scenarioId = scenario?.id ?? "";
    const response = await ApiService.resourceAvailabilityRules.create(request);
    return response.data;
  }

  async update(entity: ResourceAvailabilityRuleDto): Promise<string> {
    const request = this.transformForUpdate(entity);
    await ApiService.resourceAvailabilityRules.update(request);
    return entity.id;
  }

  async remove(entity: ResourceAvailabilityRuleDto): Promise<void> {
    await ApiService.resourceAvailabilityRules.remove(entity.resourceId, entity.id, entity.scenarioId);
    return;
  }

  protected transformForCreate(entity: ResourceAvailabilityRuleDto): CreateResourceAvailabilityRuleCommand {
    return {
      scenarioId: this._scenarioStore.selectedScenario?.id ?? GUID_EMPTY,
      resourceId: this._filterByResourceId,
      availabilityRuleId: entity.availabilityRuleId,
      validFrom: entity.validFrom,
      validTo: entity.validTo
    } as CreateResourceAvailabilityRuleCommand;
  }

  protected transformForUpdate(entity: ResourceAvailabilityRuleDto): UpdateResourceAvailabilityRuleCommand {
    const commandPayload = this.transformForCreate(entity);
    return commandPayload;
  }
}
