<script setup lang="ts">
import { computed } from "vue";
import { StepResourceUsageType } from "@masta/generated-model";

const model = defineModel<StepResourceUsageType>({ required: true });

const resourceIcon = computed(() => {
  switch (+model.value) {
    case StepResourceUsageType.Scrap:
      return "mdi-delete-variant";
    case StepResourceUsageType.Produce:
      return "mdi-export";
    case StepResourceUsageType.Consume:
      return "mdi-import";
    case StepResourceUsageType.ByProduct:
      return "mdi-source-branch-plus";
    default:
      return "mdi-calendar-multiselect";
  }
});

const color = computed(() => {
  switch (+model.value) {
    case StepResourceUsageType.Produce:
      return "green";
    default:
      return "black";
  }
});
</script>

<template>
  <v-icon :icon="resourceIcon" size="x-large" :color="color" />
</template>

<style scoped lang="scss"></style>
