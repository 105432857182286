import { computed } from "vue";
import { App } from "vue";
import { useSystemStore } from "@/store/SystemStore";

let installed = false;
export const $appContextSymbol = Symbol("$appContext");
export const AppPlugin = {
  installed: false,
  install(app: App) {
    if (installed) return;
    installed = true;
    app.config.globalProperties.$appContext = computed(() => {
      const store = useSystemStore();
      return store.context;
    });
    app.provide($appContextSymbol, app.config.globalProperties.$appContext);
  }
};
