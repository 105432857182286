import { $authService } from "@/services/AuthService";
import { ResourceInfoDto } from "@masta/generated-model";
import { useStorage } from "@vueuse/core";

export const useSearchResourceStore = () => {
    
    const selectedResource = useStorage<ResourceInfoDto | null | undefined>(`selected-resource-${$authService.tenantId}`, null, undefined, {
        listenToStorageChanges: true,
        serializer: {
            read: (v) => (v ? JSON.parse(v) : null),
            write: (v) => JSON.stringify(v)
        }
    });

    const recentResources = useStorage<ResourceInfoDto[]>(`recent-resources-${$authService.tenantId}`, null, undefined, {
        listenToStorageChanges: true,
        serializer: {
            read: (v) => (v ? JSON.parse(v) : null),
            write: (v) => JSON.stringify(v)
        }
    });

    /**
     * Add selected resource to recent resources list. Reorder the list to keep the most 10 recent resources at the top.
     */
    function addRecentResource(resource: ResourceInfoDto | null | undefined) {
        if (resource == null) {
            return;
        }
        
        const recentResourcesValue = recentResources.value ?? [];
        const index = recentResourcesValue.findIndex((r) => r.id === resource.id);
        if (index !== -1) {
            recentResourcesValue.splice(index, 1);
        }
        recentResourcesValue.unshift(resource);
        recentResources.value = recentResourcesValue.slice(0, 10);
    }

    return {
        selectedResource,
        recentResources,
        addRecentResource
    };
};
