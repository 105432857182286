import ApiService from "@/services/api";
import { AppContextDto, ModelInstanceTabInfo, ModelInstanceTabsSetting } from "@masta/generated-model";
import { defineStore } from "pinia";
import { useErrorsStore } from "@/store/ErrorsStore";

interface State {
  context: AppContextDto | null;
}

export const useSystemStore = defineStore("system", {
  state: (): State => ({
    context: null
  }),
  getters: {
    defaultSchedulingSettingName: (state): string => state.context?.defaultSchedulingSettingName ?? "",
    defaultSchedulingDragAndDropSettingName: (state): string => state.context?.defaultSchedulingDragAndDropSettingName ?? "",
    schedulingSettingNames: (state): string[] => state.context?.schedulingSettingNames ?? [],
    modelInstanceTabs: (state): ModelInstanceTabsSetting | null | undefined => state.context?.modelInstanceTabs
  },
  actions: {
    async fetchContext() {
      try {
        const { data } = await ApiService.system.getAppContext();
        this.context = data;
      } catch (e) {
        const errorsStore = useErrorsStore();
        errorsStore.handleError(e);
        throw e;
      }
    }
  }
});
