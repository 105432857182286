import { VueKeycloakConfig, VueKeycloakOptions } from "./keycloak.types";

// based on: https://github.com/dsb-norge/vue-keycloak-js/blob/main/src/index.ts
export function assertOptions(options: VueKeycloakOptions) {
  const { config, init, onReady, onInitError, onAuthRefreshError } = options;
  if (typeof config !== "string" && !isObject(config)) {
    return {
      hasError: true,
      error: `'config' option must be a string or an object. Found: '${config}'`
    };
  }
  if (!isObject(init) || typeof init!.onLoad !== "string") {
    return {
      hasError: true,
      error: `'init' option must be an object with an 'onLoad' property. Found: '${init}'`
    };
  }
  if (onReady && typeof onReady !== "function") {
    return {
      hasError: true,
      error: `'onReady' option must be a function. Found: '${onReady}'`
    };
  }
  if (onInitError && typeof onInitError !== "function") {
    return {
      hasError: true,
      error: `'onInitError' option must be a function. Found: '${onInitError}'`
    };
  }
  if (onAuthRefreshError && typeof onAuthRefreshError !== "function") {
    return {
      hasError: true,
      error: `'onAuthRefreshError' option must be a function. Found: '${onAuthRefreshError}'`
    };
  }
  return {
    hasError: false,
    error: null
  };
}

// based on: https://github.com/dsb-norge/vue-keycloak-js/blob/main/src/index.ts
export function isObject(obj: unknown) {
  return obj !== null && typeof obj === "object" && Object.prototype.toString.call(obj) !== "[object Array]";
}

// based on: https://github.com/dsb-norge/vue-keycloak-js/blob/main/src/index.ts
export function getConfig(config: VueKeycloakConfig): Promise<VueKeycloakConfig> {
  if (isObject(config)) return Promise.resolve(config);
  throw new Error(`Invalid configuration given: ${JSON.stringify(config)}`);
  // return new Promise((resolve, reject) => {
  //   const xhr = new XMLHttpRequest();
  //   xhr.open("GET", config as string);
  //   xhr.setRequestHeader("Accept", "application/json");
  //   xhr.onreadystatechange = () => {
  //     if (xhr.readyState === 4) {
  //       if (xhr.status === 200) {
  //         const result = JSON.parse(xhr.responseText) as VueKeycloakConfig;
  //         resolve(result);
  //       } else {
  //         reject(Error(xhr.statusText));
  //       }
  //     }
  //   };
  //   xhr.send();
  // });
}
