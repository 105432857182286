import { Instant } from "@js-joda/core";
import { GanttException } from "../../Exceptions";

export class TimeInterval {
  constructor(public readonly startTime: Instant, public readonly endTime: Instant) {
    if (endTime.isBefore(startTime)) {
      throw new GanttException("start must be before end");
    }
  }

  toString() {
    return `TimeInterval [startTime=${this.startTime}, endTime=${this.endTime}]`;
  }
}
