import { ServerSideDataSource } from "@/components/Grid/ServerSideDataSource";
import ApiService from "@/services/api";
import { State as ScenarioState, useScenariosStore } from "@/store/ScenariosStore";
import {
  CreateScenarioIfDoesNotExistCommand,
  GetScenariosPaginatedQuery,
  IAgGridPaginationResponse,
  ScenarioDto,
  ScenarioStatus,
  UpdateScenarioCommand
} from "@masta/generated-model";
import { IServerSideGetRowsParams } from "ag-grid-community";
import { Store } from "pinia";

export class ScenarioServerSideDataSource extends ServerSideDataSource<ScenarioDto, ScenarioDto, CreateScenarioIfDoesNotExistCommand, UpdateScenarioCommand, string> {
  private _scenarioStore: Store<string, ScenarioState>;

  constructor(id: string) {
    super(id);
    this._scenarioStore = useScenariosStore();
  }

  async create(entity: ScenarioDto): Promise<string> {
    return "";
  }

  async getAll(params: IServerSideGetRowsParams): Promise<IAgGridPaginationResponse<ScenarioDto>> {
    const scenario = this.currentScenario();
    if (!scenario) {
      return { count: 0, results: [] } as IAgGridPaginationResponse<ScenarioDto>;
    }

    const request = {
      ...this.constructServerRequest(params),
      scenarioId: scenario.id
    } as GetScenariosPaginatedQuery;

    const response = await ApiService.scenarios.getAllPaginated(request);
    return response.data as IAgGridPaginationResponse<ScenarioDto>;
  }

  async getSingle(id: string): Promise<ScenarioDto | undefined> {
    const response = await ApiService.scenarios.getScenario(id);
    return response.data;
  }

  async update(entity: ScenarioDto): Promise<string> {
    await ApiService.scenarios.updateScenario(this.transformForUpdate(entity));
    return entity.id;
  }

  async remove(entity: ScenarioDto): Promise<void> {
    if (!entity || !entity.id || entity.status === ScenarioStatus.Production || entity.id === this.currentScenario()?.id) {
      return;
    }
    await ApiService.scenarios.deleteScenario(entity.id);
  }

  protected transformForCreate(listEntity: ScenarioDto): CreateScenarioIfDoesNotExistCommand {
    return {
      name: listEntity.name,
      businessId: listEntity.businessId
    } as CreateScenarioIfDoesNotExistCommand;
  }

  protected transformForUpdate(listEntity: ScenarioDto): UpdateScenarioCommand {
    return {
      id: listEntity.id,
      name: listEntity.name,
      businessId: listEntity.businessId
    } as UpdateScenarioCommand;
  }

  private currentScenario() {
    return this._scenarioStore.selectedScenario;
  }
}
