import { BaseRowInfoColumnProvider, GanttSettings, IocSymbols, IRowInfoColumn, IRowInfoColumnHeader, ObservedObject, observeObject, SettingKey } from "../Core";
import { inject, injectable } from "inversify";

@injectable()
export class RowInfoColumnProvider extends BaseRowInfoColumnProvider {
  protected _columns: ObservedObject<IRowInfoColumn>[] = [
    observeObject({ id: "text", className: "text", width: 130, visible: true }),
    observeObject({
      id: "scale",
      className: "scale",
      width: 50,
      visible: true
    })
  ];
  protected _headers: IRowInfoColumnHeader[] = [
    { id: "text", className: "text", text: "Name" } as IRowInfoColumnHeader,
    { id: "scale", className: "scale", text: "Scale" } as IRowInfoColumnHeader
  ];

  constructor(@inject(IocSymbols.HtmlContainerSymbol) container: HTMLDivElement, @inject(GanttSettings) private _ganttSettings: GanttSettings) {
    super(container);
  }

  async beforeInitialize(): Promise<void> {
    await super.beforeInitialize();
  }
}
