import { RouteLocationNormalized, RouteLocationRaw } from "vue-router";
import { checkRoles } from "@/router/guards/util";
import { useScenariosStore } from "@/store/ScenariosStore";
import { RouteNames } from "@/router/route-names";

export const scenarioGuard: (to: RouteLocationNormalized
) => Promise<Error | RouteLocationRaw | boolean> = async (to: RouteLocationNormalized) => {
  const proceed = () => {
    if (!checkRoles(to)) {
      return false; // abort
    }

    // If the user has selected scenario, continue with the route
    const store = useScenariosStore();
    const scenarioIdParam = to.params?.scenarioId && !Array.isArray(to.params.scenarioId) ? decodeURIComponent(to.params.scenarioId) : undefined;

    const notFoundRoute: RouteLocationRaw = {
      name: RouteNames.NotFound,
      params: {
        pathMatch: "404"
      }
    };

    if (scenarioIdParam && store.isScenarioSelected && store.selectedScenario) {
      if (store.checkIfScenarioExists(scenarioIdParam)) {
        if (store.isSelectedScenarioMatching(scenarioIdParam)) {
          return true;
        } else {
          const scenario = store.getScenarioByIdOrBusinessId(scenarioIdParam);
          if (scenario) {
            store.selectScenario(scenario);
            if (store.isSelectedScenarioMatching(scenarioIdParam)) {
              if (store.scenarioBusinessId) {
                to.params.scenarioId = store.scenarioBusinessId;
              }
              return true;
            }
          }
        }
      }
    }
    // Otherwise abort
    return notFoundRoute;
  };

  const store = useScenariosStore();
  // If scenario selection has already finished, check our state using `proceed()`
  if (store.selectedScenario !== null) {
    return proceed();
  }

  return await new Promise((resolve) => {
    const stopHandle = store.$subscribe(
      (mutation, state) => {
        if (state.selectedScenario) {
          stopHandle();
          return resolve(proceed());
        }
      },
      { detached: true }
    );
  });
};
