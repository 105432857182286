<script lang="ts" setup>
import { TextPropertyModel } from "@masta/json-schema";
import { $t } from "@/i18n";

const props = defineProps<{
  modelValue: TextPropertyModel;
  disabled: boolean;
}>();

defineEmits(["update:modelValue"]);

function generateRules() {
  const result = [];

  if (props.modelValue.maxLength) {
    const maxLength = props.modelValue.maxLength;
    result.push((v: string) => v.length <= maxLength || $t("modelInstance-textProperty-maxMaxLengthCharacters-label", { $: "Max {maxLength} characters", maxLength }));
  }
  if (props.modelValue.minLength) {
    const minLength = props.modelValue.minLength;
    result.push((v: string) => v.length >= minLength || $t("modelInstance-textProperty-minMinLengthCharacters-label", { $: "Min {minLength} characters", minLength }));
  }
  if (props.modelValue.pattern) {
    const regEx = new RegExp(props.modelValue.pattern);
    result.push((v: string) => regEx.test(v) || $t("modelInstance-textProperty-notValidPattern-label", { $: "Not valid pattern" }));
  }

  return result;
}
</script>

<template>
  <v-col cols="6" >
    <div class="d-flex pa-5 rounded-lg" :style="{ backgroundColor: modelValue.color }">
      <!-- eslint-disable vue/no-mutating-props -->
      <v-text-field
        v-model="modelValue.value"
        variant="outlined"
        density="compact"
        hide-details
        :label="modelValue.name"
        :maxlength="modelValue.maxLength"
        :rules="generateRules()"
        :disabled="disabled"
        :prepend-icon="modelValue.icon"
      />
    </div>
  </v-col>
</template>