import { inject, injectable } from "inversify";
import { GanttDomElement } from "../GanttDomElement";
import { EventlineContainer } from "./EventlineContainer";
import { TimelineBlock } from "./TimelineBlock";

@injectable()
export class TimelineContainer extends GanttDomElement<HTMLDivElement> {
  constructor(@inject(TimelineBlock) private _timelineBlock: TimelineBlock, @inject(EventlineContainer) private _eventlineContainer: EventlineContainer) {
    super(TimelineContainer.name, undefined, "timeline-container");
  }

  async beforeInitialize(): Promise<void> {
    await this.addChildren([this._timelineBlock, this._eventlineContainer]);
  }
}
