import ApiService from "@/services/api";
import { ServerSideDataSource } from "@/components/Grid/ServerSideDataSource";
import { IAgGridPaginationResponse, ResourceType, ResourceWithAssignmentDto } from "@masta/generated-model";
import { IServerSideGetRowsParams } from "ag-grid-enterprise";
import { Store } from "pinia";
import { State as ScenarioState, useScenariosStore } from "@/store/ScenariosStore";

export interface ResourceAssignmentServerSideDataSourceOptions {
  resourceType: ResourceType;
  owningResourceId: string;
  reverse: boolean;
}

export class ResourceAssignmentServerSideDataSource extends ServerSideDataSource<ResourceWithAssignmentDto> {
  private _options: ResourceAssignmentServerSideDataSourceOptions;
  private _scenarioStore: Store<string, ScenarioState>;

  constructor(id: string, options: ResourceAssignmentServerSideDataSourceOptions) {
    super(id);
    this._options = options;
    this._scenarioStore = useScenariosStore();
  }

  async create(entity: ResourceWithAssignmentDto): Promise<string | undefined> {
    throw new Error("not supported");
  }

  async getAll(params: IServerSideGetRowsParams): Promise<IAgGridPaginationResponse<ResourceWithAssignmentDto>> {
    const cql = `Type = ${this._options.resourceType}`;
    const { data } = await ApiService.resourceAssignment.getPaginated({
      ...this.constructServerRequest(params, cql),
      owningResourceId: this._options.owningResourceId,
      reverse: this._options.reverse,
      scenarioId: this._scenarioStore.selectedScenario?.id ?? ""
    });
    return data;
  }

  async getSingle(id: string | undefined): Promise<ResourceWithAssignmentDto | undefined> {
    throw new Error("not supported");
  }

  async remove(entity: ResourceWithAssignmentDto): Promise<void> {
    throw new Error("not supported");
  }

  async update(entity: ResourceWithAssignmentDto): Promise<string | undefined> {
    throw new Error("not supported");
  }

  protected transformForCreate(listEntity: ResourceWithAssignmentDto): ResourceWithAssignmentDto {
    return listEntity;
  }

  protected transformForUpdate(listEntity: ResourceWithAssignmentDto): ResourceWithAssignmentDto {
    return listEntity;
  }
}
