import ApiService from "@/services/api";
import { ServerSideDataSource } from "@/components/Grid/ServerSideDataSource";
import {
  CommunicationItemDto,
  CommunicationStatus,
  CommunicationType,
  ContactDto,
  CreateCommunicationItemCommand,
  CreateContactCommand,
  GetCommunicationItemsQuery,
  GetContactsQuery,
  IAgGridPaginationResponse,
  UpdateCommunicationItemCommand,
  UpdateContactCommand
} from "@masta/generated-model";
import { IServerSideGetRowsParams } from "ag-grid-community";
import { Store } from "pinia";
import { State as ScenarioState, useScenariosStore } from "@/store/ScenariosStore";

export class CommunicationsServerSideDataSource extends ServerSideDataSource<
  CommunicationItemDto,
  CommunicationItemDto,
  CreateCommunicationItemCommand,
  UpdateCommunicationItemCommand,
  string
> {
  private _scenarioStore: Store<string, ScenarioState>;

  constructor(id: string) {
    super(id);
    this._scenarioStore = useScenariosStore();
  }

  async create(entity: CommunicationItemDto): Promise<string> {
    const request = this.transformForCreate(entity);
    const scenario = this._scenarioStore.selectedScenario;
    request.scenarioId = scenario?.id ?? "";
    const response = await ApiService.communications.create(request);
    return response.data.communicationItemId;
  }

  async getAll(params: IServerSideGetRowsParams): Promise<IAgGridPaginationResponse<CommunicationItemDto>> {
    const scenario = this.currentScenario();
    if (!scenario) {
      return { count: 0, results: [] } as IAgGridPaginationResponse<CommunicationItemDto>;
    }
    const response = await ApiService.communications.getAll({ scenarioId: scenario.id } as GetCommunicationItemsQuery);
    const communicationItems = response.data.communicationItems;
    return { count: communicationItems.length, results: communicationItems };
  }

  async getSingle(id: string): Promise<CommunicationItemDto | undefined> {
    const scenario = this.currentScenario();
    if (!scenario) {
      return undefined;
    }
    const response = await ApiService.communications.getAll({ scenarioId: scenario.id } as GetCommunicationItemsQuery);
    return response.data.communicationItems.find((dto: CommunicationItemDto) => dto.id === id);
  }

  async remove(listEntity: CommunicationItemDto): Promise<void> {
    await ApiService.communications.remove(listEntity.id);
  }

  protected transformForCreate(listEntity: CommunicationItemDto): CreateCommunicationItemCommand {
    return {
      scenarioId: listEntity.scenarioId,
      communicationType: listEntity.communicationType,
      content: listEntity.content,
      subject: listEntity.subject,
      from: listEntity.from,
      to: listEntity.to,
      cc: listEntity.cc,
      businessId: listEntity.businessId,
      plannedSendTime: listEntity.plannedSendTime,
      sentTime: listEntity.sentTime,
      deliveredTime: listEntity.deliveredTime,
      readTime: listEntity.readTime,
      acknowledgedTime: listEntity.acknowledgedTime,
      status: listEntity.status,
      contactId: listEntity.contactId
    } as CreateCommunicationItemCommand;
  }

  protected transformForUpdate(listEntity: CommunicationItemDto): UpdateCommunicationItemCommand {
    return {
      id: listEntity.id,
      ...this.transformForCreate(listEntity)
    } as UpdateCommunicationItemCommand;
  }

  async update(entity: CommunicationItemDto): Promise<string> {
    await ApiService.communications.update(this.transformForUpdate(entity));
    return entity.id;
  }

  private currentScenario() {
    return this._scenarioStore.selectedScenario;
  }
}
