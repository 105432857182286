import ApiService from "@/services/api";
import { ServerSideDataSource } from "@/components/Grid/ServerSideDataSource";
import { CreateOrUpdatePurchaseOrderLineCommand, GetPurchaseOrderLinesPaginatedQuery, IAgGridPaginationResponse, PurchaseOrderLineDto } from "@masta/generated-model";
import { IServerSideGetRowsParams } from "ag-grid-community";
import { State as ScenarioState, useScenariosStore } from "@/store/ScenariosStore";
import { Store } from "pinia";
import { GUID_EMPTY } from "@/components/ValueCellEditor/CommonFormatters";

export class PurchaseOrderLinesServerSideDataSource extends ServerSideDataSource<
  PurchaseOrderLineDto,
  PurchaseOrderLineDto,
  CreateOrUpdatePurchaseOrderLineCommand,
  CreateOrUpdatePurchaseOrderLineCommand,
  string
> {
  private _scenarioStore: Store<string, ScenarioState>;
  private _filterByProductId: string | null | undefined;
  private _filterByOrderId: string | null | undefined;

  constructor(id: string) {
    super(id);
    this._scenarioStore = useScenariosStore();
  }

  public useFilteringByProductId(productId: string | null | undefined) {
    this._filterByProductId = productId;
  }

  public useFilteringByOrderId(productId: string | null | undefined) {
    this._filterByOrderId = productId;
  }

  public static transformPurchaseOrderLineDtoToCreateOrUpdatePurchaseOrderLineCommand(orderId: string, listEntity: PurchaseOrderLineDto): CreateOrUpdatePurchaseOrderLineCommand {
    return {
      id: listEntity.id,
      scenarioId: listEntity.scenarioId ?? GUID_EMPTY,
      orderId: orderId,
      itemBusinessId: listEntity.itemBusinessId,
      itemId: listEntity.itemId,
      description: listEntity.description,
      orderLineStatus: listEntity.status,
      position: listEntity.position,
      deliveryDate: listEntity.deliveryDate,
      quantity: listEntity.quantity,
      quantityUnit: listEntity.measurementUnit,
      modelInstances: [],
      modelInstanceBusinessIds: []
    };
  }

  async create(entity: PurchaseOrderLineDto): Promise<string> {
    const request = this.transformForCreate(entity);
    const scenario = this._scenarioStore.selectedScenario;
    request.scenarioId = scenario?.id ?? "";
    const response = await ApiService.purchaseOrders.createOrUpdatePurchaseOrderLine(request);
    return response.data;
  }

  async getAll<T>(params: IServerSideGetRowsParams): Promise<IAgGridPaginationResponse<PurchaseOrderLineDto>> {
    const scenarioId = this._scenarioStore.selectedScenario?.id;

    if (!scenarioId || !this.isOrderSelected()) {
      return { count: 0, results: [] } as IAgGridPaginationResponse<PurchaseOrderLineDto>;
    }

    const request = {
      ...this.constructServerRequest(params),
      orderId: this.getOrderId(),
      scenarioId,
      resourceId: this._filterByProductId
    } as GetPurchaseOrderLinesPaginatedQuery;
    const response = await ApiService.purchaseOrders.getPurchaseOrderLinesPaginated(request);

    return response.data as IAgGridPaginationResponse<PurchaseOrderLineDto>;
  }

  async getSingle(id: string): Promise<PurchaseOrderLineDto | undefined> {
    const scenarioId = this._scenarioStore.selectedScenario?.id;

    if (!scenarioId || !this.isOrderSelected()) return;

    if (!this.getOrderId()) return;

    const response = await ApiService.purchaseOrders.getPurchaseOrderLine(this.getOrderId(), id, scenarioId);
    return response.data;
  }

  async update(entity: PurchaseOrderLineDto): Promise<string> {
    const response = await ApiService.purchaseOrders.createOrUpdatePurchaseOrderLine(this.transformForUpdate(entity));
    return response.data;
  }

  protected transformForCreate(listEntity: PurchaseOrderLineDto): CreateOrUpdatePurchaseOrderLineCommand {
    return PurchaseOrderLinesServerSideDataSource.transformPurchaseOrderLineDtoToCreateOrUpdatePurchaseOrderLineCommand(this.getOrderId(), listEntity);
  }

  protected transformForUpdate(listEntity: PurchaseOrderLineDto): CreateOrUpdatePurchaseOrderLineCommand {
    return PurchaseOrderLinesServerSideDataSource.transformPurchaseOrderLineDtoToCreateOrUpdatePurchaseOrderLineCommand(this.getOrderId(), listEntity);
  }

  async remove(entity: PurchaseOrderLineDto): Promise<void> {
    if (entity.id) {
      await ApiService.purchaseOrders.removePurchaseOrderLine(this._filterByOrderId, entity.id, this._scenarioStore.selectedScenario?.id);
    }
  }

  private isOrderSelected() {
    return this._filterByOrderId;
  }

  private getOrderId(): string {
    return this._filterByOrderId;
  }
}
