import ApiService from "@/services/api";
import { useScenariosStore } from "@/store/ScenariosStore";
import { useErrorsStore } from "@/store/ErrorsStore";
import { defineStore } from "pinia";
import { GetPlanningViewQuery, PeriodDto, PlanningViewDto, ScenarioDto } from "@masta/generated-model";
import dayjs from "dayjs";

interface State {
  view: PlanningViewDto;
  updates: any[];
  request: GetPlanningViewQuery;
}

const dateFormat = "YYYY-MM-DD";
const start = dayjs().startOf("month").subtract(1, "month");
const end = start.add(6, "month");

export const defaultRequest = {
  supplyResourceId: "",
  scenarioId: "",
  start: start.format(dateFormat),
  end: end.format(dateFormat),
  aggregate: 1,
  timeZone: "Etc/UTC",
  workloadUnit: 0
};

export const emptyView = {
  scenarioId: defaultRequest.scenarioId,
  start: defaultRequest.start,
  end: defaultRequest.end,
  timeZone: defaultRequest.timeZone,
  workloadUnit: defaultRequest.workloadUnit,
  periods: [],
  data: []
};

export const usePlanningStore = defineStore("planning", {
  state: (): State => ({
    view: emptyView,
    updates: [],
    request: defaultRequest
  }),
  getters: {
    viewScenario(state) {
      const scenariosStore = useScenariosStore();
      return state.view ? scenariosStore.getScenarioByIdOrBusinessId(state.view.scenarioId) : undefined;
    },
    isViewScenarioEditable() {
      const scenario: ScenarioDto | undefined = this.viewScenario;
      return scenario && scenario.status !== 2 && scenario.status !== 0;
    },
    getViewPeriods(state) {
      return state.view?.periods;
    }
  },
  actions: {
    async fetchView(request: GetPlanningViewQuery) {
      try {
        this.cleanUpdates();
        const response = await ApiService.planning.getPlanningView(request);
        //console.log("planning view response", response.data);
        this.view = response.data;
        this.updates = [];
      } catch (e) {
        const errorsStore = useErrorsStore();
        errorsStore.handleError(e);
      }
    },
    updateRow(update: any) {
      this.updates = [...this.updates];
      const newData = this.view.data.map((x: any) => {
        if (x.id === update.resourceId) {
          const updatedRow = { ...x };
          updatedRow[update.periodId] = update.value;
          const i = this.updates.findIndex((v) => v.resourceId === update.resourceId && v.periodId === update.periodId);
          if (i >= 0) {
            this.updates[i] = update;
          } else {
            this.updates.push(update);
          }
          return updatedRow;
        }
        return x;
      });

      this.view = {
        ...this.view,
        data: newData
      };
    },
    saveView() {
      const request = {
        scenarioId: this.view.scenarioId,
        workloadUnit: this.view.workloadUnit,
        updates: this.updates.map((u) => {
          const period = this.view.periods[u.periodId];
          return {
            resourceId: u.resourceId,
            workload: u.value,
            periodStart: period.start,
            periodEnd: period.end
          };
        })
      };
      try {
        return ApiService.planning
          .savePlanningView(request)
          .then(() => this.cleanUpdates())
          .catch((error) => {
            console.error(error);
          });
      } catch (e) {
        const errorsStore = useErrorsStore();
        errorsStore.handleError(e);
        throw e;
      }
    },
    updateCapacityByAgreement(agreementId: string) {
      const request = {
        scenarioId: this.view.scenarioId,
        agreementId
      };
      try {
        return ApiService.planning
          .updateCapacityByAgreement(request)
          .then(() => this.cleanUpdates())
          .catch((error) => {
            console.error(error);
          });
      } catch (e) {
        const errorsStore = useErrorsStore();
        errorsStore.handleError(e);
        throw e;
      }
    },
    updateCapacityByRange(agreementIds: string[], periods: PeriodDto[]) {
      const request = {
        scenarioId: this.view.scenarioId,
        agreementIds,
        periods
      };
      try {
        return ApiService.planning
          .updateCapacityByRange(request)
          .then(() => this.cleanUpdates())
          .catch((error) => {
            console.error(error);
          });
      } catch (e) {
        const errorsStore = useErrorsStore();
        errorsStore.handleError(e);
        throw e;
      }
    },
    cleanUpdates() {
      this.updates = [];
    }
  }
});
