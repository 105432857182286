import { $authService } from "@/services/AuthService";
import { RouteLocationNormalized, RouteLocationRaw } from "vue-router";
import { checkRoles } from "@/router/guards/util";

export const tenantGuard: (to: RouteLocationNormalized) => Promise<void | Error | RouteLocationRaw | boolean> = async (to: RouteLocationNormalized) => {
  if (!(to && to.meta && !!to.meta.tenantId && (to.meta.tenantId as any).length > 0)) return false;

  const authenticated = $authService.authenticated;
  if (!authenticated)
    return false;
  if (!checkRoles(to)) {
    console.warn("Unauthorized");
    return false; // abort
  }
  return true;
};
