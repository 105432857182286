<template>
  <v-card>
    <v-card-title>
      <v-row>
        <v-col cols="4" md="1" align-self="center" class="text-center">
          <v-avatar>
            <img :src="user.picture" alt="User's profile picture"/>
          </v-avatar>
        </v-col>
        <v-col cols="8" md="11">
          <p class="text-muted mb-0">
            {{ user.name }}
          </p>
          <p class="text-muted mb-0">
            {{ user.email }}
          </p>
        </v-col>
      </v-row>
    </v-card-title>

    <v-card-text>
      <pre>{{ JSON.stringify(user, null, 2) }}</pre>
    </v-card-text>
  </v-card>
</template>

<script>
import {useAuthStore} from "@/store/AuthStore";
import {mapState} from "pinia";

export default {
  name: "Profile",
  title: "Profile",
  computed: {
    ...mapState(useAuthStore, ["user"])
  }
};
</script>

<style scoped></style>
