﻿import type { IRowContainer } from "@masta/gantt2/core";
import { ActivityRenderer, GanttSettings, IActivityRenderingRequest, IocSymbols, TimelineManager } from "@masta/gantt2/core";
import { inject, injectable } from "inversify";
import { TaskSchedulingActivity } from "src/components/Gantt/Common/Model";
import { StatusColorHelper } from "@/components/Gantt/ResourcesGantt/Renderers/StatusColorHelper";
import { CustomSettingKeys } from "@/components/Gantt/ResourcesGantt/CustomSettingKeys";
import { GanttActivityColorsSetting } from "@masta/generated-model";

@injectable()
export class TaskExecutionActivityRenderer extends ActivityRenderer<TaskSchedulingActivity> {
  private _startDateColor: string = "rgba(0,0,0,1)";
  private _endDateColor: string = "rgba(0,0,0,1)";

  constructor(
    @inject(TimelineManager) timelineManager: TimelineManager,
    @inject(IocSymbols.RowContainer) rowContainer: IRowContainer,
    @inject(StatusColorHelper) private _statusColorHelper: StatusColorHelper,
    @inject(GanttSettings) protected readonly _settings: GanttSettings
  ) {
    super(timelineManager, rowContainer, TaskExecutionActivityRenderer.name);
    this.paddingInsets.left = 4;
    this.paddingInsets.right = 4;
  }

  async afterInitialize(): Promise<void> {
    await super.afterInitialize();
    const fillSetting = this._settings.getSetting<GanttActivityColorsSetting>(CustomSettingKeys.ACTIVITY_FILL_COLOR_EXECUTION);
    const textColorSetting = this._settings.getSetting<GanttActivityColorsSetting>(CustomSettingKeys.ACTIVITY_TEXT_COLOR_EXECUTION);
    if (fillSetting) {
      this.fill = fillSetting.default;
      this.fillHighlight = fillSetting.highlight;
      this.fillSelected = fillSetting.selected;
      this.fillHover = fillSetting.hover;
      this.fillPressed = fillSetting.pressed;
    }
    if (textColorSetting) {
      this.textColor = textColorSetting.default;
      this.textColorHighlight = textColorSetting.highlight;
      this.textColorSelected = textColorSetting.selected;
      this.textColorHover = textColorSetting.hover;
      this.textColorPressed = textColorSetting.pressed;
      this._startDateColor = textColorSetting.startDate ?? this._startDateColor;
      this._endDateColor = textColorSetting.endDate ?? this._endDateColor;
    }
  }

  drawActivity(request: IActivityRenderingRequest<TaskSchedulingActivity>): void {
    const { activityBounds, activityRef, position, canvas, context, x, y, h, offsetTop, selected, hover, highlighted, pressed } = request;
    let { w } = request;

    context.clearRect(0, 0, context.canvas.width, context.canvas.height);

    const font = "normal 10px Roboto";
    context.font = font;
    (context as any).letterSpacing = "0.3px";
    context.globalAlpha = this.opacity;

    this.drawBackground(activityRef, position, context, x, y, w, h, offsetTop, selected, hover, highlighted, pressed);
    context.save();
    context.strokeStyle = "rgba(0,0,0,0.6)";
    context.beginPath();
    context.rect(x, y, w, h);
    context.closePath();
    context.stroke();
    context.restore();

    const dto = activityRef.activity.userObject;

    const statusBarHeight = Math.round(h / 8);
    if (dto && dto.statistics) {
      context.fillStyle = this._statusColorHelper.getStatusBackgroundColor(dto.statistics.totalStatus);
      const sX = x;
      const sY = y + h - statusBarHeight;
      const sW = w;
      const sH = statusBarHeight;
      context.fillRect(sX, sY, sW, sH);
      context.save();
      context.strokeStyle = "rgba(0,0,0,0.6)";
      context.rect(sX, sY, sW, sH);
      context.stroke();
      context.restore();
    }

    if (w < this._minWidth) w = this._minWidth;
    context.fillStyle = this.getTextColor(selected, hover, highlighted, pressed) ?? "rgba(0,0,0,1)";

    const startDate = activityRef.activity.userObject.executionStartDateFormatted ?? activityRef.activity.userObject.statistics!.executionStart!;
    const endDate = activityRef.activity.userObject.executionEndDateFormatted ?? activityRef.activity.userObject.statistics!.executionEnd!;

    let fsize = this.getFontSize(context.font);
    if (h / 2 < fsize) {
      fsize = h / 2;
    }

    const startDateTextWidth = context.measureText(endDate).width;
    const endDateTextWidth = context.measureText(endDate).width;

    if (startDateTextWidth + endDateTextWidth + this.paddingInsets.left + this.paddingInsets.right < w) {
      context.save();
      context.font = context.font.replace(/\d+px/, `${fsize}px`);
      const textY = y + h / 1.3 - fsize;
      let textX = x + this.paddingInsets.left;
      context.fillStyle = this._startDateColor ?? context.fillStyle;
      context.fillText(startDate, textX, textY);
      textX = x + w - endDateTextWidth - this.paddingInsets.right;
      context.fillStyle = this._endDateColor ?? context.fillStyle;
      context.fillText(endDate, textX, textY);
      context.restore();
    }

    if (dto) {
      const taskName = dto.name ?? "";
      const taskBusinessId = dto.businessId ?? "";

      const taskNameLines = this.getTextLines(context, taskName, w);

      if (w > 80) {
        const tasNameX = x + w / 2;

        context.font = font.replace("normal", "bold");
        context.textAlign = "center";

        for (let i = 0; i < taskNameLines.length; i++) {
          const line = taskNameLines[i];
          const lineY = y + fsize + i * fsize;
          context.fillText(line, tasNameX, lineY);
        }

        context.font = font;

        let secondLineText = taskBusinessId;

        if (dto.step && dto.step.name) {
          secondLineText = `${taskBusinessId} ‧ ${dto.step.name}`;
        }

        const secondLineLines = this.getTextLines(context, secondLineText, w);

        const secondLineTextX = x + w / 2;

        for (let i = 0; i < secondLineLines.length; i++) {
          const line = secondLineLines[i];
          const lineY = y + fsize * (2 + taskNameLines.length) + i * fsize;
          context.fillText(line, secondLineTextX, lineY);
        }
      }
    }
  }
}
