<script lang="ts" setup>
import { SettingDto } from "@masta/generated-model";
import { ref } from "vue";

const props = defineProps<{
  schema: SettingDto;
}>();

const emit = defineEmits(["save", "cancel"]);

const valid = ref<boolean>(true);

function save() {
  emit("save", props.schema);
}

function cancel() {
  emit("cancel");
}
</script>

<template>
  <v-card>
    <v-card-title>Edit schema</v-card-title>
    <v-card-actions>
      <v-spacer />
      <v-btn variant="outlined" color="accent" text :disabled="!valid" @click="save"> {{ $t("schema-edit-save-action", { $: "Save" }) }}</v-btn>
      <v-btn variant="outlined" color="secondary" text @click="cancel"> {{ $t("schema-edit-cancel-action", { $: "Cancel" }) }}</v-btn>
    </v-card-actions>
  </v-card>
</template>
