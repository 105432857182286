<script lang="ts" setup>
import { computed, ref, watch } from "vue";
import { JsonSchemaSetting } from "@masta/generated-model";
import SchemaBuilder from "@/components/ModelInstances/SchemaBuilder/SchemaBuilder.vue";
import CodeEditor from "@/components/CodeEditor.vue";
import { createEmptySchemaModel } from "@/components/ModelInstances/SchemaBuilder/SchemaModel";

const props = defineProps<{
  modelValue: string;
}>();
const emit = defineEmits(["update:modelValue"]);

const tab = ref("gui-editor");
const setting = ref<JsonSchemaSetting>(createEmptySchemaSetting());
const jsonSchema = ref<string>("");

watch(
  () => props.modelValue,
  () => {
    if (props.modelValue) {
      setting.value = JSON.parse(props.modelValue);
    } else {
      setting.value = createEmptySchemaSetting();
    }
    jsonSchema.value = JSON.stringify(setting.value.jsonSchema);
  },
  {
    immediate: true
  }
);

watch(jsonSchema, () => {
  setting.value.jsonSchema = JSON.parse(jsonSchema.value);
  settingUpdated();
});

function updateModelFromEditor(json: string) {
  emit("update:modelValue", json);
}

function updateVersion(version: string) {
  setting.value.version = version;
  settingUpdated();
}

function settingUpdated() {
  emit("update:modelValue", JSON.stringify(setting.value, 0, 2));
}

function createEmptySchemaSetting(): JsonSchemaSetting {
  return { jsonSchema: createEmptySchemaModel(), schemaTypes: [], version: "1" };
}
</script>

<template>
  <div>
    <v-row>
      <v-text-field
        :model-value="setting.version"
        variant="outlined"
        density="compact"
        :label="$t('setting-manage-schemaVersion-label', { $: 'Schema Version' })"
        @update:model-value="updateVersion"
      />
    </v-row>
    <v-card>
      <v-tabs v-model="tab" density="compact">
        <v-tab value="gui-editor"> {{ $t("schema-editor-visualEditor-label", { $: "GUI Editor" }) }}</v-tab>
        <v-tab value="code-editor"> {{ $t("schema-editor-codeEditor-label", { $: "Code Editor" }) }}</v-tab>
      </v-tabs>
      <v-card-text>
        <v-window v-model="tab">
          <v-window-item value="gui-editor">
            <v-container>
              <schema-builder v-model="jsonSchema" />
            </v-container>
          </v-window-item>
          <v-window-item value="code-editor">
            <v-container>
              <code-editor :model-value="modelValue" language="json" readonly="false" @update:model-value="updateModelFromEditor" />
            </v-container>
          </v-window-item>
        </v-window>
      </v-card-text>
    </v-card>
  </div>
</template>
