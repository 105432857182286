import { GanttDataLoaderBase, GanttSettings, IDataLoaderOptions } from "@masta/gantt2/core";
import axios, { AxiosInstance } from "axios";
import { inject } from "inversify";
import type { AppAxiosRequestConfig } from "@/services/api/common.api";
import { AppError } from "@/services/api/errors";
import { CustomSettingKeys } from "@/components/Gantt/ResourcesGantt/CustomSettingKeys";

export interface IGanttDataLoaderOptions extends IDataLoaderOptions {
  token: string;
  tenantId: string;
}

export abstract class CommonGanttDataLoader extends GanttDataLoaderBase {
  protected _options: IGanttDataLoaderOptions;
  protected _apiClient: AxiosInstance;
  protected _scenarioId: string | undefined;

  constructor(protected _settings: GanttSettings) {
    super();
  }

  async afterInitialize(): Promise<void> {
    await super.afterInitialize();

    this._apiClient = axios.create({
      baseURL: `${import.meta.env.VITE_API_BASE_URL}/api`,
      withCredentials: false, // This is the default
      timeout: 60000
    } as AppAxiosRequestConfig);

    const getHeaders = this.getHeaders.bind(this);

    this._apiClient.interceptors.request.use(
      async function (config: AppAxiosRequestConfig) {
        config.headers;
        if (config.withoutAuthorization) {
          return config;
        }
        config.headers!.common = {
          ...(config.headers!.common as any),
          ...getHeaders()
        };
        return config;
      },
      async (error) => {
        if (error.response && error.response.data) {
          const data = error.response.data;
          if (data.status > 0 && data.type && data.type && data.title) {
            return Promise.reject(new AppError(data.status, data.type, data.title, data.detail, data.errors, data.args));
          }
        }
        return Promise.reject(error);
      }
    );

    this._apiClient.interceptors.response.use(
      async function (response) {
        return response;
      },
      (error: any) => {
        if (error.response && error.response.data) {
          const data = error.response.data;
          if (data.status > 0 && data.type && data.type && data.title) {
            return Promise.reject(new AppError(data.status, data.type, data.title, data.detail, data.errors, data.args));
          }
        }
        return Promise.reject(error);
      }
    );
  }

  private getHeaders() {
    return {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${this._options.token}`,
      "X-Tenant": this._options.tenantId
    };
  }

  protected setScenarioId() {
    this._scenarioId = this._settings.getSetting<string>(CustomSettingKeys.SCENARIO_ID) ?? this._settings.getSetting<string>(CustomSettingKeys.FILTER);
  }
}
