import { GridApi, ICellEditor, IRowNode } from "ag-grid-community";

export interface ICheckboxRefreshActionParams {
  value: boolean;

  api: GridApi;

  node: IRowNode;

  column: string;
}

/**
 * Refreshes the checkbox cell editor.
 *
 * It uses the `api.getCellEditorInstances` method to get the cell editor instances for the given row and column.
 * Then it sets the value of the checkbox cell editor to the given value and updates the data value of the node.
 *
 * HACK HACK HACK: This method is a workaround for the issue where the checkbox cell editor does not update the
 * value when the data value of the node is changed.
 */
export const refreshCheckboxCellEditor = (params: ICheckboxRefreshActionParams) => {
  const { value, api, node, column } = params;

  api.getCellEditorInstances({ rowNodes: [node], columns: [column] }).forEach((cellEditor: ICellEditor) => {
    if (cellEditor != null) {
      node.setDataValue(column, value);

      // HACK -> reach the ag-grid/core/src/widgets/AgCheckbox instance cell editor and set the value
      cellEditor.eCheckbox.setValue(value);
    }
  });
};

