import { getOS, OperatingSystem } from "./OperatingSystem";

export type GraphicsMouseEvent = {
  ctrlKey: boolean;
  shiftKey: boolean;
  altKey: boolean;
  metaKey: boolean;
  osCtrlKey: boolean;
}

export function isCtrlKey(event: GraphicsMouseEvent | MouseEvent): boolean {
  const os = getOS();
  if (os === OperatingSystem.Mac) {
    return event.metaKey;
  } else {
    return event.ctrlKey;
  }
}
