import { ApiClient } from "@/services/api/common.api";

export default {
  async get() {
    return await ApiClient.get(`/M4MatProject`);
  },
  async sendInquiry() {
    return await ApiClient.post(`/M4MatInquiry`);
  },
  async upload(content: any) {
    return await ApiClient.post(`/M4MatProject`, content);
  },
  async sendRequestForQuotation(supplierName: any) {
    return await ApiClient.post(`/M4MatRequestForQuotation`, { SupplierName: supplierName });
  }
};
