import { IDateFilterParams } from "ag-grid-community";
import dayjs from "dayjs";

export const dateColumnFilterParams = ($date: typeof dayjs): IDateFilterParams => {
  return {
    comparator: (filterLocalDateAtMidnight: Date, cellValue: string) => {
      return textDateComparator(filterLocalDateAtMidnight, cellValue, $date);
    }
  };
};


/** 
 * Date type column filter comparator. Required if the data for the column is ISO string (not native JS Date objects).
 * More info: https://www.ag-grid.com/javascript-grid-filter-date/#date-filter-comparator
 * 
 * Besides, this comparator, only applies to filtering on the customer side. In the case of data 
 * retrieved from the server (server datasource), it will not be used.
 *
 * @param filterLocalDateAtMidnight
 * @param cellValue - the date value of the cell in ISO string format.
 */
export const textDateComparator = (filterLocalDateAtMidnight: Date, cellValue: string, $date: typeof dayjs): number => {
  const dateAsString = cellValue;

  if (dateAsString == null) return -1;
  const cellDate = $date(dateAsString).startOf('day').toDate();

  if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
    return 0;
  }
  if (cellDate < filterLocalDateAtMidnight) {
    return -1;
  }
  if (cellDate > filterLocalDateAtMidnight) {
    return 1;
  }
  return 0;
};

