<script lang="ts" setup>
import RescheduleRootTaskDialog from "@/components/Tasks/RescheduleRootTaskDialog.vue";
import { SelectRootTaskActivitiesAction } from "@/components/Gantt/ResourcesGantt/SelectRootTaskActivitiesAction";
import { useProductionTasksStore } from "@/store/ProductionTasksStore";
import { RegisterRootRescheduleCommand } from "@masta/generated-model";
import { useScenariosStore } from "@/store/ScenariosStore";
import { $t } from "@/i18n";
import { useSnackbarsStore } from "@/store/SnackbarsStore";
import { v4 as uuid } from "uuid";

const dialog = defineModel<boolean>("dialog", { required: true });

const date = defineModel<string | undefined>("date", { required: false });
const action = defineModel<SelectRootTaskActivitiesAction | null | undefined>("action", {
  required: false
});

const tasksStore = useProductionTasksStore();
const scenariosStore = useScenariosStore();
const snackbarsStore = useSnackbarsStore();

function createRescheduleRootTaskRequest(pickedDate: string): RegisterRootRescheduleCommand {
  if (!action.value || !action.value.rootTaskId) {
    throw new Error("Root task not selected");
  }
  return {
    rootTaskId: action.value?.rootTaskId,
    scenarioId: scenariosStore.selectedScenario?.id,
    start: pickedDate,
    businessKey: uuid()
  } as RegisterRootRescheduleCommand;
}

async function onDateSelected(pickedDate: string) {
  try {
    const request = createRescheduleRootTaskRequest(pickedDate);
    await tasksStore.rescheduleRootTask(request);
    await snackbarsStore.createSnackbar({
      message: $t("task-list-taskRescheduled-message", { $: "Tasks rescheduled" }),
      closeable: true
    });
    action.value?.setInProgress(request.businessKey);
  } catch (e: any) {
    console.error(e);
    await snackbarsStore.createSnackbar({
      message: e.message,
      type: "error",
      closeable: true
    });
  }
}
</script>

<template>
  <reschedule-root-task-dialog v-model="dialog" v-model:date="date" @date-selected="onDateSelected" />
</template>

<style scoped lang="scss">

</style>
