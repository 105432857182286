import { interfaces } from "inversify/lib/interfaces/interfaces";
import { Lifecycle } from "../Lifecycle";
import { inject, injectable } from "inversify";
import { IocContainer } from "../IocContainer";
import { BehaviorSubject, Observable } from "rxjs";

export interface IGanttAction {
  execute(context: any): Promise<any>;
}

export interface IGanttActionManager {
  getAction<T extends IGanttAction>(serviceIdentifier: interfaces.ServiceIdentifier<T>): Promise<T>;

  execute(context: any, action: IGanttAction): Promise<void>;

  get runningActions$(): Observable<IGanttAction[]>;

  get runningActions(): IGanttAction[];

  stopAllActions(): void;

  stopAction(action: IGanttAction): void;
}

@injectable()
export class GanttActionManager extends Lifecycle implements IGanttActionManager {
  private _runningActions$$: BehaviorSubject<IGanttAction[]> = new BehaviorSubject<IGanttAction[]>([]);

  constructor(@inject(IocContainer) private _iocContainer: IocContainer) {
    super();
  }

  get runningActions$(): Observable<IGanttAction[]> {
    return this._runningActions$$.asObservable();
  }

  get runningActions(): IGanttAction[] {
    return [...this._runningActions$$.value];
  }

  async getAction<T extends IGanttAction>(serviceIdentifier: interfaces.ServiceIdentifier<T>): Promise<T> {
    return await this._iocContainer.getAsync<T>(serviceIdentifier);
  }

  async execute(context: any, action: IGanttAction): Promise<any> {
    this._runningActions$$.next([...this._runningActions$$.value, action]);
    return new Promise((resolve, reject) => {
      action.execute(context).then(
        result => {
          this.stopAction(action);
          resolve(result);
        },
        error => {
          this.stopAction(action);
          reject(error);
        }
      );
    });
  }

  stopAllActions(): void {
    this._runningActions$$.value.forEach(action => {
      this.stopAction(action);
    });
  }

  stopAction(action: IGanttAction): void {
    const actions = this._runningActions$$.value;
    const index = actions.indexOf(action);
    if (index !== -1) {
      actions.splice(index, 1);
      this._runningActions$$.next([...actions]);
    }
  }
}
