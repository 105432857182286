<script lang="ts">
import { ICellRendererParams } from 'ag-grid-community';
import { RRule } from 'rrule';
import { useRecurrenceRuleText} from '@/components/RecurrenceRule/UseRecurrenceRuleText';

export default {
  setup(props: { params: ICellRendererParams }) {
    const value = props.params.value;

    const { toText } = useRecurrenceRuleText();
    
    function extractRRule(rrule: string) {
      try {
        const rruleObj = RRule.fromString(rrule);
        return toText(rruleObj);
      } catch (error) {
        console.error(error);
        return null; 
      }
    }

    return {
      value,
      extractRRule
    };
  }
};
</script>

<template>
  <div class="rrule-cell-renderer-cell">
    <span v-if="value" class="rrule-cell-renderer-cell-value">{{ extractRRule(value) }}</span>
  </div>
</template>


<style lang="scss">
.rrule-cell-renderer-cell {
  display: flex; 
  align-items: center; 
  justify-content: center;

  .rrule-cell-renderer-cell-value {
    overflow: hidden; 
    text-overflow: ellipsis;
  }
}
</style>