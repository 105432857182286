﻿<script setup lang="ts">
import { BomGraphDto } from "@masta/generated-model";
import MermaidViewer from "@/components/Mermaid/MermaidViewer.vue";
import { ref, watch } from "vue";
import { createMermaidGraph } from "@/components/Mermaid/BomGraphMermaidRenderer";

const props = defineProps<{
  graph: BomGraphDto | undefined;
  selectedResourceId: string | undefined;
}>();

const $emit = defineEmits<{
  (e: "selected", value: string): void;
}>();

const mermaidSource = ref<string>();
const mermaidViewerRef = ref();

defineExpose({
  reload() {
    mermaidViewerRef.value?.reload();
  }
});

watch(
  () => props.graph,
  () => {
    render();
  }
);

watch(
  () => props.selectedResourceId,
  () => {
    render();
  }
);

function render() {
  mermaidSource.value = props.graph && props.selectedResourceId ? createMermaidGraph(props.graph, props.selectedResourceId) : undefined;
}

function onNodeDecorate(node: HTMLElement) {
  node.style.cursor = "pointer";
  node.onclick = () => onNodeClicked(node);
}

function onNodeClicked(node: HTMLElement) {
  const dataId = node.getAttribute("data-id");
  if (dataId) {
    $emit("selected", dataId);
  }
}
</script>

<template>
  <mermaid-viewer v-if="mermaidSource" ref="mermaidViewerRef" :source="mermaidSource" :decorate="onNodeDecorate"/>
  <div v-else style="min-height: 91px; position: relative" />
</template>
