import { BehaviorSubject } from "rxjs";

import { RendererBase } from "./RendererBase";
import { PaddingInsets, createPaddingInsets } from "./PaddingInsets";
import { TimelineModel } from "../Model";
import { TimelineManager } from "../TimelineManager";

export abstract class Renderer extends RendererBase {
  private _fill$: BehaviorSubject<string | null> = new BehaviorSubject<string | null>("rgba(173,216,230,0.6)");

  private _fillPressed$: BehaviorSubject<string | null> = new BehaviorSubject<string | null>("rgba(130,157,169,0.6)");

  private _fillHighlight$: BehaviorSubject<string | null> = new BehaviorSubject<string | null>("rgba(255,255,0,0.6)");

  private _fillSelected$: BehaviorSubject<string | null> = new BehaviorSubject<string | null>("rgba(255,0,238,0.8)");

  private _fillHover$: BehaviorSubject<string | null> = new BehaviorSubject<string | null>("rgba(109,165,176,0.6)");

  private _textColor$: BehaviorSubject<string | null> = new BehaviorSubject<string | null>("rgba(0,0,0,1)");

  private _textColorPressed$: BehaviorSubject<string | null> = new BehaviorSubject<string | null>("rgba(0, 0, 0, 1)");

  private _textColorHighlight$: BehaviorSubject<string | null> = new BehaviorSubject<string | null>("rgba(0, 0, 0, 1)");

  private _textColorSelected$: BehaviorSubject<string | null> = new BehaviorSubject<string | null>("rgba(0, 0, 0, 1)");

  private _textColorHover$: BehaviorSubject<string | null> = new BehaviorSubject<string | null>("rgba(0,0,0,1)");

  private _paddingInsets$: BehaviorSubject<PaddingInsets> = new BehaviorSubject<PaddingInsets>(createPaddingInsets());

  constructor(timelineManager: TimelineManager, name: string) {
    super(timelineManager, name);
  }

  get fill$(): BehaviorSubject<string | null> {
    return this._fill$;
  }

  set fill(value: string | null) {
    this._fill$.next(value);
  }

  get fillPressed$(): BehaviorSubject<string | null> {
    return this._fillPressed$;
  }

  set fillPressed(value: string | null) {
    this._fillPressed$.next(value);
  }

  get fillHighlight$(): BehaviorSubject<string | null> {
    return this._fillHighlight$;
  }

  set fillHighlight(value: string | null) {
    this._fillHighlight$.next(value);
  }

  get fillSelected$(): BehaviorSubject<string | null> {
    return this._fillSelected$;
  }

  set fillSelected(value: string | null) {
    this._fillSelected$.next(value);
  }

  get fillHover$(): BehaviorSubject<string | null> {
    return this._fillHover$;
  }

  set fillHover(value: string | null) {
    this._fillHover$.next(value);
  }

  get textColor$(): BehaviorSubject<string | null> {
    return this._textColor$;
  }

  set textColor(value: string | null) {
    this._textColor$.next(value);
  }

  get textColorPressed$(): BehaviorSubject<string | null> {
    return this._textColorPressed$;
  }

  set textColorPressed(value: string | null) {
    this._textColorPressed$.next(value);
  }

  get textColorHighlight$(): BehaviorSubject<string | null> {
    return this._textColorHighlight$;
  }

  set textColorHighlight(value: string | null) {
    this._textColorHighlight$.next(value);
  }

  get textColorSelected$(): BehaviorSubject<string | null> {
    return this._textColorSelected$;
  }

  set textColorSelected(value: string | null) {
    this._textColorSelected$.next(value);
  }

  get textColorHover$(): BehaviorSubject<string | null> {
    return this._textColorHover$;
  }

  set textColorHover(value: string | null) {
    this._textColorHover$.next(value);
  }

  get paddingInsets$(): BehaviorSubject<PaddingInsets> {
    return this._paddingInsets$;
  }

  get paddingInsets(): PaddingInsets {
    return this._paddingInsets$.value;
  }

  set paddingInsets(value: PaddingInsets) {
    this._paddingInsets$.next(value);
  }

  protected getFill(selected: boolean, hover: boolean, highlighted: boolean, pressed: boolean) {
    if (selected) return this._fillSelected$.value;
    if (pressed) return this._fillPressed$.value;
    if (highlighted) return this._fillHighlight$.value;
    if (hover) return this._fillHover$.value;
    return this._fill$.value;
  }

  protected getTextColor(selected: boolean, hover: boolean, highlighted: boolean, pressed: boolean) {
    if (selected) return this._textColorSelected$.value;
    if (pressed) return this._textColorPressed$.value;
    if (highlighted) return this._textColorHighlight$.value;
    if (hover) return this._textColorHover$.value;
    return this._textColor$.value;
  }

  protected getFontSize(str: string): number {
    const res = /\d+/g.exec(str);
    if (res && res.length > 0) {
      return +res[0];
    }
    return 0;
  }
}
