import { ApiClient } from "@/services/api/common.api";
import {
  ContactDto,
  CreateContactCommand,
  CreateContactResponse,
  GetContactsPaginatedQuery,
  GetContactsQuery,
  GetContactsResponse,
  IAgGridPaginationResponse,
  InviteCustomerCommand,
  UpdateContactCommand
} from "@masta/generated-model";

export default {
  async create(request: CreateContactCommand) {
    return ApiClient.put<CreateContactResponse>(`/contacts`, request);
  },
  async getAllPaginated(request: GetContactsPaginatedQuery) {
    return ApiClient.post<IAgGridPaginationResponse<ContactDto>>(`/contacts`, request);
  },
  async getAll(request: GetContactsQuery) {
    return ApiClient.get<GetContactsResponse>(`/contacts/${request.scenarioId}`);
  },
  async update(request: UpdateContactCommand) {
    return ApiClient.put<undefined>(`/contacts/${request.id}`, request);
  },
  async remove(id: string, scenarioId: string) {
    return ApiClient.delete<undefined>(`/contacts/${id}?scenarioId=${scenarioId}`);
  },
  async inviteContact({ contactId }: InviteCustomerCommand) {
    return ApiClient.post(`/customer`, { contactId });
  }
};
