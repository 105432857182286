<script lang="ts" setup>
import { onMounted, ref, watch } from "vue";
import ApiService from "@/services/api";
import { AttachmentSourceType } from "@masta/generated-model";
import VuePdfEmbed from "vue-pdf-embed";
import { $t } from "@/i18n";
import Online3dViewer from "@/views/ProductOverview/Online3dViewer.vue";

const props = defineProps<{
  documentId?: string;
  revisionNumber?: number;
  fileName?: string;
  attachmentSourceType?: AttachmentSourceType;
  attachmentContentStorageConfigurationId?: string;
  disabled: boolean;
  uri?: string;
}>();

const contentType = ref<string | null>(null);
const url = ref<string | null>(null);
const fileFunc = ref<(() => Promise<File>) | null>(null);
const loadContent = async () => {
  try {
    const { data } = await ApiService.documents.getDocumentContentStream({
      documentId: props.documentId,
      revisionNumber: props.revisionNumber,
      attachmentSourceType: props.attachmentSourceType,
      attachmentContentStorageConfigurationId: props.attachmentContentStorageConfigurationId
    });
    contentType.value = data.type;
    if (contentType.value === "text/plain") {
      url.value = await data.text();
    } else {
      url.value = window.URL.createObjectURL(data);
    }
    if (contentType.value === "application/octet-stream" && props.fileName?.toLowerCase().endsWith("stp")) {
      const blob = new Blob([data], { type: "application/octet-stream" });
      fileFunc.value = () => Promise.resolve(new File([blob], props.fileName));
    }
  } catch (e) {
    console.error(e);
  }
};
onMounted(loadContent);

watch(() => props.documentId, loadContent);

async function onShowContent() {
  try {
    const a = document.createElement("a");
    a.href = url.value!;
    a.download = props.fileName;
    a.target = "_blank";
    a.click();
  } catch (e) {
    console.error(e);
  }
}
</script>

<template>
  <v-row class="mb-4">
    <v-col cols="6">
      <slot />
    </v-col>
    <v-col cols="6">
      <v-btn prepend-icon="mdi-download" size="large" density="compact" color="primary" block @click="onShowContent">
        {{ $t("documentAttachmentDefinition-content-action", { $: "Download content" }) }}
      </v-btn>
    </v-col>
  </v-row>
  <div class="content-container">
    <v-img
      v-if="contentType?.startsWith('image/')"
      :src="url"
      style="width: 100%; height: auto;"
    />
    <video
      v-else-if="contentType?.startsWith('video/')"
      controls
      style="width: 100%; height: auto;"
    >
      <source :src="url" :type="contentType" />
    </video>

    <v-textarea
      v-else-if="contentType === 'text/plain'"
      variant="outlined"
      density="compact"
      v-model="url"
      style="width: 100%; height: auto;"
      :disabled="props.disabled"
    ></v-textarea>
    <vue-pdf-embed
      v-else-if="contentType === 'application/pdf'"
      :source="url"
      style="width: 100%; height: 100%;"
    />
    <div
      v-else-if="contentType === 'application/octet-stream' && fileName?.toLowerCase().endsWith('stp') && fileFunc !== null"
      style="width: 100%; height: 100%;"
    >
      <Online3dViewer :file="fileFunc" style="width: 100%; height: 100%;" />
    </div>
  </div>
</template>

<style scoped>
.content-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: calc(60vh - 200px); 
}
</style>

<style lang="scss" scoped></style>
