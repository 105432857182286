import { Row } from "../Row";
import { IActivity } from "../IActivity";
import { ILinesManager } from "../ILinesManager";
import { Layout } from "../Layout";

export abstract class LinesManagerBase<Activity extends IActivity> implements ILinesManager<Activity> {

  protected _row: Row<any, any, Activity>;

  protected constructor(row: Row<any, any, Activity>) {
    this._row = row;
  }

  public get row(): Row<any, any, Activity> {
    return this._row;
  }

  abstract clearCache(): void;

  abstract getLineHeight(lineIndex: number, rowHeight: number): number;

  abstract getLineIndex(activity: Activity): number;

  abstract getLineLayout(lineIndex: number): Layout;

  abstract getLineLocation(lineIndex: number, rowHeight: number): number;

  abstract layout(): void;
}
