import ApiService from "@/services/api";
import { ServerSideDataSource } from "@/components/Grid/ServerSideDataSource";
import { CreateTagCommand, IAgGridPaginationResponse, TagDto, UpdateTagCommand } from "@masta/generated-model";
import { IServerSideGetRowsParams } from "ag-grid-community";
import { useTagsStore } from "@/store/TagsStore";
import { Store } from "pinia";

type CreateTagCommandType = CreateTagCommand;
type UpdateTagCommandType = UpdateTagCommand;

export class TagsServerSideDataSource extends ServerSideDataSource<TagDto, TagDto, CreateTagCommandType, UpdateTagCommandType, string> {
  private _tagsStore: Store<string, any>;

  constructor(id: string) {
    super(id);
    this._tagsStore = useTagsStore();
  }

  async create(entity: TagDto): Promise<string> {
    const request = this.transformForCreate(entity);
    const { data } = await ApiService.tags.createTag(request);
    await this._tagsStore.fetch(true);
    return data;
  }

  async getAll(params: IServerSideGetRowsParams): Promise<IAgGridPaginationResponse<TagDto>> {
    const groupKeys = params.request.groupKeys;
    const groupId = groupKeys[groupKeys.length - 1];

    const serverRequest = this.constructServerRequest(params);

    const { data } = await ApiService.tags.getTagsPaginated({
      ...serverRequest,
      groupId
    });


    return data;
  }

  async getSingle(id: string): Promise<TagDto | undefined> {
    const result = await ApiService.tags.getTagByNameOrId(id);
    return result?.data;
  }

  async update(entity: TagDto): Promise<string> {
    const request = this.transformForUpdate(entity);
    const { data } = await ApiService.tags.updateTag(request);
    await this._tagsStore.fetch(true);
    return data;
  }

  async remove(entity: TagDto): Promise<void> {
    await ApiService.tags.deleteTag(entity.name!);
    await this._tagsStore.fetch(true);
  }

  protected transformForCreate(listEntity: TagDto): CreateTagCommandType {
    return {
      name: listEntity.name,
      color: listEntity.color,
      emoji: listEntity.emoji,
      tagType: listEntity.tagType,
      rank: listEntity.rank,
      parentName: listEntity.parentName,
      parentId: listEntity.parentId
    } as CreateTagCommand;
  }

  protected transformForUpdate(listEntity: TagDto): UpdateTagCommandType {
    return {
      ...this.transformForCreate(listEntity)
    } as UpdateTagCommand;
  }
}
