import { inject, injectable } from "inversify";
import { IocSymbols, Lifecycle, ObservableDataSet, Row, TimelineDataLoader } from "@masta/gantt2/core";
import { ResourcesCapacitiesChangeNotificationEvent } from "@masta/generated-model";
import { Instant } from "@js-joda/core";
import { Observable, Subject } from "rxjs";

@injectable()
export class ResourceCapacityChangeNotificationHandler extends Lifecycle {
  private _changes$$ = new Subject<{ resourceId: string; start: Instant; end: Instant }[]>();

  constructor(@inject(TimelineDataLoader) private _timelineDataLoader: TimelineDataLoader, @inject(IocSymbols.RowsSymbol) private _rows: ObservableDataSet<Row<any, any, any>>) {
    super();
  }

  get changes$(): Observable<{ resourceId: string; start: Instant; end: Instant }[]> {
    return this._changes$$.asObservable();
  }

  async handleNotification(event: ResourcesCapacitiesChangeNotificationEvent) {
    const ganttRows = [...this._rows.data.values()];
    const ganttRowIds = ganttRows.map((row) => row.id);
    const changes =
      event.changes
        ?.filter((c) => ganttRowIds.includes(c.resourceId))
        .map((change) => {
          return {
            resourceId: change.resourceId,
            start: Instant.parse(change.start),
            end: Instant.parse(change.end)
          };
        }) ?? [];
    this._changes$$.next(changes);
    changes.sort((a, b) => a.start.compareTo(b.start));
    // changes.forEach((change) =>
    //   console.log(
    //     "[Gantt] %cChange: ",
    //     "background-color: #fff; color: #000; font-weight: bold;",
    //     ganttRows.find((x) => x.id === change.resourceId)?.name,
    //     change.start.toJSON(),
    //     change.end.toJSON()
    //   )
    // );
    const loadedStartTime = this._timelineDataLoader.loadedStartTime;
    const loadedEndTime = this._timelineDataLoader.loadedEndTime;

    const filteredChanges = changes.filter((change) => {
      return change.start.isBefore(loadedEndTime) && change.end.isAfter(loadedStartTime);
    });
    // filteredChanges.forEach((change) =>
    //   console.log(
    //     "[Gantt] %cChange: ",
    //     "background-color: #fff; color: #000; font-weight: bold;",
    //     ganttRows.find((x) => x.id === change.resourceId)?.name,
    //     change.start.toJSON(),
    //     change.end.toJSON()
    //   )
    // );

    // console.log("[Gantt] %cRefreshing data", "background-color: #fff; color: #000; font-weight: bold;", loadedStartTime.toJSON(), loadedEndTime.toJSON());
    await this._timelineDataLoader.refreshData(
      loadedStartTime.toEpochMilli(),
      loadedEndTime.toEpochMilli(),
      filteredChanges.map((change) => change.resourceId)
    );
  }
}
