<script setup lang="ts">
import { $t } from "@/i18n";
import { DocumentDto, IResourceDto } from "@masta/generated-model";
import { computed, ref } from "vue";
import { useDocumentsStore } from "@/store/DocumentsStore";
import ApiService from "@/services/api";
import MediaGallery, { MediaGalleryComponent } from "@/components/Media/MediaGallery.vue";
import { useSnackbarsStore } from "@/store/SnackbarsStore";
import type { QrCodeProps } from "@/components/Media/MediaPicker.vue";
import { useScenariosStore } from "@/store/ScenariosStore";

const $props = defineProps<{
  resource: IResourceDto | undefined;
  contextName: string;
}>();

const mediaGalleryRef = ref<MediaGalleryComponent | null>(null);

const snackbarStore = useSnackbarsStore();
const documentsStore = useDocumentsStore();
const scenarionStore = useScenariosStore();
const qrCodeProps = computed<QrCodeProps | undefined>(() =>
  $props.resource
    ? {
        resourceId: $props.resource.id
      }
    : undefined
);

async function documentsProvider(contextName: string): Promise<DocumentDto[]> {
  if (!$props.resource?.id) {
    throw new Error("Resource id is required");
  }
  const { data } = await ApiService.documents.getDocumentsAssignedToResourceForContext(contextName, $props.resource.id, scenarionStore.selectedScenario?.id ?? "");
  return data.documentDtos;
}

async function onDelete(dto: DocumentDto) {
  if (!$props.resource?.id) {
    throw new Error("Resource id is required");
  }
  try {
    await documentsStore.deassignResourceDocument(dto.id, $props.resource.id);
    await mediaGalleryRef.value?.refreshItems();
  } catch (e: any) {
    snackbarStore.createSnackbar({
      message: e && e.detail ? e.detail : $t("resourceMediaGallery-deleteError-message", { $: "Could not delete" }),
      type: "error",
      closeable: true
    });
  }
}

async function onSelected(items: DocumentDto[]) {
  if (items.length > 0 && $props.resource) {
    for (const item of items) {
      await ApiService.resources.assignModelInstance({
        resourceId: $props.resource.id,
        resourceBusinessId: $props.resource.businessId,
        scenarioId: $props.resource.scenarioId,
        modelInstanceId: item.id,
        modelInstanceBusinessId: item.businessId
      });
    }
  }
  await mediaGalleryRef.value?.refreshItems();
}
</script>

<template>
  <media-gallery
    ref="mediaGalleryRef"
    multiple
    :context-name="contextName"
    :documents-provider="documentsProvider"
    :on-delete="onDelete"
    :qr-code-props="qrCodeProps"
    @selected="onSelected"
  ></media-gallery>
</template>

<style scoped lang="scss"></style>
