import { inject, injectable, multiInject } from "inversify";
import { GanttEvents, GanttException, GanttSettings, IocSymbols, RowChartRepository, TimelineManager } from "@masta/gantt2/core";
import { fromEvent, withLatestFrom } from "rxjs";
import ApiService from "@/services/api";
import { DragAndDropData, LayerCanvas, ViewportPointerEventNoneScrollGlass, ViewportRowsContainer } from "@masta/gantt2/gantt";
import { RowDropZone } from "@/components/Gantt/ResourcesGantt/DragAndDrop/RowDropZone";
import { $t } from "@/i18n";
import { Instant } from "@js-joda/core";
import { v4 as uuid } from "uuid";
import { useProductionTasksStore } from "@/store/ProductionTasksStore";
import { EnhancedGanttResourceCapacityDto } from "@/components/Gantt/Common/Model";
import { useScenariosStore } from "@/store/ScenariosStore";
import { BaseTaskDragHandler } from "@/components/Gantt/ResourcesGantt/DragAndDrop/BaseTaskDragHandler";

const LOG_STYLE = "color: black; font-weight: bold; background-color: white; padding: 2px 25px;";

@injectable()
export class GanttTaskDragHandler extends BaseTaskDragHandler {

  private _dragAndDropData: DragAndDropData;

  constructor(@inject(GanttEvents) ganttEvents: GanttEvents,
              @inject(GanttSettings) settings: GanttSettings,
              @inject(TimelineManager) timelineManager: TimelineManager,
              @inject(ViewportRowsContainer) rowsContainer: ViewportRowsContainer,
              @inject(RowChartRepository) rowChartRepository: RowChartRepository,
              @inject(ViewportPointerEventNoneScrollGlass) glassLayer: ViewportPointerEventNoneScrollGlass,
              @multiInject(IocSymbols.AboveRowContentSystemLayersSymbol) aboveRowLayers: LayerCanvas[] = []) {
    super(ganttEvents, settings, timelineManager, rowsContainer, rowChartRepository, glassLayer, aboveRowLayers);
    this._dragId = uuid();
  }

  set dragAndDropData(value: DragAndDropData) {
    if (this._dragAndDropData) {
      throw new GanttException("DragAndDropData is already set");
    }
    this._dragAndDropData = value;
  }

  async afterInitialize(): Promise<void> {
    await super.afterInitialize();

    // instead of using this._ganttEvents.dragOverEvent$$ we are directly subscribing to the dragover event
    // because subscribing to the dragover event using this._ganttEvents.dragOverEvent$$ lagged and some events were lost
    this.subscribe(
      fromEvent<DragEvent>(this._glassLayer.element, "dragover").pipe(withLatestFrom(this._ganttEvents.externalActivityDrag$$, this._ganttEvents.ganttActivityDrag$$))
        .subscribe(([event, isExternal, draggedActivityData]) => this.onDragOver(event, isExternal, draggedActivityData))
    );

    this.subscribe(
      this._ganttEvents.dropEvent$$.pipe(withLatestFrom(this._ganttEvents.externalActivityDrag$$, this._ganttEvents.ganttActivityDrag$$))
        .subscribe(([event, isExternal, draggedActivityData]) => this.onDrop(event, isExternal, draggedActivityData))
    );

    this.subscribe(
      this._ganttEvents.dragEndEvent$$.pipe(withLatestFrom(this._ganttEvents.externalActivityDrag$$, this._ganttEvents.ganttActivityDrag$$))
        .subscribe(([event, isExternal, draggedActivityData]) => this.onDragEnd(event, isExternal, draggedActivityData))
    );
  }

  async setContext() {
    try {
      const scenariosStore = useScenariosStore();
      const { data } = await ApiService.gantt.getDragAndDropContext(this._dragAndDropData.activity.task.id, scenariosStore.selectedScenario!.id);
      // check if component is destroyed (delay between drag cancel and API response)
      if (this.isDestroyed) {
        return;
      }
      this._context = data;
      if (this._filterRows) {
        this.hideRowsOutsideOfDndContext();
      }
      await this.setDropZones();
      if (!this._filterRows && this._rowDropZones.length > 0) {
        this.scrollToRowDropZone(this._rowDropZones[0]);
      }

      this._ghostActivityLayer.addExternalTaskGhostActivityLayerContext({
        dragId: this._dragId,
        context: this._context,
        draggableTaskDto: this._dragAndDropData.activity.task
      });
    } catch (e: any) {
      if (e.name !== "AbortError") {
        console.error(e);
      }
    }
  }

  private async onDragOver(event: DragEvent, isExternal: boolean, _: DragAndDropData[] | null) {
    // console.log("%conDragOver", LOG_STYLE, event, isExternal);
    const rowId = this._rowHoverEvent?.rowId ?? this._dragAndDropData.rowId;

    if (!isExternal && event.dataTransfer && this._context && rowId && this._mousePosition && this.isHoveringOverRow()) {
      const rowDropZone = this._rowDropZones.find(x => x.row.id === rowId);

      const isDropAllowed = rowDropZone?.isDropAllowedForSupplyArea(this._mousePosition.x) ?? false;
      if (isDropAllowed) {
        if (this._glassLayer.element.classList.contains("not-allowed")) {
          this._glassLayer.element.classList.remove("not-allowed");
        }
        event.dataTransfer.dropEffect = "move";
        event.preventDefault();
        return;
      } else {
        if (!this._glassLayer.element.classList.contains("not-allowed")) {
          this._glassLayer.element.classList.add("not-allowed");
        }
        event.dataTransfer.dropEffect = "none";
      }
    }
  }

  private async onDrop(event: DragEvent, isExternal: boolean, _: DragAndDropData[] | null) {
    // console.log("%conDrop", LOG_STYLE, event, isExternal);
    const rowId = this._rowHoverEvent?.rowId ?? this._dragAndDropData.rowId;
    if (!isExternal && !this.isDestroyed && this._dragAndDropData && !this._isDropped && this._mousePosition && rowId && this.isHoveringOverRow()) {
      this._isDropped = true;

      const rowDropZone = this._rowDropZones.find(x => x.row.id === rowId);

      const isDropAllowed = rowDropZone?.isDropAllowedForSupplyArea(this._mousePosition.x) ?? false;
      if (!rowDropZone || !isDropAllowed) {
        const name = this._dragAndDropData.activity.name;
        const businessId = this._dragAndDropData.activity.businessId;
        await this._snackbarsStore.createSnackbar({
          message:
            $t("gantt-dragAndDrop-dropNotAllowed-message", { name, businessId, $: "Task {name} {businessId} cannot be dropped here!" }),
          type: "warning",
          closeable: true
        });
        console.warn("Task cannot be dropped here");
        await this.destroy();
        return;
      }


      if (this._businessKey) {
        console.warn("Task already scheduled, businessKey: ", this._businessKey);
        await this.destroyWithTimeout();
        return;
      }

      const snapX = rowDropZone.getSnapXCoordinate(this._mousePosition.x, RowDropZone.SnapAreaWidth, this._context.schedulingLengthMillis);
      const dropTime = this._timelineManager.calculateTimeForLocation(snapX);

      this._businessKey = await this.rescheduleTask(this._dragAndDropData.activity, dropTime, rowId);

      if (this._filterRows) {
        this.showAllRows();
      }

      if (!this._businessKey) return;

      // this.cancelDestroyTimeout();

      this._dropAreasLayer.visible = false;
      this._dropAreasLayer.batchDraw();

      this._ghostActivityLayer.removeExternalTaskGhostActivityLayerContext(this._dragId);
      this._ghostActivityLayer.addSchedulingOperationGhostActivityLayerContext({
        businessKey: this._businessKey,
        rowDropZone,
        dropTime,
        context: this._context,
        draggableTaskDto: this._dragAndDropData.activity.task
      });

      this.scrollToRowDropZone(rowDropZone);
    }

    await this.destroyWithTimeout();
  }

  private async onDragEnd(event: DragEvent, isExternal: boolean, _: DragAndDropData[] | null) {
    if (!this._isDropped) {
      // console.log("%conDragEnd - destroying because not dropped", LOG_STYLE, event, isExternal, _, this._isDropped);
      await this.destroy();
    }
  }

  private async rescheduleTask(data: EnhancedGanttResourceCapacityDto, dropTime: Instant, resourceId: string | undefined) {
    const name = data.task.name;
    const businessId = data.task.businessId;
    try {
      const businessKey = uuid();
      const store = useProductionTasksStore();
      await store.rescheduleGanttDroppedTask({
        taskId: data.task.id,
        resourceId,
        time: dropTime.toJSON(),
        businessKey
      });
      await this._snackbarsStore.createSnackbar({
        message: $t("gantt-dragAndDrop-onRescheduleTaskSuccess-message", { name, businessId, $: "Task {name} {businessId} rescheduled!" }),
        type: "info",
        closeable: true
      });
      return businessKey;
    } catch (e) {
      console.error(e);
      await this._snackbarsStore.createSnackbar({
        message: $t("gantt-dragAndDrop-onRescheduleTaskError-message", { name, businessId, $: "Could not schedule task {name} {businessId} " }),
        type: "error",
        closeable: true
      });
    }
  }
}
