<script lang="ts" setup>
import { $t } from "@/i18n";
import { inject } from "vue";
import DatepickerField from "@/components/Datepicker/DatepickerField.vue";
import { $dateTimeFormatterSymbol, DateFormatter } from "@masta/shared";
import { useI18n } from "vue-i18n";

const dialog = defineModel<boolean>({ required: false });

const $dateTimeFormatter = inject<DateFormatter>($dateTimeFormatterSymbol)!;
const { locale } = useI18n();

const emit = defineEmits<{
  (e: "dateSelected", setting: string): void;
}>();

const selectedDate = defineModel<string | undefined>("date", { required: true });

function cancel() {
  dialog.value = false;
}

function confirm() {
  if (!selectedDate.value) return;
  emit("dateSelected", selectedDate.value);
  dialog.value = false;
  selectedDate.value = undefined;
}

function formatDate(date: Date) {
  return $dateTimeFormatter(date);
}

function doUpdate(value: string) {
  selectedDate.value = value;
}
</script>

<template>
  <v-dialog v-model="dialog" width="auto" @close="selectedDate = undefined">
    <v-card id="rescheduleRootTaskDialog" class="pa-8">
      <v-card-title class="d-flex align-center justify-space-between">
        <div class="text-h4">{{ $t("scheduling-rescheduleRootTaskDialog-title-label", { $: "Reschedule" }) }}</div>
        <v-icon @click="cancel">mdi-close</v-icon>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12" class="d-flex flex-row align-center mb-2">
            <datepicker-field
              v-model="selectedDate"
              :teleport="true"
              :config="{ allowPreventDefault: true }"
              :clearable="false"
              :format="formatDate"
              :locale="locale"
              :enable-time-picker="true"
              :label="$t('resource-rescheduleRootTaskDialog-rescheduleStartDate-label', { $: 'Reschedule start date' })"
              @update:model-value="doUpdate"
            >
            </datepicker-field>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-row>
          <v-col cols="6">
            <v-btn class="ma-4" variant="elevated" color="secondary" block @click="cancel">
              {{ $t("scheduling-rescheduleRootTaskDialog-cancel-action", { $: "Cancel" }) }}
            </v-btn>
          </v-col>
          <v-col cols="6">
            <v-btn class="ma-4" variant="elevated" color="primary" :disabled="!selectedDate" block @click="confirm">
              {{ $t("scheduling-rescheduleRootTaskDialog-schedule-action", { $: "Reschedule" }) }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
