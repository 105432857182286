﻿<script lang="ts" setup>
import mermaid from "mermaid";
import type mermaidAPI from "mermaid/mermaidAPI";
import panzoom from 'svg-pan-zoom';
import { onMounted, ref, watch } from "vue";

const defaultConfig: any = {
  theme: "base",
  themeVariables: {
    // NOTE: check more styling options in <style /> section of the component
    fontFamily: '"Roboto", sans-serif',
    fontSize: "12px",
    primaryColor: "rgba(122, 99, 255, 0.1)",
    primaryTextColor: "rgba(122, 99, 255, 1)",
    primaryBorderColor: "rgba(122, 99, 255, 0)",
    lineColor: "rgba(189, 177, 255, 1)",
    secondaryColor: "rgba(170, 170, 170, 1)",
    secondaryTextColor: "rgba(170, 170, 170, 1)",
    secondaryBorderColor: "rgba(124, 99, 250, 1)",
    tertiaryColor: "rgba(255, 255, 255, 0.87)",
    tertiaryBorderColor: "rgba(124, 99, 250, 1)",
    titleColor: "rgba(124, 99, 250, 1)",
    edgeLabelBackground: "rgba(255, 255, 255, 1)"
  },
  startOnLoad: false,
  securityLevel: "loose",
  contentLoaded: () => {}
};

type DecoratorFn = (node: HTMLElement) => void;

interface Props {
  source: string;
  config?: any | undefined;
  decorate: DecoratorFn | undefined;
}

export interface MermaidViewerComponent {
  reload(): void;
}

const $props = withDefaults(defineProps<Props>(), { config: {}, decorate: undefined });

const pzoomRef = ref<any>(undefined);

defineExpose<MermaidViewerComponent>({
  reload() {
    load($props.source);
  }
});

function init() {
  const config = Object.assign(defaultConfig, $props.config) as mermaidAPI.Config;
  mermaid.initialize(config);
}

async function load(source: string) {
  if (source) {    
    pzoomRef.value?.destroy();
    
    const mermaidElement = document.getElementById("mermaid") as HTMLDivElement;
    if (mermaidElement) {
      const config = Object.assign(defaultConfig, $props.config) as mermaidAPI.Config;

      config.startOnLoad = false;
      config.flowchart = { useMaxWidth: false };
      config.gantt = { useMaxWidth: false };

      mermaidElement.removeAttribute("data-processed");
      const existingSourceNode = mermaidElement.firstChild;
      const sourceNode = document.createTextNode(source);
      if (existingSourceNode) {
        mermaidElement.replaceChild(sourceNode, existingSourceNode);
      } else {
        mermaidElement.appendChild(sourceNode);
      }
      mermaid.initialize(config);

      await mermaid.run({
        nodes: [mermaidElement],
        postRenderCallback(id) {
          performGraphPostProcessing(mermaidElement);
        },
        suppressErrors: true
      });

      const svgElement = mermaidElement.firstChild as SVGElement;
        if (!svgElement) {
        return;
      }

      pzoomRef.value = panzoom(svgElement, {
        controlIconsEnabled: true,
        fit: true,
        center: true,
        zoomEnabled: true,
      });
    }
  }
}

function performGraphPostProcessing(mermaidElement: HTMLDivElement) {
  if ($props.decorate) {
    const nodes = mermaidElement.querySelectorAll<HTMLElement>(".nodes .node");
    for (let i = 0; i < nodes.length; i++) {
      $props.decorate(nodes[i]);
    }
  }
}

watch($props, (props: Props) => {
  load(props.source);
});

onMounted(() => {
  init();
  load($props.source);
});
</script>

<template>
  <div id="mermaid" class="mermaid" style="height: 100%; width: 100%; display: block;">
    [no content]
  </div>
</template>

<style lang="scss">
.mermaid {
  svg:first-of-type {
    max-height: 100%;
    max-width: 100%;
    height: 100%;
    width: 100%;
  }

  .node.default {
    polygon {
      stroke-width: 2px !important;
    }
  }

  .node.selected {
    polygon {
      fill: #7a63ff !important;
    }

    p {
      color: #fff !important;
    }
  }
  .node.error {
    polygon {
      fill: #fab9c6 !important;
    }

    p {
      color: #60000d !important;
    }
  }
  .node.error-selected {
    polygon {
      fill: #811828 !important;
    }

    p {
      color: #fff !important;
    }
  }
}
</style>
