import { ActivityHelper, IActivity } from "../../Model";

export class Column<TActivity extends IActivity> {
  private _activities: TActivity[];

  get activities(): TActivity[] {
    return this._activities;
  }

  public add(activity: TActivity): void {
    if (!this._activities) {
      this._activities = [];
    }
    this._activities.push(activity);
  }

  public hasRoomFor(activity: TActivity, predicate?: (activity: TActivity) => boolean) {
    if (!this._activities) {
      return true;
    }
    if (predicate && !predicate(activity)) {
      return true;
    }
    for (const act of this._activities) {
      if ((!predicate || predicate(act)) && ActivityHelper.instantIntersects(activity.startTime, activity.endTime, act.startTime, act.endTime)) {
        return false;
      }
    }
    return true;
  }
}
