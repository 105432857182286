import { ApiClient } from "@/services/api/common.api";
import { RegisterDataExportCommand } from "@masta/generated-model";

export default {
  async getExportContentStream(uri: string) {
    return ApiClient.get<Blob>(`export/${uri}`, {
      responseType: "blob",
      timeout: 600000
    });
  },
  async createExportData(includeWorkJournalRecords: boolean, includeTransactionData: boolean, userEmail: string) {
    const command: RegisterDataExportCommand = { includeWorkJournalRecords: includeWorkJournalRecords, includeTransactionData: includeTransactionData, userEmail: userEmail };
    return ApiClient.post<void>("export", command);
  }
};
