<script lang="ts" setup>
import { useRoute } from "vue-router";
import { inject, ref, watch } from "vue";
import { $megaMenuSymbol, IMegaMenu } from "@/plugins/mega-menu.plugin";
import { MenuItem, MenuMetaDescription } from "@/components/MegaMenu/MegaMenuModel";

const route = useRoute();
const $megaMenu = inject<IMegaMenu>($megaMenuSymbol);
if (!$megaMenu) {
  throw Error("Could not build Mega Menu. Mega Menu Plugin not initialized.");
}
const routeItems = ref<MenuItem[]>([]);
watch(
  $megaMenu.getMainMenuItemsRef(),
  (mainMenuItems: MenuItem[]) => {
    if (route.meta && route.meta.menu && (route.meta.menu as any).length > 0) {
      const menuMeta = (route.meta.menu as MenuMetaDescription[]).sort((a: MenuMetaDescription, b: MenuMetaDescription) => (a.order ?? 0) - (b.order ?? 0))[0] as
        MenuMetaDescription;
      mainMenuItems.find((x) => x.id === menuMeta.path[0]);
      const items: MenuItem[] = [];
      setRouteMenuItems(menuMeta.path, mainMenuItems, items);
      routeItems.value = items;
    }
  },
  { immediate: true }
);

function setRouteMenuItems(paths: string[], items: MenuItem[], _routeItems: MenuItem[]) {
  if (paths.length === 0) return;
  for (let i = 0; i < items.length; i++) {
    const item = items[i];
    if (paths[0] === item.id) {
      _routeItems.push({ ...item });
      if (paths.length > 1) {
        setRouteMenuItems(paths.slice(1), item.items, _routeItems);
      }
      return;
    }
  }
}
</script>

<template>
  <div class="d-flex align-center pt-4">
    <div v-for="ri in routeItems.slice(1)" :key="ri.id" class="font-weight-regular">
      <v-icon v-if="ri.icon" :icon="ri.icon" class="pr-2" />
      <span class="breadcrumb-text">{{ ri.name }}</span>
    </div>
    <div v-if="routeItems.slice(1).length > 0" class="pr-2 font-weight-regular breadcrumb-text">:</div>
    <div class="font-weight-medium breadcrumb-text flex-grow-1 d-flex align-center">
      <slot />
    </div>
  </div>
  <div class="py-4">
    <v-divider />
  </div>
</template>

<style lang="scss" scoped>
$font-size: 12px;
.breadcrumb-text {
  text-transform: uppercase;
  font-size: $font-size;
}
</style>
