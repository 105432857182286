import { ILayerRenderer } from "./ILayerRenderer";
import { inject, injectable } from "inversify";
import { Lifecycle, TimelineManager } from "../../../Core";

export type GridLinesLayerRendererRequest = {
  offset: number;
};

/**
 * @deprecated Use GridLinesLayer
 */
@injectable()
export class GridLinesLayerRenderer extends Lifecycle implements ILayerRenderer<GridLinesLayerRendererRequest> {
  public static Identifier = "GridLinesLayerRenderer";

  constructor(@inject(TimelineManager) private _timelineManager: TimelineManager) {
    super();
  }

  render(canvas: OffscreenCanvas | HTMLCanvasElement, context: OffscreenCanvasRenderingContext2D | CanvasRenderingContext2D, params: GridLinesLayerRendererRequest) {
    context.clearRect(0, 0, canvas.width, canvas.height);

    const { offset } = params;
    const height = canvas instanceof HTMLCanvasElement ? canvas.clientHeight : canvas.height;

    const datelineGridMap = this._timelineManager.datelineManager.datelineGridMap;

    for (const scalePosition of datelineGridMap.keys()) {
      const gridPositions = datelineGridMap.get(scalePosition);
      if (!gridPositions) continue;
      switch (scalePosition) {
        case 0:
          context.lineWidth = 0.5;
          context.strokeStyle = "rgba(187,187,187,0.75)";
          break;
        case 1:
          context.lineWidth = 1.5;
          context.strokeStyle = "#212121";
          break;
        default:
          context.lineWidth = 1.0;
          context.strokeStyle = "#dc2525";
          break;
      }
      for (const gridPosition of gridPositions) {
        const x = gridPosition;
        context.beginPath();
        context.moveTo(x + offset, 0);
        context.lineTo(x + offset, height);
        context.closePath();
        context.stroke();
      }
    }
  }
}
