import dayjs from "dayjs";
import { Duration } from "dayjs/plugin/duration";

// Example duration used in examples is: 1 day, 10 hours, 20 minutes, 30 seconds

// ISO format example: "PT1D10H20M30S"
// Jira-like format example: "1d 10h 20m 30s"
// Server side format example: "1:10:20:30"

export const jiraFormatValidationPattern = /^(\s*[0-9]+\s*[yMdhms]\s*)+$/;
export const jiraFormatParsingPattern = /\s*([0-9]+)\s*([yMdhms])\s*/g;

export const serverSideValidationPattern = /^-?[0-9]{1,2}:[0-9]{1,2}:[0-9]{1,2}(\.[0-9]{1,9})?$/;

export function isJiraFormattedDuration(inputDuration: string) {
  return jiraFormatValidationPattern.test(inputDuration);
}

export function isServerSideFormattedDuration(inputDuration: string) {
  return serverSideValidationPattern.test(inputDuration);
}

export function asDuration(inputDuration: string): Duration | undefined {
  if (!inputDuration) {
    return;
  }
  if (isJiraFormattedDuration(inputDuration)) {
    return fromJiraFormattedInput(inputDuration);
  }
  if (isServerSideFormattedDuration(inputDuration)) {
    return fromServerSideFormattedInput(inputDuration);
  }
  return fromIsoFormattedInput(inputDuration);
}

export function asIsoFormattedString(inputDuration: string): string | undefined {
  return asDuration(inputDuration)?.toISOString();
}

export function asJiraFormattedString(inputDuration: string | Duration): string | undefined {
  const d = typeof inputDuration === "string" ? asDuration(inputDuration) : inputDuration;

  if (d) {
    const parts: string[] = [];
    const years = d.years();
    if (years) {
      parts.push(`${years}y`);
    }
    const months = d.months();
    if (months) {
      parts.push(`${months}M`);
    }
    const days = d.days();
    if (days) {
      parts.push(`${days}d`);
    }
    const hours = d.hours();
    if (hours) {
      parts.push(`${hours}h`);
    }
    const minutes = d.minutes();
    if (minutes) {
      parts.push(`${minutes}m`);
    }
    const seconds = d.seconds();
    if (seconds) {
      parts.push(`${seconds}s`);
    }
    return parts.join(" ") ?? "0m";
  }
  return;
}

export function asHHmmFormattedString(inputDuration: string): string {
  const d = asDuration(inputDuration);
  if (d) {
    const result = `${Math.trunc(d.asHours())}:${String(d.minutes()).padStart(2, "0")}`;
    return result;
  }
  return "";
}

export function asServerSideDurationFormattedString(inputDuration: string): string | undefined {
  const d = asDuration(inputDuration);
  if (d) {
    const sign = d.asSeconds() < 0 ? "-" : "";
    const hours = String(Number(d.days()) * 24 + Number(d.hours()));
    const minutes = String(d.minutes()).padStart(2, "0");
    const seconds = String(d.seconds()).padStart(2, "0");
    return `${sign}${hours}:${minutes}:${seconds}`;
  }
  return undefined;
}

function fromJiraFormattedInput(jiraFormattedInput: string) {
  const durationParams: DurationParams = {
    years: 0,
    months: 0,
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0
  };

  let m: RegExpExecArray | null;
  while ((m = jiraFormatParsingPattern.exec(jiraFormattedInput)) !== null) {
    if (m.index === jiraFormatParsingPattern.lastIndex) {
      jiraFormatParsingPattern.lastIndex++;
    }

    const timeValuePart = Number(m[1]);
    const timeUnitPart = m[2];

    if (timeValuePart && timeUnitPart) {
      switch (timeUnitPart) {
        case "y":
          durationParams.years = timeValuePart;
          break;
        case "M":
          durationParams.months = timeValuePart;
          break;
        case "d":
          durationParams.days = timeValuePart;
          break;
        case "h":
          durationParams.hours = timeValuePart;
          break;
        case "m":
          durationParams.minutes = timeValuePart;
          break;
        case "s":
          durationParams.seconds = timeValuePart;
          break;
      }
    }
  }

  durationParams.minutes += Math.floor(durationParams.seconds / 60);
  durationParams.seconds = durationParams.seconds % 60;

  durationParams.hours += Math.floor(durationParams.minutes / 60);
  durationParams.minutes = durationParams.minutes % 60;

  durationParams.days += Math.floor(durationParams.hours / 24);
  durationParams.hours = durationParams.hours % 24;

  durationParams.months += Math.floor(durationParams.days / 30);
  durationParams.days = durationParams.days % 30;

  durationParams.years += Math.floor(durationParams.months / 12);
  durationParams.months = durationParams.months % 12;

  return dayjs.duration(durationParams);
}

function fromIsoFormattedInput(isoFormattedInput: string) {
  return dayjs.duration(isoFormattedInput);
}

interface DurationParams {
  years: number;
  months: number;
  hours: number;
  seconds: number;
  minutes: number;
  days: number;
}

function applyDurationsParamsSign(serverSideFormattedInput: string, durationParams: DurationParams) {
  if (serverSideFormattedInput.startsWith("-")) {
    if (durationParams.days > 0) {
      durationParams.days = -durationParams.days;
    } else if (durationParams.hours > 0) {
      durationParams.hours = -durationParams.hours;
    } else if (durationParams.minutes > 0) {
      durationParams.minutes = -durationParams.minutes;
    } else if (durationParams.seconds > 0) {
      durationParams.seconds = -durationParams.seconds;
    }
  }
}

function fromServerSideFormattedInput(serverSideFormattedInput: string) {
  const parsedParams = serverSideFormattedInput.split(".");
  const parsedNumbers: number[] = parsedParams[0].split(":").map((x) => Number(x));
  if (parsedNumbers.length != 3) {
    console.error(`Not a valid server side formatted duration: ${serverSideFormattedInput}`);
    return;
  }

  const totalHours = parsedNumbers[0];

  const days = Math.floor(totalHours / 24);
  const hours = totalHours % 24;
  const minutes = parsedNumbers[1];
  const seconds = parsedNumbers[2];

  const durationParams = {
    days,
    hours,
    minutes,
    seconds
  } as DurationParams;

  applyDurationsParamsSign(serverSideFormattedInput, durationParams);

  return dayjs.duration(durationParams);
}

