import { Instant } from "@js-joda/core";
import { BehaviorSubject, Observable, Subscription } from "rxjs";
import { TimelineModel } from "../Model";
import { TimelineManager } from "../TimelineManager";
import { Lifecycle } from "../Lifecycle";

export abstract class RendererBase extends Lifecycle {
  private _enabled$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  private _opacity$: BehaviorSubject<number> = new BehaviorSubject<number>(1.0);

  private _offset = 0.5;

  private _name: string;

  private _snapToPixel = true;

  protected constructor(protected readonly _timelineManager: TimelineManager, name: string) {
    super();
    this._name = name;
  }

  get enabled$(): Observable<boolean> {
    return this._enabled$.asObservable();
  }

  get enabled(): boolean {
    return this._enabled$.value;
  }

  set enabled(value: boolean) {
    this._enabled$.next(value);
  }

  get opacity$(): Observable<number> {
    return this._opacity$.asObservable();
  }

  get opacity(): number {
    return this._opacity$.value;
  }

  set opacity(value: number) {
    this._opacity$.next(value);
  }

  get offset(): number {
    return this._offset;
  }

  set offset(value: number) {
    this._offset = value;
  }

  get snapToPixel(): boolean {
    return this._snapToPixel;
  }

  set snapToPixel(value: boolean) {
    this._snapToPixel = value;
  }

  get name(): string {
    return this._name;
  }

  getLocation(time: Instant): number {
    return this._timelineManager.calculateLocationForTime(time); // TODO graphics buffer/translatex/offset
  }

  getTimeAt(location: number): Instant {
    return this._timelineManager.calculateTimeForLocation(location); // TODO graphics buffer/translatex/offset
  }
}
