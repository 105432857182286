import { ChronoUnit, DayOfWeek, Instant, TemporalUnit, ZonedDateTime, ZoneId } from "@js-joda/core";

import { ActivityRef } from "../ActivityRef";
import { Layer } from "../Layer";

import { CalendarBase } from "./CalendarBase";
import { WeekendCalendarActivity } from "./WeekendCalendarActivity";

export class WeekendCalendar extends CalendarBase<WeekendCalendarActivity> {
  private _lastStartTime = Instant.MIN;

  private _lastEndTime = Instant.MAX;

  private _lastZoneId: ZoneId;

  private _entries = new Array<WeekendCalendarActivity>();

  private _weekendDays = new Set<DayOfWeek>([DayOfWeek.SATURDAY, DayOfWeek.SUNDAY]);

  private _activities: WeekendCalendarActivity[] = [];

  constructor() {
    super("weekends");
  }

  public setWeekendDays(...dayOfWeeks: DayOfWeek[]): void {
    this._weekendDays.clear();
    dayOfWeeks.forEach((dow) => this._weekendDays.add(dow));
    this._activities.splice(0, this._activities.length);
  }

  public getWeekendDays(): DayOfWeek[] {
    return [...this._weekendDays];
  }

  getActivities(layer: Layer, startTime: Instant, endTime: Instant, temporalUnit: TemporalUnit, zoneId: ZoneId): Iterable<WeekendCalendarActivity> {
    if (!(temporalUnit instanceof ChronoUnit)) {
      return [];
    }

    const unit: ChronoUnit = temporalUnit as ChronoUnit;

    if (!this.isSupportedUnit(unit)) {
      return [];
    }
    if (startTime.equals(this._lastStartTime) && endTime.equals(this._lastEndTime) && zoneId.equals(this._lastZoneId)) {
      return this._entries;
    }

    this._entries.splice(0, this._entries.length);

    const st = ZonedDateTime.ofInstant(startTime, zoneId);
    const et = ZonedDateTime.ofInstant(endTime, zoneId);

    this.findWeekends(st, et);

    this._lastStartTime = startTime;
    this._lastEndTime = endTime;
    this._lastZoneId = zoneId;

    return this._entries;
  }

  getActivitiesById(layer: Layer, id: string[]): WeekendCalendarActivity[] {
    return this._entries.filter((x) => id.includes(x.id));
  }

  get id(): string {
    return "weekend-calendar";
  }

  getActivity(layer: Layer, id: string): WeekendCalendarActivity | undefined {
    return this._entries.find((x) => x.id === id);
  }

  removeActivity(activityRef: ActivityRef<WeekendCalendarActivity>): void {}

  private isSupportedUnit(unit: TemporalUnit) {
    if (unit instanceof ChronoUnit) {
      const chronoUnit = unit as ChronoUnit;
      switch (chronoUnit) {
        case ChronoUnit.DAYS:
        case ChronoUnit.WEEKS:
          return true;
        default:
      }
      return false;
    }

    return false;
  }

  private findWeekends(st: ZonedDateTime, et: ZonedDateTime) {
    et = et.plusDays(1).truncatedTo(ChronoUnit.DAYS);
    while (st.isBefore(et) || st.equals(et)) {
      const dow = st.dayOfWeek();
      if (this._weekendDays.has(dow)) {
        st = st.truncatedTo(ChronoUnit.DAYS);
        this._entries.push(this.getWeekendActivity(dow, Instant.from(st)));
      }

      st = st.plusDays(1);
    }
  }

  private getWeekendActivity(dow: DayOfWeek, startTime: Instant): WeekendCalendarActivity {
    let activity = this._activities.find((x) => x.dayOfWeek === dow && x.startTime.equals(startTime));
    if (!activity) {
      activity = new WeekendCalendarActivity(dow, startTime, startTime.plus(1, ChronoUnit.DAYS));
      this._activities.push(activity);
    }
    return activity;
  }
}
