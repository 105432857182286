<script setup lang="ts">
import { $t } from "@/i18n";
import { inject, ref, watch, watchEffect } from "vue";
import {
  CreateResourceCapacityCommand,
  GanttResourceDto,
  IResourceDto,
  ResourceCapacityChangeType,
  ResourceCapacityEntrySource,
  ResourceCapacityGroup
} from "@masta/generated-model";
import { VForm } from "vuetify/components";
import Api from "@/services/api";
import { useSnackbarsStore } from "@/store/SnackbarsStore";
import { useI18n } from "vue-i18n";
import { $dateTimeFormatterSymbol, DateFormatter } from "@masta/shared";
import { getEnumTitleValuePairs } from "@/composables/enumHelpers";
import { translateResourceCapacityChangeType, translateResourceCapacityEntrySource } from "@/composables/translateEnum";
import DatepickerField from "@/components/Datepicker/DatepickerField.vue";
import { getNowInISOFormat } from "@/components/Datepicker/DatepickerUtil";

const modelValue = defineModel<boolean>({ required: true });
const props = defineProps<{
  resource?: GanttResourceDto | IResourceDto;
  date?: string;
}>();

const emits = defineEmits(["created"]);

const form = ref<VForm>();
const valid = ref<boolean>(false);
const quantity = ref<number>(1);
const changeTypes = getEnumTitleValuePairs(ResourceCapacityChangeType, translateResourceCapacityChangeType);
const changeType = ref<ResourceCapacityChangeType>(ResourceCapacityChangeType.Absolute);
const entrySource = ref<ResourceCapacityEntrySource>(ResourceCapacityEntrySource.ManualEntry);

const periodStart = ref<string>(props.date ?? getNowInISOFormat());
const periodEnd = ref<string>(props.date ?? getNowInISOFormat());
const infiniteEnd = ref(true);

const entrySources = ref(getEnumTitleValuePairs(ResourceCapacityEntrySource, translateResourceCapacityEntrySource));

watch(
  () => modelValue.value,
  (newValue) => {
    if (newValue) {
      clearValues();
    }
  }
);

const { locale } = useI18n();
const $dateTimeFormatter = inject<DateFormatter>($dateTimeFormatterSymbol)!;
const snackbarsStore = useSnackbarsStore();

const quantityRules = [
  (v: any) => !isNaN(parseFloat(v)) || $t("resource-resourceCapacityCreator-quantityNumber-message", { $: "Quantity must be a number" }),
  (v: any) =>
    entrySource.value !== ResourceCapacityEntrySource.Calendar ||
    (entrySource.value === ResourceCapacityEntrySource.Calendar && v === 0) ||
    $t("resource-resourceCapacityCreator-quantity-0-message", { $: "Quantity must be 0 when entry source is Calendar" })
];

const entrySourceRules = [(v: any) => v >= 0 || $t("resource-resourceCapacityCreator-entrySource-message", { $: "Entry source is required" })];

watchEffect(() => {
  if (entrySource.value === ResourceCapacityEntrySource.Calendar) {
    quantity.value = 0;
  }
});

function clearValues() {
  periodStart.value = props.date ?? getNowInISOFormat();
  periodEnd.value = props.date ?? getNowInISOFormat();
  infiniteEnd.value = true;
  changeType.value = ResourceCapacityChangeType.Absolute;
}

async function onCreate() {
  const validationResult = await form.value?.validate();
  if (validationResult?.valid && props.resource) {
    const command = {} as CreateResourceCapacityCommand;
    command.periodStart = periodStart.value;
    command.periodEnd = infiniteEnd.value ? null : periodEnd.value;
    command.capacityGroup = ResourceCapacityGroup.Supply;
    command.resourceId = props.resource.id;
    command.scenarioId = props.resource.scenarioId;
    command.changeType = changeType.value;
    command.entrySource = entrySource.value;
    command.quantity = quantity.value;
    try {
      await Api.resourceCapacities.createResourceCapacity(command);
      emits("created");
      await snackbarsStore.createSnackbar({
        message: "Created",
        closeable: true
      });
      close();
    } catch (e: any) {
      await snackbarsStore.createSnackbar({
        message: e.response.status === 400 ? "Resouerce capacity creation failed" : e.message,
        type: "error"
      });
    }
  }
}

function onCancel() {
  close();
}

function close() {
  modelValue.value = false;
}

function formatDate(date: Date) {
  return $dateTimeFormatter(date);
}
</script>

<template>
  <v-dialog v-model="modelValue" :persistent="true" content-class="resource-capacity-create-dialog">
    <v-form ref="form" v-model="valid" @submit.prevent="">
      <v-card class="pa-8 resource-capacity-create-card">
        <v-card-title class="d-flex align-center justify-space-between">
          <div class="text-h4">{{ $t("resource-resourceCapacityCreator-title-label", { $: "Create resource capacity for" }) }} {{ resource?.businessId }}</div>
          <v-icon @click="onCancel">mdi-close</v-icon>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" class="d-flex flex-row align-center mb-2">
              <datepicker-field
                v-model="periodStart"
                :clearable="false"
                :format="formatDate"
                :locale="locale"
                :enable-time-picker="true"
                :label="$t('resource-resourceCapacityCreator-periodStart-label', { $: 'Period Start' })"
                teleport="body"
              >
              </datepicker-field>
            </v-col>
            <v-slide-y-transition>
              <v-col v-if="!infiniteEnd" cols="12" class="d-flex flex-row align-center">
                <datepicker-field
                  v-model="periodEnd"
                  :clearable="false"
                  :format="formatDate"
                  :locale="locale"
                  :enable-time-picker="true"
                  :label="$t('resource-resourceCapacityCreator-periodEnd-label', { $: 'Period End' })"
                  teleport="body"
                >
                </datepicker-field>
              </v-col>
            </v-slide-y-transition>
            <v-col cols="12" class="d-flex flex-row align-center">
              <v-checkbox v-model="infiniteEnd" :label="$t('resource-resourceCapacityCreator-infinity-label', { $: 'Infinity' })" density="compact" hide-details />
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="quantity"
                :label="$t('resource-resourceCapacityCreator-quantity-label', { $: 'Quantity' })"
                :rules="quantityRules"
                :disabled="entrySource === ResourceCapacityEntrySource.Calendar"
                density="compact"
                type="number"
                variant="outlined"
                hide-details="auto"
              />
            </v-col>
            <v-col cols="12">
              <v-select
                v-model="changeType"
                :items="changeTypes"
                variant="outlined"
                density="compact"
                hide-details="auto"
                :label="$t('resource-resourceCapacityCreator-changeType-label', { $: 'Change Type' })"
              />
            </v-col>
            <v-col cols="12">
              <v-select
                v-model="entrySource"
                :items="entrySources"
                :rules="entrySourceRules"
                variant="outlined"
                density="compact"
                hide-details="auto"
                :label="$t('resource-resourceCapacityCreator-entrySource-label', { $: 'Entry source' })"
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-row>
            <v-col cols="6">
              <v-btn class="ma-4" variant="elevated" color="secondary" block @click="onCancel">
                {{ $t("resource-resourceCapacityCreator-cancel-action", { $: "Cancel" }) }}
              </v-btn>
            </v-col>
            <v-col cols="6">
              <v-btn class="ma-4" variant="elevated" color="primary" block @click="onCreate">
                {{ $t("resource-resourceCapacityCreator-create-action", { $: "Create" }) }}
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<style lang="scss">
.resource-capacity-create-dialog {
  width: 30vw !important;
}

.resource-capacity-create-card {
  overflow: initial !important;
  z-index: initial !important;
}

@media screen and (max-width: 1900px) {
  .resource-capacity-create-dialog {
    width: 50vw !important;
  }
}

@media screen and (max-width: 1450px) {
  .resource-capacity-create-dialog {
    width: 75vw !important;
  }
}
</style>
