import { Instant, LocalTime } from "@js-joda/core";
import { IActivity } from "../IActivity";

export class ActivityHelper {
  public static activityIntersects(activity1: IActivity, activity2: IActivity): boolean {
    return ActivityHelper.instantIntersects(activity1.startTime, activity1.endTime, activity2.startTime, activity2.endTime);
  }

  public static instantIntersects(startTime1: Instant, endTime1: Instant, startTime2: Instant, endTime2: Instant): boolean {
    return ActivityHelper.millisIntersects(startTime1.toEpochMilli(), endTime1.toEpochMilli(), startTime2.toEpochMilli(), endTime2.toEpochMilli());
  }

  public static millisIntersects(startTime1: number, endTime1: number, startTime2: number, endTime2: number): boolean {
    if (startTime1 === startTime2 || endTime1 === endTime2) {
      return true;
    }
    return startTime1 < endTime2 && startTime2 < endTime1;
  }

  public static localTimeIntersects(startTime1: LocalTime, endTime1: LocalTime, startTime2: LocalTime, endTime2: LocalTime): boolean {
    if (startTime1.equals(startTime2) || endTime1.equals(endTime2)) {
      return true;
    }
    return startTime1.isBefore(endTime2) && endTime1.isAfter(startTime2);
  }
}
