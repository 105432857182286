import { inject, injectable } from "inversify";
import { GanttEvents, IRowInfoCell, IRowInfoColumn, Row } from "@masta/gantt2/core";
import { DemandSupplyScaleRowInfoTemplateCellCanvas } from "./DemandSupplyScaleRowInfoTemplateCellCanvas";
import { GanttDomElement } from "@masta/gantt2/gantt";

@injectable()
export class DemandSupplyScaleRowInfoTemplateCellWrapper<TResource extends Row<any, any, any>> extends GanttDomElement<HTMLDivElement> implements IRowInfoCell {

  readonly cellId = "demand-supply-info";
  private _column: IRowInfoColumn;

  constructor(@inject(Row<any, any, any>) private _row: TResource,
              @inject(GanttEvents) private _ganttEvents: GanttEvents,
              @inject(DemandSupplyScaleRowInfoTemplateCellCanvas) private _demSupScaleRowInfoTemplateCellCanvas: DemandSupplyScaleRowInfoTemplateCellCanvas<any>) {
    super(`${DemandSupplyScaleRowInfoTemplateCellWrapper.name}-${_row.id}`, undefined, "cell canvas");
  }


  setColumn(column: IRowInfoColumn): void {
    this._column = column;
    this._demSupScaleRowInfoTemplateCellCanvas.setColumn(this._column);
  }

  async beforeInitialize(): Promise<void> {
    await super.beforeInitialize();
    await this.addChildren([this._demSupScaleRowInfoTemplateCellCanvas]);
  }

  async afterInitialize(): Promise<void> {
    await super.afterInitialize();
  }

  async beforeDestroy(): Promise<void> {
    await super.beforeDestroy();
    await this.removeChild(this._demSupScaleRowInfoTemplateCellCanvas);
  }

  async doDrawFromBatch(): Promise<void> {
    await super.doDrawFromBatch();
    this.element.style.width = `${this._column.width}px`;
    this._demSupScaleRowInfoTemplateCellCanvas.element.style.width = `${this._column.width}px`;
  }
}
