<script lang="ts" setup>
import { onMounted, reactive, ref, watch } from "vue";
import { createEmptySchemaModel } from "@/components/ModelInstances/SchemaBuilder/SchemaModel";
import SchemaObjectProperty from "@/components/ModelInstances/SchemaBuilder/SchemaObjectProperty.vue";
import { serializeSchemaModel } from "@/components/ModelInstances/SchemaBuilder/SchemaModelSerialization";
import { deserializeSchemaModel } from "@/components/ModelInstances/SchemaBuilder/SchemaModelDeserialization";
import { $t } from "@/i18n";

const form = ref<any>(null);

const props = defineProps<{
  modelValue: string;
}>();

const emit = defineEmits<{
  (e: "update:modelValue", schema: string): void;
}>();

const schemaModel = ref(reactive(createEmptySchemaModel()));
const error = ref<string | undefined>();
let lastSchema: string | undefined;

function updateModel(schema: string) {
  if (lastSchema === schema) return;
  try {
    schemaModel.value = reactive(deserializeSchemaModel(schema));
    error.value = undefined;
  } catch (e: any) {
    console.error(e);
    error.value = e.message;
  }
}

watch(
  () => props.modelValue,
  (value, oldValue) => {
    updateModel(value);
  },
  { immediate: true }
);

watch(
  () => schemaModel.value,
  (value, oldValue) => {
    const newSchema = serializeSchemaModel(value);
    if (newSchema === lastSchema) return;
    lastSchema = newSchema;
    emit("update:modelValue", lastSchema);
  },
  { deep: true, immediate: true }
);

onMounted(() => {
  if (!props.modelValue) return;

  updateModel(props.modelValue);
});

function addProp(propType: string) {
  const propModel = createPropModel(propType);

  schemaModel.value.properties.push(propModel);
}

function createPropModel(propType: string) {
  if (propType === "string") {
    return {
      name: "",
      value: { type: propType, icon: "", color: "", minLength: null, maxLength: null, pattern: "" }
    };
  }
  return {
    name: "",
    value: { type: propType, icon: "", color: "" }
  };
}

function remove(index: number) {
  schemaModel.value.properties.splice(index, 1);
}

const titleRules = [
  (v: string) => !!v || $t("schema-builder-tileRequired-message", { $: "Title is required" }),
  (v: string) => (v && v.length > 0) || $t("schema-builder-onTitleNotEmptyRequired-message", { $: "Title must not be empty" })
];
</script>

<template>
  <v-banner v-if="error" icon="$warning" color="warning">
    <v-banner-text>
      {{ $t("schema-builder-onNotSupportedByGuiBuilder-message", { $: "Schema is not supported by GUI builder. Please use code editor instead." }) }}
    </v-banner-text>
  </v-banner>
  <div v-else>
    <v-text-field v-model="schemaModel.title" variant="outlined" :rules="titleRules" :label="$t('schema-builder-name-label', { $: 'Name' })" required />
    <v-textarea v-model="schemaModel.description" variant="outlined" :label="$t('schema-builder-description-label', { $: 'Description' })" />
    <v-container fluid>
      <div class="d-flex justify-start align-center mb-4">
        <h3 class="mx-4">
          {{ $t("schema-builder-availablePropertiesToAdd-action", { $: "Properties:" }) }}
        </h3>
        <v-btn color="secondary" variant="outlined" size="small" class="mx-4" @click="addProp('boolean')">
          <v-icon>mdi-plus</v-icon>
          {{ $t("schema-builder-addBooleanProperty-action", { $: "Boolean" }) }}
        </v-btn>
        <v-btn color="secondary" variant="outlined" size="small" class="mx-4" @click="addProp('enum')">
          <v-icon>mdi-plus</v-icon>
          {{ $t("schema-builder-addEnumValueProperty-action", { $: "Enum Values" }) }}
        </v-btn>
        <v-btn color="secondary" variant="outlined" size="small" class="mx-4" @click="addProp('number')">
          <v-icon>mdi-plus</v-icon>
          {{ $t("schema-builder-addNumberProperty-action", { $: "Number" }) }}
        </v-btn>
        <v-btn color="secondary" variant="outlined" size="small" class="mx-4" @click="addProp('string')">
          <v-icon>mdi-plus</v-icon>
          {{ $t("schema-builder-addStringProperty-action", { $: "String" }) }}
        </v-btn>
      </div>

      <schema-object-property v-for="(property, i) in schemaModel.properties" :key="i" v-model="schemaModel.properties[i]" @remove="remove(i)" />
    </v-container>
  </div>
</template>
