import { inject, injectable } from "inversify";
import { GanttEvents, Row, TimelineManager } from "../../../../Core";
import type { WeekendCalendarLayerRendererRequest } from "../../../../Worker";
import { WeekendCalendarLayerRenderer } from "../../../../Worker";
import { LayerCanvas } from "./LayerCanvas";

@injectable()
export class WeekendCalendarRowLayer extends LayerCanvas {

  constructor(
    @inject(Row<any, any, any>) private _row: Row<any, any, any>,
    @inject(TimelineManager) timelineManager: TimelineManager,
    @inject(GanttEvents) ganttEvents: GanttEvents,
    @inject(WeekendCalendarLayerRenderer.Identifier) private _renderer: WeekendCalendarLayerRenderer
    // @inject(IocSymbols.GanttWorkerSymbol) ganttWorker: IGanttWorker,

  ) {
    // super(timelineManager, ganttEvents, WeekendCalendarRowLayer.name, "weekend-calendar-layer", _row.id);
    super(timelineManager, ganttEvents, WeekendCalendarRowLayer.name, "weekend-calendar-layer");
  }

  async beforeInitialize(): Promise<void> {
    return super.beforeInitialize();
  }

  async afterInitialize(): Promise<void> {
    await super.afterInitialize();
    this.offset = 0.5;
  }

  async doDrawFromBatch(): Promise<void> {
    if (!this._row.visible || this.canvas.width === 0 || this.canvas.height === 0) return;

    if (this._timelineManager.datelineManager.scaleResolutions.length === 0) return;

    const request = {
      offsetTop: this.parent?.element.offsetTop ?? 0
    } as WeekendCalendarLayerRendererRequest;

    // this.webWorkerDraw(request);

    this._renderer.render(this.canvas as any, this.context as any, request);
  }

  // protected getRendererIdentifier(): string {
  //   return "WeekendCalendarLayerRenderer";
  // }
}
