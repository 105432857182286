import type { interfaces } from "inversify";
import { inject, injectable } from "inversify";
import { GanttDomElement } from "../../GanttDomElement";
import type { ILinesManager, IRowChartScaleProvider } from "../../../Core";
import { ActivityActionEvent, ActivityBounds, ActivityHelper, GanttEvents, IocSymbols, IRowContainer, ObservableDataSet, Row } from "../../../Core";
import { animationFrameScheduler, BehaviorSubject, debounceTime, Observable, pairwise, throttleTime } from "rxjs";
import { RowInfoContainer } from "./Info";
import { RowCanvasContainer } from "./Canvas";
import type { WebWorkerRowManager } from "../../../Worker";
import { Instant } from "@js-joda/core";

@injectable()
export class RowContainer<TResource extends Row<any, any, any>> extends GanttDomElement<HTMLDivElement> implements IRowContainer {
  private _activityBounds$$ = new ObservableDataSet<ActivityBounds>();

  public readonly hoveredActivities$$ = new BehaviorSubject<ActivityActionEvent[]>([]);
  public readonly pressedActivities$$ = new BehaviorSubject<ActivityActionEvent[]>([]);
  public readonly selectedActivities$$ = new BehaviorSubject<ActivityActionEvent[]>([]);

  private _rowManager?: WebWorkerRowManager;

  constructor(
    @inject(IocSymbols.RowContainerIocContainerSymbol) private _scopeIocContainer: interfaces.Container,
    @inject(RowInfoContainer<any>) private _rowInfoContainer: RowInfoContainer<any>,
    @inject(RowCanvasContainer<any>) private _rowCanvasContainer: RowCanvasContainer<any>,
    @inject(Row<any, any, any>) private _row: TResource,
    @inject(IocSymbols.LinesManagerSymbol) private _lineManager: ILinesManager<any>,
    @inject(IocSymbols.RowChartScaleProvider) private _rowChartScaleProvider: IRowChartScaleProvider,
    @inject(GanttEvents) private _ganttEvents: GanttEvents
  ) {
    super(_row.id, undefined, "row-container");
    this.element.dataset.id = this.identifier;
  }

  get activityBounds$$(): ObservableDataSet<ActivityBounds> {
    return this._activityBounds$$;
  }

  get activityBounds(): ActivityBounds[] {
    return Array.from(this._activityBounds$$.data.values());
  }

  set activityBounds(value: ActivityBounds[]) {
    this._activityBounds$$.clear();
    this._activityBounds$$.add(value);
  }

  get rowInfoContainer() {
    return this._rowInfoContainer;
  }

  get rowCanvasContainer() {
    return this._rowCanvasContainer;
  }

  get visible$(): Observable<boolean> {
    return this._row.visible$;
  }

  get visible() {
    return this._row.visible;
  }

  set visible(value: boolean) {
    this._row.visible = value;
  }

  get row() {
    return this._row;
  }

  get iocContainer() {
    return this._scopeIocContainer;
  }

  async beforeInitialize(): Promise<void> {
    await this.addChildren([this._rowInfoContainer, this._rowCanvasContainer]);
    // this._rowManager = this._ganttWorker.rowManager;
  }

  async afterInitialize(): Promise<void> {
    this._row.linesManager = this._lineManager;

    this.subscribe(this._ganttEvents.scrollEvent$.subscribe(this.checkRowVisibilityInViewport.bind(this)));

    this.subscribe(
      this._ganttEvents
        .observeResize(this.element)
        // .pipe(debounceTime(100))
        .subscribe(async () => {
          this.batchDraw(true);
        })
    );
    this.subscribe(
      this._row.repository.changeEvent$.pipe(debounceTime(100)).subscribe(async (e) => {
        this._row.linesManager.clearCache();
        this._row.linesManager.layout();
        this.batchDraw(true);
      })
    );
    this.subscribe(
      this._row.height$.pipe(throttleTime(0, animationFrameScheduler)).subscribe(async () => {
        // this._rowManager?.updateRowHeight(this._row.id, this._row.height);
        this.element.style.height = `${this._row.height + 1}px`;
        this._row.linesManager.clearCache();
        this._row.linesManager.layout();
        this.batchDraw(true);
      })
    );
    this.subscribe(this._row.levelNumber$.subscribe(() => {
      this.element.className = `${this._className} level-${this._row.levelNumber}`;
    }));

    this.subscribe(this._ganttEvents.rowHoverEvent$
      .subscribe((e) => {
        this.element.classList.toggle("hover", (e && e.rowId === this._row.id) ?? false);
      })
    );

    // redraw on scale change
    this.subscribe(
      this._rowChartScaleProvider.scaleValues$
        .subscribe((v) => {
            this.batchDraw(true);
          }
        ));

    // redraw on row visibility change
    this.subscribe(
      this._row.visible$.pipe(pairwise()).subscribe(([prev, curr]) => {
        if (!prev && curr) {
          this.batchDraw(true);
        }
      })
    );

    // recalculate lines on timeline refresh
    const timelineRefreshEventDebounced$ = this._ganttEvents.timelineRefreshEvent$.pipe(debounceTime(500));
    this.subscribe(timelineRefreshEventDebounced$.subscribe(() => {
      this._row.linesManager.clearCache();
      this._row.linesManager.layout();
      this.batchDraw(true);
    }));
  }

  async afterDestroy(): Promise<void> {
    // console.log("RowContainer.afterDestroy", this._row.name);
    // delete this._rowManager;
    await this._scopeIocContainer.unbindAllAsync();
  }

  batchDraw(childToo = false) {
    if (!this.visible || !this.element.isConnected) return;
    super.batchDraw(childToo);
  }

  // onLassoBoundsSelection(bounds: ActivityBounds[]): void {
  //   const _bounds = [...bounds.filter(x => x.rowId === this._row.id)];
  //   // const events = _bounds.map(b => createActivityActionEvent(b)).filter(x => x !== undefined) as ActivityActionEvent[];
  //   // this._ganttEvents.deleteSelectedActivities(this.selectedActivities$$.value);
  //   // this.selectedActivities$$.next(events);
  //   // this._ganttEvents.addSelectedActivities(events);
  // }

  public async checkRowVisibilityInViewport() {
    // const boundingParentRect = (event.target as HTMLElement).getBoundingClientRect();
    const boundingParentRect = this.element.parentElement?.parentElement?.parentElement?.getBoundingClientRect();
    if (!boundingParentRect) return;
    if (this.element.style.display === "none") {
      if (this.visible) this.visible = false;
      return;
    }
    const rect = this.element.getBoundingClientRect();
    const visible = rect.bottom >= boundingParentRect.top && rect.top <= boundingParentRect.bottom;
    if (this.visible !== visible) {
      this.visible = visible;
    }
  }

  // public get offsetTop$() {
  //   const observeOnMutation = (target: HTMLElement, config: MutationObserverInit | undefined): Observable<number> => {
  //     return new Observable((observer) => {
  //       // let previousOffsetTop = target.parentElement?.offsetTop ?? 0;
  //       const mutationObserver = new MutationObserver((mutations, instance) => {
  //         mutations.forEach((mutation) => {
  //           console.log("mutationObserver", this._row.name, mutation, this._rowCanvasContainer.element.getBoundingClientRect().height);
  //         });
  //       });
  //       mutationObserver.observe(target, config);
  //       return () => {
  //         mutationObserver.disconnect();
  //       };
  //     });
  //   };
  //   return observeOnMutation(this.element.parentElement!, { attributes: true, childList: true, characterData: true });
  // }

  public getActivityBoundsForMousePosition({ x, y }: { x: number; y: number }): Array<ActivityBounds> {
    const result = [];
    for (const bounds of this.activityBounds) {
      if (x >= bounds.x && x <= bounds.x + bounds.width && y >= bounds.y && y <= bounds.y + bounds.height) {
        result.push(bounds);
      }
    }
    return result;
  }

  public getActivityBoundsForTimeInterval(startTime: Instant, endTime: Instant): Array<ActivityBounds> {
    const result = [];
    for (const bounds of this.activityBounds) {
      if (ActivityHelper.instantIntersects(bounds.startTime, bounds.endTime, startTime, endTime)) {
        result.push(bounds);
      }
    }
    return result;
  }
}
