<script lang="ts" setup>
import { translateScenarioStatus } from "@/composables/translateEnum";
import { useScenariosStore } from "@/store/ScenariosStore";
import { ScenarioDto, ScenarioStatus } from "@masta/generated-model";
import { storeToRefs } from "pinia";
import { computed, ref } from "vue";
import ScenarioSelectorDialog from "@/components/Scenarios/ScenarioSelectorDialog.vue";
import { useRouter } from "vue-router";

const router = useRouter();
const scenarioStore = useScenariosStore();
const { selectedScenario } = storeToRefs(scenarioStore);
const dialog = ref(false);

const selectedScenarioText = computed(() => {
  if (selectedScenario.value) {
    const status = selectedScenario.value.status;
    const statusText = translateScenarioStatus(status);

    if (status === ScenarioStatus.Production) {
      return statusText;
    } else {
      return selectedScenario.value.businessId ?? "";
    }
  } else {
    console.error("Selected scenario is null !!!");
    return null;
  }
});

function openScenarioSelector() {
  dialog.value = true;
}

function onSelectedScenarioChange(scenario: ScenarioDto) {
  scenarioStore.selectScenario(scenario);
  router.push({ params: { scenarioId: scenario.businessId } });
}
</script>

<template>
  <div
    v-if="selectedScenario"
    class="overlay-line cursor-pointer"
    :class="selectedScenario.status === ScenarioStatus.Production ? 'bg-production' : 'bg-no-production'"
    @click="openScenarioSelector"
  >
    <span class="overlay-text" :class="selectedScenario.status === ScenarioStatus.Production ? 'bg-production' : 'bg-no-production'">
      {{ selectedScenarioText }}
    </span>
  </div>
  <scenario-selector-dialog v-model:dialog="dialog" :scenario="selectedScenario" @update:scenario="onSelectedScenarioChange" />
</template>

<style lang="scss">
.overlay-line {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.overlay-text {
  color: white;
  font-weight: bold;
  font-size: 10px;
  position: fixed;
  top: 2px;
  left: 50%;
  transform: translateX(-50%);
  height: 18px;
  z-index: 9999;
  border-radius: 0 0 5px 5px;
  padding: 0 5px;
}

.bg-production {
  background-color: rgb(var(--v-theme-primary));
}

.bg-no-production {
  background-color: rgb(var(--v-theme-warning));
}
</style>
