import { ApiClient } from "@/services/api-service/ApiClient";
import { GetProfilesQueryResponse, CreateOrUpdateProfileCommand, CreateOrUpdateProfileCommandResponse } from "@masta/generated-model";
import { AxiosResponse } from "axios";

export class UserInterfaceProfileController extends ApiClient {
  async getProfiles(profile: string): Promise<AxiosResponse<GetProfilesQueryResponse>> {
    return await this._axios.get<GetProfilesQueryResponse>(`/userinterface/profile/${profile}`);
  }

  async createOrUpdateProfile(command: CreateOrUpdateProfileCommand): Promise<AxiosResponse<CreateOrUpdateProfileCommandResponse>> {
    return await this._axios.post<CreateOrUpdateProfileCommandResponse>(`/userinterface/profile`, command);
  }

  async deleteProfile(id: string): Promise<AxiosResponse> {
    return await this._axios.delete(`/userinterface/profile/${id}`);
  }
}
