<template>
  <div :class="$vuetify.theme.name === 'dark' ? 'ag-theme-alpine-dark' : 'ag-theme-alpine'">
    <ag-grid-vue
      class="details"
      style="width: 100%"
      dom-layout="autoHeight"
      :column-defs="columnDefs"
      :default-col-def="defaultColDef"
      :auto-group-column-def="autoGroupColumnDef"
      :row-data="suppliers"
      :animate-rows="true"
      row-selection="single"
      @grid-ready="onGridReady"
      @selection-changed="onSelectionChanged"
    />
  </div>
</template>

<script>
import ApiService from "@/services/api";
import { AgGridVue } from "ag-grid-vue3";
import SystemEnumService from "@/services/system-enum.service";
import ButtonCellRenderer from "./ButtonCellRenderer.vue";
import { $t } from "@/i18n";

export default {
  name: "InquirySuppliersGrid",
  components: {
    AgGridVue,
    // eslint-disable-next-line vue/no-unused-components
    ButtonCellRenderer
  },
  props: {
    suppliers: {
      type: Array,
      required: true
    }
  },
  emits: ["rowSelected"],
  data: () => ({
    gridApi: null,
    columnDefs: null,
    defaultColDef: {
      flex: 1,
      sortable: true,
      resizable: true
    },
    autoGroupColumnDef: {
      minWidth: 200
    }
  }),
  beforeMount() {
    this.columnDefs = [
      {
        field: "supplierName",
        headerValueGetter: (_) => $t("material-inquirySuppliersList-supplierName-label", { $: "Supplier name" }),
        editable: false,
        filter: true
      },
      {
        field: "status",
        headerValueGetter: (_) => $t("material-inquirySuppliersList-status-label", { $: "Status" }),
        editable: false,
        filter: true,
        valueFormatter: (params) => (params.data ? SystemEnumService.matSupplierStatus(params.data.status) : null)
      },
      {
        field: "deliveryDate",
        headerValueGetter: (_) => $t("material-inquirySuppliersList-deliveryDate-label", { $: "Delivery date" }),
        editable: false,
        filter: true,
        valueFormatter: (params) => (params.data && params.data.deliveryDate ? this.$dateTimeFormatter(params.data.deliveryDate) : null)
      },
      {
        field: "supplierName",
        headerValueGetter: (_) => $t("material-inquirySuppliersList-quotation-label", { $: "Quotation" }),
        cellRendererFramework: "ButtonCellRenderer",
        cellRendererParams: {
          clicked: function (field) {
            ApiService.mat.sendRequestForQuotation(field);
          }
        }
      }
    ];
  },
  methods: {
    onGridReady(params) {
      this.gridApi = params.api;
      this.gridApi.sizeColumnsToFit();
      this.gridApi.setPopupParent(this.$el);
    },
    onSelectionChanged() {
      let rows = this.gridApi.getSelectedRows();
      if (rows.length === 1) {
        this.$emit("rowSelected", rows[0]);
      } else {
        this.$emit("rowSelected", null);
      }
    }
  }
};
</script>

<style lang="scss" scoped></style>
