﻿import ApiService from "@/services/api";
import { ServerSideDataSource } from "@/components/Grid/ServerSideDataSource";
import {
  CreateEquipmentCommand,
  CreateEquipmentGroupCommand,
  EquipmentDto,
  IAgGridPaginationResponse,
  ResourceType,
  UpdateEquipmentCommand,
  UpdateEquipmentGroupCommand,
  AgGridRequestBase
} from "@masta/generated-model";
import { IServerSideGetRowsParams } from "ag-grid-enterprise";
import { Store } from "pinia";
import { State as ScenarioState, useScenariosStore } from "@/store/ScenariosStore";

type CreateCommandType = CreateEquipmentCommand | CreateEquipmentGroupCommand;
type UpdateCommandType = UpdateEquipmentCommand | UpdateEquipmentGroupCommand;

export class EquipmentServerSideDataSource extends ServerSideDataSource<EquipmentDto, EquipmentDto, CreateCommandType, UpdateCommandType, string> {
  private _scenarioStore: Store<string, ScenarioState>;

  constructor(id: string) {
    super(id);
    this._scenarioStore = useScenariosStore();
  }

  async create(entity: EquipmentDto): Promise<string> {
    const request = this.transformForCreate(entity);
    const scenario = this._scenarioStore.selectedScenario;
    request.scenarioId = scenario?.id ?? "";
    const response = this.isGroup(entity)
      ? await ApiService.equipment.createGroup(request as CreateEquipmentGroupCommand)
      : await ApiService.equipment.create(request as CreateEquipmentCommand);
    return response.data.resourceId;
  }

  async getAll(params: IServerSideGetRowsParams): Promise<IAgGridPaginationResponse<EquipmentDto>> {
    const groupKeys = params.request.groupKeys;
    const parentId = groupKeys[groupKeys.length - 1];

    const serverRequest = this.constructServerRequest(params);
    this.modifiedFilterModel(serverRequest);
    this.modifiedSortModel(serverRequest);

    const { data } = await ApiService.equipment.getPaginated({
      ...serverRequest,
      scenarioId: this._scenarioStore.selectedScenario?.id ?? "",
      groupId: parentId,
      ignoreGrouping: false
    });
    return data;
  }

  async getSingle(id: string): Promise<EquipmentDto | undefined> {
    const scenario = this._scenarioStore.selectedScenario;
    if (!scenario) return;
    const response = await ApiService.equipment.getSingle(id, scenario.id);
    return response.data;
  }

  async remove(entity: EquipmentDto): Promise<void> {
    this.isGroup(entity)
      ? await ApiService.equipment.removeGroup(entity.id, this._scenarioStore.selectedScenario?.id)
      : await ApiService.equipment.remove(entity.id, this._scenarioStore.selectedScenario?.id);
  }

  async update(entity: EquipmentDto): Promise<string> {
    this.isGroup(entity)
      ? await ApiService.equipment.updateGroup(this.transformForUpdate(entity) as UpdateEquipmentGroupCommand)
      : await ApiService.equipment.update(this.transformForUpdate(entity) as UpdateEquipmentCommand);
    return entity.id;
  }

  protected isGroup(listEntity: EquipmentDto) {
    return listEntity.type === ResourceType.EquipmentGroup;
  }

  protected modifiedFilterModel(serverRequest: AgGridRequestBase) {
    const filterModel = serverRequest.filterModel;

    if (filterModel && "ag-Grid-AutoColumn" in filterModel) {
      // rewrite ag-Grid-AutoColumn to the businessId to the filterModel
      filterModel["businessId"] = filterModel["ag-Grid-AutoColumn"];
    }
  }

  protected modifiedSortModel(serverRequest: AgGridRequestBase) {
    const sortModel = serverRequest.sortModel;

    if (sortModel) {
      sortModel.forEach((sortModelItem) => {
        if (sortModelItem.colId === "ag-Grid-AutoColumn") {
          // rewrite ag-Grid-AutoColumn to the businessId
          sortModelItem.colId = "businessId";
        }
      });
    }
  }

  protected transformForCreate(listEntity: EquipmentDto): CreateCommandType {
    if (this.isGroup(listEntity)) {
      return {
        name: listEntity.name,
        businessId: listEntity.businessId,
        tags: listEntity.tags,
        color: listEntity.color,
        plannable: listEntity.plannable,
        scenarioId: listEntity.scenarioId ?? this._scenarioStore.selectedScenario?.id ?? undefined,
        description: listEntity.description,
        parentId: listEntity.parentId
      } as unknown as CreateEquipmentGroupCommand;
    } else {
      return {
        name: listEntity.name,
        businessId: listEntity.businessId,
        tags: listEntity.tags,
        color: listEntity.color,
        plannable: listEntity.plannable,
        scenarioId: listEntity.scenarioId ?? this._scenarioStore.selectedScenario?.id ?? undefined,
        description: listEntity.description,
        parentId: listEntity.parentId
      } as CreateEquipmentCommand;
    }
  }

  protected transformForUpdate(listEntity: EquipmentDto): UpdateCommandType {
    if (this.isGroup(listEntity)) {
      return {
        id: listEntity.id,
        name: listEntity.name,
        businessId: listEntity.businessId,
        tags: listEntity.tags,
        color: listEntity.color,
        plannable: listEntity.plannable,
        scenarioId: listEntity.scenarioId ?? this._scenarioStore.selectedScenario?.id ?? undefined,
        description: listEntity.description,
        parentId: listEntity.parentId
      } as unknown as UpdateEquipmentGroupCommand;
    } else {
      return {
        id: listEntity.id,
        name: listEntity.name,
        businessId: listEntity.businessId,
        tags: listEntity.tags,
        color: listEntity.color,
        plannable: listEntity.plannable,
        scenarioId: listEntity.scenarioId ?? this._scenarioStore.selectedScenario?.id ?? undefined,
        description: listEntity.description,
        parentId: listEntity.parentId
      } as UpdateEquipmentCommand;
    }
  }
}
