import { Instant, TemporalUnit, ZoneId } from "@js-joda/core";

import { GanttException } from "../../Exceptions";
import { ActivityRef } from "../ActivityRef";
import { IActivity } from "../IActivity";
import { Layer } from "../Layer";
import { TimeIntervalTree } from "../Util";

import { MutableActivityRepositoryBase } from "./MutableActivityRepositoryBase";
import { RepositoryActivityAddedEvent } from "./RepositoryActivityAddedEvent";
import { RepositoryActivityRemovedEvent } from "./RepositoryActivityRemovedEvent";
import { RepositoryChangedEvent } from "./RepositoryChangedEvent";

export class TimeIntervalTreeActivityRepository<Activity extends IActivity> extends MutableActivityRepositoryBase<Activity> {
  private treeMap = new Map<Layer, TimeIntervalTree<Activity>>();

  getActivities(layer: Layer, startTime: Instant, endTime: Instant, temporalUnit: TemporalUnit, zoneId: ZoneId): Iterable<Activity> {
    if (layer === null || startTime === null || endTime === null || temporalUnit === null || zoneId === null) {
      return [];
    }
    const tree = this.getTree(layer);
    const activities = tree.getIntersectingObjectsBetweenDates(startTime.toEpochMilli(), endTime.toEpochMilli());
    return activities;
  }

  getActivitiesByStartEndTime(startTime: Instant, endTime: Instant): Iterable<Activity> {
    const result = new Array<Activity>();
    if (startTime != null && endTime != null) {
      for (const tree of this.treeMap.values()) {
        tree.getIntersectingObjectsBetweenDates(startTime.toEpochMilli(), endTime.toEpochMilli()).forEach((a) => result.push(a));
      }
    }
    return result;
  }

  getAllActivities() {
    const st = this.getEarliestTimeUsed();
    const et = this.getLatestTimeUsed();
    const result = new Array<Activity>();
    if (st != null && et != null) {
      for (const tree of this.treeMap.values()) {
        tree.getIntersectingObjectsBetweenDates(st.toEpochMilli(), et.toEpochMilli()).forEach((a) => result.push(a));
      }
    }
    return result;
  }

  addActivity(activityRef: ActivityRef<Activity>): void {
    const { layer, row, activity } = activityRef;
    const tree = this.getTree(layer);
    tree.add(activity);
    this._addEvents$$.next(new RepositoryActivityAddedEvent(this, activityRef));
    this._changeEvents$$.next(new RepositoryChangedEvent(this));
  }

  addActivities(activityRef: ActivityRef<Activity>[]): void {
    for (const r of activityRef) {
      this.addActivity(r);
    }
  }

  removeActivity(activityRef: ActivityRef<Activity>): void {
    const { layer, row, activity } = activityRef;
    const tree = this.getTree(layer);
    const isMember = tree.remove(activity);
    if (!isMember) {
      throw new GanttException("given activity was not a member of this repository, maybe the start / end time were modified before removal?");
    }
    this._removeEvents$$.next(new RepositoryActivityRemovedEvent(this, activityRef));
    this._changeEvents$$.next(new RepositoryChangedEvent(this));
  }

  removeActivities(activityRef: ActivityRef<Activity>[]): void {
    for (const r of activityRef) {
      this.removeActivity(r);
    }
  }

  clearActivities(): void {
    for (const layer of this.treeMap.keys()) {
      this.getTree(layer).clear();
    }
    this._changeEvents$$.next(new RepositoryChangedEvent(this));
  }

  clearLayerActivities(layer: Layer): void {
    this.getTree(layer).clear();
    this._changeEvents$$.next(new RepositoryChangedEvent(this));
  }

  getEarliestTimeUsed(): Instant {
    if (this.treeMap.size > 0) {
      let time = null;
      for (const tree of this.treeMap.values()) {
        const earliest = tree.getEarliestTimeUsed();
        if (earliest != null && (time == null || earliest.isBefore(time))) {
          time = earliest;
        }
      }
      return time!;
    }
    return null!;
  }

  getLatestTimeUsed(): Instant {
    if (this.treeMap.size > 0) {
      let time = null;
      for (const tree of this.treeMap.values()) {
        const latest = tree.getLatestTimeUsed();
        if (latest != null && (time == null || latest.isAfter(time))) {
          time = latest;
        }
      }
      return time!;
    }
    return null!;
  }

  getActivity(layer: Layer, id: string): Activity | undefined {
    return this.getTree(layer)?.getById(id) ?? undefined;
  }

  getActivitiesById(layer: Layer, ids: string[]): Activity[] {
    return this.getTree(layer)?.getByIds(ids) ?? [];
  }

  private getTree(layer: Layer): TimeIntervalTree<Activity> {
    let key = [...this.treeMap.keys()].find((x) => x.id === layer?.id);
    if (!key) {
      this.treeMap.set(layer, new TimeIntervalTree());
      key = layer;
    }
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return this.treeMap.get(key)!;
  }
}
