<script lang="ts" setup>
import ImagePickerToolbar from "@/components/ModelInstances/Manual/ImagePickerToolbar.vue";
import TextColorPickerToolbar from "@/components/ModelInstances/Manual/TextColorPickerToolbar.vue";
import { useMarkdownImageLoader } from "@/components/ModelInstances/Manual/useMarkdownImageLoader";
import replaceLink, { ReplaceLinkOptions } from 'markdown-it-replace-link';
import { ExposePreviewParam, MdEditor, MdPreview, ToolbarNames, config } from "md-editor-v3";
import "md-editor-v3/lib/preview.css";
import "md-editor-v3/lib/style.css";
import { WritableComputedRef, computed, onMounted, ref, toRefs } from "vue";


const json = defineModel<{ manual: string }>({ required: true });
const p = defineProps<{ disabled: boolean; contextName: string }>();
const props = toRefs(p);
const mdPreviewRef = ref<ExposePreviewParam>();
const mdEditorRef = ref<typeof MdEditor>();

const { loadingMarkdownImages, loadMarkdownImageData, resolveMarkdownImageLink } = useMarkdownImageLoader();

const value: WritableComputedRef<string> = computed({
  get(): string {
    return json.value.manual;
  },
  set(newValue: string): void {
    try {
      json.value.manual = newValue;
    } catch (e) {
      console.error("err", e);
    }
  }
});

onMounted(() => {
  loadMarkdownImageData(value.value)
    .then(() => {
      mdPreviewRef.value?.rerender();
    });
});

const replaceLinkPluginOptions: ReplaceLinkOptions = {
  processHTML: true,
  replaceLink: function (link, env, token, htmlToken) {
    const mdImage = resolveMarkdownImageLink(link);
    return mdImage ?? link;
  }
};

config({
  markdownItConfig(mdit) {
    mdit.use(replaceLink, replaceLinkPluginOptions)
  }
});

const toolbars = computed(() => {
  if (!props.disabled.value) {
    return [
      "bold",
      "underline",
      "italic",
      "strikeThrough",
      "-",
      "title",
      "sub",
      "sup",
      "quote",
      "unorderedList",
      "orderedList",
      "task",
      "-",
      "codeRow",
      "code",
      "link",
      0, // toolbar index number from #defToolbars slot
      "table",
      "mermaid",
      "katex",
      1,
      2,
      "-",
      "revoke",
      "next",
      // "save",
      "=",
      "prettier",
      "pageFullscreen",
      "fullscreen",
      "preview",
      "htmlPreview",
      "catalog"
      // "github"
    ] as ToolbarNames[];
  }
  return [];
});
</script>

<template>
  <div v-if="loadingMarkdownImages" class="d-flex justify-center pa-4">
      <v-progress-circular indeterminate color="primary" size="50" />
  </div>
  <div v-else>
    <md-preview v-if="disabled" ref="mdPreviewRef" :model-value="value" />
    <md-editor v-else ref="mdEditorRef" v-model="value" language="en-US" :toolbars="toolbars" no-upload-img >
      <template #defToolbars>
        <image-picker-toolbar :editor="mdEditorRef" :context-name="contextName" />
        <text-color-picker-toolbar :editor="mdEditorRef" />
        <text-color-picker-toolbar :editor="mdEditorRef" background />
      </template>
    </md-editor>
  </div>
</template>

<style lang="scss" scoped>
.md-editor-fullscreen {
  top: 128px;
}
</style>