import { inject, injectable, multiInject } from "inversify";
import {
  ActivityActionEvent,
  createActivityActionEvent,
  GanttEvents,
  IContextMenuItemActionContext, IGanttAction,
  IocSymbols,
  Layer,
  Lifecycle,
  TimeIntervalTreeActivityRepository
} from "@masta/gantt2/core";
import { ResourceCapacityActivity } from "@/components/Gantt/Common/Model";
import { BehaviorSubject, combineLatest, debounceTime, filter, finalize, first, lastValueFrom, map, merge, startWith, Subject, tap } from "rxjs";
import { LayerCanvas, ViewportRowsContainer } from "@masta/gantt2/gantt";
import ApiService from "@/services/api";
import { ActivityConnectorsLayer } from "@/components/Gantt/ResourcesGantt/Layers/ActivityConnectorsLayer";
import { GanttTaskConnection, SchedulingFinishedNotificationEvent } from "@masta/generated-model";

@injectable()
export class OpenProductCardAction extends Lifecycle implements IGanttAction {
  constructor(@inject(IocSymbols.LayersSymbol) private _layers$$: BehaviorSubject<Layer[]>,
              @inject(ViewportRowsContainer) private _viewportRowsContainer: ViewportRowsContainer,
              @inject(GanttEvents) private _ganttEvents: GanttEvents,
              @multiInject(IocSymbols.AboveRowContentSystemLayersSymbol) aboveRowLayers: LayerCanvas[] = []) {
    super();
  }

  public getTaskId(context: IContextMenuItemActionContext) {
    if (context.activityBounds.length < 1) {
      console.warn("invalid activity bounds count , should be 1 or more but was ", context.activityBounds.length);
      return;
    }
    const layers = this._layers$$;
    const bound = context.activityBounds[0];
    const activity: ResourceCapacityActivity | undefined = context.row.repository.getActivity(layers.value.find(l => l.id === bound.layerId)!, bound.activityId);
    if (!activity) {
      console.warn("no activity found");
      return;
    }
    return activity.userObject?.task.resourceId;
  }

  public async execute(context: IContextMenuItemActionContext) {
    return this.getTaskId(context);
  }

}
