import { IServerSideDatasource, IServerSideGetRowsParams } from "ag-grid-community";
import { Store } from "pinia";
import { GridState, GridStateActions, useGridStore } from "@/store/GridRegistryState";
import { AgGridRequestBase, IAgGridPaginationResponse } from "@masta/generated-model";
import { useErrorsStore } from "@/store/ErrorsStore";
import { CustomEvents, GridRowsLoadedEvent } from "@/components/Grid/Events/CustomEvents";

export abstract class ServerSideDataSource<TListEntity, TSingleEntity = TListEntity, TCreateEntity = TListEntity, TUpdateEntity = TListEntity, TIdType = string | undefined>
  implements IServerSideDatasource {
  public readonly gridStore: Store<string, GridState, any, GridStateActions>;
  private errorStore: Store<string, any, any, { handleError(error: any): void }>;

  protected constructor(id: string) {
    this.gridStore = useGridStore(id);
    this.errorStore = useErrorsStore();
  }

  getRows(params: IServerSideGetRowsParams): void {
    this.gridStore.loading = true;
    this.addFilterFromContext(params);
    this.getAll(params)
      .then((response: any) => {
        params.success({ rowData: response.results, rowCount: response.count });

        const event: GridRowsLoadedEvent = {
          type: CustomEvents.GRID_ROWS_LOADED,
          parentNode: params.parentNode
        };

        params.api.dispatchEvent(event);
      })
      .catch((err) => {
        this.errorStore.handleError(err);
        params.fail();
      })
      .finally(() => {
        this.gridStore.loading = false;
      });
  }

  protected constructServerRequest(params: IServerSideGetRowsParams, cql = ""): AgGridRequestBase {
    const { context } = params;
    let _cql = cql;
    if (_cql === "" && context && context.cqlFilter && typeof context.cqlFilter === "function") {
      const cqlFilter = context.cqlFilter();
      if (cqlFilter) {
        _cql = cqlFilter;
        console.debug(`ServerSideDataSource: setting cql filter from context: '${_cql}'`);
      }
    }
    // if (context && context.agGridFilterModel && typeof context.agGridFilterModel === "function") {
    //   const filterModel = context.agGridFilterModel();
    //   if (filterModel) {
    //     console.debug(`ServerSideDataSource: setting filter model from context: '${JSON.stringify(filterModel, null, 2)}'`);
    //     params.request.filterModel = { ...(params.request.filterModel ?? {}), ...filterModel };
    //   }
    // }
    return {
      startRow: params.request.startRow!,
      endRow: params.request.endRow!,
      cql: _cql,
      filterModel: params.request.filterModel as any,
      sortModel: params.request.sortModel as any
    };
  }

  protected addFilterFromContext(params: IServerSideGetRowsParams) {
    const { api, context } = params;
    const filterModelOperations = [];

    if (context && context.routeParams) {
      for (const { key, value } of context.routeParams) {
        filterModelOperations.push({
          field: key,
          filterType: "text",
          type: "equals",
          filter: value[0]
        });
      }
    }

    if (filterModelOperations.length === 1) {
      const filterModel: any = {};
      const op = filterModelOperations[0];
      filterModel[op.field] = {
        filterType: op.filterType,
        type: op.type,
        filter: op.filter
      };
      api.setFilterModel({ ...api.getFilterModel(), ...filterModel });
    }
  }

  abstract getAll(params: IServerSideGetRowsParams): Promise<IAgGridPaginationResponse<TListEntity>>;

  abstract getSingle(id: TIdType): Promise<TSingleEntity | undefined>;

  abstract create(entity: TListEntity): Promise<TIdType>;

  abstract update(entity: TListEntity): Promise<TIdType>;

  abstract remove(entity: TListEntity): Promise<void>;

  protected abstract transformForUpdate(listEntity: TListEntity): TUpdateEntity;

  protected abstract transformForCreate(listEntity: TListEntity): TCreateEntity;
}
