import { useAuthStore } from "@/store/AuthStore";
import { App } from "vue";
import { AuthRoles } from "@masta/generated-model";

export const checkValues = ({ existing, values, anyModifier }: any) => {
  if (existing.length === 0 && values.length === 0) return true;
  if (existing.length === 0 && values.length > 0) return false;
  // any
  if (anyModifier && values.some((r: any) => existing.includes(r))) {
    return true;
  }
  // all
  return values.every((r: any) => existing.includes(r));
};

export class AclDirective {
  user: any = null;
  permissions = [];
  roles = [];

  constructor() {
    this.user = useAuthStore().getUser;
    if (this.user) {
      this.permissions = this.user.permissions || [];
      this.roles = this.user.tenant_roles;
    }
  }

  handlePermissions(el: any, binding: any, vnode: any) {
    this.handle(el, binding, vnode, [...new Set(this.permissions)]);
  }

  handleRoles(el: any, binding: any, vnode: any) {
    this.handle(el, binding, vnode, [...new Set(this.roles)]);
  }

  handle(el: any, binding: any, vnode: any, rolesOrPermissions: any) {
    if (this.user === null) {
      this.commentVNode(el, binding, vnode);
      return;
    }

    const abilities = binding.arg;
    const args = binding.value;
    const modifiers = binding.modifiers;

    const anyModifier = modifiers.any;
    const notModifier = modifiers.not;
    const readonlyModifier = modifiers.readonly;
    const disableModifier = modifiers.disable || modifiers.disabled;
    // const hideModifier = modifiers.hide || modifiers.hidden;

    const valuesToCheck = this.getValuesToCheck({ abilities, args });

    let isAuthorized = checkValues({
      existing: rolesOrPermissions,
      values: [...new Set(valuesToCheck)],
      anyModifier
    });
    if (notModifier) {
      isAuthorized = !isAuthorized;
    }
    if (!isAuthorized) {
      if (disableModifier) {
        this.disableVNode(el);
      } else if (readonlyModifier) {
        this.readOnlyVNode(el);
      } else {
        this.commentVNode(el, binding, vnode);
      }
    }
  }

  getValuesToCheck({ abilities, args }: any) {
    let toCheck = [];
    if (abilities && typeof abilities === "string") {
      toCheck.push(abilities);
    }
    if (args && typeof args === "string") {
      toCheck.push(args);
    }
    if (args && Array.isArray(args)) {
      toCheck = [...toCheck, ...args];
    }
    return toCheck;
  }

  commentVNode(el: any, binding: any, vnode: any) {
    // replace HTMLElement with comment node
    const comment = document.createComment(" ");
    Object.defineProperty(comment, "setAttribute", {
      value: () => undefined
    });
    vnode.elm = comment;
    vnode.text = " ";
    vnode.isComment = true;
    vnode.context = undefined;
    vnode.tag = undefined;
    vnode.dirs = undefined;

    if (vnode.componentInstance) {
      vnode.componentInstance.$el = comment;
    }

    if (el.parentNode) {
      el.parentNode.replaceChild(comment, el);
    }
  }

  disableVNode(el: any) {
    el.disabled = true;
    el.ariaDisabled = true;
  }

  readOnlyVNode(el: any) {
    el.readOnly = true;
    el.ariaReadOnly = true;
    el.getElementsByTagName("input").forEach((x: any) => {
      x.readOnly = true;
      x.ariaReadOnly = true;
    });
  }
}

// eslint-disable-next-line no-unused-vars
export const useAcl = () => {
  const getValuesToCheck = (val: any) => {
    let toCheck = [];
    if (val && typeof val === "string") {
      toCheck.push(val);
    }
    if (val && Array.isArray(val)) {
      toCheck = [...toCheck, ...val];
    }
    return toCheck;
  };

  const check = (userProp: any, values: any, anyModifier: any) => {
    const user = useAuthStore().getUser;
    if (!user) return false;
    return checkValues({
      existing: [...new Set(user[userProp])],
      values: [...new Set(getValuesToCheck(values))],
      anyModifier
    });
  };

  return {
    roles: AuthRoles,
    hasPermission(permissions: any) {
      return check("permissions", permissions, false);
    },
    hasNotPermission(permissions: any) {
      return !check("permissions", permissions, false);
    },
    hasAnyPermission(permissions: any) {
      return check("permissions", permissions, true);
    },
    hasRole(roles: any) {
      return check("tenant_roles", roles, false);
    },
    hasNotRole(roles: any) {
      return !check("tenant_roles", roles, false);
    },
    hasAnyRole(roles: any) {
      return check("tenant_roles", roles, true);
    }
  };
};

export const AclPlugin = {
  install(app: App) {
    app.config.globalProperties.$acl = useAcl();
    app.provide("$acl", app.config.globalProperties.$acl);
    // app.directive("has-permission", (el, binding, vnode) => {
    //   return new AclDirective().handlePermissions(el, binding, vnode);
    // });
    app.directive("has-role", (el, binding, vnode) => {
      return new AclDirective().handleRoles(el, binding, vnode);
    });
  }
};
