<script lang="ts" setup>
import { ref } from "vue";
import GridWrapper from "@/components/Grid/GridWrapper.vue";
import { GridWrapperComponent } from "@/components/Grid/GridWrapperComponent";
import { $t } from "@/i18n";
import { GridReadyEvent, SelectionChangedEvent, ValueFormatterParams, ValueGetterParams } from "ag-grid-community";
import CloseCardButton from "@/components/CloseCardButton.vue";
import { getSelectedRows } from "@/components/Grid/UseGridSelection";


export interface DeleteKpiItem {
  id: string;
  type: "calculation" | "scenario";
}

const props = defineProps<{
  modelValue: boolean;
  values: DeleteKpiItem[]
}>();
const emit = defineEmits<{
  (e: "update:modelValue", value: boolean): void;
  (e: "result", values: DeleteKpiItem[]): void;
}>();

const gridWrapperRef = ref<GridWrapperComponent>();
const defaultColumnDef = ref({
  sortable: true,
  resizable: true,
  floatingFilter: true,
  filterParams: {
    applyMiniFilterWhileTyping: true
  }
});

function onSelectionChanged({ api }: SelectionChangedEvent<DeleteKpiItem>) {
  selectedItems.value = getSelectedRows(api);
}

function onPrepareColumns(columnDefs: any) {
  columnDefs.value = [
    {
      field: "id",
      editable: false,
      sortable: true,
      filter: "agTextColumnFilter",
      headerValueGetter: (_: any) => $t("kpi-delete-list-id-label", { $: "Identifier" }),
      type: ["textInputTypeColumn", "textFloatingFilterColumnType"],
      floatingFilterComponentParams: {
        placeholder: $t("kpi-delete-list-id-label", { $: "Identifier" })
      }
    },
    {
      field: "type",
      editable: false,
      sortable: true,
      filter: "agTextColumnFilter",
      headerValueGetter: (_: any) => $t("kpi-delete-list-type-label", { $: "Type" }),
      valueGetter: (params: ValueGetterParams<DeleteKpiItem>) => params.data?.type === "calculation" ? $t("kpi-delete-list-type-calculation", { $: "Calculation" }) : $t("kpi-delete-list-type-scenario", { $: "Scenario" }),
      valueFormatter: (params: ValueFormatterParams<DeleteKpiItem>) => params.data?.type === "calculation" ? $t("kpi-delete-list-type-calculation", { $: "Calculation" }) : $t("kpi-delete-list-type-scenario", { $: "Scenario" }),
      type: ["textInputTypeColumn", "textFloatingFilterColumnType"],
      floatingFilterComponentParams: {
        placeholder: $t("kpi-delete-list-type-label", { $: "Type" })
      }
    }
  ];
}

const selectedItems = ref<DeleteKpiItem[]>([]);

function cancel() {
  selectedItems.value = [];

  emit("update:modelValue", false);
}

function deleteKpi() {
  const selectedItemsValue = selectedItems.value;
  selectedItems.value = [];

  emit("result", selectedItemsValue);
  emit("update:modelValue", false);
}
</script>

<template>
  <v-dialog :model-value="modelValue" width="50%" height="90vh" @update:model-value="value => emit('update:modelValue', value)">
    <v-card min-width="300px" height="100%" class="reference-picker-card">
      <close-card-button @click="cancel"></close-card-button>
      <v-card-title class="pa-0">
        <div class="text-h4 pb-5">{{ $t("kpiResults-deleteDialog-title", { $: "Delete KPI" }) }}</div>
      </v-card-title>
      <v-card-subtitle>
        <div class="text-subtitle-1">
          {{ $t("kpiResults-deleteDialog-subtitle", { $: "Select scenario or calculation from the list to delete" }) }}
        </div>
      </v-card-subtitle>
      <v-card-text class="px-0">
        <grid-wrapper
          ref="gridWrapperRef"
          :grid-data="values"
          :default-col-def="defaultColumnDef"
          row-selection="multiple"
          identifier="kpi-for-deletion"
          @prepare-columns="onPrepareColumns"
          @selection-changed="onSelectionChanged"
        >
        </grid-wrapper>
      </v-card-text>
      <v-card-actions>
        <v-row>
          <v-col cols="6">
            <v-btn class="ma-4" variant="elevated" color="secondary" block @click="cancel">
              {{ $t("kpiResults-deleteDialog-action-cancel", { $: "Cancel" }) }}
            </v-btn>
          </v-col>
          <v-col cols="6">
            <v-btn class="ma-4" variant="elevated" :color="selectedItems.length > 0 ? 'primary' : 'grey'" block :disabled="selectedItems.length === 0" @click="deleteKpi">
              {{ $t("kpiResults-deleteDialog-action-delete", { $: "Delete" }) }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style lang="scss">
</style>
