import { defineStore } from "pinia";
import { useErrorsStore } from "@/store/ErrorsStore";
import { ExecutionIssueDto, WorkJournalRecordDto } from "@masta/generated-model";
import ApiService from "@/services/api";

interface State {
  issues: ExecutionIssueDto[];
}

export const useExecutionIssuesStore = defineStore("execution-issues", {
  state: (): State => ({
    issues: []
  }),
  actions: {
    async fetchIssues() {
      try {
        const response = await ApiService.executionIssues.getIssues();
        this.issues = response.data.issues ?? [];
      } catch (e) {
        const errorsStore = useErrorsStore();
        errorsStore.handleError(e);
        throw e;
      }
    },
    async sendRecords(records: WorkJournalRecordDto[]) {
      for (const record of records) {
        await ApiService.workJournalRecords.createWorkJournalRecord(record);
      }
    }
  }
});
