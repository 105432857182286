import { DayOfWeek, Instant, TemporalUnit, ZoneId } from "@js-joda/core";

import { GanttException } from "../../Exceptions";

export enum Position {
  TOP,
  MIDDLE,
  BOTTOM,
  ONLY
}

export abstract class Resolution<TUnit extends TemporalUnit> {
  private _supportedPositions = new Set<Position>();

  private _temporalUnit: TUnit;

  private _step: number;

  private _format: string;

  constructor(temporalUnit: TUnit, format: string, step: number, ...supportedPositions: Position[]) {
    this._temporalUnit = temporalUnit;
    this._format = format;
    if (step <= 0) throw new GanttException(`step is ${step} but must be larger than 0`);
    this._step = step;
    if (supportedPositions && Array.isArray(supportedPositions) && supportedPositions.length > 0) {
      supportedPositions.forEach((p) => this._supportedPositions.add(p));
    } else {
      throw new GanttException("at least one position must be provided for resolution");
    }
  }

  public isSupportingPosition(position: Position): boolean {
    return this._supportedPositions.has(position);
  }

  get temporalUnit(): TUnit {
    return this._temporalUnit;
  }

  get step(): number {
    return this._step;
  }

  get format(): string {
    return this._format;
  }

  get supportedPositions(): Set<Position> {
    return this._supportedPositions;
  }

  public abstract formatInstant(instant: Instant, zoneId: ZoneId): string;

  public abstract truncateInstant(instant: Instant, zoneId: ZoneId, dayOfWeek: DayOfWeek): Instant;

  public abstract increment(instant: Instant, zoneId: ZoneId): Instant;

  public abstract decrement(instant: Instant, zoneId: ZoneId): Instant;

  public toString() {
    // eslint-disable-next-line max-len
    return `Resolution [supportedPositions=${[...this._supportedPositions]}, temporalUnit=${this._temporalUnit}, format=${this._format}, step=${this._step}]`;
  }
}
