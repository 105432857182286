import ApiService from "@/services/api";
import { ServerSideDataSource } from "@/components/Grid/ServerSideDataSource";
import { CreateModelInstanceCommand, IAgGridPaginationResponse, ModelInstanceDto, UpdateModelInstanceCommand } from "@masta/generated-model";
import { IServerSideGetRowsParams } from "ag-grid-community";
import { getPartsFromSchemaId } from "@/store/ModelInstancesStore";

type CreateModelInstanceCommandType = CreateModelInstanceCommand;
type UpdateModelInstanceCommandType = UpdateModelInstanceCommand;

export class ModelInstancesServerSideDataSource extends ServerSideDataSource<
  ModelInstanceDto,
  ModelInstanceDto,
  CreateModelInstanceCommandType,
  UpdateModelInstanceCommandType,
  string
> {
  private _filterBySchemaId: string | undefined;
  private _filterBySchemaVersion: string | undefined;

  constructor(id: string, schemaId: string | undefined, schemaVersion: string | undefined) {
    super(id);
    this._filterBySchemaId = schemaId;
    this._filterBySchemaVersion = schemaVersion;
  }

  public useFilteringBySchema(schemaId: string | undefined, schemaVersion: string | undefined) {
    this._filterBySchemaId = schemaId;
    this._filterBySchemaVersion = schemaVersion;
  }

  async create(entity: ModelInstanceDto): Promise<string> {
    const request = this.transformForCreate(entity);
    const { data } = await ApiService.modelInstances.createInstance(request);
    return data;
  }

  async getAll(params: IServerSideGetRowsParams): Promise<IAgGridPaginationResponse<ModelInstanceDto>> {
    const serverRequest = this.constructServerRequest(params);

    if (!this._filterBySchemaId || !this._filterBySchemaVersion) {
      return { results: [], count: 0 };
    }

    const { data } = await ApiService.modelInstances.getModelInstancesPaginated({
      ...serverRequest,
      schemaId: this._filterBySchemaId,
      schemaVersion: this._filterBySchemaVersion
    });

    return data;
  }

  async getSingle(id: string): Promise<ModelInstanceDto | undefined> {
    // const result = await ApiService.modelInstances.getModelInstanceByNameOrId(id);
    // return result?.data;
    throw new Error("Not implemented");
  }

  async update(entity: ModelInstanceDto): Promise<string> {
    const request = this.transformForUpdate(entity);
    const { data } = await ApiService.modelInstances.updateInstance(request);
    return data;
  }

  async remove(entity: ModelInstanceDto): Promise<void> {
    await ApiService.modelInstances.deleteInstance(entity.id!, entity.revisionNumber!);
  }

  protected transformForCreate(listEntity: ModelInstanceDto): CreateModelInstanceCommandType {
    if (!this._filterBySchemaId || !this._filterBySchemaVersion) {
      throw new Error("SchemaId and SchemaVersion must be set before creating a model instance");
    }
    const { key, name } = getPartsFromSchemaId(this._filterBySchemaId);
    return {
      schemaName: name,
      schemaKey: key,
      schemaVersion: this._filterBySchemaVersion,
      businessId: listEntity.businessId,
      value: listEntity.value,
      tags: listEntity.tags,
      schemaTypes: listEntity.schemaTypes
    } as CreateModelInstanceCommand;
  }

  protected transformForUpdate(listEntity: ModelInstanceDto): UpdateModelInstanceCommandType {
    return {
      id: listEntity.id,
      revisionNumber: listEntity.revisionNumber,
      businessId: listEntity.businessId,
      value: listEntity.value,
      tags: listEntity.tags,
      schemaTypes: listEntity.schemaTypes
    } as UpdateModelInstanceCommand;
  }
}
