import { inject } from "inversify";
import { GanttEvents, GanttSettings, GraphicsMousePositionEvent, IocContainer, IocSymbols, provideTransient, SettingKey, TimelineManager, TooltipActivity } from "../../../../Core";
import { GanttDomElement } from "../../../GanttDomElement";
import { ActivityTooltipRenderer } from "../Activities";
import { delay, filter, withLatestFrom } from "rxjs";

@provideTransient(ActivityTooltipLayer)
export class ActivityTooltipLayer extends GanttDomElement<HTMLDivElement> {
  private _openDelay = 500;
  private _disabled = false;

  constructor(
    @inject(TimelineManager) private _timelineManager: TimelineManager,
    @inject(GanttEvents) private _ganttEvents: GanttEvents,
    @inject(IocContainer) private _iocContainer: IocContainer,
    @inject(GanttSettings) private _ganttSettings: GanttSettings
  ) {
    super(ActivityTooltipLayer.name, undefined, "activity-tooltip-layer");
  }

  async beforeInitialize(): Promise<void> {
    return super.beforeInitialize();
  }

  async afterInitialize(): Promise<void> {
    await super.afterInitialize();
    this.subscribe(this._ganttSettings.getSetting$<number>(SettingKey.TOOLTIP_OPEN_DELAY).subscribe((v) => (this._openDelay = v ?? 500)));
    this.subscribe(this._ganttSettings.getSetting$<boolean>(SettingKey.TOOLTIP_DISABLED).subscribe((v) => (this._disabled = v ?? false)));
    this.subscribe(
      this._ganttEvents.tooltipActivityEvent$
        .pipe(filter(event => event !== null), delay(this._openDelay), withLatestFrom(this._ganttEvents.mousePositionEvent$, this._ganttEvents.rowHoverEvent$))
        .subscribe(([activity, mousePositionEvent, rowHoverEvent]) => {
          if (activity && this.isInBounds(mousePositionEvent.x, mousePositionEvent.y, activity, rowHoverEvent?.offsetTop ?? 0) && !this._disabled) {
            this.showTooltip(activity, mousePositionEvent.x, mousePositionEvent.y);
          }
        })
    );
    this.subscribe(
      this._ganttEvents.tooltipActivityEvent$
        .pipe(filter(event => !event))
        .subscribe(() => {
          this.hideTooltip();
        })
    );
    this.subscribe(
      this._ganttEvents.mousePositionEvent$
        .pipe(
          withLatestFrom(this._ganttEvents.tooltipActivityEvent$),
          filter(([_, a]) => a !== null)
        )
        .subscribe(([mousePositionEvent, activity]) => {
          if (activity) {
            this.moveTooltips(mousePositionEvent, activity);
          }
        })
    );
  }

  private async showTooltip(activity: TooltipActivity, x: any, y: any) {
    await this.hideTooltip();
    let renderer = this.getActivityTooltipRenderers(activity.activityType);
    if (!renderer) {
      renderer = this.getActivityTooltipRenderers("default");
    }
    if (renderer) {
      await this.addChild(renderer);
      this.appendChild(renderer);
      renderer.show(activity, x, y);
    }
  }

  private async hideTooltip() {
    await this.removeChildren(this.children);
  }

  private getActivityTooltipRenderers<TRenderer extends ActivityTooltipRenderer>(name: string): TRenderer | null {
    try {
      return this._iocContainer.getTagged<TRenderer>(IocSymbols.ActivityTooltipRenderer, IocSymbols.ActivityTooltipRendererTag, name);
    } catch {
      return null;
    }
  }

  private moveTooltips(e: GraphicsMousePositionEvent, activity: TooltipActivity) {
    const renderers = this.children as ActivityTooltipRenderer[];
    const renderer = renderers.find((x) => x.activity.activity.id === activity.activity.id);
    if (renderer && renderer.visible) {
      renderer.move(e.x, e.y);
    }
  }

  private isInBounds(x: number, y: number, activity: TooltipActivity, offsetTop: number): boolean {
    return x >= activity.x && x <= activity.x + activity.w && y >= activity.y && y <= activity.y + activity.h + offsetTop;
  }
}
