<script setup lang="ts">
import { ref, watch } from "vue";
import { GetMaterialStockQuery, ResourceInfoDto, ResourceType } from "@masta/generated-model";
import { useScenariosStore } from "@/store/ScenariosStore";
import { $t } from "@/i18n";
import ResourcesPicker from "@/components/Resources/ResourcesPicker.vue";

const props = defineProps<{
  modelValue: boolean;
}>();
const emit = defineEmits<{
  (e: "update:modelValue", value: boolean): void;
  (e: "calculationParamsSet", query: GetMaterialStockQuery): void;
}>();

const materials = ref<ResourceInfoDto[]>([]);
const locations = ref<ResourceInfoDto[]>([]);

watch(
  () => props.modelValue,
  (newVal) => {
    if (!newVal) {
      resetDialog();
    }
  }
);

function resetDialog() {
}

function cancel() {
  emit("update:modelValue", false);
}

function calculate() {
  const scenariosStore = useScenariosStore();
  const query: GetMaterialStockQuery = {
    materialIds: materials.value.map((x) => x.id),
    locationIds: locations.value.map((x) => x.id),
    scenarioId: scenariosStore.selectedScenario?.id || ""
  };
  emit("calculationParamsSet", query);
}
</script>

<template>
  <v-dialog :model-value="modelValue" width="50%" @update:model-value="(value) => emit('update:modelValue', value)">
    <v-card class="pa-8 calculate-card">
      <v-card-title class="d-flex align-center justify-space-between">
        <div class="text-h4">{{ $t("materialStock-parametersDialog-title-label", { $: "Material Stock" }) }}</div>
        <v-icon @click="cancel">mdi-close</v-icon>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col>
              <ResourcesPicker v-model="materials"
                :label="$t('materialsDemand-parametersDialog-materials-label', { $: 'Materials' })"
                :resource-type="ResourceType.Material" :readonly="false" />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <ResourcesPicker v-model="locations"
                :label="$t('materialsDemand-parametersDialog-locations-label', { $: 'Locations' })"
                :resource-type="ResourceType.Location" :readonly="false" />
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-row>
          <v-col cols="6">
            <v-btn class="ma-4" variant="elevated" color="secondary" block @click="cancel">
              {{ $t("materialStock-parametersDialog-cancel-label", { $: "Cancel" }) }}
            </v-btn>
          </v-col>
          <v-col cols="6">
            <v-btn class="ma-4" variant="elevated" color="primary" block @click="calculate">
              {{ $t("materialStock-parametersDialog-getStock-label", { $: "Get Stock" }) }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style scoped lang="scss">
.calculate-card {
  overflow: initial !important;
  z-index: initial !important;
}
</style>
