<script lang="ts" setup>
import GridWrapper from "@/components/Grid/GridWrapper.vue";
import { inject, reactive, ref, watch } from "vue";
import { GridWrapperComponent } from "@/components/Grid/GridWrapperComponent";
import { $dateTimeFormatterSymbol, DateFormatter } from "@masta/shared";
import ApiService from "@/services/api";
import { GetRowIdParams, GridApi } from "ag-grid-community";
import { useSnackbarsStore } from "@/store/SnackbarsStore";
import { useScenariosStore } from "@/store/ScenariosStore";
import {
  ArchiveModelInstanceCommand,
  AssignModelInstanceToCustomerOrderCommand,
  CalculateCostsForOrderCommand,
  CostMonitoringDocumentType,
  CreateNewDocumentRevisionCommand,
  DocumentDto,
  MarkConfirmedModelInstanceCommand,
  MarkSentModelInstanceCommand,
  OrderDto,
  OrderStatusChangeNotificationEvent,
  QuotationDocumentGeneratedNotificationEvent,
  ReleaseModelInstanceCommand
} from "@masta/generated-model";
import { $t } from "@/i18n";
import { translateModelInstanceStatus } from "@/composables/translateEnum";
import { OrderStatusChangedNotification, QuotationDocumentGeneratedNotification, useNotification } from "@/notifications";
import { useDebounceFn } from "@vueuse/core/index";
import { useRouter } from "vue-router";
import { getModelInstanceStatusCellStyle } from "@/components/Tasks/TaskUtils";
import { getSelectedRows } from "@/components/Grid/UseGridSelection";
import { CostControllingDocumentsServerSideDataSource } from "@/components/CostControllingDocuments/CostControllingDocumentsServerSideDataSource";
import ActionsButton from "@/components/Layout/ActionsButton.vue";

interface Props {
  readonly?: boolean;
  selectedOrder: OrderDto;
}

const router = useRouter();
const props = withDefaults(defineProps<Props>(), {});

const snackbarsStore = useSnackbarsStore();
const scenariosStore = useScenariosStore();

watch(props.selectedOrder, () => {
  serverSideDataSource.useFilteringByOrderId(props.selectedOrder.id);
  gridWrapperRef.value?.gridApi?.refreshServerSide();
});
const serverSideDataSource = reactive(new CostControllingDocumentsServerSideDataSource("costControllingDocuments", "costControllingDocuments"));
serverSideDataSource.useFilteringByOrderId(props.selectedOrder.id);

useNotification(OrderStatusChangedNotification, async (e: OrderStatusChangeNotificationEvent) => {
  onOrderStatusChanged(e);
});

const onOrderStatusChanged: (e: OrderStatusChangeNotificationEvent) => void = useDebounceFn(async (e: OrderStatusChangeNotificationEvent) => {
  if (e.orderId === props.selectedOrder.id) {
    gridWrapperRef.value?.gridApi?.refreshServerSide();
  }
}, 800);

const isRowSelected = ref(false);
const ukryjGoProsze = ref(true); //jest mi smutno bo zmienna niniejsza juz nie jest uzywana ale zostawiam ja zeby nie bylo mi az tak smutno

function getSelectedRowData(): any {
  return getSelectedRows(gridWrapperRef.value?.gridApi)[0];
}

function onSelectionChanged({ api }: { api: GridApi }) {
  isRowSelected.value = getSelectedRows(api).length > 0;
}

async function onRowDoubleClicked({ api }: { api: GridApi }) {
  if (gridWrapperRef.value?.isEditing()) return;
  await handleExistingDocument();
}

async function onReleaseModelInstance() {
  await ApiService.documents.release({ id: getSelectedRowData()?.id, revisionNumber: getSelectedRowData()?.revisionNumber } as ReleaseModelInstanceCommand);
}

async function onArchiveModelInstance() {
  await ApiService.documents.archive({ id: getSelectedRowData()?.id, revisionNumber: getSelectedRowData()?.revisionNumber } as ArchiveModelInstanceCommand);
  gridWrapperRef.value?.gridApi?.refreshServerSide();
}

async function onCreateNewRevisionOfModelInstance() {
  const row = getSelectedRowData();
  await ApiService.documents.createNewRevision({ id: row?.id } as CreateNewDocumentRevisionCommand);
  await ApiService.customerOrders.updateModelInstanceAssignment({
    orderId: props.selectedOrder.id,
    modelInstanceId: row?.id,
    scenarioId: row?.scenarioId
  } as AssignModelInstanceToCustomerOrderCommand);
  gridWrapperRef.value?.gridApi?.refreshServerSide();
}

const gridWrapperRef = ref<GridWrapperComponent>();
const $dateTimeFormatter = inject<DateFormatter>($dateTimeFormatterSymbol)!;

function resolveNextPosition(): number {
  const nextRowOrdinal = gridWrapperRef.value ? gridWrapperRef.value.gridApi.getDisplayedRowCount() + 1 : 1;
  return nextRowOrdinal * 10;
}

function getRowId(params: GetRowIdParams<DocumentDto>) {
  return `${params.data.id}-${params.data.revisionNumber}`;
}

function onPrepareColumns(columnDefs: any) {
  columnDefs.value = [
    {
      field: "businessId",
      editable: false,
      sortable: true,
      resizable: true,
      filter: "agTextColumnFilter",
      headerValueGetter: (_: any) => $t("costControlling-list-businessId-label", { $: "Business Id" })
    },
    {
      field: "value.attachment.fileName",
      editable: false,
      sortable: true,
      resizable: true,
      filter: "agTextColumnFilter",
      headerValueGetter: (_: any) => $t("costControlling-list-fileName-label", { $: "Filename" })
    },
    {
      field: "revisionNumber",
      editable: false,
      sortable: true,
      resizable: true,
      filter: "agTextColumnFilter",
      headerValueGetter: (_: any) => $t("costControlling-list-revisionNumber-label", { $: "Revision" })
    },
    {
      field: "createdBy",
      editable: false,
      resizable: true,
      headerValueGetter: (_: any) => $t("costControlling-list-createdBy-label", { $: "Created By" })
    },
    {
      field: "createdAt",
      type: ["dateTimeTypeColumn"],
      editable: false,
      resizable: true,
      valueFormatter: (params: any) => {
        return $dateTimeFormatter(params.data.createdAt);
      },
      headerValueGetter: (_: any) => $t("costControlling-list-createdAt-label", { $: "Created At" })
    },
    {
      field: "modifiedBy",
      editable: false,
      resizable: true,
      headerValueGetter: (_: any) => $t("costControlling-list-modifiedBy-label", { $: "Modified By" })
    },
    {
      field: "modifiedAt",
      type: ["dateTimeTypeColumn"],
      editable: false,
      resizable: true,
      valueFormatter: (params: any) => {
        return $dateTimeFormatter(params.data.modifiedAt);
      },
      headerValueGetter: (_: any) => $t("costControlling-list-modifiedAt-label", { $: "Modified At" })
    },
    {
      field: "status",
      editable: false,
      sortable: true,
      resizable: true,
      valueFormatter: (params: any) => translateModelInstanceStatus(params.value),
      cellStyle: (params: any) => getModelInstanceStatusCellStyle(params.value),
      headerValueGetter: (_: any) => $t("costControlling-list-status-label", { $: "Status" })
    }
  ];
}

watch(props, (newProps) => {
  serverSideDataSource.useFilteringByOrderId(newProps.selectedOrder.id);
  gridWrapperRef.value?.gridApi.refreshServerSide();
});

async function handleExistingDocument() {
  const selectedRows = getSelectedRows(gridWrapperRef.value?.gridApi);
  if (selectedRows.length === 0) {
    return;
  }
  if (selectedRows[0].businessId.endsWith(".xlsx")) {
    await downloadCostControllingDocument(selectedRows[0].id, selectedRows[0].revisionNumber);
  } else {
    await navigateToDocumentEdition();
  }
}

async function navigateToDocumentEdition() {
  const selectedRows = getSelectedRows(gridWrapperRef.value?.gridApi);
  const query = { documentId: selectedRows[0].id, revisionNumber: selectedRows[0].revisionNumber };
  await router.push({ path: "../operations/cost-controlling-document", query, params: { scenarioId: useScenariosStore().scenarioBusinessId } });
}

async function navigateToNewDocumentEdition() {
  if (props.selectedOrder) {
    const query = { orderId: props.selectedOrder.id };
    await router.push({ path: "../operations/cost-controlling-document", query, params: { scenarioId: useScenariosStore().scenarioBusinessId } });
  }
}

async function downloadCostControllingDocument(documentId: string, revisionNumber: number) {
  try {
    const singleDocument = await ApiService.costControllingDocuments.getSingle(documentId, revisionNumber);
    const { data } = await ApiService.documents.getDocumentContentStream({
      documentId: documentId,
      revisionNumber: revisionNumber
    });
    if (singleDocument && data) {
      const urlOfXlsx = window.URL.createObjectURL(data);
      const a = document.createElement("a");
      a.href = urlOfXlsx!;
      a.download = singleDocument.data.value.attachment.fileName;
      a.target = "_blank";
      a.click();
    }
  } catch (e) {
    console.error(e);
  }
}

async function calculateCostsAndGenerateExcelCalculation() {
  if (props.selectedOrder) {
    const request = {
      orderId: props.selectedOrder.id,
      scenarioId: scenariosStore.selectedScenario?.id,
      documentType: CostMonitoringDocumentType.CostControl
    } as CalculateCostsForOrderCommand;

    try {
      //calculateCosts generates snapshot of costs as well as generates excel
      await ApiService.quotations.calculateCosts(request);
      snackbarsStore.createSnackbar({
        message: $t("costControlling-list-onCalculateSuccess-message", { $: "Order has been sent to costs calculation. Please check back later" }),
        type: "success",
        closeable: true
      });
    } catch (e) {
      console.error(e);
      snackbarsStore.createSnackbar({
        message: $t("costControlling-list-onCalculateError-message", { $: "Could not calculate costs of order" }),
        type: "error",
        closeable: true
      });
    }
  }
}

// async function generateCostMonitoringExport() {
//   if (!props.selectedOrder || !scenariosStore.selectedScenario) {
//     return;
//   }
//   const orderIds = [props.selectedOrder.id];
//   try {
//     const request = { orderIds } as RegisterCostMonitoringExcelExportCommand;
//     await ApiService.productCostAccounting.generateCostMonitoringExport(request);
//     await snackbarsStore.createSnackbar({
//       message: $t("order-list-generateCostMonitoringExportSuccess-message", { $: "Cost monitoring export file is being created! An email with download link will be sent to you" }),
//       type: "success",
//       closeable: true
//     });
//   } catch (e) {
//     console.error(e);
//     await snackbarsStore.createSnackbar({
//       message: $t("order-list-generateCostMonitoringExportError-message", { $: "Could not create cost monitoring export file" }),
//       type: "error",
//       closeable: true
//     });
//   }
// }

async function markDocumentAsSent() {
  if (!props.selectedOrder) {
    return;
  }

  try {
    const selectedDocument = getSelectedRowData();
    if (selectedDocument) {
      await ApiService.documents.markSent({ id: selectedDocument.id, revisionNumber: selectedDocument.revisionNumber } as MarkSentModelInstanceCommand);
      gridWrapperRef.value?.gridApi?.refreshServerSide();
    }

    snackbarsStore.createSnackbar({
      message: $t("costControlling-list-onCostControllingSent-message", { $: "Document has been given a 'sent' status" }),
      type: "success",
      closeable: true
    });
  } catch (e) {
    console.error(e);
    snackbarsStore.createSnackbar({
      message: $t("costControlling-list-onCostControllingSentError-message", { $: "Could not mark document as 'sent'" }),
      type: "error",
      closeable: true
    });
  }
}

async function markDocumentAsConfirmed() {
  if (!props.selectedOrder) {
    return;
  }

  try {
    const selectedDocument = getSelectedRowData();
    if (selectedDocument) {
      await ApiService.documents.markConfirmed({ id: selectedDocument.id, revisionNumber: selectedDocument.revisionNumber } as MarkConfirmedModelInstanceCommand);
      gridWrapperRef.value?.gridApi?.refreshServerSide();
    }
    snackbarsStore.createSnackbar({
      message: $t("costControlling-list-onCostControllingConfirm-message", { $: "Document has been given a 'confirmed' status" }),
      type: "success",
      closeable: true
    });
  } catch (e) {
    console.error(e);
    snackbarsStore.createSnackbar({
      message: $t("costControlling-list-onCostControllingConfirmError-message", { $: "Could not mark document as 'confirmed'" }),
      type: "error",
      closeable: true
    });
  }
}

useNotification(QuotationDocumentGeneratedNotification, (e: QuotationDocumentGeneratedNotificationEvent) => {
  onQuotationDocumentChanged(e);
});

const onQuotationDocumentChanged: (e: QuotationDocumentGeneratedNotificationEvent) => void = useDebounceFn(async (e: QuotationDocumentGeneratedNotificationEvent) => {
  gridWrapperRef?.value?.gridApi.refreshServerSide();
}, 500);

const detailActions = ref([
  {
    title: $t("costControlling-list-calculateAndGenerate-action", { $: "1. Generate New" }),
    tooltip: $t("costControlling-list-calculateAndGenerate-tooltip", { $: "Calculate costs and generate new spreadsheet" }),
    action: calculateCostsAndGenerateExcelCalculation,
    icon: "mdi-calculator",
    order: 1
  },
  {
    title: $t("costControlling-list-createNewVersion-action", { $: "2. Create New Version" }),
    tooltip: $t("costControlling-list-createNewVersion-tooltip", { $: "Create New Version of calculation spreadsheet" }),
    action: onCreateNewRevisionOfModelInstance,
    icon: "mdi-ab-testing",
    disabled: () => !isRowSelected.value,
    order: 2
  },
  {
    title: $t("costControlling-list-markSent-action", { $: "3. Mark As Sent" }),
    tooltip: $t("costControlling-list-markSent-tooltip", { $: "Mark spreadsheet as 'sent' - this is just an indication/status for user's information" }),
    action: markDocumentAsSent,
    icon: "mdi-email-fast",
    disabled: () => !isRowSelected.value,
    order: 3
  },
  {
    title: $t("costControlling-list-markConfirmed-action", { $: "4. Mark As Confirmed" }),
    tooltip: $t("costControlling-list-markConfirmed-tooltip", { $: "Mark spreadsheet as 'confirmed' - this is just an indication/status for user's information" }),
    action: markDocumentAsConfirmed,
    icon: "mdi-check-circle",
    disabled: () => !isRowSelected.value,
    order: 4
  },
  {
    title: $t("costControlling-list-archive-action", { $: "5. Archive" }),
    tooltip: $t("costControlling-list-archive-tooltip", { $: "Mark spreadsheet as 'archived'" }),
    action: onArchiveModelInstance,
    icon: "mdi-archive-arrow-down-outline",
    disabled: () => !isRowSelected.value,
    order: 5
  }
]);
</script>

<template>
  <grid-wrapper
    ref="gridWrapperRef"
    identifier="costControlling"
    row-selection="single"
    :server-side="true"
    :server-side-datasource="serverSideDataSource"
    :server-side-infinite-scroll="false"
    :get-row-id="getRowId"
    :pagination="false"
    refresh-btn
    :delete-btn="!readonly"
    :hide-custom-actions-separator="readonly"
    @prepare-columns="onPrepareColumns"
    @selection-changed="onSelectionChanged"
    @row-double-clicked="onRowDoubleClicked"
  >
    <template #custom-buttons>
      <actions-button :model-value="detailActions" />

      <v-tooltip v-if="!readonly" bottom open-delay="300">
        <template #activator="{ props }">
          <v-btn :disabled="!isRowSelected" size="small" density="compact" variant="text" v-bind="props" @click="handleExistingDocument">
            <v-icon class="pr-4" icon="mdi-download-circle" />
            {{ $t("costControlling-list-download-action", { $: "Download Calculation Spreadsheet" }) }}
          </v-btn>
        </template>
        <span>{{ $t("costControlling-list-download-tooltip", { $: "Download Selected Calculation Spreadsheet" }) }}</span>
      </v-tooltip>
    </template>
  </grid-wrapper>
</template>

<style lang="scss" scoped></style>
