<script setup lang="ts">
import { inject, ref, watch } from "vue";
import { $dateFormatterSymbol, DateFormatter } from "@masta/shared";
import { useI18n } from "vue-i18n";
import { CreatePurchaseOrderFromPredictionsCommand, InternalCustomerOrderPredictionDto } from "@masta/generated-model";
import { useScenariosStore } from "@/store/ScenariosStore";
import ApiService from "@/services/api";
import { CreateSnackbarCommand, useSnackbarsStore } from "@/store/SnackbarsStore";
import { $t } from "@/i18n";
import InternalCustomerOrdersPredictionGrid from "@/components/MaterialsDemand/InternalCustomerOrdersPredictionGrid.vue";

const $dateFormatter = inject<DateFormatter>($dateFormatterSymbol)!;

const { locale } = useI18n();
const scenariosStore = useScenariosStore();
const snackbarStore = useSnackbarsStore();

const internalCustomerOrdersGrid = ref<InstanceType<typeof InternalCustomerOrdersPredictionGrid> | null>(null);

const props = defineProps<{
  modelValue: boolean;
  internalCustomerOrderPredictionDtos: InternalCustomerOrderPredictionDto[]
}>();
const emit = defineEmits<{
  (e: "update:modelValue", value: boolean): void;
  (e: "internalCustomerOrdersCreated"): void;
}>();

watch(
  () => props.modelValue,
  (newVal) => {
    if (!newVal) {
      resetDialog();
    }
  }
);

function resetDialog() {
}

function cancel() {
  emit("update:modelValue", false);
}

async function createInternalCustomerOrders() {
  try {
    await ApiService.customerOrders.createFromInternalCustomerOrderPredictions({
      scenarioId: scenariosStore.selectedScenario?.id,
      predictions: internalCustomerOrdersGrid.value?.gridWrapperRef?.gridApi.getSelectedRows()
    } as CreatePurchaseOrderFromPredictionsCommand);
    emit("internalCustomerOrdersCreated");
    emit("update:modelValue", false);
    snackbarStore.createSnackbar({
      message: $t("internalCustomerOrdersPredictions-dialog-createdInternalOrders-popup", { $: "Internal customer orders based on predictions created" }),
      type: "info"
    } as CreateSnackbarCommand);
  } catch (e: any) {
    snackbarStore.createSnackbar({
      message: e && e.detail ? e.detail : $t("internalCustomerOrdersPredictions-dialog-createdInternalCustomerOrders-error", { $: "Could not create internal customer orders" }),
      type: "error",
      closeable: true
    });
  }
}

</script>

<template>
  <v-dialog :model-value="modelValue" max-width="90%" @update:model-value="(value) => emit('update:modelValue', value)">
    <v-card class="pa-8">
      <v-card-title class="d-flex align-center justify-space-between">
        <div class="text-h4">{{ $t("internalCustomerOrdersPredictions-dialog-title", { $: "Predicted Internal Customer Orders" }) }}</div>
        <v-btn icon="mdi-close" variant="flat" @click="cancel"></v-btn>
      </v-card-title>
      <v-card-text>

        <v-row>
          <v-col>
            <div style="height: 40vh; overflow-y: auto;">
              <internal-customer-orders-prediction-grid ref="internalCustomerOrdersGrid" :internal-customer-order-prediction-dtos="props.internalCustomerOrderPredictionDtos" />
            </div>
          </v-col>
        </v-row>

      </v-card-text>
      <v-card-actions>
        <v-row>
          <v-col cols="6">
            <v-btn class="ma-4" variant="elevated" color="secondary" block @click="cancel">
              {{ $t("internalCustomerOrdersPredictions-dialog-cancel-label", { $: "Cancel" }) }}
            </v-btn>
          </v-col>
          <v-col cols="6">
            <v-btn :disabled="props.internalCustomerOrderPredictionDtos.length == 0" class="ma-4" variant="elevated" block @click="createInternalCustomerOrders">
              {{ $t("internalCustomerOrdersPredictions-dialog-createInternalCustomerOrders-label", { $: "Create InternalCustomer Orders" }) }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style scoped>
</style>
