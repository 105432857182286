/**
 * plugins/webfontloader.js
 *
 * webfontloader documentation: https://github.com/typekit/webfontloader
 */
import { load } from "webfontloader";

export async function loadFonts() {
  /** dns-prefetch */
  const prefetch = document.createElement("link");
  prefetch.rel = "dns-prefetch";
  prefetch.href = "//fonts.googleapis.com";
  document.head.appendChild(prefetch);

  /** Preconnect */
  const preconnect = document.createElement("link");
  preconnect.rel = "preconnect";
  preconnect.href = "https://fonts.gstatic.com";
  document.head.appendChild(preconnect);

  /** Webfont Config */
  await new Promise<void>(resolve => {
    load(
      /** Webfont Config */
      {
        google: {
          families: [
            "Roboto:100,300,400,500,700,900&display=swap"
            /*
            // if you use Noto Sans, replace bellow lines.
            'Noto+Sans:100,300,400,500,700,900',
            'Noto+Sans+JP:100,300,400,500,700,900',
            'Noto+Sans+KR:100,300,400,500,700,900',
            'Noto+Colr+Emoji+Glyf:100,300,400,500,700,900',
            'Noto+Sans+Mono:100,300,400,500,700,900',
            */
          ]
        },
        active: () => {
          sessionStorage.fonts = true;
          resolve();
        }
      }
    );
  });
}
