import { ApiClient } from "@/services/api/common.api";

export default {
  async importData(data: any) {
    return ApiClient.post("/import", data);
  },
  async importFromJira(jql: any) {
    return ApiClient.post("/import/jira", { Jql: jql });
  }
};
