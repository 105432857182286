import { ApiClient } from "@/services/api/common.api";
import {
  CreateResourceAssignmentCommand,
  GetAllResourcesWithAssignmentQuery,
  GetMaterialsPaginatedQuery,
  IAgGridPaginationResponse,
  MaterialDto,
  ResourceAssignmentType,
  ResourceAvailabilityRuleDto,
  ResourceWithAssignmentDto,
  AvailabilityRulesResponseDto,
  UpdateResourceModelInstanceAssignmentCommand,
  UpdateResourceAvailabilityRuleAssignmentCommand
} from "@masta/generated-model";

export default {
  async getPaginated(request: GetAllResourcesWithAssignmentQuery) {
    return ApiClient.post<IAgGridPaginationResponse<ResourceWithAssignmentDto>>(`/resourceassignments/resources`, request);
  },
  async createResourceAssignment(request: CreateResourceAssignmentCommand) {
    return ApiClient.post(`/resourceassignments`, request);
  },
  async deleteResourceAssignment(request: { resource1: string; resource2: string; scenarioId: string; assignmentType: ResourceAssignmentType }) {
    return ApiClient.delete(
      `/resourceassignments?resource1=${request.resource1}&resource2=${request.resource2}&scenarioId=${request.scenarioId}&assignmentType=${request.assignmentType}`
    );
  }
};
