import {
  ActivityBounds,
  GanttEvents,
  GraphicsMousePositionEvent,
  IocSymbols,
  Layer,
  Lifecycle,
  MutableActivityBase,
  ObservableDataSet,
  Row,
  TimelineDataLoader,
  TimelineManager
} from "@masta/gantt2/core";
import { inject, injectable } from "inversify";
import { BehaviorSubject, filter, map, Subject } from "rxjs";
import { ViewportRowsContainer } from "@masta/gantt2/gantt";
import { GanttNoteDto } from "@masta/generated-model";
import { EnhancedGanttNoteDto, NoteActivity, ResourceCapacityActivity, ResourceRow, toEnhancedGanttNoteDto } from "src/components/Gantt/Common/Model";
import { Instant } from "@js-joda/core";
import { ResourcesGanttDataLoader } from "@/components/Gantt/ResourcesGantt/ResourcesGanttDataLoader";

export class NoteClickEvent {
  constructor(public readonly event: GraphicsMousePositionEvent, public readonly note?: GanttNoteDto) {}
}

@injectable()
export class NoteHandler extends Lifecycle {
  private _noteClickEvent$$: Subject<NoteClickEvent> = new Subject<NoteClickEvent>();

  constructor(
    @inject(ViewportRowsContainer) private _rowsContainer: ViewportRowsContainer,
    @inject(TimelineManager) private _timelineManager: TimelineManager,
    @inject(TimelineDataLoader) private _timelineDataLoader: TimelineDataLoader,
    @inject(GanttEvents) private _ganttEvents: GanttEvents,
    @inject(IocSymbols.RowsSymbol) private _rows: ObservableDataSet<Row<any, any, any>>,
    @inject(IocSymbols.LayersSymbol) private _layers$$: BehaviorSubject<Layer[]>
  ) {
    super();
  }

  async afterInitialize(): Promise<void> {
    await super.afterInitialize();

    this.subscribe(
      this._ganttEvents.mouseClickEvent$
        .pipe(
          filter((e) => e.shiftKey && e.altKey),
          map((e) => this.createNoteClickEvent(e)),
          filter((e: NoteClickEvent) => !!e.note)
        )
        .subscribe((e) => this._noteClickEvent$$.next(e))
    );
  }

  get noteClickEvent$() {
    return this._noteClickEvent$$.asObservable();
  }

  fireNoteClickEventForContextMenu(e: GraphicsMousePositionEvent) {
    this._noteClickEvent$$.next(this.createNoteClickEvent(e));
  }

  hasNoteForContextMenuEvent(e: GraphicsMousePositionEvent) {
    const rowContainers = this._rowsContainer.rowContainers;
    const rowContainer = rowContainers.find((rc) => e.y >= rc.element.offsetTop && e.y <= rc.element.offsetTop + rc.element.offsetHeight);
    if (rowContainer) {
      const bounds = rowContainer.getActivityBoundsForMousePosition({
        x: e.x,
        y: e.y - rowContainer.element.offsetTop
      });
      const note = this.getNote(bounds, rowContainer.row, e.x);
      return !!note?.id;
    }
    return false;
  }

  addNote(note: GanttNoteDto) {
    const row = this._rows.data.get(note.resourceId!);
    const layer = this.getResourceNoteLayer();
    if (row && layer) {
      const activity = this.getActivity(note, row, layer);
      if (activity) {
        row.removeActivity(layer, activity);
      }
      const dto = toEnhancedGanttNoteDto("NoteActivity", note);
      row.addActivity(layer, new NoteActivity(dto) as any);
    }
    this._timelineDataLoader.refreshData(undefined, undefined, note.resourceId ? [note.resourceId] : undefined);
  }

  deleteNote(note: GanttNoteDto) {
    const row = this._rows.data.get(note.resourceId!);
    const layer = this.getResourceNoteLayer();
    if (row && layer) {
      const activity = this.getActivity(note, row, layer);
      if (activity) row.removeActivity(layer, activity);
    }
    this._timelineDataLoader.refreshData(undefined, undefined, note.resourceId ? [note.resourceId] : undefined);
  }

  private createNoteClickEvent(e: GraphicsMousePositionEvent) {
    const rowContainers = this._rowsContainer.rowContainers;
    const rowContainer = rowContainers.find((rc) => e.y >= rc.element.offsetTop && e.y <= rc.element.offsetTop + rc.element.offsetHeight);
    if (rowContainer) {
      const bounds = rowContainer.getActivityBoundsForMousePosition({
        x: e.x,
        y: e.y - rowContainer.element.offsetTop
      });
      this.getNote();
      return new NoteClickEvent(e, this.getNote(bounds, rowContainer.row, e.x));
    }
    return new NoteClickEvent(e);
  }

  private getResourceNoteLayer(): Layer | undefined {
    return this._layers$$.value.find((x) => x.id === ResourcesGanttDataLoader.RESOURCE_NOTE_LAYER);
  }

  private getActivity(note: GanttNoteDto, row: ResourceRow, layer: Layer): MutableActivityBase<any> | undefined {
    const activities = row.repository.getActivities(layer, Instant.parse(note.issueDate), Instant.parse(note.issueDate), this._timelineManager.primaryTemporalUnit, row.zoneId);
    for (const activity of activities) {
      if (activity.id === note.id) {
        return activity;
      }
    }
  }

  private getNote(activityBounds: ActivityBounds[] = [], row?: Row<any, any, any>, xPos?: number) {
    const activities: ResourceCapacityActivity[] = activityBounds
      .filter((x) => x.layerId !== ResourcesGanttDataLoader.RESOURCE_NOTE_LAYER)
      .map((x) => row?.repository.getActivitiesById(this._layers$$.value.find((l) => l.id === x.layerId)!, [x.activityId]) ?? [])
      .reduce((acc, val) => acc.concat(val), []);
    const noteActivities: NoteActivity[] = activityBounds
      .filter((x) => x.layerId === ResourcesGanttDataLoader.RESOURCE_NOTE_LAYER)
      .map((x) => row?.repository.getActivitiesById(this._layers$$.value.find((l) => l.id === x.layerId)!, [x.activityId]) ?? [])
      .reduce((acc, val) => acc.concat(val), []);
    const emptyNote = {} as EnhancedGanttNoteDto;
    if (noteActivities.length > 0) {
      return noteActivities[0].userObject;
    } else if (activities.length > 0 && row) {
      const capacity = activities[0].userObject;
      const note = emptyNote;
      note.resourceId = row.id ?? "";
      note.taskId = capacity.task?.id ?? "";
      note.stepId = capacity.step?.id ?? "";
      note.issueDate = capacity.periodStart ?? "";
      return note;
    } else if (xPos !== undefined && row) {
      const note = emptyNote;
      note.resourceId = row.id ?? "";
      note.issueDate = this._timelineManager.calculateTimeForLocation(xPos).toJSON() ?? "";
      return note;
    }
  }
}
