import { GanttEvents, GanttSettings, IocSymbols, Lifecycle, SettingKey, TimelineManager } from "../../Core";
import { inject, injectable } from "inversify";
import { ViewportPointerEventNoneScrollGlass } from "./ViewportPointerEventNoneScrollGlass";
import { filter, fromEvent } from "rxjs";
import { RowsContainer } from "./RowsContainer";

@injectable()
export class KeyboardNavigationManager extends Lifecycle {
  private _element: HTMLElement;
  private _draggableElement: HTMLDivElement;

  constructor(
    @inject(IocSymbols.HtmlContainerSymbol) private readonly _rootElement: HTMLElement,
    @inject(GanttEvents) private _ganttEvents: GanttEvents,
    @inject(GanttSettings) private _settings: GanttSettings,
    @inject(ViewportPointerEventNoneScrollGlass) private _glassLayer: ViewportPointerEventNoneScrollGlass,
    @inject(RowsContainer) private _rowsContainer: RowsContainer,
    @inject(TimelineManager) private _timelineManager: TimelineManager
  ) {
    super();
  }

  async afterInitialize(): Promise<void> {
    await super.afterInitialize();

    const movePixels = 10;

    this.subscribe(
      fromEvent<KeyboardEvent>(document, "keydown")
        .pipe(filter(() => document.activeElement === this._rootElement))
        .subscribe(async (e) => {
          const key = e.key.toLowerCase();
          if (key === "h") {
            this._settings.setSetting<boolean>({ key: SettingKey.HELP_SHOW_LAYER, value: !this._settings.getSetting<boolean>(SettingKey.HELP_SHOW_LAYER) });
            return;
          }
          if (key === "?") {
            this._settings.setSetting<boolean>({ key: SettingKey.DEBUG_SHOW_INFO, value: !this._settings.getSetting<boolean>(SettingKey.DEBUG_SHOW_INFO) });
            return;
          }
          if (key === "n") {
            this._settings.setSetting<boolean>({ key: SettingKey.HELP_SHOW_NAV_KEYBOARD, value: !this._settings.getSetting<boolean>(SettingKey.HELP_SHOW_NAV_KEYBOARD) });
            return;
          }
          if (key === "i") {
            this._settings.setSetting<boolean>({ key: SettingKey.HELP_SHOW_INFO, value: !this._settings.getSetting<boolean>(SettingKey.HELP_SHOW_INFO) });
            return;
          }
          if (key === "w" || key === "arrowup") {
            this._rowsContainer.element.scrollBy({ top: -movePixels, behavior: "smooth" });
            return;
          }
          if (key === "s" || key === "arrowdown") {
            this._rowsContainer.element.scrollBy({ top: movePixels, behavior: "smooth" });
            return;
          }

          const millisPerClick = this._timelineManager.millisPerPixel * movePixels;
          if (key === "a" || key === "arrowleft") {
            await this._timelineManager.setStartTime(this._timelineManager.startTime.minusMillis(millisPerClick));
            return;
          }
          if (key === "d" || key === "arrowright") {
            await this._timelineManager.setStartTime(this._timelineManager.startTime.plusMillis(millisPerClick));
            return;
          }

          if (key === "q" || key === "+") {
            await this._timelineManager.zoomIn();
            return;
          }

          if (key === "e" || key === "-") {
            await this._timelineManager.zoomOut();
            return;
          }
        })
    );
  }

  async beforeDestroy(): Promise<void> {
    await super.beforeDestroy();
  }
}
