import { Lifecycle } from "./Lifecycle";
import { inject, injectable } from "inversify";
import { GanttEvents } from "./Events";
import { debounceTime } from "rxjs";
import { IocSymbols } from "./IocSymbols";
import type { IGanttWorker } from "./IGanttWorker";
import { TimelineManager } from "./TimelineManager";
import { Instant } from "@js-joda/core";
import { GanttSettings, SettingKey } from "./Model";

@injectable()
export class TimelineDataLoader extends Lifecycle {
  private _previousTimelineStartTime: Instant;
  private _previousTimelineEndTime: Instant;
  private _previousLoadStartTime: Instant;
  private _previousLoadEndTime: Instant;

  constructor(
    @inject(GanttEvents) private _ganttEvents: GanttEvents,
    @inject(TimelineManager) private _timelineManager: TimelineManager,
    @inject(IocSymbols.GanttWorkerSymbol) private _ganttWorkerWrapped: IGanttWorker,
    @inject(GanttSettings) private _ganttSettings: GanttSettings
  ) {
    super();
  }

  async afterInitialize(): Promise<void> {
    await super.afterInitialize();
    const delayValue = this._ganttSettings.getSetting<number>(SettingKey.DATA_LOADER_DELAY);
    const timelineRefreshEventDebounced$ = this._ganttEvents.timelineRefreshEvent$.pipe(debounceTime(delayValue || 500));

    // Define a variable to store the previous timeline start time
    this._previousTimelineStartTime = this._timelineManager.startTime;
    this._previousTimelineEndTime = this._timelineManager.endTime;
    this._previousLoadStartTime = this._timelineManager.startTime;
    this._previousLoadEndTime = this._timelineManager.endTime;

    this.subscribe(
      timelineRefreshEventDebounced$.subscribe(async ({ startTime }) => {
        const timelineStartTime = this._timelineManager.startTime;
        const timelineEndTime = this._timelineManager.endTime;

        if (timelineStartTime.isBefore(this._previousLoadStartTime) || timelineEndTime.isAfter(this._previousLoadEndTime)) {
          if (this._timelineManager.datelineManager.scales.length > 0) {
            const loadStartTime = this._timelineManager.datelineManager.scales[0].resolution!.decrement(timelineStartTime, this._timelineManager.zoneId);
            const loadEndTime = this._timelineManager.datelineManager.scales[0].resolution!.increment(timelineEndTime, this._timelineManager.zoneId);

            await this._ganttWorkerWrapped.synchronizeTimeline(this._timelineManager.getSynchronizationModel());
            await this._ganttWorkerWrapped.loadData(loadStartTime.toEpochMilli(), loadEndTime.toEpochMilli());

            // console.log("TimelineDataLoader START: ", loadStartTime.toJSON(), " ----- ", timelineStartTime.toJSON());
            // console.log("TimelineDataLoader END: ", timelineEndTime.toJSON(), " ----- ", loadEndTime.toJSON());

            this._previousTimelineStartTime = timelineStartTime;
            this._previousTimelineEndTime = timelineEndTime;
            this._previousLoadStartTime = loadStartTime;
            this._previousLoadEndTime = loadEndTime;
          }
        }
      })
    );
  }

  get loadedStartTime(): Instant {
    return this._previousLoadStartTime;
  }

  get loadedEndTime(): Instant {
    return this._previousLoadEndTime;
  }

  async refreshData(loadStartTime?: number, loadEndTime?: number, rowIds?: string[]): Promise<void> {
    try {
      await this._ganttWorkerWrapped.synchronizeTimeline(this._timelineManager.getSynchronizationModel());
      await this._ganttWorkerWrapped.loadData(loadStartTime ?? this._previousLoadStartTime.toEpochMilli(), loadEndTime ?? this._previousLoadEndTime.toEpochMilli(), rowIds);
    } catch (e) {
      console.error("GanttWorker exception: ", e);
    }
  }
}
