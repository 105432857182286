import { MutableActivityBase } from "@masta/gantt2/core";
import { Instant } from "@js-joda/core";
import type { DateFormatter } from "@masta/shared";
import { IDraggableTaskDto } from "@/components/Gantt/ResourcesGantt/DragAndDrop/IDraggableTaskDto";

export interface ExternalTaskGhostActivityUserObject {
  draggableTaskDto: IDraggableTaskDto;
  startTimeFormatted?: string;
  endTimeFormatted?: string;
}

export class ExternalTaskGhostActivity extends MutableActivityBase<ExternalTaskGhostActivityUserObject> {

  constructor(businessKey: string, name: string, dropTime: Instant, endTime: Instant, data: ExternalTaskGhostActivityUserObject, private _dateFormatter: DateFormatter) {
    super(businessKey, name, dropTime, endTime);
    this._userObject = data;
    this._userObject.startTimeFormatted = this._dateFormatter(this.startTime.toJSON());
    this._userObject.endTimeFormatted = this._dateFormatter(this.endTime);
  }

  get startTime() {
    return this._startTime;
  }

  set startTime(value: Instant) {
    this._startTime = value;
    this._userObject.startTimeFormatted = this._dateFormatter(this.startTime.toJSON());
  }

  get endTime() {
    return this._endTime;
  }

  set endTime(value: Instant) {
    this._endTime = value;
    this._userObject.endTimeFormatted = this._dateFormatter(this.endTime.toJSON());
  }
}
