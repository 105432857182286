import { ICalendar } from "../ICalendar";
import { ActivityRepositoryBase } from "../Repository";

import { ICalendarActivity } from "./ICalendarActivity";

export abstract class CalendarBase<TActivity extends ICalendarActivity> extends ActivityRepositoryBase<TActivity> implements ICalendar<TActivity> {
  private _name: string;

  private _visible = true;

  constructor(name: string) {
    super();
    this._name = name;
  }

  abstract get id(): string;

  get name(): string {
    return this._name;
  }

  set name(value: string) {
    this._name = value;
  }

  get visible(): boolean {
    return this._visible;
  }

  set visible(value: boolean) {
    this._visible = value;
  }
}
