<template>
  <v-switch v-model="isDarkMode" color="primary" true-icon="mdi-moon-waxing-crescent" false-icon="mdi-white-balance-sunny" hide-details inset />
</template>

<script>
import ThemeSwitchMixin from "@/mixins/themeSwitch.mixin";

export default {
  name: "ThemeSwitch",
  mixins: [ThemeSwitchMixin],
  emits: ["switch"],
  computed: {
    isDarkMode: {
      get() {
        return this.theme !== "dark";
      },
      set(isDark) {
        const newTheme = isDark ? "light" : "dark";
        this.doThemeSwitch(newTheme);
      }
    }
  },
};
</script>

<style lang="scss" scoped></style>
