﻿import { IRowChartData, IRowChartDataEntry, IRowChartDataSet, RowChartDataSet } from "./index";
import { Observable } from "rxjs";
import { ObservableDataSet } from "../../ObservableDataSet";
import { GanttException } from "../../Exceptions";

export class RowChartRepository {
  private _dataSets = new ObservableDataSet<IRowChartDataSet>();

  public get dataSets$(): Observable<IRowChartDataSet[]> {
    return this._dataSets.data$;
  }

  public get dataSets(): IRowChartDataSet[] {
    return Array.from(this._dataSets.data.values());
  }

  public setData(rowChartData: IRowChartData): IRowChartDataSet {
    const { id, resourceId, positiveColor, negativeColor, lineWidth, data, visible } = rowChartData;
    if (!id || id === "") throw new GanttException("row chart data id cannot be empty or null");
    if (!resourceId || resourceId === "") throw new GanttException("row chart data resourceId cannot be empty or null");
    let dataSet = this.dataSets.find((x) => x.id === id && x.resourceId === resourceId);
    if (!dataSet) {
      dataSet = new RowChartDataSet(id, resourceId);
      if (positiveColor) dataSet.positiveColor = positiveColor;
      if (negativeColor) dataSet.negativeColor = negativeColor;
      if (lineWidth) dataSet.lineWidth = lineWidth;
      if (visible) dataSet.visible = visible;
      dataSet.data = data;
      this._dataSets.add(dataSet);
    } else {
      this._dataSets.remove(dataSet.id);
      if (data.length > 0) {
        if (positiveColor) dataSet.positiveColor = positiveColor;
        if (negativeColor) dataSet.negativeColor = negativeColor;
        if (lineWidth) dataSet.lineWidth = lineWidth;
        if (visible) dataSet.visible = visible;
        dataSet.data = data;
        this._dataSets.add(dataSet);
      }
    }
    return dataSet;
  }

  public getData(dataSetId: string, resourceId: string): IRowChartDataEntry[] {
    const dataSet = this.dataSets.find((x) => x.id === dataSetId && x.resourceId === resourceId);
    if (!dataSet) return [];
    return dataSet.data;
  }

  public removeDataSet(dataSetId: string) {
    this._dataSets.remove(dataSetId);
  }
}
