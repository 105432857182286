import { $t } from "@/i18n";
import { isJiraFormattedDuration, isServerSideFormattedDuration } from "@masta/shared";
import dayjs from "dayjs";

export function requiredRule(v: any): boolean | string {
  return (v !== undefined && v !== null && v !== "") || $t("valueEditor-edit-valueRequired-tooltip", { $: "Required" });
}

export function requiredNotEmptyArrayRule(v: any): boolean | string {
  return (v !== undefined && v !== null && Array.isArray(v) && v.length > 0) || $t("valueEditor-edit-valueRequired-tooltip", { $: "Required" });
}

export function durationRequiredRule(v: any): boolean | string {
  const isValid = typeof v === "string" && v !== "" && (isJiraFormattedDuration(v) || isServerSideFormattedDuration(v));
  return (
    isValid ||
    $t("valueEditor-edit-durationRequiredRule-tooltip", { $: "Required format: '?d ?h ?m ?s' (?: number; d: days; h: hours; m: minutes; s: seconds) - example: '10m 20s'" })
  );
}

export function validDateRequiredRule(v: any): boolean | string {
  return dayjs(v).isValid() || "Required";
}

export function isNumberRule(v: any) {
  return (!isNaN(parseFloat(v)) && isFinite(v)) || $t("valueEditor-edit-valueMustBeNumber-tooltip", { $: "Must be a number" });
}

export function isPositiveNumberRule(v: any) {
  return (!isNaN(parseFloat(v)) && isFinite(v) && v > 0) || $t("valueEditor-edit-valueMustBePositiveNumber-tooltip", { $: "Must be greater than zero" });
}

export function isZeroOrGreaterNumberRule(v: any) {
  return (!isNaN(parseFloat(v)) && isFinite(v) && v >= 0) || $t("valueEditor-edit-valueMustBeZeroOrGreater-tooltip", { $: "Must be equal or greater than zero" });
}

export function isValidRegExpRule(v: any) {
  try {
    new RegExp(v);
    return true;
  } catch (e) {
    return $t("valueEditor-edit-valueMustBeValidRegExp-tooltip", { $: "Must be a valid regular expression" });
  }
}
