<script lang="ts" setup>
import GridWrapper from "@/components/Grid/GridWrapper.vue";
import { computed, reactive, ref, toRef, watch } from "vue";
import SystemEnumService from "@/services/system-enum.service";
import { ResourceSubType, ResourceType } from "@masta/generated-model";
import { GridWrapperComponent } from "@/components/Grid/GridWrapperComponent";
import { useModelInstancesStore } from "@/store/ModelInstancesStore";
import { $t } from "@/i18n";
import { ResourceAssignmentServerSideDataSource } from "@/components/Resources/ResourceAssignment/ResourceAssignmentServerSideDataSource";
import { IRowNode } from "ag-grid-community";

interface Props {
  resourceType: ResourceType;
  resource: any;
  reverse?: boolean;
  disabled?: boolean;
}

const props = defineProps<Props>();
const emit = defineEmits([]);
const miStore = useModelInstancesStore();

const loadingOverlayTemplate = ref("<span></span>");

const serverSideDataSource = reactive(
  new ResourceAssignmentServerSideDataSource("resource-assignment", {
    resourceType: props.resourceType,
    owningResourceId: props.resource.id,
    reverse: !!props.reverse
  })
);

const gridWrapperRef = ref<GridWrapperComponent>();
const defaultColumnDef = ref({
  filter: true,
  floatingFilter: true,
  filterParams: {
    applyMiniFilterWhileTyping: true
  },
  sortable: true,
  resizable: true
});

defineExpose<any>({
  gridApi: computed(() => gridWrapperRef?.value?.gridApi)
});
const isDisabled = toRef(props, "disabled");
watch(
  isDisabled,
  () => {
    gridWrapperRef?.value?.gridApi?.showLoadingOverlay();
  },
  { immediate: true }
);

async function onFetchData() {
  await miStore.fetchSchemas();
}

async function onRefreshAction() {
  await onFetchData();
}

function onPrepareColumns(columnDefs: any) {
  columnDefs.value = [
    {
      field: "assigned",
      editable: true,
      sortable: true,
      filter: true,
      valueGetter: () => "",
      headerValueGetter: (_: any) => $t("resource-assignment-assigned-label", { $: "Assigned" }),
      headerCheckboxSelection: true,
      checkboxSelection: true,
      showDisabledCheckboxes: true
    },
    {
      field: "id",
      hide: true,
      editable: false,
      sortable: true,
      filter: true,
      headerValueGetter: (_: any) => $t("resource-assignment-id-label", { $: "Id" })
    },
    {
      field: "name",
      editable: false,
      sortable: true,
      filter: true,
      headerValueGetter: (_: any) => $t("resource-assignment-name-label", { $: "Name" })
    },
    {
      field: "businessId",
      editable: false,
      sortable: true,
      filter: true,
      headerValueGetter: (_: any) => $t("resource-assignment-businessId-label", { $: "Business ID" })
    },
    {
      field: "type",
      editable: false,
      sortable: true,
      filter: true,
      valueFormatter: (params: any) => SystemEnumService.resourceType(params.data.type),
      headerValueGetter: (_: any) => $t("resource-assignment-type-label", { $: "Type" })
    },
    {
      field: "subTypes",
      editable: false,
      sortable: true,
      valueFormatter: (params: any) => (params.data.subTypes ? SystemEnumService.resourceSubTypes(params.data.subTypes) : undefined),
      headerValueGetter: (_: any) => $t("resource-assignment-subTypes-label", { $: "Resource subtypes" }),
      filter: "agSetColumnFilter",
      filterParams: {
        values: Object.values(ResourceSubType).filter((x) => !isNaN(x)),
        valueFormatter: (params) => {
          return SystemEnumService.resourceSubTypes([+params.value]);
        }
      }
    }
  ];
}

function onFirstDataRendered(params: any) {
  params.api.forEachNode((node: IRowNode) => {
    node.setSelected(!!node.data && node.data.assigned);
  });
}
</script>

<template>
  <grid-wrapper
    ref="gridWrapperRef"
    refresh-btn
    row-selection="multiple"
    identifier="resource-assignment"
    :default-col-def="defaultColumnDef"
    server-side
    :overlay-loading-template="loadingOverlayTemplate"
    :server-side-datasource="serverSideDataSource"
    :suppress-row-click-selection="true"
    :enable-range-selection="false"
    :enable-cell-text-selection="false"
    :suppress-cell-focus="true"
    :refresh-btn-disabled="isDisabled"
    @fetch-data="onFetchData"
    @refresh-action="onRefreshAction"
    @prepare-columns="onPrepareColumns"
    @first-data-rendered="onFirstDataRendered"
  >
  </grid-wrapper>
</template>

<style lang="scss" scoped></style>
