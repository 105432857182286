import { ServerSideDataSource } from "@/components/Grid/ServerSideDataSource";
import { BusinessActionDto, GetBusinessActionsPaginatedQuery, GetOrderLinesPaginatedQuery, IAgGridPaginationResponse, OrderLineDto } from "@masta/generated-model";
import ApiService from "@/services/api";
import { IServerSideGetRowsParams } from "ag-grid-enterprise";

export class BusinessActionsServerSideDataSource extends ServerSideDataSource<BusinessActionDto, BusinessActionDto, BusinessActionDto, BusinessActionDto, string> {
  constructor(id: string) {
    super(id);
  }

  async getAll<T>(params: IServerSideGetRowsParams): Promise<IAgGridPaginationResponse<BusinessActionDto>> {
    const request = {
      ...this.constructServerRequest(params)
    } as GetBusinessActionsPaginatedQuery;
    const response = await ApiService.businessActions.getPaginated(request);

    return response.data as IAgGridPaginationResponse<BusinessActionDto>;
  }

  create(entity: BusinessActionDto): Promise<string> {
    throw new Error("Method not implemented.");
  }

  getSingle(id: string): Promise<BusinessActionDto | undefined> {
    throw new Error("Method not implemented.");
  }

  remove(entity: BusinessActionDto): Promise<void> {
    throw new Error("Method not implemented.");
  }

  protected transformForCreate(listEntity: BusinessActionDto): BusinessActionDto {
    return listEntity;
  }

  protected transformForUpdate(listEntity: BusinessActionDto): BusinessActionDto {
    return listEntity;
  }

  update(entity: BusinessActionDto): Promise<string> {
    throw new Error("Method not implemented.");
  }
}
