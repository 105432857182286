import { ChronoField, ChronoUnit, Instant, TemporalQueries, ZonedDateTime } from "@js-joda/core";

import { ChronoUnitUtils, Position, Resolution } from "../../Core";

import { Dateline } from "./Dateline";
import { TimelineDatelineCell } from "./TimelineDatelineCell";

export class ChronoUnitTimelineDatelineCell extends TimelineDatelineCell<ChronoUnit> {
  public static TEXT_CACHE = new Map<string, string>();

  private _originalClassname: string | undefined;

  private _width: string;

  private _translate: string;

  private _cached = false;

  constructor() {
    super();
    this._originalClassname = this._className;
  }

  // eslint-disable-next-line max-len
  updateCell(startTime: Instant, endTime: Instant, resolution: Resolution<ChronoUnit>, dateline: Dateline, position: Position, translate: string, width: string): void {
    if (startTime.equals(this._startTime) && endTime.equals(this._endTime) && resolution === this._resolution && position === this._scalePosition) {
      this._cached = true;
    } else {
      this._cached = false;
      this.update(startTime, endTime, resolution, dateline, position);
    }
    this._translate = translate;
    this._width = width;
  }

  async doDrawFromBatch(): Promise<void> {
    const visibility = `${this.visible ? "inherit" : "hidden"}`;
    if (this.element.style.visibility !== visibility) {
      this.element.style.visibility = visibility;
    }
    if (!this.visible) {
      return;
    }
    if (this.element.style.width !== this._width) this.element.style.width = this._width;
    if (this.element.style.transform !== this._translate) this.element.style.transform = this._translate;

    if (this._cached) return;
    const { zoneId, firstDayOfWeek } = this.dateline;
    const cacheKey = `${this.startTime.toEpochMilli()}-${zoneId.id()}-${this.resolution.format}`;
    if (!ChronoUnitTimelineDatelineCell.TEXT_CACHE.has(cacheKey)) {
      ChronoUnitTimelineDatelineCell.TEXT_CACHE.set(cacheKey, this.resolution.formatInstant(this.startTime, zoneId));
    }
    const text = ChronoUnitTimelineDatelineCell.TEXT_CACHE.get(cacheKey);
    if (text && text !== this._text) {
      this._text = text;
      this.element.innerText = this._text;
    }

    const { temporalUnit } = this.resolution;
    let zonedStartTime;
    // eslint-disable-next-line default-case
    switch (temporalUnit) {
      case ChronoUnit.DAYS:
        zonedStartTime = ChronoUnitUtils.truncateZonedTime(ZonedDateTime.ofInstant(this.startTime, zoneId), temporalUnit, 1, firstDayOfWeek);
        this._className = `${this._originalClassname} ${zonedStartTime.dayOfWeek().toString().toLowerCase()}`;
        this.addClass(this.element);
        break;
      case ChronoUnit.HALF_DAYS:
        zonedStartTime = ChronoUnitUtils.truncateZonedTime(ZonedDateTime.ofInstant(this.startTime, zoneId), temporalUnit, 1, firstDayOfWeek);
        this._className = `${this._originalClassname} ${zonedStartTime.get(ChronoField.AMPM_OF_DAY) === 0 ? "am" : "pm"}`;
        this.addClass(this.element);
        break;
      case ChronoUnit.MONTHS:
        zonedStartTime = ChronoUnitUtils.truncateZonedTime(ZonedDateTime.ofInstant(this.startTime, zoneId), temporalUnit, 1, firstDayOfWeek);
        this._className = `${this._originalClassname} ${zonedStartTime.month().toString().toLowerCase()}`;
        this.addClass(this.element);
        break;
      default:
        this._className = this._originalClassname;
        this.addClass(this.element);
    }
  }

  private addClass(element: HTMLElement) {
    if (element.className !== this._className) {
      element.className = `${element.className}`;
    }
  }
}
