import ApiService from "@/services/api";
import { ServerSideDataSource } from "@/components/Grid/ServerSideDataSource";
import { CreateOrUpdateInboundDeliveryLineCommand, GetInboundDeliveryLinesPaginatedQuery, IAgGridPaginationResponse, InboundDeliveryLineDto } from "@masta/generated-model";
import { IServerSideGetRowsParams } from "ag-grid-community";
import { State as ScenarioState, useScenariosStore } from "@/store/ScenariosStore";
import { Store } from "pinia";
import { GUID_EMPTY } from "@/components/ValueCellEditor/CommonFormatters";

export class InboundDeliveryLinesServerSideDataSource extends ServerSideDataSource<
  InboundDeliveryLineDto,
  InboundDeliveryLineDto,
  CreateOrUpdateInboundDeliveryLineCommand,
  CreateOrUpdateInboundDeliveryLineCommand,
  string
> {
  private _scenarioStore: Store<string, ScenarioState>;
  private _filterByProductId: string | null | undefined;
  private _filterByOrderId: string | null | undefined;

  constructor(id: string) {
    super(id);
    this._scenarioStore = useScenariosStore();
  }

  public useFilteringByProductId(productId: string | null | undefined) {
    this._filterByProductId = productId;
  }

  public useFilteringByOrderId(productId: string | null | undefined) {
    this._filterByOrderId = productId;
  }

  public static transformInboundDeliveryLineDtoToCreateOrUpdateInboundDeliveryLineCommand(
    orderId: string,
    listEntity: InboundDeliveryLineDto
  ): CreateOrUpdateInboundDeliveryLineCommand {
    return {
      id: listEntity.id,
      scenarioId: listEntity.scenarioId ?? GUID_EMPTY,
      orderId: orderId,
      itemBusinessId: listEntity.itemBusinessId,
      itemId: listEntity.itemId,
      description: listEntity.description,
      orderLineStatus: listEntity.status,
      position: listEntity.position,
      deliveryDate: listEntity.deliveryDate,
      quantity: listEntity.quantity,
      quantityUnit: listEntity.measurementUnit,
      modelInstances: [],
      modelInstanceBusinessIds: []
    };
  }

  async create(entity: InboundDeliveryLineDto): Promise<string> {
    const request = this.transformForCreate(entity);
    const scenario = this._scenarioStore.selectedScenario;
    request.scenarioId = scenario?.id ?? "";
    const response = await ApiService.inboundDeliveries.createOrUpdateInboundDeliveryLine(request);
    return response.data;
  }

  async getAll<T>(params: IServerSideGetRowsParams): Promise<IAgGridPaginationResponse<InboundDeliveryLineDto>> {
    const scenarioId = this._scenarioStore.selectedScenario?.id;

    if (!scenarioId || !this.isOrderSelected()) {
      return { count: 0, results: [] } as IAgGridPaginationResponse<InboundDeliveryLineDto>;
    }

    const request = {
      ...this.constructServerRequest(params),
      orderId: this.getOrderId(),
      scenarioId,
      resourceId: this._filterByProductId
    } as GetInboundDeliveryLinesPaginatedQuery;
    const response = await ApiService.inboundDeliveries.getInboundDeliveryLinesPaginated(request);

    return response.data as IAgGridPaginationResponse<InboundDeliveryLineDto>;
  }

  async getSingle(id: string): Promise<InboundDeliveryLineDto | undefined> {
    const scenarioId = this._scenarioStore.selectedScenario?.id;

    if (!scenarioId || !this.isOrderSelected()) return;

    if (!this.getOrderId()) return;

    const response = await ApiService.inboundDeliveries.getInboundDeliveryLine(this.getOrderId(), id, scenarioId);
    return response.data;
  }

  async update(entity: InboundDeliveryLineDto): Promise<string> {
    const response = await ApiService.inboundDeliveries.createOrUpdateInboundDeliveryLine(this.transformForUpdate(entity));
    return response.data;
  }

  protected transformForCreate(listEntity: InboundDeliveryLineDto): CreateOrUpdateInboundDeliveryLineCommand {
    return InboundDeliveryLinesServerSideDataSource.transformInboundDeliveryLineDtoToCreateOrUpdateInboundDeliveryLineCommand(this.getOrderId(), listEntity);
  }

  protected transformForUpdate(listEntity: InboundDeliveryLineDto): CreateOrUpdateInboundDeliveryLineCommand {
    return InboundDeliveryLinesServerSideDataSource.transformInboundDeliveryLineDtoToCreateOrUpdateInboundDeliveryLineCommand(this.getOrderId(), listEntity);
  }

  async remove(entity: InboundDeliveryLineDto): Promise<void> {
    if (entity.id) {
      await ApiService.inboundDeliveries.removeInboundDeliveryLine(this._filterByOrderId, entity.id, entity.scenarioId);
    }
  }

  private isOrderSelected() {
    return this._filterByOrderId;
  }

  private getOrderId(): string {
    return this._filterByOrderId;
  }
}
