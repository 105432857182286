<script setup lang="ts">
import { $t } from "@/i18n";
import GridWrapper from "@/components/Grid/GridWrapper.vue";
import { translateMeasurementUnit, translateOrderLineStatus } from "@/composables/translateEnum";
import { getOrderLineStatusCellStyle } from "@/components/CustomerOrders/CustomerOrderUtils";
import { inject, reactive, ref } from "vue";
import { GridWrapperComponent } from "@/components/Grid/GridWrapperComponent";
import { $dateTimeFormatterSymbol, DateFormatter } from "@masta/shared";
import { SelectionChangedEvent, ValueFormatterParams} from "ag-grid-community";
import { getSelectedRows } from "@/components/Grid/UseGridSelection";
import { CustomerOrderLineDetailsServerSideDataSource } from "@/components/CustomerOrders/CustomerOrderLineDetailsServerSideDataSource";
import { CustomerOrderLineDetailsDto, OrderLineStatus, ModelInstanceIdDto } from "@masta/generated-model";
import { groupEnumValueRowKeyCreator } from "@/components/Grid/Filters/ColumnFilters";
import { enumToEditorEntries, enumValueEntryWithLocaleComparator, translateEditorEntries } from "@/components/Grid/ColumnTypes";

const $emits = defineEmits<{
  (e: "selectionChanged", ids: CustomerOrderLineDetailsDto[]): void;
  (e: "doubleClicked", id: CustomerOrderLineDetailsDto): void;
}>();

const { onlyUngrouped = false } = defineProps<{
  onlyUngrouped: boolean;
}>();

const gridWrapperRef = ref<GridWrapperComponent>();
const $dateTimeFormatter = inject<DateFormatter>($dateTimeFormatterSymbol)!;
const serverSideDataSource = reactive(new CustomerOrderLineDetailsServerSideDataSource("customer-order-line-details"));
serverSideDataSource.useOnlyUngroupedFilter(onlyUngrouped);

function onSelectionChanged(event: SelectionChangedEvent) {
  const { api } = event;
  const selectedRows = getSelectedRows(api);
  $emits("selectionChanged", selectedRows);
}

const defaultColumnDef = ref({
  floatingFilter: true,
  filterParams: {
    applyMiniFilterWhileTyping: true
  }
});

function onPrepareColumns(columnDefs: any) {
  columnDefs.value = [
    {
      field: "itemName",
      type: ["textInputTypeColumn", "textFloatingFilterColumnType"],
      editable: false,
      sortable: true,
      resizable: true,
      filter: "agTextColumnFilter",
      headerValueGetter: (_: any) => $t("customerOrderLine-list-itemName-label", { $: "Item Name" })
    },
    {
      field: "itemBusinessId",
      type: ["textInputTypeColumn", "textFloatingFilterColumnType"],
      editable: false,
      sortable: true,
      filter: "agTextColumnFilter",
      resizable: true,
      headerValueGetter: (_: any) => $t("customerOrderLine-list-itemBusinessId-label", { $: "Item Business Id" })
    },
    {
      field: "description",
      type: ["textInputTypeColumn", "textFloatingFilterColumnType"],
      editable: false,
      sortable: true,
      filter: "agTextColumnFilter",
      resizable: true,
      headerValueGetter: (_: any) => $t("customerOrderLine-list-description-label", { $: "Description" })
    },
    {
      field: "quantity",
      type: ["numberInputTypeColumn", "numberFloatingFilterColumnType"],
      sortable: true,
      editable: false,
      filter: "agNumberColumnFilter",
      resizable: true,
      headerValueGetter: (_: any) => $t("customerOrderLine-list-quantity-label", { $: "Quantity" })
    },
    {
      field: "measurementUnit",
      editable: false,
      sortable: true,
      filter: "agNumberColumnFilter",
      resizable: true,
      type: "enumTypeColumn",
      valueFormatter: (params: any) => (params.data ? translateMeasurementUnit(params.data.measurementUnit) : null),
      headerValueGetter: (_: any) => $t("customerOrderLine-list-quantityUnit-label", { $: "Measurement Unit" })
    },
    {
      field: "position",
      type: ["numberInputTypeColumn", "numberFloatingFilterColumnType"],
      editable: false,
      sortable: true,
      filter: "agNumberColumnFilter",
      resizable: true,
      headerValueGetter: (_: any) => $t("customerOrderLine-list-position-label", { $: "Position" })
    },
    {
      field: "deliveryDate",
      type: ["datepickerTypeColumn", "dateTimeTypeColumn"],
      editable: false,
      sortable: true,
      filter: "agDateColumnFilter",
      resizable: true,
      valueFormatter: (params: any) => {
        return $dateTimeFormatter(params.data.deliveryDate);
      },
      headerValueGetter: (_: any) => $t("customerOrderLine-list-deliveryDate-label", { $: "Delivery Date" })
    },
    {
      field: "status",
      headerName: "Status",
      editable: false,
      sortable: true,
      filter: "agSetColumnFilter",
      type: ["setFloatingFilterColumnType"],
      filterParams: {
        keyCreator: groupEnumValueRowKeyCreator,
        valueFormatter: (params: ValueFormatterParams) => params.value.key,
        values: translateEditorEntries(enumToEditorEntries(OrderLineStatus), translateOrderLineStatus),
        comparator: enumValueEntryWithLocaleComparator
      },
      resizable: true,
      cellStyle: (params: any) => getOrderLineStatusCellStyle(params.data.status),
      valueGetter: (params: any) => translateOrderLineStatus(params.data.status),
      headerValueGetter: (_: any) => $t("customerOrderLine-list-status-label", { $: "Status" })
    },
    {
      field: "orderIssueDate",
      editable: false,
      sortable: true,
      resizable: true,
      filter: "agDateColumnFilter",
      valueFormatter: (params: any) => {
        return $dateTimeFormatter(params.data.issueDate);
      },
      headerValueGetter: (_: any) => $t("customerOrderLine-list-orderIssueDate-label", { $: "Order Issue Date" })
    },
    {
      field: "orderBusinessId",
      editable: false,
      sortable: true,
      resizable: true,
      filter: "agTextColumnFilter",
      headerValueGetter: (_: any) => $t("customerOrderLine-list-orderBusinessId-label", { $: "Order Business Id" })
    },
    {
      field: "orderDescription",
      editable: false,
      sortable: true,
      resizable: true,
      filter: "agTextColumnFilter",
      headerValueGetter: (_: any) => $t("customerOrderLine-list-orderDescription-label", { $: "Order Description" })
    },
    {
      field: "customerName",
      editable: false,
      sortable: true,
      resizable: true,
      filter: "agTextColumnFilter",
      headerValueGetter: (_: any) => $t("customerOrder-list-customerName-label", { $: "Customer" })
    },
    {
      field: "orderLineGroupBusinessId",
      editable: false,
      sortable: true,
      resizable: true,
      filter: "agTextColumnFilter",
      headerValueGetter: (_: any) => $t("customerOrderLine-list-orderLineGroupBusinessId-label", { $: "Group Business Id" })
    }
  ];
}
</script>

<template>
  <grid-wrapper
    ref="gridWrapperRef"
    identifier="order-line-details"
    row-selection="multiple"
    :default-col-def="defaultColumnDef"
    :server-side="true"
    :server-side-datasource="serverSideDataSource"
    :server-side-infinite-scroll="false"
    :pagination="true"
    refresh-btn
    @prepare-columns="onPrepareColumns"
    @selection-changed="onSelectionChanged"
  >
  </grid-wrapper>
</template>

<style scoped lang="scss"></style>
