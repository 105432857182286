import { Lifecycle } from "./Lifecycle";
import { inject, injectable } from "inversify";
import {
  ChronoUnitDatelineModel,
  ChronoUnitResolution,
  ChronoUnitTimelineModel,
  GanttSettings,
  ITimelineSynchronization,
  Position,
  Resolution,
  SettingKey,
  TimeInterval
} from "./Model";
import { ChronoUnit, DayOfWeek, Duration, Instant, ZoneId } from "@js-joda/core";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { GanttException } from "./Exceptions";
import { GanttEvents } from "./Events";

export type DatelineScaleChange = {
  rowIndex: number;
  scale: DatelineScaleManager;
};

export enum TimelineZoomMode {
  CENTER,
  NOW_TIME,
  KEEP_START_TME,
  KEEP_END_TIME
}

export class DatelineManager {
  private _scaleResolutions = new Array<Resolution<ChronoUnit>>();
  private _rowMap = new Map<number, DatelineScaleManager>();
  private _rowMapChange$$ = new Subject<DatelineScaleChange>();
  private _datelineGridMap = new Map<number, Array<number>>();
  private _font: string;

  constructor(private _timelineModel: ChronoUnitTimelineModel, private _dateLineModel: ChronoUnitDatelineModel) {
  }

  get scaleResolutions(): Array<Resolution<ChronoUnit>> {
    return this._scaleResolutions;
  }

  get datelineGridMap(): Map<number, Array<number>> {
    return this._datelineGridMap;
  }

  set font(value: string) {
    this._font = value;
  }

  get font(): string {
    return this._font;
  }

  get rowMapChange$(): Observable<DatelineScaleChange> {
    return this._rowMapChange$$.asObservable();
  }

  get scales(): DatelineScaleManager[] {
    return Array.from(this._rowMap.values()).sort((a, b) => a.position - b.position);
  }

  buildScales() {
    const { scaleCount } = this._dateLineModel;
    for (let i = 0; i < scaleCount; i++) {
      const scalePosition = this.getScalePosition(i, scaleCount);
      if (this._rowMap.has(i)) {
        const datelineScale = this._rowMap.get(i)!;
        datelineScale.resolution = null;
      } else {
        const datelineScale = new DatelineScaleManager(this, this._timelineModel, this._dateLineModel, scalePosition);
        this._rowMap.set(i, datelineScale);
        this._datelineGridMap.set(i, new Array<number>());
        this._rowMapChange$$.next({ rowIndex: i, scale: datelineScale });
      }
    }
  }

  build() {
    const { scaleCount } = this._dateLineModel;
    let temporalUnit = this._timelineModel.smallestTemporalUnit;
    this._scaleResolutions.splice(0, this._scaleResolutions.length);
    for (let i = 0; i < scaleCount; i++) {
      const datelineScale = this._rowMap.get(i);
      // eslint-disable-next-line no-continue
      if (!datelineScale) continue;
      const nextUnit = datelineScale.build(temporalUnit);
      if (!nextUnit) {
        break;
      }
      temporalUnit = nextUnit;
      if (datelineScale.resolution) {
        this._scaleResolutions.push(datelineScale.resolution);
      }
    }
    // save x positions for gridlines to skip consuming calculation operations
    for (let i = 0; i < scaleCount; i++) {
      const datelineScale = this._rowMap.get(i);
      const grids = this._datelineGridMap.get(i);
      if (!datelineScale || !grids) continue;
      grids.splice(0, grids.length);
      datelineScale.cellXPositions.forEach((x) => {
        const posX = x;
        if (posX >= 0) {
          grids.push(posX);
        }
      });
    }
    // console.log(
    //   "build",
    //   this._timelineModel.endTime.toJSON(),
    //   this._scaleResolutions.map((x) => x.temporalUnit.toString()),
    //   this._scaleResolutions.find((r) => r.isSupportingPosition(Position.TOP))?.temporalUnit.toString()
    // );
    this._timelineModel.primaryTemporalUnit = this._scaleResolutions.find((r) => r.isSupportingPosition(Position.TOP))?.temporalUnit ?? temporalUnit;
  }

  private getScalePosition(row: number, scaleCount: number): Position {
    if (scaleCount === 1) return Position.ONLY;
    if (row === 0) return Position.BOTTOM;
    if (row === scaleCount - 1) return Position.TOP;
    return Position.MIDDLE;
  }
}

export class DatelineScaleManager {
  private _resolution: Resolution<ChronoUnit> | null = null;
  private _canvasContextMeasurement: CanvasRenderingContext2D | OffscreenCanvasRenderingContext2D | null = null;
  private _firstDayOfWeek$$ = new BehaviorSubject<DayOfWeek>(DayOfWeek.MONDAY);
  private _minimumCellWidth = 50;
  private _cellPadding = 10;
  private _canvas: OffscreenCanvas | HTMLCanvasElement;
  private _cellXPositions = new Array<number>();
  private _datelineCellCache = new Array<DatelineCell>();
  private _datelineCells = new Array<DatelineCell>();

  constructor(private _manager: DatelineManager, private _timelineModel: ChronoUnitTimelineModel, private _dateLineModel: ChronoUnitDatelineModel, private _position: Position) {
    if (typeof window === "object") {
      this._canvas = document.createElement("canvas") as HTMLCanvasElement;
      this._canvasContextMeasurement = this._canvas.getContext("2d");
    } else {
      this._canvas = new OffscreenCanvas(3000, 50);
      this._canvasContextMeasurement = this._canvas.getContext("2d");
    }
    if (this._canvasContextMeasurement) {
      this._canvasContextMeasurement.font = this._manager.font;
    }
  }

  get resolution(): Resolution<ChronoUnit> | null {
    return this._resolution;
  }

  set resolution(value: Resolution<ChronoUnit> | null) {
    this._resolution = value;
  }

  get cellXPositions(): Array<number> {
    return this._cellXPositions;
  }

  get datelineCells(): Array<DatelineCell> {
    return this._datelineCells;
  }

  get position(): Position {
    return this._position;
  }

  set position(value: Position) {
    this._position = value;
  }

  get zoneId(): ZoneId {
    return this._timelineModel.zoneId;
  }

  get firstDayOfWeek(): DayOfWeek {
    return this._firstDayOfWeek$$.value;
  }

  get canvasContextMeasurement(): CanvasRenderingContext2D | OffscreenCanvasRenderingContext2D | null {
    return this._canvasContextMeasurement;
  }

  set canvasContextMeasurement(value: CanvasRenderingContext2D | OffscreenCanvasRenderingContext2D | null) {
    this._canvasContextMeasurement = value;
  }

  get font(): string {
    return this._manager.font;
  }

  build(chronoUnit: ChronoUnit) {
    let success = false;
    do {
      if (this._resolution) {
        success = this.buildCells(this._resolution);
      } else {
        const resolutions = this._dateLineModel.resolutionMap.get(chronoUnit);
        for (const resolution of resolutions!) {
          if (resolution.isSupportingPosition(this._position)) {
            success = this.buildCells(resolution);
          }
          if (success) {
            break;
          }
        }
      }
      if (!success) {
        const nextUnit = this._dateLineModel.nextTemporalUnit(chronoUnit);
        if (!nextUnit) return null;
        chronoUnit = nextUnit;
      }
    } while (!success);
    return this._dateLineModel.nextTemporalUnit(chronoUnit);
  }

  private _resolutionTextCache = new Map<Resolution<ChronoUnit>, Map<number, string>>();

  private buildCells(resolution: Resolution<ChronoUnit>) {
    this._datelineCells.splice(0, this.datelineCells.length);
    this._cellXPositions.splice(0, this._cellXPositions.length);
    let success = true;
    const firstDayOfWeek = this._firstDayOfWeek$$.value;
    const zoneId = this._timelineModel.zoneId;

    const timelineStartTime = resolution.decrement(this._timelineModel.startTime, zoneId);
    const timelineEndTime = this._timelineModel.endTime;
    const timelineWidth = this._timelineModel.calculateLocationForTime(timelineEndTime);

    let startTime = resolution.truncateInstant(timelineStartTime, zoneId, firstDayOfWeek);
    let x1 = this._timelineModel.calculateLocationForTime(startTime);

    let resolutionTextCache = this._resolutionTextCache.get(resolution);
    if (!resolutionTextCache) {
      resolutionTextCache = new Map<number, string>();
      this._resolutionTextCache.set(resolution, resolutionTextCache);
    }

    let cellIndex = 0;
    while (x1 < timelineWidth) {
      const minimumWidth = this.getMinimumWidth(startTime, resolution) + this._cellPadding;
      let endTime = resolution.increment(startTime, zoneId);
      let dstCorrectionInHours = 0;
      if (resolution.temporalUnit === ChronoUnit.HOURS && resolution instanceof ChronoUnitResolution) {
        if (resolution.isDSTEndIncrement()) {
          dstCorrectionInHours = 1;
        } else if (resolution.isDSTStartIncrement()) {
          dstCorrectionInHours = -1;
          endTime = resolution.increment(endTime, zoneId);
        }
      }
      if (dstCorrectionInHours > 0) {
        endTime = endTime.plus(1, ChronoUnit.HOURS);
      } else if (dstCorrectionInHours < 0) {
        endTime.minus(1, ChronoUnit.HOURS);
      }

      x1 = this._timelineModel.calculateLocationForTime(startTime);
      if (x1 < timelineWidth) {
        const x2 = this._timelineModel.calculateLocationForTime(endTime);

        if (x1 + minimumWidth <= x2) {
          const cellXPosition = Math.round(x1);
          const timeDiff = Math.round(x2 - x1);
          const cellWidth = timeDiff > minimumWidth ? timeDiff : minimumWidth;
          this._cellXPositions.push(cellXPosition);
          const cell = this.getDatelineCell(cellIndex++);
          cell.x = cellXPosition;
          cell.startTime = startTime;
          cell.endTime = endTime;
          cell.resolution = resolution;
          cell.cellWidth = cellWidth;
          if (!resolutionTextCache.has(startTime.toEpochMilli())) {
            const text = resolution.formatInstant(startTime, zoneId);
            resolutionTextCache.set(startTime.toEpochMilli(), text);
          }
          cell.text = resolutionTextCache.get(startTime.toEpochMilli())!;
          cell.textWidth = minimumWidth;
          this._datelineCells.push(cell as DatelineCell);
        } else {
          success = false;
          break;
        }

        startTime = resolution.increment(startTime, zoneId);
        if (dstCorrectionInHours > 0) {
          startTime = startTime.plus(dstCorrectionInHours, ChronoUnit.HOURS);
          continue;
        }
        if (dstCorrectionInHours < 0) {
          startTime = endTime;
        }
      }
    }
    if (success) {
      this.resolution = resolution;
    }
    return success;
  }

  private getCanvasTextWidth(text: string, font: string): number {
    if (!this._canvasContextMeasurement) return text.length * 10; // hack for missing DOM Canvas context in tests
    this._canvasContextMeasurement.font = font;
    const metrics = this._canvasContextMeasurement!.measureText(text);
    return metrics.width;
  }

  private getMinimumWidth(startTime: Instant, resolution: Resolution<ChronoUnit>) {
    const text = resolution.formatInstant(startTime, this._timelineModel.zoneId);
    return this.getCanvasTextWidth(text, this._manager.font);
  }

  private getDatelineCell(index: number): DatelineCell {
    let cell = this._datelineCellCache[index];
    if (!cell) {
      // cache cells to avoid creating new objects all the time
      cell = {} as DatelineCell;
      this._datelineCellCache[index] = cell;
    }
    return cell;
  }
}

export type DatelineCell = {
  x: number;
  startTime: Instant;
  endTime: Instant;
  resolution: Resolution<ChronoUnit>;
  cellWidth: number;
  text: string;
  textWidth: number;
};

@injectable()
export class TimelineManager extends Lifecycle {
  private _timelineModel: ChronoUnitTimelineModel;
  private _dateLineModel: ChronoUnitDatelineModel;
  private _dateLineManager: DatelineManager;
  private _font: string;
  private _width = 0;
  private _offset = 0;
  private _zoomFactor = 0.1;
  private _zoomMode = TimelineZoomMode.CENTER;

  constructor(@inject(GanttEvents) private _ganttEvents: GanttEvents, @inject(GanttSettings) private _settings: GanttSettings) {
    super();
    this._timelineModel = new ChronoUnitTimelineModel();
    this._dateLineModel = new ChronoUnitDatelineModel();

    this._dateLineManager = new DatelineManager(this._timelineModel, this._dateLineModel);
  }

  async afterInitialize(): Promise<void> {
    await super.afterInitialize();
    setInterval(async () => {
      this._timelineModel.nowTime = Instant.now();
    }, 1000) as any;
    this.subscribe(this._settings.getSetting$<number>(SettingKey.TIMELINE_ZOOM_FACTOR).subscribe((zoomFactor) => {
      if (zoomFactor) {
        this._zoomFactor = zoomFactor;
      }
    }));
  }

  get datelineManager(): DatelineManager {
    return this._dateLineManager;
  }

  set font(value: string) {
    this._font = value;
    this._dateLineManager.font = value;
  }

  get font(): string {
    return this._font;
  }

  changeZoomMode(mode: TimelineZoomMode) {
    this._zoomMode = mode;
  }

  async setTimelineWidth(width: number) {
    this._width = width;
    this._timelineModel.endTime = this._timelineModel.calculateTimeForLocation(this._width);
    // console.log("setTimelineWidth", this._timelineModel.startTime.toJSON(), this._timelineModel.millisPerPixel, this._timelineModel.endTime.toJSON(), this._width);
    // await this._dateLineManager.buildScales();
    await this._dateLineManager.build();
    await this.emitTimelineRefreshEvent();
  }

  async setTimelineStart(startTime: Instant, mainChronoUnit: ChronoUnit, unitWidth: number) {
    if (unitWidth < 10) {
      throw new GanttException("requested width must be equal to or larger than 10");
    }
    this._timelineModel.startTime = startTime;
    const requestedMillis = mainChronoUnit.duration().toMillis();
    this._timelineModel.millisPerPixel = requestedMillis / unitWidth;
    this._timelineModel.endTime = this._timelineModel.calculateTimeForLocation(this._width);
    // console.log("setTimelineStart", this._timelineModel.startTime.toJSON(), this._timelineModel.millisPerPixel, this._timelineModel.endTime.toJSON(), this._width);
    await this._dateLineManager.buildScales();
    await this._dateLineManager.build();
    await this.emitTimelineRefreshEvent();
  }

  async setStartTime(startTime: Instant) {
    this._timelineModel.startTime = startTime;
    this._timelineModel.endTime = this._timelineModel.calculateTimeForLocation(this._width);
    // console.log("setStartTime", this._timelineModel.startTime.toJSON(), this._timelineModel.millisPerPixel, this._timelineModel.endTime.toJSON(), this._width);
    // await this._dateLineManager.buildScales();
    await this._dateLineManager.build();
    await this.emitTimelineRefreshEvent();
  }

  get zoneId$(): Observable<ZoneId> {
    return this._timelineModel.zoneId$;
  }

  get zoneId(): ZoneId {
    return this._timelineModel.zoneId;
  }

  set zoneId(value: ZoneId) {
    this._timelineModel.zoneId = value;
  }

  get primaryTemporalUnit() {
    return this._timelineModel.primaryTemporalUnit;
  }

  get nowTime$(): Observable<Instant> {
    return this._timelineModel.nowTime$;
  }

  set nowTime(value: Instant) {
    this._timelineModel.nowTime = value;
  }

  get nowTime(): Instant {
    return this._timelineModel.nowTime;
  }

  get millisPerPixel(): number {
    return this._timelineModel.millisPerPixel;
  }

  get startTime(): Instant {
    return this._timelineModel.startTime;
  }

  get endTime(): Instant {
    return this._timelineModel.endTime;
  }

  get zoomFactor() {
    return this._zoomFactor;
  }

  set zoomFactor(value: number) {
    this._zoomFactor = value;
  }

  public calculateLocationForTimeMillis(time?: number): number {
    return this._timelineModel.calculateLocationForTimeMillis(time);
  }

  public calculateLocationForTime(time?: Instant): number {
    return this._timelineModel.calculateLocationForTime(time);
  }

  public calculateTimeForLocation(location: number): Instant {
    return this._timelineModel.calculateTimeForLocation(location);
  }

  public calculateWidthForDuration(duration: Duration): number {
    return this._timelineModel.calculateWidthForDuration(duration);
  }

  public getSynchronizationModel() {
    return this._timelineModel.toTimelineSynchronization();
  }

  public setSynchronizationModel(model: ITimelineSynchronization) {
    this._timelineModel.synchronize(model);
  }

  protected async emitTimelineRefreshEvent() {
    this._ganttEvents.onTimelineRefreshEvent({ startTime: this._timelineModel.startTime.toEpochMilli() });
  }

  public async zoomIn() {
    await this.zoom(true);
  }

  public async zoomOut() {
    await this.zoom(false);
  }

  public async zoom(zoomIn: boolean, anchorTime?: Instant, zoomFactor: number = this._zoomFactor) {
    const model = this._timelineModel;
    let { startTime } = model;
    let endTime = model.calculateTimeForLocation(this._width);

    const delta = endTime.toEpochMilli() - startTime.toEpochMilli();

    if (!anchorTime) {
      switch (this._zoomMode) {
        case TimelineZoomMode.NOW_TIME:
          anchorTime = model.nowTime;
          break;
        case TimelineZoomMode.CENTER:
          anchorTime = model.calculateTimeForLocation(this._offset + (this._width - this._offset) / 2);
          break;
        case TimelineZoomMode.KEEP_START_TME:
          anchorTime = startTime;
          break;
        case TimelineZoomMode.KEEP_END_TIME:
          anchorTime = endTime;
          break;
      }
    }

    if (anchorTime != null) {
      const frozenXBefore = model.calculateLocationForTime(anchorTime);

      if (zoomIn) {
        startTime = startTime.plusMillis((zoomFactor * delta) / 2);
        endTime = endTime.minusMillis((zoomFactor * delta) / 2);
      } else {
        startTime = startTime.minusMillis((zoomFactor * delta) / 2);
        endTime = endTime.plusMillis((zoomFactor * delta) / 2);
      }

      if (startTime.isBefore(endTime)) {
        const limitReached = this.showRange(new TimeInterval(startTime, endTime));

        if (limitReached) {
          return;
        }

        const frozenXAfter = model.calculateLocationForTime(anchorTime);
        const deltaX = frozenXBefore - frozenXAfter;
        const deltaTime = model.calculateTimeForLocation(this._offset + deltaX);

        const deltaMillis = deltaTime.toEpochMilli() - startTime.toEpochMilli();
        const adjustedStartTime = startTime.minusMillis(deltaMillis);

        model.startTime = adjustedStartTime;
        model.endTime = model.calculateTimeForLocation(this._width);
        await this._dateLineManager.buildScales();
        await this._dateLineManager.build();
        await this.emitTimelineRefreshEvent();
      }
    }
  }

  public showRange(interval: TimeInterval): boolean {
    if (this._width === 0) {
      // this._requestedInterval$$.next(interval);
      return true;
    }
    // this._requestedInterval$$.next(null);

    const mppWidth = this._width - this._offset;

    const st = interval.startTime.toEpochMilli();
    const et = interval.endTime.toEpochMilli();

    const mppMin = this._timelineModel.minMillisPerPixel;
    const mppMax = this._timelineModel.maxMillisPerPixel;
    const mpp = Math.round(Math.max(mppMin, Math.min(mppMax, (et - st) / mppWidth)));

    let limitReached = false;
    if (mpp === mppMin || mpp === mppMax) {
      limitReached = true;
    }

    const isZoomAnimated = false;
    if (!limitReached && isZoomAnimated) {
      // TODO animated zoom?
    } else if (!limitReached) {
      this._timelineModel.startTime = interval.startTime;
      this._timelineModel.endTime = this._timelineModel.calculateTimeForLocation(this._width);
      this._timelineModel.millisPerPixel = mpp;
    }
    // this._requestedInterval$$.next(interval);
    return limitReached;
  }

  public async doScroll(percentage: number) {
    const startTime = this._timelineModel.startTime.toEpochMilli();
    const visible = this._timelineModel.calculateTimeForLocation(this._width).toEpochMilli() - startTime;
    const jump = (visible / 100) * percentage;

    const targetStartTime = Instant.ofEpochMilli(Math.round(startTime + jump));

    this._timelineModel.startTime = targetStartTime;
    this._timelineModel.endTime = this._timelineModel.calculateTimeForLocation(this._width);

    await this._dateLineManager.build();
    await this.emitTimelineRefreshEvent();
  }

  public async synchronize(model: ITimelineSynchronization) {
    this._timelineModel.synchronize(model);
    await this._dateLineManager.buildScales();
    await this._dateLineManager.build();
    // console.log("\ttimeline synchronized...");
  }
}
