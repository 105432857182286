import { ApiClient } from "@/services/api/common.api";
import {
  CalendarDto,
  CreateCalendarResourceCommand,
  GetCalendarResourcesResponse,
  GetCalendarResourcesPaginatedQuery,
  IAgGridPaginationResponse,
  UpdateCalendarResourceCommand,
  CreateResourceResponse
} from "@masta/generated-model";

export default {
  async getAll(scenarioId: string) {
    return ApiClient.get<GetCalendarResourcesResponse>(`/calendarResources?scenarioId=${scenarioId}`);
  },
  async getPaginated(request: GetCalendarResourcesPaginatedQuery) {
    return ApiClient.post<IAgGridPaginationResponse<CalendarDto>>(`/calendarResources`, request);
  },
  async get(id: string, scenarioId: string) {
    return ApiClient.get(`/calendarResources/${id}?scenarioId=${scenarioId}`);
  },
  async create(request: CreateCalendarResourceCommand) {
    return ApiClient.put<CreateResourceResponse>(`/calendarResources`, request);
  },
  async update(request: UpdateCalendarResourceCommand) {
    return ApiClient.put<string>(`/calendarResources/${request.id}`, request);
  },
  async remove(id: string, scenarioId: string) {
    return ApiClient.delete(`/calendarResources/${id}?scenarioId=${scenarioId}`);
  }
};
