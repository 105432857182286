<script lang="ts" setup>
import GridWrapper from "@/components/Grid/GridWrapper.vue";
import { ref } from "vue";
import { GridWrapperComponent } from "@/components/Grid/GridWrapperComponent";
import { GridApi } from "ag-grid-community";
import { useModelInstancesStore } from "@/store/ModelInstancesStore";
import { storeToRefs } from "pinia";
import { $t } from "@/i18n";

const store = useModelInstancesStore();
const { schemas } = storeToRefs(store);

const $emits = defineEmits(["schemaSelected", "editSchema"]);

const gridWrapperRef = ref<GridWrapperComponent>();

async function onFetchData() {
  await store.fetchSchemas();
}

async function onRefreshAction() {
  await onFetchData();
  $emits("schemaSelected", null);
}

const defaultColumnDef = ref({
  floatingFilter: true,
  filterParams: {
    applyMiniFilterWhileTyping: true
  }
});

function onPrepareColumns(columnDefs: any) {
  columnDefs.value = [
    {
      field: "schemaName",
      type: ["textFloatingFilterColumnType"],
      editable: false,
      resizable: true,
      sortable: true,
      filter: "agTextColumnFilter",
      headerValueGetter: (_: any) => $t("schema-list-name-label", { $: "Schema" }),
      floatingFilterComponentParams: {
        placeholder: $t("schema-list-name-label", { $: "Schema" })
      }
    },
    {
      field: "version",
      type: ["textFloatingFilterColumnType"],
      editable: false,
      resizable: true,
      sortable: true,
      filter: "agTextColumnFilter",
      headerValueGetter: (_: any) => $t("schema-list-version-label", { $: "Version" }),
      floatingFilterComponentParams: {
        placeholder: $t("schema-list-version-label", { $: "Version" })
      }
    }
  ];
}

function onRowSelected({ api }: { api: GridApi }) {
  const rows = api.getSelectedRows();
  if (rows.length === 1) {
    $emits("schemaSelected", rows[0]);
  } else {
    $emits("schemaSelected", null);
  }
}

function onEditAction(schema: any) {
  $emits("editSchema", schema);
}
</script>

<template>
  <grid-wrapper
    ref="gridWrapperRef"
    refresh-btn
    custom-edit-action
    row-selection="single"
    identifier="schemas"
    :grid-data="schemas"
    :default-col-def="defaultColumnDef"
    @fetch-data="onFetchData"
    @refresh-action="onRefreshAction"
    @prepare-columns="onPrepareColumns"
    @row-selected="onRowSelected"
    @edit-action="onEditAction"
  />
</template>

<style lang="scss" scoped></style>
