import { CellClickedEvent, IRowNode, RowGroupOpenedEvent } from "ag-grid-community";


/** 

Set the behavior of grid and grouping rows to ensure that only one row is expanded at a time.

**/
export function onGroupOpenedExpandModeListener(openedEvent: RowGroupOpenedEvent) {
    const { api: gridApi, node: openedRowNode } = openedEvent;

    if (openedRowNode.expanded) {
        gridApi.forEachNode((rowNode: IRowNode, index: number) => {
  
          if (rowNode.expanded !== undefined) {
            if (openedRowNode.id !== rowNode.id && rowNode.expanded) {
              rowNode.setExpanded(false);
            }
          }
        })
      }
}

/**
 * Handle the cell clicked event on the auto group column. 
 * 
 * Here we check if the cell clicked is the auto group column and if the row is expandable.
 * If it is, we toggle the expanded state of the row.
 */
export function onAutoGroupColumnCellClicked(event: CellClickedEvent) {
  const { node, column } = event;

  if (node.isExpandable()) {
    if (column.getColId() === "ag-Grid-AutoColumn") {
      node.setExpanded(!node.expanded);
    }
  }
}