<script lang="ts" setup>
import { $t } from "@/i18n";
import QrcodeVue from "qrcode.vue";

const props = defineProps<{
  modelValue: boolean;
  link: string;
}>();
const emit = defineEmits<{
  (e: "update:modelValue", value: boolean): void;
  (e: "settingSelected", setting: string): void;
}>();

function close() {
  setModal(false);
}

function setModal(visible: boolean) {
  emit("update:modelValue", visible);
}
</script>

<template>
  <v-dialog :model-value="modelValue" width="auto" @update:model-value="setModal($event)">
    <v-card class="align-center pa-4">
      <v-card-title class="text-h4">{{ $t("qrCode-dialog-scanHyperLink-label", { $: "Scan QR code using camera" }) }}</v-card-title>
      <v-card-text>
        <qrcode-vue id="qrcode" :value="props.link" :size="250" level="L" />
      </v-card-text>
      <v-card-actions>
        <v-btn class="ma-4" block variant="elevated" color="secondary" text @click="close">
          {{ $t("qrCode-dialog-close-label", { $: "Close" }) }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
