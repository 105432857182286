import ApiService from "@/services/api";
import { ServerSideDataSource } from "@/components/Grid/ServerSideDataSource";
import { CreateOrUpdateAvailabilityRuleCommand, GetAvailabilityRulesPaginatedQuery, IAgGridPaginationResponse, AvailabilityRuleDataDto } from "@masta/generated-model";
import { IServerSideGetRowsParams } from "ag-grid-enterprise";
import { Store } from "pinia";
import { State as ScenarioState, useScenariosStore } from "@/store/ScenariosStore";
import { GUID_EMPTY } from "@/components/ValueCellEditor/CommonFormatters";

type CreateAvailabilityRuleCommandType = CreateOrUpdateAvailabilityRuleCommand;
type UpdateAvailabilityRuleCommandType = CreateOrUpdateAvailabilityRuleCommand;

export class AvailabilityServerSideDataSource extends ServerSideDataSource<
  AvailabilityRuleDataDto,
  AvailabilityRuleDataDto,
  CreateAvailabilityRuleCommandType,
  UpdateAvailabilityRuleCommandType,
  string
> {
  private _scenarioStore: Store<string, ScenarioState>;

  constructor(id: string) {
    super(id);
    this._scenarioStore = useScenariosStore();
  }

  async getAll(params: IServerSideGetRowsParams): Promise<IAgGridPaginationResponse<AvailabilityRuleDataDto>> {
    const scenarioId = this._scenarioStore.selectedScenario?.id;
    if (!scenarioId) return { results: [], count: 0 } as IAgGridPaginationResponse<AvailabilityRuleDataDto>;

    const request = {
      ...this.constructServerRequest(params),
      scenarioId: scenarioId
    } as GetAvailabilityRulesPaginatedQuery;
    const response = await ApiService.availabilityRules.getPaginated(request);
    return response.data as IAgGridPaginationResponse<AvailabilityRuleDataDto>;
  }

  async getSingle(id: string): Promise<AvailabilityRuleDataDto | undefined> {
    const scenarioId = this._scenarioStore.selectedScenario?.id;
    if (!scenarioId) return;
    const response = await ApiService.availabilityRules.get(id, scenarioId);
    return response.data;
  }

  async create(entity: AvailabilityRuleDataDto): Promise<string> {
    const request = this.transformForCreate(entity);
    const scenario = this._scenarioStore.selectedScenario;
    request.scenarioId = scenario?.id ?? "";
    const response = await ApiService.availabilityRules.create(request);
    return response.data;
  }

  async update(entity: AvailabilityRuleDataDto): Promise<string> {
    const request = this.transformForUpdate(entity);
    await ApiService.availabilityRules.update(request);
    return entity.id;
  }

  async remove(entity: AvailabilityRuleDataDto): Promise<void> {
    await ApiService.availabilityRules.remove(entity.id, entity.scenarioId);
    return;
  }

  protected transformForCreate(entity: AvailabilityRuleDataDto): CreateAvailabilityRuleCommandType {
    return {
      scenarioId: this._scenarioStore.selectedScenario?.id ?? GUID_EMPTY,
      businessId: entity.businessId,
      name: entity.name,
      availability: entity.availability,
      recurrence: entity.recurrence,
      end: entity.end,
      start: entity.start,
      color: entity.color
    } as CreateOrUpdateAvailabilityRuleCommand;
  }

  protected transformForUpdate(entity: AvailabilityRuleDataDto): UpdateAvailabilityRuleCommandType {
    const commandPayload = this.transformForCreate(entity);
    commandPayload.id = entity.id;
    return commandPayload;
  }
}
