import {
  ActivityBounds,
  GanttEvents,
  GanttException,
  GanttSettings,
  IActivityFactory,
  ILayer,
  IocContainer,
  IocSymbols,
  IRow,
  IRowChartData,
  IRowFactory,
  LassoRowSelectionEvent,
  Layer,
  Lifecycle,
  ObservableDataSet,
  Row,
  RowChartRepository,
  SettingKey
} from "./../Core";
import { inject, injectable } from "inversify";
import { IDataLoaderActivityOperation } from "../Worker";
import { BehaviorSubject } from "rxjs";
import { WebWorkerRowContainer } from "./View";

@injectable()
export class WebWorkerRowManager extends Lifecycle {
  private _rowContainers = new Map<string, WebWorkerRowContainer<any>>();

  constructor(
    @inject(IocSymbols.LayersSymbol) private _layers: BehaviorSubject<Layer[]>,
    @inject(IocSymbols.RowsSymbol) private _rows: ObservableDataSet<Row<any, any, any>>,
    @inject(IocContainer) private _iocContainer: IocContainer,
    @inject(GanttEvents) private _ganttEvents: GanttEvents,
    @inject(GanttSettings) private _ganttSettings: GanttSettings,
    @inject(RowChartRepository) private _rowChartRepository: RowChartRepository,
    @inject(IocSymbols.RowContainerFactorySymbol) private _rowIocContainerFactory: (row: Row<any, any, any>) => Promise<WebWorkerRowContainer<any>>
  ) {
    super();
  }

  geRowContainer(rowId: string): WebWorkerRowContainer<any> {
    const container = this._rowContainers.get(rowId);
    if (!container) {
      throw new GanttException(`Row container for row id ${rowId} not found`);
    }
    return container;
  }

  finalizer(): void {
    console.warn("WebWorkerRowManager.finalizer");
  }

  async afterInitialize(): Promise<void> {
    // const dataLoaderWorker = this._iocContainer.get<Remote<IDataLoaderWorker>>(IocSymbols.GanttDataLoaderSymbol);
    // await dataLoaderWorker.onLayersModified(proxy(this.onLayersChanged.bind(this)));
    // await dataLoaderWorker.onRowAdded(proxy(this.onRowAdded.bind(this)));
    // await dataLoaderWorker.onRowUpdated(proxy(this.onRowUpdated.bind(this)));
    // await dataLoaderWorker.onRowDeleted(proxy(this.onRowDeleted.bind(this)));
    // await dataLoaderWorker.onActivitiesAdded(proxy(this.onActivitiesAdded.bind(this)));
    // await dataLoaderWorker.onActivitiesRemoved(proxy(this.onActivitiesRemoved.bind(this)));
    // await dataLoaderWorker.onRowChartDataChanged(proxy(this.onRowChartDataChanged.bind(this)));

    //
    //  Lasso selection
    //
    this.subscribe(
      this._ganttEvents.lassoRowSelectionEvent$.subscribe((e) => {
        const bounds = this.getActivitiesForLasso(e);
        for (const rowContainer of this._rowContainers.values()) {
          rowContainer.onLassoBoundsSelection(bounds);
        }
      })
    );
  }

  get rows(): ObservableDataSet<Row<any, any, any>> {
    return this._rows;
  }

  updateRowHeight(rowId: string, height: number): void {
    const row = this._rows.data.get(rowId);
    if (row) {
      row.height = height;
    }
  }

  private onLayersChanged(layers: ILayer[]): void {
    const currentLayers = this._layers.value;
    const existing = currentLayers.filter((x) => layers.map((l) => l.id).includes(x.id));
    const removed = currentLayers.filter((x) => !layers.map((l) => l.id).includes(x.id));
    const added = layers
      .filter((x) => !currentLayers.map((l) => l.id).includes(x.id))
      .map((x) => {
        const layer = new Layer(x.name, x.id);
        layer.visible = x.visible;
        layer.opacity = x.opacity;
        layer.disablePointerEvents = x.disablePointerEvents;
        return layer;
      });
    for (const layer of existing) {
      const changedLayer = layers.find((x) => x.id === layer.id);
      if (changedLayer) {
        layer.visible = changedLayer.visible;
        layer.opacity = changedLayer.opacity;
        layer.disablePointerEvents = changedLayer.disablePointerEvents;
      }
    }
    this._layers.next([...existing, ...added]);
    console.debug("[Gantt] [WW] [WebWorkerRowManager] onLayersChanged", this._layers.value);
  }

  private async onRowsChanged(rows: IRow[]): Promise<void> {}

  private async onRowAdded(row: IRow): Promise<void> {
    console.debug("[Gantt] [WW] [WebWorkerRowManager] onRowAdded", row.name);
    const rowInstance = (await this._iocContainer.getAsync<IRowFactory>(IocSymbols.RowFactorySymbol)).createRow(row, [...this._rows.data.values()]);
    const container = await this._rowIocContainerFactory(rowInstance);
    this._rowContainers.set(rowInstance.id, container);
    this._rows.add(rowInstance);
  }

  private onRowUpdated(row: IRow): void {
    const resourceRow = this._rows.data.get(row.id) as Row<any, any, any> | undefined;
    if (resourceRow) {
      if (row.parentId !== resourceRow.parentId) {
        throw new GanttException("Changing parent of a row is not supported");
      }
      // const removedIds = resourceRow.childrenIds.filter((childId) => row.childrenIds.includes(childId));
      // console.log("removedIds", removedIds);
      // for (const removedId of removedIds) {
      //   const child = this._rows.data.get(removedId);
      //   if (child) {
      //     resourceRow.removeChild(child);
      //   }
      // }
      // this._rows.remove(removedIds);
    }
  }

  private async onRowDeleted(row: IRow) {
    console.log("onRowDeleted", row.name);
    const r = this._rows.data.get(row.id);
    const parent: Row<any, any, any> | undefined = r?.parent;

    await this.removeRowContainer(r as Row<any, any, any>);
    if (parent) {
      parent.removeChild(row.id);
      parent.childrenIds.splice(parent.childrenIds.indexOf(row.id), 1);
    }
  }

  private async removeRowContainer(row: Row<any, any, any>) {
    for (const child of row.children.data.values()) {
      await this.removeRowContainer(child);
    }
    const rowContainer = this._rowContainers.get(row.id);
    if (rowContainer) {
      await rowContainer.destroy();
      this._rowContainers.delete(row.id);
    }
    this._rows.remove(row.id);
  }

  private onActivitiesAdded(operation: IDataLoaderActivityOperation): void {
    const layer = this._layers.value.find((x) => x.id === operation.layerId);
    if (!layer) {
      throw new GanttException(`Layer with id ${operation.layerId} not found`);
    }
    const row = this._rows.data.get(operation.rowId);
    if (!row) {
      throw new GanttException(`Row with id ${operation.layerId} not found`);
    }
    if (row) {
      this._iocContainer.getAsync<IActivityFactory>(IocSymbols.ActivityFactory).then((factory) => {
        const activities = operation.addedActivities.map((x) => factory.createActivity(x));
        row.addActivities(layer, activities);
      });
    }
  }

  private onActivitiesRemoved(operation: IDataLoaderActivityOperation): void {
    const layer = this._layers.value.find((x) => x.id === operation.layerId);
    if (!layer) {
      throw new GanttException(`Layer with id ${operation.layerId} not found`);
    }
    const row = this._rows.data.get(operation.rowId);
    if (!row) {
      throw new GanttException(`Row with id ${operation.layerId} not found`);
    }
    if (row) {
      const activities = row.repository.getActivitiesById(layer, operation.removedActivityIds);
      row.removeActivities(layer, activities);
    }
  }

  private getActivitiesForLasso(lasso: LassoRowSelectionEvent): Array<ActivityBounds> {
    const bounds = [];
    for (const rc of this._rowContainers.values()) {
      for (const selectedRow of lasso.selectedRows) {
        if (selectedRow.id === rc.row.id) {
          bounds.push(...rc.activityBounds.filter((b) => this.isActivityInLasso(lasso, selectedRow.offsetTop, b)));
          break;
        }
      }
    }
    return bounds;
  }

  private isActivityInLasso(lasso: LassoRowSelectionEvent, offsetTop: number, bounds: ActivityBounds): boolean {
    const { xStart, xEnd, yStart, yEnd } = lasso;
    const { x, y, width, height } = bounds;
    const eYStart = yStart - offsetTop;
    const eYEnd = yEnd - offsetTop;
    const intersectsX = (xStart >= x && xStart <= x + width) || (xEnd >= x && xEnd <= x + width) || (xStart <= x && xEnd >= x + width);
    const intersectsY = (eYStart >= y && eYStart <= y + height) || (eYEnd >= y && eYEnd <= y + height) || (eYStart <= y && eYEnd >= y + height);
    return intersectsX && intersectsY;
  }

  private onRowChartDataChanged(datasets: IRowChartData[]) {
    datasets.forEach((dataset) => {
      this._rowChartRepository.setData({
        ...dataset,
        positiveColor: this._ganttSettings.getSetting(SettingKey.CHART_POSITIVE_COLOR),
        negativeColor: this._ganttSettings.getSetting(SettingKey.CHART_NEGATIVE_COLOR),
        lineWidth: this._ganttSettings.getSetting<number>(SettingKey.CHART_LINE_WIDTH)
      });
    });
  }
}
