import { inject, injectable } from "inversify";
import { DatelineHoverEvent, GanttEvents, TimeInterval, TimelineManager } from "../../../../Core";
import { LayerCanvas } from "./LayerCanvas";
import { Instant } from "@js-joda/core";

@injectable()
export class TimelineHoverLayer extends LayerCanvas {
  private _timeInterval: TimeInterval | null;

  constructor(@inject(TimelineManager) timelineManager: TimelineManager, @inject(GanttEvents) ganttEvents: GanttEvents) {
    super(timelineManager, ganttEvents, TimelineHoverLayer.name, "event-line-layer");
  }

  async beforeInitialize(): Promise<void> {
    return super.beforeInitialize();
  }

  async afterInitialize(): Promise<void> {
    await super.afterInitialize();
    this.subscribe(this._ganttEvents.datelineHoverEvent$.subscribe((interval) => this.onHoverIntervalChange(interval)));
  }

  private onHoverIntervalChange(interval: DatelineHoverEvent | null) {
    this._timeInterval = interval ? new TimeInterval(Instant.ofEpochMilli(interval.start), Instant.ofEpochMilli(interval.end)) : null;
    this.batchDraw();
  }

  async doDrawFromBatch(): Promise<void> {
    this.clear();
    if (!this._timeInterval) return;
    const { startTime, endTime } = this._timeInterval;

    const x1 = this.getLocation(startTime);
    const x2 = this.getLocation(endTime);
    this.context.fillStyle = "rgba(94,106,162,0.16)";
    this.context.fillRect(x1 + this.offset, 0, x2 - x1 + this.offset, this.canvas.clientHeight);
  }
}
