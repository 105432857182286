<script lang="ts" setup>
import { onBeforeUnmount, ref, toRef, watch } from "vue";
import { GridApi, SelectionChangedEvent } from "ag-grid-community";
import { AvailabilityRulesController } from "@/services/api-service";
import { $t } from "@/i18n";
import GenerateCalendarsDialog from "@/components/Resources/GenerateCalendarsDialog.vue";
import { getSelectedRows } from "@/components/Grid/UseGridSelection";
import { useScenariosStore } from "@/store/ScenariosStore";

interface Props {
  gridApi?: GridApi;
}

const $props = defineProps<Props>();

const dialog = ref(false);
const selectedResourceIds = ref<string[]>([]);
const scenariosStore = useScenariosStore();

const watchHandler = watch(toRef($props, "gridApi"), (value) => {
  if (value) {
    $props.gridApi?.addEventListener("selectionChanged", onSelectionChanged);
    new AvailabilityRulesController().getAll(scenariosStore.selectedScenario?.id);
    watchHandler();
  }
});

onBeforeUnmount(() => {
  $props.gridApi?.removeEventListener("selectionChanged", onSelectionChanged);
});

const isAnyRowSelected = ref(false);

function onSelectionChanged(e: SelectionChangedEvent) {
  isAnyRowSelected.value = getSelectedRows(e.api).length > 0;
  selectedResourceIds.value = getSelectedRows(e.api).map((x) => x.id);
}
</script>

<template>
  <v-tooltip location="bottom" open-delay="300">
    <template #activator="{ props }">
      <div class="d-inline-flex pr-4">
        <v-btn size="small" variant="text" density="compact" v-bind="props" :disabled="!isAnyRowSelected" @click="dialog = true">
          <v-icon icon="mdi-calendar" class="pr-4" />
          {{ $t("resource-generateCalendars-generate-action", { $: "Generate calendars" }) }}
        </v-btn>
      </div>
    </template>
    <span>{{ $t("resource-generateCalendars-generate-action", { $: "Generate calendars" }) }}</span>
  </v-tooltip>
  <generate-calendars-dialog v-model="dialog" :resource-ids="selectedResourceIds" />
</template>
