import { inject, injectable } from "inversify";
import { GanttEvents, GanttSettings, IocSymbols, type IRowChartScaleProvider, Row, SettingKey, TimelineManager } from "@masta/gantt2/core";
import { LayerCanvas } from "@masta/gantt2/gantt";
import { ResourceRow } from "@/components/Gantt/Common/Model";
import { DemandChartLayerRenderer } from "@/components/Gantt/ResourcesGantt/Layers/Renderers/DemandChartLayerRenderer";
import { SupplyChartLayerRenderer } from "@/components/Gantt/ResourcesGantt/Layers/Renderers/SupplyChartLayerRenderer";
import { CustomSettingKeys } from "@/components/Gantt/ResourcesGantt/CustomSettingKeys";
import { GanttChartResourceTypeVisibilitySetting, GanttLayerSettings, GanttResourceDto, ResourceType } from "@masta/generated-model";

@injectable()
export class SupplyDemandChartLayer extends LayerCanvas {
  private _scaleValues: number[] = [];
  private _resourceTypeVisibilitySetting: GanttChartResourceTypeVisibilitySetting;
  private _resourceType: ResourceType;

  constructor(
    @inject(Row<any, any, any>) private _row: ResourceRow,
    @inject(TimelineManager) timelineManager: TimelineManager,
    @inject(GanttEvents) ganttEvents: GanttEvents,
    @inject(IocSymbols.RowChartScaleProvider) private _rowChartScaleProvider: IRowChartScaleProvider,
    @inject(GanttSettings) private _settings: GanttSettings,
    @inject(DemandChartLayerRenderer.Identifier) private _demandRenderer: DemandChartLayerRenderer,
    @inject(SupplyChartLayerRenderer.Identifier) private _supplyRenderer: SupplyChartLayerRenderer
  ) {
    super(timelineManager, ganttEvents, SupplyDemandChartLayer.name, "supply-demand-chart-layer");
    this._resourceType = (this._row.userObject as unknown as GanttResourceDto).type;
  }

  async beforeInitialize(): Promise<void> {
    await super.beforeInitialize();
  }

  async afterInitialize(): Promise<void> {
    await super.afterInitialize();
    this.subscribe(this._settings.getSetting$<boolean>(SettingKey.CHART_SHOW).subscribe((s) => {
      this.enabled = s ?? false;
      this.visible = s ?? false;
      this.batchDraw();
    }));
    this.subscribe(
      this._rowChartScaleProvider.scaleValues$.subscribe((value) => {
        this._scaleValues = value;
        this.clear();
        this.batchDraw();
      })
    );
    this.subscribe(this._settings.getSetting$<GanttLayerSettings[]>(CustomSettingKeys.LAYERS, []).subscribe((layerSettings) => {
      const settings = layerSettings?.find(x => x.id === SupplyDemandChartLayer.name);
      if (settings) {
        this.opacity = settings.opacity;
      }
      this.clear();
      this.batchDraw();
    }));
    this.subscribe(this._settings.getSetting$<GanttChartResourceTypeVisibilitySetting[]>(CustomSettingKeys.CHART_RESOURCE_TYPE_VISIBILITY_SETTINGS, []).subscribe((s) => {
      const settings = s ?? [];

      const setting = settings.find(x => x.type === this._resourceType);

      if (setting && (!this._resourceTypeVisibilitySetting || this._resourceTypeVisibilitySetting.visible !== setting?.visible)) {
        this._resourceTypeVisibilitySetting = setting;
      }

      this.clear();
      this.batchDraw();
    }));
  }

  async doDrawFromBatch(): Promise<void> {
    if (!this._row.visible) return;
    if (!this.enabled || !this.visible) return;

    this.clear();

    if (this._resourceTypeVisibilitySetting && !this._resourceTypeVisibilitySetting.visible) {
      return;
    }

    this.context.globalAlpha = this.opacity;

    this._supplyRenderer.render(this.canvas as any, this.context as any, { scaleValues: this._scaleValues });
    this._demandRenderer.render(this.canvas as any, this.context as any, { scaleValues: this._scaleValues });
  }
}
