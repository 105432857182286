import {
  BaseRowInfoColumnProvider,
  GanttSettings,
  IocSymbols,
  IRowInfoColumn,
  IRowInfoColumnHeader, ObservableDataSet,
  ObservedObject,
  observeObject, Row,
  SettingKey
} from "@masta/gantt2/core";
import { inject, injectable } from "inversify";
import { useI18n } from "vue-i18n";
import { AppIocSymbols } from "@/components/Gantt/ResourcesGantt/AppIocSymbols";
import { CustomSettingKeys } from "@/components/Gantt/ResourcesGantt/CustomSettingKeys";
import { GanttChartResourceTypeVisibilitySetting, GanttResourceDto } from "@masta/generated-model";
import { combineLatest, debounceTime } from "rxjs";

@injectable()
export class ResourceGanttRowInfoColumnProvider extends BaseRowInfoColumnProvider {
  protected _columns: ObservedObject<IRowInfoColumn>[] = [
    observeObject({ id: "text", className: "text", width: 150, visible: true }),
    observeObject({ id: "demand-supply-info", className: "demand-supply-info", width: 50, visible: true }),
    observeObject({ id: "scale", className: "scale", width: 80, visible: true })
  ];
  protected _headers: IRowInfoColumnHeader[] = [];
  private $t: any;

  constructor(
    @inject(IocSymbols.HtmlContainerSymbol) container: HTMLDivElement,
    @inject(GanttSettings) private _ganttSettings: GanttSettings,
    @inject(AppIocSymbols.$i18n) private _i18n: ReturnType<typeof useI18n>,
    @inject(IocSymbols.RowsSymbol) private _rows: ObservableDataSet<Row<any, any, any>>
  ) {
    super(container);
    this.$t = this._i18n.t;

    this._headers = [
      { id: "text", className: "text", text: this.$t("gantt-resource-column-name-label", { $: "Name" }) } as IRowInfoColumnHeader,
      { id: "demand-supply-info", className: "demand-supply-info", text: this.$t("gantt-resource-column-demandSupply-label", { $: "Demand<br>Supply" }) } as IRowInfoColumnHeader,
      { id: "scale", className: "scale", text: this.$t("gantt-resource-column-scale-label", { $: "Scale" }) } as IRowInfoColumnHeader
    ];
  }

  async beforeInitialize(): Promise<void> {
    await super.beforeInitialize();
    this.subscribe(
      this._ganttSettings.getSetting$<number>(SettingKey.CHART_HEADER_WIDTH).subscribe((s) => {
        this.setColumnWidth("scale", s!);
      })
    );
    this.subscribe(
      this._ganttSettings.getSetting$<boolean>(SettingKey.CHART_SHOW).subscribe((s) => {
        this.setColumnEnabled("scale", s ?? true);
      })
    );
    this.subscribe(
      combineLatest([
        this._rows.data$.pipe(debounceTime(20)),
        this._ganttSettings.getSetting$<GanttChartResourceTypeVisibilitySetting[]>(CustomSettingKeys.CHART_RESOURCE_TYPE_VISIBILITY_SETTINGS)
      ])
        .subscribe(([rowsData, s]) => {
          const settings = s ?? [];

          const rowTypes = rowsData.map((row) => (row.userObject as unknown as GanttResourceDto).type);

          const isAllVisible = rowTypes.every((type) => {
            const setting = settings.find((x) => x.type === type);
            return setting?.visible ?? true;
          });

          if (!isAllVisible) {
            this.setColumnEnabled("scale", false);
          } else {
            this.setColumnEnabled("scale", true);
          }
        })
    );
  }
}
