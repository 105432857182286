<script lang="ts" setup>
import { useDebounce, useDebounceFn, useStorage } from "@vueuse/core";
import ApiService from "@/services/api";
import { MaterialSubType, ResourceInfoDto, ResourceSubType, ResourceType } from "@masta/generated-model";
import { $t } from "@/i18n";
import { translateResourceSubType } from "@/composables/translateEnum";
import { nameOrBusinessIdOrIdOrNull } from "@/components/ValueCellEditor/CommonFormatters";
import FilterGridAction, { FilterGridActionItem } from "@/components/Grid/Filters/FilterGridAction.vue";
import { ref, watch } from "vue";
import { useRouter } from "vue-router";
import { useSearchResourceStore } from "@/components/Search/UseSearchResourceStore";
import { useScenariosStore } from "@/store/ScenariosStore";

const searchText = defineModel<string | null | undefined>({ required: true });

const emits = defineEmits(["close"]);

const { addRecentResource, recentResources, selectedResource } = useSearchResourceStore();

const resourceSubTypes = ref<ResourceSubType[] | undefined>(undefined);
const resourceInfos = ref<ResourceInfoDto[]>([]);
const scenarioStore = useScenariosStore();

const fetchResources = useDebounceFn(async (query: string | null | undefined) => {
  if (!query) {
    resourceInfos.value = [];
    return;
  }
  const response = await ApiService.resources.findResources(query, scenarioStore.selectedScenario?.id ?? "", [ResourceType.Material], resourceSubTypes.value);
  resourceInfos.value = response.data.resources ?? [];
}, 250);

watch(searchText, (newValue) => {
  fetchResources(newValue);
}, { immediate: true });

const filterGridActionItems: FilterGridActionItem[] = [
  { value: MaterialSubType.RawOrSubcomponent, text: translateResourceSubType(ResourceSubType.MaterialRawOrSubcomponent) },
  { value: MaterialSubType.SemiFinishedOrFinalProduct, text: translateResourceSubType(ResourceSubType.MaterialSemiFinishedOrFinalProduct) }
];

function onResourcePickerFilerActionChanged(filterAction: any) {
  if (filterAction) {
    resourceSubTypes.value = Array.isArray(filterAction) ? filterAction : [filterAction];
  } else {
    resourceSubTypes.value = undefined;
  }
  fetchResources(searchText.value);
}

const router = useRouter();

function onResourceClick(resource: ResourceInfoDto) {
  selectedResource.value = resource;

  addRecentResource(resource);

  router.push({ name: "Product Card", query: { product: resource.id, selected: resource.id }, params: { scenarioId: useScenariosStore().scenarioBusinessId } });
  emits("close");
}
</script>

<template>
  <v-row no-gutters class="fill-height" data-cy="resources-search-list">
    <v-col cols="6" class="fill-height overflow-y-auto">
      <div class="">
        <v-list v-if="resourceInfos.length > 0" class="list pa-0">
          <v-fade-transition group>
            <v-list-item
              v-for="resource in resourceInfos"
              :key="resource.id"
              :active="selectedResource?.id === resource.id"
              :title="resource.businessId ?? ''"
              :subtitle="resource.name ?? ''"
              :base-color="selectedResource?.id === resource.id ? 'primary': undefined"
              cols="12"
              class="pa-0 rounded"
              density="compact"
              data-cy="resource"
              @click="onResourceClick(resource)"
            ></v-list-item>
          </v-fade-transition>
        </v-list>
        <div v-else class="list caption d-flex justify-center align-center h-100">
          {{ $t("topSearch-searchProductRequired-caption", { $: "Please type something to search products..." }) }}
        </div>
      </div>
    </v-col>
    <v-divider vertical class="fill-height"></v-divider>
    <v-col cols="6">
      <v-row>
        <v-col>
          <v-card variant="text">
            <v-card-title>
              {{ $t("topSearch-filterResources-label", { $: "Filter resources" }) }}
            </v-card-title>
            <v-card-text class="d-flex justify-start align-center">
              <filter-grid-action
                :items="filterGridActionItems"
                :grow="false"
                :icon="false"
                :prepend-text2="$t('productOverview-parameters-targetProductFilter-label', { $: 'Filter results:' })"
                @filter-changed="onResourcePickerFilerActionChanged"
              />
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-card variant="text">
            <v-card-title>
              {{ $t("topSearch-lastSelectedResources-label", { $: "Last selected resources" }) }}
            </v-card-title>
            <v-card-text class="d-flex align-center flex-wrap">
              <v-chip
                v-for="resource in recentResources"
                :key="resource.id"
                class="last-used-resource-chip mb-2 mr-2"
                variant="elevated"
                size="small"
                elevation="0"
                density="compact"
                @click="onResourceClick(resource)">
                {{ nameOrBusinessIdOrIdOrNull(resource) }}
              </v-chip>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<style scoped lang="scss">
.list {
  max-height: 100%;
  overflow: hidden;
  overflow-y: auto;
}

.last-used-resource-chip {
  background-color: rgb(var(--v-theme-primary), 0.3);
  color: rgb(var(--v-theme-primary));
  min-height: 26px;
}
</style>
