<script lang="ts" setup>
import { InformativeQuantitativeFeatureDefinition } from "@masta/generated-model";
import { $t } from "@/i18n";
import { isNumberRule, isZeroOrGreaterNumberRule, requiredRule } from "@/components/ValueCellEditor/CommonValidationRules";

const props = defineProps<{
  modelValue: InformativeQuantitativeFeatureDefinition;
  disabled: boolean;
}>();

const emit = defineEmits(["update:modelValue", "remove"]);

function update(propertyName: string, value: any) {
  const newFeature = { ...props.modelValue, [propertyName]: value };
  emit("update:modelValue", newFeature);
}

const nameRules = [
  (v: string) => !!v || $t("featureDefinition-informativeQuantitativeEditor-onNameRequired-message", { $: "Name is required" }),
  (v: string) => (v && v.length > 0) || $t("featureDefinition-informativeQuantitativeEditor-onNameNotEmptyRequired-message", { $: "Name must not be empty" })
];
</script>

<template>
  <v-card outlined width="100%">
    <v-card-text>
      <v-col cols="12">
        <!-- First Row: Close Button and Chip -->
        <v-row>
          <v-col cols="4" class="text-center d-flex align-center justify-center">
            <v-chip size="x-large" label class="w-100 text-white" color="accent" variant="flat" :disabled="disabled">
              {{ $t("featureDefinition-informativeQuantitativeEditor-quantitative-label", { $: "Informative Quantitative" }) }}
            </v-chip>
          </v-col>
          <v-col cols="8" class="text-right align-center justify-center">
            <v-btn
              variant="text"
              icon="mdi-close"
              size="large"
              hide-details
              :disabled="disabled"
              @click="emit('remove')"
            ></v-btn>
          </v-col>
        </v-row>
        <!-- Second Row: Name, Label, and Description -->
        <v-row>
          <v-col cols="4">
            <v-text-field
              variant="outlined"
              density="compact"
              hide-details
              :rules="[requiredRule]"
              :label="$t('featureDefinition-informativeQuantitativeEditor-name-label', { $: 'Name' })"
              :model-value="modelValue.name"
              :disabled="disabled"
              @update:model-value="update('name', $event)"
            />
          </v-col>
          <v-col cols="4">
            <v-text-field
              variant="outlined"
              density="compact"
              hide-details
              :label="$t('featureDefinition-informativeQuantitativeEditor-label-label', { $: 'Label' })"
              :model-value="modelValue.label"
              :disabled="disabled"
              @update:model-value="update('label', $event)"
            />
          </v-col>
          <v-col cols="4">
            <v-text-field
              variant="outlined"
              density="compact"
              hide-details
              :label="$t('featureDefinition-informativeQuantitativeEditor-description-label', { $: 'Description' })"
              :model-value="modelValue.description"
              :disabled="disabled"
              @update:model-value="update('description', $event)"
            />
          </v-col>
        </v-row>
        <!-- Third Row: Required Switch, Target, Tolerance Left, and Tolerance Right -->
        <v-row>
          <v-col cols="2">
            <v-switch
              variant="outlined"
              density="compact"
              hide-details
              color="primary"
              :label="$t('featureDefinition-informativeQuantitativeEditor-required-label', { $: 'Required' })"
              :model-value="modelValue.isRequired"
              :disabled="disabled"
              @update:model-value="update('isRequired', $event)"
            />
          </v-col>
          <v-col cols="2">
            <v-text-field
              variant="outlined"
              density="compact"
              hide-details
              :rules="[isNumberRule]"
              :label="$t('featureDefinition-informativeQuantitativeEditor-target-label', { $: 'Target' })"
              type="number"
              :model-value="modelValue.target"
              :disabled="disabled"
              @update:model-value="update('target', Number($event))"
            />
          </v-col>
          <v-col cols="2">
            <v-text-field
              variant="outlined"
              density="compact"
              hide-details
              :rules="[isZeroOrGreaterNumberRule]"
              :label="$t('featureDefinition-informativeQuantitativeEditor-toleranceLeft-label', { $: 'Tolerance Left' })"
              type="number"
              :model-value="modelValue.toleranceLeft"
              :disabled="disabled"
              @update:model-value="update('toleranceLeft', Number($event))"
            />
          </v-col>
          <v-col cols="2">
            <v-text-field
              variant="outlined"
              density="compact"
              hide-details
              :rules="[isZeroOrGreaterNumberRule]"
              :label="$t('featureDefinition-informativeQuantitativeEditor-toleranceRight-label', { $: 'Tolerance Right' })"
              type="number"
              :model-value="modelValue.toleranceRight"
              :disabled="disabled"
              @update:model-value="update('toleranceRight', Number($event))"
            />
          </v-col>
        </v-row>
      </v-col>
    </v-card-text>
  </v-card>
</template>

