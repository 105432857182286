import { InventoryChangeType, ProcessDraftStatus, ResourceInfoDto, ResourceType, StepResourceAssignmentType, StepResourceUsageType, StepType } from "@masta/generated-model";

export enum ProductTemplateDraftType {
  WorkOrder = 1000,
  ProductionTask = 1200
}

export enum MessageType {
  Info,
  Success,
  Warning,
  Error
}

export interface Message {
  type: MessageType;
  text: string;
}

export function getAlertType(type: MessageType) {
  switch (type) {
    case MessageType.Error:
      return "error";
    case MessageType.Warning:
      return "warning";
    case MessageType.Success:
      return "success";
    default:
      return "info";
  }
}

export interface ProcessDraft {
  id?: string;
  scenarioId: string;
  name: string | null;
  status: ProcessDraftStatus;
  productTemplates: ProductTemplateDraft[];
  targetResource: ResourceInfoDto | null;
}

export interface ProductTemplateDraft {
  position: number;
  name: string;
  businessId: string;
  wbs: string;
  taskType: ProductTemplateDraftType;
  steps: StepDraft[];
  childProductTemplates: ProductTemplateDraft[];
  isTopLevel: boolean;
  resource: ResourceInfoDto | null;
  tags: string[];
  message?: Message;
  useExistingTemplate?: boolean;
}

export interface StepDraft {
  name: string;
  type: StepType;
  position: number;
  processingTime: string;
  quantityPerTime: number;
  specs: StepDraftResourceSpec[];
  message?: Message;
}

export interface StepDraftResourceSpec {
  resources: ResourceInfoDto[];
  resourceType: ResourceType;
  usageType: StepResourceUsageType;
  assignmentType: StepResourceAssignmentType;
  quantity: number;
  inventoryChangeThresholdQuantity: number;
  inventoryChangeType: InventoryChangeType;
  message?: Message;
  isBase: boolean;
}

export function recalculateStepPositions(productTemplateDraft: ProductTemplateDraft) {
  for (let i = 0; i < productTemplateDraft.steps.length; i++) {
    const step = productTemplateDraft.steps[i];
    step.position = (i + 1) * 10;
  }
}

export function recalculateWbs(productTemplateDraft: ProductTemplateDraft) {
  for (let i = 0; i < productTemplateDraft.childProductTemplates.length; i++) {
    const childProductTemplate = productTemplateDraft.childProductTemplates[i];
    childProductTemplate.position = i + 1;
    childProductTemplate.wbs = `${productTemplateDraft.wbs}.${childProductTemplate.position.toString().padStart(4, "0")}`;
    recalculateWbs(childProductTemplate);
  }
}

export function recalculateWbsTopLevel(productTemplateDrafts: ProductTemplateDraft[]) {
  for (let i = 0; i < productTemplateDrafts.length; i++) {
    const productTemplateDraft = productTemplateDrafts[i];
    productTemplateDraft.position = i + 1;
    productTemplateDraft.wbs = `${productTemplateDraft.position.toString().padStart(4, "0")}`;
    recalculateWbs(productTemplateDraft);
  }
}

function swapElements<T>(arr: T[], i1: number, i2: number) {
  [arr[i1], arr[i2]] = [arr[i2], arr[i1]];
}

export function moveUpStep(productTemplateDraft: ProductTemplateDraft, step: StepDraft) {
  const idx = productTemplateDraft.steps.findIndex((x) => x === step);
  if (idx > 0) {
    swapElements(productTemplateDraft.steps, idx, idx - 1);
    recalculateStepPositions(productTemplateDraft);
  }
}

export function moveDownStep(task: ProductTemplateDraft, step: StepDraft) {
  const idx = task.steps.findIndex((x) => x === step);
  if (idx >= 0 && idx < task.steps.length - 1) {
    swapElements(task.steps, idx, idx + 1);
    recalculateStepPositions(task);
  }
}

export function moveUpChild(parent: ProductTemplateDraft, child: ProductTemplateDraft) {
  const idx = parent.childProductTemplates.findIndex((x) => x === child);
  if (idx > 0) {
    swapElements(parent.childProductTemplates, idx, idx - 1);
    recalculateWbs(parent);
  }
}

export function moveUpTemplate(productTemplateDrafts: ProductTemplateDraft[], productTemplateDraft: ProductTemplateDraft) {
  const idx = productTemplateDrafts.findIndex((x) => x === productTemplateDraft);
  if (idx > 0) {
    swapElements(productTemplateDrafts, idx, idx - 1);
    recalculateWbsTopLevel(productTemplateDrafts);
  }
}

export function moveDownChild(parent: ProductTemplateDraft, child: ProductTemplateDraft) {
  const idx = parent.childProductTemplates.findIndex((x) => x === child);
  if (idx >= 0 && idx < parent.childProductTemplates.length - 1) {
    swapElements(parent.childProductTemplates, idx, idx + 1);
    recalculateWbs(parent);
  }
}

export function moveDownTemplate(productTemplateDrafts: ProductTemplateDraft[], productTemplateDraft: ProductTemplateDraft) {
  const idx = productTemplateDrafts.findIndex((x) => x === productTemplateDraft);
  if (idx >= 0 && idx < productTemplateDrafts.length - 1) {
    swapElements(productTemplateDrafts, idx, idx + 1);
    recalculateWbsTopLevel(productTemplateDrafts);
  }
}

export function copyTemplate(productTemplateDrafts: ProductTemplateDraft[], productTemplateDraft: ProductTemplateDraft) {
  const idx = productTemplateDrafts.findIndex((x) => x === productTemplateDraft);
  const newTemplate = cloneTemplate(productTemplateDraft);
  productTemplateDrafts.splice(idx + 1, 0, newTemplate);
  recalculateWbsTopLevel(productTemplateDrafts);
}

function cloneTemplate(productTemplateDraft: ProductTemplateDraft): ProductTemplateDraft {
  const newTemplate = { ...productTemplateDraft };
  newTemplate.childProductTemplates = newTemplate.childProductTemplates.map(cloneTemplate);
  newTemplate.steps = newTemplate.steps.map((x) => cloneStep(x));
  return newTemplate;
}

function cloneStep(step: StepDraft): StepDraft {
  return {
    ...step,
    specs: step.specs.map(cloneSpec)
  };
}

function cloneSpec(spec: StepDraftResourceSpec): StepDraftResourceSpec {
  return {
    ...spec,
    resources: [...spec.resources]
  };
}

export function unlockTemplate(productTemplateDraft: ProductTemplateDraft) {
  if (!productTemplateDraft.useExistingTemplate) return;
  unlockTemplateHierarchy(productTemplateDraft);
}

function unlockTemplateHierarchy(productTemplateDraft: ProductTemplateDraft) {
  productTemplateDraft.useExistingTemplate = false;
  if (productTemplateDraft.childProductTemplates) {
    productTemplateDraft.childProductTemplates.forEach((x) => unlockTemplateHierarchy(x));
  }
}

export function replaceResource(process: ProcessDraft, originalResource: ResourceInfoDto, newResource: ResourceInfoDto) {
  if (process.targetResource?.id === originalResource.id) {
    process.targetResource = newResource;
  }

  process.productTemplates.forEach((x) => replaceTemplateResource(x, originalResource, newResource));
}

export function replaceTemplateResource(productTemplateDraft: ProductTemplateDraft, originalResource: ResourceInfoDto, newResource: ResourceInfoDto) {
  let modified = false;
  if (productTemplateDraft.resource?.id === originalResource.id) {
    productTemplateDraft.resource = newResource;
    modified = true;
  }
  if (productTemplateDraft.steps) {
    productTemplateDraft.steps
      .flatMap((x) => x.specs)
      .forEach((x) => {
        if (replaceSpecResource(x, originalResource, newResource)) {
          modified = true;
        }
      });
  }
  if (productTemplateDraft.childProductTemplates) {
    productTemplateDraft.childProductTemplates.forEach((x) => {
      if (replaceTemplateResource(x, originalResource, newResource)) {
        modified = true;
      }
    });
  }
  if (modified && productTemplateDraft.isTopLevel) {
    unlockTemplateHierarchy(productTemplateDraft);
  }
  return modified;
}

function replaceSpecResource(spec: StepDraftResourceSpec, originalResource: ResourceInfoDto, newResource: ResourceInfoDto) {
  let modified = false;
  for (let i = 0; i < spec.resources.length; i++) {
    if (spec.resources[i].id === originalResource.id) {
      spec.resources[i] = newResource;
      modified = true;
    }
  }
  return modified;
}
