<script lang="ts" setup>
import { isDefined } from "@/components/Common/Types";
import { IEnumValueSelectCellEditorParams } from "@/components/Grid/CellEditors/IEnumValueSelectCellEditorParams";
import { INumberInputCellEditorParams } from "@/components/Grid/CellEditors/INumberInputCellEditorParams";
import { enumToEditorEntriesExcluding, translateEditorEntries } from "@/components/Grid/ColumnTypes";
import GridWrapper from "@/components/Grid/GridWrapper.vue";
import { GridWrapperComponent } from "@/components/Grid/GridWrapperComponent";
import { getSelectedNodes, getSelectedRows } from "@/components/Grid/UseGridSelection";
import { useReferenceGrid } from "@/components/Grid/UseReferenceGrid";
import { TagsServerSideDataSource } from "@/components/Tags/TagsServerSideDataSource";
import { generateRandomColorHex } from "@/composables/colorHelpers";
import { $t } from "@/i18n";
import { AgGridFilterModel, TagDto, TagType } from "@masta/generated-model";
import {
  ColDef,
  GetContextMenuItemsParams,
  GetMainMenuItemsParams,
  GetRowIdParams,
  GridReadyEvent,
  RefreshServerSideParams,
  RowDoubleClickedEvent,
  RowNode,
  SelectionChangedEvent,
  ValueGetterParams,
  ValueSetterParams
} from "ag-grid-community";
import { reactive, Ref, ref } from "vue";
import { ITagsPickerCellEditorParams } from "@/components/Grid/CellEditors/TagsPickerCellEditor.vue";

interface Props {
  referenceValue?: string[];
  cqlFilter?: (() => string) | null | undefined;
  agGridFilterModel?: (() => AgGridFilterModel) | null | undefined | any;
  multiselect?: boolean;
  readonly?: boolean;
  single?: boolean;
  referenceValueField?: string;
}

const props = withDefaults(defineProps<Props>(), {
  multiselect: true,
  readonly: false,
  single: false,
  referenceValueField: "name"
});
const emit = defineEmits(["rowSelected", "rowDoubleClicked"]);

const serverSideDataSource = reactive(new TagsServerSideDataSource("tags"));

const DEFAULT_CREATE_VALUE = {
  tagType: TagType.Classification,
  emoji: null,
  rank: 1,
  color: () => generateRandomColorHex()
};

const gridWrapperRef = ref<GridWrapperComponent>();

function isServerSideGroup(dataItem: TagDto) {
  // every row (except the new row) is a group
  return isDefined(dataItem.id);
}

function getServerSideGroupKey(dataItem: TagDto) {
  return dataItem?.id;
}

function getRowId(params: GetRowIdParams<TagDto>) {
  return params.data.id;
}

function getParentIdPropertyName() {
  return "parentId";
}

function requiredRule(v: any): boolean | string {
  return (v !== undefined && v !== null && v !== "") || $t("agreement-edit-valueRequired-tooltip", { $: "Required" });
}

// $t("tag-type-unknown-label", {$: "Unknown"})
// $t("tag-type-Classification-label", {$: "Classification"})
// $t("tag-type-Constraint-label", {$: "Constraint"})
// $t("tag-type-SatisfactionRating-label", {$: "Satisfaction Rating"})
// $t("tag-type-SatisfactionRating-label", {$: "Organization Group"})
function translateTagType(t: TagType): string {
  const key = TagType[t] ?? "Unknown";
  return $t(`tag-type-${key}-label`);
}

function gridApi() {
  return gridWrapperRef.value!.gridApi;
}

function firstSelectedRowNode() {
  return getSelectedNodes(gridApi())[0];
}

async function onDelete() {
  const contextRowNode = firstSelectedRowNode() as RowNode;
  const parentGroupKeys = contextRowNode?.getGroupKeys().slice(0, -1);
  gridApi().refreshServerSide({ route: parentGroupKeys } as RefreshServerSideParams);
}

function onSelectionChanged(event: SelectionChangedEvent) {
  const rows = getSelectedRows(event.api);
  if (rows && rows.length >= 1) {
    emit("rowSelected", rows);
  } else {
    emit("rowSelected", null);
  }
}

function onRowDoubleClicked(event: RowDoubleClickedEvent) {
  const row = event.data as TagDto;
  emit("rowDoubleClicked", row);
}

const defaultColumnDef = ref({
  filter: false,
  floatingFilter: true,
  filterParams: {
    applyMiniFilterWhileTyping: true
  },
  sortable: true,
  resizable: true
});
const autoGroupColumnDef = ref({
  field: "name",
  type: "textInputTypeColumn",
  headerValueGetter: (_: any) => $t("tag-list-name-label", { $: "Name" }),
  editable: true,
  sortable: true,
  minWidth: 330,
  filter: false,
  cellEditorParams: {
    rules: [requiredRule],
    placeholder: $t("tag-edit-name-label", { $: "Name" }),
    isEditEnabled: () => !gridWrapperRef.value?.isUpdating()
  }
});

function onPrepareColumns(columnDefs: Ref<ColDef[]>) {
  columnDefs.value = [
    {
      field: "name",
      type: "textInputTypeColumn",
      headerValueGetter: (_: any) => $t("tag-list-name-label", { $: "Name" }),
      editable: true,
      sortable: true,
      hide: true,
      filter: "agTextColumnFilter",
      cellEditorParams: {
        rules: [requiredRule],
        placeholder: $t("tag-edit-name-label", { $: "Name" })
      }
    },
    {
      field: "tagType",
      type: "enumTypeColumn",
      headerValueGetter: (_: any) => $t("tag-list-type-label", { $: "Type" }),
      editable: true,
      sortable: true,
      valueFormatter: (params: any) => translateTagType(params.value),
      cellEditorParams: {
        rules: [requiredRule],
        placeholder: $t("tag-edit-type-label", { $: "Type" }),
        values: translateEditorEntries(enumToEditorEntriesExcluding(TagType, []), translateTagType)
      } as IEnumValueSelectCellEditorParams
    },
    {
      field: "parentName",
      filter: "agTextColumnFilter",
      headerValueGetter: (_: any) => $t("tag-list-parentName-label", { $: "Parent Name" }),
      editable: true,
      sortable: true,
      hide: true
    },
    {
      field: "rank",
      type: "numberInputTypeColumn",
      headerValueGetter: (_: any) => $t("tag-list-rank-label", { $: "Rank" }),
      editable: true,
      sortable: true,
      cellEditorParams: {
        rules: [requiredRule],
        placeholder: $t("tag-edit-rank-label", { $: "Rank" })
      } as INumberInputCellEditorParams
    },
    {
      field: "emoji",
      type: "emojiPickerTypeColumn",
      headerValueGetter: (_: any) => $t("tag-list-emoji-label", { $: "Emoji" }),
      editable: true,
      sortable: true,
      cellEditorParams: {
        rules: [requiredRule],
        placeholder: $t("tag-edit-emoji-label", { $: "Emoji" })
      }
    },
    {
      field: "color",
      editable: true,
      sortable: true,
      filter: false,
      headerValueGetter: (_: any) => $t("tags-list-color-label", { $: "Color" }),
      type: "colorTypeColumn",
      cellEditorParams: {
        placeholder: $t("tags-list-color-label", { $: "Color" })
      }
    },
    {
      field: "parentName",
      type: "tagsPickerTypeColumn",
      headerValueGetter: (_: any) => $t("tags-list-parentName-label", { $: "Parent" }),
      editable: true,
      sortable: false,
      filter: false,
      resizable: false,
      valueGetter: (params: ValueGetterParams) => {
        return params.data.parentName ? [params.data.parentName] : [];
      },
      valueSetter: (params: ValueSetterParams<TagDto, TagDto | null>) => {
        params.data.parentName = params.newValue?.name ?? null;
        params.data.parentId = params.newValue?.id ?? null;
        return true;
      },
      cellEditorParams: {
        placeholder: $t("tags-list-parentName-label", { $: "Parent" }),
        single: true,
        returnObject: true,
        inputFilter: (params: ITagsPickerCellEditorParams, tag: TagDto) => tag.id !== params.data.id,
        cqlFilter: (params: ITagsPickerCellEditorParams) => `t.Name != '${params.data.name}'`
      }
    }
  ];
}

function getMainMenuItems(params: GetMainMenuItemsParams) {
  params.defaultItems.splice(params.defaultItems.indexOf("rowGroup"), 1);
  params.defaultItems.splice(params.defaultItems.indexOf("rowUnGroup"), 1);
  params.defaultItems.push("expandAll");
  params.defaultItems.push("contractAll");
  return params.defaultItems;
}

function isRowSelected(data: TagDto): boolean {
  const rowTagRefValue = (data as any)[props.referenceValueField ?? "name"];
  if (Array.isArray(props.referenceValue)) {
    return !!props.referenceValue.filter((x) => !!x).find((tagName) => tagName === rowTagRefValue);
  }
  return props.referenceValue === rowTagRefValue;
}

function getContextMenuItems(param: GetContextMenuItemsParams) {
  return [
    {
      name: $t("personnel-list-expandAllGroups-action", { $: "Expand all groups" }),
      icon: "<span class=\"mdi mdi-expand-all\"></span>",
      action: () => gridWrapperRef.value?.gridApi.expandAll()
    },
    {
      name: $t("personnel-list-collapseAllGroups-action", { $: "Collapse all groups" }),
      icon: "<span class=\"mdi mdi-collapse-all\"></span>",
      action: () => gridWrapperRef.value?.gridApi.collapseAll()
    }
  ];
}

function onGridReady(event: GridReadyEvent) {
  referenceGrid.onGridReady(event);
}

const referenceGrid = useReferenceGrid({
  $emits: emit,
  isRowSelected: isRowSelected,
  cqlFilter: props.cqlFilter,
  agGridFilterModel: props.agGridFilterModel
});
</script>

<template>
  <grid-wrapper
    ref="gridWrapperRef"
    identifier="tags"
    :create-default-value="DEFAULT_CREATE_VALUE"
    :delete-action="onDelete"
    :default-col-def="defaultColumnDef"
    :enable-group-edit="true"
    enable-children-selection-only
    :auto-group-column-def="autoGroupColumnDef"
    :get-context-menu-items="getContextMenuItems"
    :get-main-menu-items="getMainMenuItems"
    :is-server-side-group="isServerSideGroup"
    :get-row-id="getRowId"
    :get-parent-id-property-name="getParentIdPropertyName"
    :get-server-side-group-key="getServerSideGroupKey"
    :tree-data="true"
    :row-selection="multiselect ? 'multiple' : 'single'"
    :create-btn="!readonly"
    :create-child-btn="!readonly"
    :duplicate-btn="!readonly"
    :delete-btn="!readonly"
    :edit-btn="!readonly"
    :server-side="true"
    refresh-btn
    :server-side-datasource="serverSideDataSource"
    @prepare-columns="onPrepareColumns"
    @selection-changed="onSelectionChanged"
    @row-double-clicked="onRowDoubleClicked"
    @ready="onGridReady"
  />
</template>
