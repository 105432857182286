import { ApiClient } from "@/services/api/common.api";
import {
  RegisterAutoDispatchCommand,
  RegisterDescheduleTasksCommand,
  RegisterRecalculateTasksCommand,
  RegisterReleaseTasksCommand,
  RegisterScheduleTasksCommand,
  RegisterUnreleaseTasksCommand,
  RegisterRootRescheduleCommand
} from "@masta/generated-model";

export default {
  async scheduleTasks(request: RegisterScheduleTasksCommand) {
    return ApiClient.post("/scheduling/schedule", request);
  },
  async descheduleTasks(request: RegisterDescheduleTasksCommand) {
    return ApiClient.post("/scheduling/deschedule", request);
  },
  async recalculateTasks(request: RegisterRecalculateTasksCommand) {
    return ApiClient.post("/scheduling/recalculate", request);
  },
  async releaseTasks(request: RegisterReleaseTasksCommand) {
    return ApiClient.post("/scheduling/release", request);
  },
  async unreleaseTasks(request: RegisterUnreleaseTasksCommand) {
    return ApiClient.post("/scheduling/unrelease", request);
  },
  async autoDispatchTasks(request: RegisterAutoDispatchCommand) {
    return ApiClient.post("/scheduling/autodispatch", request);
  },
  async rootReschedule(request: RegisterRootRescheduleCommand) {
    return ApiClient.post("/scheduling/rootReschedule", request);
  }
};
