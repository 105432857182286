<script setup lang="ts">
import GridWrapper from "@/components/Grid/GridWrapper.vue";
import { inject, reactive, ref, Ref, watch } from "vue";
import { ColDef, SelectionChangedEvent } from "ag-grid-community";
import { $t } from "@/i18n";
import { $dateTimeFormatterSymbol, DateFormatter } from "@masta/shared";
import { GridWrapperComponent } from "@/components/Grid/GridWrapperComponent";
import { AvailabilityServerSideDataSource } from "@/components/AvailabilityRules/ResourceAvailabilityRuleServerSideDataSource";
import { validDateRequiredRule } from "@/components/ValueCellEditor/CommonValidationRules";
import GenerateCalendarsDialog from "@/components/Resources/GenerateCalendarsDialog.vue";
import AvailabilityRulesGrid from "@/components/AvailabilityRules/AvailabilityRulesGrid.vue";
import { getSelectedRows } from "@/components/Grid/UseGridSelection";

const props = defineProps<{
  resourceId: string;
}>();
const emits = defineEmits(["selection-changed"]);

const serverSideDataSource = reactive(new AvailabilityServerSideDataSource("resource-availability-rules"));
serverSideDataSource.useFilteringByResourceId(props.resourceId);
watch(() => props.resourceId, () => {
  serverSideDataSource.useFilteringByResourceId(props.resourceId);
  gridWrapperRef.value?.gridApi.refreshServerSide();
});
const $dateTimeFormatter = inject<DateFormatter>($dateTimeFormatterSymbol)!;
const gridWrapperRef = ref<GridWrapperComponent>();
const dialog = ref(false);
const resourceIds = ref<string[]>([props.resourceId]);

async function onSelectionChanged({ api }: SelectionChangedEvent) {
  const selectedRows = getSelectedRows(api);
  emits("selection-changed", selectedRows);
}

function onPrepareColumns(columnDefs: Ref<ColDef[]>) {
  columnDefs.value = [
    {
      field: "id",
      headerName: $t("availabilityRules-list-availabilityRuleReference-label", { $: "Availability rule" }),
      editable: true,
      sortable: true,
      filter: true,
      type: "referencePickerTypeColumn",
      cellEditorParams: {
        component: AvailabilityRulesGrid,
        props: {
          rowSelection: "single",
          disableCrudControls: true
        },
        render: (data: any) => {
          return data?.name;
        },
        placeholder: "...",
        dialogTitle: $t("availabilityRules-pickedDialog-title", { $: "Select availability rule" }),
        valueSelectedCallback: (value: any) => {
          const cells = gridWrapperRef.value?.gridApi.getEditingCells() ?? [];

          if (cells.length > 0) {
            const rowNode = gridWrapperRef.value?.gridApi.getDisplayedRowAtIndex(cells[0].rowIndex as any);
            if (rowNode && value) {
              rowNode.setDataValue("businessId", value.businessId);
              rowNode.setDataValue("name", value.name);
            }
          }
        }
      },
      valueSetter: (params: any) => {
        params.data.businessId = params.newValue?.businessId;
        params.data.name = params.newValue?.name;
        params.data.availabilityRuleId = params.newValue?.id;
        if (!serverSideDataSource.gridStore.isUpdating) {
          delete params.data.id;
        }
        return true;
      },
      valueParser: (params: any) => {
        return params.newValue;
      },
      valueGetter: (param: any) => {
        return param.data;
      },
      valueFormatter: (params: any) => {
        return params.data.name ? params.data.name : params.data.businessId;
      }
    },
    {
      field: "name",
      headerValueGetter: (_: any) => $t("resourceAvailabilityRules-list-name-label", { $: "Name" }),
      editable: false,
      sortable: true
    },
    {
      field: "businessId",
      headerValueGetter: (_: any) => $t("resourceAvailabilityRules-list-businessId-label", { $: "Business ID" }),
      editable: false,
      sortable: true
    },
    {
      field: "validFrom",
      editable: true,
      sortable: true,
      resizable: true,
      filter: "agDateColumnFilter",
      type: ["datepickerTypeColumn", "dateTimeTypeColumn"],
      valueFormatter: (params) => {
        console.log("VF", params?.data?.validFrom);
        return params.data && params.data.validFrom ? $dateTimeFormatter(params.data.validFrom) : "";
      },
      cellEditorParams: {
        rules: [validDateRequiredRule],
        placeholder: $t("resourceAvailabilityRules-list-validFrom-label", { $: "Valid From" })
      },
      headerValueGetter: (_: any) => $t("resourceAvailabilityRules-list-validFrom-label", { $: "Valid From" })
    },
    {
      field: "validTo",
      editable: true,
      sortable: true,
      resizable: true,
      filter: "agDateColumnFilter",
      type: ["datepickerTypeColumn", "dateTimeTypeColumn"],
      valueFormatter: (params) => {
        console.log("VT", params?.data?.validTo);
        return params.data && params.data.validTo ? $dateTimeFormatter(params.data.validTo) : "";
      },
      cellEditorParams: {
        rules: [validDateRequiredRule],
        placeholder: $t("resourceAvailabilityRules-list-validTo-label", { $: "Valid To" })
      },
      headerValueGetter: (_: any) => $t("resourceAvailabilityRules-list-validTo-label", { $: "Valid To" })
    }
  ];
}

function generateCalendars() {
  dialog.value = true;
}

function onCancelAction() {
  gridWrapperRef.value?.gridApi.refreshServerSide();
}

function onCalendarsGenerated() {
  gridWrapperRef.value?.gridApi.refreshServerSide();
}
</script>

<template>
  <grid-wrapper
    ref="gridWrapperRef"
    identifier="resource-availability-rules"
    row-selection="single"
    :server-side="true"
    :server-side-datasource="serverSideDataSource"
    :pagination="false"
    refresh-btn
    create-btn
    delete-btn
    @prepare-columns="onPrepareColumns"
    @selection-changed="onSelectionChanged"
    @cancel-action="onCancelAction"
  >
    <template #custom-buttons>
      <v-tooltip bottom open-delay="300">
        <template #activator="{ props }">
          <v-btn size="small" v-bind="props" variant="text" density="compact" class="mr-4" @click="generateCalendars">
            <v-icon class="pr-4" icon="mdi-calendar" />
            {{ $t("resourceAvailabilityRules-list-generateCalendars-action", { $: "Generate Calendars" }) }}
          </v-btn>
        </template>
        <span>{{ $t("resourceAvailabilityRules-list-generateCalendars-action-tooltip", { $: "Generate Calendars" }) }}</span>
      </v-tooltip>
    </template>
  </grid-wrapper>

  <generate-calendars-dialog v-model="dialog" :resource-ids="resourceIds" @calendars-generated="onCalendarsGenerated" />
</template>
