import ApiService from "@/services/api";
import { defineStore } from "pinia";
import { AgreementDto, UpdateResourceModelInstanceAssignmentCommand } from "@masta/generated-model";
import { composeHierarchy } from "@/store/helper";
import { useScenariosStore } from "@/store/ScenariosStore";
import { useErrorsStore } from "@/store/ErrorsStore";

interface State {
  agreements: AgreementDto[];
}

export const useAgreementsStore = defineStore("agreements", {
  state: (): State => ({
    agreements: []
  }),
  getters: {
    agreementsVms: (state) => {
      if (!state.agreements) {
        return null;
      }
      return state.agreements.map((p) => {
        const vm: any = { ...p };
        const hierarchy: any[] = [];
        composeHierarchy(p, state.agreements, hierarchy);
        vm.hierarchy = hierarchy;
        return vm;
      });
    }
  },
  actions: {
    async fetch() {
      try {
        const scenariosStore = useScenariosStore();
        if (!scenariosStore.selectedScenario) return;
        const response = await ApiService.agreements.getAll({
          scenarioId: scenariosStore.selectedScenario.id
        });
        this.agreements = response.data.agreements;
      } catch (e) {
        const errorsStore = useErrorsStore();
        errorsStore.handleError(e);
        throw e;
      }
    },
    async update(updated: any) {
      try {
        await ApiService.agreements.update(updated);
        this.agreements = this.agreements.map((s) => (s.id === updated.id ? { ...updated } : s));
      } catch (e) {
        const errorsStore = useErrorsStore();
        errorsStore.handleError(e);
        throw e;
      }
    },
    async updateModelInstanceAssignment(request: UpdateResourceModelInstanceAssignmentCommand) {
      try {
        await ApiService.resources.updateModelInstanceAssignment(request);
        await this.fetch();
      } catch (e) {
        const errorsStore = useErrorsStore();
        errorsStore.handleError(e);
        throw e;
      }
    }
  }
});



