import { BehaviorSubject, combineLatest, map, Observable } from "rxjs";
import { inject, injectable } from "inversify";
import { GanttSettings, IRowChartScaleProvider, Row, RowChartRepository, RowChartScaleProviderBase, SettingKey } from "@masta/gantt2/core";
import { GanttChartResourceScaleSetting, GanttChartResourceTypeScaleSetting, ResourceType } from "@masta/generated-model";
import { CustomSettingKeys } from "@/components/Gantt/ResourcesGantt/CustomSettingKeys";

@injectable()
export class ResourceFixedRowChartScaleProvider<TRow extends Row<any, any, any>> extends RowChartScaleProviderBase implements IRowChartScaleProvider {
  private _min = 0;
  private _max = 0;
  private _density = 0;
  private _resourceType = ResourceType.Equipment;

  constructor(
    @inject(Row<any, any, any>) row: TRow,
    @inject(RowChartRepository) private _repository: RowChartRepository,
    @inject(GanttSettings) private _settings: GanttSettings
  ) {
    super();
    this.row = row;
  }

  private _row: BehaviorSubject<TRow> = new BehaviorSubject<TRow>(null!);

  get row(): TRow {
    return this._row.value;
  }

  set row(value: TRow) {
    this._row.next(value);
  }

  get row$(): Observable<TRow> {
    return this._row.asObservable();
  }

  public async afterInitialize() {
    this.subscribe(this.row.properties$.pipe(
      map(x => x.get("type"))
    ).subscribe(x => this._resourceType = x));

    this.setMinMaxScale();

    const hasDatasets$ = combineLatest([this._repository.dataSets$]).pipe(map(([x]) => {
      return x.filter((ds) => ds.resourceId === this.row.id).length > 0;
    }));


    this.subscribe(hasDatasets$.subscribe(hasDatasets => {
      if (hasDatasets) {
        const range = this._max - this._min;
        const step = range / this._density;
        if (isNaN(step)) return;

        const calculatedScale = [...Array(Math.round(range / step) + 1).keys()]
          .map((x) => x * step + this._min)
          .map((x) => Math.round(x * 100) / 100)
          .reverse();
        // Set min and max values for scale (array is reversed)
        if (calculatedScale.length >= 2) {
          calculatedScale[0] = this._max;
          calculatedScale[calculatedScale.length - 1] = this._min;
        }
        this.scaleValues$$.next(calculatedScale);
      }
    }));
  }

  private setMinMaxScale() {
    const fixedScale = this._settings.getSetting(SettingKey.ROW_FIXED_SCALE);
    if (fixedScale) {
      const { min, max, density } = fixedScale;
      this._min = min;
      this._max = max;
      this._density = density ?? Math.abs(min) + Math.abs(max);
    }
    const typeScaleSettings = this._settings.getSetting<GanttChartResourceTypeScaleSetting[]>(CustomSettingKeys.CHART_RESOURCE_TYPE_SCALE_SETTINGS);
    const typeScaleSettingValue = typeScaleSettings?.find(x => x.type === this._resourceType);
    if (typeScaleSettingValue) {
      this._min = typeScaleSettingValue.fixedScaleFromValue;
      this._max = typeScaleSettingValue.fixedScaleToValue;
      this._density = !isNaN(typeScaleSettingValue.density) && typeScaleSettingValue.density > 0 ? typeScaleSettingValue.density : Math.abs(this._min) + Math.abs(this._max);
    }

    const userObject: any = this._row.value.userObject;

    if (!isNaN(parseInt(userObject?.capacityScaleMin)) && !isNaN(parseInt(userObject?.capacityScaleMax))) {
      this._min = userObject?.capacityScaleMin;
      this._max = userObject?.capacityScaleMax;
      this.setDensity(this._min, this._max);
    } else {
      const resourceScaleSettings = this._settings.getSetting<GanttChartResourceScaleSetting[]>(CustomSettingKeys.CHART_RESOURCE_SCALE_SETTINGS);
      const resourceScaleSettingValue = resourceScaleSettings?.find(x => x.id === this.row.id);
      if (resourceScaleSettingValue) {
        this._min = resourceScaleSettingValue.fixedScaleFromValue;
        this._max = resourceScaleSettingValue.fixedScaleToValue;
        this._density = !isNaN(resourceScaleSettingValue.density) && resourceScaleSettingValue.density > 0 ? resourceScaleSettingValue.density : Math.abs(this._min) + Math.abs(this._max);
      }
    }
  }

  private setDensity(min: number, max: number): void {
    const density = Math.abs(min) + Math.abs(max);
    this._density = density;

    if (density > 10 && density <= 20) {
      this._density = this._density / 4;
    } else if (density > 20 && density <= 100) {
      this._density = this._density / 10;
    } else if (density > 100 && density <= 1000) {
      this._density = this._density / 100;
    } else if (density > 1000 && density <= 10000) {
      this._density = this._density / 1000;
    } else if (density > 10000 && density <= 100000) {
      this._density = this._density / 10000;
    } else if (density > 100000 && density <= 1000000) {
      this._density = this._density / 100000;
    } else if (density > 1000000 && density <= 10000000) {
      this._density = this._density / 1000000;
    } else if (density > 10000000 && density <= 100000000) {
      this._density = this._density / 10000000;
    } else if (density > 100000000 && density <= 1000000000) {
      this._density = this._density / 100000000;
    } else if (density > 1000000000 && density <= 10000000000) {
      this._density = this._density / 1000000000;
    }
  }
}
