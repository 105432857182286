<script lang="ts">
import { ICellEditorParams } from "ag-grid-community";
import { computed, ref } from "vue";
import { IControlledCellEditorParams, resolveValueRef, useValueChangeControl } from "@/components/Grid/UseValueChangeControl";
import CloseCardButton from "@/components/CloseCardButton.vue";
import { MaybeRef, toRef } from "@vueuse/core";
import { VAutocomplete, VBtn } from "vuetify/components";
import { $t } from "@/i18n";

type CellValueProcessor = (value: any) => any;

export interface IReferencePickerCellEditorParams extends IControlledCellEditorParams, ICellEditorParams {
  render: CellValueProcessor | null;
  transform: CellValueProcessor | null;
  valueSelectedCallback?: (value: any) => void;
  multiselect: boolean;
  props?: MaybeRef<any>;
  component: any;
  dialogTitle?: string;
}

// Warn: Use export default instead of <script setup /> because only this works with AgGrid!
export default {
  components: { VAutocomplete, VBtn, CloseCardButton },
  setup(props: { params: IReferencePickerCellEditorParams }) {
    const params = toRef(props, "params");

    const dialogTitle = ref<string>(props.params.dialogTitle ?? $t("referencePickerCellEditor-dialog-title", { $: "Select reference" }));
    const multiselect = ref<boolean>(props.params.multiselect);
    const isEditEnabled = toRef(props.params.isEditEnabled ? props.params.isEditEnabled(props.params) : true);

    const initialValue = multiselect.value && !Array.isArray(props.params.value) ? [props.params.value] : props.params.value;
    const referenceValue = resolveValueRef(props.params, initialValue);

    const container = ref<any>();
    const input = ref<any>();
    const componentProps = toRef(params.value.props);

    const dialog = ref<boolean>(false);
    const reference = ref<any>(null);
    const referenceSelected = ref<boolean>(!!referenceValue.value);

    function getValue() {
      return referenceValue.value;
    }

    function focusIn() {
    }

    function focusOut() {
    }

    function afterGuiAttached() {
      if (props.params.cellStartedEdit) {
        focusIn();
      }
    }

    function onBtnConfirm() {
      dialog.value = false;
      if (!referenceSelected.value) {
        referenceValue.value = null;
      } else {
        if ((params.value as any).transform) {
          referenceValue.value = (params.value as any).transform(reference.value);
        } else {
          referenceValue.value = reference.value;
        }
      }
      if (typeof params.value.valueSelectedCallback === "function") {
        params.value.valueSelectedCallback(referenceValue.value);
      }
    }

    function onBtnCancel() {
      dialog.value = false;
    }

    function onReferenceSelected(row: any) {
      referenceSelected.value = !!row;
      reference.value = row;
    }

    const placeholder = computed(() => {
      const renderedPlaceholderValue = typeof params.value.render === "function" ? params.value.render(referenceValue.value) : referenceValue.value;
      return renderedPlaceholderValue ? renderedPlaceholderValue : props.params.placeholder;
    });

    useValueChangeControl(referenceValue, props.params);

    return {
      getValue,
      focusIn,
      focusOut,
      afterGuiAttached,
      params,
      dialogTitle,
      referenceValue,
      componentProps,
      container,
      input,
      placeholder,
      dialog,
      reference,
      referenceSelected,
      onBtnConfirm,
      onBtnCancel,
      onReferenceSelected,
      isEditEnabled
    };
  },
  methods: { $t }
};
</script>

<template>
  <v-dialog v-model="dialog" width="100%" height="80vh" class="reference-picker-dialog" @click:outside="onBtnCancel">
    <template #activator="{ props }">
      <div class="reference-picker-cell-editor-container">
        <v-text-field
          :model-value="reference?.businessId"
          class="flex-1-1-100"
          :color="!isEditEnabled ? '' : referenceSelected ? 'primary' : 'error'"
          :placeholder="placeholder"
          :disabled="!isEditEnabled"
          variant="outlined"
          density="compact"
          hide-details
          v-bind="props"
        >
          <template #append-inner>
            <v-btn variant="plain" icon="mdi-select-search" density="compact" :ripple="false"></v-btn>
          </template>
        </v-text-field>
      </div>
    </template>
    <v-card min-width="300px" height="100%" class="reference-picker-card">
      <close-card-button @click="onBtnCancel"></close-card-button>
      <v-card-title class="pa-0">
        <div class="text-h4 pb-5">{{ dialogTitle }}</div>
      </v-card-title>
      <v-card-text class="px-0">
        <component :is="params.component" :reference-value="referenceValue" v-bind="componentProps" @row-selected="onReferenceSelected" />
      </v-card-text>
      <v-card-actions class="pa-0">
        <div class="left-col pl-0">
          <v-btn variant="elevated" color="secondary" class="w-100" @click="onBtnCancel">
            {{ $t("resourcePicker-list-cancel-label", { $: "Cancel" }) }}
          </v-btn>
        </div>
        <div class="middle-col"></div>
        <div class="right-col pr-0">
          <v-btn variant="elevated" color="primary" class="w-100" @click="onBtnConfirm">
            {{ $t("resourcePicker-list-confirm-label", { $: "Confirm" }) }}
          </v-btn>
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style lang="scss">
.reference-picker-cell-editor-container {
  width: 100%;
  height: 100%;
  padding: 0.31em;

  .reference-picker-autocomplete {
    .v-autocomplete__menu-icon {
      display: none !important;
    }
  }

  .reference-btn-cell-editor {
    display: block;
    font-size: 12px;

    .v-btn__content {
      white-space: break-spaces;
      text-transform: none !important;
    }
  }
}
</style>
