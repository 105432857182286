<script lang="ts" setup>
import { AgGridFilterModel, CostCatalogueItemDto } from "@masta/generated-model";
import { computed, nextTick, ref, watch } from "vue";
import ApiService from "@/services/api";
import CloseCardButton from "@/components/CloseCardButton.vue";
import { $t } from "@/i18n";
import { VAutocomplete } from "vuetify/lib/components/index.mjs";
import { useScenariosStore } from "@/store/ScenariosStore";
import CostCatalogueGrid from "@/components/CostCatalogue/CostCatalogueGrid.vue";
import { getCostCatalogueItemName } from "@/components/CostCatalogue/CostCatalogueItemNameFunction";

const modelValue = defineModel<CostCatalogueItemDto | null>({ required: true });
const props = defineProps<{
  label?: string;
  color?: string | undefined;
  placeholder?: string;
  readonly: boolean;
  rules?: any[] | undefined;
  agGridFilterModel?: (() => AgGridFilterModel) | null | undefined;
}>();
const scenarioStore = useScenariosStore();
const loading = ref(false);
const search = ref<string | undefined>();
const costCatalogueItems = ref<CostCatalogueItemDto[]>([]);
const dialog = ref(false);
const selectedItem = ref<CostCatalogueItemDto>();

const autocompleteRef = ref<VAutocomplete>();

const noDataText = computed(() => {
  if (!search.value) {
    return $t("autocomplete-control-startTyping-label", { $: "Start typing ..." });
  }
  return $t("autocomplete-control-noData-label", { $: "No data" });
});

watch(search, (newValue) => {
  if (!newValue) {
    costCatalogueItems.value = [];
    return;
  }
  querySelections(newValue);
});

function filterByNewValue(query: string, name: string) {
  return query?.trim().length > 0 ? name.toLowerCase().includes(query.toLowerCase()) : true;
}

async function querySelections(query: string) {
  if (!scenarioStore.selectedScenario) {
    return;
  }
  loading.value = true;
  const response = await ApiService.costCatalogue.getAll(scenarioStore.selectedScenario.id);
  costCatalogueItems.value = response.data.costCatalogueItemDtos.filter(x => !!x.providerBusinessId && filterByNewValue(query, getCostCatalogueItemName(x))) ?? [];
  loading.value = false;
}

function selectItem() {
  dialog.value = true;
}

function onBtnConfirm() {
  modelValue.value = selectedItem.value ?? null;
  dialog.value = false;
}

function onBtnCancel() {
  dialog.value = false;
}

function onRowSelected(row: CostCatalogueItemDto | null | undefined) {
  if (row) {
    selectedItem.value = row;
  } else {
    selectedItem.value = undefined;
  }
}

function focus() {
  nextTick(() => autocompleteRef.value?.focus());
}

function hasFocus(): boolean | undefined {
  return autocompleteRef.value?.focused;
}

function onSelectCostCatalogueItem() {
  selectItem();
}

function setMenu(menu: boolean) {
  if (autocompleteRef.value) {
    autocompleteRef.value.menu = menu;
  }
}

function getMenu(): boolean | undefined {
  return autocompleteRef.value?.menu;
}

defineExpose({ focus, hasFocus, getMenu, setMenu, onSelectCostCatalogueItem });
</script>

<template>
  <div class="d-flex align-center cost-cat-item-picker">
    <v-autocomplete
      ref="autocompleteRef"
      v-model="modelValue"
      v-model:search="search"
      class="flex-1-1-100"
      auto-select-first
      clearable
      :color="color"
      :label="label"
      :items="costCatalogueItems"
      :loading="loading"
      :disabled="props.readonly"
      :rules="props.rules"
      no-filter
      chips
      :item-title="(item) => getCostCatalogueItemName(item as any)"
      variant="outlined"
      density="compact"
      hide-details
      return-object
      :placeholder="placeholder"
      hide-no-data
      :no-data-text="noDataText"
    >
      <template #item="{ props, item }">
        <v-list-item v-bind="props" :title="getCostCatalogueItemName(item?.raw)"></v-list-item>
      </template>
      <template #chip="{ props, item }">
        <v-chip v-bind="props" :title="getCostCatalogueItemName(item?.raw)" class="chip-select text-body-1"></v-chip>
      </template>
      <template #append-inner>
        <v-btn v-if="!props.readonly" variant="plain" icon="mdi-select-search" density="compact" :ripple="false" @click.stop="onSelectCostCatalogueItem"></v-btn>
      </template>
    </v-autocomplete>
    <v-dialog v-model="dialog" width="100%" height="80vh" @click:outside="onBtnCancel">
      <v-card min-width="300px" height="100%" class="reference-picker-card">
        <close-card-button @click="onBtnCancel"></close-card-button>
        <v-card-title class="pa-0">
          <div class="text-h4 pb-5">{{ $t("costCatalogueItemPicker-list-title-label", { $: "Choose Cost Catalogue Item" }) }}</div>
        </v-card-title>
        <v-card-text class="px-0">
          <cost-catalogue-grid
            row-selection="single"
            readonly
            :reference-value="modelValue?.id"
            :ag-grid-filter-model="agGridFilterModel"
            @row-selected="onRowSelected">
          </cost-catalogue-grid>
        </v-card-text>
        <v-card-actions class="pa-0">
          <div class="left-col pl-0">
            <v-btn variant="elevated" color="secondary" class="w-100" @click="onBtnCancel">{{ $t("costCataloguesPicker-list-cancel-label", { $: "Cancel" }) }}</v-btn>
          </div>
          <div class="middle-col"></div>
          <div class="right-col pr-0">
            <v-btn variant="elevated" color="primary" class="w-100" @click="onBtnConfirm">{{ $t("costCataloguesPicker-list-confirm-label", { $: "Confirm" }) }}</v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<style scoped lang="scss">
.cost-cat-item-picker {
  :deep(.v-autocomplete__menu-icon) {
    display: none;
  }
}

.chip-select {
  color: #000000de;

  :deep(.v-chip__underlay) {
    background-color: #593cff;
  }
}
</style>
