﻿import ApiService from "@/services/api";
import { ServerSideDataSource } from "@/components/Grid/ServerSideDataSource";
import { IAgGridPaginationResponse, LocationDto } from "@masta/generated-model";
import { IServerSideGetRowsParams } from "ag-grid-enterprise";
import { Store } from "pinia";
import { State as ScenarioState, useScenariosStore } from "@/store/ScenariosStore";

export class LocationServerSideDataSource extends ServerSideDataSource<LocationDto> {
  private _scenarioStore: Store<string, ScenarioState>;

  constructor(id: string) {
    super(id);
    this._scenarioStore = useScenariosStore();
  }

  async create(entity: LocationDto): Promise<string | undefined> {
    const request = this.transformForCreate(entity);
    const scenario = this._scenarioStore.selectedScenario;
    request.scenarioId = scenario?.id ?? "";
    const response = await ApiService.locations.create(request);
    return response.data;
  }

  async getAll(params: IServerSideGetRowsParams): Promise<IAgGridPaginationResponse<LocationDto>> {
    const { data } = await ApiService.locations.getPaginated({ ...this.constructServerRequest(params), scenarioId: this._scenarioStore.selectedScenario?.id ?? "" });
    return data;
  }

  async getSingle(id: string | undefined): Promise<LocationDto | undefined> {
    const scenario = this.currentScenario();
    if (!scenario) {
      return undefined;
    }
    const response = await ApiService.locations.getSingle(id, scenario.id);
    return response.data;
  }

  async remove(entity: LocationDto): Promise<void> {
    await ApiService.locations.remove(entity.id, this._scenarioStore.selectedScenario?.id);
  }

  async update(entity: LocationDto): Promise<string | undefined> {
    const response = await ApiService.locations.update(this.transformForUpdate(entity));
    return response.data;
  }

  protected transformForCreate(listEntity: LocationDto): LocationDto {
    return listEntity;
  }

  protected transformForUpdate(listEntity: LocationDto): LocationDto {
    return listEntity;
  }

  private currentScenario() {
    return this._scenarioStore.selectedScenario;
  }
}
