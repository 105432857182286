<script lang="ts" setup>
import { DocumentEditionDetailsDto } from "@masta/generated-model";
import { ref } from "vue";
import QrCodeHyperlinkDialog from "@/components/ModelInstances/QrCodeHyperlinkDialog/QrCodeHyperlinkDialog.vue";
import ApiService from "@/services/api";
import { useI18n } from "vue-i18n";
import { useScenariosStore } from "@/store/ScenariosStore";

const props = withDefaults(
  defineProps<{
    contextName: string;
    isNew?: boolean;
    disabled?: boolean;
    modelInstanceId?: string;
    revisionNumber?: number;
    showQrCode?: boolean;
    resourceId?: string;
    orderId?: string;
    costCatalogueItemId?: string;
  }>(),
  {}
);

const i18n = useI18n();

const dialog = ref(false);
const link = ref("NO LINK SPECIFIED");

const emit = defineEmits(["requestForQrCode"]);

const scenariosStore = useScenariosStore();

async function getDocumentQrCode() {
  let fullAccessUrl = "";
  let result: DocumentEditionDetailsDto;
  if (!props.resourceId && !props.orderId && !props.costCatalogueItemId) {
    result = await ApiService.documentEditionForMobile.getDocumentEditionQrcode(scenariosStore.scenarioId, props.modelInstanceId, props.revisionNumber, props.contextName);
  } else if (props.resourceId && !props.orderId && !props.costCatalogueItemId) {
    result = await ApiService.documentEditionForMobile.getDocumentAssignedToResourceEditionQrcode(scenariosStore.scenarioId, props.modelInstanceId, props.revisionNumber, props.contextName, props.resourceId);
  } else if (!props.resourceId && props.orderId && !props.costCatalogueItemId) {
    result = await ApiService.documentEditionForMobile.getDocumentAssignedToOrderEditionQrcode(scenariosStore.scenarioId, props.modelInstanceId, props.revisionNumber, props.contextName, props.orderId);
  } else if (!props.resourceId && !props.orderId && props.costCatalogueItemId) {
    result = await ApiService.documentEditionForMobile.getDocumentAssignedToCostCatalogueItemEditionQrcode(
      scenariosStore.scenarioId,
      props.modelInstanceId,
      props.revisionNumber,
      props.contextName,
      props.costCatalogueItemId
    );
  } else {
    console.warn("Cannot get QR code for document assigned to nire than one of resource/order/cost catalogue item at the same time");
  }
  if (result) {
    fullAccessUrl = result.data.fullUrl + "&locale=" + i18n.locale.value;
    link.value = fullAccessUrl;
    dialog.value = true;
  }
}
</script>

<template>
  <slot :get-document-qr-code="getDocumentQrCode">
    <v-btn icon size="x-large" color="primary" @click="getDocumentQrCode()">
      <v-icon size="x-large">mdi-qrcode</v-icon>
    </v-btn>
  </slot>
  <qr-code-hyperlink-dialog v-model="dialog" :link="link" />
</template>

<style lang="scss" scoped></style>
