import { ActivityBounds, createGraphicsMousePositionEvent, GanttEvents, GraphicsMousePositionEvent, IocContainer, IocSymbols, Lifecycle } from "@masta/gantt2/core";
import { inject, injectable } from "inversify";
import { DragAndDropData, RowsContainer, ViewportPointerEventNoneScrollGlass, ViewportRowsContainer } from "@masta/gantt2/gantt";
import { filter, withLatestFrom } from "rxjs";
import { ExternalTaskDragHandler } from "@/components/Gantt/ResourcesGantt/DragAndDrop/ExternalTaskDragHandler";
import { ProductionTaskDto, ResourcesCapacitiesChangeNotificationEvent, SchedulingFinishedNotificationEvent } from "@masta/generated-model";
import { GanttTaskDragHandler } from "@/components/Gantt/ResourcesGantt/DragAndDrop/GanttTaskDragHandler";

const LOG_STYLE = "color: black; font-weight: bold; background-color: orange; padding: 2px 25px;";

@injectable()
export class DragAndDropService extends Lifecycle {

  private _externalTaskDragHandlers: ExternalTaskDragHandler[] = [];
  private _ganttTaskDragHandlers: GanttTaskDragHandler[] = [];
  private _lastDragOverMousePosition: GraphicsMousePositionEvent | undefined;
  private _previousScrollPosition: number;

  constructor(
    @inject(IocSymbols.HtmlContainerSymbol) private readonly _rootElement: HTMLElement,
    @inject(IocContainer) private _iocContainer: IocContainer,
    @inject(ViewportPointerEventNoneScrollGlass) private _glassLayer: ViewportPointerEventNoneScrollGlass,
    @inject(RowsContainer) private _rowsContainer: RowsContainer,
    @inject(ViewportRowsContainer) private _viewportRowsContainer: ViewportRowsContainer,
    @inject(GanttEvents) private _ganttEvents: GanttEvents
  ) {
    super();
  }

  async beforeInitialize(): Promise<void> {
    await super.beforeInitialize();
  }

  async afterInitialize(): Promise<void> {
    await super.afterInitialize();


    this.subscribe(
      this._ganttEvents.dragStartEvent$$.pipe(withLatestFrom(this._ganttEvents.externalActivityDrag$$, this._ganttEvents.ganttActivityDrag$$))
        .subscribe(([event, isExternal, draggedActivityData]) => this.onDragStart(event, isExternal, draggedActivityData))
    );
    this.subscribe(
      this._ganttEvents.dragOverEvent$$.pipe(withLatestFrom(this._ganttEvents.externalActivityDrag$$, this._ganttEvents.ganttActivityDrag$$))
        .subscribe(([event, isExternal, draggedActivityData]) => this.onDragOver(event, isExternal, draggedActivityData))
    );
    this.subscribe(
      this._ganttEvents.dragEndEvent$$.pipe(withLatestFrom(this._ganttEvents.externalActivityDrag$$, this._ganttEvents.ganttActivityDrag$$))
        .subscribe(([event, isExternal, draggedActivityData]) => this.onDragEnd(event, isExternal, draggedActivityData))
    );
    this.subscribe(
      this._ganttEvents.dragEvent$$.pipe(withLatestFrom(this._ganttEvents.externalActivityDrag$$, this._ganttEvents.ganttActivityDrag$$))
        .subscribe(([event, isExternal, draggedActivityData]) => this.onDrag(event, isExternal, draggedActivityData))
    );
    this.subscribe(
      this._ganttEvents.dropEvent$$.pipe(withLatestFrom(this._ganttEvents.externalActivityDrag$$, this._ganttEvents.ganttActivityDrag$$))
        .subscribe(([event, isExternal, draggedActivityData]) => this.onDrop(event, isExternal, draggedActivityData))
    );
    this.subscribe(
      this._ganttEvents.dragLeaveEvent$$.pipe(withLatestFrom(this._ganttEvents.externalActivityDrag$$, this._ganttEvents.ganttActivityDrag$$))
        .subscribe(([event, isExternal, draggedActivityData]) => this.onDragLeave(event, isExternal, draggedActivityData))
    );
    this.subscribe(
      this._ganttEvents.dragEnterEvent$$.pipe(withLatestFrom(this._ganttEvents.externalActivityDrag$$, this._ganttEvents.ganttActivityDrag$$))
        .subscribe(([event, isExternal, draggedActivityData]) => this.onDragEnter(event, isExternal, draggedActivityData))
    );
  }

  public async externalActivityDragStarted(dragId: string, productionTaskDto: ProductionTaskDto, _: MouseEvent) {
    // console.log("%c[DnDService] externalActivityDragStarted", LOG_STYLE, dragId);
    this.savePreviousScrollPosition();
    const handler = await this._iocContainer.getAsync<ExternalTaskDragHandler>(ExternalTaskDragHandler);
    handler.dragId = dragId;
    handler.productionTaskDto = productionTaskDto;
    this._externalTaskDragHandlers.push(handler);
    await handler.setContext();
    handler.isDestroyed$.pipe(filter(x => x)).subscribe(() => {
      // console.log("[DnDService] ExternalTaskDragHandler destroyed. Removing from list.", dragId);
      if (!handler.businessKey) this.restorePreviousScrollPosition();
      this._externalTaskDragHandlers = this._externalTaskDragHandlers.filter((x) => x.dragId !== dragId);
    });
    this._ganttEvents.externalActivityDrag$$.next(true);
    this._ganttEvents.dragAndDropEnabled$$.next(true);
  }

  public async externalActivityDragging(dragId: string, event: MouseEvent) {
    // console.log("%c[DnDService] externalActivityDragging", LOG_STYLE, dragId);
    const handler = this._externalTaskDragHandlers.find((x) => x.dragId === dragId);
    if (handler) {
      const mpe = createGraphicsMousePositionEvent(event, this._glassLayer.element, ViewportRowsContainer.DragAndDropContext);
      this._ganttEvents.onMousePositionEvent(mpe);
      await handler.onDrag();
    }
  }

  public async externalActivityDragFinished(dragId: string, _: MouseEvent) {
    // console.log("%c[DnDService] externalActivityDragFinished", LOG_STYLE, dragId);
    const handler = this._externalTaskDragHandlers.find((x) => x.dragId === dragId);
    if (!handler) {
      console.error("[DnDService] ExternalTaskDragHandler not found");
      return;
    }
    await handler.onFinished();
    this._ganttEvents.externalActivityDrag$$.next(false);
    this._ganttEvents.dragAndDropEnabled$$.next(false);
  }

  public async externalActivityDropped(dragId: string, _: MouseEvent) {
    // console.log("%c[DnDService] externalActivityDropped", LOG_STYLE, dragId);
    const handler = this._externalTaskDragHandlers.find((x) => x.dragId === dragId);
    if (!handler) {
      console.error("[DnDService] ExternalTaskDragHandler not found");
      return;
    }
    await handler.onDrop();
    this._ganttEvents.externalActivityDrag$$.next(false);
    this._ganttEvents.dragAndDropEnabled$$.next(false);
  }

  private async ganttTaskDragStarted(event: DragEvent, dndData: DragAndDropData) {
    // console.log("[DnDService] ganttTaskDragStarted");
    this.savePreviousScrollPosition();
    this.createSingleActivityDragImage(event, 0.5);

    const handler = await this._iocContainer.getAsync<GanttTaskDragHandler>(GanttTaskDragHandler);
    handler.dragAndDropData = dndData;
    this._ganttTaskDragHandlers.push(handler);
    await handler.setContext();
    handler.isDestroyed$.pipe(filter(x => x)).subscribe(() => {
      // console.log("[DnDService] GanttTaskDragHandler destroyed. Removing from list.");
      this._ganttTaskDragHandlers.splice(this._ganttTaskDragHandlers.indexOf(handler), 1);

      if (!handler.businessKey) {
        this.restorePreviousScrollPosition();
      }
    });
  }

  public async handleSchedulingOperationResultEvent(event: SchedulingFinishedNotificationEvent) {
    for (const handler of this._externalTaskDragHandlers) {
      if (handler.businessKey === event.businessKey) {
        await handler.handleSchedulingOperationResultEvent(event);
      }
    }
    for (const handler of this._ganttTaskDragHandlers) {
      if (handler.businessKey === event.businessKey) {
        await handler.handleSchedulingOperationResultEvent(event);
      }
    }
  }

  public async handleResourceCapacityChangeNotification(event: ResourcesCapacitiesChangeNotificationEvent) {
    for (const handler of this._externalTaskDragHandlers) {
      if (handler.businessKey === event.businessKey) {
        await handler.destroy();
      }
    }
    for (const handler of this._ganttTaskDragHandlers) {
      if (handler.businessKey === event.businessKey) {
        await handler.destroy();
      }
    }
  }

  private async onDragStart(event: DragEvent, isExternal: boolean, draggedActivityData: DragAndDropData[] | null) {
    // console.log("onDragStart", event, isExternal, draggedActivityData);
    if (!isExternal && draggedActivityData) {
      if (draggedActivityData.length > 1) {
        this.createMultipleActivitiesDragImage(event);
        return;
      } else {
        await this.ganttTaskDragStarted(event, draggedActivityData[0]);
      }
    }
  }

  private onDragOver(event: DragEvent, isExternal: boolean, draggedActivityData: DragAndDropData[] | null) {
    const currentMousePosition = createGraphicsMousePositionEvent(event, this._glassLayer.element, ViewportRowsContainer.DragAndDropContext);
    // check if the last mouse position is different from the current mouse position
    if (!this._lastDragOverMousePosition ||
      this._lastDragOverMousePosition.x !== currentMousePosition.x ||
      this._lastDragOverMousePosition.y !== currentMousePosition.y) {
      // send mouse position event to gantt events when dragging
      this._ganttEvents.onMousePositionEvent(currentMousePosition);
      // update the last mouse position
      this._lastDragOverMousePosition = currentMousePosition;
    }
    // this.log("onDragOver", event, isExternal, draggedActivityData);
  }

  private async onDragEnd(event: DragEvent, isExternal: boolean, draggedActivityData: DragAndDropData[] | null) {
    // if (!isExternal && draggedActivityData) {
    //   if (draggedActivityData.length === 1 && event.dataTransfer?.dropEffect === "move") {
    //     console.log("DnDService->onDragEnd->destroyWithTimeout", event, isExternal, draggedActivityData);
    //     await this._ganttTaskDragHandler?.destroyWithTimeout();
    //   } else {
    //     console.log("DnDService->onDragEnd->destroy", event, isExternal, draggedActivityData);
    //     await this._ganttTaskDragHandler?.destroy();
    //   }
    // }
  }

  private onDrag(event: DragEvent, isExternal: boolean, draggedActivityData: DragAndDropData[] | null) {
    // this.log("onDrag", event, isExternal, draggedActivityData);
  }

  private async onDrop(event: DragEvent, isExternal: boolean, draggedActivityData: DragAndDropData[] | null) {
    // console.log("onDrop", event, isExternal, draggedActivityData);
    // if (!isExternal && draggedActivityData) {
    //   if (draggedActivityData.length === 1) {
    //     await this.ganttTaskDragExecute();
    //     return;
    //   }
    // }
  }

  private onDragLeave(event: DragEvent, isExternal: boolean, draggedActivityData: DragAndDropData[] | null) {
    // this.log("onDragLeave", event, isExternal, draggedActivityData);
  }

  private onDragEnter(event: DragEvent, isExternal: boolean, draggedActivityData: DragAndDropData[] | null) {
    // this.log("onDragEnter", event, isExternal, draggedActivityData);
  }

  private createSingleActivityDragImage(event: DragEvent, alpha: number = 1) {
    const data: DragAndDropData[] = JSON.parse(event.dataTransfer?.getData("application/json") as string);

    const mousePos = createGraphicsMousePositionEvent(event, this._glassLayer.element, ViewportRowsContainer.DragAndDropContext);
    const rowContainer = this._viewportRowsContainer.rowContainers.find(x => x.row.id === data[0].rowId)!;
    const y = Math.round((mousePos.y - rowContainer.element.offsetTop));

    // const div = document.createElement("div");
    // // div.innerText = "po💩o";
    // div.innerHTML = `<span style="transform: rotate(90deg)">I</span>`;
    // // div.innerHTML = `<!--<span class="mdi mdi-cursor-text"></span>-->`;
    // div.style.cursor = "grabbing";
    // // div.style.fontSize = "48px";
    // document.body.appendChild(div);

    const dragCanvas: HTMLCanvasElement = document.createElement("canvas") as HTMLCanvasElement;
    dragCanvas.style.width = `${100}px`;
    dragCanvas.style.height = `${100}px`;

    const context = dragCanvas.getContext("2d")!;
    context.clearRect(0, 0, dragCanvas.width, dragCanvas.height);

    // scale
    const dpr = window.devicePixelRatio || 1;
    dragCanvas.width = 100 * dpr;
    dragCanvas.height = 100;
    context.scale(dpr, dpr);

    // context.globalAlpha = alpha;

    // draw text cursor rotated right 90deg (clockwise)
    // context.font = "28px sans-serif";
    // context.fillStyle = "black";
    // context.textAlign = "center";
    // context.textBaseline = "middle";
    // context.fillText("I", 0, 0);
    // context.translate(20, 20);
    // context.rotate(Math.PI / 4);

    context.fillStyle = "black";
    context.translate(10, 0);
    context.fillRect(0, 0, 2, 10);
    context.fillRect(2, 4, 15, 1);
    context.fillRect(17, 0, 2, 10);


    dragCanvas.style.cursor = "grabbing";
    dragCanvas.style.pointerEvents = "none";
    document.body.appendChild(dragCanvas);

    event.dataTransfer!.setDragImage(dragCanvas, 0, 0);

    setTimeout(() => {
      dragCanvas.remove();
    }, 10);

  }

  private createMultipleActivitiesDragImage(event: DragEvent, alpha: number = 1) {
    try {
      const data: DragAndDropData[] = JSON.parse(event.dataTransfer?.getData("application/json") as string);

      if (!Array.isArray(data) || data.length === 0) return;

      const rowContainer = this._viewportRowsContainer.rowContainers.find(x => x.row.id === data[0].rowId)!;

      const rowCanvas = rowContainer.rowCanvasContainer.rowCanvas;

      if (!rowCanvas) return;

      const actIds: string[] = data.map((x: any) => x.activity.id);

      const mousePos = createGraphicsMousePositionEvent(event, this._glassLayer.element, ViewportRowsContainer.DragAndDropContext);

      const bounds: ActivityBounds[] = rowContainer.activityBounds
        .filter(x => actIds.includes(x.activityId))
        .sort((a, b) => a.startTime.toEpochMilli() - b.startTime.toEpochMilli());

      const dpr = window.devicePixelRatio || 1;
      const activityDistance = 2;
      const totalWidth = bounds.reduce((acc, b) => acc + b.width + activityDistance, 0);

      const dragCanvas: HTMLCanvasElement = rowCanvas.element.cloneNode(true) as HTMLCanvasElement;
      dragCanvas.style.width = `${totalWidth}px`;
      dragCanvas.style.height = `${rowCanvas.element.clientHeight}px`;

      const context = dragCanvas.getContext("2d")!;
      context.clearRect(0, 0, dragCanvas.width, dragCanvas.height);

      // scale
      dragCanvas.width = totalWidth * dpr;
      dragCanvas.height = rowCanvas.element.height;
      context.scale(dpr, dpr);

      context.globalAlpha = alpha;
      let posX = 0;
      for (const b of bounds) {
        context.drawImage(b.canvas, posX, b.y, b.width, b.height);
        posX += b.width + activityDistance;
      }

      dragCanvas.style.cursor = "grabbing";
      document.body.appendChild(dragCanvas);

      const y = Math.round((mousePos.y - rowContainer.element.offsetTop));
      event.dataTransfer!.setDragImage(dragCanvas, 0, y);

      setTimeout(() => {
        dragCanvas.remove();
      }, 100);
    } catch (e) {
      console.debug(e);
    }
  }

  private savePreviousScrollPosition() {
    this._previousScrollPosition = this._rowsContainer.element.scrollTop;
  }

  private restorePreviousScrollPosition() {
    this._rowsContainer.element.scrollTo({
      top: this._previousScrollPosition,
      behavior: "smooth"
    });
  }
}
