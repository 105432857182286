<script lang="ts" setup>
import { computed, nextTick, Ref, ref } from "vue";
import { $t } from "@/i18n";
import { VTextField, VMenu } from "vuetify/lib/components/index.mjs";
import EmojisTable from "@/components/Tags/emojis";
import { useStorage } from "@vueuse/core";

const modelValue = defineModel<string>({ required: true });
const props = defineProps<{
  label?: string;
  color?: string | undefined;
  placeholder?: string;
  readonly: boolean;
  rules?: any[] | undefined;
}>();

const usedEmojis = useStorage("used-emojis", []);
const menuRef = ref<VMenu>();
const fieldRef = ref<VTextField>();

const menuActive = ref<boolean>(false);
const tab = ref<string | undefined>("Frequently used");

// translations:
// $t("emojiPicker-control-tab-frequentlyUsed-label", {$: "Frequently used"})
// $t("emojiPicker-control-tab-people-label", {$: "People"})
// $t("emojiPicker-control-tab-nature-label", {$: "Nature"})
// $t("emojiPicker-control-tab-objects-label", {$: "Objects"})
// $t("emojiPicker-control-tab-places-label", {$: "Places"})
// $t("emojiPicker-control-tab-symbols-label", {$: "Symbols"})

const emojisGrouped = computed(() => {
  return Object.entries(EmojisTable).map(([k, v]) => {
    return { name: k, emojis: Object.entries(v).map(([name, value]) => ({ name, value })) };
  });
});

const emojis = computed<Record<string, string>[]>(() => {
  return Object.values(EmojisTable)
    .flatMap((group) => Object.entries(group))
    .map((emoji) => {
      const [name, value] = emoji;
      return { name, value };
    });
});

function focus() {
  nextTick(() => {
    fieldRef.value?.focus();
    menuActive.value = true;
  });
}

function focusOut() {
  nextTick(() => {
    menuActive.value = false;
  });
}

function hasFocus(): boolean | undefined {
  return fieldRef.value?.focused;
}

function selectEmoji(emoji: string, isActive: Ref<boolean>) {
  modelValue.value = `${modelValue.value ?? ""}${emoji}`;
  if (!usedEmojis.value.includes(emoji as never)) {
    usedEmojis.value.unshift(emoji as never);
    usedEmojis.value = usedEmojis.value.slice(0, 26);
  }
  isActive.value = false;
}

function enhanceActivatorBindings(properties: Record<string, any>) {
  if (properties.ref) {
    fieldRef.value = properties.ref.value;
  }
  return properties;
}

function getEmojisForTab(emojiTab: string | undefined) {
  let used: any[] = [];
  if (emojiTab === "frequentlyUsed") {
    used = usedEmojis.value.map((emoji) => ({ name: emoji, value: emoji }));
    used = used.filter((emoji) => !emojisGrouped.value.find((group) => group.name === "frequentlyUsed")?.emojis?.find((e) => e.value === emoji.value));
  }
  return [...used, ...(emojisGrouped.value.find((group) => group.name === emojiTab)?.emojis ?? [])];
}

defineExpose({ focus, focusOut, hasFocus });
</script>

<template>
  <div class="d-flex align-center">
    <v-menu ref="menuRef" v-model="menuActive" location="bottom" :close-on-back="true" :close-on-content-click="false">
      <template #activator="activator">
        <v-text-field
          v-model="modelValue"
          :color="color"
          :label="label"
          :placeholder="placeholder"
          :items="emojis"
          :disabled="props.readonly"
          :rules="props.rules"
          variant="outlined"
          density="compact"
          clearable
          hide-details
          v-bind="enhanceActivatorBindings(activator.props)"
        >
          <template #prepend-inner>
            <span />
          </template>
        </v-text-field>
      </template>

      <template #default="{ isActive }">
        <v-card width="800px">
          <v-card-title class="d-flex justify-space-between align-center">
            <v-tabs v-model="tab">
              <v-tab v-for="group in emojisGrouped" :key="group.name" :value="group.name">
                {{ $t(`emojiPicker-control-tab-${group.name}-label`) }}
              </v-tab>
            </v-tabs>
            <v-btn class="float-right" density="compact" variant="flat" size="x-large" icon="mdi-close" @click="isActive.value = false" />
          </v-card-title>
          <v-card-text>
            <v-window v-model="tab">
              <v-window-item v-for="group in emojisGrouped" :key="group.name" :value="group.name">
                <v-card flat>
                  <v-card-text class="d-flex align-center flex-wrap justify-space-between">
                    <div v-for="item in getEmojisForTab(tab)" :key="item.name" style="font-size: 2em; line-height: 2em; cursor: pointer" @click="selectEmoji(item.value, isActive)">
                      {{ item.value }}
                    </div>
                  </v-card-text>
                </v-card>
              </v-window-item>
            </v-window>
          </v-card-text>
        </v-card>
      </template>
    </v-menu>
  </div>
</template>

<style lang="scss" scoped>
.emoji-list-item-title {
  display: flex;
  gap: 5px;
}
</style>
