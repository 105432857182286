<script lang="ts">
import DatepickerField from "@/components/Datepicker/DatepickerField.vue";
import { IControlledCellEditorParams, resolveValueRef, useValueChangeControl } from "@/components/Grid/UseValueChangeControl";
import { $dateTimeFormatterSymbol, DateFormatter } from "@masta/shared";
import { ICellEditorParams } from "ag-grid-community";
import { computed, inject, ref, toRef } from "vue";
import { useI18n } from "vue-i18n";

// Warn: Use export default instead of <script setup /> because only this works with AgGrid!
export default {
  components: { DatepickerField },
  setup(props: { params: ICellEditorParams & IControlledCellEditorParams }) {
    const params = toRef(props, "params");

    const { locale } = useI18n();
    const val = resolveValueRef(props.params, props.params.value);
    const container = ref<any>();
    const input = ref<any>();
    const hasFocus = ref<boolean>(false);  
    const $dateTimeFormatter = inject<DateFormatter>($dateTimeFormatterSymbol)!;

    const isEditEnabled = toRef(props.params.isEditEnabled ? props.params.isEditEnabled(props.params) : true);

    const rules = computed(() => {
      return (params.value as any).rules ?? [];
    });

    const errors = computed(() => {
      if (Array.isArray(rules.value)) {
        const evaluated = rules.value.map((v) => v(val.value));
        return evaluated.filter((x) => String(x) === x);
      }
      return [];
    });

    const errorMessages = computed(() => {
      return errors.value.join(",");
    });

    const isValid = computed(() => {
      if (isEditEnabled.value) {
        return errors.value.length === 0;
      } else {
        return undefined;
      }
    });

    const showTooltip = computed<boolean>(() => errors.value.length > 0 && hasFocus.value);

    function getValue() {
      return val.value;
    }

    function focusIn() {
      if (container.value) {
        const inputs = container.value.getElementsByClassName("dp__pointer");
        if (inputs.length >= 1) {
          inputs[0].focus();
          if (input.value && input.value.openMenu) {
            input.value.openMenu();
          }
        }
      }

      hasFocus.value = true;
    }

    function focusOut() {}

    function afterGuiAttached() {
      if (props.params.cellStartedEdit) {
        focusIn();
      }
    }

    function onInputFocused(focused: boolean) {
      hasFocus.value = focused;
    }

    function formatDate(date: Date) {
      return $dateTimeFormatter(date);
    }

    useValueChangeControl(val, props.params);

    return {
      getValue,
      focusIn,
      focusOut,
      afterGuiAttached,
      val,
      showTooltip,
      rules,
      hasFocus,
      errors,
      container,
      input,
      errorMessages,
      isValid,
      params,
      onInputFocused,
      isEditEnabled,
      formatDate,
      locale,
    };
  }
};
</script>

<template>
  <v-tooltip v-model="showTooltip" location="top" :offset="24" class="ag-text-field-cell-editor-tooltip">
    <template #activator="act">
      <div ref="container" class="datepicker-cell-editor-container">
        <datepicker-field
          ref="input"
          v-model="val"
          :disabled="!isEditEnabled"
          :error="!isValid"
          :placeholder="params.placeholder"
          :state="isValid"
          :format="formatDate"
          :locale="locale"
          :rules="rules"
          clearable
          teleport="body"
          color="primary"
          @update:focused="onInputFocused"
        >
        </datepicker-field>
        <div v-bind="act.props" />
      </div>
    </template>
    <span v-if="errorMessages">{{ errorMessages }}</span>
  </v-tooltip>
</template>

<style lang="scss">
.datepicker-cell-editor-container {
  width: 100%;
  .dp__main {
    padding: 0 0.31em 0 0.31em;
    height: 34px;
    --dp-border-color: rgb(221, 226, 235);

    .dp__input:not(.dp__input_focus) {
      --dp-text-color: rgb(221, 226, 235);
    }

    .dp__input_wrap {
      display: flex;
    }
  }

  .v-field--disabled {
    opacity: 0.75;
  }

  .v-input {
    font-size: 1em;

    .v-field {
      border-radius: 8px;
      font-size: 1em;

      .v-field__overlay {
        background-color: white;
      }

      .v-field__input {
        font-size: 1em;
        min-height: 34px;
      }

      .v-field__outline {
        --v-field-border-width: 2px;
      }

      color: rgba(0, 0, 0, 0.5);

      &:hover {
        color: var(--ag-data-color);
      }

      &.v-field--focused {
        color: var(--ag-data-color);
      }
    }
  }
}

.ag-text-field-cell-editor-tooltip {
  .v-overlay__content {
    background: rgba(var(--v-theme-error), 0.7);
  }
}
</style>
