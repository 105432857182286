import { ISetFilterParams, KeyCreatorParams, SetFilterValuesFuncParams, ValueFormatterParams } from "ag-grid-community";
import { TagDto } from "@masta/generated-model";
import { TagsStoreType } from "@/store/TagsStore";

export const tagsTypeColumnFilterParams = (tagsStore: TagsStoreType): ISetFilterParams<any, TagDto> => {
  return {
    values: (params: SetFilterValuesFuncParams<any, TagDto>) => {
      tagsStore.fetch().then(() => params.success(tagsStore.tags));
    },
    valueFormatter: (params: ValueFormatterParams) => `${params.value.emoji} ${params.value.name}`,
    keyCreator: (params: KeyCreatorParams) => params.value.name
  };
};
