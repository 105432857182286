<script lang="ts" setup>
import { computed, onBeforeUnmount, onMounted, reactive, ref, toRef, watch } from "vue";
import { useTheme } from "vuetify";
import { GridApi, SelectionChangedEvent } from "ag-grid-community";
import Datepicker from "@vuepic/vue-datepicker";
import ApiService from "@/services/api";
import { AvailabilityRulesController } from "@/services/api-service";
import { useAuthStore } from "@/store/AuthStore";
import { storeToRefs } from "pinia";
import { useSnackbarsStore } from "@/store/SnackbarsStore";
import { $t } from "@/i18n";
import GenerateCalendarsDialog from "@/components/Resources/GenerateCalendarsDialog.vue";
import ResourceCapacityCreator from "@/components/Resources/ResourceCapacityCreator.vue";
import { IResourceDto } from "@masta/generated-model";

interface Props {
  resource: IResourceDto;
}

const $props = defineProps<Props>();

const emits = defineEmits(["created"]);

const dialog = ref(false);
</script>

<template>
  <v-tooltip location="bottom" open-delay="300">
    <template #activator="{ props }">
      <div class="d-inline-flex pr-4">
        <v-btn size="small" variant="text" density="compact" v-bind="props" @click="dialog = true">
          <v-icon icon="mdi-plus" class="pr-4" />
          {{ $t("resource-resourceCapacity-createTransaction-action", { $: "Create Transaction" }) }}
        </v-btn>
      </div>
    </template>
    <span>{{ $t("resource-resourceCapacity-createTransaction-action", { $: "Create Transaction" }) }}</span>
  </v-tooltip>
  <resource-capacity-creator v-model="dialog" :resource="resource" @created="emits('created')" />
</template>
